const TreatmentPriceCell = ({ renderPriceEditInputCell, renderPriceCell }) => ({
    field: 'price',
    headerName: 'Price',
    sortable: false,
    width: 170,
    minWidth: 170,
    editable: true,
    renderEditCell: renderPriceEditInputCell,
    renderCell: renderPriceCell,
});

export default TreatmentPriceCell;