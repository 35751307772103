/* eslint-disable no-nested-ternary */

import { common, grey, primary, widget } from 'src/theme/palette';
import { handleTreatmentClassName } from '../utils/getClassName';

export const stackStyle = {
    ml: '0',
    mr: 'auto',
    mt: '15px',
    maxWidth: '100%',
    mb: 0,
    px: 3,
    py: '1',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    '@media (max-width: 900px)': {
        flexWrap: 'nowrap',
        mb: '0',
        pb: '0'
    }
};

export const searchFilterContent = {
    ml: '0',
    mr: 'auto',
    mb: '10px',
    maxWidth: '100%',
    px: 3,
    py: '1',
    width: '100%',
};

export const tableTitleStyle = {
    padding: '20px 24px 24px',
    paddingTop: '24px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px !important'
};

export const treatmentTagFilterStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 900px)': {
        marginBottom: '0'
    },
    '@media (max-width: 767px)': {
        flexWrap: 'wrap'
    },
    'form': {
        width: '100%',
    }
};

export const filterFormBoxStyle = {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    p: '8px 0'
};

export const financialFilterFormBoxStyle = {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    p: '16px 16px 16px 0'
};

export const financeSupplierAlertStyle = {
    mb: 2,
    bgcolor: 'rgb(202, 253, 245)',
    color: 'info.darker',
    '.MuiAlert-icon': {
        color: 'rgb(0, 184, 217)'
    }
};

export const textFieldStyle = {
    width: '100%',
    maxWidth: '100%',
    borderRadius: '15px',
    boxShadow: 'none',
    ml: '0',
    minWidth: '100%',
    'input': {
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis'
    },
    '@media (max-width: 1199px)': {
        ml: '0',
        width: '100%',
    },
    '@media (max-width: 767px)': {
        mb: '10px'
    }
};

export const treatmentBoxStyle = {
    flex: '1',
    minWidth: '200px',
    mb: {
        sm: 2,
        md: 0,
        lg: 0,
        xs: 2
    },
    mr: '16px',
    zIndex: 999999,
    '.MuiInputBase-root': {
        flexWrap: 'nowrap'
    },
    '.MuiInputBase-root .MuiAutocomplete-input': {
        pl: '0',
        pr: '0',
        ml: '5px',
        minWidth: '10px'
    },
    '@media(max-width: 1199px)': {
        width: '49%',
    },
    '@media(max-width: 900px)': {
        maxWidth: '100%',
        mb: '10px'
    },
    '@media (max-width: 767px)': {
        width: '100%',
        mb: '20px',
    }
};

export const treatmentChipBoxStyle = {
    width: '100%'
};

export const treatmentChipTextStyle = (type) => ({
    margin: '2px 2px',
    padding: '0',
    height: 'auto',
    fontSize: '11px',
    bgcolor: type === 'compaign' && 'rgba(24, 144, 255, 0.16)',
    color: type === 'compaign' && widget.tagBlueColor,
    ' .MuiChip-label': {
        padding: '0.5px 8px',
        fontSize: '14px'
    },
    ' .MuiSvgIcon-root': {
        fontSize: '16px',
        color: type === 'compaign' && widget.tagBlueColor
    },
    '&:hover': {
        bgcolor: type === 'compaign' && 'rgba(24, 144, 255, 0.16)'
    },
    '.MuiChip-deleteIcon': {
        minWidth: '20px'
    }
});

export const referralBoxStyle = {
    width: '100%',
    mb: {
        sm: 2,
        md: 0,
        lg: 0,
        xs: 2
    },
    mr: 0,
    zIndex: 999999,
    '@media(max-width: 900px)': {
        maxWidth: '100%',
        mb: '10px'
    },
    '@media (max-width: 767px)': {
        width: '100%',
    }
};

export const searchBarStyle = {
    display: 'flex',
    width: '100%',
    minWidth: '100%',
    mb: '20px',
    pl: '16px',
    pr: '24px',
    '@media(max-width: 1199px)': {
        mt: '10px',
        width: '100%'
    },
    '@media(max-width: 900px)': {
        flexWrap: 'wrap'
    },
    '@media (max-width: 767px)': {
        display: 'none'
    },
    '@media(max-width: 479px)': {
        flexWrap: 'wrap'
    }
};

export const dateRangeBoxStyle = {
    display: 'flex',
    '@media (max-width: 767px)': {
        width: '100%',
        ml: '0'
    }
};

export const startDateBoxStyle = {
    minWidth: '50px',
    ml: '16px',
    maxWidth: '400px',
    '@media (max-width: 767px)': {
        minWidth: 'auto',
        maxWidth: '400px',
    }
};


export const tabsStyle = {
    px: 3,
    bgcolor: 'background.neutral',
    borderRadius: '0',
    mb: 0,
    mt: 0,
};

export const tabStyle = (type, item, count) => ({
    minWidth: {
        xs: 'auto',
        sm: (type === 'DashNewLeadTable' || type === 'NewLeadTable' || type === 'ConsultantAttend') ? '13%' : 'auto'
    },
    'span': {
        ml: '0'
    },
    textTransform: 'none !important',
    display: (item.value === 'No Treatment' && count === 0 || item.value === 'AWAITING_RESPONSE' && count === 0 || item.value === 'NHS' && count === 0) && 'none'
});

export const tableStyle = {
    '.MuiTableCell-root': {
        boxShadow: 'none !important',
        pl: '16px !important'
    }
};

export const cancelBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
    boxShadow: 'none',
    border: '1px solid rgba(145, 158, 171, 0.32)'
};

export const yesBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
    bgcolor: `${common.mintyZest} !important`,
    color: common.white
};

export const yesBtnStyleV2 = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
};

export const tableLabel = {
    height: '22px',
    width: '40px',
    background: 'transparent !important',
    border: `2px solid ${common.tomatoRed}`,
    padding: '0 8px',
    color: common.tomatoRed,
    ml: '12px',
    fontSize: '12px'
};

export const referralOutBoxStyle = {
    flex: '1',
    minWidth: '200px',
    ml: '16px',
    '@media(max-width: 314px)': {
        ml: '0px',
        mb: '16px'
    }
};

// autocomplete
export const autocomplete = {
    '& input::selection': {
        backgroundColor: 'unset',
    },
    '& input::-webkit-selection': {
        backgroundColor: 'unset',
    },
    '& input::-moz-selection': {
        backgroundColor: 'unset',
    },
    '& input': {
        caretColor: 'transparent',
    },
};

// tableHeading
export const tableHeading = (headCell, type, practiceMessageTable) => ({
    width: headCell.width,
    display: headCell?.show !== undefined && !headCell?.show && 'none',
    minWidth: type === 'DoctorTable' ? '80px' : 'auto',
    whiteSpace: 'nowrap',
    bgcolor: type === 'NewLeadTable' && common.white,
    borderBottom: type === 'NewLeadTable' && 'solid 1px rgba(0,0,0,0.1)',
    ':first-of-type': {
        boxShadow: (practiceMessageTable || type === 'notification') ? 'none' : '',
        borderBottomLeftRadius: practiceMessageTable ? '0px' : '',
        borderRadius: '0',
        paddingLeft: type === 'similar_leads' ? '68px !important' : '16px'
    },
    ':last-of-type': {
        borderRadius: '0',
        minWidth: type !== 'DoctorTable' || type !== undefined ? '68px' :
            type === 'DoctorTable' ? '80px' : '160px',
        boxShadow: (practiceMessageTable || type === 'notification') ? 'none' : '',
        borderBottomRightRadius: practiceMessageTable ? '0px' : ''
    }
});

export const filterBoxStyle = (isLight) => ({
    padding: '0 24px',
    mt: '20px',
    mb: '20px',
    display: 'none',
    justifyContent: 'flex-end',
    '@media(max-width: 767px)': {
        display: 'flex'
    },
    'button': {
        border: '1px solid rgba(145, 158, 171, 0.32)',
        borderRadius: '50px',
        background: 'transparent !important',
        color: isLight ? grey[800] : 'white',
        boxShadow: 'none !important',
        minWidth: '80px',
        fontWeight: '700',
        padding: '6px 12px 6px 10px',
        'svg': {
            mr: '10px'
        }
    },
    '&.active + .filter-toggle-stack': {
        display: 'flex'
    },
    '&.active + .filter-toggle-stack + .filter-toggle-box': {
        display: 'flex'
    }
});

export const pickerBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '56px',
    borderRadius: '8px',
    cursor: 'pointer',
    bgcolor: 'rgba(145, 158, 171, 0.08)'
};

export const tableFilterBox = (sx) => ({
    p: 1,
    borderRadius: 1,
    overflow: 'hidden',
    borderStyle: 'dashed',
    bgcolor: 'transparent',
    ...sx,
});
export const clearButtonStyle = {
    flexShrink: 3,
    padding: '6px 15px',
    ml: '8px',
    minWidth: '86px'
};

export const mainDateFilter = {
    position: 'relative',
    display: 'inline-flex',
    lineHeight: 0,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'flex-start',
    verticalAlign: 'middle'
};

export const selectFilter = {
    position: 'absolute',
    top: '-20px',
    width: '100%',
    height: '100%',
    right: 0,
    opacity: 0
};

export const selectMenuStyle = {
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};

export const paginationBox = {
    position: 'relative',
    px: 3,
    display: 'flex',
    alignItems: 'center',
    mb: '10px'
};

export const menuPropsStyle = {
    elevation: 0,
    sx: {
        overflow: 'auto',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1,
        height: '250px',
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 95,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
};

export const columnMenuStyle = {
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
    p: '1px 0 !important',
    m: '0 !important',

};

export const selectedLeadBoxStyle = {
    position: 'absolute',
    width: '100%',
    top: 0,
    background: primary.lighter,
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0,
    minHeight: '72px',
    zIndex: '999999'
};

export const depositPendingBoxStyle = {
    flex: '1',
    ml: '16px',
    minWidth: '200px'
};

export const leadDetailTabsGridStyle = (loader) => ({
    '.MuiDataGrid-columnHeader': {
        opacity: loader && 0
    },
    '.MuiDataGrid-pinnedColumnHeaders': {
        background: loader && grey[200],
        '.MuiDataGrid-columnHeader:last-child': {
            pr: '48px'
        },
        '[data-field="actions"].MuiDataGrid-columnHeader:last-child': {
            pr: '10px'
        }
    },
    '.MuiDataGrid-virtualScrollerContent': {
        opacity: loader && 0
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center'
    },
    '[data-field="assignee"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="booked_with"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="consultation_booked_with"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="tag"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="lastContact"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="first_name"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="last_name"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="lead_treatments__appointments__datetime"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="date_attended"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="transition_date"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="consultation_date"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="created_at"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="email"] .MuiDataGrid-columnHeaderTitleContainer, [data-field="phone"] .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'flex-start'
    },
    '.MuiDataGrid-cell': {
        justifyContent: 'center'
    },
    '.MuiDataGrid-cell[data-field="first_name"], .MuiDataGrid-cell[data-field="last_name"]': {
        justifyContent: 'flex-start',
        '.MuiTypography-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    },
    '.cancelled-appointment-row': {
        backgroundColor: `${common.rust} !important`,
        '.MuiButtonGroup-root': {
            boxShadow: 'none !important'
        },
        'button.Mui-disabled': {
            backgroundColor: 'rgba(145, 158, 171, 0.24) !important'
        }
    },
    '& .MuiDataGrid-detailPanel': {
        overflow: 'visible',
    },
    ...handleTreatmentClassName()
});

export const treatmentCountBoxStyle = {
    bgcolor: 'rgb(233 233 233)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: 0,
    borderRadius: '50%',
    ml: 1,
    fontSize: 12,
    width: 32,
    minWidth: 32,
    height: 32,
    lineHeight: '32px'
};

export const assignCountBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: 0,
    borderRadius: '50%',
    ml: 0,
    fontSize: 12,
    width: 24,
    minWidth: 24,
    height: 24,
    lineHeight: '24px'
};
export const viewDetailBoxStyle = { ml: '0', p: '7px', borderRadius: '50%', width: '100%', height: '100%', display: 'flex', alignItems: 'center' };
export const zIndexStyle = { zIndex: '999999' };
export const borderRadiosStyle = { borderRadius: 2 };
export const dialogTitleStyle = { padding: '40px 40px 0px', textAlign: 'center' };
export const dialogActionStyle = { padding: '40px !important', justifyContent: 'center' };
export const dateWrapperStyle = { display: 'flex', alignItems: 'center', position: 'relative' };
export const lastNoteStyle = {
    width: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
};