import { isArray } from 'lodash';
import { useSelector } from 'src/redux/store';
import useAuth from './useAuth';

const useAdminUser = () => {
    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const { user } = useAuth();

    const isAdminUser = () => {
        if (isArray(results) && results.length > 0) {
            const admin = results.find(el => el.user && el.user.id === user?.pk);
            return admin?.role === 'ADMIN';
        }
        return false;
    };

    return {
        isAdminUser
    };
};

export default useAdminUser;
