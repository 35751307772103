/* eslint-disable default-param-last */
import { useScroll } from 'framer-motion';
import { useMemo, useState, useEffect, useCallback } from 'react';

const useOffSetTop = (top = 0, options) => {
    const { scrollY } = useScroll(options);

    const [value, setValue] = useState(false);

    const onOffSetTop = useCallback(() => {
        scrollY.on('change', (scrollHeight) => {
            setValue(scrollHeight > top);
        });
    }, [scrollY, top]);

    useEffect(() => {
        onOffSetTop();
    }, [onOffSetTop]);

    const memoizedValue = useMemo(() => value, [value]);

    return memoizedValue;
};

export default useOffSetTop;
