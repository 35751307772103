/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import { useAuth } from '../hooks';
// components
import { LoadingScreen } from '../components/loading';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
    const { isAuthenticated, isInitialized } = useAuth();
    const { pathname, search } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!isInitialized) {
        return <LoadingScreen />;
    }

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Navigate to={`/auth/login?next=${pathname}${search}`} />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
}

AuthGuard.propTypes = {
    children: PropTypes.node,
};