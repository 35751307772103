import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';

const CustomMenuItem = ({ handleClickNewLead, handleClickConsentClick, handleClickStripePaymentClick, handleClickCallScriptClick, handleReviewClick }) => (
    <>
        <MenuItem onClick={handleClickNewLead} disableRipple>New Lead</MenuItem>
        <MenuItem onClick={handleClickConsentClick} disableRipple>New Consent Link</MenuItem>
        <MenuItem onClick={handleClickStripePaymentClick} disableRipple>New Stripe Payment Link</MenuItem>
        <MenuItem onClick={handleClickCallScriptClick} disableRipple>Call Script</MenuItem>
        <MenuItem onClick={handleReviewClick} disableRipple>Generate Feedback Link</MenuItem>
    </>
);

export default CustomMenuItem;

CustomMenuItem.propTypes = {
    handleClickNewLead: PropTypes.func,
    handleClickConsentClick: PropTypes.func,
    handleClickStripePaymentClick: PropTypes.func,
    handleClickCallScriptClick: PropTypes.func,
    handleReviewClick: PropTypes.func,
};