import { isEmpty } from 'lodash';
import { useSelector } from 'src/redux/store';

export default function useGetWidgetCounter(counter) {
    const { widgetCounter } = useSelector((state) => state.widgetCounter);
    const getCounter = () => {
        if (!isEmpty(counter)) {
            return counter;
        }
        return widgetCounter;
    };

    const tabCounter = (data) => getCounter()?.filter((value) => (value.key === data ? value.count : null));

    return { tabCounter };
}

export const useGetWidgetTeethCounter = (counter) => {
    const { widgetCounter } = useSelector((state) => state.widgetCounter);
    const getCounter = () => {
        if (!isEmpty(counter)) {
            return counter;
        }
        return widgetCounter;
    };

    const tabTeethCounter = (data) => getCounter()?.filter((value) => (value.key === data ? value?.teethCount : null));

    return { tabTeethCounter };
}
