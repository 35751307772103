import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { store } from 'src/redux/store';
import { sentMessages, setSendEmailStatus } from 'src/redux/slices/mail';
import { Iconify } from 'src/components';
import { useSelector } from 'react-redux';
import { applyStylesToTags } from '../../../../utils/applyStylesToTags ';

const SendButton = ({
    editForm,
    attachment,
    handlePromiseData,
    leadDetail,
    subject,
    message,
    base,
    title,
    successDone,
    isFromSideBar,
    leadID,
    selectedFileEmpty,
    uploadAttachment,
    isFromUnableToContact,
    sendUnableToContact
}) => {
    const { dispatch } = store;
    const { mergeTags: mergeTagsInfo } = useSelector(state => ({ mergeTags: state.mergeTags }));

    const [dataMap, setDataMap] = React.useState({});


    React.useEffect(() => {
        setDataMap(prv => ({ ...prv, ...(mergeTagsInfo?.mtValPractice ?? {}), ...(mergeTagsInfo?.mtValUser ?? {}) }))
        if (!mergeTagsInfo?.mtValLoadingLeads && !mergeTagsInfo?.mtValLoadingTreatments) {
            setDataMap(prv => ({ ...prv, ...(mergeTagsInfo?.mtValTreatments ?? {}), ...(mergeTagsInfo?.mtValLeads ?? {}) }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mergeTagsInfo?.mtValLoadingLeads, mergeTagsInfo?.mtValLoadingTreatments]);

    const parseMergeTags = (datalabels) => (
        // Use regex to find all words starting with '$' followed by alphanumeric characters or underscores
        datalabels?.replace(/\$[a-zA-Z0-9_]+/g, (match) => {
            // Remove the '$' sign to get the actual key
            const key = match.slice(1);

            // Return the value from the dataMap if it exists, otherwise return the original field
            return Object.prototype.hasOwnProperty.call(dataMap, key) ? dataMap[key] : "";
        }) ?? datalabels);

    const handleClick = async () => {
        const base64Array = [];
        if (!selectedFileEmpty && attachment.attachments?.length > 0) {
            await handlePromiseData(attachment?.attachments, base64Array);
        } else {
            await handlePromiseData(uploadAttachment, base64Array);
        }
        const alreadyUploadedFile = base64Array || [];
        const newUploadedFile = base?.files || [];
        const combinedArray = alreadyUploadedFile?.concat(newUploadedFile);
        if (editForm) {
            const content = message;
            const modifiedContent = applyStylesToTags(content);
            let data = {
                category: 'COMMUNICATION',
                channel: 'EMAIL',
                subject,
                body: modifiedContent,
                attachments: combinedArray,
                lead: isFromSideBar === 'true' ? leadID : leadDetail.id
            };
            data = {
                ...data,
                subject: parseMergeTags(data?.subject),
                body: parseMergeTags(data?.body)
            }
            const getTitleCondition = title === 'Save as drafts' ? 'Save as drafts' : 'Send';
            dispatch(setSendEmailStatus(getTitleCondition));
            dispatch(sentMessages({
                data,
                title: isFromUnableToContact === 'true' ? 'SendEmail' : title,
                successDone,
                leadID: isFromSideBar === 'true' ? '' : leadDetail.id,
                sendUnableToContact: isFromUnableToContact === 'true' ? sendUnableToContact : () => { }
            }));
        }
    };

    return (
        <Button
            variant='contained'
            color='primary'
            disabled={
                // eslint-disable-next-line no-nested-ternary
                isFromSideBar === 'true'
                    ?
                    typeof message === 'object' ? Object.keys(message).length === 0 || message === '' || subject === '' || message === '<p><br></p>' || leadID === '' || leadID === undefined : message === '' || subject === '' || message === '<p><br></p>' || leadID === '' || leadID === undefined
                    :
                    message !== undefined &&
                    Object.keys(message).length === 0 || message === '<p><br></p>' || message === '' || subject === ''}
            endIcon={<Iconify icon='iconamoon:send-fill' />}
            onClick={handleClick}
            sx={{ ml: 1, textTransform: 'none !important' }}
        >
            {title}
        </Button>
    );
};

SendButton.propTypes = {
    editForm: PropTypes.object,
    attachment: PropTypes.object,
    uploadAttachment: PropTypes.array,
    handlePromiseData: PropTypes.func,
    leadDetail: PropTypes.any,
    subject: PropTypes.string,
    message: PropTypes.object,
    base: PropTypes.object,
    title: PropTypes.string,
    successDone: PropTypes.func,
    isFromSideBar: PropTypes.bool,
    leadID: PropTypes.number,
    selectedFileEmpty: PropTypes.bool,
    isFromUnableToContact: PropTypes.string,
    sendUnableToContact: PropTypes.func
};

export default SendButton;