import { useState } from 'react';

// ----------------------------------------------------------------------

export default function useTable(props) {
    const [dense, setDense] = useState(props?.defaultDense || false);

    const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');

    const [order, setOrder] = useState(props?.defaultOrder || 'asc');

    const [page, setPage] = useState(props?.defaultCurrentPage || 0);

    const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || 10);

    const [selected, setSelected] = useState(props?.defaultSelected || []);

    const onSort = (id) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const onSelectRow = (id) => {
        const selectedIndex = selected.indexOf(id);

        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const onSelectAllRows = (checked, newSelecteds) => {
        if (checked) {
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const onChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onChangeDense = (event) => {
        setDense(event.target.checked);
    };

    // filter

    return {
        dense,
        order,
        page,
        setPage,
        orderBy,
        rowsPerPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        setRowsPerPage,
        setOrder,
        //
        onSort,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    };
}

// ----------------------------------------------------------------------

export function descendingComparator(a, b, orderBy) {

    if(orderBy.includes('.')){
        const orderSplit = orderBy.split('.');
        const firstArray = (typeof b[orderSplit[0]][orderSplit[1]] === 'string') ? b[orderSplit[0]][orderSplit[1]].toLowerCase() : b[orderSplit[0]][orderSplit[1]];
        const secondArray = (typeof a[orderSplit[0]][orderSplit[1]] === 'string') ? a[orderSplit[0]][orderSplit[1]].toLowerCase() : a[orderSplit[0]][orderSplit[1]];

        if ( firstArray < secondArray) {
            return -1;
        }

        if (firstArray > secondArray) {
            return 1;
        }
    }
  
    const firstArray = (typeof b[orderBy] === 'string') ? b[orderBy].toLowerCase() : b[orderBy];
    const secondArray = (typeof a[orderBy] === 'string') ? a[orderBy].toLowerCase() : a[orderBy];

    if (firstArray < secondArray) {
        return -1;
    }

    if (firstArray > secondArray) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function emptyRows(page, rowsPerPage, arrayLength) {
    return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}