import React from 'react';
import { Box, Typography } from '@mui/material';
import { generateLabel } from '../../utils/getReferralData';
import { tableCellInnerBoxStyle } from '../../styles/NewLeadTableRowStyle';
import { treatmentCellTypoStyle } from '../../styles/LeadTableStyle';

const PaymentProcessorCell = () => ({
    field: 'payment_processor',
    headerName: 'Payment processor',
    width: 200,
    minWidth: 200,
    renderCell: (params) => <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'left' }}>
        <Typography variant='body2' sx={{ ...treatmentCellTypoStyle }}>
            {params.row.payment_processor ? generateLabel(params.row.payment_processor?.payment_processor) : '--' || '--'}
        </Typography>
    </Box>,
});

export default PaymentProcessorCell;