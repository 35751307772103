import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel } from '@mui/material';
import { fieldLabelStyle2, mainLabelBoxStyle } from '../../styles/CallScripts';

const CustomDescriptionComponent = ({ description }) => {
    if (!description) return null;

    return (
        <Box sx={{ ...mainLabelBoxStyle, bgcolor: 'widget.notificationLightGreen' }}>
            <FormLabel sx={fieldLabelStyle2}>{description}</FormLabel>
        </Box>
    );
};

export default CustomDescriptionComponent;

CustomDescriptionComponent.propTypes = {
    description: PropTypes.string,
};
