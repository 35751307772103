/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Grid,
    FormControl,
    FormHelperText,
    Button,
    Container,
    ToggleButtonGroup,
    ToggleButton,
    Typography
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSettingsContext } from 'src/components/settings/context';
import UserSearchDropdown from './UserSearchDropdown';
import {
    FormProvider,
} from './hook-form';
import Iconify from './Iconify';
// store
import { useSelector, dispatch } from '../redux/store';
import { saveNewLeadDetail, updateSteps, addAppointment } from '../redux/slices/lead';
// style
import {
    FollowUpTitle,
    controllerGrid,
    boxStyle
} from '../styles/AddAppointmentStyle';
import { toggleButtonStyle } from '../styles/Common';
// validation
import { EditAppointmentSchema } from '../validations/validations';

import { leadFooterButtonsStyle, footerBoxStyle, btnStyle } from '../styles/ScheduleReminder';

// ----------------------------------------------------------------------------------------------------------

const AddAppointmentBooked = ({ handleClose, type, leadId, treatmentId }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const settings = useSettingsContext();
    const { createLead } = useSelector((state) => state.lead);
    const { contactMethod, appointmentType, appointmentMethod } = useSelector((state) => state.schema);
    const [assignToState, setAssignToState] = useState([]);

    const defaultValues = {
        appoinmentDate: null,
        bookedWith: [],
        practiceContactedVia: '',
        preferredContactMethod: '',
        appointmentType: '',
    };

    const methods = useForm({
        resolver: yupResolver(EditAppointmentSchema(assignToState)),
        defaultValues,
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        trigger
    } = methods;

    useEffect(() => {
        if (assignToState?.length > 0) {
            trigger('bookedWith');
        }
    }, [assignToState, trigger]);

    const onSubmit = (data) => {
        if (type === 'RescheduleReminder') {
            const appointmentDetail = {
                datetime: data.appoinmentDate ? `${moment(data.appoinmentDate).format('DD-MM-YYYY HH:mm:ss')}.0Z` : null,
                method: data.preferredContactMethod || '',
                contacted_via: data.practiceContactedVia || '',
                note: data.notes || '',
                assignees: assignToState?.map((el) => el?.id),
                lead_treatment: treatmentId || createLead?.lead_treatment?.id,
                type: data?.appointmentType
            };
            dispatch(saveNewLeadDetail({
                startTreatment: {
                    appointmentDetail,
                }
            }));
            dispatch(addAppointment(appointmentDetail, practiceId, leadId, handleClose, 'AddAppointment'));
        }
    };

    const updateNewStep = (value) => {
        const attaendence = moment(value).format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss');
        dispatch(updateSteps(attaendence ? 'payment' : 'attendance'));
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%', pt: '30px' }}>
                <FollowUpTitle sx={{ mb: '30px' }}>Book an appointment</FollowUpTitle>
                <Box>
                    <Grid sx={controllerGrid}>
                        <Box>
                            <Controller
                                name='appoinmentDate'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <DateTimePicker
                                            label='Appointment date (Required)'
                                            value={field.value || null}
                                            minDateTime={new Date()}
                                            format={'dd/MM/yyyy HH:mm'}
                                            ampm={false}
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                                updateNewStep(newValue);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!error,
                                                    helperText: error?.message,
                                                    onKeyDown: (e) => e.preventDefault()
                                                },
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </Box>
                        <UserSearchDropdown name={'bookedWith'} control={control} label='Booked with (Required)' assignToState={assignToState} setAssignTo={setAssignToState} error={errors?.bookedWith} />
                    </Grid>
                    <Box sx={{ ...boxStyle, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                        <Controller
                            name="preferredContactMethod"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ m: '0 0 10px' }}
                                    >
                                        Appointment method (Required)
                                    </Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => {
                                            if (value === null) {
                                                field.onChange('');
                                                return;
                                            }
                                            field.onChange(value);
                                        }}
                                        aria-label="Platform"
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                    >
                                        {appointmentMethod?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="appointmentType"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ m: '0 0 10px' }}
                                    >
                                        Appointment type (Required)
                                    </Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => {
                                            if (value === null) {
                                                field.onChange('');
                                                return;
                                            }
                                            field.onChange(value);
                                        }}
                                        aria-label="Platform"
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                    >
                                        {appointmentType?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="practiceContactedVia"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ m: '0 0 10px' }}
                                    >
                                        Contact method (Required)
                                    </Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => {
                                            if (value === null) {
                                                field.onChange('');
                                                return;
                                            }
                                            field.onChange(value);
                                        }}
                                        aria-label="Platform"
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                    >
                                        {contactMethod?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                    </Box>
                </Box>
                <Box sx={footerBoxStyle}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                        sx={leadFooterButtonsStyle}
                    >
                        <Button
                            color="primary"
                            variant='contained'
                            type="submit"
                            sx={btnStyle}
                        >
                            Finish
                        </Button>
                    </Container>
                </Box>
            </Box>
        </FormProvider>
    );
};

AddAppointmentBooked.propTypes = {
    handleClose: PropTypes.func,
    type: PropTypes.any,
    leadId: PropTypes.any,
    treatmentId: PropTypes.any
};

export default AddAppointmentBooked;