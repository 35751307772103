import { useRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
    Grid,
    Dialog,
    Container,
    Stack,
    Button,
    Box
} from '@mui/material';
import { AddFinancialTracker } from '../../sections/@dashboard/financial';
import { Transition } from '.';
import {
    reminderDialogStyle,
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle
} from '../../styles/AddReminderPopup';

const AddFinancialTrackerPopup = ({ open, onClose, type, selected, selectedFinance }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={reminderDialogStyle}
        >
            <Stack sx={reminderStackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                        <AddFinancialTracker ref={inputRef} onClose={onClose} selected={selected} selectedFinance={selectedFinance} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={reminderFooterStyle}>
                <Button
                    color='inherit'
                    variant='outlined'
                    sx={cancelButtonStyle}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    onClick={handleNext}
                    sx={saveButtonStyle}
                >
                    {type === 'editTask' ? 'Save Changes' : 'Done'}
                </Button>
            </Box>
        </Dialog>
    );
};

export default AddFinancialTrackerPopup;

AddFinancialTrackerPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    selected: PropTypes.object,
    selectedFinance: PropTypes.any,
};