import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { Iconify } from '../../../../../components';
import { FormProvider, RHFSelect } from '../../../../../components/hook-form';
import AppointmentDateField from '../AppointmentDateField';
import { DATE_TITLES } from '../../../../../constants/newLead';
import { dateWrapperStyle, selectFilter, selectMenuStyle } from '../../../../../styles/NewLead';

const DateOfAppointmentCell = ({
    methods,
    handleChangeDateFormat,
    typed,
    updateLeadPage,
    reloadCounter,
    openAppointmentAssignTaskFuc,
    setValue,
    label = 'Date of appointment'
}) => ({
    field: 'lead_treatments__appointments__datetime',
    headerName: (
        <Box sx={dateWrapperStyle}>
            {label}
            <Iconify sx={{ mr: '8px', ml: '8px' }} icon={'mdi:calendar-filter-outline'} width={15} height={15} />
            <Box sx={selectFilter}>
                <FormProvider methods={methods}>
                    <RHFSelect
                        name={'lead_treatments__appointments__datetime'}
                        label='Title'
                        SelectProps={{ native: false }}
                        onChange={(e) => handleChangeDateFormat(e, 'lead_treatments__appointments__datetime', setValue)}
                    >
                        {DATE_TITLES.map((option, index) => (
                            <MenuItem key={index} value={option.value} sx={selectMenuStyle}>
                                <Iconify sx={{ mr: '8px' }} icon={option.icon} width={18} height={18} />
                                {option.label}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                </FormProvider>
            </Box>
        </Box>
    ),
    width: 200,
    minWidth: 160,
    sortable: false,
    renderCell: (params) => (
        <AppointmentDateField
            rowData={params.row}
            typed={typed}
            updateLeadPage={updateLeadPage}
            reloadCounter={reloadCounter}
            tableType={typed}
            OpenAssignTask={openAppointmentAssignTaskFuc}
        />
    ),
});

export default DateOfAppointmentCell;
