/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// libraries
import moment from 'moment';
import { Grid, Dialog, Container, Stack, Button, Box, Typography, Divider, useTheme } from '@mui/material';
import { uniqBy } from 'lodash';
// components
import { Label } from '..';
import { NormalLoading } from '../loading';
import { Transition } from '.';
// redux
import { useSelector } from '../../redux/store';
// hooks/utils
import { useGetCategories } from '../../hooks';
import { capitalizeFirstWord } from '../../utils/capitalizedFunc';
// style
import { common } from '../../theme/palette';
import {
    sendCampaignDialogStyle,
    reminderStackStyle,
    sendCampaignFooterStyle,
    cancelButtonStyle,
    sendButtonStyle,
    viewLeadsTextWrapper
} from '../../styles/AddReminderPopup';
import { tableLabel } from '../../styles/CallScripts';

const SendCampaignPopup = ({ open, onClose, handleSend, newCampaign, handlePreviewLeads }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [seconds, setSeconds] = useState(10);
    const [disable, setDisable] = useState(false);
    const [leadCount, setLeadCount] = useState(0);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && !el.user.is_superuser);
    const { selectedCampaign, isLoadingFinish, campaignLeadData, currentActiveTab } = useSelector((state) => state.campaign);
    const { referral } = useSelector((state) => state.schema);
    const { audienceCounter } = useSelector((state) => state.audienceManager);
    const getAudienceCount = () => audienceCounter?.recepient_count;

    const getCount = () => {
        if (currentActiveTab === 'By Conditions') return leadCount;
        if (getAudienceCount() && getAudienceCount() !== -1) return getAudienceCount();
        return 0;
    };

    useEffect(() => {
        if (campaignLeadData?.count >= 0 && currentActiveTab === 'By Conditions') {
            setLeadCount(campaignLeadData?.count);
            if (campaignLeadData?.count === 0) {
                setDisable(true);
            } else {
                setDisable(false);
            }
        }
    }, [campaignLeadData]);

    useEffect(() => {
        if (currentActiveTab === 'Existing audience' && getAudienceCount() === 0) {
            setDisable(true);
        }
    }, [getAudienceCount()]);

    const handleTimerEnd = () => {
        handleSend(true);
        setDisable(false);
    };

    const handleSendNowEvent = () => {
        setDisable(true);
        setSeconds(10);
    };

    useEffect(() => {
        if (disable && getCount() !== 0 && getCount() > 0) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(prevSeconds => prevSeconds - 1);
                } else {
                    clearInterval(interval);
                    handleTimerEnd();
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [disable, seconds]);

    const isSelected = () => Object.values(selectedCampaign)?.length > 0;

    const isDisabled = () => disable && getCount() > 0;

    const selectedCampaignFilters = selectedCampaign?.recepient?.filters;

    const categoryInArray = selectedCampaignFilters?.lead_treatments__treatment__treatment_item__treatment_category__in || [];

    const categories = useGetCategories();

    const getCategory = () => categories?.filter((el) => categoryInArray?.includes(el?.id))?.map((el) => el.name);

    const handleClose = () => { onClose('cancel'); setDisable(false); };

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={sendCampaignDialogStyle}
        >
            <NormalLoading tableData={isLoadingFinish && 'No Found'} pageCount={isLoadingFinish && 'No Found'} />
            <Stack sx={reminderStackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 0 }}>
                            <Typography sx={{ fontSize: '18px', textAlign: 'center', fontWeight: '700' }}>Ready to send your campaign?</Typography>
                            {isDisabled() && <Label sx={tableLabel}>{seconds}</Label>}
                        </Box>
                        <Divider sx={{ p: '24px 0 0' }} />
                        <Box sx={{ pt: '24px' }}>
                            <Box sx={viewLeadsTextWrapper}>
                                <Typography sx={{ fontSize: '16px', textAlign: 'center' }}>You’re about to send your campaign to: {getCount()} {getCount() > 1 ? 'leads' : 'lead'}.</Typography>
                                {currentActiveTab === 'By Conditions' && getCount() > 0 && <Button color='primary' variant='contained' sx={{ ...sendButtonStyle, marginLeft: '10px' }} onClick={handlePreviewLeads} >View Leads</Button>}
                            </Box>
                            {currentActiveTab === 'By Conditions' && <Typography sx={{ fontSize: '16px', textAlign: 'center', color: isLight ? common.black : common.white }}>
                                {(isSelected() ? selectedCampaignFilters?.lead_tags__tag__tag__name__in?.length > 0 : newCampaign?.tagsNames?.length > 0) && <Typography> Contacts tagged <b>{isSelected() ? selectedCampaignFilters?.lead_tags__tag__tag__name__in?.toString() : newCampaign?.tagsNames?.toString()}</b> <br /> </Typography>}
                                {(isSelected() ? selectedCampaignFilters?.lead_treatments__treatment__treatment_item__name__in?.length > 0 : newCampaign?.treatmentName?.length > 0) && <Typography> Contacts where treatment is <b>{isSelected() ? selectedCampaignFilters?.lead_treatments__treatment__treatment_item__name__in?.toString() : newCampaign?.treatmentName?.map(e => e.name).toString()}</b> <br /> </Typography>}
                                {
                                    (isSelected() ? selectedCampaignFilters?.lead_treatments__status__in?.length > 0 : newCampaign?.statusNames?.length > 0) && (
                                        <Typography>
                                            Contacts where status is{' '}
                                            <b>
                                                {isSelected()
                                                    ? selectedCampaignFilters?.lead_treatments__status__in?.map(e => capitalizeFirstWord(e)).join(', ')
                                                    : newCampaign?.statusNames?.map(e => e.title).join(', ')}
                                            </b>
                                            <br />
                                        </Typography>
                                    )
                                }
                                {(isSelected() ? selectedCampaignFilters?.is_only_archived : newCampaign?.archivedValue?.length > 0) && <Typography> <b>Archive leads are {(selectedCampaignFilters?.is_only_archived === 'false' || selectedCampaignFilters?.is_open_for_communication === 'false' || selectedCampaignFilters?.is_open_for_communication === false || newCampaign?.is_only_archived?.is_only_archived === 'false') ? 'not' : ''} Open to communicate</b> <br /> </Typography>}
                                {(isSelected() ? selectedCampaignFilters?.assignees__user__id__in?.length > 0 : newCampaign?.practitionerName?.length > 0) && <Typography> Contacts where practitioner is <b>{isSelected() ? getUserList()?.filter(el => selectedCampaignFilters?.assignees__user__id__in?.includes(el?.user?.id))?.map(el => `${el?.user?.salutation || ''} ${el?.user?.first_name} ${el?.user?.last_name}`) : ''}</b> <br /> </Typography>}
                                {(isSelected() ? selectedCampaignFilters?.lead_source__referral__in?.length > 0 : newCampaign?.referralName?.length > 0) && <Typography> Contacts where referral is <b>{isSelected() ? referral?.filter((el) => selectedCampaignFilters?.lead_source__referral__in?.includes(el.value))?.[0]?.label : ''}</b> <br /> </Typography>}
                                {(isSelected() ? selectedCampaignFilters?.created_at__range?.length > 0 : newCampaign?.dateRange?.length > 0) && <Typography> Contacts where date range is <b>{isSelected() ? selectedCampaignFilters?.created_at__range.map(date => moment(date).format('DD-MM-YYYY')).join(' to ') : ''}</b> <br /> </Typography>}
                                {(isSelected() ? selectedCampaignFilters?.lead_treatments__treatment__treatment_item__treatment_category__in?.length > 0 : newCampaign?.category?.length > 0) && <Typography> Contacts where category is <b>{isSelected() ? uniqBy(getCategory())?.[0] : ''}</b> <br /> </Typography>}
                                {(isSelected() ? selectedCampaignFilters?.lead_source__type__in?.length > 0 : newCampaign?.category?.length > 0) && <Typography> Contacts where source type is <b>{isSelected() ? selectedCampaignFilters?.lead_source__type__in?.[0] : ''}</b> <br /> </Typography>}
                            </Typography>}
                        </Box>
                        <Divider sx={{ p: '24px 0 0' }} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={sendCampaignFooterStyle}>
                <Button
                    color='inherit'
                    sx={cancelButtonStyle}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={() => handleSendNowEvent()}
                    sx={sendButtonStyle}
                    disabled={disable}
                >
                    Send Now
                </Button>
            </Box>
        </Dialog >
    );
};

export default SendCampaignPopup;

SendCampaignPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    newCampaign: PropTypes.object,
    handleSend: PropTypes.func,
    handlePreviewLeads: PropTypes.func
};