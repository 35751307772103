/* eslint-disable no-empty-pattern */
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import Iconify from '../Iconify';
import { FormProvider } from '../hook-form';
import { useSelector, dispatch } from '../../redux/store';
import { updateCompleteStatus } from '../../redux/slices/lead';
import { SnackbarContent, InfoIcon } from '../../styles/DialogueTabs';

const TreatmentComplete = forwardRef(({}, ref) => {
    const { createLead, followLead } = useSelector((state) => state.lead);

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getFollowLeadDetail = (data, value) => {
        if (data.lead_treatments.length > 0) {
            const latestData =  data.lead_treatments.slice().sort((a,b)=>
                new Date(b.updated_at) - new Date(a.updated_at)
            );
            if(value === 'treatment'){
                return  latestData.length > 0 ? latestData[0].treatment : '';
            }
            if(value === 'price'){
                return  latestData.length > 0 ? latestData[0].price : '';
            }
            if(value === 'lead'){
                return  latestData.length > 0 ? latestData[0].lead : '';
            }
            if(value === 'id'){
                return  latestData.length > 0 ? latestData[0].id : '';
            }
        }
        return '';
    };

    const onSubmit = async () => {
        const data = {
            treatment: followLead && followLead.lead_treatments && getFollowLeadDetail(followLead,'treatment') || createLead && createLead.lead_treatment && createLead.lead_treatment.treatment || '',
            custom_price: followLead && followLead.lead_treatments && getFollowLeadDetail(followLead,'price') || createLead && createLead.lead_treatment  && createLead.lead_treatment.price || ''
        };
        data.lead = followLead && followLead.lead_treatments && getFollowLeadDetail(followLead,'lead') || createLead && createLead.lead_treatment  && createLead.lead_treatment.lead || '';
        data.id = followLead && followLead.lead_treatments && getFollowLeadDetail(followLead,'id') || createLead && createLead.lead_treatment  && createLead.lead_treatment.id || '';
        dispatch(updateCompleteStatus(true));
    };

    const methods = useForm({});

    const {
        handleSubmit,
    } = methods;
    
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <SnackbarContent sx={{ color: 'grey.800' }}>
                    <InfoIcon sx={{marginRight: '12px'}}>
                        <Iconify sx={{color:'#1890FF'}} icon={'bxs:info-circle'} width={20} height={20} />
                    </InfoIcon> This is a info message!
                </SnackbarContent>
            </Box>
        </FormProvider>
    );
});

export default TreatmentComplete;