import { useNavigate } from 'react-router';
import { dispatch, store, useSelector } from '../redux/store';
import { leadPage } from '../redux/slices/lead';
import { handleSnooze, setReminderData } from '../redux/slices/toDoNotifications';
import { localStorageSetItem } from '../utils/storage-available';

// ----------------------------------------------------------------------

export default function useNotificationsV2() {
    const navigate = useNavigate();

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { reminderData } = useSelector((state) => state.toDoNotifications);

    const handleCloseNotiFications = (id, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        const { reminderData } = store.getState().toDoNotifications;
        localStorageSetItem('reminderData', JSON.stringify(reminderData?.filter((ele) => ele.id !== id)));
        dispatch(setReminderData(reminderData?.filter((ele) => ele.id !== id)));
    };

    const handleNavigateToLeadDetail = (id, leadId) => {
        handleCloseNotiFications(id);
        dispatch(leadPage({ name: 'New Leads', path: '/dashboard/app' }));
        navigate(`/dashboard/practice/${practiceId}/lead-detail/${leadId}`, { state: { goToTaskTab: true, taskId: id } });
    };

    return {
        notifications: reminderData, handleSnooze, handleNavigateToLeadDetail, handleCloseNotiFications
    };
}

