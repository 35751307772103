/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useDebounce } from 'use-lodash-debounce';
// hooks
import { RHFAutocomplete } from '../hook-form';
// store 
import { dispatch, useSelector } from '../../redux/store';
import { getPracticeTreatmentList, startLoading } from '../../redux/slices/practiceTreatment';
// utils
import groupedTreatments from '../../utils/groupedTreatments';

// -------------------------------------------------------------------------------------------

const TreatmentDropdown = ({ type, name, value, onChange, control, options, ...props }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { treatmentsList: { results, count }, isLoading } = useSelector((state) => state.practiceTreatment);
    const [treatmentOptions, setTreatmentOptions] = useState([]);
    const [position, setPosition] = useState(0);
    const [callApi, setCallApi] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const treatmentDebounce = useDebounce(callApi, 800);
    const inputValueDebounce = useDebounce(inputValue, 800);
    const listElem = useRef();
    const mounted = useRef();

    useEffect(() => {
        if (results) {
            const data = groupedTreatments(results);
            const arr = data.filter((elem, index, self) => self.indexOf(elem) === index);
            setTreatmentOptions(arr);
        }
    }, [results]);

    useEffect(() => {
        if (value || value === '') {
            setInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (practiceId && type === 'treatmentAPI') {
            const data = {
                limit: 30,
                offset: 0
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    }, [practiceId]);

    useEffect(() => {
        if (treatmentDebounce) {
            loadMoreData();
        }
    }, [treatmentDebounce]);

    const loadMoreData = () => {
        if (count > results?.length) {
            const data = {
                limit: 30,
                offset: results?.length
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const handleOnchange = (e, value) => {
        onChange(e, value);
        if (value) {
            const data = {
                limit: 30,
                offset: 0
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    };

    useEffect(() => {
        if (inputValueDebounce && inputValueDebounce !== value) {
            const data = {
                type: 'searchTreatment',
                treatment: inputValueDebounce
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    }, [inputValueDebounce]);

    const inputValueHandler = (value) => {
        dispatch(startLoading());
        setInputValue(value);
        if (!value) {
            const data = {
                limit: 30,
                offset: 0
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    };

    return (
        <RHFAutocomplete
            {...props}
            name={name}
            control={control}
            onChange={handleOnchange}
            value={{ name: inputValue }}
            onInputChange={(e, value) => inputValueHandler(value)}
            options={options || treatmentOptions}
            noOptionsText={isLoading ? 'Loading...' : 'No options'}
            groupBy={(option) => option?.firstLetter}
            isOptionEqualToValue={(option, value) => option.name === value}
            getOptionLabel={(option) => option?.name}
            ListboxProps={{
                ref: listElem,
                className: 'treatmentDropdown',
                onScroll: (event) => {
                    const listboxNode = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
                    if (listboxNode) {
                        setPosition(event.target.scrollTop + event.target.clientHeight);
                        setCallApi(listboxNode);
                    }
                }
            }}
        />
    );
};

TreatmentDropdown.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    control: PropTypes.any,
    options: PropTypes.any,
};

export default TreatmentDropdown;
