import PropTypes from 'prop-types';
import { Paper, Typography, Button, Box } from '@mui/material';

const SearchNotFound = ({ searchQuery, type = '', onClick, sx, ...other }) => (
    searchQuery ? (
        <Paper
            sx={{
                bgcolor: 'unset',
                textAlign: 'center',
                ...sx,
            }}
            {...other}
        >
            <Typography variant="h6" gutterBottom>
                Not Found
            </Typography>

            <Typography variant="body2">
                No results found for &nbsp;
                <strong>&quot;{searchQuery}&quot;</strong>.
                <br /> Try checking for typos or using complete words.
                <Box>
                    {type === 'consent' &&
                        <>
                            <Typography variant="body2"> OR </Typography>
                            <Button variant='contained' sx={{ ml: 1 }} onClick={onClick}> Create lead </Button>
                        </>
                    }
                </Box>
            </Typography>
        </Paper>
    ) : (
        <Typography variant="body2" sx={sx}>
            Please enter keywords
        </Typography>
    )
);

SearchNotFound.propTypes = {
    searchQuery: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    sx: PropTypes.object,
};

export default SearchNotFound;
