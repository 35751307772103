import PropTypes from 'prop-types';
// import { useState } from 'react';
// @mui
import { TableRow, TableCell, IconButton } from '@mui/material';
// components
import { Iconify } from '../../../../components';


// ----------------------------------------------------------------------

export default function NotificationSettingRows({ row, selected }) {

    return (
        <TableRow hover sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)', borderRadius:'10px'}}>
            <TableCell align="left" >
                {row.value}
            </TableCell>
            <TableCell align="left" >
                <IconButton onClick={() => selected(row, 'PLATFORM')}>
                    <Iconify icon={row.PLATFORM ? 'material-symbols:done' : 'iconoir:cancel' } color={ row.PLATFORM ? '#43a047' : '#c0c0c0' }/>
                </IconButton>
            </TableCell>
            <TableCell align="left" >
                <IconButton onClick={() => selected(row, 'EMAIL')}>
                    <Iconify icon={row.EMAIL ? 'material-symbols:done' : 'iconoir:cancel' } color={ row.EMAIL ? '#43a047' : '#c0c0c0' }/>
                </IconButton>
            </TableCell>
            <TableCell align="left" >
                <IconButton onClick={() => selected(row, 'SMS')}>
                    <Iconify icon={row.SMS ? 'material-symbols:done' : 'iconoir:cancel' } color={ row.SMS ? '#43a047' : '#c0c0c0' }/>
                </IconButton>
            </TableCell>
            <TableCell align="left" >
                {''}
            </TableCell>
        </TableRow>
    );
}

NotificationSettingRows.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.func,
};
