import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { Iconify } from 'src/components';

const CompleteTreatmentMenuItem = ({ params, handleCompleteTreatment }) => {
    if (
        params.row.status === 'CONSULTATION_ATTENDED' ||
        params.row.status === 'TREATMENT_STARTED' ||
        params.row.status === 'CONSULTATION_BOOKED' ||
        params.row.status === 'NEW'
    ) {
        return (
            <MenuItem onClick={() => handleCompleteTreatment(params.row)}>
                <Iconify icon={'fluent:cloud-sync-complete-24-filled'} />
                Complete Treatment
            </MenuItem>
        );
    }
    return null;
};

export default CompleteTreatmentMenuItem;

CompleteTreatmentMenuItem.propTypes = {
    params: PropTypes.object,
    handleCompleteTreatment: PropTypes.func,
};
