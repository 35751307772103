/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableContainer,
} from '@mui/material';
// components
import moment from 'moment';
import ReferralSummaryRow from './ReferralSummaryRow';
import { TableHeadCustom, TableNoData } from '../table';
import { TableLoading } from '../loading';
// constants
import { sourceReferralTableLabels } from '../../constants/SourceSummaryConst';
import {
    reportLeadStatuses,
    treatmentCategory,
    leadSourceReferral,
    referralSummary,
    leadArchive
} from '../../constants/ReportsLeadConst';
// hooks
import useTable from '../../hooks/useTable';
// store
import { useSelector, dispatch } from '../../redux/store';
import { reportsLeadListing } from '../../redux/slices/analytics';
import { getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
import { getTemplateList } from '../../redux/slices/practiceMessage';
// style
import { TableheadStyle, referralTableHeadStyle } from '../../styles/CategorySummaryTableStyle';
import { referralSummaryBoxStyle } from '../../styles/TreatmentTimeRageStyle';

const ReferralSummaryTable = () => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { referralSummaryData } = useSelector((state) => state.analytics);
    const { referral } = useSelector((state) => state.schema);

    const [tableRowdata, setTableRowdata] = useState(null);

    const {
        order,
        onSort,
        orderBy,
    } = useTable({ defaultOrder: 'desc' });

    useEffect(() => {
        if (practiceId) {
            const startTime = moment(new Date()).startOf('month').format('YYYY-MM-DD 00:00:00');
            const endTime = moment(new Date()).format('YYYY-MM-DD 23:59:00');
            dispatch(getTemplateList(practiceId));
            const data = {
                practiceId,
                type: referralSummary,
                category: leadSourceReferral,
                categoryStatus: reportLeadStatuses,
                startTime,
                endTime
            };
            const data1 = {
                practiceId,
                type: referralSummary,
                category: leadSourceReferral,
                categoryStatus: reportLeadStatuses,
                archivedCategory: leadArchive,
                isArchived: true,
                referral: referral?.length > 0 && referral[0]?.value,
                startTime,
                endTime
            };
            const treatmentData = {
                limit: 30,
                offset: 0
            };
            dispatch(reportsLeadListing(data));
            dispatch(reportsLeadListing(data1));
            dispatch(getPracticeTreatmentList(practiceId, treatmentData));
        }
    }, [practiceId]);

    useEffect(() => {
        if (referralSummaryData) {
            const grouped = _.mapValues(_.groupBy(referralSummaryData?.data, 'lead_source__referral'), arr => arr.map(obj => _.omit(obj, treatmentCategory)));
            const tableData = Object.entries(grouped).map((elem) => [elem[0], elem[1]?.map((el) => ({ ...el, label: referral?.filter((refEl) => el.lead_source__referral === refEl.value)[0]?.label }))]);
            setTableRowdata(tableData.filter((el) => el[0] !== 'null'));
        }
    }, [referralSummaryData]);

    const handleSorting = (value) => {
        onSort(value);
        if (value === 'enquiries') {
            const data = _.orderBy(tableRowdata, [o => o[1]?.reduce((total, currentValue) => total += currentValue?.count_id, 0)], [order]);
            setTableRowdata(data);
            return;
        }
        if (value === 'category') {
            const data = _.orderBy(tableRowdata, [o => o[1][0]?.label], [order]);
            setTableRowdata(data);
            return;
        }
        if (value === 'TOTAL_PRICE') {
            const data = _.orderBy(tableRowdata, [o => o[1]?.reduce((total, currentValue) => total += currentValue?.sum_lead_treatments__custom_price, 0)], [order]);
            setTableRowdata(data);
            return;
        }
        const data = _.orderBy(tableRowdata, [o => o[1].filter(el => el.lead_treatments__status === value)[0]?.count_id || 0], [order]);
        setTableRowdata(data);
    };

    return (
        <Box sx={{ mt: '30px' }}>
            <Box sx={referralSummaryBoxStyle}>
                <TableContainer sx={{ position: 'relative', overflow: 'hidden', '.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': { border: 'none !important' } }}>
                    <Box sx={{ maxHeight: '450px', overflow: 'auto' }}>
                        <Table stickyHeader sx={TableheadStyle} >
                            <TableHeadCustom
                                headLabel={sourceReferralTableLabels || []}
                                rowCount={10}
                                order={order}
                                orderBy={orderBy}
                                onSort={handleSorting}
                                sx={referralTableHeadStyle}
                            />
                            <TableBody>
                                {tableRowdata === null && <TableLoading tableData={tableRowdata === null && 'No Found'} pageCount={tableRowdata === null && 'No Found'} />}
                                {tableRowdata?.length > 0 && tableRowdata?.map((el, index) => <ReferralSummaryRow key={index} data={el} />)}
                                {tableRowdata?.length === 0 && <TableNoData isNotFound={tableRowdata?.length === 0} />}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
            </Box>
        </Box>
    );
};

ReferralSummaryTable.propTypes = {
};

export default ReferralSummaryTable;
