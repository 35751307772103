
// lib
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill'; 
import { Box, alpha } from '@mui/material'; 

// component
import Toolbar, { formats } from './toolbar';

// style
import { StyledEditor } from './styles'; 

// utils
import 'src/utils/highlight';

// ----------------------------------------------------------------------

export default function Editor({
    id = 'minimal-quill2',
    error,
    EditorMessage,
    simple = false,
    helperText,
    sx,
    onChanged,
    value,
    ...other
}) { 
    const modules = {
        toolbar: {
            container: `#${id}`,
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
        syntax: true,
        clipboard: {
            matchVisual: false,
        },
    };
 
    return (
        <Box>
            <StyledEditor
                sx={{
                    ...(error && {
                        border: (theme) => `solid 1px ${theme.palette.error.main}`,
                        '& .ql-editor': {
                            bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
                        },
                    }),
                    ...sx,
                }}
            >
                <Toolbar
                    id={id}
                    simple={simple} />
                <ReactQuill
                    modules={modules}
                    formats={formats}
                    placeholder="Write something awesome..."
                    {...other}
                    value={value}
                    onChange={(e) => {  
                        onChanged(e);
                    }}
                    ref={EditorMessage}
                />
            </StyledEditor>
            {helperText && helperText}
        </Box>
    );
}

Editor.propTypes = {
    error: PropTypes.bool,
    helperText: PropTypes.object,
    id: PropTypes.string,
    simple: PropTypes.bool,
    sx: PropTypes.object,
    onChanged: PropTypes.any,
    value: PropTypes.any,
    EditorMessage: PropTypes.any,
};
