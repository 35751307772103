import React from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, MenuItem, Typography, useTheme } from '@mui/material';
// other library
import CurrencyInput from 'react-currency-input-field';
// theme
import { grey, primary } from 'src/theme/palette';
// hooks
import { RHFTextField } from '../../../../components/hook-form';
// components
import { Scrollbar, Iconify } from '../../../../components';
// style
import { AmountInputStyle, ErrorTextStyle } from '../../../../styles/PaymentRefund';
import { teethAmountfieldStyle, teethAmountBoxStyle } from '../../../../styles/TreatmentEditPopover';
import { primaryTypographyPropsStyle, treatmentEditPopupListItem } from '../../../../styles/LeadDetailView';

const TreatmentPopoverScrollbar = ({
    treatments,
    values,
    errors,
    selected,
    handleScroll,
    handleTreatmentSelect,
    handleChangeTeeth,
    handleChangePrice,
    isScript
}) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const treatmentWhichNeedsTeethCount = ['Veneers', 'Composite Bonding'];

    const isTeethFieldNeeded = (row) => row.treatment_item?.name === selected && (treatmentWhichNeedsTeethCount?.includes(selected) || row.treatment_item?.treatment_category?.name === 'Implants');

    const getMargin = (isStarred) => {
        if (isStarred) return {};
        return { ml: 4 };
    };

    return (
        <Box sx={{ margin: '8px 0 0' }}>
            <Scrollbar sx={{ maxHeight: 70 * 5, minHeight: 70 * 5 }} onScroll={handleScroll}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    {treatments?.map((row, index) => (
                        <>
                            <MenuItem
                                onClick={() => handleTreatmentSelect(row)}
                                value={row.treatment_item?.name}
                                key={index}
                                disabled={selected !== row.treatment_item?.name && isScript}
                                sx={treatmentEditPopupListItem(selected, row, theme)}
                            >
                                {row?.is_starred && <Iconify
                                    icon={'mingcute:pin-fill'}
                                    width={24}
                                    height={24}
                                    sx={{ mr: 1, fontWeight: 400, color: row?.is_starred ? 'common.slateBlack' : grey[500] }}
                                />}
                                <Typography sx={{ ...primaryTypographyPropsStyle, mb: 0, ...getMargin(row?.is_starred) }}>{row?.treatment_item?.name}</Typography>
                                {row.treatment_item?.name === selected && (
                                    <Iconify
                                        icon={'ic:sharp-check'}
                                        width={24}
                                        height={24}
                                        sx={{ mr: 1, fontWeight: 400, color: primary.main, marginLeft: 'auto' }}
                                    />
                                )}
                            </MenuItem>
                            {isTeethFieldNeeded(row) && (
                                <Box sx={teethAmountBoxStyle}>
                                    <RHFTextField
                                        fullWidth
                                        sx={teethAmountfieldStyle}
                                        onChange={handleChangeTeeth}
                                        value={values.teethAmount}
                                        name="teethAmount"
                                        type="number"
                                        placeholder="Teeth amount"
                                    />
                                </Box>
                            )}
                            {row.treatment_item?.name === selected && (
                                <Box sx={{ m: '0px 0 8px', width: '100%' }}>
                                    <CurrencyInput
                                        name="treatmentPrice"
                                        value={values.treatmentPrice}
                                        decimalSeparator={'.'}
                                        thousandSeparator={','}
                                        onValueChange={(value) => handleChangePrice(value)}
                                        className="currency-input"
                                        decimalsLimit={2}
                                        decimalScale={2}
                                        inputType={'text'}
                                        prefix={'£ '}
                                        style={AmountInputStyle(errors.treatmentPrice, 'appointment', isLight)}
                                        step={1}
                                    />
                                    {errors.treatmentPrice && (
                                        <Typography variant="p" sx={ErrorTextStyle}>
                                            This field is required
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </>
                    ))}
                </Box>
            </Scrollbar>
        </Box>
    );
};

export default TreatmentPopoverScrollbar;

TreatmentPopoverScrollbar.propTypes = {
    treatments: PropTypes.any,
    values: PropTypes.any,
    errors: PropTypes.any,
    selected: PropTypes.string,
    handleScroll: PropTypes.func,
    handleTreatmentSelect: PropTypes.func,
    handleChangeTeeth: PropTypes.func,
    handleChangePrice: PropTypes.func,
    isScript: PropTypes.bool,
};
