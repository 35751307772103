/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState }  from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import {
    Typography,
    Box,
    FormControlLabel,
    Switch,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider
} from '@mui/material';
//
import Iconify from './Iconify';
// style
import { taskMainbox, messageTypeTypo } from '../styles/TasksMessageRow';

// ------------------------------------------------------------------------------------

const TasksMessageRow = ({ data, onUpdateStatus, upcomming }) => {
    const [open, setOpen] = useState({ data: '', isOpen: false });

    const handleClickOpen = (data) => {
        if (data?.instance_object?.body === '' || data?.instance_object?.body === '<p><br></p>') {
            return;
        }
        setOpen({ data, isOpen: true});
    };

    const handleClose = () => {
        setOpen({ data: '', isOpen: false});
    };

    const getCount = (key) => {
        const sentCount = data?.instance_object?.sent_messages[0]?.event_counts?.filter((e) => e.event === 'delivered')?.[0]?.count;
        const openCount = data?.instance_object?.sent_messages[0]?.event_counts?.filter((e) => e.event === 'opened')?.[0]?.count;
        const clickCount = data?.instance_object?.sent_messages[0]?.event_counts?.filter((e) => e.event === 'clicked')?.[0]?.count;
        if (key === 'sent') return sentCount || 0;
        if (key === 'open') return openCount || 0;
        if (key === 'click') return clickCount || 0;
        return 0;
    };

    return (
        <>
            <Box sx={{ ...taskMainbox, bgcolor: data.instance_object.message_group === 'SYSTEM' && 'rgb(240, 246, 250)' }}>
                <Box sx={{ padding: '8px 8px 8px 24px', minWidth: '120px' }} >
                    <Typography variant="span" noWrap sx={messageTypeTypo(data)} onClick={() => handleClickOpen(data)}>
                        <Iconify icon={data?.instance_object?.type === 'SMS' ? 'bi:chat-left-dots-fill' : data?.instance_object?.type === 'WHATSAPP' ? 'ri:whatsapp-fill' : 'eva:email-fill'} width={20} height={20} mr={1} />
                        {data?.instance_object?.type}
                    </Typography>
                </Box>
                <Box sx={{ padding: '8px', minWidth: '33.33%' }}>
                    <Typography noWrap sx={{ fontSize: '14px' }}>
                        {data?.instance_object?.subject || ''}
                    </Typography>
                </Box>
                <Box sx={{ padding: '8px', display: 'none' }}>
                    <Typography sx={{ fontSize: '14px', color: '#637381' }}>
						2 days after appointment
                    </Typography>
                </Box>
                <Box sx={{ padding: '8px', whiteSpace: 'nowrap' }}>
                    <Typography sx={{ fontSize: '14px', color: '#637381' }}>
                        {getCount('sent')} Sent | {getCount('click')} Clicked | {getCount('open')} Open
                    </Typography>
                </Box>
                {upcomming === 'upcomming' && <Box align="left" sx={{ padding: '8px' }}>
                    <FormControlLabel
                        sx={{ fontSize: '14px' }}
                        control={
                            <Switch
                                color="primary"
                                checked={data?.is_cancelled || false} 
                                onChange={() => onUpdateStatus(!data?.is_cancelled, data?.id)}
                                disabled={moment(data?.due, 'DD-MM-YYYY HH:mm:ss') < moment()}
                            />
                        }
                        label={'Cancel'}
                    />
                </Box>}
                <Box sx={{ display: 'flex', alignContent: 'center' }}>
                    <Dialog
                        open={open.isOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ '.MuiPaper-root': { minWidth: '600px', '@media(max-width: 767px)': { minWidth: '85%' } } }}
                    >
                        <DialogTitle id="alert-dialog-title" sx={{ borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)!important', pb: '24px', textAlign: 'center' }}>
                            {'Message Details'}
                        </DialogTitle>
                        <DialogContent sx={{ p: '0 !important' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='subtitle2' sx={{ fontSize: '16px', p: '8px 15px', textAlign: 'center' }}><span style={{ fontWeight: 400 }}>Subject:</span> {open?.data?.instance_object?.subject}</Typography>
                            </Box>
                            <Divider />
                            <Box sx={{ p: '9px', '.ql-container': { border: 'none', fontSize: '16px' } }}>
                                {open?.data?.instance_object?.body === '' && <Typography variant='subtitle2' sx={{ p: '12px 15px', textAlign: 'center' }}>none</Typography>}
                                {(open?.data?.instance_object?.body !== '') && 
							<Box sx={{ '.ql-editor img': { maxWidth: '550px !important' } }}>
							    <ReactQuill
							        value={open?.data?.instance_object?.body}
							        placeholder="Write something awesome..."
							        modules={{ 'toolbar': false }}
							        readOnly
							    />
							</Box>}
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ borderTop: 'solid 1px rgba(145, 158, 171, 0.24)!important' }}>
                            <Button onClick={handleClose} autoFocus variant="contained" sx={{ px: 3 }}>
							close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
        </>
    );};

TasksMessageRow.propTypes = {
    data: PropTypes.object,
    onUpdateStatus: PropTypes.func,
    upcomming: PropTypes.string,
};

export default TasksMessageRow;
