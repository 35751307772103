import { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
// @mui
import { Stack, Box, Table, TableBody, TableContainer, Typography } from '@mui/material';
// component
import { TableHeadCustom } from '../../../components/table';
// hooks
import useTable from '../../../hooks/useTable';
//
import PaymentTableRow from './paymentTableRow';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { checkoutListingSlice } from '../../../redux/slices/lead';
// style
import { PaymentTableStyle } from '../../../styles/PaymentRefund';
// constant
import { paymentTableLabels } from '../../../constants/payment';

// ----------------------------------------------------------------
export default function PaymentList({ onOpenDetailSection }) {
    const { paymentComplete } = useSelector((state) => state.lead);

    const [paymentSorting, setPaymentSorting] = useState([]);

    useEffect(() =>{
        dispatch(checkoutListingSlice([]));
    },[]);

    useEffect(() => {
        if (paymentComplete) {
            setPaymentSorting(paymentComplete.slice().sort((a, b) => new Date(moment(b.created_at, 'DD-MM-YYYY HH:mm:ss')) - new Date(moment(a.created_at, 'DD-MM-YYYY HH:mm:ss'))));
        }
    }, [paymentComplete]);

    const {
        selected,
        onSort,
        onSelectRow,
    } = useTable();

    return (
        <Stack>
            <Typography variant="h5" sx={{ fontSize: '18px !important', mb: '20px' }}> Payments</Typography>
            <TableContainer sx={PaymentTableStyle}>
                <Table stickyHeader sx={{ minWidth: { xs: 'auto', sm: 'auto' } }}>
                    <TableHeadCustom
                        headLabel={paymentTableLabels}
                        onSort={onSort}
                        rowCount={paymentSorting.length}
                        numSelected={selected.length}
                    />
                    <TableBody>
                        {paymentSorting !== undefined && paymentSorting.map((row, index) => (
                            <Box sx={{ display: 'contents' }} onClick={() => onOpenDetailSection(row.id, row.amount)} key={index}>
                                <PaymentTableRow
                                    row={row}
                                    selected={selected.includes(row.id)}
                                    onSelectRow={() => onSelectRow(row.id)}
                                />
                            </Box>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
}

PaymentList.propTypes = {
    onOpenDetailSection: PropTypes.func,
};
