import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { cancelBtnStyle, dialogActionStyle, dialogTitleStyle } from '../../../../styles/NewLead';
import { yesBtnStyleForLoader } from '../../../../styles/Common';

const CustomCompleteTreatmentDialog = ({ isLoader, open, handleClose, handleYes }) => (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={dialogTitleStyle}>
            Are you sure you want to complete the treatment?
        </DialogTitle>
        <DialogActions sx={dialogActionStyle}>
            <Button sx={cancelBtnStyle} onClick={handleClose}>
                Cancel
            </Button>
            <LoadingButton
                type='submit'
                variant='contained'
                loading={isLoader}
                loadingPosition='start'
                onClick={handleYes}
                sx={{ ...yesBtnStyleForLoader(isLoader) }}
                disabled={isLoader}
            >
                Yes
            </LoadingButton>
        </DialogActions>
    </Dialog>
);

CustomCompleteTreatmentDialog.propTypes = {
    isLoader: PropTypes.bool,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleYes: PropTypes.func,
};

export default CustomCompleteTreatmentDialog;