
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { Paper, Stack } from '@mui/material';
import { NewLeadDataGrid } from './NewLeadDataGrid';
import { useLeadTableHelper } from '../../../../hooks';
import { getMatchedCount } from '../../../../utils/allTableTab';
import { detailPanelPaper, detailPanelPaperStack, detailPanelStackStyle } from '../../../../styles/LeadTableStyle';

const GetDetailPanelContent = ({ row, ...others }) => {
    const apiRef1 = useGridApiContext();
    const props = { ...others };
    const { nearestData, nearestPastData } = useLeadTableHelper(props.typed);

    const [width, setWidth] = useState(() => {
        const dimensions = apiRef1.current.getRootDimensions();
        return dimensions.viewportInnerSize.width;
    });

    const handleViewportInnerSizeChange = useCallback(() => {
        const dimensions = apiRef1.current.getRootDimensions();
        setWidth(dimensions.viewportInnerSize.width);
    }, [apiRef1]);

    useEffect(() => apiRef1.current.subscribeEvent(
        'viewportInnerSizeChange',
        handleViewportInnerSizeChange,
    ), [apiRef1, handleViewportInnerSizeChange]);

    const treatments = row?.treatmentData?.filter((ele) => ele.id !== row?.treatment_id)?.map((ele) => (
        {
            ...row,
            ...ele,
            treatment_name: ele.treatment,
            treatmentBgColor: ele.treatment_data?.colour,
            status: ele.status,
            treatment_id: ele?.id,
            treatmentIcon: ele.treatment_data?.icon || '',
            treatmentCategory: ele.treatment_data?.treatment_item?.treatment_category?.name || '',
            leadTreatmentSpecification: ele?.leadtreatment_specification || null,
            lead_treatments__lead_contacted_counts__count:
                ele?.lead_contacted_counts?.length > 0 ? getMatchedCount(ele) : 0,
            appointments: nearestData(ele?.appointments, ele ? ele.status : '') || nearestPastData(ele?.appointments, ele ? ele.status : '') || [],
        }));

    if (treatments?.length > 0) {
        return <Stack
            sx={{ ...detailPanelStackStyle(width) }}
            direction="column"
        >
            <Paper sx={detailPanelPaper}>
                <Stack direction="column" spacing={1} sx={detailPanelPaperStack}>
                    <NewLeadDataGrid
                        tableData={treatments}
                        {...others}
                    />
                </Stack>
            </Paper>
        </Stack>;
    }
    return null;
};

export default GetDetailPanelContent;

GetDetailPanelContent.propTypes = {
    row: PropTypes.any,
    typed: PropTypes.string
};