/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
// form
import {
    Autocomplete,
    Box,
    TextField,
    Popper
} from '@mui/material';
// constants
import { Controller } from 'react-hook-form';
import { referralOutBoxStyle } from '../styles/NewLead';
import { practiceUserData } from '../utils/clinCheckUtils';
// store
import { useSelector } from '../redux/store';

const CustomPopper = (props) => (<Popper {...props} className='upload-user-popup' />);

const UserSearchDropdownSingleSelect = ({ name, label, onChange, control }) => {
    const { practiceUser } = useSelector((state) => state.practiceUser);

    return (
        <>
            <Box sx={referralOutBoxStyle}>
                <Controller
                    name={name}
                    control={control}
                    render={({ field, fieldState: { error } }) =>
                        <Autocomplete
                            name={name}
                            control={control}
                            value={field?.value}
                            onChange={onChange}
                            PopperComponent={CustomPopper}
                            getOptionLabel={(option) => option}
                            options={practiceUserData(practiceUser)?.map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`) || []}
                            renderInput={(params) => <TextField
                                label={label}
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                {...params}
                            />}
                            type={'search'}
                            renderOption={(props, option) => (
                                <li {...props} key={option} style={{ padding: '5px 9px' }}>
                                    <Box sx={{ padding: '0', textTransform: 'capitalize' }} >
                                        {option || ''}
                                    </Box>
                                </li>
                            )}
                        />
                    }
                />
            </Box>
        </>
    );
};

UserSearchDropdownSingleSelect.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    control: PropTypes.any,
    onChange: PropTypes.func,
};

export default UserSearchDropdownSingleSelect;