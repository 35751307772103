import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Iconify from '../Iconify';
import { closeIconStyle, singleScriptHeaderBoxStyle, titleDesktopStyle, backIconStyle } from '../../styles/CallScripts';

const SingleScriptHeader = ({
    backButtonClick,
    expand,
    setExpand,
}) => (
    <Box sx={singleScriptHeaderBoxStyle}>
        <Box sx={backIconStyle} onClick={backButtonClick}>
            <Iconify icon={'akar-icons:chevron-left'} width={'18px'} height={'18px'} />
        </Box>
        <Typography sx={titleDesktopStyle}>Default Enquiry Follow-up Script</Typography>
        <Box sx={closeIconStyle}>
            <Iconify onClick={() => setExpand(!expand)} icon={'eva:expand-fill'} width={'18px'} height={'18px'} sx={{ mr: '0', cursor: 'pointer' }} />
        </Box>
    </Box>
);

SingleScriptHeader.propTypes = {
    backButtonClick: PropTypes.func,
    expand: PropTypes.bool,
    setExpand: PropTypes.func,
};

export default SingleScriptHeader;
