import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomMultiSelectComponent = ({ data, methods }) => (
    <FormControl sx={{ width: '100%' }}>
        <InputLabel id='demo-multiple-name-label'>Multi select dropdown</InputLabel>
        <Controller
            render={({ field, fieldState: { error } }) => (
                <>
                    <Select
                        labelId='demo-multiple-name-label'
                        id='demo-multiple-name'
                        name={`multiCheckbox_${[data.id]}`}
                        multiple
                        sx={{ width: '100%' }}
                        input={<OutlinedInput label='Multi select dropdown' />}
                        value={field.value || []}
                        onChange={(event) => field.onChange(event.target.value)}
                    >
                        {(data?.field_type?.choices || data?.choices)?.map((options, index) => (
                            <MenuItem
                                key={index}
                                value={options}
                            >
                                {options}
                            </MenuItem>
                        ))}
                    </Select>
                    {(!!error) && (
                        <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                    )}
                </>
            )}
            control={methods.control}
            name={`multiCheckbox_${[data.id]}`}
        />
    </FormControl>
);

export default CustomMultiSelectComponent;

CustomMultiSelectComponent.propTypes = {
    data: PropTypes.object,
    methods: PropTypes.object,
};
