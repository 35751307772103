/* eslint-disable object-shorthand */
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel } from '@mui/material';
// Google map autocomplete
import Autocomplete from 'react-google-autocomplete';
// utils
import { fData } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { useAuth } from '../../../hooks';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import { changeUserDetails } from '../../../redux/slices/practiceUser';
import { dispatch, useSelector } from '../../../redux/store';
import { NewUserSchema } from '../../../validations/validations';

// ----------------------------------------------------------------------

const inputKeys = ['Backspace', 'Tab', 'ArrowRight', 'ArrowLeft', '+'];

export default function Practice({ isEdit, currentUser, practiceDetails }) {
    const [updateForm, setUpdateForm] = useState(false);
    const [updateImg, setUpdateImg] = useState(false);
    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const { user } = useAuth();
    const getUserList = () => results && results?.length > 0 && results?.filter(el => el.user && el.user.id === user?.pk);

    const defaultValues = useMemo(
        () => ({
            avatarUrl: practiceDetails && practiceDetails?.logo || '',
            name: practiceDetails && practiceDetails?.name || '',
            city: practiceDetails && practiceDetails?.address && practiceDetails?.address?.city || '',
            county: practiceDetails && practiceDetails?.address && practiceDetails?.address?.county || '',
            postcode: practiceDetails?.address && practiceDetails?.address?.postcode && practiceDetails?.address?.postcode || '',
            address_line_1: practiceDetails?.address && practiceDetails?.address?.address_line_1 && practiceDetails?.address?.address_line_1 || '',
            email: practiceDetails?.contact && practiceDetails?.contact?.email && practiceDetails?.contact?.email || '',
            website: practiceDetails?.contact && practiceDetails?.contact?.website && practiceDetails?.contact?.website || '',
            whatsapp: practiceDetails?.contact && practiceDetails?.contact?.whatsapp && practiceDetails?.contact?.whatsapp || '',
            phone: practiceDetails?.contact && practiceDetails?.contact?.phone && practiceDetails?.contact?.phone || '',
            address_line_2: practiceDetails?.address && practiceDetails?.address?.address_line_2 && practiceDetails?.address?.address_line_2 || '',
        }),
        [practiceDetails]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;


    useEffect(() => {
        if (updateForm) {
            reset(defaultValues);
            return;
        }
        setUpdateForm(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    const values = watch();

    useEffect(() => {
        if (isEdit && currentUser) {
            reset(defaultValues);
        }
        if (!isEdit) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, currentUser]);

    const onSubmit = async (data) => {
        const detail = {
            name: data.name,
            address: {
                city: data.city,
                county: data.county,
                postcode: data.postcode,
                address_line_1: data.address_line_1,
                address_line_2: data.address_line_2
            },
            contact: {
                email: data.email,
                phone: data.phone,
                website: data.website,
                whatsapp: data.whatsapp
            }
        };
        if (updateImg) {
            detail.logo = data.avatarUrl;
        }
        setUpdateImg(false);
        dispatch(changeUserDetails(detail, practiceDetails.id, 'practice'));
    };

    /**
	 * handleDrop file Callback.
	 */
    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setValue(
                        'avatarUrl',
                        reader.result
                    );
                };
                setUpdateImg(true);
            }
        },
        [setValue]
    );

    const setAddressData = (addresses, fulladdress) => {
        ClearAddressData();
        if (addresses && addresses.length > 0) {
            const address1 = fulladdress.split(',')[0];
            setValue('address_line_1', address1);
            addresses.forEach(address => {
                if (address.types.indexOf('postal_code') > -1) {
                    setValue('postcode', address.long_name);
                }
                if (address.types.indexOf('country') > -1) {
                    if (address1 === address.long_name) {
                        setValue('address_line_1', '');
                    }
                    setValue('county', address.long_name);
                }
                if (address.types.indexOf('locality') > -1 || address.types.indexOf('postal_town') > -1) {
                    if (address1.includes(address.long_name)) {
                        setValue('address_line_1', '');
                    }
                    setValue('city', address.long_name);
                }
                if (address.types.indexOf('route') > -1) {
                    if (!address1.includes(address.long_name)) {
                        setValue('address_line_2', address.long_name);
                    } else {
                        setValue('address_line_2', '');
                    }
                }
            });
        } else {
            ClearAddressData();
        }
    };

    const ClearAddressData = () => {
        setValue('postcode', '');
        setValue('address_line_2', '');
        setValue('county', '');
        setValue('city', '');
        setValue('address_line_1', '');
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} mt={4}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ py: 10, px: 3 }}>
                        {isEdit && (
                            <Label
                                color={values.status !== 'active' ? 'error' : 'success'}
                                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                            >
                                {values.status}
                            </Label>
                        )}
                        <Box sx={{ mb: 5 }}>
                            <RHFUploadAvatar
                                name="avatarUrl"
                                maxSize={3145728}
                                onDrop={handleDrop}
                                helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 2,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.secondary',
                                        }}
                                    >
										Allowed *.jpeg, *.jpg, *.png, *.gif
                                        <br /> max size of {fData(3145728)}
                                    </Typography>
                                }
                            />
                        </Box>
                        {isEdit && (
                            <FormControlLabel
                                labelPlacement="start"
                                control={
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <Switch
                                                {...field}
                                                checked={field.value !== 'active'}
                                                onChange={(event) => field.onChange(event.target.checked ? 'banned' : 'active')}
                                            />
                                        )}
                                    />
                                }
                                label={
                                    <>
                                        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
											Banned
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
											Apply disable account
                                        </Typography>
                                    </>
                                }
                                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                            />
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="h5">
							Name
                        </Typography>
                        <Box
                            sx={{
                                mt: 3,
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                            }}
                        >
                            <RHFTextField name="name" label="Practice name (Required)" required={false} disabled={!getUserList()[0]?.user?.is_superuser}/>
                        </Box>
                        <Typography variant="h5" sx={{ pt: 3 }}>
							Address
                        </Typography>
                        <Box sx={{ width: '100%', mt: 2, '.address-input': { width: '100%', boxSizing: 'border-box' } }}>
                            <Controller
                                name="address"
                                control={control}
                                sx={{ 'input.address-input': { width: '100%' } }}
                                render={({ field }) => (
                                    <Autocomplete
                                        apiKey='AIzaSyD_7FXxCWgBUUd6922n33WKqrfRDsXXxj8'
                                        onPlaceSelected={(place, inputRef) => {
                                            const addresses = place.address_components;
                                            setAddressData(addresses, inputRef.value);
                                            field.onChange(inputRef.value);
                                        }}
                                        style={{
                                            fontSize: '0.875rem'
                                        }}
                                        options={{
                                            types: ['establishment', 'geocode'],
                                            fields: ['address_components', 'formatted_address'],
                                            componentRestrictions: { country: 'uk' }
                                        }}
                                        defaultValue={field.value}
                                        className='address-input'
                                        placeholder='Search address'
                                    />
                                )}
                            />
                        </Box>
                        <Box sx={{
                            pt: 3,
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                        }}>
                            <RHFTextField name="address_line_1" label="Address line 1  (Required)" />
                            <RHFTextField name="address_line_2" label="Address line 2 (optional)" />
                            <RHFTextField name="city" label="Town or city  (Required)" />
                            <RHFTextField name="county" label="County (Required)" />
                            <RHFTextField name="postcode" label="Postcode (Required)" />
                        </Box>
                        <Typography variant="h5" sx={{ pt: 3, pb: 3 }}>
							Contact
                        </Typography>
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                            }}
                        >
                            <RHFTextField
                                name="phone"
                                label="Phone number (Required)"
                                type='string'
                                inputProps={{ maxLength: 15 }}
                                onKeyDown={(evt) => {
                                    const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
                                    const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
                                    const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
                                    if (!(isControlC || isControlV || isAllowedKey)) {
                                        evt.preventDefault();
                                    }
                                }}
                            />
                            <RHFTextField
                                name="whatsapp"
                                label="Whatsapp number"
                                type='string'
                                inputProps={{ maxLength: 15 }}
                                onKeyDown={(evt) => {
                                    const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
                                    const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
                                    const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
                                    if (!(isControlC || isControlV || isAllowedKey)) {
                                        evt.preventDefault();
                                    }
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                margin: 'auto',
                                mt: 3,
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <RHFTextField
                                name="email" label="Email (Required)" disabled={!getUserList()[0]?.user?.is_superuser}
                            />
                            <RHFTextField
                                name="website" label="Website URL (Required)" disabled={!getUserList()[0]?.user?.is_superuser}
                            />
                        </Box>
                        <Stack
                            sx={{
                                mt: 3,
                                columnGap: 2,
                                rowGap: 3,
                            }}
                            alignItems="flex-end"
                        >
                            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
								Save Changes
                            </LoadingButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </FormProvider>
    );
}

Practice.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object,
    practiceDetails: PropTypes.object,
};