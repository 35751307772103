// ----------------------------------------------------------------------

export function appBar() {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    };
}
