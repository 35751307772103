import { useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    Grid,
    Dialog,
    Container,
    Stack,
    Button,
    Box
} from '@mui/material';
// layouts
import AttendedLeadNotePopup from '../../layouts/dashboard/header/AttendedLeadNotePopup';
// style
import { dialogStyle, stackStyle, btnBoxStyle, saveBtnStyle } from '../../styles/SwitchUserPopupStyle';

const AttendedNotePopup = ({ open, onClose, type, user, handleCloseAndReload }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            open={open}
            sx={{ ...dialogStyle, ' .MuiDialog-container': { width: '474px' } }}
        >
            <Stack sx={stackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container
                        maxWidth={'xl'}
                        sx={{ padding: '0 !important' }}
                    >
                        <AttendedLeadNotePopup ref={inputRef} handleNext={onClose} type={type} user={user} handleCloseAndReload={handleCloseAndReload} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={{ ...btnBoxStyle, pt: 0 }}>
                <Button
                    color='inherit'
                    sx={{ margin: '0px 15px', fontSize: '14px', boxShadow: 'none' }}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleNext}
                    sx={saveBtnStyle}
                >
                    {'Submit'}
                </Button>
            </Box>
        </Dialog>
    );
};

export default AttendedNotePopup;

AttendedNotePopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    user: PropTypes.any,
    handleCloseAndReload: PropTypes.func,
};