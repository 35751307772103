import React, { useState } from 'react';
import PropTypes from 'prop-types';
// components
import WidgetButtons from './WidgetButtons';
import CustomWidgetCard from './CustomWidgetCard';
// redux
import { useSelector } from '../../redux/store';
// hooks/utils/constants
import { useToggle } from '../../hooks';
import groupedTreatments from '../../utils/groupedTreatments';
import { widgetArray } from '../../constants/widgetConstants';

const StartedTableReportsWidget = ({ onWidgetClick, dateRangeProps, tableTitle }) => {
    const { toggle, onOpen, onClose } = useToggle(true);
    const { treatmentsList: { results } } = useSelector((state) => state.practiceTreatment);

    const [currentWidget, setCurrentWidget] = useState('');

    const treatments = groupedTreatments(results);

    const handleOpen = () => onOpen();

    const handleClose = () => onClose();

    const handleWidgetClick = (value) => {
        if (value === '6') return;
        setCurrentWidget(value);
        onWidgetClick(treatments.filter((ele) => ele.name.toLowerCase().includes(value)));
    };

    return (
        <>
            <WidgetButtons
                toggle={toggle}
                dateRangeProps={dateRangeProps}
                handleOpen={handleOpen}
                handleClose={handleClose}
                tableTitle={tableTitle}
            />
            {toggle && tableTitle !== 'Aligner Approve Leads' && (
                <CustomWidgetCard handleWidgetClick={handleWidgetClick} widgetArray={widgetArray} currentWidget={currentWidget} />
            )}
        </>
    );
};

export default StartedTableReportsWidget;

StartedTableReportsWidget.propTypes = {
    onWidgetClick: PropTypes.func,
    dateRangeProps: PropTypes.object,
    tableTitle: PropTypes.string,
};
