import { filter } from 'lodash';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { getActivities } from 'src/redux/slices/activity';
import { getPracticeId } from 'src/redux/slices/clincheck';
import { appointmentsAttended, CompleteTreatment, FailedToAttendAppointment, followUpSlice, getLeadReminderData, StartingTreatment, startLoadingDepositPending, UpdateDepositPendingStatus, updateLeadTaskCounter } from 'src/redux/slices/lead';
import { setLoader } from 'src/redux/slices/loader';
import { getLeadTreatmentsList, setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { dispatch } from 'src/redux/store';
import { datePickerTextFieldStyle } from 'src/styles/NewLeadTableRowStyle';

export const getAttendedActiveClass = (data) => {
    if (data === 'CONSULTATION_ATTENDED') {
        return 'activeBefore';
    }
    if (data === 'TREATMENT_STARTED' || data === 'CLOSED') {
        return 'active';
    }
    return '';
};

export const BoxActive = (data) => {
    if (data === 'TREATMENT_STARTED' ||
        data === 'CONSULTATION_ATTENDED' ||
        data === 'CONSULTATION_BOOKED' ||
        data === 'CLOSED' ||
        data === 'FAILED_TO_ATTEND_CONSULTATION'
    ) {
        return true;
    }
    return false;
};

export const attendedTreatmentAPI = (row, handleStatusUpdate) => {
    const practiceId = getPracticeId();
    const data = row?.appointments;
    const now = moment().format();
    const Type = 'BOOKED';
    const filterData = data?.filter((value) => value.status === Type);
    const result = filter(filterData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() > now);
    result.sort((a, b) =>
        moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
    );
    const result2 = filter(filterData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() < now);
    result2.sort((a, b) =>
        moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
    );
    if (result?.length > 0) {
        const appointmentDetail = {
            contacted_via: result[0]?.contacted_via || '',
            datetime: result[0]?.datetime,
            method: result[0].method,
            note: result[0].note,
        };
        dispatch(appointmentsAttended(practiceId, row?.lead, result[0]?.id, appointmentDetail, handleStatusUpdate));
    }
    if (result2?.length > 0) {
        const appointmentDetail = {
            contacted_via: result2[0]?.contacted_via || '',
            datetime: result2[0]?.datetime,
            method: result2[0].method,
            note: result2[0].note,
        };
        dispatch(appointmentsAttended(practiceId, row?.lead, result2[0]?.id, appointmentDetail, handleStatusUpdate));
    }
};

export const failedAttendTreatmentAPI = (row, handleStatusUpdate) => {
    const practiceId = getPracticeId();
    const data = row?.appointments;
    const now = moment().format();
    const Type = 'BOOKED';
    const filterData = data?.filter((value) => value.status === Type);
    const result = filter(filterData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() > now);
    result.sort((a, b) =>
        moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
    );
    const result2 = filter(filterData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() < now);
    result2.sort((a, b) =>
        moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
    );
    if (result?.length > 0) {
        const appointmentDetail = {
            contacted_via: result[0]?.contacted_via || '',
            datetime: result[0]?.datetime,
            method: result[0].method,
            note: result[0].note,
        };
        dispatch(FailedToAttendAppointment(appointmentDetail, row?.lead, result[0]?.id, practiceId, handleStatusUpdate, 'failedAttendTreatment'));
    }
    if (result2?.length > 0) {
        const appointmentDetail = {
            contacted_via: result2[0]?.contacted_via || '',
            datetime: result2[0]?.datetime,
            method: result2[0].method,
            note: result2[0].note,
        };
        dispatch(FailedToAttendAppointment(appointmentDetail, row?.lead, result[0]?.id, practiceId, handleStatusUpdate, 'failedAttendTreatment'));
    }
};

export const nearestData = (row) => {
    const data = row?.appointments;
    const Type = 'BOOKED';
    const filterData = data?.filter((value) => value.status === Type);
    return filterData?.length > 0 && filterData;
};

export const completeTreatmentAPI = (data, handleUpdate) => {
    const practiceId = getPracticeId();
    const row = data;
    const details = {
        treatment: row.treatment || '',
        custom_price: row.price,
    };
    dispatch(setLoader(true));
    dispatch(CompleteTreatment(details, row.lead, row.id, practiceId, handleUpdate));
};

export const startTreatmentAPI = (data, handleUpdate) => {
    const practiceId = getPracticeId();
    const row = data;
    const details = {
        treatment: row.treatment || '',
        custom_price: row.price,
        lead: row.lead,
        id: row.id,
    };
    dispatch(setLoader(true));
    dispatch(StartingTreatment(details, practiceId, handleUpdate, 'startingTreatmentFinish'));
};

export const handleMessage = ({ message, success }) => {
    enqueueSnackbar(message, {
        variant: success ? 'success' : 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
    });
};

export const slotProps = {
    textField: {
        fullWidth: true,
        margin: 'normal',
        sx: datePickerTextFieldStyle,
        onKeyDown: (e) => e.preventDefault()
    },
};

export const handleAction = ({ actionType, setConfirmation, setBacktoAttendConfirmation, setAttendedConfirmation, setFailedAttendConfirmation }) => {
    switch (actionType) {
        case 'complete':
            setConfirmation(true);
            break;
        case 'backToAttend':
            setBacktoAttendConfirmation(true);
            break;
        case 'attended':
            setAttendedConfirmation(true);
            break;
        case 'failedAttend':
            setFailedAttendConfirmation(true);
            break;
        default:
            break;
    }
};

export const handleDepositPendingChange = (e, { data, setDepositPaidStatus, handleUpdate }) => {
    setDepositPaidStatus(e.target.checked);
    const treatment = data?.treatment;
    const id = data?.id;
    const details = {
        leadId: data.lead,
        treatmentId: id,
        payload: {
            treatment,
            is_treatment_fee_pending: !e.target.checked,
        },
    };
    dispatch(startLoadingDepositPending(true));
    dispatch(UpdateDepositPendingStatus(details, handleUpdate));
};

export const handleSuccessReload = ({leadDetail}) => {
    const practiceId = getPracticeId();
    dispatch(updateLeadTaskCounter([]));
    dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail?.id, ''));
    dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
    dispatch(getActivities(practiceId, leadDetail.id, 10, 0));
    dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
    dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
    dispatch(setCurrentTreatmentDataGlobal(null));
};