/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, } from '@mui/material';
// components
import { Label } from '../../../components';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { leadPage } from '../../../redux/slices/lead';
// style
import { statusLabelStyle, tableCellStyle } from '../../../styles/NewLeadTableRowStyle';

export default function NewLeadTableRow({ row, typed, tableTitle, tablePath }) {
    const theme = useTheme();

    const [color, setColor] = useState(false);

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { dateFormat } = useSelector((state) => state.lead);

    const navigate = useNavigate();


    const currentDate = moment().format('DD-MM-YYYY');

    useEffect(() => {
        const givenDate = formatData(row.created_at);
        if (givenDate === currentDate) {
            setColor(true);
            return;
        }
        setColor(false);
    }, [row]);

    const formatData = (data) => {
        const givenDateUTC = moment.utc(data, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ');
        const givenDateUK = givenDateUTC.utcOffset('').format('DD-MM-YYYY');
        return givenDateUK;
    };

    const showDate = (data) => (data === 'date_format' ? moment(row?.created_at, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY') : row.created_at_human);

    const handleNavigate = () => {
        if (!row.is_archive_pending && !row.is_archived) {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${row.lead_id}`);
        }
        if (row.is_archived) {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/archived/${row.lead_id}`);
        }
    };

    return (
        <TableRow sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)', pointerEvents: row.is_archive_pending ? 'none' : 'visible', bgcolor: (row.is_archive_pending || row.is_archived) && 'rgb(235 235 235)' }} hover ariaLabel='table-row'>
            <TableCell sx={{ alignItems: 'center', whiteSpace: 'nowrap', cursor: 'pointer' }} >
                <Label
                    sx={{
                        backgroundColor: color ? 'rgba(84, 214, 44, 0.16)' : 'rgba(255, 72, 66, 0.16)',
                        color: color ? '#229A16' : '#B72136',
                        cursor: 'pointer'
                    }}
                    onClick={handleNavigate}
                >
                    {showDate(dateFormat)}
                </Label>
            </TableCell>

            <TableCell align="left" sx={tableCellStyle(typed)}>
                <Typography variant="subtitle2" onClick={handleNavigate} >
                    <span style={{ textTransform: 'capitalize' }}>{row.first_name} {row.last_name}</span>
                </Typography>
            </TableCell>

            <TableCell align="left" sx={tableCellStyle(typed)} onClick={handleNavigate}>
                <Typography variant="subtitle2">
                    <span>{row.email}</span>
                </Typography>
            </TableCell>

            <TableCell align="left" sx={tableCellStyle(typed)} onClick={handleNavigate}>
                <Typography variant="subtitle2">
                    <span>{row.phone}</span>
                </Typography>
            </TableCell>

            <TableCell align="left" sx={tableCellStyle(typed)} >
                <Typography variant='body2' onClick={handleNavigate}>
                    {row?.treatment_name || ''}
                </Typography>
            </TableCell>

            <TableCell align="left" sx={tableCellStyle(typed)} onClick={handleNavigate}>
                {row.status &&
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={'success'}
                        sx={{ ...statusLabelStyle(row?.status), cursor: 'pointer' }}
                    >
                        <Typography variant='05 Others/Label' sx={{ cursor: 'pointer' }}>{row.status !== 'CLOSED' ? (row.status.charAt(0) + row.status.toLowerCase().replace(/_/g, ' ').slice(1)) : 'Completed'}</Typography>
                    </Label>
                }
            </TableCell>
        </TableRow>
    );
};

NewLeadTableRow.propTypes = {
    row: PropTypes.object,
    typed: PropTypes.string,
    tableTitle: PropTypes.any,
    tablePath: PropTypes.any,
};
