import React from 'react';
import { Box, Typography } from '@mui/material';
import { generateLabel } from '../../utils/getReferralData';
import { tableCellInnerBoxStyle } from '../../styles/NewLeadTableRowStyle';
import { treatmentCellTypoStyle } from '../../styles/LeadTableStyle';

const PaymentTypeCell = () => ({
    field: 'payment_type',
    headerName: 'Payment type',
    width: 200,
    minWidth: 200,
    renderCell: (params) =>
        <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'left' }}>
            <Typography variant='body2' sx={{ ...treatmentCellTypoStyle }}>
                {params.row.payment_type ? generateLabel(params.row.payment_type) : '--' || ''}
            </Typography>
        </Box>,
});

export default PaymentTypeCell;