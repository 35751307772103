import React from 'react';
import ApptMethodField from '../ApptMethodField';

const ApptMethodCell = ({ typed, handleSetTableData }) => ({
    field: 'app_method',
    headerName: 'Appt method',
    width: 100,
    minWidth: 80,
    sortable: false,
    renderCell: (params) => (
        <ApptMethodField
            rowData={params.row}
            tableType={typed}
            handleSetTableData={handleSetTableData}
        />
    ),
});

export default ApptMethodCell;
