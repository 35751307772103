/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import moment from 'moment';
import { m } from 'framer-motion';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Box,
    Stack,
    List,
    Badge,
    Divider,
    Typography,
    ListItemText,
    ListItemAvatar,
    ListItemButton,
    Tooltip,
    IconButton,
    Button,
    ListSubheader,
    Zoom,
    Drawer,
    Tabs,
    Tab,
    CircularProgress
} from '@mui/material';
// other libraries
import { isArray, isEmpty } from 'lodash';
// hooks
import { useAuth, useResponsive } from '../../../hooks';
// redux
import { setReminderData } from '../../../redux/slices/toDoNotifications';
import { useSelector, dispatch } from '../../../redux/store';
import {
    userNotification,
    markAllNotification,
    markSingleReadNotification,
    markAllUnNotification,
    markSingleUnreadNotification,
    userTableNotification
} from '../../../redux/slices/user';
// components
import { Iconify } from '../../../components';
import { Label } from '../../../components/labels';
import { CustomAvatar } from '../../../components/custom-avatar';
import { varHover } from '../../../components/animate';
import ReminderNotifications from '../../../components/NotificationHandler/ReminderNotifications';
// redux
import { reset } from '../../../redux/slices/mail';
import { leadPage } from '../../../redux/slices/lead';
// constant
import { TABS } from '../../../constants/notification';
// utils
import { getParsedData } from '../../../utils/storage-available';
import { setLeadSourceIcon } from '../../../utils/timelineIcon';
// style
import { notificationList, tableAvatar, notificationHead, notificationLoaderWrapper } from '../../../styles/Header';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { notification: { results, count }, count: notificationCounts, unread, tableType } = useSelector((state) => state.user);
    const userDetail = useSelector((state) => state.practiceUser);
    const { reminderData } = useSelector((state) => state.toDoNotifications);

    const navigate = useNavigate();

    const today = moment().subtract(1, 'day');

    const { user } = useAuth();

    const smUp = useResponsive('up', 'sm');

    const [notifications, setNotifications] = useState([]);
    const [openPopover, setOpenPopover] = useState(false);
    const [isAdmin, setIsAdmin] = useState('');
    const [NOTIFICATION_TAB, setNotificationsTab] = useState(TABS || []);
    const [currentTab, setCurrentTab] = useState('all');
    const [isNotificationLoading, setIsNotificationLoading] = useState(false);

    useEffect(() => {
        const savedNotifications = getParsedData('reminderData');
        if (!isEmpty(savedNotifications)) {
            dispatch(setReminderData(savedNotifications));
        }
        return () => {
            const snoozedData = getParsedData('timeout_ids') || [];
            if (!isEmpty(snoozedData)) {
                snoozedData?.forEach((element) => {
                    clearTimeout(element);
                });
            }
        };
    }, []);

    const callBack = ({ loading }) => {
        setIsNotificationLoading(loading);
    };

    const normalDate = (data) => {
        const date = moment(data, 'DD-MM-YYYY HH:mm:ss');
        return date;
    };

    const handleChangeTab = useCallback((event, newValue) => {
        setCurrentTab(newValue);
        if (newValue === 'reminder') return;
        setIsNotificationLoading(true);
        dispatch(userNotification(practiceId, isAdmin.id, 10, 0, newValue, '', '', { callBack }));
    }, [isAdmin?.id, practiceId]);

    useEffect(() => {
        if (results) {
            // eslint-disable-next-line no-nested-ternary
            const currentData = currentTab === 'unread' ? results.filter(el => el.unread) : currentTab === 'read' ? results.filter(el => !el.unread) : results;
            const afterData = currentData.filter((data) => normalDate(data.timestamp).isAfter(today));
            const beforeData = currentData.filter((data) => normalDate(data.timestamp).isBefore(today));
            const data = {
                afterData,
                beforeData
            };
            setNotifications(data);
        }
    }, [results, currentTab]);

    useEffect(() => {
        if (notificationCounts) {
            const NOTIFICATION_TAB = [];
            TABS.map((e) => Object.entries(notificationCounts).filter((f) => e.value === f[0] ? NOTIFICATION_TAB.push({ value: e.value, label: e.label, count: f[1] }) : ''));
            setNotificationsTab(NOTIFICATION_TAB);
        }
    }, [notificationCounts]);

    const getNotificationTabs = (data) => {
        const filteredNotification = data?.filter((ele) => ele?.value !== 'reminder');
        if (filteredNotification?.length > 0) {
            return [{ value: 'reminder', label: 'Reminder', count: reminderData?.length || 0 }, ...filteredNotification];
        }
        return [{ value: 'reminder', label: 'Reminder', count: reminderData?.length || 0 }, ...data];
    };

    useEffect(() => {
        if (userDetail && userDetail?.practiceUser?.results?.length) {
            setIsAdmin(userDetail.practiceUser.results.find((users) => users.user.email === user.email));
        }
    }, [userDetail]);

    useEffect(() => {
        if (isAdmin) {
            setIsNotificationLoading(true);
            dispatch(userNotification(practiceId, isAdmin.id, 1, 0, 'unread', 'countLength', '', { callBack }));
        }
    }, [isAdmin]);

    useEffect(() => {
        if (isAdmin && openPopover) {
            setIsNotificationLoading(true);
            dispatch(userNotification(practiceId, isAdmin.id, 10, 0, currentTab, 'allTab', '', { callBack }));
            dispatch(userNotification(practiceId, isAdmin.id, 1, 0, 'read', 'countLength'));
            dispatch(userNotification(practiceId, isAdmin.id, 1, 0, 'unread', 'countLength'));
        }
    }, [openPopover]);

    const handlePopover = () => {
        setOpenPopover(() => !openPopover);
    };

    const handleScroll = (e) => {
        const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop, 10) === e.target.clientHeight;
        if (bottom && count > results?.length) {
            dispatch(userNotification(practiceId, isAdmin.id, 10, results.length, currentTab));
        }
    };

    const handleMarkAllAsRead = () => {
        if (unread > 0) {
            dispatch(markAllNotification(practiceId, isAdmin.id, handleClose));
            return;
        }
        dispatch(markAllUnNotification(practiceId, isAdmin.id, handleClose));
    };

    const handleSingleMarkAsRead = (data, unreadNotification) => {
        setIsNotificationLoading(true);
        if (unreadNotification) {
            dispatch(markSingleReadNotification(practiceId, isAdmin.id, data, handleClose, 'popup'));
            return;
        }
        dispatch(markSingleUnreadNotification(practiceId, isAdmin.id, data, handleClose, 'popup'));
    };

    const handleClose = () => {
        if (notifications && isArray(notifications.beforeData) && notifications.beforeData.length < count && notifications.beforeData.length <= 10) {
            dispatch(userNotification(practiceId, isAdmin.id, 10, results.length, currentTab));
        }
        dispatch(userTableNotification(practiceId, isAdmin.id, 10, 0, 'table', (tableType === 'new')));
        dispatch(userNotification(practiceId, isAdmin.id, 1, 0, 'read', 'countLength', '', { callBack }));
        dispatch(userNotification(practiceId, isAdmin.id, 1, 0, 'all', 'countLength'));
        dispatch(userNotification(practiceId, isAdmin.id, 1, 0, 'unread', 'countLength'));
    };

    const renderHead = (
        <Stack direction="row" alignItems="center" sx={notificationHead}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                Notifications
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    You have {unread} unread messages
                </Typography>
            </Typography>

            {notificationCounts.all > 0 && <Tooltip title={unread > 0 ? 'Mark all as read' : 'Mark all as unread'}>
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                    <Iconify icon="eva:done-all-fill" color={unread > 0 ? '#43a047' : '#ff9800'} />
                </IconButton>
            </Tooltip>}

            {!smUp && (
                <IconButton onClick={handlePopover}>
                    <Iconify icon="mingcute:close-line" />
                </IconButton>
            )}

        </Stack>
    );

    const renderTabs = (
        <Tabs value={currentTab} onChange={handleChangeTab}>
            {getNotificationTabs(NOTIFICATION_TAB)?.map((tab) => (
                <Tab
                    key={tab.value}
                    iconPosition="end"
                    value={tab.value}
                    label={tab.label}
                    icon={
                        <Label
                            variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
                            color={
                                (tab.value === 'unread' && 'primary') ||
                                (tab.value === 'archived' && 'success') ||
                                'default'
                            }
                        >
                            {tab.count}
                        </Label>
                    }
                    sx={{
                        '&:not(:last-of-type)': {
                            mr: 3,
                        },
                    }}
                />
            ))}
        </Tabs>
    );

    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                color={openPopover ? 'primary' : 'default'}
                onClick={handlePopover}
                sx={{ width: 40, height: 40 }}
            >
                <Badge badgeContent={unread} color="error">
                    <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
                </Badge>
            </IconButton>
            <Drawer
                open={openPopover}
                onClose={handlePopover}
                anchor="right"
                slotProps={{
                    backdrop: { invisible: true },
                }}
                PaperProps={{
                    sx: { width: 1, maxWidth: 470 },
                }}
            >
                {renderHead}

                <Divider />

                {isNotificationLoading ? <Box sx={notificationLoaderWrapper}>
                    <CircularProgress color="success" />
                </Box> : <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pl: 2.5, pr: 1 }}
                    >
                        {renderTabs}
                        <Tooltip title={'Notification settings'}>
                            <IconButton onClick={() => navigate('/dashboard/user/notification-settings')}>
                                <Iconify icon="solar:settings-bold-duotone" />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Divider />

                    {currentTab === 'reminder' && <ReminderNotifications setOpenPopover={setOpenPopover} />}
                    {currentTab !== 'reminder' && <>
                        <Box sx={{ height: '850px', overflow: 'auto' }} onScroll={handleScroll}>
                            {notifications.afterData?.length > 0 &&
                                <List
                                    disablePadding
                                    subheader={
                                        <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                                            New
                                        </ListSubheader>
                                    }
                                >
                                    {notifications.afterData?.map((notification) => (
                                        <NotificationItem key={notification.id} notification={notification} markAsRead={handleSingleMarkAsRead} setOpenPopover={setOpenPopover} />
                                    ))}
                                </List>}

                            {notifications.beforeData?.length > 0 &&
                                <List
                                    disablePadding
                                    subheader={
                                        <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                                            Before
                                        </ListSubheader>
                                    }
                                >
                                    {notifications?.beforeData?.map((notification, index) => (
                                        <NotificationItem key={index} notification={notification} markAsRead={handleSingleMarkAsRead} setOpenPopover={setOpenPopover} />
                                    ))}
                                </List>}
                        </Box>

                        {count > 10 && <Box sx={{ p: 1 }}>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            <Button fullWidth disableRipple onClick={() => { navigate('/dashboard/user/notification-table'); setOpenPopover(false); }}>
                                View All
                            </Button>
                        </Box>}
                    </>}
                </>}
            </Drawer>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.number,
        avatar: PropTypes.node,
        type: PropTypes.string,
        title: PropTypes.string,
        isUnRead: PropTypes.bool,
        description: PropTypes.string,
        createdAt: PropTypes.instanceOf(Date),
    }),
    markAsRead: PropTypes.func,
    setOpenPopover: PropTypes.func,
};

function NotificationItem({ notification, markAsRead, setOpenPopover }) {
    const { id, icon, title, time, unread, url, verb, source } = renderContent(notification);
    const { referral } = useSelector((state) => state.schema);
    const navigate = useNavigate();

    const handleUrl = () => {
        if (url !== '') {
            dispatch(leadPage({}));
            navigate(`/dashboard/${url}`, { state: { shouldRedirectToOverview: true } });
            if (unread) {
                markAsRead(id, true);
            }
        }
    };

    const toolTipText = () => (
        <div style={{ lineHeight: '15px', whiteSpace: true, textAlign: 'center' }}>
            {verb.charAt(0).toUpperCase() + verb.slice(1).replace(/_/g, ' ')} <br />
            {source && (`Resource referral : ${setLeadSourceIcon(source, referral)?.label || 'not found'}`)}
        </div>
    );

    return (
        <>
            <ListItemButton
                onClick={() => {
                    if (verb !== 'new_email_reply_received') return;
                    const splitedLink = notification.action_link?.split('/');
                    const leadId = splitedLink?.[splitedLink?.length - 1];
                    setOpenPopover(false);
                    dispatch(reset());
                    navigate(`${notification.action_link}`, { state: { goToAllCommunicationTab: true, leadId } });
                }}
                sx={notificationList(verb === 'new_email_reply_received' ? undefined : unread, verb === 'new_email_reply_received' ? undefined : url)}
            >
                <ListItemAvatar>
                    <Tooltip title={toolTipText()} placement="bottom" TransitionProps={{ timeout: 300 }} TransitionComponent={Zoom} >
                        {!source ? <CustomAvatar
                            src={icon}
                            sx={tableAvatar(url)}
                            onClick={() => handleUrl(url)}
                        /> :
                            <Iconify icon={setLeadSourceIcon(source, referral)?.icon} sx={{ ...tableAvatar(url), color: '#1890FF', p: '3px', borderRadius: '50px' }} width={40} height={40} onClick={() => handleUrl(url)} />}
                    </Tooltip>
                </ListItemAvatar>

                <ListItemText
                    disableTypography
                    primary={title}
                    onClick={() => handleUrl(url)}
                    secondary={
                        <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
                            <Iconify icon="solar:clock-circle-bold" width={16} sx={{ mr: 0.5 }} />
                            <Typography variant="caption">{time}</Typography>
                        </Stack>
                    }
                />

                {unread ?
                    <Tooltip title={'Mark as read'}>
                        <IconButton onClick={() => markAsRead(id, unread)} sx={{ pointerEvents: 'auto !important' }}>
                            <Iconify icon={'carbon:dot-mark'} sx={{ color: 'warning.main' }} width={20} height={20} />
                        </IconButton>
                    </Tooltip> :
                    <Tooltip title={'Mark as unread'}>
                        <IconButton onClick={() => markAsRead(id, unread)} sx={{ pointerEvents: 'auto !important' }}>
                            <Iconify icon={'carbon:dot-mark'} sx={{ color: '#43a047' }} width={20} height={20} />
                        </IconButton>
                    </Tooltip>
                }
            </ListItemButton>
            <Divider sx={{ borderStyle: 'dashed' }} />
        </>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const title = (
        <Typography variant="subtitle2">
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                {notification.description}
            </Typography>
        </Typography>
    );

    if (notification.verb === 'reminder_overdue') {
        return {
            icon: '/assets/icons/notification/reminder-overdue.png',
            color: '#9A2EFE',
            title,
            time: notification.timestamp_human,
            unread: notification.unread,
            id: notification.id,
            url: notification.action_link,
            verb: notification.verb
        };
    }
    if (notification.verb === 'reminder_assigned_to_user') {
        return {
            icon: '/assets/icons/notification/assign_task.png',
            color: 'green',
            title,
            time: notification.timestamp_human,
            unread: notification.unread,
            id: notification.id,
            url: notification.action_link,
            verb: notification.verb
        };
    }
    if (notification.verb === 'reminder_due_today') {
        return {
            icon: '/assets/icons/notification/task_due.png',
            title,
            time: notification.timestamp_human,
            unread: notification.unread,
            id: notification.id,
            url: notification.action_link,
            verb: notification.verb
        };
    }
    if (notification.verb === 'lead_assigned_to_user') {
        return {
            icon: '/assets/icons/notification/assign_lead.png',
            title,
            time: notification.timestamp_human,
            unread: notification.unread,
            id: notification.id,
            url: notification.action_link,
            verb: notification.verb
        };
    }
    if (notification.verb === 'new_lead') {
        return {
            icon: '/assets/icons/notification/user.png',
            title,
            time: notification.timestamp_human,
            unread: notification.unread,
            id: notification.id,
            url: notification.action_link,
            verb: notification.verb,
            source: notification?.action_object?.lead_source?.referral
        };
    }
    if (notification?.verb === 'phone_number_clicked') {
        return {
            icon: '/assets/icons/notification/call-in.png',
            title,
            time: notification.timestamp_human,
            unread: notification.unread,
            id: notification.id,
            url: `analytics/contact-summary`,
            verb: notification.verb,
        };
    }
    if (notification?.verb === 'facebook_post_comment_created') {
        return {
            icon: '/assets/icons/notification/facebook.png',
            title,
            time: notification.timestamp_human,
            unread: notification.unread,
            id: notification.id,
            url: notification.action_link,
            verb: notification.verb,
        };
    }
    return {
        icon: '',
        title,
        time: notification.timestamp_human,
        unread: notification.unread,
        id: notification.id,
        url: notification.action_link,
        verb: notification.verb,
        source: notification?.action_object?.lead_source?.referral
    };
}