import { useEffect, useRef, useState } from 'react';
import _, { isEmpty } from 'lodash';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Box, MenuItem, Typography, Select, Button, List, ListItem, IconButton, FormControlLabel, Switch } from '@mui/material';
// components
import { saveNewAudience, setAudienceConditionListing, setAudienceIncludeArchive, updateSelectedAudience } from 'src/redux/slices/audienceManager';
import { getWord } from 'src/utils/getWord';
import { setBlankValueForKeyContainingWord } from 'src/utils/CampaignUtils';
import Iconify from './Iconify';
import { dispatch, useSelector } from '../redux/store';
import { getPracticeTreatmentList } from '../redux/slices/practiceTreatment';
import { FormProvider } from './hook-form';
import AudienceAutocompleteTags from './AudienceManager/audienceFilters/AudienceAutocompleteTags';
import AudienceStatusDropDown from './AudienceManager/audienceFilters/AudienceStatusDropDown';
import AudienceMultiTreatmentDropdown from './AudienceManager/audienceFilters/AudienceMultiTreatmentDropdown';
import AudienceLeadDateRangePicker from './AudienceManager/audienceFilters/AudienceLeadDateRangePicker';
import AudienceLeadReferelDropdown from './AudienceManager/audienceFilters/AudienceLeadReferelDropdown';
import AudienceLeadCategoryDropdown from './AudienceManager/audienceFilters/AudienceLeadCategoryDropdown';
import AudienceLeadSourceDropdown from './AudienceManager/audienceFilters/AudienceLeadSourceDropdown';
import AudiencePractitionerDropdown from './AudienceManager/audienceFilters/AudiencePractitionerDropdown';
import CustomRHFSelect from './CampaignManager/CustomRHFSelect';
import CustomConditionSelect from './CampaignManager/CustomConditionSelect';

// ----------------------------------------------------------------------

const socialLinks = [
    {
        name: 'TAGS',
        value: 'contact is tagged',
        tagSelect: '',
        id: 1,
        component: <AudienceAutocompleteTags />,
    },
];

export default function ReportsAudienceForm() {
    const [conditionListing, setConditionListing] = useState(socialLinks);
    const shouldCall = useRef(true);
    const [any, setAny] = useState('All');
    const [manual, setManual] = useState(false);
    const [orAndValue, setOrAndValue] = useState('AND');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { newAudience, selectedAudienceData, isAudienceIncludeArchive, audienceConditionListing } = useSelector((state) => state.audienceManager);

    useEffect(() => {
        if (audienceConditionListing?.length > 0) {
            setConditionListing(audienceConditionListing);
            return;
        }
        if (socialLinks.length) {
            const data = [];
            socialLinks.forEach((el) => {
                data.push({
                    name: el.name,
                    value: el.value,
                    id: el.id,
                    component: el.component,
                });
            });
            setConditionListing(data);
            return;
        }
        setConditionListing([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: 30,
                offset: 0,
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    }, [practiceId]);

    useEffect(() => {
        if (Object.values(selectedAudienceData).length === 0 && !manual) {
            const data = [];
            if (newAudience?.tags !== undefined) {
                data.push({
                    name: 'TAGS',
                    value: 'contact is tagged',
                    component: <AudienceAutocompleteTags />,
                });
            }
            if (newAudience?.status !== undefined) {
                data.push({
                    name: 'STATUS',
                    value: 'contact status is',
                    component: <AudienceStatusDropDown />,
                });
            }
            if (newAudience?.practitioner !== undefined) {
                data.push({
                    name: 'PRACTITIONER',
                    value: 'contact practitioner is',
                    component: <AudiencePractitionerDropdown />,
                });
            }
            if (newAudience?.treatment !== undefined) {
                data.push({
                    name: 'TREATMENT',
                    value: 'contact treatment is',
                    component: (
                        <AudienceMultiTreatmentDropdown
                            name={'treatment'}
                            value={[]}
                            onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)}
                            type={'compaign'}
                        />
                    ),
                });
            }
            if (data.length > 0) {
                setConditionListing([...data]);
                dispatch(setAudienceConditionListing([...data]));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newAudience, manual]);

    useEffect(() => {
        if (Object.values(selectedAudienceData).length > 0 && shouldCall.current) {
            shouldCall.current = false;
            const data = [];
            if (selectedAudienceData?.recepient?.filters?.lead_tags__tag__tag__name__in?.length > 0) {
                data.push({
                    name: 'TAGS',
                    value: 'contact is tagged',
                    component: <AudienceAutocompleteTags />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_treatments__status__in?.length > 0) {
                data.push({
                    name: 'STATUS',
                    value: 'contact status is',
                    component: <AudienceStatusDropDown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in?.length > 0) {
                data.push({
                    name: 'TREATMENT',
                    value: 'contact treatment is',
                    component: (
                        <AudienceMultiTreatmentDropdown
                            name={'treatment'}
                            value={[]}
                            onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)}
                            type={'compaign'}
                        />
                    ),
                });
            }
            if (selectedAudienceData?.recepient?.filters?.created_at__range?.length > 0) {
                data.push({
                    name: 'DATE_RANGE',
                    value: 'contact date range is',
                    component: <AudienceLeadDateRangePicker />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in) {
                data.push({
                    name: 'CATEGORY',
                    value: 'contact category is',
                    component: <AudienceLeadCategoryDropdown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_source__referral__in) {
                data.push({
                    name: 'SOURCE_REFERRAL',
                    value: 'contact source referral is',
                    component: <AudienceLeadReferelDropdown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_source__type__in) {
                data.push({
                    name: 'SOURCE_TYPE',
                    value: 'contact source type is',
                    component: <AudienceLeadSourceDropdown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.assignees__user__id__in?.length > 0) {
                data.push({
                    name: 'PRACTITIONER',
                    value: 'contact practitioner is',
                    component: <AudiencePractitionerDropdown />,
                });
            }
            if (data.length > 0) {
                setConditionListing([...data]);
                dispatch(setAudienceConditionListing([...data]));
                setManual(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAudienceData]);

    const methods = useForm();

    const CONDITIONS = [
        {
            name: 'Tags',
            value: 'TAGS',
            title: 'contact is tagged',
            component: <AudienceAutocompleteTags />,
        },
        {
            name: 'Status',
            value: 'STATUS',
            title: 'contact status is',
            component: <AudienceStatusDropDown />,
        },
        {
            name: 'Treatment',
            value: 'TREATMENT',
            title: 'contact treatment is',
            component: (
                <AudienceMultiTreatmentDropdown
                    name={'treatment'}
                    value={[]}
                    onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)}
                    type={'compaign'}
                />
            ),
        },
        {
            name: 'Category',
            value: 'CATEGORY',
            title: 'contact category is',
            component: <AudienceLeadCategoryDropdown />,
        },
        {
            name: 'Source Referral',
            value: 'SOURCE_REFERRAL',
            title: 'contact source referral is',
            component: <AudienceLeadReferelDropdown />,
        },
        {
            name: 'Date range',
            value: 'DATE_RANGE',
            title: 'contact date range is',
            component: <AudienceLeadDateRangePicker />,
        },
        {
            name: 'Source Type',
            value: 'SOURCE_TYPE',
            title: 'contact source type is',
            component: <AudienceLeadSourceDropdown />
        },
        {
            name: 'Practitioner',
            value: 'PRACTITIONER',
            title: 'contact practitioner is',
            component: <AudiencePractitionerDropdown />
        },
    ];

    const addNewLink = () => {
        const difference = _.differenceWith(
            CONDITIONS,
            conditionListing,
            (a, b) => a.value.toLowerCase() === b.name.toLowerCase()
        );
        setConditionListing([
            ...conditionListing,
            {
                name: difference[0]?.value,
                value: difference[0]?.title,
                component: difference[0]?.component,
            },
        ]);
        dispatch(
            setAudienceConditionListing([
                ...conditionListing,
                {
                    name: difference[0]?.value,
                    value: difference[0]?.title,
                    component: difference[0]?.component,
                },
            ])
        );
    };

    const hendleEvent = (event, index) => {
        let selected = conditionListing[index];
        const data = setBlankValueForKeyContainingWord(
            !isEmpty(selectedAudienceData) ? selectedAudienceData : newAudience,
            selected.name.toLowerCase()
        );
        setManual(true);
        selected = { ...selected, name: event.target.value };
        if (selected?.name === 'TAGS') {
            selected = { ...selected, value: 'contact is tagged', component: <AudienceAutocompleteTags /> };
        }
        if (selected?.name === 'STATUS') {
            selected = { ...selected, value: 'contact status is', component: <AudienceStatusDropDown /> };
        }
        if (selected?.name === 'TREATMENT') {
            selected = {
                ...selected,
                value: 'contact treatment is',
                component: (
                    <AudienceMultiTreatmentDropdown
                        name={'treatment'}
                        value={[]}
                        onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)}
                        type={'compaign'}
                    />
                ),
            };
        }
        if (selected?.name === 'CATEGORY') {
            selected = { ...selected, value: 'contact category is', component: <AudienceLeadCategoryDropdown /> };
        }
        if (selected?.name === 'SOURCE_REFERRAL') {
            selected = { ...selected, value: 'contact source referral is', component: <AudienceLeadReferelDropdown /> };
        }
        if (selected?.name === 'DATE_RANGE') {
            selected = { ...selected, value: 'contact date range is', component: <AudienceLeadDateRangePicker /> };
        }
        if (selected?.name === 'SOURCE_TYPE') {
            selected = { ...selected, value: 'contact source type is', component: <AudienceLeadSourceDropdown /> };
        }
        if (selected?.name === 'PRACTITIONER') {
            selected = { ...selected, value: 'contact practitioner is', component: <AudiencePractitionerDropdown /> };
        }
        const temp = [...conditionListing];
        temp[index] = selected;
        setConditionListing([...temp]);
        dispatch(setAudienceConditionListing([...temp]));
        if (Object.values(selectedAudienceData).length > 0) {
            dispatch(updateSelectedAudience(data));
            return;
        }
        dispatch(saveNewAudience({ ...data }));
    };

    const deleteRow = (index) => {
        const selected = conditionListing[index];
        const results = setBlankValueForKeyContainingWord(
            !isEmpty(selectedAudienceData) ? selectedAudienceData : newAudience,
            getWord(selected.name)
        );
        const data = conditionListing.filter((el, ind) => ind !== index);
        setConditionListing([...data]);
        dispatch(setAudienceConditionListing([...data]));
        if (Object.values(selectedAudienceData).length > 0) {
            dispatch(updateSelectedAudience(results));
            return;
        }
        dispatch(saveNewAudience({ ...results }));
    };

    const handleChange = (event) => {
        setAny(event.target.value);
        if (event.target.value === 'All') {
            setOrAndValue('AND');
        }
        if (event.target.value === 'Any') {
            setOrAndValue('OR');
        }
    };

    const handleTreatment = (value, selectedVal) => {
        if (value) {
            const data = { lead_treatments__treatment__treatment_item__name__in: value?.map((el) => el.name) };
            if (selectedVal && Object.values(selectedVal).length > 0) {
                const updatedCampaign = { ...selectedVal, recepient: { ...selectedVal.recepient, filters: { ...selectedVal.recepient?.filters, ...data } } };
                dispatch(updateSelectedAudience(updatedCampaign));
                return;
            }
            dispatch(saveNewAudience({ lead_treatments__treatment__treatment_item__name__in: data, treatmentName: value }));
        }
    };

    const handleArchiveFilter = (e) => {
        dispatch(setAudienceIncludeArchive(e.target.checked));
    };

    const getArchiveChecked = () => isAudienceIncludeArchive;

    return (
        <Box>
            <FormProvider methods={methods}>
                <Box sx={{ py: '24px' }}>
                    <Typography sx={{ fontSize: '16px', display: 'none' }}>
                        Contacts match
                        <Select
                            name="contactMatchCondition"
                            value={any}
                            sx={{ mx: '12px', '.MuiSelect-select': { p: '8.5px 14px' } }}
                            onChange={handleChange}
                        >
                            <MenuItem value="All">all</MenuItem>
                        </Select>
                        of the following conditions:
                    </Typography>
                    <FormControlLabel control={
                        <Switch checked={getArchiveChecked()}
                            onChange={handleArchiveFilter}
                            inputProps={{ 'aria-label': 'controlled' }} />
                    } labelPlacement="start" label="Include Archived Data" />
                </Box>
                <Box sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 460px)' }}>
                    <Box>
                        <List sx={{ p: '0', ' .items:first-of-type p': { display: 'none' } }}>
                            {conditionListing.map((link, index) => (
                                <Box key={index} sx={{ borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)' }} className="items">
                                    {conditionListing?.length > 1 && (
                                        <Typography sx={{ fontSize: '16px', fontWeight: '700', p: '24px 0 0' }}>{orAndValue}</Typography>
                                    )}
                                    <ListItem sx={{ p: '24px 0' }}>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            title="Delete"
                                            onClick={() => deleteRow(index)}
                                            sx={{ p: '0', mr: '0' }}
                                        >
                                            <Box>
                                                <Iconify icon={'eva:minus-fill'} sx={{ border: '1px solid #919EAB', borderRadius: '8px' }} />
                                            </Box>
                                        </IconButton>
                                        <CustomConditionSelect
                                            value={link.name}
                                            name='preferredContactMethod'
                                            label=''
                                            list={CONDITIONS}
                                            conditionListing={conditionListing}
                                            handleChange={hendleEvent}
                                            index={index}
                                        />
                                        <CustomRHFSelect value={link.value}/>
                                        <Box sx={{ minWidth: '41%' }}>{link?.component}</Box>
                                    </ListItem>
                                </Box>
                            ))}
                        </List>
                    </Box>
                </Box>
            </FormProvider>
            {conditionListing?.length <= 7 && (
                <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
                    <Button
                        variant="outlined"
                        onClick={addNewLink}
                        sx={{ fontSize: '14px', minWidth: '155px', minHeight: '36px' }}
                    >
                        <Iconify sx={{ marginRight: '8px' }} icon={'eva:plus-fill'} width={20} height={20} /> Add Condition
                    </Button>
                </Box>
            )}
        </Box>
    );
}
