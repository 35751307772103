import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import {
    Dialog,
    Slide,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from '@mui/material';
// Form
// Redux
// Style
import { isArray } from 'lodash';
import { validationResultPopupStyle } from '../../styles/AudienceManager';

// ------------------------------------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ------------------------------------------------------------------------------------------------

const ValidationResultPopup = ({ open, onClose, results }) => (
    <Dialog TransitionComponent={Transition} open={open} sx={validationResultPopupStyle}>
        <DialogTitle
            id="alert-dialog-title"
            sx={{ borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)!important', pb: '24px', textAlign: 'center' }}
        >
            Validation Result
        </DialogTitle>
        <DialogContent sx={{ p: '0 !important' }}>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Row No</TableCell>
                            <TableCell>Error Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isArray(results) &&
                            results.length > 0 &&
                            results.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{row.idx}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.lead_email ||
                                            row?.lead_first_name ||
                                            row?.lead_last_name ||
                                            row?.lead_phone ||
                                            row?.lead_salutation ||
                                            row?.Exception ||
                                            '-'}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions sx={{ borderTop: 'solid 1px rgba(145, 158, 171, 0.24)!important' }}>
            <Button onClick={onClose} autoFocus variant="contained" sx={{ px: 3 }}>
                close
            </Button>
        </DialogActions>
    </Dialog>
);

export default ValidationResultPopup;

ValidationResultPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    results: PropTypes.any,
};
