import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
// use @mui
import {
    Stack,
    Dialog,
    Typography,
    Box,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// other libraries
import { isEmpty } from 'lodash';
// use form
import { useForm } from 'react-hook-form';
// use components
import CustomColorPicker from 'src/components/CustomColorPicker';
import { useToggle } from 'src/hooks';
import { COLOR_CODES } from 'src/constants/newLead';
import { Iconify } from 'src/components';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {
    ErrorTextStyle,
    AmountInputStyle
} from '../../../styles/PaymentRefund';

export default function PracticeEditDialog({
    row,
    open,
    onClose,
    treatmentName,
    treatmentCategory,
    treatmentStatus = false,
    treatmentPrice,
    treatmentData,
    saveTreatMentData
}) {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [amountError, setAmountError] = useState(false);
    const [decimalSeparator] = useState('.');
    const [thousandSeparator] = useState(',');
    const [inputType] = useState('text');
    const [anchorEl, setAnchorEl] = useState(null);

    const { toggle: openColor, onOpen: onColorOpen, onClose: onColorClose } = useToggle();

    const defaultValues = useMemo(
        () => ({
            price: row?.price || '',
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [row]
    );

    const [amount, setAmount] = useState(treatmentPrice || 0.00);

    const [inputValue, setInputValue] = useState({
        price: amount,
        checked: treatmentStatus,
        color: treatmentData?.colour,
        isStarred: treatmentData?.is_starred || false,
        icon: treatmentData?.icon || '',
    });

    const handleChangeColor = (color) => {
        setInputValue((state) => ({ ...state, color }));
        onColorClose();
    };

    const handleChangeIcon = (e) => {
        setInputValue((state) => ({ ...state, icon: e.target.value }));
    };

    const handleOpenColorPicker = (event) => {
        onColorOpen();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseColorPicker = () => {
        onColorClose();
        setAnchorEl(null);
    };

    const handleChange = (value) => {
        setAmount(value);
        setInputValue({
            ...inputValue,
            price: value
        });
        if (value > 0) {
            setAmountError(false);
            return;
        }
        setAmountError(true);
    };

    const handleFavoriteChange = (e) => {
        setInputValue({
            ...inputValue,
            isStarred: e.target.checked
        });
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        handleSubmit
    } = methods;

    const onSubmit = async () => {
        if ((amount === 0 || amount === '0.00' || amount === undefined)) {
            setAmountError(true);
            return;
        }
        saveTreatMentData(inputValue);
        onClose();
    };

    const ICONS = ['mdi:tooth-outline', 'mdi:tooth'];

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
            <Stack spacing={2} sx={{ p: 3 }}>
                <Typography variant="h6">
                    Edit Treatment
                </Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3} alignItems="flex-start">
                        <RHFTextField
                            value={treatmentName}
                            name="Treatment name"
                            label="Treatment name"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Box sx={{ width: '100%' }}>
                            <CurrencyInput
                                name="treatmentValue"
                                value={amount}
                                decimalSeparator={decimalSeparator}
                                thousandSeparator={thousandSeparator}
                                onValueChange={(value, name) => handleChange(value, name)}
                                className="currency-input"
                                decimalsLimit={2}
                                decimalScale={2}
                                inputType={inputType}
                                prefix={'£ '}
                                style={AmountInputStyle(amountError, 'appointment', isLight)}
                                step={1}
                            />
                            {amountError && <Typography variant='p' sx={ErrorTextStyle}>This field is required</Typography>}
                        </Box>
                        <RHFTextField
                            value={treatmentCategory}
                            name="Category"
                            label="Category"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Box sx={{ width: '100%' }}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel
                                    size='normal'
                                    name="icon"
                                >
                                    Select icon
                                </InputLabel>
                                <Select
                                    name="icon"
                                    value={inputValue?.icon}
                                    label="Select icon"
                                    onChange={handleChangeIcon}
                                >
                                    {ICONS.map((icon) => (
                                        <MenuItem
                                            key={icon}
                                            value={icon}
                                        >
                                            <Iconify
                                                icon={icon}
                                                width={24}
                                                height={24}
                                            />
                                        </MenuItem>
                                    ))}
                                    {!isEmpty(inputValue?.icon) && <MenuItem
                                        value={''}
                                    >
                                        Remove Icon
                                    </MenuItem>}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Typography variant='p' sx={{ color: '#212B36', fontSize: '16px' }}>Select color: </Typography>
                            <CustomColorPicker open={openColor}
                                openColorPicker={handleOpenColorPicker}
                                handleClose={handleCloseColorPicker}
                                colorList={COLOR_CODES}
                                value={inputValue?.color}
                                anchorEl={anchorEl}
                                onChange={handleChangeColor}
                                style={{ marginLeft: '15px' }}
                            />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <FormControlLabel control={<Checkbox checked={inputValue.isStarred}
                                onChange={handleFavoriteChange}
                                inputProps={{ 'aria-label': 'controlled' }} />} label="Is Starred" />
                        </Box>
                        <Stack width="160px" marginTop={0} justifyContent="space-between" flexDirection="row" alignItems="flex-start" alignSelf="flex-end">
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={onClose}
                            >
                                cancel
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                            >
                                save
                            </Button>
                        </Stack>
                    </Stack>
                </FormProvider>
            </Stack>
        </Dialog>
    );
}

PracticeEditDialog.propTypes = {
    row: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    treatmentName: PropTypes.string,
    treatmentCategory: PropTypes.string,
    treatmentStatus: PropTypes.bool,
    treatmentPrice: PropTypes.number,
    treatmentData: PropTypes.any,
    saveTreatMentData: PropTypes.func
};