import React from 'react';
import { Box, Typography } from '@mui/material';
import { treatmentCellTypoStyle } from 'src/styles/LeadTableStyle';
import { tableCellInnerBoxStyle } from '../../styles/NewLeadTableRowStyle';

const PaymentAmountCell = () => ({
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    width: 200,
    minWidth: 200,
    renderCell: (params) => <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'flex-end' }}>
        <Typography variant='body2' sx={{ ...treatmentCellTypoStyle }}>
            {`£${params?.value}` || ''}{' '}
        </Typography>
    </Box>,
});

export default PaymentAmountCell;