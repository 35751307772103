import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
    posts: [],
    status: 'idle',
    error: null,
};

export const slice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        fetchPostsStart(state) {
            state.status = 'loading';
        },
        fetchPostsSuccess(state, action) {
            state.status = 'succeeded';
            state.posts = action.payload;
        },
        fetchPostsFailure(state, action) {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export default slice.reducer;

export const { fetchPostsStart, fetchPostsSuccess, fetchPostsFailure } = slice.actions;

export function fetchPosts() {
    return async () => {
        dispatch(fetchPostsStart());
        try {
            const response = await axios.get('https://help.dental-sem.co.uk/wp-json/wp/v2/posts/?posts_per_page=-1');
            dispatch(fetchPostsSuccess(response.data));
        } catch (error) {
            dispatch(fetchPostsFailure(error.toString()));
        }
    };
}
