import { findIndex, forEach } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
//
import { callApi } from '../../utils/apiCall';
import { dispatch, store } from '../store';

const initialState = {
    user: null,
    error: null,
    message: null,
    unread: 0,
    facebookNotification: 0,
    count:{},
    tableType: 'new',
    notification: {
        results: [],
        count: 0,
    },
    tableNotification: {
        results: [],
    },
    isNotificationLoading: false,
    emailNotifications: []
};

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        userdata(state, action) {
            state.user = action.payload;
        },
        login(state, action) {
            state.user = action.payload;
        },
        logout(state) {
            state.user = null;
        },
        updateUser(state, action) {
            state.user = action.payload;
        },
        passwordUpdate(state, action) {
            state.user = action.payload;
        },
        notificationData(state, action) {
            state.notification = action.payload;
        },
        notificationTableData(state, action) {
            state.tableNotification = action.payload;
        },
        notificationUnread(state, action) {
            state.unread = action.payload;
        },
        facebookNotificationData(state, action) {
            state.facebookNotification = action.payload;
        },
        notificationCount(state, action) {
            state.count = action.payload;
        },
        notificationAddMoreData(state, action) {
            state.notification.results = [
                ...state.notification.results,
                ...action.payload.results
            ];
            state.notification.count = action.payload.count;
        },
        updateTableType(state,action) {
            state.tableType = action.payload;
        },
        updateSingleNotification(state,action) {
            state.notification.results[action.payload.id].unread = action.payload.unread;
        },
        updateAllNotification(state, action) {
            forEach(state.notification.results, obj => { obj.unread = action.payload; });
        },
        // HAS ERROR
        updateError(state, action) {
            state.error = action.payload;
        },
        updateMessage(state, action) {
            state.message = action.payload;
        },
        setIsNotificationLoading(state, action) {
            state.isNotificationLoading = action.payload;
        },
    },
});

export const { login, logout, updateError, notificationData, notificationUnread, notificationTableData, updateTableType, reset, notificationCount, facebookNotificationData, setIsNotificationLoading } = slice.actions;

export default slice.reducer;

export function updateUser(profileData, updateUser, callback) {
    return async () => {
        try { 
            const response = await callApi('/api/v1/auth/user/', 'put', profileData);
            if(response.status === 200) {
                updateUser(response.data);
                callback({success : true , message : 'User updated successfully'});
            }
            else{
                let message = '';
                Object.entries(response.data).forEach(([key, value], index) => {
                    message = `${message + (index === 0 ? '' : ', ')  }${key}: ${value}`;
                });
                callback({
                    message,
                    success: false
                });
            }
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')  }${key}: ${value}`;
            });
            callback({
                message,
                success: false
            });
        }
    };
}

export function changePassword(password, callback) {
    return async () => {
        try {
            const response = await callApi('/api/v1/auth/password/change/', 'post', password);
            if (response.status === 200) {
                dispatch(slice.actions.passwordUpdate(response.data));
                dispatch(slice.actions.updateError({
                    success: true,
                    message: 'Your password has successfully updated!'
                }));
                callback({success: true, message: 'Your password has successfully updated!'});
            } else {
                callback({success: false, message: response.data });
                // dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }; 
}

export function userNotification (practiceId, id, limit, offset, type, counter, messageType, otherParams) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${id}/notifications/?${counter === 'countLength' ? 'fields={}' : ''}&limit=${limit}&offset=${offset}${type === 'unread' ? '&unread=true' : ''}${type === 'read' ? '&unread=false' : ''}${messageType === 'facebook' ? '&verb=facebook_post_comment_created' : ''}`);
            if (response.status === 200) {
                const { count } = store.getState().user;
                if(otherParams){
                    otherParams.callBack({ loading: false });
                }
                if (messageType === 'facebook') {
                    dispatch(facebookNotificationData(response.data.count));
                    return;
                }
                if(type === 'unread' && counter === 'countLength' ){
                    dispatch(notificationUnread(response.data.count));
                    dispatch(notificationCount({...count, [type]: response.data.count }));
                    return;
                }
                if (counter === 'countLength') {
                    dispatch(notificationCount({...count, [type]: response.data.count }));
                    return;
                }
                if (counter === 'allTab') {
                    dispatch(notificationCount({...count, [type]: response.data.count }));
                }
                if (offset === 0) {
                    dispatch(notificationData(response.data));
                    return;
                }
                dispatch(slice.actions.notificationAddMoreData(response.data));
            }
        }
        catch (error) {
            if(otherParams){
                otherParams.callBack({ loading: false });
            }
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function userTableNotification (practiceId, id, limit, offset, type, unread) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${id}/notifications/?fields={data,deleted,description,id,recipient,timestamp,timestamp_human,unread,verb,action_object,action_link}&limit=${limit}&offset=${offset}${type === 'table' ? `&unread=${unread}` : ''}`);
            if (response.status === 200) {
                dispatch(notificationTableData(response.data));
            } else {
                dispatch(slice.actions.updateError({ success: 'false', message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function markAllNotification(practiceId, id, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${id}/notifications/mark_all_as_read/`, 'post', {});
            if (response.status === 200) {
                dispatch(slice.actions.updateError({ success: 'true', message: 'Notification marked as read successfully' }));
                dispatch(slice.actions.updateAllNotification(false));
                handleClose();
            } else {
                dispatch(slice.actions.updateError({ success: 'false', message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function markAllUnNotification(practiceId, id, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${id}/notifications/mark_all_as_unread/`, 'post', {});
            if (response.status === 200) {
                dispatch(slice.actions.updateError({ success: 'true', message: 'Notifications marked as unread successfully' }));
                dispatch(slice.actions.updateAllNotification(true));
                handleClose();
            } else {
                dispatch(slice.actions.updateError({ success: 'false', message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function markSingleReadNotification(practiceId, userId,  id, handleClose, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${userId}/notifications/${id}/mark_as_read/`, 'post', {});
            if (response.status === 200) {
                const { notification } = store.getState().user;
                if(type === 'popup'){
                    const index = findIndex(notification.results, ['id', id]);
                    const data = {
                        id: index,
                        unread: false
                    };
                    dispatch(slice.actions.updateSingleNotification(data));
                }
                dispatch(slice.actions.updateError({ success: 'true', message: 'Notification marked as read successfully' }));
                handleClose();
            } else {
                dispatch(slice.actions.updateError({ success: 'false', message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(slice.actions.updateError({ success: 'false', message: error?.message || 'Something went wrong' }));
        }
    };
}

export function markSingleUnreadNotification(practiceId, userId,  id, handleClose, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${userId}/notifications/${id}/mark_as_unread/`, 'post', {});
            if (response.status === 200) {
                dispatch(slice.actions.updateError({ success: 'true', message: 'Notification marked as unread successfully' }));
                if(type === 'popup'){
                    const { notification } = store.getState().user;
                    const index = findIndex(notification.results, ['id', id]);
                    const data = {
                        id: index,
                        unread: true
                    };
                    dispatch(slice.actions.updateSingleNotification(data));
                }
                handleClose();
            } else {
                dispatch(slice.actions.updateError({ success: 'false', message: response?.data?.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(slice.actions.updateError({ success: 'false', message: error?.message || 'Something went wrong' }));
        }
    };
}
