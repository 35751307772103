/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useDebounce } from 'use-lodash-debounce';
import { Chip, Box, Paper, Divider } from '@mui/material';
// components
import { RHFAutocomplete, RHFCheckbox } from '../hook-form';
// redux
import { dispatch, useSelector } from '../../redux/store';
import { getPracticeTreatmentList, startLoading } from '../../redux/slices/practiceTreatment';
// utils
import groupedTreatments from '../../utils/groupedTreatments';
// style
import { treatmentChipTextStyle, treatmentChipBoxStyle } from '../../styles/NewLead';

const MultiTreatmentDropdown = ({ type, name, value, onChange, ...props }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { treatmentsList: { results, count }, isLoading } = useSelector((state) => state.practiceTreatment);
    const { treatmentStarted: { categoryName } } = useSelector((state) => state.lead);
    const { newCampaign, selectedCampaign } = useSelector((state) => state.campaign);
    const [treatmentOptions, setTreatmentOptions] = useState([]);
    const [selectedTreatment, setSelectedTreatment] = useState([]);
    const [position, setPosition] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [shouldCall, setShouldCall] = useState(false);
    const inputValueDebounce = useDebounce(inputValue, 800);
    const listElem = useRef();
    const mounted = useRef();

    useEffect(() => {
        if (results) {
            setTreatmentOptions(groupedTreatments(results));
        }
        if (type === 'autoReminder' || type === 'compaign') {
            if (results?.length < count) {
                const data = {
                    limit: count,
                    offset: results.length
                };
                dispatch(getPracticeTreatmentList(practiceId, data));
            }
        }
    }, [results]);


    useEffect(() => {
        if (Object.values(selectedCampaign).length > 0) {
            const data = selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in;
            const matchingFilms = treatmentOptions?.filter(film => data?.includes(film?.name));
            if (data !== undefined && data !== null && inputValueDebounce === '') {
                setSelectedTreatment(matchingFilms);
            }
        }
    }, [selectedCampaign, treatmentOptions]);

    const loadMoreData = () => {
        if (count > results?.length) {
            const data = {
                limit: 30,
                offset: results?.length
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const handleDelete = (delValue) => {
        const tempData = type === 'compaign' ? (newCampaign.treatmentName || selectedTreatment || []) : value;
        const data = tempData?.filter((res) => res.id !== delValue);
        onChange('handleDelete', data, selectedCampaign);
    };

    useEffect(() => {
        if (practiceId && shouldCall) {
            setShouldCall(false);
            if (inputValueDebounce) {
                const data = {
                    type: 'searchTreatment',
                    treatment: inputValueDebounce,
                    category: type === 'treatmentStarted' ? categoryName !== 'All' && categoryName : null
                };
                dispatch(getPracticeTreatmentList(practiceId, data));
            } else {
                const data = {
                    limit: 30,
                    offset: 0,
                    type: 'searchTreatment',
                    category: type === 'treatmentStarted' ? categoryName !== 'All' && categoryName : null
                };
                dispatch(getPracticeTreatmentList(practiceId, data));
            }
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        dispatch(startLoading());
        setShouldCall(true);
        setInputValue(value);
    };

    const handleToggleSelectAll = (e) => {
        e.preventDefault();
        setSelectAll(!selectAll);
        const data = {
            limit: !selectAll ? count : 30,
            offset: 0
        };
        dispatch(getPracticeTreatmentList(practiceId, data));
        if (!selectAll) {
            onChange('', treatmentOptions, selectedCampaign);
            return;
        }
        onChange('', [], selectedCampaign);
    };

    const handleOnChanged = (e, value) => {
        onChange(e, value, selectedCampaign);
        if (value.length === 0 || value?.length !== count) {
            setSelectAll(false);
        }
        if (value?.length === count) {
            setSelectAll(true);
        }
    };

    const handleScroll = (event) => {
        const listboxNode = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
        if (listboxNode) {
            setPosition(event.target.scrollTop + event.target.clientHeight);
            loadMoreData();
        }
    };

    return (
        <RHFAutocomplete
            {...props}
            multiple
            name={name}
            value={type === 'compaign' ? (newCampaign.treatmentName || selectedTreatment || []) : value}
            onChange={handleOnChanged}
            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
            options={treatmentOptions}
            noOptionsText={isLoading ? 'Loading...' : 'No options'}
            groupBy={(option) => option?.firstLetter}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option?.name}
            renderTags={(value) => (
                <Box sx={treatmentChipBoxStyle}>
                    {value?.map((item, index) => (
                        <Chip
                            sx={treatmentChipTextStyle(type)}
                            key={index}
                            label={item?.name}
                            size='small'
                            color='info'
                            variant='soft'
                            onDelete={() => handleDelete(item.id)}
                        />
                    ))}
                </Box>
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.name} style={{ padding: '5px 9px' }}>
                    <Box sx={{ padding: '0px 12px' }} >
                        <RHFCheckbox renderSelect checked={selected} label={option.name} name={option.name} />
                    </Box>
                </li>
            )}
            ListboxProps={{
                ref: listElem,
                onScroll: handleScroll
            }}
            PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                    <Paper {...restPaperProps}>
                        {(type === 'autoReminder') &&
                            <>
                                <Box
                                    onMouseDown={(e) => e.preventDefault()}
                                    sx={{ padding: '5px 12px', ':hover': { cursor: 'pointer' } }}
                                >
                                    <RHFCheckbox
                                        id='select-all-checkbox'
                                        checked={selectAll}
                                        name='Select All'
                                        label='Select All'
                                        onClick={handleToggleSelectAll}
                                    />
                                </Box>
                                <Divider />
                            </>}
                        {children}
                    </Paper>
                );
            }}
        />
    );
};

MultiTreatmentDropdown.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    control: PropTypes.any,
};

export default MultiTreatmentDropdown;
