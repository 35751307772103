/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import moment from 'moment';
// import { menuItemStyle } from 'src/styles/ProfileYourDetails';
import { UserTableListToolbar } from 'src/sections/@dashboard/user/list';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState, useCallback } from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';

// @mui
import { Box, TextField, List, ListItem, ListItemButton, ListItemText, Divider, Typography } from '@mui/material';
// import { MenuItem } from '@mui/material';

// import { useForm } from 'react-hook-form';
// components
// import { FormProvider, RHFSelect } from '../hook-form';
// store
import { useSelector, dispatch } from '../../redux/store';

import {
    getTreatmentSummary,
    addTargetTreatment,
    addStartTime,
    addEndTime,
    setDateRange,
    reportsUserListing
} from '../../redux/slices/analytics';
// utils
import { RangeShortcut, endDate, rangeShortcuts } from '../../constants/ReportSummaryConst';
// style
import { boxStyle, timeRageBoxStyle, OuterboxStyle } from '../../styles/PracticeTimeRangeStyle';
// import { InnerboxStyle} from '../../styles/PracticeTimeRangeStyle';


// ----------------------------------------------------------------------------------------

// Code is Commented here for Select Role Implementation of future use


const PracticeTimeRange = ({ type, filterName, handleFilterName, handleResetFilter }) => {

    // const methods = useForm();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { targetTreatment, startTime, endTime } = useSelector((state) => state.analytics);
    const treatmentList = useSelector((state) => state.practiceTreatment.treatmentsList?.results);
    // const userRoles = [
    //     { label: 'Admin', value: 'ADMIN', content: 'Manages all account data and perform any action including user management. This user will have access to all contact`s data in the account.' },
    //     { label: 'User', value: 'USER', content: 'Manages account contacts (leads, referrals, enquiries) and dashboard tasks. This user will have access to all contact`s data in the account.' },
    //     { label: 'Treatment Coordinator (TCO)', value: 'TCO', content: 'Manages, creates and edit messages.' },
    //     { label: 'Practitioner', value: 'Practitioner', content: 'Able to see reports only.' }
    // ];

    // const handleChange = () => {}

    const currentDate = dayjs();
    const startDate = currentDate.startOf('month');
    const [value, setValue] = useState([startDate.toDate(), currentDate.toDate()]);

    const handleFieldData = (name, value) => {
        if (value) {
            if (name === 'treatments') {
                const treatmentData = treatmentList.filter((elem) => elem?.treatment_item?.name === value)[0]?.treatment_item;
                const data = { treatmentData, practiceId };
                localStorage.setItem('report-treatment', JSON.stringify(data));
                dispatch(getTreatmentSummary(practiceId, value ? treatmentList.filter((elem) => elem?.treatment_item?.name === value)[0]?.treatment_item?.id : '', startTime || '', endTime || ''));
                dispatch(addTargetTreatment(value));
            }
            if (name === 'dateTime') {
                setValue(value);
                const changedStartTime = value[0] ? moment(value[0]?.$d, 'YYYY-MM-DD').format('DD-MM-YYYY 00:00:00') : '';
                const changedEndTime = value[1] ? moment(value[1]?.$d, 'YYYY-MM-DD').format('DD-MM-YYYY 23:59:00') : '';
                // dispatch(getTreatmentSummary(practiceId, targetTreatment ? treatmentList.filter((elem) => elem?.treatment_item?.name === targetTreatment)[0]?.treatment_item?.id : '', changedStartTime, changedEndTime));
                dispatch(reportsUserListing({ practiceId, startTime: changedStartTime, endTime: changedEndTime }));
                dispatch(addStartTime(changedStartTime));
                dispatch(addEndTime(changedEndTime));
            }
        }
    };



    const buildHandleRangeClick = (setValue) => (range) => {
        const today = dayjs();
        switch (range) {
            case RangeShortcut.allTime:
                setValue([null, null]);
                handleFieldData('dateTime', [null, null]);
                break;
            case RangeShortcut.thisWeek:
                setValue([today.startOf('week'), today.endOf('week')]);
                handleFieldData('dateTime', [today.startOf('week'), today.endOf('week')]);
                break;
            case RangeShortcut.lastWeek:
                setValue([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                handleFieldData('dateTime', [
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                break;
            case RangeShortcut.today:
                setValue([today.startOf('day'), today.endOf('day')]);
                handleFieldData('dateTime', [today.startOf('day'), today.endOf('day')]);
                break;
            case RangeShortcut.tomorrow:
                setValue([today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                handleFieldData('dateTime', [today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                break;
            case RangeShortcut.last7Days:
                setValue([today.subtract(1, 'week'), today]);
                handleFieldData('dateTime', [today.subtract(1, 'week'), today]);
                break;
            case RangeShortcut.currentMonth:
                setValue([today.startOf('month'), today.endOf('month')]);
                handleFieldData('dateTime', [today.startOf('month'), today.endOf('month')]);
                break;
            case RangeShortcut.prevMonth:
                setValue([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                handleFieldData('dateTime', [
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                break;
            case RangeShortcut.reset:
                setValue([null, null]);
                break;
            default:
                break;
        }
    };

    const RangeShortcutsPanel = ({ setValue, children }) => {
        const handleRangeClick = useCallback(
            (range) => setValue && buildHandleRangeClick(setValue)(range),
            [setValue],
        );
        return (
            <Box sx={{ display: 'flex', '@media(max-width: 900px)': { display: 'inline-block' } }}>
                <Box sx={{ m: 2 }} display="flex" gap={2}>
                    <div>
                        <Typography variant="overline">Date range shortcuts</Typography>
                        <List>
                            {rangeShortcuts.map(({ range, label }) => (
                                <ListItem key={range} disablePadding>
                                    <ListItemButton onClick={() => handleRangeClick(range)}>
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <Divider orientation="vertical" />
                </Box>
                {children}
            </Box>
        );
    };

    return (
        <Box sx={boxStyle(type)} >
            <Box sx={OuterboxStyle}>
                <UserTableListToolbar
                    filterName={filterName}
                    onFilterName={handleFilterName}
                    onResetFilter={handleResetFilter}
                />
                {/* 
           <Box sx={InnerboxStyle}>

        <FormProvider methods={methods}>

            <RHFSelect
                name={'role'}
                label='Select Role'
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                onChange={(e) => handleChange(e)}
                size='medium'
            >
                {userRoles.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.value}
                        sx={menuItemStyle}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </RHFSelect>

            </FormProvider>
            </Box>
*/}


            </Box>
            <Box sx={timeRageBoxStyle}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                        onChange={(newValue) => handleFieldData('dateTime', newValue)}
                        name={'dateTime'}
                        value={value}
                        inputFormat="DD MMM YYYY"
                        maxDate={endDate}
                        renderInput={(startProps, endProps) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: '0' }}>
                                <TextField {...startProps} onKeyDown={(evt) => evt.preventDefault()} label="Start date" />
                                <Box sx={{ mx: 1 }}> to </Box>
                                <TextField {...endProps} onKeyDown={(evt) => evt.preventDefault()} label="End date" />
                            </Box>
                        )}
                        components={{ PaperContent: RangeShortcutsPanel }}
                        PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
                        componentsProps={{ paperContent: { setValue } }}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
};

PracticeTimeRange.propTypes = {
    type: PropTypes.string,
    mapValues: PropTypes.array,
    setMapValues: PropTypes.any,
    handleData: PropTypes.func,
};

export default PracticeTimeRange;

