import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import { Iconify } from '..';
import { BoxActive, getAttendedActiveClass } from '../../utils/treatmentAccordionUtils';
import { treatmentTabStepperWrapper } from '../../styles/treatmentTabStyle';
import {
    treatmentAccordionDetail,
    treatmentAccordionDetailHeading,
    treatmentAccordionDetailIcon,
    treatmentAccordionDetailSecondIcon,
    treatmentAccordionDetailIconRounded,
    treatmentAccordionDetailLastBox,
    treatmentAccordionDetailCenterBox,
    whiteBGStyle
} from '../../styles/LeadDetailView';

const TreatmentSteppers = ({ data }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const lastBoxActive = (data) => data === 'CLOSED';
    const ftaBoxActive = (data) => data === 'FAILED_TO_ATTEND_CONSULTATION';

    const checkIcon = <Iconify icon={'material-symbols:check'} sx={treatmentAccordionDetailIcon} width={20} height={20} />;
    const middleCheckIcon = <Iconify icon={'material-symbols:check'} sx={treatmentAccordionDetailSecondIcon(isLight)} width={20} height={20} />;
    const closeIcon = <Iconify icon={'material-symbols:close-rounded'} sx={treatmentAccordionDetailIconRounded} width={20} height={20} />;
    const handleIcon = BoxActive(data.status) ? checkIcon : closeIcon;
    const whiteBgDiv = (children) => <Box sx={whiteBGStyle}>{children}</Box>;
    const handleMiddleCheckIcon = BoxActive(data.status) ? whiteBgDiv(middleCheckIcon) : whiteBgDiv(closeIcon);

    if (data.status === 'FAILED_TO_ATTEND_CONSULTATION') {
        return <Box sx={treatmentTabStepperWrapper}>
            <Box className={BoxActive(data.status) ? 'active' : ''} sx={{ ...treatmentAccordionDetail, width: '50%' }}>
                {handleIcon}
                <Typography sx={treatmentAccordionDetailHeading(isLight)}>Contacted</Typography>
            </Box>
            <Box className={BoxActive(data.status) ? 'active' : ''} sx={{ flex: 1, textAlign: 'center' }}>
                {handleMiddleCheckIcon}
                <Typography sx={treatmentAccordionDetailHeading(isLight)}>Consultation booked</Typography>
            </Box>
            <Box className={ftaBoxActive(data.status) ? 'active' : ''} sx={{ ...treatmentAccordionDetailLastBox, width: '50%' }}>
                {ftaBoxActive(data.status) ? checkIcon : closeIcon}
                <Typography sx={treatmentAccordionDetailHeading(isLight)}>Failed to attend</Typography>
            </Box>
        </Box>;
    }

    return <Box sx={treatmentTabStepperWrapper}>
        <Box className={BoxActive(data.status) ? 'active' : ''} sx={{ ...treatmentAccordionDetail, flex: 0.5 }}>
            {handleIcon}
            <Typography sx={treatmentAccordionDetailHeading(isLight)}>Contacted</Typography>
        </Box>
        <Box className={BoxActive(data.status) ? 'active' : ''} sx={{ flex: 1, textAlign: 'center' }}>
            {handleMiddleCheckIcon}
            <Typography sx={treatmentAccordionDetailHeading(isLight)}>Consultation booked</Typography>
        </Box>
        <Box className={getAttendedActiveClass(data.status)} sx={{ ...treatmentAccordionDetailCenterBox, flex: 1, textAlign: 'center' }}>
            {(data.status === 'TREATMENT_STARTED' || data.status === 'CLOSED' || data.status === 'CONSULTATION_ATTENDED') ? checkIcon : closeIcon}
            <Typography sx={treatmentAccordionDetailHeading(isLight)}>Consultation attended</Typography>
        </Box>
        <Box className={(data.status === 'TREATMENT_STARTED' || data.status === 'CLOSED') ? 'active' : ''} sx={{ textAlign: 'center', flex: 1 }}>
            {(data.status === 'TREATMENT_STARTED' || data.status === 'CLOSED') ? whiteBgDiv(checkIcon) : whiteBgDiv(closeIcon)}
            <Typography sx={treatmentAccordionDetailHeading(isLight)}>Started treatment</Typography>
        </Box>
        <Box className={lastBoxActive(data.status) ? 'active' : ''} sx={{ ...treatmentAccordionDetailLastBox, flex: 0.5 }}>
            {lastBoxActive(data.status) ? checkIcon : closeIcon}
            <Typography sx={treatmentAccordionDetailHeading(isLight)}>Completed treatment</Typography>
        </Box>
    </Box>;
};

TreatmentSteppers.propTypes = {
    data: PropTypes.object,
};

export default TreatmentSteppers;