import PropTypes from 'prop-types';
import {
    Stack,
    InputAdornment,
    TextField,
    MenuItem,
    Button
} from '@mui/material';
// components
import { Iconify } from '../../../../components';
// style 
import { menuItemStyle } from '../../../../styles/UserTableToolbarStyle';

// -------------------------------------------------------------------------

export default function UserTableToolbar({
    filterName,
    filterRole,
    onFilterName,
    onFilterRole,
    onResetFilter,
    categoryList,
    leadMoreData,
    setPosition
}) {
    return (
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
            <TextField
                fullWidth
                select
                label="Category"
                value={filterRole}
                onChange={onFilterRole}
                SelectProps={{
                    MenuProps: {
                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                        PaperProps: {
                            onScroll: (event) => {
                                const listboxNode = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
                                if (listboxNode) {
                                    setPosition(event.target.scrollTop + event.target.clientHeight);
                                    leadMoreData();
                                }
                            }
                        },
                    },
                }}
                sx={{
                    maxWidth: { sm: 240 },
                    textTransform: 'capitalize',
                }}
            >
                {categoryList?.results?.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.name}
                        sx={menuItemStyle}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                fullWidth
                value={filterName}
                onChange={(event) => onFilterName(event.target.value)}
                placeholder="Search Treatments..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    ),
                }}
            />
            {filterName && (
                <Button
                    color="error"
                    sx={{ flexShrink: 0 }}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                >
					Clear
                </Button>
            )}
        </Stack>
    );
}

UserTableToolbar.propTypes = {
    filterName: PropTypes.string,
    filterRole: PropTypes.string,
    onFilterName: PropTypes.func,
    onFilterRole: PropTypes.func,
    onResetFilter: PropTypes.func,
    categoryList: PropTypes.object,
    leadMoreData: PropTypes.func,
    setPosition: PropTypes.any,
};