export const imgStyle = {
    mt: 3,
    mx: 'auto',
    display: 'block',
    textAlign: 'center',
    color: 'text.disabled',
};

export const userNameBox = {
    mb: 3,
    display: 'grid',
    rowGap: 3,
    columnGap: 2,
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
};

export const userPhoneBox = {
    mb: 4,
    display: 'grid',
    rowGap: 4,
    columnGap: 2,
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
};

export const menuItemStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};