import { styled } from '@mui/material/styles';

export const FollowUpTitle = styled('div')(() => ({
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px'
}));

export const appointmentTreatmentStyle = {
    width: '100%',
    maxWidth: { md: 'calc(70% - 20px)' },
    mb: {
        sm: 0,
        xs: 0
    }
};

export const gridStyle = (type) => ({
    display: 'none',
    justifyContent: 'space-between',
    mt: '34px',
    mb: type === 'followUp' ? 0 : 3,
    rowGap: 2,
    width: '100%',
    ml: 'auto'
});

export const textFieldStyle = {
    maxWidth: {
        md: '30%'
    },
    mt: {
        md: 0,
        xs: 3
    }
};

export const controllerGrid = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 3,
    mt: 2,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
    },
};

export const boxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 2,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
    },
};