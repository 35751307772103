import PropTypes from 'prop-types';
import { useRef } from 'react';
// @mui
import {
    Grid,
    Dialog,
    Container,
    Stack,
    Button,
    Box
} from '@mui/material';
// layouts
import NotePopup from '../../layouts/dashboard/header/NotePopup';
// style
import { dialogStyle, stackStyle, btnBoxStyle, saveBtnStyle } from '../../styles/SwitchUserPopupStyle';

const AddNotePopup = ({ open, onClose, Users, type, options, setTemplateOpen, templateOpen }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            open={open}
            sx={{ ...dialogStyle, zIndex: 1116, visibility: templateOpen ? 'hidden' : 'visible' }}
        >
            <Stack sx={stackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container
                        maxWidth={'xl'}
                        sx={{ padding: '0 !important' }}
                    >
                        <NotePopup Users={Users} ref={inputRef} handleNext={onClose} type={type} options={options} setTemplateOpen={setTemplateOpen} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={{ ...btnBoxStyle, pt: 0 }}>
                <Button
                    color='inherit'
                    sx={{ margin: '0px 15px', fontSize: '14px', boxShadow: 'none' }}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleNext}
                    sx={saveBtnStyle}
                >
                    {'Save'}
                </Button>
            </Box>
        </Dialog>
    );
};

export default AddNotePopup;

AddNotePopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    Users: PropTypes.any,
    type: PropTypes.string,
    options: PropTypes.string,
    setTemplateOpen: PropTypes.any,
    templateOpen: PropTypes.any,
};