import React from 'react';
import PropType from 'prop-types';
import { Badge, Button } from '@mui/material';
import { arcBtnStyle } from 'src/styles/NewLeadTableRowStyle';

const CustomArchivingButton = ({ params }) => (
    <Badge badgeContent={[params.row?.lead_treatments__lead_contacted_counts__count]} color="primary"
        sx={arcBtnStyle(params.row?.lead_treatments__lead_contacted_counts__count)}
    >
        <Button
            variant="contained"
            color='primary'
            disabled={params.row.is_archive_pending}
            sx={{ backgroundColor: 'rgba(145, 158, 171, 0.24)', color: 'rgba(145, 158, 171, 0.8)', minWidth: '150px' }}
        >
            Archiving
        </Button>
    </Badge>
);

export default CustomArchivingButton;

CustomArchivingButton.propTypes = {
    params: PropType.any,
};
