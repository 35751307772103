export const LEAD_SOURCE_TYPES = [
    { label: 'Email', value: 'EMAIL', icon: 'ic:email' },
    { label: 'Online form', value: 'ONLINE_FORM', icon: 'mdi:invoice' },
    { label: 'Phone', value: 'TELEPHONE', icon: 'fluent:call-32-filled' },
    { label: 'Online chat', value: 'ONLINE_CHAT', icon: 'heroicons:chat-bubble-left-right-20-solid' },
    { label: 'Other', value: 'OTHER', icon: 'material-symbols:circle' },
    { label: 'Visit', value: 'VISIT', icon: 'material-symbols:nest-doorbell-visitor-outline' },
    { label: 'Online booking', value: 'ONLINE_BOOKING', icon: 'mdi:invoice' },
];

export const LEAD_REFERRAL_TYPES = [
    { label: 'Bus', value:'BUS', icon: 'material-symbols:directions-bus' },
    { label: 'Cinema', value: 'CINEMA', icon: 'maki:cinema' },
    { label: 'Email', value: 'EMAIL', icon: 'ic:email' },
    { label: 'Events', value: 'EVENTS', icon: 'mdi:events' },
    { label: 'Existing patient', value: 'EXISTING_PATIENT', icon: 'material-symbols:recent-patient-outline-rounded' },
    { label: 'Facebook', value: 'FACEBOOK', icon: 'ri:facebook-fill' },
    { label: 'Instagram', value: 'INSTAGRAM', icon: 'mdi:instagram' },
    { label: 'Online chat', value: 'ONLINE_CHAT', icon: 'mdi:chat-processing-outline' },
    { label: 'Organic search', value: 'ORGANIC_SEARCH', icon: 'mdi:organic-outline' },
    { label: 'Other online advertising', value: 'OTHER_ONLINE_ADVERTISING', icon: 'ic:baseline-miscellaneous-services' },
    { label: 'Paid search', value: 'PAID_SEARCH', icon: 'eva:search-outline' },
    { label: 'Paid social', value: 'PAID_SOCIAL', icon: 'material-symbols:share-sharp' },
    { label: 'Print advertising', value:'PRINT_ADVERTISING', icon: 'material-symbols:print-outline' },
    { label: 'Radio', value: 'RADIO', icon: 'mingcute:radio-fill' },
    { label: 'Friend referral', value: 'REFERRED_BY_FRIEND', icon: 'fa-solid:user-friends' },
    { label: 'Another practice', value: 'REFERRED_BY_ANOTHER_PRACTICE', icon: 'eva:home-fill' },
    { label: 'Another website', value: 'REFERRED_BY_ANOTHER_WEBSITE', icon: 'mingcute:earth-fill' },
    { label: 'Social media', value: 'SOCIAL', icon: 'material-symbols:share-sharp' },
    { label: 'TikTok', value: 'TIKTOK', icon: 'ic:twotone-tiktok' },
    { label: 'Unavailable', value: 'UNAVAILABLE', icon: 'typcn:cancel-outline' },
    { label: 'Visited practice', value: 'VISITED_PRACTICE', icon: 'medical-icon:i-family-practice' },
    { label: 'Whatsapp', value: 'WHATSAPP', icon: 'ri:whatsapp-fill' },
    { label: 'Youtube', value: 'YOUTUBE', icon: 'ri:youtube-fill' },
    { label: 'Calendly', value: 'CALENDLY', icon: 'mi:calendar' },
    { label: 'SOE', value: 'SOE', icon: 'carbon:network-enterprise' },
    { label: 'Dentally', value: 'DENTALLY', icon: 'akar-icons:dental' },
    { label: 'R4', value: 'R4', icon: 'ic:round-4k' },
    { label: 'Practice Software', value: 'PRACTICE_SOFTWARE', icon: 'grommet-icons:cloud-software' },
    { label: 'Online booking', value: 'ONLINE_BOOKING', icon: 'mdi:invoice' },
];