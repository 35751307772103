/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
//
import Iconify from './Iconify';
import TasksMessageRow from './TasksMessageRow';
// style
import {
    accordionStyle,
    accordionSummmeryStyle,
    headingTypoGraphy,
    headingSpan,
    headingCounter,
    accordionDetailStyle,
    dateTypoStyle
} from '../styles/AutoMessageAccordianStyle';

// -------------------------------------------------------------------------------------------------

const AutoMessageAccordian = ({ Heading, sentTasks, upcommingTask, cancelTask, failedTasks, onUpdateStatus }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [sentData, setSentData] = useState([]);
    const [upcommingData, setUpcommingData] = useState([]);
    const [cancelData, setCancelData] = useState([]);
    const [failedData, setFailedData] = useState([]);

    useEffect(() => {
        if (sentTasks) {
            const sentTasksData = _(sentTasks).groupBy(obj => moment(obj.due, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'))
                .map(group => ({
                    due: _.head(group).due,
                    data: group
                })).value();
            const sortedSentData = sentTasksData.sort((a, b) => new Date(moment(b.due, 'DD-MM-YYYY HH:mm:ss')) - new Date(moment(a.due, 'DD-MM-YYYY HH:mm:ss')));
            setSentData(sortedSentData);
        }
        if (upcommingTask) {
            const upcomingTasksData = _(upcommingTask).groupBy(obj => moment(obj?.instance_object?.last_due_at, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'))
                .map(group => ({
                    due: _.head(group).due,
                    data: group
                })).value();
            const sortedUpcomingData = upcomingTasksData.sort((a, b) => new Date(moment(b.due, 'DD-MM-YYYY HH:mm:ss')) - new Date(moment(a.due, 'DD-MM-YYYY HH:mm:ss')));
            setUpcommingData(sortedUpcomingData);
        }
        if(cancelTask) {
            const cancelTasksData = _(cancelTask).groupBy(obj => moment(obj?.instance_object?.last_due_at, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'))
                .map(group => ({
                    due: _.head(group).due,
                    data: group
                })).value();
            const sortedUpcomingData = cancelTasksData.sort((a, b) => new Date(moment(b.due, 'DD-MM-YYYY HH:mm:ss')) - new Date(moment(a.due, 'DD-MM-YYYY HH:mm:ss')));
            setCancelData(sortedUpcomingData);
        }
        if (failedTasks) {
            const failedTasksData = _(failedTasks).groupBy(obj => moment(obj?.instance_object?.last_due_at, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm:ss'))
                .map(group => ({
                    due: _.head(group).due,
                    data: group
                })).value();
            const sortedFailedData = failedTasksData.sort((a, b) => new Date(moment(b.due, 'DD-MM-YYYY HH:mm:ss')) - new Date(moment(a.due, 'DD-MM-YYYY HH:mm:ss')));
            setFailedData(sortedFailedData);
        }
    }, [sentTasks, upcommingTask, cancelTask, failedTasks]);

    return (
        <Accordion className={Heading.data === 0 ? 'disable' : null} sx={accordionStyle}>
            <AccordionSummary
                expandIcon={<Iconify icon={'entypo:chevron-small-down'} width={25} height={25} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={accordionSummmeryStyle(isLight)}
            >
                <Typography sx={headingTypoGraphy}>
                    <span style={headingSpan(Heading.value)} />{Heading.value}
                </Typography>
                <Typography sx={headingCounter(Heading.value)}>{Heading.value === 'Sent' && sentTasks?.length || Heading.value === 'Upcoming' && upcommingTask?.length || Heading.value === 'Cancelled' && cancelTask?.length || Heading.value === 'Failed' && failedTasks?.length || 0}</Typography>
            </AccordionSummary>
            {Heading.data !== 0 && <AccordionDetails sx={accordionDetailStyle}>
                {
                    Heading.value === 'Sent' && sentData?.length > 0 && sentData.map((row, index) =>
                        <Box key={index} sx={{ width: '100%' }}>
                            <Typography sx={dateTypoStyle}> {moment(row?.due, 'DD-MM-YYYY HH:mm:ss').format('DD MMMM [at] hh:mm a')}</Typography>
                            {row?.data.map((el) => <TasksMessageRow key={el.id} data={el} onUpdateStatus={onUpdateStatus} />)}
                        </Box>) ||
					Heading.value === 'Upcoming' && upcommingData?.length > 0 && upcommingData.map((row, index) =>
					    <Box key={index}>
					        <Typography sx={dateTypoStyle}> {moment(row?.due, 'DD-MM-YYYY HH:mm:ss').format('DD MMMM [at] hh:mm a')}</Typography>
					        {row?.data.map((el) => <TasksMessageRow key={el.id} data={el} onUpdateStatus={onUpdateStatus} upcomming={'upcomming'} />)}
					    </Box>) ||
					Heading.value === 'Cancelled' && cancelData?.length > 0 && cancelData.map((row, index) =>
					    <Box key={index}>
					        <Typography sx={dateTypoStyle}> {moment(row?.due, 'DD-MM-YYYY HH:mm:ss').format('DD MMMM [at] hh:mm a')}</Typography>
					        {row?.data.map((el) => <TasksMessageRow key={el.id} data={el} onUpdateStatus={onUpdateStatus} upcomming={'upcomming'} />)}
					    </Box>) ||
					Heading.value === 'Failed' && failedData?.length > 0 && failedData.map((row, index) =>
					    <Box key={index}>
					        <Typography sx={dateTypoStyle}> {moment(row?.due, 'DD-MM-YYYY HH:mm:ss').format('DD MMMM [at] hh:mm a')}</Typography>
					        {row?.data.map((el) => <TasksMessageRow key={el.id} data={el} onUpdateStatus={onUpdateStatus} />)}
					    </Box>)
                }
            </AccordionDetails>}
        </Accordion>
    );
};

AutoMessageAccordian.propTypes = {
    Heading: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    sentTasks: PropTypes.array,
    upcommingTask: PropTypes.array,
    cancelTask: PropTypes.array,
    failedTasks: PropTypes.array,
    onUpdateStatus: PropTypes.func
};

export default AutoMessageAccordian;
