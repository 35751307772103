/* eslint-disable react-hooks/exhaustive-deps */
// import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// @mui
import {
    Box,
    Button,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Typography
} from '@mui/material';
// constant
import { SCHEDULE_SETTINGS_TABLE_HEAD, INTERVAL } from '../../../../constants/analyticsTable';
// components
import { Iconify } from '../../../../components';
import { TableHeadCustom, TableEmptyRows, TableNoData } from '../../../../components/table';
import { TableLoading } from '../../../../components/loading';
import { ScheduleSettingRow } from '../../../../components/anlytics';
import {
    FormProvider,
    RHFCheckbox,
    RHFSelect,
    RHFTextField
} from '../../../../components/hook-form';
import useTable, { emptyRows } from '../../../../hooks/useTable';
// redux
import { useSelector, dispatch } from '../../../../redux/store';
import {
    getScheduleSettings,
    addScheduleSettings,
    updateScheduleSettings,
    deleteScheduleSettings
} from '../../../../redux/slices/analytics';
// style
import { DialogBox } from '../../../../styles/ConsentBuilder';
import {
    paginationOuterBoxStyle,
    paginationInnerBoxStyle,
} from '../../../../styles/MessageListStyle';
import { cardStyle } from '../../../../styles/ReportsSettingStyle';
// validation
import { ScheduleSettingsSchema } from '../../../../validations/validations';

// ----------------------------------------------------------------------------------------------

export default function ScheduleSettings() {

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { ScheduleSettingList } = useSelector((state) => state.analytics);
    const { reportType } = useSelector((state) => state.schema);

    const [open, setOpen] = useState(false);

    const defaultValues = {
        isEnabled: false,
        reportType: '',
        interval: '',
        recepients: '',
    };

    const methods = useForm({
        resolver: yupResolver(ScheduleSettingsSchema),
        defaultValues
    });

    const {
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const {
        handleSubmit,
        reset,
        setValue,
        formState: { isDirty },
        getValues,
        watch
    } = methods;

    const value = watch();

    useEffect(() => {
        if (practiceId) {
            dispatch(getScheduleSettings(practiceId, 10, 0));
        }
    }, [practiceId]);

    const handleOpen = () => {
        setOpen(open => !open);
        reset();
    };

    const onSubmit = (data) => {
        const payload = {
            is_enabled: data.isEnabled,
            report_type: data.reportType,
            interval: Number(data.interval),
            recepients: data.recepients.split(',').filter(r => r.trim().length > 0)
        };
        if (data.selected) {
            dispatch(updateScheduleSettings(practiceId, data.id, payload, handleUpdate));
            return;
        }
        dispatch(addScheduleSettings(practiceId, payload, handleUpdate));
    };

    const handleGetUpdatedData = (limit, offset) => {
        dispatch(getScheduleSettings(practiceId, limit, offset, handlePagination));
    };
    const handlePagination = (count) => {
        if (count === 10) {
            dispatch(getScheduleSettings(practiceId, rowsPerPage, 0));
            onChangePage('', 0);
        }
    };

    const handleUpdate = () => {
        setOpen(false);
        handleGetUpdatedData(rowsPerPage, rowsPerPage * page);
    };

    const selectRow = (row, type) => {
        if (type === 'update') {
            handleOpen();
            setValue('reportType', row.report_type);
            setValue('isEnabled', row.is_enabled);
            setValue('interval', row.interval);
            setValue('recepients', row.recepients.toString());
            setValue('selected', true);
            setValue('row', row);
            setValue('id', row.id);
            return;
        }
        if (type === 'toggle') {
            const payload = {
                is_enabled: row.is_enabled,
            };
            dispatch(updateScheduleSettings(practiceId, row.id, payload, handleUpdate));
            return;
        }
        dispatch(deleteScheduleSettings(practiceId, row.id, handleUpdate));
    };

    const handlePageNumber = (event, newPage) => {
        onChangePage(event, newPage);
        handleGetUpdatedData(rowsPerPage, rowsPerPage * newPage);
    };

    const handleChangePerPage = (event) => {
        onChangeRowsPerPage(event);
        handleGetUpdatedData(event.target.value, page);
    };

    const disabledButton = () => {
        if (isDirty || (getValues('selected') ? getValues('row')?.recepients.toString() !== value?.recepients.toString() : '')) {
            return false;
        }
        return true;
    };

    return (
        <>
            <Box sx={{ ...cardStyle, display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{ mb: 0, fontSize: '18px !important' }}> Report schedule setting</Typography>
                <Button
                    id="demo-positioned-button"
                    startIcon={<Iconify icon={'iconoir:database-settings'} />}
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-describedby={'pop-button'}
                    variant="contained"
                    onClick={handleOpen}
                >
                    <iconify-icon icon="akar-icons:plus" /> Add Report schedule
                </Button>
            </Box>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table stickyHeader sx={{ '.MuiTableCell-root': { boxShadow: 'none !important' }, '.MuiTableCell-body': { whiteSpace: 'nowrap' } }}>
                    <TableHeadCustom
                        headLabel={SCHEDULE_SETTINGS_TABLE_HEAD}
                    />
                    <TableBody>
                        {<TableLoading tableData={Object.values(ScheduleSettingList)?.length === 0 && 'No Found'} pageCount={Object.values(ScheduleSettingList)?.length === 0 && 'No Found'} />}
                        {Object.values(ScheduleSettingList)?.length > 0 && ScheduleSettingList.results.map((row, index) =>
                            <ScheduleSettingRow
                                row={row}
                                key={index}
                                selectRow={selectRow}
                            />
                        )}
                    </TableBody>
                    {ScheduleSettingList?.results?.length === 0 &&
                        <>
                            <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, ScheduleSettingList?.results?.length)} />
                            {ScheduleSettingList?.results?.length === 0 && <TableNoData isNotFound={ScheduleSettingList?.results?.length === 0} />}
                        </>}
                </Table>
            </TableContainer>

            <Box sx={paginationOuterBoxStyle}>
                <Box sx={{ ...paginationInnerBoxStyle, width: '100%', justifyContent: 'flex-end' }}>
                    <TablePagination
                        rowsPerPageOptions={[10, 25]}
                        sx={{ display: 'none' }}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        count={ScheduleSettingList?.count || 0}
                        onPageChange={handlePageNumber}
                        onRowsPerPageChange={handleChangePerPage}
                    />
                </Box>
            </Box>

            <Dialog open={open} onClose={handleOpen} sx={DialogBox('')}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle sx={{ borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)!important', pb: '24px' }}>{getValues('selected') ? 'Update Schedule Settings ' : 'Add Schedule Settings'}</DialogTitle>
                    <DialogContent sx={{ p: '24px !important' }}>
                        <RHFSelect
                            name='reportType'
                            label='Report Type (Required)'
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            sx={{ mb: 3 }}
                        >
                            {reportType?.map((options, index) => (
                                <MenuItem
                                    key={index}
                                    value={options.value}
                                >
                                    {options.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFSelect
                            name='interval'
                            label='Interval (Required)'
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            sx={{ mb: 3 }}
                        >
                            {INTERVAL.map((options, index) => (
                                <MenuItem
                                    key={index}
                                    value={options.value}
                                >
                                    {options.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField
                            name="recepients"
                            label="Recepients (Required)"
                            sx={{ width: '100%', mb: 1 }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <RHFCheckbox
                                name={'isEnabled'}
                                color="primary"
                                label={'Enabled'}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ borderTop: 'solid 1px rgba(145, 158, 171, 0.24)!important' }}>
                        <Button onClick={handleOpen}>Cancel</Button>
                        <Button type="submit" variant='contained' disabled={disabledButton()}>Submit</Button>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </>
    );
}


ScheduleSettings.propTypes = {};
