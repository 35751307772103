/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { uniqBy } from 'lodash';
import EmailEditor from 'react-email-editor';
// @mui
import { styled } from '@mui/material/styles';
import { Box, IconButton, Link, Typography, Divider, Button, FormControlLabel, Switch, Stack, useTheme } from '@mui/material';
import { Label } from 'src/components/labels';
//
import { useSelector } from '../../redux/store';
import EditorToolbar, { formats, redoChange, undoChange, imageHandler } from './EditorToolbar';
// component
import { Iconify } from '..';
// style
import { attachmentWrapper, clearButton, editorHeaderStyle, EditorRootStyle } from '../../styles/EmailMessageComposeStyle';
import { FormDialog } from '../dialogPopUp';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: 'solid 1px rgba(145, 158, 171, 0.2)',
    '& .ql-container.ql-snow': {
        borderColor: 'transparent',
        ...theme.typography.body1,
        fontFamily: theme.typography.fontFamily,
    },
    '& .mcnRetinaImage': {
        maxWidth: '1200px !important',
        paddingBottom: 0,
        display: 'inline !important',
        verticalAlign: 'bottom',
    },
    '& .ql-editor': {
        minHeight: 330,
        maxHeight: 330,
        '&.ql-blank::before': {
            fontStyle: 'normal',
            color: theme.palette.text.disabled,
        },
        '& pre.ql-syntax': {
            ...theme.typography.body2,
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[900],
        },
    },
}));

// ----------------------------------------------------------------------

export default function Editor({
    id = 'minimal-quill',
    error,
    value,
    onChange,
    simple = false,
    helperText,
    sx,
    EditorMessage,
    type,
    fullScreen,
    handleAttachment,
    screenType,
    file,
    fileSize,
    campaign,
    onLoad,
    handleIsUnlayerChange,
    useUnlayer,
    practiceId,
    ...other
}) {
    const theme = useTheme();
    const commonModules = {
        toolbar:
            type === 'sms_editor'
                ? false
                : {
                    container: `#${id}`,
                    handlers: {
                        undo: undoChange,
                        redo: redoChange,
                        image: imageHandler
                    },
                },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
        syntax: true,
        clipboard: {
            matchVisual: false,
        },
    };

    const modules = {
        toolbar: {
            container: `#${id}`,
            handlers: {
                undo: undoChange,
                redo: redoChange,
            },
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
        syntax: true,
        clipboard: {
            matchVisual: false,
        },
    };

    const { isAutoSaving, autoSaveStatus } = useSelector((state) => state.practiceMessage);
    const { isAutoSaving: isAutoSavingSA, autoSaveStatus: autoSaveStatusSA } = useSelector((state) => state.superAdmin);

    const [files, setFiles] = useState([]);

    const hiddenFileInput = useRef(null);

    function getLastPathSegment(url) {
        const splitUrl = url?.split('/');
        return splitUrl[splitUrl?.length - 1];
    }

    const handleInputFiles = (e) => {
        const uniqueFiles = Array.from(new Set([...Array.from(e.target.files)]));
        const data = [...uniqueFiles, ...files];
        const removed = uniqBy(data, 'name');
        handleAttachment(removed);
        setFiles(removed);
    };

    const handleClearUploaded = (value) => {
        const removedData = files.filter((file) => file.name !== value);
        setFiles(removedData);
        handleAttachment(removedData);
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    return (
        <div style={{ height: fullScreen ? '100%' : 'auto' }}>
            <RootStyle sx={EditorRootStyle(error, sx, type, fullScreen)}>
                {type === 'email_editor' && screenType !== 'superAdmin' && screenType !== 'Practice_Message' && screenType !== 'OnBoarding' &&
                    <Box sx={{ p: '8px 16px 0' }}>
                        <Box sx={attachmentWrapper} alignItems='center'>
                            <Stack direction="row" alignItems="center">
                                <IconButton
                                    onClick={() => {
                                        hiddenFileInput?.current?.click();
                                    }}
                                >
                                    <Iconify icon="eva:attach-2-fill" />
                                </IconButton>
                            </Stack>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                accept=".pdf,.csv,.docx,.xls,.xlsx,.doc"
                                style={{ display: 'none' }}
                                onChange={handleInputFiles}
                                multiple
                            />
                            <Box sx={{}}>
                                {files?.map((value, index) => (
                                    <Box sx={{ display: 'flex', p: '0' }} key={index}>
                                        <Label sx={{ mr: 1 }}> Uploaded files : </Label>
                                        <Typography noWrap key={value.name} variant="subtitle2" sx={{ color: 'black' }}>
                                            {value.name}
                                        </Typography>
                                        <IconButton sx={clearButton} color="error" onClick={() => handleClearUploaded(value?.name)}>
                                            <Iconify icon="basil:cross-outline" height={18} width={18} />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                            {fileSize !== undefined && Object.values(fileSize)?.length > 0 && (
                                <Box sx={{ p: '0px 18px 16px', mt: '-12px' }}>
                                    <Typography color={'error'} variant="subtitle2" sx={{ fontSize: '12px' }}>
                                        {' '}
                                        Maximum File Size should be 20MB!{' '}
                                    </Typography>
                                </Box>
                            )}
                            {file !== undefined && Object.values(file).length > 0 && file.attachments?.length > 0 && (
                                <>
                                    <Divider sx={{ mb: 1 }} />
                                    {file.attachments.map((value, index) => (
                                        <Box sx={{ display: 'flex', p: '0 16px 0', flexWrap: 'wrap' }} key={index}>
                                            <Label sx={{ mr: 1 }}> Attachment : </Label>
                                            <Link
                                                noWrap
                                                key={value.file}
                                                href={value.file}
                                                variant="body2"
                                                target="_blank"
                                                rel="noopener"
                                                sx={{ color: 'black' }}
                                            >
                                                {getLastPathSegment(value.file)}
                                            </Link>
                                            <IconButton
                                                sx={{ p: 0, ml: 1 }}
                                                color="error"
                                                onClick={() => handleAttachment(files, value.file, 'clear')}
                                            >
                                                <Iconify icon="solar:trash-bin-trash-bold" />
                                            </IconButton>
                                        </Box>
                                    ))}
                                </>
                            )}
                        </Box>
                    </Box>
                }
                {campaign && <Box sx={editorHeaderStyle}>
                    <FormControlLabel control={<Switch checked={useUnlayer}
                        onChange={handleIsUnlayerChange}
                        inputProps={{ 'aria-label': 'controlled' }} />} label="Advance editor" />
                    {useUnlayer && (isAutoSaving || isAutoSavingSA) && <Typography>{'Saving...'}</Typography>}
                    {useUnlayer && (autoSaveStatus || autoSaveStatusSA) && <Typography>{'Saved'}</Typography>}
                </Box>}
                {(campaign && useUnlayer) ? (
                    <Box sx={{ 'iframe': { minWidth: '100% !important' } }}>
                        <EmailEditor
                            ref={EditorMessage}
                            onReady={() => {
                                onLoad();
                                EditorMessage.current.editor.addEventListener('design:updated', (data) => {
                                    onChange(data);
                                });
                            }}
                            minHeight="calc(100vh - 330px)"
                        />
                    </Box>
                ) : (
                    <Stack spacing={2} flexGrow={1} sx={{ p: 2 }}>
                        <Box sx={{ borderRadius: 1, border: '1px solid rgba(145, 158, 171, 0.2)', '& .ql-editor': { bgcolor: 'rgba(145, 158, 171, 0.08)' } }}>
                            <EditorToolbar id={id} isSimple={simple} />
                            <ReactQuill
                                ref={EditorMessage}
                                value={value}
                                onChange={onChange}
                                modules={type === 'email_editor' || type === 'sms_editor' ? commonModules : modules}
                                formats={formats}
                                placeholder="Write something awesome..."
                                {...other}
                            />
                        </Box>
                    </Stack>
                )}
            </RootStyle>
            {helperText && helperText}
        </div>
    );
}

Editor.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    handleAttachment: PropTypes.any,
    error: PropTypes.bool,
    helperText: PropTypes.node,
    simple: PropTypes.bool,
    sx: PropTypes.object,
    EditorMessage: PropTypes.any,
    type: PropTypes.string,
    fullScreen: PropTypes.bool,
    screenType: PropTypes.any,
    file: PropTypes.any,
    fileSize: PropTypes.any,
    setFileSize: PropTypes.any,
    campaign: PropTypes.any,
    onLoad: PropTypes.func,
    useUnlayer: PropTypes.bool,
    handleIsUnlayerChange: PropTypes.func,
    practiceId: PropTypes.number,
};

