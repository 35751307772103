import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
// use @mui
import {
    Stack,
    Dialog,
    Typography,
    MenuItem,
    Button
} from '@mui/material';
// use form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// use components
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { DelayDataSchema, customeDataSchema } from '../../../validations/validations';

export default function SendMessageDialog({
    open,
    onClose,
    handleTime,
    composeDetail
}) {
    const [postFix, setPostFix] = useState('');
    const [customValue, setCustomeValue] = useState('');
    const [labelPrefix, setLabelPrefix] = useState(0);
    const [updateForm, setUpdateForm] = useState(false);
    const [labelTime, setLabelTime] = useState('Minutes');
    const days = ['Immediately', '1 day', '2 day', '1 week', '2 weeks', 'Custom'];
    const time = ['Minutes', 'Hours', 'Days'];
    /**
     * Form Default Field  required error handle.
     */

    const defaultValues = useMemo(
        () => ({
            delay: postFix && postFix || 'Immediately',
            time: labelTime && labelTime || 'Minutes',
            value: customValue && customValue || 0,
            prefix: labelPrefix && labelPrefix || 'After',
        }),[postFix, labelTime, customValue, labelPrefix]
    );

    const methods = useForm({
        resolver: yupResolver(postFix !== 'Custom'? DelayDataSchema : customeDataSchema),
        defaultValues,
        shouldFocusError: true,
    });
    const {
        reset,
        watch,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors }
    } = methods;
    const values = watch();

    const setValidation = (value, type) => {
        const validData = validValue(value ,type);
        if (validData) {
            setError('value', { type: 'custom', message: validData}, { shouldFocus: true });
        } else {
            clearErrors('value');
        }
    };
    const validValue = (value, type) => {
        const weeksDays = 6 * 7;
        switch (type) {
            case 'Minutes':
                return value > weeksDays * 720 && 'Please enter max 6 week minutes';
            case 'Hours':
                return value > weeksDays * 24 && 'Please enter max 6 week Hours';
            case 'Days':
                return value > weeksDays && 'Please enter max 6 Weeks';
            default:
                return '';
        }
    };
    const handleChangeTime = (event) => {
        const { value } = event.target;
        setValue('time', value);
        setValidation(values.value, value);
    };
    const countCustomSecond = (data) => {
        switch (data.time) {
            case 'Minutes':
                return data.value * 60;
            case 'Hours':
                return data.value * 3600;
            case 'Days':
                return data.value * 86400;
            default:
                return 0;
        }
    };

    const countSecond = (data) => {
        switch (data.delay) {
            case 'Immediately':
                return 0;
            case '1 day':
                return  86400;
            case '2 day':
                return  86400 * 2;
            case '1 week':
                return  86400 * 7;
            case '2 weeks':
                return  86400 * 14;
            case 'Custom':
                return countCustomSecond(data);
            default:
                return 0;
        }
    };
    const onSubmit = async (value) => {
        if (!Object.entries(errors).length) {
            const detail = {
                label: value.delay,
                second: value.prefix === 'Before' ? Number(`-${countSecond(value)}`) : countSecond(value)
            };
            handleTime(detail);
        }
    };

    useEffect(()=>{
        if(composeDetail.delay === 0){
            setValue('delay', 'Immediately');
            setPostFix('Immediately');
        }
        else if(Math.abs(composeDetail.delay) === 86400) {
            setValue('delay', '1 day');
            setValue('prefix', composeDetail.delay.toString().includes('-') ? 'Before' : 'After' );
            setLabelPrefix(composeDetail.delay.toString().includes('-') ? 'Before' : 'After');
            setPostFix('1 day');
        }
        else if(Math.abs(composeDetail.delay) === 172800) {
            setValue('delay', '2 day');
            setValue('prefix', composeDetail.delay.toString().includes('-') ? 'Before' : 'After' );
            setLabelPrefix(composeDetail.delay.toString().includes('-') ? 'Before' : 'After');
            setPostFix('2 day');
        }
        else if(Math.abs(composeDetail.delay) === 604800) {
            setValue('delay', '1 week');
            setValue('prefix', composeDetail.delay.toString().includes('-') ? 'Before' : 'After' );
            setLabelPrefix(composeDetail.delay.toString().includes('-') ? 'Before' : 'After');
            setPostFix('1 week');
        }
        else if(Math.abs(composeDetail.delay) === 1209600) {
            setValue('delay', '2 weeks');
            setValue('prefix', composeDetail.delay.toString().includes('-') ? 'Before' : 'After' );
            setLabelPrefix(composeDetail.delay.toString().includes('-') ? 'Before' : 'After');
            setPostFix('2 weeks');
        }
        else {
            setValue('delay', 'Custom');
            setValue('value', Math.abs(composeDetail.delay) / 60);
            setCustomeValue( Math.abs(composeDetail.delay) / 60 );
            setValue('prefix', composeDetail.delay && composeDetail.delay.toString().includes('-') ? 'Before' : 'After' );
            setLabelPrefix(composeDetail.delay && composeDetail.delay.toString().includes('-') ? 'Before' : 'After');
            setPostFix('Custom');
        }
    },[composeDetail, setValue]);

    useEffect(() => {
        if(updateForm) {
            reset(defaultValues);
            return;
        }
        setUpdateForm(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ defaultValues]);

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
            <Stack spacing={2} sx={{ p: 3 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3} alignItems="flex-start">
                        <Typography variant="overline" sx={{ color: 'grey.600', my: 1  }}>
                            Send message
                        </Typography>
                        <RHFSelect
                            name={'delay'}
                            label="Time"
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                                setValue('delay', event.target.value);
                                clearErrors('value');
                                setPostFix(event.target.value);
                            }}
                            sx={{ width: '100%'}}
                            SelectProps={{
                                native: false,
                            }}
                        >
                            {days.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={option}
                                    sx={{
                                        mx: 1,
                                        my: 0.5,
                                        borderRadius: 0.75,
                                        typography: 'body2',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        {values.delay === 'Custom' ?
                            <>
                                <Typography variant="p" sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 500 }}>
                                    Custom time
                                </Typography>
                                <Stack sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <RHFTextField
                                        name={'value'}
                                        label="Value"
                                        type="number"
                                        onChange={(event) => {
                                            const data = event.target.value;
                                            setValue('value', data);
                                            setValidation(data, values.time);
                                            setCustomeValue(data);
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                    <RHFSelect
                                        name={'time'}
                                        label="Time"
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: '100%'}}
                                        onChange={(event) => {
                                            handleChangeTime(event);
                                            setLabelTime(event.target.value);
                                        }}
                                        SelectProps={{
                                            native: false,
                                        }}
                                    >
                                        {time.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                value={option}
                                                sx={{
                                                    mx: 1,
                                                    my: 0.5,
                                                    borderRadius: 0.75,
                                                    typography: 'body2',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                </Stack>
                            </>
                            : ''}
                        {postFix !== 'Immediately' ? <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: .6 }}>
                            <Typography
                                variant="span"
                                sx={{
                                    my: 1,
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    bgcolor: values.prefix === 'Before' ? 'primary.lighter' : '',
                                    p: .7,
                                    borderRadius: '8px',
                                    cursor: 'pointer'
                                }}
                                onClick={() =>  setValue('prefix', 'Before')}
                            >
                                Before
                            </Typography>
                            <Typography
                                variant="span"
                                sx={{
                                    my: 1,
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    bgcolor: values.prefix === 'After' ? 'primary.lighter' : '',
                                    p: .7,
                                    borderRadius: '8px',
                                    cursor: 'pointer'
                                }}
                                onClick={() =>  setValue('prefix', 'After')}
                            >
                                After
                            </Typography>
                        </Stack> : null}
                    </Stack>
                    <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 4, mr: -2 }}>
                        <Button variant="text" onClick={() => { onClose(); }}>
                            Cancel
                        </Button>
                        <Button variant="text" type="submit">
                            OK
                        </Button>
                    </Stack>
                </FormProvider>
            </Stack>
        </Dialog>
    );
}

SendMessageDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleTime: PropTypes.func,
    composeDetail: PropTypes.object
};