import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// mui
import { Button, Dialog, DialogTitle, DialogActions, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
import { useSelector } from '../redux/store';
// style
import { cancelBtnStyle } from '../styles/NewLead';
import { yesBtnStyleForLoader } from '../styles/Common';

const UnableToContactConfirmationDialog = ({
    toggle,
    handleClose,
    handleSubmit,
    message,
    style = {},
    dialogRef,
    cancelBtn = 'Cancel',
    saveBtn = 'Yes',
    note,
}) => {
    const { isLoader } = useSelector((state) => state.loaderReducer);

    return (
        <Dialog open={toggle} onClose={handleClose} ref={dialogRef} sx={{ ...style }}>
            <DialogTitle sx={{ padding: '40px 40px 0px', textAlign: 'center' }}>
                {message?.followup ? `Do you want to submit ${note && '"Note" and '} "Reminder"?` : message}
                {note &&
                    <Typography sx={{ m: '12px 0 0', textAlign: 'left' }}>Note: {note}</Typography>
                }
                {message?.followup &&
                    <Typography sx={{ m: note ? '0' : '12px 0 0', textAlign: 'left' }}>Follow up: {message?.followup === 'No reminder' ? 'No reminder' : moment(message?.followup).format('DD-MM-YYYY HH:mm:ss')}</Typography>
                }
            </DialogTitle>
            <DialogActions sx={{ padding: '40px !important', justifyContent: 'center' }}>
                <Button sx={cancelBtnStyle} onClick={handleClose}>
                    {cancelBtn}
                </Button>
                <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isLoader}
                    loadingPosition='start'
                    onClick={handleSubmit}
                    sx={{ ...yesBtnStyleForLoader(isLoader) }}
                    disabled={isLoader}
                >
                    {saveBtn}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default UnableToContactConfirmationDialog;

UnableToContactConfirmationDialog.propTypes = {
    toggle: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    message: PropTypes.string,
    style: PropTypes.object,
    cancelBtn: PropTypes.string,
    saveBtn: PropTypes.string,
    dialogRef: PropTypes.object,
    note: PropTypes.string,
};
