import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
    Radio,
    FormLabel,
    RadioGroup,
    FormControl,
    FormHelperText,
    FormControlLabel,
} from '@mui/material';

// ----------------------------------------------------------------------

export default function RHFRadioGroup({
    row,
    name,
    label,
    size,
    options,
    spacing,
    helperText,
    ...other
}) {
    const { control } = useFormContext();

    const labelledby = label ? `${name}-${label}` : '';

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl component="fieldset">
                    {label && (
                        <FormLabel component="legend" id={labelledby} sx={{ typography: 'body2' }}>
                            {label}
                        </FormLabel>
                    )}

                    <RadioGroup {...field} aria-labelledby={labelledby} row={row} {...other}>
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio size={size} />}
                                label={option.label}
                                sx={{
                                    '&:not(:last-of-type)': {
                                        mb: spacing || 0,
                                    },
                                    ...(row && {
                                        mr: 0,
                                        '&:not(:last-of-type)': {
                                            mr: spacing || 2,
                                        },
                                    }),
                                }}
                            />
                        ))}
                    </RadioGroup>

                    {(!!error || helperText) && (
                        <FormHelperText error={!!error} sx={{ px: 2 }}>
                            {error ? error?.message : helperText}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
}

RHFRadioGroup.propTypes = {
    row: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    options: PropTypes.array,
    spacing: PropTypes.number,
    helperText: PropTypes.node,
};