import React from 'react';
import ViewDetailsField from '../ViewDetailsField';

const ViewDetailCell = ({ leadPage, tableTitle, tablePath, classNames }) => ({
    field: 'viewDetails',
    headerName: 'View Detail',
    className: classNames || '',
    sortable: false,
    minWidth: 45,
    width: 45,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
        <ViewDetailsField
            row={params?.row}
            leadPage={leadPage}
            tableTitle={tableTitle}
            tablePath={tablePath}
        />
    ),
});

export default ViewDetailCell;