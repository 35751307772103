/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
// @mui
// import { LoadingButton } from '@mui/lab';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { Iconify } from '../../../components';


// ----------------------------------------------------------------------

export default function MessageSidebarItem({
    label,
    index,
    setSequenceId,
    open,
    handleClick,
    dropdownHead,
    setSequenceName,
    sequenceName
}) {
    const getSelectId = (item, name) => {
        setSequenceId(item);
        setSequenceName(name);
    };

    const getActiveSequence = (detail) => {
        if (!sequenceName) {
            return false;
        }
        if (sequenceName.sequence && sequenceName.sequence.name && sequenceName.sequence.name === detail.sequence.name) {
            return true;
        }
        return false;
    };

    const theme = useTheme();

    const isLight = theme.palette.mode === 'light';

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px: 1 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            <ListItemButton sx={{color: open === index ? 'primary.main' : 'grey.600', height: '48px', borderRadius: '8px' , bgcolor: open === index ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) : '' }} onClick={() => handleClick(index)}>
                <ListItemText primaryTypographyProps={{ fontWeight: open === index ? 600 : 400, fontSize: '.875rem' }} primary={dropdownHead[index]} />
                {label.length > 0 && <Iconify icon={open === index ? 'eva:arrow-ios-downward-outline' : 'eva:arrow-ios-forward-outline'} width={20} height={20} />}
            </ListItemButton>
            {dropdownHead[index] === dropdownHead[0] && <Collapse in={open === index} timeout="auto" unmountOnExit sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                {label && label.map((item, index) => (
                    <List component="div" key={index} sx={{ height: '50px' }} onClick={() => { getSelectId(item.id, item);}} >
                        <ListItemButton sx={{ pl: 2, color: getActiveSequence(item) ? isLight ? '#212B36' : '#fff' : 'grey.600' }}>
                            <Iconify icon={'akar-icons:circle-fill'} width={ getActiveSequence(item) ? 10 : 6 } height={ getActiveSequence(item) ? 10 : 6 } sx={{ mr: 3, color: getActiveSequence(item) ? 'primary.main' : 'grey.600' }} />
                            <ListItemText primaryTypographyProps={{ fontWeight: getActiveSequence(item) ? 600 : 400 }} sx={{ pl: 1, maxWidth: '177px', ' span': { overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '.875rem', whiteSpace: 'nowrap' } }} primary={item.sequence.name} />
                        </ListItemButton>
                    </List>
                ))}
            </Collapse>}
        </List>
    );
}

MessageSidebarItem.propTypes = {
    label: PropTypes.array,
    dropdownHead: PropTypes.array,
    setSequenceId: PropTypes.func,
    setSequenceName: PropTypes.func,
    index: PropTypes.any,
    open: PropTypes.number,
    handleClick: PropTypes.func,
    sequenceName: PropTypes.any
};