/* eslint-disable no-nested-ternary */
export const completeBox = (markButton) => ({
    cursor: 'pointer',
    border: `2px solid ${markButton ? '#54D62C' : '#bcc8d53d'}`,
    color: 'green',
    borderRadius: '8px',
    marginBottom: '25px',
    padding: '6px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center' 
});

export const completeTypo = (markButton, isLight) => ({
    fontSize: '14px !important',
    color: `${markButton ? '#54D62C' : isLight ? '#212B36' : '#fff'}`,
    marginLeft: '10px'
});

export const methodMAinBox = (reminderError) => ({
    m: '16px 0px',
    cursor: 'pointer',
    border: reminderError ? '1px solid red' : 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    '@media(max-width: 500px)': {
        flexWrap: 'wrap'
    }
});

export const leadAutoComplete = {
    marginBottom: 2,
    '.MuiInputBase-root:hover:before': {
        borderBottom: '1px solid rgba(145, 158, 171, 0.32) !important'
    },
    '.MuiAutocomplete-input': {
        pl: '0px !important'
    },
    '.MuiAutocomplete-endAdornment': {
        display: 'none'
    }
};
export const searchIconStyle = (isLight) => ({
    color: isLight ? 'black' : '#637381',
    width: 20,
    height: 20,
    ml: '5px'
});

export const taskGroupStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};
export const addReminderHeaderBoxStyle = {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    boxShadow: '0px -1px 0px 0px #919EAB3D inset', 
    p: '0 24px 24px'
};