import PropTypes from 'prop-types';
// libraries
import {
    Grid,
    Dialog,
    Container,
    Stack,
    Box,
    Typography,
    Divider,
    Paper,
} from '@mui/material';
// components
import CampaignNoData from '../../sections/@dashboard/message/CampaignNoData';
import { Transition } from '.';
// style
import {
    sendCampaignDialogStyle,
    reminderStackStyle,
    eventBoxStyle,
    subtitleTypoStyle,
    bodyTypoStyle
} from '../../styles/AddReminderPopup';

const ReportCampaignPopup = ({ open, onClose, campaignReportData }) => (
    <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={onClose}
        sx={sendCampaignDialogStyle}
    >
        <Stack sx={{ ...reminderStackStyle, pb: 0 }}>
            <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 0 }}>
                        <Typography sx={{ fontSize: '18px', textAlign: 'center', fontWeight: '700' }}>Campaign report</Typography>
                    </Box>
                    <Divider sx={{ p: '23px 0 0' }} />
                    <Box sx={{ pt: '24px' }}>
                        {campaignReportData && campaignReportData?.length === 0 &&
                                <CampaignNoData isNotFound={campaignReportData?.length <= 0} type={'campaignReport'} />
                        }
                        {campaignReportData && campaignReportData?.length > 0 && campaignReportData.map((item, index) => (
                            <Paper key={index} elevation={3} style={{ padding: '0', margin: '0' }}>
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Box sx={eventBoxStyle} spacing={2}>
                                        <Typography variant='subtitle1' sx={subtitleTypoStyle}>{item?.event}</Typography>
                                        <Typography variant='body1' sx={bodyTypoStyle}>Event</Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'center', px: 1, width: '100%' }} spacing={2}>
                                        <Typography variant='subtitle1' sx={subtitleTypoStyle}>{item?.count}</Typography>
                                        <Typography variant='body1' sx={bodyTypoStyle}>Count</Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                </Container>
            </Grid>
        </Stack>
    </Dialog>
);

export default ReportCampaignPopup;

ReportCampaignPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    campaignReportData: PropTypes.object,
};