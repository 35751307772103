export const audienceCsvColumns = [
    {
        width: 70,
        label: 'Checked',
        dataKey: 'checked',
    },
    {
        width: 100,
        label: 'Title',
        dataKey: 'title',
    },
    {
        width: 120,
        label: 'First Name',
        dataKey: 'firstname',
    },
    {
        width: 120,
        label: 'Last Name',
        dataKey: 'lastname',
    },
    {
        width: 200,
        label: 'Email',
        dataKey: 'email',
    },
    {
        width: 120,
        label: 'Phone',
        dataKey: 'phone',
    },
    {
        width: 120,
        label: 'Date of birth',
        dataKey: 'dob',
    }
];

export const tableHeadLabel = [
    { id: 'name', label: 'Audience name', align: 'left' },
    { id: 'validation_status', label: 'Validation Status', align: 'left' },
    { id: 'validation_result', label: 'Validation Result', align: 'left' },
    { id: 'conditions', label: 'Conditions', align: 'left' },
    { id: 'contacts', label: 'Contacts', align: 'left' },
    { id: '' },
];

export const existingTableHeadLabel = [
    { id: '' },
    { id: 'name', label: 'Audience name', align: 'left' },
    { id: 'validation_status', label: 'Validation Status', align: 'left' },
    { id: 'validation_result', label: 'Validation Result', align: 'left' },
    { id: 'conditions', label: 'Conditions', align: 'left' },
    { id: 'contacts', label: 'Contacts', align: 'left' },
    { id: '' },
];