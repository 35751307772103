import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Typography, Tooltip, Zoom, IconButton } from '@mui/material';
// components
import { Iconify } from '../../../../components';
// utils
import { getTextColor } from '../../../../utils/getClassName';
// style
import { treatmentNameCellTypoStyle } from '../../../../styles/LeadTableStyle';
import { tableCellInnerBoxStyle, treatmentNameStyle } from '../../../../styles/NewLeadTableRowStyle';

const CustomTreatmentNameCell = forwardRef(({ params, handleTreatmentClick }, ref) => {
    const expandedColumns = ref.current.getExpandedDetailPanels();
    const hasDetail = params?.row?.treatmentData?.length > 1;
    const isExpanded = expandedColumns?.includes(params.id);

    const handleMultiTreatmentToggle = (e) => {
        e.stopPropagation();
        ref.current.toggleDetailPanel(params.id);
    };

    return <>
        <Tooltip
            title={params?.row?.treatmentData?.map((el) => `${el.treatment} ${el?.leadtreatment_specification?.teeth_count
                ? `(x${el?.leadtreatment_specification?.teeth_count})` : ''}`).join(', ')}
            placement="bottom"
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 300 }}
        >
            <Box onClick={(e) => handleTreatmentClick(e, params?.row)} sx={tableCellInnerBoxStyle}>
                {params?.row?.treatmentIcon && (
                    <Iconify
                        icon={params?.row?.treatmentIcon || 'lets-icons:star'}
                        width={24}
                        height={24}
                        sx={{ marginRight: '8px', color: getTextColor(params?.row?.treatmentBgColor), display: 'none' }}
                    />
                )}
                <Typography
                    variant="body2"
                    sx={{ ...treatmentNameCellTypoStyle, color: getTextColor(params?.row?.treatmentBgColor) }}
                >
                    <span style={treatmentNameStyle(params?.row?.treatmentData?.length > 1)}>
                        {params?.row?.treatment_name || ''}
                        {'  '}
                        {params?.row?.leadTreatmentSpecification?.teeth_count ? `(x${params?.row?.leadTreatmentSpecification?.teeth_count})` : ''}
                    </span>
                    {params?.row?.treatmentData?.length > 1 ? (
                        <span style={{ marginLeft: '8px' }}>{`+${params?.row?.treatmentData?.length - 1}`}</span>
                    ) : (
                        ''
                    )}
                </Typography>
                {hasDetail && <IconButton
                    size="small"
                    tabIndex={-1}
                    onClick={handleMultiTreatmentToggle}
                >
                    <Iconify icon={'ooui:expand'}
                        sx={{
                            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                            transition: (theme) =>
                                theme.transitions.create('transform', {
                                    duration: theme.transitions.duration.shortest,
                                }),
                            color: getTextColor(params?.row?.treatmentBgColor)
                        }} width={14} height={14} />
                </IconButton>}
            </Box>
        </Tooltip>
    </>;
});

export default CustomTreatmentNameCell;

CustomTreatmentNameCell.propTypes = {
    params: PropTypes.any,
    handleTreatmentClick: PropTypes.func,
};
