import { createContext } from 'react';

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const AuthContext = createContext({
    ...initialState,
    method: 'jwt',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    confirmemail: () => Promise.resolve(),
});

export { AuthContext };
