import { common, grey, primary } from 'src/theme/palette';

export const reminderStackStyle = {
    padding: '12px',
    width: '100% ',
    maxWidth: '98vw',
    maxHeight: 'calc(100vh - 150px)',
    overflow: 'auto'
};
export const treatmentFooterStyle = {
    left: '0',
    right: '0',
    bottom: '0',
    padding: '15px 24px',
    paddingBottom: '24px',
    width: '100%',
    zIndex: '99',
    textAlign: 'right',
    position: 'relative',
    p: '16px 16px !important'
};
export const cancelButtonStyle = {
    margin: '0px 15px',
    fontSize: '14px',
    boxShadow: 'none'
};
export const saveButtonStyle = {
    padding: '6px 28px',
    fontSize: '14px',
    boxShadow: common.jade
};
export const treatmentBoxStyle = {
    bgcolor: grey[0],
    zIndex: '9999999',
    position: 'relative',
    borderRadius: '12px',
    boxShadow: `-20px 20px 40px -4px ${primary.paperColor}`,
    border: 'none',
    width: '300px'
};
export const searchTeamTextfieldStyle = {
    width: '100%',
    bgcolor: 'common.lightGrey',
    borderRadius: 2,
    '.MuiInputBase-input': {
        p: '14px 14px 14px 0'
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRadius: 2
    }
};
export const teethAmountfieldStyle = {
    width: '100%',
    borderRadius: 1,
    '.MuiInputBase-input': {
        p: '9px 12px 9px'
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRadius: 1
    }
};
export const teethAmountBoxStyle = {
    margin: '8px 0',
    width: '100%',
    border: 'solid 2px',
    borderRadius: 1,
    padding: '0 8px 0',
    bgcolor: 'common.lightGrey',
};
export const customTextFieldStyle = { display: 'flex', alignItems: 'center', mb: '6px' };
export const customTextFieldStartIconStyle = { color: 'text.disabled', width: 20, height: 20 };
export const paperZIndex = { zIndex: '999999' };
export const borderRadius = { borderRadius: 2 };
export const paperGridStyle = { margin: 'auto', width: '100%' };
export const paperGridInnerContainerStyle = { padding: '0 !important' };
export const paperGridInnerBoxStyle = { width: '100%', pt: '0', textAlign: 'left' };

export const reminderFooterStyle = {
    left: '0',
    right: '0',
    position: 'absolute',
    bottom: '0',
    padding: '15px 24px',
    paddingBottom: '24px',
    width: '100%',
    zIndex: '99',
    textAlign: 'right'
};