export const COLOR_OPTIONS = [
    '#00AB55', // theme.palette.primary.main,
    '#1890FF', // theme.palette.info.main,
    '#54D62C', // theme.palette.success.main,
    '#FFC107', // theme.palette.warning.main,
    '#FF4842', // theme.palette.error.main
    '#04297A', // theme.palette.info.darker
    '#7A0C2E', // theme.palette.error.darker
];

export const VIEW_OPTIONS = [
    { value: 'dayGridMonth', label: 'Month', icon: 'ic:round-view-module' },
    { value: 'timeGridWeek', label: 'Week', icon: 'ic:round-view-week' },
    { value: 'timeGridDay', label: 'Day', icon: 'ic:round-view-day' },
    { value: 'listWeek', label: 'Agenda', icon: 'ic:round-view-agenda' },
];