// ----------------------------------------------------------------------

export function localStorageAvailable() {
    try {
        const key = '__some_random_key_you_are_not_going_to_use__';
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}

export function localStorageGetItem(key, defaultValue = '') {
    const storageAvailable = localStorageAvailable();

    let value;

    if (storageAvailable) {
        value = localStorage.getItem(key) || defaultValue;
    }

    return value;
}

export function localStorageSetItem(key, value = '') {
    const storageAvailable = localStorageAvailable();

    if (storageAvailable && key) {
        localStorage.setItem(key, value);
    }

}

export function localStorageRemoveItem(key) {
    const storageAvailable = localStorageAvailable();

    if (storageAvailable) {
        localStorage.removeItem(key);
    }
}
export function getParsedData(key) {
    let value;
    const data = localStorageGetItem(key);
    if (data) {
        value = JSON.parse(data);
    }
    return value;
}
