import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card, Box, Typography, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// components
import { Iconify } from '../../../components';
import { FormProvider } from '../../../components/hook-form';
// redux
import { dispatch } from '../../../redux/store';
import { changeUserDetails } from '../../../redux/slices/practiceUser';
// constants
import { days } from '../../../constants/openHours';
import { openHoursBoxStyle, openHoursStackStyle } from '../../../styles/OpenHours';
import { clearButton } from '../../../styles/EmailMessageComposeStyle';

// ----------------------------------------------------------------------

export default function OpenHours({ hoursList, practiceId }) {
    const [hoursData, setHoursData] = useState(days);

    const defaultValues = {
        hoursData
    };

    const methods = useForm({
        defaultValues,
    });

    /**
	 * Update open hours data
	 */
    useEffect(() => {
        ClearOpenHours();
        if (hoursList.length > 0) {
            const updatedHours = [];
            hoursData.forEach((el) => {
                const data = hoursList.find(hour => hour.day === el.day);
                if (data) {
                    const startDate = data.start_time.split(':');
                    const endDate = data.end_time.split(':');
                    el.id = data.id;
                    el.start_time = moment().set({ 'hours': Number(startDate[0]), 'minutes': Number(startDate[1]), 'second': startDate[2]}).toString();
                    el.end_time = moment().set({ 'hours': Number(endDate[0]), 'minutes': Number(endDate[1]), 'second': endDate[2]}).toString();
                } else {
                    el.start_time = null;
                    el.end_time = null;
                }
                updatedHours.push(el);
            });
            setHoursData(updatedHours);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoursList]);

    /**
	 * Clear Open hours data
	 */
    const ClearOpenHours = () => {
        const data = [];
        hoursData.forEach((el) => {
            data.push({
                ...el,
                start_time: null,
                end_time: null
            });
        });
        setHoursData(data);
    };

    /**
	 * Open hours data mobile timepicker change event
	 */
    const changeHours = (event, index, key) => {
        const selected = hoursData[index];
        selected[key] = event;
        hoursData[index] = selected;
        setHoursData([
            ...hoursData
        ]);
    };

    /**
	 * open Hours data submit to practice user
	 */
    const onSubmit = () => {
        const data = [];
        hoursData.forEach((el => {
            if (el.start_time && el.end_time) {
                const detail = {
                    day: el.day,
                    start_time: moment(el.start_time).format('HH:mm:ss'),
                    end_time: moment(el.end_time).format('HH:mm:ss')
                };
                if (el.id) {
                    detail.id = el.id;
                }
                data.push(detail);
            }
        }));
        dispatch(changeUserDetails({opening_hours: data}, practiceId, 'openHours'));
    };
    /**
	 * Selected Row start_date and end_date value clear.
	 * @param index Selected day data index.
	 */
    const deleteRow = (index) => {
        const selected = hoursData[index];
        selected.start_time = null;
        selected.end_time = null;
        hoursData[index] = selected;
        setHoursData([
            ...hoursData
        ]);
    };
    const {
        formState: { isSubmitting },
        handleSubmit
    } = methods;

    return (
        <Card sx={{ p: 3, mt: 4 }}>
            {hoursData.length === 0 ? 
                <Typography>No Data</Typography> 
                :
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={openHoursBoxStyle}
                    >
                        {hoursData.map((item, index) => (
                            <Stack
                                sx={openHoursStackStyle}
                                alignItems='center'
                                key={index}
                            >
                                <Typography>{item.day}</Typography>
                                <TimePicker
                                    label="Opens"
                                    value={(item.start_time !== null && new Date(item.start_time) )  || null}
                                    onChange={(e) => changeHours(e, index, 'start_time')}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            margin: 'normal',
                                            onKeyDown: (e) => e.preventDefault(),
                                        },
                                    }}
                                />
                                <TimePicker
                                    label="Closes"
                                    value={(item.end_time !== null && new Date(item.end_time)) || null}
                                    onChange={(e) => changeHours(e, index, 'end_time')}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            margin: 'normal',
                                            onKeyDown: (e) => e.preventDefault(),
                                        },
                                    }}
                                />
                                <IconButton
                                    style={{ cursor: 'pointer', display: 'inline' }}
                                    onClick={() => deleteRow(index)}
                                    sx={{ ...clearButton, width: 30, ml: { md: 4 } }}
                                >
                                    <Iconify icon={'solar:trash-bin-trash-bold'} />
                                </IconButton>
                            </Stack>
                        ))}
                    </Box>
                    <Stack
                        sx={{
                            mt: 3,
                            columnGap: 2,
                            rowGap: 3,
                        }}
                        alignItems="flex-end"
                    >
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
						Save Changes
                        </LoadingButton>
                    </Stack>
                </FormProvider>
            }
        </Card>
    );
}

OpenHours.propTypes = {
    hoursList: PropTypes.array,
    practiceId: PropTypes.any,
};