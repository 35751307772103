// 'newLeadLabels' - It used in different files, please check before update.
// if any column have sorting functionality then update the column id

export const newLeadLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'created_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];

export const AllLeadLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'created_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'status', label: 'Status', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];

export const LeadLabelsForReports = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'created_at' },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name' },
    { id: 'email', label: 'Email', align: 'left', sort: '' },
    { id: 'phone', label: 'Phone', align: 'left', sort: '' },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '' },
    { id: 'status', label: 'Status', align: 'left', sort: '' },
];

export const archiveLeadLabels = [
    { id: 'archived_at', label: 'Date of archived', align: 'left', sort: '', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'status', label: 'Status', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'reason', label: 'Reason', align: 'left', sort: '', show: true },
    { id: 'archivedBy', label: 'Archived by', align: 'left', sort: '', show: true },
    { id: 'open', label: 'Open to communication', align: 'left', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];

export const consultantNewAttendLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'updated_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'date_attended', label: 'Date of attended', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'deposit_pending', label: 'Deposit Pending', align: 'left', sort: '', show: true },
    { id: 'app_method', label: 'Appt method', align: 'left', sort: '', show: true },
    { id: 'app_type', label: 'Appt type', align: 'left', sort: '', show: true },
    { id: 'booked_with', label: 'Booked with', align: 'left', sort: '', show: true },
    { id: 'treatment_plan', label: 'Treatment Plan', align: 'center', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];

export const consultantClinCheckAttendLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'updated_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'date_attended', label: 'Date of attended', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'deposit_pending', label: 'Deposit Pending', align: 'left', sort: '', show: true },
    { id: 'clin_check_status', label: 'ClinCheck Status', align: 'left', sort: '', show: true },
    { id: 'app_method', label: 'Appt method', align: 'left', sort: '', show: true },
    { id: 'app_type', label: 'Appt type', align: 'left', sort: '', show: true },
    { id: 'booked_with', label: 'Booked with', align: 'left', sort: '', show: true },
    { id: 'treatment_plan', label: 'Treatment Plan', align: 'center', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];

export const consultantBookedLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'updated_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'lead_treatments__appointments__datetime', label: 'Date of appointment', align: 'left', sort: '',dateFormat: true, show: true },
    { id: 'deposit_pending', label: 'Deposit Pending', align: 'left', sort: '', show: true },
    { id: 'app_method', label: 'Appt method', align: 'left', sort: '', show: true },
    { id: 'app_type', label: 'Appt type', align: 'left', sort: '', show: true },
    { id: 'booked_with', label: 'Booked with', align: 'left', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];

export const treatmentStartedLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'updated_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'consultation_date', label: 'Consultation Date', align: 'left', sort: '', show: true },
    { id: 'consultation_booked_with', label: 'Consultation Booked With', align: 'left', sort: '', show: true },
    { id: 'lead_treatments__appointments__datetime', label: 'Date of appointment', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'booked_with', label: 'Booked with', align: 'left', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'transition_date', label: 'Transition Date', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];


export const failedToAttendLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'updated_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '', dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];


export const closedLabels = [
    { id: 'created_at', label: 'Date of enquiry', align: 'left', sort: 'updated_at', dateFormat: true, checkbox: true, show: true },
    { id: 'first_name', label: 'Name', align: 'left', sort: 'first_name', show: true },
    { id: 'email', label: 'Email', align: 'left', sort: '', show: true },
    { id: 'phone', label: 'Phone', align: 'left', sort: '', show: true },
    { id: 'treatment', label: 'Treatment', align: 'left', sort: '', show: true },
    { id: 'referral', label: 'Referral', align: 'left', sort: '', show: true },
    { id: 'contact', label: 'Contact', align: 'left', sort: '', show: true },
    { id: 'lastContact', label: 'Last contacted', align: 'left', sort: '',dateFormat: true, show: true },
    { id: 'assignee', label: 'Assignee', align: 'center', sort: '', show: true },
    { id: 'tag', label: 'Tags', align: 'left', sort: '', show: true },
    { id: '', show: true, columns: true },
];

export const depositPending = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
];

export const multiTreatmentStatus = 'NEW,CONSULTATION_BOOKED,CONSULTATION_ATTENDED,TREATMENT_STARTED,FAILED_TO_ATTEND_CONSULTATION';