import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { RHFSelect } from '../hook-form';
import CustomRadioGroupComponent from './CustomRadioGroupComponent';
import CustomLeadInquiryFields from './CustomLeadInquiryFields';
import { menuItemStyle } from '../../styles/PersonalDetailsStyle';
import { descriptionStyle, descriptionLabelStyle, nameTypoStyle } from '../../styles/CallScripts';

const CustomChoiceFieldComponent = ({ data, methods, values, handleChange, handleReferral, referralValue }) => {
    if (data?.field_type?.field_type !== 'choice') return null;

    const handleChangeCheckBox = (e, field, el) => {
        const newValue = e.target.checked ? [...field.value, el] : field.value.filter((value) => value !== el);
        field.onChange(newValue);
    };

    return <>
        {data.description !== '' &&
            <Box sx={descriptionStyle}>
                <FormLabel sx={descriptionLabelStyle}>
                    {data.description}
                </FormLabel>
            </Box>
        }
        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
        </Typography>}
        {data?.field_type?.attribute !== 'lead_source_type' &&
            <>
                {data?.field_type?.ui_type === 'DROPDOWN' && <RHFSelect
                    name={`checkbox_${[data.id]}`}
                    label='Select dropdown'
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                    {(data?.field_type?.choices || data?.choices)?.map((option, index) => (
                        <MenuItem
                            key={index}
                            value={option}
                            sx={menuItemStyle}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </RHFSelect>}
                {data?.field_type?.ui_type === 'MULTI_SELECT' &&
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id='demo-multiple-name-label'>Multi select dropdown</InputLabel>
                        <Controller
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <Select
                                        labelId='demo-multiple-name-label'
                                        id='demo-multiple-name'
                                        name={`multiCheckbox_${[data.id]}`}
                                        multiple
                                        sx={{ width: '100%' }}
                                        input={<OutlinedInput label='Multi select dropdown' />}
                                        value={field.value || []}
                                        onChange={(event) => field.onChange(event.target.value)}
                                    >
                                        {(data?.field_type?.choices || data?.choices)?.map((options, index) => (
                                            <MenuItem
                                                key={index}
                                                value={options}
                                            >
                                                {options}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {(!!error) && (
                                        <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                                    )}
                                </>
                            )}
                            control={methods.control}
                            name={`multiCheckbox_${[data.id]}`}
                        />
                    </FormControl>
                }
                {data?.field_type?.ui_type === 'CHECKBOX' && (
                    <>
                        {data?.field_type?.attribute !== 'lead_is_registered_patient' && <Controller
                            render={({ field, fieldState: { error } }) => (
                                <FormGroup>
                                    {(data?.field_type?.choices || data?.choices)?.map((el, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    name={`typeCheckbox_${[data.id]}_${index}`}
                                                    checked={field.value.includes(el)}
                                                    onChange={(e) => handleChangeCheckBox(e, field, el)}
                                                    value={el}
                                                />
                                            }
                                            label={el}
                                            sx={{ width: 'calc(50% - 16px)', display: 'flex', '.MuiTypography-root': { width: '100%' } }}
                                        />
                                    ))}
                                    {(!!error) && (
                                        <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                                    )}
                                </FormGroup>
                            )}
                            control={methods.control}
                            name={`typeCheckbox_${[data.id]}`}
                            defaultValue={[]}
                        />}
                        {data?.field_type?.attribute === 'lead_is_registered_patient' &&
                            <FormGroup sx={{ gap: 2 }}>
                                <FormControlLabel
                                    key={1}
                                    control={
                                        <Controller
                                            control={methods.control}
                                            name={'isRegister'}
                                            render={({ field }) => (
                                                <Checkbox
                                                    onChange={e => field.onChange(e.target.checked)}
                                                    checked={field.value}
                                                />
                                            )}
                                        />
                                    }
                                    label={'Is registered patient'}
                                    sx={{ width: 'fit-content' }}
                                />
                            </FormGroup>
                        }
                    </>
                )}
                <CustomRadioGroupComponent methods={methods} data={data} />
            </>
        }
        <CustomLeadInquiryFields values={values} data={data} handleChange={handleChange} handleReferral={handleReferral} referralValue={referralValue} />
    </>;
};

export default CustomChoiceFieldComponent;

CustomChoiceFieldComponent.propTypes = {
    data: PropTypes.object,
    methods: PropTypes.object,
    control: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleReferral: PropTypes.func,
    referralValue: PropTypes.func,
};