import React from 'react';
import PropTypes from 'prop-types';
// mui
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
// style
import { cancelBtnStyle, yesBtnStyle } from '../../../../styles/NewLead';

const CustomConfirmationDialog = ({
    toggle,
    handleClose,
    handleSubmit,
    message,
    style = {},
    dialogRef,
    cancelBtn = 'Cancel',
    saveBtn = 'Yes',
}) => (
    <Dialog open={toggle} onClose={handleClose} ref={dialogRef} sx={{ ...style }}>
        <DialogTitle sx={{ padding: '40px 40px 0px', textAlign: 'center' }}>{message}</DialogTitle>
        <DialogActions sx={{ padding: '40px !important', justifyContent: 'center' }}>
            <Button sx={cancelBtnStyle} onClick={handleClose}>
                {cancelBtn}
            </Button>
            <Button type="submit" sx={yesBtnStyle} onClick={handleSubmit}>
                {saveBtn}
            </Button>
        </DialogActions>
    </Dialog>
);

export default CustomConfirmationDialog;

CustomConfirmationDialog.propTypes = {
    toggle: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    message: PropTypes.string,
    style: PropTypes.any,
    cancelBtn: PropTypes.string,
    saveBtn: PropTypes.string,
    dialogRef: PropTypes.any,
};
