const getTreatmentList = (list) => list?.length > 0 ? [...new Set(list.filter(el => el?.name))] : [];

const FilterTreatmentList = (treatmentList) => {
    const list = getTreatmentList(treatmentList);
    const data = list?.map((el) => ({
        name: el?.name,
        id: el?.id,
        treatment_item_id: el?.id
    })) || [];
    return data?.sort((a, b) => {
        const textA = a?.name?.toUpperCase();
        const textB = b?.name?.toUpperCase();
        return textA.localeCompare(textB);
    });;
};

export default FilterTreatmentList;

