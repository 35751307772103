import { useSelector } from 'src/redux/store';
import useAuth from './useAuth';

export default function useIsSuperAdmin() {
    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const { user } = useAuth();

    const isSuperAdminUser = () => {
        if (results.length > 0) {
            const admin = results.find(el => el.user && el.user.id === user.pk);
            return admin?.user?.is_superuser;
        }
        return false;
    };

    return {
        isSuperAdminUser
    };
}
