/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
// form
import { useForm } from 'react-hook-form';
// @mui
import {
    Stack,
    Card,
    Paper,
    Box,
    Avatar,
    Typography,
    Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
// components
import { listHeaderBoxStyle } from 'src/styles/ContactLeadPopup';
import { Iconify, Label } from 'src/components';
import { activityCard, statusStyle } from 'src/styles/OverView';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/hooks';
import { dispatch } from 'src/redux/store';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { avatarBox, avatarStyle, box1Style, box2Style, box3Style, dateTimeUI, labelStyle, noteTextFieldStyle, paperStyle, treatmentBoxStyle } from 'src/styles/AccountSocialLinks';
import capitalizeFirstLetter from 'src/utils/capitalizedFunc';
import { TAB_HEAD } from 'src/constants/LeadDetailConst';
import { addNotes } from 'src/redux/slices/lead';
import { AccountSocialLinkSchema } from 'src/validations/validations';


// ----------------------------------------------------------------------

const NotesTab = ({ LeadNotes, isForTab, userDetails, handleChange }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { leadDetail, followLead } = useSelector((state) => state.lead);
    const { id } = useParams();
    const { user } = useAuth();

    const defaultValues = {
        addNotes: '',
        leadNotes: ''
    };

    const methods = useForm({
        resolver: yupResolver(AccountSocialLinkSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        getValues,
        setValue
    } = methods;

    const onSubmit = async () => {
        const Details = {
            lead: isForTab ? userDetails?.id?.toString() : id,
            additional_notes: getValues('addNotes')
        };
        if (leadDetail?.lead_treatments?.length > 0) {
            Details.treatment = leadDetail?.lead_treatments[0]?.id;
        }
        else if (isForTab && followLead?.lead_treatments?.length > 0) {
            Details.treatment = followLead?.lead_treatments[0]?.id;
        }
        dispatch(addNotes(Details, practiceId, '', 'leadNote'));
        setValue('addNotes', '');
    };

    const getUserName = (Name) => {
        if (Name?.created_by?.id === user?.pk) {
            return 'You commented';
        }
        if (Name?.lead_contact?.note) {
            return `${Name?.first_name} ${Name?.last_name}`;
        }
        return `${Name?.created_by?.first_name || ''} ${Name?.created_by?.last_name || ''}`;
    };

    const getStatus = (treatment) => TAB_HEAD.map((e1) => {
        let latestData = '';
        if (treatment?.status === e1.value) {
            latestData = e1.label;
        }
        return latestData;
    });

    return (
       <Box>
        <Box sx={listHeaderBoxStyle}>
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Notes</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Iconify icon={'zondicons:close-solid'} onClick={() => handleChange('', '')} sx={{ color: '#637381', mr: 0, cursor: 'pointer' }} width={24} height={24} />
                </Box>
            </Box>
         <Card sx={{...activityCard({data: {notAllow: true, type:"contactLeadPopup"}}),  overflow: 'auto', maxHeight: 'calc(100vh - 220px)', p:0, maxWidth:"100%",  boxShadow:"none" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} isForTab={isForTab}>
                <Stack spacing={0} alignItems='flex-start' sx={{ width: '100%' }}>

                    {LeadNotes && LeadNotes.length > 0 && LeadNotes.map((options, index) => (
                        <Box
                            key={index}
                            sx={{ ...box1Style,padding: "16px 24px 0", width: "100%"}} 
                        >
                            <Avatar
                                sx={{ height: '40px', width: '40px' }}
                                src={options?.created_by && options?.created_by?.avatar || ''}
                            />
                            <Box sx={box2Style(isLight)}>
                                <Box sx={box3Style}>
                                    <Paper
                                        sx={{
                                            background: isLight ? '#F4F6F8' : 'transparent',
                                            fontWeight: '600',
                                            fontSize: '14px'
                                        }}
                                        name={'leadTitle'}
                                    >
                                        {`${capitalizeFirstLetter(getUserName(options))}`}
                                    </Paper>
                                    {followLead?.lead_treatments?.length > 0 && <Button sx={statusStyle(followLead?.lead_treatments[0]?.status)}><Iconify icon={followLead?.lead_treatments[0]?.status === 'NEW' ? 'mdi:question-mark-circle-outline' : followLead?.lead_treatments[0]?.status === 'CONSULTATION_BOOKED' ? 'mdi:calendar-clock' : followLead?.lead_treatments[0]?.status === 'FAILED_TO_ATTEND_CONSULTATION' ? 'bx:calendar-x' : followLead?.lead_treatments[0]?.status === 'CONSULTATION_ATTENDED' ? 'mdi:calendar-check' : followLead?.lead_treatments[0]?.status === 'CLOSED' ? 'material-symbols:check-circle-outline' : followLead?.lead_treatments[0]?.status === 'TREATMENT_STARTED' ? 'codicon:debug-start' : 'fluent:cloud-sync-complete-24-filled'} width={15} height={15} /> {getStatus(followLead?.lead_treatments[0])}</Button>}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        marginTop:  "6px" 
                                    }}
                                >
                                    <Paper
                                        name='leadNotes'
                                        variant="outlined"
                                        sx={{ ...paperStyle(isLight), maxWidth: "75%" }}
                                    >
                                        {options?.additional_notes || options?.note || options?.lead_contact?.note || ''}
                                    </Paper>
                                    {
                                        (!options?.additional_notes) && <Label sx={{ color: 'black', backgroundColor: '#DFE3E8', p: '8px', marginTop: "0px" }} >
                                            Reminder Note
                                        </Label>
                                    }

                                </Box>
                                <Box sx={{ ...treatmentBoxStyle(leadDetail),  marginTop: "6px", justifyContent: "space-between", width: "100%" }} >
                                    {leadDetail?.lead_treatments?.length > 0 ? <Label sx={labelStyle}>
                                        {leadDetail?.lead_treatments[0]?.treatment}
                                    </Label> : (isForTab && followLead?.lead_treatments?.length > 0) ? <Label sx={labelStyle}>
                                        {followLead?.lead_treatments[0]?.treatment}
                                    </Label> : null}
                                    <Box sx={dateTimeUI} >
                                        {moment(options?.updated_at, 'DD/MM/YYYY HH:mm:ss').format('DD MMM YYYY [at] hh:mm a')}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    <Box sx={{ padding: isForTab ? "24px 24px 0" : "", width:  "100%", mb: "16px"}}>
                    <Box sx={avatarBox}>
                        <Avatar sx={avatarStyle} alt="J" src={user?.avatar || ''} />
                        <RHFTextField sx={noteTextFieldStyle} name='addNotes' multiline rows={3} label='Leave a note...' placeholder='' />
                    </Box>
                    <Box sx={{ marginLeft: '58px', mt: '20px'}}>
                        <Button type='submit' variant='contained' sx={{ float: 'right' }} >comment</Button>
                    </Box>
                </Box>
                </Stack>

            </FormProvider>
        </Card>
       </Box>
    );
};

NotesTab.propTypes = {
    LeadNotes: PropTypes.array,
    isForTab: PropTypes.bool,
    userDetails: PropTypes.any,
    handleChange: PropTypes.func
};

export default NotesTab;