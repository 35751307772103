import React from 'react';
import CustomLastNameCell from '../CustomLastNameCell';

const LastNameCell = () => ({
    field: 'last_name',
    headerName: 'Last name',
    minWidth: 100,
    editable: true,
    sortable: false,
    align: 'left',
    renderCell: (params) => (
        <CustomLastNameCell params={params} />
    ),
});

export default LastNameCell;