import * as Yup from 'yup';

export const chatBotFormSchema = Yup.object().shape({
    chatbotName: Yup.string().required('This field is required'),
    chatbot: Yup.array().of(
        Yup.object().shape({
            platform_treatments: Yup.array().of(
                Yup.object().shape({
                    treatment: Yup.string().nullable().required('This field is required'),
                    urls: Yup.array().of(
                        Yup.object().shape({
                            url: Yup.string().url('Please enter valid url').required('This field is required')
                        })
                    ),
                })
            )
        })
    )
});
export const chatBotNotesSchema = Yup.object().shape({
    chatbot_message: Yup.string().required('This field is required')
});
export const depositPaymentTypeSchema = Yup.object().shape({
    paymentMethod: Yup.string().required('This field is required'),
    depositAmount: Yup.number().required('This field is required').moreThan(0, 'Please enter valid value'),
    additionalAmount: Yup.number().when('paymentMethod', {
        is: (typed) => (typed === 'DEPOSIT_AND_GOCARDLESS' || typed === 'DEPOSIT_AND_STRIPE' || typed === 'DEPOSIT_AND_FINANCE'),
        then: Yup.number().required('This field is required').moreThan(0, 'Please enter valid value')
    }),
});
export const treatmentPlanValidationSchema = Yup.object().shape({
    handedOrEmailed: Yup.string(),
    treatment_plan_handed: Yup.mixed().when('handedOrEmailed', {
        is: (value) => value === 'treatment_plan_handed',
        then: Yup.mixed().nullable().required('Plan in person date is required')
    }),
    treatment_plan_emailed: Yup.mixed().when('handedOrEmailed', {
        is: (value) => value === 'treatment_plan_emailed',
        then: Yup.mixed().nullable().required('Plan emailed date is required')
    }),
});
export const financialSchema = Yup.object().shape({
    leadName: Yup.string().typeError('This field is required').required('This field is required.'),
    relation: Yup.string().typeError('This field is required').required('This field is required.'),
    deposit: Yup.string().nullable(true).required('This field is required.'),
    term: Yup.number().required('This field is required').typeError('This field is required'),
    loadSupplier: Yup.string().nullable(true).required('This field is required'),
});
export const bulkArchived = Yup.object().shape({
    archiveReason: Yup.string().when('typed', {
        is: (typed) => typed !== 'Restore',
        then: Yup.string().required('This field is required')
    }),
    notes: Yup.string().required('This field is required'),
});
export const ClinCheckSentFormSchema = Yup.object().shape({
    date_sent: Yup.mixed().nullable().required('Date is required'),
    who_sent: Yup.string().required('This field is required'),
});
export const treatmentPlanDateSchema = Yup.object().shape({
    treatmentPlanDate: Yup.string().nullable().required('Date is required'),
});
export const EmailValidation = Yup.object().shape({
    email: Yup.string().required('This field is required').email('Email must be a valid email address'),
});