export const phoneNumberCell = {
    field: 'phone',
    headerName: 'Phone',
    width: 140,
    minWidth: 120,
    editable: true,
    sortable: false,
    align: 'left',
};

export const actionColumnData = {
    field: 'actions',
    type: 'actions',
    headerName: 'More',
    width: 45,
    minWidth: 45,
    maxWidth: 45,
    align: 'center',
};

export const referralFieldColumnData = {
    field: 'referral',
    headerName: 'Referral',
    sortable: false,
    width: 110,
    minWidth: 80,
};
export const treatmentFieldColumnData = {
    field: 'treatment',
    headerName: 'Treatment',
    sortable: false,
    width: 170,
    minWidth: 170,
};

export const initialPaginationData = {
    pagination: {
        paginationModel: { page: 10, pageSize: 10 },
    }
};