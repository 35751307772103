import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { Iconify } from '../../../../../components';
import ConsultationDateField from '../ConsultationDateField';
import { FormProvider, RHFSelect } from '../../../../../components/hook-form';
import { DATE_TITLES } from '../../../../../constants/newLead';
import { dateWrapperStyle, selectFilter, selectMenuStyle } from '../../../../../styles/NewLead';

const ConsultationDateCell = ({
    typed,
    openAppointmentAssignTaskFuc,
    methods,
    setValue,
    handleChangeDateFormat,
    updateLeadPage,
    reloadCounter,
}) => ({
    field: 'consultation_date',
    headerName: (
        <>
            <Box sx={dateWrapperStyle}>
                Consultation date
                <Iconify sx={{ mr: '8px', ml: '8px' }} icon={'mdi:calendar-filter-outline'} width={15} height={15} />
                <Box sx={selectFilter}>
                    <FormProvider methods={methods}>
                        <RHFSelect
                            name={'consultation_date'}
                            label='Title'
                            SelectProps={{ native: false }}
                            onChange={(e) => handleChangeDateFormat(e, 'consultation_date', setValue)}
                        >
                            {DATE_TITLES.map((option, index) => (
                                <MenuItem key={index} value={option.value} sx={selectMenuStyle}>
                                    <Iconify sx={{ mr: '8px' }} icon={option.icon} width={18} height={18} />
                                    {option.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </FormProvider>
                </Box>
            </Box>
        </>
    ),
    width: 200,
    minWidth: 160,
    sortable: false,
    renderCell: (params) => (
        <ConsultationDateField
            rowData={params.row}
            typed={typed}
            updateLeadPage={updateLeadPage}
            reloadCounter={reloadCounter}
            tableType={typed}
            OpenAssignTask={openAppointmentAssignTaskFuc}
        />
    ),
});

export default ConsultationDateCell;
