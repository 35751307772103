export const RangeShortcut = {
    thisWeek: 'THIS_WEEK',
    today: 'TODAY',
    tomorrow: 'TOMORROW',
    lastWeek: 'LAST_WEEK',
    last7Days: 'LAST_7_DAYS',
    currentMonth: 'CURRENT_MONTH',
    prevMonth: 'PREV_MONTH',
    reset: 'RESET',
};

export const rangeShortcuts = [
    {
        range: RangeShortcut.today,
        label: 'Today',
    },
    {
        range: RangeShortcut.tomorrow,
        label: 'Tomorrow',
    },
    {
        range: RangeShortcut.allTime,
        label: 'All time',
    },
    {
        range: RangeShortcut.thisWeek,
        label: 'This week',
    },
    {
        range: RangeShortcut.lastWeek,
        label: 'Last week',
    },
    {
        range: RangeShortcut.last7Days,
        label: 'Last 7 days',
    },
    {
        range: RangeShortcut.currentMonth,
        label: 'Current month',
    },
    {
        range: RangeShortcut.prevMonth,
        label: 'Previous month',
    },
    {
        range: RangeShortcut.reset,
        label: 'Reset',
    },
];

export const ACCOUNT_TABS = ['All time', 'Last 24 Hours', 'Last 7 days', 'Last 30 days'];

export const endDate = new Date();
export const startDate = (addMonths) => (addMonths(new Date(), -12));