import capitalizeFirstLetter from './capitalizedFunc';

export const getAssignees = (row) => row?.assignees?.map((el) => `${el?.user?.salutation && capitalizeFirstLetter(el?.user?.salutation) || ''} ${el?.user?.first_name && capitalizeFirstLetter(el?.user?.first_name) || ''} ${el?.user?.last_name && capitalizeFirstLetter(el?.user?.last_name) || ''}`)?.toString();

export const getAssignUser = (assignees, practiceUser) => {
    const data = assignees && practiceUser?.results?.filter(e => assignees?.includes(e?.id))?.map((ele) => `${ele?.user?.salutation || ''} ${ele?.user?.first_name || ''} ${ele?.user?.last_name || ''}`);
    if (data?.length > 0) {
        return data;
    }
    return '';
};