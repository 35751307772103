/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
// libraries
import dayjs from 'dayjs';
import moment from 'moment';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Card, Box, Typography, TextField, List, ListItem, ListItemButton, ListItemText, Divider, Tabs, Tab, } from '@mui/material';
// components
import { NormalLoading } from './loading';
import CustomSummaryComponent from './CustomSummaryComponent';
// constant
import { RangeShortcut, ACCOUNT_TABS, endDate, rangeShortcuts } from '../constants/ReportSummaryConst';
import { treatmentCategory, reportLeadStatusesV2 } from '../constants/ReportsLeadConst';
// store
import { getPracticeTreatmentList } from '../redux/slices/practiceTreatment';
import { reportsLeadListing, setDateRange } from '../redux/slices/analytics';
import { useSelector, dispatch } from '../redux/store';
// hooks
import { useTabs } from '../hooks';
import { handleDateChange, handleReports, handleStatus, handleTabChange } from '../utils/reportsUtils';
// style
import {
    summaryBoxStyle,
    summaryCardStyle,
    mainSummaryCardStyle,
    summaryStackStyle,
    summaryLastStackStyle,
    orthodonticsPriceStyle,
    implantsPriceStyle,
    cosmeticPriceStyle,
    otherPriceStyle,
    greenColorLineStyle,
    yellowColorLineStyle,
    redColorLineStyle,
    blackColorLineStyle,
    dateRangePickerStyle,
    summaryStatusBoxStyle,
    leadBoxFirstStyle,
    leadBoxSecStyle,
    leadBoxThirdStyle,
    leadBoxFourStyle,
    leadBoxFiveStyle,
    statusIconStyle,
    statusCountStyle,
    statusTextStyle,
    summaryStatusBoxInnerStyle,
    leadBoxTreatmentStyle,
} from '../styles/ReportSummary';
import { common, widget } from '../theme/palette';

const ReportSummary = ({ type }) => {
    const [implantsData, setImplantsData] = useState([]);
    const [orthodonticsData, setOrthodonticsData] = useState([]);
    const [cosmeticData, setCosmeticData] = useState([]);
    const [dataIds, setDataIds] = useState([]);
    const [leadsBookedData, setLeadsBookedData] = useState([]);
    const [leadsAttendedData, setLeadsAttendedData] = useState([]);
    const [leadsNoLongerData, setLeadsNoLongerData] = useState([]);
    const [leadsTreatmentsData, setLeadsTreatmentsData] = useState([]);
    const [status, setStatus] = useState('');
    const currentDate = dayjs();
    const startDate = currentDate.startOf('month');
    const [value, setValue] = useState([startDate.toDate(), currentDate.toDate()]);
    const { currentTab, setCurrentTab } = useTabs('All time');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { results } = useSelector((state) => state.practiceTreatment?.practiceTreatment);
    const { archivedLeadReport, reportsAllLead, treatmentCategoryReports, reportsAllTreatments, treatmentStartedCount, reportAppointmentData } = useSelector((state) => state.analytics);
    const { practiceTreatment } = useSelector((state) => state.practiceTreatmentList);
    // const treatmentList = useSelector((state) => state.practiceTreatment.treatmentsList.results);

    const loader = treatmentCategoryReports === null || reportAppointmentData === null || archivedLeadReport === null || reportsAllLead === 0;

    useEffect(() => {
        dispatch(
            setDateRange({
                startDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                endDate: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            })
        );
    }, []);

    useEffect(() => {
        if (type) handleStatus({ type, setStatus });
    }, [type]);

    useEffect(() => {
        if (practiceId && practiceTreatment?.results?.length > 0) {
            handleReports({ type, callReportsAPI });
        }
    }, [practiceId, practiceTreatment]);

    useEffect(() => {
        if (status && practiceId) {
            const startTime = moment(new Date()).startOf('month').format('YYYY-MM-DD 00:00:00');
            const endTime = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
            callReportsAPI(treatmentCategory, startTime, endTime, status);
            dispatch(getPracticeTreatmentList(practiceId));
        }
    }, [status, practiceId]);

    useEffect(() => {
        if (results && treatmentCategoryReports?.data) {
            setImplantsData(treatmentCategoryReports?.data?.filter((el) => el.lead_treatments__treatment__treatment_item__treatment_category === results.filter((el) => el.treatment_item?.treatment_category?.name === 'Implants')[0]?.treatment_item?.treatment_category?.id));
            setOrthodonticsData(treatmentCategoryReports?.data.filter((el) => el.lead_treatments__treatment__treatment_item__treatment_category === results.filter((el) => el.treatment_item?.treatment_category?.name === 'Orthodontics')[0]?.treatment_item?.treatment_category?.id));
            setCosmeticData(treatmentCategoryReports?.data.filter((el) => el.lead_treatments__treatment__treatment_item__treatment_category === results.filter((el) => el.treatment_item?.treatment_category?.name === 'Cosmetic Dentistry')[0]?.treatment_item?.treatment_category?.id));
            setDataIds(treatmentCategoryReports?.data.filter((elem) => (elem.lead_treatments__treatment__treatment_item__treatment_category !== results.filter((el) => el.treatment_item?.treatment_category?.name === 'Implants')[0]?.treatment_item?.treatment_category?.id &&
                elem.lead_treatments__treatment__treatment_item__treatment_category !== results.filter((el) => el.treatment_item?.treatment_category?.name === 'Orthodontics')[0]?.treatment_item?.treatment_category?.id &&
                elem.lead_treatments__treatment__treatment_item__treatment_category !== results.filter((el) => el.treatment_item?.treatment_category?.name === 'Cosmetic Dentistry')[0]?.treatment_item?.treatment_category?.id)));
        }
    }, [results, treatmentCategoryReports?.data]);

    useEffect(() => {
        if (reportAppointmentData?.data?.length > 0) {
            setLeadsBookedData(reportAppointmentData?.data?.filter((ele) => (ele.status === 'ATTENDED' || ele.status === 'BOOKED'))?.reduce((total, currentValue) => {
                total += currentValue?.count_id;
                return total;
            }, 0) || 0);
            setLeadsAttendedData(reportAppointmentData?.data?.filter((ele) => (ele.status === 'ATTENDED'))?.reduce((total, currentValue) => {
                total += currentValue?.count_id;
                return total;
            }, 0) || 0);
        } else {
            setLeadsBookedData(0);
            setLeadsAttendedData(0);
        }
    }, [reportAppointmentData]);

    useEffect(() => {
        if (archivedLeadReport?.data) setLeadsNoLongerData(archivedLeadReport?.data?.filter((el) => el?.is_archived)[0]?.count_id);
    }, [archivedLeadReport]);

    useEffect(() => {
        if (reportsAllTreatments) setLeadsTreatmentsData(reportsAllTreatments?.data?.count_lead_treatments);
    }, [reportsAllTreatments]);

    const callReportsAPI = (typed, startTime, endTime, reportStatus) => {
        const payload = { practiceId, type: typed, startTime: startTime || '', endTime: endTime || '' };
        const data = reportStatus === 'categorySummary' ?
            { ...payload, category: treatmentCategory, categoryStatus: reportLeadStatusesV2, isCategoryTable: true } : { ...payload, reportStatus };
        dispatch(reportsLeadListing(data));
    };

    const buildHandleRangeClick = (setValue) => (range) => {
        const today = dayjs();
        switch (range) {
            case RangeShortcut.allTime:
                setValue([null, null]);
                dateChange([null, null]);
                break;
            case RangeShortcut.thisWeek:
                setValue([today.startOf('week'), today.endOf('week')]);
                dateChange([today.startOf('week'), today.endOf('week')]);
                break;
            case RangeShortcut.today:
                setValue([today.startOf('day'), today.endOf('day')]);
                dateChange([today.startOf('day'), today.endOf('day')]);
                break;
            case RangeShortcut.tomorrow:
                setValue([today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                dateChange([today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                break;
            case RangeShortcut.lastWeek:
                setValue([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                dateChange([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                break;
            case RangeShortcut.last7Days:
                setValue([today.subtract(1, 'week'), today]);
                dateChange([today.subtract(1, 'week'), today]);
                break;
            case RangeShortcut.currentMonth:
                setValue([today.startOf('month'), today.endOf('month')]);
                dateChange([today.startOf('month'), today.endOf('month')]);
                break;
            case RangeShortcut.prevMonth: {
                const dateValue = [today.subtract(1, 'months').startOf('month'), today.subtract(1, 'months').endOf('month')];
                setValue(dateValue);
                dateChange(dateValue);
            }
                break;
            case RangeShortcut.reset:
                setValue([null, null]);
                break;
            default:
                break;
        }
    };

    const RangeShortcutsPanel = ({ setValue, children }) => {
        const handleRangeClick = useCallback(
            (range) => setValue && buildHandleRangeClick(setValue)(range),
            [setValue],
        );
        return (
            <Box sx={{ display: 'flex', '@media(max-width: 900px)': { display: 'inline-block' } }}>
                <Box sx={{ m: 2 }} display='flex' gap={2}>
                    <div>
                        <Typography variant='overline'>Date range shortcuts</Typography>
                        <List>
                            {rangeShortcuts.map(({ range, label }) => (
                                <ListItem key={range} disablePadding>
                                    <ListItemButton onClick={() => handleRangeClick(range)}>
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <Divider orientation='vertical' />
                </Box>
                {children}
            </Box>
        );
    };

    const dateChange = (newValue) => {
        handleDateChange({ type, setValue, callReportsAPI, newValue });
    };

    const onChangeTab = (e, newValue) => {
        handleTabChange({ newValue, setValue, dateChange });
    };

    const handleReduce = (total, currentValue) => {
        total += currentValue?.count_id;
        return total;
    };

    const modifiedData = dataIds?.reduce(handleReduce, 0);

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
        onChangeTab(event, newValue);
    };

    return (
        <>
            {type === 'Reports' &&
                <Box sx={dateRangePickerStyle}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            onChange={(newValue) => dateChange(newValue)}
                            value={value}
                            inputFormat='DD MMM YYYY'
                            maxDate={endDate}
                            renderInput={(startProps, endProps) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
                                    <TextField {...startProps} onKeyDown={(evt) => evt.preventDefault()} label='Start date' />
                                    <Box sx={{ mx: 1 }}> to </Box>
                                    <TextField {...endProps} onKeyDown={(evt) => evt.preventDefault()} label='End date' />
                                </Box>
                            )}
                            components={{ PaperContent: RangeShortcutsPanel }}
                            PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
                            componentsProps={{ paperContent: { setValue } }}
                        />
                    </LocalizationProvider>
                </Box>
            }
            <Card sx={mainSummaryCardStyle(type)}>
                {(type === 'DashNewLeadTable' || type === 'Reports') &&
                    <>
                        <NormalLoading tableData={loader && 'No Found'} pageCount={loader && 'No Found'} />
                        <Box sx={summaryBoxStyle(type)}>
                            {type === 'DashNewLeadTable' &&
                                <Box sx={{ width: '100%' }}>
                                    <Tabs
                                        variant='scrollable'
                                        scrollButtons
                                        allowScrollButtonsMobile
                                        value={currentTab}
                                        onChange={handleChangeTab}
                                    >
                                        {ACCOUNT_TABS.map((tab, index) => (
                                            <Tab
                                                disableRipple
                                                key={index}
                                                label={tab}
                                                value={tab}
                                                sx={{ '&:not(:last-of-type)': { mr: '24px' } }}
                                            />
                                        ))}
                                    </Tabs>
                                </Box>
                            }
                        </Box>
                    </>
                }
                {(type === 'Reports' || type === 'DashNewLeadTable') &&
                    <Box sx={summaryStatusBoxStyle}>
                        <Box sx={summaryStatusBoxInnerStyle}>
                            <Box sx={leadBoxFirstStyle}>
                                <Box sx={{ ...statusIconStyle, background: 'linear-gradient(135deg, rgba(0, 123, 85, 0) 0%, rgba(0, 123, 85, 0.24) 97.35%)' }}>
                                    <img src='/assets/new.png' alt='icon1' style={{ maxHeight: '30px' }} />
                                </Box>
                                <Typography sx={{ ...statusCountStyle, color: common.mintyGreen }}>{reportsAllLead?.data?.count_id || 0}</Typography>
                                <Typography sx={{ ...statusTextStyle, color: common.mintyGreen }}>Leads received</Typography>
                            </Box>
                            <Box sx={leadBoxTreatmentStyle}>
                                <Box sx={{ ...statusIconStyle, background: 'linear-gradient(135deg, rgba(0, 57, 123, 0) 0%, rgba(0, 57, 123, 0.24) 97.35%)' }}>
                                    <img src='/assets/info.png' alt='icon1' style={{ maxHeight: '30px' }} />
                                </Box>
                                <Typography sx={{ ...statusCountStyle, color: widget.darkBlueBorder }}>{leadsTreatmentsData?.length === 0 ? 0 : leadsTreatmentsData}</Typography>
                                <Typography sx={{ ...statusTextStyle, color: widget.darkBlueBorder }}>Total treatments</Typography>
                            </Box>
                            <Box sx={leadBoxSecStyle}>
                                <Box sx={{ ...statusIconStyle, background: 'linear-gradient(135deg, rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 97.35%)' }}>
                                    <img src='/assets/comment.png' alt='icon1' style={{ maxHeight: '30px' }} />
                                </Box>
                                <Typography sx={{ ...statusCountStyle, color: widget.darkBlue }}>{leadsBookedData || 0}</Typography>
                                <Typography sx={{ ...statusTextStyle, color: widget.darkBlue }}>Leads have appointment booked </Typography>
                            </Box>
                            <Box sx={leadBoxThirdStyle}>
                                <Box sx={{ ...statusIconStyle, background: 'linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 97.35%)' }}>
                                    <img src='/assets/calendar.png' alt='icon1' style={{ maxHeight: '30px' }} />
                                </Box>
                                <Typography sx={{ ...statusCountStyle, color: common.goldenBrown }}>{leadsAttendedData || 0}</Typography>
                                <Typography sx={{ ...statusTextStyle, color: common.goldenBrown }}>Leads attended consultation</Typography>
                            </Box>
                            <Box sx={leadBoxFourStyle}>
                                <Box sx={{ ...statusIconStyle, background: 'linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 97.35%)' }}>
                                    <img src='/assets/teeth.png' alt='icon1' style={{ maxHeight: '30px' }} />
                                </Box>
                                <Typography sx={{ ...statusCountStyle, color: common.mediumSlateBlue }}>{treatmentStartedCount && treatmentStartedCount?.data && treatmentStartedCount?.data?.find(item => item.lead_treatments__state_transition_logs__state === 'TREATMENT_STARTED')?.count_id || 0}</Typography>
                                <Typography sx={{ ...statusTextStyle, color: common.mediumSlateBlue }}>Leads started treatment</Typography>
                            </Box>
                            <Box sx={leadBoxFiveStyle}>
                                <Box sx={{ ...statusIconStyle, background: 'linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 97.35%)' }}>
                                    <img src='/assets/cancel.png' alt='icon1' style={{ maxHeight: '30px' }} />
                                </Box>
                                <Typography sx={{ ...statusCountStyle, color: common.deepRed }}>{(leadsNoLongerData?.length === 0 || leadsNoLongerData === undefined) ? 0 : leadsNoLongerData}</Typography>
                                <Typography sx={{ ...statusTextStyle, color: common.deepRed }}>Leads are no longer interested</Typography>
                            </Box>
                        </Box>
                    </Box>
                }
                <Card sx={summaryCardStyle(type)}>
                    <CustomSummaryComponent
                        price={orthodonticsData[0]?.sum_lead_treatments__custom_price?.toLocaleString() || '0'}
                        priceStyle={orthodonticsPriceStyle}
                        count={orthodonticsData[0]?.count_id || 0}
                        label='Orthodontics'
                        progressStyle={greenColorLineStyle}
                        progressValue={orthodonticsData[0]?.count_id / treatmentCategoryReports?.data?.reduce(handleReduce, 0) * 100 || 0}
                        stackStyle={{ ...summaryStackStyle, pl: '0' }} />
                    <CustomSummaryComponent
                        price={implantsData[0]?.sum_lead_treatments__custom_price?.toLocaleString() || '0'}
                        priceStyle={implantsPriceStyle}
                        count={implantsData[0]?.count_id || 0}
                        label='Implants'
                        progressStyle={yellowColorLineStyle}
                        progressValue={implantsData[0]?.count_id / treatmentCategoryReports?.data?.reduce(handleReduce, 0) * 100 || 0} />
                    <CustomSummaryComponent
                        price={cosmeticData[0]?.sum_lead_treatments__custom_price?.toLocaleString() || '0'}
                        priceStyle={cosmeticPriceStyle}
                        count={cosmeticData[0]?.count_id || 0}
                        label='Cosmetic Dentistry'
                        progressStyle={redColorLineStyle}
                        progressValue={cosmeticData[0]?.count_id / treatmentCategoryReports?.data?.reduce(handleReduce, 0) * 100 || 0} />
                    <CustomSummaryComponent
                        price={dataIds?.reduce((total, currentValue) => { total += currentValue.sum_lead_treatments__custom_price; return total; }, 0)?.toLocaleString() || '0'}
                        priceStyle={otherPriceStyle}
                        count={modifiedData}
                        label='Other'
                        progressStyle={blackColorLineStyle}
                        progressValue={modifiedData / treatmentCategoryReports?.data?.reduce(handleReduce, 0) * 100 || 0}
                        stackStyle={summaryLastStackStyle} />
                </Card>
            </Card>
        </>
    );
};

ReportSummary.propTypes = {
    type: PropTypes.string
};

export default ReportSummary;