export const tableHeadStyle = (isLight) => ({
    ' .MuiTableCell-head': {
        boxShadow: 'none !important',
        minWidth: 'auto',
        backgroundImage: isLight ? 'linear-gradient(rgb(244, 246, 248) 0%, rgb(244, 246, 248) 100%)' : '',
        bgcolor: isLight ? 'rgb(255, 255, 255)' : ''
    }
});
export const importLeadCardStyle = {
    border: '1px dashed rgba(145, 158, 171, 0.32)',
    minHeight: '340px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexWrap: 'wrap'
};
export const sampleBoxStyle = {
    width: '100%',
    textAlign: 'center',
    maxWidth: '500px',
    mb: '20px'
};
export const fileImportBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    py: 3,
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.2)'
};
export const labelBoxStyle = {
    width: '50%',
    p: '16px 24px',
    display: 'flex',
    alignItems: 'center'
};
export const deleteBoxStyle = {
    lineHeight: 0,
    position: 'absolute',
    right: '-30px',
    top: '9px',
    cursor: 'pointer'
};
export const typoWrapper = { width: '50%', p: '16px 24px' };

export const customListStyle = { textAlign: 'center', fontSize: '16px', fontWeight: 700, mb: 1 };

export const fullWidthCenterDiv = { width: '100%', textAlign: 'center' };