import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem } from '@mui/material';
// components
import { useEffect, useState } from 'react';
import { Iconify } from '../../../../components';

export default function UserTableToolbar({ filterName, filterRole, onFilterName, onFilterRole, optionsRole }) {
    const [newCatArray, setNewCatArray] = useState([]);
    // eslint-disable-next-line prefer-const
    let newCatArr = ['all'];
    useEffect(() => {
        if(optionsRole) {
            optionsRole.map((item) => {
                newCatArr.push(item);
                setNewCatArray(newCatArr);
                return true;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsRole]);

    const uniqueCategoryName = Array.from(new Set(newCatArray));
    return (
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
            <TextField
                fullWidth
                select
                label="Category"
                value={filterRole}
                onChange={onFilterRole}
                SelectProps={{
                    MenuProps: {
                        sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                    },
                }}
                sx={{
                    maxWidth: { sm: 240 },
                    textTransform: 'capitalize',
                }}
            >
                {uniqueCategoryName.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option}
                        sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                fullWidth
                value={filterName}
                onChange={(event) => onFilterName(event.target.value)}
                placeholder="Search Treatments..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    ),
                }}
            />
        </Stack>
    );
}

UserTableToolbar.propTypes = {
    filterName: PropTypes.string,
    filterRole: PropTypes.string,
    onFilterName: PropTypes.func,
    onFilterRole: PropTypes.func,
    optionsRole: PropTypes.arrayOf(PropTypes.object),
};