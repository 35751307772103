/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import {
    Table,
    Box,
    TableBody,
    TableContainer,
} from '@mui/material';
//
import AnalyticsTableRow from './AnalyticsTableRow';
// components
import { TableHeadCustom, TableNoData } from '../../../../components/table';
import { TreatmentTimeRage } from '../../../../components/anlytics';
import { TableLoading } from '../../../../components/loading';
// constants
import { tableLabels } from '../../../../constants/analyticsTable';
// hooks
import useTable from '../../../../hooks/useTable';
// store
import { dispatch, useSelector } from '../../../../redux/store';
import { getTemplateList } from '../../../../redux/slices/practiceMessage';
import { treatmentSummaryTableStyle } from '../../../../styles/TreatmentSummaryTableView';

// ------------------------------------------------------------------

const TableView = ({ mapValues, type }) => {
    const [orderData, setOrderData] = useState(mapValues);
    const [isLoading, setIsLoading] = useState(true);
    const { treatmentSummary } = useSelector((state) => state.analytics);
    const treatmentList = useSelector((state) => state.practiceTreatment.treatmentsList.results);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const {
        order,
        onSort,
        orderBy,
    } = useTable({ defaultOrder: 'desc' });

    useEffect(() => {
        if (practiceId) {
            dispatch(getTemplateList(practiceId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    useEffect(() => {
        if (mapValues) {
            setOrderData(mapValues);
        }
    }, [mapValues]);

    useEffect(() => {
        if (treatmentSummary) {
            setIsLoading(false);
        }
        if (treatmentSummary === null && treatmentList && treatmentList?.length > 0) {
            setIsLoading(true);
        }
        if (treatmentList && treatmentList?.length === 0) {
            setIsLoading(false);
        }
    }, [treatmentSummary, treatmentList]);

    const handleSorting = (value) => {
        onSort(value);
        switch (value[0]) {
            case 'leads': {
                const Data = _.orderBy(orderData, [o => o?.data?.NEW?.actual], [order]);
                setOrderData(Data);
            }
                break;
            case 'year': {
                const Data = _.orderBy(orderData, [o => o?.year, o => o?.month], [order, order]);
                setOrderData(Data);
            }
                break;
            case 'consultation_target': {
                const Data = _.orderBy(orderData, [o => o?.data?.CONSULTATION_BOOKED?.target], [order]);
                setOrderData(Data);
            }
                break;
            case 'consultations': {
                const Data = _.orderBy(orderData, [o => o?.data?.CONSULTATION_BOOKED?.actual], [order]);
                setOrderData(Data);
            }
                break;
            case 'treatment_targets': {
                const Data = _.orderBy(orderData, [o => o?.data?.TREATMENT_STARTED?.target], [order]);
                setOrderData(Data);
            }
                break;
            case 'treatments': {
                const Data = _.orderBy(orderData, [o => o?.data?.TREATMENT_STARTED?.actual], [order]);
                setOrderData(Data);
            }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <TreatmentTimeRage type={type} isPrimaryView />
            <Box sx={{ boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)', borderRadius: '16px' }}>
                <TableContainer sx={treatmentSummaryTableStyle(type)}>
                    <Box sx={{ maxHeight: '450px', overflow: 'auto' }}>
                        <Table stickyHeader sx={{ '.MuiTableCell-root': { boxShadow: 'none !important', pl: '16px !important', borderBottom: '1px solid hwb(209deg 74% 13% / 50%) !important' } }} >
                            <TableHeadCustom
                                headLabel={tableLabels || []}
                                rowCount={10}
                                order={order}
                                orderBy={orderBy}
                                onSort={handleSorting}
                            />
                            <TableBody>
                                {isLoading && <TableLoading tableData={isLoading && 'No Found'} pageCount={isLoading && 'No Found'} />}
                                {mapValues && orderData?.map((el, index) => <AnalyticsTableRow data={el} key={index} />)}
                                {mapValues?.length === 0 && <TableNoData isNotFound={mapValues?.length === 0} />}
                            </TableBody>
                        </Table>
                    </Box>

                </TableContainer>
            </Box>
        </>
    );
};

TableView.propTypes = {
    mapValues: PropTypes.array,
    type: PropTypes.string,
};

export default TableView;
