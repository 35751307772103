import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// components
import { FormProvider, RHFTextField } from '../hook-form';
// constants/validation
import { inputKeys } from '../../constants/LeadDetailConst';
import { sendTestEmail } from '../../validations/validations';

const FormDialog = ({ open, data, handleClose }) => {

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const methods = useForm({
        resolver: yupResolver(sendTestEmail(data)),
    });

    const { handleSubmit, reset } = methods;

    const onSubmit = (data) => {
        handleClose('send', data);
        reset();
    };

    const handlePopupClose = () => {
        handleClose();
        reset();
    };

    const handleKeyDown = (evt) => {
        const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
        const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
        const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
        if (!(isControlC || isControlV || isAllowedKey)) {
            evt.preventDefault();
        }
    };

    return (
        <Dialog fullWidth maxWidth='xs' open={open} onClose={handlePopupClose}>
            <DialogTitle>Enter your valid {data === 'EMAIL' ? 'email address.' : 'phone number.'} </DialogTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {data === 'EMAIL' &&
                        <RHFTextField
                            autoFocus
                            margin='dense'
                            name='email'
                            id='email'
                            label='Email (Required)'
                            type='email'
                            fullWidth
                        />
                    }
                    {data === 'SMS' &&
                        <RHFTextField
                            autoFocus
                            name='phoneNumber'
                            type='string'
                            inputProps={{ maxLength: 15 }}
                            label='Phone number (Required)'
                            onKeyDown={handleKeyDown}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopupClose}>Close</Button>
                    <Button type='submit' variant='contained'>Send Test</Button>
                </DialogActions>
            </FormProvider>
        </Dialog>

    );
};

FormDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    data: PropTypes.string
};

export default FormDialog;