import { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
// redux
import { useSelector, dispatch } from '../redux/store';
import { updateError } from '../redux/slices/user';
import { saveNewCampaign } from '../redux/slices/campaign';
import MessageList from '../sections/@dashboard/message/MessageList';
// hooks
import { useAdminUser } from '../hooks';
import { FormProvider } from './hook-form';

const CampaignTemplate = forwardRef(({ handleNext }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [template, setTemplateList] = useState([]);
    const { templateDetails, userTemplateDetails } = useSelector((state) => state.practiceMessage);
    const [sequenceName, setSequenceName] = useState('');
    const { newCampaign, selectedCampaign } = useSelector((state) => state.campaign);
    const [selectedRow, setSelectedRow] = useState(newCampaign?.engagement_template || selectedCampaign?.engagement_template?.id || null);
    const { isAdminUser } = useAdminUser();

    useEffect(() => {
        if (templateDetails && templateDetails.results) {
            setTemplateList(templateDetails.results);
        }
    }, [templateDetails]);

    const defaultValues = {
        selectTemplate: 0,
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        handleSubmit,
    } = methods;

    const onSubmit = async () => {
        if (selectedRow && templateDetails && templateDetails?.results?.filter((el) => el?.id === selectedRow)?.length > 0) {
            dispatch(saveNewCampaign({ engagement_template: selectedRow }));
            handleNext(true);
        } else {
            dispatch(updateError({
                message: 'Please select any one template',
                status: false
            }));
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedRow, templateDetails]
    );

    const handleRowSelection = (rowId) => setSelectedRow(rowId);

    return (
        <FormProvider methods={methods}>
            <Box sx={{ mt: '50px' }}>
                <Typography variant='h6' sx={{ marginBottom: '24px', fontSize: '24px !important' }}>
                    Select a template for your campaign.
                </Typography>
                <Card>
                    <Typography sx={{ p: '24px 24px', fontSize: '18px', fontWeight: '700' }}>Templates</Typography>
                    <MessageList
                        messageData={template}
                        sequenceName={sequenceName}
                        setSequenceName={setSequenceName}
                        isAdmin={isAdminUser()}
                        practiceId={practiceId.toString()}
                        messageDetail={userTemplateDetails}
                        type={'Template'}
                        handleRowSelection={handleRowSelection}
                        selectedRow={selectedRow}
                        campaign
                    />
                </Card>
            </Box>
        </FormProvider>
    );
});

CampaignTemplate.propTypes = {
    handleNext: PropTypes.func,
};

export default CampaignTemplate;