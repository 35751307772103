import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Typography, Checkbox, Button } from '@mui/material';
import WhatsappMailCompose from 'src/sections/@dashboard/leadDetail/mail/whatsappMailCompose';
import Iconify from '../Iconify';
import { useSelector } from '../../redux/store';
import {
    sendEmailBoxStyle,
    sendEmailTitleStyle,
    emailSuccessBoxStyle,
    emailSuccessIconBoxStyle,
} from '../../styles/FailedToAttendTabs';

const SendWhatsappContent = ({ handleAccordian, whatsappChecked, whatsappNotification, whatsapp, whatsappMessageSuccess, flowUpdateFunc, setWhatsappNotification }) => {
    const { whatsappAccountData } = useSelector((state) => state.whatsappAccount);
    const navigate = useNavigate();

    const goToWhatsappPage = () => {
        flowUpdateFunc();
        navigate('/dashboard/whatsapp');
    };

    return (
        <>
            <Box>
                <Box onClick={() => handleAccordian('whatsapp')} sx={sendEmailBoxStyle}>
                    <Typography sx={sendEmailTitleStyle}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ fontSize: '18px', fontWeight: 700, ml: '-8px' }}>
                                <Checkbox checked={whatsappChecked} icon={<Iconify icon={'material-symbols:circle-outline'} width={24} height={24} />} checkedIcon={<Iconify icon={'bi:check-circle-fill'} width={24} height={24} />} /> Send a whatsapp
                            </Box>
                            {whatsappNotification &&
                                <Box sx={emailSuccessBoxStyle}>
                                    <Box sx={emailSuccessIconBoxStyle}><Iconify icon={'fa:send'} color='common.lightGreen' width={14} height={14} /></Box> {'Whatsapp message sent successfully!'}
                                </Box>
                            }
                        </Box>
                        {
                            !whatsapp ? <Iconify width={20} height={20} icon={'akar-icons:chevron-down'} /> : <Iconify width={20} height={20} icon={'akar-icons:minus'} />
                        }
                    </Typography>
                </Box>
                {whatsapp ?
                    <Box>
                        {whatsappAccountData?.results?.length === 0 ?
                            <Button variant='contained' sx={{ my: 2, textTransform: 'none !important' }} onClick={() => goToWhatsappPage()}>
                                Connect to whatsApp
                            </Button>
                            : <WhatsappMailCompose
                                whatsappMessageSuccess={whatsappMessageSuccess}
                                setWhatsappNotification={setWhatsappNotification}
                            />}
                    </Box>
                    : null}
            </Box>
            <Divider sx={{ m: '16px 0' }} />
        </>
    );
};

SendWhatsappContent.propTypes = {
    handleAccordian: PropTypes.func,
    whatsappChecked: PropTypes.bool,
    whatsappNotification: PropTypes.bool,
    whatsapp: PropTypes.bool,
    whatsappMessageSuccess: PropTypes.func,
    flowUpdateFunc: PropTypes.func,
    setWhatsappNotification: PropTypes.func,
};

export default SendWhatsappContent;
