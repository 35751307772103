import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, circularProgressClasses, Stack, Typography, useTheme } from '@mui/material';
import { innerCircleStyle, iconStyle, summaryTitleStyle, summaryLeadStyle, profileText, summaryStackStyle } from 'src/styles/ReportSummary';
import { grey } from 'src/theme/palette';

const CustomSummaryComponent = ({ progressValue, progressStyle, priceStyle, label, count, price, stackStyle = summaryStackStyle }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const sameProps = { variant: 'determinate', size: 80, thickness: 3 };
    const commonProps = { ...sameProps, value: 100, sx: innerCircleStyle };
    return <Box sx={{ flexGrow: 1 }}>
        <Stack direction='row' alignItems='flex-start' sx={stackStyle}>
            <Box sx={{ position: 'relative', lineHeight: '0' }}>
                <CircularProgress {...commonProps} />
                <CircularProgress
                    {...sameProps}
                    value={progressValue}
                    sx={{ ...progressStyle, [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' } }}
                />
                <Typography component='span' variant='subtitle2' sx={iconStyle(isLight)}>{(progressValue).toFixed(1)}%</Typography>
            </Box>
            <Box sx={{ ml: '30px !important' }}>
                <Typography component='span' variant='subtitle2' sx={summaryTitleStyle(isLight)}>{label}</Typography>
                <Typography component='span' variant='subtitle2' sx={summaryLeadStyle(isLight)}>{count} <span style={{ color: grey[600], fontWeight: '400' }}>enquiries</span></Typography>
                <Typography component='span' variant='subtitle2' sx={priceStyle}>£{price}</Typography>
                <Typography component='span' variant='subtitle2' sx={profileText}>Est. Value</Typography>
            </Box>
        </Stack>
    </Box>;
};

CustomSummaryComponent.propTypes = {
    progressValue: PropTypes.number,
    progressStyle: PropTypes.object,
    priceStyle: PropTypes.object,
    label: PropTypes.string,
    count: PropTypes.number,
    price: PropTypes.string,
    stackStyle: PropTypes.object,
};

export default CustomSummaryComponent;