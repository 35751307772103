import PropTypes from 'prop-types';
import { Quill } from 'react-quill';
// components
import { setBasicBody } from '../../redux/slices/campaign';
import Iconify from '../Iconify';
// Store
import { dispatch } from '../../redux/store';
import { uploadImage } from '../../redux/slices/practiceMessage';
//
import EditorToolbarStyle from './EditorToolbarStyle';
import { updateError } from '../../redux/slices/user';

// ----------------------------------------------------------------------
const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

export const formats = [
    'align',
    'background',
    'blockquote',
    'bold',
    'bullet',
    'code',
    'code-block',
    'color',
    'direction',
    'font',
    'formula',
    'header',
    'image',
    'indent',
    'italic',
    'link',
    'list',
    'script',
    'size',
    'strike',
    'table',
    'underline',
    'video',
];


export function undoChange() {
    this.quill.history.undo();
}
export function redoChange() {
    this.quill.history.redo();
}

export function imageHandler () {
    try {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const detail = {
                    image: reader.result
                };
                dispatch(uploadImage(detail, handleCallback, this.quill));
            };
        };
    } catch (error) {
        dispatch(updateError({ success: false, message: 'You could only upload images.'}));
    }
}

const handleCallback = (data) => {
    if(data.success) {
        const { image } = data.data;
        if (image) {
            data.quill.editor.insertEmbed(data.quill.getSelection().index, 'image', image);
            if (document.getElementsByClassName('ql-editor')?.[0]) {
                dispatch(setBasicBody(document.getElementsByClassName('ql-editor')?.[0]?.innerHTML));
            }
        }
        return;
    }
    dispatch(updateError({ success: false, message: data.message.image.toString() }));
};

const Embed = Quill.import('formats/image');
class ImageSetStyle extends Embed {
    static create(value) {
        const denoi = super.create(value);
        denoi.setAttribute('style', 'max-width:600px; padding-bottom: 0; display: inline !important; vertical-align: bottom;');
        denoi.setAttribute('data-proc', value.value);
        denoi.innerHTML = value.text;
        return denoi;
    }
}

const Size = Quill.import('attributors/style/size');
Quill.register(Size, true);

const Font = Quill.import('attributors/style/font');

Quill.register(Font, true);

Quill.register({
    'formats/image': ImageSetStyle
});

EditorToolbar.propTypes = {
    id: PropTypes.string.isRequired,
    isSimple: PropTypes.bool,
};

export default function EditorToolbar({ id, isSimple, ...other }) {
    return (
        <EditorToolbarStyle {...other}>
            <div id={id}>
                <div className="ql-formats">
                    <select className="ql-header" defaultValue="">
                        {HEADINGS.map((heading, index) => (
                            <option key={heading} value={index + 1}>
                                {heading}
                            </option>
                        ))}
                        <option value="">Normal</option>
                    </select>
                </div>

                <div className="ql-formats">
                    <button type="button" className="ql-bold" />
                    <button type="button" className="ql-italic" />
                    <button type="button" className="ql-underline" />
                    <button type="button" className="ql-strike" />
                </div>

                {!isSimple && (
                    <div className="ql-formats">
                        <select className="ql-color" />
                        <select className="ql-background" />
                    </div>
                )}

                <div className="ql-formats">
                    <button type="button" className="ql-list" value="ordered" />
                    <button type="button" className="ql-list" value="bullet" />
                    {!isSimple && <button type="button" className="ql-indent" value="-1" />}
                    {!isSimple && <button type="button" className="ql-indent" value="+1" />}
                </div>

                {!isSimple && (
                    <div className="ql-formats">
                        <button type="button" className="ql-script" value="super" />
                        <button type="button" className="ql-script" value="sub" />
                    </div>
                )}

                {!isSimple && (
                    <div className="ql-formats">
                        <button type="button" className="ql-code-block" />
                        <button type="button" className="ql-blockquote" />
                    </div>
                )}

                <div className="ql-formats">
                    <button type="button" className="ql-direction" value="rtl" />
                    <select className="ql-align" />
                </div>

                <div className="ql-formats">
                    <button type="button" className="ql-link" />
                    <button type="button" className="ql-image" />
                    {/* <button type="button" className="ql-video" /> */}
                </div>

                <div className="ql-formats">
                    {!isSimple && <button type="button" className="ql-formula" />}
                    <button type="button" className="ql-clean" />
                </div>

                {!isSimple && (
                    <div className="ql-formats">
                        <button type="button" className="ql-undo">
                            <Iconify icon={'ic:round-undo'} width={18} height={18} />
                        </button>
                        <button type="button" className="ql-redo">
                            <Iconify icon={'ic:round-redo'} width={18} height={18} />
                        </button>
                    </div>
                )}
            </div>
        </EditorToolbarStyle>
    );
}
