// if you change this constant please verify once in reports practitioner(patient) table.
export const TAB_HEAD = [
    { value: 'NEW', label: 'New' },
    { value: 'CONSULTATION_BOOKED', label: 'Consultation booked' },
    { value: 'CONSULTATION_ATTENDED', label: 'Consultation attended' },
    { value: 'TREATMENT_STARTED', label: 'Treatment started' },
    { value: 'FAILED_TO_ATTEND_CONSULTATION', label: 'Failed to attend consultation' },
    { value: 'CLOSED', label: 'Completed' }
];

export const inputKeys = ['Backspace', 'Tab', 'ArrowRight', 'ArrowLeft', '+'];
export const targetInputs = ['Backspace', 'Tab', 'ArrowRight', 'ArrowLeft'];
export const userInputs = ['Backspace', 'Tab', 'ArrowRight', 'ArrowLeft', ' '];

// past Appointment
export const tableLabels = [
    { id: 'Date', label: 'Date', align: 'left' },
    { id: 'Time', label: 'Time', align: 'left' },
    { id: 'Treatment', label: 'Treatment', align: 'left' },
    { id: 'Type', label: 'Type', align: 'left' },
    { id: 'Practitioner', label: 'Practitioner', align: 'left' },
];

// upcoming Appointment
export const upcomingTableLabels = [
    { id: 'Date', label: 'Date', align: 'left' },
    { id: 'Time', label: 'Time', align: 'left' },
    { id: 'Treatment', label: 'Treatment', align: 'left' },
    { id: 'Method', label: 'Method', align: 'left' },
    { id: 'Type', label: 'Type', align: 'left' },
    { id: 'Practitioner', label: 'Practitioner', align: 'left' },
    { id: 'Status', label: 'Status', align: 'left' },
    { id: '' },
];

export const upcomingAppointmentsLabels = [
    { id: 'Date', label: 'Date & Time', align: 'left' },
    { id: 'Treatment', label: 'Treatment', align: 'left' },
    { id: 'Practitioner', label: 'Practitioner', align: 'left' },
    { id: '' },
];

export const archiveAppointmentsLabels = [
    { id: 'Date', label: 'Date & Time', align: 'left' },
    { id: 'Treatment', label: 'Treatment', align: 'left' },
    { id: 'Practitioner', label: 'Practitioner', align: 'left' },
    { id: 'Status', label: 'Status', align: 'left' },
];

export const Channel = [
    { name: 'New Email', value: 'EMAIL' }
];

export const CreateLeadHeaderData = {
    title: 'Create Lead',
    button1: 'Save & Continue Later',
    button2: 'Close',
};

export const CreateCampaignHeaderData = {
    title: 'Create Campaign',
    button1: 'Save & Continue Later',
    button2: 'Close',
};

export const statuses = ['NEW', 'CONSULTATION_ATTENDED', 'ATTENDED', 'FAILED_TO_ATTEND_CONSULTATION', 'FAILED_TO_ATTEND'];

// task reminder
export const TASK_TAB_HEAD = [
    { value: '', label: 'All tasks', icon: 'fluent:tasks-app-28-regular' },
    { value: 'TODO', label: 'Todo', icon: 'mdi:to-do' },
    { value: 'NEW', label: 'Follow up', icon: 'lucide:user-plus-2' },
    { value: 'CONSULTATION_BOOKED', label: 'Record attendance', icon: 'ph:calendar-minus-bold' },
    { value: 'CONSULTATION_ATTENDED', label: 'Consultation follow', icon: 'tabler:phone' },
    { value: 'TREATMENT_STARTED', label: 'Treatment started', icon: 'ph:caret-right-duotone' },
    { value: 'FAILED_TO_ATTEND_CONSULTATION', label: 'FTA', icon: 'eva:question-mark-circle-outline' },
    { value: 'CLOSED', label: 'Completed', icon: 'fluent-mdl2:completed' },
    { value: 'CALLBACK', label: 'Callback request', icon: 'mdi:call-to-action' }
];


export const TASK_ADDITIONAL_FILTER = [
    { value: 'Today', label: 'Today' },
    { value: 'Upcoming', label: 'Upcoming' },
];

// notifications
export const ACTIVITY_OPTIONS = [
    {
        value: 'ASSIGNED_TO_LEAD',
        label: 'Notify me when someone assigns a lead to me.',
    },
    {
        value: 'ASSIGNED_TO_TASK',
        label: 'Notify me when someone assigns a task to me.',
    },
    {
        value: 'TASK_DUE_REMINDER',
        label: 'Notify me for overdue tasks.',
    },
    {
        value: 'TASK_OVERDUE_REMINDER',
        label: 'Notify me for task due.',
    },
    {
        value: 'DEPOSIT_PAID',
        label: 'Notify me for deposit paid.',
    },
    {
        value: 'NEW_LEAD',
        label: 'Notify me for new lead.',
    },
];

export const NOTIFICATION_PLATFORM = [
    'PLATFORM',
    'EMAIL',
    'SMS'
];

export const NOTIFICATION_SETTING_LABEL = [
    'ASSIGNED_TO_LEAD',
    'ASSIGNED_TO_TASK',
    'TASK_DUE_REMINDER',
    'TASK_OVERDUE_REMINDER',
    'DEPOSIT_PAID',
    'NEW_LEAD'
];

export const TABS = [
    {
        value: 'new',
        label: 'New',
    },
    {
        value: 'cleared',
        label: 'Cleared',
    },
];

export const attendedAppointmentsLabels = [
    { id: 'Date', label: 'Date & Time', align: 'left' },
    { id: 'Treatment', label: 'Treatment', align: 'left' },
    { id: 'Practitioner', label: 'Practitioner', align: 'left' },
    { id: 'Comment', label: 'Patient Feedback', align: 'left' },
    { id: '' },
];

export const pastApptTableLabels = [
    { id: 'Date', label: 'Date', align: 'left' },
    { id: 'Time', label: 'Time', align: 'left' },
    { id: 'Treatment', label: 'Treatment', align: 'left' },
    { id: 'Method', label: 'Method', align: 'left' },
    { id: 'Type', label: 'Type', align: 'left' },
    { id: 'Practitioner', label: 'Practitioner', align: 'left' },
    { id: 'Comment', label: 'Patient Feedback', align: 'left' },
    { id: 'Status', label: 'Status', align: 'left' },
    { id: '' },
];