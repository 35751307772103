export const btnHandler = ({ value, setValue, btnValues, reminderMethod }) => {
    const setTime = (days) => {
        const selectedDate = new Date();
        selectedDate.setDate(selectedDate.getDate() + days);
        selectedDate.setHours(9, 0, 0, 0);
        return selectedDate;
    };

    const setNote = (note) => {
        if (setValue) setValue('addNote', note);
    };

    switch (value) {
        case '2 hours-call':
            setNote('Call in 2 hours');
            return btnValues(reminderMethod[0].value, new Date(Date.now() + 2 * 60 * 60 * 1000));
        case 'tomorrow':
            setNote('Call tomorrow');
            return btnValues(reminderMethod[0].value, setTime(1));
        case 'week':
            setNote('Call in 1 week');
            return btnValues(reminderMethod[0].value, setTime(7));
        case 'two-week':
            setNote('Call in 2 weeks');
            return btnValues(reminderMethod[0].value, setTime(14));
        case 'custom reminder':
            setNote('Custom reminder');
            return btnValues('', null);
        case 'no-reminder':
            return btnValues('no-reminder', null);
        default:
            return null;
    }
};

export const btnValues = (setValue, clearErrors) => (reminder, reminderDateTime) => {
    setValue('reminder', reminder);
    setValue('reminderDateTime', reminderDateTime);
    clearErrors('reminder');
    clearErrors('reminderDate');
    clearErrors('reminderDateTime');
};