/* eslint-disable no-prototype-builtins */
import { isArray } from 'lodash';
import { useState } from 'react';
import { handleTablePreferences, handleTablePreferencesEdit, setTablePreferenceLoading } from 'src/redux/slices/tablePreferences';
import { dispatch, useSelector } from 'src/redux/store';
import { getCurrentTableData } from 'src/utils/tablePreferences';
import useGetCurrentUserId from './useGetCurrentUserId';
import useResponsive from './use-responsive';

const useColumnVisibilityChange = (tableTitle, defaultHidden) => {
    const userId = useGetCurrentUserId();
    const { tablePreferences: { results }, globalTablePreferences } = useSelector((state) => state.tablePreferences);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultHidden || {});
    const isDesktop = useResponsive('down', 'sm');
    const [pinnedColumns, setPinnedColumns] = useState({
        right: isDesktop ? ['actions'] : ['contact', 'viewDetails', 'actions']
    });
    const handleColumnVisibilityChange = (newColumns, i) => {
        const unVisibleColumns = Object.entries(newColumns)?.filter(([, value]) => !value)?.map(([key]) => key);
        const allColumns = i?.api?.getAllColumns()?.map((ele) => ele?.field);
        const visibleColumns = allColumns?.filter((ele) => !unVisibleColumns.includes(ele));
        dispatch(setTablePreferenceLoading(true));
        const tableData = getCurrentTableData(results, tableTitle);
        const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableTitle);
        const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
        const currentPreferences = currentTableData?.[0]?.preferences || {};
        let payload = {
            body: { table_name: tableTitle, preferences: { ...currentPreferences, columns: visibleColumns } },
            practiceUserId: userId
        };
        if (isArray(tableData) && tableData.length > 0) {
            payload = { ...payload, tableId: tableData[0].id };
            dispatch(handleTablePreferencesEdit(payload));
        } else {
            dispatch(handleTablePreferences(payload));
        }
    };
    const handleColumnOrderChange = (newColumns) => {
        dispatch(setTablePreferenceLoading(true));
        const tableData = getCurrentTableData(results, tableTitle);
        const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableTitle);
        const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
        const currentPreferences = currentTableData?.[0]?.preferences || {};
        let payload = {
            body: { table_name: tableTitle, preferences: { ...currentPreferences, columns: newColumns } },
            practiceUserId: userId
        };
        if (isArray(tableData) && tableData.length > 0) {
            payload = { ...payload, tableId: tableData[0].id };
            dispatch(handleTablePreferencesEdit(payload));
        } else {
            dispatch(handleTablePreferences(payload));
        }
    };

    return { userId, columnVisibilityModel, handleColumnVisibilityChange, setColumnVisibilityModel, handleColumnOrderChange, pinnedColumns, setPinnedColumns };
};

export default useColumnVisibilityChange;
