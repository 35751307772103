/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import moment from 'moment';
// @mui
import { Card, Typography, Stack, Box, Tooltip, Zoom } from '@mui/material';
import {
    Timeline,
    TimelineDot,
    TimelineItem,
    TimelineContent,
    TimelineSeparator,
} from '@mui/lab';
// component
import { CustomAvatar } from '../../../components/custom-avatar';
import { Iconify } from '../../../components';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { getActivities } from '../../../redux/slices/activity';
// utils
import { setIcon, setBackgroundColor } from '../../../utils/timelineIcon';
import capitalizeFirstLetter from '../../../utils/capitalizedFunc';
import { GetPracticeAvatar } from '../../../utils/createAvatar';
// style
import { activityCard, timeLineInfo, noteTooltipText, noteIcon, timeLineTypoV2, timeLineMessage } from '../../../styles/OverView';
import { timelineItemStyle } from '../../../styles/Common';

const Activities = (data) => {
    const { practiceTreatment, practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { activityList } = useSelector((state) => state.activity);

    useEffect(() => {
        if (data?.leadId) {
            dispatch(getActivities(practiceId, data?.leadId, 10, 0));
        }
    }, [data?.leadId]);

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
        if (bottom && Array.isArray(activityList?.results) && activityList?.count > activityList?.results?.length) {
            dispatch(getActivities(practiceId, data?.leadId, 10, activityList?.results?.length));
        }
    };

    return (
        <Stack>
            <Card sx={{ ...activityCard(data), maxHeight: data?.type === 'contactLeadPopup' ? 'calc(100vh - 220px)' : '920px', overflow: 'auto' }} onScroll={handleScroll}>
                <Box sx={{ p: 0, bgcolor: 'transparent', width: '100%' }}>
                    <Timeline sx={{ p: 0, width: '100%' }}>
                        {Array.isArray(activityList?.results) && activityList?.results?.slice(0).map((value, index) => (
                            <TimelineItem sx={timelineItemStyle} key={index}>
                                <TimelineSeparator>
                                    <TimelineDot color='info' sx={{ ...timeLineInfo, bgcolor: setBackgroundColor(value) }}>
                                        {setIcon(value)}
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent sx={{ padding: '0px 16px 0' }} >
                                    <Typography variant='subtitle2' sx={{...timeLineMessage, lineHeight:"inherit"}}>
                                        {value.data.data.message === 'Awaiting Response.' ? capitalizeFirstLetter('Awaiting Reply.') : capitalizeFirstLetter(value.data.data.message)}
                                    </Typography>
                                    <Typography variant='body2' sx={{...timeLineTypoV2, lineHeight:"inherit", margin:"10px 0", fontSize:"12px"}}>{moment(value.timestamp, 'DD/MM/YYYY').format('DD MMM YYYY')} at {moment(value.timestamp, 'DD/MM/YYYY hh:mm:ss').format('hh:mm a')} </Typography>
                                    {value?.action_object?.additional_notes &&
                                        <Tooltip title={capitalizeFirstLetter(value?.action_object?.additional_notes)} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                            <Typography variant='subtitle2' sx={noteTooltipText}>
                                                Notes
                                                <Iconify icon={'bi:caret-left-fill'} width={18} height={17} sx={noteIcon} />
                                            </Typography>
                                        </Tooltip>}
                                    <Box sx={{ padding: 0, display: 'flex' }}>
                                        <CustomAvatar src={value?.actor?.avatar || GetPracticeAvatar(practiceTreatment, practiceId)[0]?.logo} sx={{ height: '35px', width: '35px', backgroundColor: 'transparent' }} name={value?.actor?.first_name} />
                                        <Typography variant='subtitle2' sx={{ margin: '7px 0 0 7px' }}>
                                            {value?.actor?.first_name || ''} {value?.actor?.last_name || ''}
                                        </Typography>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </Box>
            </Card>
        </Stack>
    );
};

export default Activities;
