import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { lastNoteStyle } from 'src/styles/NewLead';
import { tableCellInnerBoxStyle } from 'src/styles/NewLeadTableRowStyle';

const LastNoteCell = ({ handleArchivedNavigate }) => ({
    field: 'archive_note',
    headerName: 'Archive note',
    sortable: false,
    editable: false,
    width: 200,
    minWidth: 150,
    renderCell: (params) => (
        <Box onClick={() => handleArchivedNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
            {params?.row?.lead_notes ? (
                <Tooltip title={params?.row?.lead_notes?.additional_notes}>
                    <Box sx={lastNoteStyle}>
                        <Typography variant='subtitle2' sx={{ fontWeight: '400', ...lastNoteStyle }}>
                            {params?.row?.lead_notes?.additional_notes || ''}
                        </Typography>
                    </Box>
                </Tooltip>
            ) : (
                ''
            )}
        </Box>
    ),
});

export default LastNoteCell;
