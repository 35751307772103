/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
// @mui
import { Card, Box, Paper, Button, Radio, Zoom, Tooltip, useTheme } from '@mui/material';
// components
import { Iconify } from '../../../components';
import { NotFound } from '../../../components/empty-content';
import { AssignUserAvatar } from '../../../components/custom-avatar';
import { NormalLoading } from '../../../components/loading';
// hooks
import { useTable } from '../../../hooks';
// redux
import {
    getLeadReminderData,
    followUpSlice,
    updateReminder,
    leadTaskListCounter,
    updateTaskCounter,
    updateLeadTaskCounter,
} from '../../../redux/slices/lead';
import { useSelector, dispatch } from '../../../redux/store';
// utils
import capitalizeFirstLetter from '../../../utils/capitalizedFunc';
import { getMessage } from '../../../utils/getTasksData';
import { getAssignees } from '../../../utils/multiAsignees';
// style
import { common } from '../../../theme/palette';
import {
    taskCard,
    taskList,
    taskMessage,
    taskUser,
    taskUserAvatar,
    taskReminderWrapper,
    assignUserAvatarStyle
} from '../../../styles/leadTaskReminder';
import { leadListCard, leadTaskPopover } from '../../../styles/ContactLeadPopup';
import { displayFlex } from '../../../styles/chatbotStyles';

const LeadTaskReminder = ({ type, selectedLead, currentTab,isForTab }) => {
    const theme = useTheme();
    const reminderDataId = useRef();
    const isLight = theme.palette.mode === 'light';
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { leadFlowReminder } = useSelector((state) => state.lead);
    const [markComplete, setMarkComplete] = useState(false);
    const [reminderList, setReminderList] = useState([]);
    const [additionalTabState, setAdditionalTabState] = useState('All');
    const [loading, setLoading] = useState(true);

    const { page, rowsPerPage } = useTable();

    useEffect(() => {
        if (!leadFlowReminder) {
            setReminderList([]);
        }
        
        if (leadFlowReminder && leadFlowReminder?.results) {
            setReminderList((isForTab && leadFlowReminder?.results?.length) ? [leadFlowReminder.results[leadFlowReminder.results.length - 1]] : leadFlowReminder?.results);
        }
        if (leadFlowReminder?.count === 0) {
            setLoading(false);
        }
    }, [leadFlowReminder]);

    useEffect(() => {
        if (currentTab) {
            setAdditionalTabState(currentTab);
            dispatch(getLeadReminderData(practiceId, type, rowsPerPage, 0, 'NEW', type === 'LeadDetail' ? selectedLead.lead_id : '', '', '', '', '', currentTab, undefined, true));
        }
    }, [currentTab]);

    const handleReminder = (value) => {
        dispatch(updateTaskCounter([]));
        if (value === 'markComplete') {
            if (type === 'LeadDetail') {
                dispatch(updateLeadTaskCounter([]));
                dispatch(getLeadReminderData(practiceId, type, rowsPerPage, rowsPerPage * page, 'NEW', type === 'LeadDetail' ? selectedLead.lead_id : '', '', '', 'leadNotes', 'counter', additionalTabState, undefined, true));
                dispatch(leadTaskListCounter(practiceId, 'LeadDetail', selectedLead?.status, selectedLead?.lead_id, '', '', undefined, true));
                dispatch(followUpSlice(selectedLead.lead_id, practiceId, 'LeadDetail'));
            }
        }
    };

    const handleSuccess = (data) => {
        switch (data.message) {
            case 'Task completed !': {
                setMarkComplete(false);
                const key = enqueueSnackbar(data.message || '', {
                    variant: data.success ? 'success' : 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                    autoHideDuration: 5000,
                    action:
                        <>
                            <Button onClick={() => undoReminder()} variant='success' size='small' sx={{ color: common.mintyZest, '&:hover': { bgcolor: 'rgba(84, 214, 44, 0.16)' } }}>
                                Undo
                            </Button>
                            <Iconify onClick={() => closeSnackbar(key)} icon={'radix-icons:cross-2'} height={15} width={15} sx={{ margin: '0px 8px', cursor: 'pointer' }} />
                        </>
                });
                return;
            }
            case 'Task not completed !': {
                setMarkComplete(false);
                enqueueSnackbar(data.message || '', {
                    variant: data.success ? 'success' : 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                });
                return;
            }
            default:
                enqueueSnackbar(data.message || '', {
                    variant: data.success ? 'success' : 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    },
                });
                break;
        }
    };

    const undoReminder = () => {
        setMarkComplete(false);
        const data = {
            is_completed: false
        };
        dispatch(updateReminder(data, reminderDataId.current, practiceId, handleReminder, 'markComplete'));
        closeSnackbar();
    };

    const markCompleteBtn = (value) => {
        reminderDataId.current = value;
        setMarkComplete(value);
        const data = {
            // eslint-disable-next-line no-unneeded-ternary
            is_completed: true,
        };
        dispatch(updateReminder(data, value, practiceId, handleReminder, 'markComplete', handleSuccess));
    };

    return (
        <Box sx={{ pt: isForTab ? 0 : 3, ...(isForTab && { width: '100%' }) }}>
            <Card sx={{ ...taskCard(type), boxShadow: 'none' }}>
                <Box sx={{ position: 'relative', m: isForTab ? '0px' : '16px 16px 0', borderRadius: 2 }}>
                    <NormalLoading tableData={reminderList.length === 0 && 'No Found'} pageCount={leadFlowReminder?.count === undefined && 'No Found'} />
                    <Box sx={{ ...taskList(type), maxHeight: 'none', ...(isForTab && {mb: 0, p:0}) }}>
                        {reminderList && reminderList.map((data) => {
                            const assigneeData = data?.assignees?.filter((el) => el?.user?.first_name && !el?.user?.is_superuser);
                            return <Box
                                key={data.id}
                                sx={{...taskReminderWrapper , ...(isForTab && {padding : "0px", borderBottom:"none", gap:"16px"})}}
                            >
                                <Box sx={{ minWidth: '40px', mt: isForTab ? '0px' : '24px' }}>
                                    <Tooltip title={data.id === markComplete ? 'Mark as incomplete' : 'Mark as complete'} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                        <Radio
                                            checked={data.id === markComplete}
                                            onClick={() => markCompleteBtn(data.id)}
                                            name='radioButtons'
                                            sx={{ p: '0', 'svg': { width: '24px', height: '24px' } }}
                                        />
                                    </Tooltip>
                                </Box>
                                <Paper sx={{ ...leadListCard(isLight, data?.lead?.is_archived, data?.is_system_generated, data?.lead?.is_archive_pending), cursor: 'default', borderRadius: 0, mt: isForTab ? '0px' : '24px' }}>
                                    <Box sx={taskMessage}>
                                        <Box>
                                            <Box sx={{ fontSize: '16px' }}>
                                                {data.title !== '' ? <Box sx={{ fontSize: '12px', lineHeight: 'inherit' }} ><b>{capitalizeFirstLetter(data?.title)}</b></Box> : getMessage(data?.lead_treatment?.status, data?.lead?.first_name, data?.lead?.last_name, data?.lead_treatment?.treatment, data?.group, data?.method)
                                                }
                                            </Box>
                                            <Box sx={{ ...taskUser, alignItems: 'flex-start', pt: '6px' }}>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Box sx={displayFlex} >
                                                        <Box sx={leadTaskPopover(data)}>
                                                            <Iconify icon={'mdi:clock-time-three-outline'} width={12} height={12} sx={{ mr: '5px' }} />
                                                            {data.datetime_human && data.datetime_human || ''}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ ...taskUser, alignItems: 'flex-start', pt: '0' }}>
                                            {assigneeData?.length > 0 ?
                                                <Box sx={taskUserAvatar}>
                                                    <Tooltip title={getAssignees(data)} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                        <Box sx={displayFlex}>
                                                            {assigneeData?.slice(0, 2)?.map((assignee, index) => (
                                                                <AssignUserAvatar
                                                                    key={index}
                                                                    sx={assignUserAvatarStyle}
                                                                    src={assignee.user?.avatar || ''}
                                                                    name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                                    role={assignee.role}
                                                                />
                                                            ))}
                                                        </Box>
                                                    </Tooltip>
                                                </Box> : ''}
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>;
                        })}
                        {(reminderList?.length === 0 && leadFlowReminder?.count !== undefined && !loading) && <NotFound />}
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};
LeadTaskReminder.propTypes = {
    type: PropTypes.string,
    selectedLead: PropTypes.object,
    currentTab: PropTypes.string,
    isForTab: PropTypes.bool
};

export default LeadTaskReminder;