import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { cancelBtnStyle, yesBtnStyle } from '../../styles/NewLead';

const ConfirmationDialog = ({
    open,
    onClose,
    title,
    onConfirm,
    cancelBtnStyle,
    confirmBtnStyle,
    confirmBtnText,
    cancelBtnText,
}) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ padding: '40px 40px 0px', textAlign: 'center' }}>
            {title}
        </DialogTitle>
        <DialogActions sx={{ padding: '40px !important', justifyContent: 'center' }}>
            <Button sx={cancelBtnStyle} onClick={onClose}>
                {cancelBtnText}
            </Button>
            <Button type='submit' sx={confirmBtnStyle} onClick={onConfirm}>
                {confirmBtnText}
            </Button>
        </DialogActions>
    </Dialog>
);

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    cancelBtnStyle: PropTypes.object,
    confirmBtnStyle: PropTypes.object,
    confirmBtnText: PropTypes.string,
    cancelBtnText: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
    confirmBtnText: 'Yes',
    cancelBtnText: 'Cancel',
    cancelBtnStyle,
    confirmBtnStyle: yesBtnStyle,
};

export default ConfirmationDialog;
