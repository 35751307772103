import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { callApi } from '../../utils/apiCall';
import { getPracticeId } from './clincheck';
import { updateError } from './user';
import { setLoader } from './loader';

const initialState = {
    isLoading: false,
    practiceChatBotsGlobal: null,
    individualChatBotGlobal: null,
    loadingForChatbot: false
};


const slice = createSlice({
    name: 'practiceChatbot',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        setPracticeChatBotsGlobal(state, action) {
            state.practiceChatBotsGlobal = action.payload;
        },
        setIndividualChatBotGlobal(state, action) {
            state.individualChatBotGlobal = action.payload;
        },
        setLoadingForChatbot(state, action) {
            state.loadingForChatbot = action.payload;
        },
    }
});

export default slice.reducer;

export const { setPracticeChatBotsGlobal, setIndividualChatBotGlobal, reset } = slice.actions;

const handleLoading = (state) => {
    if (state === false) {
        setTimeout(() => {
            dispatch(slice.actions.setLoadingForChatbot(state));
        }, 500);
    } else {
        dispatch(slice.actions.setLoadingForChatbot(state));
    }
};

export function handleGetPracticeChatBots() {
    return async () => {
        try {
            handleLoading(true);
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/chatbots/?ordering=-updated_at`);
            if (response.status === 200) {
                handleLoading(false);
                dispatch(slice.actions.setPracticeChatBotsGlobal(response.data?.results));
            } else {
                handleLoading(false);
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            handleLoading(false);
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
export function handleGetIndividualChatBot(chatbotId) {
    return async () => {
        try {
            handleLoading(true);
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/chatbots/${chatbotId}`);
            if (response.status === 200) {
                handleLoading(false);
                dispatch(slice.actions.setIndividualChatBotGlobal(response.data));
            } else {
                handleLoading(false);
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            handleLoading(false);
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
export function handleRemoveChatbot(chatbotId) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/chatbots/${chatbotId}`, 'delete');
            if (response.status === 204) {
                dispatch(handleGetPracticeChatBots());
                dispatch(updateError({ message: 'Chatbot deleted successfully', success: true }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
export function handleCreatePracticeChatBots(data) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/chatbots/`, 'post', data.body);
            if (response.status === 201) {
                if (data.handleSuccess) {
                    data.handleSuccess();
                }
                dispatch(updateError({
                    message: 'Chatbot created successfully',
                    success: true
                }));
            } else {
                dispatch(setLoader(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
export function handleEditPracticeChatBots(data) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/chatbots/${data.chatbotId}`, 'put', data.body);
            if (response.status === 200) {
                if (data.handleSuccess) {
                    data.handleSuccess();
                }
                dispatch(updateError({
                    message: 'Chatbot edited successfully',
                    success: true
                }));
            } else {
                dispatch(setLoader(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(setLoader(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}