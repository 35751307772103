import { useRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Grid, Dialog, Container, Stack, Button, Box } from '@mui/material';
import { AddReminder } from '../../sections/@dashboard/leadDetail';
import { Transition } from '.';
import {
    reminderDialogStyle,
    addReminderStackStyle,
    reminderFooterStyle,
    addReminderCancelButtonStyle,
    addRemindersaveButtonStyle} from '../../styles/AddReminderPopup';

const AddReminderPopup = ({ open, onClose, leadDetail, type, leadData, reload, reloadTaskCounter, page, handleLoading }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={reminderDialogStyle}
        >
            <Stack sx={{ ...addReminderStackStyle, overflow: 'hidden' }}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                        <AddReminder ref={inputRef} handleNext={onClose} reloadTaskCounter={reloadTaskCounter} page={page} type={type} leadDetail={leadDetail} leadData={leadData} reload={reload} handleLoading={handleLoading} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={reminderFooterStyle}>
                <Button
                    color='inherit'
                    variant='outlined'
                    sx={addReminderCancelButtonStyle}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    onClick={handleNext}
                    sx={addRemindersaveButtonStyle}
                >
                    {type === 'editTask' ? 'Save changes' : 'Save task'}
                </Button>
            </Box>
        </Dialog>
    );
};

export default AddReminderPopup;

AddReminderPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    leadDetail: PropTypes.object,
    type: PropTypes.string,
    leadData: PropTypes.object,
    reload: PropTypes.bool,
    reloadTaskCounter: PropTypes.bool,
    page: PropTypes.string,
    handleLoading: PropTypes.func,
};