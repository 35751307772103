import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Stack, Box } from '@mui/material';
// component
import { NotFound } from '../../../components/empty-content';
//
import { PaymentList, CheckoutSessionList, RefundList } from '../payment';
// redux
import { useSelector } from '../../../redux/store';

// ------------------------------------------------------------------
export default function PaymentRefunds({ leadDetail }) {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { paymentComplete } = useSelector((state) => state.lead);

    const [renderComponent, setRenderComponent] = useState('paymentList');
    const [paymentId, setPaymentId] = useState('');
    const [checkoutId, setCheckoutId] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');

    const treatmentId = leadDetail && leadDetail.lead_treatments[0]?.id;

    const onOpenDetailSection = (rowId, amount) => {
        setPaymentId(rowId);
        setPaymentAmount(amount);
        setRenderComponent('checkoutList', rowId);
    };

    const handleRefundClick = (checkoutId) => {
        setCheckoutId(checkoutId);
        setRenderComponent('refundList');
    };

    return (
        <Stack>
            <Box>
                {paymentComplete?.length > 0 &&
                    <Box>
                        {renderComponent === 'paymentList' &&
                            <PaymentList onOpenDetailSection={onOpenDetailSection} />
                        }
                        {renderComponent === 'checkoutList' && treatmentId &&
                            <CheckoutSessionList practiceId={practiceId} leadId={leadDetail.id} treatmentId={treatmentId} paymentId={paymentId} handleRefundClick={handleRefundClick} setRenderComponent={setRenderComponent} />
                        }
                        {renderComponent === 'refundList' &&
                            <RefundList practiceId={practiceId} leadId={leadDetail.id} treatmentId={treatmentId} paymentId={paymentId} setRenderComponent={setRenderComponent} checkoutId={checkoutId} paymentAmount={paymentAmount} />
                        }
                    </Box>
                }
                {paymentComplete?.length === 0 && <NotFound />}
            </Box>
        </Stack>
    );
}

PaymentRefunds.propTypes = {
    leadDetail: PropTypes.any,
};
