import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Box,
    Input,
    Portal,
    Button,
    Divider,
    Backdrop,
    IconButton,
    Typography,
    Stack,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    OutlinedInput
} from '@mui/material';
// hooks
import { useResponsive, useToggle } from '../../../hooks';
//
import SendMessageDialog from './SendMessageDialog';
// components
import { Iconify } from '../../../components';
import { FormProvider, RHFTextField, RHFAutocomplete } from '../../../components/hook-form';
// constants
import { addCategory } from '../../../constants/EmailMessageComposeConst';
import { useSelector } from '../../../redux/store';
// style
import {
    backdropStyle,
    rootStyle,
    formMainBoxStyle,
    allBoxTypeStyle,
    formControlStyle,
    multipleChipStyle,
    iconBtnStyle,
    textFieldStyle,
    stackStyle,
    editorBoxStyle,
    editorBoxBtnStyle,
    menuItemStyle,
    RHFTextFieldStyle,
    saveBtnStyle,
    formControlLabelStyle,
    editorStyle
} from '../../../styles/EmailMessageComposeStyle';
import Editor from '../../../components/editor/Editor';
// util
import { getDelayLabel } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    right: 0,
    bottom: 0,
    zIndex: 1119,
    minHeight: 440,
    outline: 'none',
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    flexDirection: 'column',
    margin: theme.spacing(3),
    boxShadow: theme.customShadows.z20,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    backgroundColor: theme.palette.background.paper,
}));

const InputStyle = styled(Input)(({ theme }) => ({
    padding: theme.spacing(0.5, 3),
    borderBottom: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

export default function SMSMessageCompose({
    openSMSCompose,
    onCloseSMSCompose,
    composeDetail,
    handleSaveMessage,
    isEdit,
    type,
    messageType,
    practiceId,
    useOrSend,
    setTemplate,
    setTemplateOpen,
    OpenTemplate,
    handleSendEmail,
    sendTemplate
}) {

    const EditorMessage = useRef();
    const [fullScreen, setFullScreen] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const { mergeTags } = useSelector((state) => state.lead);
    const isDesktop = useResponsive('up', 'sm');
    const getNameList = (start, end) => mergeTags?.slice(start, end);
    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();

    const handleClose = () => {
        onCloseSMSCompose();
        setFullScreen(false);
        document.body.style.overflowY = 'auto';
        setCompose({
            body: '',
            subject: '',
            is_enabled: composeDetail.is_enabled,
            delay: composeDetail.delay && { label: getDelayLabel(composeDetail.delay), second: composeDetail.delay } || { label: 'Immediately', second: 0 },
            personName: composeDetail.personName || [],
            category: '',
            channel: 'SMS',
            name: ''
        });
    };

    const [compose, setCompose] = useState({
        body: composeDetail.body || '',
        subject: composeDetail.subject || '',
        is_enabled: composeDetail.is_enabled,
        delay: composeDetail.delay && { label: getDelayLabel(composeDetail.delay), second: composeDetail.delay } || { label: 'Immediately', second: 0 },
        personName: composeDetail.personName || [],
        category: composeDetail.category || '',
        channel: composeDetail.channel || 'SMS',
        name: composeDetail.name || ''
    });

    const handleChangeEvent = (value, type) => {
        if (typeof value === 'string' && composeDetail[type] !== value.replace(/<\/?.+?>/ig, '')) {
            setEditForm(true);
        }
        setCompose({
            ...compose,
            [type]: value,
        });
    };

    useEffect(() => {
        if (Object.keys(composeDetail).length > 0) {
            if (Object.keys(composeDetail).length > 0) {
                setCompose({
                    ...compose,
                    body: composeDetail.body || '',
                    subject: composeDetail.subject || '',
                    is_enabled: composeDetail.is_enabled,
                    delay: composeDetail.delay && { label: getDelayLabel(composeDetail.delay), second: composeDetail.delay } || { label: 'Immediately', second: 0 },
                    personName: composeDetail.personName || [],
                    category: composeDetail.category || '',
                    channel: composeDetail.channel || 'SMS',
                    name: composeDetail.name || ''
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [composeDetail]);

    useEffect(() => {
        if (sendTemplate && sendTemplate) {
            setEditForm(true);
        }
    }, [sendTemplate]);

    const disabledTemplate = () => {
        if ((messageType === 'Practice_Message' || messageType === 'OnBoarding') && compose.subject.trim() && editForm) {
            return false;
        }
        if (compose.name.trim() && compose.category.trim() && compose.subject.trim() && (compose.body.replace(/<\/?.+?>/ig, '')).trim() && editForm) {
            return false;
        }
        if (messageType === 'LeadTemplate') {
            if (compose.subject.trim() && (compose.body.replace(/<\/?.+?>/ig, '')).trim() && editForm) {
                return false;
            }
        }
        return true;
    };

    const handleSave = () => {
        if (messageType === 'Practice_Message' || messageType === 'OnBoarding') {
            const detail = {
                delay: compose && compose.delay.second || 0,
                subject: compose && compose.subject || '',
                body: compose && compose.body.replace(/<\/?.+?>/ig, '') || '',
                is_enabled: compose && compose.is_enabled,
            };
            handleSaveMessage(detail);
            return;
        }
        const detail = {
            practice: practiceId,
            name: compose && compose.name || '',
            subject: compose && compose.subject || '',
            body: compose && compose.body.replace(/<\/?.+?>/ig, '') || '',
            channel: 'SMS',
            category: compose && compose.category || '',
        };
        if (compose.category === 'COMMUNICATION') {
            detail.base_template = import.meta.env.VITE_REACT_APP_COMMUNICATION_ID || 3;
        }
        if (compose.category === 'MARKETING') {
            detail.base_template = import.meta.env.VITE_REACT_APP_MARKETING_ID || 5;
        }
        if (compose.category === 'PROMOTION') {
            detail.base_template = import.meta.env.VITE_REACT_APP_PROMOTION_ID || 6;
        }
        handleSaveMessage(detail, composeDetail.id);
        document.body.style.overflowY = 'auto';
        handleClose();
    };

    const handleChange = (e, value) => {
        if (value) {
            const data = `$${value}`;
            if (EditorMessage.current) {
                const editor = EditorMessage.current.getEditor();
                const index = editor.getSelection() ? editor.getSelection().index : editor.getLength();
                editor.insertText(index, data, 'link');
            }
        }
    };

    const handleSend = () => {
        const detail = {
            name: compose.name || '',
            subject: compose.subject || '',
            body: compose.body || '',
            channel: 'SMS',
            category: compose.category || '',
        };
        handleSendEmail(detail);
    };

    const defaultValues = {
        title: 'title',
    };

    const methods = useForm({
        defaultValues,
    });

    if (!openSMSCompose) {
        document.body.style.overflowY = 'auto';
        return null;
    }

    if (openSMSCompose) {
        setTimeout(() => {
            document.body.style.overflow = 'hidden';
        }, 500);
    }

    const handleTime = (sendTime) => {
        setEditForm(true);
        setCompose({
            ...compose,
            delay: sendTime,
        });
        onCloseContacts();
    };

    return (
        <Portal>
            <Backdrop
                open={fullScreen || !isDesktop}
                sx={backdropStyle}
            />
            <RootStyle
                sx={rootStyle(fullScreen)}
            >
                <FormProvider fullScreenForm={fullScreen} methods={methods}>
                    {useOrSend && useOrSend === 'use' &&
						<Typography>
						    <IconButton onClick={handleClose}>
						        <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
						    </IconButton> Template
						</Typography>
                    }
                    <Box sx={formMainBoxStyle(messageType)}>
                        <Box sx={allBoxTypeStyle(messageType)}>
                            {(messageType === 'Practice_Message' || messageType === 'OnBoarding') && <Iconify icon={'eva:email-fill'} width={24} height={24} mr={1} />}
                            {(messageType === 'Template') &&
								<FormControl sx={formControlStyle}>
								    <Iconify sx={{ color: '#637381', marginRight: '5px' }} icon={'bi:filter'} width={20} height={20} />
								    <InputLabel
								        id="select-multiple-chip"
								        sx={{ fontWeight: 600 }}
								    >
										Add to category
								    </InputLabel>
								    <Select
								        labelId="select-multiple-chip"
								        id="multiple-chip"
								        value={compose.category}
								        onChange={(event) => handleChangeEvent(event.target.value, 'category')}
								        placeholder="Add to category"
								        label="Add to category"
								        input={
								            <OutlinedInput
								                inputProps={{ opacity: '1' }}
								                id="select-multiple-chip"
								                sx={{ opacity: '0' }}
								                style={{ opacity: '1' }}
								                label="Add to category"
								            />
								        }
								        sx={multipleChipStyle}
								    >
								        {addCategory.map((name, index) => (
								            <MenuItem
								                sx={menuItemStyle}
								                key={index}
								                value={name}
								            >
								                {name}
								            </MenuItem>
								        ))}
								    </Select>
								</FormControl>}

                            {(messageType !== 'Practice_Message' && messageType !== 'OnBoarding') && <RHFTextField
                                name="name"
                                placeholder="Name"
                                variant="standard"
                                value={compose.name}
                                sx={textFieldStyle}
                                onChange={(event) => handleChangeEvent(event.target.value, 'name')}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />}

                            <Typography variant="h6"
                                sx={{
                                    maxWidth: { sm: '100%', xs: '200px' },
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {isEdit
                                    ? composeDetail.message && `${composeDetail.message.lead_status} SMS Message`
                                    : 'New SMS Message'}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={fullScreen ? () => setFullScreen(false) : () => setFullScreen(true)} sx={iconBtnStyle}>
                                <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} width={20} height={20} />
                            </IconButton>
                            <IconButton onClick={handleClose}>
                                <Iconify icon={'eva:close-fill'} width={20} height={20} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Stack sx={stackStyle}>
                        <InputStyle type="text" placeholder="Subject" value={compose.subject} onChange={(event) => handleChangeEvent(event.target.value, 'subject')} sx={{ fontWeight: 600 }} />
                        {(messageType === 'Practice_Message' || messageType === 'OnBoarding') &&
						<FormControlLabel
						    sx={formControlLabelStyle}
						    control={<Switch checked={compose.is_enabled} onChange={(event) => { setEditForm(true); handleChangeEvent(event.target.checked, 'is_enabled'); }} />}
						    label={compose.is_enabled ? 'Published' : 'Unpublished'}
						/>}
                    </Stack>
                    <Editor
                        id="compose-mail"
                        value={compose.body}
                        EditorMessage={EditorMessage}
                        fullScreen={fullScreen}
                        type={type}
                        onChange={(value) => handleChangeEvent(value, 'body')}
                        placeholder="Type a message"
                        sx={editorStyle}
                    />
                    <Divider />
                    <Box sx={editorBoxStyle}>
                        {useOrSend && useOrSend !== 'use' &&
							<Button onClick={OpenTemplate} sx={editorBoxBtnStyle}>
							    <Iconify sx={{ color: '#212B36', marginRight: '10px' }} icon={'mdi:view-agenda'} width={20} height={20} /> Templates
							</Button>}
                        {(useOrSend && useOrSend !== 'use' || useOrSend === undefined) &&
							<Box sx={{ display: 'flex', width: '30%' }}>
							    <RHFAutocomplete
							        name={'more'}
							        size="small"
							        options={getNameList()?.map((name) => name.tag)}
							        onChange={handleChange}
							        sx={{ width: '100%' }}
							        renderInput={(params) => (
							            <TextField
							                {...params}
							                label="Tags"
							            />
							        )}
							    />
							</Box>
                        }
                        {messageType !== 'LeadTemplate' ?
                            <Box sx={{ flex: 1, textAlign: 'right' }}>
                                {(messageType === 'Practice_Message' || messageType === 'OnBoarding') &&
									<RHFTextField
									    name="Send"
									    label="Send"
									    readOnly
									    value={compose.delay && compose.delay.label || ''}
									    onClick={() => { onOpenContacts(); }}
									    InputProps={{
									        startAdornment:
												<InputAdornment position="start">
												    <Iconify icon={'material-symbols:send'} width={24} height={24} />
												</InputAdornment>,
									    }}
									    sx={RHFTextFieldStyle}
									/>}
                                {(messageType === 'Practice_Message' || messageType === 'OnBoarding') &&
									<Button
									    sx={saveBtnStyle}
									    variant="contained"
									    onClick={handleSave}
									    disabled={disabledTemplate()}
									>
										Save
									</Button>
                                }
                                {(messageType !== 'Practice_Message' && messageType !== 'OnBoarding') &&
									<Button
									    sx={saveBtnStyle}
									    variant="contained"
									    onClick={handleSave}
									    disabled={disabledTemplate()}
									>
										Save Template
									</Button>
                                }
                            </Box> :
                            <Box sx={{ flex: 1, textAlign: 'right' }}>
                                {useOrSend === 'send' &&
									<Button
									    sx={saveBtnStyle}
									    variant="contained"
									    onClick={handleSend}
									    disabled={disabledTemplate()}
									>
										Send Email
									</Button>
                                }
                                {useOrSend === 'use' &&
									<Button sx={saveBtnStyle} variant="contained" onClick={() => { setTemplate(composeDetail); setTemplateOpen(false); }}>
										Use Template
									</Button>}
                            </Box>
                        }
                    </Box>
                </FormProvider>
                <SendMessageDialog
                    open={openContacts}
                    handleTime={handleTime}
                    onClose={onCloseContacts}
                    composeDetail={composeDetail}
                />
            </RootStyle>
        </Portal>
    );
}

SMSMessageCompose.propTypes = {
    openSMSCompose: PropTypes.bool,
    onCloseSMSCompose: PropTypes.func,
    composeDetail: PropTypes.any,
    handleSaveMessage: PropTypes.func,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
    messageType: PropTypes.string,
    practiceId: PropTypes.string,
    useOrSend: PropTypes.string,
    setTemplate: PropTypes.any,
    setTemplateOpen: PropTypes.any,
    OpenTemplate: PropTypes.any,
    handleSendEmail: PropTypes.func,
    sendTemplate: PropTypes.bool
};