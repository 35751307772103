export const getIcon = (row, customIcon) => {
    switch (row?.method) {
        case 'VIDEO_CALL':
            return customIcon({ title: 'Video Call', icon: 'eva:video-fill' });
        case 'IN_PRACTICE':
            return customIcon({ title: 'In Practice', icon: 'eva:home-fill' });
        default:
            return customIcon({ title: 'Phone', icon: 'eva:phone-fill' });
    }
};

export const StatusValue = (value) => {
    switch (value) {
        case 'CANCELLED':
            return 'Cancelled';
        case 'ATTENDED':
            return 'Attended';
        case 'BOOKED':
            return 'Booked';
        case 'FAILED_TO_ATTEND':
            return 'Failed to attend';
        default:
            return 0;
    }
};