import { common } from 'src/theme/palette';

export const commonDialogHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
    rowGap: 2,
    flexDirection: { sm: 'row' },
    position: 'sticky',
    top: 0,
    textAlign: 'center',
    pt: 2,
    pb: 2,
    bgcolor: 'primary.main'
};
export const onBoardDialogHeader = {
    position: 'sticky',
    bgcolor: 'info.main',
    top: 0,
    textAlign: 'center',
    pt: 2,
    pb: 2
};
export const cancelBoxStyle = (onboaradHeaderButtons) => ({
    display: 'flex',
    columnGap: 2,
    justifyContent: 'center',
    position: onboaradHeaderButtons && 'absolute',
    right: onboaradHeaderButtons && '40px',
    mt: onboaradHeaderButtons && '11px',
    '@media(max-width: 900px)': {
        display: onboaradHeaderButtons && 'flex',
        mt: onboaradHeaderButtons && '10px',
        position: onboaradHeaderButtons && 'relative',
        right: onboaradHeaderButtons && '0',
    }
});
export const leadFooterButtonsStyle = (activeBackButton, type) => ({
    mt: type === 'contactLead' ? 5 : 3,
    display: 'flex',
    // eslint-disable-next-line no-nested-ternary
    justifyContent: !activeBackButton ? type === 'followup' ? 'center' : 'flex-end' : 'space-between',
    pr: { xs: type === 'contactLead' ? 0 : 4 },
    pl: { xs: type === 'contactLead' ? 0 : 4 },
    flexWrap: 'wrap'
});
export const onBoardFooterButtonsStyle = activeBackButton => ({
    mt: 3,
    display: 'flex',
    justifyContent: !activeBackButton ? 'flex-end' : 'space-between',
    pr: { sm: 7, xs: 4 },
    pl: { sm: 5, xs: 4 },
    flexWrap: 'wrap'
});
export const tabButtonBoxStyle = {
    width: '100%',
    textAlign: 'center',
    marginTop: '-30px'
};
export const tabButtonGroupStyle = {
    border: 'none',
    flexDirection: 'row',
    maxWidth: '100%',
    borderRadius: '0',
    padding: '0',
    marginBottom: '18px'
};
export const contactLeadButtonStyle = outcome => ({
    borderRadius: '0 !important',
    ':hover': { backgroundColor: 'transparent', border: 'none' },
    color: outcome === 'Contact Lead' ? '#212B36' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: '12px 16px',
    marginRight: '24px',
    marginLeft: '0 !important',
    justifyContent: 'center',
    textAlign: 'center',
    width: '322px',
    borderBottom: outcome === 'Contact Lead' ? 'solid 1px #00AB55 !important' : 'solid 1px  transparent'
});
export const timelineButtonStyle = outcome => ({
    borderRadius: '0 !important',
    ':hover': { backgroundColor: 'transparent', border: 'none' },
    color: outcome === 'Timeline' ? '#212B36' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: '12px 16px',
    marginRight: { md: '0', xs: '0' },
    marginLeft: '0 !important',
    justifyContent: 'center',
    textAlign: 'center',
    width: '322px',
    borderBottom: outcome === 'Timeline' ? 'solid 1px #00AB55 !important' : 'solid 1px  transparent',
    display: 'none !important',
    '@media(max-width: 1240px)': {
        display: 'inline-flex !important'
    }
});
export const activeStepTitleStyle = {
    mt: 1,
    mb: 4,
    width: '100%',
    textAlign: 'center'
};
export const activeStepTextStyle = {
    m: 'auto',
    mb: 5,
    textAlign: 'center',
    '@media (min-width:990px)': { width: '60%' }
};
export const footerButtonBoxStyle = {
    width: '100%',
    pb: 5,
    position: 'fixed',
    left: 0,
    bottom: 0,
    bgcolor: 'background.paper',
    zIndex: 99
};
export const backButtonStyle = type => (
    type === 'createLead' || type === 'followup' ? {
        px: { xs: 3.5, sm: 8 },
        minHeight: '40px',
        minWidth: type === 'contactLead' ? '180px' : '200px',
        padding: '5px 30px !important'
    } : {
        px: { xs: 3.5, sm: 8 },
        py: 0.75,
        minHeight: '40px',
        minWidth: type === 'contactLead' ? '180px' : '200px',
        padding: '5px 30px !important'
    }
);
export const nextButtonStyle = type => (
    type === 'createLead' || type === 'followup' ? {
        px: { xs: 3.5, sm: 8 },
        ml: '16px',
        minHeight: '40px',
        minWidth: type === 'contactLead' ? '180px' : '200px',
        padding: '5px 30px !important'
    } : {
        px: { xs: 3.5, sm: 8 },
        py: 0.75,
        ml: '16px',
        minHeight: '40px',
        minWidth: type === 'contactLead' ? '180px' : '200px',
        padding: '5px 30px !important'
    }
);
export const markAsCompleteButtonStyle = type => (
    type === 'createLead' || type === 'followup' ? {
        px: { xs: 3.5, sm: 8 },
        ml: '16px',
        minHeight: '40px',
        minWidth: '200px',
        padding: '5px 30px !important'
    } : {
        px: { xs: 3.5, sm: 8 },
        py: 0.75,
        ml: '16px',
        minHeight: '40px',
        minWidth: '200px',
        padding: '5px 30px !important'
    }
);

export const popupMainStackStyle = {
    display: 'inline-block', 
    p: 2.5, 
    pb: 1,
    position: 'relative',
    width: '100%',
    height: '100%'
};

export const drawerStyle = (drawerOpen) => ({
    position: 'fixed', 
    width: 370, 
    maxWidth: '100%', 
    display: drawerOpen ? 'flex' : 'none',
    mt: '0 !important',
    height: '100%',
    right: '16px',
    top: '68px',
    '@media(max-width: 1440px)': {
        width: 300,
    },
    '@media(max-width: 1300px)': {
        width: 270,
    },
    '@media(max-width: 1240px)': {
        display: 'none !important'
    },
    '.MuiDrawer-paper': { 
        width: 370, 
        position: 'absolute', 
        maxWidth: '100%', 
        boxShadow: 'none',
        zIndex: 1,
        overflow: 'hidden',
        '@media(max-width: 1440px)': {
            width: 300,
        },
        '@media(max-width: 1300px)': {
            width: 270,
        },
    }
});

export const drawerHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    p: '20px 20px 20px 20px', 
    justifyContent: 'space-between', 
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)'
};

export const gridContainerStyle = (type, ContactedCount, activeStep) => ({
    maxWidth: type === 'followup' && ContactedCount !== 0 && activeStep === 0 && 'calc(100% - 370px)',
    '@media(max-width: 1240px)': {
        maxWidth: '100%',
    },
});

export const dialogTypoStyle = { display: 'block', color: common.white };

export const menuItemStyle = { borderRadius: '8px', minHeight: '40px !important', color: common.slateGray };

export const cancelBtnDesign = { borderColor: common.white, color: common.white };