export const STATUS_OPTIONS = ['all', 'ADMIN', 'USER', 'Practitioner', 'TCO', 'Disabled'];

export const ADMIN_STATUS_OPTIONS = ['all', 'ADMIN', 'USER', 'Practitioner', 'TCO', 'Disabled', 'INVITE USER'];

export const validInviteUserLabels = [
    { id: 'role', label: 'Role', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: '' }
];

export const inValidInviteUserLabels = [
    { id: 'user.first_name', label: 'Name', align: 'left' },
    { id: 'role', label: 'Role', align: 'left' },
    { id: 'user.job_title', label: 'Job title', align: 'left' },
    { id: 'user.email', label: 'Email', align: 'left' },
    { id: 'user.phone', label: 'Phone number', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: '' }
];

export const notEditUser = [
    { id: 'user.first_name', label: 'Name', align: 'left' },
    { id: 'role', label: 'Role', align: 'left' },
    { id: 'user.job_title', label: 'Job title', align: 'left' },
    { id: 'user.email', label: 'Email', align: 'left' },
    { id: 'user.phone', label: 'Phone number', align: 'center' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: '' }
];