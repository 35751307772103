import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
// @mui
import { Box, Typography, Stack, MenuItem } from '@mui/material';
// components
import { Iconify } from '../../../components';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { createRefundRequest } from '../../../redux/slices/lead';
// validation
import { AddRefundPaymentSchema } from '../../../validations/validations';
// style
import { common } from '../../../theme/palette';
import {
    SelectMenuItemStyle,
    AddRefundBoxStyle,
    AddRefundTitleStyle,
    InfoBoxStyle,
    InfoIconStyle,
    InfoTextStyle,
    ErrorTextStyle,
    AmountInputStyle
} from '../../../styles/PaymentRefund';

const AddRefundPayment = forwardRef(({ handleNext, leadId, treatmentId, paymentId, checkoutId, paymentAmount }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { refundPaymentReason } = useSelector((state) => state.schema);
    const { enqueueSnackbar } = useSnackbar();

    const [value, setValue] = useState(0.0);
    const [decimalSeparator] = useState('.');
    const [thousandSeparator] = useState(',');
    const [precision] = useState('2');
    const [inputType] = useState('text');
    const [error, setError] = useState(false);
    const [refundAmountError, setRefundAmountError] = useState(false);

    const defaultValues = {
        Reason: '',
    };

    const methods = useForm({
        resolver: yupResolver(AddRefundPaymentSchema),
        defaultValues
    });

    const {
        handleSubmit,
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                setError(value === 0);
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    );

    const onSubmit = (data) => {
        if (!error && !refundAmountError) {
            const dataValue = {
                checkout_session: checkoutId,
                reason: data.Reason,
                description: data.Description,
                amount: value
            };
            dispatch(createRefundRequest(dataValue, practiceId, leadId, treatmentId, paymentId, checkoutId, handleSuccess));
        }
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        handleNext(true);
    };

    const handleChange = (event, maskedValue, floatValue) => {
        if (floatValue > 0) {
            setError(false);
            setValue(floatValue);
        }
        setRefundAmountError(floatValue > paymentAmount);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={AddRefundBoxStyle}>
                <Typography variant='h5' sx={AddRefundTitleStyle}>
                    Refund payment
                </Typography>
                <Box sx={InfoBoxStyle}>
                    <Iconify icon={'bi:info-square-fill'} sx={InfoIconStyle} color={common.foggyBlack} width={14} height={14} />
                    <Typography variant='p' sx={InfoTextStyle}>
                        Refunds take 5-10 days to appear on a customer's statement. Stripe's fees for the original payment won't be returned, but there are no additional fees for the refund.
                    </Typography>
                </Box>
                <Stack sx={{ borderBottom: 'solid 2px rgba(145, 158, 171, 0.12)', padding: '20px' }}>
                    <Box sx={{ mb: '20px' }}>
                        <CurrencyInput
                            value={value}
                            decimalSeparator={decimalSeparator}
                            thousandSeparator={thousandSeparator}
                            onChangeEvent={handleChange}
                            className='currency-input'
                            precision={precision}
                            inputType={inputType}
                            prefix={'£ '}
                            style={AmountInputStyle(error, '', isLight)}
                        />
                        {error && <Typography variant='p' sx={ErrorTextStyle}>This field is required</Typography>}
                        {refundAmountError && <Typography variant='p' sx={ErrorTextStyle}>Refund cannot be more than £{paymentAmount}.</Typography>}
                    </Box>
                    <RHFSelect
                        name='Reason'
                        label='Select reason'
                        SelectProps={{ native: false, sx: { textTransform: 'capitalize', mb: { sm: 0, xs: 0 } } }}
                    >
                        {refundPaymentReason?.map((options, index) => (
                            <MenuItem
                                key={index}
                                value={options.value}
                                sx={SelectMenuItemStyle}
                            >
                                {options.label}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <RHFTextField
                        sx={{ marginLeft: '0', mt: '20px' }}
                        name='Description' multiline rows={3}
                        label='Description'
                        placeholder='Description'
                        InputLabelProps={{ shrink: true }}
                    />
                </Stack>
            </Box>
        </FormProvider>
    );
});

AddRefundPayment.propTypes = {
    handleNext: PropTypes.func,
    leadId: PropTypes.number,
    treatmentId: PropTypes.number,
    paymentId: PropTypes.number,
    checkoutId: PropTypes.number,
    paymentAmount: PropTypes.number,
};

export default AddRefundPayment;