import { dispatch } from '../redux/store';

export function errorHandler(data, handleNext, hasError){
    let message = '';
    Object.entries(data).forEach(([key, value], index) => {
        if(typeof value === 'object'){
            Object.entries(value).forEach(([, innerValue]) => {
                message = `${message + (index === 0 ? '' : ', ')  }${key}: ${innerValue}`; // Get second parameters
            });
        }
        if(typeof value === 'string'){
            message = `${message + (index === 0 ? '' : ', ')  }${key}: ${value}`;
        }
    });
    if(typeof handleNext === 'function') {
        handleNext({
            message,
            success: false
        });
    }
    if(hasError){
        dispatch(hasError(message));
    }
}