import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';

const ClinCheckDatePicker = ({ control, label, name }) => (
    <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
            <>
                <DateTimePicker
                    label={label}
                    value={field.value || null}
                    format={'dd/MM/yyyy HH:mm'}
                    ampm={false}
                    onChange={(newValue) => {
                        field.onChange(newValue);
                    }}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            onKeyDown: (e) => e.preventDefault()
                        },
                    }}
                />
            </>
        )}
    />
);

export default ClinCheckDatePicker;

ClinCheckDatePicker.propTypes = {
    control: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
};