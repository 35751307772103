import { isEmpty } from 'lodash';
import moment from 'moment';
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { dispatch, store } from 'src/redux/store';

export const getTreatment = (isOverview, currentTreatmentDataGlobal, followLead) => {
    if (isOverview) {
        return currentTreatmentDataGlobal?.treatmentData?.id || followLead?.lead_treatments?.[0]?.id;
    }
    return followLead?.lead_treatments?.[0]?.id;
};

export const getTreatmentName = (name) => {
    const { currentTreatmentDataGlobal } = store.getState().multiTreatment;
    if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) return currentTreatmentDataGlobal?.treatmentData?.treatment;
    return name;
};

export const getTreatmentDate = (date) => `${moment(date, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').utc().format('DD MMM YYYY [at] hh:mm a')}`;

export const getData = (treatment, treatmentData, leadDetailData) => {
    if (treatment) {
        return treatmentData;
    }
    return leadDetailData;
};

export const getTreatmentDetails = (key, treatment, leadDetail) => {
    switch (key) {
        case 'id':
            return getData(treatment, treatment?.id, leadDetail?.lead_treatments?.[0]?.id);
        case 'price':
            return getData(treatment, treatment?.price, leadDetail?.lead_treatments?.[0]?.price);
        case 'status':
            return getData(treatment, treatment?.status, leadDetail?.lead_treatments?.[0]?.status);
        case 'treatment':
            return getData(treatment, treatment?.treatment, leadDetail?.lead_treatments?.[0]?.treatment);
        case 'count':
            return getData(treatment, treatment?.lead_contacted_counts?.find(item => item.status === treatment?.status)?.count || 0, leadDetail?.lead_treatments?.[0]?.lead_contacted_counts?.find(item => item.status === leadDetail?.lead_treatments?.[0]?.status)?.count || 0);
        case 'clinCheck':
            return getData(treatment, treatment?.leadtreatment_clincheck, leadDetail?.lead_treatments?.[0]?.leadtreatment_clincheck);
        default:
            return '';
    }
};

export const handleSetTreatmentDataGlobal = ({ leadDetail, treatment, setCurrentTreatmentData }) => {
    if (treatment) {
        const payload = {
            lead_id: leadDetail?.id || '',
            first_name: leadDetail.first_name || '',
            last_name: leadDetail.last_name || '',
            treatmentData: treatment?.lead_treatment
        };
        setCurrentTreatmentData(payload);
        dispatch(setCurrentTreatmentDataGlobal(payload));
    } else {
        setCurrentTreatmentData({});
        dispatch(setCurrentTreatmentDataGlobal(null));
    }
};