import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Typography, CircularProgress } from '@mui/material';
// other libraries
import { isEmpty } from 'lodash';
// custom components
import { Iconify } from '../index';
// hooks
import useGetWidgetCounter, { useGetWidgetTeethCounter } from '../../hooks/useGetWidgetCounter';
// redux
import { useSelector } from '../../redux/store';
// utils
import { getTextColor } from '../../utils/getClassName';
import { getSelectedWidgetStyle } from '../../utils/reportWidgetUtils';
// style
import { grey } from '../../theme/palette';
import {
    widgetCardWrapperStyle,
    widgetCounterTextStyle,
    widgetCounterWrapperStyle,
    widgetTextStyle,
    widgetCardStyle,
    widgetIconWrapper,
    widgetLoadingStyle,
} from '../../styles/widgetCounterStyle';

const CustomWidgetCard = ({ widgetArray, handleWidgetClick, currentWidget, counter }) => {
    const { tabCounter } = useGetWidgetCounter(counter);
    const { tabTeethCounter } = useGetWidgetTeethCounter(counter);
    const { treatmentsListForWidget: { results } } = useSelector((state) => state.practiceTreatment);

    const {widgetCounter} = useSelector((state) => state.widgetCounter);
    const [showLoading, setShowLoading] = useState({});

    const checkValidCount = (key) => {
            const res = tabCounter(key)?.[0]?.count;
            if (res === undefined || res === 0 || res === null) {
                setShowLoading((prev) => ({ ...prev, [key]: true }));
                setTimeout(() => {
                    setShowLoading((prev) => ({ ...prev, [key]: false }));
                }, 500);
            } else {
                setShowLoading((prev) => ({ ...prev, [key]: false }));
            }
    };


    const getTreatmentIcon = (treatment) => {
        if (!isEmpty(results)) {
            const icon = results.find((ele) => ele?.treatment_item?.name?.toLowerCase()?.includes(treatment))?.icon;
            return icon;
        }
        return null;
    };

    const getTreatmentCardStyle = (element) => {
        if (!isEmpty(results) && element.isTreatment) {
            const color = results.find((ele) => ele?.treatment_item?.name?.toLowerCase()?.includes(element.value))?.colour;
            if (color) {
                return { backgroundColor: color, color: getTextColor(color) };
            }
        }
        return {};
    };

    const getTreatmentColor = (element) => {
        if (!isEmpty(results) && element.isTreatment) {
            const color = results.find((ele) => ele?.treatment_item?.name?.toLowerCase()?.includes(element.value))?.colour;
            if (color) {
                return getTextColor(color);
            }
            return grey[800];
        }
        return grey[800];
    };

    const filteredWidgetCounter = widgetArray?.filter((ele) => (!ele.isTreatment || ele.isTreatment && results?.some((el) => el?.treatment_item?.name?.toLowerCase()?.includes(ele?.value))));

    useEffect(() => {
        filteredWidgetCounter.forEach((widget) => {
            checkValidCount(widget.key);
        });
    }, [counter, widgetCounter]);


    return <Box sx={widgetCardWrapperStyle}>
        {
            filteredWidgetCounter?.map((ele) => {
                const isLoading = showLoading[ele.key];
                const teethCount = tabTeethCounter(ele.key)?.[0]?.teethCount || 0;
                return <Box
                    key={ele.key}
                    sx={{ ...widgetCardStyle, width: `calc((100% - ${filteredWidgetCounter?.length - 1 || 80}px) / ${filteredWidgetCounter?.length || 6})`, ...getSelectedWidgetStyle(ele.value, currentWidget, getTreatmentColor(ele)), ...getTreatmentCardStyle(ele) }}
                    onClick={() => handleWidgetClick(ele.value, ele.key)}
                >
                    {
                        isLoading ? <Box sx={{ ...widgetLoadingStyle, minHeight: counter ? "80px" : "60px" }}>
                            <CircularProgress color="inherit" />
                        </Box> : (
                            <>
                                <Box sx={widgetCounterWrapperStyle}>
                                    <Typography sx={widgetCounterTextStyle}>{ele.isPrice ? `£${tabCounter(ele.key)?.[0]?.count || 0}` : tabCounter(ele.key)?.[0]?.count || 0}</Typography>
                                    {!ele.isPrice && teethCount ? <Typography sx={{ ...widgetCounterTextStyle, fontSize: "12px", whiteSpace: "nowrap", fontWeight: "200" }}>{`Teeth Count: ${teethCount}`}</Typography> : null}
                                    {ele.isTreatment && getTreatmentIcon(ele.value) && <Iconify icon={getTreatmentIcon(ele.value)} width={24} height={24} sx={{ color: ele.textColor }} />}
                                    {!ele.isTreatment && ele.icon && <Box sx={{ ...widgetIconWrapper, bgcolor: ele.bgColor || '#D0F2FFCC' }}>
                                        <Iconify icon={ele.icon} width={20} height={20} sx={{ color: ele.textColor }} />
                                    </Box>}
                                </Box>
                                <Typography sx={widgetTextStyle}>{ele.text}</Typography>
                            </>
                        )
                    }

                </Box>
            }
            )}
    </Box>;
};

export default CustomWidgetCard;

CustomWidgetCard.propTypes = {
    handleWidgetClick: PropTypes.func,
    widgetArray: PropTypes.any,
    currentWidget: PropTypes.string,
    counter: PropTypes.any,
};