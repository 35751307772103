import React, { useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Box, Divider, FormControlLabel, Switch, Typography, Chip, Menu, MenuItem, Button, ListItemText, IconButton } from '@mui/material';
import { m } from 'framer-motion';
import { isEmpty } from 'lodash';
// component
import { Iconify } from '../../../components';
import { varHover } from '../../../components/animate';
import { ConfirmDialog } from '../../../components/dialogPopUp';
// constants
import { options } from '../../../constants/MessageListConst';
// style
import { detailBox, detailBoxTitle, detailListBox, detailToolBarChipStyle, detailTreatmentChipBox } from '../../../styles/MessageListStyle';

const MessageDetailToolbar = ({ sequenceName, treatmentList, updateSequenceStatus, isAdmin, deleteSequenceStatus }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);

    const open = Boolean(anchorEl);
    const confirmSequence = sequenceName.sequence;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenConfirm(true);
    };

    const handleCloseConfirm = (data) => {
        deleteSequenceStatus(data);
        setOpenConfirm(false);
    };

    return (
        <>
            <Box sx={detailBox}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                    <Typography
                        variant='h5'
                        sx={{ mr: 1 }}
                    >
                        {confirmSequence && confirmSequence.name || 'Templates'}
                    </Typography>
                    {(confirmSequence && confirmSequence.name && isAdmin) &&
                        <IconButton
                            component={m.button}
                            whileTap='tap'
                            whileHover='hover'
                            variants={varHover(1.05)}
                            aria-label='more'
                            id='long-button'
                            aria-haspopup='true'
                            onClick={handleClick}
                        >
                            <Iconify icon={'eva:more-vertical-outline'} width={20} height={20} />
                        </IconButton>
                    }
                    <Menu
                        id='long-menu'
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        sx={{ width: '200px' }}
                    >
                        {options.map((option) => (
                            <MenuItem
                                key={option}
                                sx={{ color: 'rgba(255, 72, 66, 1)' }}
                                onClick={() => handleClose(sequenceName)}
                            >
                                <Iconify
                                    icon={option.icon}
                                    sx={{ mr: 1 }}
                                />
                                <ListItemText
                                    primary={option.value}
                                    primaryTypographyProps={{
                                        fontWeight: 600,
                                        fontSize: 14
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                {isAdmin && confirmSequence && confirmSequence.name &&
                    <FormControlLabel
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        control={
                            <Switch
                                checked={sequenceName.is_enabled || false}
                                onChange={() => updateSequenceStatus(!sequenceName.is_enabled)}
                                color='primary'
                            />
                        }
                        label={'Active'}
                    />
                }
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <Box>
                    <Typography
                        variant='subtitle2'
                        sx={detailBoxTitle}
                    >
                        Treatments using this sequence
                    </Typography>
                </Box>
                <Box sx={detailListBox}>
                    <Box sx={detailTreatmentChipBox} >
                        {!isEmpty(sequenceName) && treatmentList.map((item, index) => (
                            <Chip
                                key={index}
                                label={item}
                                sx={detailToolBarChipStyle}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
            <ConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title='Delete'
                content='Are you sure want to delete?'
                action={
                    <Button variant='contained' color='error' onClick={() => handleCloseConfirm(sequenceName)}>
                        Delete
                    </Button>
                }
            />
        </>
    );
};

export default MessageDetailToolbar;

MessageDetailToolbar.propTypes = {
    sequenceName: PropTypes.string,
    treatmentList: PropTypes.array,
    updateSequenceStatus: PropTypes.func,
    isAdmin: PropTypes.bool,
    deleteSequenceStatus: PropTypes.func,
};