export const reportTitleStyle = {
    fontSize: { sm: '32px !important', xs: '24px !important' },
    lineHeight: 'normal',
    mb: '24px',
    minHeight: '66px',
    display: 'flex',
    alignItems: 'center'
};

export const cardStyle = {
    padding: '0 24px',
    mb: '24px',
    width: '100%',
    '@media(max-width: 1200px)': {
        width: '100%',
        order: '0'
    }
};