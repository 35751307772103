import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Tab, Tabs, TextField, InputAdornment } from '@mui/material';
import { isArray, isEmpty, uniq } from 'lodash';
import { useLocation } from 'react-router';
// components
import { Iconify, Label } from '../../../components';
// constant 
import { LEAD_STATUS, TEMPLATE_STATUS } from '../../../constants/MessageListConst';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { saveNewCampaign } from '../../../redux/slices/campaign';
import {
    getTemplateList,
    setSearchTemplate
} from '../../../redux/slices/practiceMessage';
import { getSearchTemplateList, handleLoading } from '../../../redux/api/practiceMessageApis';
// styles
import { textFieldStyle } from '../../../styles/NewLead';

const MessageToolbar = ({
    messageData,
    filterData,
    type,
    campaign,
    filterStatus,
    onChangeFilterStatus,
    debouncedValue
}) => {

    const TABLE_HEAD = [];
    const { newCampaign } = useSelector((state) => state.campaign);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { search } = useSelector((state) => state.practiceMessage);
    const [toolHead, setToolHead] = useState([]);
    const { pathname } = useLocation();

    useEffect(() => {
        if (!isEmpty(debouncedValue)) {
            dispatch(getSearchTemplateList(practiceId, encodeURIComponent(debouncedValue), 'messageTemplates'));
        }
        if (search === '') {
            const isMessagePath = pathname.includes('message');
            dispatch(getTemplateList(practiceId, isMessagePath));
        }
    }, [debouncedValue]);

    useEffect(() => {
        setToolHead([]);
        if (messageData.length > 0) {
            messageData.filter((data) => {
                const headData = ((type === 'Practice_Message' || type === 'OnBoarding') ? data.message.lead_status.toLowerCase() : data.category.toLowerCase());
                TABLE_HEAD.push(headData.replaceAll('_', ' '));
                setToolHead(uniq(TABLE_HEAD));
                return true;
            });
        }
    }, [messageData]);

    const filterToolBar = (data) => {
        if (data.length > 0) {
            return ((type === 'Practice_Message' || type === 'OnBoarding') ? LEAD_STATUS : TEMPLATE_STATUS).filter((id1) => data.some((id2) => id2 === id1));
        }
        return data;
    };

    const getTabData = ({ filterList }) => {
        if (type === 'Practice_Message' || type === 'OnBoarding') {
            return filterList[0];
        }
        if (campaign) {
            return newCampaign?.engagement_template_tab;
        }
        return 'All';
    };

    useEffect(() => {
        if (isArray(toolHead) && toolHead.length > 0) {
            const filterList = ((type === 'Practice_Message' || type === 'OnBoarding') ? LEAD_STATUS : TEMPLATE_STATUS).filter((id1) => toolHead.some((id2) => id2 === id1));
            handleChangeTab('', getTabData({ filterList }));
        }
    }, [toolHead]);

    const getStatusCount = (data, status) => {
        let value = '';
        if (type === 'Practice_Message' || type === 'OnBoarding') {
            if (data.length > 0) {
                return data.filter(el =>
                    el.message
                    && el.message.lead_status
                    && el.message.lead_status.toLowerCase().replaceAll('_', ' ') === status.toLowerCase()).length;
            }
            value = data.length;
        }
        if (data.length > 0 && status.length > 0) {
            return data.filter(el =>
                el.category
                && el.category
                && el.category.toLowerCase() === status.toLowerCase()).length;
        }
        value = data.length;
        return value;
    };

    const handleChangeTab = (event, newValue) => {
        filterData(newValue);
        onChangeFilterStatus(event, newValue);
        if (campaign) {
            dispatch(saveNewCampaign({ engagement_template_tab: newValue }));
        }
    };

    const handleSearchEvent = (value) => {
        handleLoading(true);
        dispatch(setSearchTemplate(value));
    };

    const getTabColor = ({ tab }) => {
        switch (tab) {
            case 'communication':
            case 'promotion':
                return 'error';
            case 'marketing':
                return 'warning';
            default:
                return 'primary';
        }
    };

    return (
        <Box
            mb={2}
        >
            <Tabs
                allowScrollButtonsMobile
                variant='scrollable'
                value={filterStatus.length > 0 && filterStatus}
                onChange={handleChangeTab}
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
                sx={{ px: 2, bgcolor: 'background.neutral', ' .MuiButtonBase-root:first-of-type.Mui-disabled ': { display: 'none' } }}
            >
                {(type !== 'Practice_Message' && type !== 'OnBoarding') && <Tab
                    sx={{
                        minWidth: {
                            xs: 'auto',
                            sm: 'auto'
                        },
                        'span': {
                            mr: 1
                        }
                    }}
                    disableRipple
                    label={'All'}
                    value={'All'}
                    icon={<Label color={'info'} > {getStatusCount(messageData, '')} </Label>}
                />}
                {filterToolBar(toolHead).length && filterToolBar(toolHead).map((tab, index) => (
                    <Tab
                        sx={{
                            minWidth: '17%',
                            'span': {
                                mr: 1
                            }
                        }}
                        disableRipple
                        key={index}
                        label={tab}
                        icon={<Label color={getTabColor({ tab })} > {getStatusCount(messageData, tab)} </Label>}
                        value={tab}
                    />
                ))}
            </Tabs>
            {type === 'Template' && <Box sx={{ width: 'calc(100% - 32px)', m: '16px auto 16px' }}>
                <TextField
                    fullWidth
                    onChange={(event) => handleSearchEvent(event.target.value)}
                    value={search}
                    sx={textFieldStyle}
                    placeholder='Search templates'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>}
        </Box>
    );
};

export default MessageToolbar;

MessageToolbar.propTypes = {
    messageData: PropTypes.array,
    filterData: PropTypes.func,
    type: PropTypes.string,
    campaign: PropTypes.bool,
    filterStatus: PropTypes.string,
    onChangeFilterStatus: PropTypes.func,
    debouncedValue: PropTypes.string,
};