export const campaignConst = [
    { title: 'New lead', value: 'NEW' },
    { title: 'Consultation Booked Lead', value: 'CONSULTATION_BOOKED' },
    { title: 'Consultation Attended Lead', value: 'CONSULTATION_ATTENDED' },
    { title: 'Treatment Started Lead', value: 'TREATMENT_STARTED', },
    { title: 'Failed to attend Lead', value: 'FAILED_TO_ATTEND_CONSULTATION', },
    { title: 'Completed Lead', value: 'CLOSED', },
];

export const AUDIENCE_PREVIEW_TABLE_CONST = [
    { id: 'date', label: 'Date', dateFormat: true },
    { id: 'first_name', label: 'Name' },
];

export const menuOption = [
    'contact is tagged',
    'contact status is',
    'contact treatment is',
    'contact date range is',
    'contact source referral is',
    'contact practitioner is',
    'contact source type is',
    'contact category is',
];

export const clearNewCampaignKeys = {
    lead_tags__tag__tag__name__in: undefined,
    tagsNames: undefined,
    lead_treatments__status__in: undefined,
    statusNames: undefined,
    lead_treatments__treatment__treatment_item__name__in: undefined,
    treatmentName: undefined,
    lead_source__referral__in: undefined,
    referralName: undefined,
    assignees__user__id__in: undefined,
    practitionerName: undefined,
    created_at__range: undefined,
    dateRange: undefined,
    audienceSelected: null,
    category__in: undefined,
    lead_treatments__treatment__treatment_item__treatment_category__in: undefined,
    source__type: undefined,
    lead_source__type__in: undefined,
};