import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// @mui
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide } from '@mui/material';
// components
import { useSettingsContext } from '../settings/context';
import AudienceCampaign from '../../sections/@dashboard/campaign/AudienceCampaign';
// store
import { dispatch, useSelector } from '../../redux/store';
import { setAudienceConditionListing, updateSelectedAudience } from '../../redux/slices/audienceManager';
// style
import { commonDialogHeaderStyle, onBoardDialogHeader, cancelBoxStyle, dialogTypoStyle, cancelBtnDesign } from '../../styles/DialoguePopup';

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

const OpenAudiencePopup = ({ open, onClose, headerButtons }) => {
    const settings = useSettingsContext();
    const { selectedAudienceData } = useSelector((state) => state.audienceManager);

    const handleClose = () => {
        onClose();
        dispatch(updateSelectedAudience({}));
        dispatch(setAudienceConditionListing([]));
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={{
                zIndex: 1200,
                margin: { xs: '0 -16px', sm: 0 },
            }}
        >
            <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader}>
                <Typography
                    sx={dialogTypoStyle}
                    variant='h5'
                    component='div'
                >
                    {Object.values(selectedAudienceData)?.length > 0 ? `Update Audience ${selectedAudienceData?.name}` : 'Create Audience'}
                </Typography>
                {(headerButtons) &&
                    <Box sx={cancelBoxStyle()}>
                        <Button
                            color='inherit'
                            variant='outlined'
                            style={cancelBtnDesign}
                            onClick={() => { handleClose('close'); }}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={{ display: 'inline-block', p: 2.5, pb: 1 }}>
                <Grid container spacing={1} sx={{ width: '100%', ml: 'auto' }}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                        sx={{ '@media(max-width: 767px)': { px: '0' } }}
                    >
                        <Grid justifyContent={'center'}>
                            <Box sx={{ margin: '40px 0 0' }}>
                                <AudienceCampaign type={'AudiencePopup'} onClose={onClose} />
                            </Box>
                        </Grid>
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
};

export default OpenAudiencePopup;

OpenAudiencePopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    headerButtons: PropTypes.bool,
};