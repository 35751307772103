import PropTypes from 'prop-types';
import { DialogContent, FormControlLabel, Switch, TablePagination } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { getDuplicateLeads, getSimilarDuplicateLeads } from '../../../redux/slices/duplicateLeads';
import { dispatch, useSelector } from '../../../redux/store';
import SimilarLeadsTable from '../../../components/duplicateLeads/SimilarLeadsTable';
import { formControlLabelStyle } from '../../../styles/DuplicateLeadStyle';
import { useTable } from '../../../hooks';
import { TableLoading } from '../../../components/loading';

const SimilarLeadPopupContent = ({ leadId, handleCloseSimilarLeads, handleComplete, overviewTab, checked }) => {
    const {
        dense,
        page,
        rowsPerPage,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { duplicateLeadsData, similarLeads: { count } } = useSelector((state) => state.duplicate);

    useEffect(() => {
        if (practiceId && leadId) {
            const payload = {
                practiceId, limit: 10, offset: 0,
                needArchivedData: checked,
            };
            dispatch(getSimilarDuplicateLeads({ ...payload, leadId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId, practiceId]);

    const handleChangePage = (event, newPage) => {
        onChangePage(event, newPage);
        const payload = { practiceId, limit: rowsPerPage, offset: rowsPerPage * newPage, needArchivedData: checked, };
        dispatch(getSimilarDuplicateLeads({ ...payload, leadId }));
    };

    const handleChangePerPage = (event) => {
        onChangeRowsPerPage(event);
        const payload = { practiceId, limit: event?.target?.value, offset: 0, needArchivedData: checked, };
        dispatch(getSimilarDuplicateLeads({ ...payload, leadId }));
    };

    const handleClose = (notCallSimilar) => {
        const payload = { practiceId, limit: 10, offset: 0, needArchivedData: checked, };
        dispatch(getDuplicateLeads(payload, handleComplete));
        if (notCallSimilar) return;
        dispatch(getSimilarDuplicateLeads({ ...payload, leadId }));
    };

    return <DialogContent>
        <Box sx={{ position: 'relative' }}>
            <TableLoading tableData={duplicateLeadsData === null && 'No Found'} pageCount={duplicateLeadsData === null && 'No Found'} type={'table'} />
            <Box>
                <SimilarLeadsTable checked={checked} handleClose={handleClose} dense={dense} rowsPerPage={rowsPerPage} page={page} handleCloseSimilarLeads={handleCloseSimilarLeads} overviewTab={overviewTab} />
            </Box>
            <Box sx={{ position: 'relative', mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {count > 0 && <FormControlLabel
                        control={<Switch checked={dense} onChange={onChangeDense} />}
                        label="Dense"
                        sx={formControlLabelStyle}
                    />}
                    {count > 10 && <TablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                        onRowsPerPageChange={(event) => handleChangePerPage(event)}
                    />}
                </Box>
            </Box>
        </Box>
    </DialogContent>;
};


export default SimilarLeadPopupContent;

SimilarLeadPopupContent.propTypes = {
    leadId: PropTypes.number,
    handleCloseSimilarLeads: PropTypes.func,
    handleComplete: PropTypes.func,
    overviewTab: PropTypes.string,
    checked: PropTypes.bool,
};