import React from 'react';
// libraries
import { Box, Button, Tooltip, Typography, Badge, MenuItem, useTheme, Chip, ButtonGroupButtonContext } from '@mui/material';
import moment from 'moment';
// components
import { Iconify, Label } from '../../../../../components';
import { DataGridTableMoreMenu } from '../../../../../components/table';
import { RHFSelect, FormProvider } from '../../../../../components/hook-form';
import CustomTreatmentNameCell from '../CustomTreatmentNameCell';
import CustomViewDetailComponent from '../CustomViewDetailComponent';
import CustomArchivingButton from '../CustomArchivingButton';
import CustomFollowUpBtn from '../CustomFollowUpBtn';
import CustomCompleteTreatmentBtn from '../CustomCompleteTreatmentBtn';
import CustomFollowUpBtnV2 from '../CustomFollowUpBtnV2';
import RecordAttendanceBtnPopper from '../RecordAttendanceBtnPopper';
import ReferralField from '../ReferralField';
import { LastNoteCell, StatusCell, TagCell } from '../tableCells';
import { CustomAvatar } from '../../../../../components/custom-avatar';
import { CompleteTreatmentMenuItem, StartTreatmentMenuItem } from '../tableMenuItems';
// redux
import { useSelector } from '../../../../../redux/store';
// utils
import { getTreatmentCellClassName } from '../../../../../utils/getClassName';
// utils/constants
import { showArchivedDate } from '../../../../../utils/dataGridUtils';
import { formatData } from '../../../../../utils/leadTableUtils';
import { DATE_TITLES } from '../../../../../constants/newLead';
import { actionColumnData, referralFieldColumnData, treatmentFieldColumnData } from '../../../../../constants/dataGridConstants';
// style
import { grey } from '../../../../../theme/palette';
import {
    badgeStyle,
    recordAttendedButtonStyle,
    tableMoreMenuBoxStyle,
    chipStyle,
    tableCellInnerBoxStyle,
    arrowButtonStyle,
    dateTimeTypoStyle,
    dateOfEnquiryLabelStyle,
} from '../../../../../styles/NewLeadTableRowStyle';
import { selectFilter, selectMenuStyle, dateWrapperStyle } from '../../../../../styles/NewLead';

const ArchivedLeadsColumns = ({
    methods,
    handleChangeDateFormat,
    setValue,
    handleArchivedNavigate,
    typed,
    handleTreatmentClick,
    toggleCell,
    handleRestore,
    commonTagsProops,
    archiveViewDetails,
    openMenu,
    menuCurrentTarget,
    handleOpenMenu,
    setOpenMenuActions,
    isReportData,
    anchorMoreMenuRef,
    setOpen,
    open,
    handleAttendedClick,
    buttonHandleClose,
    handleAttendedFailedClick,
    handleAttendedPopup,
    viewDetailBtn,
    handlePopup,
    handleViewDetailClick,
    handleStartTreatment,
    editAppointmentMenuItem,
    markUnMarkDuplicateMenuItem,
    backToAttendedMenuItem,
    archiveLeadMenuItem,
    apiRef,
    handleCompleteTreatment
}) => {

    const theme = useTheme();
    const currentDate = moment().format('DD-MM-YYYY');

    const { dateArchiveFormat } = useSelector((state) => state.lead);
    const { reasons } = useSelector((state) => state.schema);

    const commonNameComponent = ({ row, name }) =>
        <Box onClick={() => handleArchivedNavigate(row)} sx={tableCellInnerBoxStyle}>
            <Typography variant='subtitle2'>
                <span style={{ textTransform: 'capitalize' }}>{name || ''}</span>
            </Typography>
        </Box>;

    const getMenuItems = (params) => {
        if (params.row.is_archive_pending) {
            return <CustomArchivingButton params={params} />;
        }
        if (params.row.status === 'CONSULTATION_BOOKED' && !params.row.is_archive_pending) {
            return (
                <Box>
                    <Box>
                        <ButtonGroupButtonContext
                            variant='contained'
                            ref={anchorMoreMenuRef}
                            aria-label='split button'
                            sx={{ boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)', minWidth: '185px' }}
                        >
                            <Button onClick={() => setOpen((prevOpen) => !prevOpen)} sx={recordAttendedButtonStyle}>
                                Record Attendance
                            </Button>
                            <Button
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label='select merge strategy'
                                aria-haspopup='menu'
                                onClick={() => setOpen((prevOpen) => !prevOpen)}
                                sx={arrowButtonStyle(theme)}
                            >
                                <Badge
                                    badgeContent={[params.row?.lead_treatments__lead_contacted_counts__count]}
                                    color='primary'
                                    sx={badgeStyle(params.row.status, params.row?.lead_treatments__lead_contacted_counts__count)}
                                >
                                    <Iconify
                                        style={{ color: grey[0] }}
                                        width={10}
                                        height={10}
                                        icon={'bxs:right-arrow'}
                                    />
                                </Badge>
                            </Button>
                        </ButtonGroupButtonContext>
                    </Box>
                    <RecordAttendanceBtnPopper
                        params={params}
                        anchorEl={anchorMoreMenuRef.current}
                        open={open}
                        handleAttendedClick={handleAttendedClick}
                        handleAttendedFailedClick={handleAttendedFailedClick}
                        buttonHandleClose={buttonHandleClose}
                    />
                </Box>
            );
        }
        if (params.row.status === 'CONSULTATION_ATTENDED' && !params.row.is_archive_pending) {
            return <CustomFollowUpBtn params={params} handleAttendedPopup={handleAttendedPopup} />;
        }
        if (
            (params.row.status === 'CLOSED' && !params.row.is_archive_pending) ||
            (params.row.status === 'TREATMENT_STARTED' && !params.row.is_archive_pending)
        ) {
            return <CustomCompleteTreatmentBtn params={params} viewDetailBtn={viewDetailBtn} />;
        }
        if (params.row.treatment_name === '' || params.row.treatment_name === null) {
            return '';
        }
        return <CustomFollowUpBtnV2 params={params} handlePopup={handlePopup} />;
    };

    const archivedLeadsColumns = [
        {
            field: 'archived_at',
            headerName: (
                <>
                    <Box sx={dateWrapperStyle}>
                        Date of archived
                        <Iconify sx={{ mr: '8px', ml: '8px' }} icon={'mdi:calendar-filter-outline'} width={15} height={15} />
                        <Box sx={selectFilter}>
                            <FormProvider methods={methods}>
                                <RHFSelect
                                    name={'archived_at'}
                                    label='Title'
                                    SelectProps={{ native: false }}
                                    onChange={(e) => handleChangeDateFormat(e, 'archived_at', setValue)}
                                >
                                    {DATE_TITLES.map((option, index) => (
                                        <MenuItem key={index} value={option.value} sx={selectMenuStyle}>
                                            <Iconify sx={{ mr: '8px' }} icon={option.icon} width={18} height={18} />
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                            </FormProvider>
                        </Box>
                    </Box>
                </>
            ),
            width: 220,
            minWidth: 160,
            editable: false,
            sortable: false,
            renderCell: (params) => (
                <Box onClick={() => handleArchivedNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
                    <Label
                        sx={dateOfEnquiryLabelStyle(formatData, params, currentDate, typed)}
                    >
                        <Typography variant='05 Others/Label' dangerouslySetInnerHTML={{ __html: showArchivedDate(dateArchiveFormat, params?.row) }} sx={dateTimeTypoStyle} />
                    </Label>
                </Box>
            ),
        },
        {
            field: 'first_name',
            headerName: 'First name',
            width: 130,
            minWidth: 120,
            editable: false,
            renderCell: (params) => commonNameComponent({ row: params?.row, name: params?.row?.first_name })
        },
        {
            field: 'last_name',
            headerName: 'Last name',
            minWidth: 100,
            editable: false,
            sortable: false,
            renderCell: (params) => commonNameComponent({ row: params?.row, name: params?.row?.last_name })
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: false,
            width: 230,
            minWidth: 100,
            renderCell: (params) => (
                <Tooltip title={`${params?.row?.email}`}>
                    <Box onClick={() => handleArchivedNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
                        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.email || ''}</Box>
                    </Box>
                </Tooltip>
            ),
        },
        {
            field: 'phone',
            headerName: 'Phone',
            sortable: false,
            width: 140,
            minWidth: 120,
            renderCell: (params) => (
                <Box onClick={() => handleArchivedNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
                    <Typography variant='subtitle2' sx={{ fontWeight: '400' }}>
                        {params?.row?.phone || ''}
                    </Typography>
                </Box>
            ),
        },
        {
            ...treatmentFieldColumnData,
            cellClassName: (params) => getTreatmentCellClassName(params?.row?.treatmentBgColor),
            renderCell: (params) => (
                <CustomTreatmentNameCell params={params} handleTreatmentClick={handleTreatmentClick} ref={apiRef} />
            ),
        },
        { ...toggleCell },
        {
            field: 'contact',
            headerName: 'Action',
            sortable: false,
            minWidth: 120,
            align: 'center',
            renderCell: (params) => (
                <MenuItem
                    onClick={() => handleRestore(params.row)}
                    sx={{ ...tableCellInnerBoxStyle, justifyContent: 'center' }}
                >
                    <Iconify icon={'ic:sharp-restore'} sx={{ mr: 1 }} />
                    Restore
                </MenuItem>
            ),
        },
        { ...StatusCell({ onChange: handleArchivedNavigate }) },
        {
            ...referralFieldColumnData,
            renderCell: (params) => (
                <ReferralField
                    params={params}
                    tableType={'archiveLeads'}
                    handleArchivedNavigate={handleArchivedNavigate}
                />
            ),
        },
        {
            field: 'reason',
            headerName: 'Reason',
            sortable: false,
            editable: false,
            minWidth: 100,
            renderCell: (params) => (
                <Box onClick={() => handleArchivedNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
                    <Typography variant='subtitle2'>
                        <span>{reasons?.filter((option) => option.value === params?.row?.reason)?.[0]?.label}</span>
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'archivedBy',
            headerName: 'Archived by',
            sortable: false,
            editable: false,
            width: 130,
            minWidth: 100,
            renderCell: (params) => (
                <Box onClick={() => handleArchivedNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
                    <Tooltip title={`${params?.row?.archivedBy?.first_name} ${params?.row?.archivedBy?.last_name}`}>
                        <CustomAvatar src={params?.row?.archivedBy?.avatar} alt={params?.row?.archivedBy?.first_name} name={params?.row?.archivedBy?.first_name} />
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: 'open',
            headerName: 'Open to communication',
            sortable: false,
            editable: false,
            width: 170,
            minWidth: 100,
            renderCell: (params) => (
                <Box onClick={() => handleArchivedNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
                    {params?.row?.openCommunication === 'Yes' ?
                        <Chip label='Yes' sx={chipStyle('Yes')} /> :
                        <Chip label='No' sx={chipStyle('No')} color='error' />
                    }
                </Box>
            ),
        },
        { ...TagCell({ ...commonTagsProops }) },
        { ...LastNoteCell({ handleArchivedNavigate }) },
        {
            field: 'viewDetails',
            headerName: 'View Detail',
            sortable: false,
            minWidth: 45,
            width: 45,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <MenuItem
                    onClick={() => archiveViewDetails(params)}
                >
                    <Iconify icon={'ant-design:file-search-outlined'} width={22} height={22} />
                </MenuItem>
            ),
        },
        {
            ...actionColumnData,
            getActions: (params) => [
                <div className='action-custom-cell' key={params.row.id}>
                    <DataGridTableMoreMenu
                        open={openMenu === params.row.id}
                        sx={{ width: '180px' }}
                        anchorEl={menuCurrentTarget}
                        type={params.row.status === 'CONSULTATION_BOOKED' ? 'FinancialTableRow' : ''}
                        onOpen={(event) => handleOpenMenu(event, params)}
                        onClose={() => setOpenMenuActions(null)}
                        actions={
                            <>
                                {typed !== 'ArchiveTable' ?
                                    <Box sx={tableMoreMenuBoxStyle}>
                                        {params.row.status !== 'CLOSED' && (typed !== 'ArchiveTable' && isReportData &&
                                            <MenuItem onClick={() => setOpenMenuActions(null)}>
                                                {getMenuItems(params)}
                                            </MenuItem>)}
                                        <CustomViewDetailComponent handleClick={() => handleViewDetailClick(params)} />
                                        <StartTreatmentMenuItem handleStartTreatment={handleStartTreatment} params={params} />
                                        <CompleteTreatmentMenuItem handleCompleteTreatment={handleCompleteTreatment} params={params} />
                                        {editAppointmentMenuItem(params)}
                                        {markUnMarkDuplicateMenuItem(params)}
                                        {backToAttendedMenuItem(params)}
                                        {archiveLeadMenuItem(params)}
                                    </Box> :
                                    <>
                                        <MenuItem onClick={() => handleRestore(params.row)}>
                                            <Iconify icon={'ic:sharp-restore'} />
                                            Restore
                                        </MenuItem>
                                        <MenuItem onClick={() => archiveViewDetails(params)} >
                                            <Iconify icon={'mdi:user-box'} />
                                            View Details
                                        </MenuItem>
                                    </>
                                }
                            </>
                        }
                    />
                </div>
            ]
        }
    ];

    return archivedLeadsColumns;
};

export default ArchivedLeadsColumns;