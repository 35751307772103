import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell } from '@mui/material';
//
import { EmptyContent } from '../empty-content';

// ----------------------------------------------------------------------

export default function TableNoData({ isNotFound }) {
    return (
        <TableRow sx={{ '&:hover td:last-child': { backgroundImage: 'none !important' } }}>
            {isNotFound ? (
                <TableCell colSpan={12}>
                    <EmptyContent
                        title="No Data"
                        sx={{
                            '& span.MuiBox-root': { height: 160 },
                        }}
                    />
                </TableCell>
            ) : (
                <TableCell colSpan={12} sx={{ p: 0 }} />
            )}
        </TableRow>
    );
}

TableNoData.propTypes = {
    isNotFound: PropTypes.bool,
};