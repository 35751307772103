import dayjs from 'dayjs';
import moment from 'moment';
import { addReportEndTime, addReportStartTime, getReportsAppointmentListing, getReportsListing, reportsUserListing, setDateRange, treatmentStartedReports } from '../redux/slices/analytics';
import { dispatch } from '../redux/store';
import { getPracticeTreatmentList } from '../redux/slices/practiceTreatment';
import { getPracticeId } from '../redux/slices/clincheck';
import {
    isLeadArchived,
    treatmentCategory,
    leadSourceType,
    leadSourceReferral,
    AllLeadData,
    AllTreatments,
    categorySummary,
    NOTBOOKED_AND_ENQUIRY_COLUMNS,
} from '../constants/ReportsLeadConst';
import { endDate } from '../constants/ReportSummaryConst';

// const DEFAULT_START_TIME = moment().startOf('month').format('YYYY-MM-DD 00:00:00');
// const DEFAULT_END_TIME = moment(new Date()).format('YYYY-MM-DD 23:59:00');

export const handleStatus = ({ type, setStatus }) => {
    switch (type) {
        case 'NewLeadTable':
            setStatus('NEW');
            break;
        case 'ConsultantAttend':
            setStatus('CONSULTATION_ATTENDED');
            break;
        case 'treatmentStarted':
            setStatus('TREATMENT_STARTED');
            break;
        case 'DashNewLeadTable':
            setStatus('NEW');
            break;
        case 'ConsultantBooked':
            setStatus('CONSULTATION_BOOKED');
            break;
        case 'FailedToAttend':
            setStatus('FAILED_TO_ATTEND_CONSULTATION');
            break;
        case 'Closed':
            setStatus('CLOSED');
            break;
        default:
            break;
    }
};

export const handleTabChange = ({ newValue, setValue, dateChange }) => {
    const today = dayjs();
    switch (newValue) {
        case 'Last 24 Hours':
            setValue([today.subtract(24, 'hours'), today]);
            dateChange([today.subtract(24, 'hours'), today]);
            break;
        case 'Last 7 days':
            setValue([today.subtract(1, 'week'), today]);
            dateChange([today.subtract(1, 'week'), today]);
            break;
        case 'Last 30 days':
            setValue([today.subtract(30, 'days'), today]);
            dateChange([today.subtract(30, 'days'), today]);
            break;
        case 'All time':
            setValue([null, null]);
            dateChange([null, null]);
            break;
        default:
            break;
    }
};

export const addMonths = (date, months) => {
    date.setMonth(date.getMonth() + months);
    return date;
};

export const handleGetReportsListingApi = (changedStartTime, changedEndTime) => {
    NOTBOOKED_AND_ENQUIRY_COLUMNS.forEach((ele) => {
        const detail = { ...ele };
        if (changedStartTime && changedEndTime) {
            detail.startTime = changedStartTime;
            detail.endTime = changedEndTime;
        }
        dispatch(getReportsListing(detail));
    });
};

export const handleGetReportsAppointmentsListing = ({ startTime, endTime }) => {
    const detail = {};
    if (startTime && endTime) {
        detail.startTime = startTime;
        detail.endTime = endTime;
    }
    dispatch(getReportsAppointmentListing(detail));
};

export const handleReports = ({ type, callReportsAPI }) => {
    const practiceId = getPracticeId();
    if (type === 'Reports') {
        dispatch(getPracticeTreatmentList(practiceId));
        const startTime = moment(new Date()).startOf('month').format('YYYY-MM-DD 00:00:00');
        const endTime = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
        // const sourceStartTime = moment(new Date().setFullYear(new Date().getFullYear() - 1)).format('YYYY-MM-DD HH:mm:ss');
        const sourceEndTime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        callReportsAPI(categorySummary, startTime, endTime, 'categorySummary');
        handleGetReportsListingApi(startTime, endTime);
        handleGetReportsAppointmentsListing({ startTime, endTime });
        callReportsAPI(isLeadArchived, startTime, endTime);
        callReportsAPI(treatmentCategory, startTime, endTime);
        callReportsAPI(AllLeadData, startTime, endTime);
        callReportsAPI(AllTreatments, startTime, endTime);
        callReportsAPI(leadSourceType, startTime, sourceEndTime);
        callReportsAPI(leadSourceReferral, startTime, sourceEndTime);
        dispatch(reportsUserListing({ practiceId }));
        dispatch(addReportStartTime(startTime));
        dispatch(addReportEndTime(endTime));
        dispatch(treatmentStartedReports({ practiceId, startTime, endTime }));
    } else if (type === 'DashNewLeadTable' && practiceId) {
        callReportsAPI(isLeadArchived);
        callReportsAPI(AllTreatments);
        callReportsAPI(treatmentCategory);
        callReportsAPI(AllLeadData);
        dispatch(treatmentStartedReports({ practiceId }));
        handleGetReportsAppointmentsListing({});
    } else if (type === 'Dashboard') {
        callReportsAPI(treatmentCategory, '', '');
        dispatch(getPracticeTreatmentList(practiceId));
        dispatch(treatmentStartedReports({ practiceId }));
    }
};

export const handleDateChange = ({ type, setValue, callReportsAPI, newValue }) => {
    const practiceId = getPracticeId();
    setValue(newValue);
    const changedStartTime = newValue[0] ? moment(newValue[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00') : '';
    const changedEndTime = newValue[1] ? moment(newValue[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:00') : '';
    const changedStartDate = newValue[0] ? moment(newValue[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
    const changedEndDate = newValue[1] ? moment(newValue[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
    dispatch(setDateRange({ startDate: changedStartDate, endDate: changedEndDate }));
    callReportsAPI(isLeadArchived, changedStartTime, changedEndTime);
    callReportsAPI(treatmentCategory, changedStartTime, changedEndTime);
    callReportsAPI(AllLeadData, changedStartTime, changedEndTime);
    callReportsAPI(AllTreatments, changedStartTime, changedEndTime);
    callReportsAPI(categorySummary, changedStartTime, changedEndTime, 'categorySummary');
    handleGetReportsListingApi(changedStartTime, changedEndTime);
    handleGetReportsAppointmentsListing({ startTime: changedStartTime, endTime: changedEndTime });
    dispatch(treatmentStartedReports({ practiceId, startTime: changedStartTime, endTime: changedEndTime }));
    if (type === 'Reports') {
        callReportsAPI(leadSourceType, changedStartTime, changedEndTime);
        callReportsAPI(leadSourceReferral, changedStartTime, changedEndTime);
        dispatch(addReportStartTime(changedStartTime));
        dispatch(addReportEndTime(changedEndTime));
    }
};

export const applySortFilter = ({ tableData }) => {
    const stabilizedThis = tableData.map((el, index) => [el, index]);
    tableData = stabilizedThis.map((el) => el[0]);
    return tableData;
};

export const isDisabled = ({ reportsLoading }) => {
    if (reportsLoading) {
        return { pointerEvents: 'none' };
    }
    return {};
};

export const handleClearReportTable = ({ onClose, UpdateTableData, type }) => {
    if (onClose) {
        onClose(true);
    }
    UpdateTableData(type, {
        search: '',
        date: [],
        treatment: [],
        perPage: 10,
        pageNumber: 0,
        tag: [],
        filterTags: [],
        referral: '',
        bookedWith: ''
    });
};

export const processLeadData = (leadData, rowStatus) => {
    const rowData = leadData.map((item) => {
        let latestData = [];
        switch (rowStatus) {
            case 'All':
                latestData =
                    item.lead_treatments.length > 1
                        ? item.lead_treatments
                            .slice()
                            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                        : item.lead_treatments;
                break;
            case 'NEW':
                latestData = item?.lead_treatments.filter((el) => el.status === 'NEW');
                break;
            case 'CONSULTATION_ATTENDED':
                latestData = item.lead_treatments.filter((el) => el.status === 'CONSULTATION_ATTENDED');
                break;
            case 'CONSULTATION_BOOKED':
                latestData = item.lead_treatments.filter((el) => el.status === 'CONSULTATION_BOOKED');
                break;
            case 'FAILED_TO_ATTEND_CONSULTATION':
                latestData = item.lead_treatments.filter((el) => el.status === 'FAILED_TO_ATTEND_CONSULTATION');
                break;
            case 'CLOSED':
                latestData = item.lead_treatments.filter((el) => el.status === 'CLOSED');
                break;
            case 'TREATMENT_STARTED':
                latestData = item.lead_treatments.filter((el) => el.status === 'TREATMENT_STARTED');
                break;
            default:
                latestData = [];
                break;
        }
        return {
            lead_id: item.id,
            email: item?.lead_contact?.email || '',
            phone: item?.lead_contact?.phone || '',
            first_name: item?.first_name || '',
            last_name: item?.last_name || '',
            status: latestData[0] ? latestData[0].status : '',
            treatment_name: latestData[0] ? latestData[0].treatment : '',
            created_at_human: item?.created_at_human || '',
            created_at: item?.created_at || '',
            is_archive_pending: item?.is_archive_pending || false,
            is_archived: item?.is_archived || false,
        };
    });
    return rowData;
};