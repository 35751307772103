import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Tooltip } from '@mui/material';
import { Iconify } from '../../../../../components';
import { FormProvider, RHFSelect } from '../../../../../components/hook-form';
import { DATE_TITLES } from '../../../../../constants/newLead';
import { dateWrapperStyle, selectFilter, selectMenuStyle } from '../../../../../styles/NewLead';

const EnquiryHeaderComponent = ({ methods, handleChangeDateFormat, setValue }) => (
    <Box sx={dateWrapperStyle}>
        Enquiry
        <Iconify sx={{ mr: '8px', ml: '8px' }} icon={'mdi:calendar-filter-outline'} width={15} height={15} />
        <Tooltip placement='bottom' title={'Date of enquiry'}>
            <Box sx={selectFilter}>
                <FormProvider methods={methods}>
                    <RHFSelect
                        name={'create_date'}
                        label='Title'
                        SelectProps={{ native: false }}
                        onChange={(e) => handleChangeDateFormat(e, 'create_date', setValue)}
                    >
                        {DATE_TITLES.map((option, index) => (
                            <MenuItem key={index} value={option.value} sx={selectMenuStyle}>
                                <Iconify sx={{ mr: '8px' }} icon={option.icon} width={18} height={18} />
                                {option.label}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                </FormProvider>
            </Box>
        </Tooltip>
    </Box>
);

export default EnquiryHeaderComponent;

EnquiryHeaderComponent.propTypes = {
    methods: PropTypes.object,
    handleChangeDateFormat: PropTypes.func,
    setValue: PropTypes.func,
};