import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { Iconify } from '..';
import TreatmentStatus from '../../sections/@dashboard/leadDetail/TreatmentStatus';
import { primary } from '../../theme/palette';
import { moreMenuWrapper, treatmentTabToolTip, treatmentTabWrapper, treatmentTypoWrapper } from '../../styles/treatmentTabStyle';

const TreatmentHeader = ({ data, handleMoreMenuClick, handleAppointmentPopup }) => (
    <Box sx={treatmentTabWrapper}>
        <Box sx={treatmentTypoWrapper}>
            <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '23.5px' }}>{data.treatment}</Typography>
            <TreatmentStatus status={data.status} />
        </Box>
        <Box sx={moreMenuWrapper}>
            <Button variant='outlined' onClick={handleMoreMenuClick} sx={treatmentTabToolTip}>
                <Iconify icon={'ri:more-2-fill'} width={24} height={24} />
            </Button>
            <Tooltip title='Book appointment for this treatment'>
                <Button
                    variant='contained'
                    onClick={() => handleAppointmentPopup()}
                    sx={{ ...treatmentTabToolTip, bgcolor: primary.main, }}
                >
                    <Iconify sx={{ mr: '8px' }} icon={'eva:plus-fill'} width={20} height={20} /> Book appointment
                </Button>
            </Tooltip>
        </Box>
    </Box>
);

TreatmentHeader.propTypes = {
    data: PropTypes.object.isRequired,
    handleMoreMenuClick: PropTypes.func.isRequired,
    handleAppointmentPopup: PropTypes.func.isRequired,
};

export default TreatmentHeader;

