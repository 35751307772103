// components
import { Iconify, Image } from '../components';

// ----------------------------------------------------------------------

const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
const FORMAT_VIDEO = ['m4v', 'avi', 'mpg', 'mp4', 'webm'];
const FORMAT_WORD = ['doc', 'docx'];
const FORMAT_EXCEL = ['xls', 'xlsx'];
const FORMAT_POWERPOINT = ['ppt', 'pptx'];
const FORMAT_PDF = ['pdf'];
const FORMAT_PHOTOSHOP = ['psd'];
const FORMAT_ILLUSTRATOR = ['ai', 'esp'];

export function getFileType(fileUrl = '') {
    return (fileUrl && fileUrl.split('.').pop()) || '';
}

export function getFileName(fileUrl) {
    return fileUrl.substring(fileUrl.lastIndexOf('/') + 1).replace(/\.[^/.]+$/, '');
}

export function getFileFormat(fileUrl) {
    switch (fileUrl.includes(getFileType(fileUrl))) {
        case FORMAT_IMG.includes(getFileType(fileUrl)):
            return 'image';
        case FORMAT_VIDEO.includes(getFileType(fileUrl)):
            return 'video';
        case FORMAT_WORD.includes(getFileType(fileUrl)):
            return 'word';
        case FORMAT_EXCEL.includes(getFileType(fileUrl)):
            return 'excel';
        case FORMAT_POWERPOINT.includes(getFileType(fileUrl)):
            return 'powerpoint';
        case FORMAT_PDF.includes(getFileType(fileUrl)):
            return 'pdf';
        case FORMAT_PHOTOSHOP.includes(getFileType(fileUrl)):
            return 'photoshop';
        case FORMAT_ILLUSTRATOR.includes(getFileType(fileUrl)):
            return 'illustrator';
        default:
            return getFileType(fileUrl);
    }
}

const getIcon = (name) => (
    <Image
        src={`https://minimal-assets-api-dev.vercel.app/assets/icons/file/${name}.svg`}
        alt={name}
        sx={{ width: 28, height: 28 }}
    />
);

export function getFileThumb(fileUrl) {
    switch (getFileFormat(fileUrl)) {
        case 'video':
            return getIcon('format_video');
        case 'word':
            return getIcon('format_word');
        case 'excel':
            return getIcon('format_excel');
        case 'powerpoint':
            return getIcon('format_powerpoint');
        case 'pdf':
            return getIcon('format_pdf');
        case 'photoshop':
            return getIcon('format_photoshop');
        case 'illustrator':
            return getIcon('format_ai');
        case 'image':
            return <Image src={fileUrl} alt={fileUrl} sx={{ height: 1 }} />;
        default:
            return <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28 }} />;
    }
}
