import React, { useEffect, useState, } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Portal, Backdrop, IconButton, Typography, styled } from '@mui/material';
// components
import { Iconify } from '../../components';
import { MessageList, EmailMessageCompose, SMSMessageCompose } from '../../sections/@dashboard/message';
// Store
import { getAutoMatedMessages } from '../../redux/slices/lead';
import { getActivities } from '../../redux/slices/activity';
import { getTemplateList } from '../../redux/slices/practiceMessage';
import { dispatch, useSelector } from '../../redux/store';
import { sendEngagementEmail, sendTemplateEmail } from '../../redux/api/practiceMessageApis';
// utils/hooks
import { applyStylesToTags } from '../../utils/applyStylesToTags ';
import { useResponsive, useAdminUser } from '../../hooks';
// styles
import {
    backDropStyle,
    RootSetStyle,
    openTamplateBoxStyle,
    typographyStyle,
    iconBtnBoxStyle,
    iconBtnStyle
} from '../../styles/leadTemplate';

const RootStyle = styled('div')(({ theme }) => ({
    right: 0,
    bottom: 0,
    zIndex: 1119,
    minHeight: 440,
    outline: 'none',
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    flexDirection: 'column',
    margin: theme.spacing(3),
    boxShadow: theme.customShadows.z20,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    backgroundColor: theme.palette.background.paper,
}));

const LeadTemplate = ({
    openEmailCompose,
    onCloseEmailCompose,
    practiceId,
    type,
    leadDetail,
    screen,
    consentLink,
    bindData,
}) => {
    const [fullScreen, setFullScreen] = useState(false);
    const [templateOpen, setTemplateOpen] = useState(false);
    const [template, setTemplateList] = useState([]);
    const [message, setMessage] = useState({});
    const [sendTemplate, setSendTemplate] = useState(false);
    const [useTemplate, setUseTemplate] = useState(bindData || false);
    const { templateDetails, userTemplateDetails } = useSelector((state) => state.practiceMessage);
    const { templateDetails: superAdminTemplateDetails } = useSelector((state) => state.superAdmin);
    const isDesktop = useResponsive('up', 'sm');
    const { isAdminUser } = useAdminUser();

    useEffect(() => {
        if (practiceId) {
            dispatch(getTemplateList(practiceId, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    useEffect(() => {
        if (Object.values(message).length > 0) {
            setSendTemplate(true);
        }
    }, [message]);

    useEffect(() => {
        if (templateDetails && templateDetails.results) {
            const data = templateDetails.results.filter(el =>
                el.channel
                && el.channel
                && el.channel.toLowerCase() === type.toLowerCase());
            setTemplateList(data);
        }
    }, [templateDetails, type]);

    useEffect(() => {
        if (useTemplate && (userTemplateDetails || superAdminTemplateDetails)) {
            const template = Object.entries(userTemplateDetails).length === 0 ? superAdminTemplateDetails : userTemplateDetails;
            const data = {
                ...template,
                body: `<p> ${template.body} ${consentLink?.body || ''}</p>`,
                superAdmin: Object.entries(userTemplateDetails).length > 0
            };
            setMessage(data);
        }
    }, [consentLink, useTemplate, userTemplateDetails, superAdminTemplateDetails]);

    const handleClose = () => {
        onCloseEmailCompose(false);
        setFullScreen(false);
    };

    if (openEmailCompose) {
        setTimeout(() => {
            document.body.style.overflow = 'hidden';
        }, 600);
    }

    const handleSendEmail = (data, leadData) => {
        const content = data?.body;
        const modifiedContent = applyStylesToTags(content);
        if (Object.values(message).length > 0 && message.superAdmin) {
            const detail = {
                lead_treatment: (leadDetail || leadData)?.lead_treatments && (leadDetail || leadData)?.lead_treatments[0]?.id || leadDetail?.lead_treatment?.id,
                category: data.category,
                channel: data.channel,
                body: modifiedContent,
                subject: data.subject,
                attachments: data.attachments
            };
            dispatch(sendEngagementEmail({ id: message.id, detail, callback: handleSuccess, data: leadDetail || leadData }));
        } else {
            const detail = {
                lead: leadDetail?.lead?.id || (leadDetail || leadData)?.id,
                category: data.category,
                channel: data.channel,
                body: modifiedContent,
                subject: data.subject,
                attachments: data.attachments
            };
            dispatch(sendTemplateEmail({ detail, handleClose: handleSuccess, data: leadDetail || leadData }));
        }
    };

    const handleSuccess = (data) => {
        onCloseEmailCompose(false);
        setTimeout(() => {
            dispatch(getAutoMatedMessages(practiceId, data?.lead?.id || data.id));
            dispatch(getActivities(practiceId, data?.lead?.id || data.id, 10, 0));
        }, 5000);
    };

    const handleSetCompose = (compose) => {
        const data = {
            ...userTemplateDetails,
            body: `<p> ${compose.body} ${consentLink?.body || ''}</p>`
        };
        setMessage(data);
    };

    return (
        <Portal>
            <Backdrop open={fullScreen || !isDesktop} sx={backDropStyle(screen)} />
            <RootStyle sx={RootSetStyle(screen, fullScreen)}>
                <Box>
                    {templateOpen && <Box sx={openTamplateBoxStyle}>
                        <Typography onClick={() => setTemplateOpen(false)} sx={typographyStyle}>
                            <IconButton onClick={() => setTemplateOpen(false)}>
                                <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
                            </IconButton>  Templates
                        </Typography>
                        <Box sx={iconBtnBoxStyle}>
                            <IconButton onClick={fullScreen ? () => setFullScreen(false) : () => setFullScreen(true)} sx={iconBtnStyle}>
                                <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} width={20} height={20} />
                            </IconButton>
                            <IconButton onClick={handleClose}>
                                <Iconify icon={'eva:close-fill'} width={20} height={20} />
                            </IconButton>
                        </Box>
                    </Box>}
                    {templateOpen ?
                        <MessageList
                            messageData={template}
                            sequenceName={null}
                            setSequenceName={null}
                            treatmentList={[]}
                            practiceId={practiceId}
                            openEmailCompose={openEmailCompose}
                            sequenceId={null}
                            isAdmin={isAdminUser()}
                            messageDetail={userTemplateDetails || {}}
                            type={'LeadTemplate'}
                            useOrSend={'use'}
                            setTemplate={handleSetCompose}
                            setTemplateOpen={setTemplateOpen}
                            composeType={type}
                            OpenTemplate={() => setTemplateOpen(true)}
                            screen={screen}
                            fullScreen={fullScreen}
                            setFullScreen={setFullScreen}
                            setUseTemplate={setUseTemplate}
                        /> :
                        <Box>
                            {type === 'EMAIL' &&
                                <EmailMessageCompose
                                    isEdit
                                    treatmentNames={[]}
                                    type='email_editor'
                                    openEmailCompose={openEmailCompose}
                                    composeDetail={Object.values(message).length > 0 ? message : (consentLink || {})}
                                    onCloseEmailCompose={() => onCloseEmailCompose(false)}
                                    messageType={'LeadTemplate'}
                                    practiceId={practiceId}
                                    OpenTemplate={() => setTemplateOpen(true)}
                                    useOrSend={'send'}
                                    handleSendEmail={handleSendEmail}
                                    leadDetail={leadDetail}
                                    sendTemplate={sendTemplate}
                                    screen={screen}
                                />
                            }
                            {type === 'SMS' &&
                                <SMSMessageCompose
                                    isEdit
                                    openSMSCompose={openEmailCompose}
                                    onCloseSMSCompose={() => onCloseEmailCompose(false)}
                                    composeDetail={message}
                                    messageType={'LeadTemplate'}
                                    type='sms_editor'
                                    practiceId={practiceId}
                                    OpenTemplate={() => setTemplateOpen(true)}
                                    useOrSend={'send'}
                                    handleSendEmail={handleSendEmail}
                                    sendTemplate={sendTemplate}
                                />}
                        </Box>
                    }
                </Box>
            </RootStyle>
        </Portal>
    );
};

LeadTemplate.propTypes = {
    openEmailCompose: PropTypes.bool,
    onCloseEmailCompose: PropTypes.func,
    practiceId: PropTypes.string,
    type: PropTypes.string,
    leadDetail: PropTypes.object,
    screen: PropTypes.string,
    consentLink: PropTypes.object,
    bindData: PropTypes.bool
};

export default LeadTemplate;