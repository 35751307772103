import React from 'react';
import PropType from 'prop-types';
import { MenuItem } from '@mui/material';
import { Iconify } from '../../../../components';

const CustomViewDetailComponent = ({ handleClick }) => (
    <MenuItem
        onClick={handleClick}
    >
        <Iconify icon={'mdi:user-box'} />
        View Details
    </MenuItem>
);

export default CustomViewDetailComponent;

CustomViewDetailComponent.propTypes = {
    handleClick: PropType.func,
};
