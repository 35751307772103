import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
//
import NavItem from './NavItem';
import { PaperStyle } from './style';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';

// ----------------------------------------------------------------------

export default function NavList({ data, depth, hasChildren }) {
    const menuRef = useRef(null);

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const { active, isExternalLink } = useActiveLink(data.path);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            handleClose();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        const appBarEl = Array.from(document.querySelectorAll('.MuiAppBar-root'));

        // Reset styles when hover
        const styles = () => {
            document.body.style.overflow = '';
            document.body.style.padding = '';
            // Apply for Window
            appBarEl.forEach((elem) => {
                elem.style.padding = '';
            });
        };

        if (open) {
            styles();
        } else {
            styles();
        }
    }, [open]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickItem = () => {
        if (!hasChildren) {
            navigate(data.path);
        }
    };

    return (
        <>
            <NavItem
                item={data}
                depth={depth}
                open={open}
                active={active}
                ref={menuRef}
                isExternalLink={isExternalLink}
                onClick={handleClickItem}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
            />


            {hasChildren && (
                <PaperStyle
                    open={open}
                    anchorEl={menuRef.current}
                    anchorOrigin={
                        depth === 1
                            ? { vertical: 'bottom', horizontal: 'left' }
                            : { vertical: 'center', horizontal: 'center' }
                    }
                    transformOrigin={
                        depth === 1
                            ? { vertical: 'top', horizontal: 'left' }
                            : { vertical: 'center', horizontal: 'left' }
                    }
                    PaperProps={{
                        onMouseEnter: handleOpen,
                        onMouseLeave: handleClose,
                    }}
                >
                    <NavSubList data={data?.children} depth={depth} />
                </PaperStyle>
            )}
        </>
    );
}

// ----------------------------------------------------------------------

function NavSubList({ data, depth }) {
    return (
        <>
            {data.map((list) => (
                <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={depth + 1}
                    hasChild={!!list.children}
                />
            ))}
        </>
    );
}

NavSubList.propTypes = {
    data: PropTypes.array,
    depth: PropTypes.number,
};

NavList.propTypes = {
    data: PropTypes.object,
    depth: PropTypes.number,
    hasChildren: PropTypes.bool,
};