import { useState } from 'react';
// lib
import { ListItemButton, ListItemText, Stack, Typography, Box, Dialog, DialogTitle, DialogActions, Button, Avatar, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
// component
import { Iconify } from 'src/components';
import capitalizeFirstLetter from '../../../../utils/capitalizedFunc';
import { cancelBtnStyle, yesBtnStyle, blueDotStyle, listItemTextStyle } from '../../../../styles/Chat';

// ----------------------------------------------------------------------

export default function MailItem({
    mail,
    selected,
    sx,
    onClick,
    mailType,
    draftMailDelete,
    isFromSideBar
}) {
    const [startConfirmation, setStartConfirmation] = useState(false);
    const [isUnread, setIsUnread] = useState(mail.is_unread);
    const message = mail?.recent_mail?.message || mail?.body || '';
    const parsedMessage = new DOMParser().parseFromString(message, 'text/html');
    let secondaryText = (parsedMessage.body.textContent || '').trim();
    secondaryText = secondaryText.replace(/([?,.])/g, '$1 ');

    const handleClick = () => {
        onClick();
        if (mailType === 'Incoming' && isUnread) {
            setIsUnread(false);
        }
    };

    const getTooltipTitle = () => {
        if (isFromSideBar) {
            const firstName = capitalizeFirstLetter(mail?.lead?.first_name || '');
            const lastName = capitalizeFirstLetter(mail?.lead?.last_name || '');
            return `${firstName} ${lastName}`;
        }
        return capitalizeFirstLetter(mail?.subject);
    };

    return (
        <ListItemButton
            sx={{
                p: '8px 8px',
                boxShadow: 'none',
                ...(selected && {
                    bgcolor: 'action.selected',
                }),
                ...sx,
                borderRadius: 1,
                mb: '4px'
            }}
            onClick={handleClick}
        >
            <>
                <Tooltip title={getTooltipTitle()}>
                    <Avatar
                        alt={isFromSideBar ? mail?.lead?.first_name : mail?.subject}
                        sx={{ mr: 2 }}
                    >
                        {isFromSideBar ? mail?.lead?.first_name?.charAt(0)?.toUpperCase() : mail?.subject?.charAt(0)?.toUpperCase()}
                    </Avatar>
                </Tooltip>
                <ListItemText
                    primary={
                        <Tooltip
                            title={getTooltipTitle()}
                        >
                            <span>
                                {isFromSideBar ? `${capitalizeFirstLetter(mail?.lead?.first_name || '')}${'\u00A0'}${capitalizeFirstLetter(mail?.lead?.last_name || '')}` : capitalizeFirstLetter(mail?.subject)}
                            </span>
                        </Tooltip>
                    }
                    secondary={`${secondaryText}`}
                    primaryTypographyProps={{
                        noWrap: true,
                        variant: 'subtitle2',
                    }}
                    secondaryTypographyProps={{
                        noWrap: true,
                        component: 'span',
                        variant: mail.isUnread ? 'subtitle2' : 'body2',
                        color: mail.isUnread ? 'text.primary' : 'text.secondary',
                    }}
                    sx={listItemTextStyle}
                />
                <Stack alignItems="flex-end" sx={{ ml: 'auto', pl: 2, height: '44px' }}>
                    <Typography
                        noWrap
                        variant="body2"
                        component="span"
                        sx={{
                            mb: '12px',
                            fontSize: 12,
                            color: 'text.disabled',
                        }}
                    >
                        {mail?.created_at_human}
                    </Typography>
                    {
                        mail.is_unread === true && (mailType === 'Incoming') && isUnread &&  <Tooltip title="Mark as read"><Box sx={blueDotStyle} /></Tooltip>
                    }
                </Stack>
                {mailType === 'Drafts' && <Box onClick={(event) => { event.stopPropagation(); setStartConfirmation(true); }} sx={{ ml: 2, lineHeight: 0, height: '44px' }}>
                    <Iconify icon={'solar:trash-bin-trash-bold'} />
                </Box>}
            </>
            <Dialog open={startConfirmation} onClose={(event) => { event.stopPropagation(); setStartConfirmation(false); }}>
                <DialogTitle sx={{ padding: '40px 40px 0px', textAlign: 'center' }}>Are you sure you want to delete this email?</DialogTitle>
                <DialogActions sx={{ padding: '40px !important', justifyContent: 'center' }}>
                    <Button sx={cancelBtnStyle} onClick={(event) => { event.stopPropagation(); setStartConfirmation(false); }}>Cancel</Button>
                    <Button sx={yesBtnStyle} onClick={(event) => { event.stopPropagation(); draftMailDelete(); }}>Yes</Button>
                </DialogActions>
            </Dialog>
        </ListItemButton>
    );
}

MailItem.propTypes = {
    mail: PropTypes.object,
    selected: PropTypes.bool,
    sx: PropTypes.object,
    onClick: PropTypes.func,
    mailType: PropTypes.string,
    draftMailDelete: PropTypes.func,
    isFromSideBar: PropTypes.any,
};