import React from 'react';
import CustomFirstNameCell from '../CustomFirstNameCell';

const FirstNameCell = () => ({
    field: 'first_name',
    headerName: 'First name',
    width: 100,
    minWidth: 100,
    editable: true,
    align: 'left',
    renderCell: (params) => <CustomFirstNameCell params={params} />
});

export default FirstNameCell;