import { createSlice } from '@reduxjs/toolkit';
// ------------------------------

const initialState = {
    pathAfterLogin: null
};

const slice = createSlice({
    name: 'afterLogin',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        addPathAfterLogin(state, action) {
            state.pathAfterLogin = action.payload;
        },
    }
});

export default slice.reducer;

export const { addPathAfterLogin, reset } = slice.actions;

