import PropTypes from 'prop-types';
// libraries
import { Grid, Dialog, Container, Stack, Button, Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import { FormProvider } from '../hook-form';
import { Transition } from '.';
// validation
import { treatmentPlanDateSchema } from '../../validations/commonValidations';
// style
import {
    assignTaskDialogStyle,
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle
} from '../../styles/AddReminderPopup';

const TreatmentPlanDatePicker = ({ open, onClose }) => {

    const defaultValues = {
        treatmentPlanDate: null,
    };

    const methods = useForm({
        resolver: yupResolver(treatmentPlanDateSchema),
        defaultValues,
    });

    const { control, handleSubmit } = methods;

    const onSubmit = () => onClose();

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={assignTaskDialogStyle}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={reminderStackStyle}>
                    <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                        <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                            <Box>
                                <Controller
                                    name='treatmentPlanDate'
                                    control={control}
                                    render={({ field, fieldState: { error } }) => <>
                                        <DateTimePicker
                                            label='Treatment plan date (Required)'
                                            value={field.value || null}
                                            minDateTime={new Date()}
                                            format={'dd/MM/yyyy HH:mm'}
                                            ampm={false}
                                            onChange={(newValue) => field.onChange(newValue)}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!error,
                                                    helperText: error?.message,
                                                    onKeyDown: (e) => e.preventDefault()
                                                },
                                            }}
                                        />
                                    </>}
                                />
                            </Box>
                        </Container>
                    </Grid>
                </Stack>
                <Box sx={reminderFooterStyle}>
                    <Button
                        color='inherit'
                        sx={cancelButtonStyle}
                        onClick={() => onClose(true)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        color='primary'
                        variant='contained'
                        sx={saveButtonStyle}
                    >
                        Done
                    </Button>
                </Box>
            </FormProvider >
        </Dialog >
    );
};

export default TreatmentPlanDatePicker;

TreatmentPlanDatePicker.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};