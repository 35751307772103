import { styled } from '@mui/material/styles';

export const tabsStyle = {
    border: '1px solid rgba(145, 158, 171, 0.12)',
    borderRadius: 1,
    width: '100%',
    padding: '4px',
    marginBottom: '24px',
    ' .Mui-disabled': {
        display: 'none'
    }
};
export const clinCheckTabStyle = {
    border: '1px solid rgba(145, 158, 171, 0.12)',
    borderRadius: 1,
    width: '100%',
    padding: '4px',
    marginBottom: '24px',
};
export const tabContentStyle = {
    width: 'auto',
    marginRight: '0 !important',
    minHeight: '56px',
    flex: '1',
    padding: {md: '0', xs: '10px 15px'},
    minWidth: {md: '180px', xs: '200px'},
    maxWidth: '100%',
    '&.Mui-selected': {
        color: '#00AB55',
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 171, 85, 0.08)'
    }
};
export const SnackbarContent = styled('div')(() => ({
    backgroundColor: '#fff',
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.16)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '22px'
}));
export const InfoIcon = styled('div')(() => ({
    backgroundColor: 'rgba(24, 144, 255, 0.16)',
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
}));
export const ArchiveTitle = styled('div')(() => ({
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px'
}));
export const archiveGridStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    mt: '30px',
    mb: 3,
    width: '100%',
    ml: 'auto',
    flexWrap: {md: 'nowrap', xs: 'wrap'}
};
export const selectMenuItemStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};
export const FollowUpTitle = styled('div')(() => ({
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px'
}));
export const followUpTabStyle = isMobile => ({
    justifyContent: !isMobile ? 'center' : 'start',
    display: 'flex',
    margin: '40px 0 45px',
    flexWrap: 'wrap'
});
export const followUpButtonGroupStyle = {
    border: '1px solid rgba(145, 158, 171, 0.12)',
    flexDirection: { md: 'row', xs: 'column' },
    maxWidth: '100%',
    borderRadius: '8px',
    padding: '4px',
    display: 'inline-block', 
    borderColor: 'common.darkGrey'
};
export const followUpHourButtonStyle = activeTime => ({
    backgroundColor: activeTime === '2 hours-call' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none' },
    borderRadius: '8px !important',
    color: activeTime === '2 hours-call' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    margin: '4px !important',
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    fontWeight: '600',
    textTransform: 'none !important'
});
export const followUpTomorrowButtonStyle = activeTime => ({
    backgroundColor: activeTime === 'tomorrow' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none' },
    borderRadius: '8px !important',
    color: activeTime === 'tomorrow' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    margin: '4px !important',
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    fontWeight: '600',
    textTransform: 'none !important'
});
export const followUpWeekButtonStyle = activeTime => ({
    backgroundColor: activeTime === 'week' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none' },
    borderRadius: '8px !important',
    color: activeTime === 'week' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    margin: '4px !important',
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    fontWeight: '600',
    textTransform: 'none !important'
});
export const followUpTwoWeekButtonStyle = activeTime => ({
    backgroundColor: activeTime === 'two-week' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none' },
    borderRadius: '8px !important',
    color: activeTime === 'two-week' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    margin: '4px !important',
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    fontWeight: '600',
    textTransform: 'none !important'
});
export const followUpCustomButtonStyle = activeTime => ({
    backgroundColor: activeTime === 'custom reminder' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none' },
    borderRadius: '8px !important',
    color: activeTime === 'custom reminder' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    margin: '4px !important',
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    fontWeight: '600',
    textTransform: 'none !important'
});
export const followUpNoReminderButtonStyle = activeTime => ({
    backgroundColor: activeTime === 'no-reminder' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'transparent', color: '#00AB55', border: 'none' },
    borderRadius: '8px !important',
    color: activeTime === 'no-reminder' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    fontWeight: '600',
    textTransform: 'none !important',
    margin: '4px !important',
});
export const customReminderBoxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 2,
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
    mb: 3,
    alignItems: 'flex-start'
};
export const appointmentLabelStyle = {
    position: 'absolute',
    right: '15px',
    top: '13px',
};
export const appointmentCard = {
    boxShadow: 'none',
    padding: 0,
    pt: '30px',
    borderRadius: '6px'
};
export const appointmentGridContainer = type => ({
    display: 'flex',
    justifyContent: 'space-between',
    mt: '30px',
    mb: type === 'followUp' ? 0 : 3,
    width: '100%',
    ml: 'auto'
});
export const appointmentTreatmentStyle = {
    width: '100%',
    maxWidth: { md: 'calc(70% - 20px)' },
    mb: {
        sm: 0,
        xs: 0
    }
};
export const appointmentGridStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
    },
    mb: 3
};
export const inputLabelStyle = isLight => ({
    backgroundColor: isLight ? '#fff' : '#212B36',
    paddingLeft: '8px',
    paddingRight: '8px'
});

export const selectStyle = {
    textTransform: 'capitalize',
    mb: {
        sm: 0,
        xs: 0
    }
};

export const textFieldStyle = {
    mt: {
        md: '0',
        xs: '20px'
    },
    marginLeft: {
        md: '20px',
        xs: '0'
    }
};

// appointmentPage

export const AppointmentTabStyle = {
    justifyContent: {
        md: 'start',
        xs: 'center'
    },
    display: 'flex',
    margin: '24px 0 24px',
    flexWrap: 'wrap'
};

export const appointmentArchivedBox = {
    border:'0px solid #919EAB',
    mt:'10px',
    borderRadius:'10px'
};

export const appointmentNotBooked = {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 4,
    mb: 3,
    rowGap: 3,
    width: '100%',
    ml: 'auto'
};


// message and reminder

export const MessageBox = {
    width: '50%',
    display: 'flex',
    flexDirection: 'column'
};

export const MessageBoxTreatmentHeading = {
    marginBottom: '15px',
    color: 'grey.500',
    fontSize: '12px',
    fontWeight: '700',
    textTransform: 'uppercase'
};

export const AutomatedMessage = {
    display: 'none',
    justifyContent: 'space-between',
    mb: 3,
    flexWrap: {
        sm: 'nowrap',
        xs: 'wrap'
    }
};

export const MessageTableContainer = {
    minWidth: 'auto',
    position: 'relative',
    outline: '1px solid rgba(145, 158, 171, 0.12)',
    borderBottom: '1px solid rgba(145, 158, 171, 0.12)',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    width: '99.5%',
    margin: 'auto',
    maxWidth: '100%',
    overflowX: 'auto',
    display: 'none',
};