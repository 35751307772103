import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'src/redux/store';
import { radioFieldStyle } from 'src/styles/field';

const CustomRadioGroupComponent = ({ data, methods }) => {
    const { preferredContactMethodData } = useSelector((state) => state.schema);

    if (data?.field_type?.ui_type !== 'RADIO') return null;

    if (data?.field_type?.attribute === 'lead_preferred_contact_method') {
        return <FormControl>
            <Controller
                render={({ field }) => (
                    <RadioGroup
                        row
                        name='preferredContactMethod'
                        value={field.value}
                        onChange={(event) => field.onChange(event.target.value)}
                    >
                        {preferredContactMethodData?.map((el, index) => (
                            <FormControlLabel
                                key={index}
                                control={<Radio />}
                                label={el.label}
                                value={el.value}
                                sx={radioFieldStyle}
                            />
                        ))}
                    </RadioGroup>
                )}
                control={methods.control}
                name='preferredContactMethod'
            />
        </FormControl>;
    }
    return (
        <FormControl>
            <Controller
                render={({ field, fieldState: { error } }) => (
                    <RadioGroup
                        row
                        name={`typeRadio_${[data.id]}`}
                        value={field.value}
                        onChange={(event) => field.onChange(event.target.value)}
                    >
                        {(data?.field_type?.choices || data?.choices)?.map((el, index) => (
                            <FormControlLabel
                                key={index}
                                control={<Radio />}
                                label={el}
                                value={el}
                                sx={radioFieldStyle}
                            />
                        ))}
                        {(!!error) && (
                            <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                        )}
                    </RadioGroup>
                )}
                control={methods.control}
                name={`typeRadio_${[data.id]}`}
            />
        </FormControl>
    );
};

export default CustomRadioGroupComponent;

CustomRadioGroupComponent.propTypes = {
    data: PropTypes.object,
    methods: PropTypes.object,
};
