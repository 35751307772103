/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide } from '@mui/material';
// components
import { useSettingsContext } from '../settings/context';
import { RenderActiveStep } from '../dialogPopUp';
// store
import { useSelector, dispatch } from '../../redux/store';
import { updateError } from '../../redux/slices/user';
import { updateSteps, updateBookingSteps, contactLeadSlice, updateActiveStep } from '../../redux/slices/lead';
// styles
import { commonDialogHeaderStyle, onBoardDialogHeader, onBoardFooterButtonsStyle, leadFooterButtonsStyle, activeStepTitleStyle, activeStepTextStyle, footerButtonBoxStyle, backButtonStyle, nextButtonStyle, markAsCompleteButtonStyle, cancelBoxStyle, dialogTypoStyle, cancelBtnDesign } from '../../styles/DialoguePopup';

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

const OpenCampaignPopup = ({ open, onClose, steps, type = 'lead', inputRef, user, updateStep, validStep, headerButtons, onboaradHeaderButtons, setActive, BookingAttendance, SendNowCampaign }) => {
    const [activeStep, setActiveStep] = useState(0);
    const settings = useSettingsContext();
    const { activeTab } = useSelector(state => state.singlePractice);
    const { selectedCampaign, newCampaign, currentActiveTab } = useSelector((state) => state.campaign);
    const { selectedAudience } = useSelector((state) => state.audienceManager);
    const isComplete = activeStep === steps.length - 1;

    useEffect(() => {
        if (validStep) {
            if (activeStep !== steps.length - 1) {
                dispatch(updateActiveStep(activeStep + 1));
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setTimeout(() => {
                    updateStep(false);
                }, 1000);
            } else {
                updateStep(false);
                handleClose();
            }
        }
    }, [validStep]);

    useEffect(() => {
        if (BookingAttendance) {
            dispatch(updateActiveStep(activeStep + 1));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            return;
        }
        setActiveStep(0);
    }, [BookingAttendance]);

    useEffect(() => {
        if (setActive) setActive(activeStep);
    }, [activeStep]);

    const handleBack = () => {
        if (type === 'onboarad') {
            if (inputRef.current && inputRef.current.handleBack) {
                inputRef.current.handleBack();
                return;
            }
            dispatch(updateActiveStep(activeStep - 1));
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            return;
        }
        if ((type === 'createLead' && activeStep < 2) || (type === 'followup' && activeStep <= 1)) {
            dispatch(updateSteps(null));
        }
        if (type === 'followup' && activeStep < 2) {
            dispatch(updateBookingSteps(null));
        }
        dispatch(updateActiveStep(activeStep - 1));
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = async (status) => {
        if (status === 'send') {
            SendNowCampaign();
            return;
        }
        if (activeStep === (steps.length - 1)) {
            status = 'finish';
        }
        await inputRef.current.submit(status);
    };

    const isNext = () => {
        if (currentActiveTab === 'Existing audience') {
            return newCampaign?.audienceSelected?.audience || selectedCampaign?.audience?.audience ||
                selectedAudience;
        }
        return newCampaign?.tagsNames?.length > 0 ||
            newCampaign?.treatmentName?.length > 0 ||
            newCampaign?.statusNames?.length > 0 ||
            newCampaign?.archivedValue ||
            newCampaign?.practitionerName?.length > 0 ||
            newCampaign?.referralName?.length > 0 ||
            newCampaign?.dateRange?.length > 0 ||
            newCampaign?.source__type?.length > 0 ||
            newCampaign?.category__in?.length > 0 ||
            selectedCampaign?.recepient?.filters?.lead_tags__tag__tag__name__in?.length > 0 ||
            selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in?.length > 0 ||
            selectedCampaign?.recepient?.filters?.lead_treatments__status__in?.length > 0 ||
            selectedCampaign?.recepient?.filters?.is_only_archived ||
            selectedCampaign?.recepient?.filters?.assignees__user__id__in?.length > 0 ||
            selectedCampaign?.recepient?.filters?.lead_source__referral__in?.length > 0 ||
            selectedCampaign?.recepient?.filters?.created_at__range?.length > 0 ||
            selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in?.length > 0 ||
            selectedCampaign?.recepient?.filters?.lead_source__type__in?.length > 0;
    };

    const scheduleThisCampaignEvent = () => {
        if (isNext()) {
            handleNext();
            return;
        }
        dispatch(updateError({
            message: 'Please select any one tag',
            status: false
        }));
    };

    const sendCampaignEvent = () => {
        if (isNext()) {
            handleNext('send');
            return;
        }
        dispatch(updateError({
            message: 'Please select any one tag',
            status: false
        }));
    };

    const handleClose = () => {
        onClose();
        dispatch(updateActiveStep(0));
        setActiveStep(0);
        dispatch(contactLeadSlice(true));
    };

    const activeBackButton = () => {
        switch (type) {
            case 'onboarad':
                return activeTab !== 'General' || activeStep !== 0;
            default:
                return activeStep !== 0;
        }
    };

    const scheduleCampaignEvent = async (status) => {
        await inputRef.current.submit(status);
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={{
                zIndex: type === 'onboarad' ? 1117 : 1200,
                margin: { xs: '0 -16px', sm: 0 },
            }}
        >
            <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader}>
                <Typography
                    sx={dialogTypoStyle}
                    variant='h5'
                    component='div'
                >
                    {Object.values(selectedCampaign)?.length > 0 ? `Update Campaign ${selectedCampaign.name}` : 'Create Campaign'}
                </Typography>
                {type === 'onboarad' && <Typography
                    sx={dialogTypoStyle}
                    variant='h6'
                    component='div'
                >
                    {user && type === 'onboarad' && 'Let’s get you started'}
                </Typography>}
                {(headerButtons || onboaradHeaderButtons) &&
                    <Box sx={cancelBoxStyle(onboaradHeaderButtons)}>
                        <Button
                            color='inherit'
                            variant='outlined'
                            style={cancelBtnDesign}
                            onClick={() => { handleClose('close'); }}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={{ display: 'inline-block', p: 2.5, pb: 1 }}>
                <Grid container spacing={1} sx={{ width: '100%', ml: 'auto' }}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                        sx={{ '@media(max-width: 767px)': { px: '0' } }}
                    >
                        <Grid container justifyContent={isComplete ? 'center' : 'flex-start'}>
                            {steps[activeStep] && steps[activeStep].title ?
                                <Box sx={activeStepTitleStyle}>
                                    <Typography variant='h3'>{steps[activeStep].title}</Typography>
                                </Box>
                                : ''}
                            {steps[activeStep] && steps[activeStep].bodyText ?
                                <Box sx={activeStepTextStyle}>
                                    <Typography variant='body'>{steps[activeStep].bodyText}</Typography>
                                </Box>
                                : ''}
                            <Grid item xs={12} md={12} sx={{ mb: 5, pb: 13 }}>
                                {steps[activeStep] && RenderActiveStep({ component: steps[activeStep].component, inputRef })}
                            </Grid>
                            <Box
                                className='buttons-box'
                                sx={footerButtonBoxStyle}
                            >
                                <Container
                                    maxWidth={settings.themeStretch ? false : 'xl'}
                                    sx={type === 'createLead' || type === 'followup' ? leadFooterButtonsStyle(activeBackButton(), type) : onBoardFooterButtonsStyle(activeBackButton())}
                                    className='buttons-container'
                                >
                                    {activeBackButton() && (
                                        <Button
                                            color='inherit'
                                            variant='outlined'
                                            disabled={!activeBackButton()}
                                            onClick={handleBack}
                                            className='back-button'
                                            sx={{ ...backButtonStyle(type), textTransform: 'none !important' }}
                                        >
                                            {activeStep === 2 ? 'Back to all templates' : activeStep === 3 ? 'Back to audience' : 'Back'}
                                        </Button>
                                    )}
                                    <Box className='buttons-right'>
                                        {
                                            type === 'followup' && activeStep === 0 ? null : activeStep === 3 ? null : (<Button
                                                color='primary'
                                                variant='outlined'
                                                onClick={() => activeStep === 2 ? scheduleThisCampaignEvent() : handleNext()}
                                                className='finish-button'
                                                sx={{ ...nextButtonStyle(type), textTransform: 'none !important' }}
                                            >
                                                {activeStep === 2 ? 'Schedule this campaign' : 'Continue'}
                                            </Button>)
                                        }
                                        {(activeStep >= 2 && activeStep !== (steps.length - 1)) &&
                                            <Button
                                                color='primary'
                                                variant='contained'
                                                onClick={() => sendCampaignEvent()}
                                                className='mark-complete-button'
                                                sx={markAsCompleteButtonStyle(type)}
                                            >
                                                Send campaign
                                            </Button>
                                        }
                                        {
                                            activeStep === 3 && (<Button
                                                color='primary'
                                                variant='contained'
                                                onClick={() => scheduleCampaignEvent()}
                                                className='mark-complete-button'
                                                sx={nextButtonStyle(type)}
                                            >
                                                {'Schedule campaign'}
                                            </Button>)
                                        }
                                    </Box>
                                </Container>
                            </Box>
                        </Grid>
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
};

export default OpenCampaignPopup;

OpenCampaignPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    steps: PropTypes.array,
    type: PropTypes.string,
    title: PropTypes.string,
    inputRef: PropTypes.any,
    user: PropTypes.object,
    BookingAttendance: PropTypes.any,
    updateStep: PropTypes.func,
    validStep: PropTypes.bool,
    headerButtons: PropTypes.bool,
    onboaradHeaderButtons: PropTypes.bool,
    setActive: PropTypes.func,
    SendNowCampaign: PropTypes.func,
};