import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
// Form
import { useForm } from 'react-hook-form';
import { FormProvider } from '../hook-form';
// Redux
import { getCurrentTreatmentIdV2, handleClinCheckInProgressStatus } from '../../redux/slices/clincheck';
import { dispatch, useSelector } from '../../redux/store';

const ClinCheckInProgressForm = forwardRef(({ handleClose, leadDetail }, ref) => {
    const { followLead } = useSelector((state) => state.lead);
    const { clinCheckListing } = useSelector((state) => state.clinCheck);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const defaultValues = {
        date_uploaded: null,
        who_uploaded: '',
        is_task_done: false
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit((data) => onSubmit(data))();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const methods = useForm({
        defaultValues,
    });

    const {
        handleSubmit
    } = methods;


    const onSubmit = () => {
        if (clinCheckListing?.results?.[0]?.status === 'IN_PROGRESS') {
            handleClose('finish');
            return;
        }
        const data = {
            leadId: followLead?.id || leadDetail?.lead_id || leadDetail?.id,
            treatmentId: getCurrentTreatmentIdV2(followLead?.lead_treatments?.[0]?.id || leadDetail?.treatment_id || leadDetail?.lead_treatments?.[0]?.id),
            practiceId,
            shouldShowMessage: true
        };
        dispatch(handleClinCheckInProgressStatus(data, () => handleClose('finish', true)));
    };

    return <div>
        <FormProvider methods={methods} />
    </div>;
});

export default ClinCheckInProgressForm;

ClinCheckInProgressForm.propTypes = {
    handleClose: PropTypes.func,
    leadDetail: PropTypes.object,
};