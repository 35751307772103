import { useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Grid, Dialog, Container, Stack, Button, Box, Typography } from '@mui/material';
// components
import Iconify from '../Iconify';
import { Archive } from '../tabs';
import { Transition } from '.';
// styles
import {
    reminderDialogStyle,
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle
} from '../../styles/AddReminderPopup';

const ArchivedLeadPopUp = ({ open, onClose, user, isDuplicate, setForceUpdate }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit('finish');
    };

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={reminderDialogStyle}
        >
            <Stack sx={reminderStackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                        <Typography variant='h5' sx={{ fontSize: '18px' }} > Archive <span style={{ textTransform: 'capitalize' }}>{user.first_name || ''} {user.last_name || ''}</span></Typography>
                        <Archive user={user} ref={inputRef} isDuplicate={isDuplicate} handleClose={onClose} setForceUpdate={setForceUpdate} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={reminderFooterStyle}>
                <Button
                    color='inherit'
                    sx={cancelButtonStyle}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    color='error'
                    variant='contained'
                    onClick={handleNext}
                    sx={saveButtonStyle}
                >
                    <Iconify icon={'eva:archive-fill'} sx={{ marginRight: '10px' }} />
                    Archive
                </Button>
            </Box>
        </Dialog>
    );
};

export default ArchivedLeadPopUp;

ArchivedLeadPopUp.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    user: PropTypes.object,
    isDuplicate: PropTypes.bool,
    setForceUpdate: PropTypes.func
};
