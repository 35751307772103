import React from 'react';
import PropType from 'prop-types';
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { isArray } from 'lodash';
import { Iconify } from '..';

const PaymentTypeDropDown = ({ value, handleChangePaymentMethod, label, options, error }) =>
    <FormControl fullWidth >
        <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
        <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={value}
            label={label}
            onChange={handleChangePaymentMethod}
            startAdornment={
                <InputAdornment position='start'>
                    <Iconify icon={'fluent:payment-16-filled'} width={20} height={20} />
                </InputAdornment>
            }
            error={!!error}
        >
            {isArray(options) && options.length > 0 && options?.map((ele, index) => <MenuItem key={index} value={ele.value}>{ele.label}</MenuItem>)}
        </Select>
        {!!error && error?.message && <FormHelperText error={!!error}>{error?.message}</FormHelperText>}
    </FormControl>;


export default PaymentTypeDropDown;

PaymentTypeDropDown.propTypes = {
    value: PropType.string,
    handleChangePaymentMethod: PropType.func,
    options: PropType.any,
    label: PropType.string,
    error: PropType.string,
};
