/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    TableContainer,
    Table,
    TableBody,
    Stack,
    TablePagination,
    Box,
    useTheme,
    Typography,
    Fade,
    Modal,
    Backdrop,
    InputAdornment,
    TextField,
    Chip
} from '@mui/material';
import { isArray } from 'lodash';
// component
import getStyles from 'src/styles/whatsappStyles';
import { Iconify } from 'src/components';
import TemplatePreviewPopup from '../../../components/dialogPopUp/TemplatePreviewPopup';
import { TableHeadCustom, TableNoData } from '../../../components/table';
import WhatsappToolbar from './WhatsappToolbar';
import {
    WhatsappTemplatesTableRow,
    WhatsappMessageCompose,
} from '.';
// redux
import { dispatch, useSelector } from '../../../redux/store';
import { getPracticeId } from '../../../redux/slices/clincheck';
import { getSearchWpTemplateList, updateWpContextMap } from '../../../redux/slices/whatsAppMessages';
import { getWhatsappAccount } from '../../../redux/slices/whatsappAccount';
// hooks/constants/utils
import useTable from '../../../hooks/useTable';
import {
    whatsappTable,
} from '../../../constants/MessageListConst';
// style
import {
    tableContainerStyle,
    stackStyle,
    paginationOuterBoxStyle,
    paginationInnerBoxStyle,
} from '../../../styles/MessageListStyle';

const WhatsappTemplateList = ({ messageData, type }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const [filterStatus, setFilterStatus] = useState('');
    const [tagData, setTagData] = useState({ open: false, data: {}, id: -1 });
    const [openTemplatePreview, setOpenTemplatePreview] = useState(false);
    const [openWhatsappCompose, setOpenWhatsappCompose] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const [previewSize, setPreviewSize] = useState('desktop');
    const TABLE_HEAD = whatsappTable;
    const practiceId = getPracticeId();
    const { whatsappAccountData } = useSelector((state) => state.whatsappAccount);
    const { wpTemplateListGlobal } = useSelector((state) => state.whatsappMessages);

    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        selected,
        setPage,
        onChangePage,
        onChangeRowsPerPage,
        setRowsPerPage
    } = useTable();

    useEffect(() => {
        if (practiceId) {
            dispatch(getWhatsappAccount(practiceId));
        }
    }, [practiceId]);

    useEffect(() => {
        if (whatsappAccountData) {
            const wpAccountId = whatsappAccountData?.results?.[0]?.id;
            if (wpAccountId) {
                dispatch(getSearchWpTemplateList({ wpAccountId }));
            }
        }
    }, [whatsappAccountData]);

    const handleRefreshPagination = () => {
        setPage(0);
        setRowsPerPage(10);
    };

    useEffect(() => {
        if (filterStatus) {
            handleRefreshPagination();
        }
    }, [filterStatus]);

    const handleTemplatePreview = (row) => {
        setOpenTemplatePreview(true);
        setPreviewData(row);
    };

    const handleClosePreviewTemplate = () => {
        setOpenTemplatePreview(false);
        setPreviewData(null);
        setPreviewSize('desktop');
    };

    const handlePreviewSize = (size) => setPreviewSize(size);

    const handleEditTags = rowData => {
        const { template_context_mapping: contextMapping,
            id: templateId
        } = rowData ?? {};
        setTagData({ open: true, data: contextMapping, id: templateId });
    };

    const handleUpdateContextMap = (newContext, id) => {
        const wpAccountId = whatsappAccountData?.results?.[0]?.id;
        if (wpAccountId) {
            dispatch(updateWpContextMap({ wpAccountId, context: newContext, id }));
        }
    };

    const headerTagRef = React.useRef(null);
    const bodyTagRef = React.useRef(null);
    const footerTagRef = React.useRef(null);

    const handleDeleteTag = (type, index) => {
        setTagData(prvObj => {
            const prvArray = prvObj?.data?.[type] ?? [];
            const newArray = [...prvArray];
            newArray.splice(index, 1);
            handleUpdateContextMap({ ...prvObj?.data, [type]: newArray }, prvObj?.id);
            return ({ open: true, data: { ...prvObj?.data, [type]: newArray }, id: prvObj?.id });
        });
    };

    const handleSubmitHeaderTag = () => {
        const { value } = headerTagRef?.current ?? {};
        headerTagRef.current.value = '';
        setTagData(prvObj => {
            const prvArray = prvObj?.data?.HEADER ?? [];
            handleUpdateContextMap({ ...prvObj?.data, 'HEADER': [...prvArray, value] }, prvObj?.id);
            return ({ open: true, data: { ...prvObj?.data, 'HEADER': [...prvArray, value] }, id: prvObj?.id });
        });
    };

    const handleSubmitBodyTag = () => {
        const { value } = bodyTagRef?.current ?? {};
        bodyTagRef.current.value = '';
        setTagData(prvObj => {
            const prvArray = prvObj?.data?.BODY ?? [];
            handleUpdateContextMap({ ...prvObj?.data, 'BODY': [...prvArray, value] }, prvObj?.id);
            return ({ open: true, data: { ...prvObj?.data, 'BODY': [...prvArray, value] }, id: prvObj?.id });
        });
    };

    const handleSubmitFooterTag = () => {
        const { value } = footerTagRef?.current ?? {};
        footerTagRef.current.value = '';
        setTagData(prvObj => {
            const prvArray = prvObj?.data?.FOOTER ?? [];
            handleUpdateContextMap({ ...prvObj?.data, 'FOOTER': [...prvArray, value] }, prvObj?.id);
            return ({ open: true, data: { ...prvObj?.data, 'FOOTER': [...prvArray, value] }, id: prvObj?.id });
        });
    };

    return (
        <Box sx={stackStyle}>
            {
                <Stack sx={stackStyle}>
                    <WhatsappToolbar
                        messageData={messageData}
                        filterData={setFilterStatus}
                        type={type}
                    />
                    <Box sx={{ maxHeight: 'calc(100vh - 230px)', overflow: 'auto' }}>
                        {<TableContainer sx={tableContainerStyle(type)} >
                            <Table size={'medium'}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={wpTemplateListGlobal && wpTemplateListGlobal?.length}
                                    numSelected={selected.length}
                                />
                                <TableBody>
                                    {isArray(wpTemplateListGlobal) && wpTemplateListGlobal.length > 0 ?
                                        wpTemplateListGlobal?.map((row, index) => (
                                            <WhatsappTemplatesTableRow
                                                key={index}
                                                row={row}
                                                selected={selected.includes(row.id)}
                                                messageType={type}
                                                handleTemplatePreview={handleTemplatePreview}
                                                handleEditTags={handleEditTags}
                                            />
                                        )) :
                                        <TableNoData isNotFound />
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Box>
                    <Box sx={paginationOuterBoxStyle}>
                        <Box sx={paginationInnerBoxStyle}>
                            {wpTemplateListGlobal?.length > 10 &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25]}
                                    component='div'
                                    rowsPerPage={rowsPerPage}
                                    page={page >= Math.ceil(wpTemplateListGlobal?.length / rowsPerPage) ? 0 : page}
                                    count={wpTemplateListGlobal?.length}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                />
                            }
                        </Box>
                    </Box>
                    <TemplatePreviewPopup
                        campaignPreview={openTemplatePreview}
                        handleClosePreviewTemplate={handleClosePreviewTemplate}
                        previewData={previewData}
                        handlePreviewSize={handlePreviewSize}
                        previewSize={previewSize}
                    />
                    <WhatsappMessageCompose
                        isEdit
                        openSMSCompose={openWhatsappCompose}
                        onCloseSMSCompose={() => setOpenWhatsappCompose(false)}
                        composeDetail={[]}
                        handleSaveMessage={''}
                        type='sms_editor'
                        messageType={type}
                        practiceId={''}
                        isNewTemplate={''}
                        onSendTestMail={''}
                        setTemplate={''}
                        setTemplateOpen={''}
                        LeadTemplate={''}
                        useTemplate={''}
                        useOrSend={''}
                    />
                </Stack>
            }
            <Modal
                open={tagData?.open}
                onClose={(e) => {
                    e?.stopPropagation();
                    setTagData({ open: false, data: {} });
                }}
                aria-labelledby="modal-profile-account-change"
                aria-describedby="modal-profile-account-change"
                slots={{ backdrop: Backdrop }}
                closeAfterTransition
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={tagData?.open}>
                    <Box sx={styles.mergetagContainer}>
                        <Box>
                            <Typography
                                sx={styles.mergetagHeader}
                            >
                                Add/Edit Tags
                            </Typography>
                        </Box>
                        <Box sx={styles.mergeTableCont}>
                            <Box>
                                <Box sx={styles.mergeTagHeaderInput}>
                                    <TextField
                                        inputRef={headerTagRef}
                                        onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitHeaderTag(); }}
                                        fullWidth
                                        placeholder="Header Tags"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '2.5rem',
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Iconify icon="mdi:page-layout-header" sx={{ color: 'text.disabled' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box sx={styles.mergeTags}>
                                    {tagData?.data?.HEADER?.map((chip, index) =>
                                        <Chip
                                            key={index}
                                            label={chip}
                                            onDelete={() => { handleDeleteTag('HEADER', index); }}
                                            sx={{ ...styles.mergeChips, background: theme.palette.success.main }}
                                        />)}
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={styles.mergeTagHeaderInput}>
                                    <TextField
                                        inputRef={bodyTagRef}
                                        fullWidth
                                        onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitBodyTag(); }}
                                        placeholder="Body Tags"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '2.5rem',
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Iconify icon="mdi:page-layout-body" sx={{ color: 'text.disabled' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box sx={styles.mergeTags}>
                                    {tagData?.data?.BODY?.map((chip, index) =>
                                        <Chip
                                            key={index}
                                            label={chip}
                                            onDelete={() => { handleDeleteTag('BODY', index); }}
                                            sx={{ ...styles.mergeChips, background: theme.palette.warning.main }}
                                        />)}
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={styles.mergeTagHeaderInput}>
                                    <TextField
                                        inputRef={footerTagRef}
                                        fullWidth
                                        onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitFooterTag(); }}
                                        placeholder="Footer Tags"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '2.5rem',
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Iconify icon="mdi:page-layout-footer" sx={{ color: 'text.disabled' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box sx={styles.mergeTags}>
                                    {tagData?.data?.FOOTER?.map((chip, index) =>
                                        <Chip
                                            key={index}
                                            label={chip}
                                            onDelete={() => { handleDeleteTag('FOOTER', index); }}
                                            sx={{ ...styles.mergeChips, background: theme.palette.info.main }}
                                        />)}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>

    );
};

WhatsappTemplateList.propTypes = {
    messageData: PropTypes.array,
    type: PropTypes.string,
};

export default WhatsappTemplateList;