export const boxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 2,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(4, 1fr)'
    }
};
export const menuItemStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};
export const fieldBoxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)'
    },
    mb: 2,
};
export const controllerBoxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 2,
    alignItems: 'flex-start',
    gridTemplateColumns: 'repeat(1, 1fr)',
};
export const selectMenuItemStyle = {
    mx: 1,
    borderRadius: 0.75,
    typography: 'body2',
    fontStyle: 'italic',
    color: 'text.secondary',
};
export const autocompleteBoxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 2,
    position: 'relative',
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
    },
};
export const selectBoxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 2,
    alignItems: 'flex-start',
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)'
    }
};
export const selectStyle = {
    textTransform: 'capitalize',
    mb: {
        sm: 3,
        xs: 3
    }
};