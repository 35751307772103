/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, ImageList, ImageListItem, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, IconButton, ImageListItemBar } from '@mui/material';
import { useDebounce } from 'use-lodash-debounce';
import Compressor from 'compressorjs';
import { Iconify } from '../../../components';
import { deleteImage, getMediaImages, setFileUploadError } from '../../../redux/slices/campaign';
import { updateError } from '../../../redux/slices/user';
import { dispatch, useSelector } from '../../../redux/store';
import { uploadImage } from '../../../redux/slices/practiceMessage';
import { ConfirmDialog } from '../../../components/dialogPopUp';

const CustomMediaLibrary = ({ practiceId, handleClose, open, setInputFiles }) => {
    const { mediaImages: { count, results }, loadMoreLoading, fileUploadError } = useSelector((state) => state.campaign);
    const primary = useRef();
    const [position, setPosition] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [callApi, setCallApi] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [id, setId] = useState('');
    const treatmentDebounce = useDebounce(callApi, 800);
    const limit = 50;
    const mounted = useRef();
    const listElem = useRef();

    const handleOpenConfirm = (id) => {
        setOpenConfirm(true);
        setId(id);
    };

    const handleDeleteConfirm = () => {
        setOpenConfirm(false);
        dispatch(deleteImage({ practiceId, id }));
        setId('');
    };

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    useEffect(() => {
        if (treatmentDebounce && open) {
            loadMoreData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treatmentDebounce]);

    const loadMoreData = () => {
        if (count > results?.length) {
            const data = {
                practiceId,
                limit,
                offset: results?.length
            };
            dispatch(getMediaImages(data));
        }
    };

    const convertImgToBase64 = (result) => {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = () => {
            const detail = {
                image: reader.result
            };
            setIsLoading(true);

            dispatch(uploadImage(detail, (resp) => {
                if (resp.success) {
                    const { image } = resp.data;
                    if (image) {
                        setInputFiles({ file: image });
                        dispatch(getMediaImages({ practiceId, limit: 50, offset: 0 }));
                    }
                    setIsLoading(false);
                    return;
                }
                setIsLoading(false);
                dispatch(updateError({ success: false, message: resp.message.image.toString() }));
            }));

        };
    };

    const handleInputFiles = async (e) => {
        const files = e.target.files[0];
        if (files && files.type?.includes('image') && files.size > 2 * 1024 * 1024) {
            dispatch(setFileUploadError('File size exceeds 4 MB limit. Please choose a smaller files.'));
            return;
        }
        dispatch(setFileUploadError(''));
        if(files?.type === 'image/gif'){
            convertImgToBase64(files);
            return;
        }
        new Compressor(files, {
            quality: 0.6,

            success(result) {
                convertImgToBase64(result);
            },
            error(err) {
                dispatch(setFileUploadError(err.message));
            },
        });
    };

    const handleClick = (e, data) => {
        data.current.click();
    };

    const handleLoadMore = (event) => {
        const listboxNode = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
        if (listboxNode && open) {
            setPosition(event.target.scrollTop + event.target.clientHeight);
            setCallApi(event.target.scrollHeight);
        }
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
        setId('');
    };

    return <div>
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            id='mediaLibrary'
        >
            <DialogTitle>My Media Library</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: '10px' }}>
                    You can pic image from here.
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <Box>
                        <Button onClick={(e) => handleClick(e, primary)} variant='contained'>
                            {isLoading ? <CircularProgress /> : 'Choose file'}
                        </Button>
                        <input
                            ref={primary}
                            type='file'
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => handleInputFiles(e)}
                        />
                        {fileUploadError && <Typography sx={{
                            color: 'red',
                            fontSize: '14px',
                            mt: '5px'
                        }}>{fileUploadError}</Typography>}
                    </Box>
                    <ImageList ref={listElem} onScroll={handleLoadMore} sx={{ width: '100%', height: 450, border: '2px solid #000', backgroundColor: '#d3d3d3' }} cols={4} rowHeight={200}>
                        {results?.map((item) => (
                            <ImageListItem key={item.id}>
                                <img
                                    srcSet={`${item.image}?h=200&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.image}?h=200&fit=crop&auto=format`}
                                    alt={item.id}
                                    loading="lazy"
                                    style={{ height: '100%', cursor: 'pointer' }}
                                    className='library-image'
                                />
                                <ImageListItemBar
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}
                                            onClick={() => handleOpenConfirm(item.id)}
                                        >
                                            <Iconify sx={{ color: '#fff' }} height={24} width={24} icon='solar:trash-bin-trash-bold' />
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>
                        ))}
                        {loadMoreLoading && <Typography>...Loading</Typography>}
                    </ImageList>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button className='library-image' onClick={() => { setCallApi(null); handleClose(); }}>Close</Button>
            </DialogActions>
            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content="Are you sure you want to delete?"
                content2="Note: Please confirm if this image has already been used in any template, as removing it will also affect the template."
                action={
                    <Button variant="contained" color="error" onClick={handleDeleteConfirm}>
                        Delete
                    </Button>
                }
            />
        </Dialog></div>;
};

export default CustomMediaLibrary;

CustomMediaLibrary.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    setInputFiles: PropTypes.any,
    practiceId: PropTypes.number,
};