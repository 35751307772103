/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// libraries
import { Stack, Button, Box, Menu } from '@mui/material';
// Components
import NavList from './NavList';
import { OpenDialogPopup, OpenAttendedPopup, OpenUnAttendedPopup } from '../../fullScreenPopUps';
import { AttendedAppointment } from '../../Attended';
import { ConsentBuilderPopUp, ReviewLinkPopUp } from '../../dialogPopUp';
import Iconify from '../../Iconify';
import { PersonalDetails, TreatmentAndAppointment, PaymentOption, MessageAndReminder } from '../../../sections/@dashboard/general/app';
import CustomMenuItem from '../CustomMenuItem';
// Redux
import { setCurrentTreatmentDataGlobal } from '../../../redux/slices/multiTreatment';
import { clearLead, updateSteps, createNewLead, clearConsentData, addNotes, updateSendReminderDuringLeadCreate } from '../../../redux/slices/lead';
import { updateError } from '../../../redux/slices/user';
import { dispatch, useSelector } from '../../../redux/store';
// Hooks/routes/utils/constants
import { useAuth, useToggle, useRecordAttendToggle, useUnRecordAttendToggle, useConsentToggle } from '../../../hooks';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { hideScrollbarY } from '../../../utils/cssStyles';
import { allowedTitles } from '../../../constants/commonConstants';
import { localStorageRemoveItem } from '../../../utils/storage-available';
// style
import { stackStyle } from '../../../styles/Common';

const NavSectionHorizontal = ({ navConfig, sx, ...other }) => {
    const {
        attendance,
        markAsComplete,
        followUpLead,
        consentLink
    } = useSelector((state) => state.lead);
    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const createHandleClose = () => setAnchorEl(null);

    const { user } = useAuth();

    const getUserList = () => results.filter(el => el.user && el.user.id === user?.pk);

    const [active, setActive] = useState(0);

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();
    const {
        toggle: openRecordAttend,
        onClose: onCloseRecordAttend
    } = useRecordAttendToggle();
    const {
        toggle: openUnRecordAttend,
        onClose: onCloseUnRecordAttend
    } = useUnRecordAttendToggle();

    const {
        toggle: ConsentBuilder,
        onOpen: onOpenConsent,
        onClose: onCloseConsent
    } = useConsentToggle();

    const {
        toggle: openReview,
        onOpen: onOpenReview,
        onClose: onCloseReview
    } = useToggle();

    const handleAttendClose = () => {
        onCloseRecordAttend();
    };
    const handleUnAttendClose = () => onCloseUnRecordAttend();

    const inputRef = useRef();
    const [validStep, setValidStep] = useState(false);

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const  { sendReminderDuringLeadCreate } = useSelector((state) => state.lead);
    
    const handleClose = (status, id) => {
        if (status === 'finish') {
            setTimeout(() => {
                if(sendReminderDuringLeadCreate){
                    const details = {
                        additional_notes: "",
                        contacted_via: "PHONE",
                        lead: id,
                        note: "UNABLE_TO_CONTACT",
                        treatment: id
                    }
                    dispatch(addNotes(details, practiceId, '', 'createLead'));
                    dispatch(updateSendReminderDuringLeadCreate(false))
                }
                dispatch(updateError({ success: true, message: 'Lead successfully created! Refresh the page or click \'Show Lead\' to view and manage it.', lead: id, timeout: 10000 }));
            }, 4000);
        }
        dispatch(clearLead());
        dispatch(updateSteps(null));
        onCloseContacts();
    };

    const onSubmit = (data) => {
        setValidStep(!!data);
    };

    const STEPS = [
        {
            label: 'Personal details',
            component: <PersonalDetails ref={inputRef} handleNext={onSubmit} />
        },
        {
            label: 'Treatment & Appointment',
            component: <TreatmentAndAppointment type={'CreatLead'} ref={inputRef} handleNext={onSubmit} />
        }
    ];
    const [leadStep, setLeadStep] = useState(STEPS);
    useEffect(() => {
        if (attendance) {
            const steps = [...STEPS];
            if (attendance === 'attendance') {
                steps.push({
                    label: 'Appointment Outcome',
                    component: <AttendedAppointment ref={inputRef} handleNext={onSubmit} active={active} type={'CreatedLead'} />
                });
            } else {
                steps.push({
                    label: 'Payment options',
                    component: <PaymentOption ref={inputRef} handleNext={onSubmit} active={active} />
                });
                steps.push({
                    label: 'Message review & reminders',
                    component: <MessageAndReminder ref={inputRef} handleNext={onSubmit} active={active} />
                });
            }
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [attendance]);

    useEffect(() => {
        if (markAsComplete && !followUpLead && !consentLink) {
            dispatch(createNewLead(handleClose));
        }
    }, [markAsComplete, followUpLead, consentLink]);

    const handleReviewClick = () => { onOpenReview(); createHandleClose(); };
    const handleClickNewLead = () => {
        localStorageRemoveItem('localTreatmentData');
        onOpenContacts();
        createHandleClose();
        dispatch(setCurrentTreatmentDataGlobal(null));
    };
    const handleClickConsentClick = () => { onOpenConsent(); dispatch(clearConsentData()); createHandleClose(); };
    const handleClickStripePaymentClick = () => { navigate(PATH_DASHBOARD.createStripePayment.root); createHandleClose(); };
    const handleClickCallScriptClick = () => { 
        navigate(PATH_DASHBOARD.user.callScripts, { state: { callScriptPopupOpen: true } });
        createHandleClose(); 
    };
    const handleClickConsentBuilder = () => { navigate(PATH_DASHBOARD.user.consentBuilder); createHandleClose(); };

    return (
        <>
            <Stack direction='row' sx={stackStyle}>
                <Stack direction='row' sx={stackStyle}>
                    <Stack direction='row' spacing={1} sx={{ ...hideScrollbarY, ...sx }} {...other}>
                        {navConfig.map((group) => (
                            <Stack key={group.subheader} direction='row' flexShrink={0}>
                                {group.items.map((list) => {

                                    if (list.title === 'Leads') {
                                        return <Box key={list.title + list.path}>
                                            <NavList data={list} depth={1} hasChildren={!!list.children} />
                                        </Box>;
                                    }
                                    if (allowedTitles.includes(list.title) || (list.title === 'Super admin' && getUserList()[0]?.user?.is_superuser)) {
                                        return (
                                            <NavList
                                                key={list.title + list.path}
                                                data={list}
                                                depth={1}
                                                hasChildren={!!list.children}
                                            />
                                        );
                                    }
                                    return '';
                                })}
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
                <Stack direction='row' sx={{ position: 'absolute', right: '30px' }}>
                    <Button
                        sx={{ mb: 0, ml: 1, minWidth: '200px' }}
                        variant='contained'
                        onClick={handleClick}
                        endIcon={<Iconify icon={'eva:arrow-down-fill'} />}
                        startIcon={<Iconify icon={'eva:plus-fill'} />}
                    >
                        Create
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={createHandleClose}
                        sx={{ '.MuiPaper-root': { minWidth: '200px' }, '.MuiMenuItem-root': { fontSize: '0.875rem' } }}
                    >
                        <CustomMenuItem
                            handleClickCallScriptClick={handleClickCallScriptClick}
                            handleClickConsentClick={handleClickConsentClick}
                            handleClickNewLead={handleClickNewLead}
                            handleClickStripePaymentClick={handleClickStripePaymentClick}
                            handleReviewClick={handleReviewClick}
                            handleClickConsentBuilder={handleClickConsentBuilder}
                        />
                    </Menu>
                </Stack>
                {openContacts && <OpenDialogPopup
                    open={openContacts}
                    onClose={handleClose}
                    steps={leadStep}
                    type={'createLead'}
                    validStep={validStep}
                    updateStep={(type) => setValidStep(type)}
                    inputRef={inputRef}
                    setActive={setActive}
                    title='Create Lead'
                    headerButtons
                />}
                {openRecordAttend && <OpenAttendedPopup
                    open={openRecordAttend}
                    onClose={handleAttendClose}
                    type={'createLead'}
                    title='Create Lead'
                    headerButtons
                />}
                {openUnRecordAttend && <OpenUnAttendedPopup
                    open={openUnRecordAttend}
                    onClose={handleUnAttendClose}
                    type={'createLead'}
                    title='Create Lead'
                    headerButtons
                />}
                {ConsentBuilder && <ConsentBuilderPopUp
                    open={ConsentBuilder}
                    onClose={onCloseConsent}
                />}
                {openReview && <ReviewLinkPopUp
                    open={openReview}
                    onClose={onCloseReview}
                />}
            </Stack>
        </>
    );
};

NavSectionHorizontal.propTypes = {
    navConfig: PropTypes.array,
    sx: PropTypes.object,
};

export default memo(NavSectionHorizontal);