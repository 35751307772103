export const commonDialogHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
    rowGap: 2,
    flexDirection: { sm: 'row' },
    position: 'sticky',
    top: 0,
    textAlign: 'center',
    pt: 2,
    pb: 2,
    bgcolor: 'primary.main'
};
export const onBoardDialogHeader = {
    position: 'sticky',
    bgcolor: 'info.main',
    top: 0,
    textAlign: 'center',
    pt: 2,
    pb: 2
};
export const leadFooterButtonsStyle = {
    mt: 3,
    display: 'flex',
    justifyContent: 'flex-end',
    pr: { xs: 4 },
    pl: { xs: 4 },
    '@media(max-width: 900px)': {
        mt: '20px'
    }
};
export const onBoardFooterButtonsStyle = {
    mt: 3,
    display: 'flex',
    justifyContent: 'flex-end',
    pr: { sm: 7, xs: 4 },
    pl: { sm: 5, xs: 4 },
    '@media(max-width: 900px)': {
        mt: '20px'
    }
};
export const onBoardDialogFooter = {
    width: '100%',
    pb: 5,
    position: 'fixed',
    left: 0,
    bottom: 0,
    bgcolor: 'background.paper',
    zIndex: 99,
    '@media(max-width: 900px)': {
        pb: '15px'
    }
};
export const closeButtonBoxStyle = {
    display: 'flex',
    columnGap: 2,
    justifyContent: 'center'
};
export const steppersGridStyle = {
    mb: { 
        md: 3,
        xs: 3 
    }, 
    mt: { 
        md: 3, 
        xs: 3 
    }
};
export const stepTitleStyle = {
    mt: 1, 
    mb: 4, 
    width: '100%', 
    textAlign: 'center'
};
export const stepTextStyle = {
    m: 'auto', 
    mb: 5, 
    textAlign: 'center', 
    '@media (min-width:990px)': { width: '60%' }
};

export const dialogBoxStyle = (type) => ({
    zIndex: type === 'onboarad' ? 1117 : 1200,
    margin: { xs: '0 -16px', sm: 0 },
});

export const btnStyle = (type) => (
    type === 'createLead' || type === 'followup' ? {
        px: {
            xs: 3.5,
            sm: 8
        }
    } :
        {
            py: 0.75,
            px: {
                xs: 3.5,
                sm: 8
            }
        });