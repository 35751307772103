import PropTypes from 'prop-types';
// libraries
import { Grid, Dialog, Container, Stack, Button, Box, Typography } from '@mui/material';
import { Transition } from '../dialogPopUp';
// styles
import { common } from '../../theme/palette';
import {
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle
} from '../../styles/AddReminderPopup';
import { archiveDialogStyle } from '../../styles/DuplicateLeadStyle';

const ArchiveConfirmationPopup = ({ open, onClose, handleArchiveSuccess, selectedLeads }) => (
    <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={onClose}
        sx={archiveDialogStyle}
    >
        <Stack sx={{ ...reminderStackStyle, pb: 1 }}>
            <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                <Container
                    maxWidth={'xl'}
                    sx={{ padding: '0 !important' }}
                >
                    <Box>
                        <Box sx={{ mb: 3 }}>
                            <Typography variant='h5' sx={{ display: 'flex', alignItems: 'center' }}>
                                Archive Confirmation
                            </Typography>
                        </Box>
                        <Box sx={{ fontSize: '14px' }}>
                            Are you sure you want to archive the <b>{selectedLeads?.leadIds?.length || 0}</b> leads selected? <br /><br />
                            Note: You can view or restore archived leads at any time from 'Archived Leads' section if needed.
                        </Box>
                    </Box>
                </Container>
            </Grid>
        </Stack>
        <Box sx={{ ...reminderFooterStyle, pt: 0 }}>
            <Button
                variant='contained'
                sx={{ ...saveButtonStyle, p: '4px 10px', bgcolor: common.tomatoRed }}
                onClick={handleArchiveSuccess}
            >
                Archive
            </Button>
            <Button
                variant='outlined'
                sx={{ ...cancelButtonStyle, m: '0 0 0 8px', p: '4px 10px' }}
                onClick={() => onClose(true)}
            >
                Cancel
            </Button>
        </Box>
    </Dialog>
);

export default ArchiveConfirmationPopup;

ArchiveConfirmationPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleArchiveSuccess: PropTypes.func,
    selectedLeads: PropTypes.any,
};