import { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Grid, Dialog, Container, Slide, Stack } from '@mui/material';
// component
import { ConsentLink } from '../../sections/@dashboard/leadDetail';
import { NormalLoading } from '../loading';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { getPracticeItem } from '../../redux/slices/practice';
// style
import { DialogBox, popUpStack } from '../../styles/ConsentBuilder';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ConsentBuilderPopUp = ({ open, onClose }) => {
    const { practice } = useSelector((state) => state.practice);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const loader = Object.keys(practice).length === 0;

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeItem(practiceId));
        }
    }, [practiceId]);

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={DialogBox(practice?.settings?.consent_base_url)}
        >
            <Stack sx={popUpStack}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    {loader ?
                        <NormalLoading
                            tableData={loader && 'No Found'}
                            pageCount={loader && 'No Found'}
                        /> :
                        <Container
                            maxWidth={'xl'}
                            sx={{ padding: '0 !important' }}
                        >
                            <ConsentLink onClose={onClose} />
                        </Container>
                    }
                </Grid>
            </Stack>
        </Dialog>
    );
};

ConsentBuilderPopUp.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default ConsentBuilderPopUp;