import React from 'react';
import CustomStatusCell from '../CustomStatusCell';
import { getStatusCellClassName } from '../../../../../utils/getClassName';

const StatusCell = ({ onChange }) => ({
    field: 'status',
    headerName: 'Status',
    sortable: false,
    width: 190,
    minWidth: 180,
    cellClassName: (params) => getStatusCellClassName(params?.row?.status),
    renderCell: (params) => <CustomStatusCell params={params} onChange={onChange} />,
});

export default StatusCell;
