export const accordionStyle = {
    border: 'none',
    width: '100%',
    boxShadow: 'none !important',
    borderRadius: '0',
    marginBottom: '24px',
    padding: '0',
    '&:before': {
        display: 'none'
    },
    '&:last-child': {
        marginBottom: '0'
    },
    '&.disable': {
        pointerEvents: 'none'
    },
    '&.disable .MuiAccordionSummary-expandIconWrapper': {
        opacity: '0.5'
    },
    '.MuiAccordion-region': {
        position: 'relative' 
    }
};

export const accordionSummmeryStyle = (value) => ({
    bgcolor: 'transparent',
    minHeight: '46px !important',
    p: '10px 0',
    margin: '0',
    ' .MuiAccordionSummary-content': {
        margin: '0',
        alignItems: 'center'
    },
    ' .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        width: '13.33px',
        height: '1.67px',
        bgcolor: value ? '#212B36' : '#fff' 
    },
    ' .Mui-expanded .status': {
        display: 'none'
    },
    ' .Mui-expanded svg': {
        display: 'none'
    } 
});

export const headingTypoGraphy = {
    fontWeight: 600,
    fontSize: '14px', 
    mr: '8px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center' 
};

export const headingSpan = (value) => ({
    width: '12px',
    height: '12px',
    display: 'inline-block',
    borderRadius: '50%',
    marginRight: '10px',
    backgroundColor: value === 'Sent' && '#54D62C' || value === 'Upcoming' && '#FFC107' || '#FF4842' 
});

export const headingCounter = (value) => ({
    minWidth: '24px',
    minHeight: '22px',
    borderRadius: '6px',
    fontSize: '12px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: value === 'Sent' && 'rgba(84, 214, 44, 0.16)' || value === 'Upcoming' && 'rgba(255, 193, 7, 0.16)' || 'rgba(255, 72, 66, 0.16)',
    color: value === 'Sent' && '#229A16' || value === 'Upcoming' && '#B78103' || '#B72136' 
});

export const accordionDetailStyle = {
    p:'16px 28px 16px',
    maxHeight: '284px',
    overflow: 'auto',
    '&:before': {
        content: '\'\'',
        width: '1px',
        height: 'calc(100% - 16px)',
        bgcolor: 'rgba(145, 158, 171, 0.24)',
        display: 'inline-block',
        position: 'absolute',
        left: '5px',
        top: '16px'
    } 
};
export const dateTypoStyle = {
    color: '#637381 !important',
    margin: '10px',
    fontSize: '14px'
};
export const cardStyle = {
    padding : '24px',
    fontSize: '14px',
    borderRadius: '8px',
};
export const conversationStartStackStyle = (theme) => ({
    width: 'auto',
    minWidth: '48px',
    maxWidth: '320px',
    p: '12px',
    bgcolor: theme.palette.grey[200],
    borderRadius: 1,
    position: 'relative'
});
export const conversationEndStackStyle = (theme) => ({
    width: 'auto',
    minWidth: '48px',
    maxWidth: '320px',
    p: '12px',
    bgcolor: theme.palette.primary.lighter,
    borderRadius: 1
});
export const choiceItemStyle = (theme) => ({
    p: '12px 24px',
    boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2),0 12px 24px -4px rgba(145, 158, 171, 0.12)',
    mb: 1,
    borderRadius: 2,
    bgcolor: theme.palette.common.white,
    color: theme.palette.grey[600],
    fontWeight: '600'
});