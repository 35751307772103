import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
// mui
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import { MenuItem } from '@mui/material';
// other libraries
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
// components
import { Iconify } from '../../../../components';
import CustomTreatmentPriceCell from './CustomTreatmentPriceCell';
import CustomTreatmentPriceEditCell from './CustomTreatmentPriceEditCell';
import GetDetailPanelContent from './GetDetailPanelContent';
import ChoicesField from './ChoicesField';
import CustomDepositPaymentCell from './CustomDepositPaymentCell';
import CustomDepositDateCell from './CustomDepositDateCell';
import { AssigneeCell, BookedWithCell, ContactActionCell, FirstNameCell, LastNameCell, TreatmentPriceCell, ViewDetailCell, ConsultationDateCell, DateOfAppointmentCell, TagCell, CreatedAtDateCell, ReferralCell, ConsultationBookedWithCell, TransitionDateCell, LastContactCell, EmailCell, StartedTableColumnActions } from './tableCells';
import DataGridSlots from './DataGridSlots';
// redux
import { setCurrentTreatmentDataGlobal } from '../../../../redux/slices/multiTreatment';
import { followUpSlice, getAppointment, leadPage, tableLead } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
// utils/hooks/constants
import { UpdateTableData } from '../../../../utils/allTableTab';
import {
    formatData,
    getColumns,
    getRowClassName,
    handleGetVisibleColumns,
    processRowUpdate,
    showDate,
} from '../../../../utils/leadTableUtils';
import { applyTablePreferences, ChangePinnedColumns, getCurrentTableData, handleColumnWidthChange, handleSortingChange, handleTablePreference } from '../../../../utils/tablePreferences';
import { getAssignees } from '../../../../utils/multiAsignees';
import capitalizeFirstLetter from '../../../../utils/capitalizedFunc';
import { getTransitionDate, handleChangeDateFormat } from '../../../../utils/dataGridUtils';
import useGetCurrentUserId from '../../../../hooks/useGetCurrentUserId';
import { useResponsive } from '../../../../hooks';
import { initialPaginationData, phoneNumberCell } from '../../../../constants/dataGridConstants';
// style
import { leadDetailTabsGridStyle } from '../../../../styles/NewLead';

const AlignerApproveLeadDataGrid = ({
    tableData,
    typed,
    isReportData,
    selectedRow,
    tableTitle,
    tablePath,
    paginationModel,
    setPaginationModel,
    handlePaginationChange,
    pageCount,
    handleChangePerSize,
    OpenAssignTask,
    reloadCounter,
    updateLeadPage,
    openBulkArchive,
    onSelectionModelChange,
    forceUpdate,
    sortingApiCall,
    columnVisibilityModel,
    handleColumnVisibilityChange,
    handleColumnOrderChange,
    openAppointmentAssignTaskFuc,
    handleReloadAPI,
    setTableData,
    handleSetTableData,
    setCurrentTreatmentData,
    isMultiTreatmentTable,
    setColumnVisibilityModel,
    selectedTab
}) => {
    const [selectData, setSelectedData] = useState({});
    const [open, setOpen] = useState(false);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
    const choicesData = useSelector((state) => state.practiceTreatment.choicesData.results);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { tablePreferences: { results }, tablePreferenceLoading, globalTablePreferences } = useSelector((state) => state.tablePreferences);
    const {
        archivedLoading,
        loadingForLead,
        loadingForClincheckLead,
    } = useSelector((state) => state.lead);

    const isDesktop = useResponsive('down', 'sm');
    const anchorRef = useRef(null);
    const anchorMoreMenuRef = useRef(null);
    const [openMenu, setOpenMenuActions] = useState(null);
    const [menuCurrentTarget, setMenuCurrentTarget] = useState(null);
    const [sortModel, setSortModel] = useState([]);
    const apiRef = useGridApiRef();

    const [pinnedColumns, setPinnedColumns] = useState({
        right: isDesktop ? ['actions'] : ['contact', 'viewDetails', 'actions']
    });

    const handleSorting = ({ key, order }) => {
        setSortModel([{ field: key, sort: order }]);
        sortingApiCall(key, order);
        UpdateTableData(typed, { order, orderValue: key, perPage: 10, pageNumber: 0 });
        setPaginationModel({ pageSize: 10, page: 0 });
    };

    useEffect(() => {
        const tableData = getCurrentTableData(results, tableTitle);
        const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableTitle);
        const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
        if (!isEmpty(choicesData) && isArray(currentTableData) && currentTableData.length > 0) {
            const allColumns = apiRef?.current?.getAllColumns()?.map((ele) => ele?.field) || [];
            handleTablePreference({ allColumns, setColumnVisibilityModel, tableTitle: selectedTab || tableTitle, setPinnedColumns, handleSorting });
        }
    }, [results, choicesData, globalTablePreferences]);

    const showTransitionDate = (data, row) => {
        const transitionDateTime = getTransitionDate(row?.treatmentData?.[0]?.state_transition_logs);
        if (transitionDateTime) {
            const formattedDate = moment(transitionDateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
            const formattedTime = moment(transitionDateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
            return `${formattedDate} <span>at ${formattedTime}</span>`;
        }
        return '';
    };

    const navigate = useNavigate();

    const setCustomTimeOut = (row, key, id) => {
        handleCurrentTreatmentData(row);
        dispatch(followUpSlice(id, practiceId));
        setTimeout(() => {
            dispatch(getAppointment(practiceId, id));
            selectedRow(row, key);
            dispatch(tableLead(row));
        }, 800);
    };

    const handleAttendedFailedClick = (id, row) => {
        setCustomTimeOut(row, 'recordUnAttended', id);
    };

    const handleAttendedClick = (id, row) => {
        setCustomTimeOut(row, 'recordAttended', id);
    };

    const handleAttendedPopup = (id, row) => {
        setCustomTimeOut(row, 'consultationAttended', id);
    };

    const handleCurrentTreatmentData = (row) => {
        const payload = {
            lead_id: row?.id || '',
            first_name: row.first_name || '',
            last_name: row.last_name || '',
            treatmentData: row?.treatmentData?.find((ele) => ele.id === row?.treatment_id)
        };
        setCurrentTreatmentData(payload);
        dispatch(setCurrentTreatmentDataGlobal(payload));
    };

    const buttonHandleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        if (anchorMoreMenuRef.current && anchorMoreMenuRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handlePopup = (id, status, row) => {
        handleCurrentTreatmentData(row);
        if (status === 'FAILED_TO_ATTEND_CONSULTATION') {
            handleAttendedFailedClick(id, row);
            return;
        }
        dispatch(tableLead(row));
        dispatch(followUpSlice(id, practiceId));
        setTimeout(() => {
            selectedRow(row, 'contactLead', true);
        }, 800);
    };

    const viewDetailBtn = (id, status, row) => {
        handleCurrentTreatmentData(row);
        if (status === 'CLOSED') {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${id}`);
            return;
        }
        if (status === 'TREATMENT_STARTED') {
            selectedRow(row, 'TreatmentConfirmation');
        }
    };

    const handleNavigate = (row) => {
        if (typed !== 'ArchiveTable' && !row?.is_archive_pending) {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${row?.lead_id}`);
        }
    };

    const handleArchivedNavigate = (row) => {
        if (typed === 'ArchiveTable' && !row.is_archive_pending) {
            dispatch(leadPage({ name: tableTitle, path: tablePath }));
            navigate(`/dashboard/practice/${practiceId}/archived/${row?.lead_id}`);
        }
    };

    const handleOpenMenu = (event, params) => {
        if (!params.is_archive_pending) {
            setOpenMenuActions(params.row.id);
            setMenuCurrentTarget(event.currentTarget);
        }
    };

    const handleStartTreatment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'StartTreatmentConfirmation');
    };
    const handleCompleteTreatment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'TreatmentConfirmation');
    };

    const handleEditAppointment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'EditAppointment');
    };

    const handleAddAppointment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'AddAppointment');
    };

    const handleBacktoAttendedPopup = (id, row) => {
        dispatch(followUpSlice(id, practiceId));
        setOpenMenuActions(null);
        dispatch(tableLead(row));
        selectedRow(row, 'backToAttended');
    };

    const handleRestore = (row) => {
        setTimeout(() => {
            setOpenMenuActions(null);
        }, 800);
        dispatch(tableLead(row));
        selectedRow(row, 'restoreArchived');
    };

    const handleSelect = (data) => {
        setSelectedData(data);
        return data;
    };

    const defaultValues = {};

    const methods = useForm({ defaultValues });

    const { setValue } = methods;

    const toggleCell = {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        hideable: false,
        renderCell: () => (
            <></>
        ),
    };

    const renderPriceEditInputCell = (params) => <CustomTreatmentPriceEditCell params={params} />;
    const renderPriceCell = (params) => <CustomTreatmentPriceCell params={params} />;

    const editAppointmentMenuItem = (params) => {
        if (params.row.status === 'CONSULTATION_BOOKED' && params.row?.appointments?.length > 0) {
            return <MenuItem
                onClick={() => handleEditAppointment(params.row)}
            >
                <Iconify icon={'solar:pen-bold'} />
                Edit appointment
            </MenuItem>;
        }
        return null;
    };
    const backToAttendedMenuItem = (params) => {
        if (params.row.status === 'TREATMENT_STARTED') {
            return <MenuItem
                onClick={() => handleBacktoAttendedPopup(params.row.lead_id, params.row)}
            >
                <Iconify icon={'jam:refresh-reverse'} />
                Back to Attended
            </MenuItem>;
        }
        return null;
    };
    const restoreLeadMenuItem = (params) => <MenuItem
        onClick={() => handleRestore(params.row)}
    >
        <Iconify icon={'ic:sharp-restore'} />
        Restore
    </MenuItem>;

    const handleViewDetailClick = (params) => {
        dispatch(leadPage({ name: tableTitle, path: tablePath }));
        navigate(`/dashboard/practice/${practiceId}/lead-detail/${params.row.lead_id}`);
    };

    const handleTagCellClick = (params) => {
        if (typed === 'ArchiveTable') {
            handleArchivedNavigate(params.row);
            return;
        };
        setSelectedData(params?.row);
    };

    const handleDeleteTreatmentPayment = (row) => {
        setOpenMenuActions(null);
        selectedRow(row, 'deleteTreatmentPayment');
    };

    const contactCellProps = {
        anchorRef,
        open,
        setOpen,
        isDesktop,
        buttonHandleClose,
        handlePopup,
        viewDetailBtn,
        handleAttendedClick,
        handleAttendedFailedClick,
        handleAttendedPopup
    };

    const treatmentStartedLeadsColumns = [
        {
            ...TransitionDateCell({
                typed,
                showTransitionDate,
                updateLeadPage,
                reloadCounter,
                getTransitionDate
            })
        },
        {
            ...CreatedAtDateCell({
                handleChangeDateFormat,
                showDate,
                handleNavigate,
                formatData,
                typed,
                methods,
                setValue
            })
        },
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...EmailCell() },
        {
            ...ConsultationDateCell({
                typed,
                openAppointmentAssignTaskFuc,
                methods,
                setValue,
                handleChangeDateFormat,
                updateLeadPage,
                reloadCounter,
            })
        },
        { ...ConsultationBookedWithCell({ openAppointmentAssignTaskFuc }) },
        {
            ...DateOfAppointmentCell({
                methods,
                handleChangeDateFormat,
                typed,
                updateLeadPage,
                reloadCounter,
                openAppointmentAssignTaskFuc,
                setValue,
            })
        },
        { ...BookedWithCell({ openAppointmentAssignTaskFuc, typed, updateLeadPage, reloadCounter }) },
        { ...phoneNumberCell },
        { ...toggleCell },
        { ...TreatmentPriceCell({ renderPriceEditInputCell, renderPriceCell }) },
        { ...ReferralCell({ updateLeadPage, reloadCounter, typed, setTableData }) },
        { ...ContactActionCell(contactCellProps) },
        { ...LastContactCell({ methods, handleChangeDateFormat, setValue, handleNavigate }) },
        { ...AssigneeCell({ getAssignees, OpenAssignTask }) },
        {
            ...TagCell({
                handleTagCellClick,
                typed,
                selectData,
                handleSelect,
                reloadCounter,
                updateLeadPage,
                setTableData,
                handleArchivedNavigate
            })
        },
        { ...CustomDepositPaymentCell(selectedRow, handleDeleteTreatmentPayment) },
        { ...CustomDepositDateCell(handleSetTableData) },
        { ...ViewDetailCell({ leadPage, tableTitle, tablePath }) },
        {
            ...StartedTableColumnActions({
                openMenu,
                menuCurrentTarget,
                handleOpenMenu,
                setOpenMenuActions,
                typed,
                handleViewDetailClick,
                isReportData,
                anchorMoreMenuRef,
                setOpen,
                open,
                handleAttendedClick,
                handleAttendedFailedClick,
                buttonHandleClose,
                handleAttendedPopup,
                viewDetailBtn,
                handlePopup,
                handleStartTreatment,
                handleCompleteTreatment,
                editAppointmentMenuItem,
                backToAttendedMenuItem,
                restoreLeadMenuItem,
                handleAddAppointment
            })
        }
    ];

    const viveraTable = [
        { ...FirstNameCell() },
        { ...LastNameCell() },
        { ...BookedWithCell({ openAppointmentAssignTaskFuc, typed, updateLeadPage, reloadCounter }) },
        { ...toggleCell },
    ];

    const getLoadingState = (type) => {
        if (type === 'ConsultantClinCheckAttend') return (pageCount === undefined || archivedLoading || loadingForClincheckLead || tablePreferenceLoading);
        return (pageCount === undefined || archivedLoading || loadingForLead || tablePreferenceLoading);
    };

    const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
        setDetailPanelExpandedRowIds(
            newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
        );
    }, []);

    const getDetailPanelHeight = useCallback(() => 'auto', []);

    const getDetailPanel = () => {
        if (isMultiTreatmentTable) return {};
        return {
            getDetailPanelContent: ({ row }) => <GetDetailPanelContent
                row={row}
                tableTitle={tableTitle}
                typed={typed}
                isReportData={isReportData}
                selectedRow={selectedRow}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                handlePaginationChange={handlePaginationChange}
                pageCount={pageCount}
                handleChangePerSize={handleChangePerSize}
                OpenAssignTask={OpenAssignTask}
                reloadCounter={reloadCounter}
                updateLeadPage={updateLeadPage}
                openBulkArchive={open}
                onSelectionModelChange={onSelectionModelChange}
                forceUpdate={forceUpdate}
                tablePath={tablePath}
                sortingApiCall={sortingApiCall}
                columnVisibilityModel={columnVisibilityModel}
                handleColumnVisibilityChange={handleColumnVisibilityChange}
                handleColumnOrderChange={handleColumnOrderChange}
                isMultiTreatmentTable
                handleReloadAPI={handleReloadAPI}
                setCurrentTreatmentData={setCurrentTreatmentData}
                openAppointmentAssignTaskFuc={openAppointmentAssignTaskFuc}
                setTableData={setTableData}
                handleSetTableData={handleSetTableData}
            />,
            getDetailPanelHeight,
            detailPanelExpandedRowIds,
            onDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange
        };
    };

    const addDynamicColumns = (columns, choicesData, selectedTab) => {
        choicesData?.forEach((choice) => {
            if (choice.metadata && choice.metadata.tables && choice.metadata.tables.includes(selectedTab === 'Vivera table' ? selectedTab : tableTitle)) {
                columns.push({
                    field: choice.name,
                    headerName: capitalizeFirstLetter(choice.name),
                    sortable: false,
                    editable: false,
                    minWidth: 200,
                    renderCell: (params) => (
                        <ChoicesField
                            rowData={params.row}
                            updateLeadPage={updateLeadPage}
                            reloadCounter={reloadCounter}
                            tableType={typed}
                            handleSetTableData={handleSetTableData}
                            choiceValue={choice}
                            choicesData={choicesData}
                        />
                    ),
                });
            }
        });
        return columns;
    };

    const dynamicColumns = getColumns(
        addDynamicColumns(
            applyTablePreferences({
                columns: selectedTab === 'Vivera table' ? viveraTable : treatmentStartedLeadsColumns,
                tableName: selectedTab || tableTitle,
                isMultiTreatmentTable
            }),
            choicesData,
            selectedTab) || [],
        selectedTab || tableTitle,
        isMultiTreatmentTable);

    const paginationModelChangeEvent = (pageData) => {
        setPaginationModel(pageData);
        handlePaginationChange(pageData);
    };

    const onSortModelChangeEvent = (sortModel, details) => {
        if (sortModel?.[0]?.field || sortModel?.[0]?.sort) {
            handleSortingChange({ userId, key: sortModel?.[0]?.field, order: sortModel?.[0]?.sort, details, tableName: selectedTab || tableTitle });
        } else {
            setSortModel(sortModel);
            sortingApiCall('created_at', 'desc');
            UpdateTableData(typed, { order: 'desc', orderValue: 'created_at', perPage: 10, pageNumber: 0 });
            setPaginationModel({ pageSize: 10, page: 0 });
        }
    };

    const userId = useGetCurrentUserId();

    const handlePinnedColumnsChange = useCallback((updatedPinnedColumns, details) => {
        ChangePinnedColumns({ updatedPinnedColumns, setPinnedColumns, userId, tableName: selectedTab || tableTitle, details });
    }, [userId]);

    const getProps = () => {
        if (isMultiTreatmentTable) return {};
        return { onColumnWidthChange: (data, api, details) => handleColumnWidthChange({ data, tableName: selectedTab || tableTitle, userId, api, details }) };
    };

    return (
        <DataGridPro
            key={forceUpdate}
            checkboxSelection={!isMultiTreatmentTable}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            rowHeight={150}
            autoHeight
            rows={tableData}
            columns={dynamicColumns}
            apiRef={apiRef}
            editMode='cell'
            processRowUpdate={(newRow, oldRow) => processRowUpdate(newRow, oldRow, handleSetTableData)}
            sortingMode='server'
            disableColumnMenu={isMultiTreatmentTable}
            sortModel={sortModel}
            onSortModelChange={(sortModel, details) => onSortModelChangeEvent(sortModel, details)}
            getRowClassName={(params) => getRowClassName(params, tableTitle)}
            getRowHeight={() => 'auto'}
            pageSizeOptions={[10, 25]}
            pagination={pageCount > 10}
            paginationMode='server'
            paginationModel={paginationModel}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            {...getProps()}
            className={`leads-datagrid-table ${isMultiTreatmentTable ? 'detail-panel-table' : ''}`}
            scrollbarSize={500}
            initialState={initialPaginationData}
            rowCount={pageCount}
            onRowSelectionModelChange={(newSelectionModel) => onSelectionModelChange(newSelectionModel)}
            disableColumnFilter
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            onColumnOrderChange={() => handleColumnOrderChange(handleGetVisibleColumns(apiRef))}
            onPaginationModelChange={(pageData) => paginationModelChangeEvent(pageData)}
            onPageSizeChange={(newPageSize) => handleChangePerSize(newPageSize)}
            loading={getLoadingState(typed)}
            sx={leadDetailTabsGridStyle(getLoadingState())}
            {...getDetailPanel()}
            slots={{ ...DataGridSlots({ isMultiTreatmentTable, tableTitle, openBulkArchive }) }}
            hideFooterPagination={isMultiTreatmentTable}
        />
    );
};

export { AlignerApproveLeadDataGrid };

AlignerApproveLeadDataGrid.propTypes = {
    tableData: PropTypes.array,
    typed: PropTypes.string,
    isReportData: PropTypes.bool,
    selectedRow: PropTypes.object,
    tableTitle: PropTypes.string,
    tablePath: PropTypes.string,
    paginationModel: PropTypes.object,
    setPaginationModel: PropTypes.func,
    handlePaginationChange: PropTypes.func,
    pageCount: PropTypes.number,
    handleChangePerSize: PropTypes.func,
    OpenAssignTask: PropTypes.func,
    reloadCounter: PropTypes.func,
    updateLeadPage: PropTypes.func,
    openBulkArchive: PropTypes.func,
    onSelectionModelChange: PropTypes.func,
    forceUpdate: PropTypes.func,
    sortingApiCall: PropTypes.func,
    columnVisibilityModel: PropTypes.object,
    handleColumnVisibilityChange: PropTypes.func,
    handleColumnOrderChange: PropTypes.func,
    openAppointmentAssignTaskFuc: PropTypes.func,
    handleReloadAPI: PropTypes.func,
    setTableData: PropTypes.func,
    handleSetTableData: PropTypes.func,
    setCurrentTreatmentData: PropTypes.func,
    isMultiTreatmentTable: PropTypes.bool,
    setColumnVisibilityModel: PropTypes.func,
    selectedTab: PropTypes.func,
};
