/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
import _ from 'lodash';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useEffect, useState, useCallback } from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import {
    Box,
    Table,
    TableBody,
    TableContainer,
    TextField,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
    Typography
} from '@mui/material';
// components
import CategorySummaryRow from './CategorySummaryRow';
import { TableHeadCustom, TableNoData } from '../table';
import { TableLoading } from '../loading';
// constants
import { tableLabels } from '../../constants/CategorySummaryConst';
import { treatmentCategory, categorySummary, reportLeadStatusesV2, NOTBOOKED_AND_ENQUIRY_COLUMNS, leadArchive } from '../../constants/ReportsLeadConst';
import { RangeShortcut, endDate, rangeShortcuts } from '../../constants/ReportSummaryConst';
// hooks
import useTable from '../../hooks/useTable';
// store
import { getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
import { getReportsListing, reportsLeadListing, setDateRange } from '../../redux/slices/analytics';
import { useSelector, dispatch } from '../../redux/store';
import { getTemplateList } from '../../redux/slices/practiceMessage';
// style
import { TableheadStyle, cardStyle, categoryMainBoxStyle, categoryTableContainerStyle, categoryTopBoxStyle } from '../../styles/CategorySummaryTableStyle';

const CategorySummaryTable = ({ type }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { categorySummaryData } = useSelector((state) => state.analytics);
    const { results } = useSelector((state) => state.practiceTreatment?.practiceTreatment);
    const [tableRowdata, setTableRowdata] = useState(null);
    const currentDate = dayjs();
    const startDate = currentDate.startOf('month');
    const [value, setValue] = useState([startDate.toDate(), currentDate.toDate()]);

    const {
        order,
        onSort,
        orderBy,
    } = useTable({ defaultOrder: 'desc' });

    const handleGetReportsListingApi = (changedStartTime, changedEndTime) => {
        NOTBOOKED_AND_ENQUIRY_COLUMNS.forEach((ele) => {
            const detail = { ...ele };
            if (changedStartTime && changedEndTime) {
                detail.startTime = changedStartTime;
                detail.endTime = changedEndTime;
            }
            dispatch(getReportsListing(detail));
        });
    };

    useEffect(() => {
        if (practiceId) {
            dispatch(getTemplateList(practiceId));
        }
        if (practiceId && type === 'Category summary') {
            const startTime = moment(new Date()).startOf('month').format('YYYY-MM-DD 00:00:00');
            const endTime = moment(new Date()).format('YYYY-MM-DD 23:59:00');
            const data = {
                practiceId,
                type: categorySummary,
                category: treatmentCategory,
                categoryStatus: reportLeadStatusesV2,
                isCategoryTable: true,
                startTime,
                endTime
            };
            dispatch(reportsLeadListing(data));
            handleGetReportsListingApi();
            dispatch(getPracticeTreatmentList(practiceId));
        }
    }, [practiceId]);

    useEffect(() => {
        if (categorySummaryData) {
            const grouped = _.mapValues(_.groupBy(categorySummaryData?.data, treatmentCategory), arr => arr.map(obj => _.omit(obj, treatmentCategory)));
            const tableData = Object.entries(grouped).filter((el) => el[0] !== 'null');
            setTableRowdata(tableData);
        }
    }, [categorySummaryData]);

    useEffect(() => {
        const startTime = moment(new Date()).startOf('month').format('YYYY-MM-DD 00:00:00');
        const endTime = moment(new Date()).format('YYYY-MM-DD 23:59:00');
        dispatch(
            setDateRange({
                startDate: moment(startTime, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                endDate: moment(endTime, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            })
        );
    }, []);

    const dateChange = (newValue) => {
        setValue(newValue);
        const changedStartTime = newValue[0] ? moment(newValue[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00') : '';
        const changedEndTime = newValue[1] ? moment(newValue[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:00') : '';
        const changedStartDate = newValue[0] ? moment(newValue[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        const changedEndDate = newValue[1] ? moment(newValue[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        dispatch(setDateRange({ startDate: changedStartDate, endDate: changedEndDate }));
        const data = {
            practiceId,
            type: categorySummary,
            category: treatmentCategory,
            categoryStatus: reportLeadStatusesV2,
            startTime: changedStartTime,
            endTime: changedEndTime,
            isCategoryTable: true,
            archivedCategory: leadArchive,
        };
        handleGetReportsListingApi(changedStartTime, changedEndTime);
        dispatch(reportsLeadListing(data));
        dispatch(reportsLeadListing({ ...data, isArchived: true }));
    };

    const handleSorting = (value) => {
        onSort(value);
        switch (value) {
            case 'enquiries': {
                const data = _.orderBy(tableRowdata, [o => o[1]?.reduce((total, currentValue) => total += currentValue?.count_id, 0)], [order]);
                setTableRowdata(data);
                return;
            }
            case 'category': {
                const data = _.orderBy(tableRowdata, [o => results.filter((el) => el.treatment_item?.treatment_category?.id === Number(o[0]))[0]?.treatment_item?.treatment_category?.name], [order]);
                setTableRowdata(data);
                return;
            }
            case 'TOTAL_PRICE': {
                const data = _.orderBy(tableRowdata, [o => o[1]?.reduce((total, currentValue) => total += currentValue?.sum_lead_treatments__custom_price, 0)], [order]);
                setTableRowdata(data);
                return;
            }
            default: {
                const data = _.orderBy(tableRowdata, [o => o[1].filter(el => el.lead_treatments__state_transition_logs__state === value)[0]?.count_id || 0], [order]);
                setTableRowdata(data);
            }
        }
    };

    const buildHandleRangeClick = (setValue) => (range) => {
        const today = dayjs();
        switch (range) {
            case RangeShortcut.allTime:
                setValue([null, null]);
                dateChange([null, null]);
                break;
            case RangeShortcut.thisWeek:
                setValue([today.startOf('week'), today.endOf('week')]);
                dateChange([today.startOf('week'), today.endOf('week')]);
                break;
            case RangeShortcut.lastWeek:
                setValue([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                dateChange([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                break;
            case RangeShortcut.today:
                setValue([today.startOf('day'), today.endOf('day')]);
                dateChange([today.startOf('day'), today.endOf('day')]);
                break;
            case RangeShortcut.tomorrow:
                setValue([today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                dateChange([today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                break;
            case RangeShortcut.last7Days:
                setValue([today.subtract(1, 'week'), today]);
                dateChange([today.subtract(1, 'week'), today]);
                break;
            case RangeShortcut.currentMonth:
                setValue([today.startOf('month'), today.endOf('month')]);
                dateChange([today.startOf('month'), today.endOf('month')]);
                break;
            case RangeShortcut.prevMonth:
                setValue([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                dateChange([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                break;
            case RangeShortcut.reset:
                setValue([null, null]);
                break;
            default:
                break;
        }
    };

    const RangeShortcutsPanel = ({ setValue, children }) => {
        const handleRangeClick = useCallback(
            (range) => setValue && buildHandleRangeClick(setValue)(range),
            [setValue],
        );
        return (
            <Box sx={{ display: 'flex', '@media(max-width: 900px)': { display: 'inline-block' } }}>
                <Box sx={{ m: 2 }} display="flex" gap={2}>
                    <div>
                        <Typography variant="overline">Date range shortcuts</Typography>
                        <List>
                            {rangeShortcuts.map(({ range, label }) => (
                                <ListItem key={range} disablePadding>
                                    <ListItemButton onClick={() => handleRangeClick(range)}>
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <Divider orientation="vertical" />
                </Box>
                {children}
            </Box>
        );
    };

    return (
        <Box>
            <Box sx={categoryTopBoxStyle(type)} >
                <Box sx={{ display: type === 'Reports' && 'none', '@media(max-width: 900px)': { width: '100%', mt: '15px' } }} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            onChange={(newValue) => dateChange(newValue)}
                            value={value}
                            inputFormat="DD MMM YYYY"
                            maxDate={endDate}
                            renderInput={(startProps, endProps) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
                                    <TextField {...startProps} onKeyDown={(evt) => evt.preventDefault()} label="Start date" />
                                    <Box sx={{ mx: 1 }}> to </Box>
                                    <TextField {...endProps} onKeyDown={(evt) => evt.preventDefault()} label="End date" />
                                </Box>
                            )}
                            components={{ PaperContent: RangeShortcutsPanel }}
                            PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
                            componentsProps={{ paperContent: { setValue } }}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box sx={categoryMainBoxStyle(type)}>
                {type === 'Reports' && <Box sx={cardStyle}>
                    <Typography variant="h4" sx={{ mb: 0, fontSize: '18px !important' }}>Category Summary</Typography>
                </Box>}
                <TableContainer sx={categoryTableContainerStyle(type)}>
                    <Box sx={{ maxHeight: '540px', overflow: 'auto' }}>
                        <Table stickyHeader sx={TableheadStyle} >
                            <TableHeadCustom
                                headLabel={tableLabels || []}
                                rowCount={10}
                                order={order}
                                orderBy={orderBy}
                                onSort={handleSorting}
                                sx={{ '.MuiTableCell-root': { cursor: 'pointer' }, '.MuiTableCell-root:first-of-type': { textAlign: 'left', minWidth: '170px' } }}
                            />
                            <TableBody>
                                {tableRowdata === null && <TableLoading tableData={tableRowdata === null && 'No Found'} pageCount={tableRowdata === null && 'No Found'} />}
                                {tableRowdata?.length > 0 && tableRowdata?.map((el, index) => <CategorySummaryRow key={index} data={el} />)}
                                {tableRowdata?.length === 0 && <TableNoData isNotFound={tableRowdata?.length === 0} />}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
            </Box>
        </Box>
    );
};

CategorySummaryTable.propTypes = {
    type: PropTypes.string,
};

export default CategorySummaryTable;
