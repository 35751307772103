import React from 'react';
import ConsultationBookedWithField from '../ConsultationBookedWithField';

const ConsultationBookedWithCell = ({ openAppointmentAssignTaskFuc }) => ({
    field: 'consultation_booked_with',
    headerName: 'Consultation booked with',
    width: 200,
    minWidth: 150,
    sortable: false,
    renderCell: (params) => (
        <ConsultationBookedWithField rowData={params.row} OpenAssignTask={openAppointmentAssignTaskFuc} />
    ),
});

export default ConsultationBookedWithCell;
