import React from 'react';
import { Box } from '@mui/material';
import AssigneeField from '../AssigneeField';
import { assignMainBoxStyle } from '../../../../../styles/NewLeadTableRowStyle';

const AssigneeCell = ({ getAssignees, OpenAssignTask }) => ({
    field: 'assignee',
    headerName: 'Assignee',
    sortable: false,
    editable: false,
    width: 150,
    minWidth: 150,
    renderCell: (params) => (
        <Box
            sx={assignMainBoxStyle}
            onClick={(e) => OpenAssignTask(params.row, e)}
            onMouseDown={(e) => OpenAssignTask(params.row, e)}
        >
            <AssigneeField
                rowData={params?.row}
                getAssignees={getAssignees}
            />
        </Box>
    ),
});

export default AssigneeCell;