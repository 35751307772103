export const TEMPLATE_HEAD = [
    { id: 'Channel', label: 'Channel', align: 'left', sort: '' },
    { id: 'Template name', label: 'Template name', align: 'left', sort: '' },
    { id: 'Subject', label: 'Subject', align: 'left', sort: '' },
    { id: 'Category', label: 'Category', align: 'left', sort: '' },
    { id: '' },
];
export const TEMPLATE_HEAD_SUPER_ADMIN = [
    { id: 'Channel', label: 'Channel', align: 'left', sort: '' },
    { id: 'Template name', label: 'Template name', align: 'left', sort: '' },
    { id: 'Subject', label: 'Subject', align: 'left', sort: '' },
    { id: 'Category', label: 'Category', align: 'left', sort: '' },
    { id: 'Tags', label: 'Tags', align: 'center', sort: '' },
    { id: '' },
];

export const TEMPLATE_CAMPAIGN_HEAD = [
    { id: '' },
    { id: 'Channel', label: 'Channel', align: 'left', sort: '' },
    { id: 'Template name', label: 'Template name', align: 'left', sort: '' },
    { id: 'Subject', label: 'Subject', align: 'left', sort: '' },
    { id: 'Category', label: 'Category', align: 'left', sort: '' },
    { id: '' },
];

export const CAMPAIGN_HEAD = [
    { id: 'create_date', label: 'Created at', dateFormat: true, align: 'left' },
    { id: 'Template name', label: 'Campaign name', align: 'left' },
    { id: 'Subject', label: 'Subject', align: 'left' },
    { id: 'Status', label: 'Status', align: 'left' },
    { id: 'schedule_date', label: 'Schedule date', align: 'left' },
    { id: 'Tags', label: 'Tags', align: 'left' },
    { id: 'Category', label: 'Category', align: 'left' },
    { id: '' },
];

export const Channel = [
    { name: 'New Email', value: 'EMAIL' },
    { name: 'New SMS', value: 'SMS' }
];

export const isAdminTable = [
    { id: 'message.type', label: 'Type', align: 'left' },
    { id: 'subject', label: 'Subject', align: 'left' },
    { id: 'delay', label: 'Send', align: 'left' },
    { id: 'is_enabled', label: 'Status', align: 'left' },
    { id: '' },
];

export const notAdminTable = [
    { id: 'message.type', label: 'Type', align: 'left' },
    { id: 'subject', label: 'Subject', align: 'left' },
    { id: 'delay', label: 'Send', align: 'left' },
    { id: 'is_enabled', label: 'Status', align: 'left' },
];

// toolbar
export const LEAD_STATUS = ['new', 'consultation ', 'consultation booked', 'consultation attended', 'treatment started', 'failed to attend consultation', 'closed'];
export const TEMPLATE_STATUS = ['communication', 'marketing', 'promotion', 'other'];
export const CAMPAIGN_STATUS = [
    { value: 'All', label: 'All' },
    { value: 'SCHEDULED', label: 'Scheduled' },
    { value: 'DRAFT', label: 'Draft' },
    { value: 'SENT', label: 'Sent' },
    { value: 'CANCELLED', label: 'Cancelled' },
];

// new
export const optionsCustom = [
    'option 1',
    'option 2',
    'option 3'
];

// whatsapp
export const names = [
    'Treatment_name',
    'Treatment_price',
    'Lead_first_name',
    'Lead_last_name',
    'Treatment_status',
];

export const days = [
    'Immediately',
    '1 day',
    '2 day',
    '1 week',
    '2 weeks',
];

// MessageHeader
export const options = [
    {
        value: 'Delete',
        icon: 'solar:trash-bin-trash-bold'
    }
];

export const facebookOptions = [
    {
        value: 'Edit',
        icon: 'solar:pen-bold',
        divider: true
    },
    {
        value: 'Delete',
        icon: 'solar:trash-bin-trash-bold',
        color: 'rgba(255, 72, 66, 1)',
    }
];

export const USER_TABS = [
    { value: 'TCO,USER', label: 'TCO’s + Users' },
    { value: 'Practitioner', label: 'Practitioner' },
    { value: 'ADMIN', label: 'Admin' },
];

export const TCO_USER_TABS = [
    { value: 'TCO,USER', label: 'TCO’s + Users' },
];

export const whatsappTable = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'category', label: 'Category', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'tags', label: 'Tags', align: 'left' },
];