import PropTypes from 'prop-types';
// @mui
import {
    useTheme,
    LinearProgress,
    Box,
    Stack,
} from '@mui/material';
// style
import { tableLoader } from '../../styles/loading';

const NormalLoading = ({ tableData, pageCount, type }) => {
    const theme = useTheme();
    return (
        tableData === 'No Found' && pageCount === 'No Found' && (
            <Box sx={{ height: type === 'treatment' ? 'auto' : '300px', width: 'auto' }}>
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={tableLoader(theme, type)}
                >
                    <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 320 }} />
                </Stack>
            </Box>
        ));
};

NormalLoading.propTypes = {
    tableData: PropTypes.any,
    pageCount: PropTypes.any,
    type: PropTypes.string
};

export default NormalLoading;