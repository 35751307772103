/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Backdrop,
    Box,
    Stack,
    Paper,
    InputBase,
    TableCell,
    MenuList,
    MenuItem,
    Typography,
    TableRow,
    IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { isArray } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';
// components
import { TableLoading } from 'src/components/loading';
import { NotFound } from 'src/components/empty-content';
import Editor from '../../../../components/editor/Editor';
import { Iconify } from '../../../../components';
// hooks
import { useBoolean, useResponsive } from '../../../../hooks';
// redux
import { useSelector, dispatch } from '../../../../redux/store';
import { getWhatsappAccountPhones, whatsappMessageSend } from '../../../../redux/slices/whatsappAccount';
import { get10WpTemplateList, getSearchWpTemplateList, updateShowWpSubjectList } from '../../../../redux/slices/whatsAppMessages';
import {
    inputBaseStyle,
    whatsappPaperStyle,
    emailBoxStyle,
    focusUnableToContactBoxStyle
} from './styles';
import {
    editorStyle
} from '../../../../styles/EmailMessageComposeStyle';
import { actionsTableCellStyle, subjectTableCellStyle } from '../../../../styles/MessageListStyle';

const ZINDEX = 1998;

const POSITION = 24;

const WhatsappMailCompose = ({ whatsappMessageSuccess, setWhatsappNotification }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const smUp = useResponsive('up', 'sm');
    const fullScreen = useBoolean(false);
    const { followLead } = useSelector((state) => state.lead);
    const { whatsappAccountData } = useSelector((state) => state.whatsappAccount);
    const { wpTemplateListGlobal, loadingForWpTemplate } = useSelector((state) => state.whatsappMessages);
    const EditorMessage = useRef();
    const [editForm, setEditForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const [activeComposeMail, setActiveComposeMail] = useState(false);
    const [focusUnableToContact, setFocusUnableToContact] = useState(false);
    const [subjectUnableContact, setSubjectUnableContact] = useState('');
    const [searchUnableContactSubjectQuery, setSearchUnableContactSubjectQuery] = useState('');
    const debouncedUnableContactSubjectValue = useDebounce(searchUnableContactSubjectQuery, 1000);
    const [templateName, setTemplateName] = useState('');

    const [compose, setCompose] = useState({
        body: '',
    });

    const handleChangeEvent = (value, type) => {
        if (typeof value === 'string' && compose.body !== value.replace(/<\/?.+?>/ig, '')) {
            setEditForm(false);
        }
        setCompose({
            ...compose,
            [type]: value,
        });
    };

    const sendWhatsappMessageEvent = (message) => {
        setLoading(true);
        dispatch(getWhatsappAccountPhones({
            practiceId,
            id: whatsappAccountData?.results[0]?.id,
            handleSuccess: (data) => handleSendRequest(data, message)
        }));
    };

    const handleSendRequest = (data, message) => {
        if (data?.success) {
            const messageData = {
                template_name: message,
                message_type: 'template',
                to: followLead?.lead_contact?.phone || ''
            };
            dispatch(whatsappMessageSend({
                practiceId,
                id: whatsappAccountData?.results[0]?.id,
                phoneId: data?.phoneId[0]?.id,
                messageData,
                handleSuccess: handleSuccessMessage,
                leadInfo: followLead
            }));
            return;
        }
        setLoading(false);
    };

    const handleSuccessMessage = (data) => {
        setLoading(false);
        if (data?.success) {
            whatsappMessageSuccess();
        }
    };

    const handleSearch = (value) => {
        if (value !== '') {
            dispatch(getSearchWpTemplateList({ wpAccountId: whatsappAccountData?.results[0]?.id, search: value }));
        }
    };

    useEffect(() => {
        if (searchUnableContactSubjectQuery.length > 0) {
            handleSearch(encodeURIComponent(searchUnableContactSubjectQuery));
        }
    }, [debouncedUnableContactSubjectValue]);

    const handleOnFocus = () => {
        if (practiceId) {
            setFocusUnableToContact(true);
            setActiveComposeMail(false);
            dispatch(get10WpTemplateList({ wpAccountId: whatsappAccountData?.results[0]?.id }));
            setWhatsappNotification(false);
        }
    };

    const handleUnableContactSubjectSearch = (data) => {
        const search = data;
        setSubjectUnableContact(search);
        setSearchUnableContactSubjectQuery(search);
    };

    const handleUse = (row) => {
        setTemplateName(row?.name);
        setEditForm(false);
        setActiveComposeMail(true);
        setFocusUnableToContact(false);
        setCompose({ body: row?.components?.find((ele) => ele.type === 'BODY')?.text });
        dispatch(updateShowWpSubjectList(false));
    };
    const handleSendWpMessage = (row) => {
        setCompose({ body: row?.components?.find((ele) => ele.type === 'BODY')?.text });
        sendWhatsappMessageEvent(row?.name);
    };

    const handleCloseEditor = () => { setActiveComposeMail(false); setFocusUnableToContact(false); };

    const renderComposeMail = () => (
        <Paper sx={whatsappPaperStyle(ZINDEX, false, POSITION)}>
            <Stack flexGrow={1} sx={{ p: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '12px 15px 0' }}>
                    <IconButton
                        onClick={handleCloseEditor}
                    >
                        <Iconify icon="mingcute:close-circle-line" />
                    </IconButton>
                </Box>
                <Editor
                    id="compose-mail"
                    value={compose.body?.replace(/\n/g, '<br>')}
                    EditorMessage={EditorMessage}
                    fullScreen={false}
                    type='sms_editor'
                    onChange={(value) => handleChangeEvent(value, 'body')}
                    placeholder="Type a message"
                    sx={editorStyle}
                    readOnly
                />
                <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', flexWrap: 'wrap', p: 2 }}>
                    <Box sx={{ ml: 'auto' }}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            endIcon={<Iconify icon="iconamoon:send-fill" />}
                            sx={{ ml: 1 }}
                            disabled={editForm}
                            loading={loading}
                            onClick={() => sendWhatsappMessageEvent(templateName || '')}
                        >
                            Send message
                        </LoadingButton>
                    </Box>
                </Stack>
            </Stack>
        </Paper>
    );
    return (
        <>
            {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}
            <Box sx={{ ...emailBoxStyle, border: 'none', display: 'flex', p: '16px 0 16px' }}>
                <InputBase
                    placeholder="Search templates"
                    startAdornment={<Iconify icon="eva:search-outline" color="grey.500" width={24} height={24} sx={{ mr: 1 }} />}
                    sx={inputBaseStyle}
                    onChange={(e) => handleUnableContactSubjectSearch(e.target.value)}
                    onFocus={handleOnFocus}
                    value={subjectUnableContact}
                />
            </Box>
            {activeComposeMail && <>{renderComposeMail()}</>}
            {focusUnableToContact && (
                <Box
                    onClick={(e) => e.stopPropagation()}
                    sx={focusUnableToContactBoxStyle}
                >
                    {loadingForWpTemplate &&
                        <TableLoading tableData={loadingForWpTemplate && 'No Found'} pageCount={loadingForWpTemplate && 'No Found'} />
                    }
                    {isArray(wpTemplateListGlobal) && wpTemplateListGlobal.length === 0 &&
                        <NotFound />
                    }
                    {(isArray(wpTemplateListGlobal) && wpTemplateListGlobal.length > 0) &&
                        wpTemplateListGlobal?.map((ele, index) => <>
                            <TableRow key={index} hover selected={null} sx={{ '&:hover': { bgcolor: '#EBF9F2 !important' } }}>
                                <TableCell sx={subjectTableCellStyle}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Typography variant="body" sx={{ fontSize: '16px', mb: '2px', lineHeight: '24px' }}>
                                            {ele.name}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={actionsTableCellStyle}>
                                    <MenuList sx={{ display: 'flex', p: 0 }}>
                                        <MenuItem sx={{ color: 'primary.main', mb: '0 !important' }} onClick={() => handleUse(ele)}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                Preview <Iconify icon={'ant-design:eye-outlined'} sx={{ ml: 1 }} width={14} height={14} />
                                            </Typography>
                                        </MenuItem>
                                        {<MenuItem sx={{ color: 'primary.main' }} onClick={() => handleSendWpMessage(ele)}>
                                            <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                                                Send <Iconify icon={'iconamoon:send-fill'} sx={{ ml: 1 }} width={14} height={14} />
                                            </Typography>
                                        </MenuItem>
                                        }
                                    </MenuList>
                                </TableCell >
                            </TableRow>
                        </>)
                    }
                </Box >
            )}
        </>
    );
};

WhatsappMailCompose.propTypes = {
    whatsappMessageSuccess: PropTypes.func,
    setWhatsappNotification: PropTypes.func,
};

export default WhatsappMailCompose;