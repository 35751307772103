import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { saveNewCampaign, updateSelectedCampaign } from '../redux/slices/campaign';
import { dispatch, useSelector } from '../redux/store';
import { ReferralDropdown } from './Dropdowns';

const LeadReferelDropdown = () => {
    const { referral } = useSelector((state) => state.schema);
    const { newCampaign, selectedCampaign } = useSelector((state) => state.campaign);
    const [getStatusValue, setStatusValue] = useState('');

    const defaultValues = {
        referral: newCampaign.referralName || getStatusValue || []
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        setValue,
    } = methods;

    useEffect(() => {
        if (selectedCampaign) {
            const statusValue = referral?.filter((el) => selectedCampaign?.recepient?.filters?.lead_source__referral__in?.includes(el.value))?.[0]?.label;
            setStatusValue(statusValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCampaign]);

    const handleReferral = (event, data) => {
        if (data) {
            const value = { lead_source__referral__in: [data?.value] };
            setValue('referral', referral?.filter((el) => data?.value?.includes(el.value))?.[0]?.label);
            if (Object.values(selectedCampaign).length > 0) {
                const updatedCampaign = { ...selectedCampaign, recepient: { ...selectedCampaign.recepient, filters: { ...selectedCampaign?.recepient?.filters, ...value } } };
                dispatch(updateSelectedCampaign(updatedCampaign));
                return;
            }
            dispatch(saveNewCampaign({ lead_source__referral__in: value, referralName: referral?.filter((el) => data.value?.includes(el.value))?.[0]?.label }));
        }
    };

    return <ReferralDropdown
        name={'referral'}
        value={newCampaign.referralName || getStatusValue || []}
        onChange={handleReferral}
    />;
};

export default LeadReferelDropdown;
