export const treatmentTeethFunc = ({ e, setValue, setTeethAmountError }) => {
    const value = e.target.value > 0 ? e.target.value : '';
    setValue('teethAmount', value);
    setTeethAmountError(value === 0);
};

export const amountHandleChangeFunc = ({ value, setValue, setPriceAmountError }) => {
    setValue('treatmentValue', value);
    if (value > 0) {
        setPriceAmountError(false);
        document.body.classList.remove('points-event');
        return;
    }
    setPriceAmountError(true);
};