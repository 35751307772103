import { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import { Label } from '../../../../components';
import { LeadListPopup } from '../../../../components/dialogPopUp';
// store
import { setAudienceData } from '../../../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../../../redux/store';
import { clearPopupLead, getLead } from '../../../../redux/slices/analytics';
// hooks/constant/utils
import { useBoolean } from '../../../../hooks';
import { LeadLabelsForReports } from '../../../../constants/TableDataConst';
import { TableOrder, TableOrderBy } from '../../../../utils/allTableTab';
// styles
import { analyticsTDStyle, analyticsAvatarStyle } from '../../../../styles/GeneralAnalytics';
import { analyticsTableCell, analyticsTableFirstCellStyle, analyticsTableRowStyle, centeredText, cursorPointer } from '../../../../styles/analytics';

const AnalyticsTableRow = ({ data }) => {
    const theme = useTheme();
    const { targetTreatment, leads } = useSelector((state) => state.analytics);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const treatmentList = useSelector((state) => state.practiceTreatment.treatmentsList?.results);
    const [rowData, setRowData] = useState({
        status: '',
        treatmentId: '',
        startDate: null,
        endDate: null,
    });

    const handleAvatar = (value) => value?.trim().charAt(0) + value?.trim().charAt(1);

    const tableOrder = TableOrder();
    const tableOrderBy = TableOrderBy();

    const open = useBoolean();

    const handleClick = (data, status) => {
        const { year, month: monthNumber } = data;
        open.onTrue();
        const startDate = moment({ year, month: monthNumber - 1, day: 1 });
        const endDate = moment({ year, month: monthNumber - 1 }).endOf('month');
        const startDateString = startDate.format('YYYY-MM-DD');
        const endDateString = endDate.format('YYYY-MM-DD');
        const treatmentId = (treatmentList.find(e => e?.treatment_item?.name === targetTreatment)?.id);
        setRowData({
            status,
            treatmentId,
            startDate: startDateString,
            endDate: endDateString
        });
        dispatch(
            getLead({
                id: practiceId,
                limit: 10,
                offset: 0,
                order: tableOrder,
                orderId: tableOrderBy,
                treatment: treatmentId,
                startDate: startDateString,
                endDate: endDateString,
                status,
            })
        );
        const updatedCampaign = {
            recepient: {
                filters: {
                    created_at__range: [startDateString, endDateString],
                    lead_treatments__treatment__treatment_item__name__in: [targetTreatment],
                },
            },
        };
        if (status && status !== 'All') {
            updatedCampaign.recepient.filters = { ...updatedCampaign.recepient.filters, lead_treatments__status__in: [status] };
        }
        dispatch(setAudienceData(updatedCampaign));
    };

    const handleClose = () => {
        open.onFalse();
        dispatch(clearPopupLead());
    };

    const handlePagination = (data) => {
        dispatch(getLead({
            id: practiceId,
            limit: data.limit,
            offset: data.offset,
            order: data.order,
            orderId: data.orderId,
            treatment: rowData.treatmentId,
            startDate: rowData?.startDate,
            endDate: rowData?.endDate,
            status: rowData?.status,
        }));
    };

    return (
        <>
            <TableRow hover sx={analyticsTableRowStyle}>
                <TableCell sx={analyticsTableFirstCellStyle}>
                    {moment(data?.month || 0, 'MM').format('MMM')} {data?.year || 0}
                </TableCell>
                <TableCell sx={analyticsTDStyle}>
                    <Avatar sx={analyticsAvatarStyle}> {handleAvatar(targetTreatment) || ''}</Avatar>
                    {targetTreatment || ''}
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleClick(data, 'NEW')}>
                    {data?.data?.NEW?.actual || 0}
                </TableCell>
                <TableCell sx={centeredText}>
                    {data?.data?.CONSULTATION_BOOKED?.target || 0}
                </TableCell>
                <TableCell sx={centeredText} onClick={() => handleClick(data, 'CONSULTATION_BOOKED')}>
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={(data?.data?.CONSULTATION_BOOKED?.target <= data?.data?.CONSULTATION_BOOKED?.actual ? 'success' : 'error')}
                        sx={cursorPointer}
                    >
                        {data?.data?.CONSULTATION_BOOKED?.actual || 0}
                    </Label>
                </TableCell>
                <TableCell sx={centeredText}>
                    {data?.data?.TREATMENT_STARTED?.target || 0}
                </TableCell>
                <TableCell sx={centeredText} onClick={() => handleClick(data, 'TREATMENT_STARTED')}>
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={(data?.data?.TREATMENT_STARTED?.target <= data?.data?.TREATMENT_STARTED?.actual ? 'success' : 'error')}
                        sx={cursorPointer}
                    >
                        {data?.data?.TREATMENT_STARTED?.actual || 0}
                    </Label>
                </TableCell>
            </TableRow>
            {open && <LeadListPopup
                open={open?.value}
                onClose={handleClose}
                type={'reportTableData'}
                updateLeadPage={(data) => handlePagination(data)}
                practiceId={practiceId}
                count={leads?.count}
                LeadLabelsForReports={LeadLabelsForReports}
                data={leads?.results}
                rowStatus={rowData?.status}
            />}
        </>
    );
};

AnalyticsTableRow.propTypes = {
    data: PropTypes.any,
};

export default AnalyticsTableRow;
