/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useDebounce } from 'use-lodash-debounce';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Chip,
    TextField,
    Autocomplete,
    Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { updateCounter } from '../../redux/slices/lead';
import { getAllTagItem, startLoading } from '../../redux/slices/tagsCreate';
// validation
import { AutocompleteTagsSchema } from '../../validations/validations';
// util
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableTags,
    TableOrder,
    TableOrderBy,
    UpdateTableData,
    TableReferral,
    TableCategory,
    TableBookedWith
} from '../../utils/allTableTab';
// style
import { grey } from '../../theme/palette';
import {
    tagsBoxStyle,
    searchTagsStyle,
    InputTags
} from '../../styles/AutocompleteTags';

const SearchAutocompleteTags = ({ tableType, reloadCounter, updateLeadPage, handlePage, handleStartedTablePayload }) => {
    const listElem = useRef();
    const mounted = useRef();
    const [position, setPosition] = useState(0);

    const {
        dashContact: { tag: dashTag, filterTags: dashFilterTags },
        newContact: { tag: newTag, filterTags: newFilterTags },
        attendContact: { tag: attendedTag, filterTags: attendedFilterTags },
        clincheckContact: { tag: clincheckAttendedTag, filterTags: clincheckAttendedFilterTags },
        bookedContact: { tag: bookedTag, filterTags: bookedFilterTags },
        treatmentStarted: { tag: treatmentTag, filterTags: treatmentFilterTags },
        dashNewContact: { tag: dashNewTag, filterTags: dashNewFilterTags },
        archivedContact: { tag: archivedContactTags, filterTags: archiveFilterTags },
    } = useSelector((state) => state.lead);

    const contactCount = TabCount(tableType);
    const pageNumber = TablePageNumber(tableType);
    const perPage = TablePerPage(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const status = TableStatus(tableType);
    const searchTags = TableTags(tableType);
    const searchTagsValue = TableFilterTags(tableType);
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const referral = TableReferral(tableType);
    const depositPendingStatus = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const [inputValue, setInputValue] = useState('');
    const inputValueDebounce = useDebounce(inputValue, 800);

    const needToCall = useRef(false);

    const { getAllTag: { results, count }, isLoading } = useSelector((state) => state?.tagsCreate || {});
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;
    const [tagsOptions, setTagsOptions] = useState([]);
    const [shouldCall, setShouldCall] = useState(false);

    useEffect(() => {
        if (results) {
            setTagsOptions(results);
        }
    }, [results]);

    useEffect(() => {
        if (searchTagsValue && shouldCall) {
            setShouldCall(false);
            dispatch(updateCounter([]));
            const reloadPayLoad = {
                limit: perPage,
                offset: pageNumber,
                filterStatus: status,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTagsValue.toString(),
                referral,
                depositPendingStatus,
                category,
                bookedWith
            };
            handleStartedTablePayload(reloadPayLoad);
            updateLeadPage(reloadPayLoad);
            if (reloadCounter) {
                reloadCounter(reloadPayLoad);
            }
        }
    }, [searchTagsValue]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const defaultValues = {
        tags: []
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(AutocompleteTagsSchema),
        defaultValues,
    });

    const {
        setValue,
        control,
    } = methods;

    const handleDelete = (chipToDelete) => {
        const tags = searchTags?.filter(value => value.tag.id !== chipToDelete.tag.id);
        const value = searchTagsValue?.filter(value => value !== chipToDelete.tag?.tag?.name);
        handlePage();
        setShouldCall(true);
        UpdateTableData(tableType, { tag: tags, filterTags: value });
    };

    const handleSearch = (data) => {
        const tags = searchTags?.filter(value => value?.tag?.id === data?.tag?.id);
        if (data?.tag?.id > 0 && tags?.length <= 0) {
            setShouldCall(true);
            handlePage();
            switch (tableType) {
                case 'NewLeadTable':
                    UpdateTableData(tableType, { tag: [...newTag, data], filterTags: [...newFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    return;
                case 'ConsultantAttend':
                    UpdateTableData(tableType, { tag: [...attendedTag, data], filterTags: [...attendedFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    return;
                case 'ConsultantClinCheckAttend':
                    UpdateTableData(tableType, { tag: [...clincheckAttendedTag, data], filterTags: [...clincheckAttendedFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    return;
                case 'ConsultantBooked':
                    UpdateTableData(tableType, { tag: [...bookedTag, data], filterTags: [...bookedFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    return;
                case 'treatmentStarted':
                    UpdateTableData(tableType, { tag: [...treatmentTag, data], filterTags: [...treatmentFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    return;
                case 'DashNewLeadTable':
                    UpdateTableData(tableType, { tag: [...dashNewTag, data], filterTags: [...dashNewFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    return;
                case 'ArchiveTable':
                    UpdateTableData(tableType, { tag: [...archivedContactTags, data], filterTags: [...archiveFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    return;
                default:
                    UpdateTableData(tableType, { tag: [...dashTag, data], filterTags: [...dashFilterTags, data?.tag?.tag?.name], perPage: 10, pageNumber: 0 });
                    break;
            }
        }
    };

    const handleTags = (event, newValue) => {
        if (event.key !== 'Backspace') {
            if (newValue && newValue?.length > 0 && newValue[newValue?.length - 1].tag?.inputValue) {
                handleSearch({});
                setValue('tagName', '');
            }
            else {
                setValue('tags', newValue[newValue?.length - 1].tag?.name);
                const data = {
                    tag: newValue[newValue.length - 1]
                };
                handleSearch(data);
            }
        }
    };

    const optionLabel = (option) => {
        if (typeof option === 'string') {
            return option?.tag?.name;
        }
        if (option?.inputValue) {
            return option?.inputValue;
        }
        return option?.tag?.name;
    };

    const leadMoreData = () => {
        if (count > results?.length) {
            dispatch(getAllTagItem(practiceId, 30, results?.length));
        }
    };

    const leadMorePosition = (event) => {
        const listboxNode = event?.target?.scrollHeight <= event?.target?.scrollTop + event?.target?.clientHeight;
        if (listboxNode) {
            setPosition(event?.target?.scrollTop + event?.target?.clientHeight);
            leadMoreData();
        }
    };

    useEffect(() => {
        if (practiceId) {
            if (inputValueDebounce) {
                const data = {
                    tag__name__icontains: inputValueDebounce,
                };
                dispatch(getAllTagItem(practiceId, 30, 0, data));
            } else if (needToCall.current) {
                needToCall.current = false;
                dispatch(getAllTagItem(practiceId, 30, 0));
            }
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        dispatch(startLoading());
        setInputValue(value);
        if (isEmpty(value)) {
            needToCall.current = true;
        }
    };

    return (
        <Box sx={tagsBoxStyle}>
            <Controller
                name="tags"
                control={control}
                render={() => (
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            value={searchTags || []}
                            onChange={handleTags}
                            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
                            id="free-solo-dialog-demo"
                            options={tagsOptions}
                            noOptionsText={isLoading ? 'Loading...' : 'No options'}
                            getOptionLabel={optionLabel}
                            selectOnFocus
                            handleHomeEndKeys
                            renderOption={(props, option) => (
                                <li {...props} key={option?.tag?.name} style={{ padding: '5px 9px' }}>
                                    <Box>
                                        <Typography sx={{ fontSize: '14px' }}>{option?.tag?.name}</Typography>
                                    </Box>
                                </li>
                            )}
                            ListboxProps={{
                                ref: listElem,
                                onScroll: leadMorePosition
                            }}
                            renderTags={(value) =>
                                value.map((option) => (
                                    <Chip
                                        sx={InputTags(option?.tag?.tag?.colour)}
                                        key={option?.tag?.id}
                                        label={option?.tag?.tag?.name}
                                        size="small"
                                        color="info"
                                        variant="soft"
                                        onDelete={() => handleDelete(option)}
                                    />
                                ))
                            }
                            sx={searchTagsStyle('')}
                            renderInput={(params) => <TextField {...params} label="Tags" sx={{ 'label': { background: grey[200] } }} />}
                        />
                    </Box>
                )}
            />
        </Box>
    );
};

export default SearchAutocompleteTags;

SearchAutocompleteTags.propTypes = {
    reloadCounter: PropTypes.func,
    tableType: PropTypes.string,
    updateLeadPage: PropTypes.func,
    handlePage: PropTypes.func,
    handleStartedTablePayload: PropTypes.func
};