import React from 'react';
import PropTypes from 'prop-types';
import { TableContainer, Table, TableBody, Box } from '@mui/material';
import { TableLoading } from '../../../components/loading';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '../../../components/table';
import { MessageTableRow, LeadTemplateMessageTableRow } from '.';
import { useSelector } from '../../../redux/store';
import { stableSort, getComparator, emptyRows } from '../../../hooks/useTable';
import { TEMPLATE_HEAD, TEMPLATE_CAMPAIGN_HEAD, isAdminTable } from '../../../constants/MessageListConst';
import { tableContainerStyle } from '../../../styles/MessageListStyle';

const TemplateMessageList = ({
    fullScreen,
    type,
    isFromSearch,
    list,
    filterData,
    page,
    rowsPerPage,
    order,
    orderBy,
    selected,
    onSort,
    handleSelectAllRows,
    handleSendUnableToContact,
    isFromUnableToContact,
    commonProps,
    campaign,
}) => {
    const TABLE_HEAD = isAdminTable;
    const { loadingForTemplate } = useSelector((state) => state.practiceMessage);
    return (
        <Box
            sx={{
                maxHeight: fullScreen ? 'calc(100vh - 230px)' : (type === 'Template' && '100%') || '420px',
                overflow: 'auto',
            }}
        >
            {type === 'LeadTemplate' ? (
                <Box>
                    <Box>
                        {isFromSearch && (
                            <>
                                {list.map((row, index) => (
                                    <LeadTemplateMessageTableRow
                                        {...commonProps({ row })}
                                        key={index}
                                        practiceMessageTable
                                        isFromSearch={isFromSearch}
                                        list={list}
                                        isFromUnableToContact={isFromUnableToContact}
                                        handleSendUnableToContact={handleSendUnableToContact}
                                    />
                                ))}
                            </>
                        )}
                        {!isFromSearch &&
                            filterData() &&
                            (type !== 'Template' ? stableSort(filterData(), getComparator(order, orderBy)) : filterData())
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <LeadTemplateMessageTableRow
                                        {...commonProps({ row })}
                                        key={index}
                                        practiceMessageTable
                                        isFromSearch={isFromSearch}
                                        list={list}
                                    />
                                ))}
                        {!isFromSearch && (
                            <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, filterData() && filterData().length)} />
                        )}

                        {!isFromSearch && filterData() && filterData().length < 1 && (
                            <TableNoData isNotFound={filterData().length < 1} />
                        )}
                    </Box>
                </Box>
            ) : (
                <>
                    {!isFromSearch && (
                        <TableContainer sx={tableContainerStyle(type, fullScreen)}>
                            <Table size={'medium'}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={
                                        type !== 'Practice_Message' ? (campaign && TEMPLATE_CAMPAIGN_HEAD) || TEMPLATE_HEAD : TABLE_HEAD
                                    }
                                    rowCount={filterData() && filterData().length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    practiceMessageTable
                                    onSelectAllRows={handleSelectAllRows}
                                />
                                <TableBody>
                                    <TableLoading
                                        tableData={loadingForTemplate && 'No Found'}
                                        pageCount={loadingForTemplate && 'No Found'}
                                        type={type === 'Template' ? '' : 'table'}
                                    />
                                    {filterData() &&
                                        (type !== 'Template' ? stableSort(filterData(), getComparator(order, orderBy)) : filterData())
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (
                                                <MessageTableRow {...commonProps({ row })} key={index} practiceMessageTable />
                                            ))}
                                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, filterData() && filterData().length)} />
                                    {filterData() && filterData().length < 1 && <TableNoData isNotFound={filterData().length < 1} />}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </>
            )}
        </Box>
    );
};

TemplateMessageList.propTypes = {
    fullScreen: PropTypes.bool,
    type: PropTypes.string,
    isFromSearch: PropTypes.bool,
    list: PropTypes.array,
    filterData: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    selected: PropTypes.array,
    onSort: PropTypes.func,
    handleSelectAllRows: PropTypes.func,
    handleSendUnableToContact: PropTypes.func,
    isFromUnableToContact: PropTypes.bool,
    commonProps: PropTypes.func,
    campaign: PropTypes.bool,
};

export default TemplateMessageList;
