import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date) {
    return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
    });
}

export const noteUpdated = (data) =>
    ([...data].sort((a,b) =>
        moment(b?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix() -
    moment(a?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix())[0]?.updated_at
    || 'No Contact'
    );

export const noteUpdatedAvatar = (data) =>
    ([...data].sort((a, b) =>
        moment(b?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix() -
    moment(a?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix())[0]?.created_by?.avatar
    || ''
    );

export const noteUpdatedBy = (data) => {
    const latestNote = [...data].sort((a, b) =>
        moment(b?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix() -
        moment(a?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix())[0];
    if (latestNote) {
        return `${latestNote?.created_by?.first_name} ${latestNote?.created_by?.last_name}`;
    }
    return '';
};

export const exportNoteUpdated = (data) => {
    const sortedData = [...data].sort((a, b) =>
        moment(b?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix() -
      moment(a?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix()
    );
  
    const latestUpdatedAt = sortedData[0]?.updated_at;
    
    if (latestUpdatedAt) {
        return moment(latestUpdatedAt, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').format('DD/MM/YYYY');
    }
    return 'No Contact';
};

export const noteUpdatedHuman = (data) =>
    ([...data].sort((a,b) =>
        moment(b?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix() -
    moment(a?.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').unix())[0]?.updated_at_human
    || 'No Contact'
    );

export const getDelayLabel = (value) => {
    if (value === 0) return 'Immediately';
    if (value === 86400 || value === -86400) return '1 day';
    if (value === 172800 || value === -172800) return '2 day';
    if (value === 604800 || value === -604800) return '1 week';
    if (value === 1209600 || value === -1209600) return '2 week';
    return 'custom';
};
