import PropTypes from 'prop-types';
// @mui
import { Stack, Dialog, Typography, Button } from '@mui/material';

export default function ConfirmDelete({ open, onClose, onDeleteRow, handleCloseMenu, data, cancelButtonText, deleteButtonText }) {

    const handleDelete = (value) => { 
        if(value === 'cancel'){
            onClose();
        }
        if(value === 'delete'){
            onDeleteRow();
        }
        handleCloseMenu();
    };

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
            <Stack spacing={2} sx={{ p: 2.5, textAlign: 'center' }}>
                <Typography variant="h6">
                    {data || 'Are you sure, you want to delete this treatment?'}
                </Typography>
                <Stack width="160px" marginTop={0} justifyContent="space-between" flexDirection="row" alignItems="flex-start" alignSelf="center">
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => handleDelete('cancel')}
                    >
                        {cancelButtonText || 'cancel'}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleDelete('delete')}
                        color='error'
                    >
                        {deleteButtonText || 'Delete'}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}

ConfirmDelete.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDeleteRow: PropTypes.func,
    handleCloseMenu: PropTypes.func,
    data: PropTypes.string,
    cancelButtonText: PropTypes.string,
    deleteButtonText: PropTypes.string,
};