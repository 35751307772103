import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
// @mui
import {
    Box, Typography
} from '@mui/material';
// hook
// components
import { Scrollbar } from '../../../components';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { dispatch, useSelector } from '../../../redux/store';
import { addNotes, consultationAttended } from '../../../redux/slices/lead';
// style
import { textFieldStyle } from '../../../styles/SwitchUserPopupStyle';

// ----------------------------------------------------------------------------------------------

const AttendedLeadNotePopup = forwardRef(({ handleNext, user, handleCloseAndReload }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { enqueueSnackbar } = useSnackbar();

    const defaultValues = {
        note: ''
    };

    const methods = useForm({
        defaultValues
    });

    const {
        handleSubmit,
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onSubmit = async (data) => {
        dispatch(consultationAttended(practiceId, user.lead_id, user.treatment_id, handleSuccess));
        if (data.note !== '') {
            const Details = {
                lead: user.lead_id,
                additional_notes: data.note,
            };
            dispatch(addNotes(Details, practiceId, handleClose, 'attended'));
        }
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        handleNext(true);
        handleCloseAndReload('finish');
    };

    const handleClose = (status) => {
        if (status === 'finish') {
            handleNext(true);
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ margin: '0 -8px' }}>
                <Scrollbar sx={{ maxHeight: 64 * 6 }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: '20px', fontWeight: '700', m: '0 0 24px' }}>Move {user?.salutation || ''} {user?.first_name || ''} {user?.last_name || ''} back to attended status?</Typography>
                        <RHFTextField
                            sx={textFieldStyle}
                            name='note'
                            rows={8}
                            multiline
                            label={'Add note'}
                            placeholder={'Leave a note..'}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </Scrollbar>
            </Box>
        </FormProvider>
    );
});

AttendedLeadNotePopup.propTypes = {
    handleNext: PropTypes.func,
    user: PropTypes.any,
    handleCloseAndReload: PropTypes.any,
};

export default AttendedLeadNotePopup;
