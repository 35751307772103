export const addTreatmentGridStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 4,
    rowGap: 2,
    width: '100%',
    ml: 'auto'
};
export const treatmentInputStyle = {
    width: '50%',
    maxWidth: { md: 'calc(70% - 20px)' },
    mb: 2
};
export const toggleBtnStyle = { width: '56px', height: '40px' };