import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
// mui
import { Box, Typography, CircularProgress } from '@mui/material';
// components

import { Iconify } from '../index';
import WidgetButtons from './WidgetButtons';
// redux
import { useSelector } from '../../redux/store';
// hooks
import { useToggle } from '../../hooks';
// utils
import { UpdateTableData } from '../../utils/allTableTab';
import { getSelectedWidgetStyle } from '../../utils/reportWidgetUtils';
// style
import { widgetCardStyle, widgetCardWrapperStyle, widgetCounterTextStyle, widgetCounterWrapperStyle, widgetTextStyle, widgetIconWrapper, widgetLoadingStyle } from '../../styles/widgetCounterStyle';


const DashBoardReportsWidget = ({  
    dateRangeProps, onChangeTab, activeWidgetProps: { currentWidget, setCurrentWidget } }) => {
    const { widgetCounter } = useSelector((state) => state.widgetCounter);

    const navigate = useNavigate();

    const { toggle, onOpen, onClose } = useToggle(true);

    const tabCounter = (data) => widgetCounter?.filter((value) => (value.key === data ? value.count : null));

    const handleOpen = () => onOpen();

    const handleClose = () => onClose();

    const [showLoading, setShowLoading] = useState({}); 

    const handleWidgetClick = (ele) => {
        if (ele.key === '1' && onChangeTab) {
            setCurrentWidget(ele.key);
            onChangeTab('', 'newLeads');
        } else if (ele.key === '1') {
            UpdateTableData('DashNewLeadTable', { date: [{ startDate: dateRangeProps.startDate, endDate: dateRangeProps.endDate }] });
            UpdateTableData('DashNewLeadTableForWidget', { date: [{ startDate: dateRangeProps.startDate, endDate: dateRangeProps.endDate }] });
            navigate('/dashboard/app', { state: { isNewLeadTab: true } });
            setCurrentWidget(ele.key);
        }
        if (ele.key === '2' && onChangeTab) {
            onChangeTab('', 'To-do', true, 'NEW');
            setCurrentWidget(ele.key);
        }
        if (ele.key === 'BOOKED') {
            navigate('/dashboard/tables/consultant-booked-table');
            UpdateTableData('ConsultantBooked', { date: [{ startDate: dateRangeProps.startDate, endDate: dateRangeProps.endDate }] });
            setCurrentWidget(ele.key);
        }
        if (ele.key === '4') {
            navigate('/dashboard/tables/consultant-attended-table');
            UpdateTableData('ConsultantAttend', { date: [{ startDate: dateRangeProps.startDate, endDate: dateRangeProps.endDate }] });
            setCurrentWidget(ele.key);
        }
        if (ele.key === '5') {
            navigate('/dashboard/tables/consultant-attended-table', { state: { isClincheck: true } });
            setCurrentWidget(ele.key);
        }
        if (ele.key === 'FAILED_TO_ATTEND') {
            navigate('/dashboard/tables/failed-to-attend-table');
            setCurrentWidget(ele.key);
        }
    };

    const widgetArray = [
        { key: '1', icon: 'streamline:user-add-plus-solid', text: 'New patient leads to contact' },
        { key: '2', icon: 'icomoon-free:phone', text: 'New patient follow ups', bgColor: '#FFE7D9', textColor: '#B72136' },
        { key: 'BOOKED', icon: 'ic:baseline-schedule', text: 'Consultations booked' },
        { key: '4', icon: 'icomoon-free:phone', text: 'Consultation follow ups', bgColor: '#FFE7D9', textColor: '#B72136' },
        { key: '5', icon: '', text: 'Patients to send clinchecks' },
        { key: 'FAILED_TO_ATTEND', icon: 'ri:calendar-close-fill', text: 'Consultation FTA\'s', bgColor: '#FFE7D9' },
    ];

    const checkValidCount = (key) => {
            const res = tabCounter(key)?.[0]?.count;   
            if (res === undefined || res === 0 || res === null) {
                setShowLoading((prev) => ({ ...prev, [key]: true }));
                setTimeout(() => {
                    setShowLoading((prev) => ({ ...prev, [key]: false }));
                }, 500);
            } else {
                setShowLoading((prev) => ({ ...prev, [key]: false }));
            }
    };

    useEffect(() => {
        widgetArray.forEach((widget) => {
            checkValidCount(widget.key);
        });
    }, [widgetCounter]);



    return (
        <>
            <WidgetButtons toggle={toggle} dateRangeProps={dateRangeProps} handleOpen={handleOpen} handleClose={handleClose} type='dashboard_widget' />
            {toggle && (
                <Box sx={widgetCardWrapperStyle}>
                    {
                        widgetArray.map((ele) => {
                            const isLoading = showLoading[ele.key];
                        return (<Box
                            key={ele.key}
                            sx={{ ...widgetCardStyle, ...getSelectedWidgetStyle(ele.key, currentWidget) }}
                            onClick={() => handleWidgetClick(ele)}
                        >
                            {    
                                isLoading ? <Box sx={widgetLoadingStyle}>
                                <CircularProgress color="inherit" />
                            </Box> :
                                <>
                                    <Box sx={widgetCounterWrapperStyle}>
                                        <Typography sx={widgetCounterTextStyle}>
                                            {tabCounter(ele.key)?.[0]?.count || 0}
                                        </Typography>
                                        
                                        {ele.icon &&
                                            <Box sx={{ ...widgetIconWrapper, bgcolor: ele.bgColor || '#D0F2FFCC' }}>
                                                <Iconify icon={ele.icon || 'ci:user-add'} width={20} height={20} sx={{ color: ele.textColor }} />
                                            </Box>}
                                    </Box>
                                    <Typography sx={widgetTextStyle}>{ele.text}</Typography>
                                </>
                            }
                        </Box>)})}
                </Box>
            )}
        </>
    );
};

export default DashBoardReportsWidget;

DashBoardReportsWidget.propTypes = {
    dateRangeProps: PropTypes.any,
    onChangeTab: PropTypes.func,
    activeWidgetProps: PropTypes.any
};
