/* eslint-disable no-nested-ternary */
import { alpha } from '@mui/material/styles';
import { isEmpty } from 'lodash';

export const contactLeadDialogStyle = (type) => ({
    zIndex: type === 'onboarad' ? 1117 : 1200,
    margin: { xs: '0 -16px', sm: 0 },
    '.MuiDialog-paperFullScreen': {
        bgcolor: '#F4F6F8'
    }
});
export const commonDialogHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { sm: 'row' },
    position: 'sticky',
    top: 0,
    textAlign: 'center',
    p: '24px !important',
    bgcolor: 'primary.main'
};
export const contactLeadTitleStyle = {
    fontSize: '26px !important',
    color: '#fff',
    lineHeight: '30.55px'
};
export const cancelBoxStyle = () => ({
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 0
});
export const contactLeadCancelButtonStyle = {
    borderColor: '#fff',
    color: '#fff',
    height: '36px',
    fontSize: '16px',
    p: '6px 16px'
};
export const leadFooterButtonsStyle = (activeBackButton, type) => ({
    mt: 3,
    display: 'flex',
    // eslint-disable-next-line no-nested-ternary
    justifyContent: !activeBackButton ? type === 'followup' ? 'center' : 'flex-end' : 'space-between',
    pr: { xs: 4 },
    pl: { xs: 4 },
    flexWrap: 'wrap'
});
export const onBoardFooterButtonsStyle = activeBackButton => ({
    mt: 3,
    display: 'flex',
    justifyContent: !activeBackButton ? 'flex-end' : 'space-between',
    pr: { sm: 7, xs: 4 },
    pl: { sm: 5, xs: 4 },
    flexWrap: 'wrap'
});
export const tabButtonBoxStyle = {
    width: '100%',
    textAlign: 'center',
    marginTop: '-30px'
};
export const tabButtonGroupStyle = {
    border: 'none',
    flexDirection: 'row',
    maxWidth: '100%',
    borderRadius: '0',
    padding: '0',
    marginBottom: '18px'
};
export const contactLeadButtonStyle = outcome => ({
    borderRadius: '0 !important',
    ':hover': { backgroundColor: 'transparent', border: 'none' },
    color: outcome === 'Contact Lead' ? '#212B36' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: '12px 16px',
    marginRight: '24px',
    marginLeft: '0 !important',
    justifyContent: 'center',
    textAlign: 'center',
    width: '322px',
    borderBottom: outcome === 'Contact Lead' ? 'solid 1px #00AB55 !important' : 'solid 1px  transparent'
});
export const timelineButtonStyle = outcome => ({
    borderRadius: '0 !important',
    ':hover': { backgroundColor: 'transparent', border: 'none' },
    color: outcome === 'Timeline' ? '#212B36' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: '12px 16px',
    marginRight: { md: '0', xs: '0' },
    marginLeft: '0 !important',
    justifyContent: 'center',
    textAlign: 'center',
    width: '322px',
    borderBottom: outcome === 'Timeline' ? 'solid 1px #00AB55 !important' : 'solid 1px  transparent',
    display: 'none !important',
    '@media(max-width: 1240px)': {
        display: 'inline-flex !important'
    }
});
export const activeStepTitleStyle = {
    mt: 1,
    mb: 4,
    width: '100%',
    textAlign: 'center'
};
export const activeStepTextStyle = {
    m: 'auto',
    mb: 5,
    textAlign: 'center',
    '@media (min-width:990px)': { width: '60%' }
};
export const footerButtonBoxStyle = {
    width: '100%',
};
export const backButtonStyle = {
    minWidth: '78px',
    minHeight: '36px',
    p: '6px 8px',
    borderRadius: 1,
    color: '#637381'
};
export const nextButtonStyle = type => (
    type === 'createLead' || type === 'followup' ? {
        px: { xs: 3.5, sm: 8 },
        ml: '16px',
        minHeight: '40px',
        minWidth: type === 'contactLead' ? '180px' : '200px',
        padding: '5px 30px !important'
    } : {
        px: { xs: 3.5, sm: 8 },
        py: 0.75,
        ml: '16px',
        minHeight: '40px',
        minWidth: type === 'contactLead' ? '180px' : '200px',
        padding: '5px 30px !important'
    }
);
export const markAsCompleteBtnStyle = type => ({
    px: { xs: 3.5, sm: 8 },
    py: 0.75,
    ml: '16px',
    minHeight: '40px',
    minWidth: type === 'contactLead' ? '180px' : '200px',
    padding: '5px 30px !important',
    '.MuiLoadingButton-loadingIndicator': {
        position: 'relative',
        marginRight: '10px',
        left: 0
    }
}
);
export const popupMainStackStyle = value => ({
    p: '16px 8px 16px 16px',
    position: 'relative',
    width: value === '' ? 'calc(100% - 85px)' : 'calc(100% - 578px)',
    height: 'auto',
    minHeight: 'auto',
    flexDirection: 'row',
    gap: 2,
    '@media (max-width: 1280px)': {
        flexDirection: 'column',
        minHeight: 'auto',
        height: 'auto',
        width: '100%',
    }
});
export const drawerStyle = (drawerOpen) => ({
    position: 'fixed',
    width: 370,
    maxWidth: '100%',
    display: drawerOpen ? 'flex' : 'none',
    mt: '0 !important',
    height: '100%',
    right: '16px',
    top: '68px',
    '@media(max-width: 1440px)': {
        width: 300,
    },
    '@media(max-width: 1300px)': {
        width: 270,
    },
    '@media(max-width: 1240px)': {
        display: 'none !important'
    },
    '.MuiDrawer-paper': {
        width: 370,
        position: 'absolute',
        maxWidth: '100%',
        boxShadow: 'none',
        zIndex: 1,
        overflow: 'hidden',
        '@media(max-width: 1440px)': {
            width: 300,
        },
        '@media(max-width: 1300px)': {
            width: 270,
        },
    }
});
export const drawerHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    p: '20px 20px 20px 20px',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)'
};
export const gridContainerStyle = (type, ContactedCount, activeStep) => ({
    maxWidth: type === 'followup' && ContactedCount !== 0 && activeStep === 0 && 'calc(100% - 370px)',
    '@media(max-width: 1240px)': {
        maxWidth: '100%',
    },
});
export const leftBoxInnerStyle = (expandLayout) => ({
    background: '#FFFFFF',
    borderRadius: 2,
    width: expandLayout ? '478px' : '100%',
    p: '24px 40px 40px 40px',
    minHeight: 'calc(100vh - 120px)',
    '@media (max-width: 1280px)': {
        width: '100% !important',
        height: 'auto',
    }
});
export const rightBoxInnerStyle = (expandLayout, value) => ({
    minWidth: isEmpty(value) ? '56px' : '562px',
    display: 'flex',
    gap: '12px',
    height: 'calc(100vh - 120px)',
    overflowY: 'auto',
    width: isEmpty(value) ? '70px' : expandLayout ? '100%' : '478px',
    position: 'fixed',
    top: '100px',
    right: '8px',
    borderRadius: 2,
    '@media (max-width: 1280px)': {
        width: '100% !important',
        display: 'none'
    }
});
export const tabContentBoxStyle = {
    background: '#FFFFFF',
    borderRadius: 2,
    width: '100%'
};

export const offerMainBoxStyle = {
    marginBottom:'20px !important',
};

export const offerBoxStyle = {
   
    padding:'15px',
};

export const offerContentStyle = {
    borderBottom: 'solid 1px',
    borderColor: '#919EAB3D',
    marginLeft:'20px',
    marginRight:'20px',
    padding:'20px',
};


export const timeLineTypoV2 = {
    color: 'grey',
    fontSize: '14px',
    lineHeight: '16.45px',
    marginBottom: '8px',
    fontWeight: 600
};


export const tabsMainBoxStyle = {
    border: '1px solid #919EAB14',
    borderRadius: 1,
    p: '3px',
    height: 'fit-content',
    '.MuiTabs-indicator': {
        display: 'none'
    },
};
export const tabButtonStyle = {
    flexDirection: 'column',
    mr: '0 !important',
    minWidth: 56,
    minHeight: 56,
    borderRadius: 1,
    textTransform: 'none !important',
    overflow: 'visible',
    '&.Mui-selected': {
        bgcolor: (theme) => `${alpha(theme.palette.primary.main, 0.08)}`,
        color: 'primary.main'
    }
};
export const listHeaderBoxStyle = {
    boxShadow: '0px -1px 0px 0px #919EAB3D inset',
    display: 'flex',
    justifyContent: 'space-between',
    p: '14px 24px',
    alignItems: 'center'
};
export const listContentBoxStyle = {
    maxHeight: 'calc(100vh - 180px)',
    overflow: 'auto',
    'ul.MuiTimeline-root': {
        margin: 0
    },
    '.MuiPaper-root': {
        maxWidth: '100%'
    }
};
export const listCounterBoxStyle = {
    padding: '0px 4px',
    background: '#B72136',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#fff',
    borderRadius: '10px',
    minWidth: '31px',
    minHeight: '20px',
    position: 'absolute',
    fontWeight: 700,
    top: '-8px',
    right: 0,
    border: '1px solid #FFFFFF',
};
export const leadListCard = (isLight, isArchived, autoReminder, isArchivePending) => ({
    background: 'transparent !important',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    margin: '24px 0px 0',
    minHeight: 'auto',
    height: 'auto',
    width: 'calc(100% - 40px)',
    overflow: 'auto',
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
        boxShadow: 'none',
        background: 'none'
    },
    pointerEvents: isArchived || isArchivePending ? 'none' : null,
    bgcolor: isArchivePending && 'rgb(235 235 235) !important',
});
export const leadTaskPopover = (data) => ({
    color: data?.datetime_human.includes('now' || 'NOW') ? 'common.lightGreen' : 'common.red',
    fontWeight: '600',
    padding: '0 8px 0 0',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    mb: '0',
});
export const tagChipStyle = {
    background: '#FF484229 !important',
    height: '22px',
    borderRadius: 1,
    p: '3px',
    fontSize: '14px',
    fontWeight: 600,
    color: '#B71D18',
    '.MuiChip-label': {
        p: '0 5px'
    }
};

export const getPriorityBgColor = (priority) => {
    if (priority === 'HIGH') {
        return '#ffa500 !important';
    }
    if (priority === 'LOW') {
        return '#D3D3D3 !important';
    }
    if (priority === 'URGENT') {
        return '#FF484229 !important';
    }
    return '#0000FF !important';
};
export const getPriorityTextColor = (priority) => {
    if (priority === 'HIGH') {
        return '#fff !important';
    }
    if (priority === 'LOW') {
        return '#000 !important';
    }
    if (priority === 'URGENT') {
        return '#B71D18 !important';
    }
    return '#fff !important';
};

export const tagChipStyleV2 = (priority) => ({
    background: getPriorityBgColor(priority),
    height: '22px',
    borderRadius: 1,
    p: '3px',
    fontSize: '14px',
    fontWeight: 600,
    color: getPriorityTextColor(priority),
    '.MuiChip-label': {
        p: '0 5px'
    }
});
export const tagsChipBoxStyle = {
    display: 'flex',
    width: '100%',
    gap: '12px',
    mt: 1
};

export const cardMainArea = (currentStep) => ({
    overflowY: 'hidden',
    height: 'auto',
    minHeight: currentStep === 0 ? 'auto' : 'calc(100vh - 265px)'
});
export const cardMainAreaWrapper = (currentStep, expandLayout) => ({
    py: currentStep === 0 ? 2 : 0,
    '.MuiButtonGroup-root': {
        flexDirection: expandLayout ? 'column' : 'row'
    },
    '@media (max-width: 1280px)': {
        flexDirection: 'row'
    },
    ...cardMainArea(currentStep)
});