import PropTypes from 'prop-types';
// @mui
import {
    useTheme,
    LinearProgress,
    Box,
    Stack,
    TableRow,
    TableCell
} from '@mui/material';
// style
import { tableLoader } from '../../styles/loading';

// ----------------------------------------------------------------------

const TableLoading = ({ tableData, pageCount, type }) => {
    const theme = useTheme();

    return (
        <TableRow>
            {tableData === 'No Found' && pageCount === 'No Found' && (
                <TableCell sx={{ position: type === 'table' ? 'unset !important' : '' }}>
                    <Box sx={{ height: type === 'table' ? 'auto' : '150px', width: 'auto' }}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={tableLoader(theme, type)}
                        >
                            <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 320 }} />
                        </Stack>
                    </Box>
                </TableCell>
            )}
        </TableRow>
    );
};

TableLoading.propTypes = {
    tableData: PropTypes.any,
    pageCount: PropTypes.any,
    type: PropTypes.any
};

export default TableLoading;