import React from 'react';
import PropType from 'prop-types';
import { Badge, Button } from '@mui/material';
import { Iconify } from 'src/components';
import { badgeStyle, flwBtnStyle } from 'src/styles/NewLeadTableRowStyle';

const CustomFollowUpBtnV2 = ({ params, handlePopup }) => {
    const getBtnName = () => {
        if (params.row.status === 'FAILED_TO_ATTEND_CONSULTATION') return 'Follow up';
        if (params.row?.lead_treatments__lead_contacted_counts__count === 0) return 'contact lead';
        return 'Follow up';
    };
    return <Button
        variant="contained"
        color="primary"
        startIcon={!params.row.is_archive_pending && <Iconify icon={'fluent:call-32-filled'} />}
        onClick={() => handlePopup(params.row.lead_id, params.row.status, params.row)}
        disabled={params.row.is_archive_pending}
        sx={flwBtnStyle(params.row.is_archive_pending)}
    >
        <Badge
            badgeContent={[params.row?.lead_treatments__lead_contacted_counts__count]}
            color="primary"
            sx={badgeStyle(params.row.status, params.row?.lead_treatments__lead_contacted_counts__count)}
        >
            {getBtnName()}
        </Badge>
    </Button>;
};

export default CustomFollowUpBtnV2;

CustomFollowUpBtnV2.propTypes = {
    params: PropType.any,
    handlePopup: PropType.func,
};
