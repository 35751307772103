import { useSelector } from '../redux/store';
import useAuth from './useAuth';

export default function useGetCurrentUserId() {
    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const { user } = useAuth();

    const getUserList = () => results.filter(el => el.user && el.user.id === user?.pk);

    return getUserList()?.[0]?.id || '';
}
