import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Popover } from '@mui/material';
import { Iconify } from '..';
import { nearestData } from '../../utils/treatmentAccordionUtils';
import { moreMenuPopover } from '../../styles/treatmentTabStyle';
import { scheduleReminderTabButton } from '../../styles/LeadDetailView';

const TreatmentMoreMenu = ({
    data,
    openMoreMenu,
    anchorMoreMenu,
    handleClosePopover,
    handleTreatment,
    handleStartTreatment,
    handleTreatmentAction,
    handlePopup,
    handleCancelPopup
}) => {
    const isStartTreatment = (data.status === 'CONSULTATION_ATTENDED' || data.status === 'CONSULTATION_BOOKED' || data.status === 'NEW');

    const handleEditTreatment = (event) => {
        const payload = {
            treatmentValue: data.treatment,
            priceValue: data.price,
            treatmentId: data.id,
            event,
            leadtreatmentSpecification: data?.leadtreatment_specification,
            treatmentCategory: data?.treatment_data?.treatment_item?.treatment_category?.name
        };
        handleTreatment(payload);
    };
    return (
        <Popover
            open={openMoreMenu}
            anchorEl={anchorMoreMenu}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            disableEnforceFocus
        >
            <Box style={moreMenuPopover}>
                <MenuItem onClick={handleEditTreatment}>
                    <Iconify sx={{ mr: '8px' }} icon={'solar:pen-bold'} width={20} height={20} /> Edit Treatment
                </MenuItem>
                {(data.status === 'CONSULTATION_BOOKED') && (nearestData(data)) &&
                    <>
                        <MenuItem onClick={(event) => handleTreatmentAction('attended', data, event)}>
                            <Iconify icon={'pajamas:todo-done'} sx={{ mr: 1 }} width={20} height={20} /> Attended
                        </MenuItem>
                        <MenuItem onClick={(event) => handleTreatmentAction('failedAttend', data, event)}>
                            <Iconify icon={'icon-park-outline:file-failed'} sx={{ mr: 1 }} width={20} height={20} /> Failed to attend
                        </MenuItem>
                    </>
                }
                {isStartTreatment &&
                    <MenuItem onClick={(event) => handleStartTreatment(data, event)}>
                        <Iconify icon={'bxs:flag'} sx={{ mr: 1 }} width={20} height={20} /> Start Treatment
                    </MenuItem>
                }
                {(data.status === 'TREATMENT_STARTED') &&
                    <>
                        <MenuItem onClick={(event) => handleTreatmentAction('complete', data, event)}>
                            <Iconify icon={'fluent:cloud-sync-complete-24-filled'} sx={{ mr: 1 }} width={20} height={20} /> Complete Treatment
                        </MenuItem>
                        <MenuItem onClick={(event) => handleTreatmentAction('backToAttend', data, event)}>
                            <Iconify icon={'jam:refresh-reverse'} sx={{ mr: 1 }} width={20} height={20} /> Back to Attended
                        </MenuItem>
                    </>
                }
                <MenuItem onClick={() => { handleCancelPopup(); }} sx={{ color: 'error.main' }}>
                    <Iconify icon='solar:trash-bin-trash-bold' width={20} height={20} sx={{ mr: 1 }} /> Cancel Treatment
                </MenuItem>
                <MenuItem onClick={() => handlePopup(data.lead)} sx={scheduleReminderTabButton}>
                    <Iconify sx={{ mr: '8px' }} icon={'mdi:clock-time-three-outline'} width={20} height={20} /> Schedule reminder
                </MenuItem>
            </Box>
        </Popover>
    );
};

TreatmentMoreMenu.propTypes = {
    data: PropTypes.object,
    openMoreMenu: PropTypes.bool,
    anchorMoreMenu: PropTypes.object,
    handleClosePopover: PropTypes.func,
    handleTreatment: PropTypes.func,
    handleStartTreatment: PropTypes.func,
    handleTreatmentAction: PropTypes.func,
    handlePopup: PropTypes.func,
    handleCancelPopup: PropTypes.func,
};

export default TreatmentMoreMenu;