/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
// @mui
import { Autocomplete, TextField } from '@mui/material';
// redux
import { useSelector } from '../../redux/store';

// -------------------------------------------------------------------------------------------

const ReferralDropdown = ({ name, value, onChange, type, ...other }) => {
    const { referral } = useSelector((state) => state.schema);

    return (
        <Autocomplete
            name={name}
            value={value || ''}
            onChange={onChange}
            options={referral}
            renderInput={(params) => <TextField {...params} label="Referral" />}
            sx={{ '.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': { display:  type === 'search' && 'none' } }}
            {...other}
        />);
};

ReferralDropdown.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    type: PropTypes.string,
};

export default ReferralDropdown;
