import PropTypes from 'prop-types';
// form
import { FormProvider as Form } from 'react-hook-form';

// ----------------------------------------------------------------------

export default function FormProvider({ children, onSubmit, methods, isForTab }) {
    return (
        <Form {...methods} >
            <form onSubmit={onSubmit} style={isForTab ? { width: '100%' } : {}}>{children}</form>
        </Form>
    );
}

FormProvider.propTypes = {
    children: PropTypes.node.isRequired,
    methods: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    isForTab: PropTypes.bool
};