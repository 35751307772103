/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { isArray, isEmpty } from 'lodash';
// @mui
import { Card, Box, Typography, Table, TableBody, TablePagination, Button, TableContainer } from '@mui/material';
// components
import { Iconify } from '.';
import { TableHeadCustom } from './table';
import { TableLoading } from './loading';
import AudienceTableRow from '../sections/@dashboard/campaign/AudienceTableRow';
import { OpenAudiencePopup } from './fullScreenPopUps';
import { dispatch, useSelector } from '../redux/store';
import { saveNewCampaign, updateConditionList, updateSelectedCampaign, setUsedIn } from '../redux/slices/campaign';
import { getAudienceList, setIsEditAudience, setSelectedRowData, deleteAudience, updateSelectedAudience, setAudienceIncludeArchive, setFileName, setImportFiles, setMappingValues, clearNewAudience, selectedAudienceData, setAudienceCounter, setOriginalDataFile } from '../redux/slices/audienceManager';
import { useTable, useToggle } from '../hooks';
import { existingTableHeadLabel } from '../constants/audienceConstants';
import { noAudienceBoxStyle } from '../styles/AudienceManager';
import {
    paginationOuterBoxStyle,
    paginationInnerBoxStyle,
} from '../styles/MessageListStyle';

const ExistingAudience = () => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { audienceListing, isAudienceLoading, selectedAudience } = useSelector((state) => state.audienceManager);
    const { count } = audienceListing;
    const [audienceData, setAudienceData] = useState([]);
    const { newCampaign, selectedCampaign } = useSelector((state) => state.campaign);
    const [selectedRow, setSelectedRow] = useState(newCampaign?.audienceSelected?.audience || selectedCampaign?.audience?.audience?.id || null);

    const {
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const {
        toggle: openAudience,
        onOpen: onOpenAudience,
        onClose: onCloseAudience
    } = useToggle();

    useEffect(() => {
        if (practiceId) {
            dispatch(getAudienceList());
        }
    }, [practiceId]);

    useEffect(() => {
        if (selectedAudience) {
            setSelectedRow(typeof selectedAudience?.audience === 'number' ? selectedAudience?.audience : selectedAudience?.audience?.id);
        }
        if (selectedAudience === null) {
            setSelectedRow(null);
        }
    }, [selectedAudience]);

    const getAudienceStatus = () => {
        if (audienceListing?.results?.find((ele) => ele.id === selectedRow)?.type !== 'CSV') return null;
        return audienceListing?.results?.find((ele) => ele.id === selectedRow)?.audience_csv?.validation_status;
    };

    useEffect(() => {
        if (!isEmpty(audienceListing)) {
            if (getAudienceStatus() === 'IN_PROGRESS' || getAudienceStatus() === 'FAILED') {
                setSelectedRow(null);
                dispatch(selectedAudienceData(null));
                dispatch(updateSelectedCampaign({ ...selectedCampaign, audience: null }));
                dispatch(saveNewCampaign({ ...newCampaign, audienceSelected: null }));
            }
            if (!isEmpty(audienceListing?.results?.find((ele) => ele.id === selectedRow))) {
                dispatch(setAudienceCounter(audienceListing?.results?.find((ele) => ele.id === selectedRow)));
            }
            setAudienceData(audienceListing.results);
        }
    }, [audienceListing]);

    const handleUpdate = (row) => {
        if (row?.id === selectedRow) {
            setSelectedRow(null);
            dispatch(selectedAudienceData(null));
            dispatch(updateSelectedCampaign({ ...selectedCampaign, audience: null }));
            dispatch(saveNewCampaign({ ...newCampaign, audienceSelected: null }));
        }
        const data = {
            practiceId,
            limit: rowsPerPage,
            offset: rowsPerPage * page,
        };
        dispatch(getAudienceList(data));
    };

    const handleRowSelection = (rowId, row) => {
        setSelectedRow(rowId);
        const isSelected = newCampaign.audienceSelected && newCampaign.audienceSelected.audience === rowId;
        const updatedSelection = isSelected
            ? null
            : { audience: rowId };
        dispatch(saveNewCampaign({ audienceSelected: updatedSelection }));
        dispatch(selectedAudienceData({ audience: rowId }));
        dispatch(setAudienceCounter(row));
    };

    const handleChangePage = (event, newPage) => {
        const data = {
            limit: rowsPerPage,
            offset: rowsPerPage * newPage,
        };
        onChangePage(event, newPage);
        dispatch(getAudienceList(data));
    };

    const handleChangePerPage = (event) => {
        onChangeRowsPerPage(event);
        const data = {
            limit: event.target.value,
            offset: 0,
        };
        dispatch(getAudienceList(data));
    };

    const onDeleteRow = (row) => {
        const data = {
            id: row.id,
        };
        dispatch(deleteAudience(data, handleUpdate));
    };

    const handleAudiencePopupClose = () => {
        const data = {
            practiceId,
            limit: rowsPerPage,
            offset: rowsPerPage * page,
        };
        dispatch(getAudienceList(data));
        onCloseAudience();
        dispatch(setUsedIn(''));
    };

    const handleSelect = (data) => {
        dispatch(clearNewAudience());
        dispatch(setSelectedRowData(data));
        dispatch(setIsEditAudience(true));
        dispatch(updateConditionList([]));
        dispatch(setFileName(data.audience_csv?.csv));
        dispatch(setImportFiles(data.audience_csv?.csv));
        dispatch(setOriginalDataFile(data.audience_csv?.csv));
        dispatch(setMappingValues({ ...data.audience_csv?.mapping }));
        dispatch(updateSelectedAudience({ ...data, recepient: data?.audience_internal_queryset }));
        dispatch(setAudienceIncludeArchive(data?.audience_internal_queryset?.is_archived_included));
        onOpenAudience();
    };

    const createNewAudience = () => {
        dispatch(clearNewAudience());
        dispatch(setSelectedRowData(null));
        dispatch(setIsEditAudience(false));
        dispatch(setFileName(''));
        dispatch(setImportFiles(''));
        dispatch(setOriginalDataFile(''));
        dispatch(setMappingValues({}));
        onOpenAudience();
    };

    return (
        <Box sx={{ mt: 3 }}>
            {isArray(audienceData) && audienceData.length === 0 && !isAudienceLoading && <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '0 0 24px' }}>
                <Button
                    variant='contained'
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={() => createNewAudience()}
                >
                    Create New Audience
                </Button>
            </Box>}
            {isArray(audienceData) && audienceData.length === 0 && !isAudienceLoading && <Box sx={noAudienceBoxStyle}>
                <Box sx={{ mr: '40px' }}>
                    <img src='/assets/Artboard.png' alt='no-audience' style={{ maxHeight: '160px', objectFit: 'contain' }} />
                </Box>
                <Box>
                    <Typography sx={{ mb: 1, fontSize: '20px', fontWeight: 700 }}>No Audience Created</Typography>
                    <Typography sx={{ color: 'common.grey', fontSize: '14px' }}>Create a new audience</Typography>
                </Box>
            </Box>}
            {isArray(audienceData) && audienceData.length > 0 && <Card>
                {isAudienceLoading && <TableLoading tableData={isAudienceLoading && 'No Found'} pageCount={isAudienceLoading && 'No Found'} />}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '24px 24px 24px' }}>
                    <Button
                        variant='contained'
                        startIcon={<Iconify icon={'eva:plus-fill'} />}
                        onClick={() => createNewAudience()}
                    >
                        Create New Audience
                    </Button>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHeadCustom
                            headLabel={existingTableHeadLabel}
                            rowCount={2}
                        />
                        <TableBody>
                            {audienceData.map((row, index) => (
                                <AudienceTableRow
                                    key={index}
                                    row={row}
                                    type={'ExistingAudience'}
                                    selectedRow={selectedRow}
                                    handleRowSelection={handleRowSelection}
                                    onDeleteRow={() => onDeleteRow(row)}
                                    onSelectRow={handleSelect}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ ...paginationOuterBoxStyle, justifyContent: 'flex-end' }}>
                    <Box sx={paginationInnerBoxStyle}>
                        <TablePagination
                            rowsPerPageOptions={[10, 25]}
                            component='div'
                            rowsPerPage={rowsPerPage}
                            page={page}
                            count={count}
                            onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                            onRowsPerPageChange={(event) => handleChangePerPage(event)}
                        />
                    </Box>
                </Box>
            </Card>}
            {openAudience && <OpenAudiencePopup
                open={openAudience}
                headerButtons
                onClose={handleAudiencePopupClose}
            />}
        </Box>
    );
};

export default ExistingAudience;