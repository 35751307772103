import { common } from 'src/theme/palette';

export const analyticsTableCell = { textAlign: 'center', cursor: 'pointer' };
export const analyticsTablePriceCell = { textAlign: 'center', color: common.mintyZest };
export const analyticsTableRowStyle = { borderBottom: '1px solid hwb(209deg 74% 13% / 50%)' };
export const analyticsTableFirstCellStyle = { textAlign: 'left', minWidth: '100px' };
export const cursorPointer = { cursor: 'pointer' };
export const centeredText = { textAlign: 'center' };
export const selectMaxWidth = { maxWidth: '300px' };
export const analyticsCategoryWrapper = { mt: '30px', mb: '30px' };
export const analyticsCategoryWrapperFormTablet = { '@media(max-width: 1200px)': { width: '100%' }, mt: 4 };