import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';
// @mui
import { Box, Stack, Dialog, Button, AppBar, Typography, Slide } from '@mui/material';
// components
import AppointmentNotBooked from '../AppointmentNotBooked';
import { dispatch, useSelector } from '../../redux/store';
import { followUpSlice } from '../../redux/slices/lead';
import {
    commonDialogHeaderStyle,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
} from '../../styles/ScheduleReminder';
import { scriptStackStyle } from '../../styles/CallScripts';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function OpenAppointmentNotBookedPopup({ open, onClose, headerButtons, leadId, onAppointmentNotBookClose }) {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { followLead } = useSelector((state) => state.lead);

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (practiceId) {
            dispatch(followUpSlice(leadId, practiceId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle()}
        >
            <AppBar sx={commonDialogHeaderStyle}>
                <Typography
                    sx={reminderTitleStyle}
                    variant="h6"
                    component="div"
                >
					Appointment not booked for {<span style={{ textTransform: 'capitalize' }}> {followLead && followLead.first_name} {followLead && followLead.last_name}</span>}
                </Typography>
                {headerButtons &&
					<Box sx={cancelBoxStyle}>
					    <Button
					        color="inherit"
					        variant='outlined'
					        style={{ borderColor: '#FFFFFF', color: '#fff' }}
					        onClick={() => { onAppointmentNotBookClose(); }}
					    >
							Close
					    </Button>
					</Box>
                }
            </AppBar>
            <Stack spacing={2} sx={scriptStackStyle}>
                <AppointmentNotBooked onClose={onClose} />
            </Stack>
        </Dialog>
    );
}

OpenAppointmentNotBookedPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    headerButtons: PropTypes.bool,
    leadId: PropTypes.any,
    onAppointmentNotBookClose: PropTypes.any,
};