/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Grid,
    FormControl,
    FormHelperText,
    ToggleButton,
    Typography,
    ToggleButtonGroup,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
//
import Iconify from './Iconify';
import { FormProvider } from './hook-form';
import UserSearchDropdown from './UserSearchDropdown';
// store
import { useSelector, dispatch } from '../redux/store';
import { getPracticeTreatmentList } from '../redux/slices/practiceTreatment';
import { saveNewLeadDetail, updateSteps, UpdateAppointment } from '../redux/slices/lead';
// style
import {
    FollowUpTitle,
    gridStyle,
    boxStyle
} from '../styles/RescheduleAppointmentStyle';
import { toggleButtonStyle } from '../styles/Common';
// validation
import { EditAppointmentSchema } from '../validations/validations';

// ---------------------------------------------------------------------------------------------------------------

const EditAppointment = forwardRef(({ handleClose, type, appoinmentSelectData }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { contactMethod, appointmentType, appointmentMethod } = useSelector((state) => state.schema);
    const appoinmentData = appoinmentSelectData?.appointments?.filter((el) => el.status === 'BOOKED')?.[0];
    const [assignToState, setAssignToState] = useState(appoinmentData?.assignees || []);

    const getDate = (date) => moment(date, 'DD/MM/YYYY HH:mm:ss');

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [assignToState]
    );

    const defaultValues = {
        appoinmentDate: new Date(getDate(appoinmentData?.datetime)) || null,
        bookedWith: [],
        practiceContactedVia: appoinmentData?.contacted_via || '',
        preferredContactMethod: appoinmentData?.method || '',
        appointmentType: appoinmentData?.type || ''
    };

    const methods = useForm({
        resolver: yupResolver(EditAppointmentSchema(assignToState)),
        defaultValues,
    });

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
        trigger
    } = methods;

    useEffect(() => {
        if (defaultValues.treatment) {
            setValue('treatment', defaultValues.treatment);
            setValue('treatmentValue', defaultValues.treatmentValue);
        }
    }, [defaultValues.treatment]);

    useEffect(() => {
        if (assignToState?.length > 0) {
            trigger('bookedWith');
        }
    }, [assignToState, trigger]);

    const onSubmit = async (data) => {
        if (`${moment(data.appoinmentDate).format('DD-MM-YYYY HH:mm:ss')}.0Z` === `${moment(defaultValues.appoinmentDate).format('DD-MM-YYYY HH:mm:ss')}.0Z` && data.bookedWith === defaultValues.bookedWith && data.practiceContactedVia === defaultValues.practiceContactedVia && data.preferredContactMethod === defaultValues.preferredContactMethod && data.appointmentType === defaultValues.appointmentType) {
            handleClose();
            return;
        }
        if (type === 'RescheduleReminder') {
            const updatedTreatment = {
                treatment: data.treatment,
                custom_price: Number(data.treatmentValue)
            };
            const appointmentDetail = {
                datetime: data.appoinmentDate ? `${moment(data.appoinmentDate).format('DD-MM-YYYY HH:mm:ss')}.0Z` : null,
                method: data.preferredContactMethod || '',
                contacted_via: data.practiceContactedVia || '',
                note: data.notes || '',
                assignees: assignToState?.map((el) => el?.id),
                id: appoinmentData?.id,
                lead_treatment: appoinmentData?.lead_treatment || '',
                lead: appoinmentSelectData?.lead_id,
                type: data?.appointmentType
            };
            dispatch(saveNewLeadDetail({
                startTreatment: {
                    appointmentDetail,
                    updatedTreatment
                }
            }));
            dispatch(UpdateAppointment(appointmentDetail, practiceId, handleClose, 'RescheduleReminder'));
        }
    };

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeTreatmentList(practiceId));
        }
    }, [practiceId]);

    const updateNewStep = (value) => {
        const attaendence = moment(value).format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss');
        dispatch(updateSteps(attaendence ? 'payment' : 'attendance'));
    };

    return (
        <Box sx={{ width: '100%', pt: '30px' }}>
            <FollowUpTitle sx={{ mb: '30px' }}>Book an appointment</FollowUpTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid sx={gridStyle}>
                    <Box>
                        <Controller
                            name='appoinmentDate'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DateTimePicker
                                    label='Appointment date (Required)'
                                    value={field.value || null}
                                    minDateTime={new Date()}
                                    format={'dd/MM/yyyy HH:mm'}
                                    ampm={false}
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                        updateNewStep(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                            onKeyDown: (e) => e.preventDefault()
                                        },
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <UserSearchDropdown name={'bookedWith'} control={control} label='Booked with (Required)' assignToState={assignToState} setAssignTo={setAssignToState} error={errors?.bookedWith} />
                </Grid>
                <Box sx={{ ...boxStyle, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <Controller
                        name="preferredContactMethod"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ m: '0 0 10px' }}
                                >
                                    Appointment method (Required)
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                    error={error && !field.value?.length}
                                >
                                    {appointmentMethod?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle(isLight)}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                {error && !field.value?.length &&
                                    <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                        {error?.message}
                                    </FormHelperText>}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="appointmentType"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ m: '0 0 10px' }}
                                >
                                    Appointment type (Required)
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                    error={error && !field.value?.length}
                                >
                                    {appointmentType?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle(isLight)}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                {error && !field.value?.length &&
                                    <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                        {error?.message}
                                    </FormHelperText>}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="practiceContactedVia"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ m: '0 0 10px' }}
                                >
                                    Contact method (Required)
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                    error={error && !field.value?.length}
                                >
                                    {contactMethod?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle(isLight)}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                {error && !field.value?.length &&
                                    <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                        {error?.message}
                                    </FormHelperText>}
                            </FormControl>
                        )}
                    />
                </Box>
            </FormProvider>
        </Box>
    );
});

EditAppointment.propTypes = {
    handleClose: PropTypes.func,
    type: PropTypes.any,
    appoinmentSelectData: PropTypes.any,
};

export default EditAppointment;