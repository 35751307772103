import React, { useEffect } from 'react';
// libraries
import { useNavigate } from 'react-router';
import { IconButton, Box, Snackbar, Typography, Button } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import PieSocket from 'piesocket-js';
// components
import { Iconify } from '../index';
// redux
import { getTime } from '../../redux/slices/toDoNotifications';
import { leadPage } from '../../redux/slices/lead';
import { dispatch, store, useSelector } from '../../redux/store';
import { getNotificationsJwt, setPersistedEmailNotifications, setWpNotifications, setEmailNotifications } from '../../redux/slices/liveNotificationSlice';
// hooks
import { useAuth, useGetCurrentUser } from '../../hooks';
// style
import { common, primary, success } from '../../theme/palette';
import { notificationContentWrapper, notificationIconWrapper, notificationIconWrapperStyle, notificationMessageWrapper, notificationWrapperV2, snackbarMessageWrapperParent, snackbarWrapper, snackbarWrapperParent, wpMessageWrapper } from '../../styles/toDoNotificationSTygle';

const LiveNotificationHandler = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const { wpNotifications, notificationsJwt, emailNotifications } = useSelector((state) => state.liveNotifications);
    const { user } = useGetCurrentUser();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const pieSocket = React.useRef(null);
    const userId = user?.user?.id;

    const handleCloseNotiFications = (id, reason) => {
        if (reason === 'clickaway') return;
        const { wpNotifications } = store.getState().liveNotifications;
        dispatch(setWpNotifications(wpNotifications.filter((ele) => ele.id !== id)));
    };

    const handleNavigateToWp = (id) => {
        navigate('/dashboard/whatsapp');
        handleCloseNotiFications(id);
    };

    useEffect(() => {
        if (isAuthenticated && user?.id && pieSocket?.current === null) {
            dispatch(getNotificationsJwt({ userId: user.id }));
        }
    }, [isAuthenticated, user]);

    useEffect(() => {
        if (notificationsJwt && userId && pieSocket?.current === null) {
            pieSocket.current = new PieSocket({
                clusterId: import.meta.env.VITE_REACT_APP_SOCKET_CLUSTER_KEY,
                apiKey: import.meta.env.VITE_REACT_APP_SOCKET_API_KEY,
                jwt: notificationsJwt,
            });
            pieSocket.current.subscribe(`${practiceId}_notifications`).then((channel) => {
                channel.listen('new-notification', (data) => {
                    if (!isEmpty(data)) {
                        dispatch(setPersistedEmailNotifications(JSON.parse(data)));
                    }
                });
            });
        }
    }, [notificationsJwt, userId, practiceId]);

    const handleCloseEmailNotiFication = (id, reason) => {
        if (reason === 'clickaway') return;
        const { emailNotifications } = store.getState().liveNotifications;
        dispatch(setEmailNotifications(emailNotifications?.filter((ele) => ele.id !== id)));
    };

    const handleNavigateToAllCommunication = (id, link, verb) => {
        handleCloseEmailNotiFication(id);
        dispatch(leadPage({ name: 'Lead details', path: '/dashboard/app' }));
        if (verb === 'new_email_reply_received') {
            const splitedLink = link?.split('/');
            const leadId = splitedLink?.[splitedLink?.length - 1];
            navigate(`/dashboard/${link}`, { state: { goToAllCommunicationTab: true, leadId } });
            return;
        }
        if (verb === 'phone_number_clicked') {
            const el = document.getElementById("new-lead-button");
            if (el) el?.click();
            navigate(`dashboard/analytics/contact-summary`)
        }
        navigate(`/dashboard/${link}`, { state: { shouldRedirectToOverview: true } });
    };

    const getBtnName = (verb) => {
        if (verb === 'new_email_reply_received') return 'Open email';
        if (verb === 'new_lead') return 'Open lead';
        if (verb === 'phone_number_clicked') return 'Open';
        return 'Open link';
    };

    const getBtnIcon = verb => {
        if (verb === "new_lead") return "wpf:adduser";
        if (verb === 'phone_number_clicked') return 'mage:phone-call-fill';
        return "ic:baseline-email"
    }

    return (
        <>
            {isAuthenticated && (!isEmpty(wpNotifications) || !isEmpty(emailNotifications)) && <Box sx={snackbarWrapperParent}>
                <Box sx={snackbarWrapper}>
                    {isAuthenticated &&
                        isArray(wpNotifications) &&
                        wpNotifications.length > 0 &&
                        [...wpNotifications].reverse().slice(0, 2).map((ele, index) => (
                            <Snackbar
                                key={index}
                                open
                                autoHideDuration={60000}
                                onClose={(e, reason) => handleCloseNotiFications(ele.id, reason)}
                                sx={{ position: 'relative' }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Box sx={notificationWrapperV2}>
                                    <Box sx={notificationContentWrapper}>
                                        <Box sx={{ ...notificationIconWrapper, background: success.lighter }}>
                                            <Iconify
                                                icon={'logos:whatsapp-icon'}
                                                width={24}
                                                height={24}
                                                color={common.meadowGreen}
                                            />
                                        </Box>
                                        <Box sx={wpMessageWrapper}>
                                            <Box sx={snackbarMessageWrapperParent}>
                                                <Box sx={notificationMessageWrapper}>
                                                    <Box sx={{ width: '100%', mr: 2 }}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                                                                {ele.from}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '14px' }}>{getTime(ele.created_time, true)}</Typography>
                                                        </Box>
                                                        <Typography sx={{ fontSize: '14px' }}>
                                                            {ele.body}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ mt: '8px', display: 'flex', gap: 1 }}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ minWidth: '130px', fontSize: '13px' }}
                                                        onClick={() => handleNavigateToWp(ele.id)}
                                                    >
                                                        Open link
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <IconButton
                                                    onClick={() => handleCloseNotiFications(ele.id)}
                                                >
                                                    <Iconify icon={'ci:close-big'} width={20} height={20} color="common.grey" />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Snackbar>
                        ))}
                    {isAuthenticated &&
                        isArray(emailNotifications) &&
                        emailNotifications.length > 0 &&
                        emailNotifications.slice(0, 2).map((emailData, index) => (
                            <Snackbar
                                key={index}
                                open={emailData.id}
                                autoHideDuration={60000}
                                onClose={(e, reason) => handleCloseEmailNotiFication(emailData.id, reason)}
                                sx={{ position: 'relative' }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Box sx={notificationWrapperV2}>
                                    <Box sx={notificationContentWrapper}>
                                        <Box sx={notificationIconWrapperStyle(emailData.verb === 'new_lead' ? success.lighter : common.peachPuff)}>
                                            <Iconify icon={getBtnIcon(emailData?.verb)} width={24} height={24} color={emailData.verb === 'new_lead' || emailData.verb === 'phone_number_clicked' ? primary.main : common.scarletBlaze} />
                                        </Box>
                                        <Box sx={snackbarMessageWrapperParent}>
                                            <Box sx={{ ...notificationMessageWrapper, alignItems: 'center' }}>
                                                <Typography sx={{ fontSize: '14px', mr: 1 }}>{emailData.description || ''}</Typography>
                                                <Button
                                                    variant="contained"
                                                    sx={{ minWidth: '100px', fontSize: '13px' }}
                                                    onClick={() => handleNavigateToAllCommunication(emailData?.id, emailData?.action_link, emailData.verb)}
                                                >
                                                    {getBtnName(emailData.verb)}
                                                </Button>
                                                <IconButton
                                                    onClick={() => handleCloseEmailNotiFication(emailData.id)}
                                                >
                                                    <Iconify icon={'ci:close-big'} width={20} height={20} color="common.grey" />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Snackbar>
                        ))}
                </Box>
            </Box >}
        </>
    );
};

export default LiveNotificationHandler;
