// @mui
// import { Stack, Button, Typography } from '@mui/material';
// hooks
// import useAuth from '../../../hooks/useAuth';
// import useLocales from '../../../hooks/useLocales';
// routes
// import { PATH_DOCS } from '../../../routes/paths';
// assets
// import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
    // const { user } = useAuth();

    // const { translate } = useLocales();

    return (
        true
    );
}
