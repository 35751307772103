/* eslint-disable no-unneeded-ternary */
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// @mui
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
    TextField,
    Button,
} from '@mui/material';
import {
    DateRangePicker,
    LocalizationProvider
} from '@mui/x-date-pickers-pro';
import moment from 'moment';
import Iconify from './Iconify';
// constant
import {
    RangeShortcut,
    rangeShortcuts
} from '../constants/ReportSummaryConst';
import { widgetButtonStyle } from '../styles/widgetCounterStyle';
import { pickerBoxStyle } from '../styles/NewLead';

const MultiDatePicker = ({ dateChange, value, endDate, setValue, startDateValue, endDateValue, type, inputFieldType }) => {
    const [selectedShortCut, setSelectedShortCut] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const today = moment();
        if (today.isSame(startDateValue, 'day') && today.isSame(endDateValue, 'day')) {
            setSelectedShortCut('Today');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (value[0] === null && value[1] === null) {
            setSelectedShortCut('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const buildHandleRangeClick = (setValue) => (range) => {
        const today = dayjs();
        const tomorrow = dayjs().add(1, 'day');
        setIsOpen(false);
        switch (range) {
            case RangeShortcut.today:
                setValue([today, today]);
                dateChange([today, today]);
                setSelectedShortCut('Today');
                break;
            case RangeShortcut.tomorrow:
                setValue([tomorrow, tomorrow]);
                dateChange([tomorrow, tomorrow]);
                setSelectedShortCut('Tomorrow');
                break;
            case RangeShortcut.allTime:
                setValue([null, null]);
                dateChange([null, null]);
                setSelectedShortCut('All Time');
                break;
            case RangeShortcut.thisWeek:
                setValue([today.startOf('week'), today.endOf('week')]);
                dateChange([today.startOf('week'), today.endOf('week')]);
                setSelectedShortCut('This week');
                break;
            case RangeShortcut.lastWeek:
                setValue([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                dateChange([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                setSelectedShortCut('Last week');
                break;
            case RangeShortcut.last7Days:
                setValue([today.subtract(1, 'week'), today]);
                dateChange([today.subtract(1, 'week'), today]);
                setSelectedShortCut('Last 7 days');
                break;
            case RangeShortcut.currentMonth:
                setValue([today.startOf('month'), today.endOf('month')]);
                dateChange([today.startOf('month'), today.endOf('month')]);
                setSelectedShortCut('Current month');
                break;
            case RangeShortcut.prevMonth:
                setValue([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                dateChange([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                setSelectedShortCut('Previous month');
                break;
            case RangeShortcut.reset:
                setValue([null, null]);
                dateChange([null, null]);
                setSelectedShortCut('reset');
                break;
            default:
                break;
        }
    };

    const getShortCuts = () => {
        if (type === 'ConsultantBooked') {
            return rangeShortcuts;
        }
        return rangeShortcuts.filter((ele) => ele.label !== 'Tomorrow');
    };

    const RangeShortcutsPanel = ({ setValue, children }) => {
        const handleRangeClick = useCallback(
            (range) => setValue && buildHandleRangeClick(setValue)(range),
            [setValue],
        );
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                {startDateValue && endDateValue &&
                    <Box sx={{ width: 'auto', p: '20px 15px 0px', fontWeight: 'bold' }}>
                        <Typography>Selected Date: <span style={{ fontWeight: 'bold' }}>{startDateValue} to {endDateValue}</span></Typography>
                    </Box>
                }
                <Box sx={{ display: 'flex', '@media(max-width: 900px)': { display: 'inline-block' } }}>
                    <Box sx={{ m: 2 }} display="flex" gap={2}>
                        <div>
                            <Typography variant="overline">Date range shortcuts</Typography>
                            <List>
                                {getShortCuts().map(({ range, label }) => (
                                    <ListItem key={range} disablePadding>
                                        <ListItemButton onClick={() => handleRangeClick(range)}>
                                            <ListItemText primary={label} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        <Divider orientation="vertical" />
                    </Box>
                    {children}
                </Box>
            </Box>
        );
    };

    const getInputFieldType = (startProps, endProps) => {
        if (type === 'inputType') {
            return <Box sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
                <TextField {...startProps} onKeyDown={(evt) => evt.preventDefault()} label="Start date" />
                <Box sx={{ mx: 1 }}> to </Box>
                <TextField {...endProps} onKeyDown={(evt) => evt.preventDefault()} label="End date" />
            </Box>;
        }
        if (inputFieldType === 'button') {
            return <Button
                sx={widgetButtonStyle}
                variant="outlined"
                {...endProps} {...startProps} onKeyDown={(evt) => evt.preventDefault()}
                onClick={() => setIsOpen(!isOpen)}
            >
                <Iconify icon={'lets-icons:date-today-duotone-line'} width={24} height={24} sx={{ mr: selectedShortCut !== 'reset' && (startDateValue || endDateValue) ? 1 : 0 }} />
                {selectedShortCut && selectedShortCut !== 'reset' ? selectedShortCut : startDateValue && endDateValue && `${moment(startDateValue).format('MMMM DD, YYYY')} - ${moment(endDateValue).format('MMMM DD, YYYY')}`}
            </Button>;
        }
        return <Box sx={pickerBoxStyle} {...endProps} {...startProps} onKeyDown={(evt) => evt.preventDefault()} onClick={() => setIsOpen(!isOpen)}>
            <Iconify icon={'solar:calendar-mark-bold-duotone'} width={24} height={24} />
        </Box>;
    };

    const handleDateChange = (newValue) => {
        dateChange(newValue);
        if (newValue[0] && newValue[1]) {
            setIsOpen(false);
        }
        setSelectedShortCut('');
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                onChange={(newValue) => handleDateChange(newValue)}
                open={isOpen}
                onClose={handleClose}
                value={value}
                inputFormat="DD MMM YYYY"
                maxDate={(type === 'ConsultantBooked' || type === 'dashboard_widget') ? '' : endDate}
                components={{ PaperContent: RangeShortcutsPanel }}
                renderInput={(startProps, endProps) => (
                    getInputFieldType(startProps, endProps)
                )}
                PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
                componentsProps={{ paperContent: { setValue } }}
            />
        </LocalizationProvider>
    );
};

MultiDatePicker.propTypes = {
    dateChange: PropTypes.func,
    value: PropTypes.array,
    endDate: PropTypes.any,
    setValue: PropTypes.func,
    children: PropTypes.any,
    startDateValue: PropTypes.any,
    endDateValue: PropTypes.any,
    type: PropTypes.string,
    inputFieldType: PropTypes.string,
};
export default MultiDatePicker;