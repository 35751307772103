import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { Label } from '..';
import { dateTimeTypoStyle, tableCellInnerBoxStyle } from '../../styles/NewLeadTableRowStyle';
import { treatmentCellTypoStyle } from '../../styles/LeadTableStyle';

const PaymentDateCell = () => ({
    field: 'deposit_date',
    headerName: 'Deposit date',
    width: 200,
    minWidth: 200,
    renderCell: (params) => {
        const formattedDate = moment(params.row.paid_at, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
        const formattedTime = moment(params.row.paid_at, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
        if (params.row.paid_at) {
            return <Button sx={{ px: 0, fontWeight: '600', '&:hover': { background: 'none' } }} disableRipple >
                <Label
                >
                    <Typography variant='05 Others/Label' sx={dateTimeTypoStyle}
                        dangerouslySetInnerHTML={{ __html: `${formattedDate} <span>at ${formattedTime}</span>` }} />
                </Label>
            </Button>;
        }
        return <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'left' }}>
            <Typography variant='body2' sx={{ ...treatmentCellTypoStyle }}>
                --
            </Typography>
        </Box>;
    }
});

export default PaymentDateCell;