import { isEmpty } from 'lodash';
import moment from 'moment';

export const compareOject = (selectedCampaign, searchTag) => {
    const result = Object.keys(selectedCampaign.recepient.filters)
        .filter((key) => key.includes(searchTag))
        .reduce((accumulator, key) => {
            accumulator[key] = selectedCampaign.recepient.filters[key];
            return accumulator;
        }, {});
    return result;
};

export const convertDate = (data) => {
    const { minute, hour, deliveryDate, timeZone } = data;
    const dateString = moment(deliveryDate).format('YYYY-MM-DD');
    const timeString = `${hour}:${minute}`;
    const dateTimeString = `${dateString} ${timeString} ${timeZone}`;
    const deliveryMoment = moment(dateTimeString, 'YYYY-MM-DD HH:mm A');
    const formattedDate = deliveryMoment.format('DD-MM-YYYY HH:mm:ss.SSSSSS[Z]');
    return formattedDate;
};

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
};

export function setBlankValueForKeyContainingWord(data, word, word2) {
    if (typeof data === 'object' && data !== null) {
        if (Array.isArray(data)) {
            data.forEach((item, index) => {
                data[index] = setBlankValueForKeyContainingWord(item, word);
            });
        } else {
            const copy = deepCopy(data);
            Object.keys(copy).forEach((key) => {
                if (key.includes(word)) {
                    copy[key] = undefined;
                }
                if (!isEmpty(word2) && key.includes(word2)) {
                    copy[key] = undefined;
                }
                copy[key] = setBlankValueForKeyContainingWord(copy[key], word);
            });
            return copy;
        }
    }
    return data;
};
