import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// slice
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';
import { getFacebook } from './practiceIntegration';

// -----------------------------------------------

const initialState = {
    isLoading: false,
    boxLoading: false,
    facebookAccounts: '',
    facebookAvailablePages: '',
    facebookAccountsPages: '',
    facebookAccountsPosts: {
        results: []
    },
    postCommments: [],
    facebookAdAccount: '',
    connectPagesData: {
        results: []
    },
    facebookAdsAvailableAccounts: []
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setFacebookAccounts(state, action) {
            state.isLoading = false;
            state.facebookAccounts = action.payload;
        },
        setFacebookAvailablePages(state, action) {
            state.isLoading = false;
            state.facebookAvailablePages = action.payload;
        },
        setFacebookAdsAvailableAccounts(state, action) {
            state.isLoading = false;
            state.facebookAdsAvailableAccounts = action.payload;
        },
        setFacebookAccountsPages(state, action) {
            state.isLoading = false;
            state.facebookAccountsPages = action.payload;
        },
        setConnectPagesData(state, action) {
            state.isLoading = false;
            state.connectPagesData = action.payload;
        },
        setFacebookAccountsPosts(state, action) {
            state.isLoading = false;
            state.facebookAccountsPosts = action.payload;
        },
        setPostComments(state, action) {
            state.isLoading = false;
            state.postCommments = action.payload;
        },
        screenLoading(state, action) {
            state.boxLoading = action.payload;
        },
        setFacebookAdAccount(state, action) {
            state.isLoading = false;
            state.facebookAdAccount = action.payload;
        },
    }
});

export default slice.reducer;

export const { setConversation, reset, startLoading, addReminderRowData, setFacebookAccountsPages, setFacebookAccountsPosts, setPostComments, screenLoading, setFacebookAdAccount, setConnectPagesData, setFacebookAdsAvailableAccounts } = slice.actions;

export function getFacebookAccounts(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/`);
            if (response.status === 200) {
                dispatch(slice.actions.setFacebookAccounts(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getFacebookAvailablePages(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/available_pages/`);
            if (response.status === 200) {
                dispatch(slice.actions.setFacebookAvailablePages(response.data));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function getFacebookAdsAvailablePages(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/available_ad_accounts/`);
            if (response.status === 200) {
                dispatch(slice.actions.setFacebookAdsAvailableAccounts(response.data));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function getFacebookConnectPages(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/connect_pages/`, 'post', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'It will take some time to sync data in CRM from Facebook. Please check again after few minutes.' }));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function deleteFacebookConnectPage(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Page has been disconnected successfully.' || 'Something went wrong' }));
                dispatch(getFacebookAvailablePages({practiceId: data?.practiceId, accountId: data?.accountId}));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function getFacebookAccountsPages(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/`);
            if (response.status === 200) {
                dispatch(slice.actions.setFacebookAccountsPages(response.data));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function getFacebookAccountsPosts(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/?ordering=-facebook_post_comments__created_time`);
            if (response.status === 200) {
                dispatch(slice.actions.setFacebookAccountsPosts(response.data));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function deletePagePost(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/`, 'delete');
            if (response.status === 204) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Post delete successfully' || 'Something went wrong' }));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function updatePageData(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/`, 'patch', data);
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Post update successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getPostComment(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/facebook-post-comments/`);
            if (response.status === 200) {
                dispatch(slice.actions.setPostComments(response.data));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function resyncToken(data, type, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/resync_token/`, 'post');
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'It will take some time to sync data in CRM from Facebook. Please check again after few minutes.' }));
                dispatch(screenLoading(false));
                if (type === 'addPostComment') {
                    dispatch(addPostComment(data));
                }
                if (type === 'deletePostComment') {
                    dispatch(deletePostComment(data, handleSuccess));
                }
                if (type === 'addCommentReply') {
                    dispatch(addCommentReply(data));
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function addPostComment(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/add_comment/`, 'post', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'It will take some time to sync data in CRM from Facebook. Please check again after few minutes.' }));
                dispatch(screenLoading(false));
            } else {
                const errorResponse = response?.data;
                if (errorResponse && errorResponse.message && errorResponse?.message?.split('190')?.length === 2) {
                    dispatch(resyncToken(data, 'addPostComment'));
                    return;
                }
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function deletePostComment(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/facebook-post-comments/${data.comment_id}/delete/`, 'post', data);
            if (response.status === 200) {
                handleSuccess({ success: true, data });
                dispatch(updateError({ success: true, message: 'Comment deleted successfully' || 'Something went wrong' }));
                dispatch(screenLoading(false));
            } else {
                const errorResponse = response?.data;
                if (errorResponse && errorResponse.message && errorResponse?.message?.split('190')?.length === 2) {
                    dispatch(resyncToken(data, 'deletePostComment', handleSuccess));
                    return;
                }
                dispatch(updateError({ success: false, message: JSON.stringify(response.data.message) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function hidePostComment(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/facebook-post-comments/${data.comment_id}/hide/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Comment hide successfully' || 'Something went wrong' }));
                handleSuccess({ success: true, data });
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data.message) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function syncPagesFacebookAccount(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/sync_pages/`, 'post', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Pages have been synced successfully' }));
                dispatch(getFacebookAccountsPages({practiceId: data?.practiceId, accountId: data?.accountId}));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getFacebookAdAccount(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/available_ad_accounts/`);
            if (response.status === 200) {
                dispatch(slice.actions.setFacebookAdAccount(response.data));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}


// Fix for facebook_account API
export function updateFacebookAdAccount(data, type, handleSuccess) {
    return async () => {
        try {
            // Only send the required `facebook_ad_accounts` field in the request body
            const payload = {
                facebook_ad_accounts: [
                    {
                        name: data?.name,
                        account_id: data?.id,
                    }
                ]
            };

            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.id}/`, 'patch', payload);
            
            if (response.status === 200) {
                if (type === 'existingAccount') {
                    handleSuccess({ success: true });
                    dispatch(updateError({ success: true, message: 'Facebook ad account connected successfully' }));
                    dispatch(getFacebookAccounts({ practiceId: data?.practiceId }));
                    dispatch(getFacebook(data?.practiceId));
                    return;
                }
                if (type === 'updateFacebookAdAccount') {
                    handleSuccess({ success: true });
                    dispatch(updateError({ success: true, message: 'Facebook ad account disconnected successfully' }));
                    dispatch(getFacebookAccounts({ practiceId: data?.practiceId }));
                    dispatch(getFacebook(data?.practiceId));
                    dispatch(getFacebookAdsAvailablePages({ practiceId: data?.practiceId, accountId: data?.id }));
                    return;
                }
                dispatch(updateError({ success: true, message: 'Facebook ad account connected successfully' }));
                dispatch(getFacebookAccounts({ practiceId: data?.practiceId }));
                dispatch(getFacebook(data?.practiceId));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}


export function addCommentReply(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/facebook-post-comments/${data.comment_id}/reply/`, 'post', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'It will take some time to sync data in CRM from Facebook. Please check again after few minutes.' }));
                dispatch(screenLoading(false));
            } else {
                const errorResponse = response?.data;
                if (errorResponse && errorResponse.message && errorResponse?.message?.split('190')?.length === 2) {
                    dispatch(resyncToken(data, 'addCommentReply'));
                    return;
                }
                dispatch(updateError({ success: false, message: JSON.stringify(response.data.message) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function editPostComment(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/${data?.pageId}/facebook-page-posts/${data?.pagePostId}/facebook-post-comments/${data.comment_id}/`, 'put', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'It will take some time to sync data in CRM from Facebook. Please check again after few minutes.' }));
                dispatch(screenLoading(false));
                dispatch(getPostComment(data));
            } else {
                const errorResponse = response?.data;
                if (errorResponse && errorResponse.message && errorResponse?.message?.split('190')?.length === 2) {
                    dispatch(resyncToken(data, 'addCommentReply'));
                    return;
                }
                dispatch(updateError({ success: false, message: JSON.stringify(response.data.message) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}

export function getFacebookAccountsPagesFilter(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/facebook-accounts/${data?.accountId}/facebook-pages/?page_id__in=${data.filter}`);
            if (response.status === 200) {
                dispatch(slice.actions.setConnectPagesData(response.data));
                dispatch(screenLoading(false));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                dispatch(screenLoading(false));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
            dispatch(screenLoading(false));
        }
    };
}
