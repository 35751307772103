import PropTypes from 'prop-types';
import _ from 'lodash';
// @mui
import {
    Card,
    CardHeader,
    Box,
    Stack,
    Divider,
    Typography
} from '@mui/material';
// components
import { Iconify } from '../../../../components';
import { Chart, useChart } from '../../../../components/chart';
import { NotFound } from '../../../../components/empty-content';
// style
import { chartNoDataBox } from '../../../../styles/CategorySummaryTableStyle';

// ----------------------------------------------------------------------
export default function AnalyticsCategory({ title, subheader, type, chart, categoryTitle, ...other }) {
    const { categories, colors, series, options } = chart;

    const chartOptions = useChart({
        colors,
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories,
        },
        tooltip: {
            y: {
                formatter: (value) => type === 'ArchiveSummary' ? `${value}` : `£${value}`,
            },
        },
        ...options,
    });

    return (
        <Card {...other}>
            <CardHeader
                title={title}
                subheader={subheader}
            />

            {chart?.series[0]?.data[0]?.data?.length > 0 ?
                (series.map((item) => (
                    <Box key={item.type} sx={{ mt: 3, mx: 3 }} dir="ltr">
                        <Chart type="bar" series={item.data} options={chartOptions} height={350} />
                    </Box>
                ))) :
                <Box sx={chartNoDataBox} dir="ltr">
                    <NotFound />
                </Box>
            }

            <Divider />

            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                <Box sx={{ py: 2, width: 1, textAlign: 'center' }}>
                    <Typography sx={{ mb: 1, typography: 'body2', color: 'text.secondary' }}>
                        {categoryTitle || 'Categories'}
                    </Typography>

                    <Typography sx={{ typography: 'h4' }}>{chart?.series[0]?.data[0]?.data?.length}</Typography>
                </Box>

                <Box sx={{ py: 2, width: 1, textAlign: 'center' }}>
                    <Typography sx={{ mb: 1, typography: 'body2', color: 'text.secondary' }}>
                        {type === 'ArchiveSummary' ? 'Count' : 'Revenue'}
                    </Typography>

                    <Typography sx={{ typography: 'h4', color: '#00AB55' }}>
                        {type === 'ArchiveSummary' ? '' : <Iconify icon={'mingcute:currency-pound-2-fill'} />}
                        {_.sumBy(chart?.series[0]?.data[0]?.data).toLocaleString()}
                    </Typography>
                </Box>
            </Stack>
        </Card>
    );
}

AnalyticsCategory.propTypes = {
    chart: PropTypes.object,
    title: PropTypes.string,
    subheader: PropTypes.string,
    categoryTitle: PropTypes.string,
    type: PropTypes.string,
};