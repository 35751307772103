import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Iconify } from 'src/components';
import { useSelector } from '../../../../redux/store';
import { tableCellInnerBoxStyle } from '../../../../styles/NewLeadTableRowStyle';

const CustomSelectTreatmentCell = ({ params, handleNext }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const navigate = useNavigate();

    const handleWhatsappClick = (e) => {
        e.preventDefault();
        navigate(`/dashboard/practice/${practiceId}/lead-detail/${params.row.lead_id}?tab=All%20communications`, { state: { goToWhatsappTab: true, treatmentId: params.row.lead_id } });
    };

    const handleClickEvent = (e) => {
        if (params?.row?.referral === 'WHATSAPP') return;
        handleNext(params?.row, e);
    };

    return (
        <Box onClick={(e) => handleClickEvent(e)} sx={tableCellInnerBoxStyle} disabled={params?.row?.is_archive_pending}>
            {params?.row?.referral === 'WHATSAPP' ?
                <Button
                    color='primary'
                    variant='contained'
                    disabled={params?.row?.is_archive_pending}
                    sx={{ p: '9px 16px' }}
                    onClick={(e) => handleWhatsappClick(e)}
                >
                    <Iconify icon={'ri:whatsapp-fill'} width={20} height={20} sx={{ mr: 1 }} /> Whatsapp
                </Button>
                : <Button
                    color='primary'
                    variant='outlined'
                    disabled={params?.row?.is_archive_pending}
                >
                    Select Treatment
                </Button>
            }
        </Box>
    );
};

export default CustomSelectTreatmentCell;

CustomSelectTreatmentCell.propTypes = {
    params: PropTypes.any,
    handleNext: PropTypes.func,
};