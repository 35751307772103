import { alpha } from '@mui/material/styles';
import { common, grey } from 'src/theme/palette';

export const contactAttemptsBox = {
    px: 1,
    width: '100%',
    textAlign: 'center',
};
export const poperStyle = {
    zIndex: 9999,
    margin: '10px 0px 15px !important',
    right: '0px !important',
    left: 'initial !important',
};
export const contactAttemptsCount = {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '36px',
    mt: 0,
    mb: 0,
};
export const contactAttemptsName = {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    mt: 0,
    mb: 0,
    color: 'grey.600',
};
export const cardStyle = {
    pt: 6,
    px: {
        md: 2,
        sm: 3,
    },
    pb: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '@media(max-width: 600px)': {
        pl: '16px',
        pr: '16px',
    },
};

export const threeDotsBtn = {
    borderRadius: 100,
    width: '35px',
    height: '35px',
    minWidth: '35px',
};
export const paper = {
    borderRadius: 100,
    width: '35px',
    height: '35px',
    minWidth: '35px',
};
export const paperMenuItem = {
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    backgroundColor: common.white,
    borderRadius: '12px',
    padding: '8px',
};
export const avatar = {
    width: 48,
    height: 48,
    fontSize: '24px',
    fontWeight: 700,
    textTransform: 'uppercase',
};
export const leadNameTypo = {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '36px',
    mt: 2,
    mb: 0,
};
export const leadTreatmentStatus = {
    fontSize: '12px',
    fontWeight: '700',
    borderRadius: '6px',
    height: '22px',
    padding: '0px 8px',
    lineHeight: '22px',
    color: common.white,
    backgroundColor: common.mintBlue,
};
export const leadTreatmentTypo = {
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '50px',
    padding: '0px 8px',
    lineHeight: '22px',
    color: common.mintyZest,
    border: `1px solid ${common.mintyZest}`,
};
export const messageBox = {
    display: 'flex',
    gap: 0,
    my: 3,
    width: '100%',
    justifyContent: 'center',
};
export const messageBtn = (value) => ({
    color: value ? 'grey.800' : 'common.white',
    borderColor: 'rgba(145, 158, 171, 0.32)',
    minWidth: '45px',
    minHeight: '45px',
    ':hover': { borderColor: 'rgba(145, 158, 171, 0.32)', bgcolor: 'rgba(145, 158, 171, 0.18)' },
});
export const menuPopover = {
    width: 160,
    '& .MuiMenuItem-root': {
        px: 1,
        typography: 'body2',
        borderRadius: 0.75,
        '& svg': {
            mr: 2,
            width: 20,
            height: 20,
        },
    },
};
export const phoneBtn = (value) => ({
    color: value ? 'common.slateGray' : 'common.white',
    borderColor: 'common.borderColor',
    minWidth: '56px',
    minHeight: '42px',
    mr: 2,
    borderRadius: 2,
    ':hover': {
        borderColor: 'rgba(145, 158, 171, 0.32)',
        bgcolor: 'rgba(145, 158, 171, 0.18)',
    },
});
export const leadContact = {
    width: 160,
    '& .MuiMenuItem-root': {
        px: 1,
        typography: 'body2',
        borderRadius: 0.75,
        '& svg': {
            mr: 2,
            width: 20,
            height: 20,
        },
    },
};
export const accBox = {
    display: 'flex',
    mb: 0,
    width: '100%',
    overflow: 'auto',
};
export const accList = {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',
    color: 'grey.500',
    textTransform: 'uppercase',
};
export const accList2 = {
    fontSize: {
        lg: '14px',
        md: '12px',
        xs: '14px',
    },
    fontWeight: 400,
    lineHeight: '22px',
    color: 'grey.500',
    '.MuiListItem-root:nth-child(1)': {
        whiteSpace: 'nowrap'
    },
    '.MuiListItem-root:nth-child(6)': {
        minHeight: '52px'
    }
};
export const listItem = {
    pt: '6px',
    pb: '6px',
    pr: 0,
    pl: '5px',
};
export const autoComBox = {
    display: 'flex',
    mb: 0,
    width: '100%',
    gap: 2,
};
export const iconBox = {
    position: 'fixed',
    bottom: '100px',
    right: '20px',
    height: '60px',
    width: '60px',
    filter: 'drop-shadow(0px 24px 48px rgba(0, 0, 0, 0.16))',
};
export const iconBtn = {
    height: '60px',
    width: '60px',
    padding: 0,
    borderRadius: '100%',
    bgcolor: `${common.mintyZest} !important`,
};
export const paperMenuList = {
    boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
    borderRadius: '8px',
    padding: '16px',
};
export const paperMenuItem2 = {
    borderRadius: '8px',
    minHeight: '64px !important',
    fontWeight: '600',
};
export const paperBox = {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: '700',
};

export const summaryBox = (isLight) => ({
    bgcolor: isLight ? grey[200] : 'rgba(145, 158, 171, 0.16)',
    borderRadius: '8px',
    padding: '4px 12px 4px',
});

export const summarySectionBox = {
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)',
    display: 'flex',
    padding: '16px 0',
};

export const attributeBoxStyle = {
    display: 'flex',
    padding: '16px 0',
    borderBottom: `solid 1px ${common.fadedBlueGray}`,
    '&:last-child': {
        borderBottom: 'none'
    }
};

export const summaryLabelText = {
    textAlign: 'left',
    width: '50%',
    color: grey[500],
    fontSize: '12px',
    fontWeight: '700',
    textTransform: 'uppercase',
};

export const summaryValueText = (isLight) => ({
    textAlign: 'right',
    width: '50%',
    color: isLight ? grey[800] : common.white,
    fontSize: '14px',
    fontWeight: '400',
    bgcolor: 'transparent !important'
});

export const startedDateTypoStyle = (isLight) => ({
    textAlign: 'right',
    color: isLight ? grey[800] : common.white,
    fontSize: '14px',
    fontWeight: '400',
    bgcolor: 'transparent !important',
    textTransform: 'none'
});
// upcoming
export const upcomingTypography = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
};

export const upcomingNoAppointment = (isLight) => ({
    bgcolor: isLight ? grey[200] : 'rgba(145, 158, 171, 0.16)',
    borderRadius: '8px',
    border: '1px dashed rgba(145, 158, 171, 0.32)',
});

export const upcomingAppointmentType = (isLight) => ({
    textAlign: 'center',
    width: '100%',
    color: isLight ? grey[800] : common.white,
    fontSize: '18px',
    fontWeight: '700',
    mb: '13px',
    mt: '10px',
});

export const upcomingPractioner = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    color: grey[600],
};

export const treatmentAccordionBox = {
    border: 'none',
    boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12) !important',
    width: '100%',
    borderRadius: '16px !important',
    marginBottom: '0',
    marginTop: '0 !important',
    padding: '0',
    '&:before': { display: 'none' },
};

export const treatmentAccordionSummary = (isLight) => ({
    bgcolor: 'transparent',
    minHeight: 'auto',
    p: '24px 16px',
    margin: '0',
    ' .MuiAccordionSummary-content': { margin: '0 10px 0 0', alignItems: 'center' },
    ' .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        width: '13.33px',
        height: '1.67px',
        bgcolor: isLight ? grey[800] : common.white,
    },
    '.Mui-expanded svg': { display: 'none' },
    ' .Mui-expanded .edit-treatment-button': { display: 'inline-block' },
});

export const treatmentAccordionSummaryTypo = {
    fontWeight: 700,
    fontSize: '12px',
    mr: '10px',
    borderRadius: '6px',
    padding: '0 8px',
    lineHeight: '22px',
    bgcolor: 'rgba(255, 72, 66, 0.16)',
    color: common.fireBerry,
};

export const treatmentAccordionDetail = {
    textAlign: 'left',
    position: 'relative',
    width: '20%',
    '&:before': {
        content: '""',
        width: '160%',
        height: '2px',
        bgcolor: 'rgba(145, 158, 171, 0.24)',
        left: '28px',
        display: 'inline-block',
        position: 'absolute',
        top: '8px',
    },
    '&.active:before': { bgcolor: common.green },
};

export const treatmentAccordionDetailLastBox = {
    textAlign: 'right',
    position: 'relative',
    width: '20%',
    '&:before': {
        content: '""',
        width: '160%',
        height: '2px',
        bgcolor: 'rgba(145, 158, 171, 0.24)',
        right: '28px',
        display: 'inline-block',
        position: 'absolute',
        top: '8px',
    },
    '&.active:before': { bgcolor: common.green },
};
export const treatmentAccordionDetailCenterBox = {
    textAlign: 'right',
    position: 'relative',
    width: '20%',
    '&:before': {
        content: '""',
        width: '80%',
        height: '2px',
        bgcolor: 'rgba(145, 158, 171, 0.24)',
        right: '60%',
        display: 'inline-block',
        position: 'absolute',
        top: '8px',
    },
    '&:after': {
        content: '""',
        width: '80%',
        height: '2px',
        bgcolor: 'rgba(145, 158, 171, 0.24)',
        left: '60%',
        display: 'inline-block',
        position: 'absolute',
        top: '8px',
    },
    '&.activeBefore:before': { bgcolor: common.green },
    '&.active:before': { bgcolor: common.green },
    '&.active:after': { bgcolor: common.green },
};

export const treatmentAccordionDetailIcon = {
    border: `2px solid ${common.lightGreen}`,
    borderRadius: '100px',
    color: common.lightGreen,
    padding: '1px',
};

export const whiteBGStyle = {
    maxWidth: '35px',
    margin: 'auto',
    position: 'relative',
    zIndex: 1,
    bgcolor: common.white,
};

export const treatmentAccordionDetailSecondIcon = (isLight) => ({
    border: `2px solid ${common.lightGreen}`,
    borderRadius: '100px',
    color: common.lightGreen,
    padding: '1px',
    position: 'relative',
    zIndex: 9,
    bgcolor: isLight ? common.white : grey[800],
});

export const treatmentAccordionDetailIconRounded = {
    border: `2px solid ${grey[500]}`,
    borderRadius: '100px',
    color: grey[500],
    padding: '1px',
};

export const treatmentAccordionDetailHeading = (isLight) => ({
    margin: '10px 0 0',
    fontSize: '14px',
    color: isLight ? grey[800] : common.white,
    fontWeight: '600',
    '@media(max-width: 360px)': {
        fontSize: '12px',
    },
});

export const treatmentAccordionTab = {
    border: 'none',
    borderRadius: 0,
    width: '100%',
    padding: '0',
    marginBottom: '24px',
    ' .Mui-disabled': {
        display: 'none',
    },
};

export const treatmentAccordionTabContent = (isLight) => ({
    width: 'auto',
    marginRight: '34px !important',
    minHeight: '48px',
    flex: 'none',
    padding: { md: '0', xs: '10px 15px' },
    minWidth: 'auto',
    borderBottom: '2px solid transparent',
    '&.Mui-selected': {
        color: isLight ? grey[800] : common.white,
        borderRadius: '0',
        borderBottom: `2px solid ${common.mintyZest}`,
    },
});

export const treatmentAccordionTabButton = (isLight) => ({
    marginTop: '0',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px',
    padding: '6px 16px',
    color: isLight ? grey[800] : common.white,
    mr: 1,
    mb: 1,
    textTransform: 'none !important'
});

export const scheduleReminderTabButton = {
    display: 'none',
};

export const treatmentCancel = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    boxShadow: 'none',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px',
    minWidth: '100px',
};

export const treatmentConfirmCancel = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
    borderRadius: '8px',
    bgcolor: `${common.mintyZest} !important`,
    color: common.white,
    minWidth: '100px',
};

export const editDetailMenuItem = {
    borderRadius: '8px',
    minHeight: '40px !important',
    color: grey[800],
};

export const editTreatmentMenuItem = {
    borderRadius: '8px',
    minHeight: '40px !important',
    color: grey[800],
};

export const archivingMenuItem = {
    borderRadius: '8px',
    minHeight: '40px !important',
    color: grey[800],
};

export const leadDetailBlankGridStyle = {
    '@media(min-width: 1500px)': {
        maxWidth: '392px !important',
    },
};

export const leadDetailTabsGridStyle = {
    '@media(min-width: 1500px)': {
        maxWidth: 'calc(100% - 392px) !important',
        flexBasis: 'calc(100% - 392px) !important',
    },
    '@media(max-width: 900px)': {
        order: '1',
    },
};

export const leadDetailSidebarGridStyle = {
    '@media(min-width: 1500px)': {
        maxWidth: '392px !important',
    },
    '@media(max-width: 900px)': {
        order: '0',
    },
};

export const leadDetailContentGridStyle = {
    '@media(min-width: 1500px)': {
        maxWidth: 'calc(100% - 392px) !important',
        flexBasis: 'calc(100% - 392px) !important',
    },
    '@media(max-width: 900px)': {
        order: '2',
    },
};
// addAssignMember

export const addAssignBox = {
    marginBottom: '24px',
    fontSize: '14px',
    color: grey[600],
    display: 'inline-block',
    lineHeight: '22px',
};

export const addAssignListItem = (selected, row, theme) => ({
    mb: '8px',
    padding: '8px 12px',
    borderRadius: '8px',
    width: 'calc(33.33% - 4px)',
    mr: '4px',
    ':hover': {
        backgroundColor: selected?.includes(row.id) ? alpha(theme.palette.primary.main, 0.08) : '',
    },
    backgroundColor: selected?.includes(row.id) ? alpha(theme.palette.primary.main, 0.08) : '',
    whiteSpace: 'normal',
    '@media (max-width: 600px)': {
        width: 'calc(50% - 4px)',
    },
    '@media (max-width: 479px)': {
        width: '100%',
    }
});
export const treatmentEditPopupListItem = (selected, row) => ({
    mb: '4px',
    padding: '8px 8px',
    borderRadius: '6px',
    width: '100%',
    mr: '0px',
    ':hover': {
        backgroundColor: selected === row.treatment_item?.name ? 'common.lightGrey' : '',
    },
    backgroundColor: selected === row.treatment_item?.name ? 'common.lightGrey' : '',
});

export const communicationCardStyle = {
    padding: '24px',
    fontSize: '14px',
    borderRadius: '8px',
    bgcolor: 'rgb(244, 246, 248)',
    display: 'flex',
    justifyContent: 'space-between',
    p: '8px',
    boxShadow: 'none'
};

export const communicationCounterBGStyle = {
    ml: '4px',
    height: 20,
    width: 20,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    bgcolor: common.scarletBlaze,
    borderRadius: '10px',
};

export const communicationCounterTextStyle = {
    fontSize: 12,
    whiteSpace: 'nowrap',
    color: common.white,
    fontWeight: '700',
    lineHeight: '20px',
};

export const AssignTo = {
    display: 'flex',
    mb: 3,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
};

export const AssignTypography = {
    fontWeight: 700,
    fontSize: '12px',
    color: grey[600],
    textTransform: 'uppercase !important',
    mr: '16px',
};

export const Assignleaddetail = (isLight) => ({
    margin: '0px 0px 0px 1px',
    padding: '0px 7px',
    fontSize: '14px',
    color: isLight ? 'common.grey' : 'common.lightGrey',
    cursor: 'pointer',
});
export const UnAssignWrapper = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    cursor: 'pointer',
};

export const ArchivedUnAssignWrapper = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
};

export const UnAssignInnerWrapper = {
    border: ' 1px dashed rgba(145, 158, 171, 0.24)',
    borderRadius: '100px',
    width: '22px',
    height: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const UnAssignTypography = (isLight) => ({
    margin: '0px 0 0px 1px',
    padding: '0px 7px',
    fontSize: '14px',
    color: isLight ? 'common.grey' : 'common.lightGrey',
});

export const searchTeamTextfieldStyle = {
    width: '100%',
    bgcolor: common.lightGrey,
    borderRadius: 2,
    '.MuiInputBase-input': {
        p: '14px 14px 14px 0'
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderRadius: 2
    }
};

export const primaryTypographyPropsStyle = {
    typography: 'subtitle2',
    mb: '4px',
    lineHeight: '19px',
};

export const secondaryTypographyPropsStyle = {
    typography: 'caption',
    fontSize: '14px',
    lineHeight: '19px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
};

export const leadDetailsBoxStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 2,
    '@media(max-width: 991px)': {
        flexWrap: 'wrap'
    },
};

export const verticalDividerStyle = {
    borderStyle: 'dashed',
    borderColor: 'common.blueishGray'
};

export const leadDetailsItemBoxStyle = {
    whiteSpace: 'nowrap'
};

export const itemTitleTypoTextStyle = {
    fontSize: '15px',
    fontWeight: 600,
    mb: 1
};

export const itemContentTypoTextStyle = {
    fontSize: '15px',
    color: 'common.grey'
};

export const tabLabelStyle = (isLight) => ({
    m: '5px 8px',
    color: isLight ? 'common.slateGray' : 'common.white',
    background: isLight ? grey[300] : 'rgba(145, 158, 171, 0.16)',
});

export const treatmentDayStyle = { fontSize: '10px', fontWeight: 700, lineHeight: '11.75px', textTransform: 'uppercase', color: grey[600], textAlign: 'center' };
export const treatmentDateStyle = { color: grey[700], fontSize: '20px', fontWeight: 700, lineHeight: '23.5px', textAlign: 'center' };
export const treatmentMonthStyle = { color: grey[600], fontSize: '14px', fontWeight: 600, lineHeight: '11.75px', textAlign: 'center' };
export const noAppointmentBoxStyle = (isLight) => ({ bgcolor: isLight ? grey[200] : 'rgba(145, 158, 171, 0.16)', borderRadius: '8px', border: '1px dashed rgba(145, 158, 171, 0.32)' });
export const flex = { display: 'flex' };
export const justifySpaceBetween = { ...flex, justifyContent: 'space-between' };
export const menuItemWrapper = { display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px' };
export const emailTabWrapper = ({ theme }) => ({
    mr: '12px',
    minWidth: '68px',
    '.MuiTab-root.Mui-selected': {
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        color: alpha(theme.palette.primary.main, 1)
    },
    '.MuiTabs-indicator': {
        display: 'none'
    }
});
export const menuItemIcon = { color: grey[800], marginRight: 15 };
export const overViewMenuItemStyle = { borderRadius: '8px', minHeight: '40px !important', color: grey[800] };
export const typoSpanStyle = { color: common.mintyGreen, fontWeight: '700' };
export const timeLineStyle = { '::before': { display: 'none' }, marginBottom: 2, borderRadius: 2, p: '20px', border: '1px dashed', borderColor: 'primary.paperColor' };
export const appointmentCardStyle = { p: 3, mb: 3 };