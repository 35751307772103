export const reminder = [{
    label: 'Call',
    value: 'CALL'
}, {
    label: 'Email',
    value: 'EMAIL'
}, {
    label: 'SMS',
    value: 'SMS'
}];

export const tableData = [{
    type: 'EMAIL',
    subject: 'Great news you’re all booked in for Invisalign',
    send: 'Immediately',
    status: true
}, {
    type: 'EMAIL',
    subject: 'Great news you’re all booked in for Invisalign',
    send: 'Immediately',
    status: true
}];


export const TABLE_HEAD = [
    { id: 'message.type', label: 'Type', align: 'left' },
    { id: 'subject', label: 'Subject', align: 'left' },
    { id: 'delay', label: 'Send', align: 'left' },
    { id: 'is_enabled', label: 'Status', align: 'left' },
    { id: 'menu', }
];