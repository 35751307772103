import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { RHFSelect } from '../hook-form';
import { menuItemStyle } from '../../styles/PersonalDetailsStyle';

const CustomDropdownComponent = ({ data }) => (
    <RHFSelect
        name={`checkbox_${[data.id]}`}
        label='Select dropdown'
        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
    >
        {(data?.field_type?.choices || data?.choices)?.map((option, index) => (
            <MenuItem
                key={index}
                value={option}
                sx={menuItemStyle}
            >
                {option}
            </MenuItem>
        ))}
    </RHFSelect>
);

export default CustomDropdownComponent;

CustomDropdownComponent.propTypes = {
    data: PropTypes.object,
};
