import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
// @mui
import { Grid, Container, Stack, Button, Box } from '@mui/material';
import { grey } from 'src/theme/palette';
// Form
import { useForm } from 'react-hook-form';
import { isDisabledTab } from '../../utils/clinCheckUtils';
import { useTabs } from '../../hooks';
import { clearClinCheckData, getClinCheckList } from '../../redux/slices/clincheck';
import { FormProvider } from '../hook-form';
// Redux
import { dispatch, useSelector } from '../../redux/store';
// Style
import {
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle,
    clinCheckBoxStyle
} from '../../styles/AddReminderPopup';
// Constants
import ClinCheckForm from '../clinCheck/ClinCheckForm';

const ClinCheckStatusPopup = ({ open, onClose, leadDetail, handleClose }) => {
    const inputRef = useRef();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { currentTab, onChangeTab } = useTabs('');
    const treatmentId = leadDetail?.treatment_id;
    const leadId = leadDetail?.lead_id;
    const clinCheckData = leadDetail?.clinCheckData;

    const defaultValues = {
        clinCheckStatus: '',
    };

    const methods = useForm({
        defaultValues,
    });

    const { setValue, handleSubmit } = methods;

    useEffect(() => {
        if (leadId && treatmentId) {
            const data = {
                leadId,
                treatmentId,
                practiceId
            };
            dispatch(clearClinCheckData());
            dispatch(getClinCheckList(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (clinCheckData?.status) {
            setValue('clinCheckStatus', clinCheckData?.status);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinCheckData]);

    const onSubmit = () => {
        inputRef.current.submit();
    };

    return (
        <>
            {open && <Box sx={clinCheckBoxStyle(grey)}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Stack sx={reminderStackStyle}>
                        <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                            <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                                <ClinCheckForm ref={inputRef} handleClose={onClose} handleCloseAndReload={handleClose} leadDetail={leadDetail} onChangeTab={onChangeTab} currentTab={currentTab} />
                            </Container>
                        </Grid>
                    </Stack>
                    <Box sx={{ ...reminderFooterStyle, position: 'relative', p: '16px 24px !important' }}>
                        <Button color="inherit" sx={cancelButtonStyle} onClick={() => onClose(true)}>
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            sx={saveButtonStyle}
                            disabled={isDisabledTab(clinCheckData?.status, currentTab)}
                        >
                            Save
                        </Button>
                    </Box>
                </FormProvider>
            </Box>}
        </>
    );
};

export default ClinCheckStatusPopup;

ClinCheckStatusPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    leadDetail: PropTypes.any,
    handleClose: PropTypes.func,
};
