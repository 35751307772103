import React, { forwardRef } from 'react';
import PropType from 'prop-types';
import { Badge, Box, Button, ButtonGroup, useTheme } from '@mui/material';
import { arrowButtonStyle, badgeStyle, recordAttendedButtonStyle } from 'src/styles/NewLeadTableRowStyle';
import { Iconify } from 'src/components';
import { grey } from 'src/theme/palette';

const CustomRecordAttendanceBtn = forwardRef(({ params, handleMoreMenuTogglePopper, moreMenuOpen }, ref) => {
    const theme = useTheme();
    return (
        <Box>
            <ButtonGroup
                variant="contained"
                ref={ref}
                aria-label="split button"
                sx={{ boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)', minWidth: '185px', borderRadius: 2 }}
            >
                <Button
                    onClick={() => handleMoreMenuTogglePopper(params.row.lead_id)}
                    id={`menuButton-${params.row.lead_id}`}
                    sx={recordAttendedButtonStyle}
                >
                    Record Attendance
                </Button>
                <Button
                    aria-controls={moreMenuOpen ? 'split-button-menu' : undefined}
                    aria-expanded={moreMenuOpen ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleMoreMenuTogglePopper(params.row.lead_id)}
                    id={`menuButton-${params.row.lead_id}`}
                    sx={arrowButtonStyle(theme)}
                >
                    <Badge
                        badgeContent={[params.row?.lead_treatments__lead_contacted_counts__count]}
                        color="primary"
                        sx={badgeStyle(params.row.status, params.row?.lead_treatments__lead_contacted_counts__count)}
                    >
                        <Iconify style={{ color: grey[0] }} width={10} height={10} icon={'bxs:right-arrow'} />
                    </Badge>
                </Button>
            </ButtonGroup>
        </Box>
    );
});

export default CustomRecordAttendanceBtn;

CustomRecordAttendanceBtn.propTypes = {
    params: PropType.any,
    handleMoreMenuTogglePopper: PropType.func,
    moreMenuOpen: PropType.bool,
};
