import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// utils
import { callApi } from '../../utils/apiCall';
import { errorHandler } from '../../utils/errorHandler';

// ---------------------------------------------------------------------
const initialState = {
    isLoading: false,
    practice: {},
    activeTab: 'General'
};


const slice = createSlice({
    name: 'singlePractice',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        getSinglePracticeData(state, action) {
            state.isLoading = false;
            state.practice = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateLoader(state, action) {
            state.isLoading = action.payload;
            state.practice = {};
        },
        updatePracticeDetail(state, action) {
            state.isLoading = false;
            state.practice = action.payload;
        },
        updatePracticeDetailTab(state, action) {
            state.activeTab = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { updatePracticeDetail, updatePracticeDetailTab, reset } = slice.actions;


export function getPracticeItem(id) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${id}/`);
            if (response.status === 200) {
                dispatch(slice.actions.getSinglePracticeData(response.data));
            } else {
                errorHandler(response.data,'',slice.actions.hasError);
            }
        } catch (error) {
            errorHandler(error,'',slice.actions.hasError);
        }
    };
}