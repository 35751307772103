export const handleLatestData = (item, tableType, rowStatus) => {
    let latestData = '';
    const consultantBookedType = tableType === 'ConsultantBooked';
    const allType = tableType === 'Dashboard' || tableType === 'NoTaskTable';
    const consultantAttendedType = tableType === 'ConsultantAttend';
    const consultantClinCheckAttendedType = tableType === 'ConsultantClinCheckAttend';
    const failedToAttendType = tableType === 'FailedToAttend';
    const treatmentStartedType = tableType === 'treatmentStarted' || tableType === 'treatmentStartedWithInvisalign';
    const closedType = tableType === 'Closed';
    const archType = tableType === 'ArchiveTable';
    const newType = tableType === 'NewLeadTable';
    const dashType = tableType === 'DashNewLeadTable';
    const getStatus = () => {
        if (allType || archType || rowStatus === 'All') return 'ALL';
        if (newType || dashType || rowStatus === 'NEW') return 'NEW';
        if (consultantAttendedType || consultantClinCheckAttendedType || rowStatus === 'CONSULTATION_ATTENDED') return 'CONSULTATION_ATTENDED';
        if (consultantBookedType || rowStatus === 'CONSULTATION_BOOKED') return 'CONSULTATION_BOOKED';
        if (failedToAttendType || rowStatus === 'FAILED_TO_ATTEND_CONSULTATION') return 'FAILED_TO_ATTEND_CONSULTATION';
        if (closedType || rowStatus === 'CLOSED') return 'CLOSED';
        if (treatmentStartedType || rowStatus === 'TREATMENT_STARTED') {
            return tableType === 'treatmentStartedWithInvisalign' ? 'TREATMENT_STARTED_INVISALIGN' : 'TREATMENT_STARTED';
        }
        return '';
    };
    const status = getStatus();
    switch (status) {
        case 'ALL':
            latestData = item.lead_treatments.length > 1
                ? item.lead_treatments.slice().sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                : item.lead_treatments;
            break;
        case 'NEW':
            latestData = item.lead_treatments.filter((el) => el.status === 'NEW');
            break;
        case 'CONSULTATION_ATTENDED':
            latestData = item.lead_treatments.filter((el) => el.status === 'CONSULTATION_ATTENDED');
            break;
        case 'CONSULTATION_BOOKED':
            latestData = item.lead_treatments.filter((el) => el.status === 'CONSULTATION_BOOKED');
            break;
        case 'FAILED_TO_ATTEND_CONSULTATION':
            latestData = item.lead_treatments.filter((el) => el.status === 'FAILED_TO_ATTEND_CONSULTATION');
            break;
        case 'CLOSED':
            latestData = item.lead_treatments.filter((el) => el.status === 'CLOSED');
            break;
        case 'TREATMENT_STARTED_INVISALIGN':
            latestData = item.lead_treatments.filter((el) => el.status === 'TREATMENT_STARTED' && el.treatment === 'Invisalign');
            break;
        case 'TREATMENT_STARTED':
            latestData = item.lead_treatments.filter((el) => el.status === 'TREATMENT_STARTED');
            break;
        default:
            latestData = '';
            break;
    }
    return latestData;
};