import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { Badge, Avatar, useTheme } from '@mui/material';
import { getCharAtName } from 'src/utils/getCharAtName';
import { avatarStyle } from 'src/styles/commonStyle';

const getColorByName = (name, role) => {
    switch (role) {
        case 'TCO':
        case 'USER':
            return 'common.darkGreen';
        case 'Practitioner':
            return 'common.darkBlue';
        case 'ADMIN':
            return 'common.darkPurple';
        default:
            return 'common.grey';
    }
};

const CustomAvatar = forwardRef(({ color, name = '', BadgeProps, children, sx, type, role, ...other }, ref) => {
    const theme = useTheme();

    const charAtName = getCharAtName(name);

    const colorByName = getColorByName(name, role);

    const colr = color || colorByName;

    const renderContent =
        colr === 'default' ? (
            <Avatar ref={ref} sx={sx} {...other}>
                {name && charAtName}
                {children}
            </Avatar>
        ) : (
            <Avatar
                ref={ref}
                sx={{
                    ...avatarStyle({ theme, colr }),
                    ...sx,
                }}
                {...other}
            >
                {type !== 'leadDetail' && (name && charAtName)}
                {children}
            </Avatar>
        );

    return BadgeProps ? (
        <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            {...BadgeProps}
        >
            {renderContent}
        </Badge>
    ) : (
        renderContent
    );
});

CustomAvatar.propTypes = {
    sx: PropTypes.object,
    name: PropTypes.string,
    children: PropTypes.node,
    BadgeProps: PropTypes.object,
    type: PropTypes.string,
    role: PropTypes.string,
    color: PropTypes.oneOf([
        'default',
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
    ]),
};

export default CustomAvatar;
