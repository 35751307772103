import PropTypes from 'prop-types';
// @mui
import { Popover } from '@mui/material';

const MenuPopover = ({ children, sx, ...other }) => (
    <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
            sx: {
                p: 1,
                width: 200,
                overflow: 'inherit',
                ...sx,
            },
        }}
        {...other}
        disableEnforceFocus
    >
        {children}
    </Popover>
);

MenuPopover.propTypes = {
    sx: PropTypes.object,
    children: PropTypes.node,
};

export default MenuPopover;