/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
// form
import { useForm } from 'react-hook-form';
// @mui
import {
    Stack,
    Card,
    Paper,
    Box,
    Avatar,
    Typography,
    Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Label from '../../../../components/Label';
// constant
import { TAB_HEAD } from '../../../../constants/LeadDetailConst';
// store
import { addNotes } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
// hooks
import { useAuth } from '../../../../hooks';
// style
import {
    box1Style,
    box2Style,
    box3Style,
    paperStyle,
    avatarStyle,
    avatarBox,
    dateTimeUI,
    labelStyle,
    noteTextFieldStyle,
    treatmentBoxStyle
} from '../../../../styles/AccountSocialLinks';
import { AccountSocialLinkSchema } from '../../../../validations/validations';
// Utils
import capitalizeFirstLetter from '../../../../utils/capitalizedFunc';

// ----------------------------------------------------------------------

const AccountSocialLinks = ({ LeadNotes }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { leadDetail } = useSelector((state) => state.lead);
    const { id } = useParams();
    const { user } = useAuth();

    const defaultValues = {
        addNotes: '',
        leadNotes: ''
    };

    const methods = useForm({
        resolver: yupResolver(AccountSocialLinkSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        getValues,
        setValue
    } = methods;

    const onSubmit = async () => {
        const Details = {
            lead: id,
            additional_notes: getValues('addNotes')
        };
        if (leadDetail?.lead_treatments?.length > 0) {
            Details.treatment = leadDetail?.lead_treatments[0]?.id;
        }
        dispatch(addNotes(Details, practiceId, '', 'leadNote'));
        setValue('addNotes', '');
    };

    const getUserName = (Name) => {
        if (Name?.created_by?.id === user?.pk) {
            return 'You commented';
        }
        if (Name?.lead_contact?.note) {
            return `${Name?.first_name} ${Name?.last_name}`;
        }
        return `${Name?.created_by?.first_name || ''} ${Name?.created_by?.last_name || ''}`;
    };

    return (
        <Card sx={{ p: 3 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} alignItems='flex-start' >
                    <Typography variant="h5" sx={{ fontSize: '18px !important' }}> Notes</Typography>
                    {LeadNotes && LeadNotes.length > 0 && LeadNotes.map((options, index) => (
                        <Box
                            key={index}
                            sx={box1Style}
                        >
                            <Avatar
                                sx={{ height: '40px', width: '40px' }}
                                src={options?.created_by && options?.created_by?.avatar || ''}
                            />
                            <Box sx={box2Style(isLight)}>
                                <Box sx={box3Style}>
                                    <Paper
                                        sx={{
                                            background: isLight ? '#F4F6F8' : 'transparent',
                                            fontWeight: '600',
                                            fontSize: '14px'
                                        }}
                                        name={'leadTitle'}
                                    >
                                        {`${capitalizeFirstLetter(getUserName(options))}`}
                                    </Paper>
                                    {leadDetail?.lead_treatments?.length > 0 && <Label sx={{ color: 'black', backgroundColor: '#DFE3E8', p: '8px' }} >
                                        {`${TAB_HEAD.filter((el) => el?.value === leadDetail?.lead_treatments[0]?.status)[0]?.label}`}
                                    </Label>}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <Paper
                                        name='leadNotes'
                                        variant="outlined"
                                        sx={paperStyle(isLight)}
                                    >
                                        {options?.additional_notes || options?.note || options?.lead_contact?.note || ''}
                                    </Paper>
                                    {
                                        (!options?.additional_notes ) && <Label sx={{ color: 'black', backgroundColor: '#DFE3E8', p: '8px', marginTop:"10px" }} >
                                        Reminder Note
                                    </Label>
                                    }
                                    
                                </Box>
                                <Box sx={treatmentBoxStyle(leadDetail)} >
                                    {leadDetail?.lead_treatments?.length > 0 && <Label sx={labelStyle}>
                                        {leadDetail?.lead_treatments[0]?.treatment}
                                    </Label>}
                                    <Box sx={dateTimeUI} >
                                        {moment(options?.updated_at, 'DD/MM/YYYY HH:mm:ss').format('DD MMM YYYY [at] hh:mm a')}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Stack>
                <Box sx={avatarBox}>
                    <Avatar sx={avatarStyle} alt="J" src={user?.avatar || ''} />
                    <RHFTextField sx={noteTextFieldStyle} name='addNotes' multiline rows={3} label='Leave a note...' placeholder='' />
                </Box>
                <Box sx={{ marginLeft: '58px', mt: '20px' }}>
                    <Button type='submit' variant='contained' sx={{ float: 'right' }} >comment</Button>
                </Box>
            </FormProvider>
        </Card>
    );
};

AccountSocialLinks.propTypes = {
    LeadNotes: PropTypes.array
};

export default AccountSocialLinks;