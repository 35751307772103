export const backDropStyle = (screen) => ({
    opacity: '1 !important',
    visibility: 'visible !important',
    background: 'rgba(0, 0, 0, 0.3)',
    zIndex: screen === 'failedToAttend' ? 9999 : 1118
});

export const RootSetStyle = (screen, fullScreen) => ({
    ...{
        zIndex: screen === 'failedToAttend' ? 9999 : 1200,
        width: { lg: '100%', xs: 'calc(100% - 30px)' },
        maxWidth: { lg: fullScreen ? '95vw' : '1151px', sm: '1151px' },
        height: { sm: 'auto', xs: 'fit-content' },
        minHeight: { sm: 'auto', xs: 'auto' },
        bottom: 'auto',
        right: 0,
        top: '50%',
        margin: 'auto',
        left: '0 !important',
        boxShadow: '0 3px 40px -4px rgb(145 158 171 / 36%)',
        transform: 'translateY(-50%)',
        maxHeight: 'calc(100vh - 30px)',
        overflow: 'hidden',
        ' form': {
            display: fullScreen ? 'flex' : '',
            flexDirection: fullScreen ? 'column' : '',
            height: fullScreen ? '100%' : ''
        }
    },
});

export const openTamplateBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px'
};

export const typographyStyle = {
    fontWeight: '700',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center'
};

export const iconBtnBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0'
};

export const iconBtnStyle = {
    display: {
        md: 'block',
        xs: 'none',
        height: '36px',
        lineHeight: '0'
    }
};