import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
// slice
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';

// -----------------------------------------------

const initialState = {
    isLoading: false,
    autoReminders: '',
    autoReminderRowData: '',
};

const slice = createSlice({
    name: 'autoReminder',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setAutoReminders(state, action) {
            state.isLoading = false;
            state.autoReminders = action.payload;
        },
        addReminderRowData(state, action) {
            state.isLoading = false;
            state.autoReminderRowData = action.payload;
        }
    }
});

export default slice.reducer;

export const { setAutoReminders, reset, startLoading, addReminderRowData } = slice.actions;

export function getAutoReminders(data) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '';
            const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}` : '';
            const param = `${limit}${offset}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/auto-reminders/?ordering=-created_at${param}&fields={created_at,delay,event,group,id,is_enabled,method,name,note,practice,title,treatments{id,practice,treatment_item{id,name}}}`);
            if (response.status === 200) {
                dispatch(slice.actions.setAutoReminders(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function addAutoReminder(data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/auto-reminders/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Auto reminder created successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getRowReminder(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/auto-reminders/${data?.id}/`);
            if (response.status === 200) {
                dispatch(slice.actions.addReminderRowData(response?.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function updateRowReminder(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/auto-reminders/${id}/`, 'put', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Reminder updated successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function enableRowReminder(id, data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data?.practice}/auto-reminders/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Reminder updated successfully' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteAutoReminder(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/auto-reminders/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Reminder Delete successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

