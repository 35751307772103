import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Radio, RadioGroup, Typography } from '@mui/material';
import Iconify from '../Iconify';
import { FormProvider, RHFTextField, RHFSelect } from '../hook-form';
import { useSelector } from '../../redux/store';
// style
import { mainLabelBoxStyle, labelBoxStyle, fieldLabelStyle, requiredTextStyle, testFieldWrapperStyle } from '../../styles/CallScripts';
import { radioFieldStyle, consentBoxItemStyle } from '../../styles/field';

const SingleConsentPreview = () => {
    const { preferredContactMethodData } = useSelector((state) => state.schema);
    const methods = useForm({});

    return (
        <FormProvider methods={methods}>
            <Box sx={{ p: '24px 24px 0', maxWidth: '1000px', mx: 'auto' }}>
                <Box>
                    <Box sx={consentBoxItemStyle}>
                        <Box sx={mainLabelBoxStyle}>
                            <Box sx={labelBoxStyle}>
                                <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: 'common.lightSlateBlue' }} /> Speak to Customer
                            </Box>
                            <FormLabel sx={fieldLabelStyle}>
                                May I have your name, please? <span style={requiredTextStyle}>(Required)</span>
                            </FormLabel>
                        </Box>
                        <Typography sx={consentBoxItemStyle}>Lead</Typography>
                        <Box sx={testFieldWrapperStyle}>
                            <RHFSelect
                                name={'title'}
                                label='Title'
                                value='Mr'
                                disabled
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            >
                                <MenuItem value='Mr'>Mr</MenuItem>
                            </RHFSelect>
                            <RHFTextField name='firstName' placeholder='First name (Required)' value='Test' disabled />
                            <RHFTextField name='lastName' placeholder='Last name' value='User' disabled />
                        </Box>
                    </Box>
                    <Box sx={consentBoxItemStyle}>
                        <Box sx={mainLabelBoxStyle}>
                            <Box sx={labelBoxStyle}>
                                <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: 'common.lightSlateBlue' }} /> Speak to Customer
                            </Box>
                            <FormLabel sx={fieldLabelStyle}>
                                Can I just confirm the number you calling from as I don’t want to lose your call? <span style={requiredTextStyle}>(Required)</span>
                            </FormLabel>
                        </Box>
                        <RHFTextField
                            name={'phone_number'}
                            type='string'
                            value={'1234567890'}
                            disabled
                            inputProps={{ maxLength: 15 }}
                        />
                    </Box>
                    <Box sx={consentBoxItemStyle}>
                        <Box sx={mainLabelBoxStyle}>
                            <Box sx={labelBoxStyle}>
                                <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: 'common.lightSlateBlue' }} /> Speak to Customer
                            </Box>
                            <FormLabel sx={fieldLabelStyle}>
                                Can I take your email address please?
                            </FormLabel>
                        </Box>
                        <RHFTextField
                            name={'email'}
                            type='string'
                            disabled
                            value={'test@gmail.com'}
                        />
                    </Box>
                    <Box sx={consentBoxItemStyle}>
                        <Box sx={mainLabelBoxStyle}>
                            <Box sx={labelBoxStyle}>
                                <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: 'common.lightSlateBlue' }} /> Speak to Customer
                            </Box>
                            <FormLabel sx={fieldLabelStyle}>
                                Registered patient
                            </FormLabel>
                        </Box>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked
                                        name='typeCheckbox'
                                    />
                                }
                                label={'Is registered patient'}
                                sx={{ width: '100%', display: 'flex', '.MuiTypography-root': { width: '100%' } }}
                            />
                        </FormGroup>
                    </Box>
                    <Box sx={consentBoxItemStyle}>
                        <Box sx={mainLabelBoxStyle}>
                            <Box sx={labelBoxStyle}>
                                <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: 'common.lightSlateBlue' }} /> Speak to Customer
                            </Box>
                            <FormLabel sx={fieldLabelStyle}>
                                Preferred Contact Method
                            </FormLabel>
                        </Box>
                        <FormControl>
                            <Controller
                                render={({ field }) => (
                                    <RadioGroup
                                        row
                                        name='preferredContactMethod'
                                        value={'phone'}
                                        onChange={(event) => field.onChange(event.target.value)}
                                    >
                                        {preferredContactMethodData?.map((el, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={<Radio />}
                                                label={el.label}
                                                value={el.value}
                                                sx={radioFieldStyle}
                                            />
                                        ))}
                                    </RadioGroup>
                                )}
                                control={methods.control}
                                name='preferredContactMethod'
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default SingleConsentPreview;