export const tableHeaderStyle = (isLight) => ({
    width: '50%',
    backgroundImage: isLight ? 'linear-gradient(rgb(244, 246, 248) 0%, rgb(244, 246, 248) 100%)' : '#161C24',
    padding: '16px'
});
export const tableCellStyle = {
    width: '50%',
    padding: '16px',
    wordBreak: 'break-all'
};
export const typeIconBGStyle = {
    width: '42px',
    height: '43.24px',
    bgcolor: 'rgba(255,108,64,0.24)',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
};
export const referralIconBGStyle = {
    width: '42px',
    height: '43.24px',
    bgcolor: 'rgba(224,45,105,0.24)',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
};
export const textStyle = {
    fontSize: '1rem',
    fontWeight: '400',
    color: '#212B36',
    lineHeight: '22px',
    textAlign: 'center',
    mt: '8px'
};
export const sourceTopCardStyle = {
    mb: '24px',
    p: '16px',
    display: 'flex',
    justifyContent: 'space-between'
};