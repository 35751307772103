import PropTypes from 'prop-types';
import React from 'react';
// @mui
import { Box, Step, Stepper, StepLabel, StepConnector } from '@mui/material';
import { styled } from '@mui/material/styles';
// Component
import Iconify from './Iconify';

// eslint-disable-next-line no-unused-vars
function QontoStepIcon ({ active, index, isComplete }) {
    const completed = index < isComplete;
    return (
        <Box
            sx={{
                zIndex: 9,
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'transparent',
                cursor: 'pointer'
            }}
        >
            {completed ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        backgroundColor: 'primary.main',
                        border: '2px solid',
                        borderColor: index <= isComplete ? 'primary.main' : 'text.disabled',
                        cursor: 'pointer'
                    }}
                >
                    <Iconify icon={'eva:checkmark-fill'} sx={{ zIndex: 1, width: 20, height: 20, color: 'grey.0' }} />
                </Box>
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        backgroundColor: 'currentColor',
                        border: '2px solid',
                        borderColor: index <= isComplete ? 'primary.main' : 'text.disabled',
                        cursor: 'pointer'
                    }}
                />
            )}
        </Box>
    );
}
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
    cursor: 'pointer',
    '& .MuiStepConnector-line': {
        borderTopWidth: 2,
        borderColor: theme.palette.divider,
    },
    '&.Mui-active, &.Mui-completed': {
        '& .MuiStepConnector-line': {
            borderColor: theme.palette.primary.main,
        },
    },
}));
export default function Steppers ({ steps, selectStep, activeStep }) {
    return (
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} sx={{ overflow: 'auto' }}>
            {steps.map((step, index) => (
                <Step key={step.label} onClick={() => selectStep(index)}>
                    <StepLabel
                        StepIconComponent={() => (<QontoStepIcon index={index} isComplete={activeStep} />)}
                        id={index}
                        sx={{
                            '& .MuiStepLabel-label': {
                                typography: 'subtitle2',
                                color: index === activeStep ? 'text.primary' : 'text.disabled',
                                cursor: 'pointer',
                                fontWeight: index === activeStep ? 'bold' : '400'
                            },
                        }}
                    >
                        {step.label}
                    </StepLabel> 
                </Step>
            ))}
        </Stepper>
    );
}

Steppers.propTypes = {
    steps: PropTypes.array,
    selectStep: PropTypes.func,
    // isComplete: PropTypes.bool,
    activeStep: PropTypes.number
};

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    index: PropTypes.any,
    isComplete: PropTypes.any
};