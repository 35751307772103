import React, { useState } from 'react';
// @mui
import { Box, TextField, InputAdornment } from '@mui/material';
// components
import { Iconify } from '../../../components';
import { textFieldStyle } from '../../../styles/NewLead';

const WhatsappToolbar = () => {
    const [search, setSearch] = useState(null);

    const handleSearchEvent = (value) => {
        setSearch(value);
    };

    return (
        <Box
            mb={2}
        >
            <Box sx={{ width: 'calc(100% - 32px)', m: '16px auto 16px' }}>
                <TextField
                    fullWidth
                    onChange={(event) => handleSearchEvent(event.target.value)}
                    value={search}
                    sx={textFieldStyle}
                    placeholder="Search templates"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};

export default WhatsappToolbar;