export const getIconAndColor = (priority) => {
    switch (priority) {
        case 'NORMAL':
            return { icon: 'heroicons:bars-2-20-solid', iconColor: '#FF6001' };
        case 'LOW':
            return { icon: 'fa6-solid:angle-down', iconColor: '#1890FF' };
        case 'HIGH':
        case 'URGENT':
            return { icon: 'fluent:chevron-double-up-16-filled', iconColor: '#D93C36' };
        default:
            return { icon: '', iconColor: '' };
    }
};