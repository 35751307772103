import React from 'react';
import ReferralField from '../ReferralField';
import { referralFieldColumnData } from '../../../../../constants/dataGridConstants';

const ReferralCell = ({
    updateLeadPage,
    reloadCounter,
    typed,
    setTableData
}) => ({
    ...referralFieldColumnData,
    renderCell: (params) => (
        <ReferralField
            params={params}
            updateLeadPage={updateLeadPage}
            reloadCounter={reloadCounter}
            tableType={typed}
            setTableData={setTableData}
        />
    ),
});

export default ReferralCell;
