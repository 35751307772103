import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { updateError } from './user';
import { dispatch } from '../store';

const initialState = {
    duplicateArchiveLoading: false,
    duplicateLeads: {
        results: []
    },
    similarLeads: {
        results: []
    },
    selectedDuplicateLeads: {
        leadIds: []
    },
    selectedSimilarLeads: {
        leadIds: []
    },
    markAsDuplicateResp: null,
    duplicateLeadsData: null
};

const slice = createSlice({
    name: 'duplicateLeads',
    initialState,
    reducers: {
        setDuplicateArchiveLoading(state, action) {
            state.duplicateArchiveLoading = action.payload;
        },
        updateDuplicateLead(state, action) {
            state.duplicateLeads.results = action.payload.results;
            state.duplicateLeads.count = action.payload.count;
        },
        updateSimilarLeads(state, action) {
            state.similarLeads.results = action.payload.results;
            state.similarLeads.count = action.payload.count;
            state.duplicateLeadsData = action.payload;
        },
        updateMarkAsDuplicate(state, action) {
            state.markAsDuplicateResp = action.payload;
        },
        updateSelectedDuplicateLeads(state, action) {
            state.selectedDuplicateLeads = action.payload;
        },
        updateSelectedSimilarLeads(state, action) {
            state.selectedSimilarLeads = action.payload;
        },
        clearTableData(state) {
            state.duplicateLeads = {
                results: []
            };
        },
        clearSimilarLeadsData(state) {
            state.similarLeads = {
                results: []
            };
        },
        resetSimilarLeadsData(state) {
            state.duplicateLeadsData = null;
        },
    }
});

export default slice.reducer;

export const { setActivities, reset, updateSelectedDuplicateLeads, setDuplicateArchiveLoading, updateSelectedSimilarLeads, clearSimilarLeadsData, updateSimilarLeads, resetSimilarLeadsData } = slice.actions;

export function getDuplicateLeads({ practiceId, searchQuery, limit = 10, offset = 0, needArchivedData = false, }, handleComplete) {
    return async () => {
        try {
            const query = `limit=${limit}&offset=${offset}&ordering=-updated_at`;
            const search = searchQuery && searchQuery.length > 0 ? `?query=${searchQuery}&${query}` : `?${query}`;
            const needArchivedDataQuery = needArchivedData === false ? '&is_archived=false' : '';
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads/duplicates/${search}${needArchivedDataQuery}`
            );
            if (response.status === 200) { dispatch(slice.actions.updateDuplicateLead(response.data)); if (handleComplete) handleComplete('success'); }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
                if (handleComplete) handleComplete('error');
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
            if (handleComplete) handleComplete('error');
        }
    };
}
export function getSimilarDuplicateLeads({ practiceId, searchQuery, limit = 10, offset = 0, leadId, needArchivedData = false, }) {
    return async () => {
        try {
            const query = `limit=${limit}&offset=${offset}&ordering=-updated_at`;
            const search = searchQuery && searchQuery.length > 0 ? `?query=${searchQuery}&${query}` : `?${query}`;
            const needArchivedDataQuery = needArchivedData === false ? '&is_archived=false' : '';
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads/${leadId}/duplicates/${search}${needArchivedDataQuery}`
            );
            if (response.status === 200) { dispatch(slice.actions.updateSimilarLeads(response.data)); }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}
export function markAsDuplicate({ practiceId, leadId, handleClose }) {
    return async () => {
        try {
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads/${leadId}/mark_as_duplicate/`, 'post'
            );
            if (response.status === 200) {
                dispatch(slice.actions.updateMarkAsDuplicate(response.data));
                dispatch(updateError({ message: 'Lead successfully marked as duplicate', success: true }));
                handleClose('finish');
            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}
export function unMarkAsDuplicate({ practiceId, leadId, handleClose }) {
    return async () => {
        try {
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads/${leadId}/unmark_as_duplicate/`, 'post'
            );
            if (response.status === 200) {
                dispatch(slice.actions.updateMarkAsDuplicate(response.data));
                dispatch(updateError({ message: 'Lead successfully unmarked as duplicate', success: true }));
                handleClose('finish');
            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}
export function bulkUnMarkAsDuplicate({ practiceId, leadId, isLastIndex, handleClose }) {
    return async () => {
        try {
            const response = await callApi(
                `/api/v1/practices/${practiceId}/leads/${leadId}/unmark_as_duplicate/`, 'post'
            );
            if (response.status === 200) {
                dispatch(slice.actions.updateMarkAsDuplicate(response.data));
                dispatch(updateError({ message: 'Lead successfully unmarked as duplicate', success: true }));
                if (isLastIndex) {
                    handleClose('finish');
                }
            }
            else {
                dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function bulkArchivedOrRestore(practiceId, data, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/bulk_archive_restore/`, 'post', data);
            if (response.status === 200) {
                handleSuccess('finish', data?.object_ids);
                dispatch(updateError({ message: data.request_type === 'ARCHIVE' ? 'Lead will archived in 5 minutes.' : 'Restore completed successfully.', success: true }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
                handleSuccess('');
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export const completedToStart = (params) => async () => {
    const { practiceId, leadId, id, handleClose } = params;
    const data = {
        id,
        lead_pk: leadId,
        practice_pk: practiceId
    };
    try {
        const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${id}/treatment_started/`, 'post', data);
        handleClose('finish');
        if (response.status === 200) {
            dispatch(updateError({ message: 'Lead status updated successfully', success: true }));
        }
        else {
            dispatch(updateError({ message: JSON.stringify(response?.data) || 'Something went wrong', success: false }));
        }
    } catch (error) {
        dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
    }
};