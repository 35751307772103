import React from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { EmailValidation } from 'src/validations/commonValidations';

const EmailForm = ({ handleEmailSubmit, formWrapper = {}, fieldWrapperSx = {}, btnSx = {} }) => {
    const emailFormMethods = useForm({
        resolver: yupResolver(EmailValidation),
        defaultValues: { email: '' },
    });

    return <>
        <FormProvider methods={emailFormMethods} >
            <Box sx={formWrapper}>
                <Box sx={fieldWrapperSx}>
                    <RHFTextField name='email' label='Email (Required)' />
                </Box>
                <Button
                    variant='contained'
                    onClick={emailFormMethods.handleSubmit(handleEmailSubmit)}
                    sx={btnSx}
                >
                    Save
                </Button>
            </Box>
        </FormProvider>
    </>;
};

export default EmailForm;

EmailForm.propTypes = {
    handleEmailSubmit: PropTypes.func,
    btnSx: PropTypes.object,
    fieldWrapperSx: PropTypes.object,
    formWrapper: PropTypes.object,
};