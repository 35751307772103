import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogTitle, Typography } from '@mui/material';
import { Iconify } from 'src/components';
import AddPractitioner from 'src/sections/@dashboard/leadDetail/AddPractitioner';
import { TypoNewUserStyle } from 'src/styles/AddUserPopup';

const AddUserPopup = ({ userOpen, setUserOpen }) => {
    const [, setAssignToValue] = useState('');

    return <Dialog open={userOpen} onClose={() => setUserOpen(false)} sx={{ zIndex: 999999, '.MuiPaper-root': { maxWidth: '790px' } }} disableEnforceFocus>
        <DialogTitle sx={{ pb: 2, fontSize: '20px !important', borderBottom: 'solid 1px #919EAB3D' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h4' sx={TypoNewUserStyle}>Add a new user</Typography>
                <Iconify
                    width={24} height={24}
                    sx={{ cursor: 'pointer', color: '#637381' }}
                    icon="ic:round-close"
                    onClick={() => setUserOpen(false)} />
            </Box>
        </DialogTitle>
        <AddPractitioner setUserOpen={setUserOpen} setAssignToValue={setAssignToValue} addUserRole />
    </Dialog>;
};

export default AddUserPopup;

AddUserPopup.propTypes = {
    userOpen: PropTypes.bool,
    setUserOpen: PropTypes.func,
};
