export const reminderAssignees = 'assignees{practice,user,role,id,created_at,updated_at,created_at_human,updated_at_human}';
export const reminderTreatmentData = 'treatment_data{practice,treatment_item,price,enabled,colour,icon,is_starred,id,created_at,updated_at,created_at_human,updated_at_human}';
export const reminderLeadTreatments = `lead_treatment{assignees,lead,treatment,${reminderTreatmentData},status,appointments,price,lead_contacted_counts,leadtreatment_clincheck,is_practice_notification_required,leadtreatment_specification,id,created_at,updated_at,created_at_human,updated_at_human}`;
export const reminderFields = `{${reminderAssignees},created_by,updated_at,created_at,datetime,datetime_human,description,group,id,is_completed,is_system_generated,lead,lead_appointment,${reminderLeadTreatments},method,note,title,priority}`;

export const leadTreatmentsAppointmentsAssignees = 'assignees{practice,user,role,id,created_at,updated_at,created_at_human,updated_at_human,}';

export const leadTreatmentsAppointments = `appointments{id,created_at,updated_at,created_at_human,updated_at_human,lead_treatment,status,datetime,datetime_human,method,type,contacted_via,note,${leadTreatmentsAppointmentsAssignees},lead_treatments}`;

export const leadTreatmentsFields = `lead_treatments{assignees,lead,treatment,treatment_data,status,custom_price,${leadTreatmentsAppointments},price,lead_contacted_counts,leadtreatment_clincheck,is_treatment_fee_pending,state_transition_logs,is_practice_notification_required,leadtreatment_specification,lead_treatment_attributes,lead_treatment_payments,id,created_at,updated_at,created_at_human,updated_at_human,}`;

export const leadFields = `fields={archived_at,is_duplicate,archived_at_human,assignees{created_at,created_at_human,id,practice,role,updated_at,updated_at_human,user},created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,${leadTreatmentsFields},salutation,updated_at,updated_at_human}`;

export const leadFlatFields = `fields={assigned_to,assignees,lead{archived_at,is_duplicate,archived_at_human,assignees{created_at,created_at_human,id,practice,role,updated_at,updated_at_human,user},created_at,created_at_human,dob,first_name,healthcare_provider,id,is_archive_pending,is_archived,is_registered_patient,last_name,lead_address,lead_contact,lead_metadata,lead_notes,lead_source,lead_tags,salutation,updated_at,updated_at_human},treatment,status,custom_price,appointments,price,lead_contacted_counts,leadtreatment_clincheck,is_treatment_fee_pending,state_transition_logs,is_practice_notification_required,leadtreatment_specification,lead_treatment_payments,lead_treatment_attributes,contacted_at,id,created_at,updated_at,created_at_human,updated_at_human}`;
