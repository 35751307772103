export const setRowLeadData = (reminder, leadData) => {
    const data = {
        lead_id: reminder?.lead_treatment?.lead || '',
        treatment_id: reminder?.lead_treatment?.id || '',
        price: reminder?.lead_treatment?.price || '',
        first_name: reminder?.lead?.first_name || '',
        last_name: reminder?.lead?.last_name || '',
        status: reminder?.lead_treatment?.status || '',
        treatment_name: reminder?.lead_treatment?.treatment || '',
        created_at_human: reminder?.lead?.created_at_human,
        lead_treatments__lead_contacted_counts__count: reminder?.lead_treatment?.lead_contacted_counts?.find(item => item.status === leadData[0]?.lead_treatment?.status)?.count || 0,
        clinCheckData: reminder?.lead_treatment?.leadtreatment_clincheck,
        leadTreatmentSpecification: reminder?.lead_treatment?.leadtreatment_specification,
        treatmentData: reminder
    };
    return data;
};