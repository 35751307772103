import { grey } from 'src/theme/palette';

export const formControlLabelStyle = {
    px: 3,
    py: 1.5,
    top: 0,
    position: {
        md: 'absolute'
    }
};

export const notificationBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 3,
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 2
};

export const notificationToggleButtonStyle = {
    bgcolor: 'transparent !important',
    '&.Mui-selected': {
        color: '#00AB55 !important',
        borderBottom: 'solid 2px #00AB55 !important',
        borderRadius: '0 !important'
    }
};

export const notificationTableStyle = {
    '.MuiTableCell-root': {
        boxShadow: 'none !important',
        pl: '16px !important',
        borderRadius: '0 !important'
    },
    '.MuiTableCell-root:first-of-type': {
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
    },
    '.MuiTableCell-root:last-of-type': {
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
    }
};

export const notificationCardStyle = {
    padding: 0
};

export const confirmDialogBoxStyle = {
    color: grey[500]
};