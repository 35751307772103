import { grey } from 'src/theme/palette';

export const summaryCardStyle = () => ({
    display: 'flex',
    alignItems: 'center',
    padding: '16px 16px',
    overflow: 'auto',
    boxShadow: 'none'
});
export const mainSummaryCardStyle = (type) => ({
    padding: '0 0 16px',
    overflow: 'auto',
    boxShadow: 'none',
    border: 'none',
    bgcolor: (type === 'DashNewLeadTable' || type === 'Reports') && 'transparent'
});
export const summaryBoxStyle = (type) => ({
    display: 'flex',
    justifyContent: 'space-between',
    mb: type === 'Dashboard' ? '24px' : '30px',
    flexWrap: 'wrap',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%'
});
export const dateRangePickerStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: '0',
    right: '24px',
    '@media(max-width: 767px)': {
        position: 'relative',
        right: '0',
        mb: '20px'
    }
};
export const summaryStatusBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    mb: '24px',
    flexWrap: 'wrap',
    alignItems: 'center'
};
export const summaryStatusBoxInnerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%'
};
export const summaryStackStyle = {
    mt: 0,
    mb: 0,
    padding: '0 28px',
    borderRight: '1px dashed rgba(145, 158, 171, 0.24)',
    justifyContent: 'center'
};
export const summaryLastStackStyle = {
    mt: 0,
    mb: 0,
    padding: '0 28px',
    justifyContent: 'center'
};
export const summaryTitleStyle = (isLight) => ({
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    color: isLight ? '#212B36' : '#fff',
    display: 'block',
    mb: '4px',
    whiteSpace: 'nowrap'
});
export const summaryLeadStyle = (isLight) => ({
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '600',
    color: isLight ? '#212B36' : '#fff',
    display: 'block',
    mb: '4px',
    whiteSpace: 'nowrap'
});
export const totalPriceStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '600',
    color: '#1890FF',
    display: 'block'
};
export const profileText = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#637381',
    display: 'block'
};
export const orthodonticsPriceStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '600',
    color: '#00AB55',
    display: 'block'
};
export const implantsPriceStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '600',
    color: '#00AB55',
    display: 'block'
};
export const cosmeticPriceStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '600',
    color: '#00AB55',
    display: 'block'
};
export const otherPriceStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '600',
    color: '#00AB55',
    display: 'block'
};
export const innerCircleStyle = {
    color: 'rgba(145, 158, 171, 0.16)',
    opacity: '0.48'
};
export const iconStyle = (isLight) => ({
    position: 'absolute',
    left: 0,
    top: '29px',
    textAlign: 'center',
    margin: 'auto',
    right: '0',
    fontSize: '14px',
    fontWeight: '600',
    color: isLight ? '#212B36' : '#fff',
});
export const blueColorLineStyle = {
    color: '#1890FF',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    opacity: '0.48'
};
export const greenColorLineStyle = {
    color: '#2CD9C5',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
};
export const yellowColorLineStyle = {
    color: '#826AF9',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
};
export const redColorLineStyle = {
    color: '#2D99FF',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
};
export const blackColorLineStyle = {
    color: '#FF6C40',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
};
export const leadBoxFirstStyle = {
    bgcolor: '#C8FACD',
    borderRadius: '16px',
    minHeight: '242px',
    padding: '40px 30px 22px',
    width: 'calc(16.66% - 20px)',
    textAlign: 'center',
    '@media(max-width: 1023px)': {
        width: 'calc(50% - 10px)',
        marginBottom: '20px',
    },
    '@media(max-width: 479px)': {
        width: '100%',
    }
};
export const leadBoxTreatmentStyle = {
    bgcolor: '#D6E4FF',
    borderRadius: '16px',
    minHeight: '242px',
    padding: '40px 30px 22px',
    width: 'calc(16.66% - 20px)',
    textAlign: 'center',
    '@media(max-width: 1023px)': {
        width: 'calc(50% - 10px)',
        marginBottom: '20px',
    },
    '@media(max-width: 479px)': {
        width: '100%',
    }
};
export const leadBoxSecStyle = {
    bgcolor: '#D0F2FF',
    borderRadius: '16px',
    minHeight: '242px',
    padding: '40px 30px 22px',
    width: 'calc(16.66% - 20px)',
    textAlign: 'center',
    '@media(max-width: 1023px)': {
        width: 'calc(50% - 10px)',
        marginBottom: '20px',
    },
    '@media(max-width: 479px)': {
        width: '100%',
    }
};
export const leadBoxThirdStyle = {
    bgcolor: '#FFF7CD',
    borderRadius: '16px',
    minHeight: '242px',
    padding: '40px 30px 22px',
    width: 'calc(16.66% - 20px)',
    textAlign: 'center',
    '@media(max-width: 1023px)': {
        width: 'calc(50% - 10px)',
        marginBottom: '20px',
    },
    '@media(max-width: 479px)': {
        width: '100%',
    }
};
export const leadBoxFourStyle = {
    bgcolor: '#F7D2FF',
    borderRadius: '16px',
    minHeight: '242px',
    padding: '40px 30px 22px',
    width: 'calc(16.66% - 20px)',
    textAlign: 'center',
    '@media(max-width: 1023px)': {
        width: 'calc(50% - 10px)',
        marginBottom: '20px',
    },
    '@media(max-width: 479px)': {
        width: '100%',
    }
};
export const leadBoxFiveStyle = {
    bgcolor: '#FFE7D9',
    borderRadius: '16px',
    minHeight: '242px',
    padding: '40px 30px 22px',
    width: 'calc(16.66% - 20px)',
    textAlign: 'center',
    '@media(max-width: 1023px)': {
        width: 'calc(50% - 10px)',
    },
    '@media(max-width: 479px)': {
        width: '100%',
    }
};
export const statusIconStyle = {
    width: '64px',
    height: '64px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 24px'
};
export const statusCountStyle = {
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '48px',
};
export const statusTextStyle = {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '22px',
    opacity: '0.72'
};
export const unreadMessageBoxItemStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    minWidth: '70px',
    p: '0 6px',
    cursor: 'pointer'
};
export const messageBoxItemInnerStyle = {
    width: '40px',
    height: '40px',
    background: 'var(--09-transparent-06-grey-16, rgba(145, 158, 171, 0.16))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px'
};
export const emergencyAccordion = {
    boxShadow: 'none !important',
    m: '0 !important',
    width: '100%',
    '&:before': {
        display: 'none'
    },
};
export const emergencyAccordionSummary = {
    padding: 0,
    minHeight: '40px !important',
    '.MuiAccordionSummary-content': {
        margin: '0 !important',
    },
    '&:before': {
        display: 'none'
    },
};
export const emergencyAccordionTextArea = {
    '.MuiInputBase-root': {
        background: 'rgba(145, 158, 171, 0.16)',
        border: 'none',
        borderRadius: '8px',
    },
    'fieldset': {
        outline: 'none',
        border: 'none',
    }
};
export const saveButtonStyle = {
    minWidth: '58px',
    margin: 0,
    padding: '5px 14px'
};
export const innerSummaryCardStyle = () => ({
    padding: '24px',
    margin: '5px',
    overflow: 'auto',
    bgcolor: grey[0]
});
export const emergencyContainer = {
    width: '100%',
};