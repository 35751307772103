import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip, Typography, Zoom } from '@mui/material';
import { isEmpty } from 'lodash';
import { getAssignUser } from '../utils/multiAsignees';
import { labelTextStyle, changeButtonStyle } from '../styles/AttendedAppointment';
import Iconify from './Iconify';

const AssignedUsersTooltip = ({ handleClick, assignees, practiceUser }) => {

    const getAssignedUsers = () => getAssignUser(assignees, practiceUser);

    return <Box sx={{ width: '100%', p: '0 0 0 40px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={labelTextStyle}>Practitioner</Typography>
            <Button sx={changeButtonStyle} onClick={handleClick}>
                <Iconify width={18} height={18} icon={'solar:pen-bold'} sx={{ mr: 1 }} />
                Change
            </Button>
        </Box>
        <Box sx={{ width: '100%', mt: '8px' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                <Tooltip title={getAssignedUsers()?.toString() || ''} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                    {`${!isEmpty(assignees) ? getAssignedUsers()?.[0] || '' : ''}`} {getAssignedUsers()?.length > 1 ? `+ ${getAssignedUsers()?.length - 1}` : ''}
                </Tooltip>
            </Typography>
        </Box>
    </Box>;
};

export default AssignedUsersTooltip;

AssignedUsersTooltip.propTypes = {
    handleClick: PropTypes.func,
    assignees: PropTypes.any,
    practiceUser: PropTypes.any,
};