/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide, ButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import SimilarLeadWarningPopup from '../../sections/@dashboard/leadDetail/SimilarLeadWarningPopup';
import Steppers from '../steppers';
import Label from '../Label';
import { useSettingsContext } from '../settings/context';
import { RenderActiveStep } from '../dialogPopUp';
// store
import { useSelector, dispatch } from '../../redux/store';
import { updateSteps, updateBookingSteps, contactLeadSlice, updateActiveStep, updateFollowUpLead, setAttendedActiveTab, setCurrentTreatmentData, setDepositStatus } from '../../redux/slices/lead';
// styles
import { popupMainStackStyle, commonDialogHeaderStyle, onBoardDialogHeader, onBoardFooterButtonsStyle, leadFooterButtonsStyle, tabButtonBoxStyle, tabButtonGroupStyle, contactLeadButtonStyle, activeStepTitleStyle, activeStepTextStyle, footerButtonBoxStyle, backButtonStyle, nextButtonStyle, markAsCompleteButtonStyle, cancelBoxStyle, timelineButtonStyle, gridContainerStyle, dialogTypoStyle, cancelBtnDesign } from '../../styles/DialoguePopup';

const Transition = forwardRef((props, ref) => <Slide direction='up' ref={ref} {...props} />);

const OpenDialogPopup = ({ open, openArchivePopup, onClose, steps, type = 'lead', inputRef, user, updateStep, validStep, headerButtons, onboaradHeaderButtons, title, setActive, BookingAttendance, ContactedCount, timeLineCount }) => {
    const [activeStep, setActiveStep] = useState(0);
    const settings = useSettingsContext();
    const [outcome, setOutcome] = useState('Contact Lead');
    const { activeTab } = useSelector(state => state.singlePractice);
    const { practice } = useSelector(state => state.practice);
    const { isAppointmentBooked, archivedLead, attendedActiveTab, treatmentDepositStatus, treatmentLoading, isLoadingFinish } = useSelector((state) => state.lead);
    const isComplete = activeStep === steps.length - 1;

    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;

    const selectStep = (index) => {
        switch (type) {
            case 'lead':
                dispatch(updateActiveStep(index));
                setActiveStep(index);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        dispatch(setAttendedActiveTab(''));
        dispatch(setCurrentTreatmentData(null));
        dispatch(setDepositStatus(false));
    }, []);

    useEffect(() => {
        if (validStep) {
            if (activeStep !== steps.length - 1) {
                dispatch(updateActiveStep(activeStep + 1));
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setTimeout(() => {
                    updateStep(false);
                }, 1000);
            } else {
                updateStep(false);
                handleClose();
            }
        }
    }, [validStep]);

    useEffect(() => {
        if (BookingAttendance) {
            dispatch(updateActiveStep(activeStep + 1));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }, [BookingAttendance]);

    useEffect(() => {
        if (setActive) setActive(activeStep);
    }, [activeStep]);

    const handleBack = () => {
        if (type === 'onboarad') {
            if (inputRef.current && inputRef.current.handleBack) {
                inputRef.current.handleBack();
                return;
            }
            dispatch(updateActiveStep(activeStep - 1));
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            return;
        }
        if ((type === 'createLead' && activeStep < 2) || (type === 'followup' && activeStep <= 1)) {
            dispatch(updateSteps(null));
        }
        if (type === 'followup' && activeStep < 2) {
            dispatch(updateBookingSteps(null));
        }
        dispatch(updateActiveStep(activeStep - 1));
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = async (status) => {
        if (status === 'Archive') {
            status = 'Archive';
            await inputRef.current.submit(status);
            return;
        }
        if (activeStep === (steps.length - 1)) {
            status = 'finish';
        }
        await inputRef.current.submit(status);
    };

    const handleArchivePopup = () => {
        openArchivePopup();
        dispatch(updateFollowUpLead('archivedLead'));
    };

    const handleClose = () => {
        onClose();
        dispatch(updateActiveStep(0));
        setActiveStep(0);
        dispatch(contactLeadSlice(true));
        setOutcome('Contact Lead');
    };

    const activeBackButton = () => {
        switch (type) {
            case 'onboarad':
                return activeTab !== 'General' || activeStep !== 0;
            default:
                return activeStep !== 0;
        }
    };

    const leadHandler = (data) => {
        setOutcome(data);
        switch (data) {
            case 'Contact Lead':
                dispatch(contactLeadSlice(true));
                break;
            case 'Timeline':
                dispatch(contactLeadSlice(false));
                setActiveStep(0);
                dispatch(updateSteps(null));
                break;
            default:
                break;
        }
    };

    const getTitle = (value) => {
        switch (value) {
            case 'followup':
                if (activeStep === 0) {
                    return 'Contact';
                }
                switch (isAppointmentBooked) {
                    case 'Booking Details':
                        return 'Booking for';
                    case 'Unable to contact':
                        return 'Unable to contact';
                    default:
                        return 'Appointment not booked for';
                }
            default:
                return '';
        }
    };

    const getButton = () => activeStep === (steps.length - 1) ? type === 'followup' && archivedLead ? 'Archive' : 'Finish' : 'Next';

    const isDisabledFinishButton = () => {
        if (treatmentLoading) {
            return true;
        }
        if (getButton() === 'Finish' && isPaymentMandatory && (type === 'CreatedLead' || outcome === 'Contact Lead') && attendedActiveTab === 'Starting treatment' && !treatmentDepositStatus) {
            return true;
        }
        return false;
    };

    return (
        <>
            <Dialog
                fullScreen
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                id='component-drawer'
                sx={{
                    zIndex: type === 'onboarad' ? 1117 : 1200,
                    margin: { xs: '0 -16px', sm: 0 },
                }}
            >
                <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader}>
                    <Typography
                        sx={dialogTypoStyle}
                        variant='h5'
                        component='div'
                    >
                        {user && type === 'onboarad' && `Welcome ${user && user.first_name} ${user && user.last_name}`} {title && title}
                        {user && getTitle(type)} {type === 'followup' && <span style={{ textTransform: 'capitalize' }}> {user && user.first_name} {user && user.last_name}</span>}
                    </Typography>
                    {type === 'onboarad' && <Typography
                        sx={dialogTypoStyle}
                        variant='h6'
                        component='div'
                    >
                        {user && type === 'onboarad' && 'Let’s get you started'}
                    </Typography>}
                    {(headerButtons || onboaradHeaderButtons) &&
                        <Box sx={cancelBoxStyle(onboaradHeaderButtons)}>
                            <Button
                                color='inherit'
                                variant='outlined'
                                style={cancelBtnDesign}
                                onClick={() => { handleClose('close'); }}
                            >
                                Close
                            </Button>
                        </Box>
                    }
                </AppBar>
                <Stack spacing={2} sx={popupMainStackStyle}>
                    <Grid container spacing={1} sx={{ width: '100%', ml: 'auto' }}>
                        <Container
                            maxWidth={settings.themeStretch ? false : 'xl'}
                        >
                            <Grid container justifyContent={isComplete ? 'center' : 'flex-start'} sx={type === 'followup' && ContactedCount !== 0 && activeStep === 0 && gridContainerStyle(type, ContactedCount, activeStep)}>
                                <Grid item xs={12} md={12} sx={{ mb: { md: 10, xs: 5 }, mt: { md: 8, xs: 5 } }}>
                                    <Steppers
                                        steps={steps}
                                        activeStep={activeStep}
                                        isComplete={isComplete}
                                        selectStep={selectStep}
                                    />
                                </Grid>
                                {type === 'followup' && activeStep === 0 && <SimilarLeadWarningPopup type={'DialogPopup'} dialogClose={() => handleClose('close')} />}
                                {type === 'followup' && ContactedCount !== 0 && activeStep === 0 && (
                                    <Box sx={tabButtonBoxStyle}>
                                        <ButtonGroup sx={tabButtonGroupStyle}>
                                            <Button
                                                name={'state'}
                                                onClick={() => leadHandler('Contact Lead')}
                                                sx={contactLeadButtonStyle(outcome)}
                                            >Contact Lead</Button>
                                            <Button
                                                name={'state'}
                                                onClick={() => leadHandler('Timeline')}
                                                sx={timelineButtonStyle(outcome)}
                                            ><Label color={outcome === 'Timeline' ? 'info' : 'default'} sx={{ marginRight: '10px' }} > {timeLineCount || 0} </Label> Timeline </Button>
                                        </ButtonGroup>

                                    </Box>
                                )}
                                {steps[activeStep] && steps[activeStep].title ?
                                    <Box sx={activeStepTitleStyle}>
                                        <Typography variant='h3'>{steps[activeStep].title}</Typography>
                                    </Box>
                                    : ''}
                                {steps[activeStep] && steps[activeStep].bodyText ?
                                    <Box sx={activeStepTextStyle}>
                                        <Typography variant='body'>{steps[activeStep].bodyText}</Typography>
                                    </Box>
                                    : ''}
                                <Grid item xs={12} md={12} sx={{ mb: 5, pb: 13 }} ariaLabel='popup-form-container' >
                                    {steps[activeStep] && RenderActiveStep({ component: steps[activeStep].component, inputRef })}
                                </Grid>
                                {type === 'followup' && activeStep === 0 ? null : <Box
                                    className='buttons-box'
                                    sx={footerButtonBoxStyle}
                                >
                                    <Container
                                        maxWidth={settings.themeStretch ? false : 'xl'}
                                        sx={type === 'createLead' || type === 'followup' ? leadFooterButtonsStyle(activeBackButton(), type) : onBoardFooterButtonsStyle(activeBackButton())}
                                        className='buttons-container'
                                    >
                                        {activeBackButton() && (
                                            <Button
                                                color='inherit'
                                                variant='outlined'
                                                disabled={!activeBackButton()}
                                                onClick={handleBack}
                                                className='back-button'
                                                sx={backButtonStyle(type)}
                                            >
                                                Back
                                            </Button>
                                        )}
                                        <Box className='buttons-right'>
                                            {
                                                type === 'followup' && ContactedCount > 2 && isAppointmentBooked === 'Unable to contact' && (<Button
                                                    color='primary'
                                                    variant='outlined'
                                                    onClick={handleArchivePopup}
                                                    className='finish-button'
                                                    sx={nextButtonStyle(type)}
                                                >
                                                    Archive
                                                </Button>)
                                            }
                                            {
                                                type === 'followup' && activeStep === 0 ? null : (
                                                    <LoadingButton
                                                        color='primary'
                                                        variant='outlined'
                                                        loading={isLoadingFinish}
                                                        loadingPosition='start'
                                                        onClick={() => handleNext()}
                                                        className='finish-button'
                                                        sx={nextButtonStyle(type)}
                                                        disabled={isDisabledFinishButton()}
                                                    >
                                                        {getButton()}
                                                    </LoadingButton>
                                                )
                                            }
                                            {(activeStep >= 1 && activeStep !== (steps.length - 1)) && type !== 'onboarad' &&
                                                <LoadingButton
                                                    color='primary'
                                                    variant='contained'
                                                    loading={isLoadingFinish}
                                                    loadingPosition='start'
                                                    onClick={() => handleNext('finish')}
                                                    className='mark-complete-button'
                                                    sx={markAsCompleteButtonStyle(type)}
                                                >
                                                    Mark As Complete
                                                </LoadingButton>
                                            }
                                        </Box>
                                    </Container>
                                </Box>}
                            </Grid>
                        </Container>
                    </Grid>
                </Stack>
            </Dialog>
        </>
    );
};

export default OpenDialogPopup;

OpenDialogPopup.propTypes = {
    open: PropTypes.bool,
    openArchivePopup: PropTypes.func,
    onClose: PropTypes.func,
    steps: PropTypes.array,
    type: PropTypes.string,
    title: PropTypes.string,
    inputRef: PropTypes.object,
    user: PropTypes.object,
    BookingAttendance: PropTypes.any,
    updateStep: PropTypes.func,
    validStep: PropTypes.bool,
    headerButtons: PropTypes.bool,
    onboaradHeaderButtons: PropTypes.bool,
    setActive: PropTypes.func,
    ContactedCount: PropTypes.number,
    timeLineCount: PropTypes.number,
};