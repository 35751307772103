export const treatmentCellTypoStyle = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    padding: '0'
};

export const treatmentNameCellTypoStyle = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    padding: '0',
    justifyContent: 'center',
    width: '100%'
};
export const detailPanelStackStyle = (width) => ({
    p: 2,
    height: '100%',
    boxSizing: 'border-box',
    position: 'sticky',
    left: 0,
    width,
});

export const detailPanelPaper = { flex: 1, mx: 'auto', width: '100%', p: 1 };
export const detailPanelPaperStack = { height: 1 };