import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { isArray, isEmpty } from 'lodash';
import { getPracticeId } from 'src/redux/slices/clincheck';
import { followUpSlice, updatePersonalDetail, updateTreatmentDetail } from 'src/redux/slices/lead';
import { dispatch, store } from 'src/redux/store';
import { getCurrentTableData } from './tablePreferences';

export const validateFirstName = (firstName) => {
    if (!firstName.trim()) {
        return 'First name cannot be blank';
    }
    return undefined;
};

export const validatePriceName = (price) => {
    const newPrice = parseInt(price, 10);
    if (newPrice === 0 || Number.isNaN(newPrice)) {
        return 'Price cannot be blank';
    }
    return undefined;
};

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== '' && !emailRegex.test(email)) {
        return 'Email must be a valid email address';
    }
    return undefined;
};

export const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+?(\d[\d\s-]{8,}\d)$/;
    if (!phoneRegex.test(phoneNumber)) {
        return 'Phone number must be a valid number';
    }
    return undefined;
};

export const handleSuccessPriceChange = (leadId, data, handleClosePriceChange) => {
    const practiceId = getPracticeId();
    enqueueSnackbar(data.message || '', {
        variant: data.success ? 'success' : 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
    });
    dispatch(followUpSlice(leadId, practiceId, 'LeadDetail', handleClosePriceChange));
};

export const processRowUpdate = (newRow, oldRow, handleClosePriceChange) => {
    const practiceId = getPracticeId();
    let error = false;
    Object.keys(newRow).forEach((field) => {
        if (newRow[field] !== oldRow[field]) {
            switch (field) {
                case 'first_name': {
                    const firstName = newRow[field];
                    const firstNameError = validateFirstName(firstName);
                    if (firstNameError) {
                        enqueueSnackbar(firstNameError, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                        error = true;
                    } else {
                        const detail = {
                            id: oldRow.id,
                            first_name: firstName,
                        };
                        dispatch(updatePersonalDetail(detail, practiceId, '', 'leadDataGrid'));
                    }
                    break;
                }
                case 'last_name': {
                    const lastName = newRow[field];
                    const detail = {
                        id: oldRow.id,
                        last_name: lastName,
                    };
                    dispatch(updatePersonalDetail(detail, practiceId, '', 'leadDataGrid'));
                    break;
                }
                case 'price': {
                    const price = newRow[field];
                    const priceError = validatePriceName(price);
                    if (priceError) {
                        enqueueSnackbar(priceError, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                        error = true;
                    } else {
                        const detail = {
                            custom_price: price,
                        };
                        dispatch(
                            updateTreatmentDetail(practiceId, oldRow.lead_id, oldRow.treatment_id, detail, (data) =>
                                handleSuccessPriceChange(oldRow.lead_id, data, handleClosePriceChange)
                            )
                        );
                    }
                    break;
                }
                case 'email': {
                    const email = newRow[field];
                    const emailError = validateEmail(email);
                    if (emailError) {
                        enqueueSnackbar(emailError, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                        error = true;
                    } else {
                        const detail = {
                            id: oldRow.id,
                            lead_contact: {
                                email,
                            },
                        };
                        dispatch(updatePersonalDetail(detail, practiceId, '', 'leadDataGrid'));
                    }
                    break;
                }
                case 'phone': {
                    const phoneNumber = newRow[field];
                    const phoneNumberError = validatePhoneNumber(phoneNumber);
                    if (phoneNumberError) {
                        enqueueSnackbar(phoneNumberError, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                        });
                        error = true;
                    } else {
                        const detail = {
                            id: oldRow.id,
                            lead_contact: {
                                phone: phoneNumber,
                            },
                        };
                        dispatch(updatePersonalDetail(detail, practiceId, '', 'leadDataGrid'));
                    }
                    break;
                }
                default:
                    break;
            }
        }
    });
    return error ? oldRow : newRow;
};
export const getTableColumns = ({
    tableTitle,
    newLeadsColumns,
    consultationBookedLeadsColumns,
    consultationAttendedLeadsColumns,
    consultationClincheckLeadsColumns,
    treatmentStartedLeadsColumns,
    attendedDateCell,
    archivedLeadsColumns,
    columns,
}) => {
    switch (tableTitle) {
        case 'New Leads':
        case 'Newest Leads':
            return newLeadsColumns;
        case 'Consultation Booked Leads':
            return consultationBookedLeadsColumns;
        case 'Consultation Attended Leads':
            return consultationAttendedLeadsColumns;
        case 'Consultation ClinCheck Leads':
            return consultationClincheckLeadsColumns;
        case 'Treatment Started Leads':
            return treatmentStartedLeadsColumns;
        case 'Failed to attend Leads':
            return [{ ...attendedDateCell }, ...newLeadsColumns];
        case 'Completed Leads':
            return newLeadsColumns;
        case 'Archived Leads':
            return archivedLeadsColumns;
        default:
            return columns;
    }
};

export const reorderArray = (columns, currentTableColumns) => {
    const indices = new Map();
    currentTableColumns.forEach((element, index) => {
        indices.set(element, index);
    });

    columns.sort((a, b) => indices.get(a) - indices.get(b));

    const filteredCurrentColumns = currentTableColumns
        .filter((el) => el !== '__check__' && el !== 'pay_deposit')
        ?.map((ele) => columns.find((el) => el.field === ele))
        ?.filter((el) => el);
    const filteredColumns = columns.filter((ele) => !currentTableColumns.includes(ele.field));
    const finalColumns = [...filteredCurrentColumns, ...filteredColumns];
    return finalColumns;
};

export const getColumns = (columns, tableTitle, isMultiTreatmentTable) => {
    if (isMultiTreatmentTable) return columns;
    const {
        tablePreferences: { results },
        globalTablePreferences,
    } = store.getState().tablePreferences;
    const tableData = getCurrentTableData(results, tableTitle);
    const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableTitle);
    const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
    const currentTableColumns = currentTableData?.[0]?.preferences?.columns || [];
    if (columns && !isEmpty(currentTableColumns)) {
        return reorderArray(columns, currentTableColumns);
    }
    return columns || [];
};

export const getRowClassName = (params, tableTitle) => {
    let className = '';
    if (params.row.is_archive_pending) {
        className += 'disabled-row';
    }
    if (
        !params.row.is_archive_pending &&
        (tableTitle === 'Consultation Booked Leads' || tableTitle === 'All Leads' || tableTitle === 'No Task Assigned') &&
        params?.row?.appointments?.[0]?.status === 'CANCELLED'
    ) {
        className += 'cancelled-appointment-row';
    }
    return className;
};

export const handleGetVisibleColumns = (apiRef) => {
    if (apiRef?.current?.getVisibleColumns) {
        return apiRef?.current?.getVisibleColumns()?.map((ele) => ele?.field);
    }
    return [];
};

export const showDate = (data, row) => {
    const dateTime = row?.created_at;
    if (data === 'date_format' && dateTime) {
        const formattedDate = moment(dateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
        const formattedTime = moment(dateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
        return `${formattedDate} <span>at ${formattedTime}</span>`;
    }
    return row.created_at_human ?? '';
};

export const showAppointmentDate = (data, row) => {
    const appointmentDateTime = row.appointments?.[0]?.datetime;
    if (data === 'date_format' && appointmentDateTime) {
        const formattedDate = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
        const formattedTime = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
        return `${formattedDate} <span>at ${formattedTime}</span>`;
    }
    return row.appointments?.[0]?.datetime_human ?? '';
};

export const showConsultationDate = (data, row) => {
    const appointmentDateTime = row?.consultationAppointment?.[0]?.datetime;
    if (data === 'date_format' && appointmentDateTime) {
        const formattedDate = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
        const formattedTime = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
        return `${formattedDate} <span>at ${formattedTime}</span>`;
    }
    return row.consultationAppointment?.[0]?.datetime_human ?? '';
};

export const showLastContactedDate = (data, row) => {
    if (data === 'date_format') {
        if (row?.last_time_contacted === 'No contact') {
            return 'No contact';
        }
        const formattedDate = moment(row?.last_time_contacted, 'DD-MM-YYYY HH:mm:ss').format('HH:mm DD/MM/YY');
        return `${formattedDate} <span>by ${row?.last_time_updated_by}</span>`;
    }
    if (row?.last_time_contacted === 'No contact') {
        return `${row?.last_time_contacted_human}`;
    }
    return `${row?.last_time_contacted_human} <span>by ${row?.last_time_updated_by}</span>`;
};

export const dateColor = (data) => {
    const today = moment();
    const date = moment(data, 'DD-MM-YYYY HH:mm:ss');
    return date.isAfter(today);
};

export const formatData = (data) => {
    const givenDateUTC = moment.utc(data, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ');
    const givenDateUK = givenDateUTC.utcOffset('').format('DD-MM-YYYY');
    return givenDateUK;
};

export const dateFindColor = (row) => {
    const currentDate = moment().format('DD-MM-YYYY');
    const givenDate = row?.appointments && formatData(row?.appointments?.[0]?.datetime);
    if (givenDate === currentDate) {
        return true;
    }
    return false;
};

export const getMoreAssignees = (data) => data?.assignees?.length > 1 ? `+ ${data?.assignees?.length - 1}` : '';

export const showAttendedDate = (data, row) => {
    const appointmentDateTime = row.appointments?.[0]?.datetime;
    if (data === 'date_format' && appointmentDateTime) {
        const formattedDate = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
        const formattedTime = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
        return `${formattedDate} <span>at ${formattedTime}</span>`;
    }
    return row.appointments?.[0]?.datetime_human ?? '';
};