import { useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Divider, Typography } from '@mui/material';
// redux
import { useSelector } from '../../../redux/store';
//
import { Markdown, Scrollbar } from '../../../components';
import MessageDetailsReplyInput from './MessageDetailsReplyInput';
import MessageDetailsAttachments from './MessageDetailsAttachments';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
});

const MarkdownStyle = styled('div')(({ theme }) => ({
    '& > p': {
        ...theme.typography.body1,
        marginBottom: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------

export default function MessageDetails() {
    const { mailId } = useParams();
    const mail = useSelector((state) => state.mail.mails.byId[mailId]);
    const isAttached = mail && mail.files.length > 0;

    if (!mail) {
        return null;
    }

    return (
        <RootStyle>
            <Divider />

            <Scrollbar sx={{ flexGrow: 1 }}>
                <Box sx={{ p: { xs: 3, md: 5 } }}>
                    <Typography variant="h3" gutterBottom>
                        {mail.subject}
                    </Typography>

                    <MarkdownStyle>
                        <Markdown children={mail.message} />
                    </MarkdownStyle>
                </Box>
            </Scrollbar>

            {isAttached && <MessageDetailsAttachments mail={mail} />}

            <Divider />

            <MessageDetailsReplyInput />
        </RootStyle>
    );
}
