/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography, } from '@mui/material';
import { NormalLoading } from '../../../../components/loading';
import MailItem from './mail-item';
import MailDetails from './mailDetails';
import { store, useSelector } from '../../../../redux/store';
import { getThreadLabels, getThreadMails, getThreadMessages, setSelectedMailInfo, starMessages, deleteMessages, setSelectedEmailId } from '../../../../redux/slices/mail';
import { readMessages, unReadMessages, unStarMessages } from '../../../../utils/mailUtils';
import { noScriptTextStyle } from '../../../../styles/CallScripts';
import { viewBoxStyle, viewListBoxStyle, viewMailDetailBoxStyle, noDataMainBoxStyle, noDataMainBoxInnerStyle, noDataImageStyle, renderListCardStyle } from './styles';

const MailView = ({ mailList, mailType, leadDetail, isLoading, handleBackClicked, successDone, isFromSideBar }) => {
    const { dispatch } = store;
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { emailMessages, draftsMessage, selectedEmailId } = useSelector((state) => state.mail);
    const { practice } = useSelector((state) => state.practice);
    const [index, setIndex] = useState(0);

    const handleMailClick = async (mailId, info, index) => {
        setIndex(index);
        dispatch(setSelectedMailInfo(info));
        if (mailId !== '') {
            dispatch(getThreadMessages(practiceId, mailId, mailType));
            const mailTypesRequiringLabels = ['Incoming', 'Outgoing', 'Starred', 'Label'];
            if (mailTypesRequiringLabels.includes(mailType)) {
                await dispatch(getThreadLabels(practiceId, mailId));
            }
        }
        dispatch(setSelectedEmailId(mailId));
        if (mailId === '') {
            handleBackClicked();
        }
    };

    const handleScroll = (e) => {
        const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop, 10) === e.target.clientHeight;
        if (bottom && mailList?.count > mailList?.results?.length) {
            if (mailType === 'Outgoing') {
                const sEmails = practice.mail_senders.map(item => item.sender_email);
                if (sEmails.length > 0) {
                    dispatch(getThreadMails({ practiceId, mailType, leadId: leadDetail ? leadDetail.id : '', limit: 25, offset: mailList?.results?.length, reload: false }));
                }
            } else {
                dispatch(getThreadMails({ practiceId, mailType, leadId: leadDetail ? leadDetail.id : '', limit: 25, offset: mailList?.results?.length, reload: false }));
            }
        }
    };

    const handleStarClick = async (mail) => {
        const payload = { id: mail.id, mailType, leadId: leadDetail ? leadDetail.id : '', limit: 25, offset: 0 };
        if (mail?.is_starred) {
            await dispatch(unStarMessages(payload));
            return;
        }
        await dispatch(starMessages(payload));
    };

    const handleMarkReadEvent = async (mail) => {
        if (mail?.is_unread) {
            await dispatch(readMessages(practiceId, mail.id, leadDetail ? leadDetail.id : '', 'mailDetails'));
            return;
        }
        await dispatch(unReadMessages(practiceId, mail.id, leadDetail ? leadDetail.id : ''));
    };

    const draftMailDelete = (mail) => {
        dispatch(deleteMessages({ practiceId, id: mail.id, mailType, leadId: leadDetail ? leadDetail.id : '', limit: 25, offset: 0 }));
    };

    useEffect(() => {
        if (mailList?.results?.length > 0 && !selectedEmailId) {
            const mail = mailList?.results[0];
            handleMailClick(mail?.id, mail, 0);
        }
        if (mailList?.results?.length > 0) {
            const mails = mailList?.results[index];
            dispatch(setSelectedMailInfo(mails));
        }
    }, [mailList]);

    const renderMailDetailsNoData = () => {
        if (mailList?.count === 0) {
            return (
                <Box sx={noDataMainBoxStyle}>
                    <Box sx={noDataMainBoxInnerStyle}>
                        <img
                            src='/assets/no-script.png'
                            alt='no-appointment'
                            style={noDataImageStyle} />
                        <Typography sx={noScriptTextStyle}> No Data</Typography>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    const handleCLickMailItem = ({ mail, index }) => {
        handleMailClick(mail.id, mail, index);
        if (mailType === 'Incoming' && mail.is_unread === true) {
            dispatch(readMessages(practiceId, mail.id, leadDetail ? leadDetail.id : ''));
        }
    };

    const renderListOrNoData = () => {
        if (mailList?.results?.length > 0) {
            return (
                <Box onScroll={handleScroll} sx={{ height: mailList?.count === 0 ? 'auto' : '100%', overflow: 'auto' }}>
                    {mailList?.results?.map((mail, index) => (
                        <MailItem
                            key={mail?.id}
                            mail={mail}
                            selected={selectedEmailId === mail.id}
                            onClick={() => handleCLickMailItem({ mail, index })}
                            mailType={mailType}
                            practiceId={practiceId}
                            draftMailDelete={() => draftMailDelete(mail)}
                            isFromSideBar={isFromSideBar}
                        />
                    ))}
                </Box>
            );
        }
        if (mailList?.count === 0) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '0 20px',
                            flexDirection: 'column'
                        }}>
                        <img
                            src='/assets/no-script.png'
                            alt='no-appointment'
                            style={{
                                maxHeight: '160px',
                                objectFit: 'contain'
                            }} />
                        <Typography sx={noScriptTextStyle}> No Data</Typography>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    const renderList = (
        <Card
            sx={renderListCardStyle}>
            <NormalLoading
                tableData={mailList?.results?.length === 0 && 'No Found'}
                pageCount={mailList?.count === null && 'No Found'}
            />
            {renderListOrNoData()}
        </Card>
    );

    const renderMailDetails = (
        <Box sx={{ width: '100%' }}>
            {
                isLoading ?
                    <NormalLoading
                        tableData={isLoading && 'No Found'}
                        pageCount={isLoading && 'No Found'}
                    /> :
                    <MailDetails
                        mail={mailType === 'Drafts' ? draftsMessage : emailMessages?.results}
                        practiceId={practiceId}
                        mailType={mailType}
                        selectedMailId={selectedEmailId}
                        leadDetail={leadDetail}
                        successDone={successDone}
                        isFromSideBar={isFromSideBar}
                        handleBackClicked={handleBackClicked}
                        onStarClick={handleStarClick}
                        handleMarkReadEvent={handleMarkReadEvent}
                    />
            }
        </Box>
    );

    const renderView = () => (
        <>
            <Box sx={viewBoxStyle}>
                <Box sx={viewListBoxStyle(mailList?.results?.length > 0)}>
                    {renderList}
                </Box>
                {mailList?.results?.length > 0 && <Box sx={viewMailDetailBoxStyle}>
                    <NormalLoading
                        tableData={mailList?.results?.length === 0 && 'No Found'}
                        pageCount={mailList?.count === null && 'No Found'}
                    />
                    {mailList?.results?.length > 0 && renderMailDetails}
                    {renderMailDetailsNoData()}
                </Box>}
            </Box>
        </>
    );

    return (<>
        <Box sx={{ position: 'relative', height: '100%' }}>
            {renderView()}
        </Box>
    </>);
};

MailView.propTypes = {
    mailList: PropTypes.object,
    mailType: PropTypes.string,
    leadDetail: PropTypes.object,
    isLoading: PropTypes.bool,
    handleBackClicked: PropTypes.func,
    successDone: PropTypes.func,
    isFromSideBar: PropTypes.bool,
};

export default MailView;