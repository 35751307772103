import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';

const ClinCheckOverViewTab = ({ onChangeTab }) => (
    <Box>
        <Box sx={{ mb: 3 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>ClinCheck Status</Typography>
        </Box>
        <Box>
            <Button
                color="primary"
                variant='contained'
                type='submit'
                onClick={() => onChangeTab('', 'ClinCheck Status')}
            >
                Check ClinCheck status
            </Button>
        </Box>
    </Box>
);

export default ClinCheckOverViewTab;

ClinCheckOverViewTab.propTypes = {
    onChangeTab: PropTypes.func,
};