import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// use mui
import { Box } from '@mui/material';

export default function Logo({ disabledLink = false, sx }) {
    const logo = (
        <Box
            component="img"
            src="/logo/logo_single.svg"
            sx={{ width: 40, height:40, cursor: 'pointer', ...sx }}
        />
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};