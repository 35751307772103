import React from 'react';
import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import { Box, Button, Typography, IconButton } from '@mui/material';
// other libraries
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
// hooks
import { useNotificationsV2 } from '../../hooks';
// redux
import { getTime } from '../../redux/slices/toDoNotifications';
// components
import { Iconify, Scrollbar } from '../index';
// style
import { common } from '../../theme/palette';
import {
    notificationContentWrapper,
    notificationIconWrapper,
    notificationMessageWrapper,
    notificationWrapper,
} from '../../styles/toDoNotificationSTygle';

export default function ReminderNotifications({ setOpenPopover }) {
    const { notifications, handleSnooze, handleNavigateToLeadDetail, handleCloseNotiFications } = useNotificationsV2();

    const getColor = (HumanReadable) => {
        if (HumanReadable?.includes('ago')) {
            return 'Red';
        }
        return 'green';
    };

    const handleNavigate = (id, leadId) => {
        setOpenPopover(false);
        handleNavigateToLeadDetail(id, leadId);
    };

    const renderNotification = (
        <Box>
            {isArray(notifications) && !isEmpty(notifications) ?
                notifications?.map((ele, index) => (
                    <>
                        <Box key={index} sx={notificationWrapper}>
                            <Box sx={notificationContentWrapper}>
                                <Box sx={notificationIconWrapper}>
                                    <Iconify icon={'material-symbols:alarm-outline'} width={24} height={24} color={common.scarletBlaze} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Box sx={notificationMessageWrapper}>
                                        <Box>
                                            <Typography sx={{ fontSize: '14px' }}>{ele.method} <b>{ele.firstName || ''} {ele.lastName || ''}</b> about {ele.treatment || ''}</Typography>
                                            <Typography sx={{ fontSize: '14px', color: getColor(ele.HumanReadable) }}>{getTime(ele.dateTime)}</Typography>
                                        </Box>
                                        <Box>
                                            <IconButton onClick={(e) => { handleCloseNotiFications(ele.id, e); }}>
                                                <Iconify icon={'ci:close-big'} width={20} height={20} color='common.grey' />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box sx={{ mt: '8px', display: 'flex', gap: 1 }}>
                                        <Button variant="outlined" sx={{ minWidth: '130px', fontSize: '13px' }}
                                            onClick={() => {
                                                const data = {
                                                    id: ele.id,
                                                    leadId: ele?.leadId,
                                                    message: ele.message,
                                                    dateTime: ele.dateTime,
                                                    HumanReadable: ele.HumanReadable,
                                                    firstName: ele.firstName,
                                                    lastName: ele.lastName,
                                                    method: ele.method,
                                                    treatment: ele.treatment,
                                                    snoozeTime: moment().add(10, 'minutes').toISOString(),
                                                    isSnooze: true
                                                };
                                                handleSnooze(data, handleCloseNotiFications);
                                            }}>Snooze</Button>
                                        <Button variant="contained" sx={{ minWidth: '130px', fontSize: '13px' }}
                                            onClick={() => handleNavigate(ele.id, ele?.leadId)}>Open task</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box></>
                )) : <Box sx={{ mt: '40px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <Iconify icon={'material-symbols:alarm-outline'} width={86} height={86} color='#919EAB' />
                    </Box>
                    <Typography variant="subtitle2" sx={{ fontSize: '20px', fontWeight: 700, textAlign: 'center' }} >
                        No new reminders
                    </Typography>
                </Box>
            }
        </Box>
    );

    return (
        <>
            <Scrollbar>
                <Stack spacing={3} sx={{ p: 2 }}>
                    {renderNotification}
                </Stack>
            </Scrollbar>
        </>
    );
};

ReminderNotifications.propTypes = {
    setOpenPopover: PropTypes.func
};
