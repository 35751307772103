import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Stack, Box, Button, Table, TableBody, TableContainer, Typography } from '@mui/material';
// component
import { Iconify } from '../../../components';
import { TableHeadCustom } from '../../../components/table';
import { NotFound } from '../../../components/empty-content';
import { TableLoading } from '../../../components/loading';
// hooks
import { useTable } from '../../../hooks';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import { getLeadPaymentCheckout } from '../../../redux/slices/lead';
//
import CheckoutTableRow from './checkoutTableRow';
// style
import {
    CheckoutBoxStyle,
    BackButtonStyle,
    TitleStyle,
    PaymentTableStyle
} from '../../../styles/PaymentRefund';
// constant
import { checkoutTableLabels } from '../../../constants/payment';

// ----------------------------------------------------------------------------------------------------------
export default function CheckoutSessionList({ practiceId, leadId, treatmentId, paymentId, handleRefundClick, setRenderComponent }) {
    const { checkoutListing : { results, count } } = useSelector((state) => state.lead);

    const {
        selected,
        onSort,
        onSelectRow,
    } = useTable();

    useEffect(() => {
        if (paymentId) {
            dispatch(getLeadPaymentCheckout(practiceId, leadId, treatmentId, paymentId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentId]);

    const handleBack = () => {
        setRenderComponent('paymentList');
    };

    return (
        <Stack>
            <Box sx={{ position: 'relative' }}>
                <Box sx={CheckoutBoxStyle}>
                    <Button
                        color="inherit"
                        variant='outlined'
                        onClick={handleBack}
                        className="back-button"
                        sx={BackButtonStyle}
                    >
                        <Iconify icon={'material-symbols:arrow-back-rounded'} width={18} height={18} />
                    </Button>
                    <Typography variant="h5" sx={TitleStyle}> Checkout summary</Typography>
                </Box>
                <TableLoading tableData={!results && 'No Found'} pageCount={count !== undefined ? 0 : 'No Found'}/>
                {results?.length > 0 &&
                    <TableContainer sx={PaymentTableStyle}>
                        <Table sx={{ minWidth: { xs: 'auto', sm: 'auto' } }}>
                            <TableHeadCustom
                                headLabel={checkoutTableLabels}
                                onSort={onSort}
                                rowCount={results.length}
                                numSelected={selected.length}
                            />
                            <TableBody>
                                {results !== undefined && results.map((row, index) => (
                                    <Box sx={{ display: 'contents' }} key={index}>
                                        <CheckoutTableRow
                                            row={row}
                                            selected={selected.includes(row.id)}
                                            onSelectRow={() => onSelectRow(row.id)}
                                            handleRefundClick={handleRefundClick}
                                        />
                                    </Box>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {results?.length === 0 && <NotFound />}
            </Box>
        </Stack>
    );
}

CheckoutSessionList.propTypes = {
    practiceId: PropTypes.any,
    leadId: PropTypes.any,
    treatmentId: PropTypes.any,
    paymentId: PropTypes.any,
    setRenderComponent: PropTypes.any,
    handleRefundClick: PropTypes.any,
};