import React, { useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Box, Button, Tooltip } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { isArray } from 'lodash';
import { useParams } from 'react-router';
// components
import CustomConfirmationDialog from '../../../pages/dashboard/tables/AllLeadTable/CustomConfirmationDialog';
import DepositPaymentPopover from '../../../pages/dashboard/tables/AllLeadTable/DepositPaymentPopover';
import { Iconify } from '../../../components';
import PaymentTypeCell from '../../../components/treatmentPaymentComponents/PaymentTypeCell';
import PaymentProcessorCell from '../../../components/treatmentPaymentComponents/PaymentProcessorCell';
import PaymentDateCell from '../../../components/treatmentPaymentComponents/PaymentDateCell';
import PaymentAmountCell from '../../../components/treatmentPaymentComponents/PaymentAmountCell';
import PaymentActionsCell from '../../../components/treatmentPaymentComponents/PaymentActionsCell';
import { EmptyContent } from '../../../components/empty-content';
// redux
import { dispatch, useSelector } from '../../../redux/store';
import { getTreatmentPayment, setDepositLoading } from '../../../redux/slices/deposit';
import { followUpSlice } from '../../../redux/slices/lead';
import { getPracticeId } from '../../../redux/slices/clincheck';
// hooks/utils
import { useLeadTableHelper, useToggle } from '../../../hooks';
import { handleDeletePaymentMethods } from '../../../utils/depositUtils';
// style
import { primary } from '../../../theme/palette';
import { treatmentTabToolTip } from '../../../styles/treatmentTabStyle';
import { leadDetailTabsGridStyle } from '../../../styles/NewLead';
import { paymentTableStyle } from '../../../styles/treatmentPaymentTableStyle';

const TreatmentDepositPayment = ({ treatmentId, leadId }) => {

    const [menuCurrentTarget, setMenuCurrentTarget] = useState(null);
    const [openMenu, setOpenMenuActions] = useState(null);
    const [anchorDepositPayment, setAnchorDepositPayment] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const { depositPaymentDataGlobal, depositLoading } = useSelector((state) => state.deposit);
    const practiceId = getPracticeId();
    const { id } = useParams();

    const { getSortedDepositPaymentData } = useLeadTableHelper();

    const paymentDeleteToggle = useToggle();

    const {
        toggle: openDepositPaymentPopup,
        onOpen: onOpenDepositPaymentPopup,
        onClose: onCloseDepositPaymentPopup,
    } = useToggle();

    const handleOpenMoreMenu = (event, params) => {
        setMenuCurrentTarget(event.currentTarget);
        setOpenMenuActions(params.row.id);
    };

    const handleCloseMoreMenu = () => setOpenMenuActions(null);

    const onEditPayment = (event) => {
        onOpenDepositPaymentPopup();
        setAnchorDepositPayment(event.currentTarget);
        setSelectedRow(getSortedDepositPaymentData(depositPaymentDataGlobal || []));
    };

    const onDeletePayment = () => paymentDeleteToggle.onOpen();

    const columns = [
        { ...PaymentTypeCell() },
        { ...PaymentProcessorCell() },
        { ...PaymentDateCell() },
        { ...PaymentAmountCell() },
        { ...PaymentActionsCell({ openMenu, menuCurrentTarget, onOpen: handleOpenMoreMenu, onClose: handleCloseMoreMenu, onEditPayment, onDeletePayment }) }
    ];

    const handleDepositPaymentOnclose = () => {
        dispatch(getTreatmentPayment({ leadId, treatmentId }));
        dispatch(setDepositLoading(false));
        onCloseDepositPaymentPopup();
        paymentDeleteToggle.onClose();
        handleCloseMoreMenu();
        dispatch(followUpSlice(id, practiceId, 'LeadDetail'));
    };

    const handleAddDepositPayment = (event) => {
        setSelectedRow(null);
        onOpenDepositPaymentPopup();
        setAnchorDepositPayment(event.currentTarget);
    };

    return (
        <Box style={{ width: '100%', height: '100%' }}>
            {isArray(depositPaymentDataGlobal) && depositPaymentDataGlobal.length === 0 && <Box sx={{ pb: 2, display: 'flex', justifyContent: 'end' }}>
                <Tooltip title='Add payment for this treatment'>
                    <Button variant='contained' onClick={handleAddDepositPayment} sx={{ ...treatmentTabToolTip, bgcolor: primary.main, }}>
                        <Iconify sx={{ mr: '8px' }} icon={'eva:plus-fill'} width={20} height={20} /> Add payment
                    </Button>
                </Tooltip>
            </Box>}
            <DataGridPro
                rows={getSortedDepositPaymentData(depositPaymentDataGlobal || [])}
                columns={columns}
                initialState={{ pinnedColumns: { right: ['actions'] }, }}
                getRowHeight={() => 'auto'}
                disableRowSelectionOnClick
                disableColumnFilter
                sx={{ ...leadDetailTabsGridStyle(depositLoading), ...paymentTableStyle }}
                className={'leads-datagrid-table treatment-payment-table'}
                autoHeight
                keepNonExistentRowsSelected
                filterMode={false}
                sortingMode={false}
                slots={{
                    noRowsOverlay: () => <EmptyContent title='No Data' />,
                    noResultsOverlay: () => null,
                }}
            />
            {openDepositPaymentPopup && (
                <DepositPaymentPopover
                    rowData={{ treatment_id: treatmentId, lead_id: leadId, deposit_payment_data: selectedRow }}
                    open={openDepositPaymentPopup}
                    anchorEl={anchorDepositPayment}
                    handleClickAwayLister={handleDepositPaymentOnclose}
                />
            )}
            <CustomConfirmationDialog
                toggle={paymentDeleteToggle.toggle}
                handleClose={() => paymentDeleteToggle.onClose()}
                handleSubmit={() => handleDeletePaymentMethods({ deposit_payment_data: getSortedDepositPaymentData(depositPaymentDataGlobal || []), lead_id: leadId }, handleDepositPaymentOnclose)}
                message={'Are you sure you want to delete this payment?'}
            />
        </Box>
    );
};

export default TreatmentDepositPayment;

TreatmentDepositPayment.propTypes = {
    treatmentId: PropTypes.number,
    leadId: PropTypes.number,
};