import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import { useAuth } from '../hooks';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import { LoadingScreen } from '../components/loading';

// ----------------------------------------------------------------------

export default function GuestGuard({ children }) {
    const { isAuthenticated, isInitialized } = useAuth();
    const { search } = useLocation();

    const defaultRoute = search?.trim()?.split('?next=');

    const SearchQuery = defaultRoute.length > 1 ? `${defaultRoute[1]}` : '';

    if (isAuthenticated) {
        return <Navigate to={SearchQuery || PATH_DASHBOARD.root } />;
    }

    if (!isInitialized) {
        return <LoadingScreen />;
    }

    return <>{children}</>;
}

GuestGuard.propTypes = {
    children: PropTypes.node,
};