import { grey } from 'src/theme/palette';

export const unableToContactBoxFirstStyle = {
    padding: '20px 0',
    cursor: 'pointer',
    margin: '0'
};
export const unableToContactTitleStyle = {
    marginBottom: '30px',
    pb: '15px',
    fontWeight: '700',
    fontSize: '24px',
    color: '#212B36',
};
export const notesTitleStyle = {
    marginBottom: '10',
    pb: 0,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
};
export const unableToContactBoxSecStyle = {
    padding: '0 10px',
    cursor: 'pointer',
    margin: '5px'
};
export const unableToContactBoxThirdStyle = {
    padding: '20px 10px',
    cursor: 'pointer',
    margin: '5px',
    position: 'relative'
};
export const sendEmailBoxStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
};
export const sendEmailBoxCountStyle = email => ({
    background: email ? '#cbcbcb' : 'none',
    border: '1px solid #cbcbcb',
    padding: '0px 6px',
    marginRight: '10px',
    borderRadius: '5px',
    fontSize: '15px'
});
export const sendEmailBoxCountSecStyle = reminder => ({
    background: reminder ? '#cbcbcb' : 'none',
    border: '1px solid #cbcbcb',
    padding: '0px 6px',
    marginRight: '10px',
    borderRadius: '5px',
    fontSize: '15px'
});
export const sendEmailTitleStyle = {
    marginBottom: '10',
    pb: 0,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between !important',
    cursor: 'pointer',
    width: '100%'
};
export const noteBoxStyle = (selectedOptions, note) => ({
    color: selectedOptions.includes(note.value) ? '#fff' : '#1890FF',
    bgcolor: selectedOptions.includes(note.value) ? '#1890FF' : 'transparent',
    border: '1px solid #1890FF',
    borderRadius: '50px',
    p: '4px 12px',
    width: 'auto',
    display: 'inline-flex',
    m: '0 8px 8px 0'
});
export const saveNoteButtonStyle = {
    textTransform: 'none !important',
    p: '8px 16px',
};
export const emailSuccessBoxStyle = {
    margin: '0px',
    background: '#E9FCD4',
    borderRadius: 1,
    p: 1,
    fontSize: '16px',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center'
};
export const emailSuccessIconBoxStyle = {
    width: 24,
    height: 24,
    borderRadius: '12px',
    background: '#54D62C29',
    mr: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};
export const contactOptionBoxInnerStyle = {
    backgroundColor: '#F4F6F8',
    p: 2,
    borderRadius: 2,
    mb: 1,
    mt: 1,
    width: '100%',
    display: 'inline-block',
    breakInside: 'avoid'
};
export const optionBoxStyle = {
    height: 48,
    backgroundColor: '#00A76F14',
    display: 'flex',
    borderRadius: 1,
    p: '11px 16px',
    alignItems: 'center',
    float: 'left',
    color: '#007867',
    mb: '12px',
    position: 'relative',
    pl: '40px'
};
export const noteMainBoxStyle = {
    display: 'block',
    bgcolor: grey[200],
    borderRadius: 1,
    position: 'relative',
};
export const openTaskPopupStyle = {
    border: '1px dashed rgba(145, 158, 171, 0.24)',
    borderRadius: '100px',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: grey[200]
};