import { reset as activity } from '../redux/slices/activity';
import { reset as afterLogin } from '../redux/slices/afterLogin';
import { reset as analytics } from '../redux/slices/analytics';
import { reset as autoReminder } from '../redux/slices/autoReminder';
import { reset as calendar } from '../redux/slices/calendar';
import { reset as forgotpassword } from '../redux/slices/forgotpass';
import { reset as kanban } from '../redux/slices/kanban';
import { reset as lead } from '../redux/slices/lead';
import { reset as mail } from '../redux/slices/mail';
import { reset as practice } from '../redux/slices/practice';
import { reset as practiceIntegration } from '../redux/slices/practiceIntegration';
import { reset as practiceMessage } from '../redux/slices/practiceMessage';
import { reset as practiceTreatment } from '../redux/slices/practiceTreatment';
import { getPracticeTreatmentData } from '../redux/slices/practiceTreatmentList';
import { reset as practiceUser } from '../redux/slices/practiceUser';
import { reset as resetPassword } from '../redux/slices/resetPassword';
import { reset as singlePractice } from '../redux/slices/singlePractice';
import { reset as tagsCreate } from '../redux/slices/tagsCreate';
import { reset as user } from '../redux/slices/user';
import { dispatch } from '../redux/store';

// ------------------------------------------------

const ClearStore = () => {
    dispatch(activity());
    dispatch(afterLogin());
    dispatch(analytics());
    dispatch(autoReminder());
    dispatch(calendar());
    dispatch(forgotpassword());
    dispatch(kanban());
    dispatch(lead());
    dispatch(mail());
    dispatch(practice());
    dispatch(practiceIntegration());
    dispatch(practiceMessage());
    dispatch(practiceTreatment());
    dispatch(getPracticeTreatmentData({results: []}));
    dispatch(practiceUser());
    dispatch(resetPassword());
    dispatch(singlePractice());
    dispatch(tagsCreate());
    dispatch(user());
};

export default ClearStore;