import { createSlice } from '@reduxjs/toolkit';

// utils

import { callApi } from '../../utils/apiCall';
import { updateError } from './user';

//
import { dispatch } from '../store';

// ------------------------------

const initialState = {
    isLoading: false,
    activityList: {
        results: [],
        count: 0,
    }
};

const slice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setActivities(state, action) {
            state.activityList = action.payload;
        },
        setActivitiesMoreData(state, action) {
            state.activityList.results = [
                ...state.activityList.results,
                ...action.payload.results
            ];
            state.activityList.count = action.payload.count;
        },
    }
});

export default slice.reducer;

export const { setActivities, reset } = slice.actions;

export function getActivities(practiceId, id, limit, offset) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/activities/?limit=${limit}&offset=${offset}`);
            if (response.status === 200) {
                if (offset === 0) {
                    dispatch(setActivities(response.data));
                    return;
                }
                dispatch(slice.actions.setActivitiesMoreData(response.data));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

