import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { updateError } from './user';
import { dispatch } from '../store';
import { getPracticeId } from './clincheck';

const initialState = {
    loadingForWpTemplate: false,
    wpTemplateListGlobal: null,
    showWpSubjectListGlobal: false,
};

const slice = createSlice({
    name: 'whatsappMessages',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        setLoadingForWpTemplate(state, action) {
            state.loadingForWpTemplate = action.payload;
        },
        setWpTemplateListGlobal(state, action) {
            state.wpTemplateListGlobal = action.payload;
        },
        updateShowWpSubjectList(state, action) {
            state.showWpSubjectListGlobal = action.payload;
        },
    }
});

export default slice.reducer;

export const { reset, updateShowWpSubjectList, setWpTemplateListGlobal } = slice.actions;

export const handleLoading = (state) => {
    if (state === false) {
        setTimeout(() => {
            dispatch(slice.actions.setLoadingForWpTemplate(state));
        }, 500);
    } else {
        dispatch(slice.actions.setLoadingForWpTemplate(state));
    }
};

const getQuery = (data) => {
    let query = '';
    if (data.search) {
        query += `?query=${data.search}`;
    }
    return query;
};

export function getSearchWpTemplateList(data) {
    const { wpAccountId } = data;
    return async () => {
        dispatch(slice.actions.updateShowWpSubjectList(true));
        try {
            handleLoading(true);
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/whatsapp-business-accounts/${wpAccountId}/whatsapp-message-templates/${getQuery(data)}`);
            if (response.status === 200) {
                dispatch(slice.actions.setWpTemplateListGlobal(response.data.results));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
            handleLoading(false);
        } catch (error) {
            dispatch(updateError(error));
            handleLoading(false);
        }
    };
}

export function updateWpContextMap(payload) {
    return async () => {
        try {
            const { wpAccountId, context, id } = payload ?? {};
            const practiceId = getPracticeId();
            const reqBody = {
                template_context_mapping: context
            };
            const response = await callApi(`/api/v1/practices/${practiceId}/whatsapp-business-accounts/${wpAccountId}/whatsapp-message-templates/${id}/`, 'PATCH', reqBody);
            if (response.status === 200) {
                dispatch(getSearchWpTemplateList({ wpAccountId }));
            }
        } catch (err) {
            console.error(err);
        }
    };
}

export function get10WpTemplateList({ wpAccountId }) {
    return async () => {
        const practiceId = getPracticeId();
        dispatch(slice.actions.updateShowWpSubjectList(true));
        try {
            handleLoading(true);
            const response = await callApi(`/api/v1/practices/${practiceId}/whatsapp-business-accounts/${wpAccountId}/whatsapp-message-templates/`);
            handleLoading(false);
            if (response.status === 200) {
                dispatch(slice.actions.setWpTemplateListGlobal(response.data.results));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError(error));
            handleLoading(false);
        }
    };
}