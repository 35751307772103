const getAssigneeList = (list) => {
    if (list && list?.length > 0) {
        return list.filter(el => el?.is_enabled);
    }
    return [];
};

const FilterAssigneeList = (userList) => {
    const list = getAssigneeList(userList);
    const data = [];
    if (list && list?.length > 0) {
        list?.forEach((el) => {

            const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

            const fullName = `${capitalize(el?.user?.salutation || '')} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`.trim();

            data.push({
                name: fullName,
                id: el?.user?.id,
                role: (el?.role === "TCO" || el?.role === "USER") ? "TCO/USER" : el?.role,
                avatar: el?.user?.avatar,
            });
        });
    }
    return data?.sort((a, b) => {
        const textA = a?.role?.toUpperCase();
        const textB = b?.role?.toUpperCase();
        // eslint-disable-next-line no-nested-ternary
        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
    });;
};

export default FilterAssigneeList;

