import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { callApi } from '../../utils/apiCall';

// ----------------------------------------------------------------

const initialState = {
    emailsCounter: ''
};

export const slice = createSlice({
    name: 'emailCounter',
    initialState,
    reducers: {
        setEmailsCounter(state, action) {
            state.emailsCounter = action.payload;
        },
    }
});

export const { setEmailsCounter } = slice.actions;

export default slice.reducer;

export function emailCounter(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/?fields={}&with_replies=${true}&is_unread=${true}`);
            if (response.status === 200) {
                dispatch(slice.actions.setEmailsCounter(response.data));
            }
            // dispatch(slice.actions.endLoading());
        } catch (error) {
            // dispatch(slice.actions.endLoading());
            dispatch(slice.actions?.hasError?.(error));
        }
    };
}