import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// 
import AddAppointmentBooked from '../AddAppointmentBooked';

const AddAppointmentBookedPopup = ({ treatmentId, handleClose, leadId }) => (
    <Box>
        <AddAppointmentBooked treatmentId={treatmentId} handleClose={handleClose} type={'RescheduleReminder'} leadId={leadId} />
    </Box>
);

export default AddAppointmentBookedPopup;

AddAppointmentBookedPopup.propTypes = {
    treatmentId: PropTypes.number,
    handleClose: PropTypes.func,
    leadId: PropTypes.number,
};
