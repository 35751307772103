/* eslint-disable no-nested-ternary */
export const btnStyle = (theme) => ({
    height: 48,
    border: `solid 1px ${theme.palette.primary.btn}`,
    borderRadius: 1,
    px: 2,
    width: '100%',
    '[placeholder="To"]': {
        bgcolor: `${theme.palette.common.lightGrey}`,
        borderRadius: 1,
        p: '5px 8px',
        width: 'auto',
        maxWidth: '100%',
        m: 0,
        fontSize: '13px',
        pointerEvents: 'none',
    }
});

export const editStyle = (fullScreen) => ({
    '& .ql-editor': {},
    ...(fullScreen?.value && {
        height: 1,
        '& .quill': {
            height: 1,
        },
        '& .ql-editor': {
            maxHeight: 'unset',
        },
    }),
    '.ql-snow .ql-tooltip': {
        left: '0 !important'
    }
});

export const paperStyle = (ZINDEX, fullScreen, POSITION, isFromDrafts, isFromSideBar, isFromUnableToContact) => (
    {
        right: 0,
        bottom: 0,
        borderRadius: '12px',
        display: 'flex',
        zIndex: ZINDEX + 1,
        m: '0',
        overflow: 'hidden',
        flexDirection: 'column',
        boxShadow: 'none',
        border: isFromUnableToContact ? '1px solid #919EAB1F' : '1px solid transparent',
        width: isFromDrafts || isFromUnableToContact ? '100%' : isFromSideBar === 'true' ? 'calc(100% - 216px)' : 'calc(100% - 216px)',
        mt: isFromSideBar === 'true' ? '0' : '43px',
        ...(fullScreen.value && {
            m: 0,
            right: POSITION / 2,
            bottom: POSITION / 2,
            width: `calc(100% - ${POSITION}px)`,
            height: `calc(100% - ${POSITION}px)`,
        }),
    }
);

export const editorBoxBtnStyle = {
    minWidth: 'auto',
    height: '36px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px',
    marginRight: '15px',
    color: 'grey.700'
};

export const toTypoStyle = {
    mr: '12px',
    p: '5px 12px',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'common.borderColor',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700
};

export const emailBoxStyle = {
    p: '8px 24px 8px 4px',
    borderBottom: '1px solid',
    borderColor: 'primary.paperColor',
    width: '100%'
};

export const emailTypoStyle = {
    border: '1px solid #919EAB3D',
    borderColor: 'primary.paperColor',
    padding: '4px 8px',
    borderRadius: '8px',
    fontSize: '13px',
    display: 'flex',
    width: 'max-content',
    fontWeight: 500
};

export const starButtonStyle = {
    padding: '0 4px',
    minWidth: 'auto',
    height: 'auto',
    background: 'none !important'
};

export const viewBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
    height: '100%'
};

export const viewListBoxStyle = (value) => ({
    display: 'flex',
    width: value ? '320px' : '100%',
    minWidth: '320px',
    borderRadius: '12px',
    bgcolor: 'common.white',
    p: 2,
    position: 'relative'
});

export const viewMailDetailBoxStyle = {
    display: 'flex',
    width: '100%',
    borderRadius: '12px',
    bgcolor: 'common.white',
    overflow: 'hidden',
    overflowY: 'auto',
    position: 'relative'
};

export const mailAccordionStyle = {
    '&:before': {
        display: 'none'
    },
    borderTop: 'solid 1px rgba(145, 158, 171, 0.2)',
    borderRadius: '0 !important',
    m: '0 !important',
    boxShadow: 'none !important'
};

export const mailAccordionSummaryStyle = {
    p: 0,
    '.MuiAccordionSummary-content': {
        width: '100%',
        wordBreak: 'break-all',
        maxWidth: 'calc(100% - 36px)'
    }
};

export const mailLabelWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.2)',
    minHeight: '56px'
};

export const emailTemplateBoxStyle = {
    height: 330,
    width: '100%',
    position: 'absolute',
    borderRadius: 2,
    p: 2,
    boxShadow: '0px 8px 16px #919EAB3D',
    border: '1px solid',
    borderColor: 'primary.paperColor',
    overflow: 'auto',
    zIndex: '99999',
    bgcolor: 'common.white',
    '.MuiBox-root': {
        maxHeight: '100%'
    }
};

export const focusUnableToContactBoxStyle = {
    width: '100%',
    overflow: 'auto',
    m: '0px 0 30px',
    position: 'relative'
};

export const noDataMainBoxStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    p: 2,
    width: '100%'
};

export const noDataMainBoxInnerStyle = {
    display: 'flex',
    padding: '0 20px',
    flexDirection: 'column'
};

export const noDataImageStyle = {
    maxHeight: '160px',
    objectFit: 'contain'
};

export const leadDetailsLinkBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '0 12px'
};

export const linkIconStyle = {
    cursor: 'pointer',
    width: '28px',
    height: '28px'
};

export const whatsappPaperStyle = (ZINDEX, fullScreen, POSITION) => (
    {
        right: 0,
        bottom: 0,
        borderRadius: '12px',
        display: 'flex',
        zIndex: ZINDEX + 1,
        m: '0',
        overflow: 'hidden',
        flexDirection: 'column',
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'common.lightGrey',
        width: '100%',
        mt: '16px',
        ...(fullScreen.value && {
            m: 0,
            right: POSITION / 2,
            bottom: POSITION / 2,
            width: `calc(100% - ${POSITION}px)`,
            height: `calc(100% - ${POSITION}px)`,
        }),
    }
);

export const inputBaseStyle = (theme) => ({
    height: 48,
    px: 2,
    width: '100%',
    bgcolor: 'common.lightGrey',
    borderRadius: 2,
    border: 'none',
    '[placeholder="To"]': {
        bgcolor: `${theme.palette.common.lightGrey}`,
        borderRadius: 1,
        p: '5px 8px',
        width: 'auto',
        maxWidth: '100%',
        m: 0,
        fontSize: '13px',
        pointerEvents: 'none',
    }
});

export const renderListCardStyle = {
    boxShadow: 'none',
    borderRadius: 0,
    position: 'relative',
    width: '100%'
};