export const taskMainbox = {
    display: 'flex',
    mb: '0',
    minHeight: '64px',
    width: '100%',
    border: '1px solid #919eab30',
    alignItems: 'center',
    justifyContent: 'flex-start' ,
    overflow: 'auto'
};
export const messageTypeTypo = (data) => ({
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '12px',
    color: '#637381',
    cursor: (data?.instance_object?.body !== '' && data?.instance_object?.body !== '<p><br></p>') ? 'pointer' : 'auto'
});