import React from 'react';
import { Box, IconButton, Tooltip, Zoom } from '@mui/material';
import { isEmpty } from 'lodash';
import { Iconify } from 'src/components';
import { generateLabel } from 'src/utils/getReferralData';
import { getCharAtName } from 'src/utils/getCharAtName';
import { common } from 'src/theme/palette';
import { assignMainBoxStyle, centerAlign, depositPaymentWrapperStyle, paymentChipStyle, paymentChipTypeStyle } from 'src/styles/NewLeadTableRowStyle';

const CustomDepositPaymentCell = (selectedRow, handleDeleteTreatmentPayment) => ({
    field: 'payment_method',
    headerName: 'Payment method',
    width: 180,
    minWidth: 160,
    sortable: false,
    renderCell: (params) => {

        const getPaymentType = (ele) => {
            if (!isEmpty(ele?.payment_processor)) return getCharAtName(ele?.payment_processor?.payment_processor);
            return getCharAtName(ele?.payment_type) || '';
        };
        const getPaymentTitle = (data) => {
            if (!isEmpty(data?.payment_processor)) return generateLabel(data?.payment_processor?.payment_processor);
            return generateLabel(data?.payment_type);
        };

        const handleClickPaymentMethod = (e) => selectedRow(params.row, 'deposit_payment', '', e);

        if (params?.row?.deposit_payment_data?.length > 0) {
            return <>
                <Box sx={depositPaymentWrapperStyle}
                    onClick={(e) => selectedRow(params.row, 'deposit_payment', '', e)}
                >
                    {params?.row?.deposit_payment_data.map((ele, index) =>
                        <Tooltip
                            key={index}
                            title={getPaymentTitle(ele)}
                            placement='bottom'
                            TransitionComponent={Zoom}
                            TransitionProps={{ timeout: 300 }}
                            style={centerAlign}
                        >
                            <Box sx={paymentChipStyle} >
                                <Box sx={paymentChipTypeStyle}>{getPaymentType(ele)}</Box> {`£ ${ele?.amount || 0.0}`}
                            </Box>
                        </Tooltip>
                    )}
                </Box>
                <Tooltip
                    title='Remove Payment'
                    placement='bottom'
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                    style={centerAlign}
                >
                    <IconButton onClick={() => handleDeleteTreatmentPayment(params?.row)}
                        onMouseDown={() => handleDeleteTreatmentPayment(params?.row)}
                        sx={{ color: 'info.dark' }}>
                        <Iconify height={24} width={24} icon='lets-icons:close-ring-duotone' />
                    </IconButton>
                </Tooltip>
            </>;
        }

        return <Box sx={assignMainBoxStyle} onClick={handleClickPaymentMethod}
            onMouseDown={handleClickPaymentMethod}>
            <IconButton
                aria-label='upload picture'
                component='label'
                sx={{ '&:hover': { bgcolor: 'transparent' } }}
            >
                <Iconify
                    icon='material-symbols:add-circle'
                    style={{ color: common.mintyZest }}
                    sx={{ mr: '0' }}
                    width={20}
                    height={20}
                />
            </IconButton>
        </Box>;
    }
});

export default CustomDepositPaymentCell;