import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, Dialog, DialogActions, DialogTitle, FormControlLabel, Switch, Typography } from '@mui/material';
import { Iconify } from '../../../components';
import { clearSimilarLeadsData, getSimilarDuplicateLeads, updateSelectedSimilarLeads } from '../../../redux/slices/duplicateLeads';
import { dispatch, useSelector } from '../../../redux/store';
import { useToggle } from '../../../hooks';
import SimilarLeadPopupContent from './SimilarLeadPopupContent';
import {
    infoIconBox,
    similarLeadViewCard,
    dynamicOuterBox,
    similarLeadsButton,
} from '../../../styles/OverView';

const SimilarLeadWarningPopup = ({ dialogClose, type }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [isArchiveChecked, setIsArchiveChecked] = useState(false);
    const {
        leadDetail, followLead
    } = useSelector((state) => state.lead);

    const {
        toggle: similarLeadOpen,
        onOpen: onSimilarLeadOpen,
        onClose: onCloseSimilarLead
    } = useToggle();

    const handleSimilarLeadsClose = () => {
        onCloseSimilarLead();
        dispatch(updateSelectedSimilarLeads({ leadIds: [] }));
        dispatch(clearSimilarLeadsData());
        if (type === 'DialogPopup') {
            dialogClose();
        }
    };

    const handleSimilarLeadPopupClose = () => {
        onCloseSimilarLead();
        dispatch(updateSelectedSimilarLeads({ leadIds: [] }));
        dispatch(clearSimilarLeadsData());
    };

    const handleArchiveFilter = (e) => {
        setIsArchiveChecked(e.target.checked);
        dispatch(updateSelectedSimilarLeads({ leadIds: [] }));
        if (practiceId) {
            const payload = {
                practiceId, limit: 10, offset: 0,
                needArchivedData: e.target.checked,
            };
            dispatch(getSimilarDuplicateLeads({ ...payload, leadId: leadDetail?.id || followLead?.id }));
        }
    };

    return <>
        {(leadDetail?.is_duplicate || followLead?.is_duplicate) && <Card sx={{ ...similarLeadViewCard, p: 3, display: 'block !important' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={{ ...infoIconBox, borderRadius: '12px', bgcolor: '#FF484229' }}>
                        <Iconify icon={'solar:users-group-rounded-bold-duotone'} sx={{ color: '#B72136' }} width={24} height={24} />
                    </Box>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Duplicate leads detected</Typography>
                </Box>
                <Box sx={{ ml: '12px' }}>
                    <Box sx={dynamicOuterBox}>
                        <Box>
                            <Button variant='outlined' sx={similarLeadsButton} onClick={() => onSimilarLeadOpen()} >
                                Compare duplicate leads <Iconify icon={'mdi:arrow-right-thin'} sx={{ color: '#33363F', ml: 1 }} width={20} height={20} />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>}
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={similarLeadOpen}
            onClose={handleSimilarLeadsClose}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <DialogTitle>Similar Leads</DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'end', m: '12px 24px' }}>
                    <FormControlLabel control={
                        <Switch checked={isArchiveChecked}
                            onChange={handleArchiveFilter}
                            inputProps={{ 'aria-label': 'controlled' }} />
                    } labelPlacement="start" label="Include Archived Data" />
                </Box>
            </Box>
            <SimilarLeadPopupContent
                checked={isArchiveChecked}
                leadId={leadDetail?.id || followLead?.id}
                handleCloseSimilarLeads={handleSimilarLeadsClose}
                overviewTab={'leadDetail'}
            />
            <DialogActions>
                <Button onClick={handleSimilarLeadPopupClose}>Close</Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default SimilarLeadWarningPopup;

SimilarLeadWarningPopup.propTypes = {
    dialogClose: PropTypes.func,
    type: PropTypes.string
};