import React from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { Label } from '../../../../../components';
import { EnquiryHeaderComponent } from '.';
import { useSelector } from '../../../../../redux/store';
import { dateOfEnquiryLabelStyle, dateTimeTypoStyle, tableCellInnerBoxStyle } from '../../../../../styles/NewLeadTableRowStyle';

const CreatedAtDateCell = ({
    handleChangeDateFormat,
    showDate,
    handleNavigate,
    formatData,
    typed,
    methods,
    setValue,
}) => {
    const { dateFormat } = useSelector((state) => state.lead);
    return {
        field: 'created_at',
        headerName: (
            <EnquiryHeaderComponent
                handleChangeDateFormat={handleChangeDateFormat}
                methods={methods}
                setValue={setValue}
            />
        ),
        width: 150,
        minWidth: 150,
        editable: false,
        renderCell: (params) => {
            const currentDate = moment().format('DD-MM-YYYY');
            return (
                <Box onClick={() => handleNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
                    <Label sx={dateOfEnquiryLabelStyle(formatData, params, currentDate, typed)}>
                        <Typography
                            variant='05 Others/Label'
                            dangerouslySetInnerHTML={{ __html: showDate(dateFormat, params?.row) }}
                            sx={dateTimeTypoStyle}
                        />
                    </Label>
                </Box>
            );
        },
    };
};

export default CreatedAtDateCell;