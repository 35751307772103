import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { TableRow, TableCell, MenuItem, MenuList, ListItemText, Tooltip } from '@mui/material';
// components
import { Iconify } from '../../../../components';
import { CustomAvatar } from '../../../../components/custom-avatar';
import { TableMoreMenu } from '../../../../components/table';
// style
import { notificationURL, tableAvatar } from '../../../../styles/Header';
// redux
import { useSelector } from '../../../../redux/store';
// utils
import { setLeadSourceIcon } from '../../../../utils/timelineIcon';

// ----------------------------------------------------------------------

export default function NotificationListRow({ row, selected, markAsRead }) {
    const [openMenu, setOpenMenuActions] = useState(null);
    const { referral } = useSelector((state) => state.schema);

    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleAvatar = (data) => {
        if (data?.verb === 'reminder_overdue') {
            return '/assets/icons/notification/reminder-overdue.png';
        }
        if (data?.verb === 'reminder_due_today') {
            return '/assets/icons/notification/task_due.png';
        }
        if (data?.verb === 'reminder_assigned_to_user') {
            return '/assets/icons/notification/assign_task.png';
        }
        if (data?.verb === 'phone_number_clicked') {
            return '/assets/icons/notification/call-in.png';
        }
        if (data?.verb === 'lead_assigned_to_user') {
            return '/assets/icons/notification/assign_lead.png';
        }
        if (data?.verb === 'new_lead') {
            return '/assets/icons/notification/user.png';
        }
        if (data?.verb === 'facebook_post_comment_created') {
            return '/assets/icons/notification/facebook.png';
        }
        return {};
    };

    const handleUrl = (data) => {
        if (data.action_link !== '') {
            navigate(`/dashboard/${data.action_link}`);
            if (data.unread) {
                markAsRead(data.id, data.unread);
            }
        }
    };

    const toolTipText = (row) => (
        <div style={{ lineHeight: '15px', whiteSpace: true, textAlign: 'center' }}>
            {row.verb.charAt(0).toUpperCase() + row.verb.slice(1).replace(/_/g, ' ')} <br />
            {row.verb === 'new_lead' && (`Resource referral : ${setLeadSourceIcon(row?.action_object?.lead_source?.referral, referral)?.label || 'not found'}`)}
        </div>
    );

    return (
        <TableRow hover selected={selected} sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)', bgcolor: row.unread && 'action.selected', borderRadius: '10px' }}>
            <TableCell align="left">
                <Tooltip title={toolTipText(row)}>
                    {!(row?.action_object?.lead_source?.referral) ? <CustomAvatar
                        src={handleAvatar(row)}
                        sx={tableAvatar(row?.action_link)}
                        onClick={() => handleUrl(row)}
                    /> :
                        <Iconify icon={setLeadSourceIcon(row?.action_object?.lead_source?.referral, referral)?.icon} sx={{ ...tableAvatar(row?.action_link), color: '#1890FF', p: '3px', borderRadius: '50px' }} width={40} height={40} onClick={() => handleUrl(row)} />
                    }
                </Tooltip>
            </TableCell>
            <TableCell align="left"
                onClick={() => handleUrl(row)}
                sx={notificationURL(row)}
            >
                {row.description}
            </TableCell>
            <TableCell align="left"
                onClick={() => handleUrl(row)}
                sx={notificationURL(row)}
            >
                {row.timestamp_human}
            </TableCell>
            <TableCell align="right" >
                <TableMoreMenu
                    open={openMenu}
                    sx={{ width: '300px' }}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                        <MenuList sx={{ padding: '0' }}>
                            <MenuItem
                                onClick={() => { markAsRead(row.id, row.unread); setOpenMenuActions(null); }}
                                sx={{ borderRadius: '8px', minHeight: '40px !important' }}
                            >
                                <Iconify
                                    icon={'eva:done-all-fill'}
                                    sx={{ color: row.unread ? 'warning.main' : '#43a047', mr: 0 }}
                                />
                                <ListItemText
                                    primary={row.unread ? 'Mark as read' : 'Mark as unread'}
                                    primaryTypographyProps={{ fontWeight: 600, fontSize: 14 }}
                                />
                            </MenuItem>
                        </MenuList>
                    }
                />
            </TableCell>
        </TableRow>
    );
}

NotificationListRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    markAsRead: PropTypes.func,
};
