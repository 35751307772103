import PropTypes from 'prop-types';
// @mui
import { Grid, Dialog, Container, Stack } from '@mui/material';
// component
import { ReviewLink } from '../../sections/@dashboard/leadDetail';
import { Transition } from '.';
// style
import { DialogBox, popUpStack } from '../../styles/ConsentBuilder';

const ReviewLinkPopUp = ({ open, onClose }) => (
    <Dialog
        TransitionComponent={Transition}
        open={open}
        sx={DialogBox()}
    >
        <Stack sx={popUpStack}>
            <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                <Container
                    maxWidth={'xl'}
                    sx={{ padding: '0 !important' }}
                >
                    <ReviewLink onClose={onClose} />
                </Container>
            </Grid>
        </Stack>
    </Dialog>
);

ReviewLinkPopUp.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default ReviewLinkPopUp;