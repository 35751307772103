import { styled } from '@mui/material/styles';
import { common } from 'src/theme/palette';

export const SelectedColor = styled('div')(() => ({
    position: 'absolute',
    left: '50px'
}));
export const ColorBoxStyle = styled('div')(() => ({
    width: '100%',
    height: 'auto',
    display: 'none',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
    position: 'relative',
    '& input': {
        width: 23,
        height: 23,
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        WebkitAppearance: 'none',
        backgroundColor: 'transparent',
        '&::-webkit-color-swatch-wrapper': { padding: 0 },
        '&::-moz-color-swatch': { border: 'none', borderRadius: '50%' },
        '&::-webkit-color-swatch': { border: 'none', borderRadius: '50%' },
    },
}));
export const tagsBoxStyle = (type) => ({
    width: '100%',
    height: type === 'table' ? '100%' : 'auto',
    padding: '0',
    maxWidth: '100%',
    marginLeft: '0',
    '@media (max-width: 900px)': {
        ml: '0',
        mb: type === 'table' ? '0' : '20px'
    }
});
export const tagsStyle = (type, isLight, tableType, length) => ({
    width: '100%',
    maxWidth: type === 'table' ? '300px' : '100%',
    maxHeight: type === 'table' ? '100%' : '100%',
    overflow: type === 'table' ? 'hidden' : 'auto',
    minWidth: '190px',
    border: type === 'leadDetail' ? 'none' : '2px solid transparent',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: type === 'leadDetail' ? 1 : 0,
    p: 0,
    // eslint-disable-next-line no-nested-ternary
    pb: type === 'leadDetail' && length > 0 ? '4px' : (type === 'leadDetail' ? (length <= 0 ? '12px' : 0) : 0),
    '.MuiAutocomplete-input': {
        width: tableType === 'ArchiveTable' && '0px !important',
        minWidth: tableType === 'ArchiveTable' && '0 !important',
        pointerEvents: tableType === 'ArchiveTable' ? 'none' : 'visible',
        maxWidth: '100%',
    },
    '.MuiOutlinedInput-notchedOutline': {
        display: 'none'
    },
    '&.Mui-focused': {
        border: type === 'leadDetail' ? `1px solid ${common.black}` : '1px solid transparent',
        p: type === 'leadDetail' ? '12px 12px 12px' : 0,
        // eslint-disable-next-line no-nested-ternary
        pb: type === 'leadDetail' && length > 0 ? '4px' : (type === 'leadDetail' ? (length <= 0 ? '12px' : 0) : 0),
    },
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: (isLight ? 'rgba(0,0,0,.1)' : 'rgba(255, 255, 255,.1)'),
        borderRadius: '10px',
    },
    '.MuiAutocomplete-endAdornment': {
        display: 'none'
    },
    '.MuiInputBase-input': {
        padding: '1px 4px 1px 0 !important'
    },
    '.MuiInputBase-root': {
        padding: '0px !important',
    },
    '[placeholder="+"]::placeholder': {
        width: '21px',
        height: '21px',
        background: 'url(/assets/plus-icon.png)',
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20px',
        fontSize: '0',
        position: 'absolute',
        verticalAlign: 'middle',
        textAlign: 'left',
        top: '2px',
        left: 0,
        right: 0,
        margin: 0
    },
    '&.Mui-focused [placeholder="+"]::placeholder': {
        background: 'none'
    },
    '&.Mui-focused [placeholder="+"]::-moz-placeholder': {
        background: 'none'
    },
    '.MuiSvgIcon-root': {
        display: (tableType === 'ArchiveTable' && 'none !important'),
    }
});

export const selectedTagStyle = () => ({
    padding: '8px',
    width: '100%'
});

export const allTagChipStyle = {
    '.MuiChip-root': {
        bgcolor: 'rgba(24, 144, 255, 0.16)',
        color: 'widget.tagBlueColor',
        height: '28px',
        margin: '0 3px'
    },
    '.MuiChip-root:hover': {
        bgcolor: 'rgba(24, 144, 255, 0.16)',
    },
    '.MuiChip-root svg': {
        color: 'widget.tagBlueColor'
    },
    '.MuiChip-root .MuiChip-label': {
        px: '8px'
    }
};

// searchbar

export const InputTags = () => ({
    margin: '2px 2px',
    padding: '0',
    height: 'auto',
    fontSize: '11px',
    ' .MuiChip-label': {
        padding: '0.5px 8px',
        fontSize: '14px'
    },
    ' .MuiSvgIcon-root': {
        fontSize: '16px',
    }
});

export const leadDetailsChipStyle = {
    margin: '0 8px 8px 0',
    padding: '4px',
    height: 'auto',
    fontSize: '11px',
    bgcolor: 'common.lightGrey',
    color: 'common.slateGray',
    ' .MuiChip-label': {
        padding: '0.5px 8px',
        fontSize: '13px'
    },
    ' .MuiSvgIcon-root': {
        fontSize: '16px',
    }
};

export const searchTagsStyle = (isLight) => ({
    width: '100%',
    borderRadius: '8px',
    maxWidth: '100%',
    maxHeight: '72px',
    minWidth: '170px',
    '@media (max-width: 600px)': {
        minWidth: '90px',
    },
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: (isLight ? 'rgba(0,0,0,.1)' : 'rgba(255, 255, 255,.1)'),
        borderRadius: '10px',
    },
    '.MuiAutocomplete-endAdornment': {
        display: 'none'
    },
});