import { common, grey } from 'src/theme/palette';

export const settingsMainBoxStyle = {
    display: 'flex', 
    alignItems: 'flex-start'
};
export const settingsMenuBoxStyle = {
    width: '210px', 
    display: 'inline-block',
    '@media(max-width: 1200px)': {
        display: 'none'
    }
};
export const settingsContentBoxStyle = {
    // width: 'calc(100% - 210px)', 
    display: 'inline-block', 
    pl: '24px', 
    mt: '72px',
    '@media(max-width: 1200px)': {
        pl: '0',
        mt: '0',
        width: '100%'
    },
    width: '100%'
};
export const settingsTitleStyle = {
    mb: '24px', 
    fontSize: '32px', 
    fontWeight: '700'
};
export const settingsMenuListBoxStyle = (isMenuActive) => ({
    pr: `${isMenuActive ? "0" : "4px"}`, 
    borderRight: `${isMenuActive ? 'none' : 'solid 1px rgba(145, 158, 171, 0.24)'}`, 
    width: '100%'
});
export const settingsIconStyle = {
    mr: '16px', 
    width: '22px !important', 
    height: '22px !important', 
    color: grey[600]
};
export const settingsMenuItemStyle = (alpha, theme) => ({
    p: '13px 8px !important', 
    mb: '4px', 
    borderRadius: '8px', 
    '&:hover': { 
        bgcolor: 'rgba(145, 158, 171, 0.08)' 
    }, 
    '&.Mui-selected': { 
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) 
    }, 
    '&.Mui-selected p': { 
        color: 'primary.main' 
    }, 
    '&.Mui-selected svg': { 
        color: 'primary.main' 
    }
});
export const mainBoxItemStyle = {
    display: 'flex', 
    justifyContent: 'left', 
    alignItems: 'center',
    '@media(max-width: 600px)': {
        flexWrap: 'wrap'
    }
};
export const autocompleteStyle = {
    width: 'auto',
    minWidth: '250px',
    '@media(max-width: 600px)': {
        minWidth: '100%',
        mb: 1
    }
};
export const emailFieldStyle = {
    ...autocompleteStyle,
    width: '100%',
    '& .MuiInputBase-root': { minHeight: '50px' }
};
export const mainBoxItemTextStyle = {
    my: 2, 
    mr: 2, 
    whiteSpace: 'nowrap',
    '@media(max-width: 600px)': {
        mb: 1
    }
};

export const emergencyIconBox = {
    bgcolor: common.vividTangerine,
    minWidth: '40px',
    minHeight: '40px',
    ml: 2,
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export const settingTemplateBoxStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    p: '8px 24px'
};

export const settingTemplateItemStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    m: '16px 0',
    width: '100%',
    gap: 3
};

export const eyeIconStyle = {
    color: 'common.grey',
    cursor: 'pointer',
    minWidth: '20px'
};

export const templateNameStyle = {
    minWidth: '300px'
};

export const centerDiv = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const submitBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
};

export const settingsPopoverIconStyle = {
    mr: '0', 
    width: '0 !important', 
    height: '22px', 
    color: grey[600],
    minWidth: '0 !important', 
    transition: 'all .3s',
    filter: 'brightness(0)',
};

export const settingsMenuPopoverStyle = {
    p: 0,
    mt: 1.5,
    ml: 0.75,
    width: '220px',
    '& .MuiMenuItem-root': {
        typography: 'body2',
        borderRadius: 0.75,
    },
    '.icon:hover svg': {
        width: '24px !important',
        minWidth: '24px !important',
        marginRight: '8px',
    }
};