/* eslint-disable no-nested-ternary */
import { alpha } from '@mui/material/styles';
import { common, grey } from 'src/theme/palette';

export const tabHeadStyle = {
    minWidth: {
        xs: 'auto',
        sm: 'auto'
    },
    textTransform: 'none !important',
    fontSize: '14px',
    alignItems: 'center'
};

export const taskCard = (type) => ({
    p: 0,
    overflow: 'auto',
    width: type === 'dashboard' ? '100%' : '100%',
    bgcolor: 'transparent',
    boxShadow: 'none',
    '@media (max-width: 1540px)': {
        width: '100%'
    }
});

export const taskTopHeaderBoxStyle = (type) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: type === 'dashboard' ? 'space-between' : 'flex-end',
    py: '6px',
    flexWrap: 'wrap'
});

export const taskList = () => ({
    px: 1,
    mx: 0,
    overflow: 'auto',
    maxHeight: '720px',
    mb: '16px',
    mt: 0,
    position: 'relative',
    borderRadius: 2
});

export const taskButton = {
    color: `${common.white} !important`,
    backgroundColor: `${common.blue} !important`,
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    maxWidth: '163px',
    height: '30px',
    fontSize: '13px',
    mb: '4px'
};

export const archivedButton = {
    maxWidth: '163px',
    height: '30px',
    fontSize: '13px',
    mb: '4px'
};

export const taskListCardStyle = (isArchived, isArchivePending) => ({
    display: 'flex',
    width: '100%',
    borderRadius: 2,
    boxShadow: isArchived || isArchivePending ? 'none' : `0px 8px 16px 0px ${common.boxShadowColor}`,
    mb: 1,
    pointerEvents: isArchived || isArchivePending ? 'none' : null,
    bgcolor: isArchivePending && 'rgb(235 235 235) !important',
    '&:hover': {
        bgcolor: 'common.mintCream'
    }
});

export const leadListCard = (isLight, isArchived, autoReminder, isArchivePending) => ({
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    margin: '0 0px 0',
    minHeight: '80px',
    height: 'auto',
    width: 'calc(100% - 48px)',
    overflow: 'auto',
    boxShadow: 'none',
    border: 'none',
    borderRadius: 2,
    pointerEvents: isArchived || isArchivePending ? 'none' : null,
    bgcolor: isArchivePending && 'rgb(235 235 235) !important',
});

export const leadListBox = (getStatusColour, data) => ({
    background: getStatusColour(data?.lead_treatment?.status, 'background') || 'rgba(24, 144, 255, 0.16)',
    color: getStatusColour(data?.lead_treatment?.status, 'color') || 'widget.tagBlueColor',
    fontWeight: '700',
    padding: '0px 8px',
    textAlign: 'center',
    borderRadius: '6px',
    fontSize: '12px',
    lineHeight: '22px',
    mb: '6px'
});

export const leadTaskPopover = (data) => ({
    color: data?.datetime_human.includes('now' || 'NOW') ? 'common.lightGreen' : 'common.fireBerry',
    fontWeight: '600',
    padding: '0 8px 0 0',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    mb: '0',
});

export const leadTaskNameStyle = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    fontSize: '12px',
    color: 'common.grey',
};

export const taskMessage = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0'
};

export const taskUser = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    alignItems: 'center',
    p: '10px 16px 0 0',
    '@media(max-width: 767px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
};

export const taskUserAvatar = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    mb: '0px',
    // '&:hover': {
    //     ' .MuiBox-root': {
    //         display: 'flex'
    //     }
    // }
};

export const taskAvatarText = (isLight) => ({
    margin: '0',
    padding: '0 0 0 8px',
    fontSize: '12px',
    color: isLight ? grey[700] : grey[500],
    cursor: 'pointer',
    whiteSpace: 'nowrap'
});

export const addTaskHideBtn = (isLight, theme) => ({
    padding: '12px 16px',
    borderRadius: '8px',
    marginTop: '10px',
    marginBottom: '24px',
    maxWidth: 'calc(100% - 48px)',
    ml: 'auto',
    mr: 'auto',
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
    color: 'primary.dark',
    ':hover': { backgroundColor: isLight ? alpha(theme.palette.primary.main, 0.2) : 'rgba(145, 158, 171, 0.16)' }
});

export const addTaskPop = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export const plusButton = {
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    backgroundColor: 'common.white',
    borderRadius: '12px',
    padding: '8px'
};

export const plusMenu = {
    borderRadius: '8px',
    minHeight: '40px !important',
    color: 'common.slateGray'
};

export const plusMenuIcon = { color: 'common.slateGray', marginRight: 15 };

export const taskConsultBtn = {
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    minWidth: '163px',
    height: '30px'
};

export const openTask = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    mb: '0'
};

export const openTaskPop = {
    border: ' 1px dashed rgba(145, 158, 171, 0.24)',
    borderRadius: '100px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const plusIconBoxStyle = {
    border: ' 1px dashed rgba(145, 158, 171, 0.24)',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    bgcolor: grey[200]
};

export const labelTypographyStyle = {
    fontSize: '13px',
    color: 'common.grey',
    mb: '4px'
};

export const openTaskText = (isLight) => ({
    margin: '0',
    padding: '0 0 0 8px',
    fontSize: '12px',
    color: isLight ? grey[700] : grey[500],
    cursor: 'pointer',
});

export const popBadge = {
    position: 'relative',
    '.MuiBadge-badge': {
        transform: 'scale(1) translate(16%, -16%)',
        backgroundColor: 'common.mintBlue',
        width: '15px',
        height: '15px',
        minWidth: '15px',
        fontSize: '12px',
        fontWeight: 700,
        color: 'common.white'
    },
};

export const editTaskBtn = {
    backgroundColor: 'transparent',
    color: 'common.grey',
    mr: '0',
    cursor: 'pointer',
    padding: '0 4px',
    pr: 0
};

export const bookBtnIcon = {
    color: 'common.grey',
    marginRight: '0'
};

export const photoIcon = {
    color: 'common.grey',
    marginLeft: '0'
};

export const completeTreatmentCancel = {
    margin: '0px 10px', padding: '5px 20px',
    fontSize: '14px',
    boxShadow: 'none',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px',
    minWidth: '100px'
};

export const assignBoxStyle = {
    p: '2px 4px',
    borderRadius: '8px',
    mr: '8px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        bgcolor: 'rgba(145, 158, 171, 0.16)'
    }
};

export const viewProfileBoxStyle = {
    p: '5px 11px',
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'common.borderColor',
    boxShadow: `0px 8px 16px 0px ${common.boxShadowColor}`,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center'
};

export const completeTreatmentConfirm = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
    borderRadius: '8px', bgcolor: `${common.mintyZest} !important`,
    color: 'common.white',
    minWidth: '100px'
};

export const callLabel = {
    backgroundColor: 'transparent',
    color: 'common.grey',
    mr: '0',
    cursor: 'pointer',
    padding: '0 4px',
    pr: 0
};

export const profileLabel = {
    color: 'common.grey',
    cursor: 'pointer',
    marginRight: '0'
};

export const viewLabel = {
    backgroundColor: 'transparent',
    color: 'common.grey',
    mr: '0',
    cursor: 'pointer',
    padding: '0 4px',
    pr: 0
};

export const editLabel = {
    backgroundColor: 'transparent',
    color: 'common.grey',
    mr: '0',
    cursor: 'pointer',
    padding: '0 4px',
    pr: 0
};

export const editFillIcon = {
    color: 'common.grey',
    cursor: 'pointer',
    marginRight: '0'
};

export const taskBadge = {
    position: 'relative',
    '.MuiBadge-badge': {
        transform: 'scale(1) translate(16%, -16%)',
        backgroundColor: 'common.mintBlue',
        width: '15px',
        height: '15px',
        minWidth: '15px',
        fontSize: '12px',
        fontWeight: 700,
        color: 'common.white'
    },
};

export const filterBoxStyle = (isLight, filterBtn) => ({
    pl: '24px',
    mt: '10px',
    mb: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    'button': {
        border: filterBtn ? '1px solid #00AB55' : '1px solid rgba(145, 158, 171, 0.32)',
        borderRadius: '50px',
        background: 'transparent !important',
        color: isLight ? 'common.slateGray' : 'white',
        boxShadow: 'none !important',
        minWidth: '80px',
        fontWeight: '700',
        padding: '6px 12px 6px 10px',
        'svg': {
            mr: '10px'
        }
    }
});


export const filterOutBox = {
    px: 3,
    bgcolor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
};

export const typographyUi = {
    fontSize: '18px !important',
    p: '0 24px 0',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
};

export const recordBtn = {
    color: `${common.white} !important`,
    backgroundColor: `${common.blue} !important`,
    border: 'none !important',
    fontSize: '13px'
};
export const iconBox = (color) => ({
    width: '27px',
    height: '27px',
    minWidth: '20px',
    minHeight: '20px',
    display: 'flex',
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    mr: 1.5
});

export const drawerStyle = (drawerOpen) => ({
    '.MuiDrawer-paper': {
        position: 'absolute',
        width: 320,
        maxWidth: '100%',
        boxShadow: 'rgba(145, 158, 171, 0.12) -40px 40px 80px -8px',
        display: drawerOpen ? 'flex' : 'none',
    }
});

export const drawerHeaderStyle = {
    display: 'flex',
    p: '24px 16px 24px 20px',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)'
};
export const textStyle = {
    '.MuiTypography-body1': {
        fontSize: '14px',
        fontWeight: '600',
        textTransform: 'capitalize'
    },
    '.MuiTypography-body2': {
        fontSize: '12px'
    }
};

export const taskReminderWrapper = {
    display: 'flex',
    width: '100%',
    borderBottom: 'solid 1px',
    borderColor: 'primary.paperColor',
    pb: 3
};

export const assignUserAvatarStyle = {
    height: '36px',
    width: '36px',
    border: '2px solid',
    borderColor: 'common.white',
    '&:nth-child(2)': {
        ml: '-8px'
    }
};

export const assignLengthStyle = {
    height: '36px',
    width: '36px',
    border: '2px solid',
    borderColor: 'common.white',
    ml: '-8px',
    borderRadius: '50%',
    background: '#C8FACD',
    color: '#027F55',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    fontWeight: 600
};

export const methodButtonStyle = {
    borderRadius: 2,
    minWidth: '150px',
    minHeight: '38px',
    bgcolor: 'primary.main'
};

export const overviewMethodButtonStyle = {
    borderRadius: 2,
    minWidth: '150px',
    minHeight: '44px',
    bgcolor: 'primary.main'
};

export const actionFormControlStyle = {
    width: '100%',
    '.MuiFormLabel-root': {
        left: '40px',
        top: '-8px',
        fontSize: '16px'
    },
    '.MuiInputBase-root': {
        paddingLeft: '40px',
        pb: 1
    },
    '.MuiFormLabel-root.MuiInputLabel-shrink': {
        top: 0
    }
};

export const selectedReminderBoxStyle = {
    position: 'absolute',
    top: '5px',
    minWidth: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
    height: 'auto'
};

export const methodFormControlStyle = {
    width: '100%',
    '.MuiFormLabel-root': {
        left: '40px',
        top: '-8px'
    },
    '.MuiInputBase-root': {
        paddingLeft: '40px'
    },
    '.MuiFormLabel-root.MuiInputLabel-shrink': {
        top: 0
    }
};

export const addSelectedReminderBoxStyle = {
    position: 'absolute',
    top: '14px',
    minWidth: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
    height: 'auto'
};

export const nameFormControlStyle = {
    width: '100%',
    '.MuiFormLabel-root': {
        left: '40px',
        top: '-8px',
        fontSize: '16px'
    },
    '.MuiInputBase-root': {
        paddingLeft: '40px'
    },
    '.MuiFormLabel-root.MuiInputLabel-shrink': {
        top: 0
    }
};

export const selectControllerStyle = {
    '&:hover:before': {
        borderBottom: '1px solid rgba(145, 158, 171, 0.32) !important'
    },
    '.MuiSelect-select': {
        bgcolor: 'transparent !important'
    },
    '.MuiSvgIcon-root': {
        display: 'none'
    }
};

export const selectColorControllerStyle = {
    color: 'primary.main',
    '&:hover:before': {
        borderBottom: '1px solid rgba(145, 158, 171, 0.32) !important'
    },
    '.MuiSelect-select': {
        bgcolor: 'transparent !important'
    },
    '.MuiSvgIcon-root': {
        display: 'none'
    }
};

export const formControlLabelStyle = {
    m: 0,
    position: 'relative',
    width: '100%',
    minHeight: '48px',
    '.MuiButtonBase-root': {
        position: 'absolute',
        opacity: 0,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        bgcolor: '#00AB5514',
        borderRadius: 1,
        'svg': {
            opacity: 0
        }
    },
    '.MuiButtonBase-root.Mui-checked': {
        opacity: 1,
        '+ .MuiFormControlLabel-label .MuiBox-root': {
            color: 'primary.dark',
            'span': {
                bgcolor: '#00AB5529',
                color: 'primary.main'
            }
        }
    },
    '.MuiFormControlLabel-label': {
        width: '100%'
    }
};

export const actionSelectStyle = {
    color: 'primary.dark',
    '&:hover:before': {
        borderBottom: '1px solid rgba(145, 158, 171, 0.32) !important'
    },
    '.MuiSelect-select': {
        bgcolor: 'transparent !important'
    },
    '.MuiSvgIcon-root': {
        display: 'none'
    }
};

export const addReminderDateTimePickerStyle = {
    '.MuiInputAdornment-positionEnd': {
        position: 'absolute',
        opacity: 0,
        width: 'calc(100% - 60px)',
        height: '100%',
        m: 0,
        'button': {
            width: '100%'
        }
    }
};

export const readOnlyMainElementStyle = {
    display: 'flex',
    alignItems: 'center',
    mb: '24px',
    pointerEvents: 'none'
};

export const commentBoxStyle = {
    borderRadius: 2,
    p: 1,
    bgcolor: 'common.boxShadowColor',
    width: '100%',
    display: 'flex',
    gap: 1
};

export const commentBoxInnerStyle = {
    width: '100%',
    position: 'relative',
    display: 'flex',
    gap: 1
};

export const dueDateBoxStyle = {
    position: 'relative',
    p: '0 0 8px 40px',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.32)',
    mt: 2,
    lineHeight: 0
};

export const dueDateButtonStyle = {
    zIndex: 1,
    p: 0,
    textTransform: 'none !important',
    '&:hover': {
        background: 'none'
    }
};

export const dueDateButtonLabelStyle = {
    color: 'primary.main',
    height: '22px',
    cursor: 'pointer',
    zIndex: 1,
    p: 0,
    fontSize: '0.875rem',
    fontWeight: 400
};

export const dueDatePickerBoxStyle = {
    position: 'absolute',
    width: '100%',
    top: '0',
    left: 0,
    maxHeight: '100%',
    opacity: 0,
    cursor: 'pointer',
    zIndex: 0
};

export const pickerTextFieldStyle = {
    position: 'absolute',
    width: '100%',
    left: 0,
    p: '0',
    top: '-15px'
};

export const assignTaskHandlerStyle = {
    width: '36px',
    height: '36px',
    border: ' 1px dashed rgba(145, 158, 171, 0.24)',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const mainFlowBoxStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    bgcolor: 'common.white',
    maxWidth: '377px'
};

export const callButtonStyle = (theme) => ({
    backgroundColor: `${theme.palette.common.blue} !important`,
    width: 'max-content',
    height: '48px',
    lineHeight: '19px'
});

export const filterCountBoxStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '0 0 0 8px',
    color: 'common.grey',
    lineHeight: '16.45px'
};

export const assignPopperStyle = {
    zIndex: '999999',
    ml: '16px !important'
};

export const taskItemDetailBoxStyle = {
    display: 'flex',
    mr: 1
};