/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { Avatar, Box, Card, Paper, Stack, Tooltip, Typography, Zoom, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react'
import { Iconify, Label } from 'src/components'
import { TAB_HEAD } from 'src/constants/LeadDetailConst';
import { box3Style, dateTimeUI, labelStyle, paperStyle, treatmentBoxStyle } from 'src/styles/AccountSocialLinks'
import { listHeaderBoxStyle } from 'src/styles/ContactLeadPopup';
import { activityCard, noteIcon, noteTooltipText, statusStyle, timeLineInfo, timeLineMessage, timeLineTypoV2 } from 'src/styles/OverView';
import { useSelector } from 'react-redux';
import { dispatch } from 'src/redux/store';
import { getActivities } from 'src/redux/slices/activity';
import { Timeline, TimelineDot, TimelineSeparator } from '@mui/lab';
import { CustomAvatar } from 'src/components/custom-avatar';
import { GetPracticeAvatar } from 'src/utils/createAvatar';
import { timelineItemStyle } from 'src/styles/Common';
import { setBackgroundColor, setIcon } from 'src/utils/timelineIcon';
import moment from 'moment';
import capitalizeFirstLetter from 'src/utils/capitalizedFunc';
import { NotFound } from 'src/components/empty-content';
import { ToDoList } from '.';


const OverviewTab = ({ treatmentTabData, leadId, handleChange, setExpandLayout, expandLayout, user, LeadNotes }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const { practiceTreatment, practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { activityList } = useSelector((state) => state.activity);
    const { leadFlowReminder, followLead } = useSelector((state) => state.lead);

    useEffect(() => {
        if (leadId) {
            dispatch(getActivities(practiceId, leadId, 10, 0));
        }
    }, [leadId]);

    const getUserName = (Name) => {
        if (Name?.created_by?.id === user?.pk) {
            return 'You commented';
        }
        if (Name?.lead_contact?.note) {
            return `${Name?.first_name} ${Name?.last_name}`;
        }
        return `${Name?.created_by?.first_name || ''} ${Name?.created_by?.last_name || ''}`;
    };

    const getOffers = (offer, isOffer) => {
        const stripHtmlAndSplit = (htmlString) => {
            if (!htmlString) return [];
            const strippedString = htmlString.replace(/<\/?[^>]+(>|$)/g, '\n');
            return strippedString.split('\n').filter((item) => item.trim() !== "");
        };

        const offerItems = stripHtmlAndSplit(isOffer ? offer?.offer : offer);

        return (
            <Box>
                {offerItems.map((item, index) => (
                    <Box key={index} sx={{ fontSize: "12px" }}>
                        {item}
                    </Box>
                ))}
            </Box>
        );
    };

    const getStatus = (treatment) => TAB_HEAD.map((e1) => {
        let latestData = '';
        if (treatment?.status === e1.value) {
            latestData = e1.label;
        }
        return latestData;
    });


    return (
        <Box>
            <Box sx={listHeaderBoxStyle}>
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Overview</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Iconify icon={'zondicons:close-solid'} onClick={() => handleChange('', '')} sx={{ color: '#637381', mr: 0, cursor: 'pointer' }} width={24} height={24} />
                </Box>
            </Box>
            <Card sx={{ ...activityCard({ data: { notAllow: true, type: "contactLeadPopup" } }), overflow: 'auto', maxHeight: 'calc(100vh - 220px)', padding: "8px 20px 0 20px", boxShadow: "none" }}>
                <Stack spacing={0} alignItems='flex-start' sx={{ width: "100%" }} >
                    <Typography sx={{ fontSize: '17px', fontWeight: 700, padding: "5px 12px", mb: "5px", mt: 1 }}>Treatment Information</Typography>
                    {treatmentTabData && treatmentTabData.length > 0 ? treatmentTabData?.map((item, index) => (
                        <Box key={index}
                            sx={{ ...timelineItemStyle, padding: "12px", width: "100%" }}>

                            <Box sx={{ display: 'flex', gap: "16px", alignItems: 'flex-start', width: "100%" }}>
                                <TimelineSeparator>
                                    <TimelineDot color='info' sx={{ ...timeLineInfo, bgcolor: setBackgroundColor() }}>
                                        {setIcon()}
                                    </TimelineDot>
                                </TimelineSeparator>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, pt: "6px" }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Paper
                                            sx={{
                                                background: isLight ? '#F4F6F8' : 'transparent',
                                                fontWeight: '500',
                                                fontSize: '12px'
                                            }}
                                            name={'leadTitle'}
                                        >
                                            <Label sx={{ color: 'black', backgroundColor: '#DFE3E8', p: '10px', fontSize: "12px", height: "25px", fontWeight: "500" }}>{item?.treatment_data?.treatment_item?.name}</Label>

                                        </Paper>
                                        <Typography variant="h6" sx={{ fontSize: '12px !important' }}>&#163; {item?.treatment_data?.price} </Typography>

                                        <Button sx={{ ...statusStyle(item.status), fontSize: "12px" }}><Iconify icon={item.status === 'NEW' ? 'mdi:question-mark-circle-outline' : item.status === 'CONSULTATION_BOOKED' ? 'mdi:calendar-clock' : item.status === 'FAILED_TO_ATTEND_CONSULTATION' ? 'bx:calendar-x' : item.status === 'CONSULTATION_ATTENDED' ? 'mdi:calendar-check' : item.status === 'CLOSED' ? 'material-symbols:check-circle-outline' : item.status === 'TREATMENT_STARTED' ? 'codicon:debug-start' : 'fluent:cloud-sync-complete-24-filled'} width={15} height={15} /> {getStatus(item)}</Button>

                                    </Box>

                                    {item?.treatment_data?.description && getOffers(item?.treatment_data?.description)}

                                    {item?.treatment_data?.additional_infos && item?.treatment_data?.additional_infos?.length > 0 && item?.treatment_data?.additional_infos.some(item => item?.categories && item?.categories?.length > 0 && item?.categories[0] === "FLOW") ? item?.treatment_data?.additional_infos?.filter(item => item?.categories && item?.categories?.length > 0 && item?.categories[0] === "FLOW")?.map((info, index) => <Box key={index} sx={index !== 0 ? { mt: "10px" } : {}}>
                                        <Typography variant='subtitle2' sx={{ ...noteTooltipText, display: "flex", alignItems: "center", gap: "5px", mb: "2px", fontSize: "12px", lineHeight: "inherit", textDecoration: "none" }}>
                                            <Iconify icon={'material-symbols:info'} width={14} height={14} sx={{}} />
                                            {"Additional info"}
                                            <Iconify icon={'bi:caret-down-fill'} width={14} height={14} sx={{ ...noteIcon, mt: "0" }} />
                                        </Typography>
                                        {getOffers(info?.text)}  </Box>) : null
                                    }

                                    <Box sx={box3Style}>
                                        <Box>
                                            {
                                                item?.treatment_data?.offers && item?.treatment_data?.offers?.length > 0 ? item?.treatment_data?.offers?.filter(offer => !offer.is_expired).map((offer, index) => <Box key={index} sx={index !== 0 ? { mt: "10px" } : {}}>
                                                    <Typography variant='subtitle2' sx={{ ...noteTooltipText, display: "flex", alignItems: "center", gap: "5px", mb: "2px", fontSize: "12px", lineHeight: "inherit", textDecoration: "none" }}>
                                                        <Iconify icon={'bxs:offer'} width={14} height={14} sx={{}} />
                                                        {capitalizeFirstLetter(offer?.name)}
                                                        <Iconify icon={'bi:caret-down-fill'} width={14} height={14} sx={{ ...noteIcon, mt: "0" }} />
                                                    </Typography>
                                                    {getOffers(offer, true)}  </Box>) : null
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    )) : <Box sx={{ ...timelineItemStyle, padding: "16px", width: "100%", display: "flex", gap: 2 }}><NotFound /></Box>}

                    <Typography sx={{ fontSize: '17px', fontWeight: 700, padding: "5px 12px", mb: "5px", mt: 1 }}>Activity</Typography>
                    <Timeline sx={{ padding: "0", width: "100%", m: 0 }}>
                        {Array.isArray(activityList?.results) ? activityList?.results?.slice(0, 1).map((value, index) => (
                            <Box key={index}
                                sx={{ ...timelineItemStyle, padding: "12px", width: "100%" }}>
                                <Box sx={{ display: 'flex', gap: "16px", alignItems: 'flex-start', width: "100%" }}>
                                    <TimelineSeparator>
                                        <TimelineDot color='info' sx={{ ...timeLineInfo, bgcolor: setBackgroundColor(value) }}>
                                            {setIcon(value)}
                                        </TimelineDot>
                                    </TimelineSeparator>
                                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", pt: "6px" }}>
                                        <Typography variant='subtitle2' sx={{ ...timeLineMessage, lineHeight: "inherit", fontSize: "12px" }}>
                                            {value.data.data.message === 'Awaiting Response.' ? capitalizeFirstLetter('Awaiting Reply.') : capitalizeFirstLetter(value.data.data.message)}
                                        </Typography>
                                        <Typography variant='body2' sx={{ ...timeLineTypoV2, lineHeight: "inherit", margin: "10px 0", fontSize: "12px" }}>{moment(value.timestamp, 'DD/MM/YYYY').format('DD MMM YYYY')} at {moment(value.timestamp, 'DD/MM/YYYY hh:mm:ss').format('hh:mm a')} </Typography>
                                        {value?.action_object?.additional_notes &&
                                            <Tooltip title={capitalizeFirstLetter(value?.action_object?.additional_notes)} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                <Typography variant='subtitle2' sx={noteTooltipText}>
                                                    Notes
                                                    <Iconify icon={'bi:caret-left-fill'} width={18} height={17} sx={noteIcon} />
                                                </Typography>
                                            </Tooltip>}
                                        <Box sx={{ padding: 0, display: 'flex' }}>
                                            <CustomAvatar src={value?.actor?.avatar || GetPracticeAvatar(practiceTreatment, practiceId)[0]?.logo} sx={{ height: '35px', width: '35px', backgroundColor: 'transparent' }} name={value?.actor?.first_name} />
                                            <Typography variant='subtitle2' sx={{ margin: '7px 0 0 7px', fontSize:"12px" }}>
                                                {value?.actor?.first_name || ''} {value?.actor?.last_name || ''}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )) : <Box sx={{ ...timelineItemStyle, padding: "16px", width: "100%", display: "flex", gap: 2 }}><NotFound /></Box>}
                    </Timeline>

                    <Typography sx={{ fontSize: '17px', fontWeight: 700, padding: "5px 12px", mb: "5px", mt: 1 }}>To-Do</Typography>
                    <ToDoList setExpandLayout={setExpandLayout} handleChange={handleChange} expandLayout={expandLayout} leadReminder={leadFlowReminder} user={user} isForTab />

                    <Typography sx={{ fontSize: '17px', fontWeight: 700, padding: "5px 12px", mb: "5px", mt: 1 }}>Notes</Typography>

                    {LeadNotes && LeadNotes.length > 0 ? LeadNotes.slice(LeadNotes.length - 1).map((options, index) => (
                        <Box key={index}
                            sx={{ ...timelineItemStyle, padding: "12px", width: "100%" }}>
                            <Box sx={{ display: 'flex', gap: "16px", alignItems: 'flex-start', width: "100%" }}>
                                <Avatar
                                    sx={{ height: '40px', width: '40px' }}
                                    src={options?.created_by && options?.created_by?.avatar || ''}
                                />

                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", pt: "6px" }}>
                                    <Box sx={box3Style}>
                                        <Paper
                                            sx={{
                                                background: '#F4F6F8',
                                                fontWeight: '600',
                                                fontSize: '12px'
                                            }}
                                            name={'leadTitle'}
                                        >
                                            {`${capitalizeFirstLetter(getUserName(options))}`}
                                        </Paper>
                                        {followLead?.lead_treatments?.length > 0 && <Button sx={{ ...statusStyle(followLead?.lead_treatments[0]?.status), fontSize: "12px" }} ><Iconify icon={followLead?.lead_treatments[0]?.status === 'NEW' ? 'mdi:question-mark-circle-outline' : followLead?.lead_treatments[0]?.status === 'CONSULTATION_BOOKED' ? 'mdi:calendar-clock' : followLead?.lead_treatments[0]?.status === 'FAILED_TO_ATTEND_CONSULTATION' ? 'bx:calendar-x' : followLead?.lead_treatments[0]?.status === 'CONSULTATION_ATTENDED' ? 'mdi:calendar-check' : followLead?.lead_treatments[0]?.status === 'CLOSED' ? 'material-symbols:check-circle-outline' : followLead?.lead_treatments[0]?.status === 'TREATMENT_STARTED' ? 'codicon:debug-start' : 'fluent:cloud-sync-complete-24-filled'} width={15} height={15} /> {getStatus(followLead?.lead_treatments[0])}</Button>}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            marginTop: "6px"
                                        }}
                                    >
                                        <Paper
                                            name='leadNotes'
                                            variant="outlined"
                                            sx={{ ...paperStyle(true), maxWidth: "75%", fontSize:"12px" }}
                                        >
                                            {options?.additional_notes || options?.note || options?.lead_contact?.note || ''}
                                        </Paper>
                                        {
                                            (!options?.additional_notes) && <Label sx={{ color: 'black', backgroundColor: '#DFE3E8', p: '8px', marginTop: "0px", fontSize:"12px" }} >
                                                Reminder Note
                                            </Label>
                                        }

                                    </Box>
                                    <Box sx={{ ...treatmentBoxStyle(), marginTop: "6px", justifyContent: "space-between", width: "100%" }} >
                                        {(followLead?.lead_treatments?.length > 0) ? <Label sx={{...labelStyle, fontSize:"12px"}}>
                                            {followLead?.lead_treatments[0]?.treatment}
                                        </Label> : null}
                                        <Box sx={dateTimeUI} >
                                            {moment(options?.updated_at, 'DD/MM/YYYY HH:mm:ss').format('DD MMM YYYY [at] hh:mm a')}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>


                        </Box>
                    )) : <Box sx={{ ...timelineItemStyle, padding: "16px", width: "100%", display: "flex", gap: 2 }}><NotFound /></Box>}
                </Stack>

            </Card>
        </Box>
    )
}

OverviewTab.propTypes = {
    treatmentTabData: PropTypes.array,
    leadId: PropTypes.any,
    setExpandLayout: PropTypes.any,
    expandLayout: PropTypes.any,
    handleChange: PropTypes.func,
    user: PropTypes.any,
    LeadNotes: PropTypes.array
};


export default OverviewTab