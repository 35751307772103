import React from 'react';
import ContactCell from '../CotactCell';

const ContactActionCell = ({
    anchorRef,
    open,
    setOpen,
    isDesktop,
    buttonHandleClose,
    handlePopup,
    viewDetailBtn,
    handleAttendedClick,
    handleAttendedFailedClick,
    handleAttendedPopup,
    minWidth = 200
}) => ({
    field: 'contact',
    headerName: 'Action',
    sortable: false,
    minWidth,
    align: 'center',
    renderCell: (params) => (
        <ContactCell
            row={params?.row}
            anchorRef={anchorRef}
            open={open}
            setOpen={setOpen}
            isDesktop={isDesktop}
            buttonHandleClose={buttonHandleClose}
            handlePopup={handlePopup}
            viewDetailBtn={viewDetailBtn}
            handleAttendedClick={handleAttendedClick}
            handleAttendedFailedClick={handleAttendedFailedClick}
            handleAttendedPopup={handleAttendedPopup}
        />
    ),
});

export default ContactActionCell;