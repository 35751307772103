import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import { updateError } from './user';

const initialState = {
    userEmail: '',
    newPassword: {
        password1: '',
        password2: ''
    }
};

export const slice = createSlice({
    name: 'Reset Password',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        resetPassword(state, action) {
            state.userEmail = action.payload;
        },
        confirmPassword(state, action) {
            state.newPassword = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
    },
});

export default slice.reducer;

export const { reset } = slice.actions;

export function resetOldPassword(data) {
    return async () => {
        try {
            const response = await axios.post('/api/v1/auth/password/reset/', data);
            if (response.status === 200) {
                dispatch(slice.actions.resetPassword(response.data));
                dispatch(updateError({
                    message: 'We have sent password reset link on your email!',
                    success: true
                }));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }; 
}

export function resetConfirmPassword(data) {
    return async () => {
        try {
            const response = await axios.post('/api/v1/auth/password/reset/confirm/', data);
            if (response.status === 200) {
                dispatch(slice.actions.confirmPassword(response.data));
                dispatch(updateError({
                    message: 'Your password has succesfully updated!',
                    success: true
                }));
            } else {
                dispatch(slice.actions.hasError(response.data));
            }
        } catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')  }${key}: ${value}`;
            });
            dispatch(updateError({
                message,
                success: false
            }));
            dispatch(slice.actions.hasError(error));
        }
    };
}