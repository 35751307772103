import PropTypes from 'prop-types';
// libraries
import { Box, Button, Container } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import { FollowUp } from '../scheduleReminder';
// style
import { leadFooterButtonsStyle, footerBoxStyle, btnStyle } from '../../styles/ScheduleReminder';

const ScheduleReminderPopup = ({ inputRef, handleClose }) => {
    const settings = useSettingsContext();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Box>
            <FollowUp ref={inputRef} handleClose={handleClose} type={'scheduleReminder'} />
            <Box sx={footerBoxStyle}>
                <Container
                    maxWidth={settings.themeStretch ? false : 'xl'}
                    sx={leadFooterButtonsStyle}
                >
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={handleNext}
                        sx={btnStyle}
                    >
                        Finish
                    </Button>
                </Container>
            </Box>
        </Box>
    );
};

export default ScheduleReminderPopup;

ScheduleReminderPopup.propTypes = {
    inputRef: PropTypes.object,
    handleClose: PropTypes.func
};
