import PropTypes from 'prop-types';
import React, { useState } from 'react';
// use @mui
import {
    TableRow,
    TableCell,
    Box,
    Typography,
    MenuItem,
    MenuList,
    ListItemText,
    Divider,
    Button,
    Radio,
} from '@mui/material';
// components
import { Iconify } from '../../../components';
import { TableMoreMenu } from '../../../components/table';
import { ConfirmDialog, FormDialog } from '../../../components/dialogPopUp';
import { useAuth } from '../../../hooks';
import { useSelector, dispatch } from '../../../redux/store';
import { setSendEmailStatus } from '../../../redux/slices/mail';
// style
import { common } from '../../../theme/palette';
import {
    messageTableCategory,
    messageTableMenuItem,
    subjectTableCellStyle,
    actionsTableCellStyle,
    leadTempMessageTableCellStyle,
    typoAlignCenterStyle,
} from '../../../styles/MessageListStyle';

const LeadTemplateMessageTableRow = ({
    row,
    selected,
    onSelectRow,
    handleDuplicate,
    onDeleteRow,
    onSendTestMail,
    messageType,
    handleUserTemplate,
    superUser,
    handleRowSelection,
    selectedRow,
    campaign,
    handleTemplatePreview,
    isFromUnableToContact,
    handleSendUnableToContact
}) => {
    const [openMenu, setOpenMenuActions] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDialogue, setDialogue] = useState(false);
    const { user } = useAuth();
    const {
        practiceUser: { results },
    } = useSelector((state) => state.practiceUser);
    const getUserList = () => results.filter((el) => el.user && el.user.id === user?.pk);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
        onDeleteRow();
    };

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handlePreview = () => {
        onSelectRow();
        handleCloseMenu();
    };

    const handleUse = () => {
        handleUserTemplate();
        handleCloseMenu();
    };

    const handleSend = (data, value) => {
        if (data === 'send') {
            onSendTestMail(value);
        }
        setDialogue(false);
    };

    const handleCampaign = () => {
        if (campaign) {
            handleRowSelection(row.id);
        }
    };
    const shouldDelete = () => {
        if (messageType === 'Practice_Message' && getUserList()[0]?.user?.is_superuser) {
            return true;
        }
        if (
            messageType !== 'Practice_Message' &&
            (getUserList()[0]?.user?.is_superuser ? true : !row?.created_by?.is_superuser)
        ) {
            return true;
        }
        return false;
    };

    const handleDuplicateTemplate = () => {
        handleDuplicate();
        handleCloseMenu();
    };

    const sendItemClickEvent = () => {
        dispatch(setSendEmailStatus('Send'));
        handleSendUnableToContact(row);
    };

    return (
        <TableRow hover selected={selected} onClick={handleCampaign} sx={{ '&:hover': { bgcolor: `${common.mintCream} !important` } }}>
            {campaign && (
                <TableCell sx={leadTempMessageTableCellStyle}>
                    <Radio checked={selectedRow === row.id} value={row.id.toString()} name='radio-button' />
                </TableCell>
            )}
            <TableCell sx={subjectTableCellStyle}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant='body' sx={{  mb: '2px', lineHeight: '24px' }}>
                        {row.subject} - {row.name}
                    </Typography>
                    <Typography variant='body' noWrap sx={{ ...messageTableCategory, fontSize: '13px', p: '2px 8px' }}>
                        {row.category.toLowerCase()}
                    </Typography>
                </Box>
            </TableCell>
            {messageType !== 'LeadTemplate' ? (
                <TableCell align='right' sx={leadTempMessageTableCellStyle}>
                    <TableMoreMenu
                        open={openMenu}
                        sx={{ width: '200px' }}
                        onOpen={handleOpenMenu}
                        onClose={handleCloseMenu}
                        actions={
                            <MenuList>
                                {(getUserList()[0]?.user?.is_superuser ? true : !row?.created_by?.is_superuser) && (
                                    <MenuItem
                                        sx={{ color: 'common.black' }}
                                        onClick={() => {
                                            onSelectRow();
                                            handleCloseMenu();
                                        }}
                                    >
                                        <Iconify icon={'solar:pen-bold'} sx={{ mr: 0 }} />
                                        <ListItemText primary='Edit' primaryTypographyProps={messageTableMenuItem} />
                                    </MenuItem>
                                )}
                                {(messageType === 'Template' || messageType === 'engagement_templates') && (
                                    <MenuItem
                                        sx={{ color: 'common.black' }}
                                        onClick={handleDuplicateTemplate}
                                    >
                                        <Iconify icon={'bxs:copy'} sx={{ mr: 0 }} />
                                        <ListItemText primary='Duplicate' primaryTypographyProps={messageTableMenuItem} />
                                    </MenuItem>
                                )}
                                {((messageType === 'Template' && row?.channel === 'EMAIL') ||
                                    messageType === 'Practice_Message' ||
                                    messageType === 'OnBoarding') && (
                                    <MenuItem
                                        sx={{ color: 'common.black' }}
                                        onClick={() => {
                                            setDialogue(true);
                                            handleCloseMenu();
                                        }}
                                    >
                                        <Iconify icon={'eva:email-fill'} sx={{ mr: 0 }} />
                                        <ListItemText primary='Send Test' primaryTypographyProps={messageTableMenuItem} />
                                    </MenuItem>
                                )}
                                {row?.channel === 'EMAIL' && (
                                    <MenuItem
                                        sx={{ color: 'common.black' }}
                                        onClick={() => {
                                            handleTemplatePreview(row);
                                            setOpenMenuActions(null);
                                        }}
                                    >
                                        <Iconify icon={'icon-park-outline:preview-close'} sx={{ mr: 0 }} />
                                        <ListItemText primary='Preview' primaryTypographyProps={messageTableMenuItem} />
                                    </MenuItem>
                                )}
                                {shouldDelete() && (
                                    <>
                                        <Divider sx={{ borderStyle: 'dashed' }} />
                                        <MenuItem
                                            sx={{ color: 'rgba(255, 72, 66, 1)' }}
                                            onClick={() => {
                                                handleOpenConfirm();
                                                handleCloseMenu();
                                            }}
                                        >
                                            <Iconify icon={'solar:trash-bin-trash-bold'} sx={{ mr: 0 }} />
                                            <ListItemText primary='Delete' primaryTypographyProps={messageTableMenuItem} />
                                        </MenuItem>
                                    </>
                                )}
                            </MenuList>
                        }
                    />
                </TableCell>
            ) : (
                <>
                    <TableCell sx={actionsTableCellStyle}>
                        <MenuList sx={{ display: 'flex', p: 0 }}>
                            {
                                isFromUnableToContact !== 'true' && <MenuItem sx={{ color: 'primary.main', m: '0 !important' }} onClick={handlePreview}>
                                    <Typography variant='subtitle2' sx={typoAlignCenterStyle}>
                                        Preview <Iconify icon={'ant-design:eye-outlined'} sx={{ ml: 1 }} width={18} height={18} />
                                    </Typography>
                                </MenuItem>
                            }
                            <MenuItem sx={{ color: 'primary.main', mb: '0 !important' }} onClick={handleUse}>
                                <Typography variant='subtitle2' sx={typoAlignCenterStyle}>
                                    Use <Iconify icon={'clarity:pencil-line'} sx={{ ml: 1 }} width={14} height={14} />
                                </Typography>
                            </MenuItem>
                            {
                                isFromUnableToContact === 'true' && <MenuItem sx={{ color: 'primary.main' }} onClick={() => sendItemClickEvent()}>
                                    <Typography variant='subtitle2' sx={typoAlignCenterStyle}>
                                        Send <Iconify icon={'iconamoon:send-fill'} sx={{ ml: 1 }} width={14} height={14} />
                                    </Typography>
                                </MenuItem>
                            }
                        </MenuList>
                    </TableCell>
                </>
            )}
            <ConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title='Delete'
                content={<Box sx={{ fontSize: '18px', mb: '20px' }}>{'Are you sure you want to delete?'}</Box>}
                contentId={
                    superUser && (
                        <Box>
                            Template: <Box sx={{ color: 'common.darkBlue' }}>{row?.name}</Box>
                            <br />
                            {row?.assigned_practices?.length > 0 && (
                                <>
                                    Practices:{' '}
                                    <Box sx={{ color: 'common.darkBlue' }}>
                                        {row?.assigned_practices?.map((el, index, array) => (
                                            <React.Fragment key={index}>
                                                {el?.name}
                                                {el?.name && array?.length - 1 !== index ? ', ' : ''}
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                </>
                            )}
                        </Box>
                    )
                }
                action={
                    <Button variant='contained' color='error' onClick={handleCloseConfirm}>
                        Delete
                    </Button>
                }
            />
            {openDialogue && (
                <FormDialog open={openDialogue} handleClose={handleSend} data={row.message?.type || row?.channel} />
            )}
        </TableRow>
    );
};

LeadTemplateMessageTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onSelectRow: PropTypes.func,
    handleDuplicate: PropTypes.func,
    onSendTestMail: PropTypes.func,
    onDeleteRow: PropTypes.func,
    messageType: PropTypes.string,
    handleUserTemplate: PropTypes.func,
    superUser: PropTypes.bool,
    handleRowSelection: PropTypes.func,
    selectedRow: PropTypes.object,
    campaign: PropTypes.object,
    handleTemplatePreview: PropTypes.func,
    isFromUnableToContact: PropTypes.bool,
    handleSendUnableToContact: PropTypes.func
};

export default LeadTemplateMessageTableRow;