import React from 'react';
import { Box, Button, Card, FormControl, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import { FormProvider } from '../../../components/hook-form';
// Validation
import { treatmentPlanValidationSchema } from '../../../validations/commonValidations';
// Style
import { taskCard } from '../../../styles/leadTaskReminder';
import { fieldWrapper } from '../../../styles/treatmentPlanStyles';

const TreatmentPlan = () => {
    const defaultValues = {
        handedOrEmailed: '',
        treatment_plan_handed: null,
        treatment_plan_emailed: null,
    };

    const methods = useForm({
        resolver: yupResolver(treatmentPlanValidationSchema),
        defaultValues,
    });

    const {
        control,
        handleSubmit,
        setValue,
        watch,
    } = methods;

    const values = watch();

    const onSubmit = () => { };

    const handleArchiveFilter = (e) => setValue('handedOrEmailed', e.target.value);

    return <Card sx={taskCard()}>
        <Box sx={{ p: 3 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ mb: 3 }}>Treatment Plan Status</Typography>
                <Box sx={fieldWrapper}>
                    <Box sx={{ minWidth: 150 }}>
                        <FormControl fullWidth>
                            <Select
                                displayEmpty
                                id='demo-simple-select'
                                value={values.handedOrEmailed}
                                onChange={handleArchiveFilter}
                                input={<OutlinedInput />}
                            >
                                <MenuItem disabled value=""><em>Select</em></MenuItem>
                                <MenuItem value={'treatment_plan_handed'}>In person</MenuItem>
                                <MenuItem value={'treatment_plan_emailed'}>Emailed</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={fieldWrapper}>
                    {values.handedOrEmailed === 'treatment_plan_emailed' && <Box>
                        <Controller
                            name='treatment_plan_emailed'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <DatePicker
                                        value={field.value || null}
                                        minDateTime={new Date()}
                                        format={'dd/MM/yyyy'}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                                onKeyDown: (e) => e.preventDefault()
                                            },
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Box>}
                    {values.handedOrEmailed === 'treatment_plan_handed' &&
                        <Box>
                            <Controller
                                name='treatment_plan_handed'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <DatePicker
                                            value={field.value || null}
                                            minDateTime={new Date()}
                                            format={'dd/MM/yyyy'}
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!error,
                                                    helperText: error?.message,
                                                    onKeyDown: (e) => e.preventDefault()
                                                },
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </Box>}
                </Box>
                <Box>
                    <Button
                        color='primary'
                        variant='contained'
                        type='submit'
                    >
                        Submit
                    </Button>
                </Box>
            </FormProvider >
        </Box >
    </Card>;
};

export default TreatmentPlan;