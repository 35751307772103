import React from 'react';
import PropType from 'prop-types';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { Iconify } from 'src/components';
import { useResponsive } from 'src/hooks';
import { common } from 'src/theme/palette';
import { menuListStyle } from 'src/styles/NewLeadTableRowStyle';

const RecordAttendanceBtnPopper = ({
    params,
    anchorEl,
    open,
    handleAttendedClick,
    handleAttendedFailedClick,
    buttonHandleClose,
}) => {
    const isDesktop = useResponsive('down', 'sm');
    const { slateGray } = common;
    return (
        <Popper
            sx={{
                zIndex: 1,
                marginLeft: '10px !important',
            }}
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            transition
            placement={isDesktop ? 'left-start' : 'right-start'}
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener onClickAway={buttonHandleClose}>
                            <MenuList id="split-button-menu" autoFocusItem sx={menuListStyle}>
                                <MenuItem
                                    key="Attended"
                                    onClick={() => handleAttendedClick(params.row.lead_id, params.row)}
                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                >
                                    <Iconify
                                        style={{ color: slateGray, marginRight: 15 }}
                                        width={20}
                                        height={20}
                                        icon={'akar-icons:check'}
                                    />{' '}
                                    Attended
                                </MenuItem>
                                <MenuItem
                                    key="Failed to attend"
                                    onClick={() => handleAttendedFailedClick(params.row.lead_id, params.row)}
                                    sx={{ borderRadius: '8px', minHeight: '40px !important', color: slateGray }}
                                >
                                    <Iconify style={{ color: slateGray, marginRight: 15 }} width={20} height={20} icon={'ci:close-big'} />{' '}
                                    Failed to attend
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

export default RecordAttendanceBtnPopper;

RecordAttendanceBtnPopper.propTypes = {
    params: PropType.any,
    anchorEl: PropType.any,
    open: PropType.bool,
    handleAttendedClick: PropType.func,
    handleAttendedFailedClick: PropType.func,
    buttonHandleClose: PropType.func,
};
