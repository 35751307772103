import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { audienceCsvColumns } from '../../constants/audienceConstants';
import { grey } from '../../theme/palette';
import { customListStyle } from '../../styles/ImportLeads';

const isNotIn = (a, b) => a.filter((value) => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

const TransferList = ({ originalProps, duplicateProps, allProps, convertToCSV, headers }) => {
    const [checked, setChecked] = useState([]);
    const { originals, setOriginals } = originalProps;
    const { duplicates, setDuplicates } = duplicateProps;
    const { all } = allProps;

    const leftChecked = intersection(checked, originals?.map((ele) => ele.id));
    const rightChecked = intersection(checked, duplicates?.map((ele) => ele.id));

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setDuplicates(duplicates.concat(originals));
        setOriginals([]);
        convertToCSV([]);
    };

    const handleCheckedRight = () => {
        setDuplicates(duplicates.concat(all?.filter((ele) => leftChecked.includes(ele.id))));
        const dataToFilter = isNotIn(originals?.map((ele) => ele.id), leftChecked);
        const filteredData = all?.filter((ele) => dataToFilter.includes(ele.id));
        setOriginals(filteredData);
        convertToCSV(filteredData);
        setChecked(isNotIn(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        const mergedData = originals.concat(all?.filter((ele) => rightChecked.includes(ele.id)));
        setOriginals(mergedData);
        convertToCSV(mergedData);
        const dataToFilter = isNotIn(duplicates?.map((ele) => ele.id), rightChecked);
        setDuplicates(all?.filter((ele) => dataToFilter.includes(ele.id)));
        setChecked(isNotIn(checked, rightChecked));
    };

    const handleAllLeft = () => {
        const mergedData = originals.concat(duplicates);
        setOriginals(mergedData);
        convertToCSV(mergedData);
        setDuplicates([]);
    };

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} sx={{ border: `1px solid ${grey[400]}` }} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    const filteredData = [{
        width: 70,
        label: 'Checked',
        dataKey: 'checked',
    }, ...audienceCsvColumns.filter((ele) => headers?.[0]?.includes(ele.dataKey))];

    const fixedHeaderContent = () => (
        <TableRow>
            {filteredData.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant='head'
                    align={column.numeric || false ? 'right' : 'left'}
                    style={{ width: column.width }}
                    sx={{
                        backgroundColor: 'background.paper',
                    }}
                >
                    {column.label}
                </TableCell>
            ))}
        </TableRow>
    );

    const rowContent = (_index, row) => <>
        {filteredData.map((column) => {
            if (column.dataKey === 'checked') {
                return <TableCell key={column.dataKey} >
                    <Checkbox
                        checked={checked.indexOf(row.id) !== -1}
                        onChange={handleToggle(row.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                            'aria-labelledby': column.dataKey,
                        }}
                    />
                </TableCell>;
            }
            return <TableCell
                key={column.dataKey}
                align={column.numeric || false ? 'right' : 'left'}
            >
                {row[column.dataKey]}
            </TableCell>;
        })}
    </>;

    const customList = (items, title) => (
        <>
            <Typography sx={customListStyle}>{title} {`(${items?.length || 0})`}</Typography>
            <Paper sx={{ height: 400, width: '100%' }}>
                <TableVirtuoso
                    data={items}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                />
            </Paper>
        </>
    );

    return (
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
            <Grid item sx={{ minWidth: 'calc(50% - 40px)' }}>{customList(originals, 'Original data')}</Grid>
            <Grid item>
                <Grid container direction='column' alignItems='center'>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleAllRight}
                        disabled={originals.length === 0}
                        aria-label='move all duplicates'
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label='move selected duplicates'
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label='move selected originals'
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant='outlined'
                        size='small'
                        onClick={handleAllLeft}
                        disabled={duplicates.length === 0}
                        aria-label='move all originals'
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item sx={{ minWidth: 'calc(50% - 40px)' }}>{customList(duplicates, 'Duplicate data')}</Grid>
        </Grid>
    );
};

export default TransferList;

TransferList.propTypes = {
    originalProps: PropTypes.object,
    duplicateProps: PropTypes.object,
    allProps: PropTypes.object,
    convertToCSV: PropTypes.func,
    headers: PropTypes.array,
};
