import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { dispatch, store } from '../store';
import { updateError } from './user';
import { getFilter, handleLoading } from '../api/practiceMessageApis';
import { getPracticeId } from './clincheck';

const initialState = {
    isLoading: false,
    practiceMessage: { results: [] },
    imageLoader: false,
    messageDetail: {},
    practiceMessageSequences: { results: [] },
    specificPracticeMessageSequences: {},
    templateDetails: { results: [] },
    templateDetailsList: [],
    userTemplateDetails: {},
    deleteTemplateDetails: { results: [] },
    message: '',
    error: '',
    isAutoSaving: false,
    autoSaveStatus: false,
    subjectTemplateList: [],
    showSubjectList: false,
    loadingForTemplate: false,
    search: null
};

const slice = createSlice({
    name: 'practiceMessage',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        startLoading(state) {
            state.isLoading = true;
            state.message = '';
            state.messageDetail = {};
            state.userTemplateDetails = {};
        },
        endLoading(state) {
            state.isLoading = false;
        },
        getPracticeSequences(state, action) {
            state.isLoading = false;
            state.practiceMessageSequences.results = [];
            state.practiceMessage = action.payload;
        },
        BlankSequence(state) {
            state.isLoading = false;
            state.practiceMessageSequences.results = [];
        },
        getPracticeSequenceMessage(state, action) {
            state.isLoading = false;
            state.practiceMessageSequences = action.payload;
        },
        getSpecificPracticeSequenceMessage(state, action) {
            state.isLoading = false;
            state.specificPracticeMessageSequences = action.payload;
        },
        getMessageDetail(state, action) {
            state.isLoading = false;
            state.messageDetail = action.payload;
        },
        getTemplate(state, action) {
            state.isLoading = false;
            state.templateDetails = action.payload;
        },
        updateTemplateDetailsList(state, action) {
            state.templateDetailsList = action.payload;
        },
        addTemplate(state, action) {
            state.isLoading = false;
            state.addTemplateDetails = action.payload;
        },
        getUserTemplate(state, action) {
            state.isLoading = false;
            state.userTemplateDetails = action.payload;
        },
        getUpdateData(state) {
            state.isLoading = false;
            state.message = 'Template updated successFully';
        },
        deleteTemplate(state) {
            state.isLoading = false;
            state.deleteTemplateDetails = 'Deleted';
            state.message = 'Template deleted successFully';
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        clearMessage(state) {
            state.message = '';
            state.error = '';
        },
        updateMessages(state, action) {
            state.isLoading = false;
            state.practiceMessageSequences.results = action.payload;
        },
        updateSequenceData(state, action) {
            state.isLoading = false;
            state.practiceMessage.results = action.payload;
        },
        imageLoaderUpdate(state, action) {
            state.imageLoader = action.payload;
        },
        setIsAutoSaving(state, action) {
            state.isAutoSaving = action.payload;
        },
        setIsAutoSaveStatus(state, action) {
            state.autoSaveStatus = action.payload;
        },
        updateSubjectTemplateList(state, action) {
            state.subjectTemplateList = action.payload;
        },
        updateShowSubjectList(state, action) {
            state.showSubjectList = action.payload;
        },
        setLoadingForTemplate(state, action) {
            state.loadingForTemplate = action.payload;
        },
        setSearchTemplate(state, action) {
            state.search = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

export const {
    clearMessage,
    reset,
    updateSubjectTemplateList,
    updateShowSubjectList,
    setSearchTemplate,
    startLoading,
    updateTemplateDetailsList,
    hasError,
    endLoading,
    setLoadingForTemplate,
    getTemplate,
    deleteTemplate,
    getUserTemplate,
    BlankSequence,
    setIsAutoSaving,
    getUpdateData,
    setIsAutoSaveStatus
} = slice.actions;

// Get Practice Sequences List
export function getPracticeSequencesList(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${id}/sequences/`);
            if (response.status === 200) {
                dispatch(slice.actions.getPracticeSequences(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSpecificPracticeSequence(id, seqId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${id}/sequences/${seqId}/`);
            if (response.status === 200) {
                dispatch(slice.actions.getSpecificPracticeSequenceMessage(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPracticeSequenceMessageList(id, seqId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${id}/sequences/${seqId}/messages/`);
            if (response.status === 200) {
                dispatch(slice.actions.getPracticeSequenceMessage(response.data));
            } else {
                dispatch(slice.actions.getPracticeSequenceMessage(initialState.practiceMessageSequences));
                dispatch(slice.actions.hasError(response.data.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPracticeMessageDetail(practiceId, sequenceId, messageId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/sequences/${sequenceId}/messages/${messageId}/`);
            if (response.status === 200) {
                dispatch(slice.actions.getMessageDetail(response.data));
            } else {
                dispatch(updateError({
                    message: response.data.detail || 'Something want wrong',
                    success: false,
                }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(updateError({
                message: error.message || 'Something want wrong',
                success: false,
            }));
        }
    };
}

export function updateMessageDetail({ sequenceId, messageId, detail, callback }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/sequences/${sequenceId}/messages/${messageId}/`, 'patch', detail);
            if (response.status === 200) {
                const { practiceMessageSequences: { results } } = store.getState().practiceMessage;
                if (results.length > 0) {
                    const data = [];
                    results.forEach(message => {
                        let messageDetail = { ...message };
                        if (messageDetail.id === messageId) {
                            messageDetail = { ...messageDetail, ...detail };
                        }
                        data.push(messageDetail);
                    });
                    dispatch(slice.actions.updateMessages(data));
                }
                callback({ success: true, message: 'Message detail updated successfully' }, true);
            } else {
                callback({ success: false, message: response.data }, true);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(updateError({
                message: error.message || 'Something want wrong',
                success: false
            }));
        }
    };
}

export function sendTestMail({ sequenceId, messageId, detail, callback }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/sequences/${sequenceId}/messages/${messageId}/send_test/`, 'put', detail);
            if (response.status === 200) {
                callback({ success: true, message: 'Test email send successfully' }, true);
            } else {
                callback({ success: false, message: response.data }, true);
            }
        } catch (error) {
            dispatch(updateError(error || 'Something want wrong'));
        }
    };
}

export function updateSequenceDetail({ sequenceId, detail, callback }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/sequences/${sequenceId}/`, 'patch', detail);
            if (response.status === 200) {
                const { practiceMessage: { results } } = store.getState().practiceMessage;
                const data = [];
                results.forEach(message => {
                    let messageDetail = { ...message };
                    if (messageDetail.id === sequenceId) {
                        messageDetail = { ...messageDetail, ...detail };
                    }
                    data.push(messageDetail);
                });
                dispatch(slice.actions.updateSequenceData(data));
                callback({
                    message: 'Sequence detail updated successfully',
                    success: true,
                }, true);
            } else {
                callback({ success: false, message: response.detail }, true);
            }
        } catch (error) {
            dispatch(updateError({
                message: error || 'Something want wrong',
                success: false
            }));
        }
    };
}

export function uploadImage(detail, callback, quill) {
    return async () => {
        try {
            dispatch(slice.actions.imageLoaderUpdate(true));
            const { practiceId } = store.getState().practiceTreatmentList;
            const response = await callApi(`/api/v1/practices/${practiceId}/media/`, 'post', detail);
            if (response.status === 201) {
                callback({ success: true, data: response.data, quill });
            } else {
                callback({ success: false, message: response.data });
            }
            dispatch(slice.actions.imageLoaderUpdate(false));
        } catch (error) {
            dispatch(slice.actions.imageLoaderUpdate(false));
            dispatch(updateError({ message: error.message || 'Something want wrong', success: false }));
        }
    };
}

// Get Practice Template List
export function getTemplateList(id, removeUnLayer, unLayerFilter) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            handleLoading(true);
            const query = removeUnLayer ? '&use_unlayer=false' : '';
            const unLayerFilterQuery = getFilter(unLayerFilter);
            const response = await callApi(`/api/v1/practices/${id}/engagement-templates/?ordering=-created_at${query}${unLayerFilterQuery}`);
            if (response.status === 200) {
                dispatch(slice.actions.getTemplate(response.data));
                handleLoading(false);
            } else {
                handleLoading(false);
                dispatch(slice.actions.hasError(response.data.detail || 'Something went wrong'));
            }
        } catch (error) {
            handleLoading(false);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserTemplateDetails(practiceId, id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-templates/${id}/`);
            if (response.status === 200) {
                dispatch(slice.actions.getUserTemplate(response.data));
            } else {
                dispatch(slice.actions.hasError(response.data.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addTemplateList({ id, details, callback, isDuplicate = false }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${id}/engagement-templates/`, 'post', details);
            if (response.status === 201) {
                dispatch(slice.actions.addTemplate(response.data));
                callback({
                    message: isDuplicate ? 'Template duplicated successfully' : 'Template added successfully',
                    success: true
                }, true);
            } else {
                dispatch(slice.actions.hasError((response.data) || 'Something went wrong'));
                callback({
                    message: response.data || 'Something went wrong',
                    success: false
                }, true);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}