/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useDebounce } from 'use-lodash-debounce';
// @mui
import { Chip, Box, Paper, Divider } from '@mui/material';
// hooks
import { RHFAutocomplete, RHFCheckbox } from '../hook-form';
// store 
import { dispatch, useSelector } from '../../redux/store';
import { getTreatmentList, startLoading } from '../../redux/slices/superAdmin';
// utils
import superAdminGroupedTreatments from '../../utils/superAdminGroupedTreatments';
// style
import { treatmentChipTextStyle } from '../../styles/NewLead';

// -------------------------------------------------------------------------------------------

const SuperAdminSelectMultiTreatments = ({ type, name, value, onChange, ...props }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { treatmentsList: { results, count }, isLoading } = useSelector((state) => state.superAdmin);
    const { treatmentStarted: { categoryName } } = useSelector((state) => state.lead);
    const [treatmentOptions, setTreatmentOptions] = useState([]);
    const [position, setPosition] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const inputValueDebounce = useDebounce(inputValue, 800);
    const listElem = useRef();
    const mounted = useRef();

    useEffect(() => {
        if (results) {
            setTreatmentOptions(superAdminGroupedTreatments(results));
        }
        if (type === 'autoReminder') {
            if (results?.length < count) {
                const data = {
                    limit: count,
                    offset: results?.length
                };
                dispatch(getTreatmentList(data));
            }
        }
    }, [results]);

    const loadMoreData = () => {
        if (count > results?.length) {
            const data = {
                limit: 30,
                offset: results?.length
            };
            dispatch(getTreatmentList(data));
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const handleDelete = (delValue) => {
        const data = value?.filter((res) => res.id !== delValue);
        onChange('', data);
    };

    useEffect(() => {
        if (practiceId) {
            if (inputValueDebounce) {
                const data = {
                    type: 'searchTreatment',
                    treatment: inputValueDebounce,
                    category: type === 'treatmentStarted' ? categoryName !== 'All' && categoryName : null
                };
                dispatch(getTreatmentList(data));
            } else {
                const data = {
                    limit: 30,
                    offset: 0,
                    type: 'searchTreatment',
                    category: type === 'treatmentStarted' ? categoryName !== 'All' && categoryName : null
                };
                dispatch(getTreatmentList(data));
            }
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        dispatch(startLoading());
        setInputValue(value);
    };

    const handleToggleSelectAll = (e) => {
        e.preventDefault();
        setSelectAll(!selectAll);
        const data = {
            limit: !selectAll ? count : 30,
            offset: 0
        };
        dispatch(getTreatmentList(data));
        if (!selectAll) {
            onChange('', treatmentOptions);
            return;
        }
        onChange('', []);
    };

    const handleOnChanged = (e, value) => {
        onChange(e, value);
        if (value.length === 0 || value?.length !== count) {
            setSelectAll(false);
        }
        if (value?.length === count) {
            setSelectAll(true);
        }
    };

    return (
        <RHFAutocomplete
            {...props}
            multiple
            name={name}
            value={value}
            onChange={handleOnChanged}
            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
            options={treatmentOptions}
            noOptionsText={isLoading ? 'Loading...' : 'No options'}
            groupBy={(option) => option?.firstLetter}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option?.name}
            renderTags={(value) => (
                <Box>
                    {value?.map((item, index) => (
                        <Chip
                            sx={treatmentChipTextStyle(type)}
                            key={index}
                            label={item?.name}
                            size="small"
                            color="info"
                            variant="soft"
                            onDelete={() => handleDelete(item.id)}
                        />
                    ))}
                </Box>
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.name} style={{ padding: '5px 9px' }}>
                    <Box sx={{ padding: '0px 12px' }} >
                        <RHFCheckbox renderSelect checked={selected} label={option.name} name={option.name} />
                    </Box>
                </li>
            )}
            ListboxProps={{
                ref: listElem,
                onScroll: (event) => {
                    const listboxNode = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
                    if (listboxNode) {
                        setPosition(event.target.scrollTop + event.target.clientHeight);
                        loadMoreData();
                    }
                }
            }}
            PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                    <Paper {...restPaperProps}>
                        {type === 'autoReminder' && <> <Box
                            onMouseDown={(e) => e.preventDefault()} // prevent blur
                            sx={{ padding: '5px 12px', ':hover': { cursor: 'pointer' } }}
                        >
                            <RHFCheckbox
                                id="select-all-checkbox"
                                renderSelect
                                checked={selectAll}
                                name='Select All'
                                label="Select All"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleToggleSelectAll(e);
                                }}
                            />
                        </Box>
                        <Divider /></>}
                        {children}
                    </Paper>
                );
            }}
        />
    );
};

SuperAdminSelectMultiTreatments.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    control: PropTypes.any,
};

export default SuperAdminSelectMultiTreatments;
