import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// use @mui
import { useTheme } from '@mui/material/styles';
import {
    TableRow,
    TableCell,
    Box,
    Typography,
    Switch,
    FormControlLabel,
    MenuItem,
    MenuList,
    ListItemText,
    Divider,
    Button,
    Radio
} from '@mui/material';
// components
import { Iconify } from '../../../components';
import { TableMoreMenu } from '../../../components/table';
import { ConfirmDialog, FormDialog } from '../../../components/dialogPopUp';
import { useAuth } from '../../../hooks';
import { useSelector } from '../../../redux/store';
// import { RHFRadioGroup } from '../../../components/hook-form';
// style
import {
    messageTableBox,
    messageTableCategory,
    messageTableMenuItem,
    engagementMenuItemPreview,
    engagementMenuItemUseTemplate
} from '../../../styles/MessageListStyle';
import { common, widget } from '../../../theme/palette';

// ----------------------------------------------------------------
export default function MessageTableRow({ row, selected, onSelectRow, handleDuplicate, onDeleteRow, onSendTestMail, onChangeStatus, practiceMessageTable, messageType, handleUserTemplate, superUser, handleRowSelection, selectedRow, campaign, handleTemplatePreview }) {

    const [openMenu, setOpenMenuActions] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [icon, setIcon] = useState('');
    const [openDialogue, setDialogue] = useState(false);
    const { user } = useAuth();
    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const getUserList = () => results.filter(el => el.user && el.user.id === user?.pk);
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
        onDeleteRow();
    };

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    useEffect(() => {
        if (messageType === 'Practice_Message' || messageType === 'OnBoarding') {
            if (row.message.type && row.message.type.toLowerCase() === 'sms') {
                setIcon('ic:baseline-sms');
            }
            if (row.message.type && row.message.type.toLowerCase() === 'email') {
                setIcon('eva:email-fill');
            }
        } else {
            if (row.channel && row.channel.toLowerCase() === 'sms') {
                setIcon('ic:baseline-sms');
            }
            if (row.channel && row.channel.toLowerCase() === 'email') {
                setIcon('eva:email-fill');
            }
        }
    }, [row, messageType]);

    const handleChangeStatus = () => {
        onChangeStatus({ is_enabled: !row.is_enabled }, row.id);
    };

    const getDelayLabel = (value) => {
        let data = '';
        if (value === 0) {
            data = 'Immediately';
        }
        else if (value === 86400 || value === -86400) {
            data = '1 day';
        }
        else if (value === 172800 || value === -172800) {
            data = '2 day';
        }
        else if (value === 604800 || value === -604800) {
            data = '1 week';
        }
        else if (value === 1209600 || value === -1209600) {
            data = '2 week';
        }
        else {
            data = 'custom';
        }
        return data;
    };

    const handlePreview = () => {
        onSelectRow();
        handleCloseMenu();
    };

    const handleUse = () => {
        handleUserTemplate();
        handleCloseMenu();
    };

    const handleSend = (data, value) => {
        if (data === 'send') {
            onSendTestMail(value);
        }
        setDialogue(false);
    };

    const handleCampaign = () => {
        if (campaign) {
            handleRowSelection(row.id);
        }
    };
    const shouldDelete = () => {
        if (messageType === 'Practice_Message' && getUserList()[0]?.user?.is_superuser) {
            return true;
        }
        if (messageType !== 'Practice_Message' && (getUserList()[0]?.user?.is_superuser ? true : !row?.created_by?.is_superuser)) {
            return true;
        }
        return false;
    };

    const handleDuplicateTemplate = () => {
        handleDuplicate();
        handleCloseMenu();
    };

    return (
        <TableRow
            hover selected={selected}
            sx={{
                borderTop: practiceMessageTable ? '2px solid rgba(145, 158, 171, 0.12)' : '',
                bgcolor: (row?.created_by?.is_superuser && messageType !== 'engagement_templates') && isLight ? 'rgb(240, 246, 250)' : 'transparent'
            }}
            onClick={handleCampaign}
        >
            {campaign && <TableCell>
                <Radio
                    checked={selectedRow === row.id}
                    value={row.id.toString()}
                    name="radio-button"
                />
            </TableCell>}
            <TableCell>
                <Box sx={{ ml: 0 }}>
                    <Typography
                        variant="span"
                        noWrap
                        sx={messageTableBox}
                    >
                        <Iconify
                            icon={icon}
                            width={20}
                            height={20}
                            mr={1}
                        />
                        {row && row.channel}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell
                sx={{ maxWidth: '320px' }}
            >
                <Box
                    sx={{ ml: 0 }}
                >
                    <Typography
                        variant="subtitle2"
                        noWrap
                        sx={{
                            width: '100%',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {(messageType !== 'Practice_Message' && messageType !== 'OnBoarding') ? row.name : row.subject}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell
                sx={{ width: '320px' }}
            >
                <Box
                    sx={{ ml: 0 }}
                >
                    <Typography
                        variant="body"
                        noWrap
                    >
                        {(messageType !== 'Practice_Message' && messageType !== 'OnBoarding') ? row.subject : getDelayLabel(row.delay)}
                    </Typography>
                </Box>
            </TableCell>
            {(messageType === 'Practice_Message' || messageType === 'OnBoarding') ?
                <TableCell align="left">
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(event) => handleChangeStatus(event)}
                                checked={row.is_enabled}
                                color="primary"
                            />
                        }
                        label={`${row.is_enabled ? 'Published' : 'Unpublished'}`}
                    />
                </TableCell> :
                <TableCell>
                    <Box
                        sx={{ ml: 0 }}
                    >
                        <Typography
                            variant="body"
                            noWrap
                            sx={messageTableCategory}
                        >
                            {row.category.toLowerCase()}
                        </Typography>
                    </Box>
                </TableCell>
            }
            {messageType === 'engagement_templates' && <TableCell align='center'>
                <Box
                    sx={{ ml: 0 }}
                >
                    <Typography
                        variant="body"
                        noWrap
                    >
                        -
                    </Typography>
                </Box>
            </TableCell>}

            {messageType !== 'LeadTemplate' ?
                <TableCell align="right" >
                    <TableMoreMenu
                        open={openMenu}
                        sx={{ width: '200px' }}
                        onOpen={handleOpenMenu}
                        onClose={handleCloseMenu}
                        actions={
                            <MenuList>
                                {(getUserList()[0]?.user?.is_superuser ? true : !row?.created_by?.is_superuser) && <MenuItem
                                    sx={{ color: common.black }}
                                    onClick={() => { onSelectRow(); handleCloseMenu(); }}
                                >
                                    <Iconify
                                        icon={'solar:pen-bold'}
                                        sx={{ mr: 0 }}
                                    />
                                    <ListItemText
                                        primary="Edit"
                                        primaryTypographyProps={messageTableMenuItem}
                                    />
                                </MenuItem>}
                                {(messageType === 'Template' || messageType === 'engagement_templates') && <MenuItem
                                    sx={{ color: common.black }}
                                    onClick={handleDuplicateTemplate}
                                >
                                    <Iconify
                                        icon={'bxs:copy'}
                                        sx={{ mr: 0 }}
                                    />
                                    <ListItemText
                                        primary="Duplicate"
                                        primaryTypographyProps={messageTableMenuItem}
                                    />
                                </MenuItem>}
                                {((messageType === 'Template' && row?.channel === 'EMAIL') || messageType === 'Practice_Message' || messageType === 'OnBoarding') &&
                                    <MenuItem
                                        sx={{ color: common.black }}
                                        onClick={() => { setDialogue(true); handleCloseMenu(); }}
                                    >
                                        <Iconify
                                            icon={'eva:email-fill'}
                                            sx={{ mr: 0 }}
                                        />
                                        <ListItemText
                                            primary="Send Test"
                                            primaryTypographyProps={messageTableMenuItem}
                                        />
                                    </MenuItem>
                                }
                                {row?.channel === 'EMAIL' && <MenuItem
                                    sx={{ color: common.black }}
                                    onClick={() => { handleTemplatePreview(row); setOpenMenuActions(null); }}
                                >
                                    <Iconify
                                        icon={'icon-park-outline:preview-close'}
                                        sx={{ mr: 0 }}
                                    />
                                    <ListItemText
                                        primary="Preview"
                                        primaryTypographyProps={messageTableMenuItem}
                                    />
                                </MenuItem>}
                                {(shouldDelete()) && <><Divider sx={{ borderStyle: 'dashed' }} />
                                    <MenuItem
                                        sx={{ color: 'rgba(255, 72, 66, 1)' }}
                                        onClick={() => { handleOpenConfirm(); handleCloseMenu(); }}
                                    >
                                        <Iconify icon={'solar:trash-bin-trash-bold'} sx={{ mr: 0 }} />
                                        <ListItemText
                                            primary="Delete"
                                            primaryTypographyProps={messageTableMenuItem}
                                        />
                                    </MenuItem></>}
                            </MenuList>
                        }
                    />
                </TableCell>
                :
                <>
                    <TableCell>
                        <MenuList
                            sx={{ display: 'flex' }}
                        >
                            <MenuItem
                                sx={{ color: 'primary.main' }}
                                onClick={handlePreview}
                            >
                                <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={engagementMenuItemPreview}
                                >
                                    Preview
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{ color: 'primary.main' }}
                                onClick={handleUse}
                            >
                                <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={engagementMenuItemUseTemplate}
                                >
                                    Use Template
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </TableCell>
                </>}
            <ConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title="Delete"
                content={<Box sx={{ fontSize: '18px', mb: '20px' }}>{'Are you sure you want to delete?'}</Box>}
                contentId={
                    superUser && <Box>
                        Template: <Box sx={{ color: widget.tagBlueColor }}>{row?.name}</Box><br />
                        {row?.assigned_practices?.length > 0 && <>Practices: <Box sx={{ color: widget.tagBlueColor }}>{row?.assigned_practices?.map((el, index, array) => <React.Fragment key={index}>{el?.name}{el?.name && array?.length - 1 !== index ? ', ' : ''}</React.Fragment>)}</Box></>}
                    </Box>
                }
                action={
                    <Button variant="contained" color="error" onClick={handleCloseConfirm}>
                        Delete
                    </Button>
                }
            />
            {openDialogue && <FormDialog
                open={openDialogue}
                handleClose={handleSend}
                data={row.message?.type || row?.channel}
            />}
        </TableRow>
    );
}

MessageTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onSelectRow: PropTypes.func,
    handleDuplicate: PropTypes.func,
    onChangeStatus: PropTypes.func,
    practiceMessageTable: PropTypes.bool,
    onSendTestMail: PropTypes.func,
    onDeleteRow: PropTypes.func,
    messageType: PropTypes.string,
    handleUserTemplate: PropTypes.func,
    superUser: PropTypes.bool,
    handleRowSelection: PropTypes.func,
    selectedRow: PropTypes.any,
    campaign: PropTypes.any,
    handleTemplatePreview: PropTypes.func,
};