import { useState } from 'react';
// @mui
import { Stack, Box, IconButton, Tooltip } from '@mui/material';
import AddUserPopup from 'src/components/dialogPopUp/AddUserPopup';
// config
import { NAV } from '../../../config';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import { Iconify } from '../../../components';
import { NavSectionMini } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavToggleButton from './CollapseButton';
import { userIconWrapperStyle, userIconInnerStyle } from '../../../styles/NavBarVerticalStyle';

// ----------------------------------------------------------------------

export default function NavMini() {
    const [userOpen, setUserOpen] = useState(false);

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_DASHBOARD_MINI },
                bgcolor: 'common.white',
            }}
        >
            <NavToggleButton
                sx={{
                    top: 22,
                    left: NAV.W_DASHBOARD_MINI - 12,
                }}
            />

            <Stack
                sx={{
                    pb: 2,
                    height: 1,
                    position: 'fixed',
                    width: NAV.W_DASHBOARD_MINI,
                    borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    ...hideScrollbarX,
                }}
            >

                <NavSectionMini data={navConfig} />
                <Box sx={{ flexGrow: 1, ...userIconWrapperStyle }} >
                    <Box sx={{ ...userIconInnerStyle, justifyContent: 'center' }}>
                        <Tooltip title="Add User" arrow>
                            <IconButton onClick={() => setUserOpen(true)} sx={{ p: 0 }}>
                                <Iconify icon={'wpf:adduser'} width={18} height={18} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Stack>
            <AddUserPopup userOpen={userOpen} setUserOpen={setUserOpen} />
        </Box>
    );
}
