import { isArray } from 'lodash';
import moment from 'moment';
import {
    updateAppointmentDateFormat,
    updateArchiveDateFormat,
    updateAttendedDateFormat,
    updateconsultationDateFormat,
    updateDateFormat,
    updateLastContactDateFormat,
} from 'src/redux/slices/lead';
import { dispatch } from 'src/redux/store';

export const handleChangeDateFormat = (e, data, setValue) => {
    switch (data) {
        case 'archived_at':
            localStorage.setItem('dateArchiveFormat', e.target.value);
            dispatch(updateArchiveDateFormat(e.target.value));
            setValue('archiveTitle', e.target.value);
            break;
        case 'created_at':
        case 'create_date':
            localStorage.setItem('dateFormat', e.target.value);
            dispatch(updateDateFormat(e.target.value));
            setValue('title', e.target.value);
            break;
        case 'lastContact':
            localStorage.setItem('lastContactFormat', e.target.value);
            dispatch(updateLastContactDateFormat(e.target.value));
            setValue('lastContactTitle', e.target.value);
            break;
        case 'date_attended':
            localStorage.setItem('attendedFormat', e.target.value);
            dispatch(updateAttendedDateFormat(e.target.value));
            setValue('attendedTitle', e.target.value);
            break;
        case 'lead_treatments__appointments__datetime':
            localStorage.setItem('appointmentFormat', e.target.value);
            dispatch(updateAppointmentDateFormat(e.target.value));
            setValue('appointmentsTitle', e.target.value);
            break;
        case 'consultation_date':
            localStorage.setItem('consultationDateFormat', e.target.value);
            dispatch(updateconsultationDateFormat(e.target.value));
            setValue('consultationDateTitle', e.target.value);
            break;
        default:
            break;
    }
};

export const getTransitionDate = (logs) => {
    const val = logs?.filter((el) => el.transition === 'to_treatment_started');
    if (isArray(val) && val.length > 1) {
        const latestDate = moment.max(val.map((ele) => moment(ele.timestamp, 'DD-MM-YYYY HH:mm:ss')));
        return latestDate;
    }
    if (isArray(val)) {
        return val?.[0]?.timestamp;
    }
    return '';
};

export const showArchivedDate = (data, row) => {
    const dateTime = row?.archived_at;
    if (data === 'date_format' && dateTime) {
        const formattedDate = moment(dateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
        const formattedTime = moment(dateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
        return `${formattedDate} <span>at ${formattedTime}</span>`;
    }
    return row.archived_at_human ?? '';
};