import PropTypes from 'prop-types';
import { useEffect, useReducer } from 'react';
// utils
import { isEmpty } from 'lodash';
import { getParsedData, localStorageGetItem, localStorageRemoveItem } from '../utils/storage-available';
import axios from '../utils/axios';
import { callApi } from '../utils/apiCall';
import { isValidToken, setSession } from '../utils/jwt';
import { updatePracticeId } from '../redux/slices/practiceTreatmentList';
import { setReminderData, setToDoData } from '../redux/slices/toDoNotifications';
import { setEmailNotifications, setWpNotifications } from '../redux/slices/liveNotificationSlice';
import { AuthContext } from './AuthContext';

// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const refreshToken = localStorage.getItem('refreshToken');

                if (accessToken && isValidToken(refreshToken)) {
                    setSession(accessToken, refreshToken);

                    const response = await callApi('/api/v1/auth/user/');
                    if (response.status === 200) {
                        const user = response.data;
                        dispatch({
                            type: 'INITIALIZE',
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        });
                        return;
                    }
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                dispatch(updatePracticeId(null));
                localStorage.removeItem('practiceId');
                window.location.reload();
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const login = async (email, password) => {
        const response = await axios.post('/api/v1/auth/login/?format=json', {
            email,
            password,
        });
        // eslint-disable-next-line camelcase
        const { access_token, user, refresh_token } = response.data;
    
        setSession(access_token, refresh_token);

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        });
    };

    const register = async (data) => {
        await axios.post('/api/v1/register/', data);
    };

    const confirmemail = async (key, emailVerification) => {
        try {
            const response = await axios.post('/api/v1/register/account-confirm-email/', key);
            if(response.status === 200) {
                emailVerification({ message: 'Email confirm successfully' });
            }
        } catch (error) {
            emailVerification({ error: error.detail });
        }
    };

    const updateCurrentUser = async (user) => {
        dispatch({
            type: 'INITIALIZE',
            payload: {
                isAuthenticated: true,
                user,
            },
        });
    };

    const logout = async () => {
        setSession(null, null);
        dispatch(updatePracticeId(null));
        localStorageRemoveItem('practiceId');
        dispatch(setToDoData([]));
        dispatch(setEmailNotifications([]));
        dispatch(setWpNotifications([]));
        dispatch(setReminderData([]));
        const intervalId = localStorageGetItem('intervalId');
        const timeoutId = localStorageGetItem('timeoutId');
        const timeoutIdV2 = localStorageGetItem('intervalIdV2');
        const snoozedData = getParsedData('timeout_ids');
        if (!isEmpty(snoozedData)) {
            snoozedData?.forEach((element) => {
                clearTimeout(element);
            });
        }
        clearInterval(intervalId);
        clearInterval(timeoutIdV2);
        clearTimeout(timeoutId);
        localStorageRemoveItem('intervalStartTime');
        localStorageRemoveItem('intervalId');
        localStorageRemoveItem('timeoutId');
        localStorageRemoveItem('intervalIdV2');
        localStorageRemoveItem('reminderData');
        localStorageRemoveItem('timeout_ids');
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'jwt',
                login,
                logout,
                register,
                confirmemail,
                updateCurrentUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export { AuthProvider };
