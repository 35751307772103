import { createSlice } from '@reduxjs/toolkit';

// utils

import { callApi } from '../../utils/apiCall';
import { updateError } from './user';

//
import { dispatch } from '../store';
import { getPracticeId } from './clincheck';

// ------------------------------

const initialState = {
    isLoading: false,
    archiveLeadDetails: []
};

const slice = createSlice({
    name: 'archive',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        endLoading(state) {
            state.isLoading = false;
        },
        setArchiveDetails(state, action) {
            state.archiveLeadDetails = action.payload;
        }
    }
});

export default slice.reducer;

export const { setArchiveDetails, startLoading, endLoading } = slice.actions;

export function getArchiveDetails(params) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/archived/${params?.leadId}/`);
            dispatch(slice.actions.endLoading());
            if (response.status === 200) {
                dispatch(slice.actions.setArchiveDetails(response.data));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(slice.actions.endLoading());
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

