import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// form
import { useFormContext } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
import { isEmpty } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';
import { updateError } from '../../redux/slices/user';
import { uploadImage } from '../../redux/slices/practiceMessage';
import { getMediaImages } from '../../redux/slices/campaign';
import { dispatch, useSelector } from '../../redux/store';
import Editor from '../editor/Editor';

// ----------------------------------------------------------------------

export default function RHFEditor({ body, setBody, templateDetails, campaign, EditorMessage, name, screenType, onOpenCustomLib, onCloseCustomLib, setInputFiles, setBase, onSubmit, ...other }) {
    const { type } = { ...other };
    const isLoaded = useRef(true);
    const { superData } = useSelector((state) => state.superAdmin);
    const {
        watch,
        setValue,
        formState: { isSubmitSuccessful, errors },
    } = useFormContext();

    const values = watch();

    const { mergeTags } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [customMergeTags, setCustomMergeTags] = useState({});
    const [attachment, setAttachment] = useState([]);
    const [fileSize, setFileSize] = useState({});
    const autoSaveFunction = useDebounce(values?.unlayer_html, 5000);
    const [isAutoSaveOn, setIsAutoSaveOn] = useState(false);

    const checkValidationForAutoSave = () => !isEmpty(values.name) && !isEmpty(values.subject) && !isEmpty(values.category) && !isEmpty(superData.practices);

    useEffect(() => {
        const handleAutoSave = () => {
            if (isAutoSaveOn && !isEmpty(templateDetails) && checkValidationForAutoSave() && campaign && values.use_unlayer && autoSaveFunction) {
                setIsAutoSaveOn(false);
                onSubmit(values, false);
            }
        };
        handleAutoSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSaveFunction]);

    useEffect(() => {
        const latestName = values.use_unlayer ? 'unlayer_html' : name;
        if (values[latestName] === '<p><br></p>') {
            setValue(latestName, '', {
                shouldValidate: !isSubmitSuccessful,
            });
        }
    }, [isSubmitSuccessful, name, values.use_unlayer, setValue, values]);

    useEffect(() => {
        if (mergeTags) {
            const modifiedData = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const mergeTag of mergeTags) {
                modifiedData[mergeTag.tag] = {
                    name: mergeTag.tag,
                    sample: mergeTag.tag,
                    value: `{{${mergeTag.tag}}}`,
                };
            }
            setCustomMergeTags(modifiedData);
        }
    }, [mergeTags]);

    const handleImageLibrary = (data, done) => {
        const handleClick = (e) => {
            if (e.target.classList.contains('library-image') && e.target.closest('#mediaLibrary')) {
                if (e.target.getAttribute('src')) {
                    onCloseCustomLib();
                    setInputFiles(null);
                    done({ url: e.target.getAttribute('src') });
                    dispatch(getMediaImages({ practiceId, limit: 50, offset: 0, shouldReset: true }));
                }
                // Remove the event listener after handling the click
                document.removeEventListener('click', handleClick);
            }
        };

        onOpenCustomLib();

        // Attach the event listener
        document.addEventListener('click', handleClick);
    };

    const handleIsUnlayerChange = (event) => {
        if (event.target.checked) {
            if (type === 'email_editor') {
                setValue('body', body || '');
            }
            isLoaded.current = true;
        }
        setValue('use_unlayer', event.target.checked);
    };

    const handleChangeEvent = (value, type) => {
        setIsAutoSaveOn(true);
        if (values.use_unlayer) {
            EditorMessage?.current?.editor?.setMergeTags(customMergeTags);
            EditorMessage?.current?.editor?.registerCallback('image', (file, done) => {
                const reader = new FileReader();
                reader.readAsDataURL(file?.accepted[0]);
                reader.onload = () => {
                    const detail = {
                        image: reader.result
                    };
                    dispatch(uploadImage(detail, (data) => {
                        if (data.success) {
                            const { image } = data.data;
                            if (image) {
                                done({ progress: 100, url: image });
                            }
                            return;
                        }
                        dispatch(updateError({ success: false, message: data.message.image.toString() }));
                    }));
                };
            });
            EditorMessage?.current?.editor?.exportHtml((data) => {
                const { html, design } = data;
                setValue('unlayer_html', html);
                setValue('unlayer_template', design);
            }, {
                minify: true,
            });
            return;
        }
        if (type === 'body' && value !== '<p><br></p>') {
            setValue('body', value);
            setBody(value);
        } else if (value === '<p><br></p>') {
            setValue('body', '');
            setBody('');
        }
    };

    const fetchData = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const handlePromiseData = async (data, base64Array) => {
        await Promise.all(
            data.map(async (obj) => ({ file: await fetchData(obj.file) })),
        ).then((value) => {
            value.map(data => base64Array.push(data));
        });
    };


    const handleAttachment = async (event, value, perform) => {
        const base64Array = [];

        const newFiles = perform === 'clear' ? [] : event;

        if (perform === 'clear') {
            const data = attachment.attachments.filter(res => res.file !== value);
            setAttachment({ ...attachment, attachments: data });
            handlePromiseData(data, base64Array);
            handlePromiseData(event, base64Array);

            setBase({
                files: base64Array,
            });
            return;
        }

        if (attachment?.attachments?.length > 0) {
            handlePromiseData(attachment.attachments, base64Array);
        }

        const largerFile = newFiles.filter(value => value.size > 20971520);

        if (largerFile?.length > 0 && newFiles.length > 0) {
            setFileSize({ file: largerFile[0]?.name, size: largerFile[0]?.size, });
        } else {
            setFileSize({});
        }

        newFiles.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                base64Array.push({ file: reader.result });
            };
            reader.onerror = (error) => {
                dispatch(updateError({
                    success: false,
                    message: JSON.stringify(error)
                }));
            };
        });

        setBase({
            files: base64Array,
        });
    };

    return <Editor
        id={name}
        value={body}
        EditorMessage={EditorMessage}
        onChange={(value) => { handleChangeEvent(value, 'body'); }}
        error={values.use_unlayer ? !!errors.unlayer_html : !!errors.body}
        helperText={
            (values.use_unlayer ? !!errors.unlayer_html : !!errors.body) && (
                <FormHelperText error={values.use_unlayer ? !!errors.unlayer_html : !!errors.body} sx={{ px: 2 }}>
                    {values.use_unlayer ? (errors.unlayer_html?.message || 'This field id required') : (errors.body?.message || 'This field id required')}
                </FormHelperText>
            )
        }
        campaign={campaign}
        screenType={screenType}
        useUnlayer={values.use_unlayer}
        handleAttachment={handleAttachment}
        fileSize={fileSize}
        onLoad={() => {
            if (isLoaded?.current) {
                isLoaded.current = false;
                EditorMessage?.current?.editor?.registerCallback('selectImage', handleImageLibrary);
                if (!isEmpty(templateDetails?.unlayer_template)) {
                    EditorMessage?.current?.editor?.loadDesign(templateDetails?.unlayer_template);
                    EditorMessage?.current?.editor?.setMergeTags(customMergeTags);
                }
                if (!isEmpty(values.unlayer_template)) {
                    EditorMessage?.current?.editor?.loadDesign(values.unlayer_template);
                    EditorMessage?.current?.editor?.setMergeTags(customMergeTags);
                }
            }
        }}
        handleIsUnlayerChange={handleIsUnlayerChange}
        {...other}
    />;
}

RHFEditor.propTypes = {
    name: PropTypes.string,
    screenType: PropTypes.string,
    EditorMessage: PropTypes.any,
    onOpenCustomLib: PropTypes.func,
    onCloseCustomLib: PropTypes.func,
    setInputFiles: PropTypes.func,
    setBase: PropTypes.func,
    campaign: PropTypes.bool,
    templateDetails: PropTypes.any,
    body: PropTypes.string,
    setBody: PropTypes.func,
    onSubmit: PropTypes.func,
};