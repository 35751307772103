import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Button, Tooltip, Typography, IconButton, ToggleButton } from '@mui/material';
// utils
import { fDate } from '../../../utils/formatTime';
// hooks
import { useResponsive } from '../../../hooks';
// components
import { Iconify } from '../../../components';
// constant
import { VIEW_OPTIONS } from '../../../constants/CalenderConst';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

export default function CalendarToolbar({ date, view, onToday, onNextDate, onPrevDate, onChangeView, onOpenFilter }) {
    const isDesktop = useResponsive('up', 'sm');

    return (
        <RootStyle>
            {isDesktop && (
                <Stack direction="row" spacing={0.5}>
                    {VIEW_OPTIONS.map((viewOption) => (
                        <Tooltip key={viewOption.value} title={viewOption.label}>
                            <ToggleButton
                                value={view}
                                selected={viewOption.value === view}
                                onChange={() => onChangeView(viewOption.value)}
                                sx={{ width: 32, height: 32, padding: 0, border: 0 }}
                            >
                                <Iconify icon={viewOption.icon} width={20} height={20} />
                            </ToggleButton>
                        </Tooltip>
                    ))}
                </Stack>
            )}

            <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton onClick={onPrevDate}>
                    <Iconify icon="eva:arrow-ios-back-fill" width={20} height={20} />
                </IconButton>

                <Typography variant="h5">{fDate(date)}</Typography>

                <IconButton onClick={onNextDate}>
                    <Iconify icon="eva:arrow-ios-forward-fill" width={20} height={20} />
                </IconButton>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
                {isDesktop && (
                    <Button size="small" color="error" variant="contained" onClick={onToday}>
                        Today
                    </Button>
                )}

                <IconButton onClick={onOpenFilter}>
                    <Iconify icon="ic:round-filter-list" />
                </IconButton>
            </Stack>
        </RootStyle>
    );
}

CalendarToolbar.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    onToday: PropTypes.func,
    onNextDate: PropTypes.func,
    onPrevDate: PropTypes.func,
    onChangeView: PropTypes.func,
    onOpenFilter: PropTypes.func,
    view: PropTypes.oneOf(['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek']),
};