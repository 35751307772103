// lib
import { Box, Button, Divider, IconButton, Link, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

// redux
import { store } from 'src/redux/store';
import { replyMessages, sentMessages } from 'src/redux/slices/mail';

// components
import { Iconify } from 'src/components';
import { Label } from 'src/components/labels';
import { clearButton } from 'src/styles/EmailMessageComposeStyle';
import { Editor } from './editor';
// utils
import { applyStylesToTags } from '../../../../utils/applyStylesToTags ';

const ReplyEditor = ({
    handleClearUploaded,
    fileSize,
    getLastPathSegment,
    handleAttachment,
    files,
    index,
    msg,
    onChanged,
    attachment,
    handlePromiseData,
    mail,
    base,
    handleClick,
    mailType,
    selectedMailId,
    leadDetail,
    successDone,
    isFromSideBar,
    leadID,
    subject,
    selectedMailInfo
}) => {
    const { dispatch } = store;
    const theme = useTheme();
    const getLeadId = () => {
        if (isFromSideBar === 'true') {
            if (leadID !== undefined || leadID !== '' || leadID !== 'undefined') {
                return leadID;
            }
            return mail.lead;
        }
        return leadDetail.id;
    };
    return (
        <Stack
            spacing={2}
            sx={{
                p: (theme) => theme.spacing(2, 2, 2, 2),
            }}
        >
            <Box sx={{ p: '0' }}>
                <Box sx={{ bgcolor: theme.palette.grey[200], borderRadius: 1, display: 'flex' }} alignItems="center">
                    <IconButton
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        <Iconify icon="eva:attach-2-fill" />
                    </IconButton>
                    <Box sx={{}}>
                        {files?.map((value, index) => (
                            <Box sx={{ display: 'flex', p: '0', flexWrap: 'wrap' }} key={index}>
                                <Label
                                    sx={{ mr: 1 }}
                                > Uploaded files : </Label>
                                <Typography
                                    noWrap
                                    key={value.name}
                                    variant="subtitle2"
                                    sx={{
                                        color: 'black'
                                    }}>
                                    {value.name}
                                </Typography>
                                <IconButton
                                    sx={clearButton}
                                    color="error"
                                    onClick={() => handleClearUploaded(value?.name)}>
                                    <Iconify icon="basil:cross-outline" height={18} width={18} />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    {fileSize !== undefined && Object.values(fileSize)?.length > 0 && (
                        <Box sx={{
                            p: '0px 18px 16px',
                            mt: '-12px'
                        }}>
                            <Typography
                                color={'error'}
                                variant="subtitle2"
                                sx={{ fontSize: '12px' }}
                            >
                                Maximum File Size should be 20MB!{' '}
                            </Typography>
                        </Box>
                    )}
                    {attachment !== undefined && Object.values(attachment).length > 0 && attachment.attachments?.length > 0 && (
                        <>
                            <Divider sx={{ mb: 1 }} />
                            {attachment.attachments.map((value, index) => (
                                <Box sx={{
                                    display: 'flex',
                                    p: '0 16px 18px',
                                    flexWrap: 'wrap'
                                }} key={index}>
                                    <Label sx={{ mr: 1 }}> Attachment : </Label>
                                    <Link
                                        noWrap
                                        key={value.file}
                                        href={value.file}
                                        variant="body2"
                                        target="_blank"
                                        rel="noopener"
                                        sx={{ color: 'black' }}
                                    >
                                        {getLastPathSegment(value.file)}
                                    </Link>
                                    <IconButton
                                        sx={{ p: 0, ml: 1 }}
                                        color="error"
                                        onClick={() => handleAttachment(files, value.file, 'clear')}
                                    >
                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                    </IconButton>
                                </Box>
                            ))}
                        </>
                    )}
                </Box>
            </Box>
            <Editor
                simple
                id={`reply-mail${index}`}
                value={msg}
                onChanged={(e) => { onChanged(e); }}
                placeholder="Type a message"
            />
            <Stack
                direction="row"
                alignItems="center"
                flexGrow={1}
                sx={{
                    justifyContent: 'flex-end',
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={msg === undefined || msg === '<p><br></p>' || Object.keys(msg).length === 0}
                    onClick={async () => {
                        const base64Array = [];

                        if (attachment.attachments?.length > 0) {
                            await handlePromiseData(attachment.attachments, base64Array);
                        }
                        const recipient = mail?.recepients[0];
                        const matchResult = recipient?.match(/<([^>]+)>/);
                        const email = matchResult ? matchResult[1] : recipient;
                        const data = {
                            message: msg,
                            attachments: base.files || base64Array || mail?.attachments,
                            to: selectedMailInfo?.lead?.lead_contact?.email || email
                        };
                        if (mailType === 'Drafts') {
                            const content = msg;
                            const modifiedContent = applyStylesToTags(content);
                            const data = {
                                lead: getLeadId(),
                                category: 'COMMUNICATION',
                                channel: 'EMAIL',
                                subject,
                                body: modifiedContent,
                                attachments: base.files || base64Array || mail?.attachments,
                            };
                            dispatch(sentMessages({ data, title: 'Send', successDone }));
                        } else {
                            dispatch(replyMessages({ id: mail.thread, messageId: mail.id, data, mailType, selectedMailId }));
                        }

                    }}
                    endIcon={<Iconify icon="iconamoon:send-fill"
                    />
                    }
                >
                    {mailType === 'Drafts' ? 'Send' : 'Reply'}
                </Button>
            </Stack>
        </Stack>
    );
};


ReplyEditor.propTypes = {
    handleClearUploaded: PropTypes.func,
    fileSize: PropTypes.number,
    getLastPathSegment: PropTypes.func,
    handleAttachment: PropTypes.func,
    files: PropTypes.array,
    index: PropTypes.number,
    msg: PropTypes.string,
    onChanged: PropTypes.func,
    attachment: PropTypes.object,
    handlePromiseData: PropTypes.func,
    mail: PropTypes.object,
    base: PropTypes.object,
    handleClick: PropTypes.func,
    mailType: PropTypes.string,
    selectedMailId: PropTypes.number,
    leadDetail: PropTypes.object,
    successDone: PropTypes.func,
    isFromSideBar: PropTypes.bool,
    leadID: PropTypes.number,
    subject: PropTypes.string,
    selectedMailInfo: PropTypes.object,
};
export default ReplyEditor;