import { styled } from '@mui/material/styles';

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export const outlinedInputStyle = {
    p: 0,
    textAlign: 'center',
    width: { xs: 36, sm: 56 },
    height: { xs: 36, sm: 56 },
};