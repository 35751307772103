import { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// facebook
import { FacebookProvider } from 'react-facebook';

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

// redux
import * as Sentry from "@sentry/react";
import ReduxProvider from './redux/reduxProvider';

// contexts
import { SettingsProvider, SettingsDrawer } from './components/settings';
import { AuthProvider } from './contexts/JWTContext';

// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';

// components
import IntervalComponentV3 from './components/NotificationHandler/IntervalComponentV3';
import { MotionLazy } from './components/animate/motion-lazy';
import SnackbarProvider from './components/snackbar/snackbar-provider';
import { ErrorHandler } from './components';
import './App.css';
import { defaultSettings } from './config';
import LiveNotificationHandler from './components/NotificationHandler/LiveNotificationHandler';
import AppErrorBoundary from './AppErrorBoundary';

LicenseInfo.setLicenseKey('1376f60f7fa17db0b1e1c94fbd0986f0Tz04NTQ0MyxFPTE3NDA4MjEzNjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// ----------------------------------------------------------------------

export default function App() {

    useEffect(() => {
        const domain = window.location.hostname;

        if (domain === 'dental-sem2.hupp.in' || domain === 'dental-sem.hupp.in') {
            const meta = document.createElement('meta');
            meta.name = 'robots';
            meta.content = 'noindex';
            document.getElementsByTagName('head')[0].appendChild(meta);
        }
    }, []);

    return (
        <Sentry.ErrorBoundary fallback={<AppErrorBoundary />} showDialog>
            <AuthProvider>
                <ReduxProvider>
                    <FacebookProvider appId="573147954687607">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SettingsProvider defaultSettings={defaultSettings} >
                                <ThemeProvider>
                                    <MotionLazy>
                                        <SnackbarProvider>
                                            <SettingsDrawer />
                                            <IntervalComponentV3 />
                                            <LiveNotificationHandler />
                                            <ErrorHandler />
                                            <Router />
                                        </SnackbarProvider>
                                    </MotionLazy>
                                </ThemeProvider>
                            </SettingsProvider>
                        </LocalizationProvider>
                    </FacebookProvider>
                </ReduxProvider>
            </AuthProvider>
        </Sentry.ErrorBoundary>
    );
}
