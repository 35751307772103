import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Iconify } from 'src/components';
import { getIcon, getStatus } from 'src/utils/leadDetailUtils';
import { statusStyle } from 'src/styles/OverView';

const TreatmentStatus = ({ status }) => (
    <Button sx={statusStyle(status)}>
        <Iconify icon={getIcon(status)} width={24} height={24} />
        {getStatus(status)}
    </Button>
);

export default TreatmentStatus;

TreatmentStatus.propTypes = {
    status: PropTypes.string,
};