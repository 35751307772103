import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { useSelector } from 'src/redux/store';
import { RHFSelect, RHFTextField } from '../hook-form';
import { testFieldWrapperStyle } from '../../styles/CallScripts';
import { menuItemStyle } from '../../styles/PersonalDetailsStyle';

const CustomCombinedFields = () => {
    const { salutationOptions } = useSelector((state) => state.schema);

    return (
        <Box sx={testFieldWrapperStyle}>
            <RHFSelect
                name={'title'}
                label='Title'
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
                {salutationOptions?.map((option, index) => (
                    <MenuItem
                        key={index}
                        value={option.value}
                        sx={menuItemStyle}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </RHFSelect>
            <RHFTextField name='firstName' placeholder='First name (Required)' />
            <RHFTextField name='lastName' placeholder='Last name' />
        </Box>
    );
};

export default CustomCombinedFields;