/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// libraries
import moment from 'moment';
import GoogleAutocomplete from 'react-google-autocomplete';
import { isEmpty } from 'lodash';
import { useDebounce } from 'use-lodash-debounce';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    useForm,
    Controller
} from 'react-hook-form';
import { fetchMergeTagValueForLeads, fetchMergeTagValueForTreatments, resetTreatmentAndLead } from 'src/redux/slices/mergeTags';
import { Box, Card, Typography, Grid, FormLabel, Button } from '@mui/material';
// components
import ReactHtmlParser from 'react-html-parser';
import TreatmentEditPopover from '../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import UserSearchDropdown from '../UserSearchDropdown';
import Iconify from '../Iconify';
import { FormProvider, RHFTextField, RHFRadioGroup } from '../hook-form';
import TreatmentFlowComponent from '../treatmentComponents/TreatmentFlowComponent';
import CustomScriptDescription from './CustomScriptDescription';
import CustomChoiceFieldComponent from './CustomChoiceFieldComponent';
import CustomScriptMobileDatePicker from './CustomScriptMobileDatePicker';
import ScriptDateTimePicker from './ScriptDateTimePicker';
import CustomCombinedFields from './CustomCombinedFields';
import CustomLeadNameAutocomplete from './CustomLeadNameAutocomplete';
// redux
import { getCurrentTreatmentIdV2 } from '../../redux/slices/clincheck';
import { createSubmission } from '../../redux/slices/callScript';
import { useSelector, dispatch } from '../../redux/store';
import { clearTaskLead, updateAssignToUser, UpdateTreatmentV3 } from '../../redux/slices/lead';
// hooks/validation/utils
import { useAddTreatment, useAuth } from '../../hooks';
import { SingleScriptPreviewSchema } from '../../validations/validations';
import capitalizeFirstLetter from '../../utils/capitalizedFunc';
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableBookedWith
} from '../../utils/allTableTab';
import { getTreatmentName } from '../../utils/multiTreatmentFlow';
import { getAddressField, handleAddressData, handleSearch, handleSuccessMessage, searchLeadHandler, setAddressData, submissionFunc } from '../../utils/callScriptCommonFunc';
import { inputKeys } from '../../constants/LeadDetailConst';
// style
import { common, error, grey } from '../../theme/palette';
import { gridStyle } from '../../styles/AddAppointmentStyle';
import { backIconStyle, closeIconStyle, singleScriptHeaderBoxStyle, titleDesktopStyle, requiredTextStyle, mainLabelBoxStyle, labelBoxStyle, unableContactButtonStyle, appointmentButtonStyle, bookingButtonStyle, nameTypoStyle, testFieldWrapperStyle, leadAddressWrapper, leadAddressFieldWrapper, submitBtnIconStyle } from '../../styles/CallScripts';

const SingleScriptPreview = ({ isScriptTCO, scriptData, BackButtonClick, onClose, followLead, onOpenAppointmentNotBooked, setLeadId, onOpenAppointmentBooked, setTreatmentId }) => {
    const { user } = useAuth();
    const { lightSlateBlue } = common;
    const mergeTags = useSelector((state) => state.mergeTags);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [expand, setExpand] = useState(false);
    const fieldData = scriptData?.sections?.map((section) => {
        const updatedFields = section?.fields?.filter((field) => field?.is_enabled);
        return { ...section, fields: updatedFields };
    });
    const { practice } = useSelector((state) => state.practice);
    const [searchQuery, setSearchQuery] = useState('');
    const { taskSearchLead, tableType } = useSelector((state) => state.lead);
    const debouncedValue = useDebounce(searchQuery, 1000);
    const { referral } = useSelector((state) => state.schema);
    const referralValue = (value, data) => referral?.length > 0 && referral?.filter((res) => res[value] === data.referral);
    const [newtreatmentData, setNewTreatment] = useState('');
    const [leadTreatment, setLeadTreatment] = useState('');
    const [leadTreatmentValue, setLeadTreatmentValue] = useState('');
    const [leadTreatmentTeethValue, setLeadTreatmentTeethValue] = useState(null);
    const contactCount = TabCount(tableType);
    const pageNumber = TablePageNumber(tableType);
    const perPage = TablePerPage(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const status = TableStatus(tableType);
    const searchTagsValue = TableFilterTags(tableType);
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const referralTable = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const [assignToState, setAssignToState] = useState(followLead?.assignees?.length > 0 ? followLead?.assignees?.map((el) => el) : []);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));
    const [priceAmountError, setPriceAmountError] = useState(false);
    const [teethAmountError, setTeethAmountError] = useState(false);
    const startDate = searchFilterDate ? searchFilterDate?.[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate?.[0]?.endDate : null;

    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);

    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);

    const {
        toggle: EditTreatment,
        onOpen: onEditTreatment,
        onClose: onCloseEditTreatment
    } = useAddTreatment();

    useEffect(() => {
        handleSearch(searchQuery);
    }, [debouncedValue]);

    useEffect(() => {
        if (searchQuery?.length === 0) {
            dispatch(clearTaskLead());
        }
    }, [taskSearchLead]);

    const getTreatmentPrice = (price) => {
        if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) return currentTreatmentDataGlobal?.treatmentData?.price?.toString();
        return price;
    };
    const getTreatmentTeeth = (teeth) => {
        if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) return currentTreatmentDataGlobal?.treatmentData?.leadtreatment_specification?.teeth_count;
        return teeth;
    };

    useEffect(() => {
        if (followLead) {
            setNewTreatment(getTreatmentName(followLead?.lead_treatments && followLead?.lead_treatments?.[0]?.treatment));
            setLeadTreatment(getTreatmentName(followLead?.lead_treatments && followLead?.lead_treatments?.[0]?.treatment));
            setLeadTreatmentValue(getTreatmentPrice(followLead?.lead_treatments && followLead?.lead_treatments?.[0]?.price?.toString()));
            setLeadTreatmentTeethValue(getTreatmentTeeth(followLead?.lead_treatments?.[0]?.leadtreatment_specification?.teeth_count));
            setSearchQuery(`${followLead?.first_name} ${followLead?.last_name}`);
        }
    }, [followLead]);

    const defaultValues = {
        leadName: `${followLead?.first_name} ${followLead?.last_name}`,
        lead_id: `${followLead?.id || ''}`,
        type: `${followLead?.healthcare_provider || ''}`,
        inquired: '',
        speaking_to: `${user?.salutation ? `${capitalizeFirstLetter(user?.salutation)}.` : ''} ${user?.first_name} ${user?.last_name}`,
        leadType: `${followLead?.lead_source?.type || ''}`,
        firstName: `${followLead?.first_name}`,
        lastName: `${followLead?.last_name}`,
        phone_number: `${followLead?.lead_contact?.phone}`,
        email: `${followLead?.lead_contact?.email}`,
        dob: followLead && followLead?.dob && new Date(moment(followLead?.dob, 'DD-MM-YYYY')) || null,
        treatment: `${getTreatmentName(followLead?.lead_treatments && followLead?.lead_treatments?.[0]?.treatment) || ''}`,
        teethAmount: getTreatmentTeeth(followLead?.lead_treatments?.[0]?.leadtreatment_specification?.teeth_count) || null,
        treatment_id: `${getCurrentTreatmentIdV2(followLead?.lead_treatments && followLead?.lead_treatments?.[0]?.id) || ''}`,
        treatmentValue: getTreatmentPrice(followLead?.lead_treatments && followLead?.lead_treatments?.[0]?.price?.toString()) || '',
        referral: `${followLead?.lead_source?.referral || ''}`,
        bookedWith: followLead?.assignees?.length > 0 ? followLead?.assignees?.map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`) : [],
        title: `${followLead?.salutation || 'MR'}`,
        isRegister: followLead && followLead?.is_registered_patient || false,
        preferredContactMethod: `${followLead?.lead_contact?.preferred_contact_method || ''}`,
        address_line_1: followLead && followLead?.lead_address && followLead?.lead_address?.address_line_1 || '',
        county: followLead && followLead?.lead_address && followLead?.lead_address?.county || '',
        city: followLead && followLead?.lead_address && followLead?.lead_address?.city || '',
        postcode: followLead && followLead?.lead_address && followLead?.lead_address?.postcode || '',
        address_line_2: followLead && followLead?.lead_address && followLead?.lead_address?.address_line_2 || '',
        address: '',
    };

    const methods = useForm({
        resolver: yupResolver(SingleScriptPreviewSchema(fieldData, practice?.preferences?.is_accepting_nhs_patients)),
        defaultValues,
    });

    const {
        control,
        setValue,
        formState: { errors },
        clearErrors,
        watch,
        handleSubmit
    } = methods;

    const values = watch();

    React.useEffect(() => {
        if (values?.lead_id) {
            dispatch(fetchMergeTagValueForLeads(values?.lead_id));
        }
        if (values?.lead_id && values?.treatment_id) {
            dispatch(fetchMergeTagValueForTreatments(values?.lead_id, values?.treatment_id));
        }
    }, [values?.lead_id, values?.treatment_id]);

    React.useEffect(() => () => dispatch(resetTreatmentAndLead()), []);

    const [dataMap, setDataMap] = React.useState({});

    React.useEffect(() => {
        setDataMap(prv => ({ ...prv, ...(mergeTags?.mtValPractice ?? {}), ...(mergeTags?.mtValUser ?? {}) }))
        if (!mergeTags?.mtValLoadingLeads && !mergeTags?.mtValLoadingTreatments) {
            setDataMap(prv => ({ ...prv, ...(mergeTags?.mtValTreatments ?? {}), ...(mergeTags?.mtValLeads ?? {}) }))
        }
    }, [mergeTags?.mtValLoadingLeads, mergeTags?.mtValLoadingTreatments]);

    const parseMergeTags = (datalabels) => (
        // Use regex to find all words starting with '$' followed by alphanumeric characters or underscores
        datalabels?.replace(/\$[a-zA-Z0-9_]+/g, (match) => {
            // Remove the '$' sign to get the actual key
            const key = match.slice(1);

            // Return the value from the dataMap if it exists, otherwise return the original field
            return Object.prototype.hasOwnProperty.call(dataMap, key) ? dataMap[key] : match;
        }) ?? datalabels);

    const onSubmit = (data, type) => {
        if (data.leadName !== undefined) {
            const leadData = {
                assignees: assignToState?.map((el) => el?.id) || [],
                first_name: data.firstName,
                last_name: data.lastName,
                dob: data.dob ? moment(data.dob).format('DD-MM-YYYY') : null,
                lead_contact: { email: data.email, phone: data.phone_number },
                healthcare_provider: data.type,
                lead_source: { referral: data.referral, type: data.leadType },
                salutation: data?.title?.toUpperCase(),
                is_registered_patient: data.isRegister,
            };
            const leadUpdateData = {
                first_name: data.firstName,
                last_name: data.lastName,
                dob: data.dob ? moment(data.dob).format('DD-MM-YYYY') : null,
                lead_contact: { email: data.email, phone: data.phone_number },
                healthcare_provider: data.type,
                lead_source: { referral: data.referral, type: data.leadType },
                salutation: data?.title?.toUpperCase(),
                is_registered_patient: data.isRegister,
            };
            handleAddressData({ data, leadData, leadUpdateData });
            const detail = {
                treatment: data.treatment,
                custom_price: data.treatmentValue,
                is_practice_notification_required: false,
                teethAmount: data.teethAmount
            };
            if (data.bookedWith) {
                dispatch(updateAssignToUser(leadData, data?.lead_id, practiceId, handleSuccess, type, 'leadUpdate'));
            } else {
                dispatch(updateAssignToUser(leadUpdateData, data?.lead_id, practiceId, handleSuccess, type, 'leadUpdate'));
            }
            if (detail.treatment !== leadTreatment || detail.custom_price !== leadTreatmentValue || detail.teethAmount !== leadTreatmentTeethValue) {
                dispatch(UpdateTreatmentV3({ detail, lead_id: data?.lead_id, treatment_id: data?.treatment_id }, type, handleSuccess));
            }
        }
        const textKeys = Object.entries(data).filter(key => key);
        const fieldDataArray = fieldData.flatMap(data => data.fields.map(field => field));
        const fieldDataWithValue = textKeys?.filter((el) => fieldDataArray.some((sx) => sx?.id?.toString().includes(el?.[0]?.split('_')[1]) ? { fields: sx.id, value: el[1] } : ''));
        const submissionData = {
            practice: Number(practiceId),
            lead: data?.lead_id,
            formId: scriptData?.id,
            fields: fieldDataWithValue?.map((el) => ({ field: Number(el?.[0]?.split('_')[1]), value: Array.isArray(el[1]) ? el[1].join(' | ') : el[1] }))
        };
        const dataValid = submissionData.fields;
        const valueValid = dataValid.map((el) => el.value !== undefined && el.value !== '');
        setLeadId(data?.lead_id);
        setTreatmentId(data?.treatment_id);
        if (data && valueValid.includes(true)) {
            dispatch(createSubmission(submissionData, handleSuccess, type));
        }
    };

    const handleSuccess = (data) => {
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: status,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTagsValue?.toString(),
            referralTable,
            category,
            bookedWith
        };
        const payload = { data: data.formData, leadId: data.leadId, type: data.event, fieldData, handleSuccess, scriptData, setLeadId };
        switch (data?.event) {
            case 'appointmentNotBook':
                if (data.leadId) {
                    submissionFunc(payload);
                }
                onOpenAppointmentNotBooked(reloadPayLoad);
                return;
            case 'appointmentBook':
                if (data.leadId) {
                    submissionFunc(payload);
                }
                onOpenAppointmentBooked(reloadPayLoad);
                return;
            default:
                break;
        }
        if (data?.leadUpdate) {
            handleSuccessMessage({ data });
        }
        if (data?.success) {
            onClose('finish', reloadPayLoad);
        }
        if (data.leadId) {
            submissionFunc({ data: data.formData, leadId: data.leadId, fieldData, handleSuccess, scriptData, setLeadId });
        }
        if (data.updateTreatment) {
            handleSuccessMessage({ data });
        }
    };

    const handleSearchLead = (value) => {
        if (value) {
            setValue('leadName', `${value?.first_name} ${value?.last_name}`);
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            setValue('lead_id', value?.id);
            setValue('treatment_id', getCurrentTreatmentIdV2(value?.lead_treatments?.[0]?.id));
        }
        clearErrors('leadName');
        const leadArr = value;
        if (leadArr) {
            setValue('treatmentValue', getTreatmentPrice(leadArr?.lead_treatments?.[0]?.price.toString()));
            setValue('teethAmount', leadArr?.lead_treatments?.[0]?.leadtreatment_specification?.teeth_count);
            setLeadTreatmentValue(getTreatmentPrice(leadArr?.lead_treatments?.[0]?.price.toString()));
            setLeadTreatmentTeethValue(leadArr?.lead_treatments?.[0]?.leadtreatment_specification?.teeth_count);
            clearErrors('treatmentValue');
            setNewTreatment(getTreatmentName(leadArr?.lead_treatments?.[0]?.treatment));
            setValue('treatment', getTreatmentName(leadArr?.lead_treatments?.[0]?.treatment));
            setLeadTreatment(getTreatmentName(leadArr?.lead_treatments?.[0]?.treatment));
            setValue('type', leadArr?.healthcare_provider);
            setValue('leadType', leadArr?.lead_source?.type);
            setValue('preferredContactMethod', leadArr?.lead_contact?.preferred_contact_method);
            setValue('bookedWith', leadArr?.assignees?.length > 0 ? leadArr?.assignees?.map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`) : []);
            setValue('referral', leadArr?.lead_source?.referral || '');
            setValue('firstName', leadArr?.first_name);
            setValue('lastName', leadArr?.last_name);
            setValue('email', leadArr?.lead_contact?.email);
            setValue('phone_number', leadArr?.lead_contact?.phone);
            setValue('title', leadArr?.salutation || 'MR');
            setValue('dob', leadArr?.dob ? new Date(moment(leadArr?.dob, 'DD/MM/YYYY')) : null);
            setValue('address_line_1', leadArr?.lead_address?.address_line_1 || '');
            setValue('address_line_2', leadArr?.lead_address?.address_line_2 || '');
            setValue('county', leadArr?.lead_address?.county || '');
            setValue('city', leadArr?.lead_address?.city || '');
            setValue('postcode', leadArr?.lead_address?.postcode || '');
            setValue('isRegister', leadArr?.is_registered_patient || false);
            clearErrors();
        } else {
            setValue('type', '');
            setValue('referral', '');
            setValue('leadType', '');
        }
    };

    const handleChange = (event, data) => setValue(data, event.target.value);

    const handleReferral = (event, data) => {
        if (data) setValue('referral', data.value);
    };

    const appointmentBookEvent = (key) => {
        handleSubmit((data) => onSubmit(data, key))();
    };

    const handleClickAwayLister = () => onCloseEditTreatment();

    const handleSaveTreatment = (data) => {
        setNewTreatment(data.selectedTreatment);
        setValue('treatmentValue', data.treatmentPrice);
        setValue('treatment', data.selectedTreatment);
        setValue('teethAmount', data.teethAmount);
        onCloseEditTreatment();
    };

    const handleTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const amountHandleChange = (value) => {
        setValue('treatmentValue', value);
        if (value > 0) {
            setPriceAmountError(false);
            document.body.classList.remove('points-event');
            return;
        }
        setPriceAmountError(true);
    };

    const handleChangeTeeth = (e) => {
        const value = e.target.value > 0 ? e.target.value : '';
        setValue('teethAmount', value);
        setTeethAmountError(value === 0);
    };

    const numberKeyDownFunc = (evt) => {
        const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
        const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
        const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
        if (!(isControlC || isControlV || isAllowedKey)) {
            evt.preventDefault();
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ maxWidth: expand ? '100%' : '1000px', mx: 'auto' }}>
                <Box sx={singleScriptHeaderBoxStyle}>
                    <Box sx={backIconStyle} onClick={BackButtonClick}>
                        <Iconify icon={'akar-icons:chevron-left'} width={'18px'} height={'18px'} />
                    </Box>
                    <Typography sx={titleDesktopStyle}>{isScriptTCO ? "TCO Script" : "Default Enquiry Follow-up Script"}</Typography>
                    <Box sx={closeIconStyle}>
                        <Iconify onClick={() => setExpand(!expand)} icon={'eva:expand-fill'} width={'18px'} height={'18px'} sx={{ mr: '24px', cursor: 'pointer' }} />
                        <Iconify onClick={BackButtonClick} icon={'material-symbols:close-rounded'} width={'18px'} height={'18px'} sx={{ cursor: 'pointer' }} />
                    </Box>
                </Box>
                <Box sx={{ p: '24px 24px 0' }}>
                    {fieldData?.map((item, index) => (
                        <Box sx={{ mb: '30px' }} key={index}>
                            {item.fields?.length > 0 && <Box sx={{ mb: '30px', borderBottom: `1px solid ${common.platinum}`, pb: '8px' }}>
                                <Typography sx={{ fontSize: '22px' }}>{(index === 0 ? 1 : index + 1)}. {item.name}</Typography>
                            </Box>}
                            {item.fields.map((data, index) => (
                                <Box sx={{ mb: '30px' }} key={index}>
                                    {data?.field_type &&
                                        <>
                                            <Box sx={mainLabelBoxStyle}>
                                                <Box sx={labelBoxStyle}>
                                                    <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: lightSlateBlue }} /> {data?.field_type?.field_type === 'label' ? "Ask" : "Ask"}
                                                </Box>
                                                <FormLabel style={{ color: grey[900], fontSize: "1rem" }}>
                                                    {data?.field_type?.field_type === 'label' ? (
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                                            <div>
                                                                {ReactHtmlParser(parseMergeTags(data.description))}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        parseMergeTags(data?.label)
                                                    )}{data?.is_required && <span style={requiredTextStyle}>(Required)</span>}
                                                </FormLabel>
                                            </Box>
                                        </>
                                    }
                                    {data?.field_type?.field_type === 'text' && <>
                                        <CustomScriptDescription data={data} />
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        <RHFTextField
                                            name={`text_${[data.id]}`}
                                            multiline rows={5}
                                        />
                                    </>}
                                    {data?.field_type?.field_type === 'label' && <>
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                    </>}
                                    {data?.field_type?.field_type === 'lead_search_select' && <>
                                        <CustomScriptDescription data={data} />
                                        {data?.field_type?.attribute === 'lead_search' &&
                                            <>
                                                {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                                    {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                                </Typography>}
                                                <CustomLeadNameAutocomplete
                                                    control={control}
                                                    handleSearchLead={(e, value) => handleSearchLead(value)}
                                                    searchLeadHandler={(event, value, reason) => searchLeadHandler(value, reason, setSearchQuery)}
                                                    searchQuery={searchQuery}
                                                    disabled
                                                />
                                                <CustomCombinedFields />
                                            </>
                                        }
                                    </>}
                                    {data?.field_type?.field_type === 'practice_treatment_search_select' && <>
                                        {data?.field_type?.attribute === 'treatment_search' &&
                                            <>
                                                {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                                    {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                                </Typography>}
                                                <Grid container spacing={1} sx={{ ...gridStyle('callScript'), display: 'flex', m: '10px 0 16px' }}  >
                                                    {EditTreatment && (
                                                        <TreatmentEditPopover
                                                            treatment={newtreatmentData || ''}
                                                            leadTreatmentSpecification={{ teeth_count: values.teethAmount }}
                                                            open={EditTreatment}
                                                            anchorEl={anchorEditTreatment}
                                                            handleClickAwayLister={handleClickAwayLister}
                                                            treatmentPrice={values.treatmentValue}
                                                            handleSaveTreatment={handleSaveTreatment}
                                                            isFlow
                                                            isScript
                                                        />
                                                    )}
                                                    <TreatmentFlowComponent treatmentData={values.treatment} teethAmount={values.teethAmount} handleTreatment={handleTreatment} amount={values.treatmentValue} amountError={priceAmountError} amountHandleChange={amountHandleChange} handleChangeTeeth={handleChangeTeeth} teethAmountError={teethAmountError} />
                                                </Grid>
                                            </>
                                        }
                                    </>}
                                    {data?.field_type?.field_type === 'practice_user_search_select' && <>
                                        {data?.field_type?.attribute === 'user_search' &&
                                            <>
                                                {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                                    {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                                </Typography>}
                                                <UserSearchDropdown name={'bookedWith'} control={control} placeholder='Assign to' sx={{ mt: '10px' }} assignToState={assignToState} setAssignTo={setAssignToState} />
                                            </>
                                        }
                                    </>}
                                    {data?.field_type?.field_type === 'char' && <>
                                        <CustomScriptDescription data={data} />
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        {(data?.field_type?.attribute !== 'phone' && data?.field_type?.attribute !== 'lead_email' && data?.field_type?.attribute !== 'lead_address') && <RHFTextField
                                            name={`char_${[data.id]}`}
                                            inputProps={{ maxLength: data?.field_type?.max_length }}
                                        />}
                                        {data?.field_type?.attribute === 'phone' && <>
                                            <Box sx={testFieldWrapperStyle}>
                                                <RHFTextField
                                                    name={'phone_number'}
                                                    onKeyDown={(evt) => numberKeyDownFunc(evt)}
                                                />
                                            </Box>
                                        </>}
                                        {data?.field_type?.attribute === 'lead_email' && <>
                                            <Box sx={testFieldWrapperStyle}>
                                                <RHFTextField
                                                    name={'email'}
                                                />
                                            </Box>
                                        </>}
                                        {data?.field_type?.attribute === 'lead_address' && <>
                                            <Box>
                                                <Box
                                                    sx={leadAddressWrapper}
                                                >
                                                    <Controller
                                                        name='address'
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <Iconify icon={'eva:search-outline'} sx={{
                                                                    position: 'absolute',
                                                                    left: '15px',
                                                                    top: '16px'

                                                                }} color='grey.500' width={24} height={24} />
                                                                <GoogleAutocomplete
                                                                    apiKey={import.meta.env.VITE_REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY}
                                                                    onPlaceSelected={(place, inputRef) => {
                                                                        const addresses = place.address_components;
                                                                        setAddressData(addresses, inputRef.value, setValue);
                                                                        field.onChange(inputRef.value);
                                                                    }}
                                                                    style={{
                                                                        paddingLeft: '45px',
                                                                        fontSize: '0.875rem'
                                                                    }}
                                                                    options={{
                                                                        types: ['establishment', 'geocode'],
                                                                        fields: ['address_components', 'formatted_address'],
                                                                        componentRestrictions: { country: 'uk' }
                                                                    }}
                                                                    defaultValue={field.value}
                                                                    className='address-input'
                                                                    placeholder='Search address'
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Box>
                                                {getAddressField(values) && <Box
                                                    sx={leadAddressFieldWrapper}
                                                >
                                                    <RHFTextField name='address_line_1' label='Address line 1' />
                                                    <RHFTextField name='address_line_2' label='Address line 2' />
                                                    <RHFTextField name='city' label='City' />
                                                    <RHFTextField name='county' label='County' />
                                                    <RHFTextField name='postcode' label='Postcode' />
                                                </Box>}
                                            </Box>
                                        </>}
                                    </>}
                                    {data?.field_type?.field_type === 'boolean' && <>
                                        <CustomScriptDescription data={data} />
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        <RHFRadioGroup
                                            name={`boolean_${[data.id]}`}
                                            options={[
                                                { label: 'Yes', value: 'yes' },
                                                { label: 'No', value: 'no' },
                                            ]}
                                            size='small'
                                            sx={{ margin: '-6px 2px 0', width: '100%' }}
                                        />
                                    </>}
                                    <CustomChoiceFieldComponent
                                        data={data}
                                        methods={methods}
                                        values={values}
                                        handleChange={handleChange}
                                        handleReferral={handleReferral}
                                        referralValue={referralValue}
                                    />
                                    {data?.field_type?.field_type === 'datetime' && <>
                                        <CustomScriptDescription data={data} />
                                        {data?.field_type?.is_name_visible && <Typography sx={nameTypoStyle(data)}>
                                            {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
                                        </Typography>}
                                        <ScriptDateTimePicker data={data} />
                                        <CustomScriptMobileDatePicker data={data} control={control} />
                                    </>}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
                {isScriptTCO ? (<Box sx={{ p: '16px 24px 24px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        type='submit'
                        variant='contained'
                        sx={unableContactButtonStyle}
                    >
                        <Iconify style={submitBtnIconStyle} icon={'formkit:submit'} /> Submit
                    </Button>
                </Box>) : (<Box sx={{ p: '16px 24px 24px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        type='submit'
                        variant='contained'
                        sx={unableContactButtonStyle}
                    >
                        <Iconify style={submitBtnIconStyle} icon={'formkit:submit'} /> Submit
                    </Button>
                    <Button
                        sx={appointmentButtonStyle}
                        onClick={() => appointmentBookEvent('appointmentNotBook')}
                    >
                        <Iconify style={submitBtnIconStyle} icon={'ant-design:calendar-filled'} /> Appointment not booked
                    </Button>
                    <Button
                        sx={bookingButtonStyle}
                        onClick={() => appointmentBookEvent('appointmentBook')}
                    >
                        <Iconify style={submitBtnIconStyle} icon={'akar-icons:circle-check-fill'} /> Book an appointment
                    </Button>
                </Box>)}

                {
                    Object.keys(errors).length > 0 && <Box sx={{ display: 'flex', justifyContent: 'center', m: '0 0 24px' }}>
                        <Typography sx={{ color: error.main, fontSize: '0.75rem' }}>Please fill the required fields.</Typography>
                    </Box>
                }
            </Card>
        </FormProvider>
    );
};

export default SingleScriptPreview;

SingleScriptPreview.propTypes = {
    BackButtonClick: PropTypes.func,
    scriptData: PropTypes.any,
    onClose: PropTypes.func,
    followLead: PropTypes.any,
    onOpenAppointmentNotBooked: PropTypes.func,
    setLeadId: PropTypes.func,
    onOpenAppointmentBooked: PropTypes.func,
    setTreatmentId: PropTypes.func,
    isScriptTCO: PropTypes.bool
};
