import React from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';
// @mui
import { Tab, Tabs, Box } from '@mui/material';
// hooks
import { useDispatch } from 'src/redux/store';
import { setAttendedActiveTab } from 'src/redux/slices/lead';
import { useTabs } from '../../hooks';
// style
import { tabsStyle, tabContentStyle } from '../../styles/DialogueTabs';

// -----------------------------------------------------------------------------------------------------------

export default function TabsComponents({ TabsContent, setActiveTab, activeAttachments, startTreatment }) {
    const { currentTab, onChangeTab } = useTabs(startTreatment === 'StartTreatment' ? 'Starting treatment' : '');
    const dispatch = useDispatch();
    return (
        <Box sx={{width: '100%'}}>
            <Tabs
                allowScrollButtonsMobile
                variant='scrollable'
                scrollButtons="auto"
                value={currentTab}
                onChange={(event, newValue) => {
                    if (setActiveTab) {
                        setActiveTab(newValue);
                        if (activeAttachments) {
                            activeAttachments(true);
                        }
                    }
                    onChangeTab(event, newValue);
                    dispatch(setAttendedActiveTab(newValue));
                }}
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    }
                }}
                sx={tabsStyle}
            >
                {TabsContent.map((tab) => (
                    <Tab
                        disableRipple
                        key={tab.value}
                        value={tab.value}
                        icon={tab.icon}
                        label={capitalCase(tab.value)}
                        sx={tabContentStyle}
                    />
                ))}
            </Tabs>
            {TabsContent.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </Box>
    );
}

TabsComponents.propTypes = {
    TabsContent: PropTypes.any,
    setActiveTab: PropTypes.func,
    activeAttachments: PropTypes.func,
    startTreatment: PropTypes.string,
};