/* eslint-disable arrow-body-style */
import * as Yup from 'yup';

export const NewUserSchema = Yup.object().shape({
    name: Yup.string().nullable().required('This field is required'),
    email: Yup.string().nullable().required('This field is required').email('Email must be a valid email address'),
    phone: Yup.string().nullable().required('This field is required').min(10, 'Phone number must be at least 10 numbers'),
    postcode: Yup.string().nullable().required('This field is required'),
    website: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    avatarUrl: Yup.string().required('This field is required'),
    address_line_1: Yup.string().nullable().required('This field is required'),
});
export const AddTreatmentSchema = Yup.object().shape({
    treatment: Yup.string().required('This field is required'),
});
export const AutocompleteTagsSchema = Yup.object().shape({
    tagName: Yup.string().required('This field is required')
});
export const PersonalDetailSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    email: Yup.string().email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('This field is required').min(10, 'Phone number must be at least 10 numbers'),
});
export const FollowUpSchema = Yup.object().shape({
    activeTime: Yup.boolean(),
    reminderDateTime: Yup.string().nullable().when('customReminder', {
        is: (value) => value === 'custom reminder',
        then: Yup.string().nullable().required('This field is required'),
    }),
});
export const NewAppointmentSchema = (assignToState) => Yup.object().shape({
    treatment: Yup.string().required('This field is required'),
    appoinmentDate: Yup.mixed().nullable().required('This field is required'),
    bookedWith: assignToState.length > 0 ? Yup.array() : Yup.array().required('This field is required').min(1, 'This field is required'),
    preferredContactMethod: Yup.string().required('This field is required'),
    practiceContactedVia: Yup.string().required('This field is required'),
    appointmentType: Yup.string().required('This field is required')
});
export const FailedAppointmentSchema = (assignToState) => Yup.object().shape({
    treatment: Yup.string().required('This field is required'),
    appoinmentDate: Yup.string().nullable(true).required('This field is required'),
    bookedWith: assignToState.length > 0 ? Yup.array() : Yup.array().required('This field is required').min(1, 'This field is required'),
    practiceContactedVia: Yup.string().required('This field is required'),
    preferredContactMethod: Yup.string().required('This field is required'),
    appointmentType: Yup.string().required('This field is required')
});
export const FailedToReminderSchema = Yup.object().shape({
    reminderDateTime: Yup.string().nullable(true).when('customReminder', {
        is: (value) => value === 'custom reminder',
        then: Yup.string().typeError('This field is required').required('This field is required'),
    }),
});
export const ScheduleReminderSchema = Yup.object().shape({
    activeTime: Yup.boolean(),
    reminderDateTime: Yup.string().nullable(true).when('customReminder', {
        is: (value) => value === 'custom reminder',
        then: Yup.string().typeError('This field is required').required('This field is required'),
    }),
});
export const CreateLeadArchiveSchema = Yup.object().shape({
    archiveReason: Yup.string().when('typed', {
        is: (typed) => typed !== 'RestoreArchived',
        then: Yup.string().required('This field is required')
    }),
    archiveNotes: Yup.string().required('This field is required')
});
export const UserPasswordSchema = Yup.object().shape({
    password: Yup.string().nullable().required('This field is required')
});
export const PracticeIntegrationSchema = Yup.object().shape({
    cancelURL: Yup.string().required('This field is required'),
    successURL: Yup.string().required('This field is required')
});
export const PaymentOptionSchema = Yup.object().shape({
    paymentLink: Yup.string().required('This field is required'),
});
export const TreatmentAndAppointmentSchema = (assignToState, activeTime) => Yup.object().shape({
    treatment: Yup.string().required('This field is required'),
    appoinmentDate: Yup.string().nullable().when('radioButton', {
        is: (radioButton) => radioButton === 'Yes',
        then: Yup.string().nullable().required('This field is required')
    }),
    bookedWith: Yup.array().when('radioButton', {
        is: (radioButton) => radioButton === 'Yes',
        then: assignToState?.length > 0 ? Yup.array() : Yup.array().required('This field is required').min(1, 'This field is required')
    }),
    preferredContactMethod: Yup.string().when('radioButton', {
        is: (radioButton) => radioButton === 'Yes',
        then: Yup.string().required('This field is required')
    }),
    practiceContactedVia: Yup.string().when('radioButton', {
        is: (radioButton) => radioButton === 'Yes',
        then: Yup.string().required('This field is required')
    }),
    appointmentType: Yup.string().when('radioButton', {
        is: (radioButton) => radioButton === 'Yes',
        then: Yup.string().required('This field is required')
    }),
    reminderDateTime: Yup.string().when('radioButton', {
      is: (radioButton) => radioButton === 'No' && activeTime === 'custom reminder',
      then: Yup.string().required('This field is required'),
      otherwise: Yup.string().nullable(),
    }),
  
});
export const AppointmentPageSchema = Yup.object().shape({
    otherNotes: Yup.boolean(),
    reason: Yup.string().required('This field is required'),
    OtherNotes: Yup.string().when('archived', {
        is: (value) => value === false,
        then: Yup.string().required('This field is required'),
    }),
    reminderDateTime: Yup.string().nullable(true).when(['reason', 'customReminder'], {
        is: (reason, customReminder) => (reason === 'FOLLOW_UP' && customReminder === 'custom reminder'),
        then: Yup.string().typeError('This field is required').required('This field is required')
    }),
    archiveNotes: Yup.string().when('archived', {
        is: (value) => value === true,
        then: Yup.string().typeError('This field is required').required('This field is required')
    })
});
export const AddReminderSchema = Yup.object().shape({
    leadName: Yup.string().typeError('This field is required').required('This field is required.'),
    relation: Yup.string().typeError('This field is required').required('This field is required.'),
    dateTime: Yup.string().nullable(true).required('This field is required.'),
    reminder: Yup.string().required('This field is required'),
    priority: Yup.string().required('This field is required'),
});
export const DelayDataSchema = Yup.object().shape({
    delay: Yup.string().nullable().required('Delay is required'),
    time: Yup.string().nullable().required('Time is required'),
});
export const customeDataSchema = Yup.object().shape({
    value: Yup.number().nullable().required('Value is required').moreThan(0, 'Please enter valid value')
});
export const TeamFormSchema = Yup.object().shape({
    email: Yup.string().required('This field is required').email('Email must be a valid email address'),
    role: Yup.array().required('This field is required'),
});
export const UserNewEditFormSchema = Yup.object().shape({
    email: Yup.string().required('This field is required').email('Email must be a valid email address'),
    role: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
});
export const AddUserSchema = Yup.object().shape({
    email: Yup.string().required('This field is required').email('Email must be a valid email address'),
    role: Yup.string().required('This field is required'),
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
});
export const EditUserSchema = Yup.object().shape({
    role: Yup.string().required('This field is required'),
});
export const AccountSocialLinkSchema = Yup.object().shape({
    addNotes: Yup.string().required('This field is required')
});
export const ProfilePasswordSchema = Yup.object().shape({
    new_password1: Yup.string().required('This field is required').min(8, 'Password must be at least 8 characters')
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
    new_password2: Yup.string().oneOf([Yup.ref('new_password1'), null], 'Passwords must match'),
});
export const ProfileYourDetailsSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().when('Typed', {
        is: 'profile',
        then: Yup.string().min(10, 'Phone number must be at least 10 numbers').required('Phone number is required')
    })
});
export const LoginSchema = Yup.object().shape({
    email: Yup.string().required('This field is required').email('Email must be a valid email address'),
    password: Yup.string().required('This field is required'),
});
export const VerifyCodeSchema = Yup.object().shape({
    new_password1: Yup.string()
        .required('This field is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
    new_password2: Yup.string()
        .oneOf([Yup.ref('new_password1'), null], 'Passwords must match'),
});
export const RegisterSchema = Yup.object().shape({
    first_name: Yup.string().required('This field is required'),
    last_name: Yup.string().required('This field is required'),
    email: Yup.string().email('Email must be a valid email address').required('This field is required'),
    password: Yup.string().required('This field is required').min(8, 'Password must be at least 8 characters')
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        ),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
export const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('This field is required'),
});
export const VerifyCodeFormSchema = Yup.object().shape({
    code1: Yup.string().required('This field is required'),
    code2: Yup.string().required('This field is required'),
    code3: Yup.string().required('This field is required'),
    code4: Yup.string().required('This field is required'),
    code5: Yup.string().required('This field is required'),
    code6: Yup.string().required('This field is required'),
});
export const AddRefundPaymentSchema = Yup.object().shape({
    Reason: Yup.string().required('This field is required'),
});
export const consentLinkSchema = Yup.object().shape({
    consentType: Yup.array().min(1, 'Minimum 1 field is required').required('This field is required'),
    assignTo: Yup.string().required('This field is required'),
});
export const consentBaseUrl = Yup.object().shape({
    consentType: Yup.array().min(1, 'Minimum 1 value is required').required('This field is required'),
    prefix: Yup.string().required('This field is required')
});
export const CampaignInfoSchema = Yup.object().shape({
    campaignName: Yup.string().required('This field is required'),
});
export const AutoReminderSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    method: Yup.string().required('This field is required'),
    group: Yup.string().required('This field is required'),
    event: Yup.string().required('This field is required'),
    delay: Yup.number().required('This field is required').typeError('This field is required'),
    treatment: Yup.array().min(1, 'This field is required').required('This field is required'),
});
export const TemplateValidation = (templateDetails) => {
    const AdminTemplateSchema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        subject: Yup.string().required('This field is required'),
        body: Yup.string().when('use_unlayer', {
            is: (isUnlayer) => !isUnlayer,
            then: Yup.string().required('This field is required')
        }),
        category: Yup.string().required('This field is required'),
        practices: templateDetails ? templateDetails?.length === 0 && Yup.array().min(1, 'This field is required') : Yup.array().min(1, 'This field is required'),
        use_unlayer: Yup.boolean(),
        unlayer_html: Yup.string().when('use_unlayer', {
            is: (isUnlayer) => isUnlayer,
            then: Yup.string().required('This field is required')
        }),
    });
    return AdminTemplateSchema;
};
export const sendTestEmail = (data) => Yup.object().shape({
    email: data === 'EMAIL' && Yup.string().required('This field is required').email('Email must be a valid email address'),
    phoneNumber: data === 'SMS' && Yup.string().required('This field is required').min(10, 'Phone number must be at least 10 numbers'),
});
export const CallScriptSchema = (sections, categoryValue, isBaseScript) => {
    let validationObject = {};
    validationObject = { ...validationObject, scriptTitle: Yup.string().required('This field is required'), treatment: Yup.array().nullable().required('This field is required').min(1, 'This field is required'), template: (categoryValue || isBaseScript) ? '' : Yup.string().required('This field is required'), category: Yup.string().required('This field is required') };
    sections?.map((item, index) => {
        if (item.name === '' || item.name === null) {
            validationObject[`sectionTitle${[index]}`] = Yup.string().required('This field is required');
        }
        item?.fields?.map((field) => {
            if (field.label === '' || field.label === null) {
                if ((field?.field_type === 'label' || field?.field_type?.field_type === 'label')) {
                    return null;
                }
                validationObject[`fieldLabel${[field.id]}`] = Yup.string().required('This field is required');
            }
            return null;
        });
        return null;
    });
    return Yup.object().shape(validationObject);
};
export const CallScriptSchemaNew = (sections, categoryValue) => {
    let validationObject = {};
    validationObject = { ...validationObject, scriptTitle: Yup.string().required('This field is required'), treatment: Yup.array().nullable().required('This field is required').min(1, 'This field is required'), template: categoryValue ? '' : Yup.string().required('This field is required') };
    sections?.map((item, index) => {
        if (item.name === '' || item.name === null) {
            validationObject[`sectionTitle${[index]}`] = Yup.string().required('This field is required');
        }
        item?.fields?.map((field) => {
            if (field.label === '' || field.label === null) {
                if ((field?.field_type === 'label' || field?.field_type === 'label')) {
                    return null;
                }
                validationObject[`fieldLabel${[field.id]}`] = Yup.string().required('This field is required');
            }
            return null;
        });
        return null;
    });
    return Yup.object().shape(validationObject);
};
export const SingleScriptPreviewSchema = (fields) => {
    let validationObject = {};
    validationObject = { ...validationObject, firstName: Yup.string().required('This field is required') };
    fields?.map((item) => {
        item?.fields?.map((field) => {
            if (field.is_required) {
                if (field.field_type?.field_type === 'text') {
                    validationObject[`text_${[field.id]}`] = Yup.string().required('This field is required');
                } else if (field.field_type?.field_type === 'char') {
                    if (field?.field_type?.attribute === 'phone') {
                        validationObject.phone_number = Yup.string().required('This field is required').min(10, 'Phone number must be at least 10 numbers');
                    }
                    if (field?.field_type?.attribute === 'lead_email') {
                        validationObject.email = Yup.string().email('Email must be a valid email address');
                    }
                    if ((field?.field_type?.attribute !== 'phone' && field?.field_type?.attribute !== 'lead_email')) {
                        validationObject[`char_${[field.id]}`] = Yup.string().required('This field is required');
                    }
                } else if (field.field_type?.field_type === 'boolean') {
                    validationObject[`boolean_${[field.id]}`] = Yup.string().required('This field is required');
                } else if (field.field_type?.field_type === 'datetime') {
                    validationObject[`datetime_${[field.id]}`] = Yup.string().required('This field is required');
                } else if (field.field_type?.field_type === 'label') {
                    validationObject[`label_${[field.id]}`] = Yup.string().required('This field is required');
                } else if (field.field_type?.field_type === 'choice') {
                    if (field.field_type?.ui_type === 'DROPDOWN') {
                        validationObject[`checkbox_${[field.id]}`] = Yup.string().required('This field is required');
                    }
                    if (field.field_type?.ui_type === 'MULTI_SELECT') {
                        validationObject[`multiCheckbox_${[field.id]}`] = Yup.array().required('This field is required').min(1, 'This field is required');
                    }
                    if (field.field_type?.ui_type === 'CHECKBOX') {
                        validationObject[`typeCheckbox_${[field.id]}`] = Yup.array().required('This field is required').min(1, 'This field is required');
                    }
                    if (field.field_type?.ui_type === 'RADIO') {
                        validationObject[`typeRadio_${[field.id]}`] = Yup.string().required('This field is required');
                    }
                } else if (field.field_type?.field_type === 'lead_search_select') {
                    validationObject.firstName = Yup.string().required('This field is required');
                } else if (field.field_type?.field_type === 'practice_treatment_search_select') {
                    validationObject.treatment = Yup.string().required('This field is required');
                    validationObject.treatmentValue = Yup.string().required('This field is required');
                }
            }
            return null;
        });
        return null;
    });
    return Yup.object().shape(validationObject);
};
export const EditCallScriptSchema = (submissionData) => {
    let validationObject = {};
    validationObject = { ...validationObject };
    submissionData?.fields?.map((data) => {
        if (data.field.is_required) {
            if (data.field.field_type?.field_type === 'text') {
                validationObject[`text_${[data.field.id]}`] = Yup.string().required('This field is required');
            } else if (data.field.field_type?.field_type === 'char') {
                validationObject[`char_${[data.field.id]}`] = Yup.string().required('This field is required');
            } else if (data.field.field_type?.field_type === 'boolean') {
                validationObject[`boolean_${[data.field.id]}`] = Yup.string().required('This field is required');
            } else if (data.field.field_type?.field_type === 'datetime') {
                validationObject[`datetime_${[data.field.id]}`] = Yup.string().required('This field is required');
            } else if (data.field.field_type?.field_type === 'label') {
                validationObject[`label_${[data.field.id]}`] = Yup.string().required('This field is required');
            } else if (data.field.field_type?.field_type === 'choice') {
                if (data.field.field_type?.ui_type === 'DROPDOWN') {
                    validationObject[`checkbox_${[data.field.id]}`] = Yup.string().required('This field is required');
                }
                if (data.field.field_type?.ui_type === 'MULTI_SELECT') {
                    validationObject[`multiCheckbox_${[data.field.id]}`] = Yup.array().required('This field is required').min(1, 'This field is required');
                }
                if (data.field.field_type?.ui_type === 'CHECKBOX') {
                    validationObject[`typeCheckbox_${[data.field.id]}`] = Yup.array().required('This field is required').min(1, 'This field is required');
                }
                if (data.field.field_type?.ui_type === 'RADIO') {
                    validationObject[`typeRadio_${[data.field.id]}`] = Yup.string().required('This field is required');
                }
            }
        }
        return null;
    });
    return Yup.object().shape(validationObject);
};
export const CreateStripePaymentSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
});
export const EditAppointmentSchema = (assignToState) => Yup.object().shape({
    appoinmentDate: Yup.string().nullable().required('This field is required'),
    bookedWith: assignToState?.length > 0 ? Yup.array() : Yup.array().required('This field is required').min(1, 'This field is required'),
    preferredContactMethod: Yup.string().required('This field is required'),
    practiceContactedVia: Yup.string().required('This field is required'),
    appointmentType: Yup.string().required('This field is required')
});
export const loanSchema = Yup.object().shape({
    name: Yup.string().required('This field is required')
});
export const ScheduleSettingsSchema = Yup.object().shape({
    recepients: Yup.string().required('This field is required'),
    reportType: Yup.string().required('This field is required'),
    interval: Yup.string().required('This field is required'),
});
export const faceBookPost = Yup.object().shape({
    name: Yup.string().required('This field is required'),
});
export const ScheduleCampaignSchema = Yup.object().shape({
    deliveryDate: Yup.string().nullable().required('This field is required'),
    hour: Yup.string().required('This field is required'),
    minute: Yup.string().required('This field is required'),
});
export const AudienceCampaignSchema = Yup.object().shape({
    audienceName: Yup.string().required('This field is required'),
});
export const ClinCheckUploadedFormSchema = Yup.object().shape({
    date_uploaded: Yup.mixed().nullable().required('Date is required'),
    who_uploaded: Yup.string().required('This field is required'),
});
export const ClinCheckReceivedFormSchema = Yup.object().shape({
    date_received: Yup.mixed().nullable().required('Date is required'),
    who_received: Yup.string().required('This field is required'),
});
export const inlineTreatmentEditSchema = Yup.object().shape({
    teethAmount: Yup.number().nullable().moreThan(0, 'Teeth should not be less then 1'),
    treatmentPrice: Yup.number().required('This field is required').moreThan(0, 'Please enter valid value')
});
export const depositPaymentSchema = Yup.object().shape({
    paymentMethod: Yup.string().required('This field is required'),
    paymentDate: Yup.mixed().nullable().required('This field is required'),
});
export const RattingSchema = Yup.object().shape({ comment: Yup.string().required('This field is required') });
export const ConsentSchema = Yup.object().shape({
    treatment: Yup.array().nullable().required('This field is required').min(1, 'This field is required'),
});