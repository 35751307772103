import PropTypes from 'prop-types';
// @mui
import { Box, Checkbox } from '@mui/material';
//
import Icon from './Icon';

// ----------------------------------------------------------------------

export default function ColorManyPicker({ colors, selected, onChangeColor, sx, ...other }) {
    return (
        <Box sx={sx}>
            {colors.map((color) => {
                const isWhite = color === '#FFFFFF' || color === 'white';

                return (
                    <Checkbox
                        key={color}
                        size="small"
                        value={color}
                        color="default"
                        checked={selected.includes(color)}
                        onChange={() => onChangeColor(color)}
                        icon={<Icon whiteColor={isWhite} />}
                        checkedIcon={<Icon checked whiteColor={isWhite} />}
                        sx={{
                            color,
                            '&:hover': { opacity: 0.72 },
                            '& svg': { width: 12, height: 12 },
                        }}
                        {...other}
                    />
                );
            })}
        </Box>
    );
}

ColorManyPicker.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string),
    onChangeColor: PropTypes.func,
    sx: PropTypes.object,
    selected: PropTypes.arrayOf(PropTypes.string),
};
