import { capitalizeFirstWord } from './capitalizedFunc';

export const toggleFieldEnabled = (isEnabled, setIsEnabled, sections, setSections, sectionArray, sectionId, index) => {
    setIsEnabled(!isEnabled);
    const updatedFields = sectionArray.fields.map((el, i) =>
        i === index ? { ...el, is_enabled: !isEnabled } : el
    );
    const updatedSections = sections.map((el) =>
        el.id === sectionId ? { ...el, fields: updatedFields } : el
    );
    setSections(updatedSections);
};

export const isRequiredFieldChange = (event, sectionArray, index, sections, sectionId, setSections) => {
    const updatedFields = sectionArray.fields.map((el, i) =>
        i === index ? { ...el, is_required: event.target.checked } : el
    );
    const updatedSections = sections.map((el) =>
        el.id === sectionId ? { ...el, fields: updatedFields } : el
    );
    setSections(updatedSections);
};

export const lableFieldChange = (event, sectionArray, index, sections, sectionId, setSections) => {
    const updatedFields = sectionArray.fields.map((el, i) =>
        i === index ? { ...el, label: event.target.value } : el
    );
    const updatedSections = sections.map((el) =>
        el.id === sectionId ? { ...el, fields: updatedFields } : el
    );
    setSections(updatedSections);
};

export const descriptionFieldChange = (event, sectionArray, index, sections, sectionId, setSections) => {
    const updatedFields = sectionArray.fields.map((el, i) =>
        i === index ? { ...el, description: event.target.value } : el
    );
    const updatedSections = sections.map((el) =>
        el.id === sectionId ? { ...el, fields: updatedFields } : el
    );
    setSections(updatedSections);
};

export const attrFieldChange = (event, sectionArray, index, sections, sectionId, setSections) => {
    const updatedFields = sectionArray.fields.map((el, i) =>
        i === index ? { ...el, attribute: event.target.value } : el
    );
    const updatedSections = sections.map((el) =>
        el.id === sectionId ? { ...el, fields: updatedFields } : el
    );
    setSections(updatedSections);
};

export const getTooltipTitle = (category) => {
    switch (category) {
        case 'INBOUND':
            return `${capitalizeFirstWord(category)} (Lead -> CRM)`;
        case 'OUTBOUND':
            return `${capitalizeFirstWord(category)} (CRM -> Lead)`;
        default:
            return capitalizeFirstWord(category);
    }
};


export const parseV2ToV1 = (v2Data, index) =>
({
    ...v2Data?.lead,
    id: `${v2Data?.lead?.id}-${index}`,
    lead_treatments: [{
        ...v2Data,
        lead: v2Data?.lead?.id,
        treatment_data: v2Data?.treatment,
        treatment: v2Data?.treatment?.treatment_item?.name
    }],
});