import { common, primary } from 'src/theme/palette';

export const displayFlex = { display: 'flex' };
export const justifyCenter = { justifyContent: 'center' };
export const centerAlign = { alignItems: 'center' };
export const justifySpaceBetween = { justifyContent: 'space-between' };
export const centeredAlignBox = { ...displayFlex, ...centerAlign, marginBottom: '10px' };
export const centeredSpaceBetween = { ...displayFlex, ...centerAlign, ...justifySpaceBetween };
export const centeredSpaceBetweenBox = { ...displayFlex, ...centerAlign, ...justifySpaceBetween, mb: 2 };
export const centeredSpaceBetweenBoxWithGap = { ...displayFlex, flexDirection: 'column', gap: '16px', ...justifySpaceBetween, mb: 2 };
export const removeIconStyle = { position: 'absolute', top: 0, right: 0 };
export const numberingHeading = { mb: 2, fontSize: '20px', fontWeight: 700 };
export const emergencyChatbotDesc = {
    color: common.grey,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16.45px',
    width: '100%',
    mb: 1
};
export const emergencyChatbotHead = {
    lineHeight: '23.5px',
    color: common.black,
    fontWeight: 700,
    fontSize: '20px',
};
export const chatbotAccordionMessage = {
    fontSize: '13px',
    color: primary.main,
    lineHeight: '22px',
    fontWeight: 700,
};
export const typoWrapper = { display: 'flex', marginBottom: '16px', width: '100%', alignItems: 'center' };