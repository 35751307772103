import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AddButton from './AddButton';
import { Label } from '../../../../components';
import { appointmentDateSlice, updateAppointmentDetail } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
import { useBoolean } from '../../../../hooks';
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableBookedWith,
} from '../../../../utils/allTableTab';
import { dateColor, dateFindColor, showConsultationDate } from '../../../../utils/leadTableUtils';
import {
    datePickerBoxStyle,
    datePickerTextFieldStyle,
    dateTimeTypoStyle,
    dateOfAppointmentLabelStyle,
    tableCellRelativeBoxStyle,
} from '../../../../styles/NewLeadTableRowStyle';

const ConsultationDateField = ({ rowData, typed, updateLeadPage, reloadCounter, tableType, OpenAssignTask }) => {
    const theme = useTheme();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { consultationDateFormat } = useSelector((state) => state.lead);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedDate, setSelectedDate] = useState(
        moment(rowData?.consultationAppointment?.[0]?.datetime, 'DD/MM/YYYY hh:mm:ss').format('ddd[,] D MMMM [at] hh:mm A')
    );
    const openCalendar = useBoolean();
    const perPage = TablePerPage(tableType);
    const pageNumber = TablePageNumber(tableType);
    const status = TableStatus(tableType);
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;
    const contactCount = TabCount(tableType);
    const searchTagsValue = TableFilterTags(tableType)?.toString();
    const referral = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));
    const buttonRef = useRef();
    const [resetKey, setResetKey] = useState(0);

    const handleDateChange = (date) => {
        if (rowData?.consultationAppointment?.[0]?.datetime_human) {
            setSelectedDate(date);
            const detail = {
                datetime: `${moment(date).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            };
            dispatch(
                updateAppointmentDetail(
                    practiceId,
                    rowData?.lead_id,
                    rowData?.consultationAppointment?.[0]?.id,
                    detail,
                    handleSuccess,
                    ''
                )
            );
        } else {
            dispatch(appointmentDateSlice({ apptDate: date, treatmentId: rowData?.treatment_id }));
            OpenAssignTask(rowData, '', buttonRef.current);
            setTimeout(() => {
                openCalendar.onFalse();
                setSelectedDate(null);
                setResetKey((prevKey) => prevKey + 1);
            }, 200);
        }
    };

    const handleSuccess = (data) => {
        openCalendar.onFalse();
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: status,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTagsValue,
            referral,
            category,
            bookedWith,
        };
        updateLeadPage(reloadPayLoad);
        reloadCounter(reloadPayLoad);
    };

    return (
        <Box
            sx={tableCellRelativeBoxStyle}
            onClick={() => {
                openCalendar.onTrue();
            }}
            ref={buttonRef}
        >
            {rowData?.consultationAppointment?.[0]?.datetime_human ? (
                <Button sx={{ px: 0, fontWeight: '600', '&:hover': { background: 'none' } }} disableRipple>
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={'success'}
                        sx={dateOfAppointmentLabelStyle(
                            dateColor(rowData?.consultationAppointment?.[0]?.datetime),
                            dateFindColor(rowData),
                            typed
                        )}
                    >
                        <Typography
                            variant='05 Others/Label'
                            dangerouslySetInnerHTML={{ __html: showConsultationDate(consultationDateFormat, rowData) }}
                            sx={dateTimeTypoStyle}
                        />
                    </Label>
                </Button>
            ) : (
                <AddButton />
            )}
            <Box sx={datePickerBoxStyle}>
                <DateTimePicker
                    key={resetKey}
                    closeOnSelect={false}
                    orientation='landscape'
                    label=''
                    open={openCalendar.value}
                    onClose={openCalendar.onFalse}
                    name='DueDatetime'
                    maxDateTime={new Date()}
                    value={selectedDate}
                    inputFormat={'dd/MM/yyyy HH:mm'}
                    onAccept={(newValue) => handleDateChange(newValue)}
                    ampm={false}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            margin: 'normal',
                            sx: datePickerTextFieldStyle,
                            onKeyDown: (e) => e.preventDefault(),
                        },
                        actionBar: { actions: ['cancel', 'accept'] },
                    }}
                />
            </Box>
        </Box>
    );
};

ConsultationDateField.propTypes = {
    rowData: PropTypes.any,
    typed: PropTypes.any,
    updateLeadPage: PropTypes.any,
    reloadCounter: PropTypes.any,
    tableType: PropTypes.any,
    OpenAssignTask: PropTypes.any,
};

export default ConsultationDateField;
