import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
// @mui
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSettingsContext } from 'src/components/settings/context';
import { isEmpty } from 'lodash';
// store
import SimilarLeadWarningPopup from '../../sections/@dashboard/leadDetail/SimilarLeadWarningPopup';
import { dispatch, useSelector } from '../../redux/store';
import { updateShowSubjectList } from '../../redux/slices/practiceMessage';
// components
import { TabsComponents, Archive } from '../tabs';
import Iconify from '../Iconify';
import { UnableToContact, FailedAppointment } from '../failedToAttendTabs';
import { clearLead, saveNewLeadDetail, FailedToAttendAppointment } from '../../redux/slices/lead';
import {
    commonDialogHeaderStyle,
    onBoardDialogHeader,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
    attendedStackStyle,
    leadFooterButtonsStyle,
    finishButtonStyle,
    PhoneIconButton,
    footerBoxStyle
} from '../../styles/ScheduleReminder';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function OpenUnAttendedPopup({ open, onClose, type = 'lead', user, inputRef, headerButtons, handleCloseAndReload }) {

    const { followLead } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const settings = useSettingsContext();

    const [activeTab, setActiveTab] = useState(null);

    const handleClose = async (action) => {
        if (action) {
            onClose();
            dispatch(clearLead());
        }
    };

    const handleFailedToAttendClose = () => {
        if (handleCloseAndReload) {
            handleCloseAndReload('finish');
        } else {
            onClose('finish');
            dispatch(clearLead());
        }
    };

    const handleNext = async () => {
        dispatch(saveNewLeadDetail({ faildAttended: { activeTab } }));
        await inputRef.current.submit('finish');
    };

    const failedToAttendClick = () => {
        dispatch(saveNewLeadDetail({ faildAttended: { activeTab } }));
        const { lead_treatments: leadTreatment, lead_appointment: leadAppoiment } = followLead;
        const appoinmentID = leadAppoiment?.filter((el) => el.status === 'BOOKED' || el.status === 'CONSULTATION_BOOKED');
        const appointmentDetail = {
            lead_treatment: leadTreatment[0].id,
        };
        dispatch(FailedToAttendAppointment(appointmentDetail, leadTreatment[0]?.lead, appoinmentID[0].id, practiceId, handleFailedToAttendClose, 'Unable to contact'));
    };

    const failedToAttendFinishSubmit = async () => {
        await inputRef.current.submit('');
    };

    const PROFILE_TABS = [
        {
            value: 'Unable to contact',
            icon: <Iconify icon={'akar-icons:circle-x-fill'} width={22} height={22} />,
            component: <UnableToContact ref={inputRef} type={type} handleNext={handleClose} completeFlow={handleFailedToAttendClose} />,
        },
        {
            value: 'Reschedule',
            icon: <Iconify icon={'eva:calendar-fill'} width={22} height={22} />,
            component: <FailedAppointment ref={inputRef} type={type} handleNext={handleClose} />,
        },
        {
            value: 'Archive',
            icon: <Iconify icon={'eva:archive-fill'} width={22} height={22} />,
            component: <Archive ref={inputRef} type={type} handleNext={handleClose} />,
        }
    ];

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle(type)}
        >
            <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader}>
                <Typography
                    sx={reminderTitleStyle}
                    variant="h6"
                    component="div"
                >
                    Unattended appointment for <span style={{ textTransform: 'capitalize' }}>{user.first_name}</span>
                </Typography>
                {headerButtons &&
                    <Box sx={cancelBoxStyle}>
                        <Button
                            color="inherit"
                            variant='outlined'
                            style={{ borderColor: theme.palette.common.white, color: theme.palette.common.white }}
                            onClick={() => { handleClose('close'); }}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={attendedStackStyle} onClick={() => { dispatch(updateShowSubjectList(false)); }}>
                <Grid container spacing={1} sx={{ maxWidth: '1088px', margin: 'auto' }}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                    >
                        <Typography
                            variant="h3"
                            sx={{ textAlign: 'center', marginBottom: { md: '60px', xs: '30px' }, fontSize: { md: '24px', xs: '1.2rem' } }}
                        >
                            {user.treatment_name} appointment unattended, call <span style={{ textTransform: 'capitalize' }}>{user.first_name} {user.last_name}</span> to find out why
                        </Typography>
                        <Card sx={{ p: { sm: 3, xs: 2 }, display: 'flex', flexDirection: 'column', alignItems: 'center', boxShadow: 'none', maxWidth: '90vw', padding: '0 !important', borderRadius: 0 }}>
                            <a
                                href={followLead && followLead.lead_contact && followLead.lead_contact.phone || ''}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                    marginBottom: '10px',
                                    maxWidth: '100%'
                                }}
                            >
                                <PhoneIconButton>
                                    <Iconify style={{ color: theme.palette.common.green }} icon={'bxs:phone'} />
                                </PhoneIconButton>
                                {!isEmpty(followLead?.lead_contact?.phone) && <Typography variant="h1" color={isLight ? 'grey.800' : theme.palette.common.white}
                                    sx={{
                                        maxWidth: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        fontSize: { lg: '48px', md: '48px', sm: '3.25rem', xs: '1.2rem' },
                                    }}
                                >
                                    {followLead.lead_contact.phone}
                                </Typography>}
                            </a>
                            {type === 'recordUnAttended' && <SimilarLeadWarningPopup type={'DialogPopup'} dialogClose={() => handleClose('close')} />}
                            <Grid container justifyContent='flex-start' sx={{ mt: 8 }}>
                                <Box sx={{ pb: '15px', mb: '20px', width: '100%', textAlign: 'center' }}>
                                    <Typography variant='h6'>Select an outcome from the call </Typography>
                                </Box>
                                <TabsComponents setActiveTab={setActiveTab} TabsContent={PROFILE_TABS} />
                            </Grid>
                            {activeTab === 'Unable to contact' && user.status === 'FAILED_TO_ATTEND_CONSULTATION' &&
                                <Box sx={footerBoxStyle}>
                                    <Container
                                        maxWidth={settings.themeStretch ? false : 'xl'}
                                        sx={leadFooterButtonsStyle}
                                    >
                                        <Button
                                            color="primary"
                                            variant='contained'
                                            onClick={() => failedToAttendFinishSubmit()}
                                            disabled={!activeTab}
                                            sx={finishButtonStyle(type)}
                                        >
                                            Finish
                                        </Button>
                                    </Container>
                                </Box>
                            }
                            {activeTab === 'Unable to contact' ?
                                <>
                                    {user.status === 'CONSULTATION_BOOKED'
                                        ?
                                        <Box sx={footerBoxStyle}>
                                            <Container
                                                maxWidth={settings.themeStretch ? false : 'xl'}
                                                sx={leadFooterButtonsStyle}
                                            >
                                                <Button
                                                    color="primary"
                                                    variant='contained'
                                                    onClick={() => failedToAttendClick()}
                                                    disabled={!activeTab}
                                                    sx={finishButtonStyle(type)}
                                                >
                                                    Finish
                                                </Button>
                                            </Container>
                                        </Box>
                                        : null}
                                </>
                                : <Box sx={footerBoxStyle}
                                >
                                    <Container
                                        maxWidth={settings.themeStretch ? false : 'xl'}
                                        sx={leadFooterButtonsStyle}
                                    >
                                        <Button
                                            color="primary"
                                            variant='contained'
                                            onClick={handleNext}
                                            disabled={!activeTab}
                                            sx={finishButtonStyle(type)}
                                        >
                                            Finish
                                        </Button>
                                    </Container>
                                </Box>}
                        </Card>
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
}

OpenUnAttendedPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    user: PropTypes.object,
    inputRef: PropTypes.any,
    headerButtons: PropTypes.bool,
    handleCloseAndReload: PropTypes.any,
};