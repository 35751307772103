import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-lodash-debounce';
// libraries
import { Stack, Box } from '@mui/material';
// component
import TemplatePreviewPopup from '../../../components/dialogPopUp/TemplatePreviewPopup';
import {
    MessageToolbar,
    MessageDetailToolbar,
    EmailMessageCompose,
    SMSMessageCompose,
    WhatsappMessageCompose,
} from '.';
import TemplatePaginationControls from './TemplatePaginationControls';
import TemplateMessageList from './TemplateMessageList';
// redux
import { saveNewCampaign } from '../../../redux/slices/campaign';
import {
    updateMessageDetail,
    updateSequenceDetail,
    addTemplateList,
    getUserTemplateDetails,
    getTemplateList,
    updateShowSubjectList,
    setSearchTemplate,
} from '../../../redux/slices/practiceMessage';
import { dispatch, useSelector } from '../../../redux/store';
import { updateError } from '../../../redux/slices/user';
import { deleteSequence, setBlankTemplate, updateTemplate } from '../../../redux/api/practiceMessageApis';
// hooks/constants/utils
import useTable from '../../../hooks/useTable';
import { useTabs } from '../../../hooks';
import { handleDuplicate, handleSelect, onDeleteRow, onSendTestMail } from '../../../utils/practiceMessageUtils';
// style
import { stackStyle } from '../../../styles/MessageListStyle';

const MessageList = ({
    messageData,
    sequenceName,
    treatmentList,
    practiceId,
    sequenceId,
    messageDetail,
    setSequenceName,
    isAdmin,
    isSuperAdmin,
    onOpenSidebar,
    type,
    setTemplate,
    setTemplateOpen,
    LeadTemplate,
    useTemplate,
    useOrSend,
    OpenTemplate,
    screen,
    fullScreen,
    setFullScreen,
    setUseTemplate,
    handleRowSelection,
    selectedRow,
    campaign,
    isTemplateList,
    unLayerFilter,
    isPracticeMessages,
    pageName,
    isFromSearch,
    list,
    isFromUnableToContact,
    handleSendUnableToContact,
}) => {
    const [filterStatus, setFilterStatus] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openEmailCompose, setOpenEmailCompose] = useState(false);
    const [openTemplatePreview, setOpenTemplatePreview] = useState(false);
    const [openSMSCompose, setOpenSMSCompose] = useState(false);
    const [openWhatsappCompose, setOpenWhatsappCompose] = useState(false);
    const [isNewTemplate, setIsNewTemplate] = useState(false);
    const [open, setOpen] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const [previewSize, setPreviewSize] = useState('desktop');
    const { newCampaign } = useSelector((state) => state.campaign);
    const { search } = useSelector((state) => state.practiceMessage);
    const { currentTab: filter, onChangeTab: onChangeFilterStatus } = useTabs(newCampaign?.engagement_template_tab || '');
    const debouncedValue = useDebounce(search, 800);

    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        selected,
        setPage,
        onSelectAllRows,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
        setRowsPerPage,
    } = useTable();

    const filterData = () => {
        if (filterStatus && (type === 'Practice_Message' || type === 'OnBoarding')) {
            return messageData.filter(
                (el) =>
                    el.message &&
                    el.message.lead_status &&
                    el.message.lead_status.toLowerCase().replaceAll('_', ' ') === filterStatus.toLowerCase()
            );
        }
        if (filterStatus !== 'All') {
            return messageData.filter(
                (el) => el.category && el.category && el.category.toLowerCase() === filterStatus.toLowerCase()
            );
        }
        return messageData;
    };

    const handleRefreshPagination = () => {
        setPage(0);
        setRowsPerPage(10);
    };

    useEffect(() => {
        if (unLayerFilter) {
            handleRefreshPagination();
            onChangeFilterStatus('', 'All');
            setFilterStatus('All');
            if (campaign) {
                dispatch(saveNewCampaign({ engagement_template_tab: 'All' }));
            }
        }
    }, [unLayerFilter]);

    useEffect(() => {
        if (filterStatus) {
            handleRefreshPagination();
        }
    }, [filterStatus]);

    const handleUserTemplate = (row) => {
        setUseTemplate(true);
        setTemplateOpen(false);
        if (isFromSearch) {
            dispatch(updateShowSubjectList(false));
        }
        dispatch(getUserTemplateDetails(practiceId, row.id, handleSuccess));
    };

    const handleSaveMessage = (detail, id, isSaveAndClose) => {
        if (type !== 'Practice_Message' && type !== 'OnBoarding') {
            if (isNewTemplate) {
                dispatch(addTemplateList({ id: practiceId, details: detail, callback: handleSuccess }));
            } else {
                dispatch(updateTemplate({ id, details: detail, callback: handleSuccess, isSaveAndClose }));
            }
        } else {
            dispatch(updateMessageDetail({ sequenceId, messageId: messageDetail.id, detail, callback: handleSuccess }));
            setTimeout(() => {
                document.body.style.overflow = 'auto';
            }, 500);
        }
    };

    const handleChangeStatus = (detail, id) => {
        dispatch(updateMessageDetail({ sequenceId, messageId: id, detail, callback: handleSuccess }));
    };

    const handleSuccess = (data, isSaveAndClose) => {
        if (data.success) {
            if (!isSaveAndClose) return;
            if (isTemplateList) {
                dispatch(getTemplateList(practiceId, false, unLayerFilter));
            } else if (isPracticeMessages) {
                dispatch(getTemplateList(practiceId, true));
            } else {
                dispatch(getTemplateList(practiceId));
            }
            dispatch(setSearchTemplate(null));
            dispatch(updateError(data));
            setOpenEmailCompose(false);
            setOpenSMSCompose(false);
            handleClosePreviewTemplate();
            return;
        }
        Object.values(data.message)?.forEach((value) => {
            dispatch(updateError({ message: value.toString(), success: false }));
        });
    };

    const updateSequenceStatus = (status) => {
        if (sequenceName && sequenceName.id) {
            const detail = { is_enabled: status };
            const data = { ...sequenceName, ...detail };
            setSequenceName(data);
            dispatch(updateSequenceDetail({ sequenceId: sequenceName.id, detail, callback: handleSuccess }));
        }
    };

    const handleClick = (value, e) => {
        setOpen(false);
        setAnchorEl(e.currentTarget);
        switch (value) {
            case 'EMAIL':
                setOpenEmailCompose(true);
                setIsNewTemplate(true);
                break;
            case 'SMS':
                setOpenSMSCompose(true);
                setIsNewTemplate(true);
                break;
            default:
                break;
        }
    };

    const handleOpen = (e) => {
        setOpen(true);
        setAnchorEl(e.currentTarget);
        dispatch(setBlankTemplate(practiceId));
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const deleteSequenceStatus = (data) => {
        dispatch(deleteSequence(practiceId, data.id));
        setSequenceName('');
    };

    const handleTemplatePreview = (row) => {
        setOpenTemplatePreview(true);
        setPreviewData(row);
    };

    const handleClosePreviewTemplate = () => {
        setOpenTemplatePreview(false);
        setPreviewData(null);
        setPreviewSize('desktop');
    };

    const handlePreviewSize = (size) => setPreviewSize(size);

    const handleCloseEmailCompose = () => {
        setOpenEmailCompose(false);
        setIsNewTemplate(false);
        setTimeout(() => {
            document.body.style.overflowY = 'auto';
        }, 500);
    };

    const handleCloseSmsCompose = () => {
        setOpenSMSCompose(false);
        setIsNewTemplate(false);
    };

    const handleSelectAllRows = (checked) => {
        onSelectAllRows(checked, filterData() && filterData().map((row) => row.id));
    };

    const commonProps = ({ row }) => ({
        row,
        isSuperAdmin,
        selected: selected.includes(row.id),
        onSelectRow: () => handleSelect({
            row,
            type,
            sequenceId,
            setOpenEmailCompose,
            setIsNewTemplate,
            setOpenSMSCompose,
            setOpenWhatsappCompose,
            handleSuccess
        }),
        handleDuplicate: () => handleDuplicate({ row, handleSuccess }),
        onSendTestMail: (value) => onSendTestMail({ sequenceId, row, value, campaign, type, handleSuccess }),
        onChangeStatus: handleChangeStatus,
        onDeleteRow: () => onDeleteRow({ row, type, sequenceId, handleSuccess }),
        messageType: type,
        LeadTemplate,
        setTemplateOpen,
        setFullScreen,
        handleUserTemplate: () => handleUserTemplate(row),
        handleRowSelection,
        selectedRow,
        campaign,
        handleTemplatePreview,
    });

    const commonTemplateProps = { page, type, filterData, rowsPerPage };

    const commonMessageComposeProps = {
        practiceId,
        handleSaveMessage,
        isNewTemplate,
        messageType: LeadTemplate && LeadTemplate.length ? LeadTemplate : type,
        setTemplate,
        LeadTemplate,
        setTemplateOpen,
        useTemplate,
    };

    return (
        <Box sx={stackStyle}>
            <Stack sx={stackStyle}>
                {(type === 'Practice_Message' || type === 'OnBoarding') && (
                    <MessageDetailToolbar
                        sequenceName={sequenceName}
                        isAdmin={isAdmin}
                        treatmentList={treatmentList}
                        updateSequenceStatus={updateSequenceStatus}
                        deleteSequenceStatus={deleteSequenceStatus}
                    />
                )}
                {!isFromSearch && (
                    <MessageToolbar
                        messageData={messageData}
                        onOpenSidebar={onOpenSidebar}
                        sequenceName={sequenceName}
                        filterData={setFilterStatus}
                        type={type}
                        campaign={campaign}
                        unLayerFilter={unLayerFilter}
                        onChangeFilterStatus={onChangeFilterStatus}
                        filterStatus={filter}
                        debouncedValue={debouncedValue}
                    />
                )}
                <TemplateMessageList
                    {...commonTemplateProps}
                    fullScreen={fullScreen}
                    isFromSearch={isFromSearch}
                    list={list}
                    order={order}
                    orderBy={orderBy}
                    selected={selected}
                    onSort={onSort}
                    handleSelectAllRows={handleSelectAllRows}
                    handleSendUnableToContact={handleSendUnableToContact}
                    isFromUnableToContact={isFromUnableToContact}
                    commonProps={commonProps}
                    campaign={campaign}
                />
                <TemplatePaginationControls
                    {...commonTemplateProps}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    pageName={pageName}
                    handleOpen={handleOpen}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleClick={handleClick}
                />
                {(Object.keys(messageDetail).length > 0 || isNewTemplate) && (
                    <EmailMessageCompose
                        {...commonMessageComposeProps}
                        isEdit
                        treatmentNames={[]}
                        type='email_editor'
                        openEmailCompose={openEmailCompose}
                        composeDetail={isNewTemplate ? {} : messageDetail}
                        onCloseEmailCompose={handleCloseEmailCompose}
                        useOrSend={useOrSend}
                        OpenTemplate={OpenTemplate}
                        screen={screen}
                        campaign={campaign || isTemplateList}
                        isFromSearch={isFromSearch}
                    />
                )}
                {(Object.keys(messageDetail).length > 0 || isNewTemplate) && (
                    <SMSMessageCompose
                        {...commonMessageComposeProps}
                        isEdit
                        openSMSCompose={openSMSCompose}
                        onCloseSMSCompose={handleCloseSmsCompose}
                        composeDetail={messageDetail}
                        type='sms_editor'
                        useOrSend={useOrSend}
                    />
                )}
                <TemplatePreviewPopup
                    campaignPreview={openTemplatePreview}
                    handleClosePreviewTemplate={handleClosePreviewTemplate}
                    previewData={previewData}
                    handlePreviewSize={handlePreviewSize}
                    previewSize={previewSize}
                />
                <WhatsappMessageCompose
                    openSMSCompose={openWhatsappCompose}
                    onCloseSMSCompose={() => setOpenWhatsappCompose(false)}
                />
            </Stack>
        </Box>
    );
};

export default MessageList;

MessageList.propTypes = {
    messageData: PropTypes.array,
    treatmentList: PropTypes.array,
    sequenceName: PropTypes.string,
    practiceId: PropTypes.string,
    sequenceId: PropTypes.number,
    setSequenceName: PropTypes.func,
    messageDetail: PropTypes.object,
    isAdmin: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    onOpenSidebar: PropTypes.func,
    type: PropTypes.string,
    setTemplate: PropTypes.func,
    setTemplateOpen: PropTypes.func,
    LeadTemplate: PropTypes.string,
    useTemplate: PropTypes.string,
    useOrSend: PropTypes.string,
    OpenTemplate: PropTypes.func,
    screen: PropTypes.string,
    fullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func,
    setUseTemplate: PropTypes.func,
    handleRowSelection: PropTypes.func,
    selectedRow: PropTypes.func,
    campaign: PropTypes.bool,
    isTemplateList: PropTypes.bool,
    unLayerFilter: PropTypes.string,
    isPracticeMessages: PropTypes.bool,
    pageName: PropTypes.string,
    list: PropTypes.array,
    isFromSearch: PropTypes.bool,
    isFromUnableToContact: PropTypes.bool,
    handleSendUnableToContact: PropTypes.func,
};