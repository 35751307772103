import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// mui
import { useTheme } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
// other libraries
import CurrencyInput from 'react-currency-input-field';
// style
import { tableAmountInputStyle } from 'src/styles/PaymentRefund';

const CustomTreatmentPriceEditCell = ({ params }) => {
    const { id, value, field, hasFocus } = params;
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const apiRef = useGridApiContext();
    const ref = useRef();

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    const handleValueChange = (value) => {
        apiRef.current.setEditCellValue({ id, field, value });
    };

    return (
        <CurrencyInput
            ref={ref}
            name="treatmentValue"
            value={value}
            decimalSeparator={'.'}
            thousandSeparator={','}
            onValueChange={handleValueChange}
            className="table-currency-input"
            decimalsLimit={2}
            decimalScale={2}
            inputType={'text'}
            prefix={'£ '}
            style={tableAmountInputStyle('', 'appointment', isLight)}
            step={1}
        />
    );
};

export default CustomTreatmentPriceEditCell;

CustomTreatmentPriceEditCell.propTypes = {
    id: PropTypes.any,
    value: PropTypes.any,
    field: PropTypes.any,
    hasFocus: PropTypes.any,
    params: PropTypes.any,
};