/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useNavigate } from 'react-router-dom';
import {
    TableRow,
    TableCell,
    Checkbox,
    MenuItem,
    Typography,
    Button,
    Box,
} from '@mui/material';
import moment from 'moment';
import { isEmpty, maxBy } from 'lodash';
// components
import Iconify from '../Iconify';
import { TableMoreMenu } from '../table';
import { Label } from '..';
// redux
import { unMarkAsDuplicate } from '../../redux/slices/duplicateLeads';
import { leadPage } from '../../redux/slices/lead';
import { dispatch, useSelector } from '../../redux/store';
// styles
import { primary } from '../../theme/palette';
import { recordAttendedButtonStyle, statusLabelStyle, duplicateTableRowStyle } from '../../styles/NewLeadTableRowStyle';

const DuplicateTableRow = ({ row, handleCheckbox, handleClose, handleSimilarLeads, isDuplicate, type, similarLeadPopupClose, overviewTab }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [openMenu, setOpenMenuActions] = useState(null);
    const navigate = useNavigate();
    const { dateFormat } = useSelector((state) => state.lead);

    const handleUnMarkAsDuplicateAPI = (leadId) => {
        setOpenMenuActions(null);
        dispatch(unMarkAsDuplicate({ practiceId, leadId, handleClose }));
    };

    const handleOpenMenu = (event) => {
        if (row.is_disabled) return;
        if (!row.is_archive_pending) {
            setOpenMenuActions(event.currentTarget);
        }
    };

    const handleNavigate = () => {
        if (!(row.is_archive_pending || row.is_archived)) {
            dispatch(leadPage({ name: 'Duplicate Leads', path: '/dashboard/tables/duplicate-lead-table' }));
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${row.lead_id}`);
            if (overviewTab === 'leadDetail') {
                similarLeadPopupClose();
            }
        }
    };

    const sortedDates = () => {
        if (!isEmpty(row) && row?.lead_notes?.length > 0) {
            return maxBy(row?.lead_notes, (a) => a.updated_at);
        }
        return null;
    };

    return (
        <TableRow hover sx={duplicateTableRowStyle(row)}>
            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Checkbox checked={row.checked} onChange={(e) => handleCheckbox(e, row.lead_id)} disabled={row.is_archive_pending || row.is_archived || row.is_disabled} sx={{ mr: 2 }} />
                <Typography variant='subtitle2' sx={{ display: 'inline-flex' }} onClick={handleNavigate}>
                    <span>{dateFormat === 'date_format' ? moment(row?.created_at, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY') : row.created_at_human}</span>
                </Typography>
            </TableCell>
            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant='subtitle2' onClick={handleNavigate}>
                    <span style={{ textTransform: 'capitalize' }}>{row?.first_name || ''} {row?.last_name || ''}</span>
                </Typography>
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subtitle2' onClick={handleNavigate}>
                    <span>{row?.lead_contact?.email || ''}</span>
                </Typography>
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subtitle2' onClick={handleNavigate}>
                    <span>{row?.lead_contact?.phone || ''}</span>
                </Typography>
            </TableCell>
            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant='subtitle2' onClick={handleNavigate}>
                    {row?.lead_treatments?.[0]?.treatment || ''}
                </Typography>
            </TableCell>
            <TableCell align='left'>
                <Typography variant='subtitle2' onClick={handleNavigate}>
                    {row?.lead_treatments?.[0]?.price ? `£${row?.lead_treatments?.[0]?.price.toLocaleString()}` : ''}
                </Typography>
            </TableCell>
            <TableCell align='left' onClick={handleNavigate}>
                {row?.lead_treatments?.length > 0 && <Label color={'success'} sx={{ ...statusLabelStyle(row?.lead_treatments?.[0]?.status), cursor: 'pointer' }}>
                    <Typography variant='05 Others/Label' sx={{ cursor: 'pointer' }}>{row?.lead_treatments?.length > 0 && row?.lead_treatments?.[0]?.status !== 'CLOSED' ? (row?.lead_treatments?.[0]?.status.charAt(0) + row?.lead_treatments?.[0]?.status.toLowerCase().replace(/_/g, ' ').slice(1)) : 'Completed'}</Typography>
                </Label>}
            </TableCell>
            <TableCell
                sx={{ maxWidth: '320px' }}
            >
                <Box sx={{ display: 'flex', overflow: 'auto' }}>
                    {!(row.is_archived) && sortedDates() &&
                        <Typography variant='subtitle2' onClick={handleNavigate}>
                            <span>{sortedDates().note || ''}</span>
                        </Typography>
                    }
                    {(row.is_archived) && row.reason_for_archiving &&
                        <Typography variant='subtitle2' onClick={handleNavigate}>
                            <span>{row.reason_for_archiving || ''}</span>
                        </Typography>
                    }
                </Box>
            </TableCell>
            {(type === 'similarLeads' || isDuplicate) && <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                {(row.is_archived || row.is_archive_pending) && <Button
                    variant='contained'
                    color='primary'
                    disabled={row.is_archive_pending || row.is_archived}
                    sx={{ backgroundColor: 'rgba(145, 158, 171, 0.24)', color: 'rgba(145, 158, 171, 0.8)', minWidth: '150px' }}
                >
                    {row.is_archive_pending ? 'Archiving' : 'Archived'}
                </Button>}
            </TableCell>}
            {isDuplicate && <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Button onClick={() => handleSimilarLeads(row)} sx={{ ...recordAttendedButtonStyle, bgcolor: `${primary.main} !important` }}>
                    View Similar Leads
                </Button>
            </TableCell>}
            {isDuplicate && <TableCell align='right'>
                <TableMoreMenu
                    sx={{ width: '180px' }}
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={() => setOpenMenuActions(null)}
                    actions={<MenuItem
                        onClick={() => handleUnMarkAsDuplicateAPI(row.lead_id)}
                    >
                        <Iconify icon={'pepicons-pop:duplicate-off'} />
                        Unmark as duplicate
                    </MenuItem>}
                />
            </TableCell>}
        </TableRow>
    );
};

export default DuplicateTableRow;

DuplicateTableRow.propTypes = {
    row: PropTypes.object,
    handleCheckbox: PropTypes.func,
    handleClose: PropTypes.func,
    handleSimilarLeads: PropTypes.func,
    isDuplicate: PropTypes.bool,
    type: PropTypes.string,
    similarLeadPopupClose: PropTypes.func,
    overviewTab: PropTypes.string,
};
