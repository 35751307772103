/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactJoyride from 'react-joyride';
// @mui
import { alpha } from '@mui/material/styles';
import {
    Typography,
    ListItemText,
    ListItemAvatar,
    MenuItem,
    IconButton
} from '@mui/material';
// components
import {
    Iconify,
    Scrollbar,
    MenuPopover,
    BadgeStatus
} from '../../../components';
import { varHover } from '../../../components/animate';
import { CustomAvatar } from '../../../components/custom-avatar';
import { SwitchUserPopup } from '../../../components/dialogPopUp';
// hook
import { useSwitchUserToggle, useAuth } from '../../../hooks';
// API
import { getPracticeUserList } from '../../../redux/slices/practiceUser';
import { dispatch, useSelector } from '../../../redux/store';
import { fetchPosts } from '../../../redux/slices/postSlice';
// utils
import capitalizeFirstLetter from '../../../utils/capitalizedFunc';

// ----------------------------------------------------------------------

export default function ContactsPopover() {
    const [open, setOpen] = useState(null);
    const [users, setUsers] = useState(null);
    const [userList, setUserList] = useState([]);
    const [showJoyride, setShowJoyride] = useState(false);
    const { user } = useAuth();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { posts } = useSelector((state) => state.posts);
    const reviewSlug = posts?.find((post) => post.slug === 'patient-appt-review');
    const getUserList = () => practiceUser?.results && practiceUser?.results?.filter(el => el.user && el?.user?.first_name && !el?.user?.is_superuser && el?.user?.id !== user?.pk && el?.role !== 'Practitioner' && el?.role !== 'TCO') || [];
    const navigate = useNavigate();
    const [run, setRun] = useState(true);
    const [stepIndex, setStepIndex] = useState(0);
    const { pathname } = useLocation();
    const tempClass = document.getElementsByClassName('appt-review-table');

    const steps = [
        {
            complete: false,
            isFixed: true,
            target: '.patient-review',
            content: reviewSlug?.acf?.walktour_content?.[0]?.step_text,
            placement: 'right',
            data: {
                next: '/dashboard/patient-review',
            },
        },
        {
            isFixed: true,
            target: '.appt-review-table',
            content: reviewSlug?.acf?.walktour_content?.[1]?.step_text,
            placement: 'left',
            data: {
                previous: '/dashboard/patient-review',
                next: '/dashboard/patient-review',
            },
        },
        {
            isFixed: true,
            target: '.generate-review-link',
            content: reviewSlug?.acf?.walktour_content?.[2]?.step_text,
            placement: 'left',
            data: {
                previous: '/dashboard/patient-review',
                next: '/dashboard/patient-review',
            },
        },
    ];

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeUserList(practiceId));
        }
    }, [practiceId]);

    const {
        toggle: openAssignTask,
        onOpen: onOpenSwitchUser,
        onClose: onCloseSwitchUser
    } = useSwitchUserToggle();

    const openSwitchUser = (data) => {
        setUsers(data);
        setOpen(null);
        onOpenSwitchUser();
    };

    useEffect(() => {
        if (practiceUser) {
            const data = _.orderBy(getUserList(), [o => (o.user?.first_name).toLowerCase(), o => (o.user?.last_name).toLowerCase()], ['asc', 'asc']);
            setUserList(data);
        }
    }, [practiceUser]);

    useEffect(() => {
        if (pathname.includes('patient-review')) {
            setTimeout(() => {
                setRun(true);
            }, 400);
        }
    }, [pathname, tempClass]);

    useEffect(() => {
        dispatch(fetchPosts());
        const timer = setTimeout(() => {
            setShowJoyride(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const handleCallback = (data) => {
        const {
            action,
            index,
            step: {
                data: { next, previous },
            },
            type,
        } = data;
        if (type !== 'step:after') return;
        const isPreviousAction = action === 'prev';
        const target = isPreviousAction && previous ? previous : next;
        const isPatientReview = pathname.includes('patient-review');
        switch (index) {
            case 0:
                if (!isPatientReview) {
                    navigate(target);
                    setRun(false);
                }
                if (isPatientReview) {
                    navigate(target);
                }
                setStepIndex(index + 1);
                break;
            case 1:
                setStepIndex(index + 1);
                if (isPreviousAction && previous) {
                    navigate(previous);
                }
                break;
            case 2:
                setRun(false);
                break;
            default:
                break;
        }
    };

    return (
        <>
            {showJoyride && reviewSlug?.acf?.walktour_enabledisable === 'Enable' && (
                <ReactJoyride
                    callback={handleCallback}
                    continuous
                    hideBackButton
                    showProgress
                    run={run}
                    disableScrolling
                    locale={{ last: 'Finish' }}
                    steps={steps}
                    stepIndex={stepIndex}
                    styles={{
                        overlay: { height: document.body.scrollHeight }
                    }}
                />
            )}
            <IconButton
                component={m.button}
                whileTap='tap'
                whileHover='hover'
                variants={varHover(1.05)}
                color={open ? 'inherit' : 'default'}
                onClick={(event) => setOpen(event.currentTarget)}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
                    }),
                }}
            >
                <Iconify icon='solar:users-group-rounded-bold-duotone' width={24} />
            </IconButton>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={() => setOpen(null)}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 376,
                    '& .MuiMenuItem-root': {
                        px: 1.5,
                        height: 64,
                        borderRadius: 0.75,
                    },
                }}
            >
                <Typography variant='h6' sx={{ p: 1.5 }}>
                    Switch User <Typography component='span'>({getUserList() ? getUserList().length : 0})</Typography>
                </Typography>
                <Scrollbar sx={{ maxHeight: 64 * 6 }}>
                    {getUserList() && userList?.map((row, index) => (
                        <MenuItem onClick={() => openSwitchUser(row)} key={index}>
                            <ListItemAvatar sx={{ position: 'relative' }}>
                                <CustomAvatar
                                    src={row.user && row.user.avatar && row.user.avatar}
                                    BadgeProps={{
                                        badgeContent: <BadgeStatus status={row.status} />,
                                    }}
                                    name={row.user.first_name}
                                    alt={row.user.first_name}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                                secondaryTypographyProps={{ typography: 'caption' }}
                                primary={`${row?.user?.salutation && `${capitalizeFirstLetter(row.user && row.user.salutation)}.` || ''} ${row.user.first_name} ${row.user.last_name}`}
                                secondary={row.status === 'offline' || row.updated_at_human}
                            />
                        </MenuItem>))}
                </Scrollbar>
            </MenuPopover>
            <SwitchUserPopup
                open={openAssignTask}
                onClose={() => onCloseSwitchUser()}
                Users={users}
            />
        </>
    );
}
