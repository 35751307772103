export const rootStyle = (fullScreen) => ({
    ...(fullScreen && {
        top: 0,
        left: 0,
        zIndex: 99,
        margin: 'auto',
        width: {
            xs: 'calc(100% - 24px)',
            md: 'calc(100% - 80px)',
        },
        height: {
            xs: 'calc(100% - 24px)',
            md: 'calc(100% - 80px)',
        },
    }),
});

export const rootBoxStyle = {
    pl: 3,
    pr: 1,
    height: 60,
    display: 'flex',
    alignItems: 'center',
};

export const btnBoxStyle = {
    py: 2,
    px: 3,
    display: 'flex',
    flexDirection: 'column'
};