/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
    useForm
} from 'react-hook-form';
// @mui 
import {
    Card
} from '@mui/material';
import moment from 'moment';
// components
import { FormProvider } from '../../../../components/hook-form';
import {  AttendedAppointment as AttendedAppointmentPopup} from '../../../../components/Attended';

// @store
import { useSelector, dispatch } from '../../../../redux/store';
import { saveNewLeadDetail, updateCompleteStatus } from '../../../../redux/slices/lead';

const AttendedAppointment = forwardRef(({ handleNext },  ref) => {

    const { createLead } = useSelector((state) => state.lead);

    const defaultValues = {
        is_clinical_photographs_taken: false,
        is_oral_health_assessment_taken: false,
        is_xray_taken: false,
        is_digital_scan_taken: false,
        is_impressions_taken: false,
        is_treatment_options_discussed: false,
        is_treatment_plan_confirmed: false,
        is_finance_options_discussed: false,
        notes: ''
    };
    const methods = useForm({
        defaultValues,
    });
    const {
        handleSubmit
    } = methods;

    useImperativeHandle(
        ref, () => ({
            async submit(status) { 
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const addAtttendStatus = (data, noteData, status) => {
        const detail = {
            lead_treatment: data.lead_treatment || '',
            datetime: data.datetime || moment().format('DD-MM-YYYY HH:mm:ss'),
            method: data.method || '',
            contacted_via: data.contacted_via || '',
            note: data.note || ''
        };
        const noteDetail = {
            additional_notes: noteData.notes,
            clinical_attachments: [
                {
                    is_clinical_photographs_taken: noteData.is_clinical_photographs_taken,
                    is_oral_health_assessment_taken: noteData.is_oral_health_assessment_taken,
                    is_xray_taken: noteData.is_xray_taken
                }
            ],
            assessment_attachments: [
                {
                    is_digital_scan_taken: noteData.is_digital_scan_taken,
                    is_impressions_taken: noteData.is_impressions_taken,
                    is_treatment_options_discussed: noteData.is_treatment_options_discussed,
                    is_treatment_plan_confirmed: noteData.is_treatment_plan_confirmed,
                    is_finance_options_discussed: noteData.is_finance_options_discussed
                }
            ]
        };
        
        dispatch(saveNewLeadDetail({ lead_atteneded: {detail, noteDetail }}));
        if (status === 'finish') {
            dispatch(updateCompleteStatus(true));
            return;
        }
        handleNext(true);
        // dispatch(appointmentsAttended(practiceId, createLead.id, data.id, detail, noteDetail, handleCallback));
    };

    // const handleCallback = (data) => {
    //     if (data.success) {
    //         handleNext(true);
    //     }
    //     dispatch(updateError(data))
    // }

    const onSubmit = (data, status) => {
        addAtttendStatus(createLead.lead_appointment, data, status);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ p: 3 }}>
                <AttendedAppointmentPopup ref={ref}  />
            </Card>
        </FormProvider>
    );
});

AttendedAppointment.propTypes = {
    handleNext: PropTypes.func
};
export default AttendedAppointment;
