/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { uniq, unionBy } from 'lodash';
import { MenuItem } from '@mui/material';
import { saveNewAudience, updateSelectedAudience } from 'src/redux/slices/audienceManager';
import { dispatch, useSelector } from '../../../redux/store';
import { RHFSelect } from '../../hook-form';
import groupedTreatments from '../../../utils/groupedTreatments';

const AudienceLeadCategoryDropdown = () => {
    const {
        treatmentsList: { results },
    } = useSelector((state) => state.practiceTreatment);
    const { newAudience, selectedAudienceData } = useSelector((state) => state.audienceManager);
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        const category = results.filter((el) => el.treatment_item?.treatment_category?.name === e.target.value)[0]?.treatment_item?.treatment_category?.id || '';
        if (category === '') return;
        const value = { lead_treatments__treatment__treatment_item__treatment_category__in: [category] };
        if (Object.values(selectedAudienceData).length > 0) {
            const updatedCampaign = { ...selectedAudienceData, recepient: { ...selectedAudienceData.recepient, filters: { ...selectedAudienceData?.recepient?.filters, ...value } } };
            dispatch(updateSelectedAudience(updatedCampaign));
            return;
        }
        dispatch(saveNewAudience({ lead_treatments__treatment__treatment_item__treatment_category__in: value, category__in: [e.target.value] }));
    };

    useEffect(() => {
        if (selectedAudienceData) {
            const selectedCategory = results.filter((el) => selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in?.includes((el.treatment_item?.treatment_category?.id)))[0]?.treatment_item?.treatment_category?.name || '';
            setSelectedCategory(selectedCategory);
        }
    }, [category, selectedAudienceData]);

    useEffect(() => {
        if (results) {
            const data = unionBy(results, 'id');
            const categoryData = uniq(groupedTreatments(data).map((e) => e.category));
            setCategory([...categoryData]);
        }
    }, [results]);
    return (
        <RHFSelect
            name="Category"
            label="Select Category"
            InputLabelProps={{ shrink: true }}
            value={newAudience.category__in || selectedCategory || ''}
            onChange={handleCategoryChange}
            sx={{ textTransform: 'capitalize', width: '100%' }}
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
        >
            {category.map((options, index) => (
                <MenuItem key={index} value={options}>
                    {options}
                </MenuItem>
            ))}
        </RHFSelect>
    );
};

export default AudienceLeadCategoryDropdown;
