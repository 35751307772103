import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';
import { localStorageSetItem, localStorageGetItem } from '../../utils/storage-available';
import { getListData, getReferralData, getSourceTypeData, getReminderMethodData, getReasonsListData, getContactMethodListData, getSalutationOptions, getFinanceTermOptions } from '../../utils/getReferralData';

// ----------------------------------------------------------------

const initialState = {
    preferredContactMethodData: (localStorageGetItem('PreferredContactMethodData') && getListData(JSON.parse(localStorageGetItem('PreferredContactMethodData'))) || []),
    referral: (localStorageGetItem('ReferralData') && getReferralData(JSON.parse(localStorageGetItem('ReferralData'))) || []),
    sourceType: (localStorageGetItem('SourceTypeData') && getSourceTypeData(JSON.parse(localStorageGetItem('SourceTypeData'))) || []),
    reminderMethod: (localStorageGetItem('ReminderMethodData') && getReminderMethodData(JSON.parse(localStorageGetItem('ReminderMethodData'))) || []),
    reminderPriority: (localStorageGetItem('ReminderPriorityData') && getListData(JSON.parse(localStorageGetItem('ReminderPriorityData'))) || []),
    reasons: (localStorageGetItem('ReasonsData') && getReasonsListData(JSON.parse(localStorageGetItem('ReasonsData'))) || []),
    contactMethod: (localStorageGetItem('ContactMethodData') && getContactMethodListData(JSON.parse(localStorageGetItem('ContactMethodData'))) || []),
    appointmentType: (localStorageGetItem('AppointmentTypeData') && getListData(JSON.parse(localStorageGetItem('AppointmentTypeData'))) || []),
    salutationOptions: (localStorageGetItem('SalutationOptions') && getSalutationOptions(JSON.parse(localStorageGetItem('SalutationOptions'))) || []),
    appointmentMethod: (localStorageGetItem('AppointmentMethodData') && getListData(JSON.parse(localStorageGetItem('AppointmentMethodData'))) || []),
    archiveReasons: (localStorageGetItem('ArchiveReasonsData') || []),
    financeTermOptions: (localStorageGetItem('FinanceTermOptions') && getFinanceTermOptions(JSON.parse(localStorageGetItem('FinanceTermOptions'))) || []),
    reportType: (localStorageGetItem('ReportTypeData') && getListData(JSON.parse(localStorageGetItem('ReportTypeData'))) || []),
    refundPaymentReason: (localStorageGetItem('RefundPaymentReasonData') && getListData(JSON.parse(localStorageGetItem('RefundPaymentReasonData'))) || []),
};

export const slice = createSlice({
    name: 'schema',
    initialState,
    reducers: {
        setPreferredContactMethod(state, action) {
            state.preferredContactMethodData = action.payload;
        },
        setReferral(state, action) {
            state.referral = action.payload;
        },
        setSourceType(state, action) {
            state.sourceType = action.payload;
        },
        setReminderMethod(state, action) {
            state.reminderMethod = action.payload;
        },
        setReminderPriority(state, action) {
            state.reminderPriority = action.payload;
        },
        setReasons(state, action) {
            state.reasons = action.payload;
        },
        setArchiveReasons(state, action) {
            state.archiveReasons = action.payload;
        },
        setContactMethod(state, action) {
            state.contactMethod = action.payload;
        },
        setAppointmentType(state, action) {
            state.appointmentType = action.payload;
        },
        setSalutationOptions(state, action) {
            state.salutationOptions = action.payload;
        },
        setAppointmentMethod(state, action) {
            state.appointmentMethod = action.payload;
        },
        setFinanceTermOptions(state, action) {
            state.financeTermOptions = action.payload;
        },
        setReportType(state, action) {
            state.reportType = action.payload;
        },
        setRefundPaymentReason(state, action) {
            state.refundPaymentReason = action.payload;
        },
    }
});

export default slice.reducer;

export function getSchemaList() {
    return async () => {
        try {
            const response = await callApi('/api/v1/schema/?format=json&fields={components{schemas}}');
            if (response.status === 200) {
                dispatch(slice.actions.setPreferredContactMethod(getListData(response?.data?.components?.schemas?.PreferredContactMethodEnum?.enum)));
                localStorageSetItem('PreferredContactMethodData', JSON.stringify(response?.data?.components?.schemas?.PreferredContactMethodEnum?.enum));
                dispatch(slice.actions.setReferral(getReferralData(response?.data?.components?.schemas?.ReferralEnum?.enum)));
                localStorageSetItem('ReferralData', JSON.stringify(response?.data?.components?.schemas?.ReferralEnum?.enum));
                dispatch(slice.actions.setSourceType(getSourceTypeData(response?.data?.components?.schemas?.LeadSourceTypeEnum?.enum)));
                localStorageSetItem('SourceTypeData', JSON.stringify(response?.data?.components?.schemas?.LeadSourceTypeEnum?.enum));
                dispatch(slice.actions.setReminderMethod(getReminderMethodData(response?.data?.components?.schemas?.Method12bEnum?.enum)));
                localStorageSetItem('ReminderMethodData', JSON.stringify(response?.data?.components?.schemas?.Method12bEnum?.enum));
                dispatch(slice.actions.setReminderPriority(getListData(response?.data?.components?.schemas?.PriorityEnum?.enum)));
                localStorageSetItem('ReminderPriorityData', JSON.stringify(response?.data?.components?.schemas?.PriorityEnum?.enum));
                dispatch(slice.actions.setReasons(getReasonsListData(response?.data?.components?.schemas?.NoteEnum?.enum?.filter((ele) => (ele !== 'AWAITING_RESPONSE' && ele !== 'FOLLOW_UP')))));
                localStorageSetItem('ReasonsData', JSON.stringify(response?.data?.components?.schemas?.ReasonFb5Enum?.enum?.filter((ele) => (ele !== 'AWAITING_RESPONSE' && ele !== 'FOLLOW_UP'))));
                dispatch(slice.actions.setArchiveReasons(response?.data?.components?.schemas?.ReasonFb5Enum?.enum));
                localStorageSetItem('ArchiveReasonsData', JSON.stringify(response?.data?.components?.schemas?.ReasonFb5Enum?.enum));
                dispatch(slice.actions.setContactMethod(getContactMethodListData(response?.data?.components?.schemas?.ContactedVia812Enum?.enum)));
                localStorageSetItem('ContactMethodData', JSON.stringify(response?.data?.components?.schemas?.ContactedVia812Enum?.enum));
                dispatch(slice.actions.setAppointmentType(getListData(response?.data?.components?.schemas?.Type918Enum?.enum)));
                localStorageSetItem('AppointmentTypeData', JSON.stringify(response?.data?.components?.schemas?.Type918Enum?.enum));
                dispatch(slice.actions.setSalutationOptions(getSalutationOptions(response?.data?.components?.schemas?.Salutation67eEnum?.enum)));
                localStorageSetItem('SalutationOptions', JSON.stringify(response?.data?.components?.schemas?.Salutation67eEnum?.enum));
                dispatch(slice.actions.setAppointmentMethod(getListData(response?.data?.components?.schemas?.Method81cEnum?.enum)));
                localStorageSetItem('AppointmentMethodData', JSON.stringify(response?.data?.components?.schemas?.Method81cEnum?.enum));
                dispatch(slice.actions.setFinanceTermOptions(getFinanceTermOptions(response?.data?.components?.schemas?.TermEnum?.enum)));
                localStorageSetItem('FinanceTermOptions', JSON.stringify(response?.data?.components?.schemas?.TermEnum?.enum));
                dispatch(slice.actions.setReportType(getListData(response?.data?.components?.schemas?.ReportTypeEnum?.enum)));
                localStorageSetItem('ReportTypeData', JSON.stringify(response?.data?.components?.schemas?.ReportTypeEnum?.enum));
                dispatch(slice.actions.setRefundPaymentReason(getListData(response?.data?.components?.schemas?.ReasonEnum?.enum)));
                localStorageSetItem('RefundPaymentReasonData', JSON.stringify(response?.data?.components?.schemas?.ReasonEnum?.enum));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}
