export function getTreatmentCellClassName(color) {
    switch (color) {
        case '#D1E9FC':
            return 'light-blue';
        case '#76B0F1':
            return 'medium-blue';
        case '#2065D1':
            return 'dark-blue';
        case '#103996':
            return 'navy-blue';
        case '#061B64':
            return 'deep-navy';
        case '#EFD6FF':
            return 'light-purple';
        case '#C684FF':
            return 'medium-purple';
        case '#8E33FF':
            return 'dark-purple';
        case '#5119B7':
            return 'violet';
        case '#27097A':
            return 'deep-violet';
        case '#CAFDF5':
            return 'light-aqua';
        case '#61F3F3':
            return 'medium-aqua';
        case '#00B8D9':
            return 'dark-aqua';
        case '#006C9C':
            return 'teal';
        case '#003768':
            return 'deep-teal';
        case '#D3FCD2':
            return 'light-green';
        case '#77ED8B':
            return 'medium-green';
        case '#22C55E':
            return 'dark-green';
        case '#118D57':
            return 'forest-green';
        case '#065E49':
            return 'deep-forest';
        case '#FFF5CC':
            return 'light-yellow';
        case '#FFD666':
            return 'medium-yellow';
        case '#FFAB00':
            return 'dark-yellow';
        case '#B76E00':
            return 'orange';
        case '#7A4100':
            return 'deep-orange';
        case '#FFE9D5':
            return 'peach';
        case '#FFAC82':
            return 'salmon';
        case '#FF5630':
            return 'light-red';
        case '#B71D18':
            return 'medium-red';
        case '#7A0916':
            return 'dark-red';
        case '#F9FAFB':
            return 'white';
        case '#F4F6F8':
            return 'light-gray';
        case '#DFE3E8':
            return 'medium-gray';
        case '#C4CDD5':
            return 'dark-gray';
        case '#919EAB':
            return 'steel';
        case '#6E7E91':
            return 'charcoal';
        default:
            return '';
    }
}
export function getStatusCellClassName(status) {
    switch (status) {
        case 'TREATMENT_STARTED':
            return 'treatment-started-color';
        case 'CONSULTATION_BOOKED':
            return 'consultation-booked-color';
        case 'CONSULTATION_ATTENDED':
            return 'consultation-attended-color';
        case 'FAILED_TO_ATTEND_CONSULTATION':
            return 'failed-to-attend-color';
        case 'NEW':
            return 'new-status-color';
        case 'CLOSED':
            return 'closed-status-color';
        default:
            return '';
    }
}

export function getTextColor(bgColor) {
    switch (bgColor) {
        case '#D1E9FC':
            return '#103996'; // Navy blue
        case '#76B0F1':
            return '#061B64'; // Deep navy
        case '#2065D1':
            return '#FFFFFF'; // White
        case '#103996':
            return '#FFFFFF'; // White
        case '#061B64':
            return '#FFFFFF'; // White
        case '#EFD6FF':
            return '#27097A'; // Dark purple
        case '#C684FF':
            return '#27097A'; // Violet
        case '#8E33FF':
            return '#EFD6FF'; // Violet
        case '#5119B7':
            return '#FFFFFF'; // White
        case '#27097A':
            return '#FFFFFF'; // White
        case '#CAFDF5':
            return '#003768'; // Dark aqua
        case '#61F3F3':
            return '#006C9C'; // Teal
        case '#00B8D9':
            return '#003768'; // Teal
        case '#006C9C':
            return '#FFFFFF'; // White
        case '#003768':
            return '#FFFFFF'; // White
        case '#D3FCD2':
            return '#065E49'; // Dark green
        case '#77ED8B':
            return '#065E49'; // Dark green
        case '#22C55E':
            return '#065E49'; // Deep forest
        case '#118D57':
            return '#D3FCD2'; // Deep forest
        case '#065E49':
            return '#FFFFFF'; // White
        case '#FFF5CC':
            return '#7A4100'; // Orange
        case '#FFD666':
            return '#7A4100'; // Orange
        case '#FFAB00':
            return '#7A4100'; // Deep orange
        case '#B76E00':
            return '#7A4100'; // Deep orange
        case '#7A4100':
            return '#FFFFFF'; // White
        case '#FFE9D5':
            return '#7A0916'; // Dark red
        case '#FFAC82':
            return '#7A0916'; // Dark red
        case '#FF5630':
            return '#FFE9D5'; // Medium red
        case '#B71D18':
            return '#FFFFFF'; // White
        case '#7A0916':
            return '#FFFFFF'; // White
        case '#F9FAFB':
            return '#6E7E91'; // Charcoal
        case '#F4F6F8':
            return '#6E7E91'; // Charcoal
        case '#DFE3E8':
            return '#6E7E91'; // Charcoal
        case '#C4CDD5':
            return '#6E7E91'; // Charcoal
        case '#919EAB':
            return '#FFFFFF'; // White
        case '#6E7E91':
            return '#FFFFFF'; // White
        default:
            return '#000000'; // Black
    }
}

export function getStatusCellTextColor(status) {
    switch (status) {
        case 'TREATMENT_STARTED':
            return { color: 'rgb(34, 154, 22)' };
        case 'CONSULTATION_BOOKED':
            return { color: 'rgb(81, 25, 183)' };
        case 'CONSULTATION_ATTENDED':
            return { color: 'rgb(183, 129, 3)' };
        case 'FAILED_TO_ATTEND_CONSULTATION':
            return { color: 'rgb(183, 33, 54)' };
        case 'NEW':
            return { color: 'rgb(0, 108, 156)' };
        case 'CLOSED':
            return { color: '#637381' };
        default:
            return '#000000';
    }
}

export const handleTreatmentClassName = () => ({
    '.light-blue': {
        backgroundColor: '#D1E9FC'
    },
    '.medium-blue': {
        backgroundColor: '#76B0F1'
    },
    '.dark-blue': {
        backgroundColor: '#2065D1'
    },
    '.navy-blue': {
        backgroundColor: '#103996'
    },
    '.deep-navy': {
        backgroundColor: '#061B64'
    },
    '.light-purple': {
        backgroundColor: '#EFD6FF'
    },
    '.medium-purple': {
        backgroundColor: '#C684FF'
    },
    '.dark-purple': {
        backgroundColor: '#8E33FF'
    },
    '.violet': {
        backgroundColor: '#5119B7'
    },
    '.deep-violet': {
        backgroundColor: '#27097A'
    },
    '.light-aqua': {
        backgroundColor: '#CAFDF5'
    },
    '.medium-aqua': {
        backgroundColor: '#61F3F3'
    },
    '.dark-aqua': {
        backgroundColor: '#00B8D9'
    },
    '.teal': {
        backgroundColor: '#006C9C'
    },
    '.deep-teal': {
        backgroundColor: '#003768'
    },
    '.light-green': {
        backgroundColor: '#D3FCD2'
    },
    '.medium-green': {
        backgroundColor: '#77ED8B'
    },
    '.dark-green': {
        backgroundColor: '#22C55E'
    },
    '.forest-green': {
        backgroundColor: '#118D57'
    },
    '.deep-forest': {
        backgroundColor: '#065E49'
    },
    '.light-yellow': {
        backgroundColor: '#FFF5CC'
    },
    '.medium-yellow': {
        backgroundColor: '#FFD666'
    },
    '.dark-yellow': {
        backgroundColor: '#FFAB00'
    },
    '.orange': {
        backgroundColor: '#B76E00'
    },
    '.deep-orange': {
        backgroundColor: '#7A4100'
    },
    '.peach': {
        backgroundColor: '#FFE9D5'
    },
    '.salmon': {
        backgroundColor: '#FFAC82'
    },
    '.light-red': {
        backgroundColor: '#FF5630'
    },
    '.medium-red': {
        backgroundColor: '#B71D18'
    },
    '.dark-red': {
        backgroundColor: '#7A0916'
    },
    '.white': {
        backgroundColor: '#F9FAFB'
    },
    '.light-gray': {
        backgroundColor: '#F4F6F8'
    },
    '.medium-gray': {
        backgroundColor: '#DFE3E8'
    },
    '.dark-gray': {
        backgroundColor: '#C4CDD5'
    },
    '.steel': {
        backgroundColor: '#919EAB'
    },
    '.charcoal': {
        backgroundColor: '#6E7E91'
    },
    '.new-status-color': {
        backgroundColor: 'rgba(0, 184, 217, 0.16)'
    },
    '.closed-status-color': {
        backgroundColor: '#919EAB29'
    },
    '.consultation-attended-color': {
        backgroundColor: 'rgba(255, 193, 7, 0.16)'
    },
    '.consultation-booked-color': {
        backgroundColor: 'rgba(142, 51, 255, 0.16)'
    },
    '.treatment-started-color': {
        backgroundColor: 'rgba(84, 214, 44, 0.16)'
    },
    '.failed-to-attend-color': {
        backgroundColor: 'rgba(255, 72, 66, 0.16)'
    },
});