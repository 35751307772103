import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, Typography, ListItemText } from '@mui/material';
import { capitalizeFirstWord } from '../../../../utils/capitalizedFunc';
import { followUpSlice, updateAppointmentDetail } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
import { TableTreatment } from '../../../../utils/allTableTab';
import {
    tableCellInnerBoxStyle,
    apptMethodSelectStyle,
} from '../../../../styles/NewLeadTableRowStyle';

const ApptTypeField = ({ rowData, tableType, handleSetTableData }) => {
    const [value, setValue] = useState(rowData?.appointments?.[0]?.type || '');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { appointmentType } = useSelector((state) => state.schema);
    const { enqueueSnackbar } = useSnackbar();
    const treatmentValue = TableTreatment(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    useEffect(() => {
        if (rowData?.appointments?.[0]?.type) {
            setValue(rowData.appointments[0].type);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        const detail = {
            type: newValue
        };
        dispatch(updateAppointmentDetail(practiceId, rowData?.lead_id, rowData?.appointments?.[0]?.id, detail, handleSuccess, ''));
        setValue(newValue);
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        dispatch(followUpSlice(rowData?.lead_id, practiceId, 'LeadDetail', handleClose));
    };

    const handleClose = (data) => {
        handleSetTableData(data);
    };

    return (
        <Box sx={tableCellInnerBoxStyle}>
            {rowData?.appointments?.[0]?.type &&
                <Select
                    value={value}
                    onChange={handleChange}
                    renderValue={(value) => (
                        <Typography variant='05 Others/Label' sx={{ fontWeight: 600 }}>{capitalizeFirstWord(value)}</Typography>
                    )}
                    IconComponent={() => null}
                    sx={apptMethodSelectStyle}
                >
                    {appointmentType?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <ListItemText primary={capitalizeFirstWord(option.label)} />
                        </MenuItem>
                    ))}
                </Select>
            }
        </Box>
    );
};

ApptTypeField.propTypes = {
    rowData: PropTypes.object,
    tableType: PropTypes.string,
    handleSetTableData: PropTypes.func,
};

export default ApptTypeField;
