/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
// libraries
import { Box, Grid, Button, Typography, Container, Card, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab';
// components
import SimilarLeadWarningPopup from '../../sections/@dashboard/leadDetail/SimilarLeadWarningPopup';
import { FormProvider, RHFTextField } from '../hook-form';
import Iconify from '../Iconify';
import ClinCheckForm from '../clinCheck/ClinCheckForm';
import { TabsComponents, NewAppointment, TreatmentComplete, FollowUp, Archive } from '../tabs';
import AssignedUsersTooltip from '../AssignedUsersTooltip';
// store
import { dispatch, useSelector } from '../../redux/store';
import { saveNewLeadDetail } from '../../redux/slices/lead';
import { updateError } from '../../redux/slices/user';
import { clearClinCheckData, getClinCheckList } from '../../redux/slices/clincheck';
// context/utils/constant/hooks
import { useSettingsContext } from '../settings/context';
import capitalizeFirstLetter from '../../utils/capitalizedFunc';
import { getTreatmentDate } from '../../utils/multiTreatmentFlow';
import { isDisabledTabForFinishButton } from '../../utils/clinCheckUtils';
import { Assessments as assessments, Clinical as clinical } from '../../constants/attendedAppointMentConst';
import { useTabs } from '../../hooks';
// style
import {
    attendedTitleStyle,
    attendedCardStyle,
    attachmentTitleStyle,
    attendedGridStyle,
    attendedTabsTitle,
    leadFooterButtonsStyle,
    attendedFooterStyle,
    btnStyle,
    textField,
    labelTextStyle,
    changeButtonStyle
} from '../../styles/AttendedAppointment';
import { primary } from '../../theme/palette';
import TCOScript from './TCOScript';
import AssignedUsersShow from '../AssignedUsersShow';

const AttendedAppointment = forwardRef(({ isOverview, type, id, handleClose, user, OpenAssignTask, editDetailHandler, handleCloseAndReload, handleReloadLeads, isFinishButtonAvailable }, ref) => {
    const attendedRef = useRef();
    const { currentTab, onChangeTab } = useTabs('');
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { followLead, currentTreatmentData } = useSelector((state) => state.lead);
    const { practice } = useSelector((state) => state.practice);
    const settings = useSettingsContext();
    const [active, setActive] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const [depositPendingStatus, setDepositPendingStatus] = useState(false);
    const { clinCheckListing } = useSelector((state) => state.clinCheck);
    const clinCheckData = clinCheckListing?.results?.[0];
    const followLeadFilterAttend = followLead?.lead_appointment?.filter((el) => el.status === 'BOOKED');

    const { isLoader } = useSelector((state) => state.loaderReducer);

    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);

    const [assigneeSelected, setAssigneeSelected] = useState(followLead?.assignees || []);

    useEffect(() => {
        if (type !== 'CreatedLead' && !isEmpty(currentTreatmentData)) {
            setDepositPendingStatus(currentTreatmentData.is_treatment_fee_pending);
        }
    }, [currentTreatmentData]);

    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;

    useEffect(() => {
        if (type !== 'CreatedLead') {
            const leadId = followLead?.id || user?.lead_id || user?.id;
            const treatmentId = followLead?.lead_treatments?.[0]?.id || user?.treatment_id || user?.lead_treatments?.[0]?.id;
            const data = {
                leadId,
                treatmentId,
                practiceId
            };
            dispatch(clearClinCheckData());
            if (leadId && treatmentId) {
                dispatch(getClinCheckList(data));
            }
        }
    }, []);

    const defaultValues = {
        is_clinical_photographs_taken: false,
        is_oral_health_assessment_taken: false,
        is_xray_taken: false,
        is_digital_scan_taken: false,
        is_impressions_taken: false,
        is_treatment_options_discussed: false,
        is_treatment_plan_confirmed: false,
        is_finance_options_discussed: false,
        notes: ''
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        control,
        handleSubmit
    } = methods;

    const appointmentNotesUpdate = async (noteData, callAppointmentNote) => {
        const noteDetail = {
            additional_notes: noteData.notes,
            clinical_attachments: [
                {
                    is_clinical_photographs_taken: noteData.is_clinical_photographs_taken,
                    is_oral_health_assessment_taken: noteData.is_oral_health_assessment_taken,
                    is_xray_taken: noteData.is_xray_taken
                }
            ],
            assessment_attachments: [
                {
                    is_digital_scan_taken: noteData.is_digital_scan_taken,
                    is_impressions_taken: noteData.is_impressions_taken,
                    is_treatment_options_discussed: noteData.is_treatment_options_discussed,
                    is_treatment_plan_confirmed: noteData.is_treatment_plan_confirmed,
                    is_finance_options_discussed: noteData.is_finance_options_discussed
                }
            ]
        };
        dispatch(saveNewLeadDetail({ attentendAppoiment: { noteDetail, activeTab, callAppointmentNote } }));
        await attendedRef.current.submit('finish');
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        []
    );

    const onSubmit = async (data) => {
        if (!activeTab) {
            dispatch(updateError({ message: 'Please select any one Tab', success: false }));
            return;
        }
        const callAppointmentNote = JSON.stringify(data) !== JSON.stringify(defaultValues);
        appointmentNotesUpdate(data, callAppointmentNote);
    };

    const handleNext = async () => {
        handleSubmit(onSubmit)();
    };

    useImperativeHandle(
        ref, () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        [activeTab]
    );

    const getCurrentTreatmentData = () => {
        if (isOverview) {
            return { selectedTreatmentData: currentTreatmentDataGlobal };
        }
        return {};
    };

    const PROFILE_TABS = [
        {
            value: 'Starting treatment',
            icon: <Iconify icon={'bxs:flag'} width={20} height={20} />,
            component: <NewAppointment {...getCurrentTreatmentData()} ref={attendedRef} type={activeTab} popupType={type} rowId={id} handleClose={handleClose} handleReloadLeads={handleReloadLeads} />,
        },
        {
            value: 'ClinCheck',
            icon: <Iconify icon={'fluent:check-20-filled'} width={20} height={20} />,
            component: <ClinCheckForm ref={attendedRef} handleClose={handleClose} handleCloseAndReload={handleCloseAndReload} leadDetail={user} currentTab={currentTab} onChangeTab={onChangeTab} />,
        },
        {
            value: 'Follow Up',
            icon: <Iconify icon={'ant-design:calendar-filled'} width={20} height={20} />,
            component: <FollowUp ref={attendedRef} handleClose={handleClose}/>,
        },
        {
            value: 'Treatment complete',
            icon: <Iconify icon={'akar-icons:circle-check-fill'} width={20} height={20} />,
            component: <TreatmentComplete ref={attendedRef} type={type} handleNext={handleNext} handleClose={handleClose} />,
        },
        {
            value: 'Archive',
            icon: <Iconify icon={'eva:archive-fill'} width={20} height={20} />,
            component: <Archive ref={attendedRef} handleClose={handleClose} screenType={type} />,
        }
    ];

    const getAttendedComponents = () => {
        if (type === 'CreatedLead') {
            return PROFILE_TABS.filter(el => el.value !== 'Archive' && el.value !== 'ClinCheck');
        }
        if (type === 'Starting treatment' && followLead?.lead_treatments?.[0]?.treatment === 'Invisalign') {
            return PROFILE_TABS;
        }
        return PROFILE_TABS.filter(el => el.value !== 'ClinCheck');
    };

    const getIsVisible = () => {
        if (isFinishButtonAvailable) {
            return {};
        }
        return { display: 'none' };
    };

    const getSelectedTreatment = () => {
        if (isOverview) return currentTreatmentDataGlobal?.treatmentData?.treatment || followLead?.lead_treatments?.[0]?.treatment;
        return followLead?.lead_treatments?.[0]?.treatment;
    };

    const getSelectedTreatmentDateTime = () => {
        const tempDate = followLead?.lead_appointment?.find((ele) => ele?.lead_treatment === currentTreatmentDataGlobal?.treatmentData?.id)?.datetime;
        if (isOverview && tempDate) return getTreatmentDate(tempDate) || '';
        return getTreatmentDate(followLeadFilterAttend?.[0]?.datetime) || '';
    };

    const getCurrentTreatmentAssignees = () => {
        if (isOverview) return followLead?.lead_appointment?.find((ele) => ele?.lead_treatment === currentTreatmentDataGlobal?.treatmentData?.id)?.assignees;
        return followLeadFilterAttend?.[0]?.assignees;
    };

    useEffect(() => {
        if (followLead?.assignees) {
            setAssigneeSelected(followLead.assignees || []);
        }
    }, [followLead]);

    return (
        <Box>
            <Grid container justifyContent='flex-start'>
                <Box sx={attendedTitleStyle}>
                    <Typography variant='h3' sx={{ fontSize: '24px !important', lineHeight: '36px' }}>Attended appointment details</Typography>
                </Box>
            </Grid>
            {type !== 'CreatedLead' && <SimilarLeadWarningPopup type={'DialogPopup'} dialogClose={() => handleClose()} />}
            <TCOScript handleClose={handleClose} handleNext={onSubmit} ref={attendedRef} />
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {user !== undefined && user && <Box sx={{ m: '30px 0 50px' }}>
                    <Box sx={{ mb: '24px', display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%', p: '0 40px 0 0', borderRight: '1px dotted rgba(145, 158, 171, 0.24)' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={labelTextStyle}>Patient details</Typography>
                                <Button sx={changeButtonStyle} onClick={() => editDetailHandler()}><Iconify width={18} height={18} icon={'solar:pen-bold'} sx={{ mr: 1 }} /> Change</Button>
                            </Box>
                            <Box sx={{ width: '100%', mt: '8px' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{capitalizeFirstLetter(followLead?.first_name || '')} {capitalizeFirstLetter(followLead?.last_name || '')}</Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{followLead?.lead_address && `${followLead?.lead_address?.address_line_1} ${followLead?.lead_address?.address_line_2} ${followLead?.lead_address?.city} ${followLead?.lead_address?.county} ${followLead?.lead_address?.postcode}`}</Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Phone: {followLead?.phone || followLead?.lead_contact?.phone || ''}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '50%', p: '0 0 0 40px' }}>
                            <Typography sx={labelTextStyle}>Treatment</Typography>
                            <Box sx={{ width: '100%', mt: '8px' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{getSelectedTreatment() || ''}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ mb: '24px', display: 'flex', width: '100%' }}>
                        <Box sx={{ width: '50%', p: '0 40px 0 0', borderRight: `1px dotted ${primary.paperColor}` }}>
                            <Typography sx={labelTextStyle}>Date & time</Typography>
                            <Box sx={{ width: '100%', mt: '8px' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{getSelectedTreatmentDateTime(followLeadFilterAttend)}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <AssignedUsersTooltip assignees={getCurrentTreatmentAssignees(followLeadFilterAttend)} handleClick={(e) => OpenAssignTask(user, getCurrentTreatmentAssignees(followLeadFilterAttend), e)} practiceUser={practiceUser} />
                            <AssignedUsersShow assigneeSelected={assigneeSelected} setAssigneeSelected={setAssigneeSelected} leadId = {followLead?.id || user?.lead_id || user?.id}/>
                           
                        </Box>
                    </Box>
                </Box>}
                <Card sx={attendedCardStyle}>
                    <Typography variant='h6' sx={attachmentTitleStyle(active)} onClick={() => setActive(!active)}>
                        Attachments
                        {
                            active ? <Iconify width={20} height={20} icon={'akar-icons:chevron-down'} /> : <Iconify width={20} height={20} icon={'akar-icons:minus'} />
                        }
                    </Typography>
                    {!active ?
                        <Box>
                            <Grid container spacing={1} sx={attendedGridStyle}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='h6' sx={{ marginBottom: '15px', fontSize: '16px !important' }}>
                                        Assessment attachments
                                    </Typography>
                                    <FormGroup sx={{ gap: 2 }}>
                                        {assessments.map((clinic, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Controller
                                                        control={control}
                                                        name={clinic.value}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                onChange={e => {
                                                                    field.onChange(e.target.checked);
                                                                }}
                                                                checked={field.value}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={clinic.label}
                                                sx={{ width: 'fit-content' }}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='h6' sx={{ marginBottom: '15px', fontSize: '16px !important' }}>
                                        Clinical attachments
                                    </Typography>
                                    <FormGroup sx={{ gap: 2 }}>
                                        {clinical.map((assessment, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Controller
                                                        control={control}
                                                        name={assessment.value}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                onChange={e => {
                                                                    field.onChange(e.target.checked);
                                                                }}
                                                                checked={field.value}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={assessment.label}
                                                sx={{ width: 'fit-content' }}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <RHFTextField
                                sx={textField}
                                name='notes' multiline rows={5}
                                label='Attachment notes'
                                placeholder='Add notes here'
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box> : null
                    }
                </Card>
            </FormProvider>
            <Grid container justifyContent='flex-start' sx={{ mt: 8 }}>
                <Box sx={attendedTabsTitle}>
                    <Typography variant='h6'>Select the outcome of the attended appointment</Typography>
                </Box>
                <TabsComponents TabsContent={getAttendedComponents()} setActiveTab={setActiveTab} activeAttachments={setActive} />
            </Grid>
            <Box sx={{ ...attendedFooterStyle, ...getIsVisible() }}>
                <Container
                    maxWidth={settings.themeStretch ? false : 'xl'}
                    sx={leadFooterButtonsStyle}
                >
                    <LoadingButton
                        color='primary'
                        variant='contained'
                        loading={isLoader}
                        loadingPosition='start'
                        onClick={handleNext}
                        sx={btnStyle(type)}
                        disabled={isDisabledTabForFinishButton(clinCheckData?.status, currentTab, activeTab, isPaymentMandatory, depositPendingStatus, type) || isLoader}
                    >
                        Finish
                    </LoadingButton>
                </Container>
            </Box>
        </Box>
    );
});

AttendedAppointment.propTypes = {
    type: PropTypes.string,
    id: PropTypes.number,
    handleClose: PropTypes.func,
    user: PropTypes.object,
    OpenAssignTask: PropTypes.func,
    editDetailHandler: PropTypes.func,
    handleCloseAndReload: PropTypes.func,
    handleReloadLeads: PropTypes.func,
    isFinishButtonAvailable: PropTypes.bool,
    isOverview: PropTypes.bool,
};

export default AttendedAppointment;
