import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const ScriptDateTimePicker = ({ data }) => {
    if (data?.field_type?.attribute === 'lead_dob') return null;

    return <Controller
        name={`datetime_${[data.id]}`}
        render={({ field, fieldState: { error } }) => (
            <DateTimePicker
                label=''
                value={field.value || null}
                format='dd/MM/yyyy HH:mm'
                ampm={false}
                onChange={(newValue) => field.onChange(newValue)}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                        onKeyDown: (e) => e.preventDefault()
                    },
                }}
            />
        )}
    />;
};

export default ScriptDateTimePicker;

ScriptDateTimePicker.propTypes = {
    data: PropTypes.object,
};