/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Box, Button, Typography, FormLabel, MenuItem, Select, OutlinedInput, FormControl, InputLabel, FormGroup, Checkbox, FormControlLabel, Radio, RadioGroup, FormHelperText } from '@mui/material';
// component
import { Iconify } from '../../../components';
import { FormProvider, RHFTextField, RHFRadioGroup, RHFSelect } from '../../../components/hook-form';
import { NormalLoading } from '../../../components/loading';
// redux
import { dispatch, useSelector } from '../../../redux/store';
import { getFormSubmission, updateSubmission, getSubmissionSection } from '../../../redux/slices/callScript';
// style
import {
    CheckoutBoxStyle,
    BackButtonStyle,
    TitleStyle,
    fieldLabelStyle,
    requiredTextStyle
} from '../../../styles/CallScripts';
import { EditCallScriptSchema } from '../../../validations/validations';
import { menuItemStyle } from '../../../styles/PersonalDetailsStyle';

// ----------------------------------------------------------------------------------------------------------
export default function EditCallScript({ formId, submissionId, setRenderComponent }) {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { submissionData } = useSelector((state) => state.callScript);

    useEffect(() => {
        if (formId && submissionId) {
            const data = {
                practiceId,
                formId,
                submissionId
            };
            dispatch(getFormSubmission(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formId, submissionId]);

    const handleBack = () => {
        setRenderComponent('scriptList');
    };

    const defaultValues = {};

    const methods = useForm({
        resolver: yupResolver(EditCallScriptSchema(submissionData)),
        defaultValues,
    });

    const {
        control,
        handleSubmit,
        setValue,
    } = methods;

    useEffect(() => {
        if (submissionData) {
            submissionData?.fields?.map((data) => {
                if (data?.field?.field_type?.field_type === 'text') {
                    setValue(`text_${[data.field.id]}`, data.value);
                };
                if (data?.field?.field_type?.field_type === 'char') {
                    setValue(`char_${[data.field.id]}`, data.value);
                };
                if (data?.field?.field_type?.field_type === 'boolean') {
                    setValue(`boolean_${[data.field.id]}`, data.value);
                };
                if (data?.field?.field_type?.field_type === 'choice') {
                    if (data?.field?.field_type?.ui_type === 'DROPDOWN') {
                        setValue(`checkbox_${[data.field.id]}`, data.value);
                    }
                    if (data?.field?.field_type?.ui_type === 'MULTI_SELECT') {
                        setValue(`multiCheckbox_${[data.field.id]}`, data?.value ? data.value.split(' | ') : []);
                    }
                    if (data?.field?.field_type?.ui_type === 'CHECKBOX') {
                        setValue(`typeCheckbox_${[data.field.id]}`, data?.value ? data.value.split(' | ') : []);
                    }
                    if (data?.field?.field_type?.ui_type === 'RADIO') {
                        setValue(`typeRadio_${[data.field.id]}`, data.value);
                    }
                };
                if (data?.field?.field_type?.field_type === 'datetime') {
                    setValue(`datetime_${[data.field.id]}`, data.value !== '' ? new Date(data.value) : '');
                };
                return true;
            });
        };
    }, [submissionData]);

    const onSubmit = (data) => {
        const textKeys = Object.entries(data).filter(key => key);
        const fieldDataArray = submissionData?.fields;
        const fieldDataWithValue = (textKeys || []).map((el) => {
            const matchingField = fieldDataArray.find(
                (sx) => sx?.field?.id?.toString() === el[0]?.split('_')[1]
            );
            if (matchingField) {
                return {
                    fields: matchingField.field.id,
                    value: el[1] === null ? '' : (Array.isArray(el[1]) ? el[1].join(' | ') : el[1]),
                    id: matchingField.id
                };
            }
            return null;
        }).filter(Boolean);
        const dataValue = {
            practice: Number(practiceId),
            lead: submissionData?.lead,
            formId: formId,
            fields: fieldDataWithValue
        };
        dispatch(updateSubmission(Number(practiceId), formId, submissionId, dataValue, handleSuccess));
    };

    const handleSuccess = (data) => {
        if (data?.success) {
            dispatch(getSubmissionSection([]));
            setRenderComponent('scriptList');
        };
    };

    return (
        <Stack>
            <Box sx={{ position: 'relative' }}>
                <NormalLoading tableData={submissionData.id === undefined && 'No Found'} pageCount={submissionData.id === undefined && 'No Found'} />
                <Box sx={CheckoutBoxStyle}>
                    <Button
                        color="inherit"
                        variant='outlined'
                        onClick={handleBack}
                        className="back-button"
                        sx={BackButtonStyle}
                    >
                        <Iconify icon={'material-symbols:arrow-back-rounded'} width={18} height={18} />
                    </Button>
                    <Typography variant="h5" sx={TitleStyle}>{submissionData?.form?.category === "TCO" ? "TCO Script" : "Enquiry Follow-up Script"}</Typography>
                </Box>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ p: '0' }}>
                        {submissionData?.fields?.map((data, index) => (
                            <Box sx={{ mb: '16px' }} key={index}>
                                {data?.field?.field_type?.field_type && <FormLabel sx={{ ...fieldLabelStyle, mb: '5px' }}>
                                    {data?.field?.label}{data?.field?.is_required && <span style={requiredTextStyle}>(Required)</span>}
                                </FormLabel>}
                                {data?.field?.field_type?.field_type === 'text' &&
                                    <RHFTextField
                                        name={`text_${[data.field.id]}`}
                                        size="small"
                                        multiline rows={5}
                                        defaultValue={data.value}
                                    />
                                }
                                {data?.field?.field_type?.field_type === 'label' &&
                                    <RHFTextField
                                        name={`label_${[data.field.id]}`}
                                        size="small"
                                        defaultValue={data.value}
                                    />
                                }
                                {data?.field?.field_type?.field_type === 'char' &&
                                    <RHFTextField
                                        name={`char_${[data.field.id]}`}
                                        size="small"
                                        inputProps={{ maxLength: data?.field?.field_type?.field_type?.max_length }}
                                        defaultValue={data.value}
                                    />
                                }
                                {data?.field?.field_type?.field_type === 'boolean' &&
                                    <RHFRadioGroup
                                        name={`boolean_${[data.field.id]}`}
                                        options={[
                                            { label: 'Yes', value: 'yes' },
                                            { label: 'No', value: 'no' },
                                        ]}
                                        size="small"
                                        defaultValue={data.value}
                                        sx={{ margin: '-6px 2px 0', width: '100%' }}
                                    />
                                }
                                {data?.field?.field_type?.field_type === 'choice' &&
                                    <Box sx={{ mt: 1 }}>
                                        {data?.field?.field_type?.ui_type === 'DROPDOWN' && <RHFSelect
                                            name={`checkbox_${[data.field.id]}`}
                                            label="Select dropdown"
                                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                            defaultValue={data.value}
                                        >
                                            {(data?.field?.field_type?.field_type?.choices || data?.field?.choices)?.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={option}
                                                    sx={menuItemStyle}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </RHFSelect>}
                                        {data?.field?.field_type?.ui_type === 'MULTI_SELECT' &&
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="demo-multiple-name-label">Multi select dropdown</InputLabel>
                                                <Controller
                                                    render={({ field, fieldState: { error } }) => (
                                                        <>
                                                            <Select
                                                                labelId="demo-multiple-name-label"
                                                                id="demo-multiple-name"
                                                                name={`multiCheckbox_${[data?.field.id]}`}
                                                                multiple
                                                                sx={{ width: '100%' }}
                                                                input={<OutlinedInput label="Multi select dropdown" />}
                                                                value={field.value || []}
                                                                onChange={(event) => field.onChange(event.target.value)}
                                                            >
                                                                {(data?.field?.field_type?.field_type?.choices || data?.field?.choices)?.map((options, index) => (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={options}
                                                                    >
                                                                        {options}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {(!!error) && (
                                                                <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                                                            )}
                                                        </>
                                                    )}
                                                    control={methods.control}
                                                    name={`multiCheckbox_${[data?.field.id]}`}
                                                    defaultValue={data?.value ? data.value.split(' | ') : []}
                                                />
                                            </FormControl>
                                        }
                                        {data?.field?.field_type?.ui_type === 'CHECKBOX' && (
                                            <Controller
                                                render={({ field, fieldState: { error } }) => (
                                                    <FormGroup>
                                                        {(data?.field?.field_type?.field_type?.choices || data?.field?.choices)?.map((el, index) => (
                                                            <FormControlLabel
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        name={`typeCheckbox_${[data?.field.id]}_${index}`}
                                                                        checked={field.value.includes(el)}
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.checked
                                                                                ? [...field.value, el]
                                                                                : field.value.filter((value) => value !== el);
                                                                            field.onChange(newValue);
                                                                        }}
                                                                        value={el}
                                                                    />
                                                                }
                                                                label={el}
                                                                sx={{ width: 'calc(50% - 16px)', display: 'flex', '.MuiTypography-root': { width: '100%' } }}
                                                            />
                                                        ))}
                                                        {(!!error) && (
                                                            <FormHelperText error={!!error}>{error ? error?.message : ''}</FormHelperText>
                                                        )}
                                                    </FormGroup>
                                                )}
                                                control={methods.control}
                                                name={`typeCheckbox_${[data?.field.id]}`}
                                                defaultValue={data?.value ? data.value.split(' | ') : []}
                                            />
                                        )}
                                        {data?.field?.field_type?.ui_type === 'RADIO' && (
                                            <FormControl>
                                                <Controller
                                                    render={({ field }) => (
                                                        <RadioGroup
                                                            row
                                                            name={`typeRadio_${[data?.field.id]}`}
                                                            value={field.value}
                                                            onChange={(event) => field.onChange(event.target.value)}
                                                        >
                                                            {(data?.field?.field_type?.field_type?.choices || data?.field?.choices)?.map((el, index) => (
                                                                <FormControlLabel
                                                                    key={index}
                                                                    control={<Radio />}
                                                                    label={el}
                                                                    value={el}
                                                                    sx={{ width: '100%', display: 'flex', '.MuiTypography-root': { width: '100%' } }}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    )}
                                                    control={methods.control}
                                                    name={`typeRadio_${[data?.field.id]}`}
                                                    defaultValue={data?.value}
                                                />
                                            </FormControl>
                                        )}
                                    </Box>
                                }
                                {data?.field?.field_type?.field_type === 'datetime' &&
                                    <Controller
                                        name={`datetime_${[data.field.id]}`}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <DateTimePicker
                                                label=""
                                                value={field.value || null}
                                                ampm={false}
                                                format="dd/MM/yyyy HH:mm"
                                                onChange={(newValue) => {
                                                    field.onChange(newValue);
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!error,
                                                        helperText: error?.message,
                                                        onKeyDown: (e) => e.preventDefault()
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                }
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ p: '10px 0 24px' }}>
                        <Button type="submit" variant="contained">Update</Button>
                    </Box>
                </FormProvider>
            </Box>
        </Stack>
    );
}

EditCallScript.propTypes = {
    formId: PropTypes.any,
    submissionId: PropTypes.any,
    setRenderComponent: PropTypes.any,
};