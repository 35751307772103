import React from 'react';
import PropTypes from 'prop-types';
import { Box, TableCell, Typography } from '@mui/material';

const CustomTableCell = ({ style, label }) =>
    <TableCell>
        <Box sx={{ ml: 0 }}>
            <Typography variant='span' noWrap sx={style}>
                {label}
            </Typography>
        </Box>
    </TableCell>;

CustomTableCell.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
};

export default CustomTableCell;