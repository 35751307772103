/* eslint-disable no-return-assign */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import {
    TableRow,
    TableCell,
    Typography,
    Box
} from '@mui/material';
//
import Iconify from '../Iconify';
import Label from '../Label';
import { CustomAvatar } from '../custom-avatar';
// constants
import { TAB_HEAD } from '../../constants/LeadDetailConst';
// store
import { useSelector } from '../../redux/store';
// style
import {
    analyticsTDStyle,
    analyticsAvatarStyle,
    rowStyle,
    labelStyle
} from '../../styles/GeneralAnalytics';

// ---------------------------------------------------------------------

const DoctorTableRow = ({ data, handleTableRow, type }) => {
    const { practiceUser: { results } } = useSelector((state) => state.practiceUser);
    const [avatar, setAvatar] = useState('');
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (results && data) {
            if (type === 'doctor') {
                setName(`${data?.user?.salutation || ''} ${data?.user?.first_name || ''} ${data?.user?.last_name || ''}`);
                setAvatar(data?.user?.avatar ? data?.user?.avatar : '');
                setCount(data?.conversions_count);
                setPrice(data?.conversions_value);
            }
            if (type === 'patient') {
                setName(`${data?.user?.salutation || ''} ${data?.lead?.first_name || ''} ${data?.lead?.last_name || ''}` || '');
            }
        }
    }, [results, data, type]);

    return (
        <TableRow hover sx={rowStyle(type)} onClick={() => handleTableRow(data?.id, avatar, name, data?.user?.job_title)}>
            <TableCell sx={analyticsTDStyle}>
                <CustomAvatar
                    src={type === 'doctor' && avatar}
                    sx={analyticsAvatarStyle}
                    name={name}
                >
                    {name.charAt(1)}
                </CustomAvatar>
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Typography variant='h7' sx={{ fontSize: '14px' }}>
                        {name}
                    </Typography>
                    {type === 'doctor' && <Typography sx={{ fontSize: '14px !important', lineHeight: '22px', color: '#637381' }}>
                        {data?.user?.job_title}
                    </Typography>}
                </Box>
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
                {type === 'doctor' ? count : data?.treatment || 0}
            </TableCell>
            <TableCell sx={{ textAlign: 'center', color: '#00AB55' }}>
                {type === 'doctor' ? `£${price?.toLocaleString() || 0}` : `£${data?.price?.toLocaleString() || ''}` || 0}
            </TableCell>
            {type === 'patient' && <TableCell sx={{ textAlign: 'right' }}>
                <Label sx={labelStyle}>
                    {TAB_HEAD.map((el) => {
                        if (el?.value === data?.status) {
                            return el?.label;
                        }
                        return '';
                    }) || ''}
                </Label>
            </TableCell>}
            {type === 'doctor' && <TableCell sx={{ textAlign: 'center', cursor: 'pointer' }}>
                <Iconify width={26} height={26} icon={'ic:round-chevron-right'} />
            </TableCell>}
        </TableRow>
    );
};

DoctorTableRow.propTypes = {
    type: PropTypes.string,
    data: PropTypes.any,
    handleTableRow: PropTypes.func,
};

export default DoctorTableRow;
