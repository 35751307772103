import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Button, MenuItem, TablePagination, Box } from '@mui/material';
import { Iconify, MenuPopover } from '../../../components';
import { Channel } from '../../../constants/MessageListConst';
import { common } from '../../../theme/palette';
import {
    paginationOuterBoxStyle,
    paginationInnerBoxStyle,
    btnStackStyle,
    menuPopperStyle,
    listBtnStackStyle,
} from '../../../styles/MessageListStyle';

const TemplatePaginationControls = ({
    filterData,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    type,
    pageName,
    handleOpen,
    open,
    anchorEl,
    handleClose,
    handleClick
}) => (
    <Box sx={paginationOuterBoxStyle}>
        <Box sx={paginationInnerBoxStyle}>
            {filterData().length > 10 && (
                <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    page={page >= Math.ceil(filterData().length / rowsPerPage) ? 0 : page}
                    count={filterData().length}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            )}
        </Box>
        {(type && type) === 'Template' && (
            <Stack spacing={3} sx={pageName === 'TemplateListPage' ? listBtnStackStyle : btnStackStyle}>
                <Button
                    id="demo-positioned-button"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-describedby={'pop-button'}
                    variant="contained"
                    onClick={handleOpen}
                    sx={{ width: 190 }}
                >
                    <iconify-icon icon="akar-icons:plus" /> Add New Template
                </Button>
                <MenuPopover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                    arrow="right-center"
                    sx={menuPopperStyle}
                >
                    <Stack spacing={0.15}>
                        {Channel.map((option) => (
                            <MenuItem
                                key={option.value}
                                onClick={(e) => handleClick(option.value, e)}
                                sx={{ color: common.black }}
                                onClose={handleClose}
                            >
                                {option.value === 'EMAIL' ? (
                                    <Stack sx={{ flexDirection: 'row' }}>
                                        {' '}
                                        <Iconify icon={'eva:email-fill'} width={20} height={20} mr={1} /> {option.name}{' '}
                                    </Stack>
                                ) : (
                                    <Stack sx={{ flexDirection: 'row' }}>
                                        {' '}
                                        <Iconify icon={'ic:baseline-sms'} width={20} height={20} mr={1} /> {option.name}{' '}
                                    </Stack>
                                )}
                            </MenuItem>
                        ))}
                    </Stack>
                </MenuPopover>
            </Stack>
        )}
    </Box>
);

TemplatePaginationControls.propTypes = {
    filterData: PropTypes.func,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    type: PropTypes.string,
    pageName: PropTypes.string,
    handleOpen: PropTypes.func,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func,
    handleClick: PropTypes.func,
};

export default TemplatePaginationControls;
