export const appointmentType = [
    {label:'Consultation', value:'CONSULTATION'},
    {label:'Treatment', value:'TREATMENT'}
];
export const paymentTypeOptions = [
    { label: 'Deposit + GoCardless', value: 'DEPOSIT_AND_GOCARDLESS' },
    { label: 'Deposit + Stripe', value: 'DEPOSIT_AND_STRIPE' },
    { label: 'Deposit + Finance', value: 'DEPOSIT_AND_FINANCE' },
    { label: 'Payment in Full Made', value: 'FULL_PAYMENT' },
    { label: 'Finance Only', value: 'FINANCE_ONLY' },
];