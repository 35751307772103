import { createSlice } from '@reduxjs/toolkit';
import { callApi } from 'src/utils/apiCall';
import { dispatch } from '../store';
import { updateError } from './user';
import { getPracticeId } from './clincheck';

const initialState = {
    wpNotifications: [],
    emailNotifications: [],
    notificationsJwt: '',
};

const slice = createSlice({
    name: 'liveNotifications',
    initialState,
    reducers: {
        resetWpNotifications(state) {
            Object.assign(state, initialState);
        },
        setPersistedWpNotifications(state, action) {
            state.wpNotifications = [...state.wpNotifications, action.payload];
        },
        setPersistedEmailNotifications(state, action) {
            state.emailNotifications = [...state.emailNotifications, action.payload];
        },
        setWpNotifications(state, action) {
            state.wpNotifications = action.payload;
        },
        setNotificationsJwt(state, action) {
            state.notificationsJwt = action.payload;
        },
        setEmailNotifications(state, action) {
            state.emailNotifications = action.payload;
        },
    },
});

export default slice.reducer;

export const { setWpNotifications, resetWpNotifications, setPersistedWpNotifications, setEmailNotifications, setPersistedEmailNotifications } = slice.actions;

export function getNotificationsJwt(data) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const { userId } = data;
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${userId}/notifications/generate_websocket_jwt/`, 'post');
            if (response.status === 200) {
                dispatch(slice.actions.setNotificationsJwt(response.data.auth));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}