import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { dispatch } from '../store';
import { updateError } from './user';

const initialState = {
    templateData: null
};

const slice = createSlice({
    name: 'settingsTemplate',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        settingsTemplateData(state, action) {
            state.templateData = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const { settingsTemplateData } = slice.actions;

export function getSettingsTemplateData(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-template-preferences/`);
            if (response.status === 200) {
                dispatch(slice.actions.settingsTemplateData(response.data));
            } else {
                dispatch(updateError({ success: true, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: JSON.stringify(error.message) || 'Something went wrong' }));
        }
    };
}

export function addSettingsTemplateData(practiceId, data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-template-preferences/`, 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Template added successfully' || 'Something went wrong' }));
                dispatch(getSettingsTemplateData(practiceId));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                dispatch(getSettingsTemplateData(practiceId));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: JSON.stringify(error.message) || 'Something went wrong' }));
        }
    };
}

export function updateSettingsTemplateData(practiceId, data, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-template-preferences/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Template update successfully' || 'Something went wrong' }));
                dispatch(getSettingsTemplateData(practiceId));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
                dispatch(getSettingsTemplateData(practiceId));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: JSON.stringify(error.message) || 'Something went wrong' }));
        }
    };
}
