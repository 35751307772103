/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    TableRow,
    TableCell,
    MenuItem,
    Divider,
    Typography
} from '@mui/material';
//
import { useSelector } from 'react-redux';
import moment from 'moment';
import { statusLabelStyle } from '../../../styles/NewLeadTableRowStyle';
import { ConfirmDelete } from '../practiceTreatment';
// use components
import { Label, Iconify } from '../../../components';
import { TableMoreMenu } from '../../../components/table';
// hooks
import { useToggle } from '../../../hooks';
// util
import { capitalizeFirstWord } from '../../../utils/capitalizedFunc';

export default function FinancialTableRow({ row, selected }) {
    const theme = useTheme();
    const { dateFormat } = useSelector((state) => state.lead);
    const { financeTermOptions } = useSelector((state) => state.schema);

    const [openMenu, setOpenMenuActions] = useState(null);

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const onDeleteRow = () => {
        selected(row, 'delete');
        onCloseContacts();
    };

    const showDate = (data) => (data === 'date_format' ? moment(row?.updatedAt, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY') : row.updatedAt_human);

    return (
        <TableRow hover sx={{ cursor: 'pointer', borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
            <TableCell align='left'>
                <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} 
                    sx={{
                        px: 2,
                        backgroundColor: 'rgba(84, 214, 44, 0.16)',
                        color: '#229A16',
                    }}
                >
                    {showDate(dateFormat) || 'No found'}
                </Label>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }} >
                <Typography variant="subtitle2" >
                    <span style={{ textTransform: 'capitalize' }}>{row.firstName} {row.lastName}</span>
                </Typography>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="subtitle2" >
                    <span style={{ textTransform: 'capitalize' }}>{row.treatmentName || 'Not found'} </span>
                </Typography>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={'success'}
                    sx={{ ...statusLabelStyle(row?.treatmentStatus), cursor: 'pointer' }}
                >
                    <Typography variant='05 Others/Label' sx={{ cursor: 'pointer' }}>{row?.treatmentStatus !== 'CLOSED' ? (row.treatmentStatus.charAt(0) + row.treatmentStatus.toLowerCase().replace(/_/g, ' ').slice(1)) : 'Completed'}</Typography></Label>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="subtitle2" >
                    <span style={{ textTransform: 'capitalize' }}> ￡{row.price || 'Not found'} </span>
                </Typography>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="subtitle2" >
                    <span style={{ textTransform: 'capitalize' }}> ￡{row.depositAmount || 'Not found'} </span>
                </Typography>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="subtitle2" >
                    <span style={{ textTransform: 'capitalize' }}> {financeTermOptions?.find(e => e.value === row.term).label || 'Not found'} </span>
                </Typography>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }} >
                <Typography variant="subtitle2" >
                    <span style={{ textTransform: 'capitalize' }}>{row.loanSupplier.name || 'Not found'} </span>
                </Typography>
            </TableCell>

            <TableCell align='left' sx={{ whiteSpace: 'nowrap' }} >
                <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    <span style={{ textTransform: 'capitalize' }}>{capitalizeFirstWord(row.status)} </span>
                </Label>
            </TableCell>

            <TableCell align='right' sx={{ width: '30%' }}>
                <TableMoreMenu
                    open={openMenu}
                    onOpen={(e) =>  setOpenMenuActions(e.currentTarget)}
                    sx={{ width: '600px' }}
                    onClose={handleCloseMenu}
                    type={'FinancialTableRow'}
                    actions={
                        <>
                            <MenuItem onClick={() => {selected(row, 'update'); setOpenMenuActions(null);}}>
                                <Iconify icon={'eva:edit-fill'} />
								Edit
                            </MenuItem>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            {row.status !== 'PESNDING' &&
                                <>
                                    {(row.status !== 'ACCEPTED' && row.status !== 'DECLINED' && row.status !== 'REQUESTED_ADDITIONAL_INFORMATION' && row.status !== 'CANCELLED') && <MenuItem onClick={() => {selected(row, 'updateStatus', 'accepted'); setOpenMenuActions(null);}}>
                                        <Iconify icon={'healthicons:i-documents-accepted'} />
                                        Accepted
                                    </MenuItem>}
                                    {(row.status !== 'ACCEPTED' && row.status !== 'DECLINED' && row.status !== 'REQUESTED_ADDITIONAL_INFORMATION' && row.status !== 'CANCELLED') && <MenuItem onClick={() => {selected(row, 'updateStatus', 'declined' ); setOpenMenuActions(null);}}>
                                        <Iconify icon={'fluent-mdl2:entry-decline'} />
                                        Declined
                                    </MenuItem>}
                                    {(row.status !== 'ACCEPTED' && row.status !== 'DECLINED' && row.status !== 'REQUESTED_ADDITIONAL_INFORMATION' && row.status !== 'CANCELLED') && <MenuItem onClick={() => {selected(row, 'updateStatus', 'requested_additional_information'); setOpenMenuActions(null);}} sx={{ whiteSpace: 'normal' }}>
                                        <Iconify icon={'ic:round-info'} width={20} height={20} sx={{ minWidth: '20px' }} />
                                        Request Additional Information
                                    </MenuItem>}
                                    {(row.status !== 'ACCEPTED' && row.status !== 'DECLINED' && row.status !== 'REQUESTED_ADDITIONAL_INFORMATION' && row.status !== 'CANCELLED') && <Divider sx={{ borderStyle: 'dashed' }} />}
                                    {(row.status !== 'ACCEPTED' && row.status !== 'DECLINED' && row.status !== 'REQUESTED_ADDITIONAL_INFORMATION' && row.status !== 'CANCELLED') && <MenuItem onClick={() => {selected(row, 'updateStatus', 'cancelled'); setOpenMenuActions(null);}} sx={{ color: 'error.main' }}>
                                        <Iconify icon={'material-symbols:cancel-rounded'} />
                                        Cancelled
                                    </MenuItem>}
                                </>}
                            <MenuItem
                                onClick={onOpenContacts}
                                sx={{ color: 'error.main' }}
                            >
                                <Iconify icon={'eva:trash-2-outline'} />
								Delete
                            </MenuItem>
                            <ConfirmDelete
                                data={'Are you sure you want to delete ?'}
                                onDeleteRow={onDeleteRow}
                                handleCloseMenu={handleCloseMenu}
                                open={openContacts}
                                onClose={onCloseContacts}
                            />
                        </>
                    }
                />
            </TableCell>
        </TableRow>
    );
};

FinancialTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.func
};
