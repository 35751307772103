import { useSelector } from 'src/redux/store';

export default function useGetCategories() {

    const {
        treatmentsList: { results: treatmentCategoryResults },
    } = useSelector((state) => state.practiceTreatment);

    const getCategories = () => treatmentCategoryResults?.map((el) => el?.treatment_item?.treatment_category);

    return getCategories() || [];
}
