import { createSlice } from '@reduxjs/toolkit';
// utils
import { callApi } from '../../utils/apiCall';
import { errorHandler } from '../../utils/errorHandler';
//
import { dispatch } from '../store';
import { updateError } from './user';
import { setFacebookAdAccount } from './chat';

// ---------------------------------------------------------------------
const initialState = {
    isLoading: false,
    practiceUser: {
        results: []
    },
    inviteUser: [],
    message: '',
    error: '',
    stripeConnected: false,
    facebookConnected: false
};

const slice = createSlice({
    name: 'practiceIntegration',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        startLoading(state) {
            state.isLoading = true;
            state.error = '';
            state.message = '';
        },
        stripeConnectedSlice(state, action) {
            state.stripeConnected = action.payload;
        },
        facebookConnectedSlice(state, action) {
            state.facebookConnected = action.payload;
        },
    }
});

export const { stripeConnectedSlice, reset, facebookConnectedSlice } = slice.actions;

export default slice.reducer;

export function getStripe(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/stripe/`);
            if (response.status === 200) {
                dispatch(slice.actions.stripeConnectedSlice(response.data.results));
            } else {
                errorHandler(response.data, slice.actions.hasError);
            }
        } catch (err) {
            dispatch(updateError({ message: err.message || 'Somthing went wrong', success: false }));
        }
    };
}

export function stripeUrl() {
    return async () => {
        try {
            const response = await callApi('/api/v1/stripe/auth_url/');
            if (response.status === 200) {
                window.location.href = response.data.auth_url;
            } else {
                dispatch(updateError({ success: true, message: response.data.message || 'Something went wrong' }));
            }
        } catch (err) {
            dispatch(updateError({ message: err.message || 'Somthing went wrong', success: false }));
        }
    };
}

export function stripePreferenceUrl(data, practiceId, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/stripe/${id}/`, 'put', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Stripe preference url connected successfully' }));
            } else {
                dispatch(updateError({ success: false, message: response.data?.stripe_account_preference?.checkout_cancel_url?.toString() || response.data?.stripe_account_preference?.checkout_success_url?.toString() || 'Something went wrong' }));
            }
        } catch (err) {
            dispatch(updateError({ message: err.message || 'Somthing went wrong', success: false }));
        }
    };
}

export function stripeCallback(data, handlesuccess) {
    return async () => {
        try {
            const response = await callApi('/api/v1/stripe/callback/', 'post', data);
            if (response.status === 200) {
                handlesuccess({ success: true, message: 'Stripe connected successfully' });
            } else {
                handlesuccess({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' });
            }
        } catch (err) {
            dispatch(updateError({ message: err.message || 'Somthing went wrong', success: false }));
        }
    };
}

export function getFacebook(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/facebook-accounts/`);
            if (response.status === 200) {
                dispatch(slice.actions.facebookConnectedSlice(response.data.results));
            } else {
                errorHandler(response.data, slice.actions.hasError);
            }
        } catch (err) {
            dispatch(updateError({ message: err.message || 'Somthing went wrong', success: false }));
        }
    };
}

export function facebookUrl(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/facebook-accounts/connect/`);
            if (response.status === 200) {
                window.location.href = response.data.auth_url;
            } else {
                dispatch(updateError({ success: true, message: response.data.message || 'Something went wrong' }));
            }
        } catch (err) {
            dispatch(updateError({ message: err.message || 'Somthing went wrong', success: false }));
        }
    };
}

export function facebookCallback(practiceId, data, handlesuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/facebook-accounts/callback/`, 'post', data);
            if (response.status === 200) {
                handlesuccess({ success: true, message: 'Facebook connected successfully' });
            } else {
                handlesuccess({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' });
            }
        } catch (err) {
            dispatch(updateError({ message: err.message || 'Somthing went wrong', success: false }));
        }
    };
}

export function deleteFacebookAccount(practiceId, id, handleSuccess) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/facebook-accounts/${id}/disconnect/`, 'post');
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Account disconnected successfully!' }));
                handleSuccess({ success: true });
                dispatch(setFacebookAdAccount(''));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
                handleSuccess({ success: false, message: JSON.stringify(response?.data) });
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}
