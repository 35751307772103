import PropTypes from 'prop-types';

// @mui
import { Box, TableRow, TableCell, TableHead, TableSortLabel, Checkbox, MenuItem, } from '@mui/material';
import { useForm } from 'react-hook-form';
//
import { RHFSelect, FormProvider } from '../hook-form';
import { Iconify } from '..';
// constant
import { DATE_TITLES } from '../../constants/newLead';
// style
import { tableHeading, mainDateFilter, selectFilter, selectMenuStyle } from '../../styles/NewLead';
// redux
import { dispatch, useSelector } from '../../redux/store';
import { updateDateFormat, updateAppointmentDateFormat, updateAttendedDateFormat, updateLastContactDateFormat } from '../../redux/slices/lead';

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------
export default function TableHeadCustomV2({
    order,
    orderBy,
    headLabel,
    onSort,
    sx,
    practiceMessageTable,
    type,
    onChange,
    leadTable,
    handleSelectAll,
    showSelected,
    disabledCheckbox
}) {

    const { dateFormat, dateAppointmentFormat, dateAttendedFormat, dateLastContactFormat } = useSelector((state) => state.lead);

    const defaultValues = {
        title: dateFormat || '',
        attendedTitle: dateAttendedFormat || '',
        appointmentsTitle: dateAppointmentFormat || '',
        lastContactTitle: dateLastContactFormat || ''
    };

    const methods = useForm({
        defaultValues,
    });

    const { setValue } = methods;

    const handleChangeDateFormat = (e, data) => {
        if (data === 'created_at' || data === 'date') {
            localStorage.setItem('dateFormat', e.target.value);
            dispatch(updateDateFormat(e.target.value));
            setValue('title', e.target.value);
            return;
        }
        if (data === 'date_attended') {
            localStorage.setItem('attendedFormat', e.target.value);
            dispatch(updateAttendedDateFormat(e.target.value));
            setValue('attendedTitle', e.target.value);
            return;
        }
        if (data === 'lead_treatments__appointments__datetime') {
            localStorage.setItem('appointmentFormat', e.target.value);
            dispatch(updateAppointmentDateFormat(e.target.value));
            setValue('appointmentsTitle', e.target.value);
        }
        if (data === 'lastContact') {
            localStorage.setItem('lastContactFormat', e.target.value);
            dispatch(updateLastContactDateFormat(e.target.value));
            setValue('lastContactTitle', e.target.value);
        }
    };

    const getName = (id) => {
        if (id === 'date_attended') return 'attendedTitle';
        if (id === 'lead_treatments__appointments__datetime') return 'appointmentsTitle';
        if (id === 'lastContact') return 'lastContactTitle';
        return 'title';
    };

    return (
        <TableHead sx={sx}>
            <TableRow sx={{ border: practiceMessageTable ? '1px solid rgba(145, 158, 171, 0.12)' : '', borderRadius: '0' }}>
                {headLabel.map((headCell) => (

                    <TableCell
                        key={headCell.id}
                        align={headCell.align || 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={tableHeading(headCell, type, practiceMessageTable)}
                    >
                        {type === 'notification' && headCell.checkbox &&
                            <Checkbox
                                onChange={(e) => onChange(e, headCell.id)}
                                checked={headCell.count === 6}
                            />
                        }
                        {type !== 'similar_leads' && ((leadTable && headCell.checkbox) || (type === 'duplicate_leads' && headCell.checkbox)) &&
                            <Checkbox
                                onChange={(e) => handleSelectAll(e, headCell.id)}
                                checked={showSelected}
                                disabled={disabledCheckbox}
                                sx={{ mr: 2 }}
                            />
                        }
                        {onSort ? (
                            <TableSortLabel
                                hideSortIcon
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => onSort(headCell.id, headCell)}
                                sx={{ 'svg': { display: headCell.sort === '' && 'none' } }}
                            >
                                {headCell.label}

                                {orderBy === headCell.id ? (
                                    <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            headCell.label
                        )}

                        {headCell.dateFormat ?
                            <Box sx={mainDateFilter}>
                                <Iconify
                                    sx={{ mr: '8px', ml: '8px' }}
                                    icon={'mdi:calendar-filter-outline'}
                                    width={15}
                                    height={15}
                                />
                                <Box sx={selectFilter}>
                                    <FormProvider methods={methods}>
                                        <RHFSelect
                                            name={getName(headCell.id)}
                                            label="Title"
                                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                            onChange={(e) => handleChangeDateFormat(e, headCell.id)}
                                        >
                                            {DATE_TITLES.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={option.value}
                                                    sx={selectMenuStyle}
                                                >
                                                    <Iconify
                                                        sx={{ mr: '8px' }}
                                                        icon={option.icon}
                                                        width={18}
                                                        height={18}
                                                    />
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </RHFSelect>
                                    </FormProvider>
                                </Box>
                            </Box> : ''}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TableHeadCustomV2.propTypes = {
    onSort: PropTypes.func,
    orderBy: PropTypes.string,
    headLabel: PropTypes.array,
    order: PropTypes.oneOf(['asc', 'desc']),
    sx: PropTypes.object,
    practiceMessageTable: PropTypes.bool,
    type: PropTypes.any,
    onChange: PropTypes.func,
    leadTable: PropTypes.bool,
    handleSelectAll: PropTypes.any,
    showSelected: PropTypes.any,
    disabledCheckbox: PropTypes.any
};