import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Iconify } from '../../../../components';
import { useSelector } from '../../../../redux/store';
import { linkIconStyle, starButtonStyle } from './styles';

const MailButtons = ({ mailType, id, onStarClick, handlePopoverClick, handleClickMarkReadEvent, goToLeadDetailsPage, isProcessing, isFromSideBar }) => {
    const { selectedMailInfo } = useSelector((state) => state.mail);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 1 }}>
            {mailType !== 'Drafts' &&
                <Button aria-describedby={id} sx={starButtonStyle}>
                    <Tooltip title={selectedMailInfo?.is_starred ? 'Starred' : 'Not starred'}>
                        <IconButton size='small' sx={{ width: '28px', height: '28px' }} onClick={() => onStarClick(selectedMailInfo)}>
                            {selectedMailInfo?.is_starred ?
                                <Iconify width={20} height={20} icon='eva:star-fill' sx={{ minWidth: '20px', color: 'warning.main' }} />
                                : <Iconify width={20} height={20} icon='eva:star-outline' />}
                        </IconButton>
                    </Tooltip>
                </Button>}
            <Button sx={starButtonStyle}>
                <Tooltip title={'Label'}>
                    <IconButton size='small' sx={linkIconStyle} onClick={(event) => handlePopoverClick(event)}>
                        <Iconify icon='material-symbols:label-outline' width={20} height={20} sx={{ color: 'common.grey' }} />
                    </IconButton>
                </Tooltip>
            </Button>
            {mailType === 'Incoming' && <Button sx={starButtonStyle}>
                <Tooltip title={selectedMailInfo?.is_unread ? 'Mark as read' : 'Mark as unread'}>
                    <IconButton size='small' sx={linkIconStyle} onClick={() => handleClickMarkReadEvent(selectedMailInfo)} disabled={isProcessing}>
                        <Iconify
                            icon={selectedMailInfo?.is_unread ? 'material-symbols:mark-email-read-rounded' : 'material-symbols:mark-email-unread-rounded'}
                            width={20}
                            height={20}
                            sx={{ color: 'common.grey' }}
                        />
                    </IconButton>
                </Tooltip>
            </Button>}
            {isFromSideBar && <Button aria-describedby={id} sx={starButtonStyle}>
                <Tooltip title='View profile'>
                    <IconButton size='small' sx={linkIconStyle} onClick={() => goToLeadDetailsPage()}>
                        <Iconify width={20} height={20} icon={'mdi:user-outline'} color='common.grey' />
                    </IconButton>
                </Tooltip>
            </Button>}
        </Box>
    );
};

MailButtons.propTypes = {
    mailType: PropTypes.string,
    id: PropTypes.number,
    onStarClick: PropTypes.func,
    handlePopoverClick: PropTypes.func,
    handleClickMarkReadEvent: PropTypes.func,
    goToLeadDetailsPage: PropTypes.func,
    isProcessing: PropTypes.bool,
    isFromSideBar: PropTypes.bool,
};

export default MailButtons;