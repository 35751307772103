/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// @mui
import {
    Box,
    TextField,
    Autocomplete
} from '@mui/material';
// style
import {
    tagsBoxStyle,
    allTagChipStyle,
} from '../styles/AutocompleteTags';
// redux
import { saveNewCampaign, updateSelectedCampaign }  from '../redux/slices/campaign';
import { dispatch, useSelector } from '../redux/store';
// constant
import { campaignConst } from '../constants/Campaign';

// ----------------------------------------------------------------------------------------------------------

export default function StatusDropDown() {
    const { newCampaign, selectedCampaign } = useSelector((state) => state.campaign);
    const [getStatusValue, setStatusValue] = useState([]);

    useEffect(() => {
        if (selectedCampaign) {
            const statusValue = campaignConst?.filter((el) => selectedCampaign?.recepient?.filters?.lead_treatments__status__in?.includes(el.value));
            setStatusValue(statusValue);
        }
    }, [selectedCampaign]);
    
    const defaultValues = {
        status: newCampaign?.statusNames || getStatusValue || []
    };

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const {
        setValue,
        control
    } = methods;

    const handleChange = (event, newValue) => {
        const data = {lead_treatments__status__in: newValue?.map((el) => el.value)};
        setValue('status', newValue[newValue?.length - 1]);
        if (Object.values(selectedCampaign).length > 0) {
            const updatedCampaign = { ...selectedCampaign, recepient: { ...selectedCampaign.recepient, filters: {...selectedCampaign.recepient?.filters, ...data} } };
            dispatch(updateSelectedCampaign(updatedCampaign));
            return;
        }
        dispatch(saveNewCampaign({ lead_treatments__status__in: data, statusNames: newValue }));
    };

    return (
        <Box sx={tagsBoxStyle}>
            <Controller
                name="status"
                control={control}
                render={() => (
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={campaignConst}
                            value={newCampaign.statusNames || getStatusValue || []}
                            getOptionLabel={(option) => option.title}
                            onChange={(event, newValue) => handleChange(event, newValue)}
                            sx={allTagChipStyle}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label=""
                                    placeholder="Status"
                                />
                            )}
                        />
                    </Box>
                )}
            />
        </Box>
    );
}