export const boxStyle = (type) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: '10px',
    mb: '30px',
    flexWrap: 'wrap',
    pl: type !== 'Treatment Summary' && '24px',
    pr: type !== 'Treatment Summary' && '24px'
});

export const boxStyleLeadDateRangePicker = (type) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    pr: type !== 'Treatment Summary' && '24px'
});


export const timeRageBoxStyle = {
    '@media(max-width: 900px)': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        mt: '20px'
    }
};

export const referralSummaryBoxStyle = {
    mt: '30px', 
    boxShadow: 'none', 
    borderRadius: '0', 
    '.MuiTableCell-head:first-of-type': { 
        borderTopLeftRadius: '0 !important' 
    }, 
    '.MuiTableCell-head:last-of-type': { 
        borderTopRightRadius: '0 !important' 
    }
};