import { Dialog, Stack, Button, Typography } from '@mui/material'; 
import PropTypes from 'prop-types';
 
const DeleteConfirmationDialog = ({ successDeleteDone, openDelete, onDeleteClose }) => (
    <Dialog fullWidth maxWidth="xs" open={openDelete} onClose={onDeleteClose}>
        <Stack spacing={2} sx={{ p: 2.5, textAlign: 'center' }}>
            <Typography variant="h6">
                {'Are you sure, you want to delete this label?'}
            </Typography>
            <Stack width="160px" marginTop={0} justifyContent="space-between" flexDirection="row" alignItems="flex-start" alignSelf="center">
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => onDeleteClose()}
                >
                    {'cancel'}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => successDeleteDone()}
                    color='error'
                >
                    {'Delete'}
                </Button>
            </Stack>
        </Stack>
    </Dialog>
);

export default DeleteConfirmationDialog;

DeleteConfirmationDialog.propTypes = {
    openDelete: PropTypes.bool,
    onDeleteClose: () => PropTypes.void,
    successDeleteDone: () => PropTypes.void,
};
