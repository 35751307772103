import { grey } from 'src/theme/palette';

export const backdropStyle = {
    zIndex: 1118,
    opacity: '1 !important',
    visibility: 'visible !important',
    background: 'rgba(0, 0, 0, 0.3)'
};

export const rootStyle = (fullScreen, screen) => ({
    ...{
        zIndex: (screen === 'failedToAttend' && 9999) || (screen === 'consent' && 1200) || 1200,
        width: { lg: '100%', xs: 'calc(100% - 30px)' },
        maxWidth: { lg: fullScreen ? 'calc(100vw - 16px)' : '1151px', sm: '1151px' },
        height: { sm: 'auto', xs: 'fit-content' },
        minHeight: { lg: fullScreen ? 'calc(100vh - 16px)' : 'auto', sm: 'auto', xs: 'auto' },
        bottom: 'auto',
        right: { lg: '0', md: '0', sm: 0 },
        top: '50%',
        left: { md: '0', xs: '0' },
        margin: { lg: 'auto', xs: 'auto' },
        boxShadow: '0 3px 40px -4px rgb(145 158 171 / 36%)',
        transform: 'translateY(-50%)',
        maxHeight: 'calc(100vh - 16px)',
        overflow: 'auto',
        'form': {
            display: fullScreen ? 'flex' : '',
            flexDirection: fullScreen ? 'column' : '',
            height: fullScreen ? '100%' : ''
        }
    },
});

export const imgLoaderBoxStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#00000017'
};

export const formMainBoxStyle = (messageType, screen) => ({
    pl: { sm: 3, xs: 1 },
    pr: 1,
    height: { sm: (messageType === 'Template' || screen === 'consent') ? 65 : 60, xs: (messageType === 'Template' || screen === 'consent') ? 'auto' : 60 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

export const allBoxTypeStyle = (messageType, screen) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
        sm: (messageType === 'Template' || screen === 'consent') ? 'space-between' : 'center',
        xs: (messageType === 'Template' || screen === 'consent') ? 'center' : 'start'
    },
    width: {
        md: (messageType === 'Template' || screen === 'consent') ? '80%' : '100%',
        xs: '100%'
    },
    margin: {
        sm: '0',
        xs: '20px 0'
    },
    flexDirection: {
        sm: 'row',
        xs: (messageType === 'Template' || screen === 'consent') ? 'column' : 'row'
    }
});

export const formControlStyle = {
    margin: '0 0px',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: {
        sm: '0',
        xs: '8px'
    },
    ' .MuiInputLabel-outlined': {
        top: '-10px !important',
        left: '10px',
        maxWidth: 'inherit',
        transform: 'translate(14px, 16px) scale(1)',
        color: '#637381'
    },
    ' .MuiFormLabel-filled.MuiInputLabel-outlined': {
        top: '0 !important',
        display: 'none'
    },
    ' .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important'
    },
    ' .MuiSelect-select': {
        paddingLeft: '0'
    }
};

export const multipleChipStyle = {
    width: '160px',
    height: '36px',
    margin: {
        sm: 'inherit',
        xs: '0 2px'
    }
};

export const textFieldStyle = {
    'input::placeholder': {
        fontWeight: 600,
    },
    'input': {
        fontWeight: 600,
        textAlign: 'center',
        height: '45px'
    },
    width:{
        sm: '50%',
        xs: '100%'
    }
};

export const boxStyle = (useOrSend, screen) => ({
    display: 'flex',
    alignItems: 'center',
    width: useOrSend === 'send' || useOrSend === 'use' ? '100%' : 'auto',
    justifyContent: 'space-between',
    marginLeft:(useOrSend === 'send' || useOrSend === 'use') && screen === 'consent' && '10px'
});

export const iconBtnStyle = {
    display: {
        md: 'block',
        xs: 'none',
        height: '36px',
        lineHeight: '0'
    }
};

export const stackStyle = {
    paddingRight: '24px',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24) !important',
    border: {
        sm:'none',
        xs: '1px solid rgba(145, 158, 171, 0.24)'
    },
    'input': {
        pr: {
            sm: '0',
            xs: '0'
        },
        textAlign: 'center'
    },
    'input::placeholder': {
        // color: '#212B36',
        fontSize: '20px',
        fontWeight: '700'
    },
    ' .MuiInput-root:after': {
        display: 'none'
    },
    ' .MuiInput-root:before': {
        display: 'none'
    }
};

export const inputStyle = {
    fontWeight: 600,
    width: '80%',
    margin: 'auto',
    border: 'none'
};

export const formControlLabelStyle = {
    position: {
        sm: 'absolute',
        xs: 'relative'
    },
    right: 0,
    minWidth: '140px',
    justifyContent: 'center'
};

export const editorBoxStyle = {
    py: 2,
    px: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: { md: 'flex-start', xs: 'center' },
    flexWrap: { md: 'nowrap', xs: 'wrap' },
    gap: { md: 0, xs: 1 },
    overflow: 'hidden'
};

export const editorBoxBtnStyle = {
    width: '130',
    height: '36px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    borderRadius: '8px',
    marginRight: '15px',
    color: 'grey.700'
};

export const sendBoxStyle = (fullScreen) => ({
    display: 'flex',
    gap: 0,
    maxWidth: { md: '400px', xs: '100%' },
    justifyContent: { md: 'start', sm: 'center', xs: 'start' },
    flexWrap: 'nowrap',
    overflowX: 'auto',
    width: fullScreen ? '63%' : '100%',
    marginBottom: { sm: '0', xs: '8px' },
    '@media(max-width: 767px)': {
        width: '100%'
    }
});

export const sendBoxChipStyle = {
    margin: '0 2px',
    // backgroundColor: 'rgba(24, 144, 255, 0.16)',
    // color: '#0C53B7'
};

export const sendBoxFormControlStyle = (type) => ({
    margin: type === 'sms' ? '0 10px 0 -8px !important' :  '0 -8px',
    marginBottom: {
        sm: '0',
        xs: '8px'
    },
    ' .MuiInputLabel-outlined': {
        top: '-10px',
        left: '17px'
    },
    ' .Mui-focused.MuiInputLabel-outlined': {
        top: 0
    }
});

export const sendBoxSecondChipStyle = {
    bgcolor: 'rgba(24, 144, 255, 0.16)',
    color: '#0C53B7',
    ':hover': {
        bgcolor: 'rgba(24, 144, 255, 0.16)'
    }
};

export const formMultiChip = {
    margin: '0 10px 0 0 !important',
    marginBottom: {
        sm: '0',
        xs: '8px'
    },
    ' .MuiInputLabel-outlined': {
        top: '-10px',
        left: '0'
    },
    ' .Mui-focused.MuiInputLabel-outlined': {
        top: 0
    }
};

export const selectStyle = {
    width: '130px',
    height: '36px',
    margin: {
        sm: 'inherit',
        xs: '0 2px'
    }
};

export const menuItemStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};

export const selectMenuItemStyle = {
    mx: 0,
    my: 0,
    typography: 'body2',
    textTransform: 'capitalize',
};

export const typographyStyle = {
    maxWidth: {
        sm: '100%',
        xs: '200px'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const commaSpanStyle = {
    fontSize: '12px',
    position: 'relative',
    left: '5px',
    top: '0'
};

export const RHFTextFieldStyle = {
    maxWidth: '180px',
    margin: {
        sm: '0 16px',
        xs: '0 2px'
    },
    ' .MuiInputBase-root': {
        height: '36px'
    },
    marginBottom: { 
        sm: '0',
        xs: '8px'
    }
};

export const saveBtnStyle = {
    px: 3,
    marginBottom: {
        sm: '0',
        xs: '8px'
    }
};

// sms
export const editorStyle = {
    borderColor: 'transparent',
    flexGrow: 1,
    height: '100%',
};


// whatsapp
export const inputSetStyle = {
    borderRadius: '0',
    minHeight: '220px',
    maxHeight: '220px',
    width: '100%',
    height: '100%'
};

export const selectOuterBox = {
    py: 2,
    px: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: {
        md: 'end',
        xs: 'center'
    },
    flexWrap: {
        md: 'nowrap',
        xs: 'wrap'
    },
    gap: {
        md: 0,
        xs: 1
    }
};

export const selectInnerBox = (fullScreen) => ({
    display: 'flex',
    gap: .5,
    maxWidth: {
        md: fullScreen ? '70%' : '400px',
        xs: '100%' },
    justifyContent: {
        md: 'start',
        xs: 'center'
    },
    width: fullScreen ? '63%' : '100%',
    flexWrap: {
        md: 'nowrap',
        xs: 'wrap'
    },
    overflowX: 'auto'
});

export const whatsappSelectStyle = {
    width: '160px',
    margin: '0 16px',
    height: '36px'
};


// Editor
export const clearButton = {
    color: 'rgb(255, 72, 66)',
    bgcolor: 'rgba(255, 72, 66, 0.08)',
    minWidth: '24px',
    borderRadius: '100px',
    minHeight: '24px',
    p: '0',
    ml: '8px',
};

export const EditorRootStyle = (error, sx, type, fullScreen) => ({
    ...(error && {
        border: (theme) => `solid 1px ${theme.palette.error.main}`,
    }),
    ...sx,
    ' #compose-mail': {
        display: type === 'sms_editor' ? 'none' : ''
    },
    '& .quill': {
        height: 'auto',
    },
    '& .ql-editor': {
        // eslint-disable-next-line no-nested-ternary
        minHeight: fullScreen ? 'calc(70vh - 70px)' : 330,
        maxHeight: { sm: fullScreen ? 'calc(70vh - 70px)' : 330, xs: 230 },
        '-webkit-overflow-scrolling': 'touch',
    },
    '.ql-tooltip.ql-editing': {
        left: '0 !important',
        top: '0 !important'
    },
    ' .ql-toolbar': {
        fontSize: '15px'
    },
    height: { lg: fullScreen ? '100%' : 'auto' }
});
export const editorHeaderStyle = { display: 'flex', p: '16px 18px', alignItems: 'center', justifyContent: 'space-between' };

export const subjectTypoTextStyle = {
    mr: '12px',
    p: '5px 12px',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'common.borderColor',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700
};

export const subjectInputBoxStyle = {
    borderBottom: '1px solid',
    p: '8px 0 0',
    borderColor: 'primary.paperColor',
    width: '100%'
};

export const subjectInputStyle = {
    border: 'none !important',
    p: 0,
    width: '100%',
    '.MuiInputBase-input': {
        textAlign: 'left'
    }
};

export const attachmentWrapper = { bgcolor: grey[200], borderRadius: 1, display: 'flex', flexWrap: 'wrap' };