import { forwardRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Dialog, Box, Button, Container, Slide, Typography, AppBar, Stack, Grid } from '@mui/material';
// 
import { useSettingsContext } from 'src/components/settings/context';
import {
    EditPersonalDetails
} from '..';
// style
import {
    commonDialogHeaderStyle,
    onBoardDialogHeader,
    dialogBoxStyle,
    closeButtonBoxStyle,
    onBoardDialogFooter,
    leadFooterButtonsStyle,
    btnStyle
} from '../../styles/EditDialogPopup';

// -------------------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EditPersonalDetailsPopup({ open, inputRef, handleClose, title, headerButtons, selectedRow }) {
    const { themeStretch } = useSettingsContext();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={dialogBoxStyle('followup')}
        >
            <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader}>
                <Typography
                    sx={{ display: 'block', color: '#fff' }}
                    variant="h5"
                    component="div"
                >
                    {title}
                </Typography>
                {headerButtons &&
                    <Box sx={closeButtonBoxStyle}>
                        <Button
                            color="inherit"
                            variant='outlined'
                            style={{ borderColor: '#FFFFFF', color: '#fff' }}
                            onClick={() => { handleClose('close'); }}
                        >
                            Close
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={{ p: 2.5, pb: 1 }}>
                <Grid container spacing={1} sx={{ width: '100%', ml: 'auto' }}>
                    <Container maxWidth={themeStretch ? false : 'xl'}>
                        <Grid item xs={12} md={12} sx={{ my: 5, pb: 13 }}>
                            <EditPersonalDetails ref={inputRef} selectedRow={selectedRow} handleNext={handleClose} type={'EditPersonalDetailsPopup'} />
                        </Grid>
                        <Box sx={onBoardDialogFooter}>
                            <Container
                                maxWidth={themeStretch ? false : 'xl'}
                                sx={leadFooterButtonsStyle}
                            >
                                <Button
                                    color="primary"
                                    variant='contained'
                                    onClick={handleNext}
                                    sx={btnStyle('followup')}
                                >
                                    Save
                                </Button>
                            </Container>
                        </Box>
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
}

EditPersonalDetailsPopup.propTypes = {
    open: PropTypes.bool,
    inputRef: PropTypes.any,
    handleClose: PropTypes.any,
    title: PropTypes.any,
    headerButtons: PropTypes.any,
    selectedRow: PropTypes.any,
};
