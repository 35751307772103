import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomCheckboxComponent = ({ data, methods }) => {
    const renderCheckboxes = (field, error) => (
        <FormGroup>
            {(data?.field_type?.choices || data?.choices)?.map((el, index) => (
                <FormControlLabel
                    key={index}
                    control={
                        <Checkbox
                            name={`typeCheckbox_${data.id}_${index}`}
                            checked={field.value.includes(el)}
                            onChange={(e) => {
                                const newValue = e.target.checked
                                    ? [...field.value, el]
                                    : field.value.filter((value) => value !== el);
                                field.onChange(newValue);
                            }}
                            value={el}
                        />
                    }
                    label={el}
                    sx={{ width: '100%', display: 'flex', '.MuiTypography-root': { width: '100%' } }}
                />
            ))}
            {!!error && (
                <FormHelperText error={!!error}>
                    {error?.message || ''}
                </FormHelperText>
            )}
        </FormGroup>
    );

    const renderRegisteredPatientCheckbox = (field) => (
        <FormGroup sx={{ gap: 2 }}>
            <FormControlLabel
                key={1}
                control={
                    <Checkbox
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                    />
                }
                label={'Is registered patient'}
                sx={{ width: 'fit-content' }}
            />
        </FormGroup>
    );

    return (
        <>
            {data?.field_type?.attribute !== 'lead_is_registered_patient' && (
                <Controller
                    render={({ field, fieldState: { error } }) => renderCheckboxes(field, error)}
                    control={methods.control}
                    name={`typeCheckbox_${data.id}`}
                    defaultValue={[]}
                />
            )}
            {data?.field_type?.attribute === 'lead_is_registered_patient' && (
                <Controller
                    control={methods.control}
                    name={'isRegister'}
                    render={({ field }) => renderRegisteredPatientCheckbox(field)}
                />
            )}
        </>
    );
};

export default CustomCheckboxComponent;

CustomCheckboxComponent.propTypes = {
    data: PropTypes.object,
    methods: PropTypes.object,
};
