import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { nameTypoStyle } from '../../styles/CallScripts';

const CustomLabelComponent = ({ data }) => (
    <Typography sx={nameTypoStyle(data)}>
        {data?.field_type?.field_type === 'label' ? '' : data?.field_type?.name}
    </Typography>
);

export default CustomLabelComponent;

CustomLabelComponent.propTypes = {
    data: PropTypes.object,
};
