import React from 'react';
import { Box, Button, ButtonGroup, Badge, MenuItem, useTheme } from '@mui/material';
import { Iconify } from '../../../../../components';
import { DataGridTableMoreMenu } from '../../../../../components/table';
import CustomViewDetailComponent from '../CustomViewDetailComponent';
import CustomArchivingButton from '../CustomArchivingButton';
import CustomFollowUpBtn from '../CustomFollowUpBtn';
import CustomCompleteTreatmentBtn from '../CustomCompleteTreatmentBtn';
import CustomFollowUpBtnV2 from '../CustomFollowUpBtnV2';
import RecordAttendanceBtnPopper from '../RecordAttendanceBtnPopper';
import { CompleteTreatmentMenuItem, StartTreatmentMenuItem } from '../tableMenuItems';
import { actionColumnData } from '../../../../../constants/dataGridConstants';
import { grey } from '../../../../../theme/palette';
import {
    badgeStyle,
    recordAttendedButtonStyle,
    tableMoreMenuBoxStyle,
    arrowButtonStyle,
} from '../../../../../styles/NewLeadTableRowStyle';

const AttendedTableColumnActions = ({
    openMenu,
    menuCurrentTarget,
    handleOpenMenu,
    setOpenMenuActions,
    typed,
    handleViewDetailClick,
    isReportData,
    anchorMoreMenuRef,
    setOpen,
    open,
    handleAttendedClick,
    handleAttendedFailedClick,
    buttonHandleClose,
    handleAttendedPopup,
    viewDetailBtn,
    handlePopup,
    handleStartTreatment,
    handleCompleteTreatment,
    editAppointmentMenuItem,
    markUnMarkDuplicateMenuItem,
    backToAttendedMenuItem,
    archiveLeadMenuItem,
    restoreLeadMenuItem,
}) => {
    const theme = useTheme();

    const getMoreMenu = (params) => {
        if (params.row.is_archive_pending) {
            return <CustomArchivingButton params={params} />;
        }
        if (params.row.status === 'CONSULTATION_BOOKED' && !params.row.is_archive_pending) {
            return (
                <Box>
                    <Box>
                        <ButtonGroup
                            variant='contained'
                            ref={anchorMoreMenuRef}
                            aria-label='split button'
                            sx={{ boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)', minWidth: '185px' }}
                        >
                            <Button onClick={() => setOpen((prevOpen) => !prevOpen)} sx={recordAttendedButtonStyle}>
                                Record Attendance
                            </Button>
                            <Button
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label='select merge strategy'
                                aria-haspopup='menu'
                                onClick={() => setOpen((prevOpen) => !prevOpen)}
                                sx={arrowButtonStyle(theme)}
                            >
                                <Badge
                                    badgeContent={[params.row?.lead_treatments__lead_contacted_counts__count]}
                                    color='primary'
                                    sx={badgeStyle(params.row.status, params.row?.lead_treatments__lead_contacted_counts__count)}
                                >
                                    <Iconify style={{ color: grey[0] }} width={10} height={10} icon={'bxs:right-arrow'} />
                                </Badge>
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <RecordAttendanceBtnPopper
                        params={params}
                        anchorEl={anchorMoreMenuRef.current}
                        open={open}
                        handleAttendedClick={handleAttendedClick}
                        handleAttendedFailedClick={handleAttendedFailedClick}
                        buttonHandleClose={buttonHandleClose}
                    />
                </Box>
            );
        }
        if (params.row.status === 'CONSULTATION_ATTENDED' && !params.row.is_archive_pending) {
            return <CustomFollowUpBtn params={params} handleAttendedPopup={handleAttendedPopup} />;
        }
        if (
            (params.row.status === 'CLOSED' && !params.row.is_archive_pending) ||
            (params.row.status === 'TREATMENT_STARTED' && !params.row.is_archive_pending)
        ) {
            return <CustomCompleteTreatmentBtn params={params} viewDetailBtn={viewDetailBtn} />;
        }
        if (params.row.treatment_name === '' || params.row.treatment_name === null) return '';
        return <CustomFollowUpBtnV2 params={params} handlePopup={handlePopup} />;
    };

    return {
        ...actionColumnData,
        getActions: (params) => [
            <div className='action-custom-cell' key={params.row.id}>
                <DataGridTableMoreMenu
                    open={openMenu === params.row.id}
                    sx={{ width: '180px' }}
                    anchorEl={menuCurrentTarget}
                    type={params.row.status === 'CONSULTATION_BOOKED' ? 'FinancialTableRow' : ''}
                    onOpen={(event) => handleOpenMenu(event, params)}
                    onClose={() => setOpenMenuActions(null)}
                    actions={
                        <>
                            {typed !== 'ArchiveTable' ? (
                                <Box sx={tableMoreMenuBoxStyle}>
                                    <CustomViewDetailComponent handleClick={() => handleViewDetailClick(params)} />
                                    {params.row.status !== 'CLOSED' && typed !== 'ArchiveTable' && isReportData && (
                                        <MenuItem onClick={() => setOpenMenuActions(null)}>{getMoreMenu(params)}</MenuItem>
                                    )}
                                    <StartTreatmentMenuItem handleStartTreatment={handleStartTreatment} params={params} />
                                    <CompleteTreatmentMenuItem handleCompleteTreatment={handleCompleteTreatment} params={params} />
                                    {editAppointmentMenuItem(params)}
                                    {markUnMarkDuplicateMenuItem(params)}
                                    {backToAttendedMenuItem(params)}
                                    {archiveLeadMenuItem(params)}
                                </Box>
                            ) : (
                                restoreLeadMenuItem(params)
                            )}
                        </>
                    }
                />
            </div>,
        ],
    };
};

export default AttendedTableColumnActions;
