import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

export default function RHFAutocomplete({ clearInput, name, label, helperText, placeholder, ...other }) {
    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    {...field}
                    onChange={(event, newValue) => { if (clearInput) { clearInput(); }; setValue(name, newValue, { shouldValidate: true }); }}
                    renderInput={(params) => (
                        <TextField
                            label={label}
                            error={!!error}
                            helperText={error ? error?.message : helperText}
                            placeholder={placeholder}
                            {...params}
                            sx={label === "Assignees" && {ml:"16px"}}
                        />
                    )}
                    {...other}
                />
            )}
        />
    );
}

RHFAutocomplete.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.node,
    placeholder: PropTypes.string,
    clearInput: PropTypes.func,
};
