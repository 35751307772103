import { createSlice } from '@reduxjs/toolkit';
//
import { isEmpty } from 'lodash';
import { dispatch, store } from '../store';
// slice
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';

// -----------------------------------------------

const initialState = {
    isAudienceLoading: false,
    audienceListing: {},
    selectedRowData: null,
    isEditAudience: false,
    selectedAudience: null,
    importFiles: '',
    fileName: '',
    newAudience: {},
    selectedAudienceData: {},
    isAudienceIncludeArchive: false,
    mappingValues: {},
    headers: null,
    audienceCounter: {},
    audienceConditionListing:[],
    audienceData: null,
    originalDataFile: '',
};

const slice = createSlice({
    name: 'audienceManager',
    initialState,
    reducers: {
        audienceManagerReset(state) {
            Object.assign(state, initialState);
        },
        setAudienceLoading(state, action) {
            state.isAudienceLoading = action.payload;
        },
        setAudienceListing(state, action) {
            state.audienceListing = action.payload;
        },
        setSelectedRowData(state, action) {
            state.selectedRowData = action.payload;
        },
        setIsEditAudience(state, action) {
            state.isEditAudience = action.payload;
        },
        selectedAudienceData(state, action) {
            state.selectedAudience = action.payload;
        },
        setImportFiles(state, action) {
            state.importFiles = action.payload;
        },
        saveNewAudience(state, action) {
            state.newAudience = {
                ...state.newAudience,
                ...action.payload
            };
        },
        clearNewAudience(state) {
            state.newAudience = {};
        },
        updateSelectedAudience(state, action) {
            state.selectedAudienceData = action.payload;
        },
        setAudienceIncludeArchive
        (state, action) {
            state.isAudienceIncludeArchive = action.payload;
        },
        setMappingValues
        (state, action) {
            state.mappingValues = action.payload;
        },
        setFileName
        (state, action) {
            state.fileName = action.payload;
        },
        setHeaders
        (state, action) {
            state.headers = action.payload;
        },
        setAudienceCounter(state, action) {
            state.audienceCounter = action.payload;
        },
        setAudienceConditionListing(state, action) {
            state.audienceConditionListing = action.payload;
        },
        setAudienceData(state, action) {
            state.audienceData = action.payload;
        },
        setOriginalDataFile(state, action) {
            state.originalDataFile = action.payload;
        }
    },
});

export default slice.reducer;

export const {
    audienceManagerReset,
    setAudienceLoading,
    setAudienceListing,
    setSelectedRowData,
    setIsEditAudience,
    selectedAudienceData,
    setImportFiles,
    saveNewAudience,
    updateSelectedAudience,
    setAudienceIncludeArchive,
    setMappingValues,
    setFileName,
    setHeaders,
    clearNewAudience,
    setAudienceCounter,
    setAudienceConditionListing,
    setAudienceData,
    setOriginalDataFile
} = slice.actions;

export const getPracticeId = () => store.getState()?.practiceTreatmentList?.practiceId;

export function getAudienceList(data, handleClose) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '&limit=10';
            const offset = data?.offset === 0 || data?.offset ? `&offset=${data?.offset}` : '';
            const searchQuery = data?.search ? `&search=${data?.search}` : '';
            const param = `${limit}${offset}${searchQuery}`;
            const response = await callApi(
                `/api/v1/practices/${getPracticeId()}/audiences/?ordering=-created_at${param}&fields={recepient_count, audience_csv,audience_internal_queryset,description,id,name,type}`
            );
            if (response.status === 200) {
                dispatch(slice.actions.setAudienceListing(response.data));
                if (handleClose) {
                    handleClose(response.data.count);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(setAudienceLoading(false));
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function getIndividualAudience(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(
                `/api/v1/practices/${getPracticeId()}/audiences/${data.audienceId}/?&fields={recepient_count,audience_csv,audience_internal_queryset,description,id,name,type}`
            );
            if (response.status === 200) {
                dispatch(slice.actions.setFileName(response.data.audience_csv?.csv));
                dispatch(slice.actions.setImportFiles(response.data.audience_csv?.csv));
                dispatch(slice.actions.updateSelectedAudience({ ...response.data, recepient: response.data?.audience_internal_queryset }));
                dispatch(slice.actions.setAudienceIncludeArchive(response.data?.audience_internal_queryset?.is_archived_included));
                dispatch(slice.actions.setSelectedRowData(response.data));
                dispatch(setIsEditAudience(true));
                dispatch(setMappingValues({ ...response.data.audience_csv?.mapping }));
                if (handleClose) {
                    handleClose(response.data.count);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(setAudienceLoading(false));
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function handleCreateAudience(data, handleClose, handleNavigate) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/audiences/`, 'post', data?.payload);
            if (response.status === 201) {
                if (!isEmpty(data?.payload?.audience_csv)) {
                    dispatch(validateAudience({ audienceId: response?.data?.id }, handleNavigate));
                } else {
                    if (data.type !== 'AudiencePopup') {
                        const payload = {
                            limit: 10,
                            offset: 0,
                        };
                        dispatch(getAudienceList(payload));
                    }
                    if(handleNavigate) {
                        handleNavigate();
                    }
                }
                if (handleClose) {
                    handleClose(data?.payload);
                }
                if (data?.shouldShowAudienceSuccessMsg) {
                    dispatch(updateError({ success: true, message: 'Audience created successfully' }));
                }
                if (data?.shouldShowMessage) {
                    dispatch(updateError({ success: true, message: 'ClinCheck status updated successfully' }));
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function handleUpdateAudience(data, handleClose, handleNavigate) {
    return async () => {
        try {
            const response = await callApi(
                `/api/v1/practices/${getPracticeId()}/audiences/${data?.audienceId}/`,
                'put',
                data?.payload
            );
            if (response.status === 200) {
                if (!isEmpty(data?.payload?.audience_csv)) {
                    dispatch(validateAudience({ audienceId: response?.data?.id }, handleNavigate));
                } else {
                    if (data.type !== 'AudiencePopup') {
                        const payload = {
                            limit: 10,
                            offset: 0,
                        };
                        dispatch(getAudienceList(payload));
                    }
                    handleNavigate();
                }
                if (handleClose) {
                    handleClose();
                }
                dispatch(updateError({ success: true, message: 'Audience updated successfully' }));
                if (data?.shouldShowMessage) {
                    dispatch(updateError({ success: true, message: 'ClinCheck status updated successfully' }));
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function deleteAudience(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/audiences/${data.id}/`, 'delete');
            if (response.status === 204) {
                handleClose(data);
                dispatch(updateError({ success: true, message: 'Audience deleted successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function validateAudience(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/audiences/${data.audienceId}/validate/`, 'post');
            if (response.status === 200) {
                if (handleClose) {
                    handleClose();
                }
                dispatch(updateError({ success: true, message: 'Audience validation scheduled and will be validated in some time, so please refresh the page after few minutes to view latest status.' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
