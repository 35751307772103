/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Dialog, DialogTitle, DialogContent, Divider, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import Iconify from '../Iconify';
import { actionIconStyle } from '../../styles/CampaignPreviewModalStyle';

const TemplatePreviewPopup = ({
    campaignPreview,
    handleClosePreviewTemplate,
    previewData,
    handlePreviewSize,
    previewSize,
}) => {
    const getSize = () => {
        if (previewSize === 'tablet') return '768px';
        if (previewSize === 'mobile') return '364px';
        return '100%';
    };

    const getSizeStyle = (device) => {
        if (device === previewSize) return { border: '1px solid red', borderRadius: '50%' };
        return {};
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'lg'}
                open={campaignPreview}
                onClose={handleClosePreviewTemplate}
                sx={{ zIndex: 1300 }}
            >
                <DialogTitle sx={{ padding: '24px 16px 24px 24px' }}>
                    {!isEmpty(previewData) && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ ...actionIconStyle, ...getSizeStyle('desktop') }}>
                                    <Tooltip title="Desktop">
                                        <Iconify
                                            sx={{ cursor: 'pointer', color: previewSize === 'desktop' ? 'red' : '#637381' }}
                                            icon={'material-symbols-light:desktop-mac-outline'}
                                            width={20}
                                            height={20}
                                            onClick={() => handlePreviewSize('desktop')}
                                        />
                                    </Tooltip>
                                </Box>
                                <Box sx={{ ...actionIconStyle, ...getSizeStyle('tablet') }}>
                                    <Tooltip title="Tablet">
                                        <Iconify
                                            sx={{ cursor: 'pointer', color: previewSize === 'tablet' ? 'red' : '#33363F' }}
                                            icon={'lucide:tablet'}
                                            width={20}
                                            height={20}
                                            onClick={() => handlePreviewSize('tablet')}
                                        />
                                    </Tooltip>
                                </Box>
                                <Box sx={{ ...actionIconStyle, ...getSizeStyle('mobile') }}>
                                    <Tooltip title="Mobile">
                                        <Iconify
                                            sx={{ cursor: 'pointer', color: previewSize === 'mobile' ? 'red' : '#33363F' }}
                                            icon={'heroicons-outline:device-mobile'}
                                            width={20}
                                            height={20}
                                            onClick={() => handlePreviewSize('mobile')}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={actionIconStyle}>
                                    <Iconify
                                        sx={{ cursor: 'pointer', color: '#33363F' }}
                                        icon={'ci:close-big'}
                                        width={20}
                                        height={20}
                                        onClick={handleClosePreviewTemplate}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}
                </DialogTitle>
                {!isEmpty(previewData) && (
                    <DialogContent sx={{ padding: '24px 0', borderTop: '1px solid rgba(145, 158, 171, 0.2)' }}>
                        <Divider />
                        <Typography variant="h6" noWrap sx={{ fontSize: '18px !important', textAlign: 'center', p: '12px 24px' }}>
                            Re: {previewData?.subject}
                        </Typography>
                        <Divider />
                        {
                            <Box sx={{ p: '24px 24px', '.ql-container': { border: 'none', fontFamily: 'Public Sans,sans-serif' } }}>
                                <Box
                                    sx={{
                                        '.ql-editor img': { maxWidth: '550px !important' },
                                        '.ql-editor': { p: 0 },
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <iframe
                                        title="template_preview"
                                        id="serviceFrameSend"
                                        srcDoc={previewData?.use_unlayer ? previewData?.unlayer_html : previewData?.body}
                                        width={getSize()}
                                        style={{ height: 'calc(100vh - 276px)', border: '1px solid #5e636e' }}
                                    />
                                </Box>
                            </Box>
                        }
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
};

export default TemplatePreviewPopup;

TemplatePreviewPopup.propTypes = {
    campaignPreview: PropTypes.bool,
    handleClosePreviewTemplate: PropTypes.func,
    previewData: PropTypes.any,
    handlePreviewSize: PropTypes.func,
    previewSize: PropTypes.string,
};
