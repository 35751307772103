import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Tooltip, Box, IconButton} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
//
import { varHover } from '../../animate';
import SvgColor from '../../SvgIconStyle';
//
import BadgeDot from './BadgeDot';

// ----------------------------------------------------------------------

export default function ToggleButton({ notDefault, open, onToggle }) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                p: 0.5,
                right: 24,
                bottom: 24,
                zIndex: 99999,
                position: 'fixed',
                borderRadius: '50%',
                boxShadow: `-12px 12px 32px -4px ${alpha(
                    theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
                    0.36
                )}`,
                ...bgBlur({ color: theme.palette.background.default }),
            }}
        >
            {notDefault && !open && (
                <BadgeDot
                    sx={{
                        top: 8,
                        right: 10,
                    }}
                />
            )}

            <Tooltip title="Settings">
                <IconButton
                    component={m.button}
                    whileTap="tap"
                    whileHover="hover"
                    variants={varHover(1.05)}
                    color="primary"
                    onClick={onToggle}
                    sx={{ p: 1.25 }}
                >
                    <SvgColor src="/assets/icons/setting/ic_setting.svg" />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

ToggleButton.propTypes = {
    open: PropTypes.bool,
    onToggle: PropTypes.func,
    notDefault: PropTypes.bool,
};