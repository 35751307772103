import { Typography, ListItemButton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

export const AvatarWrapperStyle = styled('div')({
    position: 'relative',
    width: 40,
    height: 40,
    '& .MuiAvatar-img': { borderRadius: '50%' },
    '& .MuiAvatar-root': { width: '100%', height: '100%' },
});

export const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(5),
}));

export const InfoStyle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    display: 'inline-block',
    maxWidth: 320,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
}));

export const chatWindowHeaderBoxStyle = {
    p: '12.6px 8px 12.6px 20px',
    borderBottom: '1px solid rgba(145, 158, 171, 0.2)',
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    height: 'auto',
};

export const chatWindowBoxStyle = {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 56px)',
    flexDirection: 'column',
    pb: '46px'
};

export const chatWindowFooterBoxStyle = {
    display: 'flex',
    borderTop: '1px solid rgba(145, 158, 171, 0.2)',
    p: '8px',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: 0,
    bgcolor: '#fff'
};

export const chatMessgeBoxStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
};

export const dateLabelStyle = {
    display: 'flex',
    justifyContent: 'center',
    bgcolor: 'transparent',
    textTransform: 'uppercase',
    color: '#637381'
};

export const RootStyleList = styled(ListItemButton)(({ theme }) => ({
    padding: theme.spacing(1.5, 3),
    transition: theme.transitions.create('all'),
}));

export const menuItemStyle = {
    typography: 'body2',
    borderRadius: 0.75,
    mx: 1,
    my: 0.5,
    textTransform: 'capitalize'
};

export const noPostBoxStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const noPostTextStyle = {
    textAlign: 'center',
    width: '100%',
    color: '#212B36',
    fontSize: '18px',
    fontWeight: '700',
    mb: '8px',
    mt: '10px'
};

export const postBoxStyle = {
    width: '100%',
    mb: 2,
    '.fb_iframe_widget': {
        width: '100%',
        'span': {
            width: '100% !important'
        },
        'iframe': {
            width: '300px !important'
        }
    }
};

export const replyBoxStyle = {
    width: '100%',
    maxWidth: '320px',
    display: 'flex',
    border: 'solid 1px #919EAB',
    borderRadius: 1,
    mt: '4px',
    mb: 1,
    p: '4px 8px'
};

export const messageActionStackStyle = {
    pt: 0.2,
    opacity: 0,
    top: '100%',
    left: 0,
    position: 'relative',
    justifyContent: 'flex-start',
    width: '100%',
    transition: (theme) =>
        theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
        }),
};

export const replyTypoContentStyle = {
    color: 'grey.800',
    bgcolor: 'rgb(240, 246, 250)',
    p: 1,
    mt: 0
};

export const communicationTabStyle = {
    justifyContent: 'flex-start',
    mr: '0 !important',
    p: '8px',
    borderRadius: 1,
    minHeight: '48px',
    '&.Mui-selected': {
        pointerEvents: 'none'
    }
};

export const tabBGStyle = (theme) => ({
    bgcolor: `${alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)} !important`,
    color: `${alpha(theme.palette.primary.main, 1)} !important`
});

export const communicationMainStackStyle = (currentTab) => ({
    flexGrow: 1,
    maxWidth: currentTab ? 'calc(100% - 300px)' : 'calc(100% - 84px)',
    bgcolor: 'transparent',
    height: '670px',
    p: 0,
    borderRadius: '12px',
    mt: currentTab ? '43px' : 0,
    minHeight: 'calc(100vh - 300px)'
});

export const chatBoxMainStyle = (renderComponent) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    p: renderComponent !== '' ? '0' : '16px'
});

export const chatBoxBackStyle = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: '700'
};

export const renderBodyStackStyle = (me, hasImage) => ({
    p: 1.5,
    minWidth: 48,
    maxWidth: 320,
    borderRadius: 1,
    typography: 'body2',
    bgcolor: 'background.neutral',
    ...(me && {
        color: 'grey.800',
        bgcolor: 'primary.lighter',
    }),
    ...(hasImage && {
        p: 0,
        bgcolor: 'transparent',
    }),
});

export const renderBodyBoxStyle = {
    minHeight: 220,
    borderRadius: 1.5,
    cursor: 'pointer',
    '&:hover': {
        opacity: 0.9,
    },
};

export const renderActionsStackStyle = (me) => ({
    pt: 0.5,
    opacity: 0,
    top: '100%',
    left: 0,
    position: 'absolute',
    transition: (theme) =>
        theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
        }),
    ...(me && {
        left: 'unset',
        right: 0,
    }),
});

export const emailAccordionBox = (active, theme) => ({
    border: 'none',
    boxShadow: 'none !important',
    width: '100%',
    borderRadius: '16px !important',
    marginBottom: '0',
    marginTop: '0 !important',
    padding: '0',
    bgcolor: 'transparent !important',
    '&:before': {
        display: 'none'
    },
    '.MuiAccordionSummary-root': {
        p: 1,
        borderRadius: 1,
        color: active ? alpha(theme.palette.primary.main, 1) : '#637381',
        bgcolor: active ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) : 'transparent'
    },
    '.MuiAccordionSummary-content': {
        margin: 0,
    }
});

export const incomingDotsCss = (currentTab, theme) => ({
    width: currentTab === 'Incoming' ? '8px' : '4px',
    height: currentTab === 'Incoming' ? '8px' : '4px',
    background: currentTab === 'Incoming' ? alpha(theme.palette.primary.main, 1) : '#637381',
    borderRadius: '50%',
    display: 'inline-block'
});

export const outgoingDotsCss = (currentTab, theme) => ({
    width: currentTab === 'Outgoing' ? '8px' : '4px',
    height: currentTab === 'Outgoing' ? '8px' : '4px',
    background: currentTab === 'Outgoing' ? alpha(theme.palette.primary.main, 1) : '#637381',
    borderRadius: '50%',
    display: 'inline-block'
});

export const draftsDotsCss = (currentTab, theme) => ({
    width: currentTab === 'Drafts' ? '8px' : '4px',
    height: currentTab === 'Drafts' ? '8px' : '4px',
    background: currentTab === 'Drafts' ? alpha(theme.palette.primary.main, 1) : '#637381',
    borderRadius: '50%',
    display: 'inline-block'
});

export const starredDotsCss = (currentTab, theme) => ({
    width: currentTab === 'Starred' ? '8px' : '4px',
    height: currentTab === 'Starred' ? '8px' : '4px',
    background: currentTab === 'Starred' ? alpha(theme.palette.primary.main, 1) : '#637381',
    borderRadius: '50%',
    display: 'inline-block'
});

export const labelDotsCss = (currentTab, theme) => ({
    width: currentTab === 'Labels' ? '8px' : '4px',
    height: currentTab === 'Labels' ? '8px' : '4px',
    background: currentTab === 'Labels' ? alpha(theme.palette.primary.main, 1) : '#637381',
    borderRadius: '50%',
    display: 'inline-block'
});

export const listItemAvatarStyle = {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const cancelBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
    boxShadow: 'none',
    border: '1px solid rgba(145, 158, 171, 0.32)'
};

export const yesBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
    bgcolor: '#00AB55 !important',
    color: 'common.white'
};

export const blueDotStyle = {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    bgcolor: 'rgb(0, 184, 217)'
};

export const listItemTextStyle = {
    maxWidth: '75%',
    paddingBottom: '22px',
    '.MuiListItemText-secondary': {
        position: 'absolute',
        width: 'calc(100% - 82px)'
    }
};

export const emailBoxStyle = {
    bgcolor: 'common.white', 
    p: '16px 24px', 
    borderRadius: 1, 
    height: '100%' 
};