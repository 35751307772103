import { dispatch, store } from 'src/redux/store';
import {
    endLoading,
    getInboxLabels,
    getThreadLabels,
    getThreadMails,
    hasError,
    inboxEmailCounter,
    setInComingMails,
    setInDraftsMails,
    setLabelMails,
    setOutGoingMails,
    setStarredMails
} from 'src/redux/slices/mail';
import { updateError } from 'src/redux/slices/user';
import { emailCounter } from 'src/redux/slices/emailCounter';
import { getPracticeId } from 'src/redux/slices/clincheck';
import { callApi } from './apiCall';

export const handleThreadMails = ({ leadID }) => {
    const payload = { leadId: leadID, limit: 25, offset: 0 };
    dispatch(getThreadMails({ mailType: 'Incoming', ...payload }));
    dispatch(getThreadMails({ mailType: 'Drafts', ...payload }));
    dispatch(getThreadMails({ mailType: 'Outgoing', ...payload }));
    dispatch(getThreadMails({ mailType: 'Starred', ...payload }));
};

export const getUrl = ({ practiceId, mailType, search, leadId, senderEmails }) => {
    switch (mailType) {
        case 'Incoming':
            return `/api/v1/practices/${practiceId}/inbox/threads/search/?query=${search}&with_replies=${true}&lead__in=${leadId}`;
        case 'Outgoing':
            return `/api/v1/practices/${practiceId}/inbox/threads/search/?query=${search}&thread_messages__sender__in=${senderEmails}&lead__in=${leadId}`;
        case 'Drafts':
            return `/api/v1/practices/${practiceId}/inbox/threads/search/?query=${search}&lead__in=${leadId}&status__in=DRAFT`;
        case 'Starred':
            return `/api/v1/practices/${practiceId}/inbox/threads/search/?query=${search}&lead__in=${leadId}&is_starred=${true}`;
        default:
            return '';
    }
};

export const getThreadMessageUrl = ({ practiceId, mailType, id }) => {
    switch (mailType) {
        case 'Incoming':
        case 'Outgoing':
        case 'Starred':
        case 'Label':
            return `/api/v1/practices/${practiceId}/inbox/threads/${id}/messages/`;
        case 'Drafts':
            return `/api/v1/practices/${practiceId}/transactional-messages/${id}`;
        default:
            return '';
    }
};

export const getParams = ({ mailType }) => {
    const { labelName } = store.getState().mail;
    const { practice } = store.getState().practice;
    switch (mailType) {
        case 'Incoming':
            return `with_replies=${true}`;
        case 'Outgoing':
            return `thread_messages__sender__in=${practice?.mail_senders?.map(item => item?.sender_email)}`;
        case 'Drafts':
            return 'status__in=DRAFT';
        case 'Starred':
            return `is_starred=${true}`;
        case 'Label':
            return `labels__label__name=${labelName}`;
        default:
            return '';
    }
};

export const getThreadMailsUrl = ({ mailType, practiceId, limit, offset }) => {
    switch (mailType) {
        case 'Incoming':
        case 'Outgoing':
        case 'Starred':
        case 'Label':
            return `/api/v1/practices/${practiceId}/inbox/threads/?limit=${limit}&offset=${offset}&ordering=-created_at&`;
        case 'Drafts':
            return `/api/v1/practices/${practiceId}/transactional-messages/?limit=${limit}&offset=${offset}&ordering=-created_at&`;
        default:
            return '';
    }
};

export const setEmailData = ({ mailType, response }) => {
    switch (mailType) {
        case 'Incoming':
            dispatch(setInComingMails(response.data));
            break;
        case 'Outgoing':
            dispatch(setOutGoingMails(response.data));
            break;
        case 'Drafts':
            dispatch(setInDraftsMails(response.data));
            break;
        case 'Starred':
            dispatch(setStarredMails(response.data));
            break;
        case 'Label':
            dispatch(setLabelMails(response.data));
            break;
        default:
            break;
    }
};

export const handleApiError = ({ error }) => {
    dispatch(endLoading());
    dispatch(hasError(error));
};

export const handleStarUnStartMessage = ({ response, practiceId, mailType, leadId, limit, offset, isAdded }) => {
    if (response.status === 200) {
        dispatch(updateError({ success: true, message: isAdded ? 'Star added successfully' : 'Star removed successfully' || 'Something went wrong' }));
        if (mailType === 'Starred') {
            dispatch(getThreadMails({ practiceId, mailType: 'Starred', leadId, limit, offset }));
            return;
        }
        dispatch(getThreadMails({ practiceId, mailType, leadId, limit, offset }));
        dispatch(getThreadMails({ practiceId, mailType: 'Starred', leadId, limit, offset }));
    }
};

export const handleReadUnreadMessage = ({ response, practiceId, leadID, isRead }) => {
    if (response.status === 200) {
        dispatch(updateError({ success: true, message: isRead ? 'Email mark as read successfully' : 'Email mark as unread successfully' }));
        dispatch(inboxEmailCounter(practiceId, leadID));
        dispatch(emailCounter(practiceId));
        dispatch(getThreadMails({ practiceId, mailType: 'Incoming', leadId: leadID, limit: 25, offset: 0 }));
    }
};

export const handleLabelResponse = ({ practiceId, response }) => {
    dispatch(updateError({ success: false, message: JSON.stringify(response?.data?.message) || 'Something went wrong' }));
    dispatch(getInboxLabels(practiceId, 25, 0));
};

export const handleLabelSuccess = ({ practiceId, type }) => {
    dispatch(updateError({ success: true, message: `Label ${type} successfully` || 'Something went wrong' }));
    dispatch(getInboxLabels(practiceId, 25, 0));
};

export function readMessages(practiceId, id, leadID) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/${id}/mark_as_read/`, 'POST',);
            handleReadUnreadMessage({ response, practiceId, leadID, isRead: true });
        } catch (error) {
            handleApiError({ error });
        }
    };
}
export function unReadMessages(practiceId, id, leadID) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/${id}/mark_as_unread/`, 'POST',);
            handleReadUnreadMessage({ response, practiceId, leadID });
        } catch (error) {
            handleApiError({ error });
        }
    };
}
export const updateInboxLabel = ({ labelId, data, successDone }) => async () => {
    try {
        const practiceId = getPracticeId();
        const response = await callApi(`/api/v1/practices/${practiceId}/inbox-labels/${labelId}/`, 'patch', data);
        if (response.status === 200) {
            successDone();
            handleLabelSuccess({ practiceId, type: 'updated' });
        } else { handleLabelResponse({ practiceId, response }); }
    } catch (error) {
        dispatch(hasError(error));
    }
};
export const addInboxLabel = (practiceId, data) => async () => {
    try {
        const response = await callApi(`/api/v1/practices/${practiceId}/inbox-labels/`, 'POST', data);
        if (response.status === 201) {
            handleLabelSuccess({ practiceId, type: 'added' });
        } else { handleLabelResponse({ practiceId, response }); }
    } catch (error) {
        dispatch(hasError(error));
    }
};
export const deleteThreadLabel = (practiceId, threadId, labelId,) => async () => {
    try {
        const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/${threadId}/labels/${labelId}/`, 'DELETE',);
        if (response.status === 204) {
            dispatch(updateError({ success: true, message: 'Label deleted successfully' || 'Something went wrong' }));
            dispatch(getThreadLabels(practiceId, threadId));
        } else {
            dispatch(updateError({ success: false, message: JSON.stringify(response?.data?.message) || 'Something went wrong' }));
        }
    } catch (error) {
        dispatch(hasError(error));
    }
};
export const addThreadLabel = (practiceId, threadId, data) => async () => {
    try {
        const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/${threadId}/labels/`, 'POST', data);
        if (response.status === 201) {
            dispatch(updateError({ success: true, message: 'Label added successfully' || 'Something went wrong' }));
            dispatch(getThreadLabels(practiceId, threadId));
        } else {
            dispatch(updateError({ success: false, message: JSON.stringify(response?.data?.message) || 'Something went wrong' }));
        }
    } catch (error) {
        dispatch(hasError(error));
    }
};
export function unStarMessages({ id, mailType, leadId, limit, offset }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/${id}/unstar/`, 'POST',);
            handleStarUnStartMessage({ response, practiceId, mailType, leadId, limit, offset });
        } catch (error) {
            handleApiError({ error });
        }
    };
}