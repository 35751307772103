export const treatmentSummaryTableStyle = (type) => ({
    position: 'relative', 
    overflow: 'hidden',
    '.MuiTableCell-head:first-of-type': {
        borderTopLeftRadius: type === 'Treatment Summary' && '16px !important'
    },
    '.MuiTableCell-head:last-of-type': {
        borderTopRightRadius: type === 'Treatment Summary' && '16px !important'
    },
    '.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': { 
        border: 'none !important' 
    }
});