import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import getStyles from "src/styles/whatsappStyles";
import { Iconify } from "..";

const MediaDisplayComponent = props => {
    const theme = useTheme();
    const { scrollToBottom, whatsAppMessageMedia: { whatsapp_media_mime_type: mediaType, media_file: fileDataUrl } } = props;
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    const handleLoad = () => {
        scrollToBottom();
        setLoading(false); // Hide loading indicator once image is loaded
    };

    const handleError = () => {
        scrollToBottom();
        setError(true); // Show an error message if image fails to load
        setLoading(false);
    };

    const styles = getStyles(theme);
    if (mediaType.includes("image/"))
        return <Box sx={{ maxWidth: "100%", minWidth: "3rem", display: "flex", cursor: "pointer" }} onClick={() => window.open(fileDataUrl, "_blank")
        }>
            {loading && <Box sx={styles.imageLoadingBox}>
                <CircularProgress
                    size={20}
                /></Box>} {/* Show loading indicator */}
            {error && <Typography rel="noopener noreferrer" component={"a"} href={fileDataUrl} target="_blank" sx={styles.unsupportedMedia}>Unsupported Media (View)</Typography>} {/* Show error message */}
            <img src={fileDataUrl}
                alt={`mediaType ${mediaType}`}
                onLoad={handleLoad} // Triggered when image is fully loaded
                onError={handleError} // Triggered when image fails to load
                style={{
                    display: loading ? "none" : "block",
                    width: "100%", // Adjust the width as needed (e.g., 100% of parent container)
                    height: "auto", // Keep height automatic based on width
                    objectFit: "contain" // Maintain aspect ratio
                }} />
        </Box >

    if (mediaType.includes("pdf")) {
        return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Iconify icon="bxs:file-pdf" sx={styles.mediaIcon} />
            <Typography component={"a"} rel="noopener noreferrer" href={fileDataUrl} target="_blank" sx={styles.unsupportedMedia}>View PDF</Typography>
        </Box>
    }
    if (mediaType.includes("audio")) {
        return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Iconify icon="bx:headphone" sx={styles.mediaIcon} />
            <Typography component={"a"} rel="noopener noreferrer" href={fileDataUrl} target="_blank" sx={styles.unsupportedMedia}>Listen Audio</Typography>
        </Box>
    }
    if (mediaType.includes("video")) {
        return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Iconify icon="entypo:video" sx={styles.mediaIcon} />
            <Typography component={"a"} rel="noopener noreferrer" href={fileDataUrl} target="_blank" sx={styles.unsupportedMedia}>View Video</Typography>
        </Box>
    }
    return <Typography component={"a"} rel="noopener noreferrer" href={fileDataUrl} target="_blank" sx={styles.unsupportedMedia}>Unsupported Media (View)</Typography>
};

export default MediaDisplayComponent;

MediaDisplayComponent.defaultProps = {
    whatsAppMessageMedia: {
        "whatsapp_media_file_size": 0,
        "whatsapp_media_mime_type": "",
        "media_file": "",
        "id": -1,
    },
    scrollToBottom: () => { }
}

MediaDisplayComponent.propTypes = {
    whatsAppMessageMedia: PropTypes.object,
    scrollToBottom: PropTypes.func
}