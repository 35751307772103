import PropTypes from 'prop-types';
import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import { showLastContactedDate } from '../../../../utils/leadTableUtils';
import {
    dateTimeTypoStyle,
    tableCellInnerBoxStyle
} from '../../../../styles/NewLeadTableRowStyle';

const LastContactedField = ({ rowData }) => {
    const { dateLastContactFormat } = useSelector((state) => state.lead);
    return (
        <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'flex-start' }}>
            <Box sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                <Avatar
                    width={40}
                    height={40}
                    sx={{ mr: 2, bgcolor: 'common.darkBrickRed' }}
                    src={rowData?.last_time_created_by_avatar || '/assets/disable-icon.png'}
                />
                <Typography variant='05 Others/Label' dangerouslySetInnerHTML={{ __html: showLastContactedDate(dateLastContactFormat, rowData) }} sx={dateTimeTypoStyle} />
            </Box>
        </Box>
    );
};

LastContactedField.propTypes = {
    rowData: PropTypes.object,
};

export default LastContactedField;