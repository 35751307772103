// lib
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';

// component 
import { Iconify } from 'src/components';
import { Label } from 'src/components/labels';

// style
import { clearButton } from 'src/styles/EmailMessageComposeStyle';


const AttachmentFile = ({ files, handleClearUploaded, fileSize, attachment, getLastPathSegment, handleAttachment }) => {
    const theme = useTheme();

    return (
        <>
            <Box sx={{}}>
                {files?.map((value, index) => (
                    <Box
                        sx={{
                            display: 'flex',
                            p: '0'
                        }}
                        key={index}
                    >
                        <Label sx={{ mr: 1 }}> Uploaded files : </Label>
                        <Typography
                            noWrap
                            key={value.name}
                            variant="subtitle2"
                            sx={{ color: theme.palette.common.black}}>
                            {value.name}
                        </Typography>
                        <IconButton
                            sx={clearButton}
                            color="error"
                            onClick={() => handleClearUploaded(value?.name)}>
                            <Iconify icon="basil:cross-outline" height={18} width={18} />
                        </IconButton>
                    </Box>
                ))}
            </Box>
            {fileSize !== undefined && Object.values(fileSize)?.length > 0 && (
                <Box
                    sx={{
                        p: '0px 18px 16px',
                        mt: '-12px'
                    }}
                >
                    <Typography
                        color={'error'}
                        variant="subtitle2"
                        sx={{
                            fontSize: '12px'
                        }}>
                        Maximum File Size should be 20MB!{' '}
                    </Typography>
                </Box>
            )}
            {attachment !== undefined && Object.values(attachment).length > 0 && attachment.attachments?.length > 0 && (
                <>
                    <Divider sx={{ mb: 1 }} />
                    {attachment.attachments.map((value, index) => (
                        <Box
                            sx={{
                                display: 'flex',
                                p: '16px 16px 18px',
                                flexWrap: 'wrap'
                            }}
                            key={index}>
                            <Label
                                sx={{ mr: 1 }}
                            >
                            Attachment :
                            </Label>
                            <Link
                                noWrap
                                key={value.file}
                                href={value.file}
                                variant="body2"
                                target="_blank"
                                rel="noopener"
                                sx={{ color: 'black' }}
                            >
                                {getLastPathSegment(value.file)}
                            </Link>
                            <IconButton
                                sx={{ p: 0, ml: 1 }}
                                color="error"
                                onClick={() => handleAttachment(files, value.file, 'clear')}
                            >
                                <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                        </Box>
                    ))}
                </>
            )}
        </>
    );
};
AttachmentFile.propTypes = {
    files: PropTypes.object, 
    handleClearUploaded: PropTypes.any,
    fileSize: PropTypes.any,
    attachment: PropTypes.any,
    getLastPathSegment: PropTypes.any,
    handleAttachment: PropTypes.any,
};

export default AttachmentFile;