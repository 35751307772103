/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    useImperativeHandle,
    forwardRef,
    useState,
    useEffect
} from 'react';
import { generateReasonsLabel } from 'src/utils/getReferralData';
import { yupResolver } from '@hookform/resolvers/yup';
// form
import { useForm, Controller } from 'react-hook-form';
// @mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Typography,
    MenuItem,
    Grid,
    Card,
    FormControl,
    Divider,
    Button,
    ButtonGroup,
    FormHelperText,
    ToggleButtonGroup,
    ToggleButton,
    Switch,
    Tooltip,
    Zoom,
    ClickAwayListener,
    Popper,
    Grow,
    Paper
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { grey } from 'src/theme/palette';
// components
import { Iconify } from '../../../components';
import { ArchivedAutocomplete } from '../../../components/AutoComplete';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { AssignUserAvatar } from '../../../components/custom-avatar';
import { getAssignees } from '../../../utils/multiAsignees';
import { btnHandler } from '../../../utils/reminderSelectedTimeFunc';
import { AssignTaskPopup } from '../../../components/dialogPopUp';
// constant
import {
    archiveSelection,
    followUpSelection
} from '../../../constants/appointmentReason';
// hooks
import { useResponsive, useAssignTaskToggle } from '../../../hooks';
// store
import {
    archivedLeadSlice,
    saveNewLeadDetail,
    updateCompleteStatus
} from '../../../redux/slices/lead';
import { updateError } from '../../../redux/slices/user';
import { useSelector, dispatch } from '../../../redux/store';
import { clearPracticeUserData } from '../../../redux/slices/practiceUser';
import { setLoader } from '../../../redux/slices/loader';
// validation
import { AppointmentPageSchema } from '../../../validations/validations';
// style
import {
    AppointmentTabStyle,
    followUpButtonGroupStyle,
    followUpHourButtonStyle,
    followUpTomorrowButtonStyle,
    followUpWeekButtonStyle,
    followUpCustomButtonStyle,
    followUpNoReminderButtonStyle,
    followUpTwoWeekButtonStyle,
    selectMenuItemStyle,
    appointmentNotBooked
} from '../../../styles/DialogueTabs';
import { toggleButtonStyle } from '../../../styles/Common';
import {
    taskUserAvatar,
    openTask,
    openTaskPop,
    assignUserAvatarStyle,
    assignLengthStyle,
    assignPopperStyle
} from '../../../styles/leadTaskReminder';

// ---------------------------------------------------------------------------------------

const AppointmentPage = forwardRef(({ handleNext }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { tableRowLead, archivedLead } = useSelector((state) => state.lead);
    const { reminderMethod, archiveReasons, contactMethod } = useSelector((state) => state.schema);
    const { enqueueSnackbar } = useSnackbar();
    const [activeTime, setActiveTime] = useState('custom reminder');
    const [followUp, setFollowUP] = useState(null);
    const [isCommunication, setCommunication] = useState(tableRowLead?.openCommunication || 'No');
    const [isOpen, setIsOpen] = useState(false);
    const [assignToState, setAssignToState] = useState([]);
    const [anchorElAssign, setAnchorElAssign] = useState(null);

    const defaultValues = {
        reason: '',
        OtherNotes: '',
        preferredContactMethod: '',
        reminder: '',
        reminderDateTime: null,
        addNote: '',
        archiveNotes: '',
        archived: '',
        otherNotes: '',
        customReminder: '',
        callReminder: ''
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(AppointmentPageSchema),
        defaultValues,
    });

    const {
        control,
        setValue,
        watch,
        getValues,
        clearErrors,
        handleSubmit,
    } = methods;

    const values = watch();

    const [newArchiveReasons, setNewArchiveReasons] = useState([]);
    useEffect(() => {
        if(archiveReasons && archiveReasons?.length > 0) {
            const newArchive = archiveReasons?.filter?.((value) => value !== 'UNABLE_TO_CONTACT');          
            const archive = newArchive?.sort()?.map?.((value) => ({label: value === 'NHS_WAITING_LIST' ? 'NHS waiting list' : value === 'WANT_NHS' ? 'Want NHS' : generateReasonsLabel(value), value}))
            setNewArchiveReasons(archive);
        }
    }, [archiveReasons]);

    const onSubmit = (data, status) => {
        let archiveLead = {};
        const addNotesObj = {
            note: data.reason,
            additional_notes: data.callReminder ? data.OtherNotes : data.archiveNotes,
        };
        if (data.preferredContactMethod) {
            addNotesObj.contacted_via = data.preferredContactMethod && data.preferredContactMethod;
        }
        const archiveData = {
            note: data.archiveNotes
        };
        const openCommunication = {
            id: tableRowLead?.lead_id || '',
            is_open_for_communication: isCommunication === 'Yes',
        };
        archiveLead = {
            notes: addNotesObj,
            archiveData,
            callReminder: data.callReminder,
            customReminder: data.customReminder,
            openCommunication
        };
        if (data.callReminder && data.customReminder !== 'no-reminder') {
            if (!data.reminderDateTime) {
                dispatch(updateError({
                    message: 'Please select tab',
                    status: false
                }));
                return;
            }
            const addReminderObj = {
                note: data.addNote,
                is_completed: false,
                group: 'FOLLOW_UP',
                assignees: assignToState?.map((el) => el?.id),
            };
            if (data.reminder) {
                addReminderObj.method = data.reminder;
            }
            if (data.reminderDateTime) {
                addReminderObj.datetime = `${moment(data.reminderDateTime).format('DD-MM-YYYY HH:mm:ss')}.0Z`;
            }
            archiveLead = {
                ...archiveLead,
                addReminderObj,

            };
        }
        dispatch(saveNewLeadDetail({
            archiveLead,
        }));
        if (status === 'finish') {
            dispatch(setLoader(true));
            dispatch(updateCompleteStatus(true));
            return;
        }
        handleNext(true);
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        [isCommunication]
    );

    const handleChangeCommunication = () => {
        setCommunication(!isCommunication);
    };

    const handleButtonClick = (value) => {
        btnHandler({ value, setValue, btnValues, reminderMethod });
        setActiveTime(value);
    };

    const isMobile = useResponsive('up', 'sm');

    const btnValues = (reminder, reminderDateTime) => {
        setValue('reminder', reminder);
        setValue('reminderDateTime', reminderDateTime);
        clearErrors('reminder');
        clearErrors('reminderDate');
        clearErrors('reminderDateTime');

    };

    useEffect(() => {
        if (getValues('reason') !== '') {
            if(getValues('reason') === 'NHS_WAITING_LIST') {
                dispatch(archivedLeadSlice(null));
                setValue('archived', '');
                setValue('otherNotes', false);
                setFollowUP(false);
            }
            if (getValues('reason') === 'OTHER') {
                setFollowUP(false);
                enqueueSnackbar('Lead will be archived', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
                dispatch(archivedLeadSlice(true));
                setValue('archived', true);
                setValue('otherNotes', true);
            }
            if (archiveSelection.includes(getValues('reason'))) {
                setFollowUP(false);
                enqueueSnackbar('Lead will be archived', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
                dispatch(archivedLeadSlice(true));
                setValue('archived', true);
                setValue('otherNotes', false);
            }
            if (followUpSelection.includes(getValues('reason'))) {
                dispatch(archivedLeadSlice(false));
                setValue('archived', false);
                setFollowUP(true);
                setValue('otherNotes', false);
            }
        }
        return () => {
            dispatch(archivedLeadSlice(null));
            setValue('archived', '');
        };
    }, [getValues('reason')]);

    useEffect(() => {
        setValue('customReminder', activeTime);
    }, [activeTime]);

    useEffect(() => {
        if (archivedLead === true) {
            setValue('callReminder', false);
        }
        else if(archivedLead === null) {
            setValue('callReminder', false);
        }
        else {
            setValue('callReminder', true);
        }
    }, [archivedLead]);

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const assignTaskHandler = (event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
            <Card sx={{ p: '24px 0', boxShadow: 'none' }}>
                <Typography variant='h6' sx={{ fontSize: '26px !important', mb: '24px' }}>
                    Appointment not booked
                </Typography>
                <Grid container spacing={1} sx={appointmentNotBooked}>
                    <Controller
                        name="reason"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '18px !important', fontWeight: '700', mb: '12px' }}
                                >
                                    Reason (Required)  {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2 }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </Typography>
                                
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                    error={error && !field.value?.length}
                                    defaultValue={values.preferredContactMethod}
                                >
                                    {newArchiveReasons?.filter(reason => reason.value === 'NHS_WAITING_LIST' || followUpSelection.includes(reason.value))?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle(isLight)}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap', mt:"15px" }}
                                    error={error && !field.value?.length}
                                    defaultValue={values.preferredContactMethod}
                                >
                                    {newArchiveReasons?.filter(reason => reason.value !== 'NHS_WAITING_LIST' && !followUpSelection.includes(reason.value))?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle(isLight)}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>

                            </FormControl>
                        )}
                    />
                </Grid>
                {followUp === false && getValues('reason') !== 'NHS_WAITING_LIST' && <Box
                    sx={{
                        display: 'block',
                        mb: 3,
                    }}
                >
                    <RHFTextField
                        sx={{ width: '100%' }}
                        name='archiveNotes'
                        multiline
                        rows={4}
                        label='Archive notes (Required)'
                        placeholder='Add note here'
                    />
                </Box>
                }
                {
                    followUp !== true && <Box sx={{ pt: 3, borderTop: 'solid 1px rgba(145, 158, 171, 0.2)', '.MuiFormControl-root': { width: '100%' } }}>
                        <Controller
                            name="preferredContactMethod"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: '18px !important', fontWeight: '700', mb: '12px' }}
                                    >
                                        Method of contact
                                    </Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => {
                                            if (value === null) {
                                                field.onChange('');
                                                return;
                                            }
                                            field.onChange(value);
                                        }}
                                        aria-label="Platform"
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                        defaultValue={values.preferredContactMethod}
                                    >
                                        {contactMethod?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                    </Box>
                }
                <Box
                    sx={{
                        display: 'none',
                        mb: '40px',
                    }}
                >
                    <RHFTextField sx={{ width: '100%' }} name='OtherNotes' multiline rows={4} label='Additional notes (Required)' />
                </Box>
                {followUp === true ? <>
                    <Typography
                        variant="subtitle2"
                        sx={{ fontSize: '18px !important', fontWeight: '700' }}
                    >
                        Add a follow up reminder
                    </Typography>
                    <Box sx={AppointmentTabStyle}>
                        <ButtonGroup
                            orientation={!isMobile ? 'vertical' : 'horizontal'}
                            sx={{ ...followUpButtonGroupStyle, display: 'inline-block', borderColor: 'common.darkGrey' }}
                        >
                            <Button
                                variant={activeTime === 'custom reminder' ? 'contained' : 'text'}
                                onClick={() => handleButtonClick('custom reminder')}
                                sx={followUpCustomButtonStyle(activeTime)}
                            >
                                <Iconify sx={{ marginRight: '10px' }} icon={'solar:clock-circle-bold'} width={20} height={20} /> Custom reminder
                            </Button>
                            <Button
                                variant={activeTime === '2 hours-call' ? 'contained' : 'text'}
                                onClick={() => handleButtonClick('2 hours-call')}
                                sx={followUpHourButtonStyle(activeTime)}
                            >
                                <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 hours
                            </Button>
                            <Button
                                variant={activeTime === 'tomorrow' ? 'contained' : 'text'}
                                onClick={() => handleButtonClick('tomorrow')}
                                sx={followUpTomorrowButtonStyle(activeTime)}
                            >
                                <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call 9am tomorrow
                            </Button>
                            <Button
                                variant={activeTime === 'week' ? 'contained' : 'text'}
                                onClick={() => handleButtonClick('week')}
                                sx={followUpWeekButtonStyle(activeTime)}
                            >
                                <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 1 week
                            </Button>
                            <Button
                                variant={activeTime === 'two-week' ? 'contained' : 'text'}
                                onClick={() => handleButtonClick('two-week')}
                                sx={followUpTwoWeekButtonStyle(activeTime)}
                            >
                                <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 week
                            </Button>
                            <Button
                                variant={activeTime === 'no-reminder' ? 'contained' : 'text'}
                                onClick={() => handleButtonClick('no-reminder')}
                                sx={followUpNoReminderButtonStyle(activeTime)}
                            >
                                <Iconify sx={{ marginRight: '8px' }} icon={'bxs:phone-off'} width={25} height={25} /> No reminder
                            </Button>
                        </ButtonGroup>
                    </Box>
                    {activeTime === 'custom reminder' ?
                        <Grid item xs={12} md={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ width: '50%', mb: 3 }}>
                                    <Box sx={{ display: activeTime === 'custom reminder' ? 'block' : 'none' }}>
                                        <Controller
                                            name='reminderDateTime'
                                            control={control}
                                            render={({ field, fieldState: { error } }) => (
                                                <>
                                                    <DateTimePicker
                                                        open={isOpen}
                                                        onOpen={handleToggleOpen}
                                                        onClose={() => setIsOpen(false)}
                                                        label='Custom date and time (Required)'
                                                        value={field.value || null}
                                                        format={'dd/MM/yyyy HH:mm'}
                                                        ampm={false}
                                                        minDateTime={new Date()}
                                                        onChange={(newValue) => {
                                                            field.onChange(newValue);
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                error: !!error,
                                                                helperText: error?.message,
                                                                onKeyDown: (e) => e.preventDefault()
                                                            },
                                                            toolbarButton: {
                                                                onClick: handleToggleOpen
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ width: '50%', mb: 3 }}>
                                    <RHFSelect
                                        name='reminder'
                                        label='Reminder method'
                                        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                        sx={{ mb: 2 }}
                                    >
                                        {reminderMethod?.map((options) => (
                                            <MenuItem
                                                key={options.label}
                                                value={options.value}
                                                sx={selectMenuItemStyle}
                                            >
                                                {options.label}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                    <Box>
                                        <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                                        <Box sx={{ cursor: 'pointer' }}>
                                            {assignToState?.length > 0 ? (
                                                <Box sx={taskUserAvatar}>
                                                    <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                        <Box sx={{ display: 'flex' }}>
                                                            {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                                <AssignUserAvatar
                                                                    key={index}
                                                                    sx={assignUserAvatarStyle}
                                                                    src={assignee.user?.avatar || ''}
                                                                    name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                                    role={assignee.role}
                                                                    onClick={(e) => assignTaskHandler(e)}
                                                                />
                                                            ))}
                                                            {assignToState?.length > 2 && (
                                                                <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                                    + {assignToState?.length - 2}
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                            ) : (
                                                <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                                    <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200] }} onClick={(e) => assignTaskHandler(e)}>
                                                        <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        :
                        <Box sx={{ mb: 4, display: (activeTime === 'no-reminder' || activeTime === '') ? 'none' : 'block' }}>
                            <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                            <Box sx={{ cursor: 'pointer' }}>
                                {assignToState?.length > 0 ? (
                                    <Box sx={taskUserAvatar}>
                                        <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                            <Box sx={{ display: 'flex' }}>
                                                {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                    <AssignUserAvatar
                                                        key={index}
                                                        sx={assignUserAvatarStyle}
                                                        src={assignee.user?.avatar || ''}
                                                        name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                        role={assignee.role}
                                                        onClick={(e) => assignTaskHandler(e)}
                                                    />
                                                ))}
                                                {assignToState?.length > 2 && (
                                                    <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                        + {assignToState?.length - 2}
                                                    </Box>
                                                )}
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ) : (
                                    <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                        <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200] }} onClick={(e) => assignTaskHandler(e)}>
                                            <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    } </> : ''}
                {followUp === true && <Box sx={{ mb: 3 }}><RHFTextField name="OtherNotes" multiline rows={4} label="Additional notes (Required)" InputLabelProps={{ shrink: true }} placeholder='Add notes here' /></Box>}
                {
                    followUp === true && <Box sx={{ pt: 3, borderTop: 'solid 1px rgba(145, 158, 171, 0.2)', '.MuiFormControl-root': { width: '100%' } }}>
                        <Controller
                            name="preferredContactMethod"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: '18px !important', fontWeight: '700', mb: '12px' }}
                                    >
                                        Method of contact
                                    </Typography>
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => {
                                            if (value === null) {
                                                field.onChange('');
                                                return;
                                            }
                                            field.onChange(value);
                                        }}
                                        aria-label="Platform"
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                        defaultValue={values.preferredContactMethod}
                                    >
                                        {contactMethod?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                    </Box>
                }
                {openAssignTask && (
                    <ClickAwayListener onClickAway={onClickAwayEvent}>
                        <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement="bottom" sx={assignPopperStyle}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper sx={{ borderRadius: 2 }}>
                                        <AssignTaskPopup
                                            open={openAssignTask}
                                            onClose={() => { onCloseAssignTask(); setAnchorElAssign(null); dispatch(clearPracticeUserData()); }}
                                            type={'AddReminder'}
                                            userSpecific={'TCOUsers'}
                                            setAssignTo={setAssignToState}
                                            assignees={assignToState?.map((ele) => ele.id)}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
                {followUp === false && getValues('reason') !== 'NHS_WAITING_LIST' &&
                    <>
                        <Divider sx={{ mt: 3 }} />
                        <Box sx={{ py: 3 }} >
                            <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '18px !important', fontWeight: '700', mb: 1 }}
                            >
                                Is this person open to being contacted in the future?
                            </Typography>
                            No <Switch
                                name='isCommunication'
                                checked={isCommunication}
                                onChange={handleChangeCommunication}
                                inputProps={{ 'aria-label': 'controlled' }}
                            /> Yes
                        </Box>
                        <Divider sx={{ mb: 3 }} />
                        <Box sx={{ mt: '15px' }}>
                            <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '18px !important', fontWeight: '700', mb: 2 }}
                            >
                                Tags
                            </Typography>
                            <ArchivedAutocomplete type={'table'} user={tableRowLead} />
                        </Box>
                    </>}
            </Card>
        </FormProvider >
    );
});

AppointmentPage.propTypes = {
    handleNext: PropTypes.func
};

export default AppointmentPage;
