import { widget } from 'src/theme/palette';

export const stackStyle = {
    width: '100%',
    display: 'block',
    overflowX: 'auto',
};

export const tableAdminContainerStyle = (type, fullScreen) => ({
    position: 'relative',
    width: '100%',
    maxHeight: type === 'LeadTemplate' && !fullScreen ? '221px' : '100%'
});

export const tableContainerStyle = (type, fullScreen) => ({
    minWidth: 'auto',
    position: 'relative',
    outline: '1px solid rgba(145, 158, 171, 0.12)',
    borderBottom: '1px solid rgba(145, 158, 171, 0.12)',
    borderRadius: '8px',
    borderTopRightRadius: '8px',
    width: 'calc(100% - 32px)',
    margin: '0 auto 20px',
    maxWidth: '100%',
    overflowX: 'auto',
    maxHeight: type === 'LeadTemplate' && !fullScreen ? '221px' : '100%'
});

export const paginationOuterBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: 'solid 1px rgba(145, 158, 171, 0.24)'
};

export const paginationInnerBoxStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    ' .MuiTablePagination-root': {
        border: 'none'
    }
};

export const btnStackStyle = {
    m: 3,
    alignItems: {
        md: 'flex-end',
        xs: 'center'
    }
};

export const listBtnStackStyle = {
    m: '20px 24px',
    alignItems: {
        md: 'flex-end',
        xs: 'center'
    },
    position: 'absolute',
    top: 0,
    right: 0,
};

export const menuPopperStyle = {
    width: 160,
    '& .MuiMenuItem-root': {
        px: 1,
        typography: 'body2',
        borderRadius: 0.75,
        '& svg': {
            mr: 2,
            width: 20,
            height: 20
        },
    }
};

export const detailBox = {
    display: 'flex',
    columnGap: 2,
    rowGap: 3,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    px: 2,
    pt: 2,
    pb: 1
};

export const detailBoxTitle = {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 700
};

export const detailListBox = {
    mt: 2,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
};

export const detailTreatmentChipBox = {
    display: 'flex',
    p: '0',
    m: '0',
    width: '100%',
    flexWrap: 'wrap',
    gap: { sm: '10px', xs: '0' }
};

export const messageTableBox = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize'
};

export const messageTableCategory = {
    bgcolor: 'rgba(24, 144, 255, 0.16)',
    padding: '5px 10px', color: widget.tagBlueColor,
    lineHeight: '20px', borderRadius: '6px',
    fontWeight: 600, textTransform: 'capitalize'
};

export const messageTableMenuItem = {
    fontWeight: 600,
    fontSize: 14
};

export const engagementMenuItemPreview = {
    maxWidth: '250px',
    width: 'auto',
    textOverflow: 'ellipsis',
    border: 'solid 1px #00AB55',
    borderRadius: '6px',
    padding: '5px 10px'
};

export const engagementMenuItemUseTemplate = { ...engagementMenuItemPreview };

export const addNewCampaignbtnStackStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '20px 24px',
    alignItems: {
        md: 'flex-end',
        xs: 'center'
    }
};

export const subjectTableCellStyle = {
    width: '100%',
    borderBottom: '1px dashed',
    p: '8px 16px',
    borderColor: 'common.borderColor'
};

export const actionsTableCellStyle = {
    borderBottom: '1px dashed',
    p: '8px 16px',
    verticalAlign: 'top',
    borderColor: 'common.borderColor'
};

const margin = { sm: 0, xs: 1 };

export const detailToolBarChipStyle = { marginBottom: margin, marginRight: margin };

export const leadTempMessageTableCellStyle = {
    borderBottom: '1px dashed', 
    p: '8px 16px', 
    borderColor: 'common.borderColor'
};

export const typoAlignCenterStyle = {
    display: 'flex', 
    alignItems: 'center'
};

export const messageTableCellStyle = {
    maxWidth: '320px'
};

export const dividerStyle = {
    borderStyle: 'dashed'
};