import { forwardRef } from 'react';
// @mui
import { Card, Typography, Box, Stack, useTheme } from '@mui/material';
import { Scrollbar } from '../../../components';
import { EmptyContent } from '../../../components/empty-content';
// store
import { useSelector } from '../../../redux/store';
// style
import { cardStyle, conversationStartStackStyle, conversationEndStackStyle, choiceItemStyle } from '../../../styles/AutoMessageAccordianStyle';

// -------------------------------------------------------------------------------------------

const ChatbotConversationsMessages = forwardRef(() => {
    const theme = useTheme();
    const { chatbotConversation } = useSelector((state) => state.lead);
    const transcript = chatbotConversation?.results[0]?.chatbot_conversations[0]?.transcript;
    const temp = transcript && JSON.parse(transcript);

    return (
        <Card sx={{ ...cardStyle, boxShadow: 'none' }}>
            <Scrollbar sx={{ position: 'relative', maxHeight: '600px' }}>
                <Box>
                    {!temp &&
                        <EmptyContent
                            title="No conversation found"
                            imgUrl="/assets/icons/empty/ic_email_selected.svg"
                            sx={{
                                borderRadius: 1.5,
                                bgcolor: 'background.default',
                            }}
                        />
                    }
                    {Array.isArray(temp) && temp.map((message, index) => (
                        <Box key={index} sx={{ marginBottom: 4 }}>
                            {message.sender === 'assistant' ? (
                                <Stack alignItems='flex-start'>
                                    <Stack sx={conversationStartStackStyle(theme)}>
                                        {message.message.type === 'text' &&
                                            <Typography sx={{ fontSize: '14px', 'div': { p: '0 !important' }, 'iframe': { position: 'relative !important', minHeight: '270px' } }} dangerouslySetInnerHTML={{ __html: message.message.content }} />
                                        }
                                        {message.message.type === 'single-choice' &&
                                            <Box sx={{ width: '295px' }}>
                                                <Typography sx={{ fontSize: '14px' }}>{message.message.content.text}</Typography>
                                                <Box sx={{ m: '16px 0 0' }}>
                                                    {message?.message?.content?.choices?.map((choice) => (
                                                        <Box key={choice?.title} sx={choiceItemStyle(theme)}>
                                                            {choice?.value}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        }
                                    </Stack>
                                </Stack>
                            ) : (
                                <Stack alignItems='flex-end'>
                                    <Stack sx={conversationEndStackStyle(theme)}>
                                        {message.message.type === 'text' &&
                                            <Typography sx={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: message.message.content }} />
                                        }
                                        {message.message.type === 'selectedOption' &&
                                            <Typography sx={{ fontSize: '14px' }}>{message.message.content.value}</Typography>
                                        }
                                        {message.message.type === 'input' &&
                                            <Typography sx={{ fontSize: '14px' }}>{message.message.content}</Typography>
                                        }
                                    </Stack>
                                </Stack>
                            )}
                        </Box>
                    ))}
                </Box>
            </Scrollbar>
        </Card>
    );
});

export default ChatbotConversationsMessages;
