import { common } from 'src/theme/palette';

export const filterBoxStyle = (isLight, filterBtn) => ({
    pl: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    'button': {
        border: filterBtn ? '1px solid #00AB55' : '1px solid rgba(145, 158, 171, 0.32)',
        borderRadius: '10px',
        background: 'transparent !important',
        color: isLight ? '#212B36' : 'white',
        boxShadow: 'none !important',
        minWidth: '80px',
        fontWeight: '700',
        fontSize: '13px',
        padding: '4px 10px 4px 10px',
        'svg': {
            mr: '10px',
            width: '17px'
        }
    }
});

export const reusableSidebarBoxStyle = {
    display: 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    p: '20px 20px', 
    borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
};

export const sidebarDeleteBoxStyle = {
    cursor: 'pointer', 
    lineHeight: 0, 
    border: '1px solid rgba(145, 158, 171, 0.32)', 
    borderRadius: '8px', 
    minWidth: '38px', 
    minHeight: '30px', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
};

export const chipStyle = {
    fontSize: '15px',
    color: '#637381',
    fontWeight: '700',
    mr: '8px',
    mb: '8px'
};

export const sidebarMainBox = {
    p: '0 20px 24px',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'left',
    '.MuiInputBase-root': {
        cursor: 'pointer',
        '.MuiInputBase-input': {
            cursor: 'pointer',
            p: '0'
        }
    }
};

export const avatarBox = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: '14px'
};

export const plusButton = {
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '8px'
};

export const plusMenu = {
    borderRadius: '8px',
    minHeight: '40px !important',
    color: '#212B36'
};

const getBGColor = (data) => {
    if (data?.lead_treatment?.status === 'CONSULTATION_BOOKED' && data?.lead_treatment?.appointments?.[0]?.status === 'CANCELLED') return {};
    return { backgroundColor: `${common.blue} !important` };
};

export const recordBtn = (data) => ({
    color: '#ffffff !important',
    border: 'none !important',
    fontSize: '15px',
    width: '100%',
    ...getBGColor(data)
});
export const recordBtnArrow = (data) => ({
    padding: '0 15px 0 0 !important',
    width: '10%',
    ...getBGColor(data)
});

export const btnOuterBox = {
    p: 3,
    '.MuiButtonBase-root': {
        maxWidth: '100% !important',
        width: '100% !important',
        height: '48px',
        fontSize: '15px',
        mb: '0',
        lineHeight: '19px'
    }
};

export const descriptionValueStyle = {
    '.MuiOutlinedInput-notchedOutline': { 
        border: 'none !important' 
    }, 
    '.MuiInputBase-root': { 
        color: '#212B36', 
        p: '0' 
    },
    '.Mui-disabled': {
        color: '#212B36',
        '-webkit-text-fill-color': '#212B36',
        cursor: 'not-allowed'
    }
};

export const commentBoxStyle = {
    position: 'absolute', 
    bottom: 0, 
    right: 0, 
    p: 1, 
    width: '100%', 
    textAlign: 'right'
};