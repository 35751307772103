import { Dialog, DialogTitle, TextField, Button, Box, Typography } from '@mui/material'; 

import PropTypes from 'prop-types';
import { useState } from 'react';
import * as Yup from 'yup';

import { store } from 'src/redux/store';
import { useToggle } from 'src/hooks';

import CustomColorPicker from 'src/components/CustomColorPicker';
import { COLOR_CODES } from 'src/constants/newLead';
import { addInboxLabel, updateInboxLabel } from 'src/utils/mailUtils';
 
const AddLabelDialog = ({ onClose, open, practiceId, isFromEdit, data, successDone}) => {
 
    const [label, setLabel] = useState(isFromEdit ? data.name : ''); 
    const [color, setColor] = useState(isFromEdit ? data?.colour : '');
    const [labelError, setLabelError] = useState('');
    const [colorError, setColorError] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const { dispatch } = store;

    const { toggle: openColor, onOpen: onColorOpen, onClose: onColorClose } = useToggle();

    const handleClose = () => {
        onClose();
    };

    const handleLabelChange = (value) => {
        setLabel(value);
        if (value !== '') {
            setLabelError('');
        }
    };

    const handleOpenColorPicker = (event) => {
        onColorOpen();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseColorPicker = () => {
        onColorClose();
        setAnchorEl(null);
    };
    
    const handleChangeColor = (color) => {
        setColor(color);
        setColorError('');
        onColorClose();
    };
   
    const validationSchema = Yup.object().shape({
        label: Yup.string().required('Label is required'),
        color: Yup.string().required('Color is required'),
    });


    const handleAddLabel = () => {
        validationSchema.validate({ label, color }, { abortEarly: false })
            .then(() => {
                if (isFromEdit) {
                    const payload = { name: label, colour: color }; 
                    dispatch(updateInboxLabel({ labelId: data.id, data: payload, successDone }));
                } else {
                    const data = { name: label, colour: color };
                    dispatch(addInboxLabel(practiceId, data));
                    handleClose();
                }
            })
            .catch((error) => {
                error.inner.forEach((err) => {
                    if (err.path === 'label') {
                        setLabelError(err.message);
                    } else if (err.path === 'color') {
                        setColorError(err.message);
                    }
                });
            });
    };
    return (
        <Dialog
            sx={{ '.MuiDialog-paper': { minWidth: '500px' } }}
            onClose={() => {
                handleClose();
            }}
            open={open}>
            <Box sx={{
                padding: 3
            }}>
                <DialogTitle sx={{ p: 0, mb: 3 }}> { isFromEdit ? 'Edit Label': 'Add Label'}</DialogTitle>
                <Box sx={{ mb: 3 }}>
                    <Typography sx={{ mb: '4px', fontSize: '14px', fontWeight: '700' }}>Label</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        onChange={(event) => handleLabelChange(event.target.value)} 
                        value={label} 
                        placeholder="Enter label"
                        error={Boolean(labelError)}
                        helperText={labelError}
                    /> 
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Typography sx={{ mb: '4px', fontSize: '14px', fontWeight: '700' }}>Color</Typography>
                    <CustomColorPicker
                        open={openColor}
                        openColorPicker={handleOpenColorPicker}
                        handleClose={handleCloseColorPicker}
                        colorList={COLOR_CODES}
                        value={color}
                        anchorEl={anchorEl}
                        onChange={handleChangeColor}
                    />
                    <Typography variant="body2" color="error">{colorError}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ minWidth: '120px', minHeight: '40px' }}
                        onClick={() => {
                            handleClose();
                        }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ minWidth: '120px', minHeight: '40px' }}
                        onClick={() => {
                            handleAddLabel();
                        }}>
                        {'Save'} 
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AddLabelDialog;

AddLabelDialog.propTypes = {
    open: PropTypes.bool,
    onClose: () => PropTypes.void,
    practiceId: PropTypes.any, 
    isFromEdit: PropTypes.bool,
    data: PropTypes.any,
    successDone: () => PropTypes.void,
};
