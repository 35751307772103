import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "src/utils/apiCall";
import { dispatch, store } from "../store";
import { updateError } from "./user";

const INITIAL_STATE = {
    formDataPrimary: [],
    formDataDerived: [],
    formDataPrimaryLoading: false,
    formDataDerivedLoading: false,
    primaryFormPageLoaded: -1,
    derivedFormPageLoaded: -1,
    primaryDataTotal: 0,
    derivedDataTotal: 0
}

const slice = createSlice({
    name: "callScriptV2",
    initialState: INITIAL_STATE,
    reducers: {
        reset(state) {
            Object.assign(state, INITIAL_STATE);
        },
        fetchFormDataPrimary(state) {
            state.formDataPrimaryLoading = true;
        },
        storeFormDataPrimary(state, action) {
            state.formDataPrimaryLoading = false;
            state.formDataPrimary = action?.payload?.data;
            state.primaryFormPageLoaded = action?.payload?.pageLoaded ?? state.primaryFormPageLoaded;
            state.primaryDataTotal = action?.payload?.totalPageCount;
        },
        fetchFormDataDerived(state) {
            state.formDataDerivedLoading = true;
        },
        storeFormDataDerived(state, action) {
            state.formDataDerivedLoading = false;
            state.formDataDerived = action?.payload?.data;
            state.derivedFormPageLoaded = action?.payload?.pageLoaded ?? state.primaryFormPageLoaded;
            state.derivedDataTotal = action?.payload?.totalPageCount;
        },
        startLoading(state) {
            state.formDataPrimaryLoading = true;
            state.formDataDerivedLoading = true;
        },
    }
});

export default slice.reducer;
export const { reset, startLoading } = slice.actions;

const getPracticeId = () => store.getState()?.practiceTreatmentList?.practiceId;

export function fetchFormDataPrimary(payload) {
    return async () => {
        try {
            dispatch(slice?.actions?.fetchFormDataPrimary());
            const { reset, nextPageFrom } = payload ?? {};
            const practiceId = getPracticeId();
            const callScriptStore = store.getState().callScriptV2;
            const prvData = callScriptStore?.formDataPrimary;
            const maxPageNumLoaded = callScriptStore?.primaryFormPageLoaded;
            const newPageLoaded = reset ? 0 : (maxPageNumLoaded + 1);
            const limit = 10;
            const offset = newPageLoaded * 10;
            const baseParams = `&fields={created_at,created_at_human,category,description,created_by,treatments,id,is_template,form_type,is_default,name,sections,updated_at,updated_at_human}`;
            const isBaseTemplate = `&is_template=true`;
            const params = `&limit=${limit}&offset=${offset}${isBaseTemplate}${baseParams}`;
            const response = await callApi(`/api/v1/practices/${practiceId}/forms/?ordering=-created_at${params}`);
            if (response?.status === 200) {
                if (nextPageFrom != null)
                    dispatch(slice?.actions?.storeFormDataPrimary({ data: [...prvData, ...(response?.data?.results ?? [])], pageLoaded: newPageLoaded, totalPageCount: response?.data?.count ?? 0 }));
                else
                    dispatch(slice?.actions?.storeFormDataPrimary({ data: response?.data?.results, pageLoaded: newPageLoaded, totalPageCount: response?.data?.count ?? 0 }));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching form Data", e);
            dispatch(slice?.actions?.storeFormDataPrimary({ data: [] }));
            dispatch(updateError({ success: false, message: 'Failed to fetch data, please try again after sometime' }));
        }
    }
}

export function fetchFormDataDerived(payload) {
    return async () => {
        try {
            dispatch(slice?.actions?.fetchFormDataDerived());
            const { reset, nextPageFrom } = payload ?? {};
            const practiceId = getPracticeId();
            const callScriptStore = store.getState().callScriptV2;
            const maxPageNumLoaded = callScriptStore?.derivedFormPageLoaded;
            const prvData = callScriptStore?.formDataDerived;
            const limit = 10;
            const newPageLoaded = reset ? 0 : (maxPageNumLoaded + 1);
            const offset = newPageLoaded * 10;
            const baseParams = `&fields={id,created_at,created_at_human,category,description,created_by,treatments,is_template,form_type,is_default,name,sections,updated_at,updated_at_human}`;
            const isBaseTemplate = `&is_template=false`;
            const params = `&limit=${limit}&offset=${offset}${isBaseTemplate}${baseParams}`;
            const response = await callApi(`/api/v1/practices/${practiceId}/forms/?ordering=-created_at${params}`);
            if (response?.status === 200) {
                if (nextPageFrom != null)
                    dispatch(slice?.actions?.storeFormDataDerived({ data: [...prvData, ...(response?.data?.results ?? [])], pageLoaded: newPageLoaded, totalPageCount: response?.data?.count ?? 0 }));
                else
                    dispatch(slice?.actions?.storeFormDataDerived({ data: response?.data?.results, pageLoaded: newPageLoaded, totalPageCount: response?.data?.count ?? 0 }));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching form Data", e);
            dispatch(slice?.actions?.storeFormDataDerived({ data: [] }));
            dispatch(updateError({ success: false, message: 'Failed to fetch data, please try again after sometime' }));
        }
    }
}

export function deleteScript(id, handleSuccess) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/forms/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Script deleted successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}