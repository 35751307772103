import { common, grey, widget } from 'src/theme/palette';

export const dateLabelStyle = (row, color) => ({
    px: 2,
    backgroundColor: (row || color) ? 'rgba(84, 214, 44, 0.16)' : 'rgba(255, 72, 66, 0.16)',
    color: (row || color) ? common.meadowGreen : common.fireBerry
});
export const statusLabelStyle = (row) => ({
    px: 2,
    backgroundColor: ((row === 'FAILED_TO_ATTEND_CONSULTATION' && 'rgba(255, 72, 66, 0.16)') || (row === 'NEW' && 'rgba(84, 214, 44, 0.16)')) || 'rgba(24, 144, 255, 0.16)',
    color: ((row === 'FAILED_TO_ATTEND_CONSULTATION' && common.fireBerry) || (row === 'NEW' && common.meadowGreen)) || widget.tagBlueColor
});
export const badgeStyle = (row, contactedCount) => ({
    position: 'unset',
    '.MuiBadge-badge': {
        transform: `scale(1) translate(${row === 'CONSULTATION_BOOKED' ? '50%' : '100%'}, '-50%')`,
        display: contactedCount === 0 ? 'none' : '',
        bgcolor: 'primary.darker'
    },
});
export const viewBtnStyle = (row) => ({
    color: `${grey[0]} !important`,
    backgroundColor: 'primary.main !important',
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    minWidth: row === 'CLOSED' ? '120px' : '172px',
    borderRadius: 1,
    p: '9px 16px'
});
export const archivingButtonStyle = (row) => ({
    backgroundColor: `${row.is_archive_pending ? grey[200] : 'primary.main'} !important`,
    color: 'common.white !important',
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    minWidth: '150px',
    borderRadius: 1,
    p: '9px 16px'
});
export const arcBtnStyle = (contactedCount) => ({
    position: 'relative',
    '.MuiBadge-badge': {
        transform: 'scale(1) translate(100%, \'-50%\')',
        backgroundColor: 'rgba(145, 158, 171, 0.48)',
        color: grey[0],
        display: contactedCount === 0 ? 'none' : ''
    }
});
export const flwBtnStyle = (row) => ({
    backgroundColor: `${!row.is_archive_pending && 'primary.main'} !important`,
    color: `${!row.is_archive_pending && 'common.white'} !important`,
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    minWidth: '150px',
    borderRadius: 1,
    p: '9px 16px'
});
export const chipStyle = (val) => ({
    borderRadius: '10px',
    color: 'white',
    background: val === 'Yes' ? common.mediumSeaGreen : common.fieryRed, 
    fontWeight: 800
});
export const menuListStyle = {
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    backgroundColor: grey[0],
    borderRadius: '12px',
    padding: '8px'
};
export const iconBtnStyle = {
    borderRadius: '100px',
    width: '18px',
    height: '18px',
    bgcolor: common.mintyZest,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: 'auto'
};
export const recordAttendedButtonStyle = {
    backgroundColor: 'primary.main !important', 
    color: `${grey[0]} !important`, 
    border: 'none !important',
    borderRadius: 1, 
    p: '9px 16px'
};

export const tableCellStyle = (typed) => ({ whiteSpace: 'nowrap', cursor: typed !== 'ArchiveTable' && 'pointer' });

export const duplicateTableRowStyle = (row) => ({
    cursor: 'pointer', 
    borderBottom: 'solid 1px rgba(0,0,0,0.1)', 
    pointerEvents: row.is_archive_pending ? 'none' : 'visible', 
    bgcolor: row.is_archive_pending && 'rgb(235 235 235)', 
    'td': { 
        bgcolor: row.is_archive_pending && 'rgb(235 235 235)!important' 
    }
});

export const tableMoreMenuBoxStyle = {
    'button': {
        minWidth: 'auto !important',
        whiteSpace: 'normal !important'
    },
    '.MuiButtonGroup-root': {
        minWidth: 'auto !important'
    },
    '.MuiMenuItem-root button svg': {
        mr: 0
    }
};

export const assignMainBoxStyle = {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start'
};

export const tableCellInnerBoxStyle = {
    width: '100%',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center'
};

export const tableCellRelativeBoxStyle = { ...tableCellInnerBoxStyle, position: 'relative', justifyContent: 'flex-start' };

export const tableStatusTextStyle = {
    fontWeight: 600,
    textAlign: 'center'
};

export const apptMethodSelectStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    '.MuiOutlinedInput-notchedOutline': {
        display: 'none'
    },
    '.MuiSelect-select': {
        textOverflow: 'clip',
        p: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingRight: '0 !important',
    }
};

export const apptMethodLabelStyle = () => ({
    px: 2,
    cursor: 'pointer'
});

export const datePickerBoxStyle = {
    position: 'absolute',
    width: '100%',
    top: '0',
    maxHeight: '100%',
    opacity: 0,
    cursor: 'pointer',
    zIndex: 0
};

export const datePickerTextFieldStyle = {
    position: 'absolute',
    width: '100%',
    left: 0,
    p: '0',
    top: '-15px'
};
export const arrowButtonStyle = (theme) => ({
    backgroundColor: `${theme.palette.primary.main} !important`,
    padding: '0 15px 0 0 !important',
    minWidth: '10px !important',
    borderRadius: 1
});

export const dateTimeTypoStyle = {
    textAlign: 'left',
    lineHeight: '19px',
    'span': {
        display: 'block',
        color: grey[600],
        fontWeight: 400,
        textTransform: 'none !important'
    }
};

export const dateOfEnquiryLabelStyle = (formatData, params, currentDate, typed) => ({
    backgroundColor: 'transparent',
    color: (formatData(params?.row.created_at) === currentDate) ? 'common.lightGreen' : 'common.red',
    cursor: typed !== 'ArchiveTable' && 'pointer',
    height: 'auto',
    minHeight: '22px',
    p: '1px 0',
});

export const dateOfAppointmentLabelStyle = (row, color, typed) => ({
    px: 2,
    backgroundColor: 'transparent',
    color: (row || color) ? 'common.lightGreen' : 'common.red',
    cursor: typed !== 'ArchiveTable' && 'pointer',
    height: 'auto',
    minHeight: '22px',
    p: '1px 0',
});

export const referralSelectStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    '.MuiOutlinedInput-notchedOutline': {
        display: 'none'
    },
    '.MuiSelect-select': {
        textOverflow: 'clip',
        p: 0,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        paddingRight: '0 !important',
        justifyContent: 'center'
    }
};

export const buttonGroupStyle = (theme) => ({
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)', 
    minWidth: '185px', 
    borderRadius: 1, 
    '&:hover button': { 
        bgcolor: `${theme.palette.primary.dark} !important`
    }
});

export const viewDetailBoxStyle = {
    width: '54px', 
    height: '48px', 
    borderRadius: 1, 
    border: `1px solid ${common.borderColor}`, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
};

export const treatmentNameStyle = (length) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    maxWidth: length ? '75%' : '100%',
    textAlign: 'center'
});

export const bookedWithBoxStyle = {
    width: '100%',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    p: '0 10px'
};

export const depositAmountDisabledStyle = (isDisabled) => {
    if (isDisabled) return { pointerEvents: 'none', opacity: 0.5 };
    return {};
};

export const paymentChipStyle = {
    padding: '4px 8px 4px 4px',
    backgroundColor: 'rgba(0, 184, 217, 0.16)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    color: 'info.dark',
    fontSize: '12px'
};
export const paymentChipTypeStyle = {
    padding: '0',
    bgcolor: 'info.dark',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'common.white',
    fontSize: '12px',
    lineHeight: '26px',
    textTransform: 'uppercase',
    mr: 1,
    width: '26px',
    minWidth: '26px',
    height: '26px'
};

export const centerAlign = { display: 'flex', alignItems: 'center' };

export const depositPaymentWrapperStyle = { ...centerAlign, justifyContent: 'flex-start', flexWrap: 'wrap', gap: '8px', width: 'calc(100% - 40px)', pr: '0 !important' };

export const gridToolbarColumnsButtonStyle = {
    width: '30px', 
    overflow: 'hidden', 
    minWidth: 'auto', 
    justifyContent: 'flex-start'
};

export const clinCheckStatusStyle = ({ typed }) => ({
    px: 2,
    backgroundColor: 'rgba(84, 214, 44, 0.16)',
    color: common.meadowGreen,
    cursor: typed !== 'ArchiveTable' && 'pointer',
});