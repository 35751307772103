import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import CustomCheckBox from './CustomCheckBox';
import { RHFTextField } from '../hook-form';
import { leaveNotesList } from '../../constants/LeadNoteConst';
import {
    unableToContactTitleStyle,
    notesTitleStyle,
    noteBoxStyle,
    saveNoteButtonStyle,
    noteMainBoxStyle
} from '../../styles/FailedToAttendTabs';

const NotesComponent = ({ data }) => {
    const { note,
        downIcon,
        minusIcon,
        handleAccordian,
        noteChecked,
        notesValue,
        handleNotesInputChange,
        handleOptionClick,
        selectedOptions,
        isNoteSaved,
        saveNoteSubmit
    } = data;

    return (
        <Box>
            <Typography sx={unableToContactTitleStyle}>Unable to contact lead </Typography>
            <Typography onClick={() => handleAccordian('notes')} sx={{ ...notesTitleStyle, mb: 3 }}>
                <CustomCheckBox checked={noteChecked} label='Leave a note about the call attempt' />
                {!note ? downIcon : minusIcon}
            </Typography>
            {note ?
                <>
                    <Box sx={noteMainBoxStyle} className='notes-main-box'>
                        <RHFTextField
                            name='notes'
                            autoFocus
                            multiline
                            rows={2} placeholder='Enter or select details about the call attempt'
                            value={notesValue}
                            onChange={(e) => handleNotesInputChange(e)}
                            sx={{ position: 'unset', '.MuiInputBase-root': { position: 'unset' } }}
                        />
                        <Box sx={{ p: '0 14px 8px' }}>
                            {leaveNotesList.map((note, index) => (
                                <Box
                                    key={index}
                                    onClick={() => handleOptionClick(note.value)}
                                    sx={noteBoxStyle(selectedOptions, note)}
                                >
                                    {note.label}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '12px 0 24px' }}>
                        <Button
                            disabled={isNoteSaved}
                            onClick={() => saveNoteSubmit()}
                            variant='contained'
                            sx={saveNoteButtonStyle}
                        >
                            Save note
                        </Button>
                    </Box>
                </>
                : null}
        </Box>
    );
};

NotesComponent.propTypes = {
    data: PropTypes.object
};

export default NotesComponent;