/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import moment from 'moment';
import { useImperativeHandle, forwardRef } from 'react';
// Google map autocomplete
import Autocomplete from 'react-google-autocomplete';
// form
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
// @mui
import {
    Box,
    Grid,
    Card,
    Typography,
    Checkbox,
    MenuItem,
    Divider,
    FormControl,
    FormHelperText,
    FormGroup,
    FormControlLabel,
    IconButton,
    InputAdornment,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';
// components
import { Iconify } from '.';
import { ReferralDropdown } from './Dropdowns';
// hook-form
import { FormProvider, RHFTextField, RHFSelect } from './hook-form';
// @store
import { dispatch, useSelector } from '../redux/store';
import { updatePersonalDetail } from '../redux/slices/lead';
// style
import {
    boxStyle,
    menuItemStyle,
    fieldBoxStyle,
    controllerBoxStyle,
    autocompleteBoxStyle,
    selectBoxStyle,
    selectStyle
} from '../styles/PersonalDetailsStyle';
// validation
import { PersonalDetailSchema } from '../validations/validations';
// constants
import {
    inputKeys
} from '../constants/LeadDetailConst';
import { toggleButtonStyle } from '../styles/Common';

const EditPersonalDetails = forwardRef(({ handleNext, type }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { followLead } = useSelector((state) => state.lead);
    const { preferredContactMethodData, referral, sourceType, salutationOptions } = useSelector((state) => state.schema);
    const referralValue = (value, data) => referral?.length > 0 && referral?.filter((res) => res[value] === data.referral);

    const defaultValues = {
        firstName: followLead?.first_name || '',
        lastName: followLead?.last_name || '',
        title: followLead?.salutation || '',
        email: followLead?.lead_contact?.email || '',
        notes: followLead?.lead_contact?.note || '',
        phoneNumber: followLead?.lead_contact?.phone || '',
        dob: followLead && followLead?.dob && new Date(moment(followLead?.dob, 'DD/MM/YYYY')) || null,
        preferredContactMethod: followLead && followLead?.lead_contact && followLead?.lead_contact?.preferred_contact_method || '',
        address_line_1: followLead && followLead?.lead_address && followLead?.lead_address?.address_line_1 || '',
        county: followLead && followLead?.lead_address && followLead?.lead_address?.county || '',
        city: followLead && followLead?.lead_address && followLead?.lead_address?.city || '',
        postcode: followLead && followLead?.lead_address && followLead?.lead_address?.postcode || '',
        address_line_2: followLead && followLead?.lead_address && followLead?.lead_address?.address_line_2 || '',
        address: '',
        type: followLead && followLead?.lead_source && followLead?.lead_source.type || '',
        referral: followLead && followLead?.lead_source && followLead?.lead_source?.referral || '',
        isRegister: followLead && followLead?.is_registered_patient || false
    };

    const methods = useForm({
        resolver: yupResolver(PersonalDetailSchema),
        defaultValues,
    });

    const {
        setValue,
        handleSubmit,
        watch,
        control,
    } = methods;

    const values = watch();

    const onSubmit = async (data) => {
        const detail = {
            salutation: data.title && data.title.toUpperCase() || '',
            first_name: data.firstName,
            last_name: data.lastName || '',
            dob: data.dob ? moment(data.dob, 'DD/MM/YYYY').format('DD-MM-YYYY') : null,
            is_registered_patient: data.isRegister,
            lead_contact: {
                email: data.email,
                phone: data.phoneNumber
            },
            lead_source: {}
        };
        if (data.notes) {
            detail.lead_contact.note = data.notes;
        }
        if (data) {
            detail.lead_address = {
                ...detail.lead_address,
                address_line_1: data.address_line_1
            };
        }
        if (data) {
            detail.lead_address = {
                ...detail.lead_address,
                postcode: data.postcode
            };
        }
        if (data) {
            detail.lead_address = {
                ...detail.lead_address,
                address_line_2: data.address_line_2
            };
        }
        if (data) {
            detail.lead_address = {
                ...detail.lead_address,
                city: data.city,
            };
        }
        if (data) {
            detail.lead_address = {
                ...detail.lead_address,
                county: data.county
            };
        }
        if (data.preferredContactMethod) {
            detail.lead_contact.preferred_contact_method = data.preferredContactMethod.toUpperCase();
        }
        if (data.type) {
            detail.lead_source = {
                ...detail.lead_source,
                type: data.type
            };
        }
        if (data.referral) {
            detail.lead_source = {
                ...detail.lead_source,
                referral: data.referral
            };
        }
        const updateForm = JSON.stringify(defaultValues) === JSON.stringify(data);
        if (followLead && followLead.id) {
            if (!updateForm) {
                detail.id = followLead.id;
                dispatch(updatePersonalDetail(detail, practiceId, handleNext, 'EditPersonalDetail'));
            } else {
                handleNext(true);
            }
        }
    };

    const setAddressData = (addresses, fullAddress) => {
        clearAddressData();
        if (addresses && addresses.length > 0) {
            const address1 = fullAddress.split(',')[0];
            setValue('address_line_1', address1);
            addresses.forEach(address => {
                if (address.types.indexOf('postal_code') > -1) {
                    setValue('postcode', address.long_name);
                }
                if (address.types.indexOf('country') > -1) {
                    if (address1 === address.long_name) {
                        setValue('address_line_1', '');
                    }
                    setValue('county', address.long_name);
                }
                if (address.types.indexOf('locality') > -1 || address.types.indexOf('postal_town') > -1) {
                    if (address1.includes(address.long_name)) {
                        setValue('address_line_1', '');
                    }
                    setValue('city', address.long_name);
                }
                if (address.types.indexOf('route') > -1) {
                    if (!address1.includes(address.long_name)) {
                        setValue('address_line_2', address.long_name);
                    } else {
                        setValue('address_line_2', '');
                    }
                }
            });
        } else {
            clearAddressData();
        }
    };

    const clearAddressData = () => {
        setValue('postcode', '');
        setValue('address_line_2', '');
        setValue('county', '');
        setValue('city', '');
        setValue('address_line_1', '');
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleChange = (event, data) => {
        const {
            target: { value },
        } = event;
        setValue(data, value);
    };

    const handleReferral = (event, data) => {
        if (data) {
            setValue('referral', data.value);
        }
    };

    const getAddressField = () => {
        if (values.address_line_1 || values.address_line_2 || values.city || values.county || values.postcode) {
            return true;
        }
        return false;
    };

    return (
        <FormProvider methods={methods}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="h6" sx={{ marginBottom: '15px' }}>
                            {type === 'followUp' ? 'Patient Details' : 'Personal Details'}
                        </Typography>
                        <Divider sx={{ marginBottom: '30px' }} />
                        <Box sx={boxStyle}>
                            <RHFSelect
                                name={'title'}
                                label="Title"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            >
                                {salutationOptions?.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        value={option.value}
                                        sx={menuItemStyle}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField name="firstName" label="First name (Required)" />
                            <RHFTextField name="lastName" label="Last name" />
                            <Box>
                                <Controller
                                    name="dob"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <MobileDatePicker
                                            orientation="landscape"
                                            label="Date of birth"
                                            value={field.value || null}
                                            format="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!error,
                                                    helperText: error?.message,
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <Iconify height={24} width={24} icon='solar:calendar-mark-bold-duotone' />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box sx={fieldBoxStyle}>
                            <RHFTextField name='email' label='Email' />
                            <RHFTextField
                                name='phoneNumber'
                                type='string'
                                inputProps={{ maxLength: 15 }}
                                label='Phone number (Required)'
                                onKeyDown={(evt) => {
                                    const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
                                    const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
                                    const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
                                    if (!(isControlC || isControlV || isAllowedKey)) {
                                        evt.preventDefault();
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={controllerBoxStyle}>
                            <Controller
                                name="preferredContactMethod"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ m: '0 0 10px' }}
                                        >
                                            Preferred Contact Method
                                        </Typography>
                                        <ToggleButtonGroup
                                            color="primary"
                                            exclusive
                                            value={field.value}
                                            onChange={(e, value) => {
                                                if (value === null) {
                                                    field.onChange(field.value);
                                                    return;
                                                }
                                                field.onChange(value);
                                            }}
                                            aria-label="Platform"
                                            sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                            error={error && !field.value?.length}
                                        >
                                            {preferredContactMethodData?.map((options, index) => (
                                                <ToggleButton
                                                    key={index}
                                                    value={options.value}
                                                    sx={toggleButtonStyle(isLight)}
                                                >
                                                    <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                        {error && !field.value?.length &&
                                            <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                                {error?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                )}
                            />
                        </Box>
                        <FormGroup sx={{ gap: 2 }}>
                            <FormControlLabel
                                key={1}
                                control={
                                    <Controller
                                        control={control}
                                        name={'isRegister'}
                                        render={({ field }) => (
                                            <Checkbox
                                                onChange={e => {
                                                    field.onChange(e.target.checked);
                                                }}
                                                checked={field.value}
                                            />
                                        )}
                                    />
                                }
                                label={'Is registered patient'}
                                sx={{ width: 'fit-content' }}
                            />
                        </FormGroup>
                        <Typography variant="h6" sx={{ mt: 10 }}>
                            Address
                        </Typography>
                        <Divider sx={{ marginBottom: '30px' }} />
                        <Box sx={autocompleteBoxStyle}>
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Iconify icon={'eva:search-outline'} sx={{
                                            position: 'absolute',
                                            left: '15px',
                                            top: '16px'

                                        }} color="grey.500" width={24} height={24} />
                                        <Autocomplete
                                            apiKey='AIzaSyD_7FXxCWgBUUd6922n33WKqrfRDsXXxj8'
                                            onPlaceSelected={(place, inputRef) => {
                                                const addresses = place.address_components;
                                                setAddressData(addresses, inputRef.value);
                                                field.onChange(inputRef.value);
                                            }}
                                            style={{
                                                paddingLeft: '45px',
                                                fontSize: '0.875rem'
                                            }}
                                            options={{
                                                types: ['establishment', 'geocode'],
                                                fields: ['address_components', 'formatted_address'],
                                                componentRestrictions: { country: 'uk' }
                                            }}
                                            defaultValue={field.value}
                                            className='address-input'
                                            placeholder='Search address'
                                        />
                                    </>
                                )}
                            />
                        </Box>
                        {getAddressField() && <Box sx={fieldBoxStyle}>
                            <RHFTextField name="address_line_1" label="Address line 1" />
                            <RHFTextField name="address_line_2" label="Address line 2" />
                            <RHFTextField name="city" label="City" />
                            <RHFTextField name="county" label="County" />
                            <RHFTextField name="postcode" label="Postcode" />
                        </Box>}
                        <Typography variant="h6" sx={{ mt: 10 }}>
                            Lead Source
                        </Typography>
                        <Divider sx={{ marginBottom: '30px' }} />
                        <Box sx={selectBoxStyle}>
                            <RHFSelect
                                name='type'
                                label='Type'
                                value={values.type}
                                SelectProps={{ native: false, sx: selectStyle }}
                            >
                                {sourceType.map((options, index) => (
                                    <MenuItem
                                        key={index}
                                        value={options.value}
                                        onChange={(e) => handleChange(e, 'type')}
                                        sx={menuItemStyle}
                                    >
                                        {options.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <ReferralDropdown
                                name={'referral'}
                                value={referralValue('value', values)[0]?.label || ''}
                                onChange={handleReferral}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </FormProvider>
    );
});

EditPersonalDetails.propTypes = {
    handleNext: PropTypes.func,
    type: PropTypes.string,
};

export default EditPersonalDetails;