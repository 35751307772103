/* eslint-disable camelcase */
import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Button, useTheme } from '@mui/material';
import moment from 'moment';
// redux
import { useSelector } from '../../redux/store';
//  util
import { noteUpdatedHuman } from '../../utils/formatTime';
// style
import { summaryBox, summarySectionBox, summaryLabelText, summaryValueText, startedDateTypoStyle, attributeBoxStyle } from '../../styles/LeadDetailView';

const SummaryContent = ({ leadDetail, treatmentData, handleDate }) => {

    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { referral } = useSelector((state) => state.schema);
    const { lead_notes } = leadDetail;
    const transitions = treatmentData.status === 'TREATMENT_STARTED' && treatmentData?.state_transition_logs.find(item => item?.state === 'TREATMENT_STARTED');

    const showAppointmentDate = (row) => {
        const appointmentDateTime = row;
        if (appointmentDateTime) {
            const formattedDate = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('ddd DD MMM YYYY');
            const formattedTime = moment(appointmentDateTime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A');
            return `${formattedDate} at ${formattedTime}`;
        }
        return '';
    };

    return (
        <Box sx={summaryBox(isLight)}>
            <Box sx={summarySectionBox}>
                <Typography sx={summaryLabelText}> Message</Typography>
                <Typography sx={summaryValueText(isLight)}> {leadDetail && leadDetail.lead_contact && leadDetail.lead_contact.note || 'No information'}</Typography>
            </Box>
            <Box sx={summarySectionBox}>
                <Typography sx={summaryLabelText}> Contact preferences</Typography>
                <Typography sx={summaryValueText(isLight)}> {leadDetail && leadDetail.lead_contact && leadDetail.lead_contact.preferred_contact_method !== 'NO_PREFERENCE' ? leadDetail.lead_contact.preferred_contact_method : 'No information'}</Typography>
            </Box>
            <Box sx={summarySectionBox}>
                <Typography sx={summaryLabelText}> Last contacted</Typography>
                <Typography sx={summaryValueText(isLight)}>{noteUpdatedHuman(lead_notes)}</Typography>
            </Box>
            <Box sx={summarySectionBox}>
                <Typography sx={summaryLabelText}> Potential value</Typography>
                <Typography sx={summaryValueText(isLight)}> £{treatmentData && treatmentData.price || 'No information'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', padding: '16px 0', borderBottom: treatmentData.status === 'TREATMENT_STARTED' ? 'solid 1px rgba(145, 158, 171, 0.24)' : 'none' }}>
                <Typography sx={summaryLabelText}> Lead source</Typography>
                <Typography sx={summaryValueText(isLight)}>
                    {leadDetail && leadDetail.lead_source && leadDetail.lead_source.referral && referral?.map((e1) => {
                        let latestData = '';
                        if (leadDetail.lead_source.referral === e1.value) {
                            latestData = e1.label;
                        }
                        return latestData;
                    })}
                </Typography>
            </Box>
            {
                treatmentData.status === 'TREATMENT_STARTED' && <Box sx={{ display: 'flex', padding: '16px 0', borderBottom: treatmentData?.status === 'TREATMENT_STARTED' && treatmentData?.treatment === 'Invisalign' ? 'solid 1px rgba(145, 158, 171, 0.24)' : 'none' }}>
                    <Typography sx={summaryLabelText}> Started date</Typography>
                    <Button
                        onClick={handleDate}
                        sx={{ ...summaryValueText(isLight), justifyContent: 'flex-end', p: 0 }} disableRipple>
                        <Typography sx={startedDateTypoStyle(isLight)}>{treatmentData && showAppointmentDate(transitions?.timestamp) || 'No information'}</Typography>
                    </Button>
                </Box>
            }
            {treatmentData?.status === 'TREATMENT_STARTED' && treatmentData?.treatment === 'Invisalign' &&
                treatmentData?.lead_treatment_attributes?.map(attribute => (
                    <Box key={attribute.id} sx={attributeBoxStyle}>
                        <Typography sx={summaryLabelText}>{attribute.key}</Typography>
                        <Typography sx={summaryValueText(isLight)}>{attribute?.data?.[0]?.value}</Typography>
                    </Box>
                ))
            }
        </Box>
    );
};

export default SummaryContent;

SummaryContent.propTypes = {
    leadDetail: PropTypes.object,
    treatmentData: PropTypes.object,
    handleDate: () => PropTypes.func,
};