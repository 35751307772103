export const notificationList = (unread, url) => ({
    py: 1.5,
    px: 2.5,
    mt: '1px',
    bgcolor: unread && 'action.selected',
    pointerEvents: url === '' && 'none',
    cursor:  url === '' && 'not-allowed'
});

export const notificationURL = (row) => ({
    cursor : row.action_link !== '' && 'pointer',
    pointerEvents: row.action_link === '' && 'none'
});

export const tableAvatar = (row) => ({
    padding: '8px',
    bgcolor: 'background.neutral',
    cursor : row!== '' ? 'pointer' : 'default',
    pointerEvents: 'auto'
});

export const notificationHead = { py: 2, pl: 2.5, pr: 1, minHeight: 68 };

export const notificationLoaderWrapper = { display: 'flex', justifyContent: 'center', mt: 2 };
