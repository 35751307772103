import { common } from 'src/theme/palette';

export const reminderDialogStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { width: '600px', height: 'auto', borderRadius: '16px', maxHeight: '90vh', maxWidth: '95vw' },
    ' .MuiPaper-root': { width: '100%', height: 'auto', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', paddingBottom: '75px' }
};

export const leadListingBox = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { width: 'auto', height: 'auto', borderRadius: '16px', maxHeight: '90vh', maxWidth: '95vw', overflow: "auto", },
    ' .MuiPaper-root': { width: '100%', height: 'auto', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', paddingBottom: '0', overflow: "auto", }
};

export const reminderStackStyle = {
    padding: '24px',
    width: '100% ',
    maxWidth: '98vw',
    maxHeight: 'calc(100vh - 150px)',
    overflow: 'auto'
};
export const reminderFooterStyle = {
    left: '0',
    right: '0',
    position: 'absolute',
    bottom: '0',
    padding: '15px 24px',
    paddingBottom: '24px',
    width: '100%',
    zIndex: '99',
    textAlign: 'right'
};
export const sendCampaignFooterStyle = {
    left: '0',
    right: '0',
    position: 'absolute',
    bottom: '0',
    padding: '0 24px 24px',
    paddingBottom: '24px',
    width: '100%',
    zIndex: '99',
    textAlign: 'right',
};
export const cancelButtonStyle = {
    margin: '0px 15px',
    fontSize: '14px',
    boxShadow: 'none'
};
export const saveButtonStyle = {
    padding: '6px 28px',
    fontSize: '14px',
    boxShadow: common.jade
};
export const sendButtonStyle = {
    padding: '6px 11.5px',
    fontSize: '14px',
    boxShadow: common.jade
};

// assign 
export const assignTaskDialogStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { width: '600px', height: 'auto', borderRadius: '16px', maxHeight: '90vh', maxWidth: '95vw' },
    ' .MuiPaper-root': { width: '100%', height: 'auto', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', paddingBottom: '75px' }
};
export const sendCampaignDialogStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { width: '633px', height: 'auto', borderRadius: '16px', maxHeight: '90vh', maxWidth: '95vw' },
    ' .MuiPaper-root': { width: '100%', height: 'auto', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', paddingBottom: '60px' }
};
export const eventBoxStyle = {
    textAlign: 'center',
    borderRight: '1px solid rgba(145, 158, 171, 0.24)',
    px: 1,
    width: '100%'
};
export const viewLeadsTextWrapper = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 0 24px'
};
export const assignTaskBoxStyle = (grey) => ({
    bgcolor: grey[0],
    zIndex: '9999999',
    position: 'relative',
    width: '800px',
    borderRadius: 2,
    boxShadow: `0 0 20px ${grey[300]}`,
    border: `solid 1px ${grey[400]}`,
    '@media (max-width: 860px)': {
        width: '580px',
    },
    '@media (max-width: 600px)': {
        width: '450px',
    },
    '@media (max-width: 479px)': {
        width: '320px',
    }
});
export const clinCheckBoxStyle = (grey) => ({
    bgcolor: grey[0],
    zIndex: '9999999',
    position: 'relative',
    borderRadius: 2,
    boxShadow: `0 0 20px ${grey[300]}`,
    border: `solid 1px ${grey[400]}`
});
export const addReminderCancelButtonStyle = {
    margin: '0px 15px',
    fontSize: '15px',
    boxShadow: 'none',
    minWidth: '200px',
    minHeight: '48px',
    borderRadius: 1
};
export const addRemindersaveButtonStyle = {
    padding: '6px 28px',
    fontSize: '15px',
    boxShadow: common.jade,
    minWidth: '200px',
    minHeight: '48px',
    textTransform: 'none !important',
    borderRadius: 1
};
export const addReminderStackStyle = {
    padding: '24px 0 24px',
    width: '100% ',
    maxWidth: '600px',
    maxHeight: 'calc(100vh - 150px)',
    overflow: 'auto'
};

export const subtitleTypoStyle = { fontSize: '24px', fontWeight: 700 };
export const bodyTypoStyle = { fontSize: '14px', fontWeight: 700, color: 'grey.600' };

export const leadListPopupStackStyle = { ...reminderStackStyle, width: '95vw', overflow: 'auto', maxHeight: 'calc(100vh - 100px)' };