import { createSlice } from '@reduxjs/toolkit';
// utils
import { callApi } from '../../utils/apiCall';
//
import { dispatch, store } from '../store';

// ---------------------------------------------------------------------
const initialState = {
    isLoading: false,
    practiceTreatment: {
        results: []
    },
    practiceId: localStorage.getItem('practiceId')
};


const slice = createSlice({
    name: 'practiceTreatmentList',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        getPracticeTreatmentData(state, action) {
            state.isLoading = false;
            state.practiceTreatment = action.payload;
        },
        addMorePracticeTreatmentData(state, action) {
            state.isLoading = false;
            state.practiceTreatment.results = [
                ...state.practiceTreatment.results,
                ...action.payload
            ];
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        updatePracticeId(state, action) {
            localStorage.setItem('practiceId', action.payload);
            state.practiceId = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { updatePracticeId, reset, getPracticeTreatmentData } = slice.actions;

export function getPracticeTreatmentListData(callback) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi('/api/v1/practices/?ordering=name&fields={id,name,logo}');
            const { practiceId } = store.getState().practiceTreatmentList;
            if(response.status === 200) {
                dispatch(slice.actions.getPracticeTreatmentData(response?.data));
                if ((response.data && response.data.results.length > 0) && !practiceId) {
                    const PracticeId = response.data.results[0].id;
                    dispatch(slice.actions.updatePracticeId(PracticeId));
                }
                if (response.data && response.data.results && response.data.results.length <= 0) {
                    callback();
                }
            } else {
                dispatch(slice.actions.hasError(response.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}