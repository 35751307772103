import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemAvatar, ListItemText } from '@mui/material';
import { Iconify } from 'src/components';
import { communicationCounterBGStyle, communicationCounterTextStyle } from 'src/styles/LeadDetailView';

const CustomLabelCount = ({ data, autoMatedMessages, leadDetail }) => {

    const tabCounter = (value) => {
        switch (value) {
            case 'Chatbot':
                return 0;
            case 'Automations':
                return (autoMatedMessages && autoMatedMessages?.count) || 0;
            case 'Call Script':
                return (leadDetail && leadDetail?.form_submissions?.length) || 0;
            default:
                return 0;
        }
    };

    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <ListItemAvatar sx={{ mr: 0 }}>
                <Box sx={{ lineHeight: 0 }}>
                    <Iconify icon={`${data.icon}`} sx={{ width: '24px', height: '24px' }} />
                </Box>
            </ListItemAvatar>
            {(
                <>
                    {!data.isNotText && <ListItemText
                        primary={data.value}
                        primaryTypographyProps={{ noWrap: true, variant: 'subtitle2' }}
                        sx={{ textTransform: 'capitalize', textAlign: 'left' }}
                    />}
                    {(data.value !== 'Email' && data.value !== 'Chatbot' && tabCounter(data.value) !== 0) && <Box sx={communicationCounterBGStyle}>
                        <Box
                            sx={communicationCounterTextStyle}>
                            {tabCounter(data.value)}
                        </Box>
                    </Box>}
                </>
            )}
        </Box>
    );
};

CustomLabelCount.propTypes = {
    leadDetail: PropTypes.object,
    data: PropTypes.object,
    autoMatedMessages: PropTypes.object,
};

export default CustomLabelCount;