import { Button } from '@mui/material';
import { Iconify } from '../components';
import { statusStyle } from '../styles/OverView';

export const getStatusButton = (status) => {
    const icon = {
        NEW: 'mdi:question-mark-circle-outline',
        CONSULTATION_BOOKED: 'mdi:calendar-clock',
        FAILED_TO_ATTEND_CONSULTATION: 'bx:calendar-x',
        CONSULTATION_ATTENDED: 'mdi:calendar-check',
        CLOSED: 'material-symbols:check-circle-outline',
        TREATMENT_STARTED: 'codicon:debug-start',
        default: 'fluent:cloud-sync-complete-24-filled',
    }[status] || 'fluent:cloud-sync-complete-24-filled';

    const getStatusLabel = (status) => ({
        NEW: 'New',
        CONSULTATION_BOOKED: 'Consultation Booked',
        FAILED_TO_ATTEND_CONSULTATION: 'Failed to Attend Consultation',
        CONSULTATION_ATTENDED: 'Consultation Attended',
        CLOSED: 'Completed',
        TREATMENT_STARTED: 'Treatment Started',
        default: 'Status',
    }[status] || 'Status');

    return (
        <Button sx={statusStyle(status)}>
            <Iconify icon={icon} width={16} height={16} /> {getStatusLabel(status)}
        </Button>
    );
};
