import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, InputAdornment, TextField, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Iconify } from '..';
import { SearchNotFound } from '../search-not-found';
import { useSelector } from '../../redux/store';
import { testFieldWrapperStyle } from '../../styles/CallScripts';
import { leadAutoComplete, searchIconStyle } from '../../styles/AddReminderStyle';

const CustomLeadNameAutocomplete = ({ control, searchQuery, handleSearchLead, searchLeadHandler, disabled }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const { taskSearchLead, isLoading } = useSelector((state) => state.lead);

    const noOptionsHandler = () => {
        if (searchQuery === '' || taskSearchLead && taskSearchLead.length === 0) {
            return <SearchNotFound searchQuery={searchQuery} />;
        }
        return '';
    };

    return (
        <Box sx={testFieldWrapperStyle}>
            <Controller
                name={'leadName'}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                        {...field}
                        autoFocus
                        disabled={disabled}
                        name={'leadName'}
                        inputValue={searchQuery}
                        value={searchQuery}
                        clearIcon={false}
                        options={taskSearchLead && taskSearchLead?.length > 0 ? taskSearchLead : []}
                        filterOptions={(option) => option}
                        loading={isLoading}
                        sx={{ ...leadAutoComplete, mb: 3 }}
                        onInputChange={searchLeadHandler}
                        noOptionsText={noOptionsHandler()}
                        onChange={handleSearchLead}
                        isOptionEqualToValue={(option, value) => option?.first_name === value}
                        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                        renderOption={(props, option) => {
                            const { first_name: firstName, last_name: lastName, lead_contact: leadContact } = option || {};
                            const { email, phone } = leadContact || {};
                            return (
                                <Box component='li' {...props} key={option.id} >
                                    {firstName || ''} {lastName || ''} | {email || ''} | {phone || ''}
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!error}
                                helperText={(searchQuery?.length === 0 && error) && 'This field is required'}
                                placeholder='Lead'
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Iconify icon='uil:search' sx={searchIconStyle(isLight)} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                )}
            />
        </Box>
    );
};

export default CustomLeadNameAutocomplete;

CustomLeadNameAutocomplete.propTypes = {
    control: PropTypes.object,
    searchQuery: PropTypes.string,
    handleSearchLead: PropTypes.func,
    searchLeadHandler: PropTypes.func,
    setSearchQuery: PropTypes.func,
    disabled: PropTypes.bool,
};