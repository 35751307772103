import React from 'react';
import DepositDateCell from './DepositDateCell';

const CustomDepositDateCell = (handleSetTableData) => ({
    field: 'deposit_date',
    headerName: 'Deposit date',
    minWidth: 200,
    sortable: false,
    renderCell: (params) => (
        <DepositDateCell
            rowData={params.row}
            handleSetTableData={handleSetTableData}
        />
    ),
});

export default CustomDepositDateCell;