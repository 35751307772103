/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
    TableRow,
    TableCell,
    Typography,
    Switch,
    MenuItem,
    Divider
} from '@mui/material';
import { useSelector } from 'react-redux';
//
import { ConfirmDelete } from '../../sections/@dashboard/practiceTreatment';
// use components
import { Label, Iconify } from '..';
import { TableMoreMenu } from '../table';
// constant
import { INTERVAL } from '../../constants/analyticsTable';
// hooks
import { useToggle } from '../../hooks';

const ScheduleSettingRow = ({ row, selectRow }) => {
    const { reportType } = useSelector((state) => state.schema);
    const [openMenu, setOpenMenuActions] = useState(null);

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const onDeleteRow = () => {
        selectRow(row, 'delete');
    };

    const handleSwitch = (data, row) => {
        const payload = {
            ...row,
            is_enabled: data
        };
        selectRow(payload, 'toggle');
    };

    return (
        <TableRow hover sx={{ cursor: 'pointer', borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{reportType?.find(e => e.value === row.report_type).label}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <Label sx={{ backgroundColor: 'rgba(84, 214, 44, 0.16)', color: '#229A16' }}>
                        {INTERVAL.find(e => e.value === row.interval).label}
                    </Label>
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <Switch
                        checked={row.is_enabled}
                        onClick={(e) => handleSwitch(e.target.checked, row)}
                        color="primary"
                    />
                </Typography>
            </TableCell>

            <TableCell align='right'>
                <TableMoreMenu
                    open={openMenu}
                    onOpen={(e) => setOpenMenuActions(e.currentTarget)}
                    onClose={handleCloseMenu}
                    actions={
                        <>
                            <MenuItem onClick={() => { selectRow(row, 'update'); setOpenMenuActions(null); }}>
                                <Iconify icon={'solar:pen-bold'} />
                                Edit
                            </MenuItem>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            <MenuItem
                                onClick={onOpenContacts}
                                sx={{ color: 'error.main' }}
                            >
                                <Iconify icon={'solar:trash-bin-trash-bold'} />
                                Delete
                            </MenuItem>
                            <ConfirmDelete
                                data={'Are You sure want to delete ? '}
                                onDeleteRow={onDeleteRow}
                                handleCloseMenu={handleCloseMenu}
                                open={openContacts}
                                onClose={onCloseContacts}
                            />
                        </>
                    }
                />
            </TableCell>
        </TableRow>
    );
};

export default ScheduleSettingRow;

ScheduleSettingRow.propTypes = {
    row: PropTypes.object,
    selectRow: PropTypes.func
};
