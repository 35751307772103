import { createSlice } from '@reduxjs/toolkit';
// utils
import { callApi } from '../../utils/apiCall';
import { updateError } from './user';
//
import { dispatch } from '../store';

// ------------------------------

const initialState = {
    isLoading: false,
    tracker: {}
};

const slice = createSlice({
    name: 'financialTracker',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setTracker(state, action) {
            state.tracker = action.payload;
        }
    }
});

export default slice.reducer;

export const { setTracker, reset } = slice.actions;

export function getFinancialTrackerData(practiceId, limit, offset, data, handleClose) {
    return async () => {
        try {
            const status = data?.status ? `&status__in=${data.status}` : '';
            const term = data?.term ? `&term__in=${data.term}` : '';
            const response = await callApi(`/api/v1/practices/${practiceId}/loans/?limit=${limit}&offset=${offset}&ordering=-created_at${status}${term}`);
            if (response.status === 200) {
                dispatch(slice.actions.setTracker(response.data));
                if (handleClose) {
                    handleClose(response.data.count);
                }
            } else {
                dispatch(updateError({ success: true, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function addFinancialTrackerData(practiceId, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loans/`, 'post', data);
            if (response.status === 201) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Financial Tracker added successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function updateFinancialTrackerData(practiceId, id, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loans/${id}/`, 'patch', data);
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Financial Tracker updated successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function deleteFinancialTrackerData(practiceId, id, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loans/${id}/`, 'delete');
            if (response.status === 204) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Financial Tracker deleted successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function updateFinancialStatus(practiceId, id, status, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loans/${id}/${status}/`, 'post', data);
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ success: true, message: ' Status updated successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}



