import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemIcon, MenuItem, Select } from '@mui/material';
import { isEmpty } from 'lodash';
import { Iconify } from '../../../components';
import { apptMethodSelectStyle } from '../../../styles/NewLeadTableRowStyle';

const TreatmentIconChangePopup = ({ open, iconList, value, onChange, handleClose, openTreatmentIconPopup, style = {} }) =>
    <Box sx={{ ...style }}>
        <Select
            open={open}
            onClose={handleClose}
            onOpen={openTreatmentIconPopup}
            value={value || 'icons8:plus'}
            onChange={onChange}
            fullWidth
            displayEmpty
            inputProps={{ 'aria-label': 'Icon' }}
            renderValue={(value) => (
                <ListItemIcon>
                    <Iconify
                        icon={value}
                        width={24}
                        height={24}
                    />
                </ListItemIcon>
            )}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                PaperProps: {
                    style: {
                        maxHeight: 200,
                    },
                },
            }}
            IconComponent={() => null}
            sx={apptMethodSelectStyle}
        >
            {iconList.map((icon) => (
                <MenuItem
                    key={icon}
                    value={icon}
                >
                    <Iconify
                        icon={icon}
                        width={24}
                        height={24}
                    />
                </MenuItem>
            ))}
            {!isEmpty(value) && <MenuItem
                value={''}
            >
                Remove Icon
            </MenuItem>}
        </Select>
    </Box>;

export default TreatmentIconChangePopup;

TreatmentIconChangePopup.propTypes = {
    open: PropTypes.bool,
    iconList: PropTypes.any,
    value: PropTypes.string,
    onChange: PropTypes.func,
    handleClose: PropTypes.func,
    openTreatmentIconPopup: PropTypes.func,
    style: PropTypes.any,
};