import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Popover } from '@mui/material';
import { pickerButtonStyle, colorBoxStyle, colorBoxWrapper, colorBoxWrapperParent } from '../styles/customColorPickerStyle';
import { Iconify } from './index';

const CustomColorPicker = ({ open, colorList, value, onChange, anchorEl, handleClose, openColorPicker, style = {} }) => {
    const id = open ? 'simple-popover' : undefined;
    return <Box sx={{ ...style }}>
        {value ? <Box sx={{ ...pickerButtonStyle(value) }} onClick={openColorPicker} />
            : <Iconify icon='icons8:plus' width={24} height={24} onClick={openColorPicker} sx={{ cursor: 'pointer' }} />}
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={colorBoxWrapperParent}>
                <Box sx={colorBoxWrapper}>
                    {colorList.map((ele, index) => <Box key={index} sx={{ ...colorBoxStyle(ele) }} onClick={() => onChange(ele)} />)}
                </Box>
                {value && <Button variant="contained" sx={{ width: '100%', my: 1, fontSize: '11px' }} onClick={() => onChange('')}>Remove color</Button>}
            </Box>
        </Popover>
    </Box>;
};

export default CustomColorPicker;

CustomColorPicker.propTypes = {
    open: PropTypes.bool,
    colorList: PropTypes.any,
    value: PropTypes.string,
    onChange: PropTypes.func,
    handleClose: PropTypes.func,
    openColorPicker: PropTypes.func,
    anchorEl: PropTypes.any,
    style: PropTypes.any,
};