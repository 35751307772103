import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ButtonGroup } from '@mui/material';
import Iconify from '../Iconify';
import { followUpTabStyle, followUpButtonGroupStyle, followUpCustomButtonStyle, followUpHourButtonStyle, followUpTomorrowButtonStyle, followUpWeekButtonStyle, followUpTwoWeekButtonStyle, followUpNoReminderButtonStyle } from '../../styles/DialogueTabs';

const FollowUpButtonGroup = ({ isMobile, activeTime, handleButtonClick }) => (
    <Box sx={{ ...followUpTabStyle(isMobile), margin: '-10px 0 30px' }}>
        <ButtonGroup
            orientation={!isMobile ? 'vertical' : 'horizontal'}
            sx={{ ...followUpButtonGroupStyle, display: 'inline-block', borderColor: 'common.darkGrey' }}
        >
            <Button
                variant={activeTime === 'custom reminder' ? 'contained' : 'text'}
                onClick={() => handleButtonClick('custom reminder')}
                sx={followUpCustomButtonStyle(activeTime)}
            >
                <Iconify sx={{ marginRight: '8px' }} icon={'solar:clock-circle-bold'} width={22} height={22} /> Custom reminder
            </Button>
            <Button
                variant={activeTime === '2 hours-call' ? 'contained' : 'text'}
                onClick={() => handleButtonClick('2 hours-call')}
                sx={followUpHourButtonStyle(activeTime)}
            >
                <Iconify sx={{ marginRight: '8px' }} icon={'fluent:call-32-filled'} width={22} height={22} /> Call in 2 hours
            </Button>
            <Button
                variant={activeTime === 'tomorrow' ? 'contained' : 'text'}
                onClick={() => handleButtonClick('tomorrow')}
                sx={followUpTomorrowButtonStyle(activeTime)}
            >
                <Iconify sx={{ marginRight: '8px' }} icon={'fluent:call-32-filled'} width={22} height={22} /> Call 9am tomorrow
            </Button>
            <Button
                variant={activeTime === 'week' ? 'contained' : 'text'}
                onClick={() => handleButtonClick('week')}
                sx={followUpWeekButtonStyle(activeTime)}
            >
                <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 1 week
            </Button>
            <Button
                variant={activeTime === 'two-week' ? 'contained' : 'text'}
                onClick={() => handleButtonClick('two-week')}
                sx={followUpTwoWeekButtonStyle(activeTime)}
            >
                <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 week
            </Button>
            <Button
                variant={activeTime === 'no-reminder' ? 'contained' : 'text'}
                onClick={() => handleButtonClick('no-reminder')}
                sx={followUpNoReminderButtonStyle(activeTime)}
            >
                <Iconify sx={{ marginRight: '8px' }} icon={'bxs:phone-off'} width={25} height={25} /> No reminder
            </Button>
        </ButtonGroup>
    </Box>
);

FollowUpButtonGroup.propTypes = {
    isMobile: PropTypes.bool,
    activeTime: PropTypes.string,
    handleButtonClick: PropTypes.func,
};

export default FollowUpButtonGroup;
