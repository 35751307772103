import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { updateError } from './user';
import { dispatch } from '../store';

const initialState = {
    isLoading: false,
    whatsappAccountData: null,
    whatsappAccountPhonesData: null
};

const slice = createSlice({
    name: 'whatsappAccount',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        startLoading(state) {
            state.isLoading = true;
        },
        endLoading(state) {
            state.isLoading = false;
        },
        getAccountList(state, action) {
            state.isLoading = false;
            state.whatsappAccountData = action.payload;
        },
        getAccountPhonesList(state, action) {
            state.isLoading = false;
            state.whatsappAccountPhonesData = action.payload;
        },
    }
});

export default slice.reducer;

export const { clearMessage, reset, updateSubjectTemplateList, updateShowSubjectList } = slice.actions;

export function getWhatsappAccount(id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${id}/whatsapp-business-accounts/`);
            if (response.status === 200) {
                dispatch(slice.actions.getAccountList(response.data));
                dispatch(slice.actions.endLoading());
            } else {
                dispatch(slice.actions.hasError(response.data.detail || 'Something went wrong'));
                dispatch(slice.actions.endLoading());
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message || 'Network error'));
        } finally {
            dispatch(slice.actions.endLoading());
        }
    };
}

export function getWhatsappAccountPhones({ practiceId, id, handleSuccess }) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/whatsapp-business-accounts/${id}/whatsapp-phones/`);
            if (response.status === 200) {
                dispatch(slice.actions.getAccountPhonesList(response.data));
                handleSuccess({ success: true, phoneId: response.data.results });
                return;
            }
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            handleSuccess();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function whatsappMessageSend({ practiceId, id, phoneId, messageData, handleSuccess, leadInfo }) {
    return async () => {
        try {
            const data = { ...messageData };
            let context = {};
            const respContextPractice = await callApi(
                `/api/v1/practices/${practiceId}/get_context/`,
                'GET'
            );
            const respContextLead = await callApi(
                `/api/v1/practices/${practiceId}/leads/${leadInfo?.id}/get_context/`,
                'GET'
            );
            const responseTemplateForLead = await callApi(`/api/v1/practices/${practiceId}/leads/${leadInfo?.id}/treatments/`);
            const templateIdList = responseTemplateForLead?.data?.results?.map(dt => dt?.id) ?? [];
            let treatmentContext = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const treatmentId of templateIdList) {
                // eslint-disable-next-line no-await-in-loop
                const treatMentContextResponse = await callApi(`/api/v1/practices/${practiceId}/leads/${leadInfo?.id}/treatments/${treatmentId}/get_context/`);
                treatmentContext = { ...treatmentContext, ...treatMentContextResponse?.data };
            }
            context = {
                ...context, ...(respContextPractice?.data ?? {}), ...(respContextLead?.data ?? {}), ...(treatmentContext ?? {})
            };
            data.template_context = context;
            data.lead_id = leadInfo?.id;

            const response = await callApi(`/api/v1/practices/${practiceId}/whatsapp-business-accounts/${id}/whatsapp-phones/${phoneId}/send_message/`, 'post', data);
            if (response?.status === 200) {
                handleSuccess({ success: true });
                return;
            }
            dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            handleSuccess();
        } catch (error) {
            handleSuccess();
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}