import React from 'react';
import DepositPendingField from '../DepositPendingField';

const DepositPendingCell = ({
    updateLeadPage,
    reloadCounter,
    typed,
    handleSetTableData
}) => ({
    field: 'deposit_pending',
    headerName: 'Deposit Paid?',
    width: 140,
    minWidth: 100,
    sortable: false,
    renderCell: (params) => (
        <DepositPendingField
            rowData={params.row}
            updateLeadPage={updateLeadPage}
            reloadCounter={reloadCounter}
            tableType={typed}
            handleSetTableData={handleSetTableData}
        />
    ),
});

export default DepositPendingCell;
