import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
    pass: null,
    cpass: null,
    code: null,
    email: null
};

export const slice = createSlice({
    name: 'forgotpassword',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        password(state, action) {
            state.pass = action.payload;
            state.cpass = action.payload;
            state.code = action.payload;
            state.email = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
    },
});

export const { password, reset } = slice.actions;

export const changePassword = (state) => state.pass.pass;

export default slice.reducer;

export async function forgotPassword(passData) {
    try {
        const response = await axios.post('/api/v1/auth/password/reset/', passData);
        dispatch(slice.actions.password(response.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}