import PropTypes from 'prop-types';
// @mui
import { Card, Typography } from '@mui/material';
// components
import { AutoMessageAccordian } from '../../../components';
// constants
import { messageAccordians } from '../../../constants/AutomatedMessagesConst';
// style
import { cardStyle } from '../../../styles/AutoMessageAccordianStyle';

// -------------------------------------------------------------------------------------------

const AutomatedMessages = ({ sentTasks, upcommingTask, cancelTask, failedTasks, onUpdateStatus }) => (
    <Card sx={cardStyle}>
        <Typography variant='h6' >Message Log </Typography>
        <p style={{ margin: '24px 0px', color: '#637381', lineHeight: '22px'}}> These are the automations that will be sent to the lead. You can turn them off by toggling the buttons.</p>
        {messageAccordians(sentTasks, upcommingTask, cancelTask, failedTasks).map((data) => (
            <AutoMessageAccordian key={data.value} Heading={data} sentTasks={sentTasks} cancelTask={cancelTask} upcommingTask={upcommingTask} failedTasks={failedTasks} onUpdateStatus={onUpdateStatus} />
        ))}
    </Card>
);

AutomatedMessages.propTypes = {
    sentTasks: PropTypes.array,
    upcommingTask: PropTypes.array,
    cancelTask: PropTypes.array,
    failedTasks: PropTypes.array,
    onUpdateStatus: PropTypes.func
};

export default AutomatedMessages ;
