import React, { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import PropTypes from 'prop-types';
import { Box, Button, Divider, TextField, Typography, useTheme } from '@mui/material';
import { error } from 'src/theme/palette';
import { dispatch, useSelector } from '../../redux/store';
import { getPracticeTreatmentListV2 } from '../../redux/slices/practiceTreatment';
import { btnStyle, commonTypoStyle } from '../../styles/treatmentFlowComponentsStyle';
import { ErrorTextStyle, AmountInputStyle } from '../../styles/PaymentRefund';

const TreatmentFlowComponent = ({ treatmentData, teethAmount, handleTreatment, amount, errorMessage, amountError, amountHandleChange, handleChangeTeeth, teethAmountError }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { treatmentsListV2 } = useSelector((state) => state.practiceTreatment);
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [decimalSeparator] = useState('.');
    const [thousandSeparator] = useState(',');
    const [inputType] = useState('text');
    const treatmentWhichNeedsTeethCount = ['Veneers', 'Composite Bonding'];
    const isTeethFieldNeeded = () => (treatmentWhichNeedsTeethCount?.includes(treatmentData) || treatmentsListV2?.results?.find(el => el.treatment_item.name === treatmentData)?.treatment_item?.treatment_category?.name === 'Implants');

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: 30,
                offset: 0,
            };
            dispatch(getPracticeTreatmentListV2(data));
        }
    }, [practiceId]);

    return (
        <>{treatmentData ?
            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }} >
                <Box sx={{ padding: '10px 60px 10px 0' }}>
                    <Typography variant='h6' sx={{ fontWeight: 600 }}>Treatment name</Typography>
                    <Typography variant='h4' sx={commonTypoStyle}>{treatmentData || ''}</Typography>
                </Box>
                <Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />
                {
                    (isTeethFieldNeeded()) && <>
                        <Box sx={{ padding: '10px 60px' }}>
                            <Typography variant='h6' sx={{ fontWeight: 600 }}>Teeth amount</Typography>
                            <Box sx={{ mt: '4px', maxWidth: '150px' }}>
                                <TextField
                                    fullWidth
                                    onChange={handleChangeTeeth}
                                    value={teethAmount || ''}
                                    name="teethAmount"
                                    type="number"
                                    placeholder="Teeth amount"
                                />
                                {teethAmountError && <Typography variant='p' sx={ErrorTextStyle}>Teeth amount should not be less then 1</Typography>}
                            </Box>
                        </Box>
                        <Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />
                    </>
                }
                <Box sx={{ padding: '10px 60px' }}>
                    <Typography variant='h6' sx={{ fontWeight: 600 }}>Price</Typography>
                    <Box sx={{ mt: '4px', maxWidth: '150px' }}>
                        <CurrencyInput
                            name='treatmentValue'
                            value={amount}
                            decimalSeparator={decimalSeparator}
                            thousandSeparator={thousandSeparator}
                            onValueChange={(value, name) => amountHandleChange(value, name)}
                            className='currency-input'
                            decimalsLimit={2}
                            decimalScale={2}
                            inputType={inputType}
                            prefix={'£ '}
                            style={AmountInputStyle(amountError, 'appointment', isLight)}
                            step={1}
                        />
                        {amountError && <Typography variant='p' sx={ErrorTextStyle}>This field is required</Typography>}
                    </Box>
                </Box>
                <Divider orientation={'vertical'} flexItem sx={{ borderStyle: 'dashed' }} />
                <Box sx={{ padding: '10px 20px' }}>
                    <Typography variant='h6' sx={{ opacity: 0, mb: '4px' }}>Treatment</Typography>
                    <Button sx={btnStyle} onClick={handleTreatment}>Edit treatment</Button>
                </Box>
            </Box > :
            <Box>
                <Button sx={btnStyle} onClick={handleTreatment}>Add treatment</Button>
                {!!errorMessage && <Typography sx={{ color: error.main, fontSize: '14px', mt: 1 }}>{errorMessage}</Typography>}
            </Box>}
        </>
    );
};

TreatmentFlowComponent.propTypes = {
    treatmentData: PropTypes.string,
    teethAmount: PropTypes.number,
    handleTreatment: PropTypes.func,
    amount: PropTypes.number,
    errorMessage: PropTypes.string,
    amountError: PropTypes.bool,
    amountHandleChange: PropTypes.func,
    handleChangeTeeth: PropTypes.func,
    teethAmountError: PropTypes.bool,
};

export default TreatmentFlowComponent;
