export const reportLeadStatuses = 'lead_treatments__status';
export const reportLeadStatusesV2 = 'lead_treatments__state_transition_logs__state';
export const treatmentCategory = 'lead_treatments__treatment__treatment_item__treatment_category';
export const leadSourceType = 'lead_source__type';
export const leadSourceReferral = 'lead_source__referral';
export const isLeadArchived = 'is_archived';
export const ReportTreatmentId = 'lead_treatments__treatment_item__name__in';
export const AllLeadData = 'totalLead';
export const AllTreatments = 'totalTreatments';
export const categorySummary = 'categorySummary';
export const sourceTypeSummary = 'sourceTypeSummary';
export const referralSummary = 'referralSummary';
export const archiveSummary = 'archiveSummary';
export const leadArchive = 'lead_notes__note';

export const NOTBOOKED_AND_ENQUIRY_COLUMNS = [
    { groupByFilter: true, isNotBookedTable: true },
    { groupByFilter: true, type: 'enquiry' },
];