import { common, grey } from 'src/theme/palette';

/* eslint-disable no-nested-ternary */
export const PaymentTableStyle = {
    position: 'relative',
    maxHeight: '420px',
    '.MuiTableCell-root': {
        boxShadow: 'none !important',
        pl: '16px !important'
    }
};
export const CheckoutBoxStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
};
export const BackButtonStyle = {
    width: '40px',
    height: '40px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    minWidth: '40px',
    mb: '20px'
};
export const tableAmountInputStyle = (error, type, isLight) => ({
    width: type === 'paymentOption' ? '30%' : '100%',
    marginBottom: '0',
    padding: '17px 14px',
    borderRadius: '8px',
    typography: 'body2',
    textTransform: 'capitalize',
    borderWidth: '0',
    border: 'none',
    fontSize: '15px',
    color: error ? 'common.red' : isLight ? grey[800] : 'common.white',
    background: 'transparent',
    '@media(max-width: 600px)': {
        width: type === 'paymentOption' ? '50%' : '100%',
    }
});
export const TitleStyle = {
    fontSize: '18px !important',
    mb: '20px',
    ml: '20px'
};
export const RefundButtonStyle = {
    bgcolor: `${common.blue}!important`,
    boxShadow: '0px 8px 16px rgb(51 102 255 / 24%)',
    color: common.white,
    padding: '6px 16px'
};
export const disableRefundButtonStyle = {
    bgcolor: `${common.blue}!important`,
    boxShadow: '0px 8px 16px rgb(51 102 255 / 24%)',
    color: common.white,
    padding: '6px 16px',
    opacity: '0.5',
    pointerEvents: 'none',
    cursor: 'default'
};
export const RefundBoxStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: '20px'
};
export const RefundBackButtonStyle = {
    width: '40px',
    height: '40px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    minWidth: '40px',
    mb: '0'
};
export const RefundTitleStyle = {
    fontSize: '18px !important',
    mb: '0',
    ml: '20px'
};
export const RetryButtonStyle = {
    bgcolor: `${common.mintyZest}!important`,
    boxShadow: '0 8px 16px 0 rgb(0 171 85 / 12%)',
    color: common.white,
    padding: '6px 16px'
};
export const RefundPaymentDialogStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { width: '448px', height: 'auto', borderRadius: '16px', maxHeight: '90vh', maxWidth: '95vw' },
    ' .MuiPaper-root': { width: '100%', height: 'auto', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', paddingBottom: '75px' }
};
export const RefundPaymentStackStyle = {
    padding: '0',
    width: '100% ',
    maxWidth: '98vw',
    maxHeight: 'calc(100vh - 150px)',
    overflow: 'auto'
};
export const RefundPaymentFooterStyle = {
    left: '0',
    right: '0',
    position: 'absolute',
    bottom: '0',
    padding: '20px 20px',
    paddingBottom: '20px',
    width: '100%',
    zIndex: '99',
    textAlign: 'right'
};
export const CancelButtonStyle = {
    margin: '0px 15px',
    fontSize: '14px',
    boxShadow: 'none'
};
export const SaveButtonStyle = {
    padding: '6px 28px',
    fontSize: '14px',
    boxShadow: common.jade
};
export const SelectMenuItemStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};
export const AddRefundBoxStyle = {
    width: '100%',
    pt: '0',
    textAlign: 'left'
};
export const AddRefundTitleStyle = {
    fontSize: '18px',
    padding: '20px',
    borderBottom: 'solid 2px rgba(145, 158, 171, 0.12)'
};
export const InfoBoxStyle = {
    padding: '12px 20px',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.12)',
    display: 'flex',
    bgcolor: '#f7f7f7'
};
export const InfoIconStyle = {
    mr: '10px',
    minWidth: '16px',
    position: 'relative',
    top: '4px'
};
export const InfoTextStyle = {
    marginBottom: '0',
    fontSize: '14px',
    color: grey[600],
    display: 'inline-block',
    lineHeight: '22px'
};
export const ErrorTextStyle = {
    width: '100%',
    fontSize: '0.75rem',
    color: common.tomatoRed,
    mt: '8px',
    display: 'flex'
};
export const AmountInputStyle = (error, type, isLight) => ({
    width: type === 'paymentOption' ? '30%' : '100%',
    marginBottom: '0',
    padding: '17px 14px',
    borderRadius: '8px',
    typography: 'body2',
    textTransform: 'capitalize',
    borderColor: error ? common.tomatoRed : 'rgba(145, 158, 171, 0.32)',
    borderWidth: '1px',
    borderStyle: 'solid',
    fontSize: '15px',
    color: error ? common.tomatoRed : isLight ? grey[800] : common.white,
    background: 'transparent',
    '@media(max-width: 600px)': {
        width: type === 'paymentOption' ? '50%' : '100%',
    }
});

export const depositAmountLabelType = {
    lineHeight: '1.5',
    fontSize: '1rem',
    fontWeight: 600,
    color: grey[600],
    transform: 'translate(14px, -9px) scale(0.75)',
    position: 'absolute',
    background: grey[0],
    padding: '0 3px',
};