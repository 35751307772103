import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// lib
import { Accordion, Avatar, Box, Divider, IconButton, Link, Stack, Typography, AccordionDetails, AccordionSummary, ListItemText } from '@mui/material';
import { useDebounce } from 'use-lodash-debounce';
import { uniqBy } from 'lodash';
// components
import { Iconify } from '../../../../components';
import { EmptyContent } from './empty-content';
import ReplyEditor from './replyEditor';
import AddLabelDialog from './labelAddDialog';
import MailCompose from './mailCompose';
import CustomThreadLabels from './CustomThreadLabels';
import CustomCreateLabelPopover from './CustomCreateLabelPopover';
import MailButtons from './MailButtons';
// redux
import { store, useSelector } from '../../../../redux/store';
import { updateError } from '../../../../redux/slices/user';
import { getInboxLabels } from '../../../../redux/slices/mail';
import { updateShowSubjectList } from '../../../../redux/slices/practiceMessage';
// utils
import { addThreadLabel, deleteThreadLabel } from '../../../../utils/mailUtils';
// styles
import { mailAccordionStyle, mailAccordionSummaryStyle, mailLabelWrapper } from './styles';

const MailDetails = ({ onStarClick, mail, mailType, selectedMailId, leadDetail, isFromSideBar, handleBackClicked, handleMarkReadEvent }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { selectedMailInfo, threadLabels, inboxLabels } = useSelector((state) => state.mail);
    const { dispatch } = store;
    const [files, setFiles] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const hiddenFileInput = useRef(null);
    const [fileSize, setFileSize] = useState({});
    const [base, setBase] = useState('');
    const [isClear, setIsClear] = useState(false);
    const [openAddLabel, setOpenAddLabel] = useState(false);
    const { practice } = useSelector((state) => state.practice);
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const debouncedValue = useDebounce(isProcessing, 300);

    const handleAccordionChange = (index) => {
        setExpandedIndex(index === expandedIndex ? null : index);
        setMsg('');
        setFiles([]);
        setFileSize();
        setAttachment([]);
        setIsClear(!isClear);
    };

    const [msg, setMsg] = useState();
    const [anchorEl, setAnchorEl] = useState();
    const [expandedIndex, setExpandedIndex] = useState(mail?.length > 1 ? mail?.length - 1 : 0);

    useEffect(() => {
        setExpandedIndex(mail?.length > 1 ? mail?.length - 1 : 0);
        if (mailType === 'Drafts') {
            setMsg(mail?.body);
        }
    }, [mail]);

    useEffect(() => {
        if (mailType === 'Drafts') {
            setMsg(mail?.body);
        }
    }, [mailType]);

    useEffect(() => {
        if (debouncedValue) {
            handleMarkReadEvent(selectedMailInfo);
            setIsProcessing(false);
        }
    }, [debouncedValue]);

    const fetchData = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const handlePromiseData = async (data, base64Array) => {
        await Promise.all(data.map(async (obj) => ({ file: await fetchData(obj.file) }))).then((value) => {
            value.map((data) => base64Array.push(data));
        });
    };

    const handleAttachment = async (event, value, perform) => {
        const base64Array = [];
        const newFiles = perform === 'clear' ? [] : event;
        if (perform === 'clear') {
            const data = attachment.attachments.filter((res) => res.file !== value);
            setAttachment({ ...attachment, attachments: data });
            handlePromiseData(data, base64Array);
            handlePromiseData(event, base64Array);
            setBase({ files: base64Array });
            return;
        }
        if (attachment?.attachments?.length > 0) {
            handlePromiseData(attachment.attachments, base64Array);
        }
        const largerFile = newFiles.filter((value) => value.size > 20971520);
        if (largerFile?.length > 0 && newFiles.length > 0) {
            setFileSize({ file: largerFile[0]?.name, size: largerFile[0]?.size });
        } else {
            setFileSize({});
        }
        newFiles.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                base64Array.push({ file: reader.result });
            };
            reader.onerror = (error) => { dispatch(updateError({ success: false, message: JSON.stringify(error) })); };
        });
        setBase({ files: base64Array });
    };

    const handleInputFiles = (e) => {
        const uniqueFiles = Array.from(new Set([...Array.from(e.target.files)]));
        const data = [...uniqueFiles, ...files];
        const removed = uniqBy(data, 'name');
        handleAttachment(removed);
        setFiles(removed);
    };

    const handleClearUploaded = (value) => {
        const removedData = files.filter((file) => file.name !== value);
        setFiles(removedData);
        handleAttachment(removedData);
    };

    function getLastPathSegment(url) {
        const splitUrl = url?.split('/');
        return splitUrl[splitUrl?.length - 1];
    }

    if (!mail) {
        return (
            <EmptyContent
                title='No Conversation Selected'
                description='Select a conversation to read'
                imgUrl='/assets/icons/empty/ic_email_selected.svg'
                sx={{ borderRadius: 1.5, bgcolor: 'background.default' }}
            />
        );
    };

    const renderSubject = (type) => (
        <Stack spacing={2} direction='row' flexShrink={0} sx={{ p: type === 'Drafts' ? '16px 16px' : '16px 12px' }}>
            <Typography variant='subtitle2' sx={{ flexGrow: 1, fontSize: '0.875rem' }}>
                Re: {selectedMailInfo?.subject}
            </Typography>
        </Stack>
    );

    const renderSender = (mail) => {
        let primaryContent = '';
        const senderEmail = mail?.sender?.match(/<(.+)>/)?.[1] || mail?.sender;
        if (leadDetail?.lead_contact?.email === mail?.sender || leadDetail?.lead_contact?.email === senderEmail) {
            primaryContent = leadDetail ? `${leadDetail.first_name || ''} ${leadDetail.last_name || ''}` : `${selectedMailInfo?.lead?.first_name || ''} ${selectedMailInfo?.lead?.last_name || ''}`;
        } else {
            const sender = practice?.mail_senders?.find((sender) => sender.sender_email === mail?.sender);
            primaryContent = sender ? sender.sender_name : `${selectedMailInfo?.lead?.first_name || ''} ${selectedMailInfo?.lead?.last_name || ''}`;
        }
        const senderDisplay = `<${mail?.sender}>`;
        const sender = practice?.mail_senders?.find((sender) => sender.sender_email === mail?.sender);
        return (
            <Stack flexShrink={0} direction='row' alignItems='center' sx={{ p: (theme) => theme.spacing(0.5, 2, 0.5, 2), width: '100%' }}>
                <Avatar
                    alt={sender ? mail?.sender : selectedMailInfo?.lead?.first_name}
                    src={mail?.sender ? `${mail?.sender}` : ''}
                    sx={{ mr: 2 }}
                >
                    {sender ? mail?.sender?.charAt(0)?.toUpperCase() : selectedMailInfo?.lead?.first_name?.charAt(0)?.toUpperCase()}
                </Avatar>
                <ListItemText
                    primary={<>
                        <span style={{ textTransform: 'capitalize', marginRight: '4px' }}>{primaryContent}</span>
                        <Box component='span' sx={{ typography: 'body2', color: 'text.disabled' }}>
                            {senderDisplay}
                        </Box>
                    </>}
                    secondary={<>
                        {'To: '}
                        {mail?.recepients?.map((email) => (
                            <Link key={email} sx={{ color: 'text.secondary' }}>
                                {`${email}`}
                            </Link>
                        ))}
                    </>}
                    secondaryTypographyProps={{
                        mt: 0.5,
                        noWrap: true,
                        component: 'span',
                        typography: 'caption',
                    }}
                />
                <Typography variant='caption' noWrap sx={{ color: 'text.disabled' }}>
                    {mail?.created_at_human}
                </Typography>
            </Stack>
        );
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleChange = (isChecked, item) => {
        if (isChecked.target.checked) {
            const data = { 'thread': selectedMailId, 'label': item.id };
            dispatch(addThreadLabel(practiceId, selectedMailId, data));
        }
    };

    const handleScroll = (e) => {
        const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop, 10) === e.target.clientHeight;
        if (bottom && inboxLabels.count > inboxLabels.results?.length) {
            dispatch(getInboxLabels(practiceId, 25, inboxLabels.results?.length, false));
        }
    };

    const handleCreateLabelClick = () => {
        setAnchorEl(null);
        setOpenAddLabel(true);
    };

    const renderIncomingLabelsList = () => {
        if (mailType === 'Incoming' || mailType === 'Outgoing' || mailType === 'Starred' || mailType === 'Label') {
            return (
                <Box sx={{ p: '0 12px', width: 'calc(100% - 64px)' }}>
                    {threadLabels?.results?.length > 0 && <>
                        <CustomThreadLabels handleDelete={(item) => dispatch(deleteThreadLabel(practiceId, selectedMailId, item.id))} />
                    </>}
                    <CustomCreateLabelPopover
                        anchorEl={anchorEl}
                        handleChange={handleChange}
                        handleClose={() => setAnchorEl(null)}
                        handleCreateLabelClick={handleCreateLabelClick}
                        handleScroll={handleScroll}
                        id={id}
                        open={open}
                    />
                    {openAddLabel === true && <AddLabelDialog
                        open={openAddLabel}
                        onClose={() => setOpenAddLabel(false)}
                        practiceId={practiceId}
                        threadId={selectedMailId}
                    />}
                </Box>);
        }
        return '';
    };

    const renderIncomingAndOutgoingMail = () => {
        if (mailType !== 'Drafts' && mail?.length > 0) {
            return (<Box>{mail?.map((mailItem, index) => (
                <Accordion
                    key={mailItem?.id}
                    expanded={index === expandedIndex}
                    onChange={() => handleAccordionChange(index)}
                    sx={mailAccordionStyle}
                >
                    <AccordionSummary
                        expandIcon={<IconButton><Iconify icon='entypo:chevron-small-down' /> </IconButton>}
                        aria-controls={`panel-${index}-content`}
                        id={`panel-${index}-header`}
                        sx={mailAccordionSummaryStyle}
                    >
                        {renderSender(mailItem)}
                    </AccordionSummary>
                    {index === expandedIndex && (
                        <AccordionDetails>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            <Stack spacing={2} direction='row' flexShrink={0} sx={{ p: 2 }}>
                                <Typography variant='subtitle2' sx={{ flexGrow: 1, mb: 5 }}>
                                    <Box dangerouslySetInnerHTML={{ __html: mailItem.message }} />
                                </Typography>
                            </Stack>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            {mailItem?.attachments.map((attachment, index) => (
                                <Link
                                    key={index}
                                    href={attachment.file}
                                    target='_blank'
                                    rel='noopener'
                                    variant='body2'
                                    sx={{ p: 2, wordBreak: 'break-all', display: 'flex' }}
                                >
                                    {attachment.file}
                                </Link>))}
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            {(<ReplyEditor
                                handleClearUploaded={() => handleClearUploaded()}
                                fileSize={fileSize}
                                getLastPathSegment={() => getLastPathSegment()}
                                handleAttachment={() => handleAttachment()}
                                files={files}
                                index={index}
                                msg={msg}
                                onChanged={(e) => setMsg(e)}
                                attachment={attachment}
                                handlePromiseData={() => handlePromiseData()}
                                mail={mailItem}
                                base={base}
                                handleClick={() => hiddenFileInput.current.click()}
                                practiceId={practiceId}
                                mailType={mailType}
                                selectedMailId={selectedMailId}
                                leadDetail={leadDetail}
                                isFromSideBar={isFromSideBar}
                                selectedMailInfo={selectedMailInfo}
                            />)}
                        </AccordionDetails>
                    )}
                </Accordion>
            ))}</Box>);
        }
        return '';
    };

    const onCloseCompose = () => handleBackClicked();

    const renderDraftsMail = () => {
        if (mailType === 'Drafts') {
            return <MailCompose
                practiceId={practiceId}
                leadDetail={leadDetail}
                isFromDrafts={'true'}
                draftInfo={mail}
                isFromSideBar={isFromSideBar}
                successDone={onCloseCompose}
                draftSubject={selectedMailInfo?.subject}
                selectedMailInfo={selectedMailInfo} />;
        }
        return '';
    };

    const goToLeadDetailsPage = () => {
        if (!selectedMailInfo?.lead?.is_archived) {
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${selectedMailInfo?.lead?.id}`);
        } else {
            navigate(`/dashboard/practice/${practiceId}/archived/${selectedMailInfo?.lead?.id}`);
        }
    };

    return (
        <Box onClick={() => dispatch(updateShowSubjectList(false))} sx={{ width: '100%' }} >
            <input
                type='file'
                ref={hiddenFileInput}
                accept='.pdf,.csv,.docx,.xls,.xlsx,.doc'
                style={{ display: 'none' }}
                onChange={handleInputFiles}
                multiple
            />
            {(mailType === 'Incoming' || mailType === 'Outgoing' || mailType === 'Starred' || mailType === 'Label') &&
                <Box sx={mailLabelWrapper}>
                    {renderIncomingLabelsList()}
                    <MailButtons
                        goToLeadDetailsPage={goToLeadDetailsPage}
                        handleClickMarkReadEvent={() => setIsProcessing(true)}
                        handlePopoverClick={(event) => setAnchorEl(event.currentTarget)}
                        id={id}
                        isFromSideBar={isFromSideBar}
                        isProcessing={isProcessing}
                        mailType={mailType}
                        onStarClick={onStarClick} />
                </Box>
            }
            {mailType !== 'Drafts' && renderSubject()}
            {renderIncomingAndOutgoingMail()}
            {renderDraftsMail()}
        </Box>
    );
};

MailDetails.propTypes = {
    mail: PropTypes.object,
    mailType: PropTypes.string,
    selectedMailId: PropTypes.number,
    leadDetail: PropTypes.object,
    isFromSideBar: PropTypes.bool,
    handleBackClicked: PropTypes.func,
    onStarClick: PropTypes.func,
    handleMarkReadEvent: PropTypes.func,
};

export default MailDetails;
