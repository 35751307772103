/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import {
    Box, Typography, MenuItem,
    Button
} from '@mui/material';
import { useSelector } from 'react-redux';
import { alpha, useTheme } from '@mui/material/styles';
import { updateSettingsMenuActive } from 'src/redux/slices/mergeTags';
import { dispatch } from 'src/redux/store';
import { MENU_OPTIONS } from '../constants/SettingsMenuOptions';
import { Iconify } from '.';
import { useGetCurrentUser } from '../hooks';
import { getMenuOptions } from '../utils/settingUtils';
import { settingsTitleStyle, settingsMenuListBoxStyle, settingsIconStyle, settingsMenuItemStyle } from '../styles/SettingsMenuStyle';


// ----------------------------------------------------------------------------------------------------------
const SettingsMenu = ({ selected }) => {
    const theme = useTheme();
    const { isSuperUser } = useGetCurrentUser();

    const {settingsMenuActive} = useSelector((state) => state.mergeTags);

    return (
        <Box sx={{ '@media(max-width: 1200px)': { display: 'none' }, ...(settingsMenuActive && { p:2, pt:3 }) }}>
            <Button
                sx={{ mb: 1, ml: 0, minWidth: '200px', width:"100%" }}
                variant='contained'
                onClick={() => dispatch(updateSettingsMenuActive(!settingsMenuActive))}
                startIcon={<Iconify icon={'icon-park-solid:left-one'} />}
            >
                Back to Dashboard
            </Button>
            <Box sx={settingsMenuActive && { mt: 2, pl: 1 }}>
                <Typography sx={{...settingsTitleStyle, fontSize: '19px'}}>Settings</Typography>
                <Box sx={settingsMenuListBoxStyle(settingsMenuActive)}>
                    {getMenuOptions(MENU_OPTIONS, isSuperUser)?.map((option) => (
                        <MenuItem selected={selected === option.value ? true : false} key={option.label} to={option.linkTo} component={RouterLink} className='icon' sx={settingsMenuItemStyle(alpha, theme)}>
                            <Iconify icon={option.icon} width={22} height={22} sx={settingsIconStyle} />
                            <Typography sx={{ color: '#637381', fontSize: '14px' }}>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Box>
            </Box>

        </Box>
    );
};

SettingsMenu.propTypes = {
    selected: PropTypes.string,
};

export default SettingsMenu;