import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// @mui
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import PaymentOption from '../../sections/@dashboard/general/app/PaymentOption';
import { clearLead } from '../../redux/slices/lead';
import { dispatch } from '../../redux/store';
import {
    commonDialogHeaderStyle,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
    attendedStackStyle,
    leadFooterButtonsStyle,
    finishButtonStyle
} from '../../styles/ScheduleReminder';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function OpenPayment({ open, onClose, type = 'lead', user, inputRef, headerButtons }) {
    const settings = useSettingsContext();

    const handleClose = () => {
        dispatch(clearLead());
        onClose();
    };

    const handleNext = async () => {
        await inputRef.current.submit('finish');
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle(type)}
        >
            <AppBar sx={commonDialogHeaderStyle}>
                <Typography
                    sx={reminderTitleStyle}
                    variant="h6"
                    component="div"
                >
                    Payment for <span style={{ textTransform: 'capitalize' }}>{user.first_name}</span>
                </Typography>
                {headerButtons &&
                    <Box sx={cancelBoxStyle}>
                        <Button
                            color="inherit"
                            variant='outlined'
                            style={{ borderColor: 'common.white', color: 'common.white' }}
                            onClick={() => { handleClose('close'); }}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={attendedStackStyle}>
                <Grid container spacing={1} sx={{ maxWidth: '1088px', margin: 'auto' }}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                    >
                        <PaymentOption handleClose={handleClose} ref={inputRef} type={type} />
                        <Container
                            maxWidth={settings.themeStretch ? false : 'xl'}
                            sx={leadFooterButtonsStyle}
                        >
                            <Button
                                color="primary"
                                variant='contained'
                                onClick={handleNext}
                                sx={finishButtonStyle(type)}
                            >
                                Finish
                            </Button>
                        </Container>
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
}

OpenPayment.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    user: PropTypes.object,
    inputRef: PropTypes.any,
    headerButtons: PropTypes.bool,
};