import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
// Other libraries
import { isEmpty } from 'lodash';
// Form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { updateError } from '../../redux/slices/user';
import { getUserDropdownValue, getUserId, practiceUserData } from '../../utils/clinCheckUtils';
import { useAuth } from '../../hooks';
import { FormProvider } from '../hook-form';
// Redux
import { dispatch, useSelector } from '../../redux/store';
import { getCurrentTreatmentIdV2, handleClinCheckInProgressStatus, handleClinCheckReceivedStatus } from '../../redux/slices/clincheck';
// Components
import Iconify from '../Iconify';
// Validations
import { ClinCheckReceivedFormSchema } from '../../validations/validations';
// Styles
import { toggleButtonStyle } from '../../styles/Common';
import { appointmentGridStyle } from '../../styles/DialogueTabs';
import UserSearchDropdownSingleSelect from '../UserSearchDropdownSingleSelect';
import ClinCheckDatePicker from './ClinCheckDatePicker';

const ClinCheckReceivedForm = forwardRef(({ handleClose, currentTab, leadDetail }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { clinCheckListing } = useSelector((state) => state.clinCheck);
    const { followLead } = useSelector((state) => state.lead);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { user } = useAuth();

    const currentUser = practiceUser.results?.find((e) => e?.user?.id === user?.pk);

    useEffect(() => {
        if (!isEmpty(practiceUserData(practiceUser)?.filter((ele) => ele?.user?.id === currentUser?.user?.id))) {
            setValue('who_received', getUserDropdownValue(currentUser), { shouldValidate: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultValues = {
        date_received: null,
        who_received: '',
        is_task_done: false
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit((data) => onSubmit(data))();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const methods = useForm({
        resolver: yupResolver(ClinCheckReceivedFormSchema),
        defaultValues,
    });

    const {
        control,
        setValue,
        watch,
        reset,
        handleSubmit
    } = methods;

    const values = watch();

    const onSubmit = (values) => {
        reset();
        if (clinCheckListing?.results?.[0]?.status === 'RECEIVED') {
            handleClose('finish');
            return;
        }
        if (isEmpty(currentTab)) {
            dispatch(updateError({
                message: 'Please select tab',
                status: false
            }));
            return;
        }
        if (!isEmpty(clinCheckListing?.results)) {
            handleUpdateStatus({ clinCheckId: clinCheckListing?.results?.[0]?.id, payload: values });
        } else {
            const data = {
                leadId: followLead?.id || leadDetail?.lead_id || leadDetail?.id,
                treatmentId: getCurrentTreatmentIdV2(followLead?.lead_treatments?.[0]?.id || leadDetail?.treatment_id || leadDetail?.lead_treatments?.[0]?.id),
                practiceId,
                payload: values
            };
            dispatch(handleClinCheckInProgressStatus(data, handleUpdateStatus));
        }
    };

    const handleUpdateStatus = (payload) => {
        const data = {
            leadId: followLead?.id || leadDetail?.lead_id || leadDetail?.id,
            treatmentId: getCurrentTreatmentIdV2(followLead?.lead_treatments?.[0]?.id || leadDetail?.treatment_id || leadDetail?.lead_treatments?.[0]?.id),
            clinCheckId: payload?.clinCheckId,
            practiceId,
            payload: {
                recorded_at: moment(payload?.payload?.date_received).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
                recorded_by: getUserId(practiceUser, payload?.payload?.who_received),
                note: ''
            }
        };
        dispatch(handleClinCheckReceivedStatus(data, handleClose));
    };

    const handlePracticeUserChange = (e, newValue) => {
        if (newValue === null) {
            setValue('who_received', '', { shouldValidate: true });
            return;
        };
        setValue('who_received', newValue, { shouldValidate: true });
    };

    return <div>
        <FormProvider methods={methods}>
            <Grid sx={appointmentGridStyle}>
                <ClinCheckDatePicker name={'date_received'} label={'Date received'} control={control} />
                <UserSearchDropdownSingleSelect name={'who_received'} control={control} onChange={handlePracticeUserChange} label='Who received' />
            </Grid>
            <ToggleButtonGroup
                color="primary"
                name='is_task_done'
                exclusive
                value={values.is_task_done}
                onChange={(e, value) => {
                    setValue('is_task_done', value);
                }}
                aria-label="Platform"
                sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap', display: 'none' }}
            >
                <ToggleButton
                    value={'yes'}
                    sx={toggleButtonStyle(isLight)}
                >
                    <Iconify icon={values.is_task_done === 'yes' ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: values.is_task_done === 'yes' ? '#00A76F' : '#C4CDD5' }} />Was the Task done?
                </ToggleButton>
            </ToggleButtonGroup>
        </FormProvider>
    </div>;
});

export default ClinCheckReceivedForm;

ClinCheckReceivedForm.propTypes = {
    handleClose: PropTypes.func,
    currentTab: PropTypes.string,
    leadDetail: PropTypes.any,
};