import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { Iconify } from '../../../components';
import useGetMessage from '../../../hooks/use-get-message';
import { renderBodyStackStyle, renderBodyBoxStyle, renderActionsStackStyle } from '../../../styles/Chat';

// ----------------------------------------------------------------------

export default function ChatMessageItem({ message, participants }) {
    const { user } = useMockedUser();

    const { me, senderDetails, hasImage } = useGetMessage({
        message,
        participants,
        currentUserId: `${user?.id}`,
    });

    const { firstName, avatarUrl } = senderDetails;

    const { body, createdAt } = message;

    const renderInfo = (
        <Typography
            noWrap
            variant="caption"
            sx={{
                mb: 1,
                color: 'text.disabled',
                ...(!me && {
                    mr: 'auto',
                }),
            }}
        >
            {!me && `${firstName},`} &nbsp;
            {formatDistanceToNowStrict(new Date(createdAt), {
                addSuffix: true,
            })}
        </Typography>
    );

    const renderBody = (
        <Stack sx={renderBodyStackStyle(me, hasImage)}>
            {hasImage ? (
                <Box
                    component="img"
                    alt="attachment"
                    src={body}
                    sx={renderBodyBoxStyle}
                />
            ) : (
                body
            )}
        </Stack>
    );

    const renderActions = (
        <Stack
            direction="row"
            className="message-actions"
            sx={renderActionsStackStyle(me)}
        >
            <IconButton size="small">
                <Iconify icon="solar:reply-bold" width={16} />
            </IconButton>
            <IconButton size="small">
                <Iconify icon="eva:smiling-face-fill" width={16} />
            </IconButton>
            <IconButton size="small">
                <Iconify icon="solar:trash-bin-trash-bold" width={16} />
            </IconButton>
        </Stack>
    );

    return (
        <Stack direction="row" justifyContent={me ? 'flex-end' : 'unset'} sx={{ mb: 5 }}>
            {!me && <Avatar alt={firstName} src={avatarUrl} sx={{ width: 32, height: 32, mr: 2 }} />}

            <Stack alignItems="flex-end">
                {renderInfo}

                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        position: 'relative',
                        '&:hover': {
                            '& .message-actions': {
                                opacity: 1,
                            },
                        },
                    }}
                >
                    {renderBody}
                    {renderActions}
                </Stack>
            </Stack>
        </Stack>
    );
}

ChatMessageItem.propTypes = {
    message: PropTypes.object,
    participants: PropTypes.array,
};
