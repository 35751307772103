import React from 'react';
import { Box } from '@mui/material';
import { AutocompleteTags } from '../../../../../components/AutoComplete';
import { tableCellInnerBoxStyle } from '../../../../../styles/NewLeadTableRowStyle';

const TagCell = ({
    handleTagCellClick,
    typed,
    selectData,
    handleSelect,
    reloadCounter,
    updateLeadPage,
    setTableData,
    handleArchivedNavigate,
}) => ({
    field: 'tag',
    headerName: 'Tags',
    sortable: false,
    editable: false,
    minWidth: 220,
    renderCell: (params) => (
        <Box onClick={() => handleTagCellClick(params)} sx={tableCellInnerBoxStyle}>
            <AutocompleteTags
                type='table'
                data={params?.row}
                tableType={typed}
                selectData={selectData}
                handleSelect={() => handleSelect(params?.row)}
                reloadCounter={reloadCounter}
                updateLeadPage={updateLeadPage}
                setTableData={setTableData}
                handleArchivedNavigate={handleArchivedNavigate}
            />
        </Box>
    ),
});

export default TagCell;
