/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { shallowEqual, useSelector } from "react-redux";
import { callApi } from "src/utils/apiCall";
import MediaDisplayComponent from "./whatsappComponents/MediaDisplayComponent";
import { Iconify } from ".";
import { NotFound } from "./empty-content";

const Attachments = (props) => {
    const { leadDetail } = props;
    const [loading, setLoading] = React.useState(false);

    const { practiceId } = useSelector(
        (state) => ({
            practiceId: state?.practiceTreatmentList?.practiceId,
        }),
        shallowEqual
    );

    const [data, setData] = React.useState({
        data: [],
        next: null,
        total: 0
    });

    const getDataFromApi = async (reset) => {
        try {
            const url = data?.next ?? "";
            const result = url.match(/api\/v1.*/);
            const extractedPath = result ? result[0] : null;
            const response = data?.next && !reset ? await callApi(
                `/${extractedPath}`,
                'GET',
            ) : await callApi(
                `/api/v1/practices/${practiceId}/leads-media/?lead__in=${[leadDetail?.id]}&limit=10&offset=0`,
                'GET'
            );
            if (response?.status === 200) {
                const { count, next, results } = response?.data ?? {}
                setData(prvObj => ({
                    data: reset ? [...(results ?? [])] : [...(prvObj?.data ?? []), ...(results ?? [])],
                    next,
                    total: count
                }))
            }
        } catch (_) {
            console.error("Error in Attachments Tab", _);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        setLoading(true);
        getDataFromApi();
    }, []);

    const handleDelete = async (el) => {
        setLoading(true);
        try {
            await callApi(
                `/api/v1/practices/${practiceId}/leads-media/${el?.object_id}`,
                'DELETE'
            );
        } catch (_) {
            console.error("error happended while deleting attachments", _);
        }
        getDataFromApi(true);
    }

    if (loading) {
        return <Box sx={{ overflow: "auto", height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress size={40} />
        </Box>
    }

    if (!loading && !data?.total > 0) {
        return <Box sx={{ overflow: "auto", height: "70vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <NotFound />
        </Box>
    }


    return <Box sx={{ overflow: "auto", height: "70vh" }}>
        <InfiniteScroll
            dataLength={data?.data?.length ?? 0}
            next={getDataFromApi}
            hasMore={data?.next}
            loader={<CircularProgress
                size={20}
            />}
            endMessage={
                <></>
            }
        >
            {data?.data?.map(e => (
                <Box key={e?.object_id} sx={{ display: "flex", alignItems: "center", mb: "1rem" }}>
                    <Box sx={{ maxHeight: "10rem", width: "max-content", maxWidth: "50%", p: "0.5rem", background: "white", borderRadius: "1rem", boxSizing: 'content-box', display: "flex", alignItems: "center", overflow: "hidden" }}>
                        <MediaDisplayComponent whatsAppMessageMedia={{ whatsapp_media_mime_type: e?.description, media_file: e?.remote_url }} />
                    </Box>
                    <Box sx={{ ml: "0.5rem" }}>
                        <Iconify
                            onClick={() => handleDelete(e)}
                            icon="fluent:delete-48-regular"
                            sx={{
                                color: '#FF5630',
                                width: '0.825rem',
                                height: '0.825rem',
                                cursor: 'pointer'
                            }}
                        />
                    </Box>
                </Box>))}
        </InfiniteScroll>
    </Box>
}

Attachments.propTypes = {
    leadDetail: PropTypes.object.isRequired
}

export default Attachments;