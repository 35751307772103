export const Assessments = [
    { label: 'Digital scan taken', value: 'is_digital_scan_taken' },
    { label: 'Impression taken', value: 'is_impressions_taken' },
    { label: 'Treatment options discussed', value: 'is_treatment_options_discussed' },
    { label: 'Treatment plan confirmed', value: 'is_treatment_plan_confirmed' },
    { label: 'Finance options discussed', value: 'is_finance_options_discussed' }
];

export const Clinical = [
    { label: 'Clinical photographs taken', value: 'is_clinical_photographs_taken' },
    { label: 'Oral health assessment taken ', value: 'is_oral_health_assessment_taken' },
    { label: 'X-ray taken', value: 'is_xray_taken' }
];