import React from 'react';
import PropType from 'prop-types';
import { Badge, Button } from '@mui/material';
import { badgeStyle, viewBtnStyle } from 'src/styles/NewLeadTableRowStyle';

const CustomCompleteTreatmentBtn = ({ params, viewDetailBtn }) => (
    <Button
        variant="contained"
        color='primary'
        onClick={() => viewDetailBtn(params.row.lead_id, params.row.status, params.row)}
        sx={viewBtnStyle(params.row?.status)}
    >
        <Badge badgeContent={[params.row?.lead_treatments__lead_contacted_counts__count]} color="primary"
            sx={badgeStyle(params.row.status, params.row?.lead_treatments__lead_contacted_counts__count)}
        >
            {params.row.status === 'CLOSED' ? 'View details' : 'Complete treatment'}
        </Badge>
    </Button>
);

export default CustomCompleteTreatmentBtn;

CustomCompleteTreatmentBtn.propTypes = {
    params: PropType.any,
    viewDetailBtn: PropType.func,
};
