import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Form
import { useForm } from 'react-hook-form';
import { Box, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
// Hooks
import { getClinCheckList } from '../../redux/slices/clincheck';
import { FormProvider } from '../hook-form';
// Redux
import { updateError } from '../../redux/slices/user';
import { dispatch, useSelector } from '../../redux/store';
// Components
import Iconify from '../Iconify';
import ClinCheckUploadedForm from './ClinCheckUploadedForm';
import ClinCheckSentForm from './ClinCheckSentForm';
import ClinCheckInProgressForm from './ClinCheckInProgressForm';
import ClinCheckReceivedForm from './ClinCheckReceivedForm';
import { tabContentStyle, clinCheckTabStyle } from '../../styles/DialogueTabs';

const ClinCheckForm = forwardRef(({ handleClose, handleCloseAndReload, leadDetail, currentTab, onChangeTab }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const { clinCheckListing } = useSelector((state) => state.clinCheck);

    const clinCheckData = clinCheckListing?.results?.[0];

    const reloadClinCheck = () => {
        if (leadDetail?.treatment_id) {
            const clinCheckData = {
                leadId: leadDetail?.lead_id,
                treatmentId: leadDetail?.treatment_id,
                practiceId
            };
            setTimeout(() => {
                dispatch(getClinCheckList(clinCheckData));
            }, 500);
        }
    };

    const onClose = (status, shouldReloadClinCheck) => {
        if (handleClose) {
            handleClose();
        }
        if (shouldReloadClinCheck) {
            reloadClinCheck();
        }
        if (handleCloseAndReload) {
            handleCloseAndReload('finish');
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const defaultValues = {
        clinCheckStatus: ''
    };

    const methods = useForm({
        defaultValues
    });

    const {
        handleSubmit,
    } = methods;

    const updateStatus = (status) => {
        if (status === 'IN_PROGRESS') {
            return 'In progress';
        }
        if (status === 'UPLOADED') {
            return 'Uploaded';
        }
        if (status === 'RECEIVED') {
            return 'Received';
        }
        if (status === 'SENT') {
            return 'Sent';
        }
        return '';
    };

    useEffect(() => {
        if (clinCheckData?.status) {
            onChangeTab('', updateStatus(clinCheckData?.status));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinCheckData]);

    const onSubmit = () => {
        if (isEmpty(currentTab)) {
            dispatch(updateError({
                message: 'Please select tab',
                status: false
            }));
        }
    };

    const TABS = [
        {
            id: 'IN_PROGRESS',
            value: 'In progress',
            icon: <Iconify icon={'mdi:progress-clock'} width={20} height={20} />,
            component: <ClinCheckInProgressForm leadDetail={leadDetail} currentTab={currentTab} handleClose={onClose} ref={ref} />,
        },
        {
            id: 'UPLOADED',
            value: 'Uploaded',
            icon: <Iconify icon={'iconoir:upload-square-solid'} width={20} height={20} />,
            component: <ClinCheckUploadedForm leadDetail={leadDetail} currentTab={currentTab} handleClose={onClose} ref={ref} />,
        },
        {
            id: 'RECEIVED',
            value: 'Received',
            icon: <Iconify icon={'ri:folder-received-fill'} width={20} height={20} />,
            component: <ClinCheckReceivedForm leadDetail={leadDetail} currentTab={currentTab} handleClose={onClose} ref={ref} />,
        },
        {
            id: 'SENT',
            value: 'Sent',
            icon: <Iconify icon={'icon-park-solid:message-sent'} width={20} height={20} />,
            component: <ClinCheckSentForm leadDetail={leadDetail} currentTab={currentTab} handleClose={onClose} ref={ref} />,
        },
    ];

    const isDisabled = (value, index) => {
        if (isEmpty(clinCheckData) && value !== 'IN_PROGRESS') return true;
        if (clinCheckData?.status === value) return true;
        if ((TABS.findIndex((ele) => ele.id === clinCheckData?.status) + 1) !== (index)) return true;
        return false;
    };

    return <div>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    allowScrollButtonsMobile
                    variant='scrollable'
                    scrollButtons="auto"
                    value={currentTab}
                    onChange={(event, newValue) => {
                        onChangeTab(event, newValue);
                    }}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        }
                    }}
                    sx={clinCheckTabStyle}
                >
                    {TABS.map((tab, index) => (
                        <Tab
                            disableRipple
                            key={tab.value}
                            value={tab.value}
                            icon={tab.icon}
                            label={capitalCase(tab.value)}
                            sx={tabContentStyle}
                            disabled={isDisabled(tab.id, index)}
                        />
                    ))}
                </Tabs>
                {TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Box>
        </FormProvider>
    </div>;
});

export default ClinCheckForm;

ClinCheckForm.propTypes = {
    handleClose: PropTypes.func,
    handleCloseAndReload: PropTypes.func,
    leadDetail: PropTypes.any,
    currentTab: PropTypes.any,
    onChangeTab: PropTypes.any,
};