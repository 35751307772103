import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import {
    Box,
    Tooltip
} from '@mui/material';
import { Iconify } from 'src/components';
import { leadPage } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
import { viewDetailBoxStyle } from '../../../../styles/NewLead';

const ViewDetailsField = ({
    row,
    tableTitle,
    tablePath,
}) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const navigate = useNavigate();

    const goToViewDetailPage = () => {
        dispatch(leadPage({ name: tableTitle, path: tablePath }));
        navigate(`/dashboard/practice/${practiceId}/lead-detail/${row.lead_id}`);
    };

    return (
        <Tooltip placement='bottom' title={'View details'}>
            <Box
                onClick={() => goToViewDetailPage()}
                onMouseDown={() => goToViewDetailPage()}
                sx={viewDetailBoxStyle}
            >
                <Box sx={{ lineHeight: 0 }}><Iconify icon={'ant-design:file-search-outlined'} width={22} height={22} /></Box>
            </Box>
        </Tooltip>
    );
};

ViewDetailsField.propTypes = {
    row: PropTypes.object,
    tableTitle: PropTypes.string,
    tablePath: PropTypes.string,
};

export default ViewDetailsField;
