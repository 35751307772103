import PropTypes from 'prop-types';
// @mui
import { IconButton } from '@mui/material';
// components
import { Iconify } from '../../../components';
import { useSettingsContext } from '../../../components/settings/context';
// hooks
import { useResponsive } from '../../../hooks';
// config
import { NAV } from '../../../config';

// ----------------------------------------------------------------------

export default function CollapseButton({sx, ...other}) {
    const settings = useSettingsContext();
    const isDesktop = useResponsive('up', 'lg');

    if (!isDesktop) {
        return null;
    }
    return (
        <IconButton
            size="small"
            onClick={() =>
                settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical')
            }
            sx={{
                p: 0.5,
                top: 32,
                position: 'fixed',
                left: NAV.W_DASHBOARD - 12,
                bgcolor: 'background.default',
                zIndex: (theme) => theme.zIndex.appBar + 1,
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
                '&:hover': {
                    bgcolor: 'background.default',
                },
                ...sx,
            }}
            {...other}
        >
            <Iconify
                width={16}
                icon={
                    settings.themeLayout === 'vertical'
                        ? 'eva:arrow-ios-back-fill'
                        : 'eva:arrow-ios-forward-fill'
                }
            />
        </IconButton>
    );
}

// ----------------------------------------------------------------------


CollapseButton.propTypes = {
    sx: PropTypes.object,
};