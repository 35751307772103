import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';
// config
import { HEADER } from '../../../config';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
//
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

function NavbarHorizontal() {
    const theme = useTheme();
    return (
        <AppBar
            component="nav"
            color="transparent"
            sx={{
                boxShadow: 0,
                top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            }}
        >
            <Toolbar
                sx={{
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                }}
            >
                <NavSectionHorizontal navConfig={navConfig} />
            </Toolbar>

            <Shadow />
        </AppBar>
    );
}

export default memo(NavbarHorizontal);


// ----------------------------------------------------------------------

function Shadow({ sx, ...other }) {
    return (
        <Box
            sx={{
                left: 0,
                right: 0,
                bottom: 0,
                height: 24,
                zIndex: -1,
                width: 1,
                m: 'auto',
                borderRadius: '50%',
                position: 'absolute',
                boxShadow: (theme) => theme.customShadows.z8,
                ...sx,
            }}
            {...other}
        />
    );
}

Shadow.propTypes = {
    sx: PropTypes.object,
};
