import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { Iconify } from '../../../../../components';
import { FormProvider, RHFSelect } from '../../../../../components/hook-form';
import AttendedDateField from '../AttendedDateField';
import { DATE_TITLES } from '../../../../../constants/newLead';
import { dateWrapperStyle, selectFilter, selectMenuStyle } from '../../../../../styles/NewLead';

const AttendedDateCell = ({
    methods,
    handleChangeDateFormat,
    setValue,
    typed,
    updateLeadPage,
    reloadCounter,
    openAppointmentAssignTaskFuc
}) => ({
    field: 'date_attended',
    headerName: (
        <>
            <Box sx={dateWrapperStyle}>
                Date of attended
                <Iconify sx={{ mr: '8px', ml: '8px' }} icon={'mdi:calendar-filter-outline'} width={15} height={15} />
                <Box sx={selectFilter}>
                    <FormProvider methods={methods}>
                        <RHFSelect
                            name={'date_attended'}
                            label="Title"
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            onChange={(e) => handleChangeDateFormat(e, 'date_attended', setValue)}
                        >
                            {DATE_TITLES.map((option, index) => (
                                <MenuItem key={index} value={option.value} sx={selectMenuStyle}>
                                    <Iconify sx={{ mr: '8px' }} icon={option.icon} width={18} height={18} />
                                    {option.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </FormProvider>
                </Box>
            </Box>
        </>
    ),
    width: 170,
    minWidth: 160,
    editable: false,
    sortable: false,
    renderCell: (params) => (
        <AttendedDateField
            rowData={params.row}
            typed={typed}
            updateLeadPage={updateLeadPage}
            reloadCounter={reloadCounter}
            tableType={typed}
            OpenAssignTask={openAppointmentAssignTaskFuc}
        />
    ),
});

export default AttendedDateCell;
