import { SvgIconStyle } from '../components';

const iconMappings = {
    note_added: {
        UNABLE_TO_CONTACT: {
            PHONE: {
                src: '/assets/icons/timeline/ic_phone_failed.svg',
                color: 'common.coralRed',
                bgColor: 'rgba(255, 72, 66, 0.16)',
            },
            EMAIL: {
                src: '/assets/icons/timeline/ic_email_failed.svg',
                color: 'common.coralRed',
                bgColor: 'rgba(255, 72, 66, 0.16)',
            },
            WHATSAPP: {
                src: '/assets/icons/timeline/ic_whatsapp_success.svg',
                color: 'common.mintyZest',
                bgColor: 'widget.notificationLightGreenIcon',
            },
            SMS: {
                src: '/assets/icons/timeline/ic_sms_success.svg',
                color: 'common.mintyZest',
                bgColor: 'widget.notificationLightGreenIcon',
            },
            SOCIAL_MEDIA: {
                src: '/assets/icons/timeline/ic_social_media_success.svg',
                color: 'common.mintyZest',
                bgColor: 'widget.notificationLightGreenIcon',
            },
            ONLINE_CHAT: {
                src: '/assets/icons/timeline/ic_online_chat_success.svg',
                color: 'common.mintyZest',
                bgColor: 'widget.notificationLightGreenIcon',
            },
        },
        FOLLOW_UP: {
            PHONE: {
                src: '/assets/icons/timeline/ic_phone_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
            WHATSAPP: {
                src: '/assets/icons/timeline/ic_whatsapp_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
            SMS: {
                src: '/assets/icons/timeline/ic_sms_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
            EMAIL: {
                src: '/assets/icons/timeline/ic_email_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
        },
        AWAITING_RESPONSE: {
            PHONE: {
                src: '/assets/icons/timeline/ic_phone_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
            WHATSAPP: {
                src: '/assets/icons/timeline/ic_whatsapp_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
            SMS: {
                src: '/assets/icons/timeline/ic_sms_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
            EMAIL: {
                src: '/assets/icons/timeline/ic_email_success.svg',
                color: 'common.green',
                bgColor: 'rgba(84, 214, 44, 0.16)',
            },
        },
    },
    appointment_added: {
        default: {
            src: '/assets/icons/timeline/ic_calendar_success.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    treatment_added: {
        default: {
            src: '/assets/icons/timeline/ic_calendar_success.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    appointment_rescheduled: {
        default: {
            src: '/assets/icons/timeline/ic_calendar_success.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    message_sent: {
        default: {
            src: '/assets/icons/timeline/ic_email_success.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    message_failed: {
        default: {
            src: '/assets/icons/timeline/ic_email_failed.svg',
            color: 'common.coralRed',
            bgColor: 'rgba(255, 72, 66, 0.16)',
        },
    },
    appointment_cancelled: {
        default: {
            src: '/assets/icons/timeline/ic_calendar_failed.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    stripe_checkout_session_created: {
        default: {
            src: '/assets/icons/timeline/ic_payment_status.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    lead_payment_initiated: {
        default: {
            src: '/assets/icons/timeline/ic_pound.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    whatsapp_message_sent: {
        default: {
            src: '/assets/icons/timeline/ic_whatsapp_success.svg',
            color: 'common.mintyZest',
            bgColor: 'widget.notificationLightGreenIcon',
        },
    },
    whatsapp_message_failed: {
        default: {
            src: '/assets/icons/timeline/ic_whatsapp_failed.svg',
            color: 'common.coralRed',
            bgColor: 'rgba(255, 72, 66, 0.16)',
        },
    },
};

const getDefaultIcon = () => ({
    src: '/assets/icons/timeline/ic_info.svg',
    color: 'common.mintBlue',
    bgColor: 'common.mintBGBlue',
});

const setIcon = (data) => {
    const { verb, action_object: actionObject } = data || {};
    const { note, contacted_via: contactedVia } = actionObject || {};

    const iconData =
        iconMappings[verb]?.[note]?.[contactedVia] ||
        iconMappings[verb]?.default ||
        getDefaultIcon();

    return (
        <SvgIconStyle
            src={iconData.src}
            sx={{ width: '22px', height: '22px', color: iconData.color }}
        />
    );
};

const setBackgroundColor = (data) => {
    const { verb, action_object: actionObject } = data || {};
    const { note, contacted_via: contactedVia } = actionObject || {};

    const iconData =
        iconMappings[verb]?.[note]?.[contactedVia] ||
        iconMappings[verb]?.default ||
        getDefaultIcon();

    return iconData.bgColor;
};

const setLeadSourceIcon = (data, referral) =>
    referral?.find((res) => res.value === data) || {
        icon: '/assets/icons/notification/user.png',
        label: 'Not found',
    };

const handleAppointmentTypeIcon = (data) => {
    const appointmentIcons = {
        IN_PRACTICE: 'eva:home-fill',
        VIDEO_CALL: 'eva:video-fill',
        PHONE: 'eva:phone-fill',
    };

    return appointmentIcons[data] || '';
};

export {
    setIcon,
    setLeadSourceIcon,
    handleAppointmentTypeIcon,
    setBackgroundColor,
};
