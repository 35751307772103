const getTreatmentList = (list) => {
    if (list && list?.length > 0) {
        return list.filter(el => el?.enabled);
    }
    return [];
};

const FilterTreatmentList = (treatmentList) => {
    const list = getTreatmentList(treatmentList);
    const data = [];
    if (list && list?.length > 0) {
        list?.forEach((el) => {
            data?.push({
                name: el?.treatment_item?.name,
                id: el?.id,
                price: el?.price,
                treatment_item_id : el?.treatment_item?.id,
                category: el?.treatment_item.treatment_category.name
            });
            return el;
        });
    }
    return data?.sort((a, b) => {
        const textA = a?.name?.toUpperCase();
        const textB = b?.name?.toUpperCase();
        // eslint-disable-next-line no-nested-ternary
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });;
};

export default FilterTreatmentList;

