/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { Card, Button, Box, CircularProgress, Backdrop, Modal, Fade } from '@mui/material';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { useSelector, dispatch } from 'src/redux/store';
import { followUpAppointmentHandler, updateBookingSteps, clearLead } from 'src/redux/slices/lead';
import { getCallScriptSection } from 'src/redux/slices/callScript';
import { followUpBoxStyle, followUpCardStyle, callScriptButtonStyle } from 'src/styles/FollowUp';
import { useResponsive, useToggle } from 'src/hooks';
import { CallScriptListing } from '../followup-callScript';
import { Iconify } from '..';

const TCOScript = forwardRef(({ handleNext, handleClose }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { followLead, tableRowLead } = useSelector((state) => state.lead);
    const [outcome, setOutcome] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [callScriptActive, setCallScriptActive] = useState(false);
    const { callScriptData } = useSelector((state) => state.callScript);
    const metaData = followLead && followLead?.lead_metadata;
    const leadSourceMetaData = followLead && followLead?.lead_source && followLead?.lead_source?.metadata;
    const [leadId, setLeadId] = useState('');
    const [treatmentId, setTreatmentId] = useState('');
    const [callScriptReloadPayLoad, setCallScriptReloadPayLoad] = useState({});
    const defaultValues = {
        state: 0
    };

    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);

    useEffect(() => {
        if (practiceId) {
            dispatch(getCallScriptSection({ practiceId, treatment: getTreatmentName(tableRowLead && tableRowLead.treatment_name) || '', isTemplate: false, category: 'TCO' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    const methods = useForm({
        defaultValues,
    });

    const onSubmit = (data) => {
        if (data.state > 0) {
            handleNext(true);
        }
        if (data.state < 1) {
            enqueueSnackbar('Please click one of outcome', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    };

    const {
        handleSubmit,
        setValue,
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const isMobile = useResponsive('up', 'sm');

    const appoimentHandler = (data) => {
        setValue('state', 1);
        dispatch(followUpAppointmentHandler(data));
        setOutcome(data);
        dispatch(updateBookingSteps(data));
    };

    const openCallScripts = () => {
        setCallScriptActive(true);
    };

    const {
        toggle: isAppointmentNotBooked,
        onOpen: onOpenAppointmentNotBooked,
        onClose: onCloseAppointmentNotBooked
    } = useToggle();

    const {
        toggle: isAppointmentBooked,
        onOpen: onOpenAppointmentBooked,
        onClose: onCloseAppointmentBooked
    } = useToggle();

    const onOpenAppointmentNotBookedEvent = (data) => {
        onOpenAppointmentNotBooked();
        setCallScriptReloadPayLoad(data);
    };

    const onOpenAppointmentBookedEvent = (data) => {
        onOpenAppointmentBooked();
        setCallScriptReloadPayLoad(data);
    };

    const appointmentNotBookedClose = () => {
        onCloseAppointmentNotBooked();
        dispatch(clearLead());
        handleClose('finish', callScriptReloadPayLoad);
    };

    const onAppointmentNotBookClose = () => {
        onCloseAppointmentNotBooked();
    };

    const appointmentBookedClose = () => {
        onCloseAppointmentBooked();
        dispatch(clearLead());
        handleClose('finish', callScriptReloadPayLoad);
    };

    const onAppointmentBookClose = () => {
        onCloseAppointmentBooked();
    };

    const getTreatmentName = (name) => {
        if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) return currentTreatmentDataGlobal?.treatmentData?.treatment;
        return name;
    };

    const handleFinalClose = () => {
        handleClose("tco");
        setCallScriptActive(false);
    }

    return (
        <>
            <Box sx={followUpBoxStyle}>
                <Card sx={{ ...followUpCardStyle, width: callScriptActive ? '100%' : 'auto' }} >
                    {!(callScriptData?.results?.length > 0) || !(followLead?.id || tableRowLead?.id) ? <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ mb: '24px', display: 'flex' }}>
                            <Button sx={{ ...callScriptButtonStyle, width: "18rem", display: "flex", alignItems: "center" }}>
                                <Iconify style={{ marginRight: '10px', width: 24, height: 24 }} icon={'eva:menu-2-fill'} /> TCO Scripts Loading <Box sx={{ ml: "1rem", display: "flex", alignItems: "center" }}><CircularProgress size="1rem" /></Box>
                            </Button>
                        </Box>
                    </Box > : <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ mb: '24px', display: 'flex' }}>
                            <Button sx={{ ...callScriptButtonStyle, width: "18rem", display: "flex", alignItems: "center", background: "#00A76F", color: "white", "&:hover": { background: "#007867", color: "white" } }} onClick={() => openCallScripts()}>
                                <Iconify style={{ marginRight: '10px', width: 24, height: 24 }} icon={'eva:menu-2-fill'} /> Open TCO Scripts
                            </Button>
                        </Box>
                    </Box >
                    }
                </Card >
            </Box >
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={callScriptActive}
                onClose={() => { }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={callScriptActive}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "max-content",
                        minWidth: "50%",
                        maxHeight: "90%",
                        maxWidth: "90%",
                        background: "white",
                        borderRadius: "1rem",
                        padding: "1rem 2rem 2.5rem 2rem",
                        overflow: "auto"
                    }}>
                        <Box sx={{ width: '100%' }}>
                            {(followLead?.id || tableRowLead?.id) && <CallScriptListing type="TCO" scriptList={callScriptData?.results} setCallScriptActive={setCallScriptActive} onClose={handleFinalClose} followLead={followLead} onOpenAppointmentNotBooked={onOpenAppointmentNotBookedEvent} onOpenAppointmentBooked={onOpenAppointmentBookedEvent} setLeadId={setLeadId} setTreatmentId={setTreatmentId} />}
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
});

TCOScript.propTypes = {
    handleNext: PropTypes.func,
    handleClose: PropTypes.func,
};

export default TCOScript;
