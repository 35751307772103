/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    Box,
    Button,
    Card
} from '@mui/material';
import { RenderActiveStep } from '.';
// store
import { dispatch } from '../../redux/store';
import { updateFollowUpLead, updateCompleteStatus } from '../../redux/slices/lead';

const EditDialogPopup = ({ onClose, steps, inputRef, updateStep, validStep, setActive }) => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (validStep) {
            if (activeStep !== steps.length - 1) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setTimeout(() => {
                    updateStep(false);
                }, 1000);
            } else {
                updateStep(false);
                handleClose();
            }
        }
    }, [validStep]);

    useEffect(() => {
        if (setActive) {
            setActive(activeStep);
        }
    }, [activeStep]);

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    const handleClose = () => {
        setActiveStep(0);
        onClose();
        dispatch(updateFollowUpLead(null));
        dispatch(updateCompleteStatus(false));
    };

    return (
        <Card sx={{ p: 3 }}>
            <Box>
                {steps[activeStep] && RenderActiveStep({ component: steps[activeStep].component, inputRef })}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleNext}
                >
                    {'Save changes'}
                </Button>
            </Box>
        </Card>
    );
};

export default EditDialogPopup;

EditDialogPopup.propTypes = {
    onClose: PropTypes.func,
    steps: PropTypes.array,
    inputRef: PropTypes.object,
    updateStep: PropTypes.func,
    validStep: PropTypes.bool,
    setActive: PropTypes.func
};