import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Controller } from 'react-hook-form';
import { Iconify } from '..';
import { testFieldWrapperStyle } from '../../styles/CallScripts';

const CustomScriptMobileDatePicker = ({ data, control }) => {
    if (data?.field_type?.attribute !== 'lead_dob') return null;

    return <Box sx={testFieldWrapperStyle}>
        <Controller
            name='dob'
            control={control}
            render={({ field, fieldState: { error } }) => (
                <MobileDatePicker
                    orientation='landscape'
                    label=''
                    value={field.value || null}
                    format='dd/MM/yyyy'
                    maxDate={new Date()}
                    onChange={(newValue) => field.onChange(newValue)}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton>
                                            <Iconify height={24} width={24} icon='solar:calendar-mark-bold-duotone' />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            },
                        },
                    }}
                />
            )}
        />
    </Box>;
};

export default CustomScriptMobileDatePicker;

CustomScriptMobileDatePicker.propTypes = {
    data: PropTypes.object,
    control: PropTypes.object,
};