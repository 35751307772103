/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
import { Box, Tooltip, Zoom, IconButton } from '@mui/material';
import { AssignUserAvatar } from 'src/components/custom-avatar';
import { Iconify } from 'src/components';
import { assignCountBoxStyle } from '../../../../styles/NewLead';

const AssigneeField = ({ rowData, getAssignees }) => {
    return (
        <>
            {rowData?.assignees?.length > 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Tooltip
                        title={getAssignees(rowData)}
                        placement="bottom"
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 300 }}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        {/* <AssignUserAvatar
                            sx={{ cursor: 'pointer', mr: 1, bgcolor: '#919EAB3D' }}
                            src={rowData?.assignees?.[0]?.user?.avatar || ''}
                            name={`${rowData?.assignees?.[0]?.user?.first_name || ''} ${rowData?.assignees?.[0]?.user?.last_name || ''}`}
                            role={rowData?.assignees?.[0]?.role}
                        />
                        {rowData?.assignees?.length > 1 && <Box sx={assignCountBoxStyle}>+ {rowData?.assignees?.length - 1}</Box>} */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <AssignUserAvatar
                                sx={{ cursor: 'pointer', mr: 1, bgcolor: '#919EAB3D' }}
                                src={rowData?.assignees?.[0]?.user?.avatar || ''}
                                name={`${rowData?.assignees?.[0]?.user?.first_name || ''} ${rowData?.assignees?.[0]?.user?.last_name || ''}`}
                                role={rowData?.assignees?.[0]?.role}
                            />
                            {rowData?.assignees?.length > 1 && <Box sx={assignCountBoxStyle}>+ {rowData?.assignees?.length - 1}</Box>}
                        </Box>
                    </Tooltip>
                </Box>
            ) : (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                    <IconButton
                        aria-label="upload picture"
                        component="label"
                        disabled={rowData?.is_archive_pending}
                        sx={{ '&:hover': { bgcolor: 'transparent' } }}
                    >
                        <Iconify
                            icon="material-symbols:add-circle"
                            style={{ color: '#00AB55' }}
                            sx={{ mr: '0' }}
                            width={20}
                            height={20}
                        />
                    </IconButton>
                </Box>
            )}
        </>
    );
};

AssigneeField.propTypes = {
    rowData: PropTypes.any,
    getAssignees: PropTypes.any,
};

export default AssigneeField;