/* eslint-disable no-unneeded-ternary */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// components
import NavItem from './NavItem';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';

const NavList = ({ data, depth, hasChildren }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { active, isExternalLink } = useActiveLink(data?.path);
    const [open, setOpen] = useState((data?.title === 'Analytics' && !pathname?.includes('analytics')) ? false : true);

    useEffect(() => {
        if ((data?.title === 'Analytics' && pathname?.includes('analytics')) ||
            (data?.title === 'Campaigns Manager' && pathname?.includes('campaigns')) ||
            (data?.title === 'Super admin' && pathname?.includes('super-admin')) ||
            (data?.title === 'Leads' && pathname?.includes('tables'))
        ) {
            setOpen(true);
            return;
        }
        setOpen(false);
    }, [data?.title, pathname]);

    const handleClickItem = () => {
        if (!hasChildren) {
            navigate(data?.path);
        }
        setOpen(!open);
    };

    return (
        <>
            <NavItem
                item={data}
                depth={depth}
                open={open}
                active={active}
                isExternalLink={isExternalLink}
                onClick={handleClickItem}
            />

            {hasChildren && (
                <Collapse in={open} unmountOnExit>
                    <NavSubList data={data.children} depth={depth} />
                </Collapse>
            )}
        </>
    );
};

const NavSubList = ({ data, depth }) => (
    <>
        {data.map((list) => (
            <NavList
                key={list.title + list.path}
                data={list}
                depth={depth + 1}
                hasChild={!!list.children}
            />
        ))}
    </>
);

NavSubList.propTypes = {
    data: PropTypes.array,
    depth: PropTypes.number,
};

NavList.propTypes = {
    data: PropTypes.object,
    depth: PropTypes.number,
    hasChildren: PropTypes.bool,
};

export default NavList;