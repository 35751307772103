// ----------------------------------------------------------------------

export const phoneNumber = [
    '365-374-4961',
    '904-966-2836',
    '399-757-9909',
    '692-767-2903',
    '990-588-5716',
    '955-439-2578',
    '226-924-4058',
    '552-917-1454',
    '285-840-9338',
    '306-269-2446',
    '883-373-6253',
    '476-509-8866',
    '201-465-1954',
    '538-295-9408',
    '531-492-6028',
    '981-699-7588',
    '500-268-4826',
    '205-952-3828',
    '222-255-5190',
    '408-439-8033',
    '272-940-8266',
    '812-685-8057',
    '353-801-5212',
    '606-285-8928',
    '202-767-8621',
    '222-830-0731',
    '964-940-3166',
    '262-702-2396',
    '886-261-9789',
    '352-390-5069',
    '343-907-8334',
    '575-347-2399',
    '749-228-5604',
    '774-452-2071',
    '607-841-0447',
    '395-619-2157',
    '233-834-0373',
    '586-880-2602',
    '746-772-0722',
    '638-615-3365,'
];
