/* eslint-disable no-nested-ternary */
export const gridStyle = (type) => ({
    display: 'flex',
    justifyContent: 'space-between',
    mt: '34px',
    mb: type === 'followUp' ? 0 : 3,
    rowGap: 2,
    width: '100%',
    ml: 'auto'
});

export const treatmentDropdownStyle = {
    width: '100%',
    maxWidth: {
        md: 'calc(70% - 20px)'
    },
    mb: {
        sm: 0,
        xs: 3
    }
};

export const appointmentGridStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
    },
    mb: 2,
    mt: 2
};

export const menuItemStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};

export const boxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
    },
    mb: 2,
};
export const ErrorTextStyle = {
    width: '100%',
    fontSize: '0.75rem',
    color: '#FF4842',
    mt: '8px',
    display: 'flex'
};
export const AmountInputStyle = (error, type, isLight) => ({
    width: '100%',
    marginBottom: '0',
    padding: '17px 14px',
    borderRadius: '8px',
    typography: 'body2',
    textTransform: 'capitalize',
    borderColor: error ? '#FF4842' : 'rgba(145, 158, 171, 0.32)',
    background: 'transparent',
    borderWidth: '1px',
    borderStyle: 'solid',
    fontSize: '15px',
    color: error ? '#FF4842' : isLight ? '#212B36' : '#fff',
    '@media(max-width: 600px)': {
        width: type === 'paymentOption' ? '50%' : '100%',
    }
});