/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Box, Card, Grid, Stack, Typography, Alert, MenuItem, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { invitationUser, getPracticeUserList } from '../../../redux/slices/practiceUser';
import { getPracticeItem } from '../../../redux/slices/singlePractice';
// validations
import { EditUserSchema, AddUserSchema } from '../../../validations/validations';
// styles
import { menuItemStyle, userNameBox } from '../../../styles/ProfileYourDetails';
import { AddUserButton, ButtonAssistCard, ButtonComboStyle, ButtonTypoDetails, CancelButtonStyle, CardStyleInner, CardStyleTypo, DentalTypoStyle, DentalTypoStyleTwo, EmailStyleBox, ShareBoxLoginDetails, ShareDetailBox, ShareDetailTypo, ShareStyleTypo, TypoContentAddPractioner, TypoContentInnerAddPractioner, passwordStyle, settingsMainBoxStyle1, stylePassword1 } from '../../../styles/AddUserPopup';

const AddPractitioner = ({ isEdit, currentUser, setUserOpen, setAssignToValue, addUserRole, selectPractitioner }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { salutationOptions } = useSelector((state) => state.schema);
    const { enqueueSnackbar } = useSnackbar();
    const [copied, setCopied] = useState(false);
    const [showDetails, setShowDetails] = useState(selectPractitioner ? false : false);
    const practiceData = useSelector((state) => state.singlePractice);
    const [firstNameValue, setFirstNameValue] = useState('');
    const [open, setOpen] = useState(false);

    const userRoles = [
        { label: 'Admin', value: 'ADMIN', content: 'Manages all account data and perform any action including user management. This user will have access to all contact`s data in the account.' },
        { label: 'User', value: 'USER', content: 'Manages account contacts (leads, referrals, enquiries) and dashboard tasks. This user will have access to all contact`s data in the account.' },
        { label: 'Treatment Coordinator (TCO)', value: 'TCO', content: 'Manages, creates and edit messages.' },
        { label: 'Practitioner', value: 'Practitioner', content: 'Able to see reports only.' }
    ];

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeItem(practiceId));
        }
    }, [practiceId]);

    const defaultValues = useMemo(
        () => ({
            firstName: '',
            email: '',
            role: selectPractitioner ? 'Practitioner' : '',
            lastName: '',
            jobtitle: '',
            title: '',
            isAccept: false,
        }),
        [currentUser]
    );
    const methods = useForm({
        resolver: !isEdit ? yupResolver(AddUserSchema) : yupResolver(EditUserSchema),
        defaultValues,
    });

    const {
        reset,
        setValue,
        getValues,
        handleSubmit,
        watch,
        formState: { errors },
    } = methods;

    const values = watch();

    useEffect(() => {
        if (isEdit && currentUser) {
            reset(defaultValues);
        }
        if (!isEdit) {
            reset(defaultValues);
        }
    }, [isEdit, currentUser]);

    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        let addData = {
            practice: practiceId,
            role: data.role,
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName || '',
            job_title: data.jobtitle,
            salutation: data.title || '',
            is_auto_accept: true
        };
        if (data.role) {
            addData = { ...addData, role: data.role };
        }
        dispatch(invitationUser(addData, handleError));
        setAssignToValue(`${data.title || ''} ${data.firstName || ''} ${data.lastName || ''}`);
    };

    const handleError = (data) => {
        if (data.success) {
            setUserOpen(false);
            reset(defaultValues);
            setFirstNameValue('');
            dispatch(getPracticeUserList(practiceId));
        }
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            id: 'success-bar'
        });
    };

    const isClipboardSupported = () => !!navigator.clipboard?.writeText;

    const handleCopy = () => {
        const loginDetails = `DentalSEM CRM login details.
    Email: ${values.email}
    Password: Welcome123!!
    How to change your password:
    1. Click on your profile image at the top right corner of the navigation bar.
    2. Choose ‘Profile’ from the dropdown menu.
    3. Select ‘Password’.
    4. Enter your new password.`;
        if (isClipboardSupported()) {
            navigator.clipboard.writeText(loginDetails)
                .then(() => {
                    setCopied(true);
                });
        }
    };

    const onClosePopup = () => {
        setUserOpen(false);
    };

    const handleChange = (event) => {
        setValue('role', event.target.value);
        if (getValues('role') !== 'Practitioner' && getValues('role') !== '') {
            setShowDetails(true);
        } else {
            setShowDetails(false);
        }
    };

    const firstNameChangeEvent = (event) => {
        setFirstNameValue(event.target.value);
        setValue('firstName', event.target.value);
        let trimmedValue = practiceData?.practice?.contact?.website.trim();
        if (trimmedValue.startsWith('http://')) {
            trimmedValue = trimmedValue.slice(7);
        } else if (trimmedValue.startsWith('https://')) {
            trimmedValue = trimmedValue.slice(8);
        }
        if (trimmedValue.startsWith('www')) {
            trimmedValue = trimmedValue.slice(4);
        }
        if (trimmedValue.endsWith('/')) {
            trimmedValue = trimmedValue.slice(0, -1);
        }
        setValue('email', `${event.target.value}@${trimmedValue || ''}`);
    };

    const accordionToggle = () => {
        setOpen(!open);
    };

    const handleSubmitWithoutPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
    };

    return (
        <Box sx={settingsMainBoxStyle1}>
            <FormProvider methods={methods} onSubmit={handleSubmitWithoutPropagation}>
                {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 3, boxShadow: 'none' }}>
                            {addUserRole && <>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: 'text.primary',
                                        mb: 3
                                    }}
                                >
                                    User Role
                                </Typography>
                                <Box
                                    sx={userNameBox}
                                >
                                    <RHFSelect
                                        name={'role'}
                                        label='Select'
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                        onChange={(e) => handleChange(e)}
                                        size='small'
                                    >
                                        {userRoles.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                value={option.value}
                                                sx={menuItemStyle}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                </Box></>}
                            <Box sx={{ gap: '16px' }}>
                                <Typography variant='h6' sx={{ color: 'text.primary', margin: '0 0 16px' }}>
                                    User's personal details
                                </Typography>
                                <Box>
                                    <Box sx={{ display: 'grid', columnGap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                                        <RHFSelect
                                            name={'title'}
                                            label='Title'
                                            SelectProps={{ sx: { textTransform: 'capitalize' } }}
                                            size='small'
                                        >
                                            {salutationOptions?.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={option.value}
                                                    sx={menuItemStyle}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </RHFSelect>
                                        <RHFTextField name='firstName' label='First name (Required)' id='profile-first-name' value={firstNameValue} onChange={firstNameChangeEvent} size='small' />
                                        <RHFTextField name='lastName' label='Last name (Required)' size='small' />
                                    </Box>
                                    <Stack spacing={3} alignItems='flex-end' sx={{ mt: 2 }}>
                                        <RHFTextField name='jobTitle' label='Job title' size='small' />
                                    </Stack>
                                </Box>
                            </Box>
                            <Box sx={{ gap: '16px' }}>
                                <Typography variant='h6' sx={{ color: 'text.primary', margin: '24px 0 16px' }}>
                                    User's login details
                                </Typography>
                                <Box sx={EmailStyleBox}>
                                    <RHFTextField onChange={(e) => setValue('email', e.target.value)} sx={{ width: '100%', margin: '0' }} name='email' label='Email address (Required)' id='invite-email' />
                                </Box>
                                <Box sx={{ ...passwordStyle, display: 'block' }}>
                                    <Typography variant='body2' sx={stylePassword1}>
                                        <span>Password</span>
                                        <br />
                                        <span>Welcome123!!</span>
                                    </Typography>
                                    <Typography id='invite-password' InputProps={{ readOnly: true }} />
                                </Box>
                            </Box>
                            <Box sx={{ opacity: showDetails ? '1' : '0', height: showDetails ? 'auto' : 0 }}>
                                <Box sx={ShareStyleTypo} onClick={() => accordionToggle()}>
                                    <Typography variant='h6'>
                                        Share login details
                                    </Typography>
                                    <Iconify
                                        width={24}
                                        icon={open ? 'eva:arrow-ios-upward-outline' : 'eva:arrow-ios-downward-outline'}
                                        sx={{ ml: 1, flexShrink: 0 }}
                                    />
                                </Box>
                                {open && <Box sx={ShareBoxLoginDetails}>
                                    <Iconify icon={'flat-color-icons:info'} width={32} height={32} sx={{ minWidth: '20px' }} />
                                    <Box sx={ShareDetailBox}>
                                        <Typography sx={TypoContentAddPractioner}>
                                            <span style={TypoContentInnerAddPractioner}>For the user’s first log in, they will need to use the password "Welcome123!!".</span> After they log in, they will need to change their password to make their account more secure.
                                        </Typography>
                                    </Box>
                                </Box>}
                            </Box>
                            {open && showDetails && <Card sx={{ ...CardStyleTypo, display: 'flex' }}>
                                <Typography sx={ShareDetailTypo}>To assist the new user with their initial login, you can directly copy and paste their login details to them.</Typography>
                                <Stack>
                                    <Card sx={CardStyleInner}>
                                        <Typography sx={DentalTypoStyle}>DentalSEM CRM login details.</Typography>
                                        <Typography sx={DentalTypoStyleTwo}>After you log in, you will need to change your password to make your account more secure.</Typography>
                                        <br />
                                        <Typography sx={DentalTypoStyleTwo}>Email: {values?.email ? values?.email : 'example@email.com'}</Typography>
                                        <Typography sx={DentalTypoStyleTwo}>Password: Welcome123!!</Typography>
                                        <br />
                                        <Typography sx={DentalTypoStyle}>How to change your account password:</Typography>
                                        <br />
                                        <Typography sx={DentalTypoStyleTwo}>1. Click on your profile image at the top right corner of the navigation bar.</Typography>
                                        <Typography sx={DentalTypoStyleTwo}>2. Choose ‘Profile’ from the dropdown menu.</Typography>
                                        <Typography sx={DentalTypoStyleTwo}>3. Select ‘Password’.</Typography>
                                        <Typography sx={DentalTypoStyleTwo}>4. Enter your new password</Typography>
                                        <br />
                                    </Card>
                                </Stack>
                            </Card>}
                            {showDetails && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button sx={ButtonAssistCard} onClick={handleCopy}>
                                    <Iconify icon='lucide:copy' width={16} height={16} sx={{ color: 'common.white' }} />
                                    <Typography sx={ButtonTypoDetails} variant='subtitle2'>
                                        {copied ? 'Copied!' : 'Copy and share login details'}
                                    </Typography>
                                </Button>
                            </Box>}
                            <Box sx={{ padding: '40px 0 0 0 ', gap: '24px' }}>
                                <Stack alignItems='flex-end' sx={ButtonComboStyle}>
                                    <Button color='inherit' variant='outlined' sx={CancelButtonStyle} onClick={onClosePopup}>Cancel</Button>
                                    <LoadingButton type='submit' variant='contained' sx={AddUserButton}>
                                        <Iconify icon={'mdi:tick-circle'} width={24} height={24} />
                                        {'Add user'}
                                    </LoadingButton>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </FormProvider>
        </Box>
    );
};

AddPractitioner.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object,
    setUserOpen: PropTypes.func,
    setAssignToValue: PropTypes.func,
    addUserRole: PropTypes.bool,
    selectPractitioner: PropTypes.bool,
};

export default AddPractitioner;
