import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import {
    tableCellInnerBoxStyle,
} from '../../../../styles/NewLeadTableRowStyle';

const EmailField = ({ rowData }) => (
    <Tooltip title={`${rowData?.email}`}>
        <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'flex-start' }}>
            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{rowData?.email || ''}</Box>
        </Box>
    </Tooltip>
);

EmailField.propTypes = {
    rowData: PropTypes.any,
};

export default EmailField;
