/* eslint-disable no-prototype-builtins */
import { cloneDeep, isArray, isEmpty, uniq } from 'lodash';
import { dispatch, store } from 'src/redux/store';
import { handleTablePreferences, handleTablePreferencesEdit } from 'src/redux/slices/tablePreferences';

export const getCurrentTableData = (results, tableTitle) => results?.filter((ele) => ele.table_name === tableTitle) || [];

export const handleColumnVisibility = ({ setColumnVisibilityModel, filteredData }) => {
    const columnVisibilityData = {};
    filteredData.forEach(key => {
        columnVisibilityData[key] = false;
    });
    setColumnVisibilityModel(columnVisibilityData);
};

export const handleTablePreference = ({ allColumns, setColumnVisibilityModel, tableTitle, setPinnedColumns, handleSorting }) => {
    const { tablePreferences: { results }, globalTablePreferences } = store.getState().tablePreferences;
    const tableData = getCurrentTableData(results, tableTitle);
    const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableTitle);
    const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
    if (!isEmpty(currentTableData?.[0]?.preferences?.columns)) {
        const unVisibleColumns = allColumns?.filter((el) => !currentTableData?.[0]?.preferences?.columns?.includes(el)) || [];
        const UpdatedVisibleColumns = allColumns?.reduce((total, currentVal) => {
            total = { ...total, [currentVal]: !unVisibleColumns.includes(currentVal) };
            return total;
        }, {});
        setColumnVisibilityModel(UpdatedVisibleColumns);
    }
    const currentTablePreferences = currentTableData?.[0]?.preferences?.preferences || {};
    const pinnedData = { left: [], right: [] };
    Object.entries(currentTablePreferences).forEach(([key, value]) => {
        if (value.isLeftPinned) {
            pinnedData.left.push(key);
        }
        if (value.isRightPinned) {
            pinnedData.right.push(key);
        }
        if (currentTablePreferences[key]?.hasOwnProperty('sortDirection')) {
            handleSorting({ key, order: currentTablePreferences[key].sortDirection });
        }
    });
    setPinnedColumns(pinnedData);
};

export const applyTablePreferences = ({ columns, tableName, isMultiTreatmentTable }) => {
    const { tablePreferences: { results }, globalTablePreferences } = store.getState().tablePreferences;
    const tableData = getCurrentTableData(results, tableName);
    const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableName);
    const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
    const modifiedColumns = columns?.map((ele) => {
        const currentColumn = currentTableData?.[0]?.preferences?.preferences?.[ele.field];
        return { ...ele, width: currentColumn?.width || ele.width };
    });
    if (isMultiTreatmentTable) return columns;
    return modifiedColumns;
};

export const handleColumnWidthChange = ({ data, userId, tableName, details }) => {
    const { tablePreferences: { results }, globalTablePreferences } = store.getState().tablePreferences;
    const tableData = getCurrentTableData(results, tableName);
    const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableName);
    const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
    const currentTablePreferences = currentTableData?.[0]?.preferences || {};
    const currentTableFieldPreferences = currentTablePreferences?.preferences?.[data?.colDef?.field] || {};
    const preferences = currentTablePreferences?.preferences || {};
    const payload = {
        body: {
            table_name: tableName,
            preferences: { ...currentTablePreferences, preferences: { ...preferences, [data?.colDef?.field]: { ...currentTableFieldPreferences, width: data?.width } } }
        },
        practiceUserId: userId,
    };
    if (isArray(tableData) && tableData.length > 0) {
        payload.tableId = currentTableData[0].id;
        dispatch(handleTablePreferencesEdit(payload));
    } else {
        payload.body.preferences.columns = details?.api?.getVisibleColumns()?.map((ele) => ele?.field);
        dispatch(handleTablePreferences(payload));
    }
};
export const handleSortingChange = ({ userId, tableName, details, key, order }) => {
    const { tablePreferences: { results }, globalTablePreferences } = store.getState().tablePreferences;
    const tableData = getCurrentTableData(results, tableName);
    const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableName);
    const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
    const currentTablePreferences = currentTableData?.[0]?.preferences || {};
    const currentTableFieldPreferences = currentTablePreferences?.preferences?.[key] || {};
    const preferences = currentTablePreferences?.preferences || {};
    const preferenceCopy = cloneDeep(preferences);
    Object.keys(preferenceCopy).forEach(key => {
        if (preferenceCopy[key]?.hasOwnProperty('sortDirection')) {
            delete preferenceCopy[key].sortDirection;
        }
    });
    const payload = {
        body: {
            table_name: tableName,
            preferences: { ...currentTablePreferences, preferences: { ...preferenceCopy, [key]: { ...currentTableFieldPreferences, sortDirection: order } } }
        },
        practiceUserId: userId,
    };
    if (isArray(tableData) && tableData.length > 0) {
        payload.tableId = currentTableData[0].id;
        dispatch(handleTablePreferencesEdit(payload));
    } else {
        payload.body.preferences.columns = details?.api?.getVisibleColumns()?.map((ele) => ele?.field);
        dispatch(handleTablePreferences(payload));
    }
};

export const ChangePinnedColumns = ({ updatedPinnedColumns, setPinnedColumns, userId, tableName, details }) => {
    const { tablePreferences: { results }, globalTablePreferences } = store.getState().tablePreferences;
    setPinnedColumns(updatedPinnedColumns);
    const tableData = getCurrentTableData(results, tableName);
    const currentGlobalTableData = getCurrentTableData(globalTablePreferences?.results || [], tableName);
    const currentTableData = isArray(tableData) && tableData.length > 0 ? tableData : currentGlobalTableData;
    const preferences = currentTableData?.[0]?.preferences || {};
    const currentTablePreferences = preferences?.preferences || {};
    const pinnedData = {};
    const totalPinnedData = updatedPinnedColumns.left.concat(updatedPinnedColumns.right);
    const preferenceKeys = Object.keys(currentTablePreferences)?.map((ele) => ele);
    const uniqPreferenceKeys = uniq([...preferenceKeys, ...totalPinnedData]);
    uniqPreferenceKeys.forEach((key) => {
        const oldPreferenceData = currentTablePreferences[key] || {};
        pinnedData[key] = {
            ...oldPreferenceData, isLeftPinned: updatedPinnedColumns.left?.includes(key),
            isRightPinned: updatedPinnedColumns.right?.includes(key)
        };
    });
    const payload = {
        body: {
            table_name: tableName,
            preferences: { ...preferences, preferences: { ...currentTablePreferences, ...pinnedData } }
        },
        practiceUserId: userId,
    };
    if (isArray(tableData) && tableData.length > 0) {
        payload.tableId = currentTableData[0].id;
        dispatch(handleTablePreferencesEdit(payload));
    } else {
        payload.body.preferences.columns = details?.api?.getVisibleColumns()?.map((ele) => ele?.field);
        dispatch(handleTablePreferences(payload));
    }
};