import React from 'react';
import PropTypes from 'prop-types';
import GoogleAutocomplete from 'react-google-autocomplete';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import Iconify from '../Iconify';
import { RHFTextField } from '../hook-form';
import { getAddressField, setAddressData } from '../../utils/callScriptCommonFunc';
import { leadAddressWrapper, leadAddressFieldWrapper } from '../../styles/CallScripts';

const CustomAdressComponent = ({ control, setValue, values }) => (
    <Box>
        <Box
            sx={leadAddressWrapper}
        >
            <Controller
                name='address'
                control={control}
                render={({ field }) => (
                    <>
                        <Iconify icon={'eva:search-outline'} sx={{
                            position: 'absolute',
                            left: '15px',
                            top: '16px'

                        }} color='grey.500' width={24} height={24} />
                        <GoogleAutocomplete
                            apiKey='AIzaSyD_7FXxCWgBUUd6922n33WKqrfRDsXXxj8'
                            onPlaceSelected={(place, inputRef) => {
                                const addresses = place.address_components;
                                setAddressData(addresses, inputRef.value, setValue);
                                field.onChange(inputRef.value);
                            }}
                            style={{
                                paddingLeft: '45px',
                                fontSize: '0.875rem'
                            }}
                            options={{
                                types: ['establishment', 'geocode'],
                                fields: ['address_components', 'formatted_address'],
                                componentRestrictions: { country: 'uk' }
                            }}
                            defaultValue={field.value}
                            className='address-input'
                            placeholder='Search address'
                        />
                    </>
                )}
            />
        </Box>
        {getAddressField(values) && <Box
            sx={leadAddressFieldWrapper}
        >
            <RHFTextField name='address_line_1' label='Address line 1' />
            <RHFTextField name='address_line_2' label='Address line 2' />
            <RHFTextField name='city' label='City' />
            <RHFTextField name='county' label='County' />
            <RHFTextField name='postcode' label='Postcode' />
        </Box>}
    </Box>
);

export default CustomAdressComponent;

CustomAdressComponent.propTypes = {
    control: PropTypes.object,
    setValue: PropTypes.func,
    values: PropTypes.object,
};
