import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Drawer, Box } from '@mui/material';
// hooks
import { useResponsive } from '../../../hooks';
// config
import { NAVBAR } from '../../../config';
// components
import { Scrollbar } from '../../../components';
//
import MessageSidebarItem from './MessageSidebarItem';

// ----------------------------------------------------------------------

export default function MessageSidebar({
    isOpenSidebar,
    onCloseSidebar,
    sequenceId,
    setSequenceId,
    setSequenceName,
    onNewOpenCompose,
    sidebarData,
    sequenceName,
}) {
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'md');
    const [open, setOpen] = useState(0);

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleClick = (value) => {
        if (value === open) {
            setOpen(null);
            return;
        };
        setOpen(value);
    };
    useEffect(() => {
        if(sidebarData){
            setOpen(0);
        }
    }, [sidebarData]);

    const dropdownHead = [
        'Default sequences'
    ];

    const renderContent = (
        <Scrollbar>
            <List disablePadding>
                {dropdownHead.map((label, index) => (
                    label &&
						<MessageSidebarItem
						    key={index}
						    index={index}
						    label={sidebarData}
						    sequenceId={sequenceId}
						    open={open}
						    handleClick={handleClick}
						    setSequenceId={setSequenceId}
						    dropdownHead={dropdownHead}
						    setSequenceName={setSequenceName}
						    onOpenNewCompose={() => onNewOpenCompose()}
						    sequenceName={sequenceName}
						/>
                )
                )}
            </List>
        </Scrollbar>
    );

    return (
        <>
            {isDesktop ? (
                <Drawer variant="permanent" PaperProps={{ sx: { width: NAVBAR.BASE_WIDTH, position: 'relative' } }}>
                    {renderContent}
                </Drawer>
            ) : (	
                <Box sx={{ ' nav': { maxWidth: '100%' }, borderBottom: '1px solid rgba(145, 158, 171, 0.24)' }}>
                    {renderContent}
                </Box>
            )}
        </>
    );
}

MessageSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
    sequenceId: PropTypes.number,
    setSequenceId: PropTypes.func,
    setSequenceName: PropTypes.func,
    onNewOpenCompose: PropTypes.func,
    sidebarData: PropTypes.array,
    sequenceName: PropTypes.any
};