import { dispatch } from 'src/redux/store';
import { getPracticeId } from 'src/redux/slices/clincheck';
import { getTaskWidgetCounter, getTreatmentWidgetPrice, getWidgetCounter, setWidgetCounter } from 'src/redux/slices/widgetCounter';
import { getReportsAppointmentListing } from 'src/redux/slices/analytics';
import { useGetTreatment } from './useGetTreatment';

export default function useReloadWidgetCounter() {
    const { getTreatmentId } = useGetTreatment();


    const reloadWidgetCounter = ({ array, startDate, endDate, isAppointmentDate, isDate }) => {
        if (getPracticeId()) {
            dispatch(setWidgetCounter([]));
            array.forEach((data) => {
                const detail = {
                    key: data.key
                };
                if (data.status) {
                    detail.status = data.status;
                }
                if (data.treatmentStatus) {
                    detail.treatmentStatus = data.treatmentStatus;
                }
                if ((isDate || data.isDate) && startDate && endDate) {
                    detail.dateRange = `${startDate},${endDate}`;
                }
                if (!data.shouldGetTaskCounter && (isAppointmentDate || data.isAppointmentDate) && startDate && endDate) {
                    detail.appointmentDateRange = `${startDate},${endDate}`;
                }
                if (data?.contactedCount) {
                    detail.contactedCount = data.contactedCount;
                }
                if (data?.isContactedCountNull) {
                    detail.isContactedCountNull = data.isContactedCountNull;
                }
                if (data.clinCheckStatus) {
                    detail.clinCheckStatus = data.clinCheckStatus;
                }
                if (data.isClinCheckTable) {
                    detail.isClinCheckTable = data.isClinCheckTable;
                }
                if (data.reminderContactedCount) {
                    detail.reminderContactedCount = data.reminderContactedCount;
                }
                if (data.group__in) {
                    detail.group__in = data.group__in;
                }
                if (data.completedFilter) {
                    detail.completedFilter = data.completedFilter;
                    detail.is_completed = data.is_completed;
                }
                if (data.appStatus) {
                    detail.appStatus = data.appStatus;
                }
                if (data.appStatusForPrice) {
                    detail.appStatusForPrice = data.appStatusForPrice;
                }
                if (data.treatmentStatusForPrice) {
                    detail.treatmentStatusForPrice = data.treatmentStatusForPrice;
                }
                if (data.status__in) {
                    detail.status__in = data.status__in;
                }
                if (data.isTaskDateTimeRange && startDate && endDate) {
                    detail.isTaskDateTimeRange = data.isTaskDateTimeRange;
                    detail.taskDateRange = `${startDate},${endDate}`;
                }
                const treatment = getTreatmentId(data.treatment);
                if (data.treatment) {
                    if (treatment) {
                        detail.treatment = treatment;
                    } else {
                        detail.isTreatmentNotPresent = true;
                    }
                }
                if (data.shouldGetPrice) {
                    detail.treatmentPrice = data.shouldGetPrice;
                    dispatch(getTreatmentWidgetPrice(detail));
                    return;
                }
                if (data.shouldGetTaskCounter) { 
                    dispatch(getTaskWidgetCounter(detail));
                    return;
                }
                if (data.shouldGetReportCounter) {
                    if (startDate) {
                        detail.startTime = `${startDate} 00:00:00`;
                    }
                    if (endDate) {
                        detail.endTime = `${endDate} 23:59:00`;
                    }
                    detail.shouldGetReportCounter = data.shouldGetReportCounter;
                    detail.isWidgetCounter = true;
                    dispatch(getReportsAppointmentListing(detail));
                    return;
                }

                dispatch(getWidgetCounter(detail));
            });
        }
    };

    return { reloadWidgetCounter };
}