import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { saveNewAudience, updateSelectedAudience } from '../../../redux/slices/audienceManager';
import { RHFAutocomplete } from '../../hook-form';
import { userInputs } from '../../../constants/LeadDetailConst';
import { tagsBoxStyle } from '../../../styles/AutocompleteTags';
import { dispatch, useSelector } from '../../../redux/store';

const AudiencePractitionerDropdown = () => {
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && !el.user.is_superuser);
    const [getPractitionerValue, setPractitionerValue] = useState([]);
    const { newAudience, selectedAudienceData } = useSelector((state) => state.audienceManager);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        if (practiceUser) {
            const getUserList = () => practiceUser?.results?.filter(el => el.user && el.user.first_name && !el.user.is_superuser);
            const data = _.orderBy(getUserList(), [o => (o.user?.first_name).toLowerCase(), o => (o.user?.last_name).toLowerCase()], ['asc', 'asc']);
            const consentUsers = data?.filter((value) => value.role === 'Practitioner');
            setUserList(consentUsers);
        }
    }, [practiceUser]);

    const defaultValues = {
        practitioner: newAudience?.practitionerName || getPractitionerValue || ''
    };
    const methods = useForm({
        defaultValues,
    });

    const {
        control
    } = methods;

    useEffect(() => {
        if (selectedAudienceData) {
            const value = selectedAudienceData?.recepient?.filters?.assignees__user__id__in;
            if (value) {
                const practitionerValues = getUserList()?.filter(el => value?.includes(el?.user?.id))?.map(el => `${el?.user?.salutation || ''} ${el?.user?.first_name} ${el?.user?.last_name}`) || [];
                setPractitionerValue(practitionerValues[0] || '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAudienceData]);

    const handleChange = (event, newValue) => {
        const data = { assignees__user__id__in: userList?.filter(el => newValue?.includes(`${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`))?.map((el) => el?.user?.id) };
        if (Object.values(selectedAudienceData).length > 0) {
            const updatedCampaign = { ...selectedAudienceData, recepient: { ...selectedAudienceData.recepient, filters: { ...selectedAudienceData?.recepient?.filters, ...data } } };
            dispatch(updateSelectedAudience(updatedCampaign));
            return;
        }
        dispatch(saveNewAudience({ assignees__user__id__in: data, practitionerName: newValue }));
    };

    return <Box sx={tagsBoxStyle}>
        <RHFAutocomplete
            disableClearable
            control={control}
            name={'practitioner'}
            placeholder={'Select practitioner'}
            value={newAudience?.practitionerName || getPractitionerValue || ''}
            onKeyDown={(evt) => (userInputs.includes(evt.key) || (/^[a-zA-Z]*$/).test(evt.key)) ? evt : evt.preventDefault()}
            onChange={(event, newValue) => handleChange(event, newValue)}
            options={userList?.map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`) || []}
            noOptionsText={'No options'}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
                <li {...props} key={option} style={{ padding: '5px 9px' }}>
                    <Box sx={{ padding: '0', textTransform: 'capitalize' }} >
                        {option || ''}
                    </Box>
                </li>
            )}
            sx={{ '.MuiInputBase-input': { textTransform: 'capitalize' } }}
        />
    </Box>;
};

export default AudiencePractitionerDropdown;