import PropTypes from 'prop-types';
// @mui
import { Box, Button, Container } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import AddAppointment from '../AddAppointment';
// style
import { leadFooterButtonsStyle, footerBoxStyle, btnStyle } from '../../styles/ScheduleReminder';

const AddAppointmentPopup = ({ inputRef, handleClose, treatmentData, type }) => {
    const settings = useSettingsContext();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Box>
            <AddAppointment ref={inputRef} handleClose={handleClose} type={type || 'RescheduleReminder'} treatmentData={treatmentData} />
            <Box sx={footerBoxStyle}>
                <Container
                    maxWidth={settings.themeStretch ? false : 'xl'}
                    sx={leadFooterButtonsStyle}
                >
                    <Button
                        color="primary"
                        variant='contained'
                        onClick={handleNext}
                        sx={btnStyle}
                    >
                        Finish
                    </Button>
                </Container>
            </Box>
        </Box>
    );
};

export default AddAppointmentPopup;

AddAppointmentPopup.propTypes = {
    inputRef: PropTypes.any,
    handleClose: PropTypes.func,
    treatmentData: PropTypes.any,
    type: PropTypes.string,
};
