import { alpha } from '@mui/material/styles';
import { bgBlur } from 'src/utils/cssStyles';

export const autocompleteStyle = {
    width: 1,
    height: 1,
    '& .MuiFormControl-root, .MuiOutlinedInput-root, .MuiAutocomplete-input': {
        height: 1,
        p: '0 !important',
        fontWeight: 'fontWeightBold',
        '& fieldset': { display: 'none' },
    },
};

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

export const searchBarStyle = ({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: APPBAR_MOBILE,
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    [theme.breakpoints.up('md')]: {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
});

export const popperStyle = ({ theme }) => ({
    left: '8px !important',
    top: `${APPBAR_MOBILE + 8}px !important`,
    width: 'calc(100% - 16px) !important',
    transform: 'none !important',
    [theme.breakpoints.up('md')]: {
        top: `${APPBAR_DESKTOP + 8}px !important`,
    },
    '& .MuiAutocomplete-paper': {
        padding: theme.spacing(1, 0),
    },
    '& .MuiListSubheader-root': {
        '&.MuiAutocomplete-groupLabel': {
            ...bgBlur({ color: theme.palette.background.neutral }),
            ...theme.typography.overline,
            top: 0,
            margin: 0,
            lineHeight: '48px',
            borderRadius: theme.shape.borderRadius,
        },
    },
    '& .MuiAutocomplete-listbox': {
        '& .MuiAutocomplete-option': {
            padding: theme.spacing(0.5, 2),
            margin: 0,
            display: 'block',
            border: 'dashed 1px transparent',
            borderBottom: `dashed 1px ${theme.palette.divider}`,
            '&:hover': {
                border: `dashed 1px ${theme.palette.primary.main}`,
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            },
        },
    },
});