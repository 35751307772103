import { orderBy } from 'lodash';
import { store } from 'src/redux/store';

export const getUserDropdownValue = (option) =>
    `${option?.user?.salutation || ''} ${option?.user?.first_name || ''} ${option?.user?.last_name || ''}` || '';

export const practiceUserData = (practiceUser) => {
    const getUserList = () =>
        practiceUser?.results?.filter((el) => el.user && el.user.first_name && !el.user.is_superuser);
    return orderBy(
        getUserList(),
        [(o) => (o.user?.first_name).toLowerCase(), (o) => (o.user?.last_name).toLowerCase()],
        ['asc', 'asc']
    );
};
export const getUserId = (practiceUser, value) =>
    practiceUserData(practiceUser)
        ?.map((el) => ({
            id: el.user.id,
            value: `${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`,
        }))
        ?.find((ele) => ele?.value === value)?.id || 0;

export const getUserNameById = (practiceUser, value) =>
    practiceUserData(practiceUser)
        ?.map((el) => ({
            id: el.user.id,
            value: `${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`,
        }))
        ?.find((ele) => ele?.id === value)?.value || 0;

export const getClinCheckStatus = (status) => {
    if (status === 'IN_PROGRESS') return 'In progress';
    if (status === 'UPLOADED') return 'Uploaded';
    if (status === 'RECEIVED') return 'Received';
    if (status === 'SENT') return 'Sent';
    return '-';
};

export const getCurrentTab = (tab) => {
    if (tab === 'In progress') return 'IN_PROGRESS';
    if (tab === 'Uploaded') return 'UPLOADED';
    if (tab === 'Received') return 'RECEIVED';
    if (tab === 'Sent') return 'SENT';
    return '';
};

export const isDisabledTab = (status, currentTab) => {
    const tab = getCurrentTab(currentTab);
    if (status === tab) return true;
    return false;
};

export const isDisabledTabForFinishButton = (status, currentTab, activeTab, isPaymentRequired, isFeePending, type) => {
    const tab = getCurrentTab(currentTab);
    const { treatmentLoading } = store.getState().lead;
    if (treatmentLoading) {
        return true;
    }
    if (!activeTab) {
        return true;
    }
    if (type !== 'CreatedLead' && isPaymentRequired && activeTab === 'Starting treatment' && isFeePending) {
        return true;
    }
    if (activeTab === 'ClinCheck' && (status === tab || status === 'SENT')) {
        return true;
    }
    return false;
};