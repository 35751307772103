import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { Iconify } from 'src/components';

const AddAppointmentMenuItem = ({ params, handleAddAppointment }) => {
    if (params.row.status === 'TREATMENT_STARTED') {
        return (
            <MenuItem onClick={() => handleAddAppointment(params.row)}>
                <Iconify icon={'eva:plus-fill'} /> Add appointment
            </MenuItem>
        );
    }
    return null;
};

export default AddAppointmentMenuItem;

AddAppointmentMenuItem.propTypes = {
    params: PropTypes.object,
    handleAddAppointment: PropTypes.func,
};
