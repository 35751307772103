import PropTypes from 'prop-types';
// @mui
import { Avatar, MenuItem, TableRow, TableCell, Typography, Switch, FormControlLabel } from '@mui/material';
// components
import { Iconify } from '../../../../components';
import capitalizeFirstLetter from '../../../../utils/capitalizedFunc';

// ----------------------------------------------------------------------

export default function UserTableListRow({ row, selected, onEditRow, onDeleteRow, inviteUser, handleSwitchChange, adminUser, currentUser }) {
    return (
        <TableRow hover selected={selected} sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
            {!inviteUser &&
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    {row.user && row.user.avatar === null ?
                        <Avatar alt={''} src={row.user.avatar} sx={{ mr: 2 }} /> :
                        <Avatar alt={row.user && row.user.first_name ? row.user.first_name : row.user && row.user.salutation} src={row.user && row.user.avatar} sx={{ mr: 2 }} />
                    }
                    <Typography variant="subtitle2" noWrap>
                        {row?.user?.salutation && `${capitalizeFirstLetter(row.user && row.user.salutation)}.`} {row.user && row.user.first_name} {row.user && row.user.last_name}
                    </Typography>
                </TableCell>}
            <TableCell align="left" sx={row.role === 'TCO' ? { textTransform: 'uppercase' } : { textTransform: 'capitalize' }}>
                {row.role === 'TCO' ? row.role : row.role.toLowerCase()}
            </TableCell>
            {!inviteUser &&
                <TableCell align="left">
                    {capitalizeFirstLetter(row.user && row.user.job_title)}
                </TableCell>}
            <TableCell align="left" >
                {inviteUser ? row.email : row.user && row.user.email}
            </TableCell>
            {!inviteUser &&
                <>
                    <TableCell align="left">
                        {row.user && row.user.phone}
                    </TableCell>

                    <TableCell align="left">
                        <FormControlLabel control={<Switch checked={row?.is_enabled} disabled={(adminUser !== 'ADMIN' && row.role === 'ADMIN') || (currentUser === row?.user?.email)} />} onChange={() => handleSwitchChange(row)} label={row?.is_enabled ? 'Enabled' : 'Disabled'} />
                    </TableCell>
                </>
            }
            {!inviteUser &&
                <TableCell align="right">
                    <MenuItem
                        onClick={() => onEditRow()}
                        disabled={adminUser !== 'ADMIN' && row.role === 'ADMIN'}
                        sx={{ fontSize: '18px', justifyContent: 'center', borderRadius: '8px' }}
                    >
                        <Iconify sx={{ fontSize: '18px' }} icon={'solar:pen-bold'} />
                    </MenuItem>
                </TableCell>
            }
            {inviteUser && <TableCell align="right">
                <MenuItem
                    onClick={() => onDeleteRow()}
                    sx={{ fontSize: '18px', justifyContent: 'center', borderRadius: '8px' }}
                >
                    <Iconify sx={{ fontSize: '18px' }} icon={'solar:trash-bin-trash-bold'} />
                </MenuItem>
            </TableCell>}
        </TableRow>
    );
}

UserTableListRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
    inviteUser: PropTypes.bool,
    handleSwitchChange: PropTypes.any,
    adminUser: PropTypes.any,
    currentUser: PropTypes.any,
};