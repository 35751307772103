// use routes
import { PATH_DASHBOARD } from '../routes/paths';

export const MENU_OPTIONS = [
    {
        label: 'Practice Details',
        value: 'practice_details',
        linkTo: PATH_DASHBOARD.user.practice,
        icon: 'eva:home-fill'
    },
    {
        label: 'Practice Users',
        value: 'practice_users',
        linkTo: PATH_DASHBOARD.user.practiceUser,
        icon: 'solar:users-group-rounded-bold-duotone'
    },
    {
        label: 'Practice Treatments',
        value: 'practice_treatments',
        linkTo: PATH_DASHBOARD.user.practiceTreatment,
        icon: 'mdi:dentist'
    },
    {
        label: 'Practice Messages',
        value: 'practice_messages',
        linkTo: PATH_DASHBOARD.user.message,
        icon: 'heroicons:chat-bubble-left-right-20-solid'
    },
    {
        label: 'Practice Chatbot',
        value: 'practice_chatbot',
        linkTo: PATH_DASHBOARD.user.practiceChatbot,
        icon: 'ic:round-smart-toy'
    },
    {
        label: 'Practice Integration',
        value: 'practice_integration',
        linkTo: PATH_DASHBOARD.user.integration,
        icon: 'material-symbols:attachment'
    },
    {
        label: 'Practice Consent',
        value: 'practice_consent',
        linkTo: PATH_DASHBOARD.user.consent,
        icon: 'fluent:form-new-24-filled'
    },
    {
        label: 'Notification Settings',
        value: 'notification_settings',
        linkTo: PATH_DASHBOARD.user.notification,
        icon: 'solar:bell-bing-bold-duotone'
    },
    {
        label: 'Practice Preferences',
        value: 'practice_preferences',
        linkTo: PATH_DASHBOARD.user.practicePreferences,
        icon: 'material-symbols:temp-preferences-custom'
    },
    {
        label: 'Call Script',
        value: 'call_script',
        linkTo: PATH_DASHBOARD.user.callScripts,
        icon: 'mdi:form-outline'
    },
    {
        label: 'Call Script V2',
        value: 'call_script_v2',
        linkTo: PATH_DASHBOARD.user.callScriptsV2,
        icon: 'mdi:form-outline'
    },
    {
        label: 'Auto Reminders',
        value: 'auto_reminders',
        linkTo: PATH_DASHBOARD.user.autoReminders,
        icon: 'mdi:clock-time-nine'
    },
    {
        label: 'Practice payment plans',
        value: 'Practice-payment-plans',
        linkTo: PATH_DASHBOARD.user.loan,
        icon: 'majesticons:pound-circle'
    },
    {
        label: 'Tools',
        value: 'export_to_csv',
        linkTo: PATH_DASHBOARD.user.exportToCsv,
        icon: 'tabler:tool'
    }
];