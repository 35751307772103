import { useSelector } from '../redux/store';
import useAuth from './useAuth';

export default function useGetCurrentUser() {
    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const { user } = useAuth();

    const getUserList = () => results.filter(el => el.user && el.user.id === user?.pk);

    return { user: getUserList()?.[0] || null, isSuperUser: getUserList()[0]?.user?.is_superuser };
}