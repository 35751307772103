/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
// @mui
import { Button, IconButton, Box, Snackbar, Typography } from '@mui/material';
// other libraries
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
// components
import { Iconify } from '../index';
// redux
import { leadPage } from '../../redux/slices/lead';
import { getTime, getToDoNotificationsData, handleSnooze, setToDoData } from '../../redux/slices/toDoNotifications';
import { dispatch, store, useSelector } from '../../redux/store';
import { getPracticeUserList } from '../../redux/slices/practiceUser';
import { emailCounter } from '../../redux/slices/emailCounter';
// hooks
import { useAuth } from '../../hooks';
// utils
import { getParsedData, localStorageGetItem, localStorageSetItem } from '../../utils/storage-available';
// style
import { common } from '../../theme/palette';
import { notificationContentWrapper, notificationIconWrapper, notificationMessageWrapper, notificationWrapperV2, snackbarMessageWrapperParent, snackbarWrapper, snackbarWrapperParent } from '../../styles/toDoNotificationSTygle';

export default function IntervalComponentV3() {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const intervalDuration = 5 * 60 * 1000;
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { toDoData } = useSelector((state) => state.toDoNotifications);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { user } = useAuth();
    const getAdminDetail = () => !isEmpty(practiceUser.results) ? practiceUser.results.find((users) => users.user?.email === user?.email) : null;

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeUserList(practiceId));
            dispatch(emailCounter(practiceId));
        }
    }, [practiceId]);

    useEffect(() => {
        if (isAuthenticated && practiceId && getAdminDetail()?.id) {
            const lastStartTime = parseInt(localStorageGetItem('intervalStartTime'), 10);
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - lastStartTime;

            const remainingTime = intervalDuration - (elapsedTime % intervalDuration);
            const intervalId = setTimeout(() => {
                handleApiCall(getAdminDetail()?.id);
                const nextIntervalId = setInterval(() => {
                    handleApiCall(getAdminDetail()?.id);
                }, intervalDuration);

                localStorageSetItem('intervalId', nextIntervalId);
            }, remainingTime);

            localStorageSetItem('intervalStartTime', currentTime);

            localStorageSetItem('timeoutId', intervalId);
        }
        return () => {
            const intervalId = localStorageGetItem('intervalId');
            const timeoutId = localStorageGetItem('timeoutId');
            const timeoutIdV2 = localStorageGetItem('intervalIdV2');
            clearInterval(intervalId);
            clearInterval(timeoutIdV2);
            clearTimeout(timeoutId);
            const snoozedData = getParsedData('timeout_ids') || [];
            if (!isEmpty(snoozedData)) {
                snoozedData?.forEach((element) => {
                    clearTimeout(element);
                });
            }
        };
    }, [isAuthenticated, practiceId, practiceUser]);


    const handleApiCall = () => {
        if (!isAuthenticated) return;
        const data = {
            startDate: moment().subtract(5, 'minutes').toISOString(),
            endDate: moment().add(5, 'minutes').toISOString(),
        };
        dispatch(getToDoNotificationsData(data));
    };

    const handleCloseNotiFications = (id, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        const { toDoData } = store.getState().toDoNotifications;
        dispatch(setToDoData(toDoData?.filter((ele) => ele.id !== id)));
    };

    const handleNavigateToLeadDetail = (id, leadId) => {
        handleCloseNotiFications(id);
        dispatch(leadPage({ name: 'New Leads', path: '/dashboard/app' }));
        navigate(`/dashboard/practice/${practiceId}/lead-detail/${leadId}`, { state: { goToTaskTab: true, taskId: id } });
    };

    return (
        <>
            {isAuthenticated && (!isEmpty(toDoData)) && <Box sx={snackbarWrapperParent}>
                <Box sx={snackbarWrapper}>
                    {isAuthenticated &&
                        isArray(toDoData) &&
                        toDoData.length > 0 &&
                        toDoData?.slice(0, 2)?.map((ele, index) => (
                            <Snackbar
                                key={index}
                                open
                                autoHideDuration={60000}
                                onClose={(e, reason) => handleCloseNotiFications(ele.id, reason)}
                                sx={{ position: 'relative' }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Box sx={notificationWrapperV2}>
                                    <Box sx={notificationContentWrapper}>
                                        <Box sx={notificationIconWrapper}>
                                            <Iconify
                                                icon={'material-symbols:alarm-outline'}
                                                width={24}
                                                height={24}
                                                color={common.scarletBlaze}
                                            />
                                        </Box>
                                        <Box sx={snackbarMessageWrapperParent}>
                                            <Box sx={notificationMessageWrapper}>
                                                <Box>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {ele.method}{' '}
                                                        <b>
                                                            {ele.firstName || ''} {ele.lastName || ''}
                                                        </b>{' '}
                                                        about {ele.treatment || ''}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '14px' }}>{getTime(ele.dateTime)}</Typography>
                                                </Box>
                                                <Box>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleCloseNotiFications(ele.id);
                                                        }}
                                                    >
                                                        <Iconify icon={'ci:close-big'} width={20} height={20} color="common.grey" />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Box sx={{ mt: '8px', display: 'flex', gap: 1 }}>
                                                <Button
                                                    variant="outlined"
                                                    sx={{ minWidth: '130px', fontSize: '13px' }}
                                                    onClick={() => {
                                                        const data = {
                                                            id: ele.id,
                                                            leadId: ele?.leadId,
                                                            message: ele.message,
                                                            dateTime: ele.dateTime,
                                                            HumanReadable: ele.HumanReadable,
                                                            firstName: ele.firstName,
                                                            lastName: ele.lastName,
                                                            method: ele.method,
                                                            treatment: ele.treatment,
                                                            snoozeTime: moment().add(10, 'minutes').toISOString(),
                                                            isSnooze: true,
                                                        };
                                                        handleSnooze(data, handleCloseNotiFications);
                                                    }}
                                                >
                                                    Snooze
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{ minWidth: '130px', fontSize: '13px' }}
                                                    onClick={() => handleNavigateToLeadDetail(ele.id, ele?.leadId)}
                                                >
                                                    Open task
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Snackbar>
                        ))}
                </Box>
            </Box >}
        </>
    );
}
