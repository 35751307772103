
// ----------------------------------------------------------------------

import { useSelector } from '../redux/store';

export default function useUserList() {
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const getUserList = () =>
        practiceUser.results.filter((el) => el.user && el.user.first_name && !el.user.is_superuser);

    return getUserList() || [];
}
