import { common } from 'src/theme/palette';

export const header = {
    display: 'flex',
    mt: 2,
    rowGap: 2,
    width: '100%',
    ml: 'auto',
};

export const popupHeader = {
    display: 'flex',
    alignItems: 'center',
    mt: 2,
    gap: 2,
    width: '100%',
    ml: 'auto',
};

export const autoComplete = {
    width: '50%',
    height: 'auto',
    maxHeight: '120px',
    overflow: 'auto',
    padding: '10px 0',
    maxWidth: { md: 'calc(70% - 20px)' },
    m: 1
};

export const assignToBoxStyle = {
    width: '45%',
    padding: '10px 0',
    m: 1
};

export const select = {
    width: '100%',
    padding: '0',
};

export const menuItem = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};

export const consentButton = {
    fontSize:'14px',
    maxWidth: { md: 'calc(70% - 20px)' }
    , m: 1
};

export const topButton = {
    margin : '0px 15px',
    fontSize : '14px',
    boxShadow: 'none',
};


// consent builder pop
export const DialogBox = (data) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:1200,
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { width: data ? '800px' : '550px', backgroundColor: 'transparent', height: 'auto', borderRadius :'16px', maxHeight: '90vh', maxWidth: '95vw' },
    ' .MuiPaper-root': { width: '100%', height: '50%', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', paddingBottom: '0px' }
});

export const popUpStack = {
    padding: '24px',
    width : '100% ',
    maxWidth : '98vw',
    maxHeight: 'calc(100vh - 150px)',
    overflow: 'auto'
};
