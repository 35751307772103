/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useDebounce } from 'use-lodash-debounce';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Grid, Box, Card, Typography, MenuItem } from '@mui/material';
// components
import TreatmentEditPopover from '../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import UserSearchDropdown from '../UserSearchDropdown';
import TreatmentFlowComponent from '../treatmentComponents/TreatmentFlowComponent';
import Iconify from '../Iconify';
import { FormProvider, RHFTextField, RHFRadioGroup, RHFSelect } from '../hook-form';
import CustomRadioGroupComponent from '../followup-callScript/CustomRadioGroupComponent';
import CustomLeadInquiryFields from '../followup-callScript/CustomLeadInquiryFields';
import CustomScriptMobileDatePicker from '../followup-callScript/CustomScriptMobileDatePicker';
import ScriptDateTimePicker from '../followup-callScript/ScriptDateTimePicker';
import CustomCombinedFields from '../followup-callScript/CustomCombinedFields';
import CustomLeadNameAutocomplete from '../followup-callScript/CustomLeadNameAutocomplete';
import { CustomAdressComponent, CustomCheckboxComponent, CustomMultiSelectComponent, CustomDropdownComponent, CustomDescriptionComponent, CustomLabelComponent, CustomTitleComponent } from '../followup-callScript';
import SingleScriptHeader from './SingleScriptHeader';
// store
import { dispatch, useSelector } from '../../redux/store';
import { UpdateTreatmentV3, addNewLead, addTreatment, clearTaskLead, updateAssignToUser } from '../../redux/slices/lead';
import { getCallScriptSection } from '../../redux/slices/callScript';
// utils/hooks/validation
import capitalizeFirstLetter from '../../utils/capitalizedFunc';
import { useAddTreatment, useAuth } from '../../hooks';
import { createDefaultValues, formatLeadData, formatLeadDetails, formatLeadTreatment, handleAddressData, handleLeadTypeData, handleSearch, handleSuccessMessage, handleTreatmentFunc, inboundSubmissionFunc, numberKeyDownFunc, saveTreatmentFunc, searchLeadFunc, searchLeadHandler, templateRenderFunc } from '../../utils/callScriptCommonFunc';
import { amountHandleChangeFunc, treatmentTeethFunc } from '../../utils/callScriptCommonFuncNew';
import { SingleScriptPreviewSchema } from '../../validations/validations';
// style
import { common, error } from '../../theme/palette';
import { gridStyle } from '../../styles/AddAppointmentStyle';
import { unableContactButtonStyle, loaderButtonStyle, testFieldWrapperStyle } from '../../styles/CallScripts';

const DefaultScriptView = ({ onClose, scriptData, backButtonClick }) => {
    const { user } = useAuth();
    const [expand, setExpand] = useState(false);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { callScriptData } = useSelector((state) => state.callScript);
    const [fieldData, setFieldData] = useState([]);
    const [tempFieldData, setTempFieldData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { taskSearchLead } = useSelector((state) => state.lead);
    const debouncedValue = useDebounce(searchQuery, 1000);
    const { referral } = useSelector((state) => state.schema);
    const referralValue = (value, data) => referral?.length > 0 && referral?.filter((res) => res[value] === data.referral);
    const [newtreatmentData, setNewTreatment] = useState('');
    const [leadTreatment, setLeadTreatment] = useState('');
    const [leadTreatmentValue, setLeadTreatmentValue] = useState('');
    const [leadTreatmentTeethValue, setLeadTreatmentTeethValue] = useState(null);
    const [assignToState, setAssignToState] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const [priceAmountError, setPriceAmountError] = useState(false);
    const [teethAmountError, setTeethAmountError] = useState(false);
    const InboundScripts = callScriptData?.results?.filter((el) => el.category === 'INBOUND');
    const [selectedFormId, setSelectedFormId] = useState('');
    const mergeTags = useSelector((state) => state.mergeTags);

    useEffect(() => {
        if (scriptData?.sections) {
            setFieldData(scriptData?.sections);
            setSelectedFormId(scriptData?.formId);
        }
    }, [scriptData]);

    useEffect(() => {
        if (newtreatmentData && InboundScripts?.length === 1) {
            templateRenderFunc({ value: InboundScripts?.[0]?.id, setValue, InboundScripts, setSelectedFormId, setTempFieldData, fieldData });
        }
        if (newtreatmentData && (InboundScripts?.length > 1 || InboundScripts?.length === 0)) {
            setTempFieldData([]);
            setValue('template', null);
        }
    }, [newtreatmentData, callScriptData]);

    const {
        toggle: EditTreatment,
        onOpen: onEditTreatment,
        onClose: onCloseEditTreatment
    } = useAddTreatment();

    useEffect(() => {
        handleSearch(searchQuery);
    }, [debouncedValue]);

    useEffect(() => {
        if (searchQuery?.length === 0) {
            dispatch(clearTaskLead());
        }
    }, [taskSearchLead]);

    const defaultValues = createDefaultValues(user);

    const methods = useForm({
        resolver: yupResolver(SingleScriptPreviewSchema([...fieldData, ...tempFieldData])),
        defaultValues,
    });

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
        watch,
        clearErrors
    } = methods;

    const values = watch();

    const hideLoader = () => {
        setTimeout(() => { setSubmitLoading(false); }, 2000);
    };

    const onSubmit = (data, type) => {
        setSubmitLoading(true);
        if (data.leadName !== undefined) {
            const leadData = formatLeadData(data, assignToState);
            const leadUpdateData = formatLeadData(data, [], true);
            handleLeadTypeData({ data, leadData, leadUpdateData });
            handleAddressData({ data, leadData, leadUpdateData });
            const detail = formatLeadTreatment(data);
            hideLoader();
            if (data.bookedWith) {
                dispatch(updateAssignToUser(leadData, data?.lead_id, practiceId, handleSuccess, type, 'leadUpdate'));
            } else {
                dispatch(updateAssignToUser(leadUpdateData, data?.lead_id, practiceId, handleSuccess, type, 'leadUpdate'));
            }
            if (detail.treatment !== leadTreatment || detail.custom_price !== leadTreatmentValue || detail.teethAmount !== leadTreatmentTeethValue) {
                if (data?.treatment_id === undefined) {
                    dispatch(addTreatment(detail, practiceId, data?.lead_id, handleSuccess, { needToUpdate: detail.teethAmount || detail.teethAmount === 0, teeth: detail.teethAmount || 0 }));
                } else {
                    dispatch(UpdateTreatmentV3({ detail, lead_id: data?.lead_id, treatment_id: data?.treatment_id }, type, handleSuccess));
                }
            }
        } else {
            const leadDetails = formatLeadDetails(data);
            if (data.title) {
                leadDetails.salutation = data.title && data.title.toUpperCase();
            }
            if (data.bookedWith) {
                leadDetails.assignees = assignToState?.map((el) => el?.id) || [];
            }
            if (data.preferredContactMethod) {
                leadDetails.lead_contact = {
                    ...leadDetails.lead_contact,
                    preferred_contact_method: data.preferredContactMethod?.toUpperCase()
                };
            }
            const addressFields = ['address_line_1', 'address_line_2', 'postcode', 'city', 'county'];
            addressFields.forEach(field => {
                if (data[field]) {
                    leadDetails.lead_address = {
                        ...leadDetails.lead_address,
                        [field]: data[field]
                    };
                }
            });
            const sourceFields = ['referral', 'leadType'];
            sourceFields.forEach(field => {
                if (data[field]) {
                    leadDetails.lead_source = {
                        ...leadDetails.lead_source,
                        [field]: data[field]
                    };
                }
            });
            const treatmentDetail = formatLeadTreatment(data);
            dispatch(addNewLead(leadDetails, practiceId, treatmentDetail, data, handleSuccess, type));
            hideLoader();
            return;
        }
        inboundSubmissionFunc({ data, fieldData: [...fieldData, ...tempFieldData], handleSuccess, selectedFormId });
    };

    const handleSuccess = (data) => {
        hideLoader();
        if (data?.leadUpdate) {
            handleSuccessMessage({ data });
        }
        if (data?.success) {
            onClose();
        }
        if (data.leadId) {
            inboundSubmissionFunc({ data: data.formData, fieldData: [...fieldData, ...tempFieldData], handleSuccess, selectedFormId, leadId: data.leadId });
        }
        if (data.updateTreatment) {
            handleSuccessMessage({ data });
        }
    };

    const handleReferral = (event, data) => {
        if (data) setValue('referral', data.value);
    };

    const handleSaveTreatment = (data) => {
        saveTreatmentFunc({ setNewTreatment, setValue, data });
        setTempFieldData([]);
        setValue('template', null);
        onCloseEditTreatment();
        dispatch(getCallScriptSection({ practiceId, treatment: data.selectedTreatment || '', isTemplate: false, category: 'INBOUND' }));
    };

    const [dataMap, setDataMap] = React.useState({});

    React.useEffect(() => {
        setDataMap(prv => ({ ...prv, ...(mergeTags?.mtValPractice ?? {}), ...(mergeTags?.mtValUser ?? {}) }))
        if (!mergeTags?.mtValLoadingLeads && !mergeTags?.mtValLoadingTreatments) {
            setDataMap(prv => ({ ...prv, ...(mergeTags?.mtValTreatments ?? {}), ...(mergeTags?.mtValLeads ?? {}) }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mergeTags?.mtValLoadingLeads, mergeTags?.mtValLoadingTreatments]);


    const parseMergeTags = (datalabels) => (
        // Use regex to find all words starting with '$' followed by alphanumeric characters or underscores
        datalabels?.replace(/\$[a-zA-Z0-9_]+/g, (match) => {
            // Remove the '$' sign to get the actual key
            const key = match.slice(1);

            // Return the value from the dataMap if it exists, otherwise return the original field
            return Object.prototype.hasOwnProperty.call(dataMap, key) ? dataMap[key] : match;
        }) ?? datalabels);

    const RenderFieldItem = (item) => (
        <>
            {
                item.fields?.length > 0 && <Box sx={{ mb: '30px', borderBottom: `1px solid ${common.platinum}`, pb: '8px' }}>
                    <Typography sx={{ fontSize: '22px' }}>{item.name}</Typography>
                </Box>
            }
            {
                item.fields.map((_data, index) => {
                    const data = { ..._data };
                    data.label = parseMergeTags(data?.label);
                    data.description = parseMergeTags(data?.description);
                    return (
                        <Box sx={{ mb: '30px' }} key={index}>
                            {data?.field_type && <CustomTitleComponent data={data} defaultValues={defaultValues} />}
                            {data?.field_type?.field_type === 'text' && <>
                                <CustomDescriptionComponent description={data.description} />
                                {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                <RHFTextField name={`text_${[data.id]}`} multiline rows={5} />
                            </>}
                            {data?.field_type?.field_type === 'label' && <>
                                {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                            </>}
                            {data?.field_type?.field_type === 'lead_search_select' && <>
                                <CustomDescriptionComponent description={data.description} />
                                {data?.field_type?.attribute === 'lead_search' &&
                                    <>
                                        {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                        <CustomLeadNameAutocomplete
                                            control={control}
                                            handleSearchLead={(e, value) => searchLeadFunc({ value, setValue, setSearchQuery, clearErrors, getCallScriptSection, setLeadTreatmentValue, setLeadTreatmentTeethValue, setNewTreatment, setLeadTreatment, setAssignToState })}
                                            searchLeadHandler={(event, value, reason) => searchLeadHandler(value, reason, setSearchQuery)}
                                            searchQuery={searchQuery}
                                        />
                                        <CustomCombinedFields />
                                    </>
                                }
                            </>}
                            {data?.field_type?.field_type === 'practice_treatment_search_select' && <>
                                {data?.field_type?.attribute === 'treatment_search' && <>
                                    {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                    <Grid container spacing={1} sx={{ ...gridStyle('callScript'), display: 'flex', m: '10px 0 16px' }}  >
                                        {EditTreatment && (
                                            <TreatmentEditPopover
                                                treatment={newtreatmentData || ''}
                                                leadTreatmentSpecification={{ teeth_count: values.teethAmount }}
                                                open={EditTreatment}
                                                anchorEl={anchorEditTreatment}
                                                handleClickAwayLister={() => onCloseEditTreatment()}
                                                treatmentPrice={values.treatmentValue}
                                                handleSaveTreatment={handleSaveTreatment}
                                                isFlow
                                            />
                                        )}
                                        <TreatmentFlowComponent
                                            treatmentData={newtreatmentData}
                                            teethAmount={values.teethAmount}
                                            handleTreatment={(event) => handleTreatmentFunc({ onEditTreatment, setAnchorEditTreatment, event })}
                                            amount={values.treatmentValue}
                                            amountError={priceAmountError}
                                            amountHandleChange={(value) => amountHandleChangeFunc({ value, setValue, setPriceAmountError })}
                                            handleChangeTeeth={(e) => treatmentTeethFunc({ e, setValue, setTeethAmountError })}
                                            teethAmountError={teethAmountError}
                                        />
                                    </Grid>
                                </>}
                            </>}
                            {data?.field_type?.field_type === 'practice_user_search_select' && <>
                                {data?.field_type?.attribute === 'user_search' &&
                                    <>
                                        {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                        <UserSearchDropdown
                                            name={'bookedWith'}
                                            control={control}
                                            placeholder='Assign to'
                                            sx={{ mt: '10px' }}
                                            assignToState={assignToState}
                                            setAssignTo={setAssignToState}
                                        />
                                    </>
                                }
                            </>}
                            {data?.field_type?.field_type === 'char' && <>
                                <CustomDescriptionComponent description={data.description} />
                                {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                {(data?.field_type?.attribute !== 'phone' && data?.field_type?.attribute !== 'lead_email' && data?.field_type?.attribute !== 'lead_address') && <RHFTextField
                                    name={`char_${[data.id]}`}
                                    inputProps={{ maxLength: data?.field_type?.max_length }}
                                />}
                                {data?.field_type?.attribute === 'phone' && <>
                                    <Box sx={testFieldWrapperStyle}>
                                        <RHFTextField
                                            name={'phone_number'}
                                            type='string'
                                            inputProps={{ maxLength: 15 }}
                                            onKeyDown={(evt) => numberKeyDownFunc(evt)}
                                        />
                                    </Box>
                                </>}
                                {data?.field_type?.attribute === 'lead_email' && <>
                                    <Box sx={testFieldWrapperStyle}>
                                        <RHFTextField name={'email'} />
                                    </Box>
                                </>}
                                {data?.field_type?.attribute === 'lead_address' && <>
                                    <CustomAdressComponent control={control} setValue={setValue} values={values} />
                                </>}
                            </>}
                            {data?.field_type?.field_type === 'boolean' && <>
                                <CustomDescriptionComponent description={data.description} />
                                {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                <RHFRadioGroup
                                    name={`boolean_${[data.id]}`}
                                    options={[
                                        { label: 'Yes', value: 'yes' },
                                        { label: 'No', value: 'no' },
                                    ]}
                                    size='small'
                                    sx={{ margin: '-6px 2px 0', width: '100%' }}
                                />
                            </>}
                            {data?.field_type?.field_type === 'choice' && <>
                                <CustomDescriptionComponent description={data.description} />
                                {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                {data?.field_type?.attribute !== 'lead_source_type' && <>
                                    {data?.field_type?.ui_type === 'DROPDOWN' && <CustomDropdownComponent data={data} />}
                                    {data?.field_type?.ui_type === 'MULTI_SELECT' &&
                                        <CustomMultiSelectComponent data={data} methods={methods} />
                                    }
                                    {data?.field_type?.ui_type === 'CHECKBOX' && (
                                        <CustomCheckboxComponent methods={methods} data={data} />
                                    )}
                                    <CustomRadioGroupComponent methods={methods} data={data} />
                                </>}
                                <CustomLeadInquiryFields values={values} data={data} handleChange={(event, data) => setValue(data, event.target.value)} handleReferral={handleReferral} referralValue={referralValue} />
                            </>}
                            {data?.field_type?.field_type === 'datetime' && <>
                                <CustomDescriptionComponent description={data.description} />
                                {data?.field_type?.is_name_visible && <CustomLabelComponent data={data} />}
                                <ScriptDateTimePicker data={data} />
                                <CustomScriptMobileDatePicker data={data} control={control} />
                            </>}
                        </Box>
                    )
                })
            }
        </>
    );

    return (
        <Box sx={{ position: 'relative' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Card sx={{ maxWidth: expand ? '100%' : '1000px', mx: 'auto' }}>
                    <SingleScriptHeader
                        backButtonClick={backButtonClick}
                        expand={expand}
                        setExpand={setExpand}
                    />
                    <Box sx={{ p: '24px 24px 0' }}>
                        {fieldData?.map((item, index) => (
                            <Box sx={{ mb: '30px' }} key={index}>{RenderFieldItem(item, index)}</Box>
                        ))}
                        {newtreatmentData && InboundScripts?.length > 1 && <RHFSelect
                            name='template'
                            label='Select Template'
                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            onChange={(e) => templateRenderFunc({ value: e.target.value, setValue, InboundScripts, setSelectedFormId, setTempFieldData, fieldData })}
                            sx={{ mb: '30px', '.MuiOutlinedInput-notchedOutline': { borderColor: 'common.slateGray' } }}
                        >
                            {InboundScripts && InboundScripts.map((item, index) => (
                                <MenuItem value={item.id} key={index}>{capitalizeFirstLetter(item.name)}</MenuItem>
                            ))}
                        </RHFSelect>}
                        {tempFieldData?.map((item, index) => (
                            <Box sx={{ mb: '30px' }} key={index}>{RenderFieldItem(item, index)}</Box>
                        ))}
                    </Box>
                    <Box sx={{ p: '16px 24px 24px', display: 'flex', justifyContent: 'center' }}>
                        <LoadingButton type='submit' variant='contained' sx={unableContactButtonStyle} loading={submitLoading} loadingPosition='start'>
                            <Iconify style={loaderButtonStyle(submitLoading)} icon={'formkit:submit'} width={20} height={20} /> Submit
                        </LoadingButton>
                    </Box>
                    {Object.keys(errors).length > 0 && <Box sx={{ display: 'flex', justifyContent: 'center', m: '0 0 24px' }}>
                        <Typography sx={{ color: error.main, fontSize: '0.75rem' }}>Please fill the required fields.</Typography>
                    </Box>}
                </Card>
            </FormProvider>
        </Box>
    );
};

DefaultScriptView.propTypes = {
    onClose: PropTypes.func,
    scriptData: PropTypes.array,
    backButtonClick: PropTypes.func,
};

export default DefaultScriptView;