/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
    TableRow,
    TableCell,
    Typography,
} from '@mui/material';
// use components
import { Label, Iconify } from '../../../components';

// ---------------------------------------------------------
export default function PaymentTableRow({ row, selected }) {
    const theme = useTheme();

    return (
        <TableRow hover selected={selected} sx={{ cursor: 'pointer', borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
            <TableCell sx={{ alignItems: 'center' }}>
                <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    {row.created_at_human}
                </Label>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row.payment_type}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row.mode_of_payment}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'material-symbols:currency-pound'} width={16} height={16} /> {row.amount}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

PaymentTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
};
