export const tableLabels = [
    { id: ['year', 'month'], label: 'Date', align: 'left', sort: 'Date' },
    { id: ['treatment_name'], label: 'Treatment', align: 'left', sort: 'treatment_name' },
    { id: ['leads'], label: 'Enquiries', align: 'center', sort: 'leads' },
    { id: ['consultation_target'], label: 'Consultation target', align: 'center', sort: 'consultation_target' },
    { id: ['consultations'], label: 'Consultations', align: 'center', sort: 'consultations' },
    { id: ['treatment_targets'], label: 'Go ahead target', align: 'center', sort: 'treatment_targets' },
    { id: ['treatments'], label: 'Go aheads', align: 'center', sort: 'treatments' },
];

export const tableOptions = [
    'Table view',
    'Graph view'
];

export const statuses = [
    { label: 'New', value: 'NEW' },
    { label: 'Consultation booked', value: 'CONSULTATION_BOOKED' },
    { label: 'Consultation attended', value: 'CONSULTATION_ATTENDED' },
    { label: 'Treatment started ', value: 'TREATMENT_STARTED' }
];

export const TABLE_HEAD = [
    { id: 'Treatments', label: 'Treatments' },
    { id: 'Consultation target', label: 'Consultation target' },
    { id: 'Go ahead target', label: 'Go ahead target' },
];

export const SCHEDULE_SETTINGS_TABLE_HEAD = [
    { id: 'Report type', label: 'Report type' },
    { id: 'interval', label: 'Interval' },
    { id: 'enabled', label: 'Enabled' },
    { id: '', },
];

export const INTERVAL = [
    {
        label: 'Daily',
        value: 1
    },
    {
        label: 'Weekly',
        value: 7
    },
    {
        label: 'Monthly',
        value: 30
    },
    {
        label: 'Yearly',
        value: 365
    },
];