import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
// @mui
import {
    Stack,
    Button,
    Box,
    Card,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    TableHead
} from '@mui/material';
// Form
import { useForm } from 'react-hook-form';
import moment from 'moment';
// hooks
import { useTabs } from '../../../hooks';
// components
import { NormalLoading } from '../../../components/loading';
import { Label, Scrollbar } from '../../../components';
import ClinCheckForm from '../../../components/clinCheck/ClinCheckForm';
import { FormProvider } from '../../../components/hook-form';
// redux
import { useSelector } from '../../../redux/store';
// utils
import { getClinCheckStatus, getUserNameById, isDisabledTab } from '../../../utils/clinCheckUtils';
// Style
import { activityCard } from '../../../styles/OverView';
import {
    saveButtonStyle
} from '../../../styles/AddReminderPopup';

const LeadDetailClinCheck = ({ leadDetail, reloadClinCheck }) => {
    const inputRef = useRef();
    const { clinCheckListing, clincheckLoading } = useSelector((state) => state.clinCheck);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { currentTab, onChangeTab } = useTabs('');

    const labels = [
        { id: 'status', label: 'Status' },
        { id: 'recorded_at', label: 'Recorded at' },
        { id: 'recorded_by', label: 'Recorded by' },
    ];

    const clinCheckData = clinCheckListing?.results?.[0];

    const defaultValues = {
        clinCheckStatus: ''
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        setValue,
        handleSubmit,
    } = methods;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        if (clinCheckData?.status) {
            setValue('clinCheckStatus', clinCheckData?.status);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clinCheckData]);

    const onSubmit = () => {
        inputRef.current.submit();
    };

    const clincheckLogs = clinCheckListing?.results?.[0]?.leadtreatment_clincheck_logs;

    return <Box>
        <Card sx={activityCard({ notAllow: false, type: 'leadDetail' })}>
            <NormalLoading type={'treatment'} tableData={clincheckLoading && 'No Found'} pageCount={clincheckLoading && 'No Found'} />
            <Box sx={{ p: 0, bgcolor: 'transparent', width: '100%' }}>
                <FormProvider FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack >
                        <ClinCheckForm ref={inputRef} leadDetail={leadDetail} page={'lead_detail'} handleClose={reloadClinCheck} currentTab={currentTab} onChangeTab={onChangeTab} />
                    </Stack>
                    <Box sx={{
                        mt: 1, display: 'flex',
                        justifyContent: 'end'
                    }}>
                        <Button
                            color="primary"
                            variant='contained'
                            type='submit'
                            sx={saveButtonStyle}
                            disabled={isDisabledTab(clinCheckData?.status, currentTab)}
                        >
                            Save
                        </Button>
                    </Box>
                </FormProvider >
                <Card sx={{ mt: 2 }}>
                    <Box>
                        <Scrollbar sx={{ pl: '0', pr: '0' }}>
                            <TableContainer sx={{ maxHeight: '457px', position: 'relative', minHeight: '457px' }}>
                                <Table stickyHeader >
                                    <TableHead >
                                        <TableRow sx={{ borderRadius: '0' }}>
                                            {labels?.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={'center'}
                                                >
                                                    {headCell?.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {clincheckLogs?.length > 0 && clincheckLogs.map((ele, index) => <TableRow key={index} sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)' }} hover ariaLabel='table-row' >
                                            <TableCell align="center">
                                                <Label
                                                    sx={{
                                                        px: 2,
                                                        backgroundColor: 'rgba(84, 214, 44, 0.16)',
                                                        color: '#229A16',
                                                    }}
                                                >
                                                    <Typography variant='05 Others/Label'>
                                                        <span>{getClinCheckStatus(ele?.status)}</span>
                                                    </Typography>
                                                </Label>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="subtitle2">
                                                    <span>{ele?.recorded_at ? moment(ele?.recorded_at, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY') : '-'}</span>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="subtitle2">
                                                    <span>{ele?.recorded_by ? getUserNameById(practiceUser, ele?.recorded_by) : '-'}</span>
                                                    {/* <span>{ele?.recorded_by ? ele?.recorded_by : '-'}</span> */}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Box>
                </Card>
            </Box>
        </Card></Box>;
};

export default LeadDetailClinCheck;

LeadDetailClinCheck.propTypes = {
    leadDetail: PropTypes.any,
    reloadClinCheck: PropTypes.func,
};