import { createSlice } from '@reduxjs/toolkit';
//
import { isEmpty } from 'lodash';
import { dispatch, store } from '../store';
// slice
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';

// -----------------------------------------------

const initialState = {
    isLoading: false,
    clinCheckListing: [],
    clincheckLoading: false
};

const slice = createSlice({
    name: 'clinCheck',
    initialState,
    reducers: {
        clearClinCheckData(state) {
            Object.assign(state, initialState);
        },
        setClinCheckListing(state, action) {
            state.clinCheckListing = action.payload;
        },
        setClincheckLoading(state, action) {
            state.clincheckLoading = action.payload;
        }
    }
});

export default slice.reducer;

export const { clearClinCheckData, setClinCheckListing, setClincheckLoading } = slice.actions;

export const getPracticeId = () => store.getState()?.practiceTreatmentList?.practiceId;

export const getCurrentTreatmentIdV2 = (id) => {
    const { currentTreatmentDataGlobal } = store.getState().multiTreatment;
    if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) return currentTreatmentDataGlobal?.treatmentData?.id;
    return id;
};

export function getClinCheckList(data, handleClose) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '&limit=10';
            const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}` : '';
            const param = `${limit}${offset}`;
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data.leadId}/treatments/${getCurrentTreatmentIdV2(data.treatmentId)}/clincheck/?ordering=-created_at${param}`);
            dispatch(setClincheckLoading(false));
            if (response.status === 200) {
                dispatch(slice.actions.setClinCheckListing(response.data));
                if (handleClose) {
                    handleClose(response.data.count);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(setClincheckLoading(false));
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function handleClinCheckInProgressStatus(data, callback) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data.leadId}/treatments/${data.treatmentId}/clincheck/`, 'post', {});
            if (response.status === 201) {
                if (callback) {
                    callback({ clinCheckId: response.data?.id, payload: data?.payload, status: data?.status });
                }
                if (data?.shouldShowMessage) {
                    dispatch(updateError({ success: true, message: 'ClinCheck status updated successfully' }));
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function handleClinCheckUploadedStatus(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data.leadId}/treatments/${data.treatmentId}/clincheck/${data?.clinCheckId}/to_uploaded/`, 'post', data?.payload || {});
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'ClinCheck status updated successfully' }));
                if (handleClose) {
                    handleClose('finish', true);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function handleClinCheckReceivedStatus(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data.leadId}/treatments/${data.treatmentId}/clincheck/${data?.clinCheckId}/to_received/`, 'post', data?.payload || {});
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'ClinCheck status updated successfully' }));
                if (handleClose) {
                    handleClose('finish', true);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function handleClinCheckSentStatus(data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data.leadId}/treatments/${data.treatmentId}/clincheck/${data?.clinCheckId}/to_sent/`, 'post', data?.payload || {});
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'ClinCheck status updated successfully' }));
                if (handleClose) {
                    handleClose('finish', true);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}