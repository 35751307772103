import { common, grey } from 'src/theme/palette';

export const noAudienceBoxStyle = (isLight) => ({
    mt: '0px',
    mb: '24px',
    bgcolor: isLight ? grey[200] : grey[800],
    borderRadius: 1,
    border: '1px dashed rgba(145, 158, 171, 0.32)',
    p: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 440px)'
});

export const createScriptButtonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: '50px',
    '@media(max-width: 767px)': {
        justifyContent: 'center'
    }
};

export const saveButtonStyle = {
    minWidth: '137px',
    minHeight: '48px',
    borderRadius: '8px',
    fontSize: '15px',
    '@media(max-width: 767px)': {
        minWidth: '120px',
    }
};

export const cancelButtonStyle = {
    minWidth: '82px',
    minHeight: '48px',
    mr: '40px',
    bgcolor: 'transparent',
    boxShadow: 'none',
    border: '1px solid rgba(145, 158, 171, 0.24)',
    borderRadius: '8px',
    fontSize: '15px',
    color: grey[800],
    '@media(max-width: 767px)': {
        minWidth: '120px',
    }
};

export const textFieldStyle = {
    '& fieldset': {
        borderColor: 'rgba(145, 158, 171, 0.32)'
    },
    width: '100%',
    maxWidth: '320px',
    borderRadius: '8px',
    boxShadow: 'none',
    ml: '0',
    minWidth: '320px',
    '.MuiInputBase-root': {
        pr: 0
    },
    'input': {
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        padding: '10px 14px',
    },
    '@media (max-width: 1199px)': {
        ml: '0',
        width: '100%',
    },
    '@media (max-width: 767px)': {
        mb: '10px'
    }
};

export const searchIconStyle = {
    background: 'rgba(208, 242, 255, 0.80)',
    margin: 0,
    width: '40px',
    minWidth: '40px',
    height: '40px',
    maxHeight: '40px',
    justifyContent: 'center',
    borderRadius: '0px 8px 8px 0px'
};

export const validationResultPopupStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { height: 'auto', borderRadius: '16px', maxHeight: '90vh', maxWidth: '95vw' },
    ' .MuiPaper-root': { width: '100%', height: 'auto', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', minWidth: '500px' }
};

export const validationTableHead = {
    fontSize: '14px',
    color: grey[600],
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center'
};

export const reportsAudienceStack = {
    width: '95vw', overflow: 'hidden', maxHeight: 'calc(100vh - 100px)'
};

export const reportsAudienceTitleWrapper = { ml: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' };
