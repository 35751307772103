/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell } from '@mui/material';
// components
import { LeadListPopup } from '../dialogPopUp';
// store
import { setAudienceData } from '../../redux/slices/audienceManager';
import { clearPopupLead, getLead } from '../../redux/slices/analytics';
import { dispatch, useSelector } from '../../redux/store';
// Hooks/constants/utils
import { useBoolean } from '../../hooks';
import { LeadLabelsForReports } from '../../constants/TableDataConst';
import { TableOrder, TableOrderBy } from '../../utils/allTableTab';
// styles
import { common } from '../../theme/palette';
import { analyticsTableCell, analyticsTableRowStyle } from '../../styles/analytics';

const CategorySummaryRow = ({ data }) => {
    const { results } = useSelector((state) => state.practiceTreatment?.practiceTreatment);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { leads, dateRange, notBookedColumnData, enquiryColumnData } = useSelector((state) => state.analytics);
    const [rowData, setRowData] = useState({
        status: '',
        categoryId: ''
    });
    const open = useBoolean();

    const tableOrder = TableOrder();
    const tableOrderBy = TableOrderBy();

    const handleRowClick = (data, status) => {
        setRowData({
            status,
            categoryId: data[0],
        });
        open.onTrue();
        dispatch(
            getLead({
                id: practiceId,
                categoryId: data[0],
                status,
                limit: 10,
                offset: 0,
                order: tableOrder,
                orderId: tableOrderBy,
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
            })
        );
        const updatedCampaign = {
            recepient: {
                filters: {
                    created_at__range: [dateRange?.startDate, dateRange?.endDate],
                    lead_treatments__treatment__treatment_item__treatment_category__in: [Number(data[0])],
                },
            },
        };
        if (status && status !== 'All') {
            updatedCampaign.recepient.filters = { ...updatedCampaign.recepient.filters, lead_treatments__status__in: [status] };
        }
        dispatch(setAudienceData(updatedCampaign));
    };

    const handlePagination = (data) => {
        dispatch(
            getLead({
                id: practiceId,
                categoryId: rowData.categoryId,
                status: rowData.status,
                limit: data.limit,
                offset: data.offset,
                order: data.order,
                orderId: data.orderId,
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
            })
        );
    };

    const handleClose = () => {
        open.onFalse();
        dispatch(clearPopupLead());
        dispatch(setAudienceData({}));
    };

    return (
        <>
            <TableRow hover sx={analyticsTableRowStyle}>
                <TableCell sx={{ minWidth: '100px' }}>
                    {
                        results.filter((el) => el.treatment_item?.treatment_category?.id === Number(data[0]))[0]?.treatment_item?.treatment_category?.name || ''
                    }
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'All')}>
                    {enquiryColumnData?.data?.filter((el) => el.lead_treatments__treatment__treatment_item__treatment_category === Number(data[0]))[0]?.count_id || 0}
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'NEW')}>
                    {notBookedColumnData?.data?.filter((el) => el?.lead_treatments__treatment__treatment_item__treatment_category === Number(data[0]))[0]?.count_id || 0}
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'CONSULTATION_BOOKED')}>
                    {data[1]?.filter((el) => el?.lead_treatments__state_transition_logs__state === 'CONSULTATION_BOOKED')[0]?.count_id || 0}
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'CONSULTATION_ATTENDED')}>
                    {data[1]?.filter((el) => el?.lead_treatments__state_transition_logs__state === 'CONSULTATION_ATTENDED')[0]?.count_id || 0}
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'TREATMENT_STARTED')}>
                    {data[1]?.filter((el) => el?.lead_treatments__state_transition_logs__state === 'TREATMENT_STARTED')[0]?.count_id || 0}
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'FAILED_TO_ATTEND_CONSULTATION')}>
                    {data[1]?.filter((el) => el?.lead_treatments__state_transition_logs__state === 'FAILED_TO_ATTEND_CONSULTATION')[0]?.count_id || 0}
                </TableCell>
                <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'CLOSED')}>
                    {data[1]?.filter((el) => el?.lead_treatments__state_transition_logs__state === 'CLOSED')[0]?.count_id || 0}
                </TableCell>
                <TableCell sx={{ textAlign: 'right', color: common.mintyZest }}>
                    £{data[1]?.reduce((total, currentValue) => total += currentValue?.sum_lead_treatments__custom_price, 0).toLocaleString() || 0}
                </TableCell>
                {open && <LeadListPopup
                    open={open?.value}
                    onClose={handleClose}
                    type={'reportTableData'}
                    updateLeadPage={(data) => handlePagination(data)}
                    practiceId={practiceId}
                    count={leads?.count}
                    LeadLabelsForReports={LeadLabelsForReports}
                    data={leads?.results}
                    rowStatus={rowData?.status}
                    categoryId={rowData?.categoryId}
                    dateRange={dateRange}
                    iscategorySummary
                />}
            </TableRow>
        </>
    );
};

CategorySummaryRow.propTypes = {
    data: PropTypes.any,
};

export default CategorySummaryRow;
