export const TypoNewUserStyle = {
    color: 'text.primary',
    fontWeight: '700',
    fontSize: '26px',
    lineheight: '30.55px',
    alignItem: 'center',
};
export const settingsMainBoxStyle1 = {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: '600px',
    '.MuiFormLabel-root': {
        fontSize: '13px'
    },
    '.MuiInputBase-input': {
        fontSize: '13px'
    }
};
export const TypoContentAddPractioner = {
    fontSize: '14px',
    lineheight: '22px',
    fontWeight: '600',
    fontFamily: 'Public Sans',
    color: '#091A7A',
};
export const TypoContentInnerAddPractioner = {
    fontSize: '14px',
    lineheight: '22px',
    fontWeight: '700',
    fontFamily: 'Public Sans',
    color: '#091A7A',
};
export const ShareDetailBox = {
    display: 'flex',
    gap: '12px',
    mb: '0',
};
export const ShareBoxLoginDetails = {
    bgcolor: '#CCFAFF',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px',
    padding: '8px 16px 8px 12px',
    gap: '12px',
};
export const ShareDetailTypo = {
    fontFamily: 'Public Sans',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '0',
    textAlign: 'left'
};
export const ButtonAssistCard = {
    padding: '4px 8px',
    borderRadius: '8px',
    gap: '8px',
    bgcolor: 'info.main',
    textTransform: 'none !important',
    minWidth: 216,
    '&:hover': {
        bgcolor: 'info.dark',
    }
};
export const ButtonTypoDetails = {
    fontFamily: 'Public Sans',
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '22px',
    color: '#FFFFFF',
};
export const ButtonComboStyle = {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
    gap: '24px'
};
export const ShareStyleTypo = {
    margin: '24px 0',
    fontFamily: 'Public Sans',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
};
export const CardStyleTypo = {
    marginTop: '16px',
    p: 2,
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: 'common.blueishGray',
    boxShadow: 'none',
    mb: 2
};
export const CardStyleInner = {
    p: 3,
    bgcolor: '#EDEFF2',
    borderRadius: '8px',
    padding: '16px',
    gap: '12px'
};
export const DentalTypoStyle = {
    fontFamily: 'Public Sans',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px',
    color: '#091A7A'
};
export const DentalTypoStyleTwo = {
    fontFamily: 'Public Sans',
    fontSize: '14px',
    fontWeight: '400',
    color: '#091A7A',
    lineHeight: '24px',
};
export const CancelButtonStyle = {
    width: '82px',
    height: '48px',
    padding: '11px 16px 11px 16px',
    gap: '8px'
};
export const AddUserButton = {
    display: 'flex',
    gap: '8px',
    width: '128px',
    height: '48px',
    padding: '11px 16px 11px 16px',
    borderRadius: '8px',
    textTransform: 'none !important'
};
export const passwordStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    pointerEvents: 'none',
};
export const stylePassword1 = {
    width: '100%',
    margin: '0',
    bgcolor: '#919EAB29',
    pointerEvents: 'none',
    padding: '10px 10px 10px 10px',
    borderRadius: '8px'
};
export const EmailStyleBox = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    mb: '16px'
};
