import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Stack } from '@mui/material';
import { useSelector } from '../../../../redux/store';
import { getTextColor } from '../../../../utils/getClassName';

const CustomThreadLabels = ({ handleDelete }) => {
    const { threadLabels } = useSelector((state) => state.mail);

    return (
        <Box sx={{ p: '12px 0' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
                    {threadLabels &&
                        threadLabels?.results?.map((item, index) =>
                            <Chip
                                key={index}
                                style={{ backgroundColor: item?.label?.colour, color: getTextColor(item?.label?.colour) }}
                                label={item?.label?.name}
                                variant="outlined"
                                onDelete={() => handleDelete(item)} />
                        )}
                </Stack>
            </Box>
        </Box>
    );
};

CustomThreadLabels.propTypes = {
    handleDelete: PropTypes.func,
};

export default CustomThreadLabels;