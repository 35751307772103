import { common, grey, primary } from 'src/theme/palette';

export const toggleButtonStyle = (isLight) => ({
    borderRadius: '50px !important',
    border: '1px solid rgba(145, 158, 171, 0.32) !important',
    m: '4px 16px 4px 0px !important',
    p: '4px 12px 4px 5px',
    height: '32px',
    color: isLight ? grey[800] : grey[400],
    bgcolor: 'transparent !important',
    fontWeight: '400',
    textTransform: 'none !important',
    '&.Mui-selected': {
        borderColor: `${primary.main} !important`,
        pointerEvents: 'none'
    }
});
export const toggleButtonStyleV2 = (isLight) => ({
    borderRadius: '50px !important',
    border: `solid 1px ${primary.btn} !important`,
    p: '4px 12px 4px 5px',
    height: '32px',
    color: isLight ? grey[700] : grey[400],
    bgcolor: 'transparent !important',
    fontWeight: '400'
});
export const reminderTitleStyle = {
    fontWeight: '700',
    fontSize: '18px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
};

export const stackStyle = {
    width: '100%',
    justifyContent: 'flex-start'
};

export const timelineItemStyle = {
    '::before': {
        display: 'none'
    },
    marginBottom: 2,
    borderRadius: 2,
    p: '20px',
    border: '1px dashed',
    borderColor: 'primary.paperColor'
};

export const yesBtnStyleForLoader = (isLoader) => ({
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
    bgcolor: isLoader ? '' : common.mintyZest,
});

export const appointmentTypoStyle = {
    fontSize: '16px !important', 
    fontWeight: '600', 
    mb: 2
};

export const assignToUserBoxStyle = {
    minHeight: '53px', 
    display: 'flex', 
    alignItems: 'center'
};