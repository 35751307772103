import { styled } from '@mui/material/styles';

export const FollowUpTitle = styled('div')(() => ({
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px'
}));
export const gridStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 2,
    mt: 2,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
    },
};
export const menuItemStyle = {
    mx: 1,
    my: 0.5,
    borderRadius: 0.75,
    typography: 'body2',
    textTransform: 'capitalize',
};
export const boxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    mb: 2,
    gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)'
    },
};