/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
    TableRow,
    TableCell,
    Typography,
    Button
} from '@mui/material';
// components
import { Label } from '../../../components';
// style
import { RefundButtonStyle } from '../../../styles/PaymentRefund';

export default function CheckoutTableRow({ row, selected, handleRefundClick }) {
    const theme = useTheme();

    return (
        <TableRow hover selected={selected} sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
            <TableCell sx={{ alignItems: 'center' }}>
                <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    {row.created_at_human}
                </Label>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row.payment_status}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row.status}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                {row.payment_status !== 'unpaid' ?
                    <Button
                        color="inherit"
                        variant='outlined'
                        onClick={() => handleRefundClick(row.id)}
                        className="back-button"
                        sx={RefundButtonStyle}
                    >
						Refund
                    </Button> : null}
            </TableCell>
        </TableRow>
    );
};

CheckoutTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    handleRefundClick: PropTypes.any
};