export const dialogBoxStyle = {
    width: '484px',
    minHeight: '352px',
    height: '100%',
    p: '25px 0 10px',
};

export const formOuterBoxStyle = {
    py: 2,
    px: 3, 
    display: 'flex',
    flexDirection: 'column'
};

export const btnBoxStyle = {
    display: 'flex',
    m: 0,
    p: 0,
    justifyContent: 'right',
    mt: 4
};