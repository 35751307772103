import { common, grey } from 'src/theme/palette';

export const widgetButtonStyle = {
    border: '1px solid',
    borderColor: 'common.borderColor',
    padding: '11px 16px 11px 16px',
    borderRadius: '16px',
};

export const widgetCardStyle = {
    width: 'calc((100% - 80px) / 6)',
    backgroundColor: common.white,
    display: 'flex',
    flexFlow: 'column',
    gap: '8px',
    padding: '12px 16px 12px 12px',
    borderRadius: '16px',
    cursor: 'pointer',
    color: grey[800],
    '@media (max-width: 991px)': {
        width: 'calc((100% - 33px) / 3)',
        wordBreak: 'break-all',
    },
    '@media (max-width: 576px)': {
        width: 'calc((100% - 16px) / 2)',
    }
};

export const widgetCardWrapperStyle = {
    display: 'flex',
    width: '100%',
    gap: '16px',
    marginBottom: '32px',
    '@media (max-width: 991px)': {
        flexWrap: 'wrap'
    }
};

export const widgetButtonWrapperStyle = {
    display: 'flex',
    gap: '8px',
    mb: 2,
    '&.widgetButtonWrapper .widgetButton': {
        display: 'none'
    }
};

export const widgetTextStyle = {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0px',
    textAlign: 'left',
    marginLeft: '6px',
};

export const widgetCounterTextStyle = {
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '30.55px',
    marginLeft: '12px',
};

export const widgetCounterWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const widgetIconWrapper = {
    width: '40px', height: '40px', borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export const widgetLoadingStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight:"80px"
}