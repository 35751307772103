import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { dispatch } from '../store';
import { updateError } from './user';

const initialState = {
    isLoading: false,
    generatedUrl: '',
    appointmentFeedbackList: {
        results: [],
        count: 0
    },
    isLoadingFinish: false
};

const slice = createSlice({
    name: 'feedbackReview',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        updateLoader(state, action) {
            state.isLoading = action.payload;
        },
        updateGeneratedUrl(state, action) {
            state.generatedUrl = action.payload;
        },
        updateAppointmentFeedbackList(state, action) {
            state.appointmentFeedbackList = {
                results: action.payload.results,
                count: action.payload.count
            };
        },
        startLoadingData(state, action) {
            state.isLoadingFinish = action.payload;
        },
    }
});

export default slice.reducer;

export const {
    setFeedbackList,
    updateGeneratedUrl,
    reset,
    updateAppointmentFeedbackList,
    updateLoader,
    startLoadingData
} = slice.actions;

export const getListOfRatting = (params) => async () => {
    const { practiceId, limit, offset } = params;
    dispatch(updateLoader(true));
    try {
        const response = await callApi(`/api/v1/practices/${practiceId}/leads/?ordering=-updated_at&lead_treatments__appointments__appointment_feedback__isnull=false&limit=${limit}&offset=${offset}&fields={id,first_name,last_name,updated_at,lead_treatments{appointments{appointment_feedback,datetime}}}`);
        dispatch(updateAppointmentFeedbackList(response.data));
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(slice.actions.hasError(error?.message));
        dispatch(updateLoader(false));
    }
};

export function generatingFeedbackUrl(params) {
    dispatch(updateGeneratedUrl(''));
    const { practiceId, leadId, id } = params;
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/appointments/${id}/generate_feedback_url/`, 'post');
            if (response.status === 200) {
                dispatch(updateGeneratedUrl(response.data.url));
                dispatch(updateError({ success: true, message: 'Feedback link generated successfully' }));
                dispatch(startLoadingData(false));
                return;
            }
            dispatch(startLoadingData(false));
            dispatch(updateError({ message: JSON.stringify(response.data || 'Something went wrong'), success: false }));
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message || 'Something went wrong'), success: false }));
        }
    };
}

export function handleSubmitFeedback(params) {
    return async () => {
        try {
            const response = await callApi('/api/v1/appointments/receive_feedback/', 'post', params.data, true);
            if (response.status === 201) {
                params.handleDone();
                dispatch(startLoadingData(false));
                return;
            }
            dispatch(startLoadingData(false));
            dispatch(updateError({ message: JSON.stringify(response.data || 'Something went wrong'), success: false }));
        } catch (error) {
            dispatch(startLoadingData(false));
            dispatch(updateError({ message: JSON.stringify(error.message || 'Something went wrong'), success: false }));
        }
    };
}