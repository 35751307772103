/* eslint-disable arrow-body-style */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle } from 'react';
// @mui
import {
    Box,
    Grid,
    Card,
    Typography,
    Divider,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// hook-form
import { FormProvider, RHFTextField } from './hook-form';
import { dispatch, useSelector } from '../redux/store';
import { saveNewCampaign } from '../redux/slices/campaign';
// validation
import { CampaignInfoSchema } from '../validations/validations';

const CampaignInfo = forwardRef(({ handleNext }, ref) => {
    const {newCampaign, selectedCampaign} = useSelector((state) => state.campaign);
    const defaultValues = {
        campaignName: newCampaign?.campaignName || selectedCampaign.name || '',
    };

    const methods = useForm({
        resolver: yupResolver(CampaignInfoSchema),
        defaultValues,
    });

    const {
        handleSubmit,
    } = methods;

    const onSubmit = async (data) => {
        dispatch(saveNewCampaign({campaignName: data?.campaignName}));
        handleNext(true);
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <FormProvider methods={methods}>
            <Grid container spacing={1} sx={{ mt: '20px' }}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3, boxShadow: 'none' }}>
                        <Typography variant="h6" sx={{ marginBottom: '15px', fontSize: '24px !important' }}>
                            Campaign name
                        </Typography>
                        <Divider sx={{ marginBottom: '30px' }} />
                        <Box>
                            <RHFTextField name="campaignName" label="Campaign Name (Required)" />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </FormProvider>
    );
});

CampaignInfo.propTypes = {
    handleNext: PropTypes.func,
};

export default CampaignInfo;