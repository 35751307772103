/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
    TableRow,
    TableCell,
    Typography,
    Button
} from '@mui/material';
// use components
import { Label, Iconify } from '../../../components';
import { RetryButtonStyle } from '../../../styles/PaymentRefund';

export default function RefundTableRow({ row, selected, handleRetryClick }) {
    const theme = useTheme();

    return (
        <TableRow hover selected={selected} sx={{ borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
            <TableCell sx={{ alignItems: 'center' }}>
                <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    {row.created_at_human}
                </Label>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row.reason}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row.status}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Iconify icon={'material-symbols:currency-pound'} width={16} height={16} /> {row.amount}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row.description}</span>
                </Typography>
            </TableCell>

            <TableCell align="left">
                {row.status === 'Failed' ?
                    <Button
                        color="inherit"
                        variant='outlined'
                        onClick={() => handleRetryClick(row.id)}
                        sx={RetryButtonStyle}
                    >
						Retry
                    </Button> : null}
            </TableCell>
        </TableRow>
    );
};

RefundTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    handleRetryClick: PropTypes.any
};