import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { Iconify } from 'src/components';

const StartTreatmentMenuItem = ({ params, handleStartTreatment }) => {
    if (
        params.row.status === 'CONSULTATION_ATTENDED' ||
        params.row.status === 'CONSULTATION_BOOKED' ||
        params.row.status === 'NEW'
    ) {
        return (
            <MenuItem onClick={() => handleStartTreatment(params.row)}>
                <Iconify icon={'bxs:flag'} />
                Start Treatment
            </MenuItem>
        );
    }
    return null;
};

export default StartTreatmentMenuItem;

StartTreatmentMenuItem.propTypes = {
    params: PropTypes.object,
    handleStartTreatment: PropTypes.func,
};
