export const moreMenuStyle = (type, theme) => ({
    mt: -1,
    // eslint-disable-next-line no-nested-ternary
    width: type === 'FinancialTableRow' ? 230 : type === 'AppointmentRow' ? 250 : 200,
    '& .MuiMenuItem-root': {
        px: 1,
        typography: 'body2',
        borderRadius: 0.75,
        '& svg': { mr: 2, width: 20, height: 20 },
    },
    '.MuiButtonGroup-root:hover button': {
        backgroundColor: `${theme.palette.primary.dark} !important`,
    },
});

export const moreMenuIconButtonStyle = (theme) => ({
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.grey[800],
});