/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Box, Typography, Avatar, Paper, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import { FormProvider, RHFTextField } from './hook-form';
import Label from './Label';
import { TAB_HEAD } from '../constants/LeadDetailConst';
import { addNotes } from '../redux/slices/lead';
import { dispatch, useSelector } from '../redux/store';
// hooks
import { useAuth } from '../hooks';
import {
    box1Style,
    box2Style,
    box3Style,
    paperStyle,
    avatarStyle,
    avatarBox,
    dateTimeUI,
    noteTextFieldStyle,
    treatmentBoxStyle
} from '../styles/AccountSocialLinks';
import { AccountSocialLinkSchema } from '../validations/validations';


export default function RecentNotes({ leadDetail, LeadNotes }) {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { id } = useParams();
    const { user } = useAuth();

    const defaultValues = {
        addNotes: '',
        leadNotes: ''
    };

    const methods = useForm({
        resolver: yupResolver(AccountSocialLinkSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        getValues,
        setValue
    } = methods;

    const onSubmit = async () => {
        const details = {
            lead: id,
            additional_notes: getValues('addNotes'),
        };
        if (leadDetail?.lead_treatments?.length > 0) {
            details.treatment = leadDetail?.lead_treatments[0]?.id;
        }
        dispatch(addNotes(details, practiceId, '', 'leadNote'));
        setValue('addNotes', '');
    };

    return (
        <Box>
            <Box sx={{ mb: '16px' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>Recent Notes</Typography>
            </Box>
            <Box>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3} alignItems='flex-start' >
                        {LeadNotes && LeadNotes.length > 0 && LeadNotes?.slice(-1)?.map((options, index) => (
                            <Box
                                key={index}
                                sx={{ ...box1Style, mb: '6px !important' }}
                            >
                                <Avatar
                                    sx={{ height: '40px', width: '40px' }}
                                    src={options?.created_by && options?.created_by?.avatar || ''}
                                />
                                <Box sx={box2Style(isLight)}>
                                    <Box sx={box3Style}>
                                        {leadDetail?.lead_treatments?.length > 0 && <Label sx={{ color: 'black', backgroundColor: '#DFE3E8', p: '8px' }} >
                                            {`${TAB_HEAD.filter((el) => el?.value === leadDetail?.lead_treatments[0]?.status)[0]?.label}`}
                                        </Label>}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Paper
                                            name='leadNotes'
                                            variant="outlined"
                                            sx={paperStyle(isLight)}
                                        >
                                            {options?.additional_notes || options?.note || options?.lead_contact?.note || ''}
                                        </Paper>
                                    </Box>
                                    <Box sx={{ ...treatmentBoxStyle(), mt: '4px' }} >
                                        <Box sx={dateTimeUI} >
                                            {moment(options?.updated_at, 'DD/MM/YYYY HH:mm:ss').format('DD MMM YYYY [at] hh:mm a')}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                    <Box sx={avatarBox}>
                        <Avatar sx={avatarStyle} alt="J" src={user?.avatar || ''} />
                        <RHFTextField sx={noteTextFieldStyle} size="small" name='addNotes' multiline rows={1} label='Leave a note...' placeholder='' />
                    </Box>
                    <Box sx={{ marginLeft: '0', mt: '12px' }}>
                        <Button type='submit' variant='contained' sx={{ float: 'right', fontSize: '14px' }} >comment</Button>
                    </Box>
                </FormProvider>
            </Box>
        </Box>
    );
}

RecentNotes.propTypes = {
    leadDetail: PropTypes.any,
    LeadNotes: PropTypes.array
};