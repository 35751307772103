import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    Grid,
    Dialog,
    Container,
    Slide,
    Stack,
    Button,
    Box
} from '@mui/material';
// components
import AddRefundPayment from '../../sections/@dashboard/leadDetail/AddRefundPayment';
// style
import {
    RefundPaymentDialogStyle,
    RefundPaymentStackStyle,
    RefundPaymentFooterStyle,
    CancelButtonStyle,
    SaveButtonStyle
} from '../../styles/PaymentRefund';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const RefundPaymentPopup = ({ open, onClose, leadId, treatmentId, paymentId, checkoutId, paymentAmount }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={RefundPaymentDialogStyle}
        >
            <Stack sx={RefundPaymentStackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                        <AddRefundPayment ref={inputRef} handleNext={onClose} leadId={leadId} treatmentId={treatmentId} paymentId={paymentId} checkoutId={checkoutId} paymentAmount={paymentAmount} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={RefundPaymentFooterStyle}>
                <Button
                    color="inherit"
                    sx={CancelButtonStyle}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant='contained'
                    onClick={handleNext}
                    sx={SaveButtonStyle}
                >
                    Refund
                </Button>
            </Box>
        </Dialog>
    );
};

export default RefundPaymentPopup;

RefundPaymentPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    leadId: PropTypes.func,
    treatmentId: PropTypes.func,
    paymentId: PropTypes.func,
    checkoutId: PropTypes.func,
    paymentAmount: PropTypes.func,
};
