/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, InputLabel, Typography, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CurrencyInput from 'react-currency-input-field';
import { Controller } from 'react-hook-form';
import PaymentTypeDropDown from './PaymentTypeDropDown';
import { paymentTypeOptions } from '../../constants/contactedVia';
import { AmountInputStyle, ErrorTextStyle, depositAmountLabelType } from '../../styles/PaymentRefund';

const DepositPaymentComponent = ({
    paymentMethod,
    isPaymentMandatory,
    handleChangePaymentMethod,
    handleChangeDepositAmount,
    depositAmount,
    amountError,
    additionalAmountError,
    depositPaidStatus,
    control,
    additionalAmount
}) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    if (isPaymentMandatory && !depositPaidStatus) return null;

    return (
        <Grid container rowSpacing={2} spacing={2}>
            <Grid item xs={12} sm={12} md={3} xl={3}>
                <Controller
                    name='paymentMethod'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <PaymentTypeDropDown
                                value={field.value}
                                handleChangePaymentMethod={handleChangePaymentMethod}
                                label='Payment Methods'
                                options={paymentTypeOptions}
                                error={error}
                            />
                        </>
                    )}
                />
            </Grid>
            {paymentMethod && <Grid item xs={12} sm={12} md={3} xl={3}>
                <Box sx={{ position: 'relative' }}>
                    <InputLabel id='demo-simple-select-label' sx={depositAmountLabelType}>
                        {paymentMethod === 'FULL_PAYMENT' ? 'Full payment amount' : paymentMethod === 'FINANCE_ONLY' ? 'Finance only amount' : 'Deposit amount'}
                    </InputLabel>
                    <CurrencyInput
                        labelId='demo-simple-select-label'
                        name='depositAmount'
                        placeholder='Enter Deposit amount'
                        value={depositAmount}
                        decimalSeparator={'.'}
                        thousandSeparator={','}
                        onValueChange={(value, name) => handleChangeDepositAmount(value, name)}
                        className='currency-input'
                        decimalsLimit={2}
                        decimalScale={2}
                        inputType={'text'}
                        prefix={'£ '}
                        style={AmountInputStyle(amountError, '', isLight)}
                        step={1}
                    />
                    {amountError && (
                        <Typography variant='p' sx={ErrorTextStyle}>
                            This field is required
                        </Typography>
                    )}
                </Box>
            </Grid>}
            {(paymentMethod === 'DEPOSIT_AND_GOCARDLESS' || paymentMethod === 'DEPOSIT_AND_STRIPE' || paymentMethod === 'DEPOSIT_AND_FINANCE') && <Grid item xs={12} sm={12} md={3} xl={3}>
                <Box sx={{ position: 'relative' }}>
                    <InputLabel id='deposit-amount-label' sx={depositAmountLabelType}>
                        {paymentMethod === 'DEPOSIT_AND_GOCARDLESS' ? 'Go cardless amount' : paymentMethod === 'DEPOSIT_AND_STRIPE' ? 'Stripe amount' : 'Finance amount'}
                    </InputLabel>
                    <CurrencyInput
                        labelId='deposit-amount-label'
                        name='additionalAmount'
                        placeholder={paymentMethod === 'DEPOSIT_AND_GOCARDLESS' ? 'Go cardless amount' : paymentMethod === 'DEPOSIT_AND_STRIPE' ? 'Stripe amount' : 'Finance amount'}
                        value={additionalAmount}
                        decimalSeparator={'.'}
                        thousandSeparator={','}
                        onValueChange={(value, name) => handleChangeDepositAmount(value, name)}
                        className='currency-input'
                        decimalsLimit={2}
                        decimalScale={2}
                        inputType={'text'}
                        prefix={'£ '}
                        style={AmountInputStyle(additionalAmountError, '', isLight)}
                        step={1}
                    />
                    {additionalAmountError && (
                        <Typography variant='p' sx={ErrorTextStyle}>
                            This field is required
                        </Typography>
                    )}
                </Box>
            </Grid>}
            {paymentMethod && <Grid item xs={12} sm={12} md={3} xl={3}>
                <Controller
                    name='paymentDate'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <DatePicker
                                value={field.value}
                                minDateTime={new Date()}
                                format={'dd/MM/yyyy'}
                                onChange={(newValue) => field.onChange(newValue)}
                                label='Payment Date'
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: !!error,
                                        helperText: error?.message,
                                        onKeyDown: (e) => e.preventDefault()
                                    },
                                }}
                            />
                        </>
                    )}
                />
            </Grid>}
        </Grid>
    );
};

export default DepositPaymentComponent;

DepositPaymentComponent.propTypes = {
    paymentMethod: PropTypes.string,
    isPaymentMandatory: PropTypes.bool,
    handleChangePaymentMethod: PropTypes.func,
    handleChangeDepositAmount: PropTypes.func,
    depositAmount: PropTypes.string,
    depositPaidStatus: PropTypes.bool,
    amountError: PropTypes.string,
    additionalAmountError: PropTypes.string,
    control: PropTypes.object,
    additionalAmount: PropTypes.number,
};