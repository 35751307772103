import React, { useEffect } from 'react';
// libraries
import { Box, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
// components
import { RHFSelect } from '../../hook-form';
// redux
import { saveNewAudience, updateSelectedAudience } from '../../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../../redux/store';
// constants
import { LEAD_SOURCE_TYPES } from '../../../constants/LeadSourceConst';
// styles
import { tagsBoxStyle } from '../../../styles/AutocompleteTags';
import {
    menuItemStyle,
} from '../../../styles/PersonalDetailsStyle';

const AudienceLeadSourceDropdown = () => {
    const { selectedAudienceData, newAudience } = useSelector((state) => state.audienceManager);
    const defaultValues = {
        source: '',
        type: ''
    };
    const methods = useForm({
        defaultValues,
    });

    const {
        watch,
        setValue
    } = methods;

    const values = watch();

    useEffect(() => {
        if (selectedAudienceData) {
            const statusValue = LEAD_SOURCE_TYPES?.find((el) => selectedAudienceData?.recepient?.filters?.lead_source__type__in?.includes(el.value))?.value;
            if (statusValue) {
                setValue('type', statusValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAudienceData]);

    const handleChange = (event, data) => {
        const {
            target: { value },
        } = event;
        setValue(data, value);
        const val = { lead_source__type__in: [value] };
        if (Object.values(selectedAudienceData).length > 0) {
            const updatedCampaign = { ...selectedAudienceData, recepient: { ...selectedAudienceData.recepient, filters: { ...selectedAudienceData?.recepient?.filters, ...val } } };
            dispatch(updateSelectedAudience(updatedCampaign));
            return;
        }
        dispatch(saveNewAudience({ lead_source__type__in: val, source__type: [value] }));
    };

    return <Box sx={tagsBoxStyle}>
        <RHFSelect
            name='type'
            label='Type'
            value={newAudience?.source__type || values.type}
            onChange={(e) => handleChange(e, 'type')}
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
        >
            {LEAD_SOURCE_TYPES.map((options, index) => (
                <MenuItem
                    key={index}
                    value={options.value}
                    sx={menuItemStyle}
                >
                    {options.label}
                </MenuItem>
            ))}
        </RHFSelect>
    </Box>;
};

export default AudienceLeadSourceDropdown;