import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import { RHFSelect } from '../hook-form';

const CustomConditionSelect = ({ name, label, value, list, conditionListing, handleChange, index }) => (
    <Box sx={{ mx: '24px', minWidth: '25%' }}>
        <RHFSelect
            name={name}
            label={label}
            value={value}
            onChange={(e) => handleChange(e, index)}
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
        >
            {list?.map((options, index) => (
                <MenuItem
                    key={index}
                    value={options.value}
                    disabled={conditionListing.some(e => e.name === options.value)}
                >
                    {options.name}
                </MenuItem>
            ))}
        </RHFSelect>
    </Box>
);
export default CustomConditionSelect;

CustomConditionSelect.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    list: PropTypes.any,
    conditionListing: PropTypes.any,
    handleChange: PropTypes.any,
    index: PropTypes.any
};