import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import { menuOption } from 'src/constants/Campaign';
import { RHFSelect } from '../hook-form';

const CustomRHFSelect = ({ value }) => <Box sx={{ mr: '24px', minWidth: '25%', pointerEvents: 'none' }}>
    <RHFSelect
        name='call'
        label=''
        value={value}
        SelectProps={{
            sx: { textTransform: 'capitalize' },
            IconComponent: () => null
        }}
    >
        {menuOption.map((ele, index) => <MenuItem
            key={index}
            value={ele}
        >
            {ele}
        </MenuItem>)}
    </RHFSelect>
</Box>;

export default CustomRHFSelect;

CustomRHFSelect.propTypes = {
    value: PropTypes.any
};