/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Table, TableBody, TableContainer, TextField, Card, Typography, ListItem, ListItemButton, ListItemText, List, Divider } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
// components
import SourceSummaryRow from './SourceSummaryRow';
import { TableHeadCustom, TableNoData } from '../table';
import { TableLoading } from '../loading';
import { FormProvider } from '../hook-form';
import { TreatmentDropdown } from '../Dropdowns';
import ReferralSummaryTable from './ReferralSummaryTable';
// constants
import { sourceTypeTableLabels } from '../../constants/SourceSummaryConst';
import {
    reportLeadStatuses,
    treatmentCategory,
    leadSourceType,
    sourceTypeSummary,
    referralSummary,
    leadSourceReferral,
    leadArchive
} from '../../constants/ReportsLeadConst';
import { LEAD_SOURCE_TYPES } from '../../constants/LeadSourceConst';
import { RangeShortcut, endDate, rangeShortcuts } from '../../constants/ReportSummaryConst';
// hooks
import useTable from '../../hooks/useTable';
// store
import { useSelector, dispatch } from '../../redux/store';
import { reportsLeadListing, setDateRange } from '../../redux/slices/analytics';
import { getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
import { getTemplateList } from '../../redux/slices/practiceMessage';
// style
import { TableheadStyle, sourceTypeTableHeadStyle, boxStyle, tableHeadStyle } from '../../styles/CategorySummaryTableStyle';
import { timeRageBoxStyle, referralSummaryBoxStyle } from '../../styles/TreatmentTimeRageStyle';

const SourceSummaryTable = () => {
    const methods = useForm();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { sourceTypeSummaryData, startTime: apiStartDate, endTime: apiEndDate } = useSelector((state) => state.analytics);
    const [tableRowdata, setTableRowdata] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [treatment, setTreatment] = useState('');
    const [treatmentId, setTreatmentId] = useState('');
    const [value, setValue] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]);

    const {
        order,
        onSort,
        orderBy,
    } = useTable({ defaultOrder: 'desc' });

    useEffect(() => {
        dispatch(
            setDateRange({
                startDate: moment(apiStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                endDate: moment(apiEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            })
        );
    }, []);

    useEffect(() => {
        if (practiceId) {
            const startTime = moment(new Date()).startOf('month').format('YYYY-MM-DD 00:00:00');
            const endTime = moment(new Date()).format('YYYY-MM-DD 23:59:00');
            dispatch(getTemplateList(practiceId));
            const data = {
                practiceId,
                type: sourceTypeSummary,
                category: leadSourceType,
                categoryStatus: reportLeadStatuses,
                startTime,
                endTime
            };
            const leadSourceReferralData = {
                practiceId,
                type: sourceTypeSummary,
                category: leadSourceType,
                categoryStatus: reportLeadStatuses,
                archivedCategory: leadArchive,
                isArchived: true,
                source: LEAD_SOURCE_TYPES[0].value,
                startTime,
                endTime
            };
            const treatmentData = {
                limit: 30,
                offset: 0
            };
            dispatch(reportsLeadListing(data));
            dispatch(reportsLeadListing(leadSourceReferralData));
            dispatch(getPracticeTreatmentList(practiceId, treatmentData));
        }
    }, [practiceId]);

    useEffect(() => {
        if (sourceTypeSummaryData) {
            const grouped = _.mapValues(_.groupBy(sourceTypeSummaryData?.data, 'lead_source__type'), arr => arr.map(obj => _.omit(obj, treatmentCategory)));
            const tableData = Object.entries(grouped).map((elem) => [elem[0], elem[1]?.map((el) => ({ ...el, label: LEAD_SOURCE_TYPES.filter((refEl) => el.lead_source__type === refEl.value)[0]?.label }))]);
            setTableRowdata(tableData.filter((el) => el[0] !== 'null'));
        }
    }, [sourceTypeSummaryData]);

    const handleFieldData = (typed, value) => {
        const changedStartTime = value[0] ? moment(value[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00') : '';
        const changedEndTime = value[1] ? moment(value[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:00') : '';
        const changedStartDate = value[0] ? moment(value[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        const changedEndDate = value[1] ? moment(value[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        dispatch(setDateRange({ startDate: changedStartDate, endDate: changedEndDate }));
        const payload = {
            practiceId,
            categoryStatus: reportLeadStatuses,
            treatment_id: (typed === 'treatments' && value?.id) ? value?.id : value ? treatmentId : '',
            startTime: typed === 'dateTime' ? changedStartTime || '' : startTime || '',
            endTime: typed === 'dateTime' ? changedEndTime || '' : endTime || '',
        };
        const data = {
            ...payload,
            type: sourceTypeSummary,
            category: leadSourceType,
        };
        const leadSourceReferralData = {
            ...payload,
            type: referralSummary,
            category: leadSourceReferral,
        };
        dispatch(reportsLeadListing(data));
        dispatch(reportsLeadListing(leadSourceReferralData));
        switch (typed) {
            case 'dateTime':
                setValue(value);
                setStartTime(changedStartTime);
                setEndTime(changedEndTime);
                break;
            case 'treatments':
                if (value?.name) {
                    setTreatment(value.name);
                    setTreatmentId(value.id);
                }
                if (!value) {
                    setTreatment('');
                    setTreatmentId('');
                }
                break;
            default:
                break;
        }
    };

    const handleSorting = (value) => {
        onSort(value);
        switch (value) {
            case 'enquiries': {
                const data = _.orderBy(tableRowdata, [o => o[1]?.reduce((total, currentValue) => total += currentValue?.count_id, 0)], [order]);
                setTableRowdata(data);
                return;
            }
            case 'category': {
                const data = _.orderBy(tableRowdata, [o => o[1][0]?.label], [order]);
                setTableRowdata(data);
                return;
            }
            case 'TOTAL_PRICE': {
                const data = _.orderBy(tableRowdata, [o => o[1]?.reduce((total, currentValue) => total += currentValue?.sum_lead_treatments__custom_price, 0)], [order]);
                setTableRowdata(data);
                return;
            }
            default:
                break;
        }
        const data = _.orderBy(tableRowdata, [o => o[1].filter(el => el.lead_treatments__status === value)[0]?.count_id || 0], [order]);
        setTableRowdata(data);
    };

    const RangeShortcutsPanel = ({ setValue, children }) => {
        const handleRangeClick = useCallback(
            (range) => setValue && buildHandleRangeClick(setValue)(range),
            [setValue],
        );
        return (
            <Box sx={{ display: 'flex', '@media(max-width: 900px)': { display: 'inline-block' } }}>
                <Box sx={{ m: 2 }} display='flex' gap={2}>
                    <div>
                        <Typography variant='overline'>Date range shortcuts</Typography>
                        <List>
                            {rangeShortcuts.map(({ range, label }) => (
                                <ListItem key={range} disablePadding>
                                    <ListItemButton onClick={() => handleRangeClick(range)}>
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <Divider orientation='vertical' />
                </Box>
                {children}
            </Box>
        );
    };

    const buildHandleRangeClick = (setValue) => (range) => {
        const today = dayjs();
        switch (range) {
            case RangeShortcut.allTime:
                setValue([null, null]);
                handleFieldData('dateTime', [null, null]);
                break;
            case RangeShortcut.thisWeek:
                setValue([today.startOf('week'), today.endOf('week')]);
                handleFieldData('dateTime', [today.startOf('week'), today.endOf('week')]);
                break;
            case RangeShortcut.lastWeek:
                setValue([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                handleFieldData('dateTime', [
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                break;
            case RangeShortcut.last7Days:
                setValue([today.subtract(1, 'week'), today]);
                handleFieldData('dateTime', [today.subtract(1, 'week'), today]);
                break;
            case RangeShortcut.currentMonth:
                setValue([today.startOf('month'), today.endOf('month')]);
                handleFieldData('dateTime', [today.startOf('month'), today.endOf('month')]);
                break;
            case RangeShortcut.prevMonth:
                setValue([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                handleFieldData('dateTime', [
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                break;
            case RangeShortcut.reset:
                setValue([null, null]);
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <Box sx={boxStyle}>
                <Box sx={{ width: '40%', '@media(max-width: 900px)': { width: '100%' } }}>
                    <FormProvider methods={methods}>
                        <TreatmentDropdown
                            name={'treatments'}
                            value={treatment}
                            onChange={(e, newValue) => handleFieldData('treatments', newValue)}
                            renderInput={(params) => <TextField {...params} sx={{ width: '100%', mr: '15px', minWidth: '250px' }} label='Treatment' />}
                        />
                    </FormProvider>
                </Box>
                <Box sx={timeRageBoxStyle}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            onChange={(newValue) => handleFieldData('dateTime', newValue)}
                            name={'dateTime'}
                            value={value}
                            defaultValue={[]}
                            inputFormat='DD MMM YYYY'
                            maxDate={endDate}
                            renderInput={(startProps, endProps) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: '0' }}>
                                    <TextField {...startProps} onKeyDown={(evt) => evt.preventDefault()} label='Start date' />
                                    <Box sx={{ mx: 1 }}> to </Box>
                                    <TextField {...endProps} onKeyDown={(evt) => evt.preventDefault()} label='End date' />
                                </Box>
                            )}
                            components={{ PaperContent: RangeShortcutsPanel }}
                            PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
                            componentsProps={{ paperContent: { setValue } }}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box sx={referralSummaryBoxStyle}>
                <Card sx={{ padding: '24px 0 0', mb: '30px' }} >
                    <Box sx={{ padding: '0 24px', mb: '24px', width: '100%' }}>
                        <Typography variant='h4' sx={tableHeadStyle}>Source Referral</Typography>
                    </Box>
                    <ReferralSummaryTable />
                </Card>
                <Card sx={{ padding: '24px 0 0' }}>
                    <Box sx={{ padding: '0 24px', mb: '24px', width: '100%' }}>
                        <Typography variant='h4' sx={tableHeadStyle}>Source Type</Typography>
                    </Box>
                    <TableContainer sx={{ position: 'relative', overflow: 'hidden', '.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': { border: 'none !important' } }}>
                        <Box sx={{ maxHeight: '450px', overflow: 'auto' }}>
                            <Table stickyHeader sx={TableheadStyle} >
                                <TableHeadCustom
                                    headLabel={sourceTypeTableLabels || []}
                                    rowCount={10}
                                    order={order}
                                    orderBy={orderBy}
                                    onSort={handleSorting}
                                    sx={sourceTypeTableHeadStyle}
                                />
                                <TableBody>
                                    {tableRowdata === null && <TableLoading tableData={tableRowdata === null && 'No Found'} pageCount={tableRowdata === null && 'No Found'} />}
                                    {tableRowdata?.length > 0 && tableRowdata?.map((el, index) => <SourceSummaryRow key={index} data={el} />)}
                                    {tableRowdata?.length === 0 && <TableNoData isNotFound={tableRowdata?.length === 0} />}
                                </TableBody>
                            </Table>
                        </Box>
                    </TableContainer>
                </Card>
            </Box>
        </Box>
    );
};

SourceSummaryTable.propTypes = {
    type: PropTypes.string,
};

export default SourceSummaryTable;
