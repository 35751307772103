import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, MenuItem, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { generateReasonsLabel } from 'src/utils/getReferralData';
import { followUpSelection } from 'src/constants/appointmentReason';
// hooks
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';
// component
import { ArchivedAutocomplete } from '../AutoComplete';
// store
import { dispatch, useSelector } from '../../redux/store';
import { ArchivedLeadDashBoard, saveNewLeadDetail, updateCompleteStatus } from '../../redux/slices/lead';
// style
import { ArchiveTitle, archiveGridStyle, selectMenuItemStyle, selectStyle, textFieldStyle } from '../../styles/DialogueTabs';
// validation
import { CreateLeadArchiveSchema } from '../../validations/validations';

// -----------------------------------------------------------------------------------------------------

const Archive = forwardRef(({ handleClose, type, user, screenType, isDuplicate, setForceUpdate }, ref) => {

    const { tableRowLead, createLead } = useSelector((state) => state.lead);

    const [isCommunication, setCommunication] = useState(user?.openCommunication || tableRowLead?.openCommunication || 'No');
    
    const { archiveReasons } = useSelector((state) => state.schema);

    const defaultValues = {
        archiveReason: '',
        typed: type
    };

    const [newArchiveReasons, setNewArchiveReasons] = useState([]);
    useEffect(() => {
        if(archiveReasons && archiveReasons?.length > 0) {
            const newArchive = archiveReasons?.filter?.((value) => value !== 'UNABLE_TO_CONTACT');          
            const archive = newArchive?.sort()?.map?.((value) => ({label: value === 'WANT_NHS' ? 'Want NHS' : generateReasonsLabel(value), value}))
            setNewArchiveReasons(archive);
        }
    }, [archiveReasons]);

    const methods = useForm({
        resolver: yupResolver(CreateLeadArchiveSchema),
        defaultValues
    });

    const {
        handleSubmit,
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isCommunication, createLead]
    );

    const onSubmit = async (data, status) => {
        const addNotesObj = {
            note: data.archiveReason,
            treatment: createLead?.lead_treatment?.id || tableRowLead?.treatment_id || user?.treatment_id || '',
            additional_notes: data.archiveNotes
        };
        const archiveData = {
            note: data.archiveNotes
        };
        const openCommunication = {
            id: createLead?.id || tableRowLead?.lead_id || user?.lead_id || '',
            is_open_for_communication: isCommunication === 'Yes',
        };
        dispatch(saveNewLeadDetail({
            archiveLead: {
                notes: addNotesObj,
                openCommunication,
                archiveData,
            }
        }));
        if (isDuplicate) {
            dispatch(ArchivedLeadDashBoard(handleClose, user));
            return;
        }
        if (status === 'finish') {           
            dispatch(updateCompleteStatus(true));
            if (typeof setForceUpdate === 'function') {
                setForceUpdate((prev) => !prev);
            }
            return;
        }
        handleClose();
    };

    const handleChange = (e) => {
        setCommunication(e.target.value);
    };

    return (
        <Box sx={{ width: '100%', pt: '10px' }}>
            <ArchiveTitle> {type !== 'RestoreArchived' ? 'Archive reason' : 'Restore Reason'}</ArchiveTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} sx={archiveGridStyle}>
                    {type !== 'RestoreArchived' &&
                        <RHFSelect
                            name='archiveReason'
                            label='Select reason'
                            SelectProps={{ native: false, sx: selectStyle }}
                        >
                            {newArchiveReasons?.filter(reason => reason.value !== 'NHS_WAITING_LIST' && !followUpSelection.includes(reason.value))?.map((options, index) => (
                                <MenuItem
                                    key={index}
                                    value={options.value}
                                    sx={selectMenuItemStyle}
                                >
                                    {options.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>}
                </Grid>
                <Grid container spacing={1} sx={archiveGridStyle}>
                    <RHFTextField
                        sx={{...textFieldStyle, marginLeft: 0}}
                        name="archiveNotes"
                        multiline
                        rows={4}
                        label={type !== 'RestoreArchived' ? 'Archive notes (Required)' : 'Restore notes (Required)'}
                        placeholder="Add notes here"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                {type !== 'RestoreArchived' && <>
                    <Grid container spacing={1} sx={archiveGridStyle}>
                        <Typography variant="subtitle2" >Is the lead open to be contacted in the future ?</Typography>
                    </Grid>
                    <Grid container spacing={1} sx={archiveGridStyle}>
                        <Box sx={{ border:'0px solid #919EAB', borderRadius:'10px'}}>
                            <ToggleButtonGroup
                                color="primary"
                                name='isCommunication'
                                value={isCommunication}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton sx={{ width: '56px', height: '40px'}} value={'Yes'}>Yes</ToggleButton>
                                <ToggleButton  sx={{ width: '56px', height: '40px'}} value={'No'}>No</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Grid>
                    {screenType !== 'CreatedLead' &&
                        <Grid container spacing={1} sx={archiveGridStyle}>
                            <ArchivedAutocomplete user={user || tableRowLead}/>
                        </Grid>
                    }
                </>}
            </FormProvider>
        </Box>
    );
});

Archive.propTypes = {
    handleClose: PropTypes.func,
    type: PropTypes.string,
    user : PropTypes.any,
    screenType: PropTypes.object,
    isDuplicate: PropTypes.bool,
    setForceUpdate: PropTypes.any
};

export default Archive;