import { alpha } from '@mui/material/styles';
// util
import { bgBlur } from '../utils/cssStyles';


export const loadingBoxStyle = {
    width: 100,
    height: 100,
    position: 'absolute',
    border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
};

export const insideBoxStyle = {
    width: 120,
    height: 120,
    position: 'absolute',
    border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
};

export const tableLoader = (theme, type) => ({
    top: 0,
    left: 0,
    height: type === 'table' ? '100vh' : 1,
    zIndex: 99,
    position: 'absolute',
    ...bgBlur({ color: theme.palette.background.paper }),
    width: type === 'LeadDetails' ? 'calc(100% + 24px)' : 1,
    borderRadius: type === 'tasks' ? 2 : 0
});