import PropTypes from 'prop-types';
import { Box, Typography, Tooltip, Zoom } from '@mui/material';
import { AssignUserAvatar } from 'src/components/custom-avatar';
import { primary } from '../../../../theme/palette';
import { getMoreAssignees } from '../../../../utils/leadTableUtils';
import { getAssignees } from '../../../../utils/multiAsignees';
import { tableCellInnerBoxStyle } from '../../../../styles/NewLeadTableRowStyle';

const ConsultationBookedWithField = ({ rowData, OpenAssignTask }) => {
    const assigneeIds = rowData?.consultationAppointment?.[0]?.assignees?.map((assignee) => assignee.id) || [];

    const handleClick = (e) => {
        if (rowData?.consultationAppointment?.[0]?.datetime_human) {
            OpenAssignTask(rowData, assigneeIds, e, rowData?.consultationAppointment?.[0]?.id);
        }
    };

    return (
        <Box
            sx={{ ...tableCellInnerBoxStyle, justifyContent: 'flex-start' }}
            onClick={handleClick}
            onMouseDown={handleClick}
        >
            <Typography variant='subtitle2'>
                <Tooltip
                    title={getAssignees({ assignees: rowData?.consultationAppointment?.[0]?.assignees })}
                    placement='bottom'
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    {rowData?.consultationAppointment?.[0]?.assignees?.[0]?.user?.first_name && (
                        <AssignUserAvatar
                            sx={{ cursor: 'pointer', mr: 1, bgcolor: primary.paperColor }}
                            src={rowData?.consultationAppointment?.[0]?.assignees?.[0]?.user?.avatar || ''}
                            name={`${rowData?.consultationAppointment?.[0]?.assignees?.[0]?.user?.first_name || ''} ${
                                rowData?.consultationAppointment?.[0]?.assignees?.[0]?.user?.last_name || ''
                            }`}
                            role={rowData?.consultationAppointment?.[0]?.assignees?.[0]?.role}
                        />
                    )}
                    {rowData?.consultationAppointment?.[0]?.datetime_human
                        ? `${getMoreAssignees(rowData?.consultationAppointment?.[0])}`
                        : ''}
                </Tooltip>
            </Typography>
        </Box>
    );
};

ConsultationBookedWithField.propTypes = {
    rowData: PropTypes.array,
    OpenAssignTask: PropTypes.func,
};

export default ConsultationBookedWithField;