
import EmptyContent from './empty-content';

// ----------------------------------------------------------------------

export default function NotFound() {
    return (
        <EmptyContent
            title="No Data"
            sx={{
                '& span.MuiBox-root': { height: 160 },
            }}
        />
    );
}
