import React from 'react';
import ApptTypeField from '../ApptTypeField';

const ApptTypeCell = ({
    updateLeadPage,
    reloadCounter,
    typed,
    handleSetTableData
}) => {

    const getCellClassName = (params) => {
        const appointmentType = params?.row?.appointments?.[0]?.type;
        switch (appointmentType) {
            case 'TREATMENT':
                return 'treatment-type';
            case 'CONSULTATION':
                return 'consultation-type';
            default:
                return '';
        }
    };

    return {
        field: 'app_type',
        headerName: 'Appt type',
        minWidth: 120,
        sortable: false,
        cellClassName: getCellClassName,
        renderCell: (params) => (
            <ApptTypeField
                rowData={params.row}
                updateLeadPage={updateLeadPage}
                reloadCounter={reloadCounter}
                tableType={typed}
                handleSetTableData={handleSetTableData}
            />
        ),
    };
};

export default ApptTypeCell;
