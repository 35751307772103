import PropTypes from 'prop-types';
import { useMemo, useEffect } from 'react';
// @mui
import { Box, Typography, Dialog, Autocomplete, TextField, Chip, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
// constants
import { optionsCustom } from '../../../constants/MessageListConst';
// hooks
import { RHFTextField, FormProvider } from '../../../components/hook-form';
import { Iconify } from '../../../components';
// style
import {
    dialogBoxStyle,
    formOuterBoxStyle,
    btnBoxStyle
} from '../../../styles/NewMessageComposeStyle';
// ----------------------------------------------------------------------

export default function NewMessageCompose({ isOpenNewCompose, onCloseNewCompose }) {
    const defaultValues = useMemo(
        () => ({
            email: '',
        }),
        []
    );

    const methods = useForm({
        defaultValues,
    });

    const {
        reset,
        setValue,
    } = methods;

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog maxWidth="lg" open={isOpenNewCompose} onClose={onCloseNewCompose}>
            <Box sx={dialogBoxStyle}>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>New custom sequence</Typography>
                <Box sx={formOuterBoxStyle}>
                    <FormProvider methods={methods} >
                        <RHFTextField onChange={(e) => setValue('email', e.target.value)} sx={{ width: '100%', margin: '15px 0' }} name="email" label="Sequence name" />
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={optionsCustom.map((option) => option)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={index}
                                        variant="filled"
                                        label={option}
                                        color="info"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Treatment"
                                />
                            )}
                        />
                        <Box sx={{ textAlign: 'left', my: 2, p: 0 }}>
                            <LoadingButton
                                type="button"
                                variant="text"
                                startIcon={<Iconify icon={'eva:plus-fill'} />}
                            >
								Add another custom sequence
                            </LoadingButton>
                        </Box>
                        <Box sx={btnBoxStyle}>
                            <Button
                                variant="outlined"
                                sx={{
                                    mr: 1
                                }}
                                onClick={onCloseNewCompose}
                            >
								Cancel
                            </Button>
                            <Button variant="contained">
								Save
                            </Button>
                        </Box>
                    </FormProvider>
                </Box>
            </Box>
        </Dialog>
    );
}

NewMessageCompose.propTypes = {
    isOpenNewCompose: PropTypes.bool,
    onCloseNewCompose: PropTypes.func,
};