/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState, useCallback } from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
// @mui
import { Box, TextField, List, ListItem, ListItemButton, ListItemText, Divider, Typography } from '@mui/material';
// components
// store
import { useSelector, dispatch } from '../redux/store';
import {
    getTreatmentSummary,
    addTargetTreatment,
} from '../redux/slices/analytics';
import { saveNewCampaign, updateSelectedCampaign } from '../redux/slices/campaign';
// utils
import groupedTreatments from '../utils/groupedTreatments';
import { RangeShortcut, endDate, rangeShortcuts } from '../constants/ReportSummaryConst';
// style
import { boxStyleLeadDateRangePicker, timeRageBoxStyle } from '../styles/TreatmentTimeRageStyle';

// ----------------------------------------------------------------------------------------

const LeadDateRangePicker = ({ type }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { selectedCampaign, newCampaign } = useSelector((state) => state.campaign);
    const { startTime, endTime } = useSelector((state) => state.analytics);
    const treatmentList = useSelector((state) => state.practiceTreatment.treatmentsList?.results);

    const [, setTreatmentOptions] = useState([]);
    const [, setTreatment] = useState('');
    const [value, setValue] = useState([null, null]);

    useEffect(() => {
        if (selectedCampaign?.recepient?.filters?.created_at__range?.length > 0) {
            const rangeValue = selectedCampaign?.recepient?.filters?.created_at__range;
            setValue(rangeValue);
        }
    }, [selectedCampaign]);

    useEffect(() => {
        if (treatmentList) {
            setTreatmentOptions(groupedTreatments(treatmentList));
        }
    }, [treatmentList]);

    const handleFieldData = (name, value) => {
        if (value) {
            if (name === 'treatments') {
                setTreatment(value);
                const treatmentData = treatmentList.filter((elem) => elem?.treatment_item?.name === value)[0]?.treatment_item;
                const data = { treatmentData, practiceId };
                localStorage.setItem('report-treatment', JSON.stringify(data));
                dispatch(getTreatmentSummary(practiceId, value ? treatmentList.filter((elem) => elem?.treatment_item?.name === value)[0]?.treatment_item?.id : '', startTime || '', endTime || ''));
                dispatch(addTargetTreatment(value));
            }
            if (name === 'dateTime') {
                setValue(value);
                const changedStartTime = value[0] ? moment(value[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00') : '';
                const changedEndTime = value[1] ? moment(value[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:00') : '';
                const data = { created_at__range: { changedStartTime, changedEndTime } };
                const formattedStartDate = changedStartTime.split(' ')[0];
                const formattedEndDate = changedEndTime.split(' ')[0];
                data.created_at__range = [formattedStartDate, formattedEndDate];
                if (Object.values(selectedCampaign).length > 0) {
                    const updatedCampaign = { ...selectedCampaign, recepient: { ...selectedCampaign.recepient, filters: { ...selectedCampaign?.recepient?.filters, ...data } } };
                    dispatch(updateSelectedCampaign(updatedCampaign));
                    return;
                }
                dispatch(saveNewCampaign({ created_at__range: data, dateRange: data.created_at__range }));
            }
        }
    };

    const buildHandleRangeClick = (setValue) => (range) => {
        const today = dayjs();
        switch (range) {
            case RangeShortcut.allTime:
                setValue([null, null]);
                handleFieldData('dateTime', [null, null]);
                break;
            case RangeShortcut.thisWeek:
                setValue([today.startOf('week'), today.endOf('week')]);
                handleFieldData('dateTime', [today.startOf('week'), today.endOf('week')]);
                break;
            case RangeShortcut.lastWeek:
                setValue([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                handleFieldData('dateTime', [
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                break;
            case RangeShortcut.last7Days:
                setValue([today.subtract(1, 'week'), today]);
                handleFieldData('dateTime', [today.subtract(1, 'week'), today]);
                break;
            case RangeShortcut.currentMonth:
                setValue([today.startOf('month'), today.endOf('month')]);
                handleFieldData('dateTime', [today.startOf('month'), today.endOf('month')]);
                break;
            case RangeShortcut.prevMonth:
                setValue([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                handleFieldData('dateTime', [
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                break;
            case RangeShortcut.reset:
                setValue([null, null]);
                break;
            default:
                break;
        }
    };

    const RangeShortcutsPanel = ({ setValue, children }) => {
        const handleRangeClick = useCallback(
            (range) => setValue && buildHandleRangeClick(setValue)(range),
            [setValue],
        );
        return (
            <Box sx={{ display: 'flex', '@media(max-width: 900px)': { display: 'inline-block' } }}>
                <Box sx={{ m: 2 }} display="flex" gap={2}>
                    <div>
                        <Typography variant="overline">Date range shortcuts</Typography>
                        <List>
                            {rangeShortcuts.map(({ range, label }) => (
                                <ListItem key={range} disablePadding>
                                    <ListItemButton onClick={() => handleRangeClick(range)}>
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <Divider orientation="vertical" />
                </Box>
                {children}
            </Box>
        );
    };

    return (
        <Box sx={boxStyleLeadDateRangePicker(type)} >
            <Box sx={timeRageBoxStyle}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                        onChange={(newValue) => handleFieldData('dateTime', newValue)}
                        name={'dateTime'}
                        value={newCampaign?.dateRange || value || []}
                        inputFormat="DD MMM YYYY"
                        maxDate={endDate}
                        renderInput={(startProps, endProps) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: '0' }}>
                                <TextField {...startProps} onKeyDown={(evt) => evt.preventDefault()} label="Start date" />
                                <Box sx={{ mx: 1 }}> to </Box>
                                <TextField {...endProps} onKeyDown={(evt) => evt.preventDefault()} label="End date" />
                            </Box>
                        )}
                        components={{ PaperContent: RangeShortcutsPanel }}
                        PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
                        componentsProps={{ paperContent: { setValue } }}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
};

LeadDateRangePicker.propTypes = {
    type: PropTypes.string,
    mapValues: PropTypes.array,
    setMapValues: PropTypes.any,
    handleData: PropTypes.func,
};

export default LeadDateRangePicker;
