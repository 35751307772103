import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container } from '@mui/material';
import { useSettingsContext } from '../settings';
import { useSelector } from '../../redux/store';
import { backButtonStyle, leadFooterButtonsStyle } from '../../styles/DialoguePopup';
import { nextButtonStyle, markAsCompleteBtnStyle } from '../../styles/ContactLeadPopup';

const ButtonContainer = ({ type, activeStep, activeTab, handleNext, outcome, steps, getBtnTitle, handleBack, ContactedCount, handleArchivePopup }) => {
    const settings = useSettingsContext();
    const { isLoader } = useSelector((state) => state.loaderReducer);
    const { practice } = useSelector(state => state.practice);
    const { isAppointmentBooked, attendedActiveTab, treatmentDepositStatus, treatmentLoading, archivedLead } = useSelector((state) => state.lead);
    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;

    const activeBackButton = () => {
        if (type === 'onboarad') {
            return activeTab !== 'General' || activeStep !== 0;
        }
        return activeStep !== 0;
    };

    const getButton = () => {
        if (activeStep === (steps.length - 1)) {
            if (type === 'followup' && archivedLead) return 'Archive';
            return 'Finish';
        }
        return 'Next';
    };

    const isDisabledFinishButton = () => {
        if (treatmentLoading) return true;
        if (getButton() === 'Finish' && isPaymentMandatory && (type === 'CreatedLead' || outcome === 'Contact Lead') && attendedActiveTab === 'Starting treatment' && treatmentDepositStatus) {
            return true;
        }
        return false;
    };

    return (
        <Container
            maxWidth={settings.themeStretch ? false : 'xl'}
            sx={leadFooterButtonsStyle(activeBackButton(), 'contactLead')}
            className='buttons-container'
        >
            {activeBackButton() && (
                <Button
                    color='inherit'
                    variant='outlined'
                    disabled={!activeBackButton()}
                    onClick={handleBack}
                    className='back-button'
                    sx={backButtonStyle('contactLead')}
                >
                    Back
                </Button>
            )}
            <Box className='buttons-right'>
                {
                    type === 'followup' && ContactedCount > 2 && isAppointmentBooked === 'Unable to contact' && (
                        <Button
                            color='primary'
                            variant='outlined'
                            className='finish-button'
                            onClick={handleArchivePopup}
                            sx={nextButtonStyle('contactLead')}
                        >
                            Archive
                        </Button>)
                }
                {
                    type === 'followup' && (activeStep === 0 || activeStep === 1) ? null : (
                        <Button
                            color='primary'
                            variant='outlined'
                            className='finish-button'
                            onClick={(e) => handleNext('', getButton(), e)}
                            sx={nextButtonStyle('contactLead')}
                            disabled={isDisabledFinishButton()}
                        >
                            {getButton()}
                        </Button>)
                }
                {
                    type === 'followup' && (activeStep === 1 && getButton() === 'Next') ? (
                        <Button
                            color='primary'
                            variant='outlined'
                            className='finish-button'
                            onClick={(e) => handleNext('', 'Change Booking Status', e)}
                            sx={{ ...nextButtonStyle('contactLead'), p: '5px 20px!important' }}
                        >
                            Change Booking Status
                        </Button>) : null
                }
                {
                    type === 'followup' && activeStep === 1 ? (
                        <LoadingButton
                            color='primary'
                            variant='outlined'
                            className='finish-button'
                            loading={isLoader}
                            loadingPosition='start'
                            onClick={() => handleNext()}
                            sx={nextButtonStyle('contactLead')}
                            disabled={isDisabledFinishButton()}
                        >
                            {getBtnTitle()}
                        </LoadingButton>
                    ) : null
                }
                {(activeStep > 1 && activeStep !== (steps.length - 1)) && type !== 'onboarad' &&
                    <LoadingButton
                        color='primary'
                        variant='contained'
                        loading={isLoader}
                        loadingPosition='start'
                        onClick={() => handleNext('finish')}
                        className='mark-complete-button'
                        sx={markAsCompleteBtnStyle('contactLead')}
                        disabled={isLoader}
                    >
                        Mark As Complete
                    </LoadingButton>
                }
            </Box>
        </Container>
    );
};

ButtonContainer.propTypes = {
    type: PropTypes.string,
    activeStep: PropTypes.number,
    activeTab: PropTypes.string,
    handleNext: PropTypes.func,
    outcome: PropTypes.string,
    steps: PropTypes.array,
    getBtnTitle: PropTypes.func,
    handleBack: PropTypes.func,
    ContactedCount: PropTypes.number,
    handleArchivePopup: PropTypes.func,
};

export default ButtonContainer;