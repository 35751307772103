const mainBox = {
    m: 1,
    display: 'flex',
    p: '8px 0',
    '@media(max-width: 575px)': {
        flexWrap: 'wrap',
    }
};
const textBox = {
    width: '200px',
    p: '0 10px 0 0',
    '@media(max-width: 575px)': {
        mb: 1
    }
};
const imageBox = {
    mb: 1,
    width: 240,
    'img': { objectFit: 'contain' },
    border: '1px dashed rgba(145, 158, 171, 0.32)',
    p: 1
};
export {
    mainBox,
    textBox,
    imageBox
};