export const box1Style = {
    width : '100%',
    display : 'flex',
    justifyContent : 'space-between',
    mb: '10px !important'
};

export const box2Style = (isLight) => ({
    textAlign : 'left',
    padding: `12px 12px !important`,
    paddingBottom : '0px',
    border: isLight ? '1px solid #F4F6F8' : '1px solid transparent',
    display:'flex',
    flexDirection: 'column',
    alignItems:'flex-start',
    borderRadius : '8px',
    background: isLight ? '#F4F6F8' : 'rgba(145, 158, 171, 0.1)',
    width : 'calc(100% - 56px)',
    color:'#69707a',
    '@media(max-width: 767px)': {
        width : 'calc(100% - 50px)',
    }
});

export const noteTextFieldStyle = {
    width: 'calc(100% - 56px)', 
    mt: '10px',
    '@media(max-width: 767px)': {
        width : 'calc(100% - 50px)',
    }
};

export const box3Style = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    mb: '4px',
    '@media(max-width: 767px)': {
        flexWrap: 'wrap'
    }
};

export const treatmentBoxStyle = (leadDetail) => ({
    display: 'flex', 
    justifyContent: leadDetail?.lead_treatments?.length > 0 ? 'space-between' : 'flex-end', 
    width: '100%', 
    '@media(max-width: 767px)': {
        flexWrap: 'wrap'
    }
});

export const paperStyle = (isLight) => ({
    border : 'none',
    background: isLight ? '#F4F6F8' : 'transparent',
    color:'#637381',
    fontSize : '14px',
    maxWidth: '100%',
    overflowWrap: 'break-word'
});

export const avatarStyle = {
    width: 40,
    height: 40,
    fontSize: '24px',
    mt: '10px',
    fontWeight: 700
};

export const avatarBox = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
};

export const dateTimeUI = {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '0',
    color: '#919EAB',
    fontSize: '12px',
    '@media(max-width: 479px)': {
        mb: '5px'
    }
};
export const labelStyle = {
    p: '8px !important',
    mt: '8px',
    color: '#0C53B7',
    backgroundColor: 'rgba(24, 144, 255, 0.16)',
    fontWeight: '800',
    '@media(max-width: 479px)': {
        mb: '5px'
    }
};