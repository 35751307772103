export const userIconWrapperStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    p: '0',
};
export const userIconInnerStyle = {
    width: '100%',
    borderTop: '1px solid rgba(241, 243, 244, 1)',
    display: 'flex',
    justifyContent: 'flex-end',
    p: '16px 24px',
};