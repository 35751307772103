import FilterTreatmentList from './filtertreatmentList';

const groupedTreatments = (treatmentList) => FilterTreatmentList(treatmentList).map((data) => {
    const firstLetter = data?.name[0]?.toUpperCase();
    return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...data,
    };
});

export default groupedTreatments;