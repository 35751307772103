export const treatmentCategory = [
    'all',
    'General Dentistry',
    'Restorative Dentistry',
    'Orthodontics',
    'Cosmetic Dentistry',
    'Implants',
    'Facial Rejuvenation'
];

export const isEditData = [
    { id: 'treatment_item.name', label: 'Treatment', align: 'left', sort: '' },
    { id: 'price', label: 'Price', align: 'left', sort: '' },
    { id: 'colour', label: 'Colour', align: 'left', sort: '' },
    { id: 'icon', label: 'Icon', align: 'left', sort: '' },
    { id: 'isStarred', label: 'Pin Treatment', align: 'left', sort: '' },
    { id: '', label: 'Status' },
    { id: '' },
];

export const noEditData = [
    { id: 'treatment_item.name', label: 'Treatment', align: 'left', sort: '' },
    { id: 'price', label: 'Price', align: 'left', sort: '' },
    { id: 'colour', label: 'Colour', align: 'left', sort: '' },
    { id: 'icon', label: 'Icon', align: 'left', sort: '' },
    { id: 'isStarred', label: 'Pin Treatment', align: 'left', sort: '' },
    { id: '', label: '', align: 'left', sort: '' },
];

export const practiceTabs = [
    { value: 'All', label: 'All' },
    { value: 'Enable', label: 'Enable' },
    { value: 'Disable', label: 'Disable' },
];

export const treatmentApiFields = '?fields={enabled,id,practice,price,colour,icon,is_starred,treatment_item{id,name,treatment_category{id,name}}}';