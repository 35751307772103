import PropTypes from 'prop-types';
import { memo, forwardRef, useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBar)(({ theme }) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: alpha(theme.palette.grey[600], 0.48),
        },
        '&.simplebar-visible:before': {
            opacity: 1,
        },
    },
    '& .simplebar-mask': {
        zIndex: 'inherit',
    },
}));

// ----------------------------------------------------------------------

const Scrollbar = forwardRef(({ children, sx, onScroll, ...other }, ref) => {
    const simpleBarRef = useRef();
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    useEffect(() => {
        if (!isMobile && simpleBarRef.current && onScroll) {
            simpleBarRef.current.getScrollElement().addEventListener('scroll', onScroll);
        }
        return () => {
            if (!isMobile && simpleBarRef.current && onScroll) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                simpleBarRef.current.getScrollElement().removeEventListener('scroll', onScroll);
            }
        };
    }, [isMobile, onScroll]);

    if (isMobile) {
        return (
            <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
                {children}
            </Box>
        );
    }

    return (
        <RootStyle>
            <SimpleBarStyle
                ref={simpleBarRef}
                scrollableNodeProps={{
                    ref,
                }}
                clickOnTrack={false}
                sx={sx}
                {...other}
            >
                {children}
            </SimpleBarStyle>
        </RootStyle>
    );
});

Scrollbar.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object,
    onScroll: PropTypes.func,
};

export default memo(Scrollbar);
