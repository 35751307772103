/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ClickAwayListener, Grow, Paper, Popper, Tooltip, Typography, Zoom } from '@mui/material';
import { assignPopperStyle, assignUserAvatarStyle, openTaskPop } from 'src/styles/leadTaskReminder';
import { grey } from 'src/theme/palette';
import { useAssignTaskToggle } from 'src/hooks';
import { dispatch } from 'src/redux/store';
import { clearPracticeUserData } from 'src/redux/slices/practiceUser';
import { labelTextStyle } from '../styles/AttendedAppointment';
import Iconify from './Iconify';
import { AssignUserAvatar } from './custom-avatar';
import { AssignTaskPopup } from './dialogPopUp';

const AssignedUsersShow = ({ assigneeSelected , setAssigneeSelected, leadId}) => {

    const [assignToState, setAssignToState] = useState([]);
    const [anchorElAssign, setAnchorElAssign] = useState(null);

    useEffect(() => {
        setAssigneeSelected(assignToState);
    }, [assignToState]);

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const assignTaskHandler = (event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        setAnchorElAssign(null);
        onCloseAssignTask();
        dispatch(clearPracticeUserData());
    };

    return (
        <>
    <Box sx={{ width: '100%', p: '0 0 0 40px', mt: '24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={(e) => e.stopPropagation()}>
            <Typography sx={labelTextStyle}>Assignees</Typography>
            <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200], cursor: 'pointer' }} onClick={(e) => assignTaskHandler(e)}>
                <Iconify sx={{ color: 'common.black' }} width={20} height={20} icon={'eva:plus-fill'} />
            </Box>
        </Box>
        <Box sx={{ width: '100%', mt: '0', display: "flex", gap: "5px", flexWrap: "wrap" }}>
            {
                assigneeSelected?.length ? assigneeSelected.map((ele, index) => <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', }} key={index}>
                    <Tooltip title={`${ele.user?.first_name || ''} ${ele.user?.last_name || ''}`} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>

                        <AssignUserAvatar
                            key={index}
                            sx={{ ...assignUserAvatarStyle, height: "48px", width: "48px" }}
                            src={ele.user?.avatar || ''}
                            name={`${ele.user?.first_name || ''} ${ele.user?.last_name || ''}`}
                            role={ele?.role}
                        />
                    </Tooltip>
                </Box>)
                 : "-"
            }
        </Box>
    </Box>

    {openAssignTask && (
                <ClickAwayListener onClickAway={onClickAwayEvent}>
                    <Popper open={openAssignTask} anchorEl={anchorElAssign}  transition placement="bottom" sx={assignPopperStyle}>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper sx={{ borderRadius: 2 }}>
                                    <AssignTaskPopup
                                        open={openAssignTask}
                                        onClose={() => { onCloseAssignTask(); setAnchorElAssign(null); dispatch(clearPracticeUserData()); }}
                                        type={'leadAssign'}
                                        leadId={leadId}
                                        // userSpecific={'TCOUsers'}
                                        setAssignTo={setAssignToState}
                                        assignees={assigneeSelected?.map((ele) => ele.id)}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
    </>
    )
};

export default AssignedUsersShow;

AssignedUsersShow.propTypes = {
    assigneeSelected: PropTypes.array,
    setAssigneeSelected: PropTypes.func,
    leadId: PropTypes.number
};