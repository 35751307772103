import PropTypes from 'prop-types';
import * as Yup from 'yup';
import merge from 'lodash/merge';
import { isBefore } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Stack, Button, Tooltip, TextField, IconButton, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
// components
import { Iconify } from '../../../components';
import { ColorSinglePicker } from '../../../components/color-utils';
import { FormProvider, RHFTextField, RHFSwitch } from '../../../components/hook-form';
// constants
import { COLOR_OPTIONS } from '../../../constants/CalenderConst';
// redux
import { useDispatch } from '../../../redux/store';
import { createEvent, updateEvent, deleteEvent } from '../../../redux/slices/calendar';

const getInitialValues = (event, range) => {
    const _event = {
        title: '',
        description: '',
        textColor: '#1890FF',
        allDay: false,
        start: range ? new Date(range.start) : new Date(),
        end: range ? new Date(range.end) : new Date(),
    };

    if (event || range) {
        return merge({}, _event, event);
    }

    return _event;
};

export default function CalendarForm({ event, range, onCancel }) {
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const isCreating = Object.keys(event).length === 0;

    const EventSchema = Yup.object().shape({
        title: Yup.string().required('This field is required').max(255),
        description: Yup.string().max(5000),
    });

    const methods = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues: getInitialValues(event, range),
    });

    const {
        reset,
        watch,
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        try {
            const newEvent = {
                title: data.title,
                description: data.description,
                textColor: data.textColor,
                allDay: data.allDay,
                start: data.start,
                end: data.end,
            };
            if (event.id) {
                dispatch(updateEvent(event.id, newEvent));
                enqueueSnackbar('Update success!');
            } else {
                enqueueSnackbar('Create success!');
                dispatch(createEvent(newEvent));
            }
            onCancel();
            reset();
        } catch (error) {
            enqueueSnackbar(error.message || 'Something went wrong', { variant: 'error' });
        }
    };

    const handleDelete = async () => {
        if (!event.id) return;
        try {
            onCancel();
            dispatch(deleteEvent(event.id));
            enqueueSnackbar('Delete success!');
        } catch (error) {
            enqueueSnackbar(error.message || 'Something went wrong', { variant: 'error' });
        }
    };

    const values = watch();

    const isDateError = isBefore(new Date(values.end), new Date(values.start));

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ p: 3 }}>
                <RHFTextField name="title" label="Title" />

                <RHFTextField name="description" label="Description" multiline rows={4} />

                <RHFSwitch name="allDay" label="All day" />

                <Controller
                    name="start"
                    control={control}
                    render={({ field }) => (
                        <MobileDateTimePicker
                            {...field}
                            label="Start date"
                            inputFormat={'dd/MM/yyyy HH:mm'}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    )}
                />

                <Controller
                    name="end"
                    control={control}
                    render={({ field }) => (
                        <MobileDateTimePicker
                            {...field}
                            label="End date"
                            inputFormat={'dd/MM/yyyy HH:mm'}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    error={!!isDateError}
                                    helperText={isDateError && 'End date must be later than start date'}
                                />
                            )}
                        />
                    )}
                />

                <Controller
                    name="textColor"
                    control={control}
                    render={({ field }) => (
                        <ColorSinglePicker value={field.value} onChange={field.onChange} colors={COLOR_OPTIONS} />
                    )}
                />
            </Stack>

            <DialogActions>
                {!isCreating && (
                    <Tooltip title="Delete Event">
                        <IconButton onClick={handleDelete}>
                            <Iconify icon="solar:trash-bin-trash-bold" width={20} height={20} />
                        </IconButton>
                    </Tooltip>
                )}
                <Box sx={{ flexGrow: 1 }} />

                <Button variant="outlined" color="inherit" onClick={onCancel}>
                    Cancel
                </Button>

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Add
                </LoadingButton>
            </DialogActions>
        </FormProvider>
    );
}

CalendarForm.propTypes = {
    event: PropTypes.object,
    range: PropTypes.object,
    onCancel: PropTypes.func,
};
