import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from '@mui/material';
import { Iconify } from '..';

const CustomCheckBox = ({ label, checked }) => {
    const icon = <Iconify icon={'material-symbols:circle-outline'} width={24} height={24} />;
    const checkedIcon = <Iconify icon={'bi:check-circle-fill'} width={24} height={24} />;

    return <Box sx={{ fontSize: '18px', fontWeight: 700, ml: '-8px' }}>
        <Checkbox checked={checked} icon={icon} checkedIcon={checkedIcon} />{label}
    </Box>;
};

CustomCheckBox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
};

export default CustomCheckBox;