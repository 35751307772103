import React from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Typography } from '@mui/material';
// style
import { treatmentCellTypoStyle } from '../../../../styles/LeadTableStyle';
import { tableCellInnerBoxStyle } from '../../../../styles/NewLeadTableRowStyle';

const CustomTreatmentPriceCell = ({ params }) => (
    <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'flex-end' }}>
        <Typography variant="body2" sx={{ ...treatmentCellTypoStyle }}>
            {`£${params?.value}` || ''}{' '}
        </Typography>
    </Box>
);

export default CustomTreatmentPriceCell;

CustomTreatmentPriceCell.propTypes = {
    params: PropTypes.any,
};
