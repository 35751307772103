import { common, grey } from 'src/theme/palette';

export const scheduleSection = {
    display: 'none',
    backgroundColor: grey[200],
    padding: '24px',
    borderRadius: '8px'
};
export const actionIconStyle = {
    padding: '8px',
    height: '36px',
    width: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
};
export const emailTextStyle = {
    overflow: 'hidden',
    color: grey[500],
    textOverflow: 'ellipsis',
    whitespace: 'nowrap',
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px'
};
export const emailTextStrongStyle = {
    color: grey[800],
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px'
};

export const iconStyle = { cursor: 'pointer', color: grey[600] };

export const iconWrapper = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };
export const btnStyle = { color: common.mintBlue, fontSize: '13px', textTransform: 'none !important' };
export const previewSectionBtnStyle = { fontSize: '14px', textTransform: 'none !important' };