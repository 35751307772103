export const sameTableLabels = [
    { id: 'enquiries', label: 'Enquiries', align: 'center', sort: 'enquiries' },
    { id: 'NEW', label: 'Not booked', align: 'center', sort: 'NEW' },
    { id: 'CONSULTATION_BOOKED', label: 'Consultation booked', align: 'center', sort: 'CONSULTATION_BOOKED' },
    { id: 'CONSULTATION_ATTENDED', label: 'Consultation attended', align: 'center', sort: 'CONSULTATION_ATTENDED' },
    { id: 'TREATMENT_STARTED', label: 'Treatment started', align: 'center', sort: 'TREATMENT_STARTED' },
    { id: 'FAILED_TO_ATTEND_CONSULTATION', label: 'Failed to attend', align: 'center', sort: 'FAILED_TO_ATTEND_CONSULTATION' },
    { id: 'CLOSED', label: 'Completed', align: 'center', sort: 'CLOSED' },
    { id: 'TOTAL_PRICE', label: 'Total price', align: 'center', sort: 'Total_price' }
];
export const sourceTypeTableLabels = [
    { id: 'category', label: 'Source Type', align: 'center', sort: 'category' },
    ...sameTableLabels
];
export const sourceReferralTableLabels = [
    { id: 'category', label: 'Source Referral', align: 'center', sort: 'category' },
    ...sameTableLabels
];