import { alpha } from '@mui/material/styles';

export const grey = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
};

export const primary = {
    lighter: '#C8FAD6',
    light: '#5BE49B',
    main: '#00A76F',
    dark: '#007867',
    darker: '#004B50',
    contrastText: '#FFFFFF',
    paperColor: '#919EAB3D',
    btn: '#919EABE5'
};

export const secondary = {
    lighter: '#EFD6FF',
    light: '#C684FF',
    main: '#8E33FF',
    dark: '#5119B7',
    darker: '#27097A',
    contrastText: '#FFFFFF',
};

export const info = {
    lighter: '#CAFDF5',
    light: '#61F3F3',
    main: '#00B8D9',
    dark: '#006C9C',
    darker: '#003768',
    contrastText: '#FFFFFF',
};

export const success = {
    lighter: '#D3FCD2',
    light: '#77ED8B',
    main: '#22C55E',
    dark: '#118D57',
    darker: '#065E49',
    contrastText: '#ffffff',
};

export const warning = {
    lighter: '#FFF5CC',
    light: '#FFD666',
    main: '#FFAB00',
    dark: '#B76E00',
    darker: '#7A4100',
    contrastText: grey[800],
};

export const error = {
    lighter: '#FFE9D5',
    light: '#FFAC82',
    main: '#FF5630',
    dark: '#B71D18',
    darker: '#7A0916',
    contrastText: '#FFFFFF',
};

export const widget = {
    darkBlue: '#04297A',
    darkBlueBorder: '#091A7A',
    tagBlueColor: '#0C53B7',
    notificationLightGreen: '#E9FCD4',
    notificationLightGreenIcon: '#54D62C29'
};

export const common = {
    black: '#000000',
    white: '#FFFFFF',
    green: '#54D62C',
    blue: '#3366FF',
    lightGrey: '#919EAB1F',
    grey: '#637381',
    lightGreen: '#229A16',
    fireBerry: '#B72136',
    mintyZest: '#00AB55',
    slateGray: '#212B36',
    meadowGreen: '#229A16',
    red: '#B72136',
    darkGreen: '#118D57',
    darkBlue: '#103996',
    darkPurple: '#5119B7',
    greyTransparent: '#919EAB7A',
    borderColor: '#919EAB99',
    scarletBlaze: '#D93C36',
    boxShadowColor: '#919EAB29',
    slateBlack: '#222222',
    blueishGray: '#919EAB33',
    rust: '#FF484229',
    labelGreyBackground: '#919EAB14',
    vividTangerine: '#FFC10729',
    bronzeBrown: '#B78103',
    slateBlue: '#637381ad',
    steelGrey: '#757575',
    lightBluishGrey: 'rgba(145, 158, 171, 0.16)',
    lightGreyBG: '#919EAB29',
    darkGreenContrast: '#007B55',
    darkGrey: '#919EABB2',
    ratingColor: '#faaf00',
    mintCream: '#EBF9F2',
    mintBlue: '#1890FF',
    paleGreen: '#E9FCD4',
    lightSlateBlue: '#919EABCC',
    peachPuff: '#FDDECC',
    gunmetalGray: '#33363F',
    tomatoRed: '#FF4842',
    veryPaleBlue: '#E6ECFF',
    coralRed: '#FF4842',
    platinum: '#ccc',
    jade: '#00AB55 24%',
    foggyBlack: 'rgba(0,0,0,0.3)',
    babyBlue: '#D0F2FF',
    fadedBlueGray: 'rgba(145, 158, 171, 0.24)',
    lightJade: 'rgba(0, 171, 85, 0.08)',
    foggyGrey: 'rgba(145, 158, 171, 0.32)',
    stillBlue: 'rgba(145, 158, 171, 0.16)',
    orange: 'orange',
    violet: 'violet',
    skyBlue: '#078DEE',
    mintGreen: 'rgb(202, 253, 245)',
    turquoise: 'rgb(0, 184, 217)',
    translucentTurquoise: 'rgba(0, 184, 217, 0.16)',
    darkCyan: 'rgb(0, 108, 156)',
    deepGreen: '#C8FACD',
    mintyGreen: '#005249',
    mediumSeaGreen:'#40C07F',
    fieryRed:'#F04640',
    darkBrickRed: '#FF48423D',
    mintBGBlue: '#1890FF29',
    goldenBrown: '#7A4F01',
    mediumSlateBlue: '#855CF8',
    deepRed : '#7A0C2E',
};

export const action = {
    hover: alpha(grey[500], 0.08),
    selected: alpha(grey[500], 0.16),
    disabled: alpha(grey[500], 0.8),
    disabledBackground: alpha(grey[500], 0.24),
    focus: alpha(grey[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
};

const base = {
    primary,
    secondary,
    info,
    success,
    warning,
    error,
    grey,
    common,
    divider: alpha(grey[500], 0.2),
    action,
    widget
};

export function palette(mode) {
    const light = {
        ...base,
        mode: 'light',
        text: {
            primary: grey[800],
            secondary: grey[600],
            disabled: grey[500],
        },
        background: {
            paper: '#FFFFFF',
            default: '#FFFFFF',
            neutral: grey[200],
        },
        action: {
            ...base.action,
            active: grey[600],
        },
    };

    const dark = {
        ...base,
        mode: 'dark',
        text: {
            primary: '#FFFFFF',
            secondary: grey[500],
            disabled: grey[600],
        },
        background: {
            paper: grey[800],
            default: grey[900],
            neutral: alpha(grey[500], 0.12),
        },
        action: {
            ...base.action,
            active: grey[500],
        },
    };

    return mode === 'light' ? light : dark;
}
