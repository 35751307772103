/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';
import { updateError } from './user';
import { dispatch, store } from '../store';
import { getPracticeId } from './clincheck';
import { updateCompleteStatus } from './lead';
import { callApi } from '../../utils/apiCall';
import { getPaymentType, handleEditPaymentSuccess } from '../../utils/depositUtils';

const initialState = {
    depositData: null,
    depositPaymentDataGlobal: [],
    depositLoading: false,
};

const slice = createSlice({
    name: 'deposit',
    initialState,
    reducers: {
        setDepositData(state, action) {
            state.depositData = action.payload;
        },
        setDepositPaymentDataGlobal(state, action) {
            state.depositPaymentDataGlobal = action.payload;
        },
        setDepositLoading(state, action) {
            state.depositLoading = action.payload;
        },
    }
});

export default slice.reducer;

export const { setDepositData, setDepositPaymentDataGlobal, setDepositLoading } = slice.actions;

const handleSuccess = ({ leadTreatmentId, leadId }) => {
    const { practice } = store.getState().practice;
    const { depositData } = store.getState().deposit;
    const { depositAmount, paymentDate, additionalAmount, paymentMethod } = depositData;
    const paymentProcessorId = practice?.payment_processors?.find((ele) => paymentMethod?.includes(ele?.payment_processor))?.id || practice?.payment_processors?.[0]?.id;
    switch (paymentMethod) {
        case 'DEPOSIT_AND_GOCARDLESS':
        case 'DEPOSIT_AND_STRIPE':
            dispatch(createTreatmentPayment({
                body: {
                    payment_processor: paymentProcessorId || 0,
                    amount: additionalAmount,
                    paid_at: paymentDate
                }, treatmentId: leadTreatmentId, leadId,
            }));
            return;
        case 'DEPOSIT_AND_FINANCE':
            dispatch(createTreatmentPayment({
                body: {
                    payment_type: 'FINANCE',
                    amount: depositAmount,
                    paid_at: paymentDate
                }, treatmentId: leadTreatmentId, leadId,
            }));
            break;
        default:
            break;
    }
};

export function creteTreatmentPaymentFlow() {
    return async () => {
        try {
            const { followLead, createLead } = store.getState().lead;
            const { depositData } = store.getState().deposit;
            const { depositAmount, paymentDate, paymentMethod, treatmentId, depositPaymentData } = depositData;
            const leadId = followLead?.id || createLead?.id;
            const leadTreatmentId = treatmentId || createLead?.lead_treatment?.id;
            dispatch(setDepositData({ ...depositData, isDepositFlow: false }));
            if (depositPaymentData?.length > 0) {
                dispatch(editTreatmentPayment({
                    body: {
                        payment_type: getPaymentType(paymentMethod),
                        amount: depositAmount,
                        paid_at: paymentDate
                    }, treatmentId, leadId, paymentId: depositPaymentData?.[0]?.id,
                    handleSuccess: () => handleEditPaymentSuccess({ data: depositData, depositPaymentData, treatmentId, leadId })
                }));
                return;
            }
            dispatch(createTreatmentPayment({
                body: {
                    payment_type: getPaymentType(paymentMethod),
                    amount: depositAmount,
                    paid_at: paymentDate
                }, treatmentId: leadTreatmentId, leadId,
                handleSuccess: () => handleSuccess({ leadId, leadTreatmentId })
            }));
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function getTreatmentPayment(data) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data.leadId}/treatments/${data.treatmentId}/payments/`);
            dispatch(setDepositLoading(false));
            if (response.status === 200) {
                dispatch(setDepositPaymentDataGlobal(response?.data?.results));
            } else {
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(setDepositLoading(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
export function createTreatmentPayment(data) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data.leadId}/treatments/${data.treatmentId}/payments/`, 'post', data.body);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Treatment payment done successfully' }));
                if (data.handleSuccess) {
                    data.handleSuccess();
                }
            } else {
                dispatch(setDepositLoading(false));
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(setDepositLoading(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function editTreatmentPayment(data) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data.leadId}/treatments/${data.treatmentId}/payments/${data.paymentId}`, 'patch', data.body);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Treatment payment updated successfully' }));
                if (data.handleSuccess) {
                    data.handleSuccess();
                }
            } else {
                dispatch(setDepositLoading(false));
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(setDepositLoading(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function deleteTreatmentPayment(data) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${data.leadId}/treatments/${data.treatmentId}/payments/${data.paymentId}`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Treatment payment deleted successfully' }));
                if (data.handleSuccess) {
                    data.handleSuccess();
                }
            } else {
                dispatch(setDepositLoading(false));
                dispatch(updateCompleteStatus(false));
                dispatch(updateError({ message: JSON.stringify(response.data), success: false }));
            }
        } catch (error) {
            dispatch(setDepositLoading(false));
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}
