import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import { useOffSetTop } from '../../hooks';
// config
import { HEADER } from '../../config';
//
import Header from './Header';

// ----------------------------------------------------------------------

export default function CompactLayout() {
    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

    return (
        <>
            <Header isOffset={isOffset} />

            <Container component="main">
                <Stack
                    sx={{
                        py: 12,
                        m: 'auto',
                        maxWidth: 400,
                        minHeight: '100vh',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Outlet />
                </Stack>
            </Container>
        </>
    );
}
