import PropTypes from 'prop-types';
// libraries
import { Dialog, Button, DialogTitle, DialogActions, DialogContent, Typography } from '@mui/material';

const ConfirmDialog = ({ title, content, content2, action, open, contentId, onClose, ...other }) => (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} {...other}>
        {title && <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>}
        {content &&
            <DialogContent sx={{ typography: 'body2' }}>
                {content}
                {content2 && <Typography variant='body2' sx={{ mt: 1 }}>
                    {content2}
                </Typography>}
                {contentId &&
                    <Typography variant='subtitle2' sx={{ mt: 1 }}>
                        {contentId}
                    </Typography>
                }
            </DialogContent>
        }
        <DialogActions>
            <Button variant='outlined' color='inherit' onClick={onClose}>
                Cancel
            </Button>
            {action}
        </DialogActions>
    </Dialog>
);

export default ConfirmDialog;

ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.node,
    action: PropTypes.node,
    content: PropTypes.node,
    onClose: PropTypes.func,
    contentId: PropTypes.node,
    content2: PropTypes.node
};