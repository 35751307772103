export const leaveNotesList = [
    {
        label: 'No answer',
        value:'No answer'
    },
    {
        label: 'Left voicemail',
        value:'Left voicemail'
    },
    {
        label: 'Incorrect number',
        value:'Incorrect number'
    },
    {
        label: 'Call declined',
        value:'Call declined'
    },
    {
        label: 'Poor call quality',
        value:'Poor call quality'
    },
    {
        label: 'Reached different person',
        value:'Reached different person'
    },
    {
        label: 'Busy line',
        value:'Busy line'
    },
    {
        label:'Requested call-back',
        value:'Requested call-back'
    }
];