/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import _ from 'lodash';
import { Chip, Box, Paper, Divider, MenuItem, Checkbox } from '@mui/material';
// components
import { RHFSelect, RHFAutocomplete } from '../hook-form';
// redux
import { getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
import { dispatch, useSelector } from '../../redux/store';
// utils
import groupedTreatments from '../../utils/groupedTreatments';
// style
import { selectCategoryStyle, categoryTreatment } from '../../styles/CallScripts';

const SelectCategoryTreatment = ({ name, value, onChange, ...props }) => {
    const { treatmentsList: { results, count } } = useSelector((state) => state.practiceTreatment);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const [treatmentOption, SetTreatmentOptions] = useState([]);
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All' || '');
    const [selectAll, setSelectAll] = useState(false);

    const filterTreatment = selectedCategory !== 'All' ? treatmentOption.filter(e => e.category === selectedCategory.trim()) : treatmentOption;
    const filterValue = selectedCategory !== 'All' ? value.filter(e => e.category === selectedCategory) : value;
    const filterUnSelectValue = selectedCategory !== 'All' ? value.filter(e => e.category !== selectedCategory) : [];

    useEffect(() => {
        if (results) {
            const data = _.unionBy(results, 'id');
            const categoryData = _.uniq(groupedTreatments(data).map(e => e.category));
            SetTreatmentOptions(groupedTreatments(data));
            setCategory(['All', ...categoryData]);
        }
        if (results?.length < count) {
            const data = {
                limit: count,
                offset: results.length,
                enable: true
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    }, [results]);

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: 30,
                offset: 0,
                enable: true
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    }, [practiceId]);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        setSelectAll(false);
    };

    const handleOnChanged = (e, value) => {
        onChange(e, value);
    };

    const handleDelete = (delValue) => {
        const data = value?.filter((res) => res.id !== delValue);
        onChange('', data);
    };

    const handleToggleSelectAll = (e) => {
        e.preventDefault();
        if (e.target.checked !== undefined) {
            if (e.target.checked) {
                onChange('', _.uniqBy([...value, ...filterTreatment], 'id'));
                setSelectAll(true);
                return;
            }
            onChange('', filterUnSelectValue);
            setSelectAll(false);
        }
    };

    const showSelected = () => {
        const isTrue = areArraysOfObjectsEqual(filterTreatment, filterValue, 'id');
        if (isTrue) {
            setSelectAll(true);
            return true;
        }
        setSelectAll(false);
        return false;
    };

    const areArraysOfObjectsEqual = (filteredTreatments, filteredValues, key) => {
        if (filteredTreatments.length !== filteredValues.length) {
            return false;
        }
        return filteredTreatments.every(treatment => filteredValues.map(value => value[key]).includes(treatment[key]));
    };

    return (
        <Box sx={categoryTreatment}>
            <RHFSelect
                name='Category'
                label='Select Category'
                InputLabelProps={{ shrink: true }}
                value={selectedCategory || ''}
                onChange={handleCategoryChange}
                sx={{ ...selectCategoryStyle, width: '260px' }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
                {category.map((options, index) => (
                    <MenuItem
                        key={index}
                        value={options}
                    >
                        {options}
                    </MenuItem>
                ))}
            </RHFSelect>
            <RHFAutocomplete
                {...props}
                multiple
                name={name}
                label='Select treatments (Required)'
                value={value || []}
                onChange={handleOnChanged}
                options={filterTreatment}
                groupBy={(option) => option?.firstLetter}
                disableCloseOnSelect
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                getOptionLabel={(option) => option?.name}
                sx={{ ...selectCategoryStyle, width: '100%', maxWidth: '100%' }}
                renderTags={(value) => (
                    <Box>
                        {value?.map((item, index) => (
                            <Chip
                                key={index}
                                label={item?.name}
                                size='small'
                                color='info'
                                variant='soft'
                                sx={{ m: '3px' }}
                                onDelete={() => handleDelete(item.id)}
                            />
                        ))}
                    </Box>
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.name} style={{ padding: '5px 9px' }}>
                        <Box sx={{ padding: '0px 12px' }} >
                            <Checkbox checked={selected} label={option.name} name={option.name} />{option.name}
                        </Box>
                    </li>
                )}
                PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                        <Paper {...restPaperProps}>
                            {
                                <>
                                    <Box
                                        onMouseDown={(e) => e.preventDefault()}
                                        sx={{ padding: '5px 12px', ':hover': { cursor: 'pointer' } }}
                                    >
                                        <Checkbox
                                            id='select-all-checkbox'
                                            checked={showSelected(value) || selectAll}
                                            name='Select All'
                                            label='Select All'
                                            onClick={handleToggleSelectAll}
                                        /> Select All
                                    </Box>
                                    <Divider />
                                </>}
                            {children}
                        </Paper>
                    );
                }}
            />
        </Box>
    );
};

SelectCategoryTreatment.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    control: PropTypes.any
};

export default SelectCategoryTreatment;