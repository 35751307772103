/* eslint-disable react-hooks/exhaustive-deps */

import PropTypes from 'prop-types';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// -------------------------------------------------------------------------------------------

const SelectArchiveReasons = ({ name, value, onChange, type, archiveReasonData, ...other }) => {

    return (
        <Autocomplete
            name={name}
            value={value || ''}
            onChange={onChange}
            options={archiveReasonData || []}
            renderInput={(params) => <TextField {...params} label="Archive Reason" />}
            sx={{ '.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': { display:  type === 'search' && 'none' } }}
            {...other}
        />);
};

SelectArchiveReasons.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    type: PropTypes.string,
    archiveReasonData: PropTypes.array
};

export default SelectArchiveReasons;
