/* eslint-disable react-hooks/exhaustive-deps */
import { paramCase } from 'change-case';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';
// @mui
import {
    Box,
    Tab,
    Tabs,
    Card,
    Table,
    Switch,
    Button,
    Tooltip,
    Divider,
    TableBody,
    Container,
    IconButton,
    TableContainer,
    TablePagination,
    FormControlLabel,
} from '@mui/material';
import { useSnackbar } from 'notistack';
// components
import { useLocation } from 'react-router';
import { updateSelectedPracticeForMenu } from 'src/redux/slices/mergeTags';
import { useSettingsContext } from 'src/components/settings/context';
import useIsSuperAdmin from 'src/hooks/useIsSuperAdmin';
import { HeaderBreadcrumbs, Scrollbar, Iconify } from '../../../components';
import {
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TableSelectedActions
} from '../../../components/table';
import { TableLoading } from '../../../components/loading';
// constant
import {
    STATUS_OPTIONS,
    validInviteUserLabels,
    inValidInviteUserLabels,
    ADMIN_STATUS_OPTIONS
} from '../../../constants/PracticeUserListConst';
// hooks
import {
    useTabs,
    useAuth,
    useTable
} from '../../../hooks';
import { getComparator, stableSort } from '../../../hooks/useTable';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// sections
import { UserTableListRow, UserTableListToolbar } from '../../../sections/@dashboard/user/list';
// store
import { clearMessage, getInviteUser, DeleteInviteUser, disableUser, enableUser, getPracticeUserListWithFilter, startLoading } from '../../../redux/slices/practiceUser';
import { dispatch, useSelector } from '../../../redux/store';
// style
import { formControlLabelStyle } from '../../../styles/PracticeUserListStyle';
import { settingsMainBoxStyle, settingsContentBoxStyle } from '../../../styles/SettingsMenuStyle';

// ----------------------------------------------------------------------

export default function PracticeUserList() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        selected,
        setSelected,
        onSelectAllRows,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const settings = useSettingsContext();
    const { isSuperAdminUser } = useIsSuperAdmin();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [adminUser, setAdminUser] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterRole] = useState('all');
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
    const { message, error, practiceUserWithFilter: { results, count }, inviteUser, isLoading, practiceUser } = useSelector((state) => state.practiceUser);
    const [tableData, setTableData] = useState([]);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const isEdit = adminUser;
    const denseHeight = dense ? 52 : 72;
    const [tabFilterData, setTabFilterData] = useState('');
    const debouncedValue = useDebounce(filterName, 800);

    const validInviteUser = () => filterStatus === 'INVITE USER';

    const filterData = (data) => data.filter(el => el.user && !el.user.is_superuser);

    const tableHeadHandler = () => validInviteUser() ? validInviteUserLabels : inValidInviteUserLabels;

    const TABLE_HEAD = tableHeadHandler();

    const getUserList = () => {
        if (validInviteUser()) {
            return inviteUser.length ? inviteUser.filter(el => !el.is_accepted) : [];
        }
        return results && results.length ? filterData(results) : [];
    };

    const getRoleFilter = () => {
        if (isSuperAdminUser()) {
            return tabFilterData === 'all' || tabFilterData === 'INVITE USER' || tabFilterData === 'Disabled' ? '' : tabFilterData;
        }
        return tabFilterData === '' || tabFilterData === 'all' || tabFilterData === 'INVITE USER' || tabFilterData === 'Disabled' ? 'ADMIN,USER,Practitioner,TCO' : tabFilterData;
    };
    const isEnabled = () => tabFilterData === 'Disabled' ? true : '';

    useEffect(() => {
        if (tabFilterData) {
            const data = {
                practiceId,
                limit: 10,
                offset: 0,
                role: getRoleFilter(),
                is_enabled: isEnabled(),
                searchQuery: filterName
            };
            dispatch(getPracticeUserListWithFilter(data));
            resetPagination();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabFilterData]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (filterName !== null) {
            setPage(0);
            const data = {
                practiceId,
                limit: 10,
                offset: 0,
                role: getRoleFilter(),
                is_enabled: isEnabled(),
                searchQuery: debouncedValue
            };
            dispatch(getPracticeUserListWithFilter(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    const handleChangePerPage = (event) => {
        onChangeRowsPerPage(event);
        setPage(0);
        const data = {
            practiceId,
            limit: event.target.value,
            offset: 0,
            role: getRoleFilter(),
            searchQuery: filterName,
            is_enabled: isEnabled(),
        };
        dispatch(getPracticeUserListWithFilter(data));
    };

    const handleChangePage = (event, newPage) => {
        onChangePage(event, newPage);
        const data = {
            practiceId,
            limit: rowsPerPage,
            offset: rowsPerPage * newPage,
            role: getRoleFilter(),
            searchQuery: filterName,
            is_enabled: isEnabled(),
        };
        dispatch(getPracticeUserListWithFilter(data));
    };

    useEffect(() => {
        if (adminUser?.role === 'ADMIN' && !STATUS_OPTIONS.includes('INVITE USER')) {
            STATUS_OPTIONS.push('INVITE USER');
        }
    }, [adminUser?.role]);

    useEffect(() => {
        if (practiceUser && practiceUser?.results?.length) {
            const currentUser = user?.email;
            setTableData(getUserList());
            setAdminUser(practiceUser?.results?.find((users) => users?.user?.email === currentUser));
        }
    }, [practiceUser, inviteUser]);

    useEffect(() => {
        if (practiceId) {
            dispatch(getInviteUser(practiceId));
            const data = {
                practiceId,
                limit: 10,
                offset: 0,
                role: getRoleFilter(),
            };
            dispatch(getPracticeUserListWithFilter(data));
            resetPagination();
        }
    }, [practiceId]);

    useEffect(() => {
        if (message) {
            enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
        if (error) {
            enqueueSnackbar(error, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
    }, [message, error]);

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const handleDeleteRow = (id) => {
        if (!validInviteUser()) {
            const deleteRow = tableData.filter((row) => row.id !== id);
            setSelected([]);
            setTableData(deleteRow);
            return;
        }
        dispatch(DeleteInviteUser(practiceId, id, handleSuccess));
    };

    const handleSwitchChange = (row) => {
        if (row.is_enabled) {
            const data = {
                practice: row.practice,
                id: row.id,
                user: row.user,
                role: row.role,
                notification_preferences: row.notification_preferences
            };
            dispatch(disableUser(practiceId, data, handleUpdate));
            return;
        }
        const data = {
            practice: row.practice,
            id: row.id,
            user: row.user,
            role: row.role,
            notification_preferences: row.notification_preferences
        };
        dispatch(enableUser(practiceId, data, handleUpdate));
    };

    const handleUpdate = (data) => {
        enqueueSnackbar(typeof data.message === 'string' ? data.message : 'Something went wrong', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            id: 'success-bar'
        });
        const getData = {
            practiceId,
            limit: rowsPerPage,
            offset: rowsPerPage * page,
            role: getRoleFilter(),
            is_enabled: isEnabled(),
            searchQuery: filterName
        };
        dispatch(getPracticeUserListWithFilter(getData));
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(typeof data.message === 'string' ? data.message : 'Something went wrong', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
        });
        if (data.success) {
            resetPagination();
        }
    };

    const dataFiltered = applySortFilter({
        tableData: getUserList(),
        comparator: getComparator(order, 'role'),
        filterRole,
        filterStatus,
    });

    const handleDeleteRows = (selected) => {
        const deleteRows = tableData.filter((row) => !selected.includes(row.id));
        setSelected([]);
        setTableData(deleteRows);
    };

    const isNotFound =
        (!count && !!filterName) ||
        (!count && !!filterRole) ||
        (!count && !!filterStatus);

    const isNotFoundInviteUser =
        (!dataFiltered.length && !!filterName) ||
        (!dataFiltered.length && !!filterRole) ||
        (!dataFiltered.length && !!filterStatus);

    const handleChangeTab = (event, newValue) => {
        dispatch(startLoading());
        setTabFilterData(newValue);
        onChangeFilterStatus(event, newValue);
    };

    const resetPagination = () => {
        onChangeRowsPerPage({ target: { value: 10 } });
        onChangePage('', 0);
    };

    const location = useLocation()

    useEffect(()=>{
        dispatch(updateSelectedPracticeForMenu("practice_users"))
    }, [location.pathname])

    return (
        <>
            <Helmet>
                <title> Practice Users : List | Dental SEM CRM </title>
            </Helmet>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
                <Box sx={settingsMainBoxStyle}>
                    <Box sx={settingsContentBoxStyle}>
                        <HeaderBreadcrumbs
                            heading="Practice Users"
                            links={[
                                { name: 'Dashboard', href: PATH_DASHBOARD.root },
                                { name: 'Practice Users' },
                            ]}
                            action={isEdit &&
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to={PATH_DASHBOARD.user.new}
                                    id='invite-btn'
                                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                                >
                                    Invite User
                                </Button>
                            }
                        />
                        <Card>
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={filterStatus}
                                onChange={handleChangeTab}
                                sx={{ px: 2, bgcolor: 'background.neutral' }}
                            >
                                {adminUser?.role === 'ADMIN' ?
                                    ADMIN_STATUS_OPTIONS.map((tab, index) => (
                                        <Tab disableRipple key={index} label={tab === 'TCO' ? tab : tab.toLowerCase()} value={tab} />
                                    ))
                                    :
                                    STATUS_OPTIONS.map((tab, index) => (
                                        <Tab disableRipple key={index} label={tab === 'TCO' ? tab : tab.toLowerCase()} value={tab} />
                                    ))
                                }
                            </Tabs>

                            <Divider sx={{ mb: 2.5 }} />
                            {!validInviteUser() && <UserTableListToolbar
                                filterName={filterName}
                                onFilterName={handleFilterName}
                                onResetFilter={() => setFilterName('')}
                            />}
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                    {selected.length > 0 && (
                                        <TableSelectedActions
                                            dense={dense}
                                            numSelected={selected.length}
                                            rowCount={tableData.length}
                                            onSelectAllRows={(checked) =>
                                                onSelectAllRows(
                                                    checked,
                                                    tableData.map((row) => row.id)
                                                )
                                            }
                                            actions={
                                                <Tooltip title="Delete">
                                                    <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                                                        <Iconify icon={'solar:trash-bin-trash-bold'} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        />
                                    )}
                                    <Table size={dense ? 'small' : 'medium'} sx={{ '.MuiTableCell-root': { boxShadow: 'none !important' } }}>
                                        <TableHeadCustom
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={tableData.length}
                                            numSelected={selected.length}
                                            onSelectAllRows={(checked) =>
                                                onSelectAllRows(
                                                    checked,
                                                    tableData.map((row) => row.id)
                                                )
                                            }
                                        />
                                        <TableBody>
                                            <TableLoading tableData={isLoading && 'No Found'} pageCount={isLoading && 'No Found'} />
                                            {(validInviteUser() ? (stableSort(dataFiltered, getComparator(order, 'role')).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) : results)?.map((row, index) => (
                                                <UserTableListRow
                                                    key={index}
                                                    row={row}
                                                    selected={selected.includes(row.id)}
                                                    onDeleteRow={() => handleDeleteRow(row.id)}
                                                    onEditRow={() => navigate(PATH_DASHBOARD.user.edit(paramCase(JSON.stringify(row?.id))))}
                                                    tableListData={getUserList()}
                                                    inviteUser={validInviteUser()}
                                                    isEdit={isEdit}
                                                    handleSwitchChange={handleSwitchChange}
                                                    adminUser={adminUser?.role}
                                                    currentUser={user?.email} 
                                                />
                                            ))}
                                            <TableEmptyRows height={denseHeight} />
                                            <TableNoData isNotFound={validInviteUser() ? isNotFoundInviteUser : isNotFound} />
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <Box sx={{ position: 'relative' }}>
                                {validInviteUser() ?
                                    dataFiltered.length > 10 && <TablePagination
                                        rowsPerPageOptions={[10, 25]}
                                        component="div"
                                        count={dataFiltered.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={onChangePage}
                                        onRowsPerPageChange={onChangeRowsPerPage}
                                    />
                                    :
                                    count > 10 && <TablePagination
                                        rowsPerPageOptions={[10, 25]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                        onRowsPerPageChange={(event) => handleChangePerPage(event)}
                                    />
                                }
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label="Dense"
                                    sx={formControlLabelStyle}
                                />
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterStatus, filterRole }) {
    const stabilizedThis = tableData.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    tableData = stabilizedThis.map((el) => el[0]);

    const usersData = [];

    // ----- To combine the first name, last name and salutation of a user
    tableData.forEach((user) => {
        usersData.push({
            ...user,
            name: `${user.user && user.user.salutation} ${user.user && user.user.first_name} ${user.user && user.user.last_name}`,
        });
    });

    if (filterStatus !== 'all') {
        if (filterStatus !== 'INVITE USER') {
            tableData = tableData.filter((item) => item.role === filterStatus);
        }
    }

    if (filterRole !== 'all') {
        tableData = tableData.filter((item) => item.role === filterRole);
    }

    return tableData;
}
