/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    useImperativeHandle,
    forwardRef,
    useEffect,
    useState
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Grid,
    TextField,
    InputAdornment,
    FormControl,
    FormHelperText,
    Typography,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
//
import Iconify from './Iconify';
import UserSearchDropdown from './UserSearchDropdown';
import {
    FormProvider,
    RHFAutocomplete,
    RHFTextField
} from './hook-form';
// store
import { useSelector, dispatch } from '../redux/store';
import { saveNewLeadDetail, updateSteps, addAppointment } from '../redux/slices/lead';
import { getPracticeTreatmentList } from '../redux/slices/practiceTreatment';
// style
import {
    FollowUpTitle,
    appointmentTreatmentStyle,
    gridStyle,
    textFieldStyle,
    controllerGrid,
    boxStyle
} from '../styles/AddAppointmentStyle';
import { toggleButtonStyle } from '../styles/Common';
// validation
import { NewAppointmentSchema } from '../validations/validations';
// utils
import groupedTreatments from '../utils/groupedTreatments';

// ----------------------------------------------------------------------------------------------------------

const AddAppointment = forwardRef(({ handleClose, type, treatmentData }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const treatmentList = useSelector((state) => state.practiceTreatment.practiceTreatment.results);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { contactMethod, appointmentType, appointmentMethod } = useSelector((state) => state.schema);
    const [treatmentOptions, setTreatmentOptions] = useState([]);
    const [newtreatmentData, setNewTreatment] = useState(treatmentData.treatment);
    const [assignToState, setAssignToState] = useState([]);

    const defaultValues = {
        treatment: treatmentData && treatmentData.treatment || '',
        treatmentValue: treatmentData && treatmentData.price || '',
        appoinmentDate: null,
        bookedWith: [],
        practiceContactedVia: type === 'leadTable' ? 'PHONE' : '',
        preferredContactMethod: type === 'leadTable' ? 'IN_PRACTICE' : '',
        appointmentType: type === 'leadTable' ? 'TREATMENT' : '',
    };

    const methods = useForm({
        resolver: yupResolver(NewAppointmentSchema(assignToState)),
        defaultValues,
    });

    const {
        control,
        setValue,
        clearErrors,
        handleSubmit,
        getValues,
        formState: { errors },
        trigger
    } = methods;

    useEffect(() => {
        if (treatmentList) {
            setTreatmentOptions(groupedTreatments(treatmentList));
        }
    }, [treatmentList]);

    useEffect(() => {
        if (assignToState?.length > 0) {
            trigger('bookedWith');
        }
    }, [assignToState, trigger]);

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        [assignToState]
    );

    useEffect(() => {
        if (defaultValues.treatment) {
            setValue('treatment', defaultValues.treatment);
            setValue('treatmentValue', defaultValues.treatmentValue);
        }
    }, [defaultValues.treatment]);

    const onSubmit = async (data) => {
        if (type === 'RescheduleReminder' || type === 'leadTable') {
            const updatedTreatment = {
                treatment: data.treatment,
                custom_price: Number(data.treatmentValue)
            };
            const appointmentDetail = {
                datetime: data.appoinmentDate ? `${moment(data.appoinmentDate).format('DD-MM-YYYY HH:mm:ss')}.0Z` : null,
                method: data.preferredContactMethod || '',
                contacted_via: data.practiceContactedVia || '',
                note: data.notes || '',
                assignees: assignToState?.map((el) => el?.id),
                lead_treatment: treatmentData?.id || '',
                type: data?.appointmentType
            };
            dispatch(saveNewLeadDetail({
                startTreatment: {
                    appointmentDetail,
                    updatedTreatment
                }
            }));
            dispatch(addAppointment(appointmentDetail, practiceId, treatmentData?.lead, handleClose, 'AddAppointment'));
        }
    };

    const handleTreatment = (value) => {
        if (value) {
            setValue('treatmentValue', value.price.toString());
            clearErrors('treatmentValue');
            setNewTreatment(value.name);
            setValue('treatment', value.name);
        }
        else {
            setValue('treatmentValue', '');
            setValue('treatment', '');
            setNewTreatment('');
        }
    };

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeTreatmentList(practiceId));
        }
    }, [practiceId]);

    const updateNewStep = (value) => {
        const attaendence = moment(value).format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss');
        dispatch(updateSteps(attaendence ? 'payment' : 'attendance'));
    };

    return (
        <Box sx={{ width: '100%', pt: '30px' }}>
            <FollowUpTitle sx={{ mb: '30px' }}>Book an appointment</FollowUpTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} sx={gridStyle(type)}  >
                    <RHFAutocomplete
                        name={'treatment'}
                        control={control}
                        sx={appointmentTreatmentStyle}
                        value={{ name: newtreatmentData }}
                        options={treatmentOptions}
                        groupBy={(option) => option.firstLetter}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, newValue) => handleTreatment(newValue)}
                        renderInput={(params) => <TextField {...params} label="Treatment (Required)" error={getValues('treatment').length <= 0} helperText={getValues('treatment').length <= 0 && 'This field is required'} />}
                    />
                    <RHFTextField
                        sx={textFieldStyle}
                        name='treatmentValue'
                        label='Treatment value (Required)'
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <Iconify icon={'material-symbols:currency-pound'} width={16} height={16} />
                            </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid sx={controllerGrid}>
                    <Box>
                        <Controller
                            name='appoinmentDate'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <DateTimePicker
                                        label='Appointment date (Required)'
                                        value={field.value || null}
                                        minDateTime={new Date()}
                                        format={'dd/MM/yyyy HH:mm'}
                                        ampm={false}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                            updateNewStep(newValue);
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                                onKeyDown: (e) => e.preventDefault()
                                            },
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Box>
                    <UserSearchDropdown name={'bookedWith'} control={control} label='Booked with (Required)' assignToState={assignToState} setAssignTo={setAssignToState} error={errors?.bookedWith} />
                </Grid>
                <Box sx={{ ...boxStyle, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                    <Controller
                        name="preferredContactMethod"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ m: '0 0 10px' }}
                                >
                                    Appointment method (Required)
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                    error={error && !field.value?.length}
                                >
                                    {appointmentMethod?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle(isLight)}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                {error && !field.value?.length &&
                                    <FormHelperText error sx={{ px: 2 }}>
                                        {error?.message}
                                    </FormHelperText>}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="appointmentType"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ m: '0 0 10px' }}
                                >
                                    Appointment type (Required)
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                    error={error && !field.value?.length}
                                >
                                    {appointmentType?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                {error && !field.value?.length &&
                                    <FormHelperText error sx={{ px: 2 }}>
                                        {error?.message}
                                    </FormHelperText>}
                            </FormControl>
                        )}
                    />
                    <Controller
                        name="practiceContactedVia"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControl>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ m: '0 0 10px' }}
                                >
                                    Contact method (Required)
                                </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    value={field.value}
                                    onChange={(e, value) => {
                                        if (value === null) {
                                            field.onChange('');
                                            return;
                                        }
                                        field.onChange(value);
                                    }}
                                    aria-label="Platform"
                                    sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                    error={error && !field.value?.length}
                                >
                                    {contactMethod?.map((options, index) => (
                                        <ToggleButton
                                            key={index}
                                            value={options.value}
                                            sx={toggleButtonStyle}
                                        >
                                            <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                {error && !field.value?.length &&
                                    <FormHelperText error sx={{ px: 2 }}>
                                        {error?.message}
                                    </FormHelperText>}
                            </FormControl>
                        )}
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <RHFTextField
                        sx={{ width: { xs: '100%', md: '100%' } }}
                        name="notes" multiline rows={4}
                        label="Appointment notes"
                        placeholder="Add notes here"
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
            </FormProvider>
        </Box>
    );
});

AddAppointment.propTypes = {
    handleClose: PropTypes.func,
    type: PropTypes.any,
    treatmentData: PropTypes.any,
};

export default AddAppointment;