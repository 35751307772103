import { styled } from '@mui/material/styles';

export const commonDialogHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
    rowGap: 2,
    flexDirection: { sm: 'row' },
    position: 'fixed !important',
    top: 0,
    textAlign: 'center',
    pt: 3,
    pb: 3,
    bgcolor: 'primary.main'
};
export const onBoardDialogHeader = {
    position: 'fixed !important',
    bgcolor: 'info.main',
    top: 0,
    textAlign: 'center',
    pt: 3,
    pb: 3
};
export const reminderDialogStyle =  type => ({
    zIndex: type === 'onboarad' ? 1117 : 1200,
    margin: { xs: '0 -16px', sm: 0 },
    pt: { xs: 15, sm: 10 }
});
export const reminderTitleStyle = {
    display: 'block',
    fontSize: '24px !important',
    lineHeight: '36px',
    color: '#fff'
};
export const cancelBoxStyle = {
    display: 'flex',
    columnGap: 2,
    justifyContent: 'center'
};
export const reminderStackStyle = {
    display: 'inline-block',
    padding: {
        md: '40px 30px 150px',
        xs: '40px 10px 150px 5px'
    }
};
export const appointmentTreatmentStyle = {
    width: '100%',
    maxWidth: { md: 'calc(70% - 20px)' },
    mb: {
        sm: 0,
        xs: 0
    }
};

// openAttenedPopUp
export const attendedStackStyle = {
    display: 'inline-block',
    padding: {
        md: '70px 30px 150px',
        xs: '70px 10px 150px 5px'
    }
};

// openPayment
export const leadFooterButtonsStyle = {
    mt: 3,
    display: 'flex',
    justifyContent: 'center',
    pr: { xs: 4 },
    pl: { xs: 4 },
    '@media(max-width: 900px)': {
        mt: '20px'
    }
};

export const finishButtonStyle = type => (
    type === 'createLead' || type === 'followup' ? {
        px: { xs: 3.5, sm: 8 }
    } : {
        px: { xs: 3.5, sm: 8 },
        py: 0.75
    }
);

// unattended

export const PhoneIconButton = styled('div')(() => ({
    backgroundColor: 'rgba(0, 171, 85, 0.16)',
    borderRadius: 12,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16
}));

export const footerBoxStyle = {
    width: '100%',
    pb: 5,
    position: 'fixed',
    left: 0,
    bottom: 0,
    bgcolor: 'background.paper',
    zIndex: 99,
    '@media(max-width: 900px)': {
        pb: '20px'
    }
};

// reschedule
export const btnStyle = {
    py: 0.75,
    px: {
        xs: 3.5,
        sm: 8
    }
};