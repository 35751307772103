import { createSlice } from '@reduxjs/toolkit';
// utils
import moment from 'moment';
import { getParsedData, localStorageSetItem } from 'src/utils/storage-available';
import { callApi } from '../../utils/apiCall';
//
import { dispatch, store } from '../store';
import { updateError } from './user';
import { getPracticeId } from './clincheck';

// ---------------------------------------------------------------------
const initialState = {
    isLoading: false,
    toDoNotificationData: [],
    reminderNotificationsData: [],
    toDoData: [],
    reminderData: [],
};

const slice = createSlice({
    name: 'toDoNotifications',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        setToDoNotificationData(state, action) {
            state.toDoNotificationData = action.payload;
        },
        setReminderNotificationsData(state, action) {
            state.reminderNotificationsData = action.payload;
        },
        setToDoData(state, action) {
            state.toDoData = action.payload;
        },
        setReminderData(state, action) {
            state.reminderData = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

export const { setToDoNotificationData, setReminderNotificationsData, reset, setToDoData, setReminderData } = slice.actions;

export const getMessage = (firstName, lastName, treatment, method) => {
    const messageForFutureDate = `${method} ${firstName} ${lastName} about ${treatment}`;
    return messageForFutureDate;
};

export const handleSnooze = (data, handleCloseNotiFications) => {
    const { id, firstName, treatment, lastName, method, snoozeTime } = data;
    const currentTime = moment();
    const snoozeEndTime = moment(snoozeTime);
    if (currentTime.isSameOrAfter(snoozeEndTime)) {
        const { toDoData, reminderData } = store.getState().toDoNotifications;
        const temp = {
            ...data,
            message: getMessage(firstName, lastName, treatment, method)
        };
        const uniqReminderData =  reminderData.filter((ele) => ele.id !== id);
        const uniqToDoData =  toDoData.filter((ele) => ele.id !== id);
        localStorageSetItem('reminderData', JSON.stringify([temp, ...uniqReminderData]));
        dispatch(setToDoData([temp, ...uniqToDoData]?.slice(0, 2)));
        dispatch(setReminderData([temp, ...uniqReminderData]));
    } else {
        const timeout = setTimeout(() => {
            const { toDoData, reminderData } = store.getState().toDoNotifications;
            const temp = {
                ...data,
                message: getMessage(firstName, lastName, treatment, method)
            };
            const uniqReminderData =  reminderData.filter((ele) => ele.id !== id);
            const uniqToDoData =  toDoData.filter((ele) => ele.id !== id);
            localStorageSetItem('reminderData', JSON.stringify([temp, ...uniqReminderData]));
            dispatch(setToDoData([temp, ...uniqToDoData]?.slice(0, 2)));
            dispatch(setReminderData([temp, ...uniqReminderData]));
        }, snoozeEndTime.diff(currentTime));
        const oldTimeOutIds = getParsedData('timeout_ids') || [];
        localStorageSetItem('timeout_ids', JSON.stringify([...oldTimeOutIds, timeout]));
    }
    handleCloseNotiFications(id);
};

export const getTime = (dateTime, isWp) => {
    const dateTimeString = moment(dateTime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ');
    if (isWp) return `${moment(dateTimeString, 'HH:mm').format('hh:mm A')} `;
    return `Due today at ${moment(dateTimeString, 'HH:mm').format('hh:mm A')} `;
};

export function getToDoNotificationsData(data) {
    return async () => {
        try {
            const fields = '&fields={assignees,created_by,updated_at,created_at,datetime,datetime_human,description,group,id,is_completed,is_system_generated,lead,lead_appointment,lead_treatment,method,note,title,priority}';
            const response = await callApi(
                `/api/v1/practices/${getPracticeId()}/reminders/?datetime__gte=${data.startDate}&datetime__lte=${data.endDate
                }${fields}&is_completed=false&lead__is_archived=false`
            );
            if (response.status === 200) {
                const newNotifications = response.data?.results?.map((ele, index) => ({
                    leadId: ele.lead.id,
                    id: ele.id,
                    message: getMessage(
                        ele?.lead?.first_name,
                        ele?.lead?.last_name,
                        ele?.lead_treatment?.treatment,
                        ele?.method,
                    ),
                    dateTime: ele?.datetime,
                    firstName: ele?.lead?.first_name,
                    HumanReadable: ele.datetime_human,
                    lastName: ele?.lead?.last_name,
                    method: ele?.method,
                    treatment: ele?.lead_treatment?.treatment,
                    key: index
                }));
                localStorageSetItem('reminderData', JSON.stringify(newNotifications));
                dispatch(slice.actions.setToDoData(newNotifications?.slice(0, 2)));
                dispatch(slice.actions.setReminderData(newNotifications));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: 'Something went wrong' }));
        }
    };
}