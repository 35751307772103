import React from 'react';
import PropTypes from 'prop-types';
// mui
// components
import WidgetButtons from './WidgetButtons';
import CustomWidgetCard from './CustomWidgetCard';
// hooks
import { useToggle, useWidgetColors } from '../../hooks';

const NewLeadTableReportsWidget = ({
    counter,
    onWidgetClick,
    currentSelectedTab,
    dateRangeProps
}) => {
    const { DARK_BLUE } = useWidgetColors();
    const { toggle, onOpen, onClose } = useToggle(true);
    const tabCounter = (data) => counter?.filter((value) => (value.filterStatus === data ? value.count : null));

    const handleOpen = () => onOpen();

    const handleClose = () => onClose();

    const widgetArray = [
        { bgColor: '#D0F2FFCC', textColor: DARK_BLUE, key: '0', icon: 'streamline:user-add-plus-solid', value: '0', text: 'New patient leads to contact' },
        { bgColor: '#FFF7CD', textColor: '#B78103', key: 'AWAITING_RESPONSE', icon: 'mingcute:time-fill', value: 'AWAITING_RESPONSE', text: 'Awaiting lead responses' },
        { bgColor: '#FFE7D9', textColor: '#B72136', key: '1', icon: 'icomoon-free:phone', value: '1', text: '1st follow-up required' },
        { bgColor: '#FFE7D9', textColor: '#B72136', key: '2', icon: 'icomoon-free:phone', value: '2', text: '2nd follow-up required' },
        { bgColor: '#FFE7D9', textColor: '#B72136', key: '3', icon: 'icomoon-free:phone', value: '3', text: '3rd+ follow-up required' },
    ];

    const handleWidgetClick = (value) => {
        const count = tabCounter('AWAITING_RESPONSE')?.[0]?.count;
        if (value === 'AWAITING_RESPONSE' && (count === 0 || count === undefined || count === null)) return;
        onWidgetClick(value);
    };

    return (
        <>
            <WidgetButtons
                toggle={toggle}
                dateRangeProps={dateRangeProps}
                handleOpen={handleOpen}
                handleClose={handleClose}
            />
            {toggle && (
                <CustomWidgetCard handleWidgetClick={handleWidgetClick} widgetArray={widgetArray} currentWidget={currentSelectedTab} counter={counter} />
            )}
        </>
    );
};

export default NewLeadTableReportsWidget;

NewLeadTableReportsWidget.propTypes = {
    counter: PropTypes.func,
    onWidgetClick: PropTypes.func,
    currentSelectedTab: PropTypes.any,
    dateRangeProps: PropTypes.any
};
