import PropTypes from 'prop-types';
import React, { useState } from 'react';
// use @mui
import {
    TableRow,
    TableCell,
    Box,
    Typography,
    Button,
    useTheme
} from '@mui/material';
// components
import { ConfirmDialog, FormDialog } from '../../../components/dialogPopUp';
// style
import { messageTableCategory } from '../../../styles/MessageListStyle';

const WhatsappTemplatesTableRow = ({ row, selected, messageType, handleEditTags }) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDialogue, setDialogue] = useState(false);
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleSend = () => {
        setDialogue(false);
    };

    return (
        <TableRow
            hover selected={selected}
            sx={{
                bgcolor: (row?.created_by?.is_superuser && messageType !== 'engagement_templates') && isLight ? 'rgb(240, 246, 250)' : 'transparent'
            }}
        >
            <TableCell
                sx={{ maxWidth: '320px' }}
            >
                <Box
                    sx={{ ml: 0 }}
                >
                    <Typography
                        variant='subtitle2'
                        noWrap
                        sx={{
                            width: '100%',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {row.name}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box
                    sx={{ ml: 0 }}
                >
                    <Typography
                        variant='body'
                        noWrap
                        sx={messageTableCategory}
                    >
                        {row.category?.toLowerCase()}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box
                    sx={{ ml: 0 }}
                >
                    <Typography
                        variant='body'
                        noWrap
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {row.status?.toLowerCase()}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box
                    sx={{ ml: 0 }}
                >
                    <Typography
                        variant='body'
                        noWrap
                        sx={{ textDecoration: 'underline', cursor: 'pointer', color: theme.palette.info.dark }}
                        onClick={() => handleEditTags(row)}
                    >
                        View Tags
                    </Typography>
                </Box>
            </TableCell>
            <ConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title='Delete'
                content={<Box sx={{ fontSize: '18px', mb: '20px' }}>{'Are you sure you want to delete?'}</Box>}
                action={
                    <Button variant='contained' color='error' onClick={handleCloseConfirm}>
                        Delete
                    </Button>
                }
            />
            {openDialogue && <FormDialog
                open={openDialogue}
                handleClose={handleSend}
                data={row.message?.type || row?.channel}
            />}
        </TableRow>
    );
};

WhatsappTemplatesTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    messageType: PropTypes.string,
    handleEditTags: PropTypes.func
};

export default WhatsappTemplatesTableRow;