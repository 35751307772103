import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography, List, ListItem, ListItemText, useTheme } from '@mui/material';
import Iconify from '../Iconify';
import { NormalLoading } from '../loading';
import SingleScriptPreview from './SingleScriptPreview';
import capitalizeFirstLetter from '../../utils/capitalizedFunc';
import { callScriptListItemStyle, closeButtonStyle } from '../../styles/CallScripts';

const CallScriptListing = ({ type, scriptList, setCallScriptActive, onClose, followLead, onOpenAppointmentNotBooked, setLeadId, onOpenAppointmentBooked, setTreatmentId }) => {
    const [singleScriptActive, setSingleScriptActive] = useState(scriptList?.length === 1);
    const theme = useTheme();
    const isScriptTCO = type === "TCO";
    const isLight = theme.palette.mode === 'light';
    const [scriptData, setScriptData] = useState(scriptList?.length === 1 ? scriptList[0] : []);

    const BackButtonClick = () => {
        if (scriptList?.length === 1) {
            setCallScriptActive(false);
            return;
        }
        setSingleScriptActive(!singleScriptActive);
    };

    const handleScriptClick = (value) => {
        const updateValue = { ...value, sections: [...value?.sections]?.sort((a, b) => a.order - b.order) };
        setSingleScriptActive(!singleScriptActive);
        setScriptData(updateValue);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {!singleScriptActive ? <Card sx={{ width: isScriptTCO ? '100%' : '563px', mx: 'auto' }}>
                <Box sx={{ p: '15px 16px', borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)', position: 'relative' }}>
                    <Typography sx={{ fontSize: '20px', fontWeight: '700', textAlign: 'center' }}>{isScriptTCO ? "TCO Scripts" : "Call Scripts"}</Typography>
                    {<Box sx={closeButtonStyle} onClick={() => setCallScriptActive(false)}>
                        <Iconify icon={'eva:close-fill'} width={'20px'} height={'20px'} />
                    </Box>}
                </Box>
                <Box sx={{ p: '24px' }}>
                    <Box>
                        <NormalLoading tableData={scriptList === undefined && 'No Found'} pageCount={scriptList === undefined && 'No Found'} />
                        <List sx={{ m: '0', p: '0' }}>
                            {scriptList?.map((value, index) => (
                                <ListItem key={index} sx={callScriptListItemStyle(isLight)} onClick={() => handleScriptClick(value)}>
                                    <ListItemText primary={`${capitalizeFirstLetter(value.name)}`} sx={{ '.MuiTypography-root': { fontSize: '13px', fontWeight: '700' } }} />
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Iconify icon={'akar-icons:chevron-right'} width={'20px'} height={'20px'} />
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Card> :
                <SingleScriptPreview isScriptTCO={isScriptTCO} scriptData={scriptData} BackButtonClick={BackButtonClick} onClose={onClose} followLead={followLead} onOpenAppointmentNotBooked={onOpenAppointmentNotBooked} setLeadId={setLeadId} onOpenAppointmentBooked={onOpenAppointmentBooked} setTreatmentId={setTreatmentId} />
            }
        </Box>
    );
};

export default CallScriptListing;

CallScriptListing.propTypes = {
    scriptList: PropTypes.array,
    setCallScriptActive: PropTypes.func,
    onClose: PropTypes.func,
    followLead: PropTypes.any,
    onOpenAppointmentNotBooked: PropTypes.func,
    setLeadId: PropTypes.func,
    onOpenAppointmentBooked: PropTypes.func,
    setTreatmentId: PropTypes.func,
    type: PropTypes.string
};