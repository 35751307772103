/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useDebounce } from 'use-lodash-debounce';
import {
    Box,
    TextField,
    Autocomplete
} from '@mui/material';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { getAllTagItem, startLoading } from '../../redux/slices/tagsCreate';
import { saveNewCampaign, updateSelectedCampaign } from '../../redux/slices/campaign';
// validation
import { AutocompleteTagsSchema } from '../../validations/validations';
// style
import {
    tagsBoxStyle,
    selectedTagStyle,
    allTagChipStyle,
} from '../../styles/AutocompleteTags';

const AutocompleteAllTags = ({ type, tableType }) => {
    const { getAllTag: { results, count }, isLoading } = useSelector((state) => state.tagsCreate || []);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { getTag } = useSelector((state) => state.tagsCreate || []);
    const { newCampaign, selectedCampaign } = useSelector((state) => state.campaign);
    const allData = getTag;
    const [position, setPosition] = useState(0);
    const listElem = useRef();
    const mounted = useRef();
    const [inputValue, setInputValue] = useState('');
    const inputValueDebounce = useDebounce(inputValue, 800);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const defaultValues = {
        tags: newCampaign?.tagsNames || selectedCampaign?.recepient?.filters?.lead_tags__tag__tag__name__in || []
    };

    const notArchType = tableType !== 'ArchiveTable';

    const allTags = notArchType ? results?.map(e => e?.tag?.name) : [];

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(AutocompleteTagsSchema),
        defaultValues,
    });

    const {
        setValue,
        control,
    } = methods;

    useEffect(() => {
        if (practiceId) {
            dispatch(getAllTagItem(practiceId, 30, 0));
        }
    }, [practiceId]);

    const handleTags = (event, newValue) => {
        const data = { lead_tags__tag__tag__name__in: newValue };
        setValue('tags', newValue[newValue?.length - 1]);
        if (Object.values(selectedCampaign).length > 0) {
            const updatedCampaign = { ...selectedCampaign, recepient: { ...selectedCampaign.recepient, filters: { ...selectedCampaign?.recepient?.filters, ...data } } };
            dispatch(updateSelectedCampaign(updatedCampaign));
            return;
        }
        dispatch(saveNewCampaign({ lead_tags__tag__tag__name__in: data, tagsNames: newValue }));
    };

    const getOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option?.inputValue;
        }
        return option;
    };

    const leadMoreData = () => {
        if (count > results?.length) {
            dispatch(getAllTagItem(practiceId, 30, results?.length));
        }
    };

    const leadMorePosition = (event) => {
        const listboxNode = event?.target?.scrollHeight <= event?.target?.scrollTop + event?.target?.clientHeight;
        if (listboxNode) {
            setPosition(event?.target?.scrollTop + event?.target?.clientHeight);
            leadMoreData();
        }
    };

    useEffect(() => {
        if (practiceId) {
            if (inputValueDebounce) {
                const data = {
                    tag__name__icontains: inputValueDebounce,
                };
                dispatch(getAllTagItem(practiceId, 30, 0, data));
            } else {
                dispatch(getAllTagItem(practiceId, 30, 0));
            }
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        dispatch(startLoading());
        setInputValue(value);
    };

    return (
        <Box sx={tagsBoxStyle(type)}>
            <Controller
                name="tags"
                control={control}
                render={() => (
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={allTags || []}
                            noOptionsText={isLoading ? 'Loading...' : 'No options'}
                            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
                            value={newCampaign.tagsNames || selectedCampaign?.recepient?.filters?.lead_tags__tag__tag__name__in || allData || []}
                            sx={allTagChipStyle}
                            onChange={(event, newValue) => handleTags(event, newValue)}
                            getOptionLabel={(option) => getOptionLabel(option)}
                            ListboxProps={{
                                ref: listElem,
                                onScroll: leadMorePosition
                            }}
                            renderOption={(props, option,) => (
                                <li {...props} key={option} style={{ padding: '0' }}>
                                    <Box sx={selectedTagStyle(allData, option)}>{option}</Box>
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} placeholder={'Tag'} />}
                        />
                    </Box>
                )}
            />
        </Box>
    );
};

export default AutocompleteAllTags;

AutocompleteAllTags.propTypes = {
    type: PropTypes.string,
    selectData: PropTypes.object,
    data: PropTypes.object,
    handleSelect: PropTypes.func,
    tableType: PropTypes.string,
    reloadCounter: PropTypes.func,
    updateLeadPage: PropTypes.func,
};