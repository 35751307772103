/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { updateError } from './user';
// utils
import { callAdminApi } from '../../utils/apiCall';

const initialState = {
    isLoading: false,
    templates: {},
    superData: {
        practices: []
    },
    templateDetails: {},
    autoReminders: '',
    autoReminderRowData: '',
    practiceTreatment: {
        results: []
    },
    treatmentsList: {
        results: []
    },
    isNewTemplate: false,
    isAutoSaving: false,
    autoSaveStatus: false
};

export const slice = createSlice({
    name: 'superAdmin',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        getTemplateLits(state, action) {
            state.templates = action.payload;
        },
        getTemplateSingleDetails(state, action) {
            state.templateDetails = action.payload;
        },
        updateSuperAdmin(state, action) {
            state.superData = action.payload;
        },
        setAutoReminders(state, action) {
            state.isLoading = false;
            state.autoReminders = action.payload;
        },
        addReminderRowData(state, action) {
            state.isLoading = false;
            state.autoReminderRowData = action.payload;
        },
        getPracticeTreatmentData(state, action) {
            state.isLoading = false;
            state.practiceTreatment = action.payload;
        },
        addTreatmentsList(state, action) {
            state.isLoading = false;
            state.treatmentsList = action.payload;
        },
        addMoreTreatmentsList(state, action) {
            state.isLoading = false;
            state.treatmentsList.results = [
                ...state.treatmentsList.results,
                ...action.payload
            ];
        },
        setIsNewTemplate(state, action) {
            state.isNewTemplate = action.payload;
        },
        setIsAutoSaving(state, action) {
            state.isAutoSaving = action.payload;
        },
        setIsAutoSaveStatus(state, action) {
            state.autoSaveStatus = action.payload;
        }
    }
});

export const { updateSuperAdmin, getTemplateSingleDetails, reset, startLoading, setAutoReminders, addReminderRowData, addTreatmentsList, setIsNewTemplate } = slice.actions;

export default slice.reducer;

const getFilter = (filter) => {
    switch (filter) {
        case 'all': return '';
        case 'unLayer': return '&use_unlayer=true';
        case 'basic': return '&use_unlayer=false';
        default: return '';
    }
};

// GET API
export function getAllTemplates(limit, offset, unLayerFilter) {
    return async () => {
        try {
            const unLayerFilterQuery = getFilter(unLayerFilter);
            const response = await callAdminApi(`/api/v1/management/engagement-templates/?limit=${limit}&offset=${offset}&ordering=-created_at${unLayerFilterQuery}`);
            if (response.status === 200) {
                dispatch(slice.actions.getTemplateLits(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

export function getTemplateDetails(id) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/engagement-templates/${id}`);
            if (response.status === 200) {
                dispatch(slice.actions.getTemplateSingleDetails(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

// POST API
export function addTemplate(data, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi('/api/v1/management/engagement-templates/', 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Template created successfully' }));
                handleSuccess(response);
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

export function assignTemplate(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/engagement-templates/${id}/assign/`, 'post', data);
            if (response.status === 200) {
                if (handleSuccess) {
                    handleSuccess();
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

export function unAssignTemplate(id, data, handleSuccess, isSaveAndCloses) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/engagement-templates/${id}/unassign/`, 'post', data);
            if (response.status === 200) {
                if (handleSuccess) {
                    handleSuccess('unAssign', isSaveAndCloses);
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

// PATCH API
export function updateTemplate(id, data) {
    return async () => {
        dispatch(slice.actions.setIsAutoSaving(true));
        try {
            const response = await callAdminApi(`/api/v1/management/engagement-templates/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Template updated successfully' }));
                dispatch(slice.actions.setIsAutoSaveStatus(true));
                setTimeout(() => {
                    dispatch(slice.actions.setIsAutoSaveStatus(false));
                }, 2000);
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
            dispatch(slice.actions.setIsAutoSaving(false));
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
            dispatch(slice.actions.setIsAutoSaving(false));
        }
    };
}

// DELETE API
export function deleteTemplate(id, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/engagement-templates/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Template deleted successfully' }));
                handleSuccess();
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

export function getAutoReminders(data) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '';
            const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}` : '';
            const param = `${limit}${offset}`;
            const response = await callAdminApi(`/api/v1/management/auto-reminders/?ordering=-created_at${param}&fields={created_at,delay,event,group,id,is_enabled,method,name,note,title,treatments{id,name}}`);
            if (response.status === 200) {
                dispatch(slice.actions.setAutoReminders(response.data));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function addAutoReminder(data, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi('/api/v1/management/auto-reminders/', 'post', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Auto reminder created successfully' }));
                handleSuccess(response);
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getRowReminder(data) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/auto-reminders/${data?.id}/`);
            if (response.status === 200) {
                dispatch(slice.actions.addReminderRowData(response?.data));
            } else {
                dispatch(updateError({ success: false, message: response?.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function updateRowReminder(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/auto-reminders/${id}/`, 'put', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Reminder updated successfully' }));
                handleSuccess(response);
            } else {
                dispatch(updateError({ success: false, message: response?.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function enableRowReminder(id, data) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/auto-reminders/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Reminder updated successfully' }));
            } else {
                dispatch(updateError({ success: false, message: response?.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function deleteAutoReminder(id, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/auto-reminders/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Reminder Delete successfully' }));
                handleSuccess({ success: true });
            } else {
                dispatch(updateError({ success: false, message: response?.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error?.message || 'Something went wrong' }));
        }
    };
}

export function assignAutoReminder(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/auto-reminders/${id}/assign/`, 'post', data);
            if (response.status === 200) {
                if (handleSuccess) {
                    handleSuccess();
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}
export function unAssignAutoReminder(id, data, handleSuccess) {
    return async () => {
        try {
            const response = await callAdminApi(`/api/v1/management/auto-reminders/${id}/unassign/`, 'post', data);
            if (response.status === 200) {
                if (handleSuccess) {
                    handleSuccess('unAssign');
                }
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message }));
        }
    };
}

export function getTreatmentList(data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callAdminApi(`/api/v1/management/treatment-items/?ordering=treatment_item__name${data?.limit ? `&limit=${data?.limit}` : ''}${data?.offset === 0 || data?.offset ? `&offset=${data?.offset}` : ''}${data?.treatment ? `&treatment_item__name__icontains=${data?.treatment}` : ''}${data?.category ? `&treatment_item__treatment_category__name__icontains=${data?.category}` : ''}`);
            if (response.status === 200) {
                if (data?.limit || data?.type === 'searchTreatment') {
                    if (data?.offset === 0 || data?.type === 'practiceTreatment' || data?.type === 'searchTreatment') {
                        dispatch(slice.actions.addTreatmentsList(response?.data));
                    } else {
                        dispatch(slice.actions.addMoreTreatmentsList(response?.data?.results));
                    }
                    return;
                }
                dispatch(slice.actions.getPracticeTreatmentData(response?.data));
            } else {
                dispatch(updateError({ message: JSON.stringify(response.detail) || 'Something went wrong', success: false }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}