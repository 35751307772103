/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import { useEffect, useRef, useState } from 'react';
// @mui
import { Box, Typography, Card, Button, Tooltip, IconButton } from '@mui/material';
// other libraries
import Papa from 'papaparse';
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
// components
import FileMappings from './FileMappings';
import TransferList from './TransferList';
import { VisuallyHiddenInput } from '../styledComponents';
import { Iconify } from '..';
// redux
import { setFileName, setImportFiles, setMappingValues, setOriginalDataFile } from '../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../redux/store';
// utils/constants
import { emailRegex } from '../../utils/regex';
import { sampleCsv } from '../../constants/commonConstants';
// style
import { common, grey } from '../../theme/palette';
import { importLeadCardStyle, sampleBoxStyle } from '../../styles/ImportLeads';

const UploadFile = () => {
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isFileValue, setIsFileValue] = useState('');
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [uploadedFile, setUploadedFile] = useState('');
    const [originals, setOriginals] = useState([]);
    const [duplicates, setDuplicates] = useState([]);
    const [all, setAll] = useState([]);
    const { importFiles, fileName } = useSelector((state) => state.audienceManager);
    const csvLink = fileName;
    const fileInputRef = useRef(null);

    const handleHeaders = (file) => {
        Papa.parse(file, {
            download: true,
            header: false,
            complete(results) {
                setHeaders(results.data);
            },
        });
    };

    useEffect(() => {
        if (importFiles) {
            setIsFileValue(fileName);
            setIsFileUploaded(true);
            handleHeaders(importFiles);
        }
    }, [importFiles]);

    useEffect(() => {
        if (importFiles && !importFiles.includes('https://dentalcrm-media')) {
            Papa.parse(importFiles, {
                complete(results) {
                    const filteredData = results.data?.filter((item) => !Object.values(item).every(value => value === '')) || [];
                    setUploadedFile(filteredData);
                },
                download: true,
                header: true,
            });
        }
    }, []);

    const dobCheck = (item) => {
        if (!item.dob) return true;
        const dob = moment(item.dob, 'DD/MM/YYYY', true);
        if (!dob.isValid()) return true;
        return moment().diff(dob, 'years') > 17;
    };

    const handleFilter = (group, key) => group.find((item) => item.title && item.title.toLowerCase() === key && dobCheck(item));

    useEffect(() => {
        if (!isEmpty(uploadedFile)) {
            const filteredData = uploadedFile.filter((entry) => entry.email && emailRegex.test(entry.email));
            const groupByEmail = filteredData.reduce((groups, item) => {
                const group = groups[item.email] || [];
                group.push(item);
                groups[item.email] = group;
                return groups;
            }, {});

            const originals = [];
            const duplicates = [];

            const isInArray = (obj, array) => array.some((item) => JSON.stringify(item) === JSON.stringify(obj));
            const isMatchesObj = (a, b) => JSON.stringify(a) === JSON.stringify(b);
            Object.values(groupByEmail).forEach((group) => {
                if (group.length > 1) {
                    let original = handleFilter(group, 'mrs');
                    if (!original) {
                        original = handleFilter(group, 'mr');;
                    }
                    if (!original) {
                        const filteredData = group.find((item) => item.title && dobCheck(item));
                        if (!isEmpty(filteredData)) {
                            original = filteredData;
                        }
                    }
                    if (isEmpty(original)) {
                        const filteredData = group.find((item) => dobCheck(item));
                        if (!isEmpty(filteredData)) {
                            original = filteredData;
                        }
                    }
                    if (isArray(original)) {
                        originals.push(...original);
                        const duplicateItems = group.filter((item) => !isInArray(item, original));
                        duplicates.push(...duplicateItems);
                    } else {
                        const duplicateItems = group.filter((item) => !isMatchesObj(item, original));
                        originals.push(original);
                        duplicates.push(...duplicateItems);
                    }
                } else {
                    const item = group[0];
                    if (dobCheck(item)) {
                        originals.push(item);
                    } else {
                        duplicates.push(item);
                    }
                }
            });
            const filteredOriginals = originals?.filter((ele) => ele?.email) || [];
            const filteredDuplicates = uploadedFile.filter((item) => !isInArray(item, filteredOriginals)) || [];
            setOriginals(filteredOriginals?.map((ele, index) => ({ ...ele, id: index })));
            convertToCSV(filteredOriginals);
            setDuplicates(filteredDuplicates?.map((ele, index) => ({ ...ele, id: originals?.length + index })));
            setAll([...filteredOriginals, ...filteredDuplicates]?.map((ele, index) => ({ ...ele, id: index })));
        }
    }, [uploadedFile]);

    const convertToBase64 = (result, isOriginal) => {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = () => {
            if (isOriginal) {
                dispatch(setOriginalDataFile(reader.result));
                return;
            }
            dispatch(setImportFiles(reader.result));
        };
    };

    const handleFileUpload = (event) => {
        if (event.target.value) {
            setCsvHeaders([]);
            dispatch(setMappingValues({}));
            dispatch(setImportFiles(''));
            dispatch(setOriginalDataFile(''));
            setIsFileUploaded(true);
            setIsFileValue(event.target.files[0].name);
            dispatch(setFileName(event.target.files[0].name));
            convertToBase64(event.target.files[0]);
            Papa.parse(event.target.files[0], {
                complete(results) {
                    const filteredData = results.data?.filter((item) => !Object.values(item).every(value => value === '')) || [];
                    setUploadedFile(filteredData);
                    fileInputRef.current.value = null;
                },
                header: true,
            });
            handleHeaders(event.target.files[0]);
        }
    };

    const convertToCSV = (fileData) => {
        if (isEmpty(fileData)) {
            dispatch(setImportFiles(''));
            return;
        }
        const data = fileData.map((el) => {
            let payload = {};
            if (isArray(headers) && headers.length > 0) {
                payload = headers[0].reduce((total, current) => {
                    total = { ...total, [current]: el[current] };
                    return total;
                }, {});
            } else {
                payload = {
                    firstname: el.firstname || '',
                    lastname: el.lastname || '',
                    email: el?.email || '',
                    phone: el?.phone || '',
                    title: el?.title || '',
                    dob: el.dob || '',
                };
            }
            return payload;
        });
        const csvRows = [];
        const keys = Object.keys(data[0]);
        csvRows.push(keys.join(','));
        for (const row of data) {
            const values = keys.map((key) => `"${row[key]}"`);
            csvRows.push(values.join(','));
        }
        const newCsvData = csvRows.join('\n');

        const blob = new Blob([newCsvData], { type: 'text/csv;charset=utf-8;' });
        convertToBase64(blob, true);
    };

    return (
        <>
            <Box>
                <Card sx={importLeadCardStyle}>
                    <Box sx={{ width: '100%', textAlign: 'center', p: '0 24px' }}>
                        <Typography sx={{ fontSize: '18px', color: grey[600] }}>Brows your file here.</Typography>
                        <Typography sx={{ fontSize: '14px', color: common.slateBlue, m: '5px 0 13px' }}>— or —</Typography>
                        <Box sx={{ mb: 1 }}>
                            <Button component='label' variant='contained' sx={{ borderRadius: 0 }}>
                                Browse
                                <VisuallyHiddenInput type='file' onChange={handleFileUpload} accept='.csv' ref={fileInputRef} />
                            </Button>
                            <Tooltip title="Download sample csv.">
                                <IconButton href={sampleCsv} sx={{ ml: 1 }}>
                                    <Iconify
                                        sx={{ cursor: 'pointer' }}
                                        icon={'material-symbols:info-outline'}
                                        width={20}
                                        height={20}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {isFileValue && (
                            <Typography
                                sx={{ fontWeight: '700', display: 'block', color: grey[600] }}
                                component={isFileValue?.split('csv/')[1] ? 'a' : 'p'}
                                href={csvLink}
                                clickable
                            >
                                {isFileValue.split('csv/')[1] || isFileValue || ''}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={sampleBoxStyle}>
                        <Typography sx={{ fontSize: '14px', color: grey[600] }}>You can import .csv file.</Typography>
                    </Box>
                </Card>
                {isFileUploaded && uploadedFile && (
                    <Card sx={{ mt: 2, padding: '24px' }}>
                        <TransferList
                            originalProps={{ originals, setOriginals }}
                            duplicateProps={{ duplicates, setDuplicates }}
                            allProps={{ all, setAll }}
                            convertToCSV={convertToCSV}
                            headers={headers}
                        />
                    </Card>
                )}
                {isFileUploaded && (
                    <Box sx={{ mt: 3 }}>
                        <FileMappings csvHeaders={csvHeaders} setCsvHeaders={setCsvHeaders} />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default UploadFile;
