/* eslint-disable no-return-assign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell } from '@mui/material';
// components
import { LeadListPopup } from '../dialogPopUp';
// redux
import { setAudienceData } from '../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../redux/store';
import { clearPopupLead, getLead } from '../../redux/slices/analytics';
// Hooks/constants/utils
import { useBoolean } from '../../hooks';
import { LeadLabelsForReports } from '../../constants/TableDataConst';
import { TableOrder, TableOrderBy } from '../../utils/allTableTab';
// styles
import { analyticsTableCell, analyticsTableFirstCellStyle, analyticsTablePriceCell, analyticsTableRowStyle } from '../../styles/analytics';

const ReferralSummaryRow = ({ data }) => {
    const { referral } = useSelector((state) => state.schema);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { leads, dateRange } = useSelector((state) => state.analytics);
    const [rowData, setRowData] = useState({
        status: '',
        referral: ''
    });
    const open = useBoolean();

    const tableOrder = TableOrder();
    const tableOrderBy = TableOrderBy();

    const handleRowClick = (data, status) => {
        setRowData({
            status,
            referral: data[0],
        });
        open.onTrue();
        dispatch(
            getLead({
                id: practiceId,
                status,
                limit: 10,
                offset: 0,
                order: tableOrder,
                orderId: tableOrderBy,
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
                referral: data[0],
            })
        );
        const updatedCampaign = {
            recepient: {
                filters: {
                    created_at__range: [dateRange?.startDate, dateRange?.endDate],
                    lead_source__referral__in: [data[0]],
                },
            },
        };
        if (status && status !== 'All') {
            updatedCampaign.recepient.filters = { ...updatedCampaign.recepient.filters, lead_treatments__status__in: [status] };
        }
        dispatch(setAudienceData(updatedCampaign));
    };

    const handlePagination = (data) => {
        dispatch(
            getLead({
                id: practiceId,
                status: rowData.status,
                limit: data.limit,
                offset: data.offset,
                order: data.order,
                orderId: data.orderId,
                startDate: dateRange?.startDate,
                endDate: dateRange?.endDate,
                referral: rowData?.referral,
            })
        );
    };

    const handleClose = () => {
        open.onFalse();
        dispatch(clearPopupLead());
    };

    const getCountId = (status) => data[1]?.filter((el) => el?.lead_treatments__status === status)[0]?.count_id || 0;

    return (
        <TableRow hover sx={analyticsTableRowStyle}>
            <TableCell sx={analyticsTableFirstCellStyle}>
                {
                    referral?.length > 0 && referral?.filter((el) => el.value === data[0])[0].label
                }
            </TableCell>
            <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'All')}>
                {data[1]?.filter((el) => el.lead_treatments__status !== null)?.reduce((total, currentValue) => total += currentValue?.count_id, 0) || 0}
            </TableCell>
            <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'NEW')}>
                {getCountId('NEW')}
            </TableCell>
            <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'CONSULTATION_BOOKED')}>
                {getCountId('CONSULTATION_BOOKED')}
            </TableCell>
            <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'CONSULTATION_ATTENDED')}>
                {getCountId('CONSULTATION_ATTENDED')}
            </TableCell>
            <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'TREATMENT_STARTED')}>
                {getCountId('TREATMENT_STARTED')}
            </TableCell>
            <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'FAILED_TO_ATTEND_CONSULTATION')}>
                {getCountId('FAILED_TO_ATTEND_CONSULTATION')}
            </TableCell>
            <TableCell sx={analyticsTableCell} onClick={() => handleRowClick(data, 'CLOSED')}>
                {getCountId('CLOSED')}
            </TableCell>
            <TableCell sx={analyticsTablePriceCell}>
                £{data[1]?.reduce((total, currentValue) => total += currentValue?.sum_lead_treatments__custom_price, 0).toLocaleString() || 0}
            </TableCell>
            {open && <LeadListPopup
                open={open?.value}
                onClose={handleClose}
                type={'reportTableData'}
                updateLeadPage={(data) => handlePagination(data)}
                practiceId={practiceId}
                count={leads?.count}
                LeadLabelsForReports={LeadLabelsForReports}
                data={leads?.results}
                rowStatus={rowData?.status}
            />}
        </TableRow>
    );
};

ReferralSummaryRow.propTypes = {
    data: PropTypes.any,
};

export default ReferralSummaryRow;
