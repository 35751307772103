const TABS = [
    {
        value: 'all',
        label: 'All',
        count: 0,
    },
    {
        value: 'unread',
        label: 'Unread',
        count: 0,
    },
    {
        value: 'read',
        label: 'Read',
        count: 0,
    },
];

export { TABS };