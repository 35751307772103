import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { MuiColorInput } from 'mui-color-input';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Card, Stack, Box, Button, Typography, IconButton, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { Iconify, Image } from '../../../components';
// hook-form
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
// redux
import { dispatch } from '../../../redux/store';
import { changeUserDetails } from '../../../redux/slices/practiceUser';
import { updateError } from '../../../redux/slices/user';
// style
import { clearButton } from '../../../styles/EmailMessageComposeStyle';
import { mainBox, textBox, imageBox } from '../../../styles/brandingStyle';

// ----------------------------------------------------------------------

export default function PracticeBranding({ branding, practiceId }) {

    const [inputFiles, setInputFiles] = useState([]);

    const primary = useRef();
    const secondary = useRef();
    const emailLogo = useRef();
    const emailFooterLogo = useRef();
    const [colorError, setColorError] = useState('');
    const [color, setColor] = useState(branding?.brand_colour || '');

    const defaultValues = {
        textContent: branding?.banner_text || '',
        bannerEnabled: branding?.is_banner_enabled || false,
    };

    const methods = useForm({
        defaultValues
    });
    
    const handleChangeColor = (newValue) => {
        setColor(newValue);
        setColorError('');
    };

    useEffect(() =>{
        const newObj = [];
        if (branding !== null  && Object.values(branding)?.length) {
            if (branding?.primary_banner?.length) {
                newObj.push({ type: 'primary', file: branding?.primary_banner });
            }
            if (branding?.secondary_banner?.length) {
                newObj.push({ type: 'secondary', file: branding?.secondary_banner });
            }
            if (branding?.email_logo?.length) {
                newObj.push({ type: 'emailLogo', file: branding?.email_logo });
            }
            if (branding?.email_footer_banner?.length) {
                newObj.push({ type: 'emailFooter', file: branding?.email_footer_banner });
            }
            setInputFiles(newObj);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[branding]);

    const { handleSubmit } = methods;

    const filterImages = (type) => inputFiles.filter(el => el.type === type);

    const fetchData = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const onSubmit = async (data) => {
        try {
            const payload = {
                is_banner_enabled: data.bannerEnabled,
                primary_banner: (filterImages('primary')[0]?.file?.includes('https') ? await fetchData(filterImages('primary')[0]?.file) : filterImages('primary')[0]?.file) || '',
                secondary_banner: (filterImages('secondary')[0]?.file?.includes('https') ? await fetchData(filterImages('secondary')[0]?.file) : filterImages('secondary')[0]?.file) || '',
                email_logo: (filterImages('emailLogo')[0]?.file?.includes('https') ? await fetchData(filterImages('emailLogo')[0]?.file) : filterImages('emailLogo')[0]?.file) || '',
                email_footer_banner: (filterImages('emailFooter')[0]?.file?.includes('https') ? await fetchData(filterImages('emailFooter')[0]?.file) : filterImages('emailFooter')[0]?.file) || '',
            };
            if (color) {
                payload.brand_colour = color;
            }
            if (data?.textContent?.length > 0) {
                payload.banner_text = data.textContent;
            }
            if (Object.values(payload)?.length) {
                dispatch(changeUserDetails({branding: payload}, practiceId, 'practice-branding'));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };

    const handleClick = (e, data) => {
        data.current.click();
    };

    const handleInputFiles = async (e, data, remove) => {
        if(remove === 'remove') {
            const filteredData = inputFiles.filter(el => el.type !== data);
            setInputFiles(filteredData);
            e.current.value = '';
            return;
        }
        const files = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = () => {
            const filteredData = inputFiles.filter(el => el.type === data);
            if (filteredData?.length > 0) {
                const updatedArray = inputFiles.filter(obj => (obj.type !== data));
                setInputFiles([...updatedArray, { type: data, file: reader.result }]);
                return;
            }
            setInputFiles([...inputFiles, { type: data, file: reader.result }]);
        };
    };

    return (
        <Card sx={{ p: 3, mt: 4 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <Box sx={mainBox}>
                        <RHFCheckbox name={'bannerEnabled'} label="Is banner enabled" />
                    </Box>
                    <Divider sx={{ mb: 1 }}/>
                    <Box sx={mainBox}>
                        <Box sx={textBox}>
                            <Typography> Primary Banner: </Typography>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 200px)', '@media(max-width: 575px)': { width: '100%' } }}>
                            {(filterImages('primary')?.length) ?
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Image alt="primary" src={filterImages('primary')[0]?.file} sx={imageBox} />
                                    <IconButton
                                        sx={{ ...clearButton, ml: 1 }}
                                        color='error'
                                        onClick={() => handleInputFiles(primary, 'primary', 'remove')}
                                    >
                                        <Iconify icon="basil:cross-outline" height={18} width={18} />
                                    </IconButton>
                                </Box> : ''}
                            <Box sx={{ display: 'flex' , alignItems: 'center' }}>
                                {(filterImages('primary')?.length > 0) && <Typography sx={{ mr: 1 }}> Change : </Typography>}
                                <Button onClick={(e) => handleClick(e, primary)} variant='contained'>
                                Choose file
                                </Button>
                                <input
                                    type='file'
                                    ref={primary}
                                    accept="image/*"
                                    style={{display: 'none'}} 
                                    onChange={(e) => handleInputFiles(e, 'primary')}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 1 }}/>
                    <Box sx={mainBox}>
                        <Box sx={textBox}>
                            <Typography> Secondary Banner: </Typography>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 200px)', '@media(max-width: 575px)': { width: '100%' } }}>
                            {(filterImages('secondary')?.length) ?
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Image alt="secondary" src={filterImages('secondary')[0]?.file} sx={imageBox} />
                                    <IconButton
                                        sx={{ ...clearButton, ml: 1 }}
                                        color='error'
                                        onClick={() => handleInputFiles(secondary, 'secondary', 'remove')}
                                    >
                                        <Iconify icon="basil:cross-outline" height={18} width={18} />
                                    </IconButton>
                                </Box> : ''}
                            <Box sx={{ display: 'flex' , alignItems: 'center' }}>
                                {(filterImages('secondary')?.length > 0) && <Typography sx={{ mr: 1 }}> Change : </Typography>}
                                <Button onClick={(e) => handleClick(e, secondary)} variant='contained'>
                                Choose file
                                </Button>
                                <input
                                    type='file'
                                    ref={secondary}
                                    accept="image/*"
                                    style={{display: 'none'}}
                                    onChange={(e) => handleInputFiles(e, 'secondary')}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 2 }}/>
                    <Box sx={mainBox}>
                        <Box sx={textBox}>
                            <Typography> Banner text : </Typography>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 170px)', '@media(max-width: 575px)': { width: '100%' } }}>
                            <RHFTextField  name={'textContent'} multiline rows={3} label='Banner text' placeholder='Enter banner text....'  />
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 1 }}/>
                    <Box sx={mainBox}>
                        <Box sx={textBox}>
                            <Typography> Email logo: </Typography>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 170px)', '@media(max-width: 575px)': { width: '100%' } }}>
                            {(filterImages('emailLogo')?.length) ?
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Image alt="emailLogo" src={filterImages('emailLogo')[0]?.file} sx={imageBox} />
                                    <IconButton
                                        sx={{ ...clearButton, ml: 1 }}
                                        color='error'
                                        onClick={() => handleInputFiles(emailLogo, 'emailLogo', 'remove')}
                                    >
                                        <Iconify icon="basil:cross-outline" height={18} width={18} />
                                    </IconButton>
                                </Box> : ''}
                            <Box sx={{ display: 'flex' , alignItems: 'center' }}>
                                {(filterImages('emailLogo')?.length > 0) && <Typography sx={{ mr: 1 }}> Change : </Typography>}
                                <Button onClick={(e) => handleClick(e, emailLogo)} variant='contained'>
                                Choose file
                                </Button>
                                <input
                                    type='file'
                                    ref={emailLogo}
                                    accept="image/*"
                                    style={{display: 'none'}} 
                                    onChange={(e) => handleInputFiles(e, 'emailLogo')}
                                />
                            </Box>
                        </Box>

                    </Box>
                    <Divider sx={{ mb: 1 }}/>
                    <Box sx={mainBox}>
                        <Box sx={textBox}>
                            <Typography> Email footer banner: </Typography>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 170px)', '@media(max-width: 575px)': { width: '100%' } }}>
                            {(filterImages('emailFooter')?.length) ?
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Image alt="emailFooter" src={filterImages('emailFooter')[0]?.file} sx={imageBox} />
                                    <IconButton
                                        sx={{ ...clearButton, ml: 1 }}
                                        color='error'
                                        onClick={() => handleInputFiles(emailFooterLogo, 'emailFooter', 'remove')}
                                    >
                                        <Iconify icon="basil:cross-outline" height={18} width={18} />
                                    </IconButton>
                                </Box> : ''}
                            <Box sx={{ display: 'flex' , alignItems: 'center' }}>
                                {(filterImages('emailFooter')?.length > 0) && <Typography sx={{ mr: 1 }}> Change : </Typography>}
                                <Button onClick={(e) => handleClick(e, emailFooterLogo)} variant='contained'>
                                Choose file
                                </Button>
                                <input
                                    type='file'
                                    ref={emailFooterLogo}
                                    accept="image/*"
                                    style={{display: 'none'}} 
                                    onChange={(e) => handleInputFiles(e, 'emailFooter')}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ mb: 1 }}/>
                    <Box sx={mainBox}>
                        <Box sx={textBox}>
                            <Typography> Brand colour: </Typography>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 170px)', '@media(max-width: 575px)': { width: '100%' } }}>
                            <MuiColorInput 
                                format="hex" 
                                value={color} 
                                onChange={handleChangeColor} 
                                isAlphaHidden
                            />
                            <Typography variant="body2" color="error">{colorError}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Stack
                    sx={{
                        mt: 3,
                        columnGap: 2,
                        rowGap: 3,
                    }}
			    alignItems="flex-end"
		    >
                    <LoadingButton
                        type="submit"
                        variant="contained"
                    >
                    Save Changes
                    </LoadingButton>
		    </Stack>
            </FormProvider>
        </Card>
    );
}

PracticeBranding.propTypes = {
    branding: PropTypes.array,
    practiceId: PropTypes.any,
};