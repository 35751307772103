import { isEmpty } from 'lodash';
import { useSelector } from '../redux/store';

export function useGetTreatment() {
    const { treatmentsList: { results } = {} } = useSelector((state) => state.practiceTreatment ?? {});

    const getTreatmentId = (treatment) => {
        if (!isEmpty(results) && !isEmpty(treatment)) {
            return results?.filter((ele) => ele?.treatment_item?.name?.toLowerCase()?.includes(treatment))?.map((ele) => ele.id)?.toString();
        }
        return null;
    };

    return { getTreatmentId };
}
