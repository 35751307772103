import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Popover } from '@mui/material';
import { useSelector } from '../../../../redux/store';

const CustomCreateLabelPopover = ({ id, open, anchorEl, handleClose, handleScroll, handleChange, handleCreateLabelClick }) => {
    const { threadLabels, inboxLabels } = useSelector((state) => state.mail);

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            sx={{ '.MuiPopover-paper': { width: '260px' } }}
        >
            <Box sx={{ p: '16px 0 24px 16px' }}>
                <FormGroup onScroll={handleScroll} sx={{ maxHeight: '350px', overflow: 'auto', flexDirection: 'row' }}>
                    {inboxLabels && inboxLabels?.results?.map((item, index) =>
                        <Box sx={{ width: '100%', display: 'flex', p: '0 8px' }} key={index}>
                            <FormControlLabel
                                checked={threadLabels.results.some(threadItem => threadItem.label.id === item.id)}
                                control={<Checkbox onChange={(check) => handleChange(check, item)} />}
                                label={item?.name} />
                        </Box>)}
                </FormGroup>
                <Button aria-describedby={id} variant='contained' sx={{ mt: 3 }} onClick={handleCreateLabelClick}>Create label</Button>
            </Box>
        </Popover>
    );
};

CustomCreateLabelPopover.propTypes = {
    handleScroll: PropTypes.func,
    handleChange: PropTypes.func,
    handleCreateLabelClick: PropTypes.func,
    handleClose: PropTypes.func,
    id: PropTypes.number,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
};

export default CustomCreateLabelPopover;