/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */

import { grey, common } from 'src/theme/palette';

export const archiveButton = (value) => {
    return {
        marginTop: '0px',
        borderRadius: '8px',
        padding: '6px 16px',
        mr: '12px',
        pointerEvents: value ? 'none' : null,
        backgroundColor: value ? '#F4F6F8!important' : '#ffffff',
        border: value ? '#F4F6F8!important' : '1px solid rgba(145, 158, 171, 0.32)',
    };
};

export const overViewCard = {
    p: 3,
    display: 'flex',
    mb: 2,
    flexDirection: 'column'
};
export const flowButtonWrapper = {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    flexWrap: 'wrap'
};
export const flowButtonContentWrapper = (length) => ({
    p: 1,
    border: '1px dashed #919EAB99',
    borderRadius: 1,
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: length === 1 ? '100%' : 'calc(33.33% - 12px)',
    '@media (max-width: 1559px)': {
        minWidth: length === 1 ? '100%' : 'calc(50% - 12px)',
    },
});
export const treatmentTestStyle = {
    fontSize: 16,
    fontWeight: 600,
    color: 'common.grey',
    marginLeft: '8px'
};
export const statusStyle = (status) => ({
    fontSize: 14,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    borderRadius: 1,
    p: '4px 12px 4px 4px!important',
    width: 'auto',
    minWidth: 'auto',
    maxWidth: 'fit-content',
    background: status === 'TREATMENT_STARTED' ? '#54D62C29' : status === 'NEW' ? '#00B8D929' : status === 'FAILED_TO_ATTEND_CONSULTATION' ? '#FF484229' : status === 'CONSULTATION_BOOKED' ? '#8E33FF29' : status === 'CONSULTATION_ATTENDED' ? '#FFC10729' : '#919EAB29',
    color: status === 'TREATMENT_STARTED' ? '#229A16' : status === 'NEW' ? '#006C9C' : status === 'FAILED_TO_ATTEND_CONSULTATION' ? '#B71D18' : status === 'CONSULTATION_BOOKED' ? '#5119B7' : status === 'CONSULTATION_ATTENDED' ? '#B78103' : '#637381',
    border: 'none !important',
    textTransform: 'none !important',
    lineHeight: '18px',
    pointerEvents: 'none',
    whiteSpace: 'nowrap'
});

export const infoIconBox = {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(24, 144, 255, 0.16)',
    mr: 1.5
};

export const dynamicOuterBox = {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '.swiper-button-prev, .swiper-button-next': {
        width: '10px',
        color: '#212B36'
    },
    '.swiper-button-prev:after, .swiper-button-next:after': {
        fontSize: '20px',
        fontWeight: 900
    },
    '.swiper-button-prev': {
        left: 0
    },
    '.swiper-button-next': {
        right: 0
    }
};

export const dynamicButton = {
    marginTop: '0px',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    backgroundColor: '#3366FF !important',
    borderRadius: '8px',
    padding: '6px 16px',
    mr: '0',
};

export const similarLeadsButton = {
    marginTop: '0px',
    border: 'none',
    borderRadius: '8px',
    mr: '0',
    bgcolor: 'transparent !important',
    boxShadow: 'none !important',
    p: '6px 8px'
};

export const paperStyle = {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    padding: '0 0 0 16px',
    margin: '0',
    height: 'auto',
    width: 'calc(100% - 20px)',
    overflow: 'auto',
};

export const unassignedBoxStyle = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    mb: '0'
};

export const recordAttendanceStyle = {
    backgroundColor: `${common.blue} !important`,
    border: 'none !important',
    fontSize: '13px',
    color: `${common.white} !important`
};

export const statusBox = {
    fontWeight: '700',
    padding: '0px 8px',
    textAlign: 'center',
    borderRadius: '6px',
    fontSize: '12px',
    lineHeight: '22px',
    mb: '6px'
};

export const timeLabel = {
    fontWeight: '600',
    padding: '0 8px 0 0',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    mb: '0'
};

export const avatarBox = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media(max-width: 767px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
};

export const avatarInnerBox = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    mb: '0px',
    mt: '0px'
};

export const avatarTypoGraphy = {
    margin: '0px 5px 0px 1px',
    padding: '0px 7px',
    fontSize: '12px',
    cursor: 'pointer',
    whiteSpace: 'nowrap'
};

export const badgeStyle = {
    position: 'relative',
    '.MuiBadge-badge': {
        transform: 'scale(1) translate(0%, -60%)',
        backgroundColor: '#74CAFF'
    },
    cursor: 'pointer'
};

export const labelStyle = {
    backgroundColor: 'transparent',
    color: 'common.grey',
    mr: '10px',
    cursor: 'pointer'
};

export const plusIconBox = {
    border: ' 1px dashed rgba(145, 158, 171, 0.24)',
    borderRadius: '100px',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const unassignTypo = {
    margin: '0px 5px 0px 1px',
    padding: '0px 7px',
    fontSize: '12px',
    cursor: 'pointer',
};

export const timeLineInfo = {
    p: 0,
    borderWidth: 0,
    margin: '0',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const timeLineTypo = {
    color: 'grey.600',
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '8px'
};
export const timeLineTypoV2 = {
    color: grey[500],
    fontSize: '14px',
    lineHeight: '16.45px',
    marginBottom: '8px',
    fontWeight: 600
};
export const timeLineMessage = {
    marginBottom: '4px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16.45px',
};

export const menuListStyle = {
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '8px'
};

export const cancelBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    boxShadow: 'none',
    border: '1px solid rgba(145, 158, 171, 0.32)',
    minWidth: '100px',
};

export const yesBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    bgcolor: '#00AB55 !important',
    color: '#fff',
    minWidth: '100px',
    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
};

// Activities

export const activityCard = (data) => {
    return {
        boxShadow: data.notAllow ? 'none' : null,
        p: '24px 24px 16px',
        display: 'flex',
        mb: 3, width: '100%',
        maxWidth: (data.type === 'leadDetail' ? '100%' : '670px'),
        ml: 'auto', marginRight: 'auto'
    };
};
export const iconBox = (color) => ({
    width: '27px',
    height: '27px',
    minWidth: '20px',
    minHeight: '20px',
    display: 'flex',
    borderRadius: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    mr: 1.5
});

export const messageBox = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    alignItems: 'flex-start'
};

// icom
export const noteTooltipText = {
    display: 'inline-block',
    color: '#1877F2',
    textDecoration: 'underline',
    fontWeight: 400
};

export const noteIcon = {
    verticalAlign: 'top',
    mt: '2px'
};

export const takeDepositButton = {
    mt: '18px',
    width: '100%',
    bgcolor: 'common.darkGreenContrast',
    borderRadius: '8px',
    color: '#fff',
    boxShadow: '0px 8px 16px 0px rgba(0, 171, 85, 0.24)',
    '&:hover': {
        bgcolor: 'common.darkGreenContrast'
    }
};

export const overviewMainBoxStyle = {
    display: 'flex',
    gap: '24px',
    '@media(max-width: 767px)': {
        flexWrap: 'wrap',
        width: '100%',
        gap: 0
    }
};

export const overviewLeftLayoutBoxStyle = {
    width: '55%',
    '@media(max-width: 767px)': {
        width: '100%',
    }
};

export const overviewRightLayoutBoxStyle = {
    width: 'calc(100% - 55% - 24px)',
    '@media(max-width: 767px)': {
        width: '100%',
    }
};

export const leadAppointmentCardStyle = {
    p: '24px',
    mb: 3,
    bgcolor: '#C8FACD',
    boxShadow: 'none'
};

export const lockIconMainBoxStyle = {
    position: 'absolute',
    right: '24px',
    top: '24px'
};

export const lockIconInnerBoxStyle = {
    width: '54px',
    height: '54px',
    borderRadius: '100%',
    bgcolor: 'common.darkGreenContrast',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)'
};

export const priceValueTextStyle = {
    fontSize: '24px',
    color: '#005249',
    fontWeight: '700',
    margin: '8px 0',
    lineHeight: 1.2
};

export const similarLeadViewCard = {
    p: 3,
    display: 'flex',
    mb: 3,
    border: '1px dashed #919EAB99',
    background: '#FFE7D9',
    borderRadius: 2,
    boxShadow: 'none'
};

export const treatmentCountStyle = {
    background: '#919EAB29',
    padding: '2px 6px',
    borderRadius: '6px',
    color: 'common.grey',
    fontSize: '12px',
    fontWeight: 700,
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '8px',
};

export const overViewDynamicOuterBox = (finalTreatmentArray) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: finalTreatmentArray.length > 2 ? 0 : 2,
    '.swiper-button-prev, .swiper-button-next': {
        width: '10px',
        color: '#212B36'
    },
    '.swiper-button-prev:after, .swiper-button-next:after': {
        fontSize: '20px',
        fontWeight: 900
    },
    '.swiper-button-prev': {
        left: 0
    },
    '.swiper-button-next': {
        right: 0
    }
});

export const finalTreatmentBoxStyle = (finalTreatmentArray) => ({
    overflow: 'hidden',
    p: finalTreatmentArray.length > 3 ? '0 25px' : 0,
    position: 'relative',
    '@media(max-width: 1559px)': {
        p: finalTreatmentArray.length > 2 ? '0 25px' : 0,
    }
});

export const dotsIconBoxStyle = {
    display: 'flex',
    lineHeight: 0,
    ml: 'auto',
    cursor: 'pointer'
};