import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Label } from '../../../../../components';
import { getClinCheckStatus } from '../../../../../utils/clinCheckUtils';
import { clinCheckStatusStyle, tableCellInnerBoxStyle } from '../../../../../styles/NewLeadTableRowStyle';

const ClincheckStatusCell = ({ typed, handleClinCheckStatusShow }) => {
    const theme = useTheme();

    return {
        field: 'clin_check_status',
        headerName: 'ClinCheck Status',
        width: 150,
        minWidth: 140,
        editable: false,
        sortable: false,
        renderCell: (params) => (
            <Box
                onClick={(event) => handleClinCheckStatusShow(params.row, event)}
                sx={tableCellInnerBoxStyle}
            >
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    sx={clinCheckStatusStyle({ typed })}
                >
                    <Typography variant="05 Others/Label">
                        <span>{getClinCheckStatus(params?.row?.clinCheckData?.status)}</span>
                    </Typography>
                </Label>
            </Box>
        ),
    };
};

export default ClincheckStatusCell;