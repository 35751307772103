import { TAB_HEAD } from 'src/constants/LeadDetailConst';

export const getIcon = (status) => {
    switch (status) {
        case 'NEW':
            return 'mdi:question-mark-circle-outline';
        case 'CONSULTATION_BOOKED':
            return 'mdi:calendar-clock';
        case 'FAILED_TO_ATTEND_CONSULTATION':
            return 'bx:calendar-x';
        case 'CONSULTATION_ATTENDED':
            return 'mdi:calendar-check';
        case 'CLOSED':
            return 'material-symbols:check-circle-outline';
        case 'TREATMENT_STARTED':
            return 'codicon:debug-start';
        default:
            return 'fluent:cloud-sync-complete-24-filled';
    }
};

export const getStatus = (status) => {
    const statusItem = TAB_HEAD.find((el) => status === el.value);
    return statusItem ? statusItem.label : '';
};