import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Iconify } from 'src/components';
import { grey, primary } from 'src/theme/palette';
import { toggleButtonStyle } from 'src/styles/Common';

const RHFToggleGroup = ({ control, options, name, label }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const handleChangeToggleBtn = ({ value, field }) => {
        if (value === null) {
            field.onChange('');
            return;
        }
        field.onChange(value);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl>
                    <Typography variant='subtitle2' sx={{ m: '0 0 10px' }}>{label}</Typography>
                    <ToggleButtonGroup
                        color='primary'
                        exclusive
                        value={field.value}
                        onChange={(e, value) => handleChangeToggleBtn({ value, field })}
                        aria-label='Platform'
                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                        error={error && !field.value?.length}
                    >
                        {options?.map((option, index) => (
                            <ToggleButton
                                key={index}
                                value={option.value}
                                sx={toggleButtonStyle(isLight)}
                            >
                                <Iconify icon={field.value === option.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === option.value ? primary.main : grey[400] }} />{option.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    {error && !field.value?.length &&
                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>{error?.message}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};

RHFToggleGroup.propTypes = {
    control: PropTypes.object,
    options: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
};

export default RHFToggleGroup;