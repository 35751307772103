/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel } from '@mui/material';
import Iconify from '../Iconify';
import { fieldLabelStyle, mainLabelBoxStyle, labelBoxStyle, requiredTextStyle } from '../../styles/CallScripts';

const CustomTitleComponent = ({ data, defaultValues }) => (
    <Box sx={mainLabelBoxStyle}>
        <Box sx={labelBoxStyle}>
            <Iconify icon={'heroicons:chat-bubble-left-right-20-solid'} width={'24px'} height={'24px'} sx={{ mr: 1, color: 'common.lightSlateBlue' }} /> {data?.field_type?.field_type === 'label' ? "Ask" : "Ask"}
        </Box>
        <FormLabel sx={fieldLabelStyle}>
            {data?.field_type?.field_type === 'label' ? (
                <div dangerouslySetInnerHTML={{ __html: `${data.description}&nbsp;${data?.field_type?.attribute === 'lead_salutation' ? defaultValues.speaking_to : ''}` || '' }} style={{ display: 'flex', flexWrap: 'wrap' }} />
            ) : (data?.label)}{data?.is_required && <span style={requiredTextStyle}>(Required)</span>}
        </FormLabel>
    </Box>
);

export default CustomTitleComponent;

CustomTitleComponent.propTypes = {
    data: PropTypes.object,
    defaultValues: PropTypes.object,
};
