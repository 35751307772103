import { useState } from 'react';

// ----------------------------------------------------------------------

export default function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(null);

    const copy = async (text) => {
        if (!navigator?.clipboard) {
            return false;
        }

        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            return true;
        } catch (error) {
            setCopiedText(null);
            return false;
        }
    };

    return { copiedText, copy };
}
