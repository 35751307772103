import { callApi } from 'src/utils/apiCall';
import { dispatch } from '../store';
import {
    endLoading,
    getPracticeSequenceMessageList,
    getPracticeSequencesList,
    getTemplate,
    hasError,
    setLoadingForTemplate,
    startLoading,
    updateShowSubjectList,
    updateSubjectTemplateList,
    updateTemplateDetailsList,
    deleteTemplate as setDeleteTemplate,
    getUserTemplate,
    BlankSequence,
    setIsAutoSaving,
    getUpdateData,
    setIsAutoSaveStatus
} from '../slices/practiceMessage';
import { updateError } from '../slices/user';
import { getPracticeId } from '../slices/clincheck';

export const getFilter = (filter) => {
    switch (filter) {
        case 'all':
            return '';
        case 'unLayer':
            return '&use_unlayer=true';
        case 'basic':
            return '&use_unlayer=false';
        default:
            return '';
    }
};

export const handleLoading = (state) => {
    if (state === false) {
        setTimeout(() => {
            dispatch(setLoadingForTemplate(state));
        }, 500);
    } else {
        dispatch(setLoadingForTemplate(state));
    }
};

export function updateTemplate({ id, details, callback, isSaveAndClose }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            dispatch(setIsAutoSaving(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-templates/${id}/`, 'patch', details);
            if (response.status === 200) {
                dispatch(getUpdateData(response.data));
                if (!isSaveAndClose) {
                    dispatch(updateError({ success: true, message: 'Template updated successFully' }));
                }
                if (isSaveAndClose) {
                    callback({
                        message: 'Template updated successFully',
                        success: true
                    }, isSaveAndClose);
                }
                dispatch(setIsAutoSaveStatus(true));
                setTimeout(() => {
                    dispatch(setIsAutoSaveStatus(false));
                }, 2000);
            } else {
                dispatch(hasError(response.data) || 'Something went wrong');
                if (isSaveAndClose) {
                    callback({
                        message: response.data || 'Something went wrong',
                        success: false
                    }, isSaveAndClose);
                }
            }
            dispatch(setIsAutoSaving(false));
        } catch (error) {
            dispatch(setIsAutoSaving(false));
            dispatch(hasError(error));
        }
    };
}

export function messageScheduleAPI(practiceId, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/transactional-messages/${id}/schedule/`, 'post');
            if (response.status === 200) {
                return;
            }
            dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function sendTemplateEmail({ detail, handleClose, data, status }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/transactional-messages/`, 'post', detail);
            if (response.status === 201) {
                dispatch(messageScheduleAPI(practiceId, response.data.id));
                if (status === 'UnableToContact') {
                    handleClose(data, true);
                    return;
                }
                dispatch(updateError({ success: true, message: 'Email send successfully' }));
                handleClose(data, true);
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function sendEngagementEmail({ id, detail, callback, data }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-templates/${id}/send/`, 'post', detail);
            if (response.status === 200) {
                dispatch(updateError({ success: true, message: 'Email send successfully' }));
                callback(data);
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function setBlankTemplate(id) {
    return async () => {
        try {
            if (id) {
                dispatch(getUserTemplate({}));
            }
        } catch (e) {
            dispatch(hasError('Something went wrong'));
        }
    };
}

export function setBlankSequence() {
    return async () => {
        try {
            dispatch(BlankSequence([]));
        } catch (e) {
            dispatch(hasError('Something went wrong'));
        }
    };
}

export function deleteTemplate(practiceId, id, callback) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/engagement-templates/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(setDeleteTemplate());
                callback({
                    message: 'Template deleted successfully',
                    success: true
                }, true);
            } else {
                dispatch(hasError((response.data) || 'Something went wrong'));
                callback({
                    message: response.data.detail || 'Something went wrong',
                    success: false
                }, true);
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

export function deleteSequenceMessage(practiceId, sequenceId, id) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/sequences/${sequenceId}/messages/${id}/`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Sequence Message deleted successfully' }));
                dispatch(getPracticeSequenceMessageList(practiceId, sequenceId));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function deleteSequence(practiceId, sequenceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/sequences/${sequenceId}`, 'delete');
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Sequence Message deleted successfully' }));
                dispatch(getPracticeSequencesList(practiceId));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getSearchTemplateList(id, search, type) {
    return async () => {
        dispatch(updateShowSubjectList(true));
        try {
            dispatch(startLoading());
            const response = await callApi(
                `/api/v1/practices/${id}/engagement-templates/search/?query=${search}&use_unlayer=true${type === 'messageTemplates' ? '' : '&channel__in=EMAIL'
                }`
            );
            if (response.status === 200) {
                if (type === 'messageTemplates') {
                    dispatch(getTemplate(response.data));
                    handleLoading(false);
                    return;
                }
                dispatch(updateSubjectTemplateList(response.data.results));
            } else {
                dispatch(hasError(response.data.detail || 'Something went wrong'));
                handleLoading(false);
            }
            dispatch(endLoading());
        } catch (error) {
            dispatch(hasError(error));
            handleLoading(false);
        }
    };
}

export function get10TemplateList(id, removeUnLayer, unLayerFilter) {
    return async () => {
        dispatch(startLoading());
        dispatch(updateShowSubjectList(true));
        try {
            const query = removeUnLayer ? '&use_unlayer=false' : '';
            const unLayerFilterQuery = getFilter(unLayerFilter);
            const response = await callApi(
                `/api/v1/practices/${id}/engagement-templates/?ordering=-created_at${query}${unLayerFilterQuery}&channel__in=EMAIL`
            );
            if (response.status === 200) {
                dispatch(updateTemplateDetailsList(response.data.results));
            } else {
                dispatch(hasError(response.data.detail || 'Something went wrong'));
            }
            dispatch(endLoading());
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}
