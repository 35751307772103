import { useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Grid, Dialog, Container, Stack, Button, Box, Typography } from '@mui/material';
// components
import AddTreatment from '../AddTreatment';
import { Transition } from '.';
// styles
import {
    reminderDialogStyle,
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle
} from '../../styles/AddReminderPopup';

const AddTreatmentPopUp = ({ open, onClose, leadDetail, leadId, type, selectTreatmentId, selectTreatment, selectTreatmentPrice, updateLeadPage }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            onClose={onClose}
            sx={reminderDialogStyle}
        >
            <Stack sx={reminderStackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container
                        maxWidth={'xl'}
                        sx={{ padding: '0 !important' }}
                    >
                        <Typography>{leadDetail && leadDetail.lead_treatments.length > 0 ? 'Edit Treatment' : 'Add Treatment'}</Typography>
                        <AddTreatment
                            leadId={leadId}
                            ref={inputRef}
                            onClose={onClose}
                            handleNext={handleNext}
                            leadDetail={leadDetail}
                            type={type}
                            selectTreatmentId={selectTreatmentId}
                            selectTreatment={selectTreatment}
                            selectTreatmentPrice={selectTreatmentPrice}
                            updateLeadPage={updateLeadPage}
                        />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={reminderFooterStyle}>
                <Button
                    color='inherit'
                    sx={cancelButtonStyle}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    className='add-treatment-button'
                    onClick={handleNext}
                    sx={saveButtonStyle}
                >
                    Done
                </Button>
            </Box>
        </Dialog>
    );
};

export default AddTreatmentPopUp;

AddTreatmentPopUp.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    leadDetail: PropTypes.object,
    leadId: PropTypes.number,
    type: PropTypes.string,
    selectTreatment: PropTypes.string,
    selectTreatmentPrice: PropTypes.number,
    selectTreatmentId: PropTypes.number,
    updateLeadPage: PropTypes.func,
};