import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Iconify } from 'src/components';
import { plusButtonIconStyle, plusButtonStyle, plusButtonWrapperStyle } from 'src/styles/plusBtnStyle';

const AddButton = () => (
    <Box sx={plusButtonWrapperStyle}>
        <IconButton
            aria-label="add appointment"
            component="label"
            sx={plusButtonStyle}
        >
            <Iconify
                icon="material-symbols:add-circle"
                sx={plusButtonIconStyle}
                width={20}
                height={20}
            />
        </IconButton>
    </Box>
);

export default AddButton;