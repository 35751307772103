/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useForm, Controller } from 'react-hook-form';
import { Box, Card, Typography, MenuItem } from '@mui/material';
import Papa from 'papaparse';
import { isArray, isEmpty } from 'lodash';
// components
import { RHFSelect, FormProvider } from '../hook-form';
// redux
import { setHeaders, setMappingValues } from '../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../redux/store';
// styles
import { labelBoxStyle, typoWrapper } from '../../styles/ImportLeads';

const FileMappings = ({ csvHeaders, setCsvHeaders }) => {
    const { mappingValues, importFiles } = useSelector((state) => state.audienceManager);
    const fieldName = [
        { label: 'First name', value: 'lead_first_name' },
        { label: 'Last name', value: 'lead_last_name' },
        { label: 'Title', value: 'lead_salutation' },
        { label: 'Email', value: 'lead_email' },
        { label: 'Phone', value: 'lead_phone' },
    ];

    const defaultValues = {};

    const methods = useForm({
        defaultValues,
    });

    const { control, setValue, reset } = methods;

    useEffect(() => {
        if (importFiles) {
            Papa.parse(importFiles, {
                download: true,
                header: false,
                complete(results) {
                    setCsvHeaders(results.data);
                    dispatch(setHeaders(results.data));
                },
            });

        }
    }, [importFiles]);

    useEffect(() => {
        if (!isEmpty(mappingValues)) {
            Object.entries(mappingValues).forEach((ele) => {
                setValue(ele[0], ele[1]);
            });
        }
        if (isEmpty(mappingValues)) {
            reset();
        }
    }, [mappingValues]);

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Card sx={{ borderRadius: '16px' }}>
                <FormProvider methods={methods}>
                    <Box
                        sx={{
                            width: '100%',
                            backgroundImage: 'linear-gradient(rgb(244, 246, 248) 0%, rgb(244, 246, 248) 100%)',
                            display: 'flex',
                        }}
                    >
                        <Box sx={typoWrapper}>
                            <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: 600 }}>Fields in file</Typography>
                        </Box>
                        <Box sx={typoWrapper}>
                            <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: 600 }}>Fields in CRM</Typography>
                        </Box>
                    </Box>
                    {isArray(csvHeaders) && csvHeaders.length > 0 && isArray(csvHeaders[0]) &&
                        csvHeaders[0].length > 0 &&
                        csvHeaders[0].map((ele, index) => (
                            <Box key={index} sx={{ width: '100%', display: 'flex', borderBottom: '1px solid rgb(241, 243, 244)' }}>
                                <Box sx={labelBoxStyle}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{ele}</Typography>
                                </Box>
                                <Box sx={typoWrapper}>
                                    <Controller
                                        name={ele}
                                        control={control}
                                        render={({ field }) => (
                                            <RHFSelect
                                                {...field}
                                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                                sx={{ maxWidth: '300px' }}
                                                size="small"
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                    dispatch(setMappingValues({ ...mappingValues, [ele]: e.target.value }));
                                                }}
                                            >
                                                {fieldName?.map((option, index) => (
                                                    <MenuItem value={option.value} key={index}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </RHFSelect>
                                        )}
                                    />
                                </Box>
                            </Box>
                        ))}
                </FormProvider>
            </Card>
        </Box>
    );
};

export default FileMappings;

FileMappings.propTypes = {
    csvHeaders: PropTypes.array,
    setCsvHeaders: PropTypes.func,
};