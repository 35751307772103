/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// use @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, InputLabel, IconButton, FormControl, Select, MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
// use hooks
import { clearTablePreferences, getGlobalTablePreferences, resetTablePreferences } from 'src/redux/slices/tablePreferences';
import { resetWhatsAppNotifCount } from 'src/redux/slices/whatsapp';
import { useOffSetTop, useResponsive, useAuth } from '../../../hooks';
// utils
import { bgBlur } from '../../../utils/cssStyles';
import { localStorageRemoveItem, localStorageGetItem } from '../../../utils/storage-available';
// use config
import { HEADER, NAV } from '../../../config';
// components
import { SvgColor } from '../../../components/svg-color';
import { useSettingsContext } from '../../../components/settings/context';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import ContactsPopover from './ContactsPopover';
import SettingsPopover from './SettingsPopover';
import NotificationsPopover from './NotificationsPopover';
// redux
import { getPracticeTreatmentListData, updatePracticeId } from '../../../redux/slices/practiceTreatmentList';
import { setReminderData, setToDoData } from '../../../redux/slices/toDoNotifications';
import { addTreatmentsList, clearTreatmentsListForWidget, getChoicesList } from '../../../redux/slices/practiceTreatment';
import { dispatch, useSelector } from '../../../redux/store';
import {
    updateAttendTable,
    updateNewTable,
    updateBookedTable,
    updateArchiveTable,
    updateDashboardTable,
    updateTreatmentStartedTable,
    updateDashNewLeadsTable,
    clearTableData,
    updateCounter,
    updateFailedToAttendTable,
    updateClosedTable,
    clearTaskLead,
    clearSeparateTaskCounter,
    reset as leadReset,
    getMergeTags,
    updateNoTaskTable,
} from '../../../redux/slices/lead';
import { getPracticeData } from '../../../redux/slices/practice';
import { notificationData } from '../../../redux/slices/user';
import { clearData } from '../../../redux/slices/practiceUser';
import { audienceManagerReset } from '../../../redux/slices/audienceManager';
import { clearTreatmentCategoryReport, reset } from '../../../redux/slices/analytics';
import { reset as practiceIntegrationReset } from '../../../redux/slices/practiceIntegration';
import { reset as chatReset } from '../../../redux/slices/chat';
import { reset as callScriptReset } from '../../../redux/slices/callScript';
import { clearMediaImages } from '../../../redux/slices/campaign';
import { getSchemaList } from '../../../redux/slices/schema';
import { setEmailNotifications, setWpNotifications } from '../../../redux/slices/liveNotificationSlice';
import { setWpTemplateListGlobal } from '../../../redux/slices/whatsAppMessages';

// ----------------------------------------------------------------------
export default function DashboardHeader({ onOpenSidebar }) {
    const theme = useTheme();

    const { themeLayout } = useSettingsContext();

    const isNavHorizontal = themeLayout === 'horizontal';

    const isNavMini = themeLayout === 'mini';

    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !isNavHorizontal;

    const navigate = useNavigate();
    const isDesktop = useResponsive('up', 'lg');

    const { practiceId, practiceTreatment } = useSelector((state) => state.practiceTreatmentList);

    const [selectedId, setSelectedId] = useState(Number(practiceId));

    const { globalTablePreferences } = useSelector((state) => state.tablePreferences);
    const choicesData = useSelector((state) => state.practiceTreatment.choicesData.results);

    const { logout } = useAuth();

    useEffect(() => {
        setSelectedId(practiceId);

    }, [practiceId]);

    useEffect(() => {
        const data = {
            limit: 10,
            offset: 0
        };
        dispatch(getPracticeTreatmentListData(handleCallBack, data));
    }, []);

    useEffect(() => {
        const getReferralData = localStorageGetItem('ReferralData') || [];
        const getMergeTagsData = localStorageGetItem('MergeTagsData') || [];
        const getSourceTypeData = localStorageGetItem('SourceTypeData') || [];
        const getListData = localStorageGetItem('PreferredContactMethodData') || [];
        const getReminderMethodData = localStorageGetItem('ReminderMethodData') || [];
        const getReminderPriorityData = localStorageGetItem('ReminderPriorityData') || [];
        const getReasonsData = localStorageGetItem('ReasonsData') || [];
        const getContactMethodData = localStorageGetItem('ContactMethodData') || [];
        const getAppointmentTypeData = localStorageGetItem('AppointmentTypeData') || [];
        const getAppointmentMethodData = localStorageGetItem('AppointmentMethodData') || [];
        const getSalutationOptionsData = localStorageGetItem('SalutationOptions') || [];
        const getArchiveReasonsData = localStorageGetItem('ArchiveReasonsData') || [];
        const getFinanceTermOptionsData = localStorageGetItem('FinanceTermOptions') || [];
        const getreportTypeData = localStorageGetItem('ReportTypeData') || [];
        const getRefundPaymentReasonData = localStorageGetItem('RefundPaymentReasonData') || [];
        if (getListData?.length === 0 || getReferralData?.length === 0 || getSourceTypeData?.length === 0 || getReminderMethodData?.length === 0 || getReminderPriorityData?.length === 0 || getReasonsData?.length === 0 || getContactMethodData?.length === 0 || getAppointmentTypeData?.length === 0 || getAppointmentMethodData?.length === 0 || getSalutationOptionsData?.length === 0 || getArchiveReasonsData?.length === 0 || getFinanceTermOptionsData?.length === 0 || getreportTypeData?.length === 0 || getRefundPaymentReasonData?.length === 0) {
            dispatch(getSchemaList());
        }
        if (isEmpty(globalTablePreferences)) {
            dispatch(getGlobalTablePreferences());
        }
        if (isEmpty(choicesData?.results) && practiceId) {
            dispatch(getChoicesList(practiceId));
        }
        if (getMergeTagsData?.length === 0) {
            dispatch(getMergeTags());
        }
    }, [practiceId]);

    const handleCallBack = async () => {
        await logout();
    };

    const changeValue = (id) => {
        setSelectedId(id);
        dispatch(updatePracticeId(id));
    };

    const onChangeSelect = (event) => {
        navigate('/dashboard');
        changeValue(event.target.value);
        dispatch(reset());
        dispatch(resetTablePreferences());
        dispatch(clearTablePreferences());
        dispatch(clearTreatmentsListForWidget());
        dispatch(practiceIntegrationReset());
        dispatch(setToDoData([]));
        dispatch(setEmailNotifications([]));
        dispatch(setWpNotifications([]));
        dispatch(setReminderData([]));
        dispatch(chatReset());
        dispatch(callScriptReset());
        dispatch(audienceManagerReset());
        dispatch(addTreatmentsList([]));
        dispatch(clearTableData());
        dispatch(getPracticeData({}));
        dispatch(updateCounter([]));
        dispatch(clearTaskLead());
        dispatch(clearData());
        dispatch(clearSeparateTaskCounter());
        dispatch(clearTreatmentCategoryReport());
        dispatch(clearMediaImages());
        dispatch(setWpTemplateListGlobal(null));
        dispatch(notificationData([{
            results: [],
            count: 0
        }]));
        dispatch(updateArchiveTable({
            pageNumber: 0,
            perPage: 10,
        }));
        dispatch(updateDashNewLeadsTable({
            count: '0',
            labelCounter: 0,
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'NEW',
            tag: [],
            filterTags: [],
            referral: ''
        }));
        dispatch(updateBookedTable({
            count: 'All',
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'CONSULTATION_BOOKED',
            tag: [],
            filterTags: [],
            referral: '',
            bookedWith: 0
        }));
        dispatch(updateFailedToAttendTable({
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'FAILED_TO_ATTEND_CONSULTATION',
            tag: [],
            filterTags: [],
            referral: '',
        }));
        dispatch(updateClosedTable({
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'CLOSED',
            tag: [],
            filterTags: [],
            referral: ''
        }));
        dispatch(updateAttendTable({
            count: '0',
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'CONSULTATION_ATTENDED',
            tag: [],
            filterTags: [],
            referral: '',
            bookedWith: 0
        }));
        dispatch(updateNewTable({
            count: '0',
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'NEW',
            tag: [],
            filterTags: [],
            referral: ''
        }));
        dispatch(updateTreatmentStartedTable({
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'TREATMENT_STARTED',
            tag: [],
            filterTags: [],
            referral: '',
            category: '0'
        }));
        dispatch(updateDashboardTable({
            count: 'All',
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'TREATMENT_STARTED',
            tag: [],
            filterTags: [],
            referral: ''
        }));
        dispatch(updateNoTaskTable({
            count: 'All',
            pageNumber: 0,
            perPage: 10,
            order: 'desc',
            orderValue: 'created_at',
            search: '',
            treatment: [],
            date: [],
            status: 'All',
            tag: [],
            filterTags: [],
            referral: ''
        }));
        localStorageRemoveItem('dateFormat');
        localStorageRemoveItem('ReferralData');
        localStorageRemoveItem('SourceTypeData');
        localStorageRemoveItem('PreferredContactMethodData');
        localStorageRemoveItem('ReminderMethodData');
        localStorageRemoveItem('ReminderPriorityData');
        localStorageRemoveItem('AppointmentTypeData');
        localStorageRemoveItem('AppointmentMethodData');
        localStorageRemoveItem('FinanceTermOptions');
        localStorageRemoveItem('ReportTypeData');
        localStorageRemoveItem('SalutationOptions');
        localStorageRemoveItem('ArchiveReasonsData');
        localStorageRemoveItem('RefundPaymentReasonData');
        localStorageRemoveItem('ContactMethodData');
        localStorageRemoveItem('ReasonsData');
        localStorageRemoveItem('MergeTagsData');
        localStorageRemoveItem('reminderData');
        localStorageRemoveItem('timeout_ids');
        dispatch(leadReset());
        dispatch(resetWhatsAppNotifCount());
    };

    useEffect(() => {
        setTimeout(() => {
            document.body.style.pointerEvents = 'inherit';
        }, 3000);
        document.body.style.pointerEvents = 'none';
    }, []);

    const renderContent = (
        <>

            {!isDesktop && (
                <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                    <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
                </IconButton>
            )}

            <FormControl
                sx={{
                    mt: 0.5,
                    mr: {
                        sm: 2,
                        xs: 0.5
                    },
                    width: 300
                }}
            >
                <InputLabel
                    sx={{
                        fontSize: {
                            sm: '16px',
                            xs: '14px'
                        }
                    }}
                    size="small"
                    id="select-practice-dropdown"
                >
                    Select Practice
                </InputLabel>
                <Select
                    inputProps={{ style: { width: 100 } }}
                    labelId="select-practice-dropdown"
                    id="demo-multiple-checkbox"
                    label="Select Practice"
                    size="small"
                    sx={{
                        fontSize: {
                            sm: '16px',
                            xs: '13px'
                        }
                    }}
                    onChange={(val) => onChangeSelect(val)}
                    defaultValue={`${selectedId}`}
                    value={`${selectedId}`}
                >
                    {practiceTreatment !== undefined && practiceTreatment.results.map((val) => (
                        <MenuItem
                            sx={{
                                width: 300,
                                fontSize: {
                                    sm: '16px',
                                    xs: '14px'
                                }
                            }}
                            key={val.id}
                            value={`${val.id}`}
                        >
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {val.name}
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Searchbar />

            <Stack
                flexGrow={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={{ xs: 0.5, sm: 1.5 }}
            >
                <NotificationsPopover />
                <ContactsPopover />
                <SettingsPopover />
                <AccountPopover />
            </Stack>
        </>
    );

    return (
        <AppBar
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(isDesktop && {
                    width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
                    height: HEADER.H_DASHBOARD_DESKTOP,
                    ...(isOffset && {
                        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: 'background.default',
                        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                        borderBottom: `dashed 1px ${theme.palette.divider}`,
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
                    }),
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

DashboardHeader.propTypes = {
    onOpenSidebar: PropTypes.func,
};