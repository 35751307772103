/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// libraries
import { Chip, Box, Paper, Divider } from '@mui/material';
// components
import { RHFAutocomplete, RHFCheckbox } from '../hook-form';
// store
import { useSelector } from '../../redux/store';
// style
import { treatmentChipTextStyle } from '../../styles/NewLead';

const PracticeDropdowns = ({ name, value, onChange, ...props }) => {
    const { practiceTreatment: { results }, isLoading } = useSelector((state) => state.practiceTreatmentList);
    const [selectAll, setSelectAll] = useState(false);
    const [practiceOptions, setPracticeOptions] = useState([]);

    useEffect(() => {
        if (value.length === results.length) {
            setSelectAll(true);
            return;
        }
        setSelectAll(false);
    }, [value, results]);

    useEffect(() => {
        if (results) {
            const data = results;
            const arr = data.filter((elem, index, self) => self.indexOf(elem) === index);
            setPracticeOptions(arr);
            setPracticeOptions(results);
        }
    }, [results]);

    const handleDelete = (delValue) => {
        const data = value?.filter((res) => res.id !== delValue);
        onChange('', data);
    };

    const handleToggleSelectAll = (e) => {
        e.preventDefault();
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            onChange('', results);
            return;
        }
        onChange('', []);
    };

    return (
        <RHFAutocomplete
            {...props}
            multiple
            name={name}
            value={value}
            limitTags={2}
            onChange={onChange}
            options={practiceOptions}
            disableCloseOnSelect
            noOptionsText={isLoading ? 'Loading...' : 'No options'}
            groupBy={(option) => option?.firstLetter}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option?.name}
            renderTags={(value) => (
                <Box >
                    {value.map((value, index) => (
                        <Chip
                            sx={treatmentChipTextStyle}
                            key={index}
                            label={value.name}
                            size='small'
                            color='info'
                            variant='soft'
                            onDelete={() => handleDelete(value.id)}
                        />
                    ))}
                </Box>
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.name} style={{ padding: '5px 9px', marginTop: '1px' }}>
                    <Box sx={{ padding: '0px 12px' }} >
                        <RHFCheckbox renderSelect checked={selected} label={option.name} name={option.name} />
                    </Box>
                </li>
            )}
            PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                    <Paper {...restPaperProps}>
                        <Box
                            onMouseDown={(e) => e.preventDefault()}
                            sx={{ padding: '5px 12px' }}
                        >
                            <RHFCheckbox
                                id='select-all-checkbox'
                                renderSelect
                                checked={selectAll}
                                name='Select All'
                                label='Select All'
                                onClick={handleToggleSelectAll}
                            />
                        </Box>
                        <Divider />
                        {children}
                    </Paper>
                );
            }}
        />
    );
};

PracticeDropdowns.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    control: PropTypes.any,
};

export default PracticeDropdowns;
