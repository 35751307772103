export const openHoursBoxStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
};
export const openHoursStackStyle = {
    display: 'grid',
    columnGap: 2,
    rowGap: 3,
    gridTemplateColumns: { md: 'repeat(1, 1fr)', xl: 'repeat(4, 1fr)' },
};