/* eslint-disable react/jsx-no-duplicate-props */
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
// @mui 
import { Card, Typography, Divider, Box, Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
// components
import { FormProvider, RHFRadioGroup } from '../../../../components/hook-form';
import { Iconify } from '../../../../components';
import { useSelector, dispatch } from '../../../../redux/store';
import { updateCompleteStatus, saveNewLeadDetail } from '../../../../redux/slices/lead';
import { getStripe } from '../../../../redux/slices/practiceIntegration';
import { PaymentOptionSchema } from '../../../../validations/validations';
// style
import {
    ErrorTextStyle,
    AmountInputStyle
} from '../../../../styles/PaymentRefund';

const PaymentOption = forwardRef(({ handleNext, type }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { newLeadDetail, newLeadDetail : { payment, personalDetail }, leadDetail } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { stripeConnected } = useSelector((state) => state.practiceIntegration);

    const location = window.location.origin;

    const [open, toggleOpen] = useState(false);
    const [decimalSeparator] = useState('.');
    const [thousandSeparator] = useState(',');
    const [inputType] = useState('text');
    const [link, setLink] = useState(false);
    const [error, setError] = useState(false);
    const [amount, setAmount] = useState(payment?.amount || 0.00);
    const [show, setShow] = useState(true);
    const [methodOption, setMethodOption] = useState([
        { label: 'Deposit', value: 'DEPOSIT' },
        { label: 'Consultation fee', value: 'CONSULTATION' },
        { label: 'Treatment', value: 'TREATMENT' },
    ]);

    const defaultValues = {
        treatmentFee: payment && payment.payment_type || ( type === 'lead' ? 'DEPOSIT' : 'No'),
        paymentLink : payment && payment.mode_of_payment || 'PHONE'
    };

    const methods = useForm({
        resolver: (show ? yupResolver(PaymentOptionSchema) : null),
        defaultValues,
    });

    const {
        handleSubmit,
        watch,
        setValue
    } = methods;
    const values = watch();

    useImperativeHandle(
        ref, () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
                // await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [amount]
    );

    useEffect(() => {
        if (defaultValues.treatmentFee === 'No') {
            setShow(false);
        }
    }, [defaultValues.treatmentFee]);

    useEffect(()=>{
        if(defaultValues.paymentLink === 'SEND'){
            setLink(true);
        }
    },[defaultValues.paymentLink]);

    useEffect(()=>{
        if(type !== 'lead'){
            methodOption.unshift({ label: 'No', value: 'No' });
        }else{
            methodOption.unshift();
        }
        setMethodOption(methodOption);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[type]);

    useEffect(() => {
        if (practiceId) {
            dispatch(getStripe(practiceId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceId]);

    useEffect(()=>{
        if(defaultValues.paymentLink === 'SEND'){
            setLink(true);
            return;
        }
        setLink(false);
    },[defaultValues.paymentLink]);

    const handlePayment = (e) =>{
        if(e.target.value !== undefined){
            if(e.target.value === 'No'){
                setShow(false);
            }else{
                setShow(true);
            }
        }
    };
    const handlePaymentLink = (e) => {
        if(e.target.value === 'SEND' && stripeConnected.length === 0){
            toggleOpen(true);
        }
        if(e.target.value === 'SEND'){
            setLink(true);
            return;
        }
        setLink(false);
    };

    const handleClose = () => {
        setValue('paymentLink', '');
        toggleOpen(false);
    };
    const handleNoSelected = () => {
        setValue('paymentLink', '');
        toggleOpen(false);
    };
    const goToPracticeIntegrationPage = () => {
        window.open(`${location}/dashboard/user/integration`, '_blank');
        setValue('paymentLink', '');
        toggleOpen(false);
    };
    const onSubmit = (data, status) => {
        if ((amount === 0 || amount === '0.00' || amount === undefined) && data.treatmentFee !== 'No') {
            setError(true);
            return;
        }
        const payment = {
            payment_type: data.treatmentFee,
            mode_of_payment: data.paymentLink,
            amount
        };
        dispatch(saveNewLeadDetail({payment}));
        if(status === 'finish'){
            dispatch(updateCompleteStatus(true));
            return;
        }
        handleNext(true);
    };

    const handleChange = (value) => {
        setAmount(value);
        if (value > 0) {
            setError(false);
            return;
        }
        setError(true);
    };

    const capitalize = s => s && `${s[0].toUpperCase()  }${s.slice(1).toLowerCase()} Amount`;
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ p: 3, boxShadow: 'none' }}>
                <Typography variant='h5' sx={{ marginBottom: '15px' }}>
						Payment options
                </Typography>

                <Divider sx={{ mb: '40px' }} />

                <Typography variant='subtitle1' sx={{ mb: '8px' }}>
					Take a deposit or consultation fee for {newLeadDetail?.lead_treatment?.treatment || leadDetail?.lead_treatments[0]?.treatment || ''}?
                </Typography>
                <RHFRadioGroup
                    name={'treatmentFee'}
                    sx={{ display: 'flex', flexDirection : 'column', gap: 1.5, ml: 0, mb: '24px' }}
                    onClick={handlePayment}
                    options={methodOption}
                />
                {show &&
				    <Box>
				        <Box sx={{ mb: '20px' }}>
				            <Typography variant='subtitle2' sx={{ mb: '8px' }}>
								Enter {capitalize(values.treatmentFee)}
				            </Typography>
				            <CurrencyInput
				                name="CurrencyAmount"
				                value={amount}
				                decimalSeparator={decimalSeparator}
				                thousandSeparator={thousandSeparator}
				                onValueChange={(value, name) => handleChange(value, name)}
				                className="currency-input"
				                decimalsLimit={2}
				                decimalScale={2}
				                inputType={inputType}
				                prefix={'£ '}
				                style={AmountInputStyle(error, 'paymentOption', isLight)}
				                step={1}
				            />
				            {error && <Typography variant='p' sx={ErrorTextStyle}>This field is required</Typography>}
                    	</Box>
				        <Typography variant='subtitle1' sx={{ mb: '8px', mt: '24px' }}>
							How would you like to take the payment?
				        </Typography>
				        <RHFRadioGroup
				            name={'paymentLink'}
				            sx={{ display: 'flex', flexDirection : 'column', gap: 1.5, ml: 0 }}
				            onClick={handlePaymentLink}
				            options={[
				                { label: 'Send a payment link', value: 'SEND' },
				                { label: 'Take over the phone', value: 'PHONE' },
				                { label: 'In practice', value: 'INPRACTICE' },
				                { label: 'Email', value: 'EMAIL' },
				            ]}
				        />
				    </Box>}
                {link &&
					<Box sx={{background:'#D0F2FF', color:'#04297A', display:'flex', alignItems:'center', mt: '20px', padding:'17px'}}>
					    <Iconify icon='material-symbols:info' width={40} height={40} />
					    <Typography variant='subtitle1' sx={{ mb: '0px', mt: '0px', ml:'12px', fontSize: '14px', fontWeight: '400' }}>
							A payment link to <span style={{ fontWeight:'700', textTransform: 'capitalize' }}>{personalDetail?.first_name || `${leadDetail && leadDetail.first_name || ''} ${leadDetail && leadDetail.last_name || ''}`}
					        </span> for the amount of <Iconify icon={'material-symbols:currency-pound'} width={16} height={16} sx={{ position: 'relative', top: '2px' }} /><span style={{ fontWeight:'700'}}>{amount || 0}
					        </span>  will be emailed to <span style={{fontWeight:'700'}}>{personalDetail?.lead_contact?.email || leadDetail?.lead_contact?.email}</span>
					    </Typography>
					</Box>}
            </Card>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ padding: '40px 40px 0px', textAlign: 'center' }}>Stripe connection is require to send online payment Link. Do you want to redirect on Practice Integration Page to connect Stripe account?</DialogTitle>
                <DialogActions sx={{ padding: '40px !important', justifyContent: 'center' }}>
                    <Button sx={{ margin : '0px 10px', padding: '5px 20px', fontSize : '14px', boxShadow: 'none', border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '8px', minWidth: '100px' }} onClick={handleNoSelected}>No</Button>
                    <Button type="submit" sx={{ margin : '0px 10px', padding: '5px 20px', fontSize : '14px', boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)', borderRadius: '8px', bgcolor: '#00AB55 !important', color: '#fff', minWidth: '100px' }} onClick={() => goToPracticeIntegrationPage()}>Yes</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
});

PaymentOption.propTypes = {
    handleNext: PropTypes.func,
    type: PropTypes.string,
};
export default PaymentOption;
