import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { noAppointmentImgStyle, noAppointmentTextStyle, noAppointmentTextWrapper, noAppointmentWrapper } from 'src/styles/UpcommingAppointments';

const NoAppointmentComponent = () => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    return <Box sx={noAppointmentWrapper({ isLight })}>
        <Box sx={noAppointmentTextWrapper}>
            <img src='/assets/no-data.png' alt='no-appointment' style={noAppointmentImgStyle} />
            <Typography sx={noAppointmentTextStyle(isLight)}> No appointments</Typography>
        </Box>
    </Box>;
};

export default NoAppointmentComponent;