import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import { followUpSlice, updateTreatmentDetail } from '../../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../../redux/store';
import { TableTreatment } from '../../../../utils/allTableTab';
import { tableCellInnerBoxStyle, apptMethodSelectStyle } from '../../../../styles/NewLeadTableRowStyle';

const DepositPendingField = ({ rowData, tableType, handleSetTableData }) => {
    const [value, setValue] = useState(rowData?.treatmentData?.[0]?.is_treatment_fee_pending ? 'No' : 'Yes');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { enqueueSnackbar } = useSnackbar();
    const treatmentValue = TableTreatment(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    const handleChange = (event) => {
        const newValue = event.target.value;
        const detail = {
            is_treatment_fee_pending: newValue !== 'Yes',
        };
        dispatch(
            updateTreatmentDetail(
                practiceId,
                rowData?.lead_id,
                rowData?.treatmentData?.[0]?.id,
                detail,
                handleSuccess,
                'deposit_cell'
            )
        );
        setValue(newValue);
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
        dispatch(followUpSlice(rowData?.lead_id, practiceId, 'LeadDetail', handleClose));
    };

    const handleClose = (data) => handleSetTableData(data);

    return (
        <Box sx={tableCellInnerBoxStyle}>
            <Select value={value} onChange={handleChange} IconComponent={() => null} sx={apptMethodSelectStyle}>
                <MenuItem value='Yes'>Yes</MenuItem>
                <MenuItem value='No'>No</MenuItem>
            </Select>
        </Box>
    );
};

DepositPendingField.propTypes = {
    rowData: PropTypes.array,
    tableType: PropTypes.string,
    handleSetTableData: PropTypes.func,
};

export default DepositPendingField;