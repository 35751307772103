import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { updateError } from './user';
import { dispatch } from '../store';
import { getPracticeId } from './audienceManager';

const initialState = {
    tablePreferenceLoading: false,
    tablePreferences: [],
    globalTablePreferences: []
};

const slice = createSlice({
    name: 'tablePreferences',
    initialState,
    reducers: {
        resetTablePreferences(state) {
            Object.assign(state, initialState);
        },
        clearTablePreferences(state) {
            state.tablePreferences = [];
        },
        setTablePreferenceLoading(state, action) {
            state.tablePreferenceLoading = action.payload;
        },
        setTablePreferences(state, action) {
            state.tablePreferences = action.payload;
        },
        setGlobalTablePreferences(state, action) {
            state.globalTablePreferences = action.payload;
        }
    }
});

export default slice.reducer;

export const { setTablePreferences, resetTablePreferences, clearTablePreferences, setTablePreferenceLoading, setGlobalTablePreferences } = slice.actions;

const handleStopLoading = () => {
    setTimeout(() => {
        dispatch(slice.actions.setTablePreferenceLoading(false));
    }, 500);
};

export function getTablePreferences(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/users/${data?.practiceUserId}/table-column-preferences/?fields={id,table_name,preferences}`);
            handleStopLoading();
            if (response.status === 200) {
                dispatch(slice.actions.setTablePreferences(response.data));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            handleStopLoading();
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getGlobalTablePreferences() {
    return async () => {
        try {
            const response = await callApi('/api/v1/utils/globaltablecolumnpreference/');
            handleStopLoading();
            if (response.status === 200) {
                dispatch(slice.actions.setGlobalTablePreferences(response.data));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            handleStopLoading();
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function handleTablePreferences(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/users/${data?.practiceUserId}/table-column-preferences/`, 'post', data.body);
            if (response.status === 201) {
                dispatch(getTablePreferences({ practiceUserId: data?.practiceUserId }));
                dispatch(updateError({ success: true, message: 'Column visibility stored successfully' }));
            } else {
                handleStopLoading();
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            handleStopLoading();
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function handleTablePreferencesEdit(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/users/${data?.practiceUserId}/table-column-preferences/${data?.tableId}`, 'patch', data.body);
            if (response.status === 200) {
                dispatch(getTablePreferences({ practiceUserId: data?.practiceUserId }));
                dispatch(updateError({ success: true, message: 'Table preference updated successfully' }));
            } else {
                handleStopLoading();
                dispatch(updateError({ success: false, message: JSON.stringify(response.data?.columns) || 'Something went wrong' }));
            }
        } catch (error) {
            handleStopLoading();
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

