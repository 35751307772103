/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useDebounce } from 'use-lodash-debounce';
import { Chip, Box, Typography } from '@mui/material';
import _ from 'lodash';
// components
import { RHFAutocomplete, RHFCheckbox } from '../hook-form';
import { useSelector } from '../../redux/store';
// utils
import groupedAssignees from '../../utils/groupedAssignee';
// style
import { treatmentChipTextStyle, treatmentChipBoxStyle } from '../../styles/NewLead';
import { AssignUserAvatar } from '../custom-avatar';



const MultiAssigneesDropdown = ({ type, name, value, onChange, ...props }) => {

    const [assigneeOptions, setAssigneeOptions] = useState([]);
    const [selectedAssignee, setSelectedAssignee] = useState([]);
    const [position, setPosition] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const inputValueDebounce = useDebounce(inputValue, 800);
    const listElem = useRef();
    const mounted = useRef();
    const { practiceUser } = useSelector((state) => state.practiceUser);

    const getUserList = () =>
        practiceUser?.results?.filter((el) => el.user && el.user.first_name && !el.user.is_superuser);
    const userList = _.orderBy(
        getUserList(),
        [(o) => (o.user?.first_name).toLowerCase(), (o) => (o.user?.last_name).toLowerCase()],
        ['asc', 'asc']
    );

    useEffect(() => {
        if (userList?.length) {
            setAssigneeOptions(groupedAssignees(userList));
        }
    }, [userList]);

    useEffect(() => {
        setSelectedAssignee(value);
    }, [value]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const handleDelete = (delValue) => {
        const data = selectedAssignee?.filter((res) => res.id !== delValue);
        setSelectedAssignee(data);
        onChange('', data);
    };

    useEffect(() => {
        if (inputValueDebounce) {
            const filteredValue = userList?.results?.filter((res) => res?.user?.first_name?.toLowerCase().includes(inputValueDebounce?.toLowerCase()) || res?.user?.last_name?.toLowerCase().includes(inputValueDebounce?.toLowerCase()));
            setAssigneeOptions(groupedAssignees(filteredValue));

        } else {
            setAssigneeOptions(groupedAssignees(userList.results));
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        setInputValue(value);
    };

    const handleOnChanged = (e, value) => {
        const data = []
        assigneeOptions.forEach(option => {
            const found = value.find(val => val?.id === option?.id)
            if (found) {
                data.push(option)
            }
        })
        setSelectedAssignee(data);
        onChange(e, value);

    };

    const handleScroll = (event) => {
        const listboxNode = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
        if (listboxNode) {
            setPosition(event.target.scrollTop + event.target.clientHeight);
        }
    };

    const labelDisplay = (item) => (
            <Box sx={{ display: 'flex', alignItems: 'center'}}>
                <AssignUserAvatar sx={{ width: '40px', height: "40px", bgcolor: 'primary.paperColor' }} src={item?.avatar} name={item?.name} role={item?.role} />
                <Typography variant="" ml={1}>
                    {item?.name}
                </Typography>                                     
            </Box>)


    return (
        <RHFAutocomplete
            {...props}
            multiple
            name={name}
            value={selectedAssignee}
            onChange={handleOnChanged}
            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
            options={assigneeOptions}
            noOptionsText={'No options'}
            groupBy={(option) => option?.role}
            isOptionEqualToValue={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option?.name}
            renderTags={(value) => (
                <Box sx={treatmentChipBoxStyle}>
                    {value?.map((item, index) => (
                        <Chip
                            sx={treatmentChipTextStyle(type)}
                            key={index}
                            label={item?.name}
                            size='small'
                            color='info'
                            variant='soft'
                            onDelete={() => handleDelete(item.id)}
                        />
                    ))}
                </Box>
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option.name} style={{ padding: '5px 9px' }}>
                    <Box sx={{ padding: '0px 12px' }} >
                        <RHFCheckbox renderSelect checked={selected} label={labelDisplay(option)} name={option.name} />
                    </Box>
                </li>
            )}
            ListboxProps={{
                ref: listElem,
                onScroll: handleScroll
            }}

        />
    );
};

MultiAssigneesDropdown.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    control: PropTypes.any,
};

export default MultiAssigneesDropdown;
