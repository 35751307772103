// @mui
import { alpha, styled } from '@mui/material/styles';
import {  ListItemIcon, ListSubheader, ListItemButton } from '@mui/material';
// config
import { ICON, NAV } from '../../../config';

// ----------------------------------------------------------------------

export const ListItemStyle =  styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'caption',
})(({ active, disabled, depth, caption, theme }) => {
    const isLight = theme.palette.mode === 'light';

    const subItem = depth !== 1;

    const activeStyle = {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        ...(!isLight && {
            color: theme.palette.primary.light,
        }),
    };

    const activeSubStyle = {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
    };

    return {
        position: 'relative',
        textTransform: 'capitalize',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1.5),
        marginBottom: theme.spacing(0.5),
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.borderRadius,
        height: NAV.H_DASHBOARD_ITEM,
        // Sub item
        ...(subItem && {
            height: NAV.H_DASHBOARD_ITEM_SUB,
            ...(depth > 2 && {
                paddingLeft: theme.spacing(depth),
            }),
            ...(caption && {
                height: NAV.H_DASHBOARD_ITEM,
            }),
        }),
        // Active item
        ...(active && {
            ...activeStyle,
            '&:hover': {
                ...activeStyle,
            },
        }),
        // Active sub item
        ...(subItem &&
      active && {
            ...activeSubStyle,
            '&:hover': {
                ...activeSubStyle,
            },
        }),
        // Disabled
        ...(disabled && {
            '&.Mui-disabled': {
                opacity: 0.64,
            },
        }),
    };
});

// ----------------------------------------------------------------------

export const ListItemIconStyle  = styled(ListItemIcon)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ICON.NAVBAR_ITEM,
    height: ICON.NAVBAR_ITEM,
});


// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled(ListSubheader)(({ theme }) => ({
    ...theme.typography.overline,
    fontSize: 11,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export const ListDotIcon = styled('span', {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ active, theme }) => ({
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.text.disabled,
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
    }),
    ...(active && {
        transform: 'scale(2)',
        backgroundColor: theme.palette.primary.main,
    }),
}));

//
export const createButton = {
    mb: 0,
    width: '100%',
    height: '40px',
    minWidth: '48px',
    marginBottom: '10px',
    minHeight: '40px',
    maxWidth: '200px',
    ml: 'auto',
    mr: 'auto',
    '.MuiButton-startIcon': {
        mr: '16px'
    },
    '.MuiButton-endIcon': {
        ml: '16px'
    }
};

export const badgeStyle = {
    borderRadius: '6px', 
    border: '1px solid #D93C36', 
    p: '1px 8px', 
    fontSize: '12px', 
    fontWeight: '700', 
    color: '#D93C36' 
};