import { useTheme } from '@mui/material';

export default function useWidgetColors() {
    const theme = useTheme();
    const DARK_BLUE = theme.palette.widget.darkBlue;
    const DARK_BLUE_BORDER = theme.palette.widget.darkBlueBorder;
    const WHITE = theme.palette.common.white;
    const BLACK = theme.palette.grey[800];

    return { DARK_BLUE, DARK_BLUE_BORDER, WHITE, BLACK };
}
