import PropTypes from 'prop-types';
// libraries
import { Box, Button, Container } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import EditAppointment from '../EditAppointment';
// style
import { leadFooterButtonsStyle, footerBoxStyle, btnStyle } from '../../styles/ScheduleReminder';

const EditAppointmentPopup = ({ inputRef, handleClose, appoinmentSelectData }) => {
    const { themeStretch } = useSettingsContext();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Box>
            <EditAppointment ref={inputRef} handleClose={handleClose} type={'RescheduleReminder'} appoinmentSelectData={appoinmentSelectData} />
            <Box sx={footerBoxStyle}>
                <Container
                    maxWidth={themeStretch ? false : 'xl'}
                    sx={leadFooterButtonsStyle}
                >
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={handleNext}
                        sx={btnStyle}
                    >
                        Finish
                    </Button>
                </Container>
            </Box>
        </Box>
    );
};

export default EditAppointmentPopup;

EditAppointmentPopup.propTypes = {
    inputRef: PropTypes.object,
    handleClose: PropTypes.func,
    appoinmentSelectData: PropTypes.object,
};
