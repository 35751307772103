import { useLocales as getLocales } from '../locales';

// ----------------------------------------------------------------------

/*
 * Locales code
 * https://gist.github.com/raushankrjha/d1c7e35cf87e69aa8b4208a8171a8416
 */

function getLocaleCode() {
    const {
        currentLang: {
            numberFormat: { code, currency },
        },
    } = getLocales();

    return {
        code: code ?? 'en-US',
        currency: currency ?? 'USD',
    };
}

// ----------------------------------------------------------------------

export function fNumber(inputValue) {
    const { code } = getLocaleCode();

    if (!inputValue) return '';

    const number = Number(inputValue);

    const fm = new Intl.NumberFormat(code, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(number);

    return fm;
}

// ----------------------------------------------------------------------

export function fData(inputValue) {
    if (!inputValue) return '';

    if (inputValue === 0) return '0 Bytes';

    const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

    const decimal = 2;

    const baseValue = 1024;

    const number = Number(inputValue);

    const index = Math.floor(Math.log(number) / Math.log(baseValue));

    const fm = `${parseFloat((number / baseValue ** index).toFixed(decimal))} ${units[index]}`;

    return fm;
}
