/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// libraries
import { uniq, unionBy } from 'lodash';
import { MenuItem } from '@mui/material';
// components
import { RHFSelect } from '../../hook-form';
// redux
import { saveNewCampaign, updateSelectedCampaign } from '../../../redux/slices/campaign';
import { dispatch, useSelector } from '../../../redux/store';
// utils
import groupedTreatments from '../../../utils/groupedTreatments';

const LeadCategoryDropdown = () => {
    const {
        treatmentsList: { results },
    } = useSelector((state) => state.practiceTreatment);
    const { newCampaign, selectedCampaign } = useSelector((state) => state.campaign);
    const [category, setCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        const category = results.find((el) => el.treatment_item?.treatment_category?.name === e.target.value)?.treatment_item?.treatment_category?.id || '';
        if (category === '') return;
        const value = { lead_treatments__treatment__treatment_item__treatment_category__in: [category] };
        if (Object.values(selectedCampaign).length > 0) {
            const updatedCampaign = { ...selectedCampaign, recepient: { ...selectedCampaign.recepient, filters: { ...selectedCampaign?.recepient?.filters, ...value } } };
            dispatch(updateSelectedCampaign(updatedCampaign));
            return;
        }
        dispatch(saveNewCampaign({ lead_treatments__treatment__treatment_item__treatment_category__in: value, category__in: [e.target.value] }));
    };

    useEffect(() => {
        if (selectedCampaign) {
            const selectedCategory = results.find((el) => selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in?.includes((el.treatment_item?.treatment_category?.id)))?.treatment_item?.treatment_category?.name || '';
            setSelectedCategory(selectedCategory);
        }
    }, [category, selectedCampaign]);

    useEffect(() => {
        if (results) {
            const data = unionBy(results, 'id');
            const categoryData = uniq(groupedTreatments(data).map((e) => e.category));
            setCategory([...categoryData]);
        }
    }, [results]);
    return (
        <RHFSelect
            name='Category'
            label='Select Category'
            InputLabelProps={{ shrink: true }}
            value={newCampaign.category__in || selectedCategory || ''}
            onChange={handleCategoryChange}
            sx={{ textTransform: 'capitalize', width: '100%' }}
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
        >
            {category.map((options, index) => (
                <MenuItem key={index} value={options}>
                    {options}
                </MenuItem>
            ))}
        </RHFSelect>
    );
};

export default LeadCategoryDropdown;
