import PropTypes from 'prop-types';
import { useState } from 'react';
import {
    Box,
    Typography,
    Tooltip,
    Zoom,
    ClickAwayListener,
    Popper,
    Grow,
    Paper,
} from '@mui/material';
import Iconify from './Iconify';
import { AssignUserAvatar } from './custom-avatar';
import { AssignTaskPopup } from './dialogPopUp';
import { useAssignTaskToggle } from '../hooks';
import { getAssignees } from '../utils/multiAsignees';
import { dispatch } from '../redux/store';
import { clearPracticeUserData } from '../redux/slices/practiceUser';
import {
    taskUserAvatar,
    openTask,
    plusIconBoxStyle,
    assignUserAvatarStyle,
    assignLengthStyle,
    assignPopperStyle,
    labelTypographyStyle
} from '../styles/leadTaskReminder';

const AssignedToLead = ({ label, assignToState, setAssignTo }) => {
    const [anchorElAssign, setAnchorElAssign] = useState(null);

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const assignTaskHandler = (event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const onCloseEvent = () => {
        onCloseAssignTask(); 
        setAnchorElAssign(null); 
        dispatch(clearPracticeUserData());
    };

    return (
        <Box>
            {label && <Typography sx={labelTypographyStyle}>{label}</Typography>}
            <Box sx={{ cursor: 'pointer' }}>
                {assignToState?.length > 0 ? (
                    <Box sx={taskUserAvatar}>
                        <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                            <Box sx={{ display: 'flex' }}>
                                {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                    <AssignUserAvatar
                                        key={index}
                                        sx={assignUserAvatarStyle}
                                        src={assignee.user?.avatar || ''}
                                        name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                        role={assignee.role}
                                        onClick={(e) => assignTaskHandler(e)}
                                    />
                                ))}
                                {assignToState?.length > 2 && (
                                    <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                        + {assignToState?.length - 2}
                                    </Box>
                                )}
                            </Box>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                        <Box sx={plusIconBoxStyle} onClick={(e) => assignTaskHandler(e)}>
                            <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                        </Box>
                    </Box>
                )}
            </Box>
            {openAssignTask && (
                <ClickAwayListener onClickAway={onClickAwayEvent}>
                    <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement='bottom' sx={assignPopperStyle}>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper sx={{ borderRadius: 2 }}>
                                    <AssignTaskPopup
                                        open={openAssignTask}
                                        onClose={() => onCloseEvent()}
                                        type={'AddReminder'}
                                        setAssignTo={setAssignTo}
                                        assignees={assignToState?.map((ele) => ele.id)}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
        </Box>
    );
};

AssignedToLead.propTypes = {
    label: PropTypes.string,
    assignToState: PropTypes.array,
    setAssignTo: PropTypes.any,
};

export default AssignedToLead;