import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Portal, Button, Backdrop, Typography, IconButton } from '@mui/material';
// hooks
import { useResponsive } from '../../../hooks';
import { Iconify } from '../../../components';
import EmailMessageCompose from './EmailMessageCompose';
import SMSMessageCompose from './SMSMessageCompose';
import WhatsappMessageCompose from './WhatsappMessageCompose';
// style
import { rootStyle, rootBoxStyle, btnBoxStyle } from '../../../styles/MessageComposeStyle';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    right: 0,
    zIndex: 998,
    minHeight: 'fit-content',
    outline: 'none',
    display: 'flex',
    bottom: { sm: '100px', xs: '0px' },
    position: 'fixed',
    overflow: 'hidden',
    maxWidth: '344px',
    width: '100%',
    flexDirection: 'column',
    margin: theme.spacing(3),
    boxShadow: theme.customShadows.z20,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    backgroundColor: theme.palette.background.paper,
}));


// ----------------------------------------------------------------------

export default function MessageCompose({ isOpenCompose, onCloseCompose }) {
    // eslint-disable-next-line no-unused-vars
    const [fullScreen, setFullScreen] = useState(false);
    const [openEmailCompose, setOpenEmailCompose] = useState(false);
    const [openSMSCompose, setOpenSMSCompose] = useState(false);
    const [openWhatsappCompose, setOpenWhatsappCompose] = useState(false);
    const isDesktop = useResponsive('up', 'sm');

    const handleClose = () => {
        onCloseCompose();
        setFullScreen(false);
    };

    if (!isOpenCompose) {
        return null;
    }

    return (
        <Portal>
            <Backdrop open={fullScreen || !isDesktop} sx={{ zIndex: 98 }} />
            <RootStyle sx={rootStyle(fullScreen)} >
                <Box sx={rootBoxStyle} >
                    <Typography variant="h6">Compose message type</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={handleClose}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Box>
                <Box sx={btnBoxStyle}>
                    <Button
                        variant="outlined"
                        sx={{ mb: 2 }}
                        onClick={() => setOpenEmailCompose(true)}
                    >
                        <Iconify icon={'eva:email-fill'} width={20} height={20} mr={1} />
						Email
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setOpenSMSCompose(true)}
                        sx={{ mb: 2 }}
                    >
                        <Iconify icon={'ic:baseline-sms'} width={20} height={20} mr={1} />
						SMS
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setOpenWhatsappCompose(true)}
                    >
                        <Iconify icon={'ri:whatsapp-fill'} width={20} height={20} mr={1} />
						Whatsapp
                    </Button>
                </Box>
            </RootStyle>
            <EmailMessageCompose openEmailCompose={openEmailCompose} onCloseEmailCompose={() => setOpenEmailCompose(false)} />
            <SMSMessageCompose openSMSCompose={openSMSCompose} onCloseSMSCompose={() => setOpenSMSCompose(false)} />
            <WhatsappMessageCompose openSMSCompose={openWhatsappCompose} onCloseSMSCompose={() => setOpenWhatsappCompose(false)} />
        </Portal>
    );
}

MessageCompose.propTypes = {
    isOpenCompose: PropTypes.bool,
    onCloseCompose: PropTypes.func,
};