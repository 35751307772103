import { createSlice } from '@reduxjs/toolkit';
import { callApi } from 'src/utils/apiCall';
import { dispatch } from '../store';
import { updateError } from './user';
import { getPracticeId } from './clincheck';

// ------------------------------

const initialState = {
    currentTreatmentDataGlobal: null,
    treatmentsList: []
};

const slice = createSlice({
    name: 'multiTreatment',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        setCurrentTreatmentDataGlobal(state, action) {
            state.currentTreatmentDataGlobal = action.payload;
        },
        setTreatmentListGlobal(state, action) {
            state.treatmentsList = action.payload.results;
        }
    }
});

export default slice.reducer;

export const { setCurrentTreatmentDataGlobal, reset } = slice.actions;

export function getLeadTreatmentsList(data) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${getPracticeId()}/leads/${data?.leadId}/treatments/`);
            if (response.status === 200) {
                dispatch(slice.actions.setTreatmentListGlobal(response.data));
            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}