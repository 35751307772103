import React from 'react';
import EmailField from '../EmailField';

const EmailCell = () => ({
    field: 'email',
    headerName: 'Email',
    width: 230,
    minWidth: 100,
    editable: true,
    sortable: false,
    renderCell: (params) => <EmailField rowData={params?.row} />,
});

export default EmailCell;
