import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoader: false,
};

const slice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader(state, actions) {
            state.isLoader = actions.payload;
        },
    }
});

export default slice.reducer;

export const { setLoader } = slice.actions;

