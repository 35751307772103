import PropTypes from 'prop-types';
// libraries
import { Box, Button, Container } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import AttachmentAppointment from '../AttachmentAppointment';
// style
import { leadFooterButtonsStyle, footerBoxStyle, btnStyle } from '../../styles/ScheduleReminder';

const AttachmentsAppointmentPopup = ({ inputRef, handleClose, treatmentData, appoinmentID, appoinmentSelectData, pastAppointment }) => {
    const settings = useSettingsContext();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Box>
            <AttachmentAppointment ref={inputRef} handleClose={handleClose} type={'RescheduleReminder'} treatmentData={treatmentData} appoinmentID={appoinmentID} appoinmentSelectData={appoinmentSelectData} pastAppointment={pastAppointment} />
            <Box sx={footerBoxStyle}>
                <Container
                    maxWidth={settings.themeStretch ? false : 'xl'}
                    sx={leadFooterButtonsStyle}
                >
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={handleNext}
                        sx={btnStyle}
                    >
                        Finish
                    </Button>
                </Container>
            </Box>
        </Box>
    );
};

export default AttachmentsAppointmentPopup;

AttachmentsAppointmentPopup.propTypes = {
    inputRef: PropTypes.any,
    handleClose: PropTypes.func,
    treatmentData: PropTypes.any,
    appoinmentID: PropTypes.number,
    appoinmentSelectData: PropTypes.any,
    pastAppointment: PropTypes.any,
};
