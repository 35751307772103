import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel } from '@mui/material';
import { descriptionLabelStyle, descriptionStyle } from 'src/styles/CallScripts';

const CustomScriptDescription = ({ data }) => {
    if (data.description === '') return null;
    return <Box sx={descriptionStyle}>
        <FormLabel sx={descriptionLabelStyle}>{data.description}</FormLabel>
    </Box>;
};

export default CustomScriptDescription;

CustomScriptDescription.propTypes = {
    data: PropTypes.any,
};
