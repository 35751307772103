import React from 'react';
import { Box } from '@mui/material';
import StartedDateField from '../StartedDateField';
import { dateWrapperStyle } from '../../../../../styles/NewLead';

const TransitionDateCell = ({
    typed,
    showTransitionDate,
    updateLeadPage,
    reloadCounter,
    getTransitionDate
}) => ({
    field: 'transition_date',
    headerName: <Box sx={dateWrapperStyle}>Started date</Box>,
    width: 160,
    minWidth: 160,
    sortable: false,
    renderCell: (params) => (
        <StartedDateField
            rowData={params.row}
            typed={typed}
            showTransitionDate={showTransitionDate}
            updateLeadPage={updateLeadPage}
            reloadCounter={reloadCounter}
            tableType={typed}
            getTransitionDate={getTransitionDate}
        />
    ),
});

export default TransitionDateCell;
