import { forwardRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    Grid,
    Dialog,
    Container,
    Slide,
    Stack,
    Box,
    Typography,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from '@mui/material';
// components
import { Iconify } from '..';
// style
import {
    sendCampaignDialogStyle,
    reminderStackStyle
} from '../../styles/AddReminderPopup';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AudienceReportPopup = ({ open, onClose }) => {
    const campaignReportData = [
        {
            icon: 'EMAIL',
            name: 'test',
            count: 1
        },
        {
            icon: 'SMS',
            name: 'test',
            count: 1
        }
    ];

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            onClose={onClose}
            sx={sendCampaignDialogStyle}
        >
            <Stack sx={{ ...reminderStackStyle, pb: 0 }}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 0 }}>
                            <Typography sx={{ fontSize: '18px', textAlign: 'center', fontWeight: '700' }}>Audience report</Typography>
                        </Box>
                        <Divider sx={{ p: '23px 0 0' }} />
                        <Box sx={{ pt: '24px' }}>
                            <Table sx={{ border: 'solid 1px rgba(145, 158, 171, 0.08)' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Channel</TableCell>
                                        <TableCell align="left">Campaign name</TableCell>
                                        <TableCell align="left">Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaignReportData && campaignReportData?.length > 0 && campaignReportData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell align="left">
                                                {row.icon === 'EMAIL' ? <Stack sx={{ flexDirection: 'row' }}> <Iconify icon={'eva:email-fill'} width={20} height={20} mr={1} /> {row.icon} </Stack> : <Stack sx={{ flexDirection: 'row' }}> <Iconify icon={'ic:baseline-sms'} width={20} height={20} mr={1} /> {row.icon} </Stack>}
                                            </TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.count}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
};

export default AudienceReportPopup;

AudienceReportPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.any,
};