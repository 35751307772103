import { grey } from 'src/theme/palette';

export const attendedTitleStyle = {
    pb: '15px',
    mb: '15px',
    width: '100%',
    textAlign: 'left',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)'
};
export const attendedCardStyle = {
    p: 0,
    boxShadow: 'none',
    marginTop: 5,
    pt: 2,
    borderRadius: 1
};
export const attachmentTitleStyle = active => ({
    marginBottom: '0',
    pb: active ? '15px' : 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderBottom: active ? 'solid 1px rgba(145, 158, 171, 0.24)' : null
});
export const attendedGridStyle = {
    width: '100%',
    ml: 'auto',
    mt: 5,
    mb: 5
};
export const attendedTabsTitle = {
    pb: '15px',
    mb: '20px',
    width: '100%',
    textAlign: 'left'
};
export const leadFooterButtonsStyle = {
    mt: 3,
    display: 'flex',
    justifyContent: 'center',
    pr: { xs: 4 },
    pl: { xs: 4 },
};
export const attendedFooterStyle = {
    width: '100%',
    pb: 5,
    position: 'fixed',
    left: 0,
    bottom: 0,
    bgcolor: 'background.paper',
    zIndex: 99
};
export const btnStyle = (type) => (
    type === 'createLead' || type === 'followup' ?
        {
            px: {
                xs: 3.5,
                sm: 8
            }
        } : {
            py: 0.75,
            px: {
                xs: 3.5,
                sm: 8
            },
        }
);
export const textField = {
    width: {
        xs: '100%',
        md: '100%'
    }
};
export const labelTextStyle = {
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: grey[500]
};
export const changeButtonStyle = {
    fontSize: '13px',
    p: '0',
    '&:hover': {
        bgcolor: 'transparent'
    }
};

export const cardWrapper = { mb: '24px', display: 'flex', width: '100%' };

export const typoWrapper = { width: '100%', mt: '8px' };
export const typoStyle = { fontSize: '14px', fontWeight: 600 };
export const normalTypoStyle = { fontSize: '14px', fontWeight: 400 };
export const widthPadding = { width: '50%', p: '0 40px 0 0' };