import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { capitalCase } from 'change-case';
import Label from '../Label';
import { useSelector } from '../../redux/store';
import { common, grey } from '../../theme/palette';
import { treatmentAccordionTab, treatmentAccordionTabContent } from '../../styles/LeadDetailView';

const TreatmentTabs = ({ data, currentTab, TABS, onChangeTabHandler, pastAppointment, appointment }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { depositPaymentDataGlobal } = useSelector((state) => state.deposit);
    const { taskLeadCounter } = useSelector((state) => state.lead);

    const labelCount = (data) => {
        if (data.value !== 'Summary') {
            return <>
                <Box sx={{ fontWeight: '600' }}>
                    {data.value !== 'Treatment plan' &&
                        <Label
                            color={'default'}
                            sx={{ m: '5px 8px', color: isLight ? grey[800] : grey[0], background: isLight ? grey[300] : common.lightBluishGrey }}>
                            {(data.value === 'Attended appointments' && pastAppointment.length || 0) ||
                                (data.value === 'Tasks' && taskLeadCounter?.filter(value => value.filter === '' ? value.count : 0)[0]?.count || 0) ||
                                (data.value === 'Appointments' && appointment.length || 0) ||
                                (data.value === 'Payment' && depositPaymentDataGlobal?.length || 0)
                                || 0}
                        </Label>}
                    {capitalCase(data.value)}
                </Box>
            </>;
        }
        return (capitalCase(data.value));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                allowScrollButtonsMobile
                variant='scrollable'
                scrollButtons='auto'
                value={currentTab}
                onChange={(event, newValue) => { onChangeTabHandler(event, newValue, data); }}
                TabIndicatorProps={{ style: { display: 'none' } }}
                sx={treatmentAccordionTab}
            >
                {TABS.map((tab) => (
                    <Tab
                        disableRipple
                        key={tab.value}
                        value={tab.value}
                        label={labelCount(tab)}
                        sx={treatmentAccordionTabContent(isLight)}
                    />
                ))}
            </Tabs>
            {TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </Box>
    );
};

TreatmentTabs.propTypes = {
    currentTab: PropTypes.string,
    onChangeTabHandler: PropTypes.func,
    data: PropTypes.object,
    pastAppointment: PropTypes.array,
    appointment: PropTypes.array,
    TABS: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            component: PropTypes.node,
        })
    ),
};

export default TreatmentTabs;