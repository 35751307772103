/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
    TableRow,
    TableCell,
    Typography,
    MenuItem
} from '@mui/material';
// use components
import { Label } from '../../../components';
import { TableMoreMenu } from '../../../components/table';
import { deleteScriptSubmission } from '../../../redux/slices/callScript';
import { followUpSlice } from '../../../redux/slices/lead';
import { dispatch, useSelector } from '../../../redux/store';

// ---------------------------------------------------------
export default function ScriptTableRow({ row, selected, onOpenDetailSection, onEditDetailSection }) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const deleteReminder = (formId, id) => {
        dispatch(deleteScriptSubmission(practiceId, formId, id, handleSuccess));
        setOpen(false);
    };

    const handleSuccess = (data) => {
        if (data?.success) {
            dispatch(followUpSlice(row.lead, practiceId, 'LeadDetail'));
        }
    };

    return (
        <TableRow hover selected={selected} sx={{ cursor: 'pointer', borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
            <TableCell sx={{ alignItems: 'center' }} onClick={() => onOpenDetailSection(row?.form?.id, row.id)}>
                <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}>
                    {row.created_at_human}
                </Label>
            </TableCell>

            <TableCell align="left" onClick={() => onOpenDetailSection(row?.form?.id, row.id)}>
                <Typography variant="body">
                    <span style={{ textTransform: 'capitalize' }}>{row?.form && row?.form?.name}</span>
                </Typography>
            </TableCell>

            <TableCell align='right' sx={{ p: '0 16px !important', maxWidth: '40px' }}>
                <TableMoreMenu
                    open={open}
                    sx={{ width: '180px' }}
                    onOpen={(e) => setOpen(e?.currentTarget)}
                    onClose={() => setOpen(false)}
                    actions={
                        <>
                            <MenuItem onClick={() => onEditDetailSection(row?.form?.id, row.id)}>
                                Edit
                            </MenuItem>
                            <MenuItem onClick={() => onOpenDetailSection(row?.form?.id, row.id)}>
                                View
                            </MenuItem>
                            <MenuItem onClick={() => deleteReminder(row?.form?.id, row?.id)}>
                                Delete
                            </MenuItem>
                        </>
                    }
                />
            </TableCell>
        </TableRow>
    );
};

ScriptTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onOpenDetailSection: PropTypes.any,
    onEditDetailSection: PropTypes.any,
};
