/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDebounce } from 'use-lodash-debounce';
// @mui
import {
    Box,
    MenuItem,
    Typography,
    TextField,
    InputAdornment,
    Autocomplete
} from '@mui/material';
import { isArray } from 'lodash';
// components
import { Iconify } from '../../../components';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { SearchNotFound } from '../../../components/search-not-found';
// redux
import { useSelector, dispatch } from '../../../redux/store';
import {
    searchLead,
    clearTaskLead,
    addTaskSearchLead
} from '../../../redux/slices/lead';
import { getSupplierDataOnScroll, setSupplier } from '../../../redux/slices/loan';
import { addFinancialTrackerData, updateFinancialTrackerData } from '../../../redux/slices/financialTracker';
// validation
import { financialSchema } from '../../../validations/commonValidations';
// style
import {
    leadAutoComplete,
    taskGroupStyle
} from '../../../styles/AddReminderStyle';

const AddFinancialTracker = forwardRef(({ selected, selectedFinance, onClose }, ref) => {

    const [searchQuery, setSearchQuery] = useState('');
    const [leadTreatments, setLeadTreatments] = useState([]);
    const [searchLeads, setSearchLeads] = useState(null);
    const [isCalled, setIsCalled] = useState(false);
    const [amount, setAmount] = useState('');

    const { taskSearchLead, isLoading } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { financeSupplier } = useSelector((state) => state.loan);
    const { financeTermOptions } = useSelector((state) => state.schema);

    const debouncedValue = useDebounce(searchQuery, 1000);

    const defaultValues = {
        leadName: selected?.firstName && `${selected?.firstName} ${selected?.lastName}` || '',
        relation: '',
        deposit: selectedFinance?.depositAmount || '',
        term: selectedFinance?.term || '',
        loadSupplier: selectedFinance?.loanSupplier?.id || '',
    };

    const methods = useForm({
        resolver: yupResolver(financialSchema),
        mode: 'onChange',
        defaultValues
    });

    const {
        handleSubmit,
        setValue,
        clearErrors,
        control,
        reset,
    } = methods;

    useEffect(() => {
        if (selected !== null) {
            setSearchQuery(`${selected.first_name} ${selected.last_name}`);
            if (isArray(selected?.lead_treatments) && selected?.lead_treatments.length > 0) {
                setLeadTreatments(selected?.lead_treatments?.map((el) => ({ treatment: el.treatment, id: el.id, price: el?.price })));
                const selectedTreatment = selected?.lead_treatments?.find((ele) => ele.treatment === selectedFinance.treatmentName);
                setValue('relation', selectedTreatment?.id);
                setAmount(selectedTreatment?.price);
            }
            setValue('leadName', `${selected?.firstName} ${selected?.lastName}`);
        }
    }, [selected]);

    useEffect(() => {
        if (searchQuery.length > 0) {
            let search = searchQuery;
            if (Number(searchQuery) && !searchQuery.includes('+')) {
                search = `+44${searchQuery}`;
            }
            setIsCalled(true);
            dispatch(searchLead(encodeURIComponent(search), practiceId, 'FinancialTracker'));
        }
    }, [debouncedValue]);

    useEffect(() => {
        if (practiceId) {
            dispatch(setSupplier([]));
            dispatch(getSupplierDataOnScroll(practiceId, 10, 0));
        }
    }, [practiceId]);

    useEffect(() => {
        if (isCalled && taskSearchLead) {
            setSearchLeads(taskSearchLead);
            setIsCalled(false);
        }
    }, [taskSearchLead]);

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        [selected]
    );

    const handleClose = () => {
        onClose();
        reset();
        setSearchQuery('');
        setValue('dateTime', '');
        dispatch(addTaskSearchLead([]));
        setSearchLeads(null);
        setIsCalled(false);
        setAmount('');
    };

    const onSubmit = (data) => {
        const payload = {
            treatment: data.relation,
            deposit_amount: data.deposit,
            loan_supplier: data.loadSupplier,
            term: data.term,
        };
        if (selected !== null) {
            dispatch(updateFinancialTrackerData(practiceId, selectedFinance.id, payload, handleClose));
            return;
        }
        dispatch(addFinancialTrackerData(practiceId, payload, handleClose));
    };

    const searchLeadHandler = (value, reason) => {
        if (reason === 'input') {
            setSearchQuery(value);
        }
        if (value.length === 0) {
            dispatch(clearTaskLead());
        }
    };


    const noOptionsHandler = () => {
        if (searchQuery === '' || searchLeads && searchLeads?.length === 0) {
            return <SearchNotFound searchQuery={searchQuery} />;
        }
        return '';
    };

    const handleSearchLead = (value) => {
        if (value) {
            setValue('leadName', `${value?.first_name} ${value?.last_name}`);
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            setValue('lead_id', value?.id);
        }
        clearErrors('leadName');
        const leadArr = [];
        value?.lead_treatments?.map((el) => leadArr.push({ treatment: el?.treatment, id: el?.id, price: el?.price }));
        if (leadArr?.length > 0) {
            setLeadTreatments(leadArr);
        }
    };

    const handleTreatments = (e) => {
        setValue('relation', e?.target?.value);
        clearErrors('relation');
        const tempValue = leadTreatments?.filter((el) => el?.id === e?.target?.value)?.[0]?.price;
        setAmount(tempValue);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%', pt: '0', textAlign: 'left' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h5' sx={{ marginBottom: '24px', fontSize: '18px !important' }}>
                        {selected !== null ? 'Edit loan Details ' : 'Add new loan'}
                    </Typography>
                </Box>
                <Controller
                    name={'leadName'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                            {...field}
                            autoFocus
                            name={'leadName'}
                            inputValue={searchQuery}
                            value={searchQuery}
                            clearIcon={false}
                            options={searchLeads && searchLeads?.length > 0 ? searchLeads : []}
                            filterOptions={(option) => option}
                            loading={isCalled && isLoading}
                            sx={leadAutoComplete}
                            onInputChange={(event, value, reason) => searchLeadHandler(value, reason)}
                            noOptionsText={noOptionsHandler()}
                            onChange={(e, value) => handleSearchLead(value)}
                            isOptionEqualToValue={(option, value) => option?.first_name === value}
                            getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                            renderOption={(props, option) => {
                                const { first_name: firstName, last_name: lastName, lead_contact: leadContact } = option;
                                const { email, phone } = leadContact || {};
                                return (
                                    <Box component='li' {...props} key={option.id} >
                                        {firstName || ''} {lastName || ''} | {email || ''} | {phone || ''}
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!error}
                                    helperText={(searchQuery?.length === 0 && error) && 'This field is required'}
                                    placeholder='Lead'
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Iconify icon='uil:search' />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                    )}
                />
                <RHFSelect
                    name='relation'
                    label='In relation to treatment'
                    sx={{ marginBottom: '24px' }}
                    onChange={(e, value) => handleTreatments(e, value)}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                    {leadTreatments.map((options, index) => (
                        <MenuItem
                            key={index}
                            value={options?.id}
                            sx={taskGroupStyle}
                        >
                            {options?.treatment}
                        </MenuItem>
                    ))}
                </RHFSelect>

                <Box sx={{ display: 'block', mb: 3 }}>
                    <TextField sx={{ width: '100%' }} disabled value={amount} label='Treatment Price' />
                </Box>

                <Box sx={{ display: 'block', mb: 3 }}>
                    <RHFTextField sx={{ width: '100%' }} name='deposit' label='Deposit amount (Required)' />
                </Box>

                <RHFSelect
                    name='term'
                    label='Term (Required)'
                    sx={{ marginBottom: '24px' }}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                    {financeTermOptions.map((options, index) => (
                        <MenuItem
                            key={index}
                            value={options?.value}
                            sx={taskGroupStyle}
                        >
                            {options?.label}
                        </MenuItem>
                    ))}
                </RHFSelect>

                <RHFSelect
                    name='loadSupplier'
                    label='Loan supplier (Required)'
                    sx={{ marginBottom: '0' }}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                    {financeSupplier?.results?.filter((option) => option?.is_enabled)?.map((options, index) => (
                        <MenuItem
                            key={index}
                            value={options?.id}
                            sx={taskGroupStyle}
                        >
                            {options?.name}
                        </MenuItem>
                    ))}
                </RHFSelect>

            </Box>
        </FormProvider>
    );
});

AddFinancialTracker.propTypes = {
    selected: PropTypes.object,
    onClose: PropTypes.func,
    selectedFinance: PropTypes.object,
};

export default AddFinancialTracker;
