import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Link } from '@mui/material';
// config
import { HEADER } from '../../config';
// utils
import { bgBlur } from '../../utils/cssStyles';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import { Logo } from '../../components';

// ----------------------------------------------------------------------

export default function Header({ isOffset }) {
    const theme = useTheme();

    return (
        <AppBar color="transparent" sx={{ boxShadow: 0 }}>
            <Toolbar
                sx={{
                    justifyContent: 'space-between',
                    height: {
                        xs: HEADER.MOBILE_HEIGHT,
                        md: HEADER.MAIN_DESKTOP_HEIGHT,
                    },
                    transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(isOffset && {
                        ...bgBlur({ color: theme.palette.background.default }),
                        height: {
                            md: HEADER.MAIN_DESKTOP_HEIGHT - 16,
                        },
                    }),
                }}
            >
                <Logo />

                <Link component={RouterLink} to={PATH_PAGE.faqs} variant="subtitle2" color="inherit">
          Need Help?
                </Link>
            </Toolbar>

            {isOffset && <Shadow />}
        </AppBar>
    );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }) {
    return (
        <Box
            sx={{
                left: 0,
                right: 0,
                bottom: 0,
                height: 24,
                zIndex: -1,
                m: 'auto',
                borderRadius: '50%',
                position: 'absolute',
                width: 'calc(100% - 48px)',
                boxShadow: (theme) => theme.customShadows.z8,
                ...sx,
            }}
            {...other}
        />
    );
}

Header.propTypes = {
    isOffset: PropTypes.bool,
};

Shadow.propTypes = {
    sx: PropTypes.object,
};