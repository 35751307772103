
export const checkoutTableLabels = [
    { id: 'created_at', label: 'Date', align: 'left' },
    { id: 'payment_status', label: 'Payment status', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'refund', label: '', align: 'left' },
];

export const paymentTableLabels = [
    { id: 'created_at', label: 'Date', align: 'left' },
    { id: 'payment_type', label: 'Payment Type', align: 'left' },
    { id: 'mode_of_payment', label: 'Mode of Payment', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'left' },
];

export const refundTableLabel = [
    { id: 'created_at', label: 'Date', align: 'left' },
    { id: 'reason', label: 'Reason', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'left' },
    { id: 'description', label: 'Description', align: 'left' },
    { id: '', label: '', align: 'left' },
];