import { useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Grid, Dialog, Container, Stack, Button, Box } from '@mui/material';
// components
import SwitchUserlogin from '../../layouts/dashboard/header/SwitchUserlogin';
import { Transition } from '.';
// style
import { common } from '../../theme/palette';
import { dialogStyle, stackStyle, btnBoxStyle } from '../../styles/SwitchUserPopupStyle';

const SwitchUserPopup = ({ open, onClose, Users, type }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={dialogStyle}
        >
            <Stack sx={stackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container
                        maxWidth={'xl'}
                        sx={{ padding: '0 !important' }}
                    >
                        <SwitchUserlogin Users={Users} ref={inputRef} handleNext={onClose} type={type} />
                    </Container>
                </Grid>
            </Stack>
            <Box sx={btnBoxStyle}>
                <Button
                    color='inherit'
                    sx={{ margin: '0px 15px', fontSize: '14px', boxShadow: 'none' }}
                    onClick={() => onClose(true)}
                >
                    Cancel
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleNext}
                    sx={{ padding: '6px 28px', fontSize: '14px', boxShadow: common.jade }}
                >
                    {type === 'LeadDetail' ? 'Add note' : 'Submit'}
                </Button>
            </Box>
        </Dialog>
    );
};

export default SwitchUserPopup;

SwitchUserPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    Users: PropTypes.object,
    type: PropTypes.string,
};