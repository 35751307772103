import _mock from './_mock';

// ----------------------------------------------------------------------

export const _userFollowers = [...Array(18)].map((_, index) => ({
    id: _mock.id(index),
    avatarUrl: _mock.image.avatar(index),
    name: _mock.name.fullName(index),
    country: _mock.address.country(index),
    isFollowed: _mock.boolean(index),
}));

