import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Grid, Dialog, Container, Stack, Button, Box, styled } from '@mui/material';
import { useSelector } from 'react-redux';
// components
import { Iconify } from '..';
import { NormalLoading } from '../loading';
import { Transition } from '.';
// style
import {
    sendCampaignDialogStyle,
    reminderStackStyle,
    sendCampaignFooterStyle,
    cancelButtonStyle,
    sendButtonStyle
} from '../../styles/AddReminderPopup';

const CustomTypography = styled('p')({ fontSize: '18px', textAlign: 'center', fontWeight: '700' });

const ScheduleConfirmCampaignPopup = ({ open, onClose, handleSend, draftEvent, editValue, handlePreviewLeads, isEditDuration }) => {
    const [leadCount, setLeadCount] = useState(0);
    const { isLoadingFinish, campaignLeadData, currentActiveTab } = useSelector((state) => state.campaign);
    const { audienceCounter } = useSelector((state) => state.audienceManager);

    const getAudienceCount = () => audienceCounter?.recepient_count;

    useEffect(() => {
        if (campaignLeadData?.count >= 0) {
            setLeadCount(campaignLeadData?.count);
        }
    }, [campaignLeadData]);

    const onClickDraftEvent = () => {
        draftEvent();
        onClose();
    };

    const onClickScheduleEvent = () => {
        onClose();
        handleSend();
    };

    const getCount = () => {
        if (currentActiveTab === 'By Conditions') return leadCount;
        if (getAudienceCount() && getAudienceCount() !== -1) return getAudienceCount();
        return 0;
    };

    const isDisabled = () => getCount() === 0;
    const isLoading = isLoadingFinish && 'No Found';

    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            sx={sendCampaignDialogStyle}
        >
            <NormalLoading tableData={isLoading} pageCount={isLoading} />
            <Stack sx={reminderStackStyle}>
                <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                    <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {isEditDuration ? <CustomTypography>Are you sure you want to update?</CustomTypography>
                                : <CustomTypography>You’re about to schedule your campaign to: {getCount()} {getCount() > 1 ? 'leads' : 'lead'}</CustomTypography>}
                            {currentActiveTab === 'By Conditions' && getCount() > 0 && <Button color='primary' variant='contained' sx={{ ...sendButtonStyle, marginLeft: '10px' }} onClick={handlePreviewLeads} >View Leads</Button>}
                        </Box>
                    </Container>
                </Grid>
            </Stack>
            {editValue === 'Edit' && <Box onClick={() => onClose()} sx={{ position: 'absolute', top: '6px', right: '6px' }}>
                <Iconify icon={'icon-park-solid:close-one'} width={'18px'} height={'18px'} sx={{ color: 'text.disabled', cursor: 'pointer' }} />
            </Box>}
            <Box sx={{ ...sendCampaignFooterStyle, textAlign: 'center' }}>
                <Button
                    color='primary'
                    variant='contained'
                    sx={cancelButtonStyle}
                    onClick={() => onClickDraftEvent()}
                >
                    Draft
                </Button>
                {!isEditDuration && <Button
                    color='primary'
                    variant='contained'
                    sx={sendButtonStyle}
                    onClick={() => onClickScheduleEvent()}
                    disabled={isDisabled()}
                >
                    Schedule
                </Button>}
            </Box>
        </Dialog>
    );
};

export default ScheduleConfirmCampaignPopup;

ScheduleConfirmCampaignPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleSend: PropTypes.func,
    draftEvent: PropTypes.func,
    editValue: PropTypes.string,
    handlePreviewLeads: PropTypes.func,
    isEditDuration: PropTypes.bool,
};