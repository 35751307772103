export const messageAccordians = (sentTasks, upcommingTask, cancelTask, failedTasks) => [
    {	value: 'Sent',
        data: sentTasks?.length,
    },
    {	value: 'Upcoming',
        data: upcommingTask?.length
    },
    {	value: 'Cancelled',
        data: cancelTask?.length
    },
    {
        value: 'Failed',
        data: failedTasks?.length
    }
];
