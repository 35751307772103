export const AddTaskReminders = [{
    icon: 'fluent:call-20-filled',
    rotate: '4.1',
    label: 'Call',
    value: 'CALL'
}, {
    icon: 'eva:email-fill',
    label: 'Email',
    value: 'EMAIL'
}, {
    icon: 'bi:chat-left-dots-fill',
    label: 'SMS',
    value: 'SMS'
}, {
    icon: 'ri:whatsapp-fill',
    label: 'WhatsApp',
    value: 'WHATSAPP'
}, {
    label: 'Other',
    value: 'OTHER'
}];

// Please verify in autoReminder when you changed 'taskGroupConst'

export const taskGroupConst = [
    {
        label: 'Follow up',
        value: 'FOLLOW_UP'
    },
    {
        label: 'New Lead',
        value: 'NEW_LEAD'
    },
    {
        label: 'TODO',
        value: 'TODO'
    },
    {
        label: 'Other',
        value: 'OTHER'
    },
];

export const taskEventConst = [
    {
        label: 'Lead Treatment Status New',
        value: 'lead_treatment_new'
    },
    {
        label: 'Lead Treatment Status Consultation Booked',
        value: 'lead_treatment_consultation_booked'
    },
    {
        label: 'Lead Treatment Status Consultation Attended',
        value: 'lead_treatment_consultation_attended'
    },
    {
        label: 'Lead Treatment Status Treatment Started',
        value: 'lead_treatment_treatment_started'
    },
    {
        label: 'Lead Treatment Status Failed To Attend Consultation',
        value: 'lead_treatment_failed_to_attend_consultation'
    },
    {
        label: 'Lead Treatment Status Closed',
        value: 'lead_treatment_closed'
    },
    {
        label: 'Deposit Paid',
        value: 'stripe_checkout_session_paid'
    },
];

export const delayTime = [
    { label: 'Immediately', value: 1, oldValue: 1 },
    { label: '1 Hour', value: 3600, oldValue: 3600000 },
    { label: '2 Hour', value: 7200, oldValue: 7200000 },
    { label: '1 Day', value: 86400, oldValue: 86400000 },
    { label: '2 Day', value: 172800, oldValue: 172800000 },
    { label: '1 Week', value: 604800, oldValue: 604800000 },
    { label: '2 Week', value: 1209600, oldValue: 1210000000 },
    { label: '1 Month', value: 2628000, oldValue: 2592000000 },
    { label: '3 Months', value: 7884000, oldValue: 7776000000 },
    { label: '6 Months', value: 15768000, oldValue: 15552000000 },
    { label: '12 Months', value: 31536000, oldValue: 31104000000 },
];