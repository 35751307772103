import { alpha } from '@mui/system';
import { communicationTabStyle } from './Chat';
import { action, grey, primary } from '../theme/palette';

export const treatmentTabCardStyle = {
    position: 'relative', p: 3, width: 'calc(100% - 150px)', borderRadius: '8px',
    '@media(max-width: 600px)': {
        width: '100%'
    }
};
export const treatmentTabWrapper = {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, gap: '16px',
    '@media(max-width: 900px)': {
        flexDirection: 'column',
        alignItems: 'start'
    }
};
export const treatmentTabToolTip = { textTransform: 'none !important', padding: '8px 16px 8px 16px', borderRadius: '16px' };
export const treatmentTabStepperWrapper = { display: 'flex', mb: '24px', width: '100%', justifyContent: 'space-between' };
export const treatmentTabNewTreatmentBtnStyle = {
    marginTop: '0',
    borderRadius: '16px',
    padding: '8px 16px 8px 16px',
    gap: '8px',
    fontWeight: 700,
    textTransform: 'none !important',
};
export const treatmentDetailCardStyle = {
    bgcolor: grey[200], padding: '12px', gap: '16px', borderRadius: '16px', marginTop: '10px', display: 'flex', justifyContent: 'space-between',
    '@media(max-width: 600px)': {
        flexDirection: 'column'
    }
};
export const treatmentDetailTabStyle = {
    fontSize: '14px',
    color: grey[600],
    fontWeight: 400,
    textAlign: 'left',
};
export const moreMenuPopover = { display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px' };

export const treatmentTypoWrapper = {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    '@media(max-width: 479px)': {
        flexDirection: 'column'
    }
};
export const moreMenuWrapper = {
    display: 'flex',
    gap: '16px',
    '@media(max-width: 479px)': {
        flexDirection: 'column'
    }
};
export const tabsWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 2
};
export const tabsStyle = (theme) => ({
    width: '150px',
    mr: '4px',
    '.MuiTab-root.Mui-selected': {
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        color: alpha(theme.palette.primary.main, 1),
        fontWeight: 600,
    },
    '.MuiTabs-indicator': {
        display: 'none'
    },
    '@media(max-width: 600px)': {
        width: '100%'
    }
});
export const treatmentDetailTabStyleV2 = {
    ...communicationTabStyle,
    ...treatmentDetailTabStyle,
    textAlign: 'left',
    textTransform: 'none !important'
};

export const treatmentTabsStyle = {
    width: '150px',
    mr: '4px',
    '.MuiTab-root.selected-tab': {
        bgcolor: alpha(primary.main, action.selectedOpacity),
        color: alpha(primary.main, 1),
        fontWeight: 600,
    },
    '.MuiTabs-indicator': {
        display: 'none',
    },
};

export const treatmentDEtailWrapper = { bgcolor: grey[0], p: 3, pt: 4, width: '100%' };