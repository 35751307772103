import { common, grey, primary } from 'src/theme/palette';

export const snoozeButtonBox = { display: 'flex', justifyContent: 'end' };

export const notificationWrapper = {
    borderRadius: 2,
    border: `1px solid ${primary.paperColor}`,
    background: grey[200],
    p: '20px',
    mb: 2
};
export const notificationWrapperV2 = {
    borderRadius: 2,
    border: `1px solid ${primary.paperColor}`,
    boxShadow: `-20px 20px 40px -4px ${primary.paperColor}`,
    backgroundImage: 'linear-gradient(90deg, rgba(24, 144, 255, 0) 0%, rgba(14, 86, 153, 0.12) 100%), linear-gradient(90deg, rgb(255 255 255 / 89%) 0%, rgba(255, 80, 80, 0) 100%)',
    backdropFilter: 'blur(8px)',
    p: '12px',
    maxWidth: '350px',
    width: '350px',
};
export const notificationContentWrapper = { display: 'flex', alignItems: 'flex-start', gap: 1 };
export const notificationIconWrapper = { width: '40px', height: '40px', minWidth: '40px', borderRadius: '50%', background: common.peachPuff, display: 'flex', alignItems: 'center', justifyContent: 'center' };
export const notificationIconWrapperStyle = (bgColor = common.peachPuff) => ({ ...notificationIconWrapper, background: bgColor });
export const notificationMessageWrapper = { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' };

export const notificationMessageStyle = {
    fontSize: '14px',
    fontWeight: 600,
};
export const notificationCloseButtonStyle = {
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    backgroundColor: grey[300],
    color: grey[800]
};
export const notificationTimeStyle = { color: grey[500], fontSize: '11px', ml: 1 };
export const snackbarWrapper = { display: 'flex', flexDirection: 'column', gap: 3 };
export const snackbarWrapperParent = { position: 'fixed', top: '16px', right: '16px', zIndex: 999999999 };
export const snackbarMessageWrapperParent = { display: 'flex', flexDirection: 'column', width: '100%' };
export const wpMessageWrapper = { display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' };