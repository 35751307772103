/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState, useCallback } from 'react';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
// @mui
import { Box, TextField, List, ListItem, ListItemButton, ListItemText, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
// components
import { TreatmentDropdown } from '../Dropdowns';
import { FormProvider } from '../hook-form';
// store
import { useSelector, dispatch } from '../../redux/store';
import {
    getTreatmentSummary,
    addTargetTreatment,
    addStartTime,
    addEndTime
} from '../../redux/slices/analytics';
// utils
import groupedTreatments from '../../utils/groupedTreatments';
import { RangeShortcut, endDate, rangeShortcuts } from '../../constants/ReportSummaryConst';
// style
import { boxStyle, timeRageBoxStyle } from '../../styles/TreatmentTimeRageStyle';

// ----------------------------------------------------------------------------------------
function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
}

const TreatmentTimeRage = ({ type, isPrimaryView }) => {
    const methods = useForm();
    const { control } = methods;
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { targetTreatment, startTime, endTime } = useSelector((state) => state.analytics);
    const treatmentList = useSelector((state) => state.practiceTreatment.treatmentsList?.results);

    const [treatmentOptions, setTreatmentOptions] = useState([]);
    const [treatment, setTreatment] = useState('');
    const [value, setValue] = useState(isPrimaryView ? [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()] : [addMonths(new Date(), -12), new Date()]);

    useEffect(() => {
        if (treatmentList) {
            setTreatmentOptions(groupedTreatments(treatmentList));
        }
    }, [treatmentList]);

    const handleFieldData = (name, value) => {
        if (value) {
            if (name === 'treatments') {
                setTreatment(value);
                const treatmentData = treatmentList.filter((elem) => elem?.treatment_item?.name === value)[0]?.treatment_item;
                const data = { treatmentData, practiceId };
                localStorage.setItem('report-treatment', JSON.stringify(data));
                dispatch(getTreatmentSummary(practiceId, value ? treatmentList.filter((elem) => elem?.treatment_item?.name === value)[0]?.treatment_item?.id : '', startTime || '', endTime || ''));
                dispatch(addTargetTreatment(value));
            }
            if (name === 'dateTime') {
                setValue(value);
                const changedStartTime = value[0] ? moment(value[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00') : '';
                const changedEndTime = value[1] ? moment(value[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD 23:59:00') : '';
                dispatch(getTreatmentSummary(practiceId, targetTreatment ? treatmentList.filter((elem) => elem?.treatment_item?.name === targetTreatment)[0]?.treatment_item?.id : '', changedStartTime, changedEndTime));
                dispatch(addStartTime(changedStartTime));
                dispatch(addEndTime(changedEndTime));
            }
        }
    };

    const handleTreatment = () => {
        const localValue = JSON.parse(localStorage.getItem('report-treatment'));
        const matchedValue = localValue?.practiceId === practiceId && localValue.treatmentData?.name;
        return matchedValue;
    };

    const buildHandleRangeClick = (setValue) => (range) => {
        const today = dayjs();
        switch (range) {
            case RangeShortcut.allTime:
                setValue([null, null]);
                handleFieldData('dateTime', [null, null]);
                break;
            case RangeShortcut.thisWeek:
                setValue([today.startOf('week'), today.endOf('week')]);
                handleFieldData('dateTime', [today.startOf('week'), today.endOf('week')]);
                break;
            case RangeShortcut.lastWeek:
                setValue([
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                handleFieldData('dateTime', [
                    today.startOf('week').subtract(1, 'week'),
                    today.endOf('week').subtract(1, 'week'),
                ]);
                break;
            case RangeShortcut.today:
                setValue([today.startOf('day'), today.endOf('day')]);
                handleFieldData('dateTime', [today.startOf('day'), today.endOf('day')]);
                break;
            case RangeShortcut.tomorrow:
                setValue([today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                handleFieldData('dateTime', [today.startOf('day').add(1, 'day'), today.endOf('day').add(1, 'day')]);
                break;
            case RangeShortcut.last7Days:
                setValue([today.subtract(1, 'week'), today]);
                handleFieldData('dateTime', [today.subtract(1, 'week'), today]);
                break;
            case RangeShortcut.currentMonth:
                setValue([today.startOf('month'), today.endOf('month')]);
                handleFieldData('dateTime', [today.startOf('month'), today.endOf('month')]);
                break;
            case RangeShortcut.prevMonth:
                setValue([
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                handleFieldData('dateTime', [
                    today.subtract(1, 'months').startOf('month'),
                    today.subtract(1, 'months').endOf('month')
                ]);
                break;
            case RangeShortcut.reset:
                setValue([null, null]);
                break;
            default:
                break;
        }
    };

    const RangeShortcutsPanel = ({ setValue, children }) => {
        const handleRangeClick = useCallback(
            (range) => setValue && buildHandleRangeClick(setValue)(range),
            [setValue],
        );
        return (
            <Box sx={{ display: 'flex', '@media(max-width: 900px)': { display: 'inline-block' } }}>
                <Box sx={{ m: 2 }} display="flex" gap={2}>
                    <div>
                        <Typography variant="overline">Date range shortcuts</Typography>
                        <List>
                            {rangeShortcuts.map(({ range, label }) => (
                                <ListItem key={range} disablePadding>
                                    <ListItemButton onClick={() => handleRangeClick(range)}>
                                        <ListItemText primary={label} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <Divider orientation="vertical" />
                </Box>
                {children}
            </Box>
        );
    };

    return (
        <Box sx={boxStyle(type)} >
            <Box sx={{ width: '40%', '@media(max-width: 900px)': { width: '100%' } }}>
                <FormProvider methods={methods}>
                    <TreatmentDropdown
                        name={'treatments'}
                        control={control}
                        type={'generalAnalytics'}
                        options={treatmentOptions}
                        value={handleTreatment() || targetTreatment || treatment || ''}
                        onChange={(e, newValue) => handleFieldData('treatments', newValue?.name)}
                        renderInput={(params) => <TextField {...params} label="Treatment (Required)" sx={{ width: '100%', mr: '15px', minWidth: '250px' }} />}
                    />
                </FormProvider>
            </Box>
            <Box sx={timeRageBoxStyle}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                        onChange={(newValue) => handleFieldData('dateTime', newValue)}
                        name={'dateTime'}
                        value={value}
                        inputFormat="DD MMM YYYY"
                        maxDate={endDate}
                        renderInput={(startProps, endProps) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: '0' }}>
                                <TextField {...startProps} onKeyDown={(evt) => evt.preventDefault()} label="Start date" />
                                <Box sx={{ mx: 1 }}> to </Box>
                                <TextField {...endProps} onKeyDown={(evt) => evt.preventDefault()} label="End date" />
                            </Box>
                        )}
                        components={{ PaperContent: RangeShortcutsPanel }}
                        PaperProps={{ sx: { display: 'flex', flexDirection: 'row' } }}
                        componentsProps={{ paperContent: { setValue } }}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
};

TreatmentTimeRage.propTypes = {
    type: PropTypes.string,
    mapValues: PropTypes.array,
    setMapValues: PropTypes.any,
    handleData: PropTypes.func,
};

export default TreatmentTimeRage;
