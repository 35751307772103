import { useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    Grid,
    Container,
    Stack,
    Button,
    Box
} from '@mui/material';
// components
import { AddAppointmentAssignTeamMember } from '../../sections/@dashboard/leadDetail';
// redux
import { dispatch } from '../../redux/store';
import { appointmentDateSlice, appointmentUserSlice } from '../../redux/slices/lead';
import { clearPracticeUserData } from '../../redux/slices/practiceUser';
// style
import { grey } from '../../theme/palette';
import {
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle,
    assignTaskBoxStyle
} from '../../styles/AddReminderPopup';

const AppointmentAssignTaskPopup = ({ assignees, selectedTreatmentData, open, onClose, leadDetail, reminder, user, type, leadId, appoinmentSelectedId }) => {
    const inputRef = useRef();

    const handleNext = async () => {
        await inputRef.current.submit();
    };

    const cancelClickEvent = () => {
        onClose();
        dispatch(clearPracticeUserData());
        dispatch(appointmentDateSlice(null));
        dispatch(appointmentUserSlice(null));
    };

    return (
        <>
            {open && <Box sx={assignTaskBoxStyle(grey)}>
                <Stack sx={{ ...reminderStackStyle, p: '24px 24px 0' }}>
                    <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                        <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                            <AddAppointmentAssignTeamMember assignees={assignees} selectedTreatmentData={selectedTreatmentData} ref={inputRef} handleNext={onClose} leadDetail={leadDetail} reminder={reminder} type={type} user={user} leadId={leadId} appoinmentSelectedId={appoinmentSelectedId} />
                        </Container>
                    </Grid>
                </Stack>
                <Box sx={{ ...reminderFooterStyle, position: 'relative', p: '8px 24px 16px 24px!important' }}>
                    <Button
                        color='inherit'
                        sx={cancelButtonStyle}
                        onClick={cancelClickEvent}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={handleNext}
                        sx={saveButtonStyle}
                    >
                        Done
                    </Button>
                </Box>
            </Box>}
        </>
    );
};

export default AppointmentAssignTaskPopup;

AppointmentAssignTaskPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    leadDetail: PropTypes.any,
    reminder: PropTypes.any,
    user: PropTypes.any,
    type: PropTypes.string,
    leadId: PropTypes.number,
    selectedTreatmentData: PropTypes.any,
    assignees: PropTypes.any,
    appoinmentSelectedId: PropTypes.number,
};