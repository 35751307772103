import React from 'react';
import PropType from 'prop-types';
import { Badge, Button } from '@mui/material';
import { Iconify } from 'src/components';
import { archivingButtonStyle, badgeStyle } from 'src/styles/NewLeadTableRowStyle';

const CustomFollowUpBtn = ({ params, handleAttendedPopup }) => (
    <Button
        variant="contained"
        color='primary'
        startIcon={<Iconify icon={'fluent:call-32-filled'} />}
        onClick={() => { handleAttendedPopup(params.row.lead_id, params.row); }}
        disabled={params.row.is_archive_pending}
        sx={archivingButtonStyle}
    >
        {
            <Badge badgeContent={[params.row?.lead_treatments__lead_contacted_counts__count]} color="primary"
                sx={badgeStyle(params.row.status, params.row?.lead_treatments__lead_contacted_counts__count)}
            >
                {params.row.is_archive_pending ? 'Archiving' : 'Follow up'}
            </Badge>
        }
    </Button>
);

export default CustomFollowUpBtn;

CustomFollowUpBtn.propTypes = {
    params: PropType.any,
    handleAttendedPopup: PropType.func,
};
