import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// @mui
import { Box, Stack, Dialog, Grid, Button, AppBar, Typography, Container, Slide } from '@mui/material';
// components
import { useSettingsContext } from 'src/components/settings/context';
import { AttendedAppointment } from '../Attended';
import { clearLead } from '../../redux/slices/lead';
import { dispatch } from '../../redux/store';
import {
    commonDialogHeaderStyle,
    onBoardDialogHeader,
    reminderTitleStyle,
    cancelBoxStyle,
    reminderDialogStyle,
    attendedStackStyle
} from '../../styles/ScheduleReminder';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function OpenAttendedPopup({ isOverview, open, onClose, type = 'lead', user, inputRef, headerButtons, editDetailHandler, OpenAssignTask, handleCloseAndReload, handleReloadLeads }) {
    const settings = useSettingsContext();
    const handleClose = async (action) => {
        if (action === 'tco') {
            return;
        }
        dispatch(clearLead());
        onClose();
    };

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={reminderDialogStyle(type)}
            disableEnforceFocus
        >
            <AppBar sx={headerButtons ? commonDialogHeaderStyle : onBoardDialogHeader}>
                <Typography
                    sx={reminderTitleStyle}
                    variant="h6"
                    component="div"
                >
                    Attended appointment for <span style={{ textTransform: 'capitalize' }}>{user.first_name}</span>
                </Typography>
                {headerButtons &&
                    <Box sx={cancelBoxStyle}>
                        <Button
                            color="inherit"
                            variant='outlined'
                            style={{ borderColor: '#FFFFFF', color: '#fff' }}
                            onClick={() => { handleClose('close'); }}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            </AppBar>
            <Stack spacing={2} sx={attendedStackStyle}>
                <Grid container spacing={1} sx={{ maxWidth: '1088px', margin: 'auto' }}>
                    <Container
                        maxWidth={settings.themeStretch ? false : 'xl'}
                    >
                        <AttendedAppointment isOverview={isOverview} isFinishButtonAvailable handleCloseAndReload={handleCloseAndReload} handleClose={handleClose} id={user.lead_id} user={user} type='Starting treatment' inputRef={inputRef} OpenAssignTask={OpenAssignTask} editDetailHandler={editDetailHandler} handleReloadLeads={handleReloadLeads} />
                    </Container>
                </Grid>
            </Stack>
        </Dialog>
    );
}

OpenAttendedPopup.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    user: PropTypes.object,
    inputRef: PropTypes.any,
    headerButtons: PropTypes.bool,
    editDetailHandler: PropTypes.any,
    OpenAssignTask: PropTypes.any,
    handleCloseAndReload: PropTypes.func,
    handleReloadLeads: PropTypes.func,
    isOverview: PropTypes.bool
};