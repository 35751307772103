import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import { useChart } from '../../../../components/chart';

// ----------------------------------------------------------------------

export default function AnalyticsWebsiteVisits({ title, subheader, chartLabels, chartData, ...other }) {
    const chartOptions = useChart({
        plotOptions: { bar: { columnWidth: '16%' } },
        fill: { type: chartData.map((i) => i.fill) },
        labels: chartLabels,
        xaxis: { type: 'datetime' },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)}`;
                    }
                    return y;
                },
            },
            x: {
                formatter: (x) => moment(new Date(x), 'DD/MM/YYYY').format('MMM YYYY')
            }
        },
    });
	
    return (
        <Box>
            <Card {...other}>
                <CardHeader title={title} subheader={subheader} />
                <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                    <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
                </Box>
            </Card>
        </Box>
    );
}

AnalyticsWebsiteVisits.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};