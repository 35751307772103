/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { List, Button, Stack, Box, Menu, Typography } from '@mui/material';
// Components
import { shallowEqual } from 'react-redux';
import Iconify from '../../Iconify';
import NavList from './NavList';
import { AttendedAppointment } from '../../Attended';
import { ConsentBuilderPopUp, ReviewLinkPopUp } from '../../dialogPopUp';
import { OpenDialogPopup, OpenAttendedPopup, OpenUnAttendedPopup } from '../../fullScreenPopUps';
import { PersonalDetails, TreatmentAndAppointment, PaymentOption, MessageAndReminder } from '../../../sections/@dashboard/general/app';
import CustomMenuItem from '../CustomMenuItem';
// Redux
import { setCurrentTreatmentDataGlobal } from '../../../redux/slices/multiTreatment';
import { clearLead, updateSteps, createNewLead, clearConsentData, addNotes, updateSendReminderDuringLeadCreate } from '../../../redux/slices/lead';
import { updateError } from '../../../redux/slices/user';
import { dispatch, useSelector } from '../../../redux/store';
// hooks/routes/constants
import { useAuth, useToggle, useRecordAttendToggle, useUnRecordAttendToggle, useConsentToggle } from '../../../hooks';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { allowedTitlesVertical } from '../../../constants/commonConstants';
import { localStorageRemoveItem } from '../../../utils/storage-available';
// style
import { createButton } from './style';

const NavSectionVertical = ({ navConfig, sx, ...other }) => {

    const {
        attendance,
        markAsComplete,
        followUpLead,
        consentLink,
        practice
    } = useSelector((state) => ({
        attendance: state.lead?.attendance,
        markAsComplete: state.lead?.markAsComplete,
        followUpLead: state.lead?.followUpLead,
        consentLink: state.lead?.consentLink,
        practice: state.practice?.practice
    }), shallowEqual);

    const filterNHSNav = practice?.preferences?.is_accepting_nhs_patients;

    const { practiceUser: { results } } = useSelector(state => state.practiceUser);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const createHandleClose = () => setAnchorEl(null);

    const { user } = useAuth();

    const getUserList = () => results.filter(el => el.user && el.user.id === user?.pk);

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();
    const {
        toggle: openRecordAttend,
        onClose: onCloseRecordAttend
    } = useRecordAttendToggle();
    const {
        toggle: openUnRecordAttend,
        onClose: onCloseUnRecordAttend
    } = useUnRecordAttendToggle();

    const {
        toggle: ConsentBuilder,
        onOpen: onOpenConsent,
        onClose: onCloseConsent
    } = useConsentToggle();

    const {
        toggle: openReview,
        onOpen: onOpenReview,
        onClose: onCloseReview
    } = useToggle();

    const handleAttendClose = () => onCloseRecordAttend();
    const handleUnAttendClose = () => onCloseUnRecordAttend();

    const inputRef = useRef();
    const [validStep, setValidStep] = useState(false);
    const [active, setActive] = useState(0);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const  { sendReminderDuringLeadCreate } = useSelector((state) => state.lead);
    const handleClose = (status, id) => {
        if (status === 'finish') {
            setTimeout(() => {
                if(sendReminderDuringLeadCreate){
                    const details = {
                        additional_notes: "",
                        contacted_via: "PHONE",
                        lead: id,
                        note: "UNABLE_TO_CONTACT",
                        treatment: id
                    }
                    dispatch(addNotes(details, practiceId, '', 'createLead'));
                    dispatch(updateSendReminderDuringLeadCreate(false))
                }
                dispatch(updateError({ success: true, message: 'Lead successfully created! Refresh the page or click \'Show Lead\' to view and manage it.', lead: id, timeout: 10000 }));
            }, 5000);
        }
        dispatch(clearLead());
        dispatch(updateSteps(null));
        onCloseContacts();
    };

    const onSubmit = (data) => setValidStep(!!data);

    const STEPS = [
        {
            label: 'Personal details',
            component: <PersonalDetails ref={inputRef} handleNext={onSubmit} active={active} />
        },
        {
            label: 'Treatment & Appointment',
            component: <TreatmentAndAppointment type={'CreatLead'} ref={inputRef} handleNext={onSubmit} active={active} />
        }
    ];

    const [leadStep, setLeadStep] = useState(STEPS);
    useEffect(() => {
        if (attendance) {
            const steps = [...STEPS];
            if (attendance === 'attendance') {
                steps.push({
                    label: 'Appointment Outcome',
                    component: <AttendedAppointment ref={inputRef} handleNext={onSubmit} active={active} type={'CreatedLead'} />
                });
            } else {
                steps.push({
                    label: 'Payment options',
                    component: <PaymentOption ref={inputRef} handleNext={onSubmit} active={active} />
                });
                steps.push({
                    label: 'Message review & reminders',
                    component: <MessageAndReminder ref={inputRef} handleNext={onSubmit} active={active} />
                });
            }
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [attendance]);

    useEffect(() => {
        if (markAsComplete && !followUpLead && !consentLink) {
            dispatch(createNewLead(handleClose));
        }
    }, [markAsComplete, followUpLead, consentLink]);

    const handleReviewClick = () => { onOpenReview(); createHandleClose(); };
    const handleClickNewLead = () => {
        localStorageRemoveItem('localTreatmentData');
        onOpenContacts();
        createHandleClose();
        dispatch(setCurrentTreatmentDataGlobal(null));
    };
    const handleClickConsentClick = () => { onOpenConsent(); dispatch(clearConsentData()); createHandleClose(); };
    const handleClickStripePaymentClick = () => { navigate(PATH_DASHBOARD.createStripePayment.root); createHandleClose(); };
    const handleClickCallScriptClick = () => {
        navigate(PATH_DASHBOARD.user.callScripts, { state: { callScriptPopupOpen: true } });
        createHandleClose();
    };
    const handleClickConsentBuilder = () => { navigate(PATH_DASHBOARD.user.consentBuilder); createHandleClose(); };

    return (
        <Stack
            sx={{
                pb: '24px',
                ...sx,
            }}
            {...other}>
            <Stack sx={{ margin: '30px 0 12px', width: '100%', padding: '0 16px' }}>
                <Button
                    sx={{ ...createButton, maxWidth: '100%' }}
                    variant='contained'
                    onClick={handleClick}
                    endIcon={<Iconify icon={'eva:arrow-down-fill'} />}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                    Create
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={createHandleClose}
                    sx={{ '.MuiPaper-root': { minWidth: '200px' }, '.MuiMenuItem-root': { fontSize: '0.875rem' } }}
                >
                    <CustomMenuItem
                        handleClickCallScriptClick={handleClickCallScriptClick}
                        handleClickConsentClick={handleClickConsentClick}
                        handleClickNewLead={handleClickNewLead}
                        handleClickStripePaymentClick={handleClickStripePaymentClick}
                        handleReviewClick={handleReviewClick}
                        handleClickConsentBuilder={handleClickConsentBuilder}
                    />
                </Menu>
            </Stack>
            {navConfig.map((group) => (
                <List key={group.subheader} disablePadding sx={{ px: 2 }}>
                    {group.items.map((list) => {
                        if (allowedTitlesVertical.includes(list.title) || (list.title === 'Super admin' && getUserList()[0]?.user?.is_superuser)) {
                            if (list.title === 'Leads') {
                                const sublist = { ...list, children: list?.children?.filter(el => (!filterNHSNav && el?.title !== "NHS") || filterNHSNav) }
                                return (
                                    <Box key={list.title + list.path}>
                                        <NavList
                                            data={sublist}
                                            depth={1}
                                            hasChildren={!!list.children}
                                        />
                                    </Box>
                                );
                            } if (list.title === 'Messaging') {
                                return (
                                    <Box key='messaging' sx={{ p: '24px 0px 8px 16px' }}>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 700, textTransform: 'uppercase' }}>
                                            Messaging
                                        </Typography>
                                    </Box>
                                );
                            }
                            return (
                                <NavList
                                    key={list.title + list.path}
                                    data={list}
                                    depth={1}
                                    hasChildren={!!list.children}
                                />
                            );
                        }
                        return null;
                    })}
                </List>
            ))}
            {openContacts && <OpenDialogPopup
                open={openContacts}
                onClose={handleClose}
                steps={leadStep}
                type={'createLead'}
                validStep={validStep}
                updateStep={(type) => setValidStep(type)}
                inputRef={inputRef}
                title='Create Lead'
                headerButtons
                setActive={setActive}
            />}
            {openRecordAttend && <OpenAttendedPopup
                open={openRecordAttend}
                onClose={handleAttendClose}
                type={'createLead'}
                title='Create Lead'
                headerButtons
            />}
            {openUnRecordAttend && <OpenUnAttendedPopup
                open={openUnRecordAttend}
                onClose={handleUnAttendClose}
                type={'createLead'}
                title='Create Lead'
                headerButtons
            />}
            {ConsentBuilder && <ConsentBuilderPopUp
                open={ConsentBuilder}
                onClose={onCloseConsent}
            />}
            {openReview && <ReviewLinkPopUp
                open={openReview}
                onClose={onCloseReview}
            />}
            <Box id="new-lead-button" onClick={handleClickNewLead} sx={{ visibility: "hidden" }} />
        </Stack>
    );
};

NavSectionVertical.propTypes = {
    sx: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default NavSectionVertical;