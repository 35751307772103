import { common, grey } from 'src/theme/palette';

export const dialogStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': {
        background: common.foggyBlack
    },
    ' .MuiDialog-container': {
        width: '344px',
        backgroundColor: 'transparent',
        height: 'auto',
        borderRadius: '16px',
        maxHeight: '90vh',
        maxWidth: '95vw'
    },
    ' .MuiPaper-root': {
        width: '100%',
        height: 'auto',
        boxShadow: 'none',
        margin: '0 0',
        maxHeight: '90vh',
        maxWidth: '95vw',
        paddingBottom: '75px'
    }
};
export const stackStyle = {
    padding: '24px',
    width : '100% ',
    maxWidth : '98vw',
    maxHeight: 'calc(100vh - 150px)',
    overflow: 'auto'
};
export const btnBoxStyle = {
    left: '0',
    right:'0',
    position: 'absolute',
    bottom : '0',
    padding: '15px 24px',
    paddingBottom: '24px',
    width: '100%',
    zIndex: '99',
    textAlign: 'right'
};
export const textFieldStyle = {
    margin: '5px 0px',
    width: {
        xs: '100%',
        md: '100%'
    },
};
export const numberStyle = {
    color: grey[800], 
    fontWeight: '600', 
    m: '0 0 24px',
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline'
    }
};
export const treatmentBoxStyle = {
    fontSize: '14px', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    width: '50%'
};

export const saveBtnStyle = { padding: '6px 28px', fontSize: '14px', boxShadow: `${common.mintyZest} 24%` };
