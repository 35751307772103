import PropTypes from 'prop-types';
// @mui
import { IconButton, useTheme } from '@mui/material';
//
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';
import { moreMenuStyle, moreMenuIconButtonStyle } from '../../styles/TableMoreMenu';

// ----------------------------------------------------------------------

export default function DataGridTableMoreMenu({ actions, open, anchorEl, onClose, onOpen, type }) {
    const theme = useTheme();

    return (
        <>
            <IconButton onClick={onOpen} sx={moreMenuIconButtonStyle(theme)}>
                <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                className={open ? 'simple-popove22r dataGrid-menu-popover' : undefined}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                arrow="right-top"
                sx={moreMenuStyle(type, theme)}
            >
                {actions}
            </MenuPopover>
        </>
    );
}

DataGridTableMoreMenu.propTypes = {
    actions: PropTypes.node,
    open: PropTypes.object,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    type: PropTypes.string,
    anchorEl: PropTypes.any,
};