import React from 'react';
import BookedWithField from '../BookedWithField';

const BookedWithCell = ({
    openAppointmentAssignTaskFuc,
    typed,
    updateLeadPage,
    reloadCounter,
}) => ({
    field: 'booked_with',
    headerName: 'Booked with',
    width: 150,
    minWidth: 140,
    sortable: false,
    renderCell: (params) => (
        <BookedWithField
            rowData={params.row}
            OpenAssignTask={openAppointmentAssignTaskFuc}
            tableType={typed}
            updateLeadPage={updateLeadPage}
            reloadCounter={reloadCounter}
        />
    ),
});

export default BookedWithCell;