import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import practiceReducer from './slices/practice';
import singlePracticeReducer from './slices/singlePractice';
import practiceTreatmentReducer from './slices/practiceTreatment';
import practiceMessageReducer from './slices/practiceMessage';
import practiceTreatmentListReducer from './slices/practiceTreatmentList';
import practiceUserReducer from './slices/practiceUser';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import leadReducer from './slices/lead';
import forgotpasswordReducer from './slices/forgotpass';
import tagsCreateReducer from './slices/tagsCreate';
import practiceIntegrationReducer from './slices/practiceIntegration';
import activityReducer from './slices/activity';
import analyticsReducer from './slices/analytics';
import afterLoginReducer from './slices/afterLogin';
import autoReminderReducer from './slices/autoReminder';
import superAdminReducer from './slices/superAdmin';
import callScriptReducer from './slices/callScript';
import chatReducer from './slices/chat';
import loanReducer from './slices/loan';
import financialReducer from './slices/financialTracker';
import campaignReducer from './slices/campaign';
import duplicateReducer from './slices/duplicateLeads';
import clinCheckReducer from './slices/clincheck';
import audienceManagerReducer from './slices/audienceManager';
import archiveReducer from './slices/archive';
import tablePreferencesReducer from './slices/tablePreferences';
import multiTreatmentReducer from './slices/multiTreatment';
import widgetCounterReducer from './slices/widgetCounter';
import toDoNotificationsReducer from './slices/toDoNotifications';
import schemaReducer from './slices/schema';
import emailCounterReducer from './slices/emailCounter';
import feedbackReviewReducer from './slices/feedbackReview';
import practiceChatbotReducer from './slices/practiceChatbot';
import postsReducer from './slices/postSlice';
import whatsappAccountReducer from './slices/whatsappAccount';
import whatsAppReducer from './slices/whatsapp';
import liveNotificationsReducer from './slices/liveNotificationSlice';
import whatsappMessagesReducer from './slices/whatsAppMessages';
import depositReducer from './slices/deposit';
import settingsTemplateReducer from './slices/settingsTemplate';
import loaderReducer from './slices/loader';
import callScriptV2Reducer from './slices/callScriptV2';
import mergeTagReducer from "./slices/mergeTags";

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['whatsApp', 'mergeTags'],
};

const rootReducer = combineReducers({
    mail: mailReducer,
    calendar: calendarReducer,
    kanban: kanbanReducer,
    user: userReducer,
    lead: leadReducer,
    forgotpassword: forgotpasswordReducer,
    practice: practiceReducer,
    singlePractice: singlePracticeReducer,
    practiceTreatment: practiceTreatmentReducer,
    practiceMessage: practiceMessageReducer,
    practiceTreatmentList: practiceTreatmentListReducer,
    practiceUser: practiceUserReducer,
    tagsCreate: tagsCreateReducer,
    practiceIntegration: practiceIntegrationReducer,
    activity: activityReducer,
    analytics: analyticsReducer,
    afterLogin: afterLoginReducer,
    autoReminder: autoReminderReducer,
    superAdmin: superAdminReducer,
    callScript: callScriptReducer,
    chat: chatReducer,
    loan: loanReducer,
    financialTracker: financialReducer,
    campaign: campaignReducer,
    duplicate: duplicateReducer,
    clinCheck: clinCheckReducer,
    audienceManager: audienceManagerReducer,
    archive: archiveReducer,
    tablePreferences: tablePreferencesReducer,
    multiTreatment: multiTreatmentReducer,
    widgetCounter: widgetCounterReducer,
    toDoNotifications: toDoNotificationsReducer,
    schema: schemaReducer,
    emailCounter: emailCounterReducer,
    feedbackReview: feedbackReviewReducer,
    practiceChatbot: practiceChatbotReducer,
    posts: postsReducer,
    whatsappAccount: whatsappAccountReducer,
    whatsApp: whatsAppReducer,
    liveNotifications: liveNotificationsReducer,
    whatsappMessages: whatsappMessagesReducer,
    deposit: depositReducer,
    settingsTemplate: settingsTemplateReducer,
    loaderReducer,
    callScriptV2: callScriptV2Reducer,
    mergeTags: mergeTagReducer
});

export { rootPersistConfig, rootReducer };
