export const onSortValue = ['treatment_name', 'Status', 'Contact'];

export const TAB_HEAD = [
    { value: 'All', label: 'All' },
    { value: 'NEW', label: 'New' },
    { value: 'CONSULTATION_BOOKED', label: 'Consultation booked' },
    { value: 'CONSULTATION_ATTENDED', label: 'Consultation attended' },
    { value: 'TREATMENT_STARTED', label: 'Treatment started' },
    { value: 'FAILED_TO_ATTEND_CONSULTATION', label: 'Failed to attend consultation' },
    { value: 'CLOSED', label: 'Completed' }
];

export const TAB_HEAD_FOLLOW_UP = [
    { value: 'All', label: 'All' },
    { value: 'NEW', label: 'New' },
    { value: 'FOLLOW_UP', label: 'Follow up' },
    { value: 'CONSULTATION_BOOKED', label: 'Consultation booked' },
    { value: 'CONSULTATION_ATTENDED', label: 'Consultation attended' },
    { value: 'TREATMENT_STARTED', label: 'Treatment started' },
    { value: 'FAILED_TO_ATTEND_CONSULTATION', label: 'Failed to attend consultation' },
    { value: 'CLOSED', label: 'Completed' }
];

export const NEW_TAB_HEAD = [
    { value: '0', label: 'New' },
    { value: '1', label: 'Follow up 1' },
    { value: '2', label: 'Follow up 2' },
    { value: '3', label: 'Follow up 3 +' },
];

export const DASHBOARD_WIDGET_COUNTER = [
    { key: '1', status: 'NEW', isContactedCountNull: true, isDate: true },
    { key: '2', treatmentStatus: 'NEW', group__in: true, completedFilter: true, isTaskDateTimeRange: true, shouldGetTaskCounter: true, is_completed: false },
    { key: '3', shouldGetReportCounter: true, status: 'CONSULTATION_BOOKED', status__in: 'BOOKED' },
    { key: '4', status: 'CONSULTATION_ATTENDED', isAppointmentDate: true },
    { key: '5', status: 'CONSULTATION_ATTENDED', isClinCheckTable: true, clinCheckStatus: 'IN_PROGRESS,UPLOADED', isAppointmentDate: true },
    { key: '6', shouldGetReportCounter: true, status: 'FAILED_TO_ATTEND_CONSULTATION', status__in: 'CANCELLED,FAILED_TO_ATTEND' },
];

const appointmentDataBookedTable = { appStatus: 'BOOKED' };
const appointmentDataAttendedTable = { appStatus: 'ATTENDED' };

export const BOOKED_TABLE_WIDGET_COUNTER = [
    { key: '1', shouldGetReportCounter: true, status: 'CONSULTATION_BOOKED', status__in: 'BOOKED' },
    { key: '2', status: 'CONSULTATION_BOOKED', treatment: 'invisalign', ...appointmentDataBookedTable },
    { key: '3', status: 'CONSULTATION_BOOKED', treatment: 'bonding', ...appointmentDataBookedTable },
    { key: '4', status: 'CONSULTATION_BOOKED', treatment: 'implants', ...appointmentDataBookedTable },
    { key: '5', status: 'CONSULTATION_BOOKED', treatment: 'whitening', ...appointmentDataBookedTable },
];
export const STARTED_TABLE_WIDGET_COUNTER = [
    { key: '1', treatmentStatusForPrice: 'TREATMENT_STARTED', treatmentPrice: true },
    { key: '2', status: 'TREATMENT_STARTED', treatment: 'invisalign' },
    { key: '3', status: 'TREATMENT_STARTED', treatment: 'bonding' },
    { key: '4', status: 'TREATMENT_STARTED', treatment: 'implants' },
    { key: '5', status: 'TREATMENT_STARTED', treatment: 'whitening' },
    { key: '6', status: 'TREATMENT_STARTED', appStatus: 'BOOKED', isAppointmentDate: true },
];
export const FTA_TABLE_WIDGET_COUNTER = [
    { key: '1', shouldGetReportCounter: true, status: 'FAILED_TO_ATTEND_CONSULTATION', status__in: 'CANCELLED,FAILED_TO_ATTEND' },
    { key: '2', status: 'FAILED_TO_ATTEND_CONSULTATION', treatment: 'invisalign' },
    { key: '3', status: 'FAILED_TO_ATTEND_CONSULTATION', treatment: 'bonding' },
    { key: '4', status: 'FAILED_TO_ATTEND_CONSULTATION', treatment: 'implants' },
];
export const ATTENDED_TABLE_WIDGET_COUNTER = [
    { key: '1', status: 'CONSULTATION_ATTENDED' },
    { key: '2', status: 'CONSULTATION_ATTENDED', treatment: 'invisalign', ...appointmentDataAttendedTable },
    { key: '3', status: 'CONSULTATION_ATTENDED', treatment: 'bonding', ...appointmentDataAttendedTable },
    { key: '4', status: 'CONSULTATION_ATTENDED', treatment: 'implants', ...appointmentDataAttendedTable },
    { key: '5', status: 'CONSULTATION_ATTENDED', treatment: 'whitening', ...appointmentDataAttendedTable },
    { key: '6', treatmentStatusForPrice: 'CONSULTATION_ATTENDED', shouldGetPrice: true, appStatusForPrice: 'ATTENDED' },
];
export const CLINCHECK_TABLE_WIDGET_COUNTER = [
    { key: '1', isClinCheckTable: true, status: 'CONSULTATION_ATTENDED' },
    { key: '2', isClinCheckTable: true, status: 'CONSULTATION_ATTENDED', treatment: 'invisalign' },
    { key: '3', isClinCheckTable: true, status: 'CONSULTATION_ATTENDED', treatment: 'bonding' },
    { key: '4', isClinCheckTable: true, status: 'CONSULTATION_ATTENDED', treatment: 'implants' },
    { key: '5', isClinCheckTable: true, status: 'CONSULTATION_ATTENDED', treatment: 'whitening' },
    { key: '6', isClinCheckTable: true, status: 'CONSULTATION_ATTENDED', shouldGetPrice: true },
];

export const CONSULTANT_NEW_ATTENDED_HEAD = [
    { value: '0', label: 'New' },
    { value: '1', label: 'Follow up 1' },
    { value: '2', label: 'Follow up 2' },
    { value: '3', label: 'Follow up 3 +' },
];

export const CONSULTANT_CLINCHECK_ATTENDED_HEAD = [];

export const CONSULTANT_BOOKED_HEAD = [
    { value: 'All', label: 'All' },
    { value: 'IN_PRACTICE', label: 'In practice' },
    { value: 'VIDEO_CALL', label: 'Video call' },
    { value: 'PHONE', label: 'Phone' },
];

export const DASH_NEW_HEADER = [
    { value: '0', label: 'New' },
    { value: 'NHS', label: 'NHS' },
    { value: 'AWAITING_RESPONSE', label: 'Awaiting reply' },
    { value: '1', label: 'Follow up 1' },
    { value: '2', label: 'Follow up 2' },
    { value: '3', label: 'Follow up 3 +' },
];
// notification
export const NOTIFICATION_HEAD = [
    { id: '' },
    { id: 'description', label: 'Description', sort: '' },
    { id: 'timestamp', label: 'Timestamp', sort: '' },
    { id: '' },
];

export const NOTIFICATION_HEAD_SETTING = [
    { id: 'notify', label: 'Notify', sort: '' },
    { id: 'platform', label: 'Platform', sort: '', checkbox: true },
    { id: 'email', label: 'Email', sort: '', checkbox: true },
    { id: 'sms', label: 'SMS', sort: '', checkbox: true },
    { id: '' },
];

export const DATE_TITLES = [
    {
        label: 'Date',
        value: 'date_format',
        icon: 'solar:calendar-mark-bold-duotone'
    },
    {
        label: 'Time',
        value: 'human_readable',
        icon: 'uim:clock'
    },
];

export const COLOR_CODES = [
    '#D1E9FC',
    '#76B0F1',
    '#2065D1',
    '#103996',
    '#061B64',
    '#EFD6FF',
    '#C684FF',
    '#8E33FF',
    '#5119B7',
    '#27097A',
    '#CAFDF5',
    '#61F3F3',
    '#00B8D9',
    '#006C9C',
    '#003768',
    '#D3FCD2',
    '#77ED8B',
    '#22C55E',
    '#118D57',
    '#065E49',
    '#FFF5CC',
    '#FFD666',
    '#FFAB00',
    '#B76E00',
    '#7A4100',
    '#FFE9D5',
    '#FFAC82',
    '#FF5630',
    '#B71D18',
    '#7A0916',
    '#F9FAFB',
    '#F4F6F8',
    '#DFE3E8',
    '#C4CDD5',
    '#919EAB',
    '#6E7E91',
];

export const startedTreatmentColumns = ['treatment', 'price', 'status', 'lead_treatments__appointments__datetime', 'booked_with', 'actions',];
export const bookedAttendedColumns = ['treatment', 'price', 'status', 'deposit_pending', 'app_method', 'app_type', 'lead_treatments__appointments__datetime', 'actions'];
export const commonColumns = ['treatment', 'status', 'price', 'actions'];