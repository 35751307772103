import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Box, Typography, Table, TableBody, TableContainer, TableCell, TableRow, MenuItem, Dialog, DialogTitle, DialogActions, Button, Zoom, Tooltip } from '@mui/material';
// components
import { TableHeadCustom, TableMoreMenu } from './table';
import Iconify from './Iconify';
import { OpenReSchedulePopup, OpenAttachmentsPopup, OpenAppointmentPopup } from './fullScreenPopUps';
import NoAppointmentComponent from './NoAppointmentComponent';
// redux
import { useSelector, dispatch } from '../redux/store';
import { cancelAppointment, appointmentsAttended } from '../redux/slices/lead';
// hooks/constants/utils
import { useTable, useToggle, useAppointmentToggle } from '../hooks';
import { upcomingAppointmentsLabels } from '../constants/LeadDetailConst';
import { getAssignees } from '../utils/multiAsignees';
// styles
import { grey } from '../theme/palette';
import { cancelBtnStyle, yesBtnStyle, dateTextStyle, treatmentTextStyle, nameTextStyle, dialogTitle, dialogActions, normalWhiteSpace, appointmentTableContainer, appointmentTableHead } from '../styles/UpcommingAppointments';

const UpcomingAppointmentsOverview = ({ leadAppointment, treatmentData, leadDetail, treatments, handleReloadAPI }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [tableData, setTableData] = useState(leadAppointment);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const rowData = [];
    const upcomingRef = useRef();
    const [openMenu, setOpenMenuActions] = useState(null);
    const [startConfirmation, setStartConfirmation] = useState(false);
    const [appoinmentID, setAppoinmentID] = useState(null);
    const [appoinmentSelectData, setAppoinmentSelectData] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef();
    const [confirmation, setConfirmation] = useState(false);

    useEffect(() => {
        if (leadAppointment) {
            leadAppointment?.map((item) => {
                const latestData = item;
                rowData.push({
                    assignees: item?.assignees || [],
                    Date: moment(latestData?.datetime, 'DD-MM-YYYY').format('ddd, D MMM'),
                    Time: moment(latestData?.datetime, 'DD-MM-YYYY HH:mm:ss').format('hh:mm A'),
                    Treatment: treatments?.find((ele) => ele.id === item?.lead_treatment)?.treatment || treatmentData?.treatment,
                    method: latestData?.method,
                    Practioner: latestData?.assignees?.length > 0 ? practiceUser?.results?.filter(el => latestData?.assignees?.some((ele) => ele === el.id) || latestData?.assignees?.some((ele) => ele?.id === el.id)) : [],
                    Status: latestData?.status,
                    appointment_id: latestData?.id,
                    datetime: latestData?.datetime,
                    contacted_via: latestData?.contacted_via,
                    type: latestData?.type || '',
                    note: latestData?.note || '',
                    selectedTreatmentData: treatments?.find((ele) => ele.id === item?.lead_treatment) || treatmentData
                });
                return item;
            });
            setTableData(rowData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadAppointment]);

    const { onSelectAllRows } = useTable();

    const handleOpenMenu = (event, row) => {
        setOpenMenuActions(event.currentTarget);
        upcomingRef.current = row?.appointment_id;
    };

    const handleCloseMenu = () => setOpenMenuActions(null);

    const cancelAppointmentClick = (appointmentId) => {
        setStartConfirmation(true);
        setOpenMenuActions(null);
        setAppoinmentID(appointmentId);
    };

    const cancelAppointmentAPI = () => {
        dispatch(cancelAppointment(practiceId, leadDetail?.id, appoinmentID, handleSuccess));
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        setStartConfirmation(false);
    };

    const handleReScheduleClose = () => onCloseReSchedule();

    const handleAttachmentClose = () => onCloseAttachment();

    const {
        toggle: openReSchedule,
        onOpen: onOpenReSchedule,
        onClose: onCloseReSchedule
    } = useToggle();

    const {
        toggle: openAttachments,
        onOpen: onOpenAttachment,
        onClose: onCloseAttachment
    } = useToggle();

    const {
        toggle: openAppointment,
        onOpen: onOpenAppointment,
        onClose: onCloseAppointment
    } = useAppointmentToggle();

    useEffect(() => {
        const htmlElement = document.querySelector('body');
        if (openAttachments) {
            htmlElement.classList.add('overflow-hidden');
        } else {
            htmlElement.classList.remove('overflow-hidden');
        }
    }, [openAttachments]);

    const handlePopup = (appointmentId, appointmentData) => {
        setAppoinmentID(appointmentId);
        setAppoinmentSelectData(appointmentData);
        setTimeout(() => {
            onOpenReSchedule();
            setOpenMenuActions(null);
        }, 800);
    };

    const attachmentPopup = (appointmentId, appointmentData) => {
        setAppoinmentID(appointmentId);
        setAppoinmentSelectData(appointmentData);
        setTimeout(() => {
            onOpenAttachment();
            setOpenMenuActions(null);
        }, 800);
    };

    const handleAttended = (appointmentData) => {
        setAppoinmentSelectData(appointmentData);
        setConfirmation(true);
        setOpenMenuActions(null);
    };

    const handleAppointmentClose = () => {
        onCloseAppointment();
    };

    const handleAppointmentPopup = () => {
        setTimeout(() => {
            onOpenAppointment();
        }, 800);
    };

    const attendedTreatmentAPI = () => {
        const appointmentDetail = {
            contacted_via: appoinmentSelectData?.contacted_via || '',
            datetime: appoinmentSelectData?.datetime,
            method: appoinmentSelectData.method,
            note: appoinmentSelectData.note,
        };
        dispatch(appointmentsAttended(practiceId, leadDetail?.id, appoinmentSelectData?.appointment_id, appointmentDetail, handleStatusUpdate));
    };

    const handleStatusUpdate = (data) => {
        enqueueSnackbar('Lead treatment status updated successfully', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        setConfirmation(false);
        handleReloadAPI('finish');
    };

    return (
        <Box>
            <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>Upcoming appointments</Typography>
            </Box>
            <Box>
                {leadAppointment && leadAppointment?.length > 0 ?
                    <TableContainer sx={appointmentTableContainer}>
                        <Table>
                            <TableHeadCustom
                                headLabel={upcomingAppointmentsLabels}
                                rowCount={tableData?.length}
                                sx={appointmentTableHead}
                                onSelectAllRows={(checked) => onSelectAllRows(checked)}
                            />
                            <TableBody>
                                {tableData !== undefined && tableData && tableData?.map((row, index) => (
                                    <TableRow key={index} selected={false} sx={{ borderBottom: '1px solid rgba(145, 158, 171, 0.12)', ' .MuiTableCell-body': { p: '16px 12px !important' } }}>
                                        <TableCell>
                                            <Box sx={{ ml: 0 }}>
                                                <Typography variant='span' noWrap sx={dateTextStyle}>
                                                    {row?.Date}
                                                    <span style={{ color: grey[700], display: 'block' }}>{row?.Time}</span>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ ml: 0 }}>
                                                <Typography variant='span' noWrap sx={treatmentTextStyle}>
                                                    {row?.Treatment}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ ml: 0 }}>
                                                <Typography variant='span' noWrap sx={nameTextStyle}>
                                                    <Tooltip title={getAssignees({ assignees: row?.Practioner })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                        <span style={{ textTransform: 'capitalize', marginRight: row?.Practioner?.[0]?.user?.salutation ? '4px' : '0' }}>{`${row?.Practioner?.[0]?.user?.salutation || ''}`.toLowerCase()}</span> {row.Practioner?.[0]?.user?.first_name} {row.Practioner?.[0]?.user?.last_name} {row?.Practioner?.length > 1 ? `+ ${row?.Practioner?.length - 1}` : ''}
                                                    </Tooltip>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align='right' >
                                            {row.Status !== 'CANCELLED' && <TableMoreMenu
                                                open={upcomingRef.current === row.appointment_id && openMenu}
                                                sx={{ width: '180px' }}
                                                onOpen={(e) => handleOpenMenu(e, row)}
                                                onClose={handleCloseMenu}
                                                actions={
                                                    <Box>
                                                        {row.Status !== 'CANCELLED' &&
                                                            <MenuItem onClick={() => handlePopup(row.appointment_id, row)} sx={normalWhiteSpace}>
                                                                Edit/Reschedule
                                                            </MenuItem>
                                                        }
                                                        {row.Status !== 'CANCELLED' && row.Status !== 'FAILED_TO_ATTEND' &&
                                                            <MenuItem onClick={() => cancelAppointmentClick(row.appointment_id)} sx={normalWhiteSpace}>
                                                                Cancel
                                                            </MenuItem>
                                                        }
                                                        {row?.Status === 'BOOKED' &&
                                                            <MenuItem onClick={() => handleAttended(row)} sx={normalWhiteSpace}>
                                                                Attended
                                                            </MenuItem>
                                                        }
                                                        <MenuItem onClick={() => attachmentPopup(row.appointment_id, row)} sx={normalWhiteSpace}>
                                                            View detail
                                                        </MenuItem>
                                                    </Box>
                                                }
                                            />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <NoAppointmentComponent />
                }
            </Box>
            {leadDetail?.lead_treatments[0]?.status !== 'CLOSED' && <Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                        <Button sx={{ marginTop: '24px', ml: 'auto' }} variant='contained' onClick={() => handleAppointmentPopup()}>
                            <Iconify sx={{ mr: '8px' }} icon={'eva:plus-fill'} width={20} height={20} /> Add appointment
                        </Button>
                    </Box>
                </Box>
            </Box>}
            <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
                <DialogTitle sx={dialogTitle}>
                    Are you sure you want to attended the treatment?
                </DialogTitle>
                <DialogActions sx={dialogActions}>
                    <Button sx={cancelBtnStyle} onClick={() => setConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button type='submit' sx={yesBtnStyle} onClick={() => attendedTreatmentAPI()}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={startConfirmation} onClose={() => setStartConfirmation(false)}>
                <DialogTitle sx={dialogTitle}>Are you sure you want to cancel the appointment ?</DialogTitle>
                <DialogActions sx={dialogActions}>
                    <Button sx={cancelBtnStyle} onClick={() => setStartConfirmation(false)}>Cancel</Button>
                    <Button type='submit' sx={yesBtnStyle} onClick={() => cancelAppointmentAPI()}>Yes</Button>
                </DialogActions>
            </Dialog>
            {openReSchedule && <OpenReSchedulePopup
                open={openReSchedule}
                headerButtons
                onClose={handleReScheduleClose}
                treatmentData={treatmentData}
                inputRef={inputRef}
                appoinmentID={appoinmentID}
                appoinmentSelectData={appoinmentSelectData}
            />}
            {openAttachments && <OpenAttachmentsPopup
                open={openAttachments}
                headerButtons
                onClose={handleAttachmentClose}
                inputRef={inputRef}
                appoinmentID={appoinmentID}
            />}
            {openAppointment && <OpenAppointmentPopup
                open={openAppointment}
                headerButtons
                onClose={handleAppointmentClose}
                treatmentData={treatmentData}
                inputRef={inputRef}
            />}
        </Box>
    );
};

UpcomingAppointmentsOverview.propTypes = {
    leadAppointment: PropTypes.object,
    treatmentData: PropTypes.object,
    leadDetail: PropTypes.object,
    treatments: PropTypes.object,
    handleReloadAPI: PropTypes.func,
};

export default UpcomingAppointmentsOverview;