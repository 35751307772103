export const pickerButtonStyle = (value) => ({
    backgroundColor: value,
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    cursor: 'pointer'
});
export const colorBoxStyle = (value) => ({
    backgroundColor: value,
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    margin: '2px'
});
export const colorBoxWrapper = { display: 'flex', flexWrap: 'wrap' };
export const colorBoxWrapperParent = { width: '115px', borderRadius: '10px' };