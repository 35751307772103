import PropTypes from 'prop-types';
// @mui
import { Box, Typography } from '@mui/material';
import { Iconify } from '../../../components';
import { Activities } from '../leadDetail';
import { listHeaderBoxStyle } from '../../../styles/ContactLeadPopup';

// ------------------------------------------------------------------------------------------------------------

export default function ActivityList({ setExpandLayout, expandLayout, handleChange, leadId }) {

    return (
        <Box>
            <Box sx={listHeaderBoxStyle}>
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Activity log</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Iconify icon={expandLayout ? 'fluent:arrow-swap-28-filled' : 'fluent:arrow-fit-24-filled'} onClick={() => setExpandLayout(!expandLayout)} sx={{ color: '#637381', mr: 0, cursor: 'pointer', display: 'none' }} width={24} height={24} />
                    <Iconify icon={'zondicons:close-solid'} onClick={() => handleChange('', '')} sx={{ color: '#637381', mr: 0, cursor: 'pointer' }} width={24} height={24} />
                </Box>
            </Box>
            <Box>
                <Activities notAllow leadId={leadId} type="contactLeadPopup" />
            </Box>
        </Box>
    );
};

ActivityList.propTypes = {
    setExpandLayout: PropTypes.any,
    expandLayout: PropTypes.any,
    handleChange: PropTypes.func,
    leadId: PropTypes.any,
};