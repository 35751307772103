import { common, grey, primary } from 'src/theme/palette';

export const contentTextStyle = {
    fontSize: '14px',
    color: grey[600],
    textAlign: 'left',
    fontWeight: '600'
};
export const searchMainBoxStyle = {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '20px 8px 20px 20px'
};
export const moreIconstyle = {
    minWidth: '36px',
    minHeight: 'auto',
    lineHeight: '0',
    textAlign: 'center',
    cursor: 'pointer'
};
export const formControlLabelStyle = {
    px: '0',
    py: '10px',
};
export const treatmentAccordionBox = {
    border: 'none',
    boxShadow: 'none !important',
    width: '100%',
    borderRadius: '0 !important',
    marginBottom: '0 !important',
    marginTop: '0 !important',
    borderBottom: `solid 1px ${common.blueishGray}`,
    padding: '0', 
    '&:before': { display: 'none' },
};

export const treatmentAccordionSummary = (isLight) => ({
    bgcolor: 'transparent',
    minHeight: 'auto !important',
    p: '12px 16px',
    margin: '0',
    ' .MuiAccordionSummary-content': { margin: '0 !important', alignItems: 'center' },
    ' .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { width: '13.33px', height: '1.67px', bgcolor: isLight ? grey[800] : common.white },
    ' .Mui-expanded .status': { display: 'none' },
    '.Mui-expanded svg': { display: 'none' },
    ' .Mui-expanded .edit-treatment-button': { display: 'inline-block' }
});
export const selectedLeadBoxStyle = (dense) => ({
    position: 'absolute',
    width: '100%',
    top: 0,
    background: primary.lighter,
    zIndex: 99999,
    padding: dense ? '6px 16px' : '11.5px 16px',
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between'
});
export const archiveDialogStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ' .MuiBackdrop-root': { background: common.foggyBlack },
    ' .MuiDialog-container': { width: '500px', height: 'auto', borderRadius: '16px', maxHeight: '90vh', maxWidth: '95vw' },
    ' .MuiPaper-root': { width: '100%', height: 'auto', boxShadow: 'none', margin: '0 0', maxHeight: '90vh', maxWidth: '95vw', paddingBottom: '75px' }
};
export const tableLabel = {
    height: '22px',
    width: '32px',
    background: 'transparent !important',
    border: `2px solid ${common.tomatoRed}`,
    padding: '0 8px',
    color: common.tomatoRed,
    ml: '12px',
    fontSize: '12px'
};
export const whiteBGStyle = {
    display: 'flex',
    width: '40px',
    height: '40px',
    padding: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    bgcolor: 'rgba(84, 214, 44, 0.16)'
};
export const confirmationMainBoxStyle = {
    display: 'flex', 
    alignItems: 'flex-start', 
    p: 3, 
    maxWidth: '561px'
};
export const sourceLabelStyle = {
    backgroundColor: 'rgba(84, 214, 44, 0.16)', 
    color: common.lightGreen, 
    p: '3px 6px', 
    height: '26px'
};