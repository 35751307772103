import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { errorHandler } from '../../utils/errorHandler';
import { dispatch, store } from '../store';
import { updatePracticeDetail } from './singlePractice';
import { updateError } from './user';

const initialState = {
    isLoading: true,
    practiceUser: {
        results: []
    },
    inviteUser: [],
    message: '',
    error: '',
    editUserData: '',
    practiceUserWithFilter: {},
    usersData: null,
    practiceUserCounter: []
};

const slice = createSlice({
    name: 'practiceUser',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        startLoading(state) {
            state.isLoading = true;
            state.error = '';
            state.message = '';
        },
        getPracticeUserData(state, action) {
            state.isLoading = false;
            state.practiceUser = action.payload;
        },
        getPracticeUserDataWithFilter(state, action) {
            state.isLoading = false;
            state.practiceUserWithFilter = action.payload;
        },
        // CLEAR
        clearData(state) {
            state.practiceUser.results = [];
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload || 'Something went wrong';
        },
        updateDetail(state, action) {
            state.isLoading = false;
            state.error = '';
            state.message = action.payload;
        },
        updatePracticeUser(state, action) {
            state.isLoading = false;
            state.practiceUser.results = action.payload;
        },
        addPracticeUser(state) {
            state.isLoading = false;
        },
        clearMessage(state) {
            state.error = '';
            state.message = '';
        },
        updateInviteUserList(state, action) {
            state.isLoading = false;
            state.error = '';
            state.message = '';
            state.inviteUser = action.payload;
        },
        practiceUserDataSlice(state, action) {
            state.editUserData = action.payload;
        },
        getUserListing(state, action) {
            state.isLoading = false;
            state.usersData = action.payload;
        },
        updateCounter(state, action) {
            state.practiceUserCounter = action.payload;
        },
        clearPracticeUserData(state) {
            state.usersData = null;
        },
    }
});

// Reducer
export default slice.reducer;
export const { clearMessage, reset, clearData, startLoading, updateCounter, clearPracticeUserData } = slice.actions;

export function getPracticeUserList(id, offset = 0, allData = []) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${id}/users/?offset=${offset}&ordering=created_at&fields={id,practice,role,user,is_enabled,notification_preferences{id,is_enabled,notification_event,notification_type}}&is_enabled=true`);
            if (response.status === 200) {
                allData = allData.concat(response.data.results);
                if (allData.length < response.data.count) {
                    dispatch(getPracticeUserList(id, allData.length, allData));
                } else {
                    dispatch(slice.actions.getPracticeUserData({ results: allData }));
                }
            } else {
                dispatch(slice.actions.hasError(response.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPracticeUserListWithFilter(data) {
    return async () => {
        try {
            const limit = data?.limit ? `&limit=${data?.limit}` : '';
            const offset = (data?.offset === 0 || data?.offset) ? `&offset=${data?.offset}` : '';
            const role = data.role ? `&role__in=${data?.role}` : '';
            const isEnabled = data.is_enabled ? '&is_enabled=false' : '&is_enabled=true';
            const searchQuery = data.searchQuery ? `&user__first_name__icontains=${data?.searchQuery}` : '';
            const param = `${limit}${offset}${role}${isEnabled}${searchQuery}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/users/?fields={id,practice,role,user,is_enabled,notification_preferences{id,is_enabled,notification_event,notification_type}}${data ? param : ''}`);
            if (response.status === 200) {
                dispatch(slice.actions.getPracticeUserDataWithFilter(response.data));
            } else {
                dispatch(slice.actions.hasError(response.detail || 'Something went wrong'));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInviteUser(id) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await callApi(`/api/v1/practices/${id}/invitations/`);
            if (response.status === 200) {
                dispatch(slice.actions.updateInviteUserList(response.data.results));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error.message || 'Something went wrong'));
        }
    };
}

export function DeleteInviteUser(practiceId, id, callback) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/invitations/${id}/`, 'delete');
            if (response.status === 204) {
                let { inviteUser } = store.getState().practiceUser;
                inviteUser = inviteUser.filter(el => el.id !== id);
                dispatch(slice.actions.updateInviteUserList(inviteUser));
                callback({
                    success: true,
                    message: 'Invite user deleted successfully'
                });
            }
        } catch (error) {
            callback({
                success: false,
                message: error.message ? error.message : 'Something went wrong'
            });
        }
    };
}
export function patchPracticeUserData(practiceId, id, data, callback) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const { practiceUser } = store.getState().practiceUser;
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${id}/`, 'patch', data);
            if (response.status === 200) {
                callback({ success: true, message: 'User updated successfully' });
                const results = [];
                practiceUser.results.forEach(ele => {
                    if (ele.id === id) {
                        ele = response.data;
                    }
                    results.push(ele);
                });
                dispatch(slice.actions.updatePracticeUser(results));
                dispatch(getPracticeUserList(practiceId));
            } else {
                let message = '';
                if (response.data?.user) {
                    Object.entries(response.data?.user).forEach(([key, value], index) => {
                        message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
                    });
                } else {
                    Object.entries(response.data).forEach(([key, value], index) => {
                        message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
                    });
                }
                callback({
                    message,
                    success: false
                });
                dispatch(slice.actions.hasError(message));
            }
        }
        catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            callback({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(message));
        }
    };
}

export function getPracticeUserData(practiceId, id, callback) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${id}/?fields={id,practice,role,user,notification_preferences{id,is_enabled,notification_event,notification_type}}`);
            if (response.status === 200) {
                dispatch(slice.actions.practiceUserDataSlice(response.data));
            } else {
                let message = '';
                Object.entries(response.data).forEach(([key, value], index) => {
                    message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
                });
                callback({
                    message,
                    success: false
                });
                dispatch(slice.actions.hasError(message));
            }
        }
        catch (error) {
            let message = '';
            Object.entries(error).forEach(([key, value], index) => {
                message = `${message + (index === 0 ? '' : ', ')}${key}: ${value}`;
            });
            callback({
                message,
                success: false
            });
            dispatch(slice.actions.hasError(message));
        }
    };
}

export function changeUserDetails(data, id, type, handleNext) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(updatePracticeDetail(response.data));
                if (handleNext) {
                    dispatch(updateError({
                        message: handleMessage(type),
                        success: true
                    }));
                    handleNext({ success: true, message: handleMessage(type) });
                    return;
                }
                dispatch(slice.actions.updateDetail(handleMessage(type)));
            } else {
                errorHandler(response.data, handleNext, slice.actions.hasError);
            }
        }
        catch (error) {
            errorHandler(error, handleNext, updateError);
        }
    };
}

export function invitationUser(data, callback) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${data.practice}/invitations/`, 'post', data);
            if (response.status === 201) {
                callback({ message: 'User added successfully', success: true });
            } else {
                errorHandler(response.data, callback);
            }
        } catch (error) {
            errorHandler(error, callback, slice.actions.hasError);
        }
    };
}


export function notificationSetting(practiceId, id, data, type) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${id}/`, 'put', data);
            if (response.status === 200) {
                if (type !== 'onload') {
                    dispatch(updateError({ success: 'true', message: 'Notification setting updated' }));
                }
                dispatch(slice.actions.practiceUserDataSlice(response.data));
            } else {
                dispatch(updateError({ success: 'false', message: response.data.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: 'false', message: error.message || 'Something went wrong' }));
        }
    };
}

const handleMessage = (type) => {
    switch (type) {
        case 'social':
            return 'Social Link detail updated successfully';
        case 'practice':
            return 'Practice detail updated successfully';
        case 'openHours':
            return 'Opening Hours detail updated successfully';
        case 'profile':
            return 'Profile updated successfully';
        case 'practice-user':
            return 'Practice user updated successfully';
        case 'practice-branding':
            return 'Practice branding updated successfully';
        default:
            return 'Something went wrong';
    }
};

export function disableUser(practiceId, data, callback) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${data.id}/disable/`, 'put', data);
            if (response.status === 200) {
                callback({ message: 'User status disabled successfully', success: true });
                dispatch(getPracticeUserList(practiceId));
                return;
            }
            dispatch(updateError({ success: 'false', message: response.data.message || 'Something went wrong' }));
        } catch (error) {
            dispatch(updateError({ success: 'false', message: error.message || 'Something went wrong' }));
        }
    };
}

export function enableUser(practiceId, data, callback) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/users/${data.id}/enable/`, 'put', data);
            if (response.status === 200) {
                callback({ message: 'User status enabled successfully', success: true });
                dispatch(getPracticeUserList(practiceId));
                return;
            }
            dispatch(updateError({ success: 'false', message: response.data.message || 'Something went wrong' }));
        } catch (error) {
            dispatch(updateError({ success: 'false', message: error.message || 'Something went wrong' }));
        }
    };
}

export function getPracticeUserListing(data) {
    return async () => {
        try {
            const role = data.role ? `&role__in=${data?.role}` : '';
            const searchQuery = data.searchQuery ? `&user__first_name__icontains=${data?.searchQuery}` : '';
            const param = `${role}${searchQuery}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/users/?fields={id,practice,role,user,is_enabled,notification_preferences{id,is_enabled,notification_event,notification_type}}&is_enabled=true&ordering=-created_at${param}&user__is_superuser=false`);
            if (response.status === 200) {
                dispatch(slice.actions.getUserListing(response.data));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPracticeUserListingCounter(data) {
    return async () => {
        try {
            const role = data.role ? `&role__in=${data?.role}` : '';
            const searchQuery = data.searchQuery ? `&user__first_name__icontains=${data?.searchQuery}` : '';
            const param = `${role}${searchQuery}`;
            const response = await callApi(`/api/v1/practices/${data?.practiceId}/users/?ordering=-created_at${param}&fields={}&is_enabled=true&user__is_superuser=false`);
            if (response.status === 200) {
                const { practiceUserCounter } = store.getState().practiceUser;
                dispatch(slice.actions.updateCounter([...practiceUserCounter, { filterRole: data.role, count: response.data.count }]));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response.data) || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}