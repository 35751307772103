import { createSlice } from '@reduxjs/toolkit';
// utils
import { callApi } from '../../utils/apiCall';
import { updateError } from './user';
//
import { dispatch } from '../store';

// ------------------------------

const initialState = {
    isLoading: false,
    supplier: {},
    financeSupplier: {}
};

const slice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setSupplier(state, action) {
            state.supplier = action.payload;
        },
        financeSupplier(state, action) {
            state.financeSupplier = action.payload;
        }
    }
});

export default slice.reducer;

export const { setSupplier, reset, financeSupplier } = slice.actions;

export function getSupplierData(practiceId, limit, offset, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loan-suppliers/?limit=${limit}&offset=${offset}&ordering=-created_at`);
            if (response.status === 200) {
                dispatch(slice.actions.setSupplier(response.data));
                if (handleClose) {
                    handleClose(response.data.count);
                }
            } else {
                dispatch(updateError({ success: true, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function getSupplierDataOnScroll(practiceId) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loan-suppliers/?ordering=-created_at`);
            if (response.status === 200) {
                dispatch(slice.actions.financeSupplier(response.data));
            } else {
                dispatch(updateError({ success: true, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function addSupplierData(practiceId, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loan-suppliers/`, 'post', data);
            if (response.status === 201) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Loan supplier added successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: response.data.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function updateLoanData(practiceId, id, data, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loan-suppliers/${id}/`, 'patch', data);
            if (response.status === 200) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Loan supplier updated successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: response.data.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

export function deleteLoanData(practiceId, id, handleClose) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/loan-suppliers/${id}/`, 'delete');
            if (response.status === 204) {
                handleClose();
                dispatch(updateError({ success: true, message: 'Loan supplier deleted successfully' || 'Something went wrong' }));
            } else {
                dispatch(updateError({ success: false, message: response.data.message || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}





