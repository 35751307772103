export const targetContainerStyle = {
    maxHeight: '450px',
    overflow: 'auto',
    padding: '0 !important'
};
export const targetBoxStyle = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    pt: '10px',
    mb: '20px'
};
export const buttonBoxStyle = {
    display: 'none',
    justifyContent: 'right',
    alignItems: 'flex-end',
    mt: '20px'
};
export const analyticsTDStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: '600',
    paddingLeft: '15px !important'
};
export const analyticsAvatarStyle = {
    width: 40,
    height: 40,
    mr: '16px',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase'
};

export const labelStyle = {
    px: 2,
    backgroundColor: 'rgba(0, 171, 85, 0.16)',
    color: '#007B55',
};

export const rowStyle = (type) => type === 'doctor' ? {
    borderBottom: 'solid 1px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    ':hover' : {
        backgroundColor: 'rgba(145, 158, 171, 0.08)'
    }
} :
    {
        borderBottom: 'solid 1px rgba(0,0,0,0.1)'
    };