/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDebounce } from 'use-lodash-debounce';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    MenuItem,
    Typography,
    TextField,
    Autocomplete,
    Select,
    InputLabel,
    FormControl,
    Tooltip,
    Zoom,
    ClickAwayListener,
    Popper,
    Grow,
    Paper
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { grey } from 'src/theme/palette';
// components
import { Iconify } from '../../../components';
import { RHFSelect, FormProvider, RHFTextField } from '../../../components/hook-form';
import { SearchNotFound } from '../../../components/search-not-found';
import { AssignUserAvatar } from '../../../components/custom-avatar';
import { AssignTaskPopup } from '../../../components/dialogPopUp';
import { useAssignTaskToggle } from '../../../hooks';
// constants
import { taskGroupConst } from '../../../constants/AddTaskReminder';
// store
import {
    addReminder,
    editReminder,
    getLeadReminderData,
    leadTaskListCounter,
    updateLeadTaskCounter,
    searchLead,
    clearTaskLead
} from '../../../redux/slices/lead';
import { useSelector, dispatch } from '../../../redux/store';
import { clearPracticeUserData } from '../../../redux/slices/practiceUser';
import { AddReminderSchema } from '../../../validations/validations';
import { getAssignees } from '../../../utils/multiAsignees';
// style
import {
    completeBox,
    completeTypo,
    methodMAinBox,
    leadAutoComplete,
    taskGroupStyle,
    addReminderHeaderBoxStyle
} from '../../../styles/AddReminderStyle';
import {
    taskUserAvatar,
    openTask,
    assignTaskHandlerStyle,
    assignUserAvatarStyle,
    assignLengthStyle,
    nameFormControlStyle,
    addSelectedReminderBoxStyle,
    methodFormControlStyle,
    selectControllerStyle,
    actionSelectStyle,
    addReminderDateTimePickerStyle
} from '../../../styles/leadTaskReminder';

// ------------------------------------------------------------------------------------------------------------------

const AddReminder = forwardRef(({ handleNext, leadDetail, type, leadData, reload, reloadTaskCounter, page, handleLoading }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const { enqueueSnackbar } = useSnackbar();

    const [markButton, setMarkButton] = useState(false);
    const [reminderError, setReminderError] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedBox, setSelectedBox] = useState('');
    const [assignToState, setAssignToState] = useState([]);
    const [leadTreatments, setLeadTreatments] = useState([]);

    const debouncedValue = useDebounce(searchQuery, 1000);

    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const {
        taskTableData: {
            limit,
            offset,
            filter,
            assignUser
        },
        taskSearchLead,
        isLoading,
        leadDetailTaskPageFilter
    } = useSelector((state) => state.lead);
    const { reminderPriority, reminderMethod } = useSelector((state) => state.schema);
    const [anchorElAssign, setAnchorElAssign] = useState(null);
    const [error, setError] = useState('');
    const [assignToStateLength, setAssignToStateLength] = useState(0);

    const getDate = (date) => moment(date, 'DD/MM/YYYY HH:mm:ss');
    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && !el.user.is_superuser);

    const assigneesData = leadData?.assignees?.filter((el) => el?.user?.is_superuser === false);

    const defaultValues = {
        reminder: type === 'editTask' ? (leadData?.method)?.toUpperCase() : '',
        leadName: type === 'editTask' ? leadData?.lead && `${leadData.lead?.first_name} ${leadData?.lead?.last_name}` || '' : leadDetail && leadDetail?.first_name && `${leadDetail?.first_name} ${leadDetail?.last_name} ` || '',
        relation: type === 'editTask' ? (leadData?.lead_treatment?.id) || '' : leadDetail?.lead_treatments?.length > 0 && leadDetail?.lead_treatments[0]?.id || '',
        dateTime: type === 'editTask' ? leadData && new Date(getDate(leadData?.datetime)) : '',
        assignTo: type === 'editTask' ? (leadData && assigneesData?.length > 0) && assigneesData?.map((ele) => `${ele?.user?.salutation || ''} ${ele?.user?.first_name} ${ele?.user?.last_name}`) || [] : [],
        taskNotes: type === 'editTask' ? leadData && leadData?.note || '' : '',
        taskGroup: type === 'editTask' ? leadData && leadData?.group || '' : '',
        lead_id: page === 'LeadDetail' ? leadDetail?.id : leadData?.lead?.id,
        isCompleted: false,
        isAssign: false,
        priority: ''
    };

    useEffect(() => {
        setAssignToStateLength(assignToState?.length || 0);
        if (assignToState?.length > 0) {
            setError('');
        }
    }, [assignToState]);

    const methods = useForm({
        resolver: yupResolver(AddReminderSchema),
        mode: 'onChange',
        defaultValues
    });

    const {
        control,
    } = methods;

    const {
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors }
    } = methods;
    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                if (assignToStateLength === 0) {
                    setError('This field is required');
                    showRequiredFieldSnackbar();
                } else {
                    setError('');
                }
                await handleSubmit(onSubmit)();
            },
        }),
        [assignToStateLength]
    );

    useEffect(() => {
        if (practiceUser) {
            const data = getUserList().filter((el) => leadData && leadData?.assignees?.some((ele) => ele.user?.first_name === el.user.first_name) && leadData?.assignees?.some((ele) => ele.user?.last_name === el.user.last_name));
            setValue('isAssign', data.length > 0);
        }
    }, [practiceUser]);

    useEffect(() => {
        if (type === 'editTask' && leadData) {
            setSearchQuery(`${leadData?.lead?.first_name} ${leadData?.lead?.last_name}`);
            setSelectedBox(leadData?.method);
            setValue('assignTo', leadData?.assignees?.map((ele) => `${ele?.user?.salutation || ''} ${ele?.user?.first_name} ${ele?.user?.last_name}`) || []);
            setAssignToState(assigneesData?.length > 0 ? assigneesData?.map((ele) => `${ele?.user?.salutation || ''} ${ele?.user?.first_name} ${ele?.user?.last_name}`) : []);
            if (leadData?.lead_treatment) {
                setLeadTreatments([{ treatment: leadData?.lead_treatment?.treatment, id: leadData?.lead_treatment?.id }]);
            }
            setValue('relation', leadData?.lead_treatment?.id);
        }
        if (page === 'LeadDetail') {
            setSelectedBox(leadData?.method);
            setSearchQuery(`${leadDetail?.first_name} ${leadDetail?.last_name}`);
            if (leadDetail?.lead_treatments?.length > 0) {
                const leadTreatment = [];
                leadDetail?.lead_treatments?.map((el) => leadTreatment.push({ treatment: el?.treatment, id: el?.id }));
                setLeadTreatments(leadTreatment);
            }
        }
    }, [leadData, leadDetail]);

    const showRequiredFieldSnackbar = () => {
        enqueueSnackbar('Please fill required field', {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    const onSubmit = (data) => {
        if (assignToState?.length === 0) {
            setError('This field is required');
            showRequiredFieldSnackbar();
            return;
        }
        if (!data?.reminder) {
            setReminderError(true);
            return;
        }
        const reminderData = {
            method: (data.reminder).toUpperCase(),
            datetime: `${moment(data.dateTime).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            note: data.taskNotes,
            assignees: assignToState?.map((el) => el?.id),
            is_completed: data.isCompleted,
            group: 'TODO',
            lead: data?.lead_id || '',
            lead_treatment: Number(data?.relation),
            priority: data.priority
        };
        if (type === 'addTask') {
            dispatch(addReminder(reminderData, practiceId, handleSuccess, 'LeadDetail'));
            if (page === 'LeadDetail' && handleLoading) {
                handleLoading('start');
            }
        }
        if (type === 'editTask') {
            dispatch(editReminder(reminderData, leadData.id, practiceId, handleSuccess));
            if (type === 'editTask' && handleLoading) {
                handleLoading('start');
            }
        }
    };

    const handleSuccess = (data) => {
        if (type === 'editTask') {
            enqueueSnackbar(data.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
        if (reload) {
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', limit, offset, filter, leadDetail.id, assignUser, '', 'leadNotes', 'counter', leadDetailTaskPageFilter));
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan', '', leadDetailTaskPageFilter));
        }
        if (reloadTaskCounter) {
            dispatch(updateLeadTaskCounter([]));
            dispatch(leadTaskListCounter(practiceId, 'LeadDetail', leadDetail?.lead_treatments[0]?.status, leadDetail?.id, '', leadDetailTaskPageFilter));
            dispatch(leadTaskListCounter(practiceId, 'LeadDetail', '', leadDetail?.id, '', leadDetailTaskPageFilter));
            dispatch(leadTaskListCounter(practiceId, 'LeadDetail', 'TODO', leadDetail?.id, '', leadDetailTaskPageFilter));
        }
        handleNext(true);
        if ((page === 'LeadDetail' || type === 'editTask') && handleLoading) {
            setTimeout(() => handleLoading(), 5000);
        }
    };

    const markButtonHandler = () => {
        setMarkButton(!markButton);
        setValue('isCompleted', !markButton);
    };

    const noOptionsHandler = () => {
        if (searchQuery === '' || taskSearchLead && taskSearchLead.length === 0) {
            return <SearchNotFound searchQuery={searchQuery} />;
        }
        return '';
    };

    useEffect(() => {
        if (searchQuery?.length === 0) {
            dispatch(clearTaskLead());
        }
    }, [taskSearchLead]);

    useEffect(() => {
        if (searchQuery.length > 0) {
            let search = searchQuery;
            if (Number(searchQuery) && !searchQuery.includes('+')) {
                search = `+44${searchQuery}`;
            }
            dispatch(searchLead(encodeURIComponent(search), practiceId, 'taskLead'));
        }
    }, [debouncedValue]);

    const handleSearchLead = (value) => {
        if (value) {
            setValue('leadName', `${value?.first_name} ${value?.last_name}`);
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            setValue('lead_id', value?.id);
        }
        clearErrors('leadName');
        const leadArr = [];
        value?.lead_treatments?.map((el) => leadArr.push({ treatment: el?.treatment, id: el?.id }));
        if (leadArr?.length > 0) {
            setLeadTreatments(leadArr);
        }
    };

    const searchLeadHandler = (value, reason) => {
        if (type === 'addTask' && reason === 'input') {
            setSearchQuery(value);
        }
        if (value.length === 0) {
            dispatch(clearTaskLead());
        }
    };

    const handleReminder = (event) => {
        setSelectedBox(event.target.value);
        setValue('reminder', event.target.value);
        setReminderError(false);
        clearErrors('reminder');
    };

    const handleTreatments = (e) => {
        setValue('relation', e?.target?.value);
        clearErrors('relation');
    };

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const assignTaskHandler = (event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%', pt: '0', textAlign: 'left' }}>
                <Box sx={addReminderHeaderBoxStyle}>
                    <Typography variant='h5' sx={{ fontSize: '18px !important' }}>
                        {type === 'addTask' ? 'New task' : 'Edit task'}
                    </Typography>
                    {type === 'editTask' &&
                        <Box onClick={markButtonHandler} sx={completeBox(markButton)}>
                            {markButton && <Iconify icon={'twemoji:check-mark'} width={16} height={12} sx={{ 'path': { fill: '#54D62C' } }} />}
                            <Typography variant='h5' sx={completeTypo(markButton, isLight)}>
                                {markButton ? 'Completed' : 'Mark As Complete'}
                            </Typography>
                        </Box>}
                    <Box onClick={() => handleNext(true)} sx={{ lineHeight: 0, cursor: 'pointer' }}>
                        <Iconify icon={'ci:close-big'} width={26} height={26} sx={{ color: 'common.grey' }} />
                    </Box>
                </Box>
                <Box sx={{ p: '0 24px', overflow: 'auto', maxHeight: 'calc(100vh - 270px)' }}>
                    <Box sx={methodMAinBox(reminderError)}>
                        <FormControl variant="standard" sx={methodFormControlStyle}>
                            <Box sx={addSelectedReminderBoxStyle}>
                                <Iconify icon={reminderMethod?.find(reminder => reminder.value === selectedBox)?.icon || 'mdi:target'} width={24} height={24} sx={{ color: selectedBox ? 'primary.dark' : 'common.grey' }} />
                            </Box>
                            <InputLabel id="demo-simple-select-standard-label" sx={{ fontSize: '16px' }}>Trigger an action</InputLabel>
                            <Controller
                                name="reminder"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        label="Trigger an action"
                                        sx={actionSelectStyle}
                                        error={!!errors.reminder}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleReminder(e);
                                        }}
                                    >
                                        {reminderMethod?.map((reminder) => (
                                            <MenuItem key={reminder.value} value={reminder.value}>
                                                <Typography variant="inherit">{reminder.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.reminder && (
                                <Typography variant="caption" sx={{ color: 'error.main', mt: 1 }}>
                                    {errors.reminder.message}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl variant="standard" sx={nameFormControlStyle}>
                            <Box sx={addSelectedReminderBoxStyle}>
                                <Iconify icon={'lets-icons:user'} width={24} height={24} sx={{ color: 'common.grey' }} />
                            </Box>
                            <Controller
                                name={'leadName'}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field}
                                        name={'leadName'}
                                        inputValue={searchQuery}
                                        value={searchQuery}
                                        clearIcon={false}
                                        readOnly={type === 'editTask' || page === 'LeadDetail'}
                                        options={taskSearchLead && taskSearchLead?.length > 0 ? taskSearchLead : []}
                                        filterOptions={(option) => option}
                                        loading={isLoading}
                                        sx={leadAutoComplete}
                                        onInputChange={(event, value, reason) => searchLeadHandler(value, reason)}
                                        noOptionsText={noOptionsHandler()}
                                        onChange={(e, value) => handleSearchLead(value)}
                                        isOptionEqualToValue={(option, value) => option?.first_name === value}
                                        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                                        renderOption={(props, option) => {
                                            const { first_name: firstName, last_name: lastName, lead_contact: leadContact } = option;
                                            const { email, phone } = leadContact || {};
                                            return (
                                                <Box component="li" {...props} key={option.id} >
                                                    {firstName || ''} {lastName || ''} | {email || ''} | {phone || ''}
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={!!error}
                                                labelId="demo-patient-select-standard-label"
                                                label="Patient"
                                                variant="standard"
                                                helperText={(searchQuery?.length === 0 && error) && 'This field is required'}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <FormControl variant="standard" sx={methodFormControlStyle}>
                            <Box sx={addSelectedReminderBoxStyle}>
                                <Iconify icon={'lets-icons:tooth'} width={24} height={24} sx={{ color: 'common.grey' }} />
                            </Box>
                            <InputLabel id="demo-treatment-select-standard-label" sx={{ fontSize: '16px' }}>Treatment</InputLabel>
                            <Controller
                                name="relation"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        labelId="demo-treatment-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name='relation'
                                        label='Treatment'
                                        sx={selectControllerStyle}
                                        error={!!errors.relation}
                                        onChange={(e, value) => handleTreatments(e, value)}
                                    >
                                        {leadTreatments.map((options, index) => (
                                            <MenuItem
                                                key={index}
                                                value={options?.id}
                                                sx={taskGroupStyle}
                                            >
                                                {options?.treatment}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.relation && (
                                <Typography variant="caption" sx={{ color: 'error.main', mt: 1 }}>
                                    {errors.relation.message}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <FormControl variant="standard" sx={nameFormControlStyle}>
                            <Box sx={addSelectedReminderBoxStyle}>
                                <Iconify icon={'majesticons:calendar'} width={24} height={24} sx={{ color: 'common.grey' }} />
                            </Box>
                            <Controller
                                name='dateTime'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        <DateTimePicker
                                            orientation="landscape"
                                            ampm={false}
                                            label='Due date'
                                            value={field.value || null}
                                            minDateTime={new Date()}
                                            format="dd/MM/yyyy HH:mm"
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            sx={addReminderDateTimePickerStyle}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    variant: 'standard',
                                                    error: !!error,
                                                    helperText: error?.message,
                                                    onKeyDown: (e) => e.preventDefault()
                                                },
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <FormControl variant="standard" sx={methodFormControlStyle}>
                            <Box sx={addSelectedReminderBoxStyle}>
                                <Iconify icon={'ic:outline-flag'} width={24} height={24} sx={{ color: 'common.grey' }} />
                            </Box>
                            <InputLabel id="demo-priority-select-standard-label" sx={{ fontSize: '16px' }}>Priority</InputLabel>
                            <Controller
                                name="priority"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        labelId="demo-priority-select-standard-label"
                                        id="demo-simple-select-standard"
                                        label="Priority"
                                        sx={selectControllerStyle}
                                        error={!!errors.priority}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    >
                                        {reminderPriority?.map((reminder) => (
                                            <MenuItem key={reminder.value} value={reminder.value}>
                                                <Typography variant="inherit">{reminder.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.priority && (
                                <Typography variant="caption" sx={{ color: 'error.main', mt: 1 }}>
                                    {errors.priority.message}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                    <RHFSelect
                        name='taskGroup'
                        label='Task group'
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                        sx={{ marginBottom: '25px', display: 'none' }}
                    >
                        {taskGroupConst.map((options, index) => (
                            <MenuItem
                                key={index}
                                value={options?.value}
                                sx={taskGroupStyle}
                            >
                                {options?.label}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <Box sx={{ display: 'block', mb: 2 }}>
                        <RHFTextField sx={{ width: '100%', bgcolor: '#919EAB14', borderRadius: 1 }} name='taskNotes' multiline rows={3} label='Details' placeholder='Add notes here' />
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                        <Box sx={{ cursor: 'pointer' }}>
                            {assignToState?.length > 0 ? (
                                <Box sx={taskUserAvatar}>
                                    <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                        <Box sx={{ display: 'flex' }}>
                                            {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                <AssignUserAvatar
                                                    key={index}
                                                    sx={assignUserAvatarStyle}
                                                    src={assignee.user?.avatar || ''}
                                                    name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                    role={assignee.role}
                                                    onClick={(e) => assignTaskHandler(e)}
                                                />
                                            ))}
                                            {assignToState?.length > 2 && (
                                                <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                    + {assignToState?.length - 2}
                                                </Box>
                                            )}
                                        </Box>
                                    </Tooltip>
                                </Box>
                            ) : (
                                <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                    <Box sx={{ ...assignTaskHandlerStyle, bgcolor: grey[200] }} onClick={(e) => assignTaskHandler(e)}>
                                        <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        {error && <Typography sx={{ color: 'error.main', fontSize: '0.75rem', mt: 1 }}>{error}</Typography>}
                    </Box>
                </Box>
            </Box>
            {openAssignTask && (
                <ClickAwayListener onClickAway={onClickAwayEvent}>
                    <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement="bottom" sx={{ zIndex: '999999' }}>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper sx={{ borderRadius: 2 }}>
                                    <AssignTaskPopup
                                        open={openAssignTask}
                                        onClose={() => { onCloseAssignTask(); setAnchorElAssign(null); dispatch(clearPracticeUserData()); }}
                                        leadDetail={leadDetail}
                                        type={'AddReminder'}
                                        setAssignTo={setAssignToState}
                                        assignees={assignToState?.map((ele) => ele.id)}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
        </FormProvider>
    );
});

AddReminder.propTypes = {
    handleNext: PropTypes.func,
    leadDetail: PropTypes.any,
    type: PropTypes.string,
    leadData: PropTypes.any,
    reload: PropTypes.bool,
    reloadTaskCounter: PropTypes.bool,
    page: PropTypes.string,
    handleLoading: PropTypes.func
};

export default AddReminder;