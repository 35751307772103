import PropTypes from 'prop-types';
// @mui
import { Box, Typography, useTheme } from '@mui/material';
import { grey } from 'src/theme/palette';
import { noDataTypoStyle } from '../../../styles/CampaignPageStyle';

const CampaignNoData = ({ isNotFound, type }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    return (
        <Box sx={{ width: '100%' }}>
            {isNotFound && (
                <Box sx={{ bgcolor: isLight ? grey[200] : 'rgba(145, 158, 171, 0.12)', borderRadius: '8px', border: '1px dashed rgba(145, 158, 171, 0.32)' }}>
                    <Box sx={{ display: 'flex', padding: '0 20px', flexDirection: 'column' }}>
                        <img src='/assets/no-script.png' alt='no-appointment' style={{ maxHeight: '160px', objectFit: 'contain' }} />
                        <Typography sx={noDataTypoStyle(isLight)}> {type === 'campaignReport' ? 'No data' : 'No campaigns'}</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

CampaignNoData.propTypes = {
    isNotFound: PropTypes.bool,
    type: PropTypes.string
};

export default CampaignNoData;