export const boxStyle = (type) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: '10px',
    // mb: '30px',
    flexWrap: 'wrap',
    pl: type !== 'Treatment Summary' && '24px',
    pr: type !== 'Treatment Summary' && '24px'
});



export const timeRageBoxStyle = {
    '@media(max-width: 900px)': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        mt: '20px'
    }
};

export const OuterboxStyle = () => ({
    width: '50%',
    display: 'flex',
    '@media(max-width: 900px)': {
        width: '100%',
        display: 'flex',
    }
});

export const InnerboxStyle = () => ({
    width: '50%',
    '@media(max-width: 900px)': {
        width: '100%',
    }
});
