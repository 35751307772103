/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { dispatch } from 'src/redux/store';
import { editTreatmentPayment } from 'src/redux/slices/deposit';
import { followUpSlice } from 'src/redux/slices/lead';
import { getPracticeId } from 'src/redux/slices/clincheck';

const DepositDateCell = ({ rowData, handleSetTableData }) => {
    const practiceId = getPracticeId();

    const dateString = rowData?.deposit_payment_data?.length > 0
        ? rowData?.deposit_payment_data?.[0]?.paid_at : null;
    const initialDate = dateString ? moment(dateString, 'DD/MM/YYYY').toDate() : null;
    const [dateValue, setDateValue] = useState(initialDate);

    useEffect(() => {
        if (rowData?.deposit_payment_data?.[0]?.paid_at) {
            const dateString = rowData?.deposit_payment_data?.[0]?.paid_at;
            const initialDate = dateString ? moment(dateString, 'DD/MM/YYYY').toDate() : null;
            setDateValue(initialDate);
        }
    }, [rowData?.deposit_payment_data?.[0]?.paid_at]);

    const handleSuccessEditTreatmentPayment = (payload) => {
        if (rowData?.deposit_payment_data?.length > 1) {
            dispatch(editTreatmentPayment({
                ...payload,
                paymentId: rowData?.deposit_payment_data?.[1]?.id,
                handleSuccess
            }));
            return;
        }
        handleSuccess();
    };

    const onChangeDate = (newValue) => {
        setDateValue(newValue);
        const treatmentId = rowData?.deposit_payment_data?.[0]?.treatment;
        const leadId = rowData?.lead_id;
        const payload = {
            body: { paid_at: moment(newValue).format('YYYY-MM-DD HH:mm:ss') || null },
            treatmentId,
            leadId,
        };
        dispatch(editTreatmentPayment({
            ...payload,
            paymentId: rowData?.deposit_payment_data?.[0]?.id,
            handleSuccess: () => handleSuccessEditTreatmentPayment(payload)
        }));
    };

    const handleSuccess = () => dispatch(followUpSlice(rowData?.lead_id, practiceId, 'LeadDetail', handleClose));

    const handleClose = (data) => handleSetTableData(data);

    return (
        <Box>
            <DatePicker
                label='Date'
                value={dateValue}
                format={'dd/MM/yyyy'}
                onChange={onChangeDate}
                disabled={rowData?.deposit_payment_data?.length === 0}
                slotProps={{
                    textField: {
                        fullWidth: true
                    },
                }}
            />
        </Box>
    );
};

export default DepositDateCell;

DepositDateCell.propTypes = {
    rowData: PropTypes.array,
    handleSetTableData: PropTypes.func,
};