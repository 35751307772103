import { Box, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { communicationTabStyle, draftsDotsCss, incomingDotsCss, labelDotsCss, listItemAvatarStyle, outgoingDotsCss, starredDotsCss } from 'src/styles/Chat';
import { getPrimaryText } from '../../../utils/emailTabTitle';

const RenderTabItem = ({currentTab, tabTitle, count, handleTabClicked}) =>
{
    const theme = useTheme(); 

    const renderStyle = (type) => {
        switch (type) {
            case 'Incoming':
                return incomingDotsCss(currentTab, theme);
            case 'Outgoing':
                return outgoingDotsCss(currentTab, theme);
            case 'Drafts':
                return draftsDotsCss(currentTab, theme);
            case 'Labels':
                return labelDotsCss(currentTab, theme);
            case 'Starred':
                return starredDotsCss(currentTab, theme);
            default:
                return '';
        }
    };

   
    return (
        <ListItem
            disableRipple
            button
            selected={currentTab === tabTitle}
            onClick={(e) => {
                handleTabClicked(e, tabTitle);
            }}
            sx={{ ...communicationTabStyle, bgcolor: 'transparent !important' }}
        >
            <Box sx={{ display: 'flex', width: '100%' }}>
                <ListItemAvatar sx={listItemAvatarStyle}>
                    <Box sx={{ lineHeight: 0 }}>
                        <span style={renderStyle(tabTitle)} />
                    </Box>
                </ListItemAvatar>
                {(
                    <>
                        <ListItemText
                            primary={getPrimaryText(tabTitle)}
                            primaryTypographyProps={{
                                noWrap: true,
                                variant: 'subtitle2',
                            }}
                            sx={{
                                textTransform: 'capitalize',
                                textAlign: 'left',
                                color: currentTab === tabTitle ? theme.palette.grey[800] : theme.palette.grey[600]
                            }}
                        />
                    </>
                )}
            </Box>
            {tabTitle !== 'Outgoing' && count !== 0 && <Typography sx={{ fontSize: '12px', fontWeight: '700', color: theme.palette.grey[600] }}> {count} </Typography>}
        </ListItem>
    );
};
    

RenderTabItem.propTypes = {
    currentTab : PropTypes.object, 
    tabTitle: PropTypes.any,
    count : PropTypes.any,
    handleTabClicked: PropTypes.any,
};
export default RenderTabItem;