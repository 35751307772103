/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
// libraries
import { Box, Typography, Grid, Card, Divider, MenuItem, ButtonGroup, Button } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { isEmpty } from 'lodash';
import { AppointmentTabStyle, followUpButtonGroupStyle, followUpCustomButtonStyle, followUpHourButtonStyle, followUpNoReminderButtonStyle, followUpTomorrowButtonStyle, followUpTwoWeekButtonStyle, followUpWeekButtonStyle, selectMenuItemStyle } from 'src/styles/DialogueTabs';
// components
import { btnHandler } from 'src/utils/reminderSelectedTimeFunc';
import AssignedToLead from 'src/components/AssignedToLead';
import { Iconify, UserSearchDropdown } from '../../../../components';
import { FormProvider, RHFRadioGroup, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import TreatmentEditPopover from '../../../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import TreatmentFlowComponent from '../../../../components/treatmentComponents/TreatmentFlowComponent';
import RHFToggleGroup from './RHFToggleGroup';
// store
import { useSelector, dispatch } from '../../../../redux/store';
import { addAppointment, UpdateAppointment, updateSteps, saveNewLeadDetail, updateCompleteStatus, savePersonalLeadDetails, updateSendReminderDuringLeadCreate } from '../../../../redux/slices/lead';
// hooks/validation
import { useAddTreatment, useResponsive, useTreatmentAndAppointment } from '../../../../hooks';
import { TreatmentAndAppointmentSchema } from '../../../../validations/validations';
import { localStorageGetItem, localStorageSetItem } from '../../../../utils/storage-available';
// style
import { gridStyle, appointmentGridStyle, boxStyle } from '../../../../styles/TreatmentAndAppointmentStyle';


const TreatmentAndAppointment = forwardRef(({ isOverview, handleNext, type }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { newLeadDetail, tableRowLead } = useSelector((state) => state.lead);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { contactMethod, appointmentType, appointmentMethod } = useSelector((state) => state.schema);
    const [treatmentData, setTreatment] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { getTreatment, getAssignUser, getType, handleSuccessMessage } = useTreatmentAndAppointment({ isOverview, type });
    const [assignToState, setAssignToState] = useState(newLeadDetail?.lead_appointment && getAssignUser() || []);
    const [assignToLeadState, setAssignToLeadState] = useState([]);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const [priceAmountError, setPriceAmountError] = useState(false);
    const [teethAmountError, setTeethAmountError] = useState(false);
    const localTreatmentData = localStorageGetItem('localTreatmentData') ? JSON.parse(localStorageGetItem('localTreatmentData')) || {} : {};

    const { toggle: EditTreatment, onOpen: onEditTreatment, onClose: onCloseEditTreatment } = useAddTreatment();

    const getDate = (date) => moment(date, 'DD/MM/YYYY HH:mm:ss');

    const [amount, setAmount] = useState(getTreatment('price') || 0.00);

    const isMobile = useResponsive('up', 'sm');
    const [activeTime, setActiveTime] = useState('custom reminder');
    const [isOpen, setIsOpen] = useState(false);
    const [assignToStateNoBook, setAssignToStateNoBook] = useState([]);
    const { reminderMethod } = useSelector((state) => state.schema);

    const defaultValues = {
        treatment: getTreatment('treatment'),
        treatmentValue: amount,
        teethAmount: getTreatment('teeth') || null,
        radioButton: getType(type),
        appoinmentDate: newLeadDetail && newLeadDetail.lead_appointment && new Date(getDate(newLeadDetail.lead_appointment.datetime)) || null,
        bookedWith: newLeadDetail?.lead_appointment && getAssignUser() || [],
        practiceContactedVia: newLeadDetail && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.contacted_via || 'PHONE',
        preferredContactMethod: newLeadDetail && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.method || 'IN_PRACTICE',
        appointmentType: newLeadDetail && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.type || 'CONSULTATION',
        reminderDateTime: '',
        OtherNotes: '',
        reminder: '',
        assigneesNoBook: []
    };

    useEffect(() => {
        if (defaultValues.treatment) {
            setTreatment(defaultValues.treatment);
        }
    }, [defaultValues.treatment]);

    const methods = useForm({
        resolver: yupResolver(TreatmentAndAppointmentSchema(assignToState, activeTime)),
        mode: 'onSubmit',
        defaultValues,
    });

    const { control, setValue, clearErrors, watch, getValues, formState: { errors }, handleSubmit, trigger } = methods;

    useEffect(() => {
        if (!isEmpty(localTreatmentData)) {
            if (localTreatmentData.treatment) {
                setTreatment(localTreatmentData.treatment);
                setValue('treatment', localTreatmentData.treatment);
                setValue('teethAmount', localTreatmentData.teethAmount);
            }
            if (localTreatmentData.price) {
                setAmount(localTreatmentData.price);
            }
        }
    }, []);

    useEffect(() => {
        if (assignToState?.length > 0) {
            trigger('bookedWith');
        }
    }, [assignToState, trigger]);

    useEffect(() => {

        if (assignToStateNoBook?.length > 0) {
            setValue('assigneesNoBook', assignToStateNoBook)
        }
    }, [assignToStateNoBook]);

    const values = watch();

    const practiceUserId = (e) => e ? practiceUser?.results?.filter(el => e?.includes(`${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`))?.map((el) => el?.id) : [];

    React.useEffect(() => {
        let finalArray = [];
        if (assignToLeadState.length > 0) {
            finalArray = assignToLeadState?.map(e => e?.id);
        }
        dispatch(savePersonalLeadDetails({ assignees: finalArray }));
    }, [assignToLeadState])

    const onSubmit = (data, status) => {
        
        if (data.radioButton === '') {
            enqueueSnackbar('Please select one of the option for appointment', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }
        const detail = {};
        detail.addAppointment = false;
        detail.lead_treatment = {
            treatment: data.treatment,
            custom_price: amount,
            teethAmount: data.teethAmount
        };
        if (data.radioButton === 'Yes') {
            detail.addAppointment = true;
            detail.lead_appointment = {
                datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                method: data.preferredContactMethod,
                contacted_via: data.practiceContactedVia,
                assignees: assignToState?.map((el) => el?.id),
                type: data?.appointmentType
            };
        }
        if (data.radioButton === 'No') {
            if (activeTime !== '' && activeTime !== 'no-reminder') {
                const addReminderObj = {
                    is_completed: false,
                    assignees: data?.assigneesNoBook?.map((el) => el?.id),
                    group: 'FOLLOW_UP',
                    note: data.OtherNotes,
                };
                if (data?.reminderDateTime) {
                    addReminderObj.datetime = `${moment(data.reminderDateTime).format('DD-MM-YYYY HH:mm:ss')}.0Z`;
                }
                if (data?.reminder) {
                    addReminderObj.method = data.reminder?.toUpperCase();
                }
                detail.lead_reminder = addReminderObj
                dispatch(updateSendReminderDuringLeadCreate(true));
            }
        }

        dispatch(saveNewLeadDetail(detail));
        if (status === 'finish') {
            dispatch(updateCompleteStatus(true));
            return;
        }
        handleNext(true);
    };

    const handleSuccess = (data) => {
        if (data && data.appoiment === 'Yes') {
            if (data.treatmentId) {
                callAppointment(data.treatmentId);
                return;
            }
            if (type !== 'followUp') {
                handleSuccessMessage({ data });
                handleNext(true);
                return;
            }
        }
        handleSuccessMessage({ data });
        handleNext(true);
    };

    const callAppointment = (treatmentId) => {
        const contactedVia = getValues('practiceContactedVia');
        if (treatmentId && type === 'followUp') {
            if (newLeadDetail && newLeadDetail.lead_appointment) {
                if (moment(getDate(newLeadDetail.lead_appointment.datetime)).format('DD-MM-YYYY HH:mm:ss') !== moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss') ||
                    newLeadDetail.lead_appointment.method !== getValues('preferredContactMethod') ||
                    newLeadDetail.lead_appointment.contacted_via !== getValues('practiceContactedVia')) {
                    const updateAppointment = {
                        lead_treatment: treatmentId,
                        datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                        method: getValues('preferredContactMethod'),
                        contacted_via: contactedVia[0],
                        assignees: practiceUserId(getValues('bookedWith')),
                        type: getValues('appointmentType')
                    };
                    updateAppointment.lead = tableRowLead.lead_id;
                    updateAppointment.id = newLeadDetail.lead_appointment.id;
                    dispatch(UpdateAppointment(updateAppointment, practiceId, handleSuccess));
                }
                else {
                    handleNext(true);
                }
            }
            else {
                const followUpAppointment = {
                    lead_treatment: treatmentId,
                    datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                    method: getValues('preferredContactMethod'),
                    contacted_via: contactedVia[0],
                    assignees: practiceUserId(getValues('bookedWith')),
                    type: getValues('appointmentType')
                };
                dispatch(addAppointment(followUpAppointment, practiceId, tableRowLead.lead_id, handleSuccess));
            }
        }
        else if (treatmentId) {
            if (newLeadDetail && newLeadDetail.lead_appointment) {
                if (moment(getDate(newLeadDetail.lead_appointment.datetime)).format('DD-MM-YYYY HH:mm:ss') !== moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss') ||
                    !getValues('bookedWith')?.includes(newLeadDetail.lead_appointment.note) ||
                    newLeadDetail.lead_appointment.method !== getValues('preferredContactMethod') ||
                    newLeadDetail.lead_appointment.contacted_via !== getValues('practiceContactedVia')) {
                    const updateAppointment = {
                        lead_treatment: treatmentId,
                        datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                        method: getValues('preferredContactMethod'),
                        contacted_via: contactedVia[0],
                        assignees: practiceUserId(getValues('bookedWith')),
                        type: getValues('appointmentType')
                    };
                    updateAppointment.lead = newLeadDetail.lead_treatment.lead;
                    updateAppointment.id = newLeadDetail.lead_appointment.id;
                    dispatch(UpdateAppointment(updateAppointment, practiceId, handleSuccess));
                }
                else {
                    handleNext(true);
                }
            }
            else {
                const appointmentDetail = {
                    lead_treatment: treatmentId,
                    datetime: `${moment(getValues('appoinmentDate')).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                    method: getValues('preferredContactMethod'),
                    contacted_via: contactedVia[0],
                    assignees: practiceUserId(getValues('bookedWith')),
                    type: getValues('appointmentType')
                };
                dispatch(addAppointment(appointmentDetail, practiceId, newLeadDetail.id, handleSuccess));
            }
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status), formErrorHandler)();
            }
        }),
        [amount, assignToState]
    );

    const radioHandler = (e) => {
        switch (e.target.value) {
            case 'Yes':
                setValue('radioButton', e.target.value);
                if (getValues('appoinmentDate') !== null) {
                    updateNewStep(getValues('appoinmentDate'));
                }
                break;
            case 'No':
                setValue('radioButton', e.target.value);
                dispatch(updateSteps(null));
                break;
            default:
                break;
        }
    };

    const updateNewStep = (value) => {
        const attaendence = moment(value).format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss');
        dispatch(updateSteps(attaendence ? 'payment' : 'attendance'));
    };

    useEffect(() => {
        if (newLeadDetail) {
            if (getValues('appoinmentDate') && newLeadDetail.lead_appointment && newLeadDetail.lead_appointment.datetime) {
                const date = getDate(newLeadDetail.lead_appointment.datetime);
                updateNewStep(date);
            }
        }
    }, [newLeadDetail]);

    const handleClickAwayLister = () => onCloseEditTreatment();

    const handleSaveTreatment = (data) => {
        setTreatment(data.selectedTreatment);
        setAmount(data.treatmentPrice);
        setValue('treatment', data.selectedTreatment);
        setValue('teethAmount', data.teethAmount);
        localStorageSetItem('localTreatmentData', JSON.stringify({ treatment: data.selectedTreatment, teethAmount: data.teethAmount, price: data.treatmentPrice }));
        onCloseEditTreatment();
    };

    const handleTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const formErrorHandler = () => {
        if (type !== 'followUp') return;
        enqueueSnackbar('Required field is missing', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
    };

    const handleSetAssign = (values) => {
        setAssignToState(values);
        setValue('bookedWith', values);
    };

    const amountHandleChange = (value) => {
        setAmount(value);
        localStorageSetItem('localTreatmentData', JSON.stringify({ treatment: treatmentData, teethAmount: values.teethAmount, price: value }));
        if (value > 0) {
            setPriceAmountError(false);
            document.body.classList.remove('points-event');
            return;
        }
        setPriceAmountError(true);
    };

    const handleChangeTeeth = (e) => {
        const value = e.target.value > 0 ? e.target.value : '';
        setValue('teethAmount', value);
        localStorageSetItem('localTreatmentData', JSON.stringify({ treatment: treatmentData, teethAmount: value, price: amount }));
        setTeethAmountError(value === 0);
    };

    const handleChangeDate = ({ newValue, field }) => {
        field.onChange(newValue);
        updateNewStep(newValue);
    };


    const handleButtonClick = (value) => {
        btnHandler({ value, setValue, btnValues, reminderMethod });
        setActiveTime(value);
    };
    const btnValues = (reminder, reminderDateTime) => {
        setValue('reminder', reminder);
        setValue('reminderDateTime', reminderDateTime);
        clearErrors('reminder');
        clearErrors('reminderDateTime');
    };

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ p: 3, boxShadow: 'none' }}>
                <Typography variant='h6' sx={{ pb: 2 }}>
                    {type === 'followUp' ? 'Book an appointment' : 'Treatment'}
                </Typography>
                <Divider />
                <Grid container spacing={1} sx={gridStyle(type)} >
                    {EditTreatment && (
                        <TreatmentEditPopover
                            treatment={treatmentData || ''}
                            leadTreatmentSpecification={{ teeth_count: values.teethAmount }}
                            open={EditTreatment}
                            anchorEl={anchorEditTreatment}
                            handleClickAwayLister={handleClickAwayLister}
                            treatmentPrice={amount}
                            handleSaveTreatment={handleSaveTreatment}
                            isFlow
                        />
                    )}
                    <TreatmentFlowComponent treatmentData={treatmentData} teethAmount={values.teethAmount} handleTreatment={handleTreatment} amount={amount} errorMessage={errors.treatment?.message} amountError={priceAmountError} amountHandleChange={amountHandleChange} handleChangeTeeth={handleChangeTeeth} teethAmountError={teethAmountError} />
                </Grid>
                {type === 'followUp' ? null : <Box sx={{ mb: '40px', display: 'flex', flexDirection: 'column' }}>
                    Book an appointment now?
                    <RHFRadioGroup
                        name={'radioButton'}
                        onClick={(e) => radioHandler(e)}
                        options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                        ]}
                        sx={{ mt: '2px', width: '100%', flexDirection: 'row' }}
                    />
                </Box>}
                <Box sx={{ mt: '0' }}>
                    {values.radioButton === 'Yes' ?
                        <>
                            {type === 'followUp' ? null :
                                <>
                                    <Typography variant='h6' sx={{ pb: 2 }}>Book an appointment</Typography>
                                    <Divider sx={{ mb: '34px' }} />
                                </>
                            }
                            <Grid sx={appointmentGridStyle}>
                                <Box>
                                    <Controller
                                        name='appoinmentDate'
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <DateTimePicker
                                                {...field}
                                                label='Appointment date (Required)'
                                                value={field.value || null}
                                                format='dd/MM/yyyy HH:mm'
                                                onChange={(newValue) => handleChangeDate({ newValue, field })}
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!error,
                                                        helperText: error?.message,
                                                        onKeyDown: (e) => e.preventDefault()
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                    <UserSearchDropdown name={'bookedWith'} control={control} label='Booked with (Required)' assignToState={assignToState} setAssignTo={handleSetAssign} error={errors?.bookedWith} />
                                    <AssignedToLead
                                        name={'assignedTo'}
                                        label="Assigned to (Used for system Task)"
                                        control={control}
                                        assignToState={assignToLeadState}
                                        setAssignTo={setAssignToLeadState}
                                    />
                                </Box>
                            </Grid>
                            <Box sx={{ ...boxStyle, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                                <RHFToggleGroup control={control} label='Appointment method (Required)' name='preferredContactMethod' options={appointmentMethod} />
                                <RHFToggleGroup control={control} label='Appointment type (Required)' name='appointmentType' options={appointmentType} />
                                <RHFToggleGroup control={control} label='Contact method (Required)' name='practiceContactedVia' options={contactMethod} />
                            </Box>
                        </>
                        : values.radioButton === 'No' ? (
                            <>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '18px !important', fontWeight: '700' }}
                                >
                                    Add a follow up reminder
                                </Typography>
                                <Box sx={AppointmentTabStyle}>
                                    <ButtonGroup
                                        orientation={!isMobile ? 'vertical' : 'horizontal'}
                                        sx={{ ...followUpButtonGroupStyle, display: 'inline-block', borderColor: 'common.darkGrey' }}
                                    >
                                        <Button
                                            variant={activeTime === 'custom reminder' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('custom reminder')}
                                            sx={followUpCustomButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'solar:clock-circle-bold'} width={20} height={20} /> Custom reminder
                                        </Button>
                                        <Button
                                            variant={activeTime === '2 hours-call' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('2 hours-call')}
                                            sx={followUpHourButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 hours
                                        </Button>
                                        <Button
                                            variant={activeTime === 'tomorrow' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('tomorrow')}
                                            sx={followUpTomorrowButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call 9am tomorrow
                                        </Button>
                                        <Button
                                            variant={activeTime === 'week' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('week')}
                                            sx={followUpWeekButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 1 week
                                        </Button>
                                        <Button
                                            variant={activeTime === 'two-week' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('two-week')}
                                            sx={followUpTwoWeekButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 week
                                        </Button>
                                        <Button
                                            variant={activeTime === 'no-reminder' ? 'contained' : 'text'}
                                            onClick={() => handleButtonClick('no-reminder')}
                                            sx={followUpNoReminderButtonStyle(activeTime)}
                                        >
                                            <Iconify sx={{ marginRight: '8px' }} icon={'bxs:phone-off'} width={25} height={25} /> No reminder
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                                {activeTime === 'custom reminder' ? (
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Box sx={{ width: '50%', mb: 3 }}>
                                                <Box sx={{ display: activeTime === 'custom reminder' ? 'block' : 'none' }}>
                                                    <Controller
                                                        name='reminderDateTime'
                                                        control={control}
                                                        render={({ field, fieldState: { error } }) => (
                                                            <>
                                                                <DateTimePicker
                                                                    open={isOpen}
                                                                    onOpen={handleToggleOpen}
                                                                    onClose={() => setIsOpen(false)}
                                                                    label='Custom date and time (Required)'
                                                                    value={field.value || null}
                                                                    format={'dd/MM/yyyy HH:mm'}
                                                                    ampm={false}
                                                                    minDateTime={new Date()}
                                                                    onChange={(newValue) => {
                                                                        field.onChange(newValue);
                                                                    }}
                                                                    slotProps={{
                                                                        textField: {
                                                                            fullWidth: true,
                                                                            error: !!error,
                                                                            helperText: error?.message,
                                                                            onKeyDown: (e) => e.preventDefault()
                                                                        },
                                                                        toolbarButton: {
                                                                            onClick: handleToggleOpen
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: '50%', mb: 3 }}>
                                                <RHFSelect
                                                    name='reminder'
                                                    label='Reminder method'
                                                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                                    sx={{ mb: 2 }}
                                                >
                                                    {reminderMethod?.map((options) => (
                                                        <MenuItem
                                                            key={options.label}
                                                            value={options.value}
                                                            sx={selectMenuItemStyle}
                                                        >
                                                            {options.label}
                                                        </MenuItem>
                                                    ))}
                                                </RHFSelect>
                                            </Box>
                                        </Box>
                                    </Grid>) : null
                                }

                                <Box sx={{ mb: 4, display: (activeTime === 'no-reminder' || activeTime === '') ? 'none' : 'block' }}>
                                <AssignedToLead
                                        name={'assigneesNoBook'}
                                        label="Assignees"
                                        control={control}
                                        assignToState={assignToStateNoBook}
                                        setAssignTo={setAssignToStateNoBook}
                                    />
                                    {/* <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                                    <Box sx={{ cursor: 'pointer' }}>
                                        {assignToState?.length > 0 ? (
                                            <Box sx={taskUserAvatar}>
                                                <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                            <AssignUserAvatar
                                                                key={index}
                                                                sx={assignUserAvatarStyle}
                                                                src={assignee.user?.avatar || ''}
                                                                name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                                role={assignee.role}
                                                                onClick={(e) => assignTaskHandler(e)}
                                                            />
                                                        ))}
                                                        {assignToState?.length > 2 && (
                                                            <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                                + {assignToState?.length - 2}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        ) : (
                                            <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200] }} onClick={(e) => assignTaskHandler(e)}>
                                                    <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                                </Box>
                                            </Box>
                                        )}
                                    </Box> */}
                                </Box>

                                {activeTime !== 'no-reminder' ? <Box sx={{ mb: 3, width: '50%' }}><RHFTextField name="OtherNotes" multiline rows={4} label="Additional notes (Required)" InputLabelProps={{ shrink: true }} placeholder='Add notes here' /></Box> : null}

                            </>
                        ) : null
                    }
                </Box>
            </Card>
        </FormProvider>
    );
});

TreatmentAndAppointment.propTypes = {
    handleNext: PropTypes.func,
    type: PropTypes.string,
    isOverview: PropTypes.bool
};

export default TreatmentAndAppointment;