import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, ListItemText, Link } from '@mui/material';
// guards
import { RoleBasedGuard } from '../../../guards';
// Icon
import Iconify from '../../Iconify';
// redux
import { useSelector } from '../../../redux/store';
// hooks
import { useLocales } from '../../../hooks';
// styles
import { ListItemStyle, ListDotIcon, ListItemIconStyle, badgeStyle } from './style';

const NavItem = ({ item, depth, active, open, isExternalLink, ...other }) => {
    const { translate } = useLocales();

    const subItem = depth !== 1;

    const { title, path, icon, info, children, disabled, caption, roles } = item;
    const { emailsCounter } = useSelector((state) => state.emailCounter);

    const renderContent = (
        <ListItemStyle depth={depth} active={active} caption={!!caption} disabled={disabled} {...other}>
            {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}

            {subItem && (
                <ListItemIconStyle>
                    <ListDotIcon active={active && subItem} />
                </ListItemIconStyle>
            )}

            <ListItemText
                primary={translate(title)}
                secondary={
                    caption && (
                        <Tooltip title={translate(caption)} placement='top-start'>
                            <span>{translate(caption)}</span>
                        </Tooltip>
                    )
                }
                primaryTypographyProps={{
                    noWrap: true,
                    component: 'span',
                    variant: active ? 'subtitle2' : 'body2',
                }}
                secondaryTypographyProps={{
                    noWrap: true,
                    variant: 'caption',
                }}
            />
            {title === 'Emails' && emailsCounter && emailsCounter?.count !== 0 &&
                <Box sx={badgeStyle}>
                    {emailsCounter?.count}
                </Box>
            }
            {info && (
                <Box component='span' sx={{ lineHeight: 0 }}>
                    {info}
                </Box>
            )}

            {!!children && (
                <Iconify
                    width={16}
                    icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                    sx={{ ml: 1, flexShrink: 0 }}
                />
            )}
        </ListItemStyle>
    );
    const renderItem = () => {
        // ExternalLink
        if (isExternalLink)
            return (
                <Link href={path} target='_blank' rel='noopener' underline='none'>
                    {renderContent}
                </Link>
            );

        // Has child
        if (children) {
            return renderContent;
        }

        // Default
        return (
            <Link component={RouterLink} to={path} underline='none' className={title === 'Patient feedback' ? 'patient-review' : ''}>
                {renderContent}
            </Link>
        );
    };

    return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
};

NavItem.propTypes = {
    open: PropTypes.bool,
    active: PropTypes.bool,
    item: PropTypes.object,
    depth: PropTypes.number,
    isExternalLink: PropTypes.bool,
};

export default NavItem;