/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// @mui
import {
    Box,
    Card,
    Divider,
    Typography,
    Select,
    FormControl,
    InputLabel,
    // Checkbox,
    MenuItem,
    IconButton,
    InputAdornment,
    FormHelperText
} from '@mui/material';
import { Iconify } from '.';
import { FormProvider } from './hook-form';
import { updateError } from '../redux/slices/user';
import { dispatch, useSelector } from '../redux/store';
import { convertDate } from '../utils/CampaignUtils';
import { saveNewCampaign, updateCampaignStep, startLoadingData, previewRecepientsCampaign, setFinalCampaignData, updateCampaign, updateSelectedCampaign } from '../redux/slices/campaign';
import { ScheduleCampaignSchema } from '../validations/validations';
import { editDurationCardStyle, dateTimeTextStyle } from '../styles/CampaignPageStyle';
import { selectedAudienceData } from '../redux/slices/audienceManager';

const ScheduleCampaigne = forwardRef(({ handleNext, ScheduleCampaign, isEditDuration, type }, ref) => {
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [timeZone, setTimeZone] = useState('AM');
    const hoursArr = new Array(12);
    const Hours = hoursArr.fill('').map((_, index) => {
        const hour = (index + 1).toString().padStart(2, '0');
        return hour;
    });
    const arr = new Array(59);
    const filledArr = arr.fill('').map((_, index) => {
        const minute = (index + 1).toString().padStart(2, '0');
        return minute;
    });
    const Time = ['AM', 'PM'];
    const { newCampaign, selectedCampaign, isIncludeArchive, currentActiveTab } = useSelector((state) => state.campaign);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const isoDateString = moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').toISOString();
    const date = new Date(isoDateString);
    const nowUtc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    // const Minutes = ["5 minutes", "10 minutes", "15 minutes", "20 minutes", "25 minutes", "30 minutes"];
    // const [selectMinutes, setSelectMinutes] = useState('5 minutes');
    // const Amount = ["£2", "£4", "£6", "£8", "£10", "£12", "£14", "£16", "£18", "£20"];
    // const [selectAmount, setSelectAmount] = useState('');
    const defaultValues = {
        deliveryDate: newCampaign?.scheduled !== undefined && newCampaign?.scheduled && newCampaign?.scheduled?.deliveryDate && new Date(newCampaign?.scheduled?.deliveryDate) || (Object.values(selectedCampaign).length > 0 && new Date(nowUtc)) || null,
        hour: (newCampaign?.scheduled !== undefined && newCampaign?.scheduled?.hour) || (Object.values(selectedCampaign).length > 0 && moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm').format('hh')) || '',
        minute: (newCampaign?.scheduled !== undefined && newCampaign?.scheduled?.minute) || (Object.values(selectedCampaign).length > 0 && moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm').format('mm')) || '',
        timeZone: (newCampaign?.scheduled !== undefined && newCampaign?.scheduled?.timeZone) || (Object.values(selectedCampaign).length > 0 && moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm').format('A').toLocaleUpperCase()) || 'AM'
    };

    const methods = useForm({
        resolver: yupResolver(ScheduleCampaignSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        control,
        clearErrors,
        watch
    } = methods;

    const value = watch();

    useEffect(() => {
        if (Object.values(selectedCampaign).length > 0) {
            setHour(moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm:ss').format('hh'));
            setMinute(moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm:ss').format('mm'));
            setTimeZone((moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm:ss').format('A').toLocaleUpperCase()));
            setValue('hour', moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm:ss').format('hh'));
            setValue('minute', moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm:ss').format('mm'));
            setValue('timeZone', moment(selectedCampaign.scheduled_at, 'DD-MM-YYYY HH:mm:ss').format('A').toLocaleUpperCase());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCampaign]);

    useEffect(() => {
        if (newCampaign?.scheduled !== undefined && Object.values(selectedCampaign).length === 0) {
            setHour(newCampaign?.scheduled?.hour);
            setMinute(newCampaign?.scheduled?.minute);
            setTimeZone(newCampaign?.scheduled?.timeZone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newCampaign]);

    useEffect(() => {
        if (value.deliveryDate && hour && minute) {
            dispatch(saveNewCampaign({ scheduled: value, update: Object.values(selectedCampaign).length > 0, id: selectedCampaign.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hour, minute, timeZone]);

    const onSubmit = async (data) => {
        const parsedDate = convertDate(data);
        const parsedCurrentDate = moment().format('DD-MM-YYYY HH:mm:ss.SSSSSS[Z]');
        const moment1 = moment(parsedDate, 'DD-MM-YYYY HH:mm:ss.SSSSSS[Z]');
        const moment2 = moment(parsedCurrentDate, 'DD-MM-YYYY HH:mm:ss.SSSSSS[Z]');
        if (moment1.isBefore(moment2)) {
            dispatch(updateError({
                message: 'Scheduled time must be in the future',
                status: false
            }));
            return;
        }
        dispatch(saveNewCampaign({ scheduled: data, update: Object.values(selectedCampaign).length > 0, id: selectedCampaign.id }));
        if (selectedCampaign && selectedCampaign?.id) {
            dispatch(startLoadingData(true));
            const campaignData = {
                practiceId,
                filters: {
                    ...(newCampaign.lead_tags__tag__tag__name__in || selectedCampaign?.recepient?.filters?.lead_tags__tag__tag__name__in ? { lead_tags__tag__tag__name__in: newCampaign.lead_tags__tag__tag__name__in || selectedCampaign?.recepient?.filters.lead_tags__tag__tag__name__in } : {}),
                    ...(newCampaign.lead_treatments__status__in || selectedCampaign?.recepient?.filters?.lead_treatments__status__in ? { lead_treatments__status__in: newCampaign.lead_treatments__status__in || selectedCampaign?.recepient?.filters?.lead_treatments__status__in } : {}),
                    ...(newCampaign.lead_treatments__treatment__treatment_item__name__in || selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in ? { lead_treatments__treatment__treatment_item__name__in: newCampaign.lead_treatments__treatment__treatment_item__name__in || selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in } : {}),
                    ...(newCampaign.assignees__user__id__in || selectedCampaign?.recepient?.filters?.assignees__user__id__in ? { assignees__user__id__in: newCampaign.assignees__user__id__in || selectedCampaign?.recepient?.filters?.assignees__user__id__in } : {}),
                    ...(newCampaign.lead_source__referral__in || selectedCampaign?.recepient?.filters?.lead_source__referral__in ? { lead_source__referral__in: newCampaign.lead_source__referral__in || selectedCampaign?.recepient?.filters?.lead_source__referral__in } : {}),
                    ...(newCampaign.created_at__range || selectedCampaign?.recepient?.filters?.created_at__range ? { created_at__range: newCampaign.created_at__range || selectedCampaign?.recepient?.filters?.created_at__range } : {}),
                    ...(newCampaign.lead_source__type__in || selectedCampaign?.recepient?.filters?.lead_source__type__in ? newCampaign.lead_source__type__in || { lead_source__type__in: selectedCampaign?.recepient?.filters?.lead_source__type__in } : {}),
                    ...(newCampaign.lead_treatments__treatment__treatment_item__treatment_category__in
                        || selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in
                        ? newCampaign.lead_treatments__treatment__treatment_item__treatment_category__in
                        || {
                            lead_treatments__treatment__treatment_item__treatment_category__in:
                                selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in
                        } : {}),
                },
                is_archived_included: isIncludeArchive
            };
            if (currentActiveTab === 'By Conditions') {
                campaignData.filters = {
                    ...(newCampaign.lead_tags__tag__tag__name__in || selectedCampaign?.recepient?.filters?.lead_tags__tag__tag__name__in ? { lead_tags__tag__tag__name__in: newCampaign.lead_tags__tag__tag__name__in || selectedCampaign?.recepient?.filters.lead_tags__tag__tag__name__in } : {}),
                    ...(newCampaign.lead_treatments__status__in || selectedCampaign?.recepient?.filters?.lead_treatments__status__in ? { lead_treatments__status__in: newCampaign.lead_treatments__status__in || selectedCampaign?.recepient?.filters?.lead_treatments__status__in } : {}),
                    ...(newCampaign.lead_treatments__treatment__treatment_item__name__in || selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in ? { lead_treatments__treatment__treatment_item__name__in: newCampaign.lead_treatments__treatment__treatment_item__name__in || selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in } : {}),
                    ...(newCampaign.assignees__user__id__in || selectedCampaign?.recepient?.filters?.assignees__user__id__in ? { assignees__user__id__in: newCampaign.assignees__user__id__in || selectedCampaign?.recepient?.filters?.assignees__user__id__in } : {}),
                    ...(newCampaign.lead_source__referral__in || selectedCampaign?.recepient?.filters?.lead_source__referral__in ? { lead_source__referral__in: newCampaign.lead_source__referral__in || selectedCampaign?.recepient?.filters?.lead_source__referral__in } : {}),
                    ...(newCampaign.created_at__range || selectedCampaign?.recepient?.filters?.created_at__range ? { created_at__range: newCampaign.created_at__range || selectedCampaign?.recepient?.filters?.created_at__range } : {}),
                    ...(newCampaign.lead_source__type__in || selectedCampaign?.recepient?.filters?.lead_source__type__in ? newCampaign.lead_source__type__in || { lead_source__type__in: selectedCampaign?.recepient?.filters?.lead_source__type__in } : {}),
                    ...(newCampaign.lead_treatments__treatment__treatment_item__treatment_category__in
                        || selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in
                        ? newCampaign.lead_treatments__treatment__treatment_item__treatment_category__in
                        || {
                            lead_treatments__treatment__treatment_item__treatment_category__in:
                                selectedCampaign?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in
                        } : {}),
                };
                campaignData.is_archived_included = isIncludeArchive;
            }
            if (currentActiveTab === 'By Conditions' && newCampaign.is_only_archived || selectedCampaign?.recepient?.filters?.is_only_archived) {
                if ((newCampaign.is_only_archived && newCampaign?.is_only_archived?.is_open_for_communication === 'false') || (selectedCampaign?.recepient?.filters?.is_only_archived && (selectedCampaign?.recepient?.filters?.is_open_for_communication === 'false' || selectedCampaign?.recepient?.filters?.is_open_for_communication === false))) {
                    campaignData.filters = {
                        ...campaignData.filters,
                        is_open_for_communication: false,
                    };
                } else if (newCampaign.is_only_archived || selectedCampaign?.recepient?.filters?.is_only_archived) {
                    campaignData.filters = {
                        ...campaignData.filters,
                        is_open_for_communication: true,
                    };
                }
            }
            let finalCampaignData = {};
            if (currentActiveTab === 'By Conditions') {
                const filteredFilters = Object.entries(campaignData?.filters).filter(([key, value]) => !Array.isArray(value) || value.length > 0).reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});
                finalCampaignData = {
                    ...campaignData,
                    filters: filteredFilters,
                };
            }
            if (currentActiveTab === 'Existing audience') {
                finalCampaignData = {
                    ...campaignData,
                    audience: newCampaign.audienceSelected || { audience: selectedCampaign?.audience?.audience }
                };
            };
            const fieldParams = {
                limit: 10,
                offset: 0
            };
            dispatch(setFinalCampaignData(finalCampaignData));
            const date = typeof newCampaign.scheduled === 'string' ? newCampaign.scheduled : convertDate(newCampaign.scheduled || data);
            let tempData = {};
            tempData = {
                practiceId,
                recepient: { filters: finalCampaignData.filters, is_archived_included: finalCampaignData?.is_archived_included },
                name: newCampaign?.campaignName,
                engagement_template: newCampaign?.engagement_template,
                scheduled_at: date,
            };
            if (currentActiveTab === 'Existing audience') {
                tempData.recepient = null;
            }
            if (currentActiveTab === 'By Conditions') {
                tempData.audience = null;
                dispatch(updateCampaign(tempData, selectedCampaign?.id, (respData) => {
                    dispatch(selectedAudienceData(respData?.audience));
                    dispatch(previewRecepientsCampaign(finalCampaignData, selectedCampaign?.id, fieldParams));
                }));
            } else {
                tempData.audience = typeof finalCampaignData.audience?.audience === 'number' ? finalCampaignData.audience : { audience: finalCampaignData.audience?.audience?.id };
                dispatch(updateCampaign(tempData, selectedCampaign?.id, (respData) => { dispatch(updateSelectedCampaign(respData)); }));
                dispatch(startLoadingData(false));
            }
        } else {
            dispatch(updateCampaignStep(true));
            dispatch(startLoadingData(true));
        }
        if (selectedCampaign && selectedCampaign?.status && selectedCampaign?.status !== 'DRAFT') {
            dispatch(updateCampaignStep(true));
            if (handleNext) handleNext();
            return;
        }
        ScheduleCampaign();
        if (handleNext) handleNext();
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleHourChange = (event) => {
        setHour(event.target.value);
        setValue('hour', event.target.value);
        clearErrors('hour');
    };

    const handleMinuteChange = (event) => {
        setMinute(event.target.value);
        setValue('minute', event.target.value);
        clearErrors('minute');
    };

    const handleTimeZoneChange = (event) => {
        setTimeZone(event.target.value);
        setValue('timeZone', event.target.value);
    };

    // const handleChange = (event) => {
    //     setValue('role', event.target.name);
    // };

    // const handleMinutesChange = (event) => {
    //     setSelectMinutes(event.target.value);
    // }

    // const handleAmountChange = (event) => {
    //     setSelectAmount(event.target.value);
    // }

    const getCss = () => {
        return isEditDuration ? editDurationCardStyle : {};
    };

    return (
        <FormProvider methods={methods}>
            <Box sx={{ mt: type === 'CampaignPreviewPopup' ? '24px' : '50px' }}>
                <Typography variant="h6" sx={{ marginBottom: '24px', fontSize: '24px !Important' }}>
                    {type === 'CampaignPreviewPopup' ? 'Edit duration' : 'Schedule your campaign.'}
                </Typography>
                <Card sx={{ p: type === 'CampaignPreviewPopup' ? '24px 24px 0' : '24px', '@media(max-width: 767px)': { p: '24px 16px' }, ...getCss() }}>
                    <Typography sx={dateTimeTextStyle(type)}>Delivery date and time</Typography>
                    <Divider sx={{ mb: '24px' }} />
                    <Box sx={{ maxWidth: '620px', mb: '24px' }}>
                        <Typography sx={{ fontSize: '13px', fontWeight: '500', mb: '12px' }}>Delivery date</Typography>
                        <Controller
                            name="deliveryDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <MobileDatePicker
                                    orientation="landscape"
                                    value={field.value || null}
                                    minDate={new Date()}
                                    format={'dd/MM/yyyy'}
                                    onChange={(newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                            InputProps: {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <Iconify height={24} width={24} icon='solar:calendar-mark-bold-duotone' />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                        <Typography sx={{ fontSize: '13px', fontWeight: '500', m: type === 'CampaignPreviewPopup' ? '24px 0 18px' : '12px 0' }}>Delivery time</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <Controller
                                name='hour'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl sx={{ width: '31%' }}>
                                        <InputLabel
                                            size='normal'
                                            error={error && !field.value.length}
                                            name="hour"
                                        >
                                            Hour
                                        </InputLabel>
                                        <Select
                                            name="hour"
                                            value={hour}
                                            label="Hour"
                                            disabled={value?.deliveryDate === null}
                                            onChange={handleHourChange}
                                            error={error && !field.value.length}
                                        >
                                            {Hours.map((hour) => (
                                                <MenuItem
                                                    key={hour}
                                                    value={hour}
                                                >
                                                    {hour}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error && !field.value.length &&
                                            <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                                {error?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name='minute'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl sx={{ width: '31%' }}>
                                        <InputLabel
                                            size='normal'
                                            error={error && !field.value.length}
                                            name="minute"
                                        >
                                            Minute
                                        </InputLabel>
                                        <Select
                                            name="minute"
                                            value={minute}
                                            label="Minute"
                                            disabled={value?.deliveryDate === null}
                                            onChange={handleMinuteChange}
                                            error={error && !field.value.length}
                                        >
                                            <MenuItem
                                                value={'00'}
                                            >
                                                {'00'}
                                            </MenuItem>
                                            {filledArr.map((minute) => (
                                                <MenuItem
                                                    key={minute}
                                                    value={minute}
                                                >
                                                    {minute}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error && !field.value.length &&
                                            <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                                {error?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name='timeZone'
                                control={control}
                                render={() => (
                                    <FormControl sx={{ width: '31%' }}>
                                        <Select
                                            name="timeZone"
                                            value={timeZone}
                                            label=""
                                            onChange={handleTimeZoneChange}
                                            disabled={value?.deliveryDate === null}
                                        >
                                            {Time.map((name, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Box>
                    </Box>
                    {/* {'need for future'} */}
                    {/* <Typography sx={{ fontSize: '13px', fontWeight: '500', my: '12px' }}>Batch delivery</Typography>
                    <Typography sx={{ fontSize: '16px', mb: '12px' }}>You can prevent website-crushing click floods by delivering your campaign in batches.</Typography>
                    <Box sx={ScheduleCampaigneBoxStyle}>
                        <Controller
                            name='role'
                            control={control}
                            render={() => (
                                <Box sx={{ display: 'flex', whiteSpace: 'nowrap', pt: '8px' }}>
                                    <Checkbox sx={{height: '40px', mt: '6px'}} onChange={handleChange} name="Send in" />
                                    <Box sx={{marginTop: '16px', mr: '24px', '@media(max-width: 900px)': { mr: '12px' } }}>
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                            Send in
                                        </Typography>
                                    </Box>
                                    <FormControl sx={{ width: '155px' }}>
                                        <InputLabel
                                            size='normal'
                                            name="Amount"
                                        >
                                           Amount
                                        </InputLabel>
                                        <Select
                                            name="Amount"
                                            value={selectAmount}
                                            label="Amount"
                                            onChange={handleAmountChange}
                                        >
                                            {Amount.map((name, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Box sx={{marginTop: '16px', px: '24px', '@media(max-width: 900px)': { px: '12px' } }}>
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>batches of contacts</Typography>
                                    </Box>
                                    <FormControl sx={{ width: '155px' }}>
                                        <InputLabel
                                            size='normal'
                                            name="Minutes"
                                        >
                                           Minutes
                                        </InputLabel>
                                        <Select
                                            name="Minutes"
                                            value={selectMinutes}
                                            label="Minutes"
                                            onChange={handleMinutesChange}
                                        >
                                            {Minutes.map((name, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Box sx={{marginTop: '16px', pl: '24px', '@media(max-width: 900px)': { pl: '12px' }}}>
                                        <Typography variant="body2" sx={{ color: 'text.primary' }}>apart</Typography>
                                    </Box>
                                </Box>
                            )}
                        />
                    </Box>
                    <Divider /> */}
                </Card>
            </Box>
        </FormProvider>
    );
});

ScheduleCampaigne.propTypes = {
    handleNext: PropTypes.func,
    ScheduleCampaign: PropTypes.func,
    isEditDuration: PropTypes.bool,
    type: PropTypes.string
};

export default ScheduleCampaigne;