export const TableheadStyle = {
    '.MuiTableCell-root': {
        boxShadow: 'none !important',
        pl: '16px !important',
        borderBottom: '1px solid hwb(209deg 74% 13% / 50%) !important'
    },
};
export const cardStyle = {
    padding: '0 24px',
    mb: '24px',
    width: '100%',
    '@media(max-width: 1200px)': {
        width: '100%',
        order: '0'
    }
};

export const categoryMainBoxStyle = (type) => ({
    mt: '30px', 
    boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)', 
    padding: type === 'Reports' && '24px 0 0',
    borderRadius: '16px',
    '.MuiTableCell-head:first-of-type': {
        borderTopLeftRadius: type !== 'Reports' && '16px !important'
    },
    '.MuiTableCell-head:last-of-type': {
        borderTopRightRadius: type !== 'Reports' && '16px !important'
    }
});

export const categoryTableContainerStyle = (type) => ({
    position: 'relative', 
    overflow: 'hidden', 
    borderRadius: type !== 'Reports' && '16px', 
    '.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': { 
        border: 'none !important' 
    }
});

export const categoryTopBoxStyle = (type) => ({
    display: type === 'Reports' ? 'none ' : 'flex', 
    justifyContent: 'flex-end', 
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 3,
    '@media(max-width: 900px)': {
        flexWrap: 'wrap'
    }
});

export const referralTableHeadStyle = {
    '.MuiTableCell-root': { 
        borderRadius: '0 !important', 
        cursor: 'pointer' 
    }, 
    '.MuiTableCell-root:first-of-type': { 
        minWidth: '150px', 
        textAlign: 'left' 
    }
};

export const sourceTypeTableHeadStyle = {
    '.MuiTableCell-root': { 
        borderRadius: '0 !important', 
        cursor: 'pointer' 
    }, 
    '.MuiTableCell-root:first-of-type': { 
        minWidth: '110px', 
        textAlign: 'left' 
    }
};

export const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0'
};

export const chartNoDataBox = {
    mt: 3,
    mx: 3,
    height: '350px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

export const tableHeadStyle = { mb: 0, fontSize: '18px !important' };