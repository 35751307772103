import { createSlice } from '@reduxjs/toolkit';
import { callApi } from '../../utils/apiCall';
import { dispatch } from '../store';
import { updateError } from './user';

const initialState = {
    isLoading: false,
    practice: {}
};

const slice = createSlice({
    name: 'practice',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        getPracticeData(state, action) {
            state.isLoading = false;
            state.practice = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateLoader(state, action) {
            state.isLoading = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const { getPracticeData, reset } = slice.actions;

export function getPracticeItem(id) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${id}/`);
            dispatch(slice.actions.getPracticeData(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updatePractice({ id, data, isEmergencyAppointment = false }) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${id}/`, 'patch', data);
            if (response.status === 200) {
                dispatch(slice.actions.getPracticeData(response.data));
                if (isEmergencyAppointment) {
                    dispatch(updateError({ success: true, message: 'Data updated successfully.' }));
                    return;
                }
                dispatch(updateError({ success: true, message: 'Data updated successfully. Please refresh the page' }));
            } else {
                dispatch(updateError({ success: false, message: response?.data?.settings?.consent_base_url.toString() || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}

export function generateConsentUrl(id, data, handleCopy) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${id}/generate_consent_url/`, 'post', data);
            if (response.status === 200) {
                handleCopy(response.data);
                dispatch(updateError({ success: true, message: 'Consent link generated successfully' }));
            } else {
                dispatch(updateError({ success: false, message: response?.data.practice_user?.toString() || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message) || 'Something went wrong', success: false }));
        }
    };
}