import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const CustomFirstNameCell = ({ params }) =>
    <Typography variant="subtitle2" align="center" textAlign='center'>
        <span style={{ textTransform: 'capitalize' }}>{params?.row?.first_name || ''}</span>
    </Typography>;

export default CustomFirstNameCell;

CustomFirstNameCell.propTypes = {
    params: PropTypes.any,
};
