import { common, grey } from 'src/theme/palette';

const { paleGreen } = common;

const boxShadow = { boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' };

export const scriptListItemStyle = {
    p: '16px',
    mb: '10px',
    ...boxShadow,
    borderRadius: '8px',
    flexWrap: 'wrap',
    gap: 1
};

const commonStyle = {
    bgcolor: grey[300],
    boxShadow: `0px 8px 16px ${common.stillBlue}`,
    borderRadius: '8px',
    fontSize: '13px',
    color: grey[800],
};

export const viewButtonStyle = {
    minWidth: '51px',
    mr: '8px',
    ...commonStyle,
    '&:hover': {
        bgcolor: grey[300]
    }
};
export const editButtonStyle = {
    minWidth: '46px',
    mr: '8px',
    ...commonStyle,
    '&:hover': {
        bgcolor: grey[300]
    }
};
export const deleteButtonStyle = {
    minWidth: '46px',
    mr: '0px',
    ...commonStyle,
    '&:hover': {
        bgcolor: grey[300]
    }
};
export const addSectionButtonStyle = {
    minWidth: '113px',
    minHeight: '36px',
    bgcolor: common.blue,
    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
    borderRadius: '8px',
    fontSize: '14px',
    color: common.white,
    '&:hover': {
        bgcolor: common.blue,
        boxShadow: 'none'
    }
};
export const previewButtonStyle = (isLight) => ({
    minWidth: '164px',
    minHeight: '48px',
    mr: '16px',
    bgcolor: 'transparent',
    boxShadow: 'none',
    border: `1px solid ${common.foggyGrey}`,
    borderRadius: '8px',
    fontSize: '15px',
    color: isLight ? grey[800] : common.white,
    '@media(max-width: 767px)': {
        minWidth: '120px',
    }
});
export const saveButtonStyle = {
    minWidth: '164px',
    minHeight: '48px',
    borderRadius: '8px',
    fontSize: '15px',
    '@media(max-width: 767px)': {
        minWidth: '120px',
    }
};
export const sectionBoxItemStyle = (isPreviousTemplate) => ({
    bgcolor: common.white,
    ...boxShadow,
    borderRadius: '16px',
    marginBottom: '40px',
    minHeight: 'auto',
    padding: '24px',
    background: isPreviousTemplate ? "rgb(212 212 212)" : "unset"
});
export const createScriptMainBox = {
    ' .open': {
        display: 'block'
    },
    ' .remove': {
        display: 'none'
    }
};
export const addFieldButtonStyle = {
    minWidth: '105px',
    minHeight: '30px',
    ...commonStyle,
    padding: '3px 10px',
    pointerEvents: 'visible',
    '&:hover': {
        bgcolor: grey[300],
        boxShadow: 'none'
    }
};
export const dragBoxStyle = {
    minWidth: '38px',
    minHeight: 'auto',
    lineHeight: '0',
    mr: '16px',
    textAlign: 'center',
    cursor: 'pointer'
};
export const scriptTitleBoxStyle = {
    p: '24px',
    ...boxShadow,
    borderRadius: '16px'
};
export const callScriptMainContainer = {
    width: '100%',
    marginLeft: 'auto',
    boxSizing: 'border-box',
    marginRight: 'auto',
    display: 'block',
    padding: '0 24px'
};
export const sectionTitleStyle = {
    pointerEvents: 'visible',
    'input ': {
        padding: '0',
        height: '18px',
        fontWeight: '600'
    },
    '.MuiOutlinedInput-notchedOutline': {
        display: 'none'
    }
};
export const moreIconstyle = {
    minWidth: '38px',
    minHeight: 'auto',
    lineHeight: '0',
    textAlign: 'center',
    cursor: 'pointer'
};
export const deleteIconStyle = {
    minWidth: '38px',
    minHeight: '26px',
    textAlign: 'center',
    cursor: 'pointer'
};
export const copyIconStyle = {
    minWidth: '38px',
    minHeight: '26px',
    textAlign: 'center',
    cursor: 'pointer'
};
export const dotsIconStyle = {
    minWidth: '38px',
    minHeight: '26px',
    textAlign: 'center',
    cursor: 'pointer'
};
export const arrowIconBoxStyle = {
    minWidth: '38px',
    minHeight: 'auto',
    lineHeight: '0',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'visible'
};
export const checkboxFormGroupBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    mb: '8px'
};
export const createScriptButtonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: '50px',
    '@media(max-width: 767px)': {
        justifyContent: 'center'
    }
};
export const fieldBGBoxStyle = (isEnabled) => ({
    bgcolor: common.veryPaleBlue,
    p: '16px',
    borderRadius: '8px',
    opacity: !isEnabled && '0.6'
});
export const fieldHeaderBoxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    mb: '16px',
    '@media(max-width: 500px)': {
        flexWrap: 'wrap'
    }
};
export const fieldHeaderLeftBoxStyle = {
    display: 'flex',
    width: 'calc(100% - 150px)',
    pointerEvents: 'visible',
    '@media(max-width: 500px)': {
        width: '100%',
        mb: '10px'
    }
};
export const fieldHeaderRightBoxStyle = {
    width: '150px',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media(max-width: 500px)': {
        width: '100%'
    }
};
export const checkboxListBoxStyle = {
    mb: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
        flexWrap: 'wrap'
    }
};
export const checkboxFormControlStyle = {
    width: '100%',
    pr: '0',
    '@media(max-width: 767px)': {
        width: '100%',
        borderRight: 'none',
        pr: '0',
    }
};
export const nameListBoxStyle = {
    mb: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
        flexWrap: 'wrap'
    }
};
export const dateTimeListBoxStyle = {
    mb: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    '@media(max-width: 767px)': {
        flexWrap: 'wrap'
    }
};
export const nameFieldStyle = {
    width: '49.5%',
    'input': {
        height: '40px',
        padding: '0 16px'
    },
    '@media(max-width: 600px)': {
        width: '49%',
    },
    '@media(max-width: 479px)': {
        width: '100%',
        mb: '8px'
    }
};
export const dateBoxMainStyle = {
    maxWidth: '160px',
    mr: '40px',
    '@media(max-width: 600px)': {
        mb: '10px',
        maxWidth: '100%',
        width: '100%',
        mr: '0'
    },
};
export const timeBoxMainStyle = {
    display: 'flex',
    alignItems: 'center',
    '@media(max-width: 600px)': {
        maxWidth: '100%',
        width: '100%',
    },
};
export const timeFormControlStyle = {
    width: '75px',
    ml: '16px',
    '@media(max-width: 600px)': {
        width: 'calc(33.33% - 10px)',
        ml: '10px'
    }
};
export const callScriptListItemStyle = (isLight) => ({
    bgcolor: isLight ? grey[200] : common.stillBlue,
    ...boxShadow,
    borderRadius: '16px',
    mb: '16px',
    p: '16px',
    cursor: 'pointer',
    '&:last-of-type': {
        mb: '0'
    }
});
export const backIconStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    lineHeight: 0,
    cursor: 'pointer'
};
export const closeIconStyle = {
    position: 'absolute',
    top: '50%',
    right: '24px',
    transform: 'translateY(-50%)',
    lineHeight: 0,
    '@media(max-width: 600px)': {
        top: '25%'
    }
};
export const singleScriptHeaderBoxStyle = {
    p: '15px 16px',
    borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)',
    position: 'relative',
    '@media(max-width: 600px)': {
        borderBottom: 'none',
        p: '50px 16px 0'
    }
};
export const titleDesktopStyle = {
    fontSize: '20px',
    fontWeight: '700',
    textAlign: 'center',
};
export const titleMobileStyle = {
    fontSize: '20px',
    fontWeight: '700',
    textAlign: 'center',
    mb: '24px',
    display: 'none',
    '@media(max-width: 600px)': {
        display: 'inline-block',
        width: '100%',
        mt: '-20px'
    }
};
export const scriptStackStyle = {
    display: 'inline-block',
    padding: {
        md: '70px 30px 150px',
        xs: '70px 16px 150px 16px'
    }
};
export const contentTextStyle = {
    fontSize: '14px',
    color: grey[600],
    textAlign: 'left',
    fontWeight: '600'
};
export const noScriptTextStyle = {
    textAlign: 'center',
    width: '100%',
    color: grey[800],
    fontSize: '18px',
    fontWeight: '700',
    mb: '8px',
    mt: '10px'
};
export const formItemBoxStyle = (isLight) => ({
    bgcolor: isLight ? common.veryPaleBlue : grey[900],
    p: '16px 16px',
    borderRadius: '8px',
    mb: '20px'
});
export const formDeleteBoxStyle = {
    lineHeight: 0,
    minWidth: '25px',
    textAlign: 'right',
    cursor: 'pointer'
};
export const fieldLabelStyle = {
    fontSize: '16px',
    fontWeight: '600',
    color: `${grey[800]} !important`,
    width: '100%',
    display: 'inline-block',
    mb: '0'
};
export const fieldLabelStyle2 = {
    fontSize: '16px',
    fontWeight: '400',
    color: `${grey[800]} !important`,
    width: '100%',
    display: 'inline-block',
    mb: '0'
};
export const requiredTextStyle = {
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '13px',
    pl: '5px'
};
export const scriptTableStyle = {
    position: 'relative',
    maxHeight: '420px',
    '.MuiTableCell-root': {
        boxShadow: 'none !important',
        pl: '16px !important'
    }
};
export const CheckoutBoxStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
};
export const BackButtonStyle = {
    width: '40px',
    height: '40px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    minWidth: '40px',
    mb: '20px'
};
export const TitleStyle = {
    fontSize: '18px !important',
    mb: '20px',
    ml: '20px'
};
export const selectCategoryStyle = {
    maxWidth: { sm: 240 },
    textTransform: 'capitalize',
    width: '50%',
    mt: '10px'
};
export const closeButtonStyle = {
    position: 'absolute',
    right: '24px',
    lineHeight: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    color: grey[600],
    cursor: 'pointer'
};
export const mainLabelBoxStyle = {
    bgcolor: common.babyBlue,
    borderRadius: '8px',
    p: 1,
    mb: '16px',
    'h1, h2, h3, h4, h5, h6, ol, ul, p': {
        m: 0
    }
};
export const labelBoxStyle = {
    mb: 1,
    color: grey[600],
    fontSize: '14px',
    fontStyle: 'italic',
    display: 'flex',
    alignItems: 'center'
};
const commonBtnStyle = {
    border: `solid 1px ${common.foggyGrey}`,
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    fontWeight: '600',
};
export const unableContactButtonStyle = (outcome) => ({
    backgroundColor: outcome === 'Unable to contact' ? common.lightJade : 'transparent',
    ':hover': { backgroundColor: common.lightJade, color: common.mintyZest, borderColor: common.mintyZest },
    borderRadius: '8px !important',
    color: outcome === 'Unable to contact' ? common.mintyZest : grey[600],
    ...commonBtnStyle,
    marginRight: { md: '4px', xs: '0' },
});
export const appointmentButtonStyle = (outcome) => ({
    backgroundColor: outcome === 'Appointment Not Booked' ? common.lightJade : 'transparent',
    ':hover': { backgroundColor: common.lightJade, color: common.mintyZest, borderColor: common.mintyZest },
    borderRadius: '8px !important',
    color: outcome === 'Appointment Not Booked' ? common.mintyZest : grey[600],
    ...commonBtnStyle,
    textTransform: 'none',
    marginRight: { md: '4px', xs: '0' },
});
export const bookingButtonStyle = (outcome) => ({
    backgroundColor: outcome === 'Booking Details' ? common.lightJade : 'transparent',
    ':hover': { backgroundColor: common.lightJade, color: common.mintyZest, borderColor: common.mintyZest },
    borderRadius: '8px !important',
    color: outcome === 'Booking Details' ? common.mintyZest : grey[600],
    ...commonBtnStyle,
    textTransform: 'none',
});

export const categoryTreatment = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    width: '100%'
};

export const tableLabel = {
    height: '22px',
    width: '32px',
    background: 'transparent !important',
    border: `2px solid ${common.tomatoRed}`,
    padding: '0 8px',
    color: common.tomatoRed,
    ml: '12px',
    fontSize: '12px'
};
export const formChoiceWrapStyle = {
    display: 'flex',
    columnGap: '8px',
    alignItems: 'center',
    flexWrap: 'wrap'
};
export const formChoiceWrapInnerStyle = {
    display: 'flex',
    columnGap: '8px',
    alignItems: 'center',
    width: '100%'
};
export const leadAddressCommonStyle = { display: 'grid', columnGap: 2, rowGap: 3, mb: 2 };
export const leadAddressWrapper = {
    ...leadAddressCommonStyle,
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
    position: 'relative'
};
export const leadAddressFieldWrapper = {
    ...leadAddressCommonStyle,
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
};
export const descriptionStyle = { ...mainLabelBoxStyle, bgcolor: paleGreen };
export const descriptionLabelStyle = { ...fieldLabelStyle, fontWeight: '400' };
export const testFieldWrapperStyle = { display: 'grid', columnGap: 2, gridTemplateColumns: 'repeat(3, 1fr)' };
export const nameTypoStyle = (data) => ({ mb: data?.field_type?.field_type === 'label' ? '30px' : '16px' });
export const loaderButtonStyle = (loading) => ({ marginRight: '10px', opacity: loading ? '0' : '1' });
export const submitBtnIconStyle = { marginRight: '10px', width: 20, height: 20 };
export const fieldDeleteBoxStyle = (email) => ({
    lineHeight: 0,
    minWidth: '25px',
    textAlign: 'right',
    cursor: 'pointer',
    pointerEvents: email === 'kp.fe@hupp.tech' ? 'visible' : 'none',
    opacity: email === 'kp.fe@hupp.tech' ? '1' : '0.5',
});

export const tableContainerStyle = {
    minWidth: 'auto',
    position: 'relative',
    outline: '1px solid rgba(145, 158, 171, 0.12)',
    borderBottom: '1px solid rgba(145, 158, 171, 0.12)',
    borderRadius: '8px',
    borderTopRightRadius: '8px',
    width: '100%',
    margin: '0 auto 20px',
    maxWidth: '100%',
    overflowX: 'auto',
    maxHeight: '100%'
};

export const messageTableCategory = {
    bgcolor: 'rgba(24, 144, 255, 0.16)',
    padding: '5px 10px',
    color: 'widget.tagBlueColor',
    lineHeight: '20px',
    borderRadius: '6px',
    fontWeight: 600, textTransform: 'capitalize'
};

export const messageTableMenuItem = {
    fontWeight: 600,
    fontSize: 14
};