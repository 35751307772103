import { isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'src/redux/store';

const useTreatmentAndAppointment = ({ isOverview, type }) => {
    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);
    const { newLeadDetail, tableRowLead } = useSelector((state) => state.lead);
    const { practiceUser } = useSelector((state) => state.practiceUser);

    const getTreatment = (value) => {
        if (isOverview && !isEmpty(currentTreatmentDataGlobal)) {
            switch (value) {
                case 'treatment':
                    return currentTreatmentDataGlobal?.treatmentData?.treatment;
                case 'price':
                    return currentTreatmentDataGlobal?.treatmentData?.price;
                case 'teeth':
                    return currentTreatmentDataGlobal?.treatmentData?.leadtreatment_specification?.teeth_count;
                default:
                    return '';
            }
        }
        if (type === 'followUp') {
            switch (value) {
                case 'treatment':
                    if (newLeadDetail && newLeadDetail.lead_treatment) {
                        return newLeadDetail.lead_treatment.treatment;
                    }
                    return tableRowLead && tableRowLead.treatment_name;
                case 'teeth':
                    if (newLeadDetail && newLeadDetail.lead_treatment) {
                        return newLeadDetail.lead_treatment?.leadtreatment_specification?.teeth_count;
                    }
                    return tableRowLead?.leadTreatmentSpecification?.teeth_count;
                case 'price':
                    if (newLeadDetail && newLeadDetail.lead_treatment) {
                        if (newLeadDetail.lead_treatment.custom_price === 0) {
                            return '0';
                        }
                        return newLeadDetail.lead_treatment.custom_price || '';
                    }
                    if (tableRowLead && tableRowLead.custom_price === 0) {
                        return '0';
                    }
                    return tableRowLead.price || '';
                case 'id':
                    if (newLeadDetail && newLeadDetail.lead_treatment) {
                        return newLeadDetail.lead_treatment.id;
                    }
                    return tableRowLead && tableRowLead.treatment_id;
                default:
                    return '';
            }
        }
        if (type === 'CreatLead') {
            switch (value) {
                case 'treatment':
                    if (newLeadDetail && newLeadDetail.lead_treatment && newLeadDetail.lead_treatment.treatment) {
                        return newLeadDetail.lead_treatment.treatment || '';
                    }
                    return '';
                case 'price':
                    if (newLeadDetail && newLeadDetail.lead_treatment) {
                        if (newLeadDetail.lead_treatment.custom_price === 0) {
                            return '0';
                        }
                        return newLeadDetail.lead_treatment.custom_price || '';
                    }
                    return '';
                case 'teeth':
                    if (newLeadDetail && newLeadDetail.lead_treatment) {
                        return newLeadDetail.lead_treatment?.teethAmount || null;
                    }
                    return null;
                default:
                    return '';
            }
        }
        return '';
    };

    const getAssignUser = () => {
        const data = practiceUser?.results?.filter(e => (newLeadDetail?.lead_appointment?.assignees?.includes(e?.id)));
        const dataToReturn = data?.map((el) => el);
        return dataToReturn;
    };

    const getType = () => {
        if (type === 'followUp') {
            return 'Yes';
        } if (newLeadDetail && newLeadDetail.lead_appointment) {
            return 'Yes';
        }
        return '';
    };

    const handleSuccessMessage = ({ data }) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
        });
    };

    return { getTreatment, getAssignUser, getType, handleSuccessMessage };
};

export default useTreatmentAndAppointment;