/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
// @mui
import {
    Grid,
    Button,
    Box,
    Typography,
    TextField,
    InputAdornment,
    Autocomplete,
    Tooltip
} from '@mui/material';
// components
import { Iconify, Scrollbar } from '../../../components';
import { SearchNotFound } from '../../../components/search-not-found';
import { FormProvider } from '../../../components/hook-form';
// redux
import { store, useSelector } from '../../../redux/store';
import { updateError } from '../../../redux/slices/user';
import { clearTaskLead, searchLead } from '../../../redux/slices/lead';
import { generatingFeedbackUrl, updateGeneratedUrl } from '../../../redux/slices/feedbackReview';
// hooks
import { useDebounce } from '../../../hooks/use-debounce';
import { useCopyToClipboard } from '../../../hooks';
// style
import {
    header,
    consentButton,
    topButton,
    popupHeader,
} from '../../../styles/ConsentBuilder';
import { leadAutoComplete } from '../../../styles/AddReminderStyle';

const ReviewLink = ({ onClose }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchLeads, setSearchLeads] = useState(null);
    const [isCalled, setIsCalled] = useState(false);
    const { taskSearchLead, isLoading } = useSelector((state) => state.lead);
    const { posts } = useSelector((state) => state.posts);
    const reviewSlug = posts?.find((post) => post.slug === 'patient-appt-review');
    const debouncedValue = useDebounce(searchQuery, 1000);
    const { dispatch } = store;
    const [isApptAvailable, setIsApptAvailable] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [appointmentData, setAppointmentData] = useState();
    const { generatedUrl } = useSelector((state) => state.feedbackReview);
    const { copy } = useCopyToClipboard();
    const [selectedLeadId, setSelectedLeadId] = useState('');

    const defaultValues = {
        leadName: '',
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        control,
    } = methods;

    const handleCloseReview = () => {
        onClose(true);
        dispatch(updateGeneratedUrl(''));
    };

    useEffect(() => {
        if (isCalled && taskSearchLead) {
            setSearchLeads(taskSearchLead);
            setIsCalled(false);
        }
    }, [taskSearchLead]);

    const searchLeadHandler = (value, reason) => {
        if (reason === 'input') {
            setSearchQuery(value);
        }
        if (value.length === 0) {
            dispatch(clearTaskLead());
        }
    };

    const noOptionsHandler = () => {
        if (searchQuery === '' || (searchLeads && searchLeads?.length === 0)) {
            return <SearchNotFound searchQuery={searchQuery} />;
        }
        return '';
    };

    const handleSearchLead = (value) => {
        if (value) {
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            setSelectedLeadId(value.id);
            const apptData = value.lead_treatments[0]?.appointments;
            const now = moment().format();
            const filterData = apptData?.filter(item => (item.status === 'ATTENDED'));
            const result = _.filter(filterData, obj => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() < now);
            result?.sort((a, b) => moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ')));
            if (result?.length === 0) {
                setIsApptAvailable(true);
                enqueueSnackbar('The selected lead hasn\'t any attended appointments.', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
                return;
            }
            const attendedApptDataAvailable = result && result[0];
            if (attendedApptDataAvailable?.status === 'ATTENDED') {
                setAppointmentData(attendedApptDataAvailable);
                setIsApptAvailable(false);
            }
        }
        const leadArr = [];
        value?.lead_treatments?.map((el) => leadArr.push({ treatment: el?.treatment, id: el?.id, price: el?.price }));
    };

    useEffect(() => {
        if (searchQuery.length > 0) {
            let search = searchQuery;
            if (Number(searchQuery) && !searchQuery.includes('+')) {
                search = `+44${searchQuery}`;
            }
            const status = ['CONSULTATION_ATTENDED', 'CONSULTATION_BOOKED', 'TREATMENT_STARTED', 'FAILED_TO_ATTEND_CONSULTATION', 'CLOSED'];
            setIsCalled(true);
            dispatch(searchLead(encodeURIComponent(search), practiceId, 'ReviewLink', status));
        }
    }, [debouncedValue]);

    const handleGenerateUrl = () => {
        const params = {
            practiceId,
            leadId: selectedLeadId,
            id: appointmentData?.id,
        };
        dispatch(generatingFeedbackUrl(params));
    };

    const handleCopy = (e) => {
        e.stopPropagation();
        dispatch(updateError({ success: true, message: 'Feedback link copied successfully' }));
        copy(generatedUrl);
    };

    return (
        <>
            <FormProvider methods={methods}>
                <Box>
                    <Scrollbar sx={{ maxHeight: 64 * 6 }}>
                        <Grid container sx={popupHeader}  >
                            <Typography variant='h5' sx={{ fontSize: '18px' }} >Search lead to generate link</Typography>
                            {reviewSlug?.acf?.tooltip_enabledisable === 'Enable' && (
                                <Tooltip
                                    title={reviewSlug?.acf?.tooltip_content}
                                >
                                    <Iconify icon='eva:info-outline' width={18} sx={{ cursor: 'pointer' }} />
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid container sx={header}  >
                            <Box sx={{ width: '100%', p: '10px 0' }}>
                                <Autocomplete
                                    autoFocus
                                    disableClearable
                                    control={control}
                                    name={'leadName'}
                                    inputValue={searchQuery}
                                    value={searchQuery}
                                    clearIcon={false}
                                    options={searchLeads && searchLeads?.length > 0 ? searchLeads : []}
                                    filterOptions={(option) => option}
                                    loading={isCalled && isLoading}
                                    sx={{ ...leadAutoComplete, mb: 0 }}
                                    onInputChange={(event, value, reason) => searchLeadHandler(value, reason)}
                                    noOptionsText={noOptionsHandler()}
                                    onChange={(e, value) => handleSearchLead(value)}
                                    isOptionEqualToValue={(option, value) => option?.first_name === value}
                                    getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                                    renderOption={(props, option) => {
                                        const { first_name: firstName, last_name: lastName, lead_contact } = option;
                                        const { email, phone } = lead_contact || {};
                                        return (
                                            (!isCalled && !isLoading) && <Box component='li' {...props} key={option.id}>
                                                {firstName || ''} {lastName || ''} | {email || ''} | {phone || ''}
                                            </Box>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Lead name'
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <Iconify icon='uil:search' />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                                <Button
                                    color='inherit'
                                    sx={topButton}
                                    onClick={handleCloseReview}
                                >
                                    cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    sx={consentButton}
                                    disabled={isApptAvailable}
                                    onClick={() => handleGenerateUrl()}
                                >
                                    Generate feedback link
                                </Button>
                                {generatedUrl !== '' && <Button
                                    color='primary'
                                    variant='contained'
                                    sx={consentButton}
                                    onClick={(e) => handleCopy(e)}
                                >
                                    Copy link
                                </Button>}
                            </Box>
                        </Grid>
                    </Scrollbar>
                </Box>
            </FormProvider>
        </>
    );
};

ReviewLink.propTypes = {
    onClose: PropTypes.func
};

export default ReviewLink;
