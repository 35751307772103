/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable arrow-body-style */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle , useEffect } from 'react';
import { useForm } from 'react-hook-form';
// @mui
import {
    Box,
    Card,
    Typography,
    Tabs,
    Tab,
    Divider,
} from '@mui/material';
import RecepientsByConditions from './RecepientsByConditions';
import ExistingAudience from './ExistingAudience';
// import RecepientsImportEmail from './RecepientsImportEmail';
// hooks
import {
    useTabs,
} from '../hooks';
import { FormProvider } from './hook-form';
import { dispatch, useSelector } from '../redux/store';
import { setCurrentTab } from '../redux/slices/campaign';

const CreateAudience = forwardRef(({ handleNext }, ref) => {
    const { selectedCampaign } = useSelector((state) => state.campaign);
    const { currentTab, onChangeTab } = useTabs(selectedCampaign?.audience?.id ? 'Existing audience' : Object.values(selectedCampaign)?.length > 0 ? 'By Conditions' : 'Existing audience');

    const AUDIENCE_TABS = [
        {
            value: 'Existing audience',
            component: <ExistingAudience />,
        },
        {
            value: 'By Conditions',
            component: <RecepientsByConditions />,
        },
        // {
        //     value: 'Import Emails',
        //     component: <RecepientsImportEmail handleNext={handleNext} ref={ref} />,
        // }
    ];

    useEffect(() => {
        dispatch(setCurrentTab(selectedCampaign?.audience?.id ? 'Existing audience' : Object.values(selectedCampaign)?.length > 0 ? 'By Conditions' : 'Existing audience'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultValues = {};

    const methods = useForm({
        defaultValues,
    });


    const {
        handleSubmit,
    } = methods;

    const onSubmit = async () => {
        handleNext(true);
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <FormProvider methods={methods}>
            <Box sx={{ mt: '50px' }}>
                <Typography variant="h6" sx={{ marginBottom: '24px', fontSize: '24px !Important' }}>
                    Create Audience
                </Typography>
                <Card sx={{ p: '24px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: '700', mb: 1 }}>Target Audience</Typography>
                    <Typography sx={{ color: '#777777', fontSize: '14px', mb: '16px' }}>Choose your audience for this campaign by selecting certain conditions.</Typography>
                    <Tabs
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        value={currentTab}
                        onChange={(e, newVal) => { dispatch(setCurrentTab(newVal)); onChangeTab('', newVal); }}
                        sx={{ mb: '12px' }}
                    >
                        {AUDIENCE_TABS.map((tab, index) => (
                            <Tab
                                disableRipple
                                key={index}
                                label={tab.value}
                                value={tab.value} />
                        ))}
                    </Tabs>
                    <Divider />
                    {AUDIENCE_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}
                </Card>
            </Box>
        </FormProvider>
    );
});

CreateAudience.propTypes = {
    handleNext: PropTypes.func,
};

export default CreateAudience;