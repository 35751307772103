import { grey, primary, common } from 'src/theme/palette';

export const cancelBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
    boxShadow: 'none',
    border: '1px solid rgba(145, 158, 171, 0.32)'
};

export const yesBtnStyle = {
    margin: '0px 10px',
    padding: '5px 20px',
    fontSize: '14px',
    borderRadius: '8px',
    minWidth: '100px',
    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
    bgcolor: `${common.mintyZest} !important`,
    color: common.white
};

export const dialogActionStyle = {
    padding: '40px !important',
    justifyContent: 'center'
};

export const noAppointmentTextStyle = (isLight) => ({
    textAlign: 'center',
    width: '100%',
    color: isLight ? grey[800] : common.white,
    fontSize: '18px',
    fontWeight: '700',
    mb: '13px',
    mt: '10px'
});

export const dateTextStyle = {
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: '700',
    color: 'common.grey'
};

export const treatmentTextStyle = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '400',
    color: 'common.grey'
};

export const commentTextStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '14px',
    fontWeight: '400',
    color: 'common.grey',
    minWidth: '10vw',
    maxHeight: '100px',
    overflow: 'auto'
};

export const nameTextStyle = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    color: 'common.grey'
};

export const treatmentTypoStyle = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '400',
    color: 'common.grey'
};

export const dateTypoTextStyle = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: '700',
    color: 'common.grey'
};

export const timeTypoTextStyle = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: '700',
    color: grey[700]
};

export const methodTypoTextStyle = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    color: 'common.grey'
};

export const statusTypoTextStyle = {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    color: 'common.grey'
};

export const noApptTypoStyle = (isLight) => ({
    textAlign: 'center',
    width: '100%',
    color: isLight ? grey[800] : 'common.white',
    fontSize: '18px',
    fontWeight: '700',
    mb: '13px',
    mt: '10px'
});

export const mixedAppointmentSwitchWrapper = { display: 'flex', justifyContent: 'start', alignItems: 'center', mb: 3 };
export const upComingSwitchStyle = (checked) => ({ fontSize: '14px', lineHeight: '22px', fontWeight: checked ? 400 : 800, color: checked ? grey[800] : primary.main });
export const pastSwitchStyle = (checked) => ({ fontSize: '14px', lineHeight: '22px', fontWeight: checked ? 800 : 400, color: checked ? primary.main : grey[800] });

export const newConsentButtonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    mb: '24px'
};

export const dialogTitle = { padding: '40px 40px 0px', textAlign: 'center' };
export const dialogActions = { padding: '40px !important', justifyContent: 'center' };
export const noAppointmentWrapper = ({ isLight }) => ({ bgcolor: isLight ? grey[200] : 'rgba(145, 158, 171, 0.16)', borderRadius: '8px', border: '1px dashed rgba(145, 158, 171, 0.32)' });
export const normalWhiteSpace = { whiteSpace: 'normal' };
export const noAppointmentTextWrapper = { display: 'flex', padding: '0 20px', flexDirection: 'column' };
export const noAppointmentImgStyle = { maxHeight: '160px', objectFit: 'contain' };
export const appointmentTableContainer = { width: '100%', position: 'relative', border: '1px solid rgba(145, 158, 171, 0.12)' };
export const appointmentTableHead = { ' .MuiTableCell-head': { boxShadow: 'none !important', minWidth: 'auto', p: '14px 12px !important' } };