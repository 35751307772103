/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
// @mui
import { Stack, Box, Table, TableBody, TableContainer } from '@mui/material';
import { NotFound } from '../../../components/empty-content';
import { TableHeadCustom } from '../../../components/table';
import useTable from '../../../hooks/useTable';
import ScriptTableRow from './ScriptTableRow';
import ViewCallScript from './ViewCallScript';
import EditCallScript from './EditCallScript';
import { dispatch } from '../../../redux/store';
import { getSubmissionSection } from '../../../redux/slices/callScript';
import { scriptTableStyle } from '../../../styles/CallScripts';

// ------------------------------------------------------------------

const scriptTableLabels = [
    { id: 'created_at', label: 'Date', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: '', label: '', align: 'left' }
];
export default function LeadDetailCallScript({ leadDetail }) {
    const formData = leadDetail && leadDetail?.form_submissions && [...leadDetail?.form_submissions]?.sort((a, b) => {
        const createdAtA = moment(b.created_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ');
        const createdAtB = moment(a.created_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ');
        return createdAtA.isBefore(createdAtB) ? -1 : 1;
    });
    const [renderComponent, setRenderComponent] = useState('scriptList');
    const [formId, setFormId] = useState('');
    const [submissionId, setSubmissionId] = useState('');

    const {
        selected,
        onSort,
        onSelectRow,
    } = useTable();

    useEffect(() => {
        dispatch(getSubmissionSection({submissionData: []}));
    }, [submissionId]);

    const onOpenDetailSection = (formId, id) => {
        setFormId(formId);
        setSubmissionId(id);
        setRenderComponent('scriptView', formId);
    };

    const onEditDetailSection = (formId, id) => {
        setFormId(formId);
        setSubmissionId(id);
        setRenderComponent('scriptEdit', formId);
    };

    return (
        <Stack>
            <Box sx={{ p: 2 }}>
                {formData?.length > 0 &&
                    <Box>
                        {renderComponent === 'scriptList' &&
                            <TableContainer sx={scriptTableStyle}>
                                <Table stickyHeader sx={{ minWidth: { xs: 'auto', sm: 'auto' } }}>
                                    <TableHeadCustom
                                        headLabel={scriptTableLabels}
                                        onSort={onSort}
                                        rowCount={formData.length}
                                        numSelected={selected.length}
                                        type={'LeadDetailCallScript'}
                                    />
                                    <TableBody>
                                        {formData !== undefined && formData.map((row, index) => (
                                            <Box sx={{ display: 'contents' }} key={index}>
                                                <ScriptTableRow
                                                    row={row}
                                                    selected={selected.includes(row.id)}
                                                    onSelectRow={() => onSelectRow(row.id)}
                                                    onOpenDetailSection={onOpenDetailSection}
                                                    onEditDetailSection={onEditDetailSection}
                                                />
                                            </Box>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                        {renderComponent === 'scriptView' &&
                            <ViewCallScript formId={formId} submissionId={submissionId} setRenderComponent={setRenderComponent} />
                        }
                        {renderComponent === 'scriptEdit' &&
                            <EditCallScript formId={formId} submissionId={submissionId} setRenderComponent={setRenderComponent} />
                        }
                    </Box>
                }
                {formData?.length === 0 && <NotFound />}
            </Box>
        </Stack>
    );
}

LeadDetailCallScript.propTypes = {
    leadDetail: PropTypes.any,
};