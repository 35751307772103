import React from 'react';
import PropTypes from 'prop-types';
// mui
import { Box, Typography } from '@mui/material';
// utils
import { getStatusCellTextColor } from '../../../../utils/getClassName';
// style
import {
    tableCellInnerBoxStyle,
    tableStatusTextStyle
} from '../../../../styles/NewLeadTableRowStyle';

const CustomStatusCell = ({ params, onChange }) => {
    if (!params?.row?.status) return null;
    return (
        <Box onClick={() => onChange(params?.row)} sx={tableCellInnerBoxStyle}>
            <Typography variant="05 Others/Label" sx={{ cursor: 'pointer', ...tableStatusTextStyle, ...getStatusCellTextColor(params?.row?.status) }}>
                {params?.row?.status !== 'CLOSED'
                    ? params?.row?.status.charAt(0) + params?.row?.status.toLowerCase().replace(/_/g, ' ').slice(1)
                    : 'Completed'}
            </Typography>
        </Box>
    );
};

export default CustomStatusCell;

CustomStatusCell.propTypes = {
    params: PropTypes.any,
    onChange: PropTypes.func,
};
