/* eslint-disable react-hooks/exhaustive-deps */
import { useState, memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';
// @mui
import { Box, Slide, Popper, TextField, InputAdornment, ClickAwayListener, IconButton, Button, Typography, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// components
import { Iconify } from '../../../components';
import { SearchNotFound } from '../../../components/search-not-found';
// store
import { searchLead, clearApiData, leadPage } from '../../../redux/slices/lead';
import { useSelector, dispatch, } from '../../../redux/store';
// utils
import { getStatusButton } from '../../../utils/getStatusButton';
// styles
import { treatmentBoxStyle } from '../../../styles/SwitchUserPopupStyle';
import { autocompleteStyle, popperStyle, searchBarStyle } from '../../../styles/searchBarStyle';

const SearchBarStyledComponent = styled('div')(({ theme }) => (searchBarStyle({ theme })));

const PopperStyledComponent = styled((props) => <Popper {...props} />)(({ theme }) => (popperStyle({ theme })));

const Searchbar = () => {
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const { searchedLead, isLoading } = useSelector((state) => state.lead);

    const debouncedValue = useDebounce(searchQuery, 1000);

    const handleClick = (LeadId) => {
        setOpen(false);
        dispatch(clearApiData());
        setSearchQuery('');
        dispatch(leadPage({}));
        if (LeadId?.is_archived) {
            navigate(`practice/${practiceId}/archived/${LeadId.id}`);
            return;
        }
        navigate(`practice/${practiceId}/lead-detail/${LeadId.id}`, { state: { shouldRedirectToOverview: true } });
    };

    const searchLeadHandler = (value) => {
        setSearchQuery(value);
        if (value.length === 0) {
            dispatch(clearApiData());
        }
    };

    useEffect(() => {
        if (open) {
            setOpen(false);
        }
    }, [pathname]);

    useEffect(() => {
        if (searchQuery.length === 0) {
            dispatch(clearApiData());
        }
    }, [searchedLead]);


    useEffect(() => {
        if (searchQuery.length > 0) {
            let search = searchQuery;
            if (Number(searchQuery) && !searchQuery.includes('+')) {
                search = `+44${searchQuery}`;
            }
            dispatch(searchLead(encodeURIComponent(search), practiceId));
        }
    }, [debouncedValue]);

    const noOptionsHandler = () => {
        if (searchQuery === '' || searchedLead && searchedLead.length === 0) {
            return <SearchNotFound searchQuery={searchQuery} />;
        }
        return '';
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                {!open && (
                    <IconButton onClick={() => setOpen((prev) => !prev)} ariaLabel='search-button'>
                        <Iconify icon={'eva:search-fill'} width={20} height={20} />
                    </IconButton>
                )}
                <Slide direction='down' in={open} mountOnEnter unmountOnExit >
                    <SearchBarStyledComponent>
                        <Autocomplete
                            sx={autocompleteStyle}
                            ariaLabel='search-slide-input'
                            autoHighlight
                            disablePortal
                            disableClearable
                            loading={isLoading}
                            popupIcon={null}
                            PopperComponent={PopperStyledComponent}
                            options={searchedLead && searchedLead.length > 0 ? searchedLead : []}
                            filterOptions={(option) => option}
                            onInputChange={(event, value) => searchLeadHandler(value)}
                            noOptionsText={noOptionsHandler()}
                            getOptionLabel={(option) => option.first_name}
                            onChange={(e, value) => handleClick(value)}
                            renderOption={(props, option) => {
                                const { first_name: firstName, last_name: lastName, lead_contact: leadContact, is_archived: isArchived, lead_treatments: leadTreatments } = option;
                                const { email, phone } = leadContact || {};
                                const latestTreatment = leadTreatments && leadTreatments.length > 0
                                    ? [...leadTreatments].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))[0]
                                    : null;
                                return (
                                    <Box component='li' {...props} key={option.id} sx={{ display: 'flex !important', justifyContent: 'space-between !important' }} >
                                        <Typography sx={{ fontSize: '14px', width: '50%', mr: 2 }}>{firstName || ''}  {lastName || ''} | {email || ''}  | {phone || ''}</Typography>
                                        <Box sx={treatmentBoxStyle}>
                                            {latestTreatment ?
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {latestTreatment.treatment || ''} {getStatusButton(latestTreatment.status)}
                                                </Box>
                                                : ''}
                                            {isArchived && <Button disabled variant='contained' size='small' sx={{ m: '0 0 0 16px', fontSize: '12px !important', p: '2px 10px !important' }} onClick={() => handleClick(option)}> Archived </Button>}
                                        </Box>
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoFocus
                                    placeholder='Search...'
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </SearchBarStyledComponent>
                </Slide>
            </div>
        </ClickAwayListener>
    );
};

export default memo(Searchbar);


