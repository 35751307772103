import { createSlice } from '@reduxjs/toolkit';
import { callApi } from 'src/utils/apiCall';
import axios from '../../utils/axios';
import { dispatch, store } from '../store';
import { updateError } from './user';
import { getPracticeId } from './clincheck';
import {
    getParams,
    getThreadMailsUrl,
    getThreadMessageUrl,
    getUrl,
    handleApiError,
    handleLabelSuccess,
    handleStarUnStartMessage,
    handleThreadMails,
    setEmailData,
} from '../../utils/mailUtils';

const initialState = {
    isLoading: false,
    error: null,
    mails: { byId: {}, allIds: [] },
    labels: [],
    inComingMails: { results: [], count: null },
    draftsMails: { count: null, results: [] },
    starredMails: { count: null, results: [] },
    labelMails: { count: null, results: [] },
    outgoingMails: { count: null, results: [] },
    emailMessages: { count: 0, results: [] },
    searchMessages: { count: 0, results: [] },
    selectedMailInfo: null,
    draftsMessage: null,
    reCalledApi: false,
    threadLabels: { count: 0, results: [] },
    inboxLabels: { count: 0, results: [] },
    labelName: '',
    inComingMailsCount: '',
    selectedEmailId: '',
    sendEmailStatus: '',
    emailsCount: '',
};

const slice = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        startLoading(state) {
            state.isLoading = true;
        },
        setReCalledApi(state, action) {
            state.reCalledApi = action.payload;
        },
        endLoading(state) {
            state.isLoading = false;
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setSelectedMailInfo(state, action) {
            state.selectedMailInfo = action.payload;
        },
        getLabelsSuccess(state, action) {
            state.isLoading = false;
            state.labels = action.payload;
        },
        setInComingMails(state, action) {
            state.inComingMails.results = [...state.inComingMails.results, ...action.payload.results];
            state.inComingMails.count = action.payload.count;
        },
        setInComingMailsCount(state, action) {
            state.inComingMailsCount = action.payload;
        },
        setInDraftsMails(state, action) {
            state.draftsMails.results = [...state.draftsMails.results, ...action.payload.results];
            state.draftsMails.count = action.payload.count;
        },
        setStarredMails(state, action) {
            state.starredMails.results = [...state.starredMails.results, ...action.payload.results];
            state.starredMails.count = action.payload.count;
        },
        setLabelMails(state, action) {
            state.labelMails.results = [...state.labelMails.results, ...action.payload.results];
            state.labelMails.count = action.payload.count;
        },
        setOutGoingMails(state, action) {
            state.outgoingMails.results = [...state.outgoingMails.results, ...action.payload.results];
            state.outgoingMails.count = action.payload.count;
        },
        clearMails(state) {
            state.inComingMails.results = [];
            state.inComingMails.count = null;
            state.draftsMails.results = [];
            state.draftsMails.count = null;
            state.outgoingMails.results = [];
            state.outgoingMails.count = null;
            state.starredMails.results = [];
            state.starredMails.count = null;
            state.labelMails.results = [];
            state.labelMails.count = null;
        },
        setMailInfo(state, action) {
            state.emailMessages.results = [...action.payload.results];
            state.emailMessages.count = action.payload.count;
        },
        setSearchMails(state, action) {
            state.searchMessages.results = [...action.payload.results];
            state.searchMessages.count = action.payload.count;
        },
        setDraftMailMessages(state, action) {
            state.draftsMessage = action.payload;
        },
        setSearchMailsClear(state) {
            state.searchMessages.results = [];
            state.searchMessages.count = 0;
        },
        setThreadLabels(state, action) {
            state.threadLabels.results = [...action.payload.results];
            state.threadLabels.count = action.payload.count;
        },
        setInboxLabels(state, action) {
            state.inboxLabels.results = action.payload.results;
            state.inboxLabels.count = action.payload.count;
        },
        setInboxAddMoreLabels(state, action) {
            state.inboxLabels.results = [...state.inboxLabels.results, ...action.payload.results];
            state.inboxLabels.count = action.payload.count;
        },
        setLabelName(state, action) {
            state.labelName = action.payload;
        },
        setSelectedEmailId(state, action) {
            state.selectedEmailId = action.payload;
        },
        setSendEmailStatus(state, action) {
            state.sendEmailStatus = action.payload;
        },
        setEmailsCount(state, action) {
            state.emailsCount = action.payload;
        },
    },
});
export const {
    reset,
    clearMails,
    setSelectedMailInfo,
    setReCalledApi,
    setLabelName,
    setSelectedEmailId,
    setSendEmailStatus,
    setEmailsCount,
    setInComingMails,
    setOutGoingMails,
    setInDraftsMails,
    setStarredMails,
    setLabelMails,
    endLoading,
    hasError,
} = slice.actions;
// Reducer
export default slice.reducer;

export function getLabels() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/mail/labels');
            dispatch(slice.actions.getLabelsSuccess(response.data.labels));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getThreadMails({ mailType, leadId, limit, offset, reload }) {
    return async () => {
        const practiceId = getPracticeId();
        if (offset === 0) {
            dispatch(slice.actions.clearMails());
        }
        dispatch(slice.actions.setSearchMailsClear());
        if (reload !== false) {
            dispatch(slice.actions.startLoading());
        }
        try {
            const params = `${getParams({ mailType })}${leadId ? `&lead__in=${leadId}` : ''}`;
            const response = await callApi(`${getThreadMailsUrl({ mailType, practiceId, limit, offset })}${params}`);
            if (response.status === 200) {
                setEmailData({ mailType, response });
                dispatch(slice.actions.setReCalledApi(false));
            }
            dispatch(slice.actions.endLoading());
        } catch (error) {
            handleApiError({ error });
        }
    };
}

export function getThreadMessages(practiceId, id, mailType) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(getThreadMessageUrl({ practiceId, mailType, id }));
            if (response.status === 200) {
                if (mailType === 'Drafts') {
                    dispatch(slice.actions.setDraftMailMessages(response.data));
                } else {
                    dispatch(slice.actions.setMailInfo(response.data));
                }
            } else {
                dispatch(slice.actions.updateError({ success: 'false', message: response?.data?.message || 'Something went wrong' }));
            }
            dispatch(slice.actions.endLoading());
        } catch (error) {
            handleApiError({ error });
        }
    };
}

export function inboxEmailCounter(practiceId, leadId, outSideCounter) {
    return async () => {
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/?fields={}&with_replies=${true}&is_unread=${true}&lead__in=${leadId || ''}`);
            if (response.status === 200) {
                if (outSideCounter) {
                    dispatch(slice.actions.setEmailsCount(response.data));
                    return;
                }
                dispatch(slice.actions.setInComingMailsCount(response.data));
            }
            dispatch(slice.actions.endLoading());
        } catch (error) {
            handleApiError({ error });
        }
    };
}

export function scheduleMessages(practiceId, id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            await callApi(`/api/v1/practices/${practiceId}/transactional-messages/${id}/schedule/`, 'POST',);
            dispatch(slice.actions.endLoading());
        } catch (error) {
            handleApiError({ error });
        }
    };
}

export function deleteMessages({ practiceId, id, mailType, leadId, limit, offset }) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await callApi(`/api/v1/practices/${practiceId}/transactional-messages/${id}/`, 'delete',);
            if (response.status === 204) {
                dispatch(updateError({ success: true, message: 'Draft email deleted successfully' || 'Something went wrong' }));
                dispatch(getThreadMails({ practiceId, mailType, leadId, limit, offset }));
                dispatch(slice.actions.endLoading());
            }
        } catch (error) {
            handleApiError({ error });
        }
    };
}
export function starMessages({ id, mailType, leadId, limit, offset }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/${id}/star/`, 'POST',);
            handleStarUnStartMessage({ response, practiceId, mailType, leadId, limit, offset, isAdded: true });
        } catch (error) {
            handleApiError({ error });
        }
    };
}
export function searchInbox({ search, mailType, leadId, senderEmails }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            dispatch(slice.actions.startLoading());
            const response = await callApi(getUrl({ practiceId, mailType, search, leadId, senderEmails }));
            if (response.status === 200) {
                dispatch(slice.actions.setSearchMails(response.data));
            } else {
                dispatch(slice.actions.updateError({ success: 'false', message: response?.data?.message || 'Something went wrong' }));
            }
            dispatch(slice.actions.endLoading());
        } catch (error) {
            handleApiError({ error: error.message });
        }
    };
}
export function replyMessages({ id, messageId, data, mailType, selectedMailId }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            dispatch(slice.actions.startLoading());
            const response = await callApi(`/api/v1/practices/${practiceId}/inbox/threads/${id}/messages/${messageId}/reply/`, 'POST', data);
            if (response.status === 201) {
                dispatch(updateError({ success: true, message: 'Message sent successfully' || 'Something went wrong' }));
                dispatch(getThreadMessages(practiceId, selectedMailId, mailType));
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data?.message) || 'Something went wrong' }));
            }
            dispatch(slice.actions.endLoading());
        } catch (error) {
            handleApiError({ error: error.message });
        }
    };
}
export function sentMessages({ data, title, successDone, leadID, sendUnableToContact }) {
    return async () => {
        try {
            const practiceId = getPracticeId();
            const { sendEmailStatus } = store.getState().mail;
            const response = await callApi(`/api/v1/practices/${practiceId}/transactional-messages/`, 'POST', data);
            if (response.status === 201) {
                if (title === 'SendEmail') {
                    if (sendEmailStatus !== 'Save as drafts') {
                        dispatch(scheduleMessages(practiceId, response.data.id));
                    }
                    handleThreadMails({ leadID });
                    sendUnableToContact();
                    return;
                }
                dispatch(
                    updateError({
                        success: true,
                        message:
                            title !== 'Send'
                                ? 'Message saved as draft successfully'
                                : 'Message sent successfully' || 'Something went wrong',
                    })
                );
                if (title === 'Send') {
                    dispatch(scheduleMessages(practiceId, response.data.id));
                    handleThreadMails({ leadID });
                    sendUnableToContact();
                    return;
                }
                dispatch(slice.actions.setReCalledApi(true));
                handleThreadMails({ leadID });
                successDone();
            } else {
                dispatch(updateError({ success: false, message: JSON.stringify(response?.data?.message) || 'Something went wrong' }));
            }
            dispatch(slice.actions.endLoading());
        } catch (error) {
            handleApiError({ error: error.message });
        }
    };
}
export const getThreadLabels = (practiceId, threadId) => async () => {
    try {
        const response = await axios.get(`/api/v1/practices/${practiceId}/inbox/threads/${threadId}/labels/`, {
            params: {},
        });
        dispatch(slice.actions.setThreadLabels(response.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};
export const getInboxLabels = (practiceId, limit, offset) => async () => {
    try {
        const response = await axios.get(
            `/api/v1/practices/${practiceId}/inbox-labels/?limit=${limit}&offset=${offset}&ordering=-created_at`,
            { params: {} }
        );
        if (response.status === 200) {
            if (offset === 0) {
                dispatch(slice.actions.setInboxLabels(response.data));
                return;
            }
            dispatch(slice.actions.setInboxAddMoreLabels(response.data));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};
export const deleteInboxLabel = (practiceId, threadId, labelId) => async () => {
    try {
        const response = await callApi(`/api/v1/practices/${practiceId}/inbox-labels/${labelId}/`, 'DELETE',);
        if (response.status === 204) {
            handleLabelSuccess({ practiceId, type: 'deleted' });
            if (threadId !== '') {
                dispatch(getThreadLabels(practiceId, threadId));
            }
        } else {
            dispatch(updateError({ success: false, message: JSON.stringify(response?.data?.message) || 'Something went wrong' }));
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};
