import { styled } from '@mui/material/styles';

export const PhoneIconButton = styled('div')(() => ({
    backgroundColor: 'rgba(0, 171, 85, 0.16)',
    borderRadius: 12,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16
}));
export const followUpBoxStyle = {
    p: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};
export const followUpTitleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
    fontSize: {
        md: '24px',
        xs: '1.2rem'
    }
};
export const followUpSubTitleStyle = {
    textAlign: 'center',
    color: '#637381',
    fontSize: '14px',
    mb: '24px',
    display: 'flex',
    justifyContent: 'center'
};
export const followUpCardStyle = {
    p: { sm: 3, xs: 2 },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'none',
    maxWidth: '90vw',
    padding: '0 !important',
    borderRadius: 0
};
export const numberLinkStyle = {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginBottom: '24px',
    maxWidth: '100%'
};
export const numberTextStyle = {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: { lg: '48px', md: '48px', sm: '3.25rem', xs: '1.2rem' },
};
export const callScriptButtonStyle = {
    borderRadius: '8px',
    border: '1px solid rgba(145, 158, 171, 0.12)',
    width: '195px',
    height: '66px',
    color: '#637381'
};
export const textStyle = {
    textAlign: { xs: 'center' },
    marginTop: { lg: '0', md: '0' },
    marginBottom: { md: '24px', xs: '16px' }
};
export const buttonGroupStyle = {
    border: '1px solid rgba(145, 158, 171, 0.12)',
    flexDirection: { md: 'row', xs: 'column' },
    maxWidth: '100%',
    borderRadius: '8px',
    padding: '4px'
};
export const unableContactButtonStyle = (outcome) => ({
    backgroundColor: outcome === 'Unable to contact' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none', boxShadow: 'none' },
    borderRadius: '8px !important',
    // eslint-disable-next-line no-nested-ternary
    color: outcome === 'Unable to contact' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    margin: '0 !important',
    marginRight: { md: '4px', xs: '0' },
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    textTransform: 'none !important'
});
export const appointmentButtonStyle = (outcome) => ({
    backgroundColor: outcome === 'Appointment Not Booked' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none', boxShadow: 'none' },
    borderRadius: '8px !important',
    // eslint-disable-next-line no-nested-ternary
    color: outcome === 'Appointment Not Booked' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    marginRight: { md: '4px', xs: '0' },
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    textTransform: 'none !important'
});
export const bookingButtonStyle = (outcome) => ({
    backgroundColor: outcome === 'Booking Details' ? 'rgba(0, 171, 85, 0.08)' : 'transparent',
    ':hover': { backgroundColor: 'rgba(0, 171, 85, 0.08)', color: '#00AB55', border: 'none', boxShadow: 'none' },
    borderRadius: '8px !important',
    // eslint-disable-next-line no-nested-ternary
    color: outcome === 'Booking Details' ? '#00AB55' : '#637381',
    border: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    padding: { md: '16px', xs: '12px 8px' },
    justifyContent: { xs: 'flex-start' },
    textAlign: 'left',
    textTransform: 'none !important'
});