export const socialLink = [
    {
        name: 'Facebook',
        value: 'FACEBOOK'
    },
    {
        name: 'Instagram',
        value: 'INSTAGRAM'
    },
    {
        name: 'Twitter',
        value: 'TWITTER'
    },
    {
        name: 'Youtube',
        value: 'YOUTUBE'
    },
    {
        name: 'TikTok',
        value: 'TIKTOK'
    },
    {
        name: 'Online Booking',
        value: 'ONLINE_BOOKING'
    },
    {
        name: 'Google Business Listing',
        value: 'GOOGLE_BUSINESS_LISTING'
    },
    {
        name: 'Google Business Review',
        value: 'GOOGLE_BUSINESS_REVIEW'
    }
];