import React from 'react';
import { Button, Stack } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid';
import { EmptyContent } from '../../../../components/empty-content';
import { Iconify } from '../../../../components';
import { useSelector } from '../../../../redux/store';
import { gridToolbarColumnsButtonStyle } from '../../../../styles/NewLeadTableRowStyle';

const DataGridSlots = ({ isMultiTreatmentTable, tableTitle, openBulkArchive }) => {
    const { multiLead } = useSelector((state) => state.lead);
    return {
        toolbar: () => {
            if (isMultiTreatmentTable) return null;
            return (
                <>
                    <GridToolbarContainer>
                        <Stack spacing={1} flexGrow={1} direction='row' alignItems='center' justifyContent='flex-end'>
                            {multiLead?.leadId?.length !== 0 && <Button
                                size='small'
                                color='error'
                                startIcon={
                                    <Iconify icon={tableTitle === 'Archived Leads' ? 'ic:sharp-restore' : 'solar:trash-bin-trash-bold'} />
                                }
                                onClick={() => openBulkArchive.onTrue()}
                                disabled={multiLead?.leadId?.length === 0}
                                sx={{ display: 'inline-flex' }}
                            >
                                {tableTitle === 'Archived Leads' ? 'Restore' : 'Archive'} ({multiLead?.leadId?.length})
                            </Button>}
                            <GridToolbarColumnsButton
                                startIcon={<Iconify icon={'system-uicons:settings'} width={24} height={24} />}
                                sx={gridToolbarColumnsButtonStyle}
                            />
                        </Stack>
                    </GridToolbarContainer>
                </>
            );
        },
        noRowsOverlay: () => <EmptyContent title='No Data' />,
        noResultsOverlay: () => null,
    };
};
export default DataGridSlots;
