/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Button,
    Stack,
    MenuItem,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    Grid,
    Container,
    useTheme,
    InputLabel,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
// components
import { Iconify } from '../../../../components';
import { FormProvider } from '../../../../components/hook-form';
// redux
import { createTreatmentPayment, editTreatmentPayment, setDepositLoading } from '../../../../redux/slices/deposit';
import { dispatch } from '../../../../redux/store';
// validation
import { depositPaymentTypeSchema } from '../../../../validations/commonValidations';
// constants/utils
import { paymentTypeOptions } from '../../../../constants/contactedVia';
import { getPaymentType, handleCreatePayment, handleCreateProcessorPayment, handleEditPaymentSuccess, handleGetPaymentTypeData } from '../../../../utils/depositUtils';
// style
import {
    reminderStackStyle,
    reminderFooterStyle,
    cancelButtonStyle,
    saveButtonStyle,
    treatmentBoxStyle,
} from '../../../../styles/TreatmentEditPopover';
import { primary } from '../../../../theme/palette';
import { AmountInputStyle, ErrorTextStyle, depositAmountLabelType } from '../../../../styles/PaymentRefund';
import { primaryTypographyPropsStyle, treatmentEditPopupListItem } from '../../../../styles/LeadDetailView';

const DepositPaymentPopover = ({ rowData, open, anchorEl, handleClickAwayLister }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const treatmentId = rowData?.treatment_id;
    const leadId = rowData?.lead_id;

    const depositedAmount = rowData?.deposit_payment_data?.[0]?.amount;

    const getAdditionalPaidAmount = (data) => data?.deposit_payment_data?.[1]?.amount;

    const [selected, setSelected] = useState(handleGetPaymentTypeData(rowData) || '');

    const defaultValues = {
        paymentMethod: handleGetPaymentTypeData(rowData) || '',
        depositAmount: depositedAmount || 0.0,
        additionalAmount: getAdditionalPaidAmount(rowData) || 0.0,
    };

    const methods = useForm({
        resolver: yupResolver(depositPaymentTypeSchema),
        defaultValues,
    });

    const {
        setValue,
        watch,
        handleSubmit,
        formState: { errors },
    } = methods;
    const values = watch();

    const handleCreatePaymentSuccess = (data) => {
        const payload = { data, depositPaymentData: rowData?.deposit_payment_data, isEdit: false, treatmentId, leadId, handleClickAwayLister };
        switch (data.paymentMethod) {
            case 'DEPOSIT_AND_GOCARDLESS':
            case 'DEPOSIT_AND_STRIPE':
                handleCreateProcessorPayment(payload);
                return;
            case 'DEPOSIT_AND_FINANCE':
                handleCreatePayment(payload);
                return;
            default:
                break;
        }
        handleClickAwayLister(leadId);
    };

    const onSubmit = async (data) => {
        dispatch(setDepositLoading(true));
        const body = { payment_type: getPaymentType(data.paymentMethod), amount: data.depositAmount };
        const payload = { body, treatmentId, leadId };
        if (rowData?.deposit_payment_data?.length > 0) {
            dispatch(editTreatmentPayment({
                ...payload, paymentId: rowData?.deposit_payment_data?.[0]?.id,
                handleSuccess: () => handleEditPaymentSuccess({ data, depositPaymentData: rowData?.deposit_payment_data, treatmentId, leadId, handleClickAwayLister })
            }));
            return;
        }
        dispatch(createTreatmentPayment({
            ...payload,
            handleSuccess: () => handleCreatePaymentSuccess(data)
        }));
    };

    const handleSelectPaymentType = (paymentType) => {
        setSelected(paymentType);
        setValue('paymentMethod', paymentType);
        if (handleGetPaymentTypeData(rowData) === paymentType) {
            setValue('depositAmount', depositedAmount || 0.0);
            setValue('additionalAmount', getAdditionalPaidAmount(rowData) || 0.0);
            return;
        }
        setValue('depositAmount', 0.0);
        setValue('additionalAmount', 0.0);
    };

    const handleChangePrice = (name, value) => {
        setValue(name, value);
    };

    const getLabel = (key) => {
        switch (key) {
            case 'DEPOSIT_AND_GOCARDLESS':
                return 'Go cardless amount';
            case 'DEPOSIT_AND_STRIPE':
                return 'Stripe amount';
            default:
                return 'Finance amount';
        }
    };

    return (
        <ClickAwayListener onClickAway={() => handleClickAwayLister()}>
            <Popper open={open} anchorEl={anchorEl} transition placement='top' sx={{ zIndex: '999999' }}>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper sx={{ borderRadius: 2 }}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{ ...treatmentBoxStyle, width: 'auto' }}>
                                    <Stack sx={reminderStackStyle}>
                                        <Grid container spacing={1} sx={{ margin: 'auto', width: '100%' }}>
                                            <Container maxWidth={'xl'} sx={{ padding: '0 !important' }}>
                                                <Box sx={{ width: '100%', pt: '0', textAlign: 'left' }}>
                                                    <Stack>
                                                        {paymentTypeOptions?.map((row, index) => (
                                                            <>
                                                                <MenuItem
                                                                    onClick={() => handleSelectPaymentType(row.value)}
                                                                    value={row.value}
                                                                    key={index}
                                                                    sx={treatmentEditPopupListItem(selected, row.value, theme)}
                                                                >
                                                                    <Typography sx={{ ...primaryTypographyPropsStyle, mb: 0 }}>{row?.label}</Typography>
                                                                    {row.value === selected && (
                                                                        <Iconify
                                                                            icon={'ic:sharp-check'}
                                                                            width={24}
                                                                            height={24}
                                                                            sx={{ mr: 1, fontWeight: 400, color: primary.main, marginLeft: 'auto' }}
                                                                        />
                                                                    )}
                                                                </MenuItem>
                                                                {row.value === selected && (
                                                                    <Box sx={{ m: '5px 0 8px', width: '100%' }}>
                                                                        <InputLabel id='demo-simple-select-label' sx={depositAmountLabelType}>
                                                                            {selected === 'FULL_PAYMENT' ? 'Full payment amount' : selected === 'FINANCE_ONLY' ? 'Finance only amount' : 'Deposit amount'}
                                                                        </InputLabel>
                                                                        <CurrencyInput
                                                                            labelId='demo-simple-select-label'
                                                                            name='depositAmount'
                                                                            value={values.depositAmount}
                                                                            decimalSeparator={'.'}
                                                                            thousandSeparator={','}
                                                                            onValueChange={(value, name) => handleChangePrice(name, value)}
                                                                            className='currency-input'
                                                                            decimalsLimit={2}
                                                                            decimalScale={2}
                                                                            inputType={'text'}
                                                                            prefix={'£ '}
                                                                            style={AmountInputStyle(errors.depositAmount, 'appointment', isLight)}
                                                                            step={1}
                                                                        />
                                                                        {errors.depositAmount && (
                                                                            <Typography variant='p' sx={ErrorTextStyle}>
                                                                                This field is required
                                                                            </Typography>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                                {row.value === selected && (selected === 'DEPOSIT_AND_GOCARDLESS' || selected === 'DEPOSIT_AND_STRIPE' || selected === 'DEPOSIT_AND_FINANCE') &&
                                                                    <Box sx={{ m: '5px 0 8px', width: '100%' }}>
                                                                        <InputLabel id='deposit-amount-label' sx={depositAmountLabelType}>
                                                                            {getLabel(selected)}
                                                                        </InputLabel>
                                                                        <CurrencyInput
                                                                            labelId='deposit-amount-label'
                                                                            name='additionalAmount'
                                                                            placeholder={getLabel(selected)}
                                                                            value={values?.additionalAmount}
                                                                            decimalSeparator={'.'}
                                                                            thousandSeparator={','}
                                                                            onValueChange={(value, name) => handleChangePrice(name, value)}
                                                                            className='currency-input'
                                                                            decimalsLimit={2}
                                                                            decimalScale={2}
                                                                            inputType={'text'}
                                                                            prefix={'£ '}
                                                                            style={AmountInputStyle(errors.additionalAmount, '', isLight)}
                                                                            step={1}
                                                                        />
                                                                        {errors.additionalAmount && (
                                                                            <Typography variant='p' sx={ErrorTextStyle}>
                                                                                This field is required
                                                                            </Typography>
                                                                        )}
                                                                    </Box>}
                                                            </>
                                                        ))}
                                                        {!isEmpty(errors) && isEmpty(selected) && (
                                                            <Typography variant='p' sx={{ ...ErrorTextStyle, justifyContent: 'center' }}>
                                                                Required field is missing
                                                            </Typography>
                                                        )}
                                                    </Stack>
                                                </Box>
                                            </Container>
                                        </Grid>
                                    </Stack>
                                    <Box sx={{ ...reminderFooterStyle, position: 'relative', p: '16px 16px !important' }}>
                                        <Button color='inherit' sx={cancelButtonStyle} onClick={() => handleClickAwayLister()}>
                                            Cancel
                                        </Button>
                                        <Button color='primary' variant='contained' type='submit' sx={saveButtonStyle}>
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </FormProvider>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </ClickAwayListener>
    );
};

export default DepositPaymentPopover;

DepositPaymentPopover.propTypes = {
    rowData: PropTypes.object,
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    handleClickAwayLister: PropTypes.func,
};