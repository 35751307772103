import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const CustomLastNameCell = ({ params }) =>
    <Typography variant="subtitle2" align="center" textAlign='center'>
        <span style={{ textTransform: 'capitalize' }}>{params?.row?.last_name || ''}</span>
    </Typography>;

export default CustomLastNameCell;

CustomLastNameCell.propTypes = {
    params: PropTypes.any,
};