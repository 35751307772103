import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { Iconify } from '../../../../../components';
import { FormProvider, RHFSelect } from '../../../../../components/hook-form';
import LastContactedField from '../LastContactedField';
import { DATE_TITLES } from '../../../../../constants/newLead';
import { dateWrapperStyle, selectFilter, selectMenuStyle } from '../../../../../styles/NewLead';
import { tableCellInnerBoxStyle } from '../../../../../styles/NewLeadTableRowStyle';

const LastContactCell = ({ methods, handleChangeDateFormat, setValue, handleNavigate }) => ({
    field: 'lastContact',
    headerName: (
        <>
            <Box sx={dateWrapperStyle}>
                Last contacted
                <Iconify
                    sx={{ mr: '8px', ml: '8px' }}
                    icon={'mdi:calendar-filter-outline'}
                    width={15}
                    height={15}
                />
                <Box sx={selectFilter}>
                    <FormProvider methods={methods}>
                        <RHFSelect
                            name={'lastContact'}
                            label='Title'
                            SelectProps={{ native: false }}
                            onChange={(e) => handleChangeDateFormat(e, 'lastContact', setValue)}
                        >
                            {DATE_TITLES.map((option, index) => (
                                <MenuItem key={index} value={option.value} sx={selectMenuStyle}>
                                    <Iconify sx={{ mr: '8px' }} icon={option.icon} width={18} height={18} />
                                    {option.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </FormProvider>
                </Box>
            </Box>
        </>
    ),
    sortable: false,
    width: 230,
    minWidth: 210,
    renderCell: (params) => (
        <Box onClick={() => handleNavigate(params?.row)} sx={tableCellInnerBoxStyle}>
            <LastContactedField rowData={params?.row} />
        </Box>
    ),
});

export default LastContactCell;
