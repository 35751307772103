import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogTitle, FormControl, Switch, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from '../../redux/store';
// style
import { cancelBtnStyle } from '../../styles/NewLead';
import { centerDiv, submitBtnStyle } from '../../styles/SettingsMenuStyle';

const StartTreatmentPopup = ({
    isOpen,
    handleCloseStartTreatment,
    isPaymentMandatory,
    depositStatus,
    handleDepositPendingChange,
    startTreatmentAPI,
}) => {
    const { isLoader } = useSelector((state) => state.loaderReducer);

    const getTreatmentStartPopupStyle = () => {
        if (isPaymentMandatory) return { padding: '0px 40px 0px', textAlign: 'center' };
        return { padding: '40px 40px 0px', textAlign: 'center' };
    };

    const onSubmit = () => startTreatmentAPI();

    return (
        <Dialog open={isOpen} onClose={handleCloseStartTreatment}>
            {isPaymentMandatory && (
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                    <FormControl>
                        <Typography variant='subtitle2' sx={{ m: '0 0 10px', textAlign: 'center' }}>
                            Deposit Paid?
                        </Typography>
                        <Box sx={{ ...centerDiv, mb: 2 }}>
                            <Typography>No</Typography>
                            <Switch
                                checked={depositStatus}
                                onChange={handleDepositPendingChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography>Yes</Typography>
                        </Box>
                    </FormControl>
                </Box>
            )}
            <DialogTitle sx={{ ...getTreatmentStartPopupStyle() }}>Are you sure you want to start the treatment?</DialogTitle>
            <DialogActions sx={{ padding: '40px !important', justifyContent: 'center' }}>
                <Button sx={cancelBtnStyle} onClick={handleCloseStartTreatment}>
                    Cancel
                </Button>
                <LoadingButton
                    variant='outlined'
                    loading={isLoader}
                    loadingPosition='start'
                    onClick={onSubmit}
                    sx={submitBtnStyle}
                    disabled={isPaymentMandatory && !depositStatus || isLoader}
                >
                    Yes
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default StartTreatmentPopup;

StartTreatmentPopup.propTypes = {
    isOpen: PropTypes.bool,
    handleCloseStartTreatment: PropTypes.func,
    isPaymentMandatory: PropTypes.bool,
    depositStatus: PropTypes.string,
    handleDepositPendingChange: PropTypes.func,
    startTreatmentAPI: PropTypes.func,
};