import React from 'react';
import { MenuItem } from '@mui/material';
import { DataGridTableMoreMenu } from '../table';
import { Iconify } from '..';

const PaymentActionsCell = ({ openMenu, menuCurrentTarget, onOpen, onClose, onEditPayment, onDeletePayment }) => ({
    field: 'actions',
    type: 'actions',
    headerName: '',
    width: 45,
    minWidth: 45,
    maxWidth: 45,
    align: 'center',
    getActions: (params) => [
        <div className='action-custom-cell' key={params.row.id}>
            <DataGridTableMoreMenu
                open={openMenu === params.row.id}
                sx={{ width: '180px' }}
                anchorEl={menuCurrentTarget}
                onOpen={(e) => onOpen(e, params)}
                onClose={onClose}
                actions={
                    <>
                        <MenuItem onClick={onEditPayment}>
                            <Iconify icon={'solar:pen-bold'} />
                            Edit payment
                        </MenuItem>
                        <MenuItem sx={{ color: 'error.main' }} onClick={onDeletePayment}>
                            <Iconify icon='solar:trash-bin-trash-bold' width={20} height={20} sx={{ mr: 1 }} />
                            Delete payment
                        </MenuItem>
                    </>
                }
            />
        </div>
    ]
});

export default PaymentActionsCell;