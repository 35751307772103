export const SIMILAR_TABLE_HEAD = [
    { id: 'date', label: 'Date', dateFormat: true, checkbox: true },
    { id: 'first_name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Phone' },
    { id: 'treatment', label: 'Treatment' },
    { id: 'treatment_price', label: 'Treatment price' },
    { id: 'status', label: 'Status' },
    { id: 'notes', label: 'Notes' },
    { id: 'archived', label: '' },
];
export const DUPLICATE_TABLE_HEAD = [
    { id: 'date', label: 'Date', dateFormat: true, checkbox: true },
    { id: 'first_name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Phone' },
    { id: 'treatment', label: 'Treatment' },
    { id: 'treatment_price', label: 'Treatment price' },
    { id: 'status', label: 'Status' },
    { id: 'notes', label: 'Notes' },
    { id: 'show_similar', label: '' },
    { id: '', label: '' },
    { id: 'action', label: '' },
];

export const archiveFilterData = [
    { label: 'Data with archived leads', value: 'is_un_archived' },
    { label: 'Data without archived leads', value: 'is_archived' },
];